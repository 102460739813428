import React, { useState, useEffect, Fragment } from "react";
import { List, arrayMove } from "react-movable";
import { Button, TextField, MenuItem, makeStyles } from "@material-ui/core";
import { Notification, useNotify } from "react-admin";
import { APIClient, generateAuthHeader } from "../../lib";

export const ProductOrdering = props => {
  const { record, forSupplier, forDigitalContentGroup } = props;
  /* 
  This component is used for 3 levels of product ordering
  1) supplier: id = supplier ID
  2) campaign_products: id = campaign ID
  3) digital_content_inventory_group_products: id = digital_content_inventory_group ID
  */
  const resourceId = record.id;
  const classes = styles();
  const [fetchUrl, setFetchUrl] = useState("");
  const [updateUrl, setUpdateUrl] = useState("");
  const [originalCategories, setOriginalCategories] = useState([]);
  const [originalProducts, setOriginalProducts] = useState({});
  const notify = useNotify();

  useEffect(() => {
    if (!resourceId) return;
    if (forSupplier) {
      setFetchUrl(
        `/product_suppliers/${resourceId}/category-and-product-order`,
      );
      setUpdateUrl(
        `/product_suppliers/${resourceId}/category-and-product-order`,
      );
    } else if (forDigitalContentGroup) {
      setFetchUrl(
        `/digital_content_inventory_groups/${resourceId}/category-and-product-order`,
      );
      setUpdateUrl(
        `/digital_content_inventory_groups/${resourceId}/category-and-product-order`,
      );
    } else {
      setFetchUrl(`/campaign_products/campaigns/${resourceId}`);
      setUpdateUrl(
        `/campaign_products/campaigns/${resourceId}/update_display_order`,
      );
    }
  }, [resourceId, forSupplier, forDigitalContentGroup]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(fetchUrl);
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setOriginalCategories(data.categories);
      setOriginalProducts(data.categorizedProducts);
    };
    if (fetchUrl) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUrl]);

  return (
    <div className={classes.container}>
      <div className={classes.directions}>
        Drag & Drop the Chips to reorder the Categories and Products then press
        save
      </div>

      <Categories
        originalCategories={originalCategories}
        updateUrl={updateUrl}
      />

      <hr className={classes.hr} />

      <Products
        originalCategories={originalCategories}
        originalProducts={originalProducts}
        updateUrl={updateUrl}
      />
      <Notification />
    </div>
  );
};

function Categories({ originalCategories, updateUrl }) {
  const classes = styles();
  const notify = useNotify();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories([...originalCategories]);
  }, [originalCategories]);

  const updateOrder = async () => {
    const update = {
      type: "categories",
      categoryOrder: categories.map(c => c.id),
    };
    const res = await APIClient.put(updateUrl, update, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("Your changes have been saved.", { type: "info" });
  };

  return (
    <Fragment>
      <div className={classes.subHeader}>categories</div>

      <List
        values={categories}
        lockVertically={true}
        onChange={({ oldIndex, newIndex }) => {
          setCategories(arrayMove(categories, oldIndex, newIndex));
        }}
        renderList={({ children, props, isDragged }) => (
          <ul
            {...props}
            style={{ cursor: isDragged ? "grabbing" : undefined }}
            className={classes.list}
          >
            {children}
          </ul>
        )}
        renderItem={({ value, props, isDragged, isSelected }) => {
          return (
            <li
              key={value.id}
              {...props}
              style={{
                ...props.style,
                cursor: isDragged ? "grabbing" : "grab",
                backgroundColor:
                  isDragged || isSelected ? "#A9A9A9" : "#FFFFFF",
              }}
              className={classes.item}
            >
              {value.name}
            </li>
          );
        }}
      />
      <Button onClick={updateOrder} variant="contained" color="primary">
        save changes
      </Button>
    </Fragment>
  );
}

function Products({ originalCategories, originalProducts, updateUrl }) {
  const classes = styles();
  const notify = useNotify();
  const [products, setProducts] = useState({});
  const [categoryId, setCategoryId] = useState("");

  useEffect(() => {
    setProducts({ ...originalProducts });
  }, [originalProducts]);

  const productChange = (newOrder, categoryId) => {
    setProducts({ ...products, [categoryId]: newOrder });
  };

  const updateOrder = async () => {
    const productOrder = {};
    Object.keys(products).forEach(catId => {
      productOrder[catId] = products[catId].map(p => p.id);
    });
    const update = { type: "products", productOrder };

    const res = await APIClient.put(updateUrl, update, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("Your changes have been saved.", { type: "info" });
  };

  return (
    <Fragment>
      <div className={classes.subHeader}>products</div>
      <div>
        <TextField
          select
          label="Select a Category to sort"
          value={categoryId}
          className={classes.categorySelect}
          onChange={({ target }) => setCategoryId(target.value)}
        >
          {originalCategories.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {categoryId && Array.isArray(products[categoryId]) && (
        <div key={categoryId} className={classes.categoryProducts}>
          <List
            values={products[categoryId]}
            lockVertically={true}
            onChange={({ oldIndex, newIndex }) => {
              productChange(
                arrayMove(products[categoryId], oldIndex, newIndex),
                categoryId,
              );
            }}
            renderList={({ children, props, isDragged }) => (
              <ul
                {...props}
                style={{ cursor: isDragged ? "grabbing" : undefined }}
                className={classes.list}
              >
                {children}
              </ul>
            )}
            renderItem={({ value, props, isDragged, isSelected }) => {
              const { id, product_name, thumbnail_image } = value;
              return (
                <li
                  key={id}
                  {...props}
                  style={{
                    ...props.style,
                    cursor: isDragged ? "grabbing" : "grab",
                    backgroundColor:
                      isDragged || isSelected ? "#A9A9A9" : "#FFFFFF",
                  }}
                  className={classes.item}
                >
                  <div className={classes.productInfo}>
                    <img
                      className={classes.thumbnail}
                      src={thumbnail_image}
                      alt="thumbnail"
                    />
                    <div> {product_name}</div>
                  </div>
                </li>
              );
            }}
          />
        </div>
      )}
      <Button onClick={updateOrder} variant="contained" color="primary">
        save changes
      </Button>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    padding: "24px 0",
  },
  directions: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    paddingBottom: 24,
  },
  subHeader: {
    color: theme.palette.primary.main,
    fontSize: "24px",
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "24px 0",
    textAlign: "center",
  },
  hr: {
    margin: "24px 0",
  },
  list: {
    padding: 0,
    width: "fit-content",
    marginBottom: 24,
  },
  item: {
    padding: "4px 8px",
    margin: "6px 0",
    listStyleType: "none",
    border: "1px solid #A9A9A9",
    borderRadius: 8,
    zIndex: 99999,
    width: "fit-content",
  },
  categorySelect: {
    minWidth: 250,
    marginBottom: 24,
  },
  categoryProducts: {
    maxWidth: "100%",
  },
  productInfo: {
    display: "flex",
    alignItems: "center",
  },
  thumbnail: {
    maxWidth: 30,
    maxHeight: 30,
    marginRight: 12,
  },
}));
