import React from "react";
import { TabbedShowLayout, TabbedShowLayoutTabs, Tab, Show } from "react-admin";
import { CompanyGroupProducts } from "./CompanyGroupProducts";
import { CPGCampaignDefaults } from "./CPGCampaignDefaults";
import { RepTab } from "./RepTab";
import { ProductCampaignReceiptTemplate } from "../../donationReceiptConfig/ProductCampaignReceiptTemplate";
import { donationReceiptConfigLevels } from "../../../lib";

export const CompanyProductGroupDetails = props => {
  const supplier_product_group_id = props.id;
  const Title = ({ record }) => (
    <span>
      {record ? record.group_name : ""} #{supplier_product_group_id}
    </span>
  );

  return (
    <Show {...props} title={<Title />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="Products" path="">
          <CompanyGroupProducts />
        </Tab>
        <Tab label="Campaign Defaults" path="campaign_defaults">
          <CPGCampaignDefaults />
        </Tab>
        <Tab label="Reps" path="reps">
          <RepTab />
        </Tab>
        <Tab label="Receipt Email Config" path="email_config">
          <ProductCampaignReceiptTemplate
            urlSuffix={`${donationReceiptConfigLevels.COMPANY_PRODUCT_GROUP}?supplier_product_group_id=${supplier_product_group_id}`}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
