import React, { useState, useEffect, Fragment } from "react";
import { useNotify } from "react-admin";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import { useSelector } from "react-redux";
import {
  APIClient,
  APIUtilClient,
  generateAuthHeader,
  isFulfiller,
} from "../../../lib";

export function AssignDeliveryNums({ campaign_id, prizeFulfillmentOnly }) {
  const classes = styles();
  const [showDialog, setShowDialog] = useState(false);
  const role = useSelector(state => state.user.role);

  const toggleDialog = () => {
    setShowDialog(prev => !prev);
  };

  if (isFulfiller(role) || prizeFulfillmentOnly) return <></>;
  return (
    <Fragment>
      <Button
        size="small"
        variant="outlined"
        startIcon={<LooksOneIcon />}
        color="primary"
        onClick={toggleDialog}
        className={classes.button}
      >
        Assign Delivery Nums
      </Button>
      {showDialog && (
        <Modal campaign_id={campaign_id} toggleDialog={toggleDialog} />
      )}
    </Fragment>
  );
}

function Modal({ campaign_id, toggleDialog }) {
  const [order, setOrder] = useState("class");
  const [loading, setLoading] = useState(false);
  const [alreadyAssigned, setAlreadyAssigned] = useState(null);
  const notify = useNotify();
  const classes = styles();

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/volunteer_campaigns/delivery_numbers_check?campaign_id=${campaign_id}`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setAlreadyAssigned(Boolean(data.assignedCount));
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const assignNums = async () => {
    setLoading(true);

    const res = await APIUtilClient.post(
      "/volunteer_campaigns/assign_delivery_numbers",
      { campaign_id, order },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;

    if (error) notify(errorMessage, "warning");
    else notify("Student Delivery Numbers have been successfully assigned");

    setLoading(false);
    toggleDialog();
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Assign Student Delivery Numbers</DialogTitle>
      <DialogContent>
        {alreadyAssigned && (
          <DialogContentText className={classes.warning}>
            Warning! Numbers were already assigned. Re-assigning numbers might
            change student/s numbers.
          </DialogContentText>
        )}
        <DialogContentText>Select your preferences</DialogContentText>
        <div>
          <TextField
            className={classes.input}
            select
            label="Order Numbers By"
            value={order}
            onChange={e => setOrder(e.target.value)}
          >
            <MenuItem value="last_name">Last Name</MenuItem>
            <MenuItem value="class">Class</MenuItem>
          </TextField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={loading || alreadyAssigned === null}
          onClick={assignNums}
        >
          Assign Numbers
          {loading && (
            <CircularProgress
              color="primary"
              className={classes.spinner}
              size={24}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = makeStyles(_theme => ({
  button: { marginRight: 6, marginBottom: 6 },
  spinner: { position: "absolute" },
  input: {
    width: 230,
    marginTop: 24,
  },
  warning: { color: "red" },
}));
