import React from "react";
import {
  StatsCard,
  DailyPerformanceChart,
  PerformanceChart,
} from "../../components";
import { connect } from "react-redux";
import { styles } from "./Dashboard.styles";

const _SiteAdminDashboard = props => {
  const {
    todaysDonationCount,
    todaysDonationTotal,
    todaysBiggestDonation,
    totalActiveCampaigns,
    totalOrgs,
    totalDonations,
    topCampaignData,
  } = props;
  const classes = styles();
  return (
    <div className={classes.wrapper}>
      <StatsCard
        field1={todaysDonationCount}
        field1Txt="donations today"
        field2={todaysDonationTotal}
        field2Txt="total donations today"
        field3={todaysBiggestDonation}
        field3Txt="biggest donation today"
      />

      <div className={classes.charts}>
        <div className={classes.leftChart}>
          <PerformanceChart
            data={topCampaignData}
            title="Top Campaign Performance"
            label="Campaign"
          />
        </div>
        <DailyPerformanceChart />
      </div>
      <StatsCard
        field1={totalActiveCampaigns}
        field1Txt="active campaigns"
        field2={totalOrgs}
        field2Txt="organizations"
        field3={totalDonations}
        field3Txt="lifetime total donations"
      />
    </div>
  );
};

export const SiteAdminDashboard = connect(state => {
  return { ...state.dashboard };
})(_SiteAdminDashboard);
