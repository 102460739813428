import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  theme => ({
    // LIST
    drawer: {
      minWidth: 800,
      [theme.breakpoints.down("xs")]: {
        minWidth: "unset",
      },
    },
    editDrawer: {
      minWidth: "100%",
    },
    drawerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 10px 0 16px",
      fontSize: 18,
    },
    statusChip: {
      color: "#FFFFFF",
      backgroundColor: "#008000",
    },
    receivingChip: {
      backgroundColor: "orange",
    },
    closedChip: {
      backgroundColor: "#FF0000",
    },
    // EDIT / RECEIVE
    editContainer: {
      margin: "16px",
    },
    table: {
      width: "fit-content",
      minWidth: 300,
      marginBottom: 24,
    },
    thumbnail: {
      maxHeight: 50,
    },
    exportButton: {
      marginBottom: 16,
    },
    addAll: {
      minWidth: 90,
    },
    qtyInput: {
      minWidth: 64,
    },

    //RECEIVE
    closeOrder: {
      backgroundColor: "#FF0000",
      color: "#FFFFFF",
    },
  }),
  { classNamePrefix: "po" },
);
