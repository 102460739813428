import { makeStyles, Button } from "@material-ui/core";
import { useForm } from "react-final-form";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { DonationDetailModes } from "../../../../types";
import { ButtonSpinner } from "../../../../components";
import { useIsMobile } from "../../../../hooks/ui";
import { Fragment } from "react";

type Props = {
  submitting: boolean;
  setShowDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setMode: React.Dispatch<React.SetStateAction<DonationDetailModes>>;
};
export function Bottom({ submitting, setShowDelete, setMode }: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const form = useForm();

  return (
    <div className={classes.container}>
      {!isMobile && (
        <Fragment>
          <Button
            variant="text"
            className={classes.delete}
            startIcon={<DeleteOutlineIcon />}
            onClick={() => setShowDelete(true)}
            disabled={submitting}
          >
            DELETE
          </Button>
          <div>
            <Button
              color="primary"
              variant="text"
              onClick={() => setMode(DonationDetailModes.view)}
              disabled={submitting}
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              className={classes.save}
              disabled={submitting}
              onClick={() => form.submit()}
            >
              Save
              <ButtonSpinner show={submitting} />
            </Button>
          </div>
        </Fragment>
      )}

      {isMobile && (
        <div className={classes.mobileButtons}>
          <Button
            color="primary"
            className={classes.save}
            disabled={submitting}
            onClick={() => form.submit()}
          >
            Save changes
            <ButtonSpinner show={submitting} />
          </Button>
          <Button
            color="primary"
            variant="text"
            onClick={() => setMode(DonationDetailModes.view)}
            disabled={submitting}
          >
            CANCEL
          </Button>
        </div>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    height: 84,
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      padding: "16px 16px 8px 16px",
    },
  },
  delete: {
    color: theme.palette.error.main,
  },
  save: {
    width: 120,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  mobileButtons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));
