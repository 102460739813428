import React, { useState } from "react";
import { useNotify } from "react-admin";
import { Button, makeStyles } from "@material-ui/core";
import { APIClient, generateAuthHeader } from "../lib";

export function SendOrgRestPWButton({ record }) {
  const { id, orgEmail } = record;
  const notify = useNotify();
  const classes = styles();
  const [loading, setLoading] = useState(false);

  const handleClick = async e => {
    e.stopPropagation();
    setLoading(true);
    const res = await APIClient.post(
      "/users/forgot-password",
      { email: orgEmail, campaign_id: id, ccRep: true },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) notify(errorMessage, "warning");
    else notify("Reset Link successfully sent");
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  return (
    <Button
      onClick={handleClick}
      variant="outlined"
      color="primary"
      className={classes.button}
      disabled={loading}
      size="small"
    >
      send link
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  button: { width: 86 },
}));
