import { Fade, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import { LightbulbSVG } from "../../assets/LightbulbSVG";

interface TooltipProps {
  text: React.ReactNode | string;
}

export function Tooltip({ text }: TooltipProps) {
  const classes = styles();
  const [dismissed, setDismissed] = useState(false);

  if (dismissed) return <></>;
  return (
    <Fade in={true} timeout={500} style={{ transitionDelay: "1000ms" }}>
      <div className={classes.tooltipContainer}>
        <div className={classes.tooltip}>
          <div className={classes.lightbulb}>
            <LightbulbSVG />
          </div>
          <div className={classes.text}>{text}</div>
          <IconButton
            size="small"
            className={classes.iconButton}
            onClick={() => setDismissed(true)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Fade>
  );
}

const styles = makeStyles(_theme => ({
  tooltipContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  tooltip: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    top: 12,
    width: 320,
    backgroundColor: "#1B47AA",
    color: "#FFFFFF",
    padding: "6px 8px 15px 12px",
    borderRadius: 8,
    "&:before": {
      content: '""',
      position: "absolute",
      right: 152,
      top: "-7px",
      borderTop: "none",
      borderRight: "8px solid transparent",
      borderLeft: "8px solid transparent",
      borderBottom: "8px solid #1B47AA",
    },
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "20px",
    width: 236,
    marginTop: 12,
  },
  iconButton: {
    height: "fit-content",
    color: "#FFFFFF",
  },
  lightbulb: {
    marginTop: 12,
  },
}));
