import React, { useState } from "react";
import { Notification, useNotify } from "react-admin";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { APIClient, generateAuthHeader } from "../../../lib";

export function ConvertToPOModal({
  supplier_id,
  open,
  toggle,
  sourceData = {},
}) {
  const { source_id, sourceName, ...products } = sourceData;
  const notify = useNotify();
  const [submitting, setSubmitting] = useState(false);

  const createPO = async () => {
    setSubmitting(true);
    const items = [];
    Object.keys(products).forEach(product_id => {
      const { needed } = products[product_id];
      if (needed > 0) {
        items.push({
          product_id: Number(product_id),
          item_qty: Number(needed),
        });
      }
    });

    if (!items.length) {
      toggle(false);
      notify(`There are no needed Products for ${sourceName}!`, "warning");
      setSubmitting(false);
      return;
    }

    const res = await APIClient.post(
      "/product_source_purchase_orders/create_from_projections",
      {
        supplier_id,
        product_source_id: Number(source_id),
        product_source_purchase_order_products: items,
      },
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) notify(errorMessage, "warning");
    else notify(`PO successfully created for ${sourceName}.`);

    toggle(false);
    setSubmitting(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>CONFIRM ACTION:</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This action will generate a PO with with all currently calculated
          NEEDED quantities.
        </DialogContentText>
        <DialogContentText>
          Once generated, see `PO` tab to access the PO and to edit details.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => toggle(false)}>cancel</Button>
        <Button
          onClick={createPO}
          color="primary"
          autoFocus
          disabled={submitting}
        >
          create PO
        </Button>
      </DialogActions>
      <Notification />
    </Dialog>
  );
}
