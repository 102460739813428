import React, { useState, useEffect } from "react";
import { useNotify, useMutation } from "react-admin";
import { Button, CircularProgress } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { styles } from "./ProductGroup.styles";

export const AddProduct = ({ product_id, group_id, refetch }) => {
  const classes = styles();
  const notify = useNotify();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowButton(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const [add, { loading }] = useMutation(
    {
      type: "create",
      resource: "supplier_product_groups/add_group_product",
      payload: { data: { product_id, group_id: Number(group_id) } },
    },
    {
      onFailure: error => notify(error.message, "warning"),
      onSuccess: () => {
        refetch();
        notify("Product Added");
      },
    },
  );

  if (!showButton) return <></>;
  return (
    <Button
      variant="contained"
      className={classes.addButton}
      startIcon={<SaveIcon />}
      onClick={e => {
        e.stopPropagation();
        add();
      }}
      disabled={loading}
    >
      add
      {loading && <CircularProgress size={18} className={classes.addSpinner} />}
    </Button>
  );
};
