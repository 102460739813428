import React, { useState, useEffect, Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  useNotify,
  required,
  SelectInput,
  FormDataConsumer,
} from "react-admin";
import { TextField, makeStyles } from "@material-ui/core";
import {
  APIClient,
  generateAuthHeader,
  checkpointEmailDayOffsetOptions,
} from "../../lib";
import { OnChange } from "react-final-form-listeners";
import { useField } from "react-final-form";

const requiredField = [required()];

export default function CheckpointEmailCreate(props) {
  const classes = styles();
  const notify = useNotify();
  const [emailOptions, setEmailOptions] = useState([]);
  const [emails, setEmails] = useState({});

  useEffect(() => {
    fetchEmailOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEmailOptions = async () => {
    const res = await APIClient.get("/checkpoint_emails/email_options", {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setEmailOptions(Object.values(data));
    setEmails(data);
  };

  return (
    <Create
      {...props}
      successMessage="Checkpoint Email added successfully"
      title="Add Checkpoint Email"
    >
      <SimpleForm redirect="list">
        <SelectInput
          source="email_code"
          label="Select An Email"
          choices={emailOptions}
          optionText="description"
          optionValue="code"
          translateChoice={false}
          validate={requiredField}
          fullWidth
        />

        <FormDataConsumer>
          {({ formData }) => {
            const { email_code } = formData;
            if (!email_code || !emails[email_code]) return null;
            const {
              defaultSubject,
              greeting,
              closer,
              defaultMainBody,
              hasDaySelector,
              daySelectorLabel,
            } = emails[email_code];
            if (!hasDaySelector) formData.day_offset = undefined;
            return (
              <Fragment>
                <ChangeListener emails={emails} />
                {hasDaySelector && (
                  <SelectInput
                    label={daySelectorLabel}
                    source="day_offset"
                    choices={checkpointEmailDayOffsetOptions}
                    translateChoice={false}
                    validate={requiredField}
                    fullWidth
                  />
                )}
                <TextInput
                  initialValue={defaultSubject}
                  source="subject"
                  validate={requiredField}
                  multiline
                  fullWidth
                />
                <TextField
                  variant="filled"
                  label="Greeting"
                  value={greeting}
                  multiline
                  disabled
                  fullWidth
                  className={classes.disabledInput}
                />
                <TextInput
                  initialValue={defaultMainBody}
                  source="main_body"
                  validate={requiredField}
                  multiline
                  fullWidth
                />
                <TextField
                  variant="filled"
                  label="Closer"
                  value={closer}
                  disabled
                  fullWidth
                  multiline
                  className={classes.disabledInput}
                />
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}

const ChangeListener = ({ emails }) => {
  const codeField = useField("email_code");
  const subjectField = useField("subject");
  const mainBodyField = useField("main_body");
  return (
    <OnChange name="email_code">
      {() => {
        if (emails[codeField.input.value]) {
          const { defaultSubject, defaultMainBody } = emails[
            codeField.input.value
          ];
          subjectField.input.onChange(defaultSubject);
          mainBodyField.input.onChange(defaultMainBody);
        }
      }}
    </OnChange>
  );
};

const styles = makeStyles(_theme => ({
  disabledInput: {
    marginBottom: 19,
  },
}));
