import { useEffect, useState } from "react";
import { useDataProvider, useNotify } from "react-admin";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { useHistory } from "react-router-dom";
import { RA_SUB_PATH, isNewAppUser, roles } from "../../lib";

export function ImpersonateOrg({ orgId, campaignId }) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const history = useHistory();
  const [currUrl, setCurrUrl] = useState("");

  useEffect(() => {
    const { pathname, search } = history.location;
    setCurrUrl(`${pathname}${search}`);
  }, [history.location]);

  const impersonate = e => {
    e.stopPropagation();
    dataProvider
      .getOne("orgs/impersonate", { id: orgId })
      .then(({ data }) => {
        const { impersonationToken } = data;
        localStorage.setItem("orgImpersonationToken", impersonationToken);
        const redirect = isNewAppUser(roles.ORG) ? "/" : `/${RA_SUB_PATH}`;
        if (campaignId) localStorage.setItem("selectedCampaignId", campaignId);
        localStorage.setItem("orgImpersonatePrevUrl", currUrl);
        window.location.replace(redirect);
      })
      .catch(({ message }) => {
        return notify(message || "an unexpected error occurred", "warning");
      });
  };

  return <DashboardIcon onClick={e => impersonate(e)} />;
}
