import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { store } from "../../lib/store";
import { useDispatch } from "react-redux";

export type RootState = ReturnType<typeof store.getState>;
export type AppThunkAction<ReturnType = any> = ThunkAction<
  ReturnType,
  RootState,
  any,
  AnyAction
>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
