import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  IconButton,
  makeStyles,
  TextField as MuiTextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DownloadIcon from "@material-ui/icons/GetApp";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  APIClient,
  APIUtilClient,
  generateAuthHeader,
  launchPacketSections,
  required,
  downloadPdf,
} from "../../lib";
const SECTIONS = [
  {
    field: "headerItems",
    header: "HEADER ITEMS",
    section: launchPacketSections.HEADER,
  },
  {
    field: "materialItems",
    header: "MATERIAL ITEMS",
    section: launchPacketSections.MATERIAL,
    isMaterial: true,
  },
  {
    field: "internalItems",
    header: "INTERNAL ITEMS",
    section: launchPacketSections.INTERNAL,
  },
];

export function LaunchPacket({ record }) {
  const { id: campaign_id, orgName } = record;
  const classes = styles();
  const notify = useNotify();
  const [initialValues, setInitialValues] = useState({});
  const [defaultMaterialFields, setDefaultMaterialFields] = useState([]);

  useEffect(() => {
    if (campaign_id) fetchLaunchPacket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchLaunchPacket = async () => {
    const res = await APIClient.get(
      `/campaign_launch_packets/campaigns/${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setInitialValues(data);
    setDefaultMaterialFields(data.defaultMaterialFields);
  };

  const onSubmit = async values => {
    const {
      notes,
      headerItems = [],
      internalItems = [],
      materialItems = [],
    } = values;
    const update = {
      notes,
      campaign_launch_packet_items: [
        ...headerItems,
        ...internalItems,
        ...materialItems,
      ],
    };

    const res = await APIClient.put(
      `/campaign_launch_packets/campaigns/${campaign_id}`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) {
      return notify(errorMessage, "warning");
    }
    fetchLaunchPacket();
    notify("info has been successfully updated");
  };

  const download = async () => {
    const res = await APIUtilClient.get(
      `/campaign_launch_packets/campaigns/${campaign_id}/pdf`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    return downloadPdf(
      res,
      `Campaign ${campaign_id} - ${orgName} - Launch Packet.pdf`,
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.downloadContainer}>
        <Button
          variant="contained"
          color="primary"
          endIcon={<DownloadIcon />}
          onClick={download}
        >
          export
        </Button>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {SECTIONS.map(e => {
                const { field, header, section, isMaterial } = e;
                return (
                  <TableContainer
                    key={field}
                    component={Paper}
                    className={classes.table}
                  >
                    <FieldArray name={field}>
                      {({ fields }) => (
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={2}>{header}</TableCell>
                              <TableCell align="right">
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    fields.push({ section, value: null })
                                  }
                                  startIcon={<AddIcon />}
                                >
                                  add
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {fields.map((row, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>
                                    {isMaterial && (
                                      <Field
                                        name={`${row}.name`}
                                        label="Name"
                                        validate={required}
                                        className={classes.input}
                                        render={({ input, meta, ...rest }) => {
                                          const showError =
                                            ((meta.submitError &&
                                              !meta.dirtySinceLastSubmit) ||
                                              meta.error) &&
                                            meta.touched;
                                          return (
                                            <Autocomplete
                                              {...rest}
                                              {...input}
                                              className={classes.input}
                                              freeSolo
                                              selectOnFocus
                                              onInputChange={(_e, newValue) =>
                                                input.onChange(newValue)
                                              }
                                              onChange={(_e, newValue) =>
                                                input.onChange(newValue)
                                              }
                                              options={defaultMaterialFields}
                                              getOptionLabel={option => option}
                                              renderInput={params => (
                                                <MuiTextField
                                                  variant="standard"
                                                  {...params}
                                                  multiline
                                                  helperText={
                                                    showError
                                                      ? meta.error ||
                                                        meta.submitError
                                                      : undefined
                                                  }
                                                  error={showError}
                                                />
                                              )}
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {!isMaterial && (
                                      <Field
                                        variant="standard"
                                        component={TextField}
                                        name={`${row}.name`}
                                        label="Name"
                                        validate={required}
                                        multiline={true}
                                        className={classes.input}
                                      />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      variant="standard"
                                      component={TextField}
                                      name={`${row}.value`}
                                      label="Value"
                                      multiline={true}
                                      className={classes.input}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton
                                      onClick={() => fields.remove(index)}
                                      size="small"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      )}
                    </FieldArray>
                  </TableContainer>
                );
              })}

              <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Notes:</TableCell>
                      <TableCell>
                        <Field
                          variant="standard"
                          component={TextField}
                          name="notes"
                          multiline={true}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
              >
                save changes
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    minHeight: 400,
    paddingBottom: 40,
  },
  downloadContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  table: {
    margin: "24px 0",
    width: "fit-content",
    minWidth: 780,
  },
  input: {
    minWidth: 300,
  },
}));
