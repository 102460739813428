import React from "react";
import {
  Button,
  IconButton,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import { connect } from "react-redux";
import { updateProfile as updateProfileAction } from "../../customState";
import { TextInput } from "../../components";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import {
  APIClient,
  generateAuthHeader,
  required,
  email as emailValidation,
  convertFileToBase64,
  composeValidators,
  isVolunteer,
  isOrg,
  isOrgOrVolunteer,
  isRep,
} from "../../lib";
import { useNotify, usePermissions } from "react-admin";
import { ImageField, ImageInput } from "react-admin";
import { styles } from "./Profile.styles";
import SaveIcon from "@material-ui/icons/Save";

function _UpdateProfile(props) {
  const {
    profile: {
      notification_emails = [],
      donation_notifications,
      phone,
      email,
      logo,
      first_name,
      last_name,
      agreed_to_receive_texts,
    },
    updateProfile,
    textingEnabled,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const { permissions: { role } = {} } = usePermissions();

  const onSubmit = async values => {
    let { logoBase64, notification_emails, ...postObj } = values;
    postObj.notification_emails = notification_emails
      ? notification_emails
      : [];
    if (logoBase64) {
      const b64Obj = await convertFileToBase64(logoBase64, "logoBase64").catch(
        console.error,
      );
      postObj = { ...b64Obj, ...postObj };
    }

    const res = await APIClient.put("/users/profile", postObj, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) {
      return notify(errorMessage, "warning");
    }
    updateProfile(data);
    notify("your profile has been successfully updated", { type: "info" });
  };

  return (
    <div className={classes.tabContainer}>
      <Form
        mutators={{ ...arrayMutators }}
        initialValues={{
          notification_emails,
          donation_notifications,
          phone,
          email,
          logo,
          first_name,
          last_name,
          agreed_to_receive_texts,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {isVolunteer(role) && (
                <TextInput
                  name="first_name"
                  label="First Name"
                  validation={required}
                />
              )}
              {isVolunteer(role) && (
                <TextInput
                  name="last_name"
                  label="Last Name"
                  validation={required}
                />
              )}
              <TextInput
                name="email"
                label="Email"
                validation={composeValidators(required, emailValidation)}
              />
              {(isOrgOrVolunteer(role) || isRep(role)) && (
                <TextInput name="phone" label="Phone" validation={required} />
              )}
              {isVolunteer(role) && textingEnabled && (
                <div>
                  <FormControlLabel
                    control={
                      <Field
                        name="agreed_to_receive_texts"
                        component={Checkbox}
                        color="primary"
                        type="checkbox"
                      />
                    }
                    label="I agree to receive account related texts"
                  />
                </div>
              )}
              {isOrg(role) && (
                <FieldArray name="notification_emails">
                  {({ fields }) => (
                    <div>
                      <div>
                        Send contact form submissions and donation
                        notifications(if enabled) to
                      </div>
                      {fields.map((name, index) => (
                        <div key={name} className={classes.arrayInputContainer}>
                          <Field
                            name={`${name}.email`}
                            label="Email"
                            variant="filled"
                            component={TextField}
                            validate={composeValidators(
                              required,
                              emailValidation,
                            )}
                            className={classes.arrayInput}
                            type={"email"}
                            InputLabelProps={{ shrink: true }}
                          />
                          <IconButton
                            onClick={() => fields.remove(index)}
                            size="small"
                          >
                            <RemoveIcon />
                          </IconButton>
                        </div>
                      ))}
                      <Button
                        className={classes.addButton}
                        onClick={() => fields.push({ email: "" })}
                      >
                        <AddIcon />
                        {` Add Email`}
                      </Button>
                    </div>
                  )}
                </FieldArray>
              )}
              {isOrg(role) && (
                <Field
                  name={"donation_notifications"}
                  type="checkbox"
                  render={({ input, meta: _rm, ...rest }) => (
                    <FormControlLabel
                      control={<Switch {...rest} {...input} />}
                      label="Enable Donation/Sale Notifications"
                    />
                  )}
                />
              )}
              {isOrg(role) && (
                <div className={classes.logoWrapper}>
                  <div>Organization Logo</div>
                  <div className={classes.logoContainer}>
                    <div className={classes.currentLogo}>
                      <div className={classes.label}>Current Logo</div>
                      <img src={logo} alt="logo" className={classes.image} />
                    </div>
                    <ImageInput
                      className={classes.image}
                      source="logoBase64"
                      label="Change Logo"
                      accept="image/*"
                    >
                      <ImageField
                        source="src"
                        title="title"
                        className={classes.image}
                      />
                    </ImageInput>
                  </div>
                </div>
              )}

              <Button
                type="submit"
                disabled={submitting}
                className={classes.button}
              >
                <SaveIcon className={classes.saveIcon} />
                update
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}

export const UpdateProfile = connect(
  state => {
    const {
      profile,
      user: { textingEnabled },
    } = state;
    return { profile, textingEnabled };
  },
  { updateProfile: updateProfileAction },
)(_UpdateProfile);
