import React, { useState, useEffect } from "react";
import {
  Datagrid,
  TextField,
  Create,
  Filter,
  TextInput,
  SimpleForm,
  useListController,
  ListView,
  Pagination,
  TopToolbar,
  Notification,
  required,
  minLength,
  ExportButton,
  downloadCSV,
  BooleanField,
  useNotify,
  SelectInput,
} from "react-admin";
import { useSelector } from "react-redux";
import jsonExport from "jsonexport/dist";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton } from "@material-ui/core";
import { styles } from "./ProductGroup.styles";
import { getRoleBooleans } from "../../customState";
import { APIClient, generateAuthHeader } from "../../lib";
const validateName = [required(), minLength(2)];

const GroupFilter = ({ supplierList, isSuperAdmin, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput label="Group Name" source="q" alwaysOn />
      {isSuperAdmin && (
        <SelectInput
          source="supplier_id"
          label="Supplier"
          choices={supplierList}
          optionText="supplier_name"
          translateChoice={false}
          alwaysOn
        />
      )}
    </Filter>
  );
};

export function ProductGroupList() {
  const classes = styles();
  const [asideOpen, setAsideOpen] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const { isSuperAdmin } = useSelector(state => getRoleBooleans(state));
  const companySupplierId = useSelector(state => state.user.supplier_id);
  const notify = useNotify();

  useEffect(() => {
    if (isSuperAdmin) fetchSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin]);

  const fetchSuppliers = async () => {
    const res = await APIClient.get(`/product_suppliers?_end=1000`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setSupplierList(data);
  };

  const controllerProps = useListController({
    sort: { field: "group_name", order: "ASC" },
    basePath: "/supplier_product_groups",
    resource: "supplier_product_groups",
    perPage: 25,
  });

  const onSuccess = () => {
    controllerProps.refetch();
    notify(`Group added successfully`);
    setAsideOpen(false);
  };

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        className={classes.addGroup}
        onClick={() => setAsideOpen(true)}
      >
        add group
      </Button>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues }}
        exporter={groupExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title="Supplier Product Groups"
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        empty={false}
        actions={<Actions />}
        filters={
          <GroupFilter
            supplierList={supplierList}
            isSuperAdmin={isSuperAdmin}
          />
        }
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick={"show"}>
          <TextField source="id" label="Group ID" />
          {isSuperAdmin && (
            <TextField source="supplier_id" label="Supplier ID" />
          )}
          <TextField source="group_name" label="Group Name" />
          <BooleanField source="active" />
        </Datagrid>
      </ListView>

      <Drawer open={asideOpen} anchor="right">
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            <div>Add a Group</div>
            <IconButton onClick={() => setAsideOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <Create
            resource="supplier_product_groups"
            basePath="/supplier_product_groups"
            title=" "
            onSuccess={onSuccess}
          >
            <SimpleForm
              initialValues={{
                supplier_id: isSuperAdmin ? null : companySupplierId,
              }}
            >
              {isSuperAdmin && (
                <SelectInput
                  source="supplier_id"
                  label="Supplier"
                  choices={supplierList}
                  optionText="supplier_name"
                  translateChoice={false}
                />
              )}
              <TextInput source="group_name" validate={validateName} />
            </SimpleForm>
          </Create>
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}

function groupExporter(groups) {
  const formattedGroups = groups.map(group => {
    const { id, group_name, active } = group;
    const exportObj = {
      ID: id,
      "Group Name": group_name,
      Active: active,
    };
    return exportObj;
  });
  jsonExport(formattedGroups, (err, csv) => {
    downloadCSV(csv, "Product Groups");
  });
}
