export function ShareIconSVG() {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <title>Share</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1110, -312)" fill="#248BEF">
          <g transform="translate(1077, 288)">
            <g transform="translate(25, 16)">
              <g transform="translate(8.64, 8.64)">
                <g transform="translate(2.56, 5.12)">
                  <path d="M20.4800001,8.96000004 C20.4800001,9.81760004 20.4800001,10.6624 20.4800001,11.52 C22.0160001,11.52 24.0128001,11.52 25.6000001,11.52 C25.6000001,10.6624 25.6000001,9.81760004 25.6000001,8.96000004 C24.0128001,8.96000004 22.0160001,8.96000004 20.4800001,8.96000004 Z M17.9200001,17.4208001 C19.1488001,18.3296001 20.7488001,19.5328001 22.0160001,20.4800001 C22.5280001,19.8016001 23.0400001,19.1104001 23.5520001,18.4320001 C22.2848001,17.4848001 20.6848001,16.2816001 19.4560001,15.3600001 C18.9440001,16.0512001 18.4320001,16.7424001 17.9200001,17.4208001 Z M23.5520001,2.04800001 C23.0400001,1.36960001 22.5280001,0.678400003 22.0160001,0 C20.7488001,0.947200004 19.1488001,2.15040001 17.9200001,3.07200001 C18.4320001,3.75040002 18.9440001,4.44160002 19.4560001,5.12000002 C20.6848001,4.19840002 22.2848001,3.00800001 23.5520001,2.04800001 Z M2.56000001,6.40000003 C1.152,6.40000003 0,7.55200003 0,8.96000004 L0,11.52 C0,12.9280001 1.152,14.0800001 2.56000001,14.0800001 L3.84000002,14.0800001 L3.84000002,19.2000001 L6.40000003,19.2000001 L6.40000003,14.0800001 L7.68000003,14.0800001 L14.0800001,17.9200001 L14.0800001,2.56000001 L7.68000003,6.40000003 L2.56000001,6.40000003 Z M8.99840004,8.58880004 L11.52,7.07840003 L11.52,13.4016001 L8.99840004,11.8912 L8.38400003,11.52 L2.56000001,11.52 L2.56000001,8.96000004 L8.38400003,8.96000004 L8.99840004,8.58880004 Z M17.2800001,10.24 C17.2800001,8.53760004 16.5376001,7.00162563 15.3600001,5.95202562 L15.3600001,14.5152001 C16.5376001,13.4784001 17.2800001,11.9424 17.2800001,10.24 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
