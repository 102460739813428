import { TextField } from "@material-ui/core";
import { FieldRenderProps } from "react-final-form";

export function TextFieldWrapper(props: FieldRenderProps<any, any>) {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    helperText,
    ...rest
  } = props;

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <TextField
      {...rest}
      name={name}
      helperText={
        showError
          ? meta.error || meta.submitError
          : helperText
          ? helperText
          : undefined
      }
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      value={value}
    />
  );
}
