import { makeStyles, FormControlLabel, Switch } from "@material-ui/core";
import { TextField, Checkbox } from "final-form-material-ui";
import { DonationDetailModes, GenericObject } from "../../../../types";
import { Field, useForm } from "react-final-form";
import { sharedStyles } from "./sharedStyles";
import { requiredField } from "../../../../lib";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { getIsProductCampaign } from "../../../../state";
import { getIsGivvr } from "../../../../../customState";
import { NullableField } from "../../../../components";

type Props = {
  donation: GenericObject;
  mode: DonationDetailModes;
  formValues: any;
};

export function DonorInfoCard({ donation, mode, formValues }: Props) {
  const classes = styles();
  const form = useForm();
  const sharedClasses = sharedStyles();
  const isProduct = useSelector(getIsProductCampaign);
  const isGivvr = useSelector(getIsGivvr);
  const {
    title,
    first_name,
    last_name,
    anonymous,
    display_name,
    address,
    apt,
    city,
    state,
    zip,
    email,
    phone,
    from_brochure,
    shippingAddress,
    ship_to_school,
    ship_to_name,
  } = donation;
  const SA = shippingAddress as null | GenericObject;

  useEffect(() => {
    const isSame = shippingAddress ? false : true;
    form.change("shippingSameAsBilling", isSame);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingAddress]);

  if (mode === DonationDetailModes.view) {
    return (
      <div className={sharedClasses.card}>
        <div className={sharedClasses.subheader}>Donor Info</div>
        <h3 className={classes.name}>
          {title && `${title} `}
          {`${first_name} ${last_name}`}
        </h3>
        {(display_name || anonymous) && (
          <div className={classes.displayWrapper}>
            <div className={classes.displayName}>Display</div>
            <div>{anonymous ? "Anonymous" : display_name}</div>
          </div>
        )}
        <div className={classes.nameSpacer} />
        <div className={classes.address}>{address}</div>
        {apt && <div className={classes.address}>{apt}</div>}
        <div className={classes.address}>
          {city ? `${city}, ` : ""}
          {`${state} ${zip}`}
        </div>
        <div className={classes.emailPhone}>
          {!from_brochure && <div>{email}</div>}
          {phone && <div className={classes.phone}>{phone}</div>}
        </div>
        {SA && (
          <div className={classes.shipTo}>
            <h3 className={classes.h3}>Ship to</h3>
            {ship_to_name && (
              <div className={classes.address}>{ship_to_name}</div>
            )}
            <div className={classes.address}>{SA.address}</div>
            {SA.apt && <div className={classes.address}>{SA.apt}</div>}
            <div className={classes.address}>
              {SA.city}, {SA.state} {SA.zip}
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Donor Info</div>
      {isGivvr && (
        <NullableField
          component={TextField}
          name="title"
          label="Title"
          className={classes.input}
        />
      )}
      <div className={classes.inputGroup}>
        <Field
          component={TextField}
          name="first_name"
          label="First name"
          validate={requiredField}
          className={classes.halfInput}
        />
        <Field
          component={TextField}
          name="last_name"
          label="Last name"
          validate={requiredField}
          className={classes.halfInput}
        />
      </div>
      <NullableField
        component={TextField}
        name="display_name"
        label="Display name"
        className={classes.input}
      />
      <div className={classes.inputGroup}>
        <Field
          component={TextField}
          name="address"
          label="Address"
          validate={requiredField}
          className={classes.input}
        />
        <NullableField
          component={TextField}
          name="apt"
          label="Apt"
          className={classes.apt}
        />
      </div>
      <Field
        component={TextField}
        name="city"
        label="City"
        validate={requiredField}
        className={classes.input}
      />
      <div className={classes.inputGroup}>
        <Field
          component={TextField}
          name="state"
          label="State"
          validate={requiredField}
          className={classes.halfInput}
        />
        <Field
          component={TextField}
          name="zip"
          label="Zip"
          validate={requiredField}
          className={classes.halfInput}
        />
      </div>
      <div className={classes.inputGroup}>
        <Field
          component={TextField}
          name="email"
          label="Email"
          validate={requiredField}
          className={classes.halfInput}
        />
        <Field
          component={TextField}
          name="phone"
          label="Phone"
          validate={requiredField}
          className={classes.halfInput}
        />
      </div>

      {isProduct && !ship_to_school && (
        <Fragment>
          <FormControlLabel
            className={classes.checkboxWrapper}
            control={
              <Field
                component={Checkbox}
                name="shippingSameAsBilling"
                type="checkbox"
              />
            }
            label="Shipping and billing address are the same"
          />
        </Fragment>
      )}

      {isProduct && !ship_to_school && !formValues.shippingSameAsBilling && (
        <Fragment>
          <h3 className={classes.h3}>Ship to</h3>
          <div className={classes.space6} />
          <NullableField
            component={TextField}
            name="ship_to_name"
            label="Ship to name"
            className={classes.input}
          />
          <div className={classes.inputGroup}>
            <Field
              component={TextField}
              name="shippingAddress.address"
              label="Shipping address"
              validate={requiredField}
              className={classes.input}
            />
            <NullableField
              component={TextField}
              name="shippingAddress.apt"
              label="Apt"
              className={classes.apt}
            />
          </div>
          <Field
            component={TextField}
            name="shippingAddress.city"
            label="Shipping city"
            validate={requiredField}
            className={classes.input}
          />
          <div className={classes.inputGroup}>
            <Field
              component={TextField}
              name="shippingAddress.state"
              label="Shipping state"
              validate={requiredField}
              className={classes.halfInput}
            />
            <Field
              component={TextField}
              name="shippingAddress.zip"
              label="Shipping zip"
              validate={requiredField}
              className={classes.halfInput}
            />
          </div>
        </Fragment>
      )}

      <Field
        name="anonymous"
        type="checkbox"
        render={({ input, meta: _rm, ...rest }) => (
          <FormControlLabel
            className={classes.switchLabel}
            control={<Switch {...rest} {...input} />}
            label="Donate anonymously"
          />
        )}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  name: {
    fontWeight: 500,
    lineHeight: "18px",
    color: theme.palette.text.secondary,
  },
  displayWrapper: {
    marginTop: 12,
    display: "flex",
  },
  displayName: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginRight: 16,
    textWrap: "nowrap",
  },
  nameSpacer: {
    height: 16,
  },
  h3: {
    fontWeight: 500,
    lineHeight: "18px",
    marginBottom: 16,
    color: theme.palette.text.secondary,
  },
  address: {
    lineHeight: "22px",
  },
  emailPhone: {
    marginTop: 16,
    lineHeight: "18px",
    paddingBottom: 16,
  },
  phone: {
    marginTop: 16,
  },
  shipTo: {
    marginTop: 8,
    marginBottom: 16,
  },
  space6: {
    height: 6,
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  halfInput: {
    width: "calc(50% - 8px)",
    marginBottom: 24,
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  apt: {
    width: 80,
    marginBottom: 24,
    marginLeft: 16,
  },
  checkboxWrapper: {
    marginRight: 0,
    marginBottom: 15,
    marginTop: -9,
  },
  switchLabel: {
    color: theme.palette.text.primary,
  },
}));
