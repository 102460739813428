import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(theme => ({
  container: {
    minHeight: 300,
    margin: "6px 24px 56px 24px",
  },
  warning: {
    color: "#FF0000",
    fontSize: 15,
    paddingBottom: 4,
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rep: {
    fontWeight: 600,
    fontSize: 16,
  },
  downloadContainer: {
    display: "flex",
  },
  status: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    margin: "24px 0",
    width: "fit-content",
    height: "fit-content",
    minWidth: 300,
  },
  pcoTable: {
    marginTop: 36,
    width: "fit-content",
  },
  input: {
    width: 130,
  },
  wideInput: {
    minWidth: 300,
    width: "100%",
  },
  grandTtl: {
    fontWeight: 600,
    fontSize: 16,
  },
  actionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "38px",
  },
  actionButton: {
    backgroundColor: "#FF0000",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FF0000",
      color: "#FFFFFF",
      opacity: 0.7,
    },
  },
  paidOn: {
    marginRight: 12,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  paymentDetailsContainer: {
    display: "flex",
  },
  leftPmtTbl: {
    marginRight: 42,
  },
  paymentDetailsWarning: {
    fontSize: 14,
    fontStyle: "italic",
    lineHeight: "16px",
    color: "#FF0000",
    marginTop: 4,
    fontWeight: 300,
  },
  adjustmentsAndFees: {
    display: "flex",
  },
  placeholder: {
    color: "rgba(0, 0, 0, 0.38)",
  },
  trackingLink: {
    color: theme.palette.primary.main,
    fontSize: 12,
    overflow: "hidden",
    maxWidth: 300,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  copyTtl: {
    width: 24,
    paddingLeft: 0,
    paddingRight: "8px !important",
  },
  actionOr: {
    margin: "0 24px",
    fontWeight: 600,
    fontSize: 20,
  },
  // MODAL STYLES
  dialog: {
    padding: "24px",
    width: "800px",
    maxWidth: "100%",
  },
  dialogHeaderSection: {
    fontSize: 16,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingBottom: "24px",
  },
  dialogHeader: {
    fontSize: 24,
    paddingBottom: 4,
  },
  closeIcon: {
    position: "absolute",
    right: 0,
  },
  modalInput: { margin: "12px 0" },
}));
