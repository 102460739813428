import { useEffect, useState } from "react";
import queryString from "query-string";
import {
  makeStyles,
  Button,
  Theme,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import { useSelector } from "react-redux";
import { APIAuthClient, changeVolunteerToken, requiredField } from "../lib";
import {
  APIRes,
  GenericObject,
  TeamsAutocomplete,
  ToastTypes,
  paths,
} from "../types";
import {
  getCampaignId,
  setToast,
  setTopBarTitleComponent,
  teamActions,
} from "../state";
import { useIsMobile } from "../hooks/ui";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch } from "../types/state";

export function AddAssociatedVolunteerAccount() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [campaignData, setCampaignData] = useState<GenericObject>({});
  const [teams, setTeams] = useState<TeamsAutocomplete>([]);
  const { campaign_id: _campaign_id } = queryString.parse(location.search);
  const campaign_id = _campaign_id ? Number(_campaign_id) : undefined;
  const email = useSelector((state: any) => state.user.email);
  const stateCampaignId = useSelector(getCampaignId);
  const inGlobalMode = !stateCampaignId;
  const classes = styles({ inGlobalMode });
  const {
    text_drip_campaign,
    class_options,
    company_frontend_url,
  } = campaignData;

  useEffect(() => {
    const component = (
      <h1 className={classes.title}>Add associated user account</h1>
    );
    dispatch(setTopBarTitleComponent(component));
    return () => {
      dispatch(setTopBarTitleComponent(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!campaign_id) {
      dispatch(
        setToast(
          "There was no campaign selected for this request",
          ToastTypes.warn,
        ),
      );
      history.replace(paths.HOME);
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchData = async () => {
    const url = `/volunteers/get_create_associated_volunteer_configs?campaign_id=${campaign_id}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setCampaignData(data);
    if (data.hasTeams) {
      const teamRes = await dispatch(
        teamActions.fetchCampaignTeamAutocomplete(campaign_id),
      );
      if (teamRes) setTeams(teamRes);
    }
  };

  const onSubmit = async (values: GenericObject) => {
    const { email: _rm, ...rest } = values;
    const url = "/volunteers/create_associated_volunteer";
    const post = { campaign_id, ...rest };
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    const { token } = data;
    changeVolunteerToken(token);
    localStorage.setItem("selectedCampaignId", `${campaign_id}`);
    window.location.replace(paths.HOME);
  };

  return (
    <div className={classes.container}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {(isMobile || inGlobalMode) && (
                <h1 className={classes.title}>Add associated user account</h1>
              )}

              <div className={classes.paragraph}>
                Associated users share the same email address. All associated
                user accounts can be accessed by logging in to any of the linked
                user accounts.
              </div>

              <div className={classes.inputRow}>
                <Field
                  name="first_name"
                  placeholder="First name"
                  component={TextField}
                  validate={requiredField}
                  className={classes.input}
                />
                <Field
                  name="last_name"
                  placeholder="Last name"
                  component={TextField}
                  validate={requiredField}
                  className={classes.input}
                />
              </div>

              <div className={classes.inputRow}>
                <Field
                  name="email"
                  component={TextField}
                  disabled
                  className={classes.input}
                />
                <Field
                  name="phone"
                  placeholder="Phone"
                  component={TextField}
                  validate={requiredField}
                  className={classes.input}
                />
              </div>

              {(teams.length > 0 ||
                (Array.isArray(class_options) && class_options.length > 0)) && (
                <div className={classes.inputRow}>
                  {Array.isArray(class_options) && class_options.length > 0 && (
                    <Field
                      select
                      name="class_name"
                      component={TextField}
                      validate={requiredField}
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (value: string) =>
                          value !== "" ? value : "Select a Class",
                      }}
                      className={classes.input}
                    >
                      {class_options.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  )}
                  {teams.length > 0 && (
                    <Field
                      name="team_id"
                      component={TextField}
                      select
                      className={classes.input}
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (value: string) =>
                          value !== "" ? value : "Select a Team",
                      }}
                    >
                      {teams.map(
                        ({ id, name }: { id: number; name: string }, i) => (
                          <MenuItem key={i} value={id}>
                            {name}
                          </MenuItem>
                        ),
                      )}
                    </Field>
                  )}
                </div>
              )}

              {text_drip_campaign && (
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      name="agreed_to_receive_texts"
                      type="checkbox"
                      className={classes.checkbox}
                      classes={{
                        checked: classes.checked,
                      }}
                    />
                  }
                  className={classes.formControl}
                  label={
                    <div>
                      <div>
                        I agree to receive account related text messages
                      </div>
                      <div className={classes.textLegal}>
                        Message and data rates may apply. Reply STOP to opt out.
                        Reply HELP for assistance. Frequency varies. Subject to{" "}
                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                        <a
                          href={`${company_frontend_url || ""}/privacy`}
                          target="_blank"
                          className={classes.privacyLink}
                        >
                          Privacy Policy
                        </a>
                        .
                      </div>
                    </div>
                  }
                />
              )}

              {!isMobile && <div className={classes.divider} />}
              <div className={classes.buttons}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={submitting}
                  className={classes.save}
                >
                  Save and go to account
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  disabled={submitting}
                  to={paths.HOME}
                  component={Link}
                >
                  CANCEL AND RETURN TO HOME
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

type StyleProps = {
  inGlobalMode: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    paddingLeft: 32,
    paddingBottom: 24,
    backgroundColor: "#FFFFFF",
    alignItems: ({ inGlobalMode }) => (inGlobalMode ? "center" : "flex-start"),
    paddingTop: ({ inGlobalMode }) => (inGlobalMode ? 40 : 0),
    [theme.breakpoints.down("sm")]: {
      padding: () => "0 16px 24px 16px",
    },
  },
  form: {
    width: 552,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: 23,
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0.15,
      lineHeight: "32px",
    },
  },
  paragraph: {
    marginTop: 24,
    marginBottom: 24,
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  inputRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    width: 264,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  formControl: {
    alignItems: "flex-start",
    marginRight: 0,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  checkbox: {
    marginTop: -6,
  },
  checked: {
    color: `${theme.palette.secondary2.main} !important`,
  },
  textLegal: {
    fontSize: 12,
    letterSpacing: 0.11,
    lineHeight: "18px",
    paddingTop: 4,
  },
  privacyLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },
  divider: {
    height: 22,
    width: "100%",
    marginBottom: 16,
    borderBottom: "1px solid #DBDEEE",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  save: {
    width: 200,
    borderRadius: 18,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      marginBottom: 8,
      marginTop: 8,
      marginRight: 0,
    },
  },
}));
