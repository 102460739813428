// cSpell:ignore Textsms
import { useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import {
  makeStyles,
  Collapse,
  Checkbox,
  IconButton,
  Button,
} from "@material-ui/core";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import { RootState } from "../../../types/state";
import { Contact, GenericObject, UpdateEmailRecipients } from "../../../types";
import { EditContact } from "../../../components/contacts/EditContact";
import { DeleteContact } from "../../../components/contacts/DeleteContact";
import { affiliateLink, textMessageLink } from "../../../lib";
import { AFFILIATES } from "../../../../lib";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";

type ContactRowsProps = {
  setShowSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  updateEmailRecipients: UpdateEmailRecipients;
  emailRecipients: GenericObject;
  displayContacts: Contact[];
};
export function ContactRows({
  setShowSendEmail,
  updateEmailRecipients,
  emailRecipients,
  displayContacts,
}: ContactRowsProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <div className={classes.rowsWrapper}>
      <div className={classes.rows}>
        {isDesktop && (
          <div className={classes.rowContainer}>
            <div className={classNames(classes.row, classes.headerRow)}>
              <div />
              <div />
              <div>Name</div>
              <div>Phone</div>
              <div>Email</div>
              <div>Last email sent</div>
              <div />
            </div>
          </div>
        )}

        {displayContacts.map((contact, index) => {
          const lastRow = index === displayContacts.length - 1;
          return (
            <Row
              key={contact.id}
              contact={contact}
              lastRow={lastRow}
              setShowSendEmail={setShowSendEmail}
              updateEmailRecipients={updateEmailRecipients}
              emailRecipients={emailRecipients}
            />
          );
        })}
      </div>
    </div>
  );
}

type RowProps = {
  contact: Contact;
  lastRow: boolean;
  setShowSendEmail: React.Dispatch<React.SetStateAction<boolean>>;
  updateEmailRecipients: UpdateEmailRecipients;
  emailRecipients: GenericObject;
};
function Row({
  contact,
  lastRow,
  setShowSendEmail,
  updateEmailRecipients,
  emailRecipients,
}: RowProps) {
  const classes = styles();
  const [expanded, setExpanded] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const { orgName, webpageLink } = useSelector(
    (state: RootState) => state.campaign,
  );
  const link = affiliateLink(webpageLink, AFFILIATES.VOLUNTEER_TO_CONTACT_TEXT);
  const {
    id,
    name,
    phone,
    email,
    email_error_status,
    lastEmail,
    emailsSentCount,
    manualEmails,
    dripEmails,
  } = contact;
  const hasManuals = Array.isArray(manualEmails) && manualEmails.length > 0;
  const hasDrips = Array.isArray(dripEmails) && dripEmails.length > 0;
  const disableEmail = Boolean(!email || email_error_status);

  const toggleExpand = () => setExpanded(prev => !prev);
  const onCloseEdit = () => setShowEdit(false);
  const onCloseDelete = () => setShowDelete(false);
  const onSendSingleEmail = () => {
    updateEmailRecipients(id, email, true, true);
    setShowSendEmail(true);
  };

  return (
    <div className={classNames(!lastRow && classes.rowContainer)}>
      <div className={classes.row}>
        <div>
          <IconButton onClick={toggleExpand} size="small">
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        {isDesktop && (
          <div>
            <Checkbox
              checked={Boolean(emailRecipients[id])}
              classes={{ checked: classes.checked }}
              onChange={({ target }) =>
                updateEmailRecipients(id, email, target.checked)
              }
              disabled={disableEmail}
            />
          </div>
        )}
        <div className={classes.name}>{name}</div>
        {isDesktop && <div>{phone}</div>}
        {isDesktop && <div>{email}</div>}
        {isDesktop && (
          <div>
            {lastEmail && (
              <div
                className={classNames(
                  classes.dateChip,
                  classes[lastEmail.status],
                )}
              >
                {format(new Date(lastEmail.created_at), "M/d/y")}
              </div>
            )}
          </div>
        )}
        {isDesktop && (
          <div className={classes.rightActions}>
            <Button
              size="small"
              variant="text"
              color="primary"
              startIcon={<MailOutlineIcon />}
              onClick={onSendSingleEmail}
              disabled={disableEmail}
            >
              COMPOSE EMAIL
            </Button>
            <Button
              size="small"
              variant="text"
              color="primary"
              startIcon={<TextsmsOutlinedIcon />}
              href={textMessageLink(link, orgName, true, phone)}
              disabled={!phone}
            >
              SEND TEXT
            </Button>
            <IconButton
              size="small"
              color="primary"
              onClick={() => setShowEdit(true)}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={() => setShowDelete(true)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </div>
        )}

        {isMobile && (
          <div className={classes.rightActions}>
            <IconButton
              size="small"
              color="primary"
              href={textMessageLink(link, orgName, true, phone)}
              disabled={!phone}
            >
              <TextsmsOutlinedIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={onSendSingleEmail}
              disabled={disableEmail}
            >
              <MailOutlineIcon />
            </IconButton>
          </div>
        )}
      </div>

      <Collapse in={expanded} timeout="auto">
        <div className={classes.expand}>
          {isMobile && (
            <div className={classes.expandSection}>
              <div className={classes.expandInfoSection}>
                <div>
                  <IconButton size="small">
                    <MailOutlineIcon />
                  </IconButton>
                </div>
                <div className={classes.expandInfo}>
                  <div>{email ? email : <span>&#8212;</span>}</div>
                  {email && (
                    <div className={classes.sentCount}>
                      {emailsSentCount} emails sent
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className={classes.expandSection}>
              <div className={classes.expandInfoSection}>
                <div>
                  <IconButton size="small">
                    <PhoneOutlinedIcon />
                  </IconButton>
                </div>
                <div className={classes.expandInfo}>
                  {phone ? phone : <span>&#8212;</span>}
                </div>
              </div>
            </div>
          )}
          <div className={classes.expandSection}>
            <div className={classes.expandLabel}>User-sent emails</div>
            {!hasManuals && <div className={classes.none}>None</div>}
            {hasManuals && (
              <div className={classes.dateChips}>
                {manualEmails.map(({ created_at, status }, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(classes.dateChip, classes[status])}
                    >
                      {format(new Date(created_at), "M/d/y")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div
            className={classNames(
              classes.expandSection,
              isDesktop && classes.noBorder,
            )}
          >
            <div className={classes.expandLabel}>Automated emails</div>
            {!hasDrips && <div className={classes.none}>None</div>}
            {hasDrips && (
              <div className={classes.dateChips}>
                {dripEmails.map(({ created_at, status }, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(classes.dateChip, classes[status])}
                    >
                      {format(new Date(created_at), "M/d/y")}
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {isMobile && (
            <div
              className={classNames(classes.expandSection, classes.noBorder)}
            >
              <div>
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  startIcon={<EditOutlinedIcon />}
                  onClick={() => setShowEdit(true)}
                >
                  EDIT
                </Button>
                <Button
                  size="small"
                  variant="text"
                  color="primary"
                  startIcon={<DeleteOutlineIcon />}
                  className={classes.expandEditButton}
                  onClick={() => setShowDelete(true)}
                >
                  DELETE
                </Button>
              </div>
            </div>
          )}
        </div>
      </Collapse>
      <EditContact isOpen={showEdit} onClose={onCloseEdit} contact={contact} />
      <DeleteContact
        isOpen={showDelete}
        onClose={onCloseDelete}
        contact={contact}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  rowsWrapper: {
    width: "100%",
    height: "fit-content",
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    overflowY: "hidden",
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  rows: {
    minWidth: 1150,
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      maxWidth: "100%",
    },
  },
  rowContainer: {
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "40px 60px .5fr .35fr .6fr .35fr 352px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 20,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 58,
      gridTemplateColumns: "40px 1fr 90px",
    },
  },
  headerRow: {
    minHeight: 56,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  checked: {
    color: `${theme.palette.secondary2.main} !important`,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "16px",
    overflowWrap: "anywhere",
  },
  rightActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  expand: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#EAEBF3",
    padding: "8px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  expandSection: {
    borderBottom: "1px solid #C9CDDE",
    display: "flex",
    maxWidth: "100%",
    alignItems: "center",
    fontSize: 14,
    letterSpacing: 0.15,
    padding: "6px 24px",
    minHeight: 52,
    [theme.breakpoints.down("sm")]: {
      minHeight: 62,
      borderBottom: "1px solid #DBDEEE",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      padding: "6px 15px",
    },
  },
  expandLabel: {
    width: 188,
    minWidth: 188,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      minWidth: "unset",
      padding: "4px 0 12px 0",
      fontWeight: 500,
      letterSpacing: 0.3,
    },
  },
  dateChips: {
    display: "flex",
    flexWrap: "wrap",
  },
  dateChip: {
    height: 32,
    width: 87,
    borderRadius: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    letterSpacing: 0.15,
    margin: "4px 8px 4px 0",
  },
  // IMPORTANT: don't change this className because it's based on the status from the API call
  pending: {
    color: "#683800",
    backgroundColor: "#FFF8E9",
  },
  // IMPORTANT: don't change this className because it's based on the status from the API call
  failed: {
    color: "#5E0808",
    backgroundColor: "#FDEAEA",
  },
  // IMPORTANT: don't change this className because it's based on the status from the API call
  delivered: {
    color: "#083D15",
    backgroundColor: "#EFFCF0",
  },
  noBorder: {
    borderBottom: "none",
  },
  expandEditButton: {
    marginLeft: 20,
    color: "#ED2A2A",
  },
  expandInfoSection: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
  },
  expandInfo: {
    marginLeft: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "15px",
    overflowWrap: "anywhere",
  },
  sentCount: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.secondary2,
    paddingTop: 4,
  },
  none: {
    fontSize: 14,
    letterSpacing: 0.15,
    color: theme.palette.text.secondary2,
  },
}));
