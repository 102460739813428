import React, { useState } from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { APIUtilClient, downloadPdf, generateAuthHeader } from "../../../lib";

export function PullSheetExport(props) {
  const {
    campaign_id,
    selectedIds,
    isShipToSchool = false,
    currentFilters = {},
  } = props;
  const { warehouse_id, ...filters } = currentFilters;
  const classes = styles();
  const [loading, setLoading] = useState(false);

  const exportPullSheet = async () => {
    setLoading(true);
    let post = { isShipToSchool };
    if (campaign_id) post.campaign_id = Number(campaign_id);
    if (warehouse_id) post.warehouse_id = warehouse_id;
    if (selectedIds) {
      post.selectedIds = selectedIds;
    } else {
      post = { ...post, ...filters };
    }
    const res = await APIUtilClient.post(
      "/order_fulfillment/pdf/pull_sheet",
      post,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return downloadPdf(res, "pull-sheet.pdf");
  };

  return (
    <Button
      size="small"
      startIcon={<DownloadIcon />}
      color="primary"
      onClick={exportPullSheet}
      disabled={loading}
      className={classes.button}
    >
      {`unfulfilled ${selectedIds ? "(selected)" : ""} pull sheet`}
      {loading && (
        <CircularProgress
          color="primary"
          className={classes.spinner}
          size={24}
        />
      )}
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  button: { whiteSpace: "nowrap" },
  spinner: { position: "absolute" },
}));
