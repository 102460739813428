import { useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useIsMobile, useScrollToTop } from "../../../hooks/ui";
import { ListContainer, ListNoResults, MultiSearch } from "../../../components";
import { useList } from "../../../hooks/list";
import { GenericObject, discountCardOfferSearchFields } from "../../../types";
import { OfferRows } from "./OfferRows";
import { AddOffer } from "./AddOffer";
import { NoOffersSVG } from "../../../assets";

export function Offers({ cardRecord }: { cardRecord: GenericObject }) {
  const { id: discount_card_id } = cardRecord;
  const classes = styles();

  const [showAdd, setShowAdd] = useState(false);
  const isMobile = useIsMobile();
  useScrollToTop();

  const listProps = useList<any>({
    baseUrl: `/discount_cards/${discount_card_id}/offers_list`,
    isReady: true,
    initialState: { sort: "offer_name", order: "asc" },
  });
  const { listIsFiltered, total, noResults, refreshList, showRows } = listProps;

  return (
    <div className={classes.container}>
      <ListContainer listProps={listProps} topOffset={210}>
        {isMobile && (
          <div className={classes.mobileTop}>
            <div className={classes.count}>
              {total} Offer
              {total !== 1 ? "s" : ""}
            </div>
            <IconButton onClick={() => setShowAdd(true)}>
              <AddIcon />
            </IconButton>
          </div>
        )}

        <div className={classes.searchRow}>
          <MultiSearch
            listProps={listProps}
            fields={discountCardOfferSearchFields}
          />
          {!isMobile && (
            <Button
              color="primary"
              className={classes.add}
              startIcon={<AddIcon />}
              onClick={() => setShowAdd(true)}
            >
              Offer
            </Button>
          )}
        </div>

        {noResults && (
          <ListNoResults
            SVG={NoOffersSVG}
            listIsFiltered={listIsFiltered}
            label="offers"
            buttonLabel="Add offer"
            buttonFunc={() => setShowAdd(true)}
          />
        )}

        {showRows && <OfferRows listProps={listProps} />}

        {showAdd && (
          <AddOffer
            onClose={() => setShowAdd(false)}
            refreshList={refreshList}
            cardRecord={cardRecord}
          />
        )}
      </ListContainer>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    backgroundColor: "#F7F7F7",
    paddingTop: 24,
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
    },
  },
  mobileTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 16,
  },
  add: {
    width: 100,
  },
  count: {
    fontSize: 14,
    letterSpacing: 0.47,
    color: theme.palette.text.secondary,
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
  },
}));
