import React, { useState, Fragment } from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useNotify } from "react-admin";
import DownloadIcon from "@material-ui/icons/GetApp";
import { APIUtilClient, generateAuthHeader } from "../../../lib";
import { ReportDownloader } from "../../../components";

export function ShipToHomePickSlipsExport(props) {
  const { campaign_id, selectedIds, currentFilters = {} } = props;
  const classes = styles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [downloadId, setDownloadId] = useState(null);

  const exportPickSlips = async () => {
    setLoading(true);
    let post = { isShipToSchool: false };
    if (campaign_id) post.campaign_id = Number(campaign_id);
    if (selectedIds) {
      post.selectedIds = selectedIds;
    } else {
      post = { ...post, ...currentFilters };
    }
    const res = await APIUtilClient.post(
      "/order_fulfillment/pdf/home_pick_slips",
      post,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) {
      notify(errorMessage, "warning");
      setLoading(false);
      return;
    }
    setDownloadId(data.downloadId);
  };

  return (
    <Fragment>
      <Button
        size="small"
        startIcon={<DownloadIcon />}
        color="primary"
        onClick={exportPickSlips}
        disabled={loading}
        className={classes.button}
      >
        {`unfulfilled ${selectedIds ? "(selected)" : ""} pick slips`}
        {loading && (
          <CircularProgress
            color="primary"
            className={classes.spinner}
            size={24}
          />
        )}
      </Button>
      <ReportDownloader downloadId={downloadId} setLoading={setLoading} />
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  button: { whiteSpace: "nowrap" },
  spinner: { position: "absolute" },
}));
