import React, { Fragment, useState, useEffect } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  TableHead,
  Collapse,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

export function Product({ product = {}, expandAll }) {
  const {
    groups = {},
    sku,
    product_name,
    sold,
    allocated,
    completedSold,
    fulfilled,
    items_per_case,
    onHand,
    onOrder,
    projectedItems,
    needed,
    stock_qty,
  } = product;
  const classes = styles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  return (
    <Fragment>
      <TableRow className={classes.parentRow}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{product_name}</TableCell>
        <TableCell>{sku}</TableCell>
        <TableCell>{sold}</TableCell>
        <TableCell>{completedSold}</TableCell>
        <TableCell>{fulfilled}</TableCell>
        <TableCell>{allocated}</TableCell>
        <TableCell>{stock_qty}</TableCell>
        <TableCell>{onHand}</TableCell>
        <TableCell>{onOrder}</TableCell>
        <TableCell>{projectedItems}</TableCell>
        <TableCell>{needed}</TableCell>
        <TableCell>{items_per_case}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={13} className={classes.collapseCell}>
          <Collapse in={open} timeout="auto">
            <Table size="small" className={classes.expandTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Product Group</TableCell>
                  <TableCell>Ttl Sold</TableCell>
                  <TableCell>Completed Sold</TableCell>
                  <TableCell>Completed Percent</TableCell>
                  <TableCell>Group Projected Items</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(groups).map(groupId => {
                  const {
                    groupName,
                    sold,
                    completedSold,
                    completedPercent,
                    projectedItems,
                  } = groups[groupId];
                  return (
                    <TableRow key={groupId}>
                      <TableCell>{groupName}</TableCell>
                      <TableCell>{sold}</TableCell>
                      <TableCell>{completedSold}</TableCell>
                      <TableCell>{completedPercent}%</TableCell>
                      <TableCell>{projectedItems}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const styles = makeStyles(
  _theme => ({
    parentRow: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    collapseCell: {
      paddingBottom: 0,
      paddingTop: 0,
      height: "unset",
    },
    expandTable: {
      marginBottom: 4,
      backgroundColor: "#f7f7f2",
    },
  }),
  { classNamePrefix: "projectionProduct" },
);
