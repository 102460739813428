// cSpell:ignore appversion, appsource
import jsonServerProvider from "ra-data-json-server";
import { fetchUtils } from "react-admin";
import { getAuthToken } from "./tokenUtils";
const baseUrl = process.env.REACT_APP_API_BASE_URL;

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const activeToken = getAuthToken();
  options.headers.set("Authorization", `Bearer ${activeToken}`);
  options.headers.set("appversion", process.env.REACT_APP_VERSION);
  options.headers.set("appsource", "RADataProvider");

  return fetchUtils.fetchJson(url, options);
};

const provider = jsonServerProvider(baseUrl, httpClient);

const convertFileToBase64 = (file, key, returnPlainObj) => {
  const title = file.title;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () =>
      resolve(
        returnPlainObj
          ? { title, base64: reader.result }
          : { [key]: { title, base64: reader.result } },
      );
    reader.onerror = reject;
  });
};

const convertSponsorLogos = async sponsors => {
  const convertedSponsors = sponsors.map(async s => {
    if (!s) return {};
    const { sponsor_logoBase64, ...rest } = s;
    if (!sponsor_logoBase64) return Promise.resolve(rest);
    const converted = await convertFileToBase64(
      sponsor_logoBase64,
      "sponsor_logoBase64",
    );
    rest.sponsor_logoBase64 = converted.sponsor_logoBase64;
    return rest;
  });
  const cs = await Promise.all(convertedSponsors);
  return { sponsors: cs };
};

const transformImages = async params => {
  const data = params.data || {};
  const imageKeys = [];

  Object.keys(data).forEach(k => {
    if (k.includes("Base64") && data[k] && data[k].rawFile instanceof File) {
      imageKeys.push(k);
    }
  });

  if (imageKeys.length || data.sponsors) {
    const sponsorsP = !data.sponsors
      ? Promise.resolve(null)
      : convertSponsorLogos(data.sponsors);
    const transformedData = await Promise.all([
      ...imageKeys.map(key => convertFileToBase64(params.data[key], key)),
      sponsorsP,
    ]);
    return Object.assign({}, ...transformedData);
  }
  return Promise.resolve({});
};

const dataProvider = {
  ...provider,
  getOne: (resource, params) => {
    let formattedId = params.id;
    const campaignId = localStorage.getItem("selectedCampaignId");
    if ((resource === "volunteers" || resource === "teams") && campaignId) {
      formattedId = `${formattedId}?campaign_id=${campaignId}`;
    }

    return provider.getOne(resource, { id: formattedId });
  },
  delete: (resource, params) => {
    // tack on the campaign_id
    if (resource === "volunteers") {
      const { id, previousData } = params;
      const formattedId = `${id}?campaign_id=${localStorage.getItem(
        "selectedCampaignId",
      )}`;
      return provider.delete(resource, { id: formattedId, previousData });
    }
    return provider.delete(resource, params);
  },
  update: (resource, params) => {
    let formattedId = params.id;
    if (
      resource === "volunteers" ||
      resource === "teams" ||
      resource === "contacts"
    ) {
      formattedId = `${formattedId}?campaign_id=${localStorage.getItem(
        "selectedCampaignId",
      )}`;
    }
    return transformImages(params).then(transformedData => {
      return provider.update(resource, {
        id: formattedId,
        data: {
          ...params.data,
          ...transformedData,
        },
      });
    });
  },
  create: (resource, params) => {
    // tack on the campaign_id
    if (resource === "volunteers" || resource === "teams") {
      return provider.create(resource, {
        data: {
          campaign_id: parseInt(localStorage.getItem("selectedCampaignId")),
          ...params.data,
        },
      });
    }
    return transformImages(params).then(transformedData => {
      return provider.create(resource, {
        ...params,
        data: {
          ...params.data,
          ...transformedData,
        },
      });
    });
  },
};

export { dataProvider, convertFileToBase64 };
