import { isNewAppUser } from "./helpers";
import { parseUserToken, loggedInCheck, clearStorage } from "./tokenUtils";

const authProvider = {
  login: () => Promise.resolve(),
  logout: () => {
    const { role } = parseUserToken();
    if (isNewAppUser(role)) {
      window.location.replace("/");
    }
    setTimeout(() => {
      window.location.reload();
    }, 200);
    return Promise.resolve("/login");
  },
  checkAuth: () => {
    const { role } = parseUserToken();
    if (isNewAppUser(role)) {
      window.location.replace("/");
    }
    if (loggedInCheck()) return Promise.resolve();
    return Promise.reject();
  },
  checkError: error => {
    const status = error.status;
    if (status === 401 || status === 403) {
      clearStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => {
    return Promise.resolve(parseUserToken());
  },
};
export { authProvider };
