import React from "react";
import {
  ImageInput,
  ImageField,
  useNotify,
  useMutation,
  useRefresh,
} from "react-admin";
import { Button, MenuItem, FormControlLabel } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Close";
import { Form, Field } from "react-final-form";
import {
  convertFileToBase64,
  integerOnly,
  productStructureTypes,
  productTypes,
  twoDecimalsOnly,
} from "../../lib";
import SaveIcon from "@material-ui/icons/Save";
import { useSelector } from "react-redux";
import { getIsOwner } from "../../customState";
import { TextField, Checkbox } from "final-form-material-ui";
import { styles } from "./ProductGroup.styles";

export const CustomizeGroupProduct = ({ record }) => {
  const {
    groupProductId,
    custom_price,
    digital_content_addition_id,
    digital_content_addition_code_qty,
    custom_image,
    custom_thumbnail_image,
    ship_to_school_only,
    ship_to_home_only,
    custom_ship_to_school_only,
    custom_ship_to_home_only,
    id: product_id,
    product_added,
    product_type,
    structure_type,
  } = record;

  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const isOwner = useSelector(state => getIsOwner(state));
  const showDigitalFields = isOwner && product_type !== productTypes.DIGITAL;
  const isVariant = structure_type === productStructureTypes.VARIANT;

  const [update, { loading }] = useMutation();

  const onSubmit = async values => {
    if (loading) return;
    let {
      customImageBase64,
      customThumbnailImageBase64,
      custom_price,
      custom_ship_to_school_only,
      custom_ship_to_home_only,
      digital_content_addition_id,
      digital_content_addition_code_qty,
      addToCurrentCampaigns,
    } = values;
    let data = {
      group_id: groupProductId,
      product_id,
      custom_price: custom_price ? custom_price : null,
      custom_ship_to_school_only:
        typeof custom_ship_to_school_only !== "boolean"
          ? null
          : custom_ship_to_school_only,
      custom_ship_to_home_only:
        typeof custom_ship_to_home_only !== "boolean"
          ? null
          : custom_ship_to_home_only,
      digital_content_addition_id: digital_content_addition_id
        ? Number(digital_content_addition_id)
        : null,
      digital_content_addition_code_qty: digital_content_addition_code_qty
        ? Number(digital_content_addition_code_qty)
        : null,
      addToCurrentCampaigns,
    };
    if (customImageBase64) {
      const imageB64 = await convertFileToBase64(
        customImageBase64,
        "customImageBase64",
      ).catch(console.error);
      data = { ...data, ...imageB64 };
    }
    if (customThumbnailImageBase64) {
      const tnB64 = await convertFileToBase64(
        customThumbnailImageBase64,
        "customThumbnailImageBase64",
      ).catch(console.error);
      data = { ...data, ...tnB64 };
    }

    update(
      {
        type: "update",
        resource: "supplier_product_groups/update_group_product",
        payload: { id: groupProductId, data },
      },
      {
        onFailure: error => notify(error.message, "warning"),
        onSuccess: () => {
          refresh();
          notify("Product Updated");
        },
      },
    );
  };

  if (!product_added)
    return (
      <div className={classes.notAdded}>
        You need to add this product to the group before you can customize it!
      </div>
    );
  return (
    <div>
      <div className={classes.header}>Customize Group Product</div>
      <Form
        initialValues={{
          custom_price,
          digital_content_addition_id,
          digital_content_addition_code_qty,
          custom_ship_to_school_only,
          custom_ship_to_home_only,
          addToCurrentCampaigns: false,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.topSection}>
                <Field
                  name="custom_price"
                  label="Custom Price"
                  variant="filled"
                  component={TextField}
                  validate={twoDecimalsOnly}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                />
                <div className={classes.shippingSection}>
                  <div className={classes.shippingLabel}>
                    Ship to school only
                  </div>
                  <div className={classes.prodDefault}>
                    Product Default:{" "}
                    {ship_to_school_only ? <CheckIcon /> : <XIcon />}
                  </div>
                  <ShippingSelect
                    name="custom_ship_to_school_only"
                    disable={isVariant}
                  />
                </div>

                <div className={classes.shippingSection}>
                  <div className={classes.shippingLabel}>Ship to home only</div>
                  <div className={classes.prodDefault}>
                    Product Default:{" "}
                    {ship_to_home_only ? <CheckIcon /> : <XIcon />}
                  </div>
                  <ShippingSelect
                    name="custom_ship_to_home_only"
                    disable={isVariant}
                  />
                </div>
              </div>

              <div className={classes.imagesContainer}>
                <div className={classes.imageSection}>
                  <div className={classes.currentImage}>
                    <div className={classes.label}>Current Custom Image</div>
                    {custom_image && (
                      <img
                        src={custom_image}
                        alt="logo"
                        className={classes.image}
                      />
                    )}
                  </div>
                  <ImageInput
                    source="customImageBase64"
                    label="Change Custom Image"
                    accept="image/*"
                  >
                    <ImageField
                      source="src"
                      title="title"
                      className={classes.image}
                    />
                  </ImageInput>
                </div>
                <div className={classes.divider}></div>
                <div className={classes.imageSection}>
                  <div className={classes.currentImage}>
                    <div className={classes.label}>
                      Current Custom Thumbnail
                    </div>
                    {custom_thumbnail_image && (
                      <img
                        src={custom_thumbnail_image}
                        alt="logo"
                        className={classes.image}
                      />
                    )}
                  </div>
                  <ImageInput
                    source="customThumbnailImageBase64"
                    label="Change Custom Thumbnail"
                    accept="image/*"
                  >
                    <ImageField
                      source="src"
                      title="title"
                      className={classes.image}
                    />
                  </ImageInput>
                </div>
              </div>

              {showDigitalFields && (
                <Field
                  name="digital_content_addition_id"
                  label="Digital-Content Addition Id"
                  variant="filled"
                  component={TextField}
                  validate={integerOnly}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  className={classes.customInput}
                />
              )}
              {showDigitalFields && (
                <Field
                  name="digital_content_addition_code_qty"
                  label="Digital-Content Addition Code Qty"
                  variant="filled"
                  component={TextField}
                  validate={integerOnly}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  className={classes.customInput}
                />
              )}

              {!isVariant && (
                <div className={classes.addToCampaigns}>
                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        name="addToCurrentCampaigns"
                        type="checkbox"
                      />
                    }
                    label="Add this product to current campaigns"
                  />
                </div>
              )}

              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  className={classes.saveButton}
                  startIcon={<SaveIcon className={classes.icon} />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

const ShippingSelect = ({ name, disable }) => {
  const classes = styles();

  return (
    <Field
      name={name}
      variant="standard"
      label="Customize"
      select
      SelectProps={{ displayEmpty: true }}
      InputLabelProps={{ shrink: true }}
      component={TextField}
      className={classes.shippingSelect}
      disabled={disable}
    >
      <MenuItem value="">Use Product default</MenuItem>
      <MenuItem value={true}>
        <CheckIcon />
      </MenuItem>
      <MenuItem value={false}>
        <XIcon />
      </MenuItem>
    </Field>
  );
};
