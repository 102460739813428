import { useState, useEffect, Fragment } from "react";
import { makeStyles, InputAdornment, MenuItem } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  DonationDetailModes,
  GenericObject,
  PaymentMethod,
  PaymentMethods,
} from "../../../../types";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { sharedStyles } from "./sharedStyles";
import {
  addNumbers,
  composeValidators,
  formatMoney,
  formatMoneyUnPadded,
  requiredField,
  twoDecimalsOnly,
} from "../../../../lib";
import { useSelector } from "react-redux";
import { getIsRaffleCampaign } from "../../../../state";
import { getIsVolunteer } from "../../../../../customState";
import { RootState } from "../../../../types/state";
import { NullableField } from "../../../../components";

type Props = {
  donation: GenericObject;
  mode: DonationDetailModes;
  formValues: GenericObject;
};

export function DonationCard({ donation, mode, formValues }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const isVolunteer = useSelector(getIsVolunteer);
  const [paymentMethodTxt, setPaymentMethodTxt] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const {
    ccType,
    maskedCC,
    pmt_type,
    total_amount,
    num_of_payments,
    additional_donation,
    tip,
    processing_fee,
    campaign_ticket_package,
  } = donation;
  const isRaffle = useSelector(getIsRaffleCampaign);
  const ticketPackages = useSelector(
    ({ campaign }: RootState) => campaign.ticketPackages,
  );
  const TP = campaign_ticket_package as {
    num_of_tickets: number;
    price: number;
  } | null;
  const tickets = TP ? TP.num_of_tickets : null;
  const ticketPrice = TP ? TP.price : null;

  useEffect(() => {
    const paymentType = pmt_type as PaymentMethod;
    if (paymentType === "cc") {
      setPaymentMethodTxt(`${ccType ? ccType : "Card"} ending in ${maskedCC}`);
    } else setPaymentMethodTxt(PaymentMethods[paymentType] || "");
  }, [pmt_type, ccType, maskedCC]);

  useEffect(() => {
    const {
      tip,
      processing_fee,
      additional_donation,
      ticket_package_id,
      total_amount,
    } = formValues;
    // we don't show fees to volunteers
    let total = addNumbers(
      isVolunteer ? 0 : tip,
      isVolunteer ? 0 : processing_fee,
      additional_donation,
    );
    if (isRaffle) {
      const TP = ticketPackages.find(({ id }) => ticket_package_id === id);
      if (TP) total = addNumbers(TP.price, total);
    } else {
      total = addNumbers(total_amount, total);
    }
    setGrandTotal(total);
  }, [formValues, isRaffle, ticketPackages, isVolunteer]);

  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Donation</div>
      {mode === DonationDetailModes.edit && (
        <div className={classes.warningBox}>
          <ErrorOutlineIcon className={classes.warningIcon} />
          <div>
            Updating amount will not automatically charge or credit the donor.
            You need to do it manually.
          </div>
        </div>
      )}
      {isRaffle && mode === DonationDetailModes.view && (
        <Fragment>
          <div className={classes.row}>
            <div className={classes.label}>
              {tickets &&
                ticketPrice &&
                formatTicketOption(tickets, ticketPrice)}
              {!tickets && "Tickets"}
            </div>
            <div>{tickets ? formatMoney(ticketPrice) : "None"}</div>
          </div>
          {additional_donation && (
            <div className={classes.row}>
              <div className={classes.label}>Additional donation</div>
              <div>{formatMoney(additional_donation)}</div>
            </div>
          )}
        </Fragment>
      )}

      {isRaffle && mode === DonationDetailModes.edit && (
        <Fragment>
          <NullableField
            select
            component={TextField}
            name="ticket_package_id"
            className={classes.input}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem value="">
              <span className={classes.placeholder}>
                Select a Ticket Package
              </span>
            </MenuItem>
            {ticketPackages.map((tp, i) => {
              const { id, price, num_of_tickets } = tp;
              return (
                <MenuItem key={i} value={id}>
                  {formatTicketOption(num_of_tickets, price)}
                </MenuItem>
              );
            })}
          </NullableField>
          <NullableField
            component={TextField}
            name="additional_donation"
            label="Additional donation"
            validate={twoDecimalsOnly}
            className={classes.additionalDonationInput}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Fragment>
      )}

      {!isRaffle && mode === DonationDetailModes.view && (
        <div className={classes.row}>
          <div className={classes.label}>Donation Amount</div>
          <div>{formatMoney(total_amount)}</div>
        </div>
      )}

      {!isRaffle && mode === DonationDetailModes.edit && (
        <Field
          component={TextField}
          name="total_amount"
          label="Donation amount"
          validate={composeValidators(requiredField, twoDecimalsOnly)}
          className={classes.additionalDonationInput}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      )}

      {!isVolunteer && tip && (
        <div className={classes.row}>
          <div>Tip</div>
          <div>{formatMoney(tip)}</div>
        </div>
      )}

      {!isVolunteer && processing_fee && (
        <div className={classes.row}>
          <div className={classes.label}>Processing fee</div>
          <div>{formatMoney(processing_fee)}</div>
        </div>
      )}

      <div className={classes.divider} />
      <div className={classes.row}>
        <div className={classes.total}>Total</div>
        <div className={classes.total}>{formatMoney(grandTotal)}</div>
      </div>
      <div className={classes.divider} />
      <div className={classes.row}>
        <div className={classes.secondaryLabel}>Payment method</div>
        <div className={classes.secondaryFigure}>{paymentMethodTxt}</div>
      </div>
      {(num_of_payments as number) > 0 && (
        <Fragment>
          <div className={classes.divider} />
          <div className={classes.row}>
            <div className={classes.secondaryLabel}>Payment schedule</div>
            <div className={classes.secondaryFigure}>
              Paid in {num_of_payments} monthly installments
            </div>
          </div>
        </Fragment>
      )}
      <div className={classes.spacer} />
    </div>
  );
}

function formatTicketOption(tickets: number, ticketPrice: number) {
  const price = formatMoneyUnPadded(ticketPrice);
  return `${tickets} ticket${tickets > 1 ? "s" : ""} for ${price}`;
}

const styles = makeStyles(theme => ({
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "22px",
    color: theme.palette.text.primary,
    marginBottom: 8,
  },
  label: {
    fontWeight: 500,
  },
  total: {
    fontWeight: 600,
  },
  address: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "22px",
  },
  divider: {
    width: "100%",
    height: 1,
    borderBottom: "1px solid #EAEBF3",
    margin: "16px 0",
  },
  spacer: {
    paddingBottom: 16,
  },
  secondaryLabel: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  secondaryFigure: {
    color: theme.palette.text.secondary,
  },
  warningBox: {
    backgroundColor: "#FDE9E9",
    color: "#5E0808",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    padding: 16,
    width: "100%",
    marginBottom: 20,
    borderRadius: 4,
    display: "flex",
  },
  warningIcon: {
    marginRight: 12,
  },
  input: {
    width: "100%",
    marginBottom: 24,
    marginTop: 6,
  },
  additionalDonationInput: {
    width: 208,
    marginBottom: 8,
  },
  placeholder: {
    color: theme.palette.text.secondary2,
  },
}));
