import React, { useState, forwardRef } from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { useDataProvider, useNotify } from "react-admin";
import { isGivvrCompany } from "../../../lib";
import {
  donationExporter,
  ticketExporter,
  byOrderExporter,
  byProductExporter,
} from "../donationExports";

export const CampaignDonationDownloads = forwardRef(
  (
    {
      filterValues,
      currentSort,
      campaign_id,
      handleClose,
      label,
      reportType,
      campaign_type_id,
      companyId,
    },
    ref,
  ) => {
    const isGivvr = isGivvrCompany(companyId);
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [downloading, setDownloading] = useState(false);

    const download = async () => {
      setDownloading(true);
      handleClose();
      try {
        const { data } = await dataProvider.getList("donations", {
          sort: currentSort,
          filter: { ...filterValues, campaign_id, campaign_type_id },
          pagination: { page: 1, perPage: 10000 },
        });
        switch (reportType) {
          case "donation":
            donationExporter(data, campaign_type_id, isGivvr);
            break;
          case "raffle":
            ticketExporter(data, isGivvr);
            break;
          case "order":
            byOrderExporter(data);
            break;
          case "byProduct":
            byProductExporter(data);
            break;
          default:
        }
      } catch (error) {
        notify(
          error && error.message
            ? error.message
            : "There was an error downloading your report, please try again later.",
          "warning",
        );
      }
      // this may not be necessary now that we close the menu right away
      setTimeout(() => {
        setDownloading(false);
      }, 2000);
    };

    return (
      <MenuItem onClick={download} disabled={downloading} ref={ref}>
        <ListItemIcon>
          <DownloadIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={label} />
      </MenuItem>
    );
  },
);
