import { useState } from "react";
import {
  Button,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import { ButtonSpinner } from "../../../components";
import { APIAuthClient } from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions, setToast } from "../../../state";
import { RootState } from "../../../types/state";

export function TextOptIn() {
  const classes = styles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const {
    campaignId,
    text_drip_campaign,
    agreed_to_receive_texts,
    company_frontend_url,
  } = useSelector(({ campaign }: RootState) => campaign);

  const onSubmit = async () => {
    setSubmitting(true);
    const url = `/volunteers/agree_to_receive_texts?campaign_id=${campaignId}`;
    const res = await APIAuthClient.put<any, APIRes>(url, {});
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(
      campaignActions.updateCampaignInfo({ agreed_to_receive_texts: true }),
    );
    dispatch(
      setToast(
        "Your account has been updated to receive texts",
        ToastTypes.success,
      ),
    );
  };

  if (!text_drip_campaign || agreed_to_receive_texts) return <></>;
  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <SmsOutlinedIcon />

        <div className={classes.middle}>
          <div className={classes.header}>
            Opt in to receive convenient fundraiser reminders via text message.
          </div>
          <FormControlLabel
            classes={{ root: classes.formControl, label: classes.label }}
            control={
              <Checkbox
                checked={checked}
                onChange={() => setChecked(prev => !prev)}
                className={classes.checkbox}
              />
            }
            label={
              <div>
                <div>I agree to receive account related text messages</div>
                <div className={classes.textLegal}>
                  Message and data rates may apply. Reply STOP to opt out. Reply
                  HELP for assistance. Frequency varies. Subject to{" "}
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href={`${company_frontend_url || ""}/privacy`}
                    target="_blank"
                    className={classes.privacyLink}
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          color="primary"
          size="small"
          className={classes.button}
          disabled={!checked || submitting}
          onClick={onSubmit}
        >
          Submit
          <ButtonSpinner show={submitting} />
        </Button>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#E6F5FF",
    padding: "14px 16px 16px 16px",
    color: "#003554",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  left: {
    display: "flex",
  },
  middle: {
    marginLeft: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  header: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.13,
    lineHeight: "20px",
    paddingBottom: 14,
  },
  formControl: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  label: {
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: 0.15,
  },
  textLegal: {
    fontSize: 12,
    letterSpacing: 0.11,
    lineHeight: "18px",
    paddingTop: 4,
  },
  privacyLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
  button: {
    borderRadius: 15,
    width: 300,
    maxWidth: "100%",
  },
  checkbox: {
    color: "#003554 !important",
    marginTop: -6,
  },
}));
