import { APIAuthClient } from "../../lib";
import { APIRes, GenericAutocomplete } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const repTypes = {
  REP_AUTOCOMPLETE_SET: "REP_AUTOCOMPLETE_SET",
};

const repActions = {
  fetchRepAutocomplete(): AppThunkAction {
    return async dispatch => {
      const url = "/reps/autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      const repAutocomplete = data;
      const repAutocompleteIdMap = {};
      (data as GenericAutocomplete).forEach(
        e => (repAutocompleteIdMap[e.id] = e),
      );

      dispatch({
        type: repTypes.REP_AUTOCOMPLETE_SET,
        data: { repAutocomplete, repAutocompleteIdMap },
      });
    };
  },
};

export { repActions, repTypes };
