import { Drawer, IconButton, makeStyles } from "@material-ui/core";
import { Fragment, useState } from "react";
import { Datagrid, List, TextField } from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import { Shipping } from "../../components";
import { shippingCalculatedByChoices, shippingConfigLevels } from "../../lib";

export default function CompanySuppliersList(props) {
  const classes = styles();
  const [showAside, setShowAside] = useState(false);
  const [shippingProps, setShippingProps] = useState({});

  const onRowClick = (_, __, record) => {
    const {
      company_shipping_rate_type,
      product_supplier_id: supplier_id,
      supplier_shipping_calculated_by,
      supplier_shipping_rate_type,
      use_company_shipping_rates: coCustom,
    } = record;

    setShippingProps({
      readOnly: !coCustom,
      configLevel: coCustom
        ? shippingConfigLevels.COMPANY
        : shippingConfigLevels.SUPPLIER,
      supplier_id,
      shipping_calculated_by: coCustom
        ? shippingCalculatedByChoices.RATE_TABLE
        : supplier_shipping_calculated_by,
      shipping_rate_type: coCustom
        ? company_shipping_rate_type
        : supplier_shipping_rate_type,
    });
    setShowAside(true);
  };

  return (
    <Fragment>
      <List
        {...props}
        perPage={25}
        title="Company Suppliers"
        bulkActionButtons={false}
        exporter={false}
        sort={{ field: "id", order: "ASC" }}
        filters={undefined}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" />
          <TextField source="product_supplier_id" label="Supplier ID" />
          <TextField source="supplier_name" label="Supplier Name" />
        </Datagrid>
      </List>

      <Drawer open={showAside} anchor="right">
        <div className={classes.container}>
          <div className={classes.drawerHeader}>
            <div>Shipping</div>
            <IconButton onClick={() => setShowAside(false)} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.mainContent}>
            <Shipping {...shippingProps} />
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    width: "90vw",
    minWidth: "90vw",
    maxWidth: "100%",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px 16px 16px 20px",
    fontSize: 32,
    fontWeight: "bold",
    borderBottom: "1px solid grey",
  },
  mainContent: {
    padding: 20,
  },
}));
