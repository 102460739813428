import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(theme => ({
  ///// VOLUNTEER LIST  /////
  datagridBarClosed: {
    maxWidth: "calc(100vw - 95px)",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: () => "100vw",
    },
  },
  datagridBarOpen: {
    maxWidth: "calc(100vw - 280px)",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: () => "100vw",
    },
  },
  list: {
    "& div[class*='MuiCard-root']": {
      boxShadow: "none",
    },
  },
  siteLink: {
    display: "flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.87)",
  },
  actionButton: {
    marginLeft: "20px",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
  },
  emailButton: {
    width: "102px",
    color: theme.palette.primary.main,
  },
  impersonateButton: {
    display: "flex",
    alignItems: "center",
  },
  emailsSent: {
    whiteSpace: "nowrap",
  },
  searchLabel: {
    textTransform: "capitalize",
  },
  invalidContactCell: {
    color: "#FF0000",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  invalidCount: {
    paddingRight: "10px",
  },
  mainList: {
    height: "calc(100vh - 80px)",
    overflow: "scroll",
  },

  ///// ACTIVATE PREVIOUS VOLUNTEERS /////
  pvAppbar: {
    color: "#ffffff",
  },
  pvTitle: {
    paddingLeft: "12px",
    textTransform: "capitalize",
  },
  pvListContainer: {
    padding: "80px 18px 18px 18px",
  },
  pvButton: {
    color: theme.palette.primary.main,
  },
  pvButtonIcon: {
    fontSize: "20px",
    marginRight: "5px",
  },
  pvWarning: {
    marginBottom: 6,
    textAlign: "center",
    fontStyle: "italic",
    fontSize: 16,
    color: "#FF0000",
    fontWeight: 500,
  },

  ///// IMPORT VOLUNTEERS /////
  importContainer: {
    padding: "12px 24px 24px 24px",
    width: "750px",
    maxWidth: "100%",
  },
  importClose: {
    display: "flex",
    justifyContent: "flex-end",
  },
  importSubHeader: {
    fontSize: "18px",
    padding: "16px 0",
  },
  importTxt: {
    fontSize: "15px",
    paddingRight: "24px",
  },
  downloadSection: {
    display: "flex",
    alignItems: "center",
  },
  fileDrop: {
    marginTop: 16,
  },
  importButtons: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "90px",
    height: "36px",
  },
  progress: {
    color: "#ffffff",
  },
  saveBtnContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  failedUploadTable: {
    marginBottom: "36px",
  },
  failedUploadTh: {
    textAlign: "left",
    paddingRight: "32px",
  },
  failedUploadCell: {
    textAlign: "left",
  },
  importWarning: {
    fontStyle: "italic",
    textAlign: "center",
    marginBottom: 24,
    color: "#FF0000",
    fontWeight: 500,
  },
  // MODAL STYLES
  dialog: {
    padding: "24px",
    width: "800px",
    maxWidth: "100%",
  },
  dialogHeader: {
    fontSize: "22px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingBottom: "24px",
  },
  closeIcon: {
    position: "absolute",
    right: 0,
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    margin: "12px 0",
  },
  chip: {
    margin: "6px 12px 6px 0",
  },
  input: { marginTop: "24px" },
  txtMsgInput: { margin: "24px 0" },
  fileUpload: { marginTop: "10px" },
  button: {
    // margin: "24px 0",
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    fontWeight: 600,
    fontSize: "16px",
  },
  sendIcon: {
    marginLeft: "10px",
    fontSize: "20px",
  },
}));
