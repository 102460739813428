import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import {
  Button,
  IconButton,
  makeStyles,
  Checkbox,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { APIAuthClient, requiredField } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { getCampaignAutocomplete, setToast } from "../../../state";
import {
  ButtonSpinner,
  ConfirmationDialog,
  NullableField,
  ResponsiveModal,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RedButton } from "../../../components/ui/RedButton";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  record: GenericObject;
};

export function EditEmployee({
  onClose: _onClose,
  refreshList,
  record,
}: Props) {
  const { id } = record;
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const campaignAC = useSelector(getCampaignAutocomplete);
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setInitialValues(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const {
      first_name,
      last_name,
      phone,
      email,
      notes,
      org_employee_campaigns,
    } = values;
    const org_employee_campaign_ids = org_employee_campaigns.map(
      ({ campaign_id }) => campaign_id,
    );
    const update = {
      first_name,
      last_name,
      phone,
      email,
      notes,
      org_employee_campaign_ids,
    };
    const url = `/org_employees/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
    refreshList();
  };

  const onDelete = async () => {
    const url = `/org_employees/${id}`;
    const res = await APIAuthClient.delete<any, APIRes>(url);
    const { error, errorMessage } = res;
    if (error) {
      return dispatch(setToast(errorMessage));
    }
    dispatch(setToast("Employee deleted", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Edit employee</h1>
                <IconButton onClick={onClose} className={classes.close}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div className={classes.mainContent}>
                <div className={classes.inputGroup}>
                  <Field
                    component={TextField}
                    name="first_name"
                    label="First name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                  <Field
                    component={TextField}
                    name="last_name"
                    label="Last name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                  <Field
                    component={TextField}
                    name="phone"
                    label="Phone"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                </div>
                <NullableField
                  component={TextField}
                  name="notes"
                  label="Notes"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />

                <h2 className={classes.campaignsSubheader}>
                  Add employee to campaigns
                </h2>
                <p className={classes.campaignsInstructions}>
                  Select the campaigns that this employee can manage
                </p>
                <Field
                  name="org_employee_campaigns"
                  render={({ input, meta: _rm, ...rest }) => {
                    return (
                      <Autocomplete
                        {...rest}
                        {...input}
                        multiple
                        disableCloseOnSelect
                        onChange={(_, value) => input.onChange(value)}
                        options={campaignAC}
                        getOptionLabel={(o: any) =>
                          `${o.name} #${o.campaign_id}`
                        }
                        getOptionSelected={(o: any, c: any) => {
                          if (!c) return false;
                          if (typeof c === "string")
                            return `${o.campaign_id}` === c;
                          return o.campaign_id === c.campaign_id;
                        }}
                        renderInput={params => (
                          <MuiTextField
                            {...params}
                            placeholder="Select campaigns"
                          />
                        )}
                        renderOption={(o, { selected }) => (
                          <Fragment>
                            <Checkbox
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {o.name} #{o.campaign_id}
                          </Fragment>
                        )}
                      />
                    );
                  }}
                />
              </div>

              <div className={classes.bottom}>
                <RedButton
                  variant="text"
                  disabled={submitting}
                  startIcon={<DeleteIcon />}
                  onClick={() => setShowDelete(true)}
                >
                  DELETE
                </RedButton>
                <div className={classes.saveAndCancel}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
      {showDelete && (
        <ConfirmationDialog
          title="Are you sure you want to delete this employee?"
          actionFunc={onDelete}
          onClose={() => setShowDelete(false)}
          redButton
          actionLabel="DELETE"
        />
      )}
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      borderBottom: "none",
      padding: "24px 12px 18px 16px",
    },
  },
  close: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -16,
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  campaignsSubheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    marginTop: 16,
    marginBottom: 12,
    color: theme.palette.primary.main,
  },
  campaignsInstructions: {
    fontSize: 14,
    letterSpacing: 0.13,
    color: theme.palette.text.secondary,
    marginBottom: 16,
    lineHeight: "20px",
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
      paddingBottom: 8,
    },
  },
  saveAndCancel: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "center",
      marginBottom: 12,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
