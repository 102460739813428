import { Layout, Sidebar } from "react-admin";
import Menu from "./CustomMenu";
import CustomAppBar from "./CustomAppBar";
import { useSelector } from "react-redux";
import { isRepManager } from "../lib";
import { Fragment } from "react";

export default function CustomLayout(props) {
  const role = useSelector(state => state.user.role);
  const repManager = isRepManager(role);

  return (
    <Layout
      {...props}
      menu={Menu}
      appBar={CustomAppBar}
      sidebar={repManager ? HideSidebar : ShowSidebar}
    />
  );
}

function ShowSidebar({ children }) {
  return <Sidebar>{children}</Sidebar>;
}

function HideSidebar() {
  return <Fragment></Fragment>;
}
