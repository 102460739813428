import { Button, IconButton, makeStyles } from "@material-ui/core";
import { ListProps } from "../../../types";
import CancelIcon from "@material-ui/icons/Cancel";
import { useIsMobile } from "../../../hooks/ui";

type Props = {
  listProps: ListProps;
};

export function FilterChips({ listProps }: Props) {
  const { filterChips, clearFilter, clearAllFilters } = listProps;
  const classes = styles();
  const isMobile = useIsMobile();

  const removeFilter = (fieldArr: string[], chipField: string) => {
    clearFilter(fieldArr, chipField);
  };

  if (!Object.keys(filterChips).length) return <></>;
  return (
    <div className={classes.container}>
      {Object.keys(filterChips).map(k => {
        const { fields, text } = filterChips[k];
        return (
          <div key={k} className={classes.chip}>
            <div className={classes.txt}>{text}</div>
            <IconButton onClick={() => removeFilter(fields, k)}>
              <CancelIcon fontSize={isMobile ? "small" : "medium"} />
            </IconButton>
          </div>
        );
      })}
      {!isMobile && (
        <div>
          <Button
            variant="text"
            color="primary"
            size="small"
            className={classes.clearAll}
            onClick={clearAllFilters}
          >
            CLEAR FILTERS
          </Button>
        </div>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
      overflowY: "hidden",
      overflowX: "auto",
    },
  },
  chip: {
    height: 32,
    borderRadius: 16,
    marginRight: 8,
    backgroundColor: "#E0E0E0",
    fontSize: 13,
    letterSpacing: 0.15,
    display: "flex",
    alignItems: "center",
    padding: "0 2px 0 12px",
    overflow: "hidden",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      height: 24,
      borderRadius: 12,
      minWidth: "fit-content",
    },
  },
  txt: {
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    overflow: "hidden",
    lineHeight: "normal",
  },
  clearAll: {
    marginBottom: 8,
    minWidth: "fit-content",
  },
}));
