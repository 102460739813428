import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, makeStyles } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsDesktop, useIsMobile, useScrollToTop } from "../../hooks/ui";
import { getCampaignId } from "../../state";
import {
  FilterChips,
  FilterDrawerButton,
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../components";
import { useSetTopBarTitle } from "../../hooks/ui";
import { useList } from "../../hooks/list";
import {
  SubmissionFilterFields,
  SubmissionQueryParams,
  submissionSearchFields,
  submissionSortFields,
} from "../../types/submission";
import { SubmissionRows } from "./components/SubmissionRows";
import { Filters } from "./components/Filters";
import { NoSubmissionsSVG } from "../../assets";

export function Submissions() {
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const [isReady, setIsReady] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  useScrollToTop();

  useEffect(() => {
    if (campaign_id) setIsReady(true);
  }, [campaign_id]);

  const listProps = useList<SubmissionQueryParams>({
    baseUrl: "/submissions/org_campaign_list",
    isReady,
    initialState: {
      sort: "created_at",
      order: "desc",
      campaign_id,
    },
    filterFields: SubmissionFilterFields,
  });
  const {
    listIsFiltered,
    total,
    noResults,
    filterDrawerActive,
    showRows,
  } = listProps;

  useSetTopBarTitle(<h1 className={classes.title}>Submissions</h1>);

  return (
    <ListContainer listProps={listProps}>
      {isMobile && (
        <div className={classes.topSection}>
          <div>
            <h1 className={classes.title}>Submissions</h1>
            <div className={classes.count}>
              {total} Submission
              {total !== 1 ? "s" : ""}
            </div>
          </div>
          <div>
            <IconButton
              onClick={() => setShowSort(true)}
              className={classes.sort}
            >
              <SwapVertIcon />
            </IconButton>
            <IconButton onClick={() => setShowFilter(true)}>
              <FilterListIcon />
            </IconButton>
          </div>
        </div>
      )}

      <div className={classes.searchAndActionsRow}>
        <MultiSearch listProps={listProps} fields={submissionSearchFields} />
        {isDeskTop && (
          <div>
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
          </div>
        )}
      </div>

      <FilterChips listProps={listProps} />

      {noResults && (
        <ListNoResults
          SVG={NoSubmissionsSVG}
          listIsFiltered={listIsFiltered}
          label="submissions"
        />
      )}

      {showRows && <SubmissionRows listProps={listProps} />}

      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={submissionSortFields}
        />
      )}
      {showFilter && (
        <Filters onClose={() => setShowFilter(false)} listProps={listProps} />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0 8px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  sort: {
    marginRight: 8,
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
}));
