import { FormControlLabel, Switch } from "@material-ui/core";
import { ListProps } from "../../../types";
import { FilterWrapper } from "./FilterWrapper";

type Props = {
  header: string;
  switchLabel: string;
  chipText: string;
  field: string;
  listProps: ListProps;
};
export function SwitchFilter({
  header,
  switchLabel,
  chipText,
  field,
  listProps,
}: Props) {
  const { isActiveFilter, updateTempFilters, getFilterValue } = listProps;
  return (
    <FilterWrapper active={isActiveFilter(field)} header={header}>
      <FormControlLabel
        control={
          <Switch
            checked={getFilterValue(field, true)}
            onChange={({ target }) =>
              updateTempFilters({
                field,
                value: target.checked ? true : null,
                chipText,
              })
            }
          />
        }
        label={switchLabel}
      />
    </FilterWrapper>
  );
}
