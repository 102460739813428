export function LinkedinSVG() {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <title>Linkedin</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-204, -418)" fill="#006A9A" fillRule="nonzero">
          <g transform="translate(204, 418)">
            <path d="M7.54279213,10.4065041 L1.04257372,10.4065041 C0.75408364,10.4065041 0.520325203,10.6368108 0.520325203,10.9208113 L0.520325203,31.4856928 C0.520325203,31.7697961 0.75408364,32 1.04257372,32 L7.54279213,32 C7.83128221,32 8.06504065,31.7697961 8.06504065,31.4856928 L8.06504065,10.9208113 C8.06504065,10.6368108 7.83128221,10.4065041 7.54279213,10.4065041 Z"></path>
            <path d="M4.29440839,0 C1.92644989,0 0,1.92529646 0,4.29179362 C0,6.65933702 1.92644989,8.58536585 4.29440839,8.58536585 C6.66048456,8.58536585 8.58536585,6.6592324 8.58536585,4.29179362 C8.58547043,1.92529646 6.66048456,0 4.29440839,0 Z"></path>
            <path d="M23.7758142,9.88617886 C21.1916048,9.88617886 19.2812852,10.9918802 18.1226041,12.2482327 L18.1226041,10.9120269 C18.1226041,10.6279095 17.8912194,10.3975079 17.6056588,10.3975079 L11.4437745,10.3975079 C11.158214,10.3975079 10.9268293,10.6279095 10.9268293,10.9120269 L10.9268293,31.4853781 C10.9268293,31.7695984 11.158214,32 11.4437745,32 L17.8639247,32 C18.1494853,32 18.38087,31.7695984 18.38087,31.4853781 L18.38087,21.3063399 C18.38087,17.8762474 19.3169545,16.5399387 21.7193025,16.5399387 C24.335666,16.5399387 24.5435814,18.68219 24.5435814,21.4828199 L24.5435814,31.485481 C24.5435814,31.7697013 24.7749661,32 25.0605266,32 L31.4830547,32 C31.7686153,32 32,31.7697013 32,31.485481 L32,20.2006385 C32,15.1002116 31.02287,9.88617886 23.7758142,9.88617886 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
