import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Input } from "./RepCommissionInput";
import { FieldArray } from "react-final-form-arrays";
import {
  formatMoney,
  calcAndFormatPercentAmount,
  calcPercentAmount,
  twoDecimalsOnly,
  required,
  composeValidators,
} from "../../../lib";
import { styles } from "./RepCommission.styles";

export function CommissionWorksheet({
  rcData,
  disableEdit,
  formData,
  isProduct,
  totalCommission,
}) {
  const classes = styles();
  const {
    donationTotal,
    donationProfit,
    donationWholesale,
    hasCustomProfitProducts,
    totalProfit,
    totalRaised,
    totalWholesale,
  } = rcData;
  const { donation_commission_percent } = formData;

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table size="small">
        <caption>Commission Worksheet</caption>
        <TableHead>
          <TableRow>
            <TableCell>Profit Category</TableCell>
            {isProduct && <TableCell>Online/Offline</TableCell>}
            <TableCell>Amount Raised</TableCell>
            {isProduct && hasCustomProfitProducts && (
              <TableCell>Is Custom Profit</TableCell>
            )}
            <TableCell>Org Profit</TableCell>
            <TableCell>Wholesale</TableCell>
            <TableCell>Commission %</TableCell>
            <TableCell>Commission</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name="categoryAggregates">
            {({ fields }) => {
              return fields.map((row, index) => {
                const {
                  profit_category_name,
                  isOnline,
                  total,
                  isCustomProductProfit,
                  rep_commission_online_percent,
                  rep_commission_offline_percent,
                  rep_commission_custom_profit_product_online_percent,
                  rep_commission_custom_profit_product_offline_percent,
                  profit,
                  wholesale,
                } = fields.value[index];
                let name;
                let percent;
                switch (true) {
                  case Boolean(isOnline && !isCustomProductProfit):
                    name = "rep_commission_online_percent";
                    percent = rep_commission_online_percent;
                    break;
                  case Boolean(isOnline && isCustomProductProfit):
                    name =
                      "rep_commission_custom_profit_product_online_percent";
                    percent = rep_commission_custom_profit_product_online_percent;
                    break;
                  case Boolean(!isOnline && !isCustomProductProfit):
                    name = "rep_commission_offline_percent";
                    percent = rep_commission_offline_percent;
                    break;
                  case Boolean(!isOnline && isCustomProductProfit):
                    name =
                      "rep_commission_custom_profit_product_offline_percent";
                    percent = rep_commission_custom_profit_product_offline_percent;
                    break;
                  default:
                }
                const commission = calcPercentAmount(wholesale, percent);
                return (
                  <TableRow key={index}>
                    <TableCell>{profit_category_name}</TableCell>
                    <TableCell>{isOnline ? "Online" : "Offline"}</TableCell>
                    <TableCell>{formatMoney(total)}</TableCell>
                    {hasCustomProfitProducts && (
                      <TableCell>
                        {isCustomProductProfit ? <CheckIcon /> : ""}
                      </TableCell>
                    )}
                    <TableCell>{formatMoney(profit)}</TableCell>
                    <TableCell>{formatMoney(wholesale)}</TableCell>
                    <TableCell>
                      <Input
                        name={`${row}.${name}`}
                        type="number"
                        isPercent
                        disabled={disableEdit}
                        validate={composeValidators(required, twoDecimalsOnly)}
                      />
                    </TableCell>
                    <TableCell>{formatMoney(commission)}</TableCell>
                  </TableRow>
                );
              });
            }}
          </FieldArray>

          {(!isProduct || donationTotal > 0) && (
            <TableRow>
              <TableCell>Donations</TableCell>
              {isProduct && <TableCell>N/A</TableCell>}
              <TableCell>{formatMoney(donationTotal)}</TableCell>
              {isProduct && hasCustomProfitProducts && <TableCell />}
              <TableCell>{formatMoney(donationProfit)}</TableCell>
              <TableCell>{formatMoney(donationWholesale)}</TableCell>
              <TableCell>
                <Input
                  name="donation_commission_percent"
                  type="number"
                  isPercent
                  disabled={disableEdit}
                />
              </TableCell>
              <TableCell>
                {calcAndFormatPercentAmount(
                  donationWholesale,
                  donation_commission_percent,
                )}
              </TableCell>
            </TableRow>
          )}
          {/* TOTALS */}
          <TableRow>
            <TableCell className={classes.totalFigure}>Totals:</TableCell>
            {isProduct && <TableCell />}
            <TableCell className={classes.totalFigure}>
              {formatMoney(totalRaised)}
            </TableCell>
            {hasCustomProfitProducts && <TableCell />}
            <TableCell className={classes.totalFigure}>
              {formatMoney(totalProfit)}
            </TableCell>
            <TableCell className={classes.totalFigure}>
              {formatMoney(totalWholesale)}
            </TableCell>
            <TableCell />
            <TableCell className={classes.totalFigure}>
              {totalCommission}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
