import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
  email,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  useNotify,
  ImageInput,
  ImageField,
  regex,
} from "react-admin";
import {
  APIClient,
  generateAuthHeader,
  shippingRateTypeOptions,
  TEN_DIGIT_REGEX,
  TWO_DECIMAL_REGEX,
} from "../../lib";
import { styles } from "./Company.styles";

const {
  REACT_APP_DEFAULT_FRONTEND_URL,
  REACT_APP_DEFAULT_FROM_EMAIL,
} = process.env;
const validateEmail = [required(), email()];
const validateName = [required(), minLength(2)];
const requiredInput = [required()];
const tenDigitPhone = [
  regex(TEN_DIGIT_REGEX, "10 digit number without leading `+1` or punctuation"),
];
const moneyInput = [regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only")];
const requiredMoneyInput = [
  regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only"),
  required(),
];

export default function CompanyCreate(props) {
  const classes = styles();
  const notify = useNotify();
  const [suppliers, setSuppliers] = useState([]);
  const [dcInventoryGroups, setDcInventoryGroups] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/product_suppliers?_end=1000", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) {
        return notify(errorMessage, "warning");
      }
      setSuppliers(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/digital_content_inventory_groups", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) {
        return notify(errorMessage, "warning");
      }
      setDcInventoryGroups(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Create
      {...props}
      successMessage="company added successfully"
      title="Add Company"
    >
      <SimpleForm
        redirect="list"
        initialValues={{
          company_frontend_url: REACT_APP_DEFAULT_FRONTEND_URL,
          company_from_email: REACT_APP_DEFAULT_FROM_EMAIL,
        }}
      >
        <TextInput source="company_name" validate={validateName} />
        <TextInput source="company_slug" validate={validateName} />
        <TextInput source="company_support_phone" />
        <TextInput source="company_support_email" />
        <TextInput source="company_website" />
        <TextInput source="company_address" />
        <TextInput source="company_city" />
        <TextInput source="company_state" />
        <TextInput source="company_zip" />
        <TextInput
          source="company_frontend_url"
          label="Company Frontend URL - without trailing slash (EX: https://instaraise.com) DO NOT EDIT THIS UNTIL THE DNS IS CONFIGURED"
          validate={requiredInput}
          fullWidth
        />
        <TextInput
          source="company_from_email"
          label="From Email For Sendgrid Emails - DO NOT EDIT THIS UNTIL THE DNS IS CONFIGURED IN SENDGRID"
          validate={requiredInput}
          fullWidth
        />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="contact_name" validate={validateName} />
        <ImageInput
          source="companyLogoBase64"
          label="Company Logo"
          accept="image/*"
          multiple={false}
        >
          <ImageField
            source="src"
            title="title"
            classes={{ image: classes.logo }}
          />
        </ImageInput>
        <ImageInput
          source="companyLowResolutionLogoBase64"
          label="Company Low Resolution Logo"
          accept="image/*"
          multiple={false}
        >
          <ImageField
            source="src"
            title="title"
            classes={{ image: classes.logo }}
          />
        </ImageInput>
        <ReferenceInput
          label="Gateway Provider"
          source="gateway_provider_id"
          reference="gateway_providers"
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="gateway_login_key" />
        <TextInput source="gateway_trans_key" />
        <TextInput source="gateway_public_key" />
        <BooleanInput
          label="Enable Raffle Campaigns"
          source="raffle_campaigns_enabled"
        />
        <BooleanInput
          label="Enable Donation Campaigns"
          source="donation_campaigns_enabled"
        />
        <BooleanInput
          label="Enable Match Campaigns"
          source="match_campaigns_enabled"
        />
        <BooleanInput
          label="Enable Product Campaigns"
          source="product_campaigns_enabled"
        />
        <BooleanInput
          label="Using Gift Card Sites"
          source="using_gift_card_sites"
        />
        <SelectInput
          source="company_managed_supplier_id"
          label="Company Managed Supplier"
          optionText="supplier_name"
          resettable
          choices={suppliers}
          translateChoice={false}
        />
        <BooleanInput
          label="Managed Supplier Has Distributors"
          source="company_managed_supplier_has_distributors"
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { product_campaigns_enabled } = formData;

            if (!product_campaigns_enabled) {
              formData.company_product_suppliers = null;
            }
            return (
              <div>
                {product_campaigns_enabled && (
                  <Fragment>
                    <ArrayInput
                      validate={requiredInput}
                      source="company_product_suppliers"
                      fullWidth={false}
                      className={classes.enabledSuppliers}
                    >
                      <SimpleFormIterator
                        disableReordering
                        TransitionProps={{ enter: false }}
                      >
                        <SelectInput
                          validate={requiredInput}
                          source="product_supplier_id"
                          label="Supplier"
                          choices={suppliers}
                          optionText="supplier_name"
                          translateChoice={false}
                        />
                        <BooleanInput
                          {...rest}
                          label="Company Can Customize Products"
                          source="company_can_customize_products"
                          className={classes.supplierOptions}
                        />
                        <BooleanInput
                          {...rest}
                          label="Rep Can Customize Products On Campaign"
                          source="rep_can_customize_products"
                          className={classes.supplierOptions}
                        />
                        <BooleanInput
                          {...rest}
                          label="Use Company Custom Shipping Rates"
                          source="use_company_shipping_rates"
                          className={classes.supplierOptions}
                        />
                        <FormDataConsumer>
                          {({ scopedFormData = {}, getSource }) => {
                            const {
                              use_company_shipping_rates,
                            } = scopedFormData;
                            const src = getSource("company_shipping_rate_type");
                            if (!use_company_shipping_rates) return <></>;
                            return (
                              <Fragment>
                                <SelectInput
                                  source={src}
                                  label="Shipping Rate Type"
                                  choices={shippingRateTypeOptions}
                                  translateChoice={false}
                                  fullWidth
                                  className={classes.noHelperTxt}
                                />
                                <div />
                                <TextInput
                                  source={getSource(
                                    "company_default_shipping_price",
                                  )}
                                  type="number"
                                  label="Default Shipping Price"
                                  validate={requiredMoneyInput}
                                  fullWidth
                                />
                                <div />
                                <TextInput
                                  source={getSource(
                                    "company_free_shipping_from_amount",
                                  )}
                                  type="number"
                                  label="Free shipping when sale (+ donation) amount reaches"
                                  validate={moneyInput}
                                  fullWidth
                                  helperText="IMPORTANT! If you are not using this feature please leave this field
                                  blank. Do NOT set to zero. or all your shipping will be free."
                                />
                              </Fragment>
                            );
                          }}
                        </FormDataConsumer>
                      </SimpleFormIterator>
                    </ArrayInput>
                    <div>
                      <hr />
                      <ArrayInput
                        source="company_digital_content_inventory_groups"
                        label="Digital Content Inventory Groups"
                        fullWidth={false}
                        {...rest}
                      >
                        <SimpleFormIterator
                          disableReordering
                          TransitionProps={{ enter: false }}
                        >
                          <SelectInput
                            {...rest}
                            validate={requiredInput}
                            source="digital_content_inventory_group_id"
                            label="Group"
                            choices={dcInventoryGroups}
                            optionText="group_name"
                            translateChoice={false}
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                      <hr />
                    </div>
                  </Fragment>
                )}
              </div>
            );
          }}
        </FormDataConsumer>
        <BooleanInput
          label="Enable Rep Hierarchy"
          source="rep_hierarchy_enabled"
        />
        <BooleanInput
          label="Enable Campaign Management"
          source="enable_campaign_management"
          fullWidth
        />
        <BooleanInput label="Enable Texting" source="enable_texting" />
        <BooleanInput label="Rep Can Manage" source="rep_can_manage" />
        <BooleanInput label="Org Can Manage" source="org_can_manage" />
        <BooleanInput
          label="Company Can Allow Orgs To Create Campaigns"
          source="company_can_allow_orgs_to_create_campaigns"
          fullWidth
        />
        <BooleanInput
          label="Org Can Create Campaigns"
          source="org_can_create_campaigns"
          fullWidth
        />
        <BooleanInput
          label="Enable Discount Cards"
          source="enable_discount_cards"
        />
        <TextInput source="signalwire_space_url" />
        <TextInput
          source="signalwire_project_id"
          label="Signalwire Project ID"
        />
        <TextInput source="signalwire_api_token" />
        <TextInput
          source="signalwire_from_number"
          label="Signalwire From Number (10 digits without leading `+1`)"
          validate={tenDigitPhone}
          fullWidth
        />
        <TextInput source="taxjar_api_key" fullWidth />
        <TextInput source="external_api_key" fullWidth />
        <BooleanInput
          label="Allow Apple Pay"
          source="allow_apple_pay"
          fullWidth
        />
        <TextInput
          source="apple_pay_merchant_id"
          label="Apple Pay Merchant Id"
        />
        <BooleanInput
          label="No Cash Donations/Sales"
          source="no_cash_donations"
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
}
