import React, { Fragment } from "react";
import { ColorInput, ColorField } from "react-admin-color-input";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles(
  _theme => ({
    colorGroup: {
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
    },
    colorDivider: {
      marginRight: 12,
    },
  }),
  { classNamePrefix: "colors" },
);

export const ThemeColorPickers = props => {
  const { formData, ...rest } = props;
  const classes = styles();

  return (
    <Fragment>
      <div className={classes.colorGroup}>
        <ColorInput source="primary_color" {...rest} picker="Sketch" />
        <div className={classes.colorDivider}></div>
        <ColorField source="primary_color" {...rest} record={formData} />
      </div>

      <div className={classes.colorGroup}>
        <ColorInput source="primary_text" {...rest} picker="Sketch" />
        <div className={classes.colorDivider}></div>
        <ColorField source="primary_text" {...rest} record={formData} />
      </div>

      <div className={classes.colorGroup}>
        <ColorInput source="secondary_color" {...rest} picker="Sketch" />
        <div className={classes.colorDivider}></div>
        <ColorField source="secondary_color" {...rest} record={formData} />
      </div>

      <div className={classes.colorGroup}>
        <ColorInput source="secondary_text" {...rest} picker="Sketch" />
        <div className={classes.colorDivider}></div>
        <ColorField source="secondary_text" {...rest} record={formData} />
      </div>
    </Fragment>
  );
};
