import React, { useState, useEffect } from "react";
import {
  TextInput,
  List,
  Datagrid,
  TextField,
  Create,
  required,
  TopToolbar,
  SimpleForm,
  Filter,
  Notification,
  useNotify,
  useListContext,
  Edit,
  AutocompleteInput,
} from "react-admin";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { APIClient, generateAuthHeader } from "../../lib";

const requiredField = [required()];

const RepFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Rep" source="q" alwaysOn />
    </Filter>
  );
};

export function CompanyPrizeProgramReps({ record = {} }) {
  const { id: company_prize_program_id } = record;
  const classes = styles();
  const notify = useNotify();
  const basePath = `/company_prize_programs/${company_prize_program_id}/show/reps`;
  const [showCreate, setShowCreate] = useState(false);
  const [reps, setReps] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/reps/autocomplete", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setReps(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setShowCreate(true)}
      >
        add rep
      </Button>
    </TopToolbar>
  );

  return (
    <div className={classes.container}>
      <List
        resource="company_prize_program_reps"
        basePath={basePath}
        perPage={25}
        title=" "
        bulkActionButtons={false}
        exporter={false}
        empty={false}
        filter={{ company_prize_program_id }}
        filters={<RepFilter />}
        actions={<Actions />}
        component="div"
      >
        <GridWrapper
          company_prize_program_id={company_prize_program_id}
          basePath={basePath}
          showCreate={showCreate}
          setShowCreate={setShowCreate}
          reps={reps}
        />
      </List>
    </div>
  );
}

const GridWrapper = ({
  company_prize_program_id,
  basePath,
  showCreate,
  setShowCreate,
  reps,
  ...props
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  return (
    <div>
      <Datagrid
        {...props}
        rowClick={(id, basePath, record) => {
          setSelectedRow({ id, basePath, record });
          setShowEdit(true);
        }}
      >
        <TextField source="id" />
        <TextField source="rep_id" label="Rep Id" />
        <TextField source="rep_name" label="Rep Name" />
      </Datagrid>
      <AddRep
        basePath={basePath}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        company_prize_program_id={company_prize_program_id}
        reps={reps}
      />
      <EditRep
        selectedRow={selectedRow}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        reps={reps}
      />
    </div>
  );
};

function AddRep({
  showCreate,
  setShowCreate,
  basePath,
  company_prize_program_id,
  reps,
}) {
  const classes = styles();
  const notify = useNotify();
  const { refetch } = useListContext();

  const transformDataForSubmit = values => {
    return { company_prize_program_id, ...values };
  };

  return (
    <Drawer open={showCreate} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Add Rep</div>
          <IconButton onClick={() => setShowCreate(false)} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Create
          resource="company_prize_program_reps"
          basePath={basePath}
          onSuccess={() => {
            refetch();
            notify("Rep added successfully");
            setShowCreate(false);
          }}
          transform={transformDataForSubmit}
        >
          <SimpleForm>
            <AutocompleteInput
              source="rep_id"
              label="Select a Rep"
              choices={reps}
              translateChoice={false}
              validate={requiredField}
              fullWidth
            />
          </SimpleForm>
        </Create>
      </div>
      <Notification />
    </Drawer>
  );
}

function EditRep({ showEdit, setShowEdit, selectedRow, reps }) {
  const { id, basePath = "" } = selectedRow;
  const classes = styles();
  const notify = useNotify();
  const { refetch } = useListContext();

  const transformDataForSubmit = values => {
    const { company_prize_program_id, rep_id } = values;
    return { company_prize_program_id, rep_id };
  };

  const closeAndRefetch = () => {
    refetch();
    setShowEdit(false);
  };

  if (!id) return <></>;
  return (
    <Drawer open={showEdit} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Edit Rep</div>
          <IconButton onClick={closeAndRefetch} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Edit
          resource="company_prize_program_reps"
          basePath={basePath}
          id={id}
          undoable={false}
          onSuccess={() => {
            closeAndRefetch();
            notify("Rep updated successfully");
          }}
          transform={transformDataForSubmit}
        >
          <SimpleForm>
            <AutocompleteInput
              source="rep_id"
              label="Select a Rep"
              choices={reps}
              translateChoice={false}
              validate={requiredField}
              fullWidth
            />
          </SimpleForm>
        </Edit>
      </div>
      <Notification />
    </Drawer>
  );
}

const styles = makeStyles(theme => ({
  container: {
    margin: "12px 16px",
  },
  drawer: {
    minWidth: 500,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
}));
