import { discountCardConfigLevels } from "../../../types";

export function customerCardExportFormatter(data: any) {
  if (!Array.isArray(data)) return [];
  return data.map(row => {
    const {
      activation_code,
      assigned,
      campaign_id,
      card_name,
      config_level,
      donation_id,
      id,
      parent_campaign_product_discount_card_id,
      phone,
    } = row;
    return {
      ID: id,
      "Campaign ID": campaign_id,
      "Campaign card ID": parent_campaign_product_discount_card_id,
      Origin:
        config_level === discountCardConfigLevels.DONATION_PRODUCT
          ? "Order"
          : "Manually generated",
      Assigned: assigned ? "Yes" : "No",
      "Card name": card_name,
      "User phone": phone,
      "Order ID": donation_id,
      "Activation code": activation_code,
    };
  });
}
