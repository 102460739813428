import React from "react";
import { InputAdornment } from "@material-ui/core";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

export const Input = props => {
  const { isMoney, ...rest } = props;
  const data = { ...rest };
  if (isMoney) {
    data.InputProps = {
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    };
  }
  return (
    <Field
      variant="standard"
      component={TextField}
      parse={value => value}
      {...data}
    />
  );
};
