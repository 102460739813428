import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
  email,
  SelectInput,
} from "react-admin";
import { companyRoleOptions } from "../../lib";

const validateEmail = [required(), email()];
const validateName = [required(), minLength(2)];
const requiredInput = [required()];

export default function CompanyEmployeeCreate(props) {
  return (
    <Create
      {...props}
      successMessage="Employee added successfully"
      title="Add Employee"
    >
      <SimpleForm redirect="list">
        <TextInput source="first_name" validate={validateName} />
        <TextInput source="last_name" validate={validateName} />
        <TextInput source="email" validate={validateEmail} />
        <SelectInput
          label="Role"
          source="role"
          validate={requiredInput}
          choices={companyRoleOptions}
          translateChoice={false}
        />
      </SimpleForm>
    </Create>
  );
}
