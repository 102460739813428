import { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import SyncIcon from "@material-ui/icons/Sync";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "../../../types/state";
import { ConvertSupportersSVG } from "../../../assets/ConvertSupportersSVG";
import { useIsMobile } from "../../../hooks/ui";
import { ConvertSupportersToContacts } from "../../../components/contacts/ConvertSupportersToContacts";

export function ConvertSupportersBanner() {
  const classes = styles();
  const isMobile = useIsMobile();
  const convertibleDonors = useSelector(
    (state: RootState) => state.contacts.convertibleDonors,
  );
  const [showDialog, setShowDialog] = useState(false);
  const [hide, setHide] = useState(false);

  if (hide || !convertibleDonors.length) return <></>;
  return (
    <div className={classes.container}>
      {isMobile && (
        <CloseIcon className={classes.close} onClick={() => setHide(true)} />
      )}
      {!isMobile && (
        <div className={classes.svgWrapper}>
          <ConvertSupportersSVG />
        </div>
      )}
      <div className={classes.mainContent}>
        <div className={classes.svgAndText}>
          {isMobile && (
            <div className={classes.svgWrapper}>
              <ConvertSupportersSVG />
            </div>
          )}
          <div>
            <h3 className={classes.title}>
              Add past supporters to your contacts
            </h3>
            <div className={classes.msg}>
              Save past campaign donors to your contacts for convenient and
              streamlined future engagement.
            </div>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            size={isMobile ? "small" : "medium"}
            color="primary"
            onClick={() => setShowDialog(true)}
            startIcon={<SyncIcon />}
          >
            Convert donors to contacts
          </Button>
        </div>
      </div>
      <ConvertSupportersToContacts
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#E6F5FF",
    padding: "14px 16px 14px 110px",
    position: "relative",
    display: "flex",
    color: "#003554",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: "22px 16px 14px 16px",
      marginTop: 12,
      marginBottom: 0,
      width: "calc(100% + 32px)",
      marginLeft: -16,
      marginRight: -16,
    },
  },
  close: {
    position: "absolute",
    right: 4,
    top: 4,
    color: theme.palette.text.secondary2,
  },
  svgWrapper: {
    position: "absolute",
    left: 16,
    top: 16,
    bottom: 16,
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      minWidth: "fit-content",
      marginRight: 10,
    },
  },
  mainContent: {
    height: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  svgAndText: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "24px",
    paddingBottom: 4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "20px",
      paddingTop: 4,
      paddingBottom: 6,
    },
  },
  msg: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.13,
      lineHeight: "18px",
    },
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  button: {
    width: 252,
    minWidth: 252,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      minWidth: 300,
      marginLeft: 0,
      marginTop: 24,
    },
  },
  spinner: {
    position: "absolute",
    color: "#003554",
  },
}));
