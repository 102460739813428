import { store } from "../../../lib";
import { setToast } from "../../state";
import {
  fetchAndDownloadCsv,
  fetchAndDownloadExcel,
  fetchAndDownloadPdf,
} from "../fetchAndDownloadMethods";

export async function campaignVolunteerListDownload(
  campaignId: any,
  volunteerLabel: string,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/volunteers/campaign_volunteer_list_download?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(
    url,
    `${volunteerLabel} - Campaign #${campaignId}`,
    CB,
  );
}

export async function orderFormDiscrepancyReport(
  campaignId: any,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/reports/order-form-discrepancy-report?campaign_id=${campaignId}`;
  await fetchAndDownloadPdf(url, "Order Form Discrepancy Report", CB);
}

export async function STSStudentInfoReport(
  campaignId: any,
  volunteerLabelSingular: string,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/xlsx_reports/sts_student_info?campaign_id=${campaignId}`;
  await fetchAndDownloadExcel(
    url,
    `Ship To School ${volunteerLabelSingular} Info Report`,
    CB,
  );
}

export async function bigKahunaLargeOrderStudentPhoneNumbers(
  campaignId: any,
  volunteerLabelSingular: string,
  CB?: () => void,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/reports/big-kahuna-large-order-student-phones?campaign_id=${campaignId}`;
  await fetchAndDownloadCsv(
    url,
    `Large Order ${volunteerLabelSingular} Phone Numbers`,
    CB,
  );
}

export async function bigKahunaLimoReport(
  campaignId: any,
  CB?: () => void,
  isBlastParty = false,
) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  let url = `/xlsx_reports/big_kahuna_monster_limo?campaign_id=${campaignId}`;
  let name = "Monster Limo Party Report";
  if (isBlastParty) {
    url = `${url}&custom=blast_party`;
    name = "Blast Party Report";
  }
  await fetchAndDownloadExcel(url, name, CB);
}

export async function teachersReport(campaignId: any, CB?: () => void) {
  if (!campaignId) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  const url = `/xlsx_reports/teachers_report?campaign_id=${campaignId}`;
  await fetchAndDownloadExcel(url, "Teachers Report", CB);
}
