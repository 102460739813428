import { makeStyles, Tabs, Tab } from "@material-ui/core";
import { CircularLoader } from "../../components";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/state";
import { Reports } from "./reports/Reports";
import { CreateProgram } from "./program/CreateProgram";
import { Tiers } from "./tiers/Tiers";
import { useIsMobile, useSetTopBarTitle } from "../../hooks/ui";
import { giveawayActions } from "../../state";
import { ProgramConfig } from "./program/ProgramConfig";
import { getIsImpersonating } from "../../lib";
enum Screens {
  Reports = "Reports",
  Tiers = "Tiers",
  Config = "Config",
  CreateProgram = "CreateProgram",
}

export function Giveaways() {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { campaignGiveawayId } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const isImpersonating = getIsImpersonating();
  const { dataFetched } = useSelector(({ giveaway }: RootState) => giveaway);
  const [activeTab, setActiveTab] = useState<Screens | null>(null);

  useSetTopBarTitle(<h1 className={classes.title}>Giveaways</h1>);

  useEffect(() => {
    if (campaignGiveawayId) {
      setActiveTab(Screens.Reports);
      dispatch(giveawayActions.fetchCampaignGiveawayData(campaignGiveawayId));
    } else if (typeof campaignGiveawayId !== "undefined") {
      setActiveTab(Screens.CreateProgram);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignGiveawayId]);

  if (
    typeof campaignGiveawayId === "undefined" ||
    (campaignGiveawayId && !dataFetched)
  ) {
    return (
      <Fragment>
        {isMobile && <h1 className={classes.title}>Giveaways</h1>}
        <div className={classes.pageLoading}>
          <CircularLoader show />
        </div>
      </Fragment>
    );
  }
  return (
    <div>
      {isMobile && <h1 className={classes.title}>Giveaways</h1>}
      {activeTab && (
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{ root: classes.tabs }}
        >
          {campaignGiveawayId && (
            <Tab
              label="Giveaway reports"
              value={Screens.Reports}
              className={classes.tab}
            />
          )}
          {campaignGiveawayId && (
            <Tab
              label="Giveaway tiers"
              value={Screens.Tiers}
              className={classes.tab}
            />
          )}
          {isImpersonating && campaignGiveawayId && (
            <Tab
              label="Config"
              value={Screens.Config}
              className={classes.tab}
            />
          )}
          {isImpersonating && !campaignGiveawayId && (
            <Tab
              label="Create program"
              value={Screens.CreateProgram}
              className={classes.tab}
            />
          )}
        </Tabs>
      )}

      {activeTab === Screens.Config && <ProgramConfig />}
      {activeTab === Screens.Reports && <Reports />}
      {activeTab === Screens.Tiers && <Tiers />}
      {activeTab === Screens.CreateProgram && <CreateProgram />}
    </div>
  );
}

const styles = makeStyles(theme => ({
  pageLoading: {
    marginTop: 100,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      padding: "12px 16px 12px 16px",
      lineHeight: "unset",
    },
  },
  tabs: {
    borderBottom: "1px solid #DBDEEE",
    margin: "0 32px 18px 32px",
    position: "sticky",
    top: 56,
    backgroundColor: "#F7F7F7",
    zIndex: 9,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      top: 56,
    },
  },
  tab: {
    maxWidth: "fit-content",
    minWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
