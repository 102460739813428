import { useEffect, useState } from "react";

type Props = {
  type: "gold" | "silver" | "bronze";
};

export function AwardSVG({ type }: Props) {
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");

  useEffect(() => {
    switch (type) {
      case "gold":
        setColor1("#F6F3BE");
        setColor2("#E1B04E");
        setColor3("#BD8B36");
        break;
      case "silver":
        setColor1("#F9FBFC");
        setColor2("#C3D3DD");
        setColor3("#A5B7C6");
        break;
      case "bronze":
        setColor1("#F4D8BE");
        setColor2("#E0904F");
        setColor3("#BC6C37");
        break;
    }
  }, [type]);

  return (
    <svg width="63px" height="78px" viewBox="0 0 63 78">
      <title>Award</title>
      <defs>
        <linearGradient
          x1="65.2679768%"
          y1="4.32525952%"
          x2="34.3529087%"
          y2="96.8425606%"
          id={`linearGradient-${type}-1`}
        >
          <stop stopColor={color1} offset="0%"></stop>
          <stop stopColor={color2} offset="42%"></stop>
          <stop stopColor={color1} offset="76%"></stop>
          <stop stopColor={color3} offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="34.9478292%"
          y1="2.20588235%"
          x2="62.017598%"
          y2="88.2352941%"
          id={`linearGradient-${type}-2`}
        >
          <stop stopColor={color1} offset="0%"></stop>
          <stop stopColor={color2} offset="42%"></stop>
          <stop stopColor={color1} offset="76%"></stop>
          <stop stopColor={color3} offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="49.9858877%"
          x2="100%"
          y2="49.9858877%"
          id={`linearGradient-${type}-5`}
        >
          <stop stopColor={color1} offset="0%"></stop>
          <stop stopColor={color2} offset="42%"></stop>
          <stop stopColor={color1} offset="76%"></stop>
          <stop stopColor={color3} offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-63, -401)">
          <g transform="translate(32, 133.5)">
            <g transform="translate(32, 222)">
              <g transform="translate(0, 46)">
                <polygon
                  fill={`url(#linearGradient-${type}-1)`}
                  fillRule="nonzero"
                  points="17.6092753 77 11.7733484 66.2716263 0 69.5947232 13.3803427 38 30.9896181 45.4052768"
                ></polygon>
                <polygon
                  fill={`url(#linearGradient-${type}-2)`}
                  fillRule="nonzero"
                  points="43.3606784 77 49.1997927 66.2716263 60.9795711 69.5947232 47.5919207 38 29.989953 45.4052768"
                ></polygon>
                <polygon
                  fill={`url(#linearGradient-${type}-5)`}
                  fillRule="nonzero"
                  points="60.9795711 30.4913915 55.4203271 37.171606 56.9004973 45.7456957 48.7251385 48.741462 45.7303755 56.9023426 37.1763685 55.4216765 30.4983912 61 23.8032026 55.4216765 15.2491956 56.9023426 12.2544326 48.741462 4.07907376 45.7456957 5.57645527 37.171606 0 30.4913915 5.57645527 23.811177 4.07907376 15.2543043 12.2544326 12.258538 15.2491956 4.0804403 23.8032026 5.56110641 30.4983912 0 37.1763685 5.56110641 45.7303755 4.0804403 48.7251385 12.258538 56.9004973 15.2543043 55.4203271 23.811177"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
