import React, { useState, useEffect } from "react";
import {
  Datagrid,
  TextField,
  Create,
  Filter,
  NumberInput,
  SimpleForm,
  useListController,
  ListView,
  Pagination,
  TopToolbar,
  Notification,
  required,
  useNotify,
  SelectInput,
  FunctionField,
} from "react-admin";
import classnames from "classnames";
import { format } from "date-fns";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton, Chip } from "@material-ui/core";
import {
  APIClient,
  generateAuthHeader,
  poStatuses,
  poStatusOptions,
} from "../../../lib";
import { EditSourcePO } from "./EditSourcePO";
import { ReceiveSourcePO } from "./ReceiveSourcePO";
import classNames from "classnames";
import { styles } from "./SourcePO.styles";

const asideModes = {
  CREATE: "add",
  EDIT: "edit",
  RECEIVE: "receive",
  CLOSED: null,
};

const PoFilter = props => {
  return (
    <Filter {...props}>
      <NumberInput label="Search PO ID" source="q" alwaysOn />
      <SelectInput
        source="source_po_status"
        label="Status"
        choices={poStatusOptions}
        translateChoice={false}
        alwaysOn
      />
    </Filter>
  );
};

export function SourcePurchaseOrders(props) {
  const { record: { id: supplier_id } = {} } = props;
  const classes = styles();
  const notify = useNotify();
  const [asideMode, setAsideMode] = useState(asideModes.CLOSED);
  const [activePo, setActivePo] = useState({});
  const [productSources, setProductSources] = useState([]);

  useEffect(() => {
    if (supplier_id) fetchProductSources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const fetchProductSources = async () => {
    const res = await APIClient.get(
      `/product_sources/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductSources(data);
  };

  const controllerProps = useListController({
    sort: { field: "id", order: "DESC" },
    basePath: "",
    resource: "product_source_purchase_orders",
    filter: { supplier_id },
    perPage: 25,
  });

  const closeAside = () => {
    setAsideMode(asideModes.CLOSED);
    controllerProps.refetch();
  };

  const onCreateSuccess = createdRecord => {
    setActivePo(createdRecord);
    setTimeout(() => {
      setAsideMode(asideModes.EDIT);
    }, 5);
  };

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setAsideMode(asideModes.CREATE)}
      >
        add PO
      </Button>
    </TopToolbar>
  );

  const onRowClick = (_, __, record) => {
    setActivePo(record);
    setAsideMode(asideModes.EDIT);
  };

  const onReceiveClick = (e, record) => {
    e.stopPropagation();
    setActivePo(record);
    setAsideMode(asideModes.RECEIVE);
  };

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={<PoFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" />
          <TextField source="productSource" />
          <FunctionField
            label="Create Date"
            source="created_at"
            render={({ created_at }) => {
              return created_at ? format(new Date(created_at), "Pp") : "";
            }}
          />
          <FunctionField
            source="status"
            label="Status"
            render={({ status }) => (
              <Chip
                label={status}
                size="small"
                className={classNames(
                  classes.statusChip,
                  status === poStatuses.RECEIVING && classes.receivingChip,
                  status === poStatuses.CLOSED && classes.closedChip,
                )}
              />
            )}
          />
          <FunctionField
            render={record => {
              return (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={e => onReceiveClick(e, record)}
                >
                  receive
                </Button>
              );
            }}
          />
        </Datagrid>
      </ListView>

      <Drawer
        open={Boolean(asideMode)}
        anchor="right"
        PaperProps={{
          classes: {
            root: classnames(
              classes.drawer,
              asideMode !== asideModes.CREATE && classes.editDrawer,
            ),
          },
        }}
      >
        <div className={classes.drawerHeader}>
          <div>
            {asideMode === asideModes.CREATE
              ? "Add a Purchase Order"
              : asideMode === asideModes.EDIT
              ? "Purchase Order"
              : "Receive Purchase Order"}
          </div>
          <IconButton onClick={closeAside} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        {asideMode === asideModes.CREATE && (
          <SourcePoCreate
            onCreateSuccess={onCreateSuccess}
            supplier_id={supplier_id}
            productSources={productSources}
          />
        )}

        {asideMode === asideModes.EDIT && (
          <EditSourcePO record={activePo} closeAside={closeAside} />
        )}

        {asideMode === asideModes.RECEIVE && (
          <ReceiveSourcePO record={activePo} closeAside={closeAside} />
        )}
        <Notification />
      </Drawer>
    </div>
  );
}

function SourcePoCreate({ supplier_id, onCreateSuccess, productSources }) {
  const createProps = {
    resource: "product_source_purchase_orders",
    basePath: "purchase-orders",
    title: " ",
    onSuccess: ({ data }) => data && onCreateSuccess(data),
  };

  return (
    <Create {...createProps}>
      <SimpleForm initialValues={{ supplier_id }}>
        <SelectInput
          source="product_source_id"
          label="Select a source"
          choices={productSources}
          translateChoice={false}
          resettable
          validate={[required()]}
        />
      </SimpleForm>
    </Create>
  );
}
