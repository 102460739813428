import React, { Fragment, useState, useRef } from "react";
import {
  Datagrid,
  TextField,
  TextInput,
  BooleanField,
  Filter,
  useListController,
  ListView,
  Pagination,
  Notification,
  SelectInput,
  TopToolbar,
} from "react-admin";
import {
  TextField as MuiTextField,
  InputAdornment,
  Drawer,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ShipToSchoolOrder } from "./ShipToSchoolOrder";
import { PullSheetExport } from "./components/PullSheetExport";
import { ShipToSchoolPickSlipsExport } from "./components/ShipToSchoolPickSlipsExport";
import { AssignDeliveryNums } from "./components/AssignDeliveryNums";
import { STSPalletLabel } from "./components/STSPalletLabel";
import { ManuallyFulfillSTSOrders } from "./components/ManuallyFulfillSTSOrders";
import { StudentNumbersExport } from "../../components";
import { styles } from "./OrderFulfillment.styles";
const sortOptions = [
  { value: "classes", label: "Classes" },
  { value: "students", label: "Students" },
];

const SchoolOrdersFilter = ({ warehouses, ...props }) => {
  return (
    <Fragment>
      <Filter {...props}>
        <TextInput label="Student ID or Name" source="q" alwaysOn />
        <SelectInput
          source="warehouse_id"
          label="Warehouse"
          choices={warehouses}
          translateChoice={false}
          resettable
          alwaysOn
        />
      </Filter>
    </Fragment>
  );
};

const OrderActions = ({ campaign_id, currentFilters, orderPickSlipsBy }) => (
  <TopToolbar>
    <PullSheetExport isShipToSchool {...{ campaign_id, currentFilters }} />
    <ShipToSchoolPickSlipsExport
      {...{ campaign_id, currentFilters, orderPickSlipsBy }}
    />
  </TopToolbar>
);

const BulkActions = ({
  selectedIds,
  campaign_id,
  currentFilters,
  orderPickSlipsBy,
}) => {
  return (
    <div>
      <PullSheetExport
        isShipToSchool
        {...{ selectedIds, campaign_id, currentFilters }}
      />
      <ShipToSchoolPickSlipsExport
        {...{ selectedIds, campaign_id, currentFilters, orderPickSlipsBy }}
      />
    </div>
  );
};

export function ShipToSchoolStudentOrderList(props) {
  const {
    campaign_id,
    warehouses,
    prizeFulfillmentOnly,
    printStsLabelsAtFulfillment,
  } = props;
  const resource = "order_fulfillment/by_campaign/school";
  const classes = styles();
  const searchRef = useRef();
  const [selectedStudentId, setSelectedStudentId] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [disableRefresh, setDisableRefresh] = useState(false);
  const [orderPickSlipsBy, setOrderPickSlipsBy] = useState(
    sortOptions[0].value,
  );

  const controllerProps = useListController({
    sort: { field: "last_name", order: "ASC" },
    basePath: "",
    resource,
    perPage: 25,
    filter: { campaign_id },
  });
  const currentFilters = controllerProps.filterValues || {};
  const warehouse_id = currentFilters.warehouse_id || "";

  const handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && selectedStudentId) setOpenDrawer(true);
  };

  const focusInput = () => {
    setTimeout(() => {
      if (searchRef.current) searchRef.current.focus();
    }, 50);
    // set focus again in case it didn't pickup the first time
    setTimeout(() => {
      if (searchRef.current) searchRef.current.focus();
    }, 400);
  };

  const onRowClick = id => {
    setSelectedStudentId(id);
    setOpenDrawer(true);
  };

  const closeDrawerAndRefresh = () => {
    setOpenDrawer(false);
    setSelectedStudentId("");
    focusInput();
    // TODO: for now we aren't refreshing the list every time an order is fulfilled but we are adding a manual refresh button instead
    // controllerProps.refetch();
  };

  const refreshList = () => {
    if (disableRefresh) return;
    setDisableRefresh(true);
    controllerProps.refetch();
    focusInput();
    setTimeout(() => setDisableRefresh(false), 1000);
  };

  return (
    <Fragment>
      <div className={classes.studentOrdersTop}>
        <div className={classes.studentOrdersTopLeft}>
          <div className={classes.refreshContainer} onClick={refreshList}>
            <RefreshIcon className={classes.refreshIcon} /> Refresh list
          </div>
          <MuiTextField
            size="small"
            label="Order pick slips by"
            select
            value={orderPickSlipsBy}
            onChange={({ target }) => setOrderPickSlipsBy(target.value)}
            className={classes.sortSelect}
          >
            {sortOptions.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </MuiTextField>
        </div>

        <MuiTextField
          className={classes.studentOrderInput}
          color="primary"
          autoFocus
          placeholder="scan or enter student ID"
          type="number"
          value={selectedStudentId}
          onChange={({ target }) => setSelectedStudentId(target.value)}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => selectedStudentId && setOpenDrawer(true)}
              >
                <IconButton size="small">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={searchRef}
        />
        <div className={classes.stsButtonActions}>
          <AssignDeliveryNums
            campaign_id={campaign_id}
            prizeFulfillmentOnly={prizeFulfillmentOnly}
          />
          <StudentNumbersExport campaign_id={campaign_id} />
          <STSPalletLabel campaign_id={campaign_id} />
          <ManuallyFulfillSTSOrders campaign_id={campaign_id} />
        </div>
      </div>
      <ListView
        title=" "
        {...controllerProps}
        empty={false}
        bulkActionButtons={
          <BulkActions {...{ campaign_id, currentFilters, orderPickSlipsBy }} />
        }
        exporter={false}
        component="div"
        filters={<SchoolOrdersFilter warehouses={warehouses} />}
        actions={
          <OrderActions
            {...{ campaign_id, orderPickSlipsBy, currentFilters }}
          />
        }
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" label="Student ID" />
          <TextField source="first_name" label="Student First Name" />
          <TextField source="last_name" label="Student Last Name" />
          <TextField source="class_name" label="Class" />
          <BooleanField source="fulfilled" />
          <TextField source="itemCount" label="Items" />
          <TextField source="unfulfilledItemCount" label="Unfulfilled Items" />
          <TextField source="backOrderedItemCount" label="BO Item Count" />
          <TextField source="backOrderedScanCount" label="BO Scanned Count" />
          <TextField source="prizeCount" label="Prizes" />
          <TextField
            source="unfulfilledPrizeCount"
            label="Unfulfilled Prizes"
          />
        </Datagrid>
      </ListView>

      <Drawer open={openDrawer} anchor="right">
        <div className={classes.drawerContent}>
          <div className={classes.drawerHeader}>
            <div>{`Student ID: ${selectedStudentId}`}</div>
            <IconButton onClick={closeDrawerAndRefresh} size="small">
              <CloseIcon />
            </IconButton>
          </div>

          <ShipToSchoolOrder
            volunteer_id={selectedStudentId}
            campaign_id={campaign_id}
            closeDrawerAndRefresh={closeDrawerAndRefresh}
            warehouse_id={warehouse_id}
            printStsLabelsAtFulfillment={printStsLabelsAtFulfillment}
          />
        </div>
        <Notification />
      </Drawer>
    </Fragment>
  );
}
