import { Fragment, useEffect, useState } from "react";
import { makeStyles, Button, useMediaQuery, Theme } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  APIAuthClient,
  composeValidators,
  confirmPWValidation,
  emailValidation,
  requiredField,
} from "../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../types";
import { setToast } from "../../state";
import { getIsVolunteer, getVolunteerId } from "../../../customState";
import { WebpageQR } from "../../components";
import { useSetTopBarTitle } from "../../hooks/ui";

export function Profile() {
  const classes = styles();
  const dispatch = useDispatch();
  const volunteerId = useSelector(getVolunteerId);
  const isVolunteer = useSelector(getIsVolunteer);
  const [data, setData] = useState<GenericObject>({});
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  useSetTopBarTitle(
    <div>
      <h1 className={classes.title}>My Profile</h1>
      {isVolunteer && (
        <div className={classes.sellerId}>Seller ID {volunteerId}</div>
      )}
    </div>,
    [volunteerId],
  );

  useEffect(() => {
    const fetch = async () => {
      const res = await APIAuthClient.get<any, APIRes>("/users/profile");
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      setData(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: GenericObject) => {
    const {
      first_name,
      last_name,
      email,
      phone,
      currentPassword,
      newPassword,
    } = values;
    const put: GenericObject = {
      email,
      phone,
      currentPassword,
      newPassword,
    };
    if (isVolunteer) {
      put.first_name = first_name;
      put.last_name = last_name;
    }
    const res = await APIAuthClient.put<any, APIRes>("/users/profile", put);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setData(data);
    dispatch(setToast("Profile updated", ToastTypes.success));
  };

  return (
    <div className={classes.container}>
      <Form
        onSubmit={onSubmit}
        initialValues={data}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {isMobile && (
                <div className={classes.topContent}>
                  <div>
                    <h1 className={classes.title}>My Profile</h1>
                    {isVolunteer && (
                      <div className={classes.sellerId}>
                        Seller ID {volunteerId}
                      </div>
                    )}
                  </div>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={submitting}
                    className={classes.save}
                    size="small"
                  >
                    Save
                  </Button>
                </div>
              )}
              {!isMobile && <div className={classes.topSpacer} />}

              <div className={classes.group}>
                <div className={classes.groupTitle}>Info</div>
                {isVolunteer && (
                  <div className={classes.inputRow}>
                    <Field
                      name="first_name"
                      placeholder="First name"
                      component={TextField}
                      validate={requiredField}
                      className={classes.input}
                    />
                    <Field
                      name="last_name"
                      placeholder="Last name"
                      component={TextField}
                      validate={requiredField}
                      className={classes.input}
                    />
                  </div>
                )}

                <div className={classes.inputRow}>
                  <Field
                    name="email"
                    placeholder="Email"
                    component={TextField}
                    validate={composeValidators(requiredField, emailValidation)}
                    className={classes.input}
                  />
                  <Field
                    name="phone"
                    placeholder="Phone"
                    component={TextField}
                    validate={requiredField}
                    className={classes.input}
                  />
                </div>
              </div>

              <div className={classes.group}>
                <div className={classes.groupTitle}>Change password</div>
                <div className={classes.inputRow}>
                  <Field
                    name="currentPassword"
                    placeholder="Current password"
                    component={TextField}
                    type="password"
                    className={classes.input}
                  />
                </div>

                <div className={classes.inputRow}>
                  <Field
                    name="newPassword"
                    placeholder="New password"
                    component={TextField}
                    type="password"
                    className={classes.input}
                  />
                  <Field
                    name="confirmPW"
                    placeholder="Confirm new password"
                    component={TextField}
                    validate={confirmPWValidation}
                    type="password"
                    className={classes.input}
                  />
                </div>
              </div>

              {!isMobile && (
                <Fragment>
                  <div className={classes.divider} />
                  <Button
                    type="submit"
                    color="primary"
                    disabled={submitting}
                    className={classes.save}
                  >
                    Save
                  </Button>
                </Fragment>
              )}

              {isMobile && isVolunteer && <WebpageQR noBorderRadius />}
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingLeft: 32,
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingBottom: 0,
      backgroundColor: "#F7F7F7",
    },
  },
  topSpacer: {
    marginBottom: 24,
  },
  topContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 16px 16px 16px",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "32px",
    },
  },
  sellerId: {
    color: theme.palette.text.secondary2,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "22px",
    },
  },
  form: {
    width: 552,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  group: {
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 8,
      marginBottom: 16,
    },
  },
  groupTitle: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    marginBottom: 16,
  },
  inputRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    width: 264,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  divider: {
    width: "100%",
    marginBottom: 16,
    borderBottom: "1px solid #DBDEEE",
  },
  save: {
    width: 120,
    borderRadius: 18,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    [theme.breakpoints.down("sm")]: {
      width: 70,
      fontSize: 13,
      letterSpacing: 0.37,
    },
  },
}));
