import { useEffect, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import Quill from "quill";
import { useDispatch, useSelector } from "react-redux";
import { APIAuthClient, sanitizeQuillHtml } from "../../../lib";
import { APIRes, GuidedSetupScreen, quillConfigOptions } from "../../../types";
import { sharedStyles } from "../shared.styles";
import { ParagraphSVG } from "../SVGs/ParagraphSVG";
import {
  getVolunteerCampaignId,
  setToast,
  updateVolunteerSetupData,
} from "../../../state";
import { Tooltip } from "../components/Tooltip";
import { RootState } from "../../../types/state";

export function Paragraph(props: GuidedSetupScreen) {
  const {
    activeScreen,
    setDisableNext,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setSubmitting,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const quillDivRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const dispatch = useDispatch();
  const currHtml = useSelector(
    (state: RootState) => state.volunteerSetup.display_text_html,
  );
  const volunteerCampaignId = useSelector(getVolunteerCampaignId);

  useEffect(() => {
    if (activeScreen !== "PARAGRAPH") return;
    if (quillDivRef.current) {
      quillRef.current = new Quill(quillDivRef.current, quillConfigOptions);
    }
  }, [activeScreen]);

  // this must be after Quill initialization
  useEffect(() => {
    if (activeScreen === "PARAGRAPH") {
      if (quillRef.current) {
        quillRef.current.clipboard.dangerouslyPasteHTML(currHtml);
      }
    }
  }, [activeScreen, currHtml]);

  useEffect(() => {
    if (activeScreen === "PARAGRAPH") setDisableNext(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]);

  useEffect(() => {
    if (nextTriggeredBy === "PARAGRAPH") {
      submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  const submit = useMemo(
    () => async () => {
      setSubmitting(true);
      const html = sanitizeQuillHtml(quillRef.current?.root.innerHTML);
      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaigns/${volunteerCampaignId}`,
        { display_text_html: html },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      const _html = data.display_text_html;
      dispatch(updateVolunteerSetupData({ display_text_html: _html }));
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [volunteerCampaignId],
  );

  if (activeScreen !== "PARAGRAPH") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <ParagraphSVG />
      <h1 className={sharedClasses.screenHeader}>Personalize your message</h1>
      <p className={sharedClasses.screenParagraph}>
        Tell supporters why you are getting involved, what you are trying to
        accomplish & how much you appreciate their support
      </p>

      <div className={classes.instructions}>
        Do not leave empty lines before or after the text
      </div>

      <div className={sharedClasses.tooltipWrapper}>
        <Tooltip text="This content will appear on your personal page. Feel free to craft your own message or simply customize the default one to reflect your personal style." />
      </div>

      <div className="quill">
        <div ref={quillDivRef} />
      </div>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  instructions: {
    marginTop: 40,
    marginBottom: 16,
    color: "rgba(0,0,0,0.33)",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    textAlign: "center",
  },
}));
