import { Fragment, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { EditTier } from "./EditTier";
import { RootState } from "../../../types/state";
import { useSelector } from "react-redux";
import { getIsImpersonating } from "../../../lib";
const MIN_WIDTH = 1000;

type Props = {
  listProps: ListProps<any>;
};
export function TierRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const { data_point = "" } = useSelector(
    ({ giveaway }: RootState) => giveaway,
  );

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Giveaway name"
                fieldName="giveaway_name"
                params={params}
                setParams={setParams}
              />
              <div>Data point</div>
              <SortableColumnHeader
                columnName="Min threshold"
                fieldName="min_threshold"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Tier sort order"
                fieldName="sort_order"
                params={params}
                setParams={setParams}
              />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
              data_point={data_point}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Tiers per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
  data_point: string;
};
function Row({ row, lastRow, refreshList, data_point }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const isImpersonating = getIsImpersonating();
  const [showEdit, setShowEdit] = useState(false);
  const { giveaway_name, min_threshold, sort_order } = row;

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{giveaway_name}</div>
            <div>{data_point}</div>
            <div>{min_threshold}</div>
            <div>{sort_order}</div>
            <div>
              {isImpersonating && (
                <IconButton onClick={() => setShowEdit(true)}>
                  <EditIcon color="primary" />
                </IconButton>
              )}
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div className={classes.name}>{giveaway_name}</div>
              <div className={classes.dataPoint}>{data_point}</div>
              <div className={classes.rule}>Min threshold: {min_threshold}</div>
            </div>

            {isImpersonating && (
              <div className={classes.mobileEdit}>
                <IconButton onClick={() => setShowEdit(true)}>
                  <ChevronRightIcon />
                </IconButton>
              </div>
            )}
          </Fragment>
        )}
      </div>
      {showEdit && (
        <EditTier
          onClose={() => setShowEdit(false)}
          refreshList={refreshList}
          record={row}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "1fr .8fr 180px 180px 52px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  mobileLeft: {
    overflow: "hidden",
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingBottom: 4,
    },
  },
  dataPoint: {
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  rule: {
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.text.secondary,
      paddingTop: 4,
    },
  },
}));
