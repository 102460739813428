import { ReactNode } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import { Toast } from "../../components";
import { theme } from "../../theme/theme";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../../types/state";

type Props = {
  children: ReactNode | ReactNode[];
};
export function ReactAdminNewAppWrapper({ children }: Props) {
  const classes = styles();
  const sidebarOpen = useSelector(
    ({ admin }: RootState) => admin.ui.sidebarOpen,
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        className={classNames(
          "newApp",
          classes.wrapper,
          sidebarOpen && classes.sidebarOpen,
        )}
      >
        {children}
        <Toast />
      </div>
    </ThemeProvider>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    maxWidth: "calc(100vw - 55px)",
    backgroundColor: "#F7F7F7",
    margin: -24,
    marginLeft: -5,
    marginTop: -8,
    paddingTop: 24,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      paddingTop: 8,
      paddingBottom: 0,
      maxWidth: () => "100vw",
    },
  },
  sidebarOpen: {
    maxWidth: "calc(100vw - 240px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: () => "100vw",
    },
  },
}));
