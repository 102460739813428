import { ClickAwayListener, makeStyles } from "@material-ui/core";
import { useState } from "react";
import InfoIcon from "@material-ui/icons/InfoOutlined";

type Props = {
  content: string | JSX.Element;
};
export function ClickTooltip({ content }: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = styles();

  return (
    <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
      <span>
        <span
          className={classes.icon}
          onClick={e => {
            e.stopPropagation();
            setShowTooltip(prev => !prev);
          }}
        >
          <InfoIcon />
        </span>
        {showTooltip && <span className={classes.tooltip}>{content}</span>}
      </span>
    </ClickAwayListener>
  );
}

const styles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary2,
    cursor: "pointer",
    position: "relative",
  },
  tooltip: {
    display: "flex",
    zIndex: 999,
    borderRadius: 8,
    backgroundColor: "#1B47AA",
    color: "#FFFFFF",
    fontSize: 14,
    left: 0,
    letterSpacing: 0,
    lineHeight: "18px",
    width: 280,
    minWidth: 246,
    maxWidth: "100%",
    padding: "4px 8px",
    position: "absolute",
  },
}));
