import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  theme => ({
    drawer: {
      padding: 20,
      width: "100%",
      minWidth: 300,
      maxWidth: "100%",
    },
    drawerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 10px 0 16px",
      fontSize: 18,
    },
    addGroup: {
      marginRight: 16,
    },
    thumbnail: { maxHeight: 50 },
    removeButton: {
      backgroundColor: "#FACBCB",
      color: "#FF0000",
    },
    removeSpinner: {
      position: "absolute",
      color: "#FF0000",
    },
    addButton: {
      backgroundColor: "#CCE3CC",
      color: "#008000",
    },
    addSpinner: {
      position: "absolute",
      color: "#008000",
    },
    // CUSTOMIZE PRODUCT STYLES
    notAdded: { color: "red" },
    header: {
      color: theme.palette.primary.main,
      fontSize: "24px",
      fontWeight: 600,
      textAlign: "center",
      padding: "24px 0",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "24px 0",
      borderTop: "1px solid gray",
    },
    saveButton: {
      color: "#FFFFFF",
    },
    icon: {
      color: "#FFFFFF",
    },
    imagesContainer: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    imageSection: {
      display: "flex",
    },
    currentImage: {
      marginRight: "24px",
    },
    label: {
      width: "158px",
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: 400,
      fontSize: "12.5px",
      paddingTop: "8px",
    },
    image: {
      paddingTop: "8px",
      maxWidth: "150px",
    },
    addToCampaigns: {
      marginTop: 10,
      marginBottom: 18,
    },
    divider: {
      borderRight: "1px solid gray",
      height: "100px",
      width: "1px",
      margin: "0 12px",
    },
    topSection: {
      display: "flex",
      alignItems: "center",
      marginBottom: 16,
    },
    shippingSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 48,
    },
    prodDefault: {
      display: "flex",
      alignItems: "center",
      marginBottom: 8,
    },
    customInput: {
      display: "inline-flex",
      width: 380,
      marginRight: 16,
      marginBottom: 16,
    },
    shippingSelect: {
      width: 200,
    },
    shippingLabel: {
      fontWeight: 600,
      fontSize: 16,
      paddingBottom: 10,
      textTransform: "uppercase",
    },
  }),
  {
    classNamePrefix: "productGroup",
  },
);
