import React, { Fragment } from "react";
import {
  SimpleShowLayout,
  TextField,
  TopToolbar,
  EditButton,
  Show,
} from "react-admin";
import { useSelector } from "react-redux";
import { getRoleBooleans } from "../../customState";

const RepShow = props => {
  const { isCompanyOrCompanyAdmin } = useSelector(state =>
    getRoleBooleans(state),
  );
  const campaignManagementEnabled = useSelector(
    ({ user }) => user.campaignManagementEnabled,
  );

  const RepTitle = ({ record }) => {
    return (
      <span>
        {record ? `Viewing - ${record.first_name} ${record.last_name}` : ""}
      </span>
    );
  };

  const RepActions = ({ basePath, data }) => (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} label="EDIT REP" />
    </TopToolbar>
  );

  return (
    <Fragment>
      <Show {...props} actions={<RepActions />} title={<RepTitle />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="email" />
          <TextField source="phone" />
          <TextField source="association" />
          {isCompanyOrCompanyAdmin && <TextField source="general_notes" />}
          {isCompanyOrCompanyAdmin && campaignManagementEnabled && (
            <TextField source="org_profit_notes" />
          )}
          {isCompanyOrCompanyAdmin && campaignManagementEnabled && (
            <TextField source="rep_commission_notes" />
          )}
        </SimpleShowLayout>
      </Show>
    </Fragment>
  );
};

export default RepShow;
