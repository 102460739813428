import React, { Fragment, useState } from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  useNotify,
  List,
  useRefresh,
  NumberInput,
  Show,
  SimpleShowLayout,
  Confirm,
} from "react-admin";
import { makeStyles, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { format } from "date-fns";
import { APIClient, generateAuthHeader, isProductCampaign } from "../../lib";
import { getSeasonId } from "../../customState";

const DonationFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Customer Name/Email" source="q" alwaysOn />
      <NumberInput label="Order ID" source="id" alwaysOn />
      <NumberInput label="Campaign ID" source="campaign_id" alwaysOn />
    </Filter>
  );
};

export default function DeletedDonationList() {
  const season_id = useSelector(getSeasonId);

  return (
    <List
      resource="deleted_donations"
      title="Deleted Sales"
      basePath="/deleted_donations"
      location={{}}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      filter={{ season_id }}
      bulkActionButtons={false}
      filters={<DonationFilter />}
      actions={false}
      empty={false}
    >
      <Datagrid rowClick="expand" expand={<Donation />}>
        <TextField source="id" label="Order ID" sortable={true} />
        <FunctionField
          label="Sale Date"
          source="created_at"
          render={({ created_at }) => {
            return created_at ? format(new Date(created_at), "Pp") : "";
          }}
        />
        <FunctionField
          label="Deleted On"
          source="deleted_at"
          render={({ deleted_at }) => {
            return deleted_at ? format(new Date(deleted_at), "Pp") : "";
          }}
        />
        <TextField source="orgName" sortable={false} label="Org" />
        <TextField source="campaign_id" label="Campaign ID" />
        <TextField source="campaignName" sortable={false} label="Campaign" />
        <TextField source="first_name" sortable={true} />
        <TextField source="last_name" sortable={true} />
        <TextField source="email" sortable={true} />
        <TextField source="total_amount" sortable={true} label="Sale" />
        <TextField source="volunteerName" label="Seller" />
        <FunctionField
          label="STS/STH"
          source="ship_to_school"
          render={({ ship_to_school, campaign_type_id }) => {
            if (!isProductCampaign(campaign_type_id)) return "";
            return ship_to_school ? "STS" : "STH";
          }}
        />
      </Datagrid>
    </List>
  );
}

function Donation({ id, basePath, resource, record }) {
  const { campaign_type_id } = record;
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const classes = styles();
  const [submitting, setSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const isProduct = isProductCampaign(campaign_type_id);

  const unDeleteDonation = async () => {
    setSubmitting(true);
    const res = await APIClient.put(
      `/deleted_donations/${id}/un_delete`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) {
      notify(errorMessage, "warning");
      setSubmitting(false);
      return;
    }
    notify("Sale has been successfully un-deleted!", { type: "info" });
    dispatch({
      type: "RA/TOGGLE_LIST_ITEM_EXPAND",
      payload: id,
      meta: { resource },
    });
    setTimeout(() => refresh(), 100);
  };

  return (
    <Fragment>
      <Show resource={resource} id={id} basePath={basePath} component="div">
        <SimpleShowLayout>
          <Fragment>
            <div className={classes.showHeader}>Additional Info</div>
          </Fragment>
          <TextField source="phone" />
          <TextField source="address" />
          <TextField source="apt" />
          <TextField source="city" />
          <TextField source="state" />
          <TextField source="zip" />
          <TextField source="internal_notes" />
          <TextField source="company_internal_notes" />
          {isProduct && (
            <FunctionField
              label="Products"
              render={({ products }) => {
                if (!Array.isArray(products) || !products.length) return "";
                return (
                  <div>
                    {Array.isArray(products) &&
                      products.map((p, index) => {
                        const {
                          price,
                          product_name,
                          sku,
                          qty,
                          thumbnail_image,
                        } = p;
                        return (
                          <div key={index} className={classes.productContainer}>
                            <img
                              className={classes.img}
                              src={thumbnail_image}
                              alt="product"
                            />
                            <div>
                              <div>{product_name}</div>
                              <div>SKU: {sku}</div>
                              <div>{`$${price} / each`}</div>
                              <div>Qty: {qty}</div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                );
              }}
            />
          )}

          <Fragment>
            <Button
              className={classes.unDelete}
              variant="contained"
              onClick={() => setOpenDialog(true)}
              disabled={submitting}
            >
              Un-delete
            </Button>
          </Fragment>
        </SimpleShowLayout>
      </Show>
      <Confirm
        isOpen={openDialog}
        title={`Are you sure?`}
        content={`Are you sure you want to un-delete sale #${id}`}
        translateOptions={false}
        onConfirm={unDeleteDonation}
        onClose={() => setOpenDialog(false)}
        loading={submitting}
      />
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  showHeader: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: -22,
    marginBottom: 8,
  },
  productContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
  },
  img: {
    height: "75px",
    width: "75px",
    marginRight: "24px",
  },
  unDelete: { color: "#FFFFFF", backgroundColor: "red" },
}));
