import { createTheme } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    secondary2: Palette["secondary"];
  }
  interface PaletteOptions {
    secondary2: PaletteOptions["secondary"];
  }
  interface TypeText {
    secondary2: TypeText["secondary"];
  }
}

export const customTheme = createTheme({
  palette: {
    text: {
      primary: "#212121",
      secondary: "#5E5E5E",
      secondary2: "#AAAAAA",
      disabled: "#AAAAAA",
    },
    primary: { main: "#248BEF", contrastText: "#FFFFFF" },
    secondary: { main: "#212121", contrastText: "#FFFFFF" },
    secondary2: { main: "#FD7B6F", contrastText: "#FFFFFF" },
    error: { main: "#ED2A2A", contrastText: "#FFFFFF" },
    background: { default: "#F7F7F7", paper: "#FFFFFF" },
  },
  typography: {
    fontFamily: `${[
      "Graphik Web",
      "sans-serif",
      "Roboto",
      "Helvetica",
      "Arial",
    ].join(",")} !important`,
  },
  overrides: {
    MuiTableCell: {
      root: {
        height: "40px",
      },
    },
    MuiInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
      },
    },
    MuiFilledInput: {
      input: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          display: "none",
        },
      },
    },

    // @ts-expect-error any
    // https://github.com/marmelab/react-admin/issues/4537
    RaAutocompleteSuggestionList: {
      suggestionsContainer: {
        zIndex: 9000,
      },
    },
    RaSimpleFormIterator: {
      root: {
        marginTop: 12,
      },
      index: {
        display: "none",
      },
    },
    RaFilterForm: {
      form: { minHeight: "unset", marginTop: 0 },
    },
    RaLayout: {
      appFrame: {
        marginTop: "56px !important",
      },
    },
    RaSidebar: {
      root: {
        height: "unset",
      },
    },
    RaToolbar: {
      mobileToolbar: { position: "initial" },
      spacer: { display: "none" },
    },
    RaMenuItemLink: {
      root: {
        fontSize: 15,
      },
    },
    RaFileInput: {
      dropZone: {
        background: "#fafafa",
        minHeight: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    RaImageInput: {
      dropZone: {
        background: "#fafafa",
        minHeight: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
  },
});
