import React, { Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  DateInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

const requiredField = [required()];

export default function CompanySeasonCreate(props) {
  const classes = styles();

  return (
    <Create
      {...props}
      successMessage="Season added successfully"
      title="Add a Season"
    >
      <SimpleForm redirect="list">
        <TextInput source="season_name" validate={requiredField} label="Name" />
        <BooleanInput source="is_default" label="Set As Default" />
        <Fragment>
          <div className={classes.warning}>
            These dates are only applicable to suppliers who service other
            distributors on Instaraise for season management of distributors'
            campaigns.
          </div>
          <div className={classes.warning}>
            They do NOT determine your own company's seasons.
          </div>
        </Fragment>
        <DateInput
          source="distributor_min_campaign_end_date"
          label="Distributor Min Campaign End Date"
          className={classes.date}
        />
        <DateInput
          source="distributor_max_campaign_end_date"
          label="Distributor Max Campaign End Date"
          className={classes.date}
        />
      </SimpleForm>
    </Create>
  );
}

const styles = makeStyles(_theme => ({
  date: { width: 265 },
  warning: { color: "red" },
}));
