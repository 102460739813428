import React from "react";
import { makeStyles } from "@material-ui/core";

const styles = makeStyles(
  _theme => ({
    container: {
      display: "flex",
      alignItems: "center",
      marginBottom: "18px",
    },
    img: {
      height: "75px",
      width: "75px",
      marginRight: "24px",
    },
  }),
  {
    classNamePrefix: "products",
  },
);

export function Products({ products }) {
  const classes = styles();
  if (!Array.isArray(products) || !products.length) {
    return <div>Donation only</div>;
  }
  return (
    <div>
      {Array.isArray(products) &&
        products.map((p, index) => {
          const {
            price,
            product_name,
            sku,
            qty,
            thumbnail_image,
            donation_product_personalization: DPP,
          } = p;
          let p13nStr = null;
          if (Array.isArray(DPP) && DPP.length) {
            p13nStr = DPP.map(({ text }) => text).join(", ");
          }
          return (
            <div key={index} className={classes.container}>
              <img
                className={classes.img}
                src={thumbnail_image}
                alt="product"
              />
              <div>
                <div>{product_name}</div>
                <div>SKU: {sku}</div>
                <div>{`$${price} / each`}</div>
                <div>Qty: {qty}</div>
                {p13nStr && <div>Personalization(s): {p13nStr}</div>}
              </div>
            </div>
          );
        })}
    </div>
  );
}
