import { Button, IconButton, makeStyles } from "@material-ui/core";
import screenfull from "screenfull";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import {
  dashboardDataActions,
  getCampaignId,
  getShowLeaderboard,
  setToast,
} from "../../state";
import { useEffect, useState, Fragment, useRef } from "react";
import { Progress } from "./components/Progress";
import { RecentDonations } from "./components/RecentDonations";
import { RootState } from "../../types/state";
import { APIAuthClient, getCompanyLogoHref } from "../../lib";
import { APIRes, GenericObject, paths } from "../../types";
import { TopVolunteersAndTeams } from "./components/TopVolunteersAndTeams";
import { useHistory } from "react-router-dom";
import { Timer } from "./components/Timer";
import { useIsDesktop, useIsMobile, useLogout } from "../../hooks/ui";

export function Leaderboard() {
  const classes = styles();
  const dispatch = useDispatch();
  const logout = useLogout();
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const interval = useRef<undefined | NodeJS.Timeout>(undefined);
  const campaignTypeIdRef = useRef<any>(null);
  const hasTeamsRef = useRef<boolean>(false);
  const profitBasedRef = useRef<boolean>(false);
  const campaignId = useSelector(getCampaignId);
  const showLB = useSelector(getShowLeaderboard);
  const companyId = useSelector(({ user }: RootState) => user.companyId);
  const {
    primary_color,
    secondary_color,
    campaign_type_id,
    show_profit_based_progress,
    campaignInfoFetched,
    orgName,
    webpageLink,
    hasTeams,
  } = useSelector(({ campaign }: RootState) => campaign, shallowEqual);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [secondaryColor, setSecondaryColor] = useState<string>("");
  const [primaryColor, setPrimaryColor] = useState<string>("");
  const [isActiveState, setIsActiveState] = useState(true);
  const [teams, setTeams] = useState<GenericObject[]>([]);
  const [volunteers, setVolunteers] = useState<GenericObject[]>([]);
  const [donations, setDonations] = useState<GenericObject[]>([]);
  const companyLogoHref = getCompanyLogoHref(companyId);

  useEffect(() => {
    if (!showLB) history.replace(paths.HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLB]);

  useEffect(() => {
    if (secondary_color) setSecondaryColor(secondary_color);
    if (primary_color) setPrimaryColor(primary_color);
  }, [secondary_color, primary_color]);

  // initial data fetch
  useEffect(() => {
    if (campaignInfoFetched && campaignId && campaign_type_id) {
      campaignTypeIdRef.current = campaign_type_id;
      if (show_profit_based_progress) profitBasedRef.current = true;
      if (hasTeams) hasTeamsRef.current = true;
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignInfoFetched,
    campaignId,
    campaign_type_id,
    show_profit_based_progress,
    hasTeams,
  ]);

  useEffect(() => {
    interval.current = setInterval(() => {
      if (campaignTypeIdRef.current) fetchData();
    }, 1000 * 120);
    return () => clearInterval(interval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handler = () => {
      const _isActive = document.visibilityState === "visible";
      setIsActiveState(_isActive);
    };

    window.addEventListener("visibilitychange", handler);
    return () => {
      window.removeEventListener("visibilitychange", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeams = async () => {
    let url = `/leaderboards/campaigns/${campaignId}/top-teams?campaign_type_id=${campaignTypeIdRef.current}`;
    if (profitBasedRef.current) url = `${url}&profit_based=true`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setTeams(data);
  };

  const getVolunteers = async () => {
    let url = `/leaderboards/campaigns/${campaignId}/top-volunteers?campaign_type_id=${campaignTypeIdRef.current}`;
    if (profitBasedRef.current) url = `${url}&profit_based=true`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setVolunteers(data);
  };

  const getDonations = async () => {
    let url = `/leaderboards/campaigns/${campaignId}/latest-donations?campaign_type_id=${campaignTypeIdRef.current}`;
    if (profitBasedRef.current) url = `${url}&profit_based=true`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, status, data } = res;
    if (error) {
      // If their session expired log them out
      if (status === 403) return logout();
      return dispatch(setToast(errorMessage));
    }
    setDonations(data);
  };

  const fetchData = () => {
    if (!isActiveState) return;
    dispatch(dashboardDataActions.getCampaignProgressStats());
    getVolunteers();
    if (hasTeamsRef.current) getTeams();
    getDonations();
  };

  const toggleFullScreen = () => {
    screenfull.toggle();
    setTimeout(() => {
      setIsFullScreen(screenfull.isFullscreen);
    }, 500);
  };

  return (
    <div
      className={classes.container}
      style={{ backgroundColor: isMobile ? secondaryColor : "#FFFFFF" }}
    >
      <div>
        <div className={classes.topWrapper}>
          <div className={classes.top} style={{ color: secondaryColor }}>
            <div className={classes.topLeft}>
              <Button
                variant="text"
                startIcon={<ArrowBackIcon />}
                style={{ color: secondaryColor }}
                onClick={() => history.push(paths.HOME)}
                size={isMobile ? "small" : "medium"}
              >
                BACK
              </Button>
              {isDesktop && (
                <img
                  alt="logo"
                  src={companyLogoHref}
                  className={classes.logo}
                />
              )}
            </div>
            {isDesktop && <div className={classes.orgName}>{orgName}</div>}
            {isMobile && (
              <img alt="logo" src={companyLogoHref} className={classes.logo} />
            )}
            <div className={classes.topRight}>
              {isDesktop && (
                <Fragment>
                  <Timer />
                  <IconButton
                    size="small"
                    style={{ color: secondaryColor }}
                    onClick={toggleFullScreen}
                  >
                    {!isFullScreen && <FullscreenIcon />}
                    {isFullScreen && <FullscreenExitIcon />}
                  </IconButton>
                </Fragment>
              )}
            </div>
          </div>
        </div>

        {isDesktop && (
          <div className={classes.linkWrapper}>
            <div
              className={classes.link}
              style={{ backgroundColor: secondaryColor }}
            >
              <span className={classes.join}>Join now at</span>
              {webpageLink}
            </div>
          </div>
        )}
      </div>

      <div className={classes.middleContentWrapper}>
        <div
          className={classes.middleContent}
          style={{ backgroundColor: secondaryColor }}
        >
          {isMobile && <div className={classes.orgName}>{orgName}</div>}
          <Progress />
          <TopVolunteersAndTeams
            hasTeams={hasTeamsRef.current}
            teams={teams}
            volunteers={volunteers}
          />
        </div>
      </div>

      <RecentDonations donations={donations} />

      {isMobile && (
        <div className={classes.mobileJoinWrapper}>
          <div
            className={classes.mobileJoin}
            style={{ backgroundColor: primaryColor }}
          >
            <div className={classes.joinBold}>Join now at</div>
            {webpageLink}
          </div>
        </div>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  topWrapper: {
    backgroundColor: "#FFFFFF",
    padding: "32px 32px 16px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  top: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  topLeft: {
    width: 228,
    display: "flex",
    alignItems: "center",
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      width: 70,
      marginRight: 0,
    },
  },
  topRight: {
    width: 228,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: 70,
    },
  },
  logo: {
    maxWidth: 142,
    maxHeight: 48,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: 160,
    },
  },
  orgName: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.23,
    lineHeight: "30px",
    maxWidth: "calc(100% - 440px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      color: "#FFFFFF",
      maxWidth: "100%",
      paddingBottom: 24,
      overflowWrap: "anywhere",
      textOverflow: "unset",
      textWrap: "unset",
      textAlign: "center",
      fontSize: 22,
      letterSpacing: 0.18,
      lineHeight: "28px",
      overflow: "unset",
    },
  },
  linkWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    padding: "0 32px 22px 32px",
  },
  link: {
    maxWidth: "95%",
    minHeight: 40,
    borderRadius: 24,
    padding: "10px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.19,
    lineHeight: "24px",
    overflowWrap: "anywhere",
  },
  join: {
    fontWeight: "bold",
    paddingRight: 8,
  },
  middleContentWrapper: {
    width: "100%",
    padding: "0 32px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  middleContent: {
    width: "100%",
    borderRadius: 8,
    padding: 32,
    paddingBottom: 8,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 16px 16px",
      borderRadius: 0,
    },
  },
  mobileJoinWrapper: {
    width: "100%",
    padding: 16,
    paddingTop: 8,
  },
  mobileJoin: {
    width: "100%",
    borderRadius: 8,
    padding: 16,
    color: "#FFFFFF",
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0.23,
    lineHeight: "32px",
    textAlign: "center",
    overflowWrap: "anywhere",
  },
  joinBold: {
    fontWeight: "bold",
  },
}));
