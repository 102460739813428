import { useEffect, useState } from "react";
import { APIClient, generateAuthHeader, RA_SUB_PATH } from "../../lib";
import { useNotify } from "react-admin";
import { Button, Card, makeStyles } from "@material-ui/core";

export function RepManagerDashboard() {
  const notify = useNotify();
  const classes = styles();
  const [reps, setReps] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/company_employees/get_managing_reps", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setReps(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const impersonate = async repId => {
    const res = await APIClient.get(`reps/impersonate/${repId}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    const { impersonationToken } = data;
    localStorage.setItem("repImpersonationToken", impersonationToken);

    window.location.replace(`/${RA_SUB_PATH}`);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>Select a Rep to impersonate</div>
      <div className={classes.reps}>
        {reps.map(rep => {
          const { id, first_name, last_name } = rep;
          return (
            <Card key={id} className={classes.card}>
              <div className={classes.name}>
                {first_name} {last_name}
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => impersonate(id)}
              >
                Impersonate
              </Button>
            </Card>
          );
        })}
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 24,
    paddingLeft: 19,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: 24,
  },
  reps: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  card: {
    padding: 24,
    margin: "0 12px 24px 12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 100,
    width: 300,
    maxWidth: "100%",
  },
  name: {
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: 18,
  },
}));
