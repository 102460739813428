export function TwitterBlueSmallSVG() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
      <title>Twitter</title>
      <g
        id="--Student"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-114, -265)" fill="#248BEF" fillRule="nonzero">
          <g transform="translate(16, 198)">
            <g transform="translate(15, 56)">
              <g transform="translate(72, 0)">
                <g transform="translate(11, 11)">
                  <path d="M10.7142,7.62264779 L17.4162,0 L15.8286,0 L10.0068,6.6172856 L5.3604,0 L0,0 L7.0278,10.0074604 L0,18 L1.5876,18 L7.7316,11.0104246 L12.6396,18 L18,18 M2.1606,1.17142381 L4.5996,1.17142381 L15.8274,16.8861282 L13.3878,16.8861282"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
