import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from "react-admin";

const requiredField = [required()];

export default function DigitalContentCodeCreate(props) {
  return (
    <Create
      {...props}
      successMessage="Digital Content Code added successfully"
      title="Add Digital Content Code"
    >
      <SimpleForm redirect="list">
        <NumberInput
          source="digital_content_id"
          validate={requiredField}
          label="Digital Content ID"
        />
        <TextInput source="code" validate={requiredField} />
      </SimpleForm>
    </Create>
  );
}
