import React from "react";
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  makeStyles,
  Theme,
  Portal,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../state";
import { ToastTypes } from "../../types";
import { RootState } from "../../types/state";

export function Toast() {
  const dispatch = useDispatch();
  const toastType = useSelector((state: RootState) => state.ui.toastType);
  const toast = useSelector((state: RootState) => state.ui.toast);
  const classes = styles({ toastType });

  const onClose = () => {
    dispatch(uiActions.resetToast());
  };

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(toast)}
        autoHideDuration={8000}
        onClose={(_, type) => {
          if (type === "timeout") onClose();
        }}
        className={classes.root}
        classes={{ root: classes.root }}
      >
        <SnackbarContent
          classes={{ root: classes.contentRoot, message: classes.contentRoot }}
          className={classes.toast}
          message={
            <div className={classes.msgContainer}>
              {toastType === ToastTypes.success && (
                <CheckCircleOutlineIcon
                  fontSize="small"
                  className={classes.successIcon}
                />
              )}
              {toastType === ToastTypes.warn && (
                <ErrorOutlineIcon
                  fontSize="small"
                  className={classes.warnIcon}
                />
              )}
              {toast}
            </div>
          }
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={onClose}
              size="small"
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </Portal>
  );
}

type StyleProps = {
  toastType?: ToastTypes;
};
const styles = makeStyles<Theme, StyleProps>(_theme => ({
  root: {
    zIndex: 9999999,
    maxWidth: "97vw",
  },
  toast: {
    backgroundColor: ({ toastType }) =>
      toastType === ToastTypes.warn
        ? "#FDE9E9 !important"
        : toastType === ToastTypes.success
        ? "#EFFCF0 !important"
        : toastType === ToastTypes.info
        ? "rgb(50, 50, 50) !important"
        : "transparent !important",
  },
  contentRoot: {
    color: ({ toastType }) =>
      toastType === ToastTypes.success
        ? "#083D15 !important"
        : toastType === ToastTypes.warn
        ? "#5E0808 !important"
        : "#FFFFFF !important",
    maxWidth: "97vw",
  },
  msgContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    letterSpacing: 0.15,
    zIndex: 9999999,
    overflowWrap: "anywhere",
  },
  successIcon: {
    marginRight: 12,
    color: "#083D15 !important",
  },
  warnIcon: {
    marginRight: 12,
    color: "#5E0808 !important",
  },
  closeIcon: {
    color: ({ toastType }) =>
      toastType === ToastTypes.success
        ? "#083D15 !important"
        : toastType === ToastTypes.warn
        ? "#5E0808 !important"
        : "#FFFFFF !important",
  },
}));
