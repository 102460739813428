import {
  IconButton,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { useSelector } from "react-redux";
import {
  getCampaignId,
  getIsLoadingDownload,
  getVolunteerLabel,
} from "../../../state";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { useDrawerTransition } from "../../../hooks/ui";
import { useState } from "react";
import { studentPickSlipsDownload } from "../../../exports/donation/donationDownloads";

type Props = {
  onClose: () => void;
};
export function DownloadStudentPickSlips({ onClose: _onClose }: Props) {
  const classes = styles();
  const isLoading = useSelector(getIsLoadingDownload);
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const campaignId = useSelector(getCampaignId);
  const { volunteerLabelLCSingular } = useSelector(getVolunteerLabel);
  const [excludeBrochures, setExcludeBrochures] = useState(false);

  const download = () => {
    studentPickSlipsDownload(campaignId, excludeBrochures, onClose);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        <div className={classes.top}>
          <h2>Download {volunteerLabelLCSingular} pick slips</h2>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.contentWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={excludeBrochures}
                onChange={({ target }) => setExcludeBrochures(target.checked)}
              />
            }
            label="Exclude order form (brochure) orders"
          />
          <div className={classes.buttonWrapper}>
            <Button
              color="primary"
              startIcon={<GetAppOutlinedIcon />}
              disabled={isLoading}
              onClick={download}
            >
              Download pick slips
              <ButtonSpinner show={isLoading} />
            </Button>
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    borderBottom: "1px solid #DBDEEE",
    minHeight: 64,
    padding: "0 16px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      alignItems: "flex-start",
      lineHeight: "32px",
    },
  },
  contentWrapper: {
    width: "100%",
    padding: 24,
  },
  buttonWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
    marginBottom: 16,
  },
  button: {
    width: 240,
  },
}));
