import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(theme => ({
  smallInput: {
    width: 80,
  },
  medInput: {
    maxWidth: 120,
    minWidth: 120,
    width: 120,
  },
  addButton: {
    marginRight: 16,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
  drawerContent: {
    minWidth: 700,
    maxWidth: "100%",
  },
  productDrawer: {
    minWidth: 800,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  roCategories: {
    marginBottom: 24,
  },
  imageRow: {
    display: "inline-flex",
    marginRight: "1rem",
  },
  image: {
    maxWidth: 200,
    "& img": {
      maxWidth: "100%",
    },
  },
  additionalImagesCreate: {
    minWidth: 300,
  },
  productImageArr: { width: "100% !important" },
  productImageGroup: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 16,
  },
  productImageGroupItem: { marginRight: 20, minWidth: 100 },
  productImageGroupDropzone: { marginRight: 20, maxWidth: 300 },
  productImage: { maxWidth: 200 },
  thumbnail: { maxHeight: 65 },
  warning: { color: "red", fontWeight: 600 },
  suggestionAC: {
    width: 600,
    maxWidth: "100%",
  },
  fitContent: {
    width: "fit-content",
  },
  activeWarning: { color: "red", fontWeight: 600, marginTop: "-26px" },
  editForm: {
    "& .MuiPaper-root": { overflow: "visible" },
  },
  editToolbar: {
    position: "sticky",
    bottom: 0,
    zIndex: 2,
  },
}));
