export function StartSVG() {
  return (
    <svg width="269px" height="235px" viewBox="0 0 269 235">
      <title>Start</title>
      <defs>
        <path
          d="M16.8069479,29.2348248 C46.9411899,-7.60462602 101.387326,-9.63033647 134.180562,23.1742298 C166.985128,-9.63033647 221.419627,-7.60462602 251.559994,29.2348248 C277.75857,61.2673856 272.150889,108.99147 242.886952,138.260613 L154.597212,226.544841 C143.323666,237.818386 125.037763,237.818386 113.763911,226.544841 L25.4796834,138.260613 C-3.78394711,108.99147 -9.39744647,61.2673856 16.8069479,29.2348248"
          id="path-1"
        ></path>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-53, -152)">
          <g transform="translate(53, 152)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use id="Mask" fill="#FFFFFF" xlinkHref="#path-1"></use>
              <polygon
                id="Fill-3"
                fill="#E3F2FE"
                mask="url(#mask-2)"
                points="1.30770691e-05 235 260.999854 235 260.999854 0.000160783256 1.30770691e-05 0.000160783256"
              ></polygon>
              <polygon
                id="Fill-6"
                fill="#E3F2FE"
                mask="url(#mask-2)"
                points="-1.26367713 236.263577 262.263531 236.263577 262.263531 -1.26314774 -1.26367713 -1.26314774"
              ></polygon>
              <path
                d="M277.866189,166.28714 L80.801839,166.28714 C78.0008971,166.283915 75.7312795,164.014477 75.7280545,161.213757 L75.7280545,39.7094682 C75.7302045,37.6577027 77.393247,35.9947918 79.4451748,35.9923732 L279.158622,35.9923732 C281.246025,35.9947918 282.937824,37.686457 282.940243,39.7736951 L282.940243,161.213757 C282.937018,164.014477 280.667131,166.283915 277.866189,166.28714"
                id="Fill-9"
                fill="#D8D8D8"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M270.677481,159.102145 L87.0187905,159.102145 C84.3264248,159.102145 82.1360893,157.051186 82.1360893,154.530483 L82.1360893,48.7230742 C82.1360893,46.8535087 83.7593563,45.332488 85.7543085,45.332488 L271.882032,45.332488 C273.91004,45.332488 275.559914,46.8785007 275.559914,48.7789703 L275.559914,154.530483 C275.559914,157.051186 273.369578,159.102145 270.677481,159.102145"
                id="Fill-12"
                fill="#FFFFFF"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M282.869453,39.419265 L75.6567275,39.419265 L75.6567275,36.8362172 C75.6610275,34.02636 77.9389764,31.7496662 80.7493247,31.7475164 L277.777125,31.7475164 C280.587473,31.7496662 282.865422,34.02636 282.869453,36.8362172 L282.869453,39.419265 Z"
                id="Fill-15"
                fill="#BDBDBD"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M164.292859,129.360279 L262.646448,129.360279 C265.363808,129.360279 267.566506,127.157487 267.566506,124.44061 L267.566506,79.9940224 C267.566506,77.2768776 265.363808,75.0743537 262.646448,75.0743537 L164.292859,75.0743537 C161.5755,75.0743537 159.372802,77.2768776 159.372802,79.9940224 L159.372802,124.44061 C159.372802,127.157487 161.5755,129.360279 164.292859,129.360279"
                id="Fill-18"
                fill="#D8D8D8"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M251.908403,104.677205 L173.493317,104.677205 C172.134771,104.677205 171.033153,103.575943 171.033153,102.217236 C171.033153,100.858798 172.134771,99.7575358 173.493317,99.7575358 L251.908403,99.7575358 C253.266948,99.7575358 254.368566,100.858798 254.368566,102.217236 C254.368566,103.575943 253.266948,104.677205 251.908403,104.677205"
                id="Fill-21"
                fill="#FFFFFF"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M251.908403,115.131722 L173.493317,115.131722 C172.134771,115.131722 171.033153,114.03046 171.033153,112.671754 C171.033153,111.313315 172.134771,110.212054 173.493317,110.212054 L251.908403,110.212054 C253.266948,110.212054 254.368566,111.313315 254.368566,112.671754 C254.368566,114.03046 253.266948,115.131722 251.908403,115.131722"
                id="Fill-24"
                fill="#FFFFFF"
                mask="url(#mask-2)"
              ></path>
              <path
                d="M238.070454,94.2227137 L173.493451,94.2227137 C172.134637,94.2227137 171.033288,93.1214518 171.033288,91.762745 C171.033288,90.4043069 172.134637,89.303045 173.493451,89.303045 L238.070454,89.303045 C239.429,89.303045 240.530617,90.4043069 240.530617,91.762745 C240.530617,93.1214518 239.429,94.2227137 238.070454,94.2227137"
                id="Fill-27"
                fill="#FFFFFF"
                mask="url(#mask-2)"
              ></path>
            </g>
            <g id="Group-69" transform="translate(20, 35)">
              <path
                d="M65.1840587,0 C65.9118436,0 66.501707,0.589833135 66.501707,1.31758076 C66.501707,2.04532839 65.9118436,2.63516153 65.1840587,2.63516153 C64.4562738,2.63516153 63.8664105,2.04532839 63.8664105,1.31758076 C63.8664105,0.589833135 64.4562738,0 65.1840587,0"
                id="Fill-31"
                fill="#FFFFFF"
              ></path>
              <path
                d="M70.1856912,0 C70.9134761,0 71.5033394,0.589833135 71.5033394,1.31758076 C71.5033394,2.04532839 70.9134761,2.63516153 70.1856912,2.63516153 C69.4579063,2.63516153 68.868043,2.04532839 68.868043,1.31758076 C68.868043,0.589833135 69.4579063,0 70.1856912,0"
                id="Fill-33"
                fill="#FFFFFF"
              ></path>
              <path
                d="M75.1872968,0 C75.9150817,0 76.504945,0.589833135 76.504945,1.31758076 C76.504945,2.04532839 75.9150817,2.63516153 75.1872968,2.63516153 C74.4595119,2.63516153 73.8696485,2.04532839 73.8696485,1.31758076 C73.8696485,0.589833135 74.4595119,0 75.1872968,0"
                id="Fill-35"
                fill="#FFFFFF"
              ></path>
              <path
                d="M96.6164888,98.1244145 L50.8300274,98.1244145 C47.8583959,98.1209196 45.4500113,95.7129272 45.4467851,92.741179 L45.4467851,38.5547628 C45.4492048,36.3322694 47.2502506,34.5313158 49.4728578,34.5286274 L97.9094026,34.5286274 C100.167498,34.5313158 101.997311,36.3610352 102,38.6190154 L102,92.741179 C101.996505,95.7129272 99.5883892,98.1211885 96.6164888,98.1244145"
                id="Fill-37"
                fill="#FFFFFF"
              ></path>
              <path
                d="M49.4728309,35.1439196 C47.589785,35.1460703 46.0640446,36.6720015 46.0618937,38.5546822 L46.0618937,92.7413672 C46.0648511,95.3733027 48.1979303,97.5062727 50.8300005,97.5092299 L96.6164619,97.5092299 C99.2485322,97.5062727 101.381611,95.3733027 101.384569,92.7413672 L101.384569,38.6192036 C101.382418,36.7007672 99.8279103,35.1460703 97.9093757,35.1439196 L49.4728309,35.1439196 Z M96.6164619,98.1243339 L50.8300005,98.1243339 C47.858369,98.1211078 45.4499844,95.7128466 45.4467582,92.7413672 L45.4467582,38.5546822 C45.4491779,36.3324576 47.2502237,34.5312352 49.4728309,34.5288156 L97.9093757,34.5288156 C100.167472,34.5312352 101.997285,36.3612234 101.999973,38.6192036 L101.999973,92.7413672 C101.996478,95.7128466 99.5883623,98.1211078 96.6164619,98.1243339 L96.6164619,98.1243339 Z"
                id="Fill-39"
                fill="#212121"
              ></path>
              <path
                d="M73.6624974,50.2699404 C78.8822771,50.2363355 83.1409076,54.4404424 83.1745142,59.659686 C83.2081208,64.8791984 79.0037986,69.1376109 73.7840189,69.1712158 C68.5642391,69.2048207 64.3056087,65.0007137 64.2722709,59.7814701 C64.2386643,54.5619577 68.4427176,50.3035453 73.6624974,50.2699404"
                id="Fill-41"
                fill="#FD7B6F"
              ></path>
              <path
                d="M88.8647328,82.3833705 L58.5819448,82.3833705 L58.5819448,82.2868572 C58.5883972,76.4842326 63.2911728,71.7816979 69.0940945,71.7752457 L78.352583,71.7752457 C84.1555048,71.7816979 88.8582803,76.4842326 88.8647328,82.2868572 L88.8647328,82.3833705 Z"
                id="Fill-43"
                fill="#FD7B6F"
              ></path>
              <path
                d="M45.5568804,70.9054166 C43.8112183,71.7853272 41.6827096,71.083657 40.8027539,69.3378154 C40.7089242,69.1517787 40.6317635,68.9576768 40.5720781,68.7579293 L28.0050854,68.2092286 L31.8381217,62.91471 L43.1845221,64.2476146 C45.1009058,63.8107509 47.0084174,65.0100425 47.4453034,66.9260592 C47.8109434,68.5296849 47.0301945,70.1747117 45.5568804,70.9054166 Z"
                id="Fill-45"
                fill="#FFD1D2"
              ></path>
              <path
                d="M10.6661681,65.8884207 L3.7553034,52.5792688 C1.81257209,48.84805 2.16261861,44.3366599 4.6578427,40.9498241 C4.90411199,40.6213026 5.16543704,40.3016528 5.43428998,40.0000153 C7.65958572,37.5008864 10.92588,36.1868005 14.2618072,36.4481122 C17.7103838,36.6822711 20.850855,38.5162919 22.7494944,41.4044311 L26.0684839,46.4212388 C26.4080452,46.7244894 30.9763942,50.9398876 29.2769748,56.4247443 L28.9850005,61.6845826 C28.9430595,62.3865217 28.4459504,62.977699 27.7617197,63.1395402 L12.4303811,66.7011215 C11.7257175,66.8656511 10.999008,66.5309463 10.6661681,65.8884207 Z"
                id="Fill-47"
                fill="#2498FE"
              ></path>
              <path
                d="M5.81923361,91.1461294 C4.45588038,89.7452085 4.48626076,87.5041652 5.88752226,86.1408817 C6.03673563,85.9954397 6.19885395,85.8637086 6.37145754,85.7467635 L3.12236982,73.5952331 L9.32373162,75.6620686 L11.4581551,86.8847597 C12.4502224,88.5814037 11.8789099,90.7608828 10.182179,91.7528994 C8.76236669,92.5830747 6.95863235,92.3322478 5.81923361,91.1461294 Z"
                id="Fill-49"
                fill="#FFD1D2"
              ></path>
              <path
                d="M4.29604731,85.3966543 C3.83388911,85.1727112 3.51019018,84.7366541 3.42980315,84.2293546 L1.29027149,63.4760462 C1.00233,61.7909623 0.959851234,60.07308 1.16417946,58.3756295 L3.72849876,42.8464037 C3.95057128,40.9631854 5.61557751,39.5886107 7.50668906,39.7270629 C9.46286301,39.8665904 10.9356394,41.5653851 10.7961047,43.5217277 C10.7923408,43.5754956 10.7872326,43.6295322 10.7807801,43.6833001 L8.6872223,61.4976587 L11.0848528,82.5085151 C11.1609382,83.1542668 10.8323999,83.7803932 10.2578611,84.084988 L5.72231213,85.3802551 C5.27709167,85.614414 4.74664482,85.6205973 4.29604731,85.3966543 Z"
                id="Fill-51"
                fill="#2498FE"
              ></path>
              <polygon
                id="Fill-53"
                fill="#FFD1D2"
                points="38.6558826 148.684916 42.8959622 147.881087 41.8119472 131.144774 35.5543951 132.331699"
              ></polygon>
              <path
                d="M37.3118868,147.505815 L45.6619212,145.922352 L45.6621901,145.922352 C48.6010215,145.365048 51.4352691,147.295314 51.9926013,150.233994 C51.9926013,150.234263 51.9926013,150.234263 51.9926013,150.234263 L52.0254013,150.407396 L38.3539607,152.999812 L37.3118868,147.505815 Z"
                id="Fill-55"
                fill="#212121"
              ></path>
              <polygon
                id="Fill-57"
                fill="#FFD1D2"
                points="1.13549286 148.816916 5.45112017 148.816647 7.50408118 132.17147 1.1346863 132.172008"
              ></polygon>
              <path
                d="M0.0347013987,147.407876 L8.53368038,147.407608 L8.53394924,147.407608 C11.525207,147.407339 13.9499916,149.831999 13.9499916,152.823104 L13.9499916,152.823373 L13.9499916,152.999462 L0.0349702516,153 L0.0347013987,147.407876 Z"
                id="Fill-59"
                fill="#212121"
              ></path>
              <path
                d="M13.7623592,15.4244591 C18.5371873,15.4244591 22.4081318,19.2952054 22.4081318,24.0697891 C22.4081318,28.8443727 18.5371873,32.715119 13.7623592,32.715119 C8.98753102,32.715119 5.11658646,28.8443727 5.11658646,24.0697891 C5.11658646,19.2952054 8.98753102,15.4244591 13.7623592,15.4244591"
                id="Fill-61"
                fill="#FFD1D2"
              ></path>
              <path
                d="M0.424941434,142.127203 C0.124632705,141.803521 -0.0278069087,141.369345 0.00418659053,140.928987 L5.88910849,87.5138703 C5.83829528,87.0135606 5.03926436,77.9440024 11.524266,66.3392908 L11.5602923,66.2750383 L28.9381395,61.456365 L30.8596314,69.5615971 L43.7371493,140.034021 C43.8331298,140.880596 43.2403091,141.650282 42.397724,141.774217 L37.9154078,142.446584 C37.1325081,142.563798 36.3832149,142.085264 36.1603359,141.325525 L20.9843942,93.7963728 C20.9032006,93.5162424 20.610151,93.3552077 20.3300062,93.4363971 C20.1579403,93.4864012 20.0229761,93.6200143 19.9713564,93.7915337 L7.52158351,141.489517 C7.32263234,142.164572 6.70400173,142.628857 6.0004136,142.631545 L1.58369758,142.631545 C1.14439189,142.631545 0.72498131,142.448197 0.424941434,142.127203"
                id="Fill-63"
                fill="#212121"
              ></path>
              <path
                d="M37.1529409,69.9432143 L18.4286782,66.732737 C15.9374869,66.3146921 13.9581916,64.4107731 13.4436071,61.9379904 L9.53367888,43.473041 C8.95752704,40.7515821 10.6964678,38.0782454 13.4180661,37.502123 C13.6936403,37.4437849 13.9737851,37.408567 14.2552741,37.3970069 C16.8790099,37.3018379 19.140332,39.2280705 19.4634932,41.8333909 L21.4255819,58.2919936 L37.9436374,62.5837421 C38.5840451,62.8041902 39.0134032,63.4077341 39.0117946,64.08494 L39.0047999,68.3860979 C39.0034557,69.260094 38.2936839,69.9676787 37.419643,69.9663364 C37.330115,69.9663364 37.2411247,69.9585381 37.1529409,69.9432143 Z"
                id="Fill-65"
                fill="#2498FE"
              ></path>
              <path
                d="M21.116885,22.062824 C22.4931431,21.7783922 23.8312242,20.862726 24.1987462,19.5064324 C24.5200254,18.3216582 24.0449623,17.0441344 23.3010462,16.0674417 C21.3056197,13.5809483 17.8739809,12.7714735 14.9776282,14.1043781 C15.1104415,13.4056651 14.5851029,12.7163615 13.9382427,12.4203696 C13.2687989,12.1733064 12.5531124,12.0773308 11.8419964,12.138895 C9.59277275,12.1703491 7.19809966,12.2456241 5.33817505,13.5110501 C3.85518226,14.5200035 2.91365928,16.1792788 2.42569121,17.9052263 C1.93799198,19.6314426 1.85814266,21.4426121 1.78151957,23.234694 C1.64709311,26.375542 2.87763299,32.9860283 2.87763299,32.9860283 C2.87763299,32.9860283 7.2298243,34.1823626 9.13626047,34.7367089 C11.1851887,35.3321877 14.6418309,35.9279353 16.6907591,36.5236829 C17.2150223,36.6761147 17.9172662,36.7616055 18.1807421,36.2833407 C18.3912539,35.9015891 18.147942,35.4364973 17.9032858,35.0757151 C16.8259921,33.4866068 15.5919571,31.9880973 14.7630835,30.2565042 C13.9344788,28.5249111 13.546524,26.467485 14.2581777,24.6845436 C14.9695626,22.9016022 19.7406268,22.3472559 21.116885,22.062824"
                id="Fill-67"
                fill="#212121"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
