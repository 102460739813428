import {
  makeStyles,
  FormControlLabel,
  Tooltip,
  RadioGroup,
  Radio,
  Checkbox,
} from "@material-ui/core";
import { useField } from "react-final-form";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { getVolunteerLabel } from "../../../state";
import { RootState } from "../../../types/state";

export function DuplicateEmailOptions() {
  const classes = styles();
  const { volunteerLabel, volunteerLabelLCSingular } = useSelector(
    getVolunteerLabel,
  );
  const {
    allow_associated_volunteer_accounts: allowAssocAccounts,
  } = useSelector(({ campaign }: RootState) => campaign);
  const canDupeInput = useField("can_duplicate_email").input;
  const canDupe = useField("can_duplicate_email").input.value;
  const associateAccountsField = useField("associate_shared_emails").input;
  const associateAccounts = useField("associate_shared_emails").input.value;

  const onCanDupeChange = (target: EventTarget & HTMLInputElement) => {
    const checked = target.checked;
    canDupeInput.onChange(checked);
    associateAccountsField.onChange(checked ? true : "");
  };

  return (
    <div className={classes.container}>
      <div>
        <Checkbox
          className={classes.checkbox}
          onChange={({ target }) => onCanDupeChange(target)}
        />
      </div>

      <div>
        <div className={classes.checkboxLabel}>
          Allow this account to use an email address that is already registered
          by another {volunteerLabelLCSingular}{" "}
          <Tooltip
            title={
              <div>
                This should only be allowed where multiple{" "}
                {volunteerLabelLCSingular} accounts must share a common email
                address (e.g. parent/guardian).
                <div>
                  {volunteerLabel} should not be allowed to create duplicate
                  accounts.
                </div>
              </div>
            }
            classes={{ tooltip: classes.tooltip }}
          >
            <span
              onClick={e => e.preventDefault()}
              className={classes.dupeInfoIcon}
            >
              <InfoIcon onClick={e => e.preventDefault()} />
            </span>
          </Tooltip>
        </div>
        <div className={classes.dupeMsg}>
          {volunteerLabel} cannot use an email address that is in use by an
          admin user.
        </div>

        {allowAssocAccounts && canDupe && (
          <Fragment>
            <div className={classes.optionsTitle}>Select an option</div>
            <RadioGroup
              value={associateAccounts}
              onChange={({ target }) => {
                const val = target.value;
                associateAccountsField.onChange(val === "false" ? false : true);
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={
                  <div>
                    Link this account with other accounts sharing this email
                    address
                    <div className={classes.radioSubText}>
                      All linked user accounts can be accessed from any linked
                      user login.
                    </div>
                  </div>
                }
                classes={{ label: classes.radioLabel, root: classes.radioRoot }}
              />
              <div className={classes.spacer} />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={
                  <div>
                    Do NOT link this account with other accounts sharing this
                    email address
                    <div className={classes.radioSubText}>
                      This user account will not be accessible by logging into
                      accounts sharing this email address.
                    </div>
                  </div>
                }
                classes={{ label: classes.radioLabel, root: classes.radioRoot }}
              />
            </RadioGroup>
          </Fragment>
        )}
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    marginBottom: 24,
  },
  checkbox: {
    marginTop: -6,
  },
  checkboxLabel: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
    maxWidth: 586,
  },
  dupeInfoIcon: {
    display: "inline-flex",
    verticalAlign: "bottom",
    color: theme.palette.text.secondary2,
    cursor: "pointer",
    marginLeft: 6,
  },
  tooltip: {
    borderRadius: 4,
    backgroundColor: "#1B47AA",
    color: "#FFFFFF",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "15px",
    width: 220,
    padding: 8,
  },
  dupeMsg: {
    fontSize: 14,
    letterSpacing: 0.13,
    color: theme.palette.text.secondary,
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "22px",
    },
  },
  optionsTitle: {
    marginTop: 24,
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
  },
  radioLabel: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  radioRoot: {
    alignItems: "flex-start",
  },
  radioSubText: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.13,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginTop: 2,
  },
  spacer: {
    height: 16,
  },
}));
