import React, { useState } from "react";
import { Notification } from "react-admin";
import { Paper, makeStyles, ButtonGroup, Button } from "@material-ui/core";
import {
  donationReceiptConfigCampaignTypes,
  donationReceiptConfigLevels,
} from "../../lib";
import { DonationCampaignReceiptTemplate } from "./DonationCampaignReceiptTemplate";
import { ProductCampaignReceiptTemplate } from "./ProductCampaignReceiptTemplate";

export function CompanyDonationReceiptConfigs() {
  const classes = styles();
  const [campaignType, setCampaignType] = useState(
    donationReceiptConfigCampaignTypes.PRODUCT,
  );
  return (
    <Paper className={classes.paper}>
      <ButtonGroup>
        <Button
          variant={
            campaignType === donationReceiptConfigCampaignTypes.PRODUCT
              ? "contained"
              : "outlined"
          }
          color="primary"
          onClick={() =>
            setCampaignType(donationReceiptConfigCampaignTypes.PRODUCT)
          }
        >
          Product
        </Button>
        <Button
          variant={
            campaignType === donationReceiptConfigCampaignTypes.DONATION
              ? "contained"
              : "outlined"
          }
          color="primary"
          onClick={() =>
            setCampaignType(donationReceiptConfigCampaignTypes.DONATION)
          }
        >
          Donation
        </Button>
      </ButtonGroup>
      {campaignType === donationReceiptConfigCampaignTypes.DONATION ? (
        <DonationCampaignReceiptTemplate
          urlSuffix={`${donationReceiptConfigLevels.COMPANY}?campaign_type=${donationReceiptConfigCampaignTypes.DONATION}`}
        />
      ) : (
        <ProductCampaignReceiptTemplate
          urlSuffix={`${donationReceiptConfigLevels.COMPANY}?campaign_type=${donationReceiptConfigCampaignTypes.PRODUCT}`}
        />
      )}

      <Notification />
    </Paper>
  );
}

const styles = makeStyles(_theme => ({
  paper: {
    minHeight: 300,
    padding: 24,
  },
}));
