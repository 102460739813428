import React, { useRef, useEffect } from "react";
import { useNotify, Notification } from "react-admin";
import { generateAuthHeader, APIUtilClient, downloadStatuses } from "../lib";

export function ReportDownloader({ downloadId, setLoading }) {
  const notify = useNotify();
  const intervalRef = useRef(null);
  const attemptsRef = useRef(1);

  useEffect(() => {
    if (downloadId) fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadId]);

  const reset = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    attemptsRef.current = 1;
    setLoading(false);
  };

  const fetchReport = async () => {
    intervalRef.current = setInterval(() => {
      if (attemptsRef.current > 20) {
        notify(
          "This report is taking time to generate, please go to the `Downloads` tab in a few minutes to retrieve it.",
          "warning",
        );
        reset();
        return;
      }
      attemptsRef.current = attemptsRef.current + 1;
      getReportData();
    }, 3000);
  };

  const getReportData = async () => {
    const res = await APIUtilClient.get(`/downloads/${downloadId}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) {
      reset();
      notify(errorMessage, "warning");
      return;
    }

    const { status, signedUrl } = data;
    if (status === downloadStatuses.FAILED) {
      reset();
      notify("There was an error generating your report.", "warning");
    } else if (status === downloadStatuses.COMPLETE) {
      reset();
      window.open(signedUrl, "_blank");
    }
  };

  return <Notification />;
}
