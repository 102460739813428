// cSpell:ignore Swipeable
import {
  MenuItem,
  SwipeableDrawer,
  TextField,
  makeStyles,
  IconButton,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { useEffect, useState } from "react";
import { ListProps } from "../../types";
import { useDrawerTransition } from "../../hooks/ui";
import classNames from "classnames";

type Props = {
  onClose: () => void;
  listProps: ListProps;
  sortFields: { field: string; label: string }[];
};

export function MobileListSort({
  onClose: _onClose,
  listProps,
  sortFields,
}: Props) {
  const { params, setParams } = listProps;
  const classes = styles();
  const [sort, setSort] = useState("");
  const [prevSort, setPrevSort] = useState("");
  const [order, setOrder] = useState("asc");
  const [prevOrder, setPrevOrder] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  useEffect(() => {
    setPrevSort((params.sort as string) || "");
    setSort((params.sort as string) || "");
    setPrevOrder((params.order as string) || "");
    setOrder((params.order as string) || "");
  }, [params]);

  useEffect(() => {
    setHasChanges(Boolean(sort !== prevSort || order !== prevOrder));
  }, [sort, prevSort, order, prevOrder]);

  const onApply = () => {
    setParams((prev: any) => {
      return { ...prev, page: 0, sort, order };
    });
    onClose();
  };

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={onClose}
      onOpen={() => {}}
      classes={{ paper: classes.drawer }}
      transitionDuration={500}
      anchor="bottom"
    >
      <div className={classes.wrapper}>
        <div className={classes.top}>
          <div className={classes.close}>
            <IconButton onClick={onClose}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </div>
          <h2 className={classes.title}>Sort by</h2>
        </div>

        <div className={classes.main}>
          {sortFields.map(({ label, field }) => {
            const active = field === sort;
            return (
              <div
                key={field}
                className={classNames(classes.row, active && classes.activeRow)}
                onClick={() => setSort(field)}
              >
                <div>{label}</div>
                {active && <CheckIcon className={classes.check} />}
              </div>
            );
          })}
          <div className={classes.bottomSpacer} />
        </div>

        <div className={classes.bottom}>
          <div className={classes.select}>
            <TextField
              value={order}
              select
              onChange={({ target }) => setOrder(target.value)}
              fullWidth
              InputProps={{
                startAdornment:
                  order === "asc" ? (
                    <ArrowUpwardIcon
                      fontSize="small"
                      className={classes.directionIcon}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      fontSize="small"
                      className={classes.directionIcon}
                    />
                  ),
              }}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </TextField>
          </div>
          <Button
            color="primary"
            className={classes.button}
            onClick={onApply}
            disabled={!hasChanges}
          >
            Apply
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
}

const styles = makeStyles(_theme => ({
  drawer: {
    top: 75,
    borderRadius: "20px 20px 0 0",
    maxHeight: "unset",
  },
  wrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  top: {
    position: "sticky",
    top: 0,
    padding: "12px 12px 16px 16px",
    zIndex: 9,
    backgroundColor: "#FFFFFF",
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  main: {
    flex: 1,
    width: "100%",
  },
  row: {
    height: 52,
    width: "100%",
    borderTop: "1px solid #EAEBF3",
    padding: "0 24px 0 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
    letterSpacing: 0.11,
  },
  activeRow: {
    backgroundColor: "#EEF6FE",
  },
  check: {
    color: "#1AC846",
  },
  bottomSpacer: {
    borderTop: "1px solid #EAEBF3",
    marginBottom: 48,
  },
  bottom: {
    borderTop: "1px solid #EAEBF3",
    position: "sticky",
    bottom: 0,
    padding: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 9,
    backgroundColor: "#FFFFFF",
  },
  select: {
    marginBottom: 16,
    width: "100%",
  },
  directionIcon: {
    marginRight: 16,
  },
  button: {
    width: 300,
    maxWidth: "100%",
  },
}));
