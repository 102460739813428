export const giveawayDataPointOptions = [
  "Items sold",
  "Dollar amount raised",
  "Sale count",
  "Contacts",
  "Contacts with confirmed emails",
];

export const giveawayTierSortFields = [
  { field: "giveaway_name", label: "Giveaway name" },
  { field: "min_threshold", label: "Minimum threshold" },
  { field: "sort_order", label: "Tier sort order" },
];

export const giveawayTimezoneOptions = [
  { name: "Eastern", value: "US/Eastern" },
  { name: "Central", value: "US/Central" },
  { name: "Mountain", value: "America/Denver" },
  { name: "Pacific", value: "US/Pacific" },
];

export const giveawayTimezoneValueNameMapping = {
  "US/Eastern": "Eastern",
  "US/Central": "Central",
  "America/Denver": "Mountain",
  "US/Pacific": "Pacific",
};

export const giveawayReportSortFields = [
  { field: "created_at", label: "Generated on" },
  { field: "id", label: "ID" },
  { field: "start_date", label: "Start date" },
  { field: "end_date", label: "End date" },
  { field: "marked_given_out", label: "Marked given out" },
];
