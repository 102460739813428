import { Fragment, useState } from "react";
import { format } from "date-fns";
import { IconButton, makeStyles, Collapse } from "@material-ui/core";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps;
};
export function EventTicketRows({ listProps }: Props) {
  const { rows, params, setParams, nextPage, paginationProps } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Ticket ID"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Event"
                fieldName="event_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Order ID"
                fieldName="donation_id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Customer name"
                fieldName="customerName"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Customer email"
                fieldName="customerEmail"
                params={params}
                setParams={setParams}
              />
              <div>Ticket code</div>
              <SortableColumnHeader
                columnName="Used"
                fieldName="used"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Used timestamp"
                fieldName="used_date"
                params={params}
                setParams={setParams}
              />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return <Row key={row.id as number} row={row} lastRow={lastRow} />;
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Tickets per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
};
function Row({ row, lastRow }: RowProps) {
  const {
    customerEmail,
    customerName,
    donation_id,
    event_name,
    id,
    ticket_code,
    used,
    used_date,
  } = row;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [expand, setExpanded] = useState(false);
  const toggleExpand = () => setExpanded(prev => !prev);

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{`#${id}`}</div>
            <div>{event_name}</div>
            <div>{donation_id}</div>
            <div>{customerName}</div>
            <div>{customerEmail}</div>
            <div>{ticket_code}</div>
            <div>
              {used ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>
              {used_date &&
                format(new Date(used_date as string), "M/d/yyyy',' pp")}
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileVisibleContentWrapper}>
              <div className={classes.expandBtn}>
                <IconButton onClick={toggleExpand} size="small">
                  {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
              <div className={classes.mobileVisibleContent}>
                <div>{event_name}</div>
                <div className={classes.customer}>Customer: {customerName}</div>
                <div className={classes.used}>
                  Used:&nbsp;&nbsp;
                  {used &&
                    used_date &&
                    format(new Date(used_date as string), "M/d/yyyy',' pp")}
                  {!used && (
                    <CloseIcon className={classes.x} fontSize="small" />
                  )}
                </div>
              </div>
            </div>
            <Collapse
              in={expand}
              timeout="auto"
              classes={{ root: classes.collapse }}
            >
              <div className={classes.expand}>
                <div className={classes.expandRow}>
                  <span className={classes.expandLabel}>Ticket code:</span>
                  {ticket_code}
                </div>
                <div className={classes.expandRow}>
                  <span className={classes.expandLabel}>Order ID:</span>
                  {donation_id}
                </div>
                <div className={classes.expandRow}>
                  <span className={classes.expandLabel}>Customer email:</span>
                  {customerEmail}
                </div>
              </div>
            </Collapse>
          </Fragment>
        )}
      </div>
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "112px 1fr 120px 1fr 1fr 106px 90px 186px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 0,
      letterSpacing: 0.1,
      lineHeight: "19px",
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mobileVisibleContentWrapper: {
    display: "flex",
    padding: "16px 16px 16px 8px",
  },
  expandBtn: {
    marginTop: 8,
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  mobileVisibleContent: {
    paddingLeft: 8,
  },
  customer: {
    paddingTop: 8,
    paddingBottom: 8,
    fontWeight: 500,
    letterSpacing: 0.1,
  },
  used: {
    display: "flex",
    alignItems: "center",
  },
  collapse: {
    width: "100%",
    maxWidth: "100%",
  },
  expand: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "#EAEBF3",
    padding: 16,
    paddingBottom: 8,
  },
  expandRow: {
    paddingBottom: 6,
    color: theme.palette.text.secondary,
    lineHeight: "19px",
  },
  expandLabel: {
    display: "inline-flex",
    width: "fit-content",
    minWidth: "fit-content",
    fontWeight: 500,
    paddingRight: 8,
  },
}));
