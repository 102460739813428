import React, { useState } from "react";
import { Button } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import {
  APIUtilClient,
  generateAuthHeader,
  downloadXlsx,
  filterObjToQueryStr,
} from "../../../lib";

export function CampaignsExport({ season_id, filterValues = {} }) {
  const [submitting, setSubmitting] = useState(false);

  const handleClick = async () => {
    setSubmitting(true);
    const queryParams = filterObjToQueryStr({
      season_id: season_id ? season_id : "",
      ...filterValues,
    });
    const res = await APIUtilClient.get(
      `/xlsx_reports/campaigns_report?${queryParams}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    downloadXlsx(res, "Campaigns.XLSX");
    setTimeout(() => setSubmitting(false), 2000);
  };

  return (
    <Button
      onClick={handleClick}
      disabled={submitting}
      color="primary"
      startIcon={<DownloadIcon />}
      size="small"
    >
      export
    </Button>
  );
}
