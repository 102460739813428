import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import { APIRes, GuidedSetupScreen } from "../../../types";
import { APIAuthClient } from "../../../lib";
import { setToast, updateVolunteerSetupData } from "../../../state";
import { TextOptInSVG } from "../SVGs/TextOptInSVG";
import { sharedStyles } from "../shared.styles";
import { RootState } from "../../../types/state";

export function TextOptIn(props: GuidedSetupScreen) {
  const {
    activeScreen,
    setDisableNext,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setSubmitting,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const currOptedIn = useSelector(
    (state: RootState) => state.volunteerSetup.agreed_to_receive_texts,
  );
  const { campaignId, company_frontend_url } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const [optedIn, setOptedIn] = useState(false);

  useEffect(() => {
    if (activeScreen === "TEXT_AGREEMENT") {
      setOptedIn(currOptedIn ? currOptedIn : false);
    }
  }, [activeScreen, currOptedIn]);

  useEffect(() => {
    if (activeScreen === "TEXT_AGREEMENT") {
      if (!optedIn) setDisableNext(true);
      else setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen, optedIn]);

  useEffect(() => {
    if (nextTriggeredBy === "TEXT_AGREEMENT") {
      submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  const submit = useMemo(
    () => async () => {
      setSubmitting(true);
      const url = `/volunteers/agree_to_receive_texts?campaign_id=${campaignId}`;
      const res = await APIAuthClient.put<any, APIRes>(url, {});
      const { error, errorMessage } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      dispatch(updateVolunteerSetupData({ agreed_to_receive_texts: true }));
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [optedIn, campaignId],
  );

  if (activeScreen !== "TEXT_AGREEMENT") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <TextOptInSVG />
      <h1 className={sharedClasses.screenHeader}>Stay on top of your game</h1>
      <p className={sharedClasses.screenParagraph}>
        Opt in to receive convenient fundraiser reminders via text message
      </p>

      <div className={classes.contentSpacer} />

      <FormControlLabel
        classes={{ root: classes.formControl }}
        control={
          <Checkbox
            checked={optedIn}
            onChange={() => setOptedIn(prev => !prev)}
            color="primary"
            classes={{ root: classes.checkbox }}
          />
        }
        label={
          <div>
            <div>I agree to receive account related text messages</div>
            <div className={classes.textLegal}>
              Message and data rates may apply. Reply STOP to opt out. Reply
              HELP for assistance. Frequency varies. Subject to{" "}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={`${company_frontend_url || ""}/privacy`}
                target="_blank"
                className={classes.privacyLink}
              >
                Privacy Policy
              </a>
              .
            </div>
          </div>
        }
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  contentSpacer: {
    paddingBottom: 72,
  },
  formControl: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  checkbox: {
    marginTop: -9,
  },
  textLegal: {
    fontSize: 12,
    letterSpacing: 0.11,
    lineHeight: "18px",
    paddingTop: 4,
  },
  privacyLink: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },
}));
