import React from "react";
import RichTextInput from "ra-input-rich-text";

export function CustomRichText({ label, source, ...rest }) {
  // font-family is set to "Graphik Web" in quilljs.css so it should look the way it is displayed on the main site
  return (
    <RichTextInput
      source={source}
      label={`${label} (Do not leave any empty lines on the top or bottom of text)`}
      toolbar={[
        ["bold", "italic", "underline", "strike"],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, false] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["link"],
        ["clean"],
      ]}
      {...rest}
    />
  );
}
