import { makeStyles, Tabs, Tab, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CircularLoader, ResponsiveModal, Toast } from "../../../components";
import { useDrawerTransition } from "../../../hooks/ui";
import {
  APIRes,
  GenericObject,
  discountCardConfigLevels,
} from "../../../types";
import { Fragment, useEffect, useRef, useState } from "react";
import { EditCard } from "./EditCard";
import { Offers } from "../offer/Offers";
import { OfferOrdering } from "../offer/OfferOrdering";
import { APIAuthClient } from "../../../lib";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { GenerateCards } from "./GenerateCards";
enum Screens {
  EditCard = "EditCard",
  Offers = "Offers",
  Ordering = "Ordering",
  Generate = "Generate",
}

type Props = {
  id: any;
  onClose: () => void;
  refreshFunc?: () => any;
  isCampaignCard?: boolean;
};
export function DiscountCardDetails({
  onClose: _onClose,
  refreshFunc,
  id,
  isCampaignCard = false,
}: Props) {
  const dispatch = useDispatch();
  const { isOpen, onClose: closeFunc } = useDrawerTransition(_onClose);
  const classes = styles();
  const needsRefreshRef = useRef(false);
  const [activeTab, setActiveTab] = useState<Screens>(Screens.EditCard);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState<GenericObject>({});
  const { config_level } = record;

  useEffect(() => {
    const fetch = async () => {
      const url = `/discount_cards/${id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        return dispatch(setToast(errorMessage));
      }
      setRecord(data);
      setLoading(false);
    };
    if (id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onClose = () => {
    if (needsRefreshRef.current && refreshFunc) refreshFunc();
    closeFunc();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} fullHeight>
      <div className={classes.wrapper}>
        <div className={classes.top}>
          <div className={classes.titleRow}>
            <div className={classes.title}>
              Edit {isCampaignCard ? "campaign card" : "card template"}
            </div>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
          >
            <Tab
              label={
                isCampaignCard ? "campaign Card details" : "template details"
              }
              value={Screens.EditCard}
              className={classes.tab}
              disabled={loading}
            />
            <Tab
              label="Offers"
              value={Screens.Offers}
              className={classes.tab}
              disabled={loading}
            />
            <Tab
              label="Sort order"
              value={Screens.Ordering}
              className={classes.tab}
              disabled={loading}
            />
            {config_level === discountCardConfigLevels.CAMPAIGN_PRODUCT && (
              <Tab
                label="Generate cards"
                value={Screens.Generate}
                className={classes.tab}
                disabled={loading}
              />
            )}
          </Tabs>
        </div>
        <div className={classes.content}>
          <CircularLoader show={loading} />

          {!loading && (
            <Fragment>
              {activeTab === Screens.EditCard && (
                <EditCard
                  record={record}
                  setRecord={setRecord}
                  needsRefreshRef={needsRefreshRef}
                  isCampaignCard={isCampaignCard}
                />
              )}
              {activeTab === Screens.Offers && <Offers cardRecord={record} />}
              {activeTab === Screens.Ordering && (
                <OfferOrdering discountCardId={id} />
              )}
              {activeTab === Screens.Generate && (
                <GenerateCards record={record} />
              )}
            </Fragment>
          )}
        </div>
      </div>
      <Toast />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: 1200,
    maxWidth: "100%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  top: {
    position: "sticky",
    top: 0,
    borderBottom: "1px solid #DBDEEE",
    backgroundColor: "#FFFFFF",
    zIndex: 9,
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    height: 64,
    alignItems: "center",
    paddingLeft: 24,
    paddingRight: 18,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 12,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  tab: {
    maxWidth: "fit-content",
    minWidth: "fit-content",
    paddingLeft: 24,
    paddingRight: 24,
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
}));
