import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  BooleanInput,
  DateInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

const requiredField = [required()];

export default function CompanySeasonEdit(props) {
  const classes = styles();

  const transformDataForSubmit = values => {
    const {
      season_name,
      is_default,
      distributor_min_campaign_end_date,
      distributor_max_campaign_end_date,
    } = values;
    return {
      season_name,
      is_default,
      distributor_min_campaign_end_date,
      distributor_max_campaign_end_date,
    };
  };

  return (
    <Edit
      {...props}
      undoable={false}
      successMessage="Season updated successfully"
      title="Edit Season"
      transform={transformDataForSubmit}
    >
      <SimpleForm
        redirect="list"
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled />
        <TextInput source="season_name" validate={requiredField} label="Name" />
        <BooleanInput source="is_default" label="Set As Default" />
        <Fragment>
          <div className={classes.warning}>
            These dates are only applicable to suppliers who service other
            distributors on Instaraise for season management of distributors'
            campaigns.
          </div>
          <div className={classes.warning}>
            They do NOT determine your own company's seasons.
          </div>
        </Fragment>
        <DateInput
          source="distributor_min_campaign_end_date"
          label="Distributor Min Campaign End Date"
          className={classes.date}
        />
        <DateInput
          source="distributor_max_campaign_end_date"
          label="Distributor Max Campaign End Date"
          className={classes.date}
        />
      </SimpleForm>
    </Edit>
  );
}

const styles = makeStyles(_theme => ({
  date: { width: 265 },
  warning: { color: "red" },
}));
