export function ConvertSupportersSVG() {
  return (
    <svg width="70px" height="76px" viewBox="0 0 70 76">
      <title>Convert Supporters</title>
      <defs>
        <polygon points="0 0 25.3575444 0 25.3575444 4.17752106 0 4.17752106"></polygon>
        <polygon points="0 0 37.896 0 37.896 75.9996 0 75.9996"></polygon>
        <polygon points="0 0 9.6199 0 9.6199 10.4474518 0 10.4474518"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-292, -104)">
          <g transform="translate(292, 104)">
            <path
              d="M52.0185,13.8191 C55.8775,10.9681 61.3105,9.8761 65.5615,12.0991 C60.7755,14.1771 56.8795,17.8811 54.5615,22.5551 C53.6745,24.3611 52.8755,26.4621 51.0445,27.2971 C49.9055,27.8161 48.5605,27.7161 47.3805,27.2961 C46.2015,26.8771 45.1515,26.1651 44.1175,25.4601 L43.8065,25.4221 C45.6485,20.9921 48.1595,16.6701 52.0185,13.8191"
              fill="#BCDFFE"
            ></path>
            <path
              d="M65.5273,12.1942 C61.5583,12.3182 57.7293,13.6902 54.5843,16.1152 C53.8973,16.6252 53.2963,17.2392 52.8013,17.9372 C52.3413,18.6322 52.0753,19.4372 52.0313,20.2692 C51.9753,21.0442 52.0073,21.8442 51.7643,22.5922 C51.5003,23.3622 50.9203,23.9842 50.1693,24.3002 C49.2543,24.7132 48.2463,24.7732 47.2573,24.7982 C46.1603,24.8252 45.0193,24.8362 44.0213,25.3532 C43.9003,25.4152 43.8103,25.2242 43.9313,25.1612 C45.6673,24.2622 47.6973,24.8492 49.5223,24.3142 C50.3743,24.0642 51.1553,23.5562 51.4973,22.7062 C51.7963,21.9632 51.7643,21.1392 51.8143,20.3542 C51.8443,19.5412 52.0733,18.7492 52.4813,18.0452 C52.9393,17.3252 53.5163,16.6882 54.1873,16.1612 C55.6803,14.9522 57.3543,13.9842 59.1473,13.2942 C61.1903,12.4962 63.3543,12.0522 65.5463,11.9831438 C65.6813,11.9792 65.6623,12.1902 65.5273,12.1942 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M55.5507,15.2746 C54.8587,14.0706 55.1407,12.5426 56.2167,11.6646 C56.3227,11.5796 56.4607,11.7396 56.3547,11.8256 C55.3507,12.6406 55.0907,14.0666 55.7417,15.1846 C55.8107,15.3016 55.6187,15.3916 55.5507,15.2746 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M52.0085,19.8725 C53.3325,20.2425 54.7465,20.0995 55.9705,19.4735 C56.0915,19.4115 56.1815,19.6025 56.0605,19.6645 C54.7865,20.3145 53.3135,20.4595 51.9375,20.0715 C51.8055,20.0345 51.8785,19.8355 52.0085,19.8725 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M61.18,12.776 C61.348,13.289 61.763,13.683 62.283,13.825 C62.415,13.86 62.343,14.059 62.212,14.024 C61.638,13.865 61.179,13.431 60.989,12.866 C60.966,12.813 60.988,12.752 61.039,12.726 C61.092,12.701 61.155,12.723 61.18,12.776 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M68.6821,27.932 C67.5681,27.672 66.4341,27.503 65.2921,27.427 C65.2041,27.419 65.1141,27.413 65.0261,27.408 C62.2731,27.254 59.5151,27.612 56.8931,28.464 C55.8491,28.804 54.8331,29.223 53.8541,29.717 C52.5011,30.4 51.0881,31.247 49.6321,31.437 C49.4801,31.459 49.3271,31.471 49.1741,31.474 L43.7851,25.804 C43.7801,25.785 43.7721,25.767 43.7671,25.748 L43.5411,25.531 C43.5901,25.502 43.6411,25.473 43.6901,25.444 C43.7181,25.427 43.7471,25.412 43.7761,25.395 C43.7951,25.384 43.8141,25.373 43.8311,25.362 C43.8371,25.358 43.8431,25.355 43.8491,25.352 C43.8651,25.341 43.8821,25.333 43.8971,25.323 C44.1831,25.16 44.4701,24.999 44.7591,24.839 C46.9681,23.627 49.2931,22.589 51.7011,21.937 C51.7741,21.917 51.8471,21.896 51.9221,21.879 C53.0101,21.592 54.1191,21.393 55.2381,21.286 C55.8521,21.229 56.4681,21.204 57.0831,21.209 C58.6751,21.227 60.2531,21.494 61.7621,22.002 C64.7681,23.015 67.4171,24.979 68.8261,27.775 C68.8621,27.847 68.8971,27.917 68.9321,27.99 C68.8491,27.97 68.7661,27.95 68.6821,27.932 Z"
              fill="#BCDFFE"
            ></path>
            <g transform="translate(43.6725, 24.3745)">
              <mask fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g></g>
              <path
                d="M25.1755444,3.67115912 C21.9315444,1.38115912 18.0485444,0.171159122 14.0775444,0.213159122 C13.2225444,0.207159122 12.3715444,0.335159122 11.5565444,0.594159122 C10.7715444,0.872159122 10.0745444,1.35515912 9.53854445,1.99315912 C9.02654445,2.57815912 8.57154445,3.23615912 7.92654445,3.68615912 C7.25154445,4.14315912 6.41454445,4.29015912 5.62454445,4.09015912 C4.64554445,3.87015912 3.80354445,3.31015912 2.99954445,2.73515912 C2.10754445,2.09615912 1.19054445,1.41815912 0.0815444488,1.22915912 C-0.0524555512,1.20715912 -0.00945555124,1.00015912 0.124544449,1.02215912 C2.05254445,1.35015912 3.31954445,3.04015912 5.09954445,3.71215912 C5.92954445,4.02515912 6.85954445,4.09015912 7.64454445,3.61815912 C8.33054445,3.20415912 8.80054445,2.52715912 9.31354445,1.93015912 C9.82754445,1.29915912 10.4875444,0.805159122 11.2365444,0.488159122 C12.0355444,0.189159122 12.8795444,0.0281591225 13.7325444,0.0111591225 C15.6535444,-0.0558408775 17.5715444,0.179159122 19.4195444,0.708159122 C21.5305444,1.30015912 23.5255444,2.24915912 25.3175444,3.51415912 C25.4285444,3.59215912 25.2855444,3.74915912 25.1755444,3.67115912 Z"
                fill="#FFFFFF"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M59.0276,24.4986 C59.1996,23.1206 60.3446,22.0706 61.7326,22.0176 C61.8686,22.0126 61.8826,22.2236 61.7466,22.2286 C60.4536,22.2756 59.3876,23.2566 59.2346,24.5416 C59.2186,24.6766 59.0116,24.6326 59.0276,24.4986 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M53.431,26.0371 C54.265,27.1291 55.48,27.8671 56.834,28.1031 C56.969,28.1271 56.925,28.3341 56.791,28.3101 C55.383,28.0621 54.12,27.2921 53.254,26.1531 C53.172,26.0441 53.349,25.9291 53.431,26.0371 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M65.0266,25.8928 C64.8516,26.4038 64.9456,26.9678 65.2756,27.3948 C65.3596,27.5018 65.1826,27.6178 65.0996,27.5108 C64.7366,27.0378 64.6316,26.4158 64.8196,25.8498 C64.8336,25.7938 64.8876,25.7578 64.9446,25.7678 C65.0016,25.7798 65.0386,25.8358 65.0266,25.8928 Z"
              fill="#FFFFFF"
            ></path>
            <g transform="translate(13.1044, 0)">
              <mask fill="white">
                <use xlinkHref="#path-3"></use>
              </mask>
              <g></g>
              <path
                d="M37.896,18.06 L37.478,18.06 L37.478,6.62 C37.478,2.964 34.514,0 30.858,0 L6.621,0 C2.965,0 0,2.964 0,6.62 L0,69.379 C0,73.035 2.965,76 6.621,76 L30.858,76 C34.514,76 37.478,73.035 37.478,69.379 L37.478,26.203 L37.896,26.203 L37.896,18.06 Z"
                fill="#212121"
                mask="url(#mask-4)"
              ></path>
            </g>
            <path
              d="M44.229,1.7221 L41.065,1.7221 C41.555,2.9231 40.979,4.2941 39.777,4.7841 C39.496,4.8991 39.195,4.9581 38.89,4.9581 L25.006,4.9581 C23.708,4.9581 22.657,3.9061 22.657,2.6091 C22.657,2.3051 22.716,2.0041 22.831,1.7221 L19.876,1.7221 C17.145,1.7221 14.932,3.9361 14.932,6.6661 L14.932,69.3331 C14.932,72.0631 17.145,74.2771 19.876,74.2771 L19.876,74.2771 L44.229,74.2771 C46.96,74.2771 49.173,72.0631 49.173,69.3331 L49.173,69.3331 L49.173,6.6661 C49.173,3.9361 46.96,1.7221 44.229,1.7221"
              fill="#FFFFFF"
            ></path>
            <path
              d="M32.5205,27.3770456 C30.4495,27.3711 28.4065,27.8611 26.5635,28.8061 C20.1085,32.0941 17.5425,39.9931 20.8305,46.4481 C24.1195,52.9031 32.0185,55.4701 38.4735,52.1811 C39.0765,51.8741 39.6545,51.5211 40.2035,51.1241 C40.9115,50.6131 41.5665,50.0321 42.1585,49.3891 C47.0735,44.0711 46.7475,35.7751 41.4295,30.8601 C39.0045,28.6191 35.8225,27.3751 32.5205,27.3770456 L32.5205,27.3770456 Z M32.5205,54.146106 C24.9875,54.1531 18.8755,48.0521 18.8684906,40.5191 C18.8684906,40.5111 18.8684906,40.5021 18.8684906,40.4941 C18.8625,35.3591 21.7435,30.6571 26.3205,28.3291 C29.6075,26.6571 33.4305,26.3821 36.9225,27.5681 C44.0605,30.0001 47.8745,37.7591 45.4425,44.8971 C43.5575,50.4271 38.3635,54.1451 32.5205,54.146106 Z"
              fill="#F0F0F0"
            ></path>
            <g transform="translate(0, 28.3555)">
              <mask fill="white">
                <use xlinkHref="#path-5"></use>
              </mask>
              <g></g>
              <path
                d="M2.9379,0.0224517582 C4.3539,0.200451758 5.3569,1.49145176 5.1799,2.90745176 C5.1609,3.05845176 5.1289,3.20745176 5.0839,3.35245176 L9.6199,7.13045176 L7.9959,10.4474518 L1.6649,5.02145176 C0.3259,4.50645176 -0.3411,3.00345176 0.1739,1.66445176 C0.6049,0.544451758 1.7479,-0.134548242 2.9379,0.0224517582 Z"
                fill="#FFD1D2"
                mask="url(#mask-6)"
              ></path>
            </g>
            <path
              d="M45.3019,44.1346 C45.2109,43.1566 44.8349,41.3986 44.5159,40.7486 C43.5349,38.1806 37.1959,36.0706 36.3449,35.7986 L36.1289,33.3986 L27.3219,32.4506 L26.2049,35.5206 L23.0459,36.7056 C22.6119,36.8676 22.2729,37.2146 22.1219,37.6526 L19.2879,38.4716 L16.4909,39.2806 L6.7759,32.4346 L6.3169,32.7346 L3.2509,34.7346 L2.7779,35.0436 L15.3509,46.3866 L20.0209,45.2906 L23.5329,44.4646 L23.8329,45.6816 L23.0569,49.9576 C27.7019,54.6056 35.0349,55.1916 40.3599,51.3406 L42.3559,49.5706 C43.7459,48.0666 44.7729,46.2636 45.3559,44.3006 L45.3019,44.1346 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M30.5064,18.448 C33.8314,17.347 37.4184,19.15 38.5194,22.475 C39.6204,25.799 37.8174,29.387 34.4924,30.488 C31.1684,31.588 27.5804,29.786 26.4794,26.461 C25.3794,23.136 27.1814,19.549 30.5064,18.448"
              fill="#FFD1D2"
            ></path>
            <path
              d="M27.4907,17.2388 C28.1067,16.3588 29.0327,15.7418 30.0827,15.5128 C31.1207,15.2988 32.2007,15.4968 33.0957,16.0648 C34.0687,15.6738 35.1177,15.4138 36.1487,15.5458 C37.1787,15.6778 38.1887,16.2538 38.6657,17.2018 C38.7667,17.4478 38.8877,17.6838 39.0297,17.9088 C39.4497,18.4688 40.2307,18.5418 40.9207,18.5688 C40.5717,18.7718 40.2227,18.9748 39.8747,19.1788 C40.2217,19.3408 40.5697,19.5028 40.9167,19.6648 C40.4437,19.8448 39.9707,20.0258 39.4977,20.2058 C40.5987,21.1738 40.8647,22.8958 40.4487,24.3398 C40.0537,25.7068 39.1487,26.8578 38.1387,27.8428 C37.9417,27.1078 38.1587,26.3108 38.3027,25.5498 C38.4547,24.7458 38.4797,23.8048 37.9167,23.2368 C37.5367,22.8538 36.9797,22.7388 36.4537,22.6458 C33.8037,22.1768 31.1297,21.8598 28.4437,21.6948 C28.0837,21.6448 27.7177,21.6818 27.3757,21.8038 C26.7227,22.0938 26.4597,22.8918 26.2697,23.5988 C26.1797,22.3578 26.2517,21.1098 26.4857,19.8868 C26.6647,18.9508 26.9477,18.0148 27.4907,17.2388"
              fill="#212121"
            ></path>
            <path
              d="M35.7328,60.5785 L28.2378,60.5785 C27.6958,60.5785 27.2558,60.1395 27.2558,59.5965 C27.2558,59.0545 27.6958,58.6155 28.2378,58.6155 L35.7328,58.6155 C36.2748,58.6155 36.7148,59.0545 36.7148,59.5965 C36.7148,60.1395 36.2748,60.5785 35.7328,60.5785"
              fill="#FD7B6F"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
