import { Fragment, useCallback, useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash.debounce";
import { ContactsSVG } from "../../assets/ContactsSVG";
import { useIsMobile } from "../../hooks/ui";
import { useSelector } from "react-redux";
import { RootState } from "../../types/state";

export function ConvertSupportersCurrentContacts() {
  const classes = styles();
  const isMobile = useIsMobile();
  const contacts = useSelector((state: RootState) => state.contacts.contacts);
  const [displayContacts, setDisplayContacts] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [noContacts, setNoContacts] = useState(false);

  useEffect(() => {
    if (!Array.isArray(contacts) || !contacts.length) {
      setNoContacts(true);
    } else {
      setDisplayContacts(contacts);
      setNoContacts(false);
    }
  }, [contacts]);

  const handleSearch = (newVal: string) => {
    if (!newVal) return setDisplayContacts(contacts);
    if (Array.isArray(contacts)) {
      const filtered = contacts.filter(({ name }) =>
        name.toLowerCase().includes(newVal.toLowerCase()),
      );
      setDisplayContacts(filtered);
    }
  };

  const onChange = ({ target }) => {
    setSearch(target.value);
    debouncedHandleSearch(target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), []);

  if (noContacts)
    return (
      <div className={classes.noContactsContainer}>
        <ContactsSVG />
        <div className={classes.noContacts}>No contacts yet</div>
      </div>
    );
  return (
    <Fragment>
      <div className={classes.searchContainer}>
        <TextField
          placeholder="Search contacts"
          value={search}
          size="small"
          onChange={onChange}
          className={classes.input}
          InputProps={{
            startAdornment: (
              <SearchIcon fontSize="small" className={classes.searchIcon} />
            ),
          }}
        />
      </div>

      <div className={classes.bodyContainer}>
        {Array.isArray(displayContacts) &&
          displayContacts.map((c, index) => {
            const { name, email, phone } = c;
            return (
              <div key={index} className={classes.row}>
                <div className={classes.name}>{name}</div>
                <div className={classes.phoneAndEmail}>
                  <div className={classes.phone}>{phone}</div>
                  {isMobile && phone && email && (
                    <div className={classes.separatorDot}>•</div>
                  )}
                  <div className={classes.email}>{email}</div>
                </div>
              </div>
            );
          })}
      </div>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  searchContainer: {
    height: 64,
    minHeight: 64,
    display: "flex",
    alignItems: "center",
    paddingLeft: 24,
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
      borderBottom: "1px solid #EAEBF3",
    },
  },
  input: {
    height: 32,
    width: 300,
    borderRadius: 4,
    backgroundColor: "#EAEBF3",
    "& .MuiInputBase-root": {
      height: 32,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary2,
    marginRight: 8,
  },
  bodyContainer: {
    flex: "1 1 auto",
    overflowY: "auto",
    paddingBottom: 24,
  },
  row: {
    minHeight: 52,
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #C9CDDE",
    padding: "6px 24px 6px 40px",
    [theme.breakpoints.down("sm")]: {
      minHeight: 50,
      height: "fit-content",
      borderBottom: "1px solid #EAEBF3",
      flexDirection: "column",
      alignItems: "unset",
      padding: "8px 16px 8px 16px",
    },
  },
  name: {
    width: 266,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 4,
      width: "100%",
    },
  },
  phoneAndEmail: {
    display: "flex",
    alignItems: "center",
  },
  separatorDot: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    padding: "0 6px",
    fontSize: 16,
  },
  phone: {
    width: 140,
    padding: "0 16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      padding: 0,
      overflow: "unset",
      textOverflow: "unset",
      fontSize: 12,
      letterSpacing: 0.4,
      color: theme.palette.text.secondary,
    },
  },
  email: {
    width: 314,
    paddingLeft: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
      padding: 0,
      fontSize: 12,
      letterSpacing: 0.4,
      color: theme.palette.text.secondary,
    },
  },
  noContactsContainer: {
    width: "100%",
    marginTop: 60,
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
  noContacts: {
    fontSize: 20,
    letterSpacing: 0.15,
    lineHeight: "32px",
    textAlign: "center",
    marginTop: 16,
  },
}));
