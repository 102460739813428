import React, { useState } from "react";
import {
  Datagrid,
  TextField,
  Create,
  Filter,
  TextInput,
  SimpleForm,
  useListController,
  ListView,
  Pagination,
  useNotify,
  TopToolbar,
  Notification,
  required,
  minLength,
  email,
  Edit,
  Toolbar,
  SaveButton,
  ExportButton,
  downloadCSV,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton } from "@material-ui/core";
import { styles } from "./Product.styles";
const validateName = [required(), minLength(2)];
const validateEmail = [email()];
const asideModes = { CREATE: "add", EDIT: "edit", CLOSED: null };

const SourceFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Sources" source="q" alwaysOn />
    </Filter>
  );
};

export function ProductSources(props) {
  const { record: { id: supplier_id } = {} } = props;
  const classes = styles();
  const [asideMode, setAsideMode] = useState(asideModes.CLOSED);
  const [activeSourceId, setActiveSourceId] = useState(null);
  const notify = useNotify();
  const basePath = `/product_suppliers/${supplier_id}/show/sources`;

  const controllerProps = useListController({
    sort: { field: "name", order: "ASC" },
    basePath: basePath,
    resource: "product_sources",
    filter: { supplier_id },
    perPage: 25,
  });

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        className={classes.addButton}
        onClick={() => setAsideMode(asideModes.CREATE)}
      >
        add source
      </Button>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues, supplier_id }}
        exporter={sourceExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  const onRowClick = id => {
    setActiveSourceId(id);
    setAsideMode(asideModes.EDIT);
  };

  const onSuccess = msg => {
    controllerProps.refetch();
    setAsideMode(asideModes.CLOSED);
    notify(msg);
  };

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={<SourceFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="contact" />
          <TextField source="phone" />
          <TextField source="email" />
        </Datagrid>
      </ListView>

      <Drawer open={Boolean(asideMode)} anchor="right">
        <div className={classes.productDrawer}>
          <div className={classes.drawerHeader}>
            <div>
              {asideMode === asideModes.CREATE ? "Add a Source" : "Edit Source"}
            </div>
            <IconButton
              onClick={() => setAsideMode(asideModes.CLOSED)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {asideMode === asideModes.CREATE && (
            <SourceCreate
              onSuccess={onSuccess}
              supplier_id={supplier_id}
              basePath={basePath}
            />
          )}

          {asideMode === asideModes.EDIT && (
            <SourceEdit
              id={activeSourceId}
              onSuccess={onSuccess}
              basePath={basePath}
            />
          )}
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}

function SourceCreate({ supplier_id, onSuccess, basePath }) {
  const createProps = { resource: "product_sources", basePath, title: " " };

  return (
    <Create
      {...createProps}
      onSuccess={() => onSuccess("Source added successfully")}
    >
      <SimpleForm initialValues={{ supplier_id }}>
        <TextInput source="name" validate={validateName} />
        <TextInput source="contact" />
        <TextInput source="phone" />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="address" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="zip" />
        <TextInput source="country" />
      </SimpleForm>
    </Create>
  );
}

function SourceEdit({ id, onSuccess, basePath }) {
  const props = { id, resource: "product_sources", basePath, title: " " };

  return (
    <Edit
      {...props}
      onSuccess={() => onSuccess("Source updated successfully")}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="name" validate={validateName} />
        <TextInput source="contact" />
        <TextInput source="phone" />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="address" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="zip" />
        <TextInput source="country" />
      </SimpleForm>
    </Edit>
  );
}

function sourceExporter(sources) {
  const formattedSources = sources.map(source => {
    const {
      contact,
      email,
      id,
      name,
      phone,
      address,
      city,
      state,
      zip,
      country,
    } = source;
    const exportObj = {
      ID: id,
      Name: name,
      Contact: contact,
      Email: email,
      Phone: phone,
      Address: address,
      City: city,
      State: state,
      zip: zip,
      Country: country,
    };
    return exportObj;
  });
  jsonExport(formattedSources, (err, csv) => {
    downloadCSV(csv, "Product Sources");
  });
}
