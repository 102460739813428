import React from "react";
import Card from "@material-ui/core/Card";
import Chart from "react-google-charts";
import { styles } from "./Chart.styles";
import { connect } from "react-redux";

function _DailyPerformanceChart(props) {
  const classes = styles();
  const { data } = props;

  return (
    <Card elevation={3} className={classes.card}>
      <div className={classes.cardContent}>
        {Array.isArray(data) && data.length && (
          <Chart
            style={{ flex: 1, height: "100%" }}
            chartType="ColumnChart"
            loader={<div>Loading Chart...</div>}
            data={data}
            options={{
              legend: { position: "none" },
              vAxis: { format: "currency", minValue: 1 },
              title: "Daily Performance",
            }}
          />
        )}
      </div>
    </Card>
  );
}

export const DailyPerformanceChart = connect(state => {
  return {
    data: state.dashboard.dailyPerformanceData,
  };
})(_DailyPerformanceChart);
