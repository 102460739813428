import { ListProps } from "../../../types";
import {
  FiltersDrawer,
  FilterWrapper,
  SwitchFilter,
} from "../../../components";
import { Fragment } from "react";
import { MaterialFields } from "../../../types/materials";
import { makeStyles, MenuItem, TextField } from "@material-ui/core";
import { RootState } from "../../../types/state";
import { useSelector } from "react-redux";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function MaterialFilters({ onClose, listProps }: Props) {
  const { getFilterValue, isActiveFilter, updateTempFilters } = listProps;
  const classes = styles();
  const categories = useSelector(
    ({ material }: RootState) => material.materialCategoryAutocomplete,
  );

  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target,
      currentTarget: { attributes },
    } = e;
    const category = attributes.getNamedItem("data-category")?.value || "";
    updateTempFilters({
      field: MaterialFields.category_id,
      value: target.value,
      chipText: `Category - ${category}`,
    });
  };

  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter materials"
    >
      <Fragment>
        <SwitchFilter
          field={MaterialFields.include_inactive}
          chipText="Include inactive"
          header="Include inactive material"
          switchLabel="Include inactive"
          listProps={listProps}
        />
        <FilterWrapper
          header="Category"
          active={isActiveFilter(MaterialFields.category_id)}
        >
          <TextField
            select
            fullWidth
            value={getFilterValue(MaterialFields.category_id)}
            onChange={onCategoryChange}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem className={classes.labelOption} value="">
              Select a category
            </MenuItem>
            {categories.map(({ id, name }, index) => (
              <MenuItem key={index} value={id} data-category={name}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        </FilterWrapper>
      </Fragment>
    </FiltersDrawer>
  );
}

const styles = makeStyles(theme => ({
  labelOption: {
    color: theme.palette.text.secondary2,
  },
}));
