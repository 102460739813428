import { useEffect, useState } from "react";

type Props = {
  type: "gold" | "silver" | "bronze";
};
export function AwardSmallSVG({ type }: Props) {
  const [color1, setColor1] = useState("");
  const [color2, setColor2] = useState("");
  const [color3, setColor3] = useState("");

  useEffect(() => {
    switch (type) {
      case "gold":
        setColor1("#F6F3BE");
        setColor2("#E1B04E");
        setColor3("#BD8B36");
        break;
      case "silver":
        setColor1("#F9FBFC");
        setColor2("#C3D3DD");
        setColor3("#A5B7C6");
        break;
      case "bronze":
        setColor1("#F4D8BE");
        setColor2("#E0904F");
        setColor3("#BC6C37");
        break;
    }
  }, [type]);

  return (
    <svg width="49px" height="62px" viewBox="0 0 49 62">
      <title>Award</title>
      <defs>
        <linearGradient
          x1="65.4759825%"
          y1="4.32525952%"
          x2="34.139738%"
          y2="96.8425606%"
          id={`linearGradient-${type}-1`}
        >
          <stop stopColor={color1} offset="0%"></stop>
          <stop stopColor={color2} offset="42%"></stop>
          <stop stopColor={color1} offset="76%"></stop>
          <stop stopColor={color3} offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="34.7427635%"
          y1="2.20588235%"
          x2="62.1813217%"
          y2="88.2352941%"
          id={`linearGradient-${type}-2`}
        >
          <stop stopColor={color1} offset="0%"></stop>
          <stop stopColor={color2} offset="42%"></stop>
          <stop stopColor={color1} offset="76%"></stop>
          <stop stopColor={color3} offset="100%"></stop>
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="49.9858877%"
          x2="100%"
          y2="49.9858877%"
          id={`linearGradient-${type}-5`}
        >
          <stop stopColor={color1} offset="0%"></stop>
          <stop stopColor={color2} offset="42%"></stop>
          <stop stopColor={color1} offset="76%"></stop>
          <stop stopColor={color3} offset="100%"></stop>
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-12, -470)">
          <g transform="translate(13, 471)">
            <polygon
              fill={`url(#linearGradient-${type}-1)`}
              fillRule="nonzero"
              points="13.6375546 60 9.11790393 51.7474048 0 54.3036332 10.3624454 30 24 35.6963668"
            ></polygon>
            <polygon
              fill={`url(#linearGradient-${type}-2)`}
              fillRule="nonzero"
              points="33.3549973 60 37.8771163 51.7474048 47 54.3036332 36.6318951 30 23 35.6963668"
            ></polygon>
            <polygon
              id="Path"
              fill={`url(#linearGradient-${type}-5)`}
              fillRule="nonzero"
              points="47 23.4933672 42.7152131 28.6404177 43.8560542 35.2466836 37.554897 37.554897 35.2466836 43.8427886 28.6536833 42.7019475 23.5066328 47 18.3463167 42.7019475 11.7533164 43.8427886 9.44510302 37.554897 3.14394581 35.2466836 4.2980525 28.6404177 0 23.4933672 4.2980525 18.3463167 3.14394581 11.7533164 9.44510302 9.44510302 11.7533164 3.14394581 18.3463167 4.2847869 23.5066328 0 28.6536833 4.2847869 35.2466836 3.14394581 37.554897 9.44510302 43.8560542 11.7533164 42.7152131 18.3463167"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}
