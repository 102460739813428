import React, { useState } from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { APIUtilClient, downloadPdf, generateAuthHeader } from "../../../lib";

export function STSPalletLabel({ campaign_id }) {
  const classes = styles();
  const [loading, setLoading] = useState(false);

  const download = async () => {
    setLoading(true);
    const res = await APIUtilClient.get(
      `/order_fulfillment/pdf/school_pallet_label?campaign_id=${campaign_id}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return downloadPdf(res, "Ship To School Pallet Label.pdf");
  };

  return (
    <Button
      size="small"
      variant="outlined"
      className={classes.button}
      startIcon={<DownloadIcon />}
      color="primary"
      onClick={download}
      disabled={loading}
    >
      Pallet Label
      {loading && (
        <CircularProgress
          color="primary"
          className={classes.spinner}
          size={24}
        />
      )}
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  button: { marginBottom: 6, marginLeft: 6 },
  spinner: { position: "absolute" },
}));
