import React, { Fragment } from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  required,
  BooleanInput,
  regex,
  SelectInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { OnChange } from "react-final-form-listeners";
import { useField } from "react-final-form";
import {
  englishMonthOptions,
  englishYearOptions,
  hebrewMonthOptions,
  hebrewYearOptions,
  TWO_DECIMAL_REGEX,
} from "../../lib";

const requiredInput = [required()];
const validMoney = regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only");
const requiredMoneyInput = [validMoney, required()];

export function CreateItem({ donation_group_id, onSuccess }) {
  const classes = styles();
  const basePath = `/donation_groups/${donation_group_id}/show`;

  return (
    <Create
      resource="donation_group_items"
      basePath={basePath}
      title=" "
      onSuccess={() => onSuccess("Item added successfully")}
    >
      <SimpleForm initialValues={{ donation_group_id, multi_use: false }}>
        <SelectInput
          source="tier"
          choices={[
            { id: "1", name: "1" },
            { id: "2", name: "2" },
            { id: "3", name: "3" },
          ]}
          validate={requiredInput}
          translateChoice={false}
        />
        <TextInput source="label" validate={requiredInput} />
        <BooleanInput source="multi_use" label="Multi Use" />
        <BooleanInput
          source="one_time_use_per_volunteer"
          label="One Time Use Per Student"
        />
        <TextInput
          source="amount"
          type="number"
          validate={requiredMoneyInput}
        />
        <ImageInput
          source="avatarBase64"
          label="Avatar"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="is_day_picker" label="Display Day Picker" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { is_day_picker } = formData;
            if (!is_day_picker) return;
            return (
              <SelectInput
                {...rest}
                source="calendar_type"
                label="Calendar Type"
                choices={[
                  { id: "English", name: "English" },
                  { id: "Hebrew", name: "Hebrew" },
                ]}
                validate={requiredInput}
                translateChoice={false}
              />
            );
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { is_day_picker, calendar_type } = formData;
            if (!is_day_picker || !calendar_type) return null;
            const isHebrew = calendar_type === "Hebrew";
            return (
              <Fragment>
                <SelectInput
                  {...rest}
                  source="day_picker_year"
                  label="Select A Year"
                  choices={isHebrew ? hebrewYearOptions : englishYearOptions}
                  validate={requiredInput}
                  translateChoice={false}
                />
                <div />
                <SelectInput
                  {...rest}
                  source="day_picker_month"
                  label="Select A Month"
                  choices={isHebrew ? hebrewMonthOptions : englishMonthOptions}
                  validate={requiredInput}
                  translateChoice={false}
                />
                <div />
                <BooleanInput
                  source="day_picker_display_english_and_hebrew"
                  label={`Also Display ${
                    isHebrew ? "English" : "Hebrew"
                  } Dates`}
                  {...rest}
                  className={classes.fitContent}
                />
              </Fragment>
            );
          }}
        </FormDataConsumer>
        <Fragment>
          <ChangeListener />
        </Fragment>
      </SimpleForm>
    </Create>
  );
}

const ChangeListener = () => {
  const yearField = useField("day_picker_year");
  const monthField = useField("day_picker_month");
  return (
    <OnChange name="calendar_type">
      {() => {
        if (yearField.input.value) yearField.input.onChange(null);
        if (monthField.input.value) monthField.input.onChange(null);
      }}
    </OnChange>
  );
};

const styles = makeStyles(_theme => ({
  fitContent: {
    width: "fit-content",
  },
}));
