import { useState, Fragment, useEffect } from "react";
import TimerIcon from "@material-ui/icons/TimerOutlined";
import Countdown from "react-countdown-now";
import {
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";

export function Timer() {
  const classes = styles();
  const { secondary_color, primary_color } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const [secondaryColor, setSecondaryColor] = useState<string>("");
  const [primaryColor, setPrimaryColor] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [countdownTime, setCountdownTime] = useState<number>(0);

  useEffect(() => {
    if (secondary_color) setSecondaryColor(secondary_color);
    if (primary_color) setPrimaryColor(primary_color);
  }, [secondary_color, primary_color]);

  const handleClose = () => setOpen(false);

  const setTimer = () => {
    const hourMS = +hours * 3600000;
    const minMS = +minutes * 60000;
    const ttlMS = Date.now() + (hourMS + minMS);
    setCountdownTime(ttlMS);
    handleClose();
  };

  const handleClear = () => {
    setHours("");
    setMinutes("");
    setCountdownTime(0);
  };

  return (
    <Fragment>
      <IconButton
        size="small"
        style={{ color: secondaryColor }}
        className={classes.timerIcon}
        onClick={() => setOpen(true)}
      >
        <TimerIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Setup Timer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter Time below to setup a Timer
          </DialogContentText>
          <TextField
            label="Hours"
            type="number"
            value={hours}
            onChange={e => setHours(e.target.value)}
            className={classes.leftInput}
          />
          <TextField
            label="Minutes"
            type="number"
            value={minutes}
            onChange={e => setMinutes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="text">
            Cancel
          </Button>
          {(minutes || hours) && (
            <Button onClick={handleClear} color="secondary" variant="text">
              Clear
            </Button>
          )}
          {(minutes || hours) && (
            <Button onClick={setTimer} color="primary">
              Set
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {countdownTime > 0 && (
        <Countdown
          date={countdownTime}
          intervalDelay={1000}
          renderer={({ days, hours, minutes, seconds }) => {
            return (
              <div
                className={classes.container}
                style={{ backgroundColor: primaryColor }}
              >
                <div className={classes.timeContainer}>
                  {days > 0 && (
                    <div className={classes.section}>
                      <div className={classes.time}>{days}</div>
                      <div className={classes.label}>days</div>
                    </div>
                  )}
                  {hours > 0 && (
                    <div className={classes.section}>
                      <div className={classes.time}>{hours}</div>
                      <div className={classes.label}>hours</div>
                    </div>
                  )}
                  <div className={classes.section}>
                    <div className={classes.time}>{minutes}</div>
                    <div className={classes.label}>minutes</div>
                  </div>
                  <div className={classes.section}>
                    <div className={classes.time}>{seconds}</div>
                    <div className={classes.label}>seconds</div>
                  </div>
                </div>
              </div>
            );
          }}
        />
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  timerIcon: {
    marginRight: 8,
  },
  leftInput: {
    marginRight: 24,
  },
  container: {
    position: "fixed",
    zIndex: 99999999,
    bottom: 24,
    right: 24,
    height: 250,
    padding: "0 28px",
    borderRadius: 8,
    boxShadow:
      "0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.2)",
    color: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timeContainer: {
    display: "flex",
  },
  section: {
    minWidth: 82,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 28px",
  },
  time: {
    fontSize: 56,
    fontWeight: "bold",
    letterSpacing: 0,
    paddingBottom: 26,
  },
  label: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.25,
  },
}));
