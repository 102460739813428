import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  InputAdornment,
  TableFooter,
  Button,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { TextField } from "final-form-material-ui";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useNotify } from "react-admin";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  APIClient,
  generateAuthHeader,
  required,
  requiredInteger,
  composeValidators,
  twoDecimalsOnly,
  shippingRateTypes,
} from "../../lib";

export function ZoneRateTable({
  zone,
  fetchShippingRates,
  rateType,
  isLiveRate,
  readOnly = false,
}) {
  const { id, name, shipping_rates = [] } = zone;
  const keyWord =
    rateType === shippingRateTypes.WEIGHT ? "Ounces" : "Item Count";
  const classes = styles();
  const notify = useNotify();

  const onSubmit = async ({ shipping_rates: _rates = [] }) => {
    if (!_rates.length) return notify("You have no rates!", "warning");
    const errorMsg = errorCheck(_rates);
    if (errorMsg) return notify(errorMsg, "warning");
    const rateCopy = JSON.parse(JSON.stringify(_rates));
    //add a max to last row so it will pass validation
    rateCopy[rateCopy.length - 1].max = 100000;
    const res = await APIClient.put(`/shipping_rates/zone/${id}`, rateCopy, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    fetchShippingRates();
    notify("Rates have been successfully updated");
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ shipping_rates }}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TableContainer component={Paper} className={classes.table}>
              <FieldArray name="shipping_rates">
                {({ fields }) => (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.dataCol}>
                          {`${keyWord} Start`}
                        </TableCell>
                        <TableCell className={classes.dataCol}>
                          {`${keyWord} End`}
                        </TableCell>
                        <TableCell className={classes.dataCol}>
                          {isLiveRate ? "Additional Fee" : "Price"}
                        </TableCell>
                        <TableCell align="right">
                          {!readOnly && (
                            <Button
                              color="primary"
                              onClick={() =>
                                fields.push({ min: fields.length ? null : 0 })
                              }
                              startIcon={<AddIcon />}
                            >
                              add rate
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((row, index) => {
                        const isLast = fields.length === index + 1;
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Field
                                variant="standard"
                                component={TextField}
                                name={`${row}.min`}
                                validate={requiredInteger}
                                type="number"
                                disabled={readOnly}
                                parse={value =>
                                  value || value === 0 || value === "0"
                                    ? Number(value)
                                    : value
                                }
                              />
                            </TableCell>
                            <TableCell>
                              {isLast && `AND UP`}
                              {!isLast && (
                                <Field
                                  variant="standard"
                                  component={TextField}
                                  name={`${row}.max`}
                                  validate={requiredInteger}
                                  type="number"
                                  disabled={readOnly}
                                  parse={value =>
                                    value || value === 0 || value === "0"
                                      ? Number(value)
                                      : value
                                  }
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              <Field
                                variant="standard"
                                component={TextField}
                                name={`${row}.price`}
                                validate={composeValidators(
                                  required,
                                  twoDecimalsOnly,
                                )}
                                type="number"
                                disabled={readOnly}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              {!readOnly && (
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                  size="small"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={3}>{`ZONE: ${name}`}</TableCell>
                        <TableCell align="right">
                          {!readOnly && (
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              disabled={submitting}
                            >
                              save
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                )}
              </FieldArray>
            </TableContainer>
          </form>
        );
      }}
    />
  );
}

function errorCheck(rates = []) {
  // check for duplicate or out of order mins
  const valid = rates.every(({ min }, i, a) => !i || a[i - 1].min < min);
  if (!valid) return "You have duplicate or out of order data!";

  // check that min starts with 0
  if (rates[0].min !== 0) return "First row must start with 0!";

  // only one row all is good
  if (rates.length === 1) return;

  // check for sequence errors
  let expected = 0;
  let sequenceError = null;
  rates.forEach(({ min, max }, i) => {
    const isFirst = i === 0;
    const isLast = i === rates.length - 1;
    if (!isFirst && (min !== expected || (!isLast && max < min))) {
      sequenceError = "Your start and end numbers overlap or have gaps!";
    }
    expected = max + 1;
  });

  return sequenceError;
}

const styles = makeStyles(_theme => ({
  table: { minWidth: 770, marginBottom: 24, width: "fit-content" },
  dataCol: { width: 210 },
}));
