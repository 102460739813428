import { useEffect, useState, useRef } from "react";
import omit from "lodash.omit";
import { useIsDesktop, useIsMobile } from "./ui";
import {
  APIRes,
  FilterChipsObject,
  GenericObject,
  ListProps,
  MobileBulkSelectHeaderProps,
  ObjectOfObjects,
  PaginationComponentProps,
  StringObject,
} from "../types";
import {
  APIAuthClient,
  downloadCsv,
  generateListExportQueryParams,
  generateQueryParamsWithPagination,
  scrollToTop,
} from "../lib";
import { useDispatch } from "react-redux";
import { setToast } from "../state";
const MOBILE_LIMIT = 15;
const DESKTOP_DEFAULT_LIMIT = 10;

type BaseProps = {
  page: number;
  limit: number;
};

type Props = {
  baseUrl: string;
  isReady: boolean;
  initialState: GenericObject;
  filterFields?: StringObject;
  exportFormatter?: (data: any) => GenericObject[];
  exportFileName?: string;
};
export function useList<T extends BaseProps>({
  baseUrl,
  isReady,
  initialState,
  filterFields = {},
  exportFormatter,
  exportFileName,
}: Props): ListProps<T> {
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  const [params, setParams] = useState<T>({
    page: 0,
    limit: 0,
    ...initialState,
  } as T);
  const { page, limit } = params;
  const [loading, setLoading] = useState(true);
  const [downloadingList, setDownloadingList] = useState(false);
  // this includes any filtering including search bar
  const [listIsFiltered, setListIsFiltered] = useState(false);
  const [rows, setRows] = useState<GenericObject[]>([]);
  const [total, setTotal] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [showRows, setShowRows] = useState(false);
  // FILTER STATE
  // if the Filter Drawer has any active filters
  const [filterDrawerActive, setFilterDrawerActive] = useState(false);
  const [tempFilters, setTempFilters] = useState<GenericObject>({});
  const [hasPrevFilters, setHasPrevFilters] = useState(false);
  const [tempFilterChips, setTempFilterChips] = useState<FilterChipsObject>({});
  const [filterChips, setFilterChips] = useState<FilterChipsObject>({});
  const [activeFilterChanges, setActiveFilterChanges] = useState<GenericObject>(
    {},
  );
  const [allFiltersClearedMode, setAllFiltersClearedMode] = useState(false);
  const [hasFilterChanges, setHasFilterChanges] = useState(false);
  const [hasClearableFilters, setHasClearableFilters] = useState(false);
  // SELECTED ROWS STATE
  const [selectedRows, setSelectedRows] = useState<ObjectOfObjects>({});
  const [hasSelectedRows, setHasSelectedRows] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [mobileSelectRowsMode, setMobileSelectRowsMode] = useState(false);
  const [
    mobileBulkSelectHeaderProps,
    setMobileBulkSelectHeaderProps,
  ] = useState<MobileBulkSelectHeaderProps>({});
  const loadingRef = useRef(true);
  const totalRef = useRef(0);
  const pageRef = useRef(0);
  const totalPagesRef = useRef(0);
  const dispatch = useDispatch();
  // baseParamLength is the `initialState.length` + 2 (page & limit)
  const baseParamLength = Object.keys(initialState).length + 2;

  // THIS MUST BE FIRST
  useEffect(() => {
    setParams(prev => {
      return {
        ...prev,
        page: 0,
        limit: isMobile ? MOBILE_LIMIT : isDeskTop ? DESKTOP_DEFAULT_LIMIT : 0,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, isDeskTop]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  useEffect(() => {
    if (!noResults && ((isMobile && page > 0) || !loading)) {
      setShowRows(true);
    } else setShowRows(false);
  }, [page, isMobile, loading, noResults]);

  useEffect(() => {
    if (isReady && params.limit) fetchData();
    // if the `params.length` is greater then the `baseParamLength` we know they are using filters
    setListIsFiltered(Object.keys(params).length > baseParamLength);

    const _filterDrawerActive = Object.keys(filterFields).some(field => {
      if (typeof params[field] !== "undefined") return true;
      else return false;
    });
    setFilterDrawerActive(_filterDrawerActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, baseParamLength, isReady]);

  useEffect(() => {
    const count = Object.keys(selectedRows).length;
    setHasSelectedRows(count > 0);
    setSelectedRowCount(count);
    setSelectedRowIds(Object.keys(selectedRows).map(id => Number(id)));
    setAllRowsSelected(Boolean(count && count === rows.length));
  }, [selectedRows, rows]);

  useEffect(() => {
    const hasClearable = Object.keys(tempFilters).some(f => {
      if (tempFilters[f] || typeof tempFilters[f] === "boolean") {
        return true;
      }
      return false;
    });
    setHasClearableFilters(hasClearable);
  }, [tempFilters]);

  useEffect(() => {
    setHasFilterChanges(
      Boolean(allFiltersClearedMode || Object.keys(activeFilterChanges).length),
    );
  }, [activeFilterChanges, allFiltersClearedMode]);

  const fetchData = async () => {
    loadingRef.current = true;
    setNoResults(false);
    setLoading(true);
    const query = generateQueryParamsWithPagination(params);
    const url = `${baseUrl}?${query}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setData(data);
    setLoading(false);
  };

  const exportList = async () => {
    setDownloadingList(true);
    const query = generateListExportQueryParams(params);
    const url = `${baseUrl}?${query}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) dispatch(setToast(errorMessage));
    else {
      const dataArr = exportFormatter ? exportFormatter(data.rows) : data.rows;
      downloadCsv(dataArr, exportFileName || "List export");
    }
    setTimeout(() => setDownloadingList(false), 2000);
  };

  const refreshList = () => {
    // if it's mobile set page back to 0 which will trigger a `fetchData`
    if (isMobile) {
      setParams(prev => {
        return { ...prev, page: 0 };
      });
    } else fetchData();
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newLimit = Number(event.target.value);
    setParams(prev => {
      return { ...prev, page: 0, limit: newLimit };
    });
  };

  const handleChangePage = (_: any, newPage: number) => {
    // (page is zero-based & totalPagesRef is one-based)
    const noMorePages = newPage + 1 > totalPagesRef.current;
    if (loadingRef.current || totalRef.current < 1 || noMorePages) return;
    setParams(prev => {
      return { ...prev, page: newPage };
    });
  };

  const nextPage = () => {
    handleChangePage(undefined, pageRef.current + 1);
  };

  const setData = (data: { count: number; rows: GenericObject[] }) => {
    const { count, rows } = data;
    setTotal(count);

    if (isMobile && page > 0) setRows(prev => [...prev, ...rows]);
    else {
      scrollToTop();
      setRows(rows);
      setSelectedRows({});
    }

    if (!count) setNoResults(true);
    else setNoResults(false);
    totalPagesRef.current = Math.ceil(count / limit);
    totalRef.current = count;
    loadingRef.current = false;
  };

  const setupInitialTempFilters = () => {
    const temp = {};
    let prevFilterCount = 0;
    Object.keys(filterFields).forEach(field => {
      if (params[field] || typeof params[field] === "boolean") {
        temp[field] = params[field];
        prevFilterCount++;
      }
    });
    setTempFilters(temp);
    setHasPrevFilters(prevFilterCount > 0);
    setTempFilterChips(filterChips);
  };

  const updateFilters = () => {
    const newFilters = {};
    Object.keys(tempFilters).forEach(k => {
      if (tempFilters[k] || typeof tempFilters[k] === "boolean") {
        newFilters[k] = tempFilters[k];
      }
    });
    setParams(prev => {
      // remove prev filters
      const rest = omit(prev, Object.keys(filterFields)) as T;
      // add current filters
      return { ...rest, page: 0, ...newFilters };
    });
    setFilterChips(tempFilterChips);
    setTempFilters({});
    setTempFilterChips({});
  };

  const updateTempFilters = ({
    field,
    value,
    customChipField,
    chipText,
    checkActiveChipByText,
    customChipFilterFields,
  }: {
    field: string;
    value: any;
    customChipField?: string;
    customChipFilterFields?: string[];
    chipText: string;
    checkActiveChipByText?: boolean;
  }) => {
    setAllFiltersClearedMode(false);

    setTempFilters((prev: any) => {
      const { [field]: _rm, ...rest } = prev;
      return { ...rest, [field]: value };
    });

    setActiveFilterChanges((prev: any) => {
      const { [field]: _rm, ...rest } = prev;
      if (value === params[field]) return rest;
      else if (
        value ||
        typeof value === "boolean" ||
        params[field] ||
        typeof params[field] === "boolean"
      ) {
        return { ...rest, [field]: value };
      } else return rest;
    });

    setTempFilterChips((prev: any) => {
      const chipField = customChipField ? customChipField : field;
      const filterFields = customChipFilterFields
        ? customChipFilterFields
        : [field];
      const { [chipField]: _rm, ...rest } = prev;
      if (
        (checkActiveChipByText && chipText) ||
        (!checkActiveChipByText && (value || typeof value === "boolean"))
      ) {
        return {
          ...rest,
          [chipField]: { text: chipText, fields: filterFields },
        };
      } else return rest;
    });
  };

  const getFilterValue = (
    field: string,
    isBoolean = false,
    isNullable = false,
  ) => {
    let value: any;
    if (
      typeof tempFilters[field] !== "undefined" &&
      tempFilters[field] !== null
    ) {
      value = tempFilters[field];
    } else {
      if (isBoolean) value = false;
      else if (isNullable) value = null;
      else value = "";
    }
    return value;
  };

  const isActiveFilter = (field: string, field2?: string) => {
    return Boolean(
      tempFilters[field] ||
        typeof tempFilters[field] === "boolean" ||
        (field2 &&
          (tempFilters[field2] || typeof tempFilters[field2] === "boolean")),
    );
  };

  const setAllFiltersToBeCleared = () => {
    if (hasPrevFilters) setAllFiltersClearedMode(true);
    setTempFilters({});
    setActiveFilterChanges({});
    setTempFilterChips({});
  };

  const clearAllFilters = () => {
    setParams(prev => {
      // remove filters
      const rest = omit(prev, Object.keys(filterFields)) as T;
      return { ...rest, page: 0 };
    });
    setFilterChips({});
  };

  const clearFilter = (fieldArr: string[], filterChipField: string) => {
    // the `filterChipField` can be different than the param field(s) that we are removing (for example a date-range chip has a chip field name which combines the start & end dates)
    setFilterChips(prev => {
      const { [filterChipField]: _rm, ...rest } = prev;
      return rest;
    });
    setParams(prev => {
      // remove filters
      const rest = omit(prev, fieldArr) as T;
      return { ...rest, page: 0 };
    });
  };

  // TODO: implement this if we use bulk action that are also accessible in-row
  // const updateSelectedRows: UpdateSelectedRows = ({ id, value, mode }) => {
  //   switch (mode) {
  //     case "ADD":
  //       setSelectedRows(prev => {
  //         if (allRowsSelected) return { [id]: value };
  //         else return { ...prev, [id]: value };
  //       });
  //       break;
  //     case "ADD_AND_CLEAR_OTHERS":
  //       setSelectedRows({ [id]: value });
  //       break;
  //   }
  // };

  const toggleSelectedRow = (id: any, row: GenericObject) => {
    setSelectedRows(prev => {
      if (prev[id]) {
        const { [id]: _rm, ...rest } = prev;
        return rest;
      } else {
        return { ...prev, [id]: row };
      }
    });
  };

  const removeSelectedRow = (id: any) => {
    setSelectedRows(prev => {
      const { [id]: _rm, ...rest } = prev;
      return rest;
    });
  };

  const selectAllRows = () => {
    const temp = {};
    rows.forEach(r => {
      temp[r.id as number] = r;
    });
    setSelectedRows(temp);
  };

  const unselectAllRows = () => setSelectedRows({});

  const toggleMobileSelectRowsMode = (isActive: boolean) => {
    setMobileSelectRowsMode(isActive);
  };

  const paginationProps: PaginationComponentProps = {
    count: total,
    rowsPerPage: limit,
    page,
    onPageChange: handleChangePage,
    onRowsPerPageChange: handleChangeRowsPerPage,
  };

  return {
    listIsFiltered,
    pageRef,
    rows,
    showRows,
    total,
    noResults,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    refreshList,
    nextPage,
    paginationProps,
    params,
    setParams,
    // BULK ACTION PROPS
    hasSelectedRows,
    selectedRowCount,
    selectedRowIds,
    selectedRows,
    allRowsSelected,
    toggleSelectedRow,
    removeSelectedRow,
    selectAllRows,
    unselectAllRows,
    toggleMobileSelectRowsMode,
    mobileSelectRowsMode,
    mobileBulkSelectHeaderProps,
    setMobileBulkSelectHeaderProps,
    // FILTER PROPS
    tempFilters,
    filterChips,
    hasFilterChanges,
    hasClearableFilters,
    filterDrawerActive,
    updateFilters,
    updateTempFilters,
    getFilterValue,
    isActiveFilter,
    setAllFiltersToBeCleared,
    clearAllFilters,
    setupInitialTempFilters,
    clearFilter,
    exportList,
    downloadingList,
  };
}
