import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

export const DatagridWrapper = ({ children }) => {
  const classes = styles();
  const sidebarOpen = useSelector(state => state.admin.ui.sidebarOpen);

  return (
    <div
      className={
        sidebarOpen ? classes.datagridBarOpen : classes.datagridBarClosed
      }
    >
      {children}
    </div>
  );
};

const styles = makeStyles(theme => ({
  datagridBarClosed: {
    maxWidth: "calc(100vw - 95px)",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: () => "100vw",
    },
  },
  datagridBarOpen: {
    maxWidth: "calc(100vw - 280px)",
    overflowX: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: () => "100vw",
    },
  },
}));
