import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { FamilyMembersSVG } from "../../../assets/FamilyMembersSVG";
import { paths } from "../../../types";
import { RootState } from "../../../types/state";

export function FamilyMembers() {
  const classes = styles();
  const { allow_family_accounts, family_members } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const [memberStr, setMemberStr] = useState<null | string>(null);

  useEffect(() => {
    if (Array.isArray(family_members) && family_members.length) {
      const str = family_members
        .map(({ first_name, last_name }) => `${first_name} ${last_name}`)
        .join(", ");
      setMemberStr(str);
    }
  }, [family_members]);

  if (!allow_family_accounts) return <></>;
  return (
    <div className={classes.container}>
      <h3 className={classes.title}>My family members</h3>

      <div className={classes.content}>
        <div className={classes.top}>
          <div className={classes.iconWrapper}>
            <FamilyMembersSVG />
          </div>
          <div className={classes.members}>
            {!memberStr ? "No family members" : memberStr}
          </div>
        </div>

        <div className={classes.txt}>
          To update your family members, navigate to{" "}
          <Link to={paths.VOLUNTEER_GUIDED_SETUP} className={classes.link}>
            campaign setup
          </Link>{" "}
          and proceed through the prompts until you reach the family members
          section.
        </div>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    marginBottom: 40,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.14,
    lineHeight: "22px",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.11,
    },
  },
  content: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    padding: 24,
  },
  top: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  iconWrapper: {
    height: 48,
    minWidth: 48,
    borderRadius: 8,
    backgroundColor: "#F1F9FF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
  members: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.11,
    lineHeight: "22px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      letterSpacing: 0.1,
      lineHeight: "20px",
    },
  },
  txt: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "18px",
    color: theme.palette.text.secondary2,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));
