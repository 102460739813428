import englishMessages from "ra-language-english";
import polyglotI18nProvider from "ra-i18n-polyglot";

englishMessages.ra.boolean.null = "Use Default";
englishMessages.ra.auth.auth_check_error =
  "Your session has expired, please log back in";

const messages = { ...englishMessages };

export const i18nProvider = polyglotI18nProvider(
  _locale => {
    return messages;
  },
  "en",
  { allowMissing: true },
);
