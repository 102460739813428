import { Fragment, useEffect } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Share } from "./components/Share";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/state";
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from "../../types";
import { dashboardDataActions, getCampaignInfoFetched } from "../../state";
import { ShareCardView } from "../../types/dashboard";
import { VolunteerOverview } from "./components/VolunteerOverview";
import { OrgCampaignOverview } from "./components/OrgCampaignOverview";
import { OrgHeader } from "./components/OrgHeader";
import { useIsMobile } from "../../hooks/ui";
import { MediaCenter } from "./components/MediaCenter";
const FULL_VIEW_MIN_WIDTH = 1200;

export function OrgDashboard() {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const sidebarOpen = useSelector(({ ui }: RootState) => ui.sidebarOpen);
  const campaignInfoFetched = useSelector(getCampaignInfoFetched);
  const display2Cols = useMediaQuery(
    `(min-width: ${FULL_VIEW_MIN_WIDTH +
      (sidebarOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH)}px)`,
  );

  useEffect(() => {
    if (campaignInfoFetched) {
      dispatch(dashboardDataActions.getVolunteerParticipationStats());
      dispatch(dashboardDataActions.getCampaignProgressStats());
      dispatch(dashboardDataActions.getCampaignDonationStats());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfoFetched]);

  return (
    <div className={classes.container}>
      <OrgHeader />
      {display2Cols && (
        <div className={classes.columns}>
          <div className={classes.leftColumn}>
            <VolunteerOverview />
            <OrgCampaignOverview />
          </div>
          <div className={classes.rightColumn}>
            <Share view={ShareCardView.orgSignUp} />
            <Share view={ShareCardView.orgShare} />
            <MediaCenter />
          </div>
        </div>
      )}
      {!display2Cols && (
        <Fragment>
          {!isMobile && <Share view={ShareCardView.orgSignUp} />}
          {!isMobile && <Share view={ShareCardView.orgShare} />}
          <VolunteerOverview />
          <OrgCampaignOverview />
          <MediaCenter />
        </Fragment>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: 1680,
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "0 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  columns: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  leftColumn: {
    width: "68%",
    marginRight: 32,
  },
  rightColumn: {
    width: "32%",
    minWidth: 315,
  },
}));
