import React, { useState, Fragment } from "react";
import {
  SimpleShowLayout,
  TextField,
  ReferenceField,
  FunctionField,
  ShowView,
  useNotify,
  Confirm,
  useRefresh,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@material-ui/core";
import {
  campaignTypeBooleans,
  paymentTypes,
  APIClient,
  generateAuthHeader,
  isVolunteer,
  isCompanyOrCompanyAdminOrRep,
} from "../../lib";
import { Products } from "./Products";
import { CheckForDonationShippingUpdates } from "../../components";

export function DonationShow(props) {
  const {
    id,
    volunteerLabel,
    record,
    record: {
      campaign_type_id,
      shippingAddress,
      tip,
      processing_fee,
      num_of_payments,
      ship_to_school,
    },
    basePath,
    associatedDonation,
    canManage,
    forExpand,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);
  const [showFulfilledWarning, setShowFulfilledWarning] = useState(false);
  const role = useSelector(state => state.user.role);
  const volunteerUser = isVolunteer(role);
  const coOrRepUser = isCompanyOrCompanyAdminOrRep(role);
  const { isRaffle, isProduct } = campaignTypeBooleans(campaign_type_id);

  const showProps = {
    basePath,
    loaded: true,
    loading: false,
    resource: "donations",
    version: 0,
    record,
  };

  const refreshList = () => {
    if (forExpand) {
      dispatch({
        type: "RA/TOGGLE_LIST_ITEM_EXPAND",
        payload: id,
        meta: {
          resource: associatedDonation ? "donations/associated" : "donations",
        },
      });
      setTimeout(() => {
        refresh();
      }, 100);
    } else {
      history.push("/donations");
    }
  };

  const deleteDonation = async () => {
    const res = await APIClient.delete(`donations/${id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, code } = res;
    if (error) {
      if (code === 113) {
        setShowFulfilledWarning(true);
        setOpenDialog(false);
      } else notify(errorMessage, "warning");
      return;
    }
    notify("Record successfully deleted");
    refreshList();
  };

  const Title = ({ record }) => (
    <span>
      {isProduct ? "Order" : "Donation"} #{record ? record.id : ""}
    </span>
  );

  return (
    <Fragment>
      <ShowView {...showProps} title={forExpand ? " " : <Title />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="first_name" />
          <TextField source="last_name" />
          <TextField source="display_name" />
          <TextField source="address" />
          <TextField source="apt" />
          <TextField source="city" />
          <TextField source="state" />
          <TextField source="zip" />
          <TextField source="email" />
          <TextField source="phone" />
          {shippingAddress && (
            <TextField source="ship_to_name" label="Ship To Name" />
          )}
          {shippingAddress && (
            <TextField
              source="shippingAddress.address"
              label="Shipping Address"
            />
          )}
          {shippingAddress && (
            <TextField source="shippingAddress.apt" label="Shipping Apt" />
          )}
          {shippingAddress && (
            <TextField source="shippingAddress.city" label="Shipping City" />
          )}
          {shippingAddress && (
            <TextField source="shippingAddress.state" label="Shipping State" />
          )}
          {shippingAddress && (
            <TextField source="shippingAddress.zip" label="Shipping Zip" />
          )}
          <FunctionField
            label={<span className={classes.label}>{volunteerLabel}</span>}
            render={({ volunteer }) =>
              volunteer ? volunteer.volunteerName : ""
            }
          />
          <TextField source="solicited_by" />
          <FunctionField
            label="Payment Type"
            render={record => paymentTypes[record.pmt_type]}
          />
          <TextField source="ccType" sortable={false} label="CC Type" />
          <TextField source="maskedCC" sortable={false} label="CC Last 4" />
          {isRaffle && (
            <ReferenceField
              label="Ticket package"
              source="ticket_package_id"
              reference="campaign_ticket_packages"
              link={false}
              allowEmpty={true}
            >
              <FunctionField
                label=" "
                render={record =>
                  `${record.num_of_tickets} tickets for $${record.price}`
                }
              />
            </ReferenceField>
          )}
          {isRaffle && (
            <FunctionField
              label="Additional Donation"
              render={({ additional_donation }) =>
                additional_donation ? `$${additional_donation}` : 0
              }
            />
          )}
          {!isProduct && (
            <FunctionField
              label={isRaffle ? "Total Donation" : "Donation Amount"}
              render={record => `$${record.total_amount}`}
            />
          )}
          {num_of_payments > 0 && (
            <FunctionField
              label="Payment Schedule"
              render={() => `Paid in ${num_of_payments} monthly installments`}
            />
          )}
          {isProduct && (
            <FunctionField
              label="Items Total"
              render={({ items_total }) =>
                items_total ? `$${items_total}` : ""
              }
            />
          )}
          {isProduct && (
            <FunctionField
              label="Shipping And Handling"
              render={({ shipping_and_handling }) =>
                !shipping_and_handling || shipping_and_handling === "0.00"
                  ? ""
                  : `$${Number(shipping_and_handling).toFixed(2)}`
              }
            />
          )}
          {isProduct && (
            <FunctionField
              label="Online Fee"
              render={({ online_fee }) => (online_fee ? `$${online_fee}` : "")}
            />
          )}
          {isProduct && (
            <FunctionField
              label="Custom Fee"
              render={({ custom_fee }) => (custom_fee ? `$${custom_fee}` : "")}
            />
          )}
          {isProduct && (
            <FunctionField
              label="Tax"
              render={({ total_tax }) => (total_tax ? `$${total_tax}` : "")}
            />
          )}
          {isProduct && (
            <FunctionField
              label="Additional Donation"
              render={({ additional_donation }) =>
                additional_donation ? `$${additional_donation}` : ""
              }
            />
          )}
          {tip > 0 && <FunctionField label="Tip" render={() => `$${tip}`} />}
          {processing_fee > 0 && (
            <FunctionField
              label="Processing Fee"
              render={() => `$${processing_fee}`}
            />
          )}
          {isProduct && (
            <FunctionField
              label="Total"
              render={({ charge_amount }) => `$${charge_amount}`}
            />
          )}
          {isProduct && (
            <FunctionField
              label="Campaign Credited"
              render={({ total_amount }) => `$${total_amount}`}
            />
          )}
          {!volunteerUser && (
            <TextField source="internal_notes" label="Internal Notes" />
          )}
          {coOrRepUser && (
            <TextField
              source="company_internal_notes"
              label="Company Internal Notes"
            />
          )}
          {isProduct && (
            <FunctionField
              label="Products"
              render={({ products }) => {
                return <Products products={products} />;
              }}
            />
          )}
          <FunctionField
            addLabel={false}
            render={({ images }) => {
              if (!Array.isArray(images) || !images.length) return null;
              return (
                <div>
                  <div className={classes.imgLabel}>
                    Images (click on a thumbnail to view full image)
                  </div>
                  {images.map((image, i) => (
                    <div key={i}>
                      <a href={image} target="_blank" rel="noopener noreferrer">
                        <img
                          alt="No Preview"
                          src={image}
                          className={classes.image}
                        />
                      </a>
                    </div>
                  ))}
                </div>
              );
            }}
          />
          <TextField source="external_id" label="External ID" />
          <TextField source="comments" label="Display message" />
          {isProduct && !ship_to_school && (
            <Fragment>
              <div className={classes.shippingButton}>
                <CheckForDonationShippingUpdates
                  donation_id={id}
                  callback={refreshList}
                />
              </div>
            </Fragment>
          )}
          {canManage && (
            <FunctionField
              addLabel={false}
              render={() => (
                <Button
                  className={classes.delete}
                  variant="contained"
                  onClick={() => setOpenDialog(true)}
                >
                  delete
                </Button>
              )}
            />
          )}
        </SimpleShowLayout>
      </ShowView>
      <Confirm
        isOpen={openDialog}
        title={`Are you sure?`}
        content={`Are you sure you want to delete sale #${id}`}
        translateOptions={false}
        onConfirm={deleteDonation}
        onClose={() => setOpenDialog(false)}
      />
      <FulfilledWarningDialog
        showFulfilledWarning={showFulfilledWarning}
        setShowFulfilledWarning={setShowFulfilledWarning}
        id={id}
        refreshList={refreshList}
      />
    </Fragment>
  );
}

function FulfilledWarningDialog({
  id,
  showFulfilledWarning,
  setShowFulfilledWarning,
  refreshList,
}) {
  const notify = useNotify();
  const [restockInventory, setRestockInventory] = useState(true);
  const [deleteFromShipstation, setDeleteFromShipstation] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => setShowFulfilledWarning(false);

  const onSubmit = async () => {
    setSubmitting(true);
    const post = {
      donation_id: Number(id),
      restock_inventory: restockInventory,
      delete_from_shipstation: deleteFromShipstation,
    };
    const res = await APIClient.post(
      "/donations/delete_fulfilled_order",
      post,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) {
      notify(errorMessage, { type: "warning" });
      setSubmitting(false);
    } else {
      notify("Record successfully deleted");
      refreshList();
    }
  };

  return (
    <Dialog open={showFulfilledWarning} onClose={handleClose}>
      <DialogTitle>
        This Order has already been fulfilled! Are you sure you want to delete
        it?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Delete options</DialogContentText>
        <RadioGroup
          value={restockInventory}
          onChange={({ target }) =>
            setRestockInventory(target.value === "true")
          }
          row
        >
          <FormControlLabel
            value={true}
            control={<Radio />}
            label="Delete and add back to Inventory"
          />
          <FormControlLabel
            value={false}
            control={<Radio />}
            label="Just Delete"
          />
        </RadioGroup>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={deleteFromShipstation}
              onChange={({ target }) =>
                setDeleteFromShipstation(target.checked)
              }
            />
          }
          label="Delete Order in ShipStation"
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          disabled={submitting}
          onClick={onSubmit}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = makeStyles(_theme => ({
  label: { textTransform: "capitalize" },
  imgLabel: {
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: 12,
    fontWeight: 400,
  },
  image: { maxHeight: 75, maxWidth: "100%", margin: "6px 0" },
  delete: { color: "#FFFFFF", backgroundColor: "red" },
  shippingButton: { margin: "16px 0 24px 0" },
}));
