import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";
import { FormApi } from "final-form";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  convertFileToB64,
  requiredField,
  stateOptions,
} from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  CircularLoader,
  NullableField,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
  WYSIWYGField,
} from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { ClickTooltip } from "../../../components/ui/ClickTooltip";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { PreviewEventEmail } from "./PreviewEventEmail";
import { NotificationSchedule } from "./NotificationSchedule";

type Props = {
  id: any;
  onClose: () => void;
  refreshList: () => void;
};

export function EditEventGroupEvent({
  id,
  onClose: _onClose,
  refreshList,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  useEffect(() => {
    const fetch = async () => {
      const url = `/event_group_events/${id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setInitialValues(data);
      setLoading(false);
    };
    if (id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = async (values: any, _form: FormApi, complete: any) => {
    const {
      emailLogo: _rm,
      id: _rm2,
      ticket_image: _rm3,
      imageFile,
      use_custom_event_notifications,
      event_group_event_notifications: EN,
      ...rest
    } = values;
    const event_group_event_notifications =
      !use_custom_event_notifications || !EN
        ? null
        : EN.map((e: GenericObject) => {
            const { id, days_before_event } = e;
            return {
              id: id ? id : null,
              days_before_event: Number(days_before_event),
            };
          });
    const update: GenericObject = {
      ...rest,
      use_custom_event_notifications,
      event_group_event_notifications,
    };
    if (imageFile) update.ticketImageBase64 = await convertFileToB64(imageFile);
    const url = `/event_group_events/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    refreshList();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div>Edit event</div>
          <IconButton className={classes.close} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        {loading && (
          <div className={classes.loadingWrapper}>
            <CircularLoader show />
          </div>
        )}

        {!loading && (
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, submitting, form, values, pristine }) => {
              return (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <div className={classes.mainContent}>
                    <div className={classes.activeContainer}>
                      <SwitchField fieldName="active" label="Active" />
                    </div>
                    <Field
                      component={TextFieldWrapper}
                      name="event_name"
                      label="Event name"
                      validate={requiredField}
                      className={classes.input}
                    />
                    <Field
                      component={TextFieldWrapper}
                      name="org_name"
                      label="Org name"
                      validate={requiredField}
                      className={classes.input}
                    />
                    <div className={classes.inputGroup}>
                      <Field
                        component={TextFieldWrapper}
                        name="date"
                        label="Date"
                        validate={requiredField}
                        className={classes.halfInput}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                      <Field
                        component={TextFieldWrapper}
                        name="time"
                        label="Time"
                        validate={requiredField}
                        className={classes.halfInput}
                        type="time"
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                    <Field
                      component={TextFieldWrapper}
                      name="location_name"
                      label="Location"
                      validate={requiredField}
                      className={classes.input}
                    />
                    <div className={classes.inputGroup}>
                      <Field
                        name="location_address"
                        label="Address"
                        component={TextFieldWrapper}
                        validate={requiredField}
                        className={classes.halfInput}
                      />
                      <Field
                        name="location_city"
                        label="City"
                        component={TextFieldWrapper}
                        validate={requiredField}
                        className={classes.halfInput}
                      />
                    </div>

                    <div className={classes.inputGroup}>
                      <Field
                        select
                        name="location_state"
                        label="State"
                        component={TextFieldWrapper}
                        validate={requiredField}
                        className={classes.halfInput}
                      >
                        {stateOptions.map(({ name, code }) => (
                          <MenuItem key={code} value={code}>
                            {name}
                          </MenuItem>
                        ))}
                      </Field>
                      <Field
                        name="location_zip"
                        component={TextFieldWrapper}
                        label="Zip"
                        validate={requiredField}
                        className={classes.halfInput}
                      />
                    </div>

                    <div className={classes.ticketConfigsHeader}>
                      Ticket configurations
                    </div>
                    <div className={classes.label}>Ticket image</div>
                    <div className={classes.ticketImgWrapper}>
                      <ImageDropzoneField
                        fieldName="imageFile"
                        currImage={values.ticket_image}
                        noDelete
                      />
                    </div>
                    <NullableField
                      component={TextFieldWrapper}
                      name="ticket_additional_info"
                      label="Additional display info (optional)"
                      className={classes.input}
                      type="textarea"
                      multiline
                      minRows={2}
                    />

                    <div className={classes.notificationsHeader}>
                      Event notifications
                    </div>

                    <div className={classes.notificationSubheader}>
                      Email notifications will be sent on behalf of participants
                      who have opted in to have this event's notifications sent.
                    </div>

                    <div className={classes.customNotificationsContainer}>
                      <SwitchField
                        fieldName="use_custom_event_notifications"
                        label="Use custom event notifications"
                      />
                      <div className={classes.infoIcon}></div>
                      <ClickTooltip content="By default notifications are sent according to the `group's` set schedule. Switch on to customize the notification schedule for this event." />
                    </div>

                    {values.use_custom_event_notifications && (
                      <Fragment>
                        <NotificationSchedule fieldName="event_group_event_notifications" />
                        <div className={classes.scheduleSpacer} />
                      </Fragment>
                    )}

                    <NullableField
                      component={TextFieldWrapper}
                      name="email_subject"
                      label="Email subject"
                      className={classes.input}
                    />
                    <NullableField
                      component={TextFieldWrapper}
                      name="email_link"
                      label="Link to purchase tickets"
                      className={classes.input}
                    />
                    <div className={classes.label}>Email body</div>
                    <WYSIWYGField
                      fieldName="email_template"
                      currentValue={values.email_template}
                      msg="Avoid leaving empty lines at the beginning or end of the email body."
                    />

                    <PreviewEventEmail formValues={values} />
                  </div>

                  <div className={classes.bottom}>
                    {!isMobile && (
                      <Button
                        variant="text"
                        disabled={submitting}
                        onClick={onClose}
                      >
                        CANCEL
                      </Button>
                    )}

                    <Button
                      color="primary"
                      className={classes.save}
                      disabled={submitting || pristine}
                      onClick={() => form.submit()}
                    >
                      Save
                      <ButtonSpinner show={submitting} />
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        )}
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 624,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  header: {
    position: "sticky",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    top: 0,
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      borderBottom: "none",
    },
  },
  close: {
    [theme.breakpoints.down("sm")]: {
      marginRight: -4,
      marginTop: -4,
    },
  },
  loadingWrapper: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    padding: "16px 24px 0 24px",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 0 16px",
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      width: "100%",
    },
  },
  activeContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
  },
  ticketConfigsHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 16,
    paddingTop: 8,
  },
  label: {
    color: theme.palette.text.secondary,
    paddingBottom: 8,
  },
  ticketImgWrapper: {
    width: "100%",
    marginBottom: 24,
  },
  notificationsHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 8,
    paddingTop: 8,
  },
  notificationSubheader: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px",
    },
  },
  customNotificationsContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    position: "relative",
  },
  infoIcon: {
    marginLeft: 8,
  },
  scheduleSpacer: {
    marginBottom: 24,
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
