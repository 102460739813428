import { makeStyles } from "@material-ui/core";

export const sharedStyles = makeStyles(theme => ({
  card: {
    padding: "24px 24px 8px 24px",
    backgroundColor: "#FFFFFF",
    width: "100%",
    height: "fit-content",
    maxWidth: "100%",
    color: theme.palette.text.secondary,
    marginBottom: 24,
    fontSize: 16,
    overflowWrap: "anywhere",
    letterSpacing: 0.17,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 8px 16px",
      width: "100%",
      fontSize: 14,
      letterSpacing: 0.15,
      marginBottom: 0,
      borderTop: "1px solid #EAEBF3",
    },
  },
  subheader: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.19,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.15,
    },
  },
}));
