import React from "react";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import { TextInput } from "../../components";
import {
  APIClient,
  generateAuthHeader,
  required,
  pwMatchValidation,
  composeValidators,
} from "../../lib";
import { useNotify } from "react-admin";
import { styles } from "./Profile.styles";

export function ChangePW() {
  const classes = styles();
  const notify = useNotify();

  const onSubmit = async values => {
    const res = await APIClient.post("/users/change-password", values, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage } = res;
    if (error) {
      return notify(errorMessage, "warning");
    }
    notify("your password has been successfully updated", { type: "info" });
  };

  return (
    <div className={classes.container}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextInput
                name="currentPassword"
                label="Current Password"
                validation={required}
                type="password"
              />
              <TextInput
                name="newPassword"
                label="New Password"
                validation={required}
                type="password"
              />
              <TextInput
                name="confirmPW"
                label="Confirm Password"
                validation={composeValidators(required, pwMatchValidation)}
                type="password"
              />

              <Button
                type="submit"
                disabled={submitting}
                className={classes.button}
              >
                <SaveIcon className={classes.saveIcon} />
                update
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}
