import React from "react";
import { useNotify, downloadCSV } from "react-admin";
import { Button, makeStyles } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import DownloadIcon from "@material-ui/icons/GetApp";
import { APIClient, generateAuthHeader } from "../lib";

export function CampaignDonationProductAggregatesCSV({
  campaign_id,
  postCloseout = false,
}) {
  const notify = useNotify();
  const classes = styles();

  const handleClick = async () => {
    const res = await APIClient.get(
      `/donation_products/campaign_aggregates?campaign_id=${campaign_id}&post_closeout=${postCloseout}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) {
      notify(errorMessage, "warning");
      return;
    }
    jsonExport(data, (_err, csv) => {
      downloadCSV(csv, `Campaign Product Sales #${campaign_id}`);
    });
  };

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      endIcon={<DownloadIcon />}
      className={classes.button}
      size="small"
    >
      product sales CSV
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  button: { marginLeft: 10 },
}));
