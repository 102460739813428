import { IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GenericObject } from "../../../types";
import { useSelector } from "react-redux";
import { getIsProductCampaign } from "../../../state";
import { ResponsiveModal } from "../../../components";

type Props = {
  onClose: () => void;
  failedRows: GenericObject[];
};
export function FailedUploads({ onClose, failedRows }: Props) {
  const classes = styles();
  const isProduct = useSelector(getIsProductCampaign);

  return (
    <ResponsiveModal isOpen={true} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <h2>
            Some of your {isProduct ? "orders" : "donations"} failed to upload
          </h2>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            <div className={classes.headerRow}>
              <div className={classes.left}>Row number</div>
              <div className={classes.right}>Error</div>
            </div>
            {failedRows.map((r, index) => {
              const { row_number, msg } = r;
              return (
                <div key={index} className={classes.row}>
                  <div className={classes.left}>{row_number}</div>
                  <div className={classes.right}>{msg}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: 600,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    borderBottom: "1px solid #DBDEEE",
    minHeight: 64,
    padding: "0 16px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      alignItems: "flex-start",
      lineHeight: "32px",
    },
  },
  contentWrapper: {
    width: "100%",
    padding: 24,
  },
  content: {
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    overflowWrap: "anywhere",
  },
  headerRow: {
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "10px 16px",
  },
  row: {
    minHeight: 40,
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #C9CDDE",
    padding: "10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "18px",
    overflowWrap: "anywhere",
  },
  left: {
    width: 102,
    minWidth: 102,
    overflowWrap: "anywhere",
    marginRight: 16,
  },
  right: {
    flex: 1,
    overflowWrap: "anywhere",
  },
}));
