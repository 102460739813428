import React, { useState, useEffect, Fragment } from "react";
import {
  Datagrid,
  TextField,
  ImageField,
  Filter,
  TextInput,
  SelectInput,
  useListController,
  ListView,
  Pagination,
  TopToolbar,
  Notification,
  useNotify,
  ExportButton,
  downloadCSV,
  BooleanInput,
  BooleanField,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton, Chip } from "@material-ui/core";
import { styles } from "./Product.styles";
import { ProductCreate } from "./ProductCreate";
import { ProductEdit } from "./ProductEdit";
import { VariantOptionsModal } from "./configurableProduct/VariantOptionsModal";
import {
  APIClient,
  generateAuthHeader,
  productStructureTypes,
} from "../../lib";
const asideModes = { CREATE: "add", EDIT: "edit", CLOSED: null };

const ProductFilter = props => {
  const classes = styles();
  const {
    productSources,
    productWarehouses,
    profitCategories,
    productCategories,
    ...rest
  } = props;
  return (
    <Filter {...rest}>
      <TextInput
        label="Name/ID"
        source="q"
        alwaysOn
        className={classes.medInput}
      />
      <TextInput
        label="Sku"
        source="sku"
        alwaysOn
        className={classes.smallInput}
      />
      <SelectInput
        label="Category"
        source="category_id"
        choices={productCategories}
        translateChoice={false}
        className={classes.medInput}
        resettable
        alwaysOn
      />
      <SelectInput
        source="source_id"
        label="Source"
        choices={productSources}
        translateChoice={false}
        resettable
        alwaysOn
        className={classes.medInput}
      />
      <SelectInput
        source="warehouse_id"
        label="Warehouse"
        choices={productWarehouses}
        translateChoice={false}
        resettable
        alwaysOn
        className={classes.medInput}
      />
      <SelectInput
        source="profit_category_id"
        label="Profit Category"
        choices={profitCategories}
        translateChoice={false}
        resettable
        alwaysOn
      />
      <BooleanInput source="include_inactive" label="Incl. Inactive" alwaysOn />
      <BooleanInput source="exclude_variants" label="Excl. Variants" alwaysOn />
    </Filter>
  );
};

export function ProductList(props) {
  const { record: { id: supplier_id } = {} } = props;
  const classes = styles();
  const notify = useNotify();
  const [asideMode, setAsideMode] = useState(asideModes.CLOSED);
  const [activeProductId, setActiveProductId] = useState(null);
  const [productSources, setProductSources] = useState([]);
  const [productWarehouses, setProductWarehouses] = useState([]);
  const [profitCategories, setProfitCategories] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [showVariantOptionsModal, setShowVariantOptionsModal] = useState(false);

  useEffect(() => {
    if (supplier_id) {
      fetchProductSources();
      fetchProductWarehouses();
      fetchProfitCategories();
      fetchProductCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const fetchProductSources = async () => {
    const res = await APIClient.get(
      `/product_sources/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductSources(data);
  };

  const fetchProductWarehouses = async () => {
    const res = await APIClient.get(
      `/product_warehouses/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductWarehouses(data);
  };

  const fetchProfitCategories = async () => {
    const res = await APIClient.get(
      `/profit_categories/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProfitCategories(data);
  };

  const fetchProductCategories = async () => {
    const res = await APIClient.get(
      `/product_categories/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductCategories(data);
  };

  const controllerProps = useListController({
    sort: { field: "product_name", order: "ASC" },
    basePath: "",
    resource: "products",
    filter: { supplier_id },
    perPage: 25,
    syncWithLocation: false,
  });

  const closeAside = () => {
    setAsideMode(asideModes.CLOSED);
    controllerProps.refetch();
  };

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        className={classes.addButton}
        onClick={() => setAsideMode(asideModes.CREATE)}
      >
        add product
      </Button>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues, supplier_id }}
        exporter={productExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  const onRowClick = id => {
    setActiveProductId(id);
    setAsideMode(asideModes.EDIT);
  };

  return (
    <Fragment>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={
          <ProductFilter
            {...{
              productSources,
              productWarehouses,
              profitCategories,
              productCategories,
            }}
          />
        }
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" />
          <TextField source="sku" />
          <TextField source="product_name" label="Name" />
          <ImageField
            source="thumbnail_image"
            label="Thumbnail"
            sortable={false}
            classes={{ image: classes.thumbnail }}
          />

          <ArrayField source="categories" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="name" size="small" />
            </SingleFieldList>
          </ArrayField>

          <TextField source="product_price" label="Price" />
          <BooleanField source="active" />
          <TextField source="structure_type" label="Structure Type" />
          <TextField source="product_parent_id" label="Parent ID" />
          <FunctionField
            addLabel={false}
            render={({ id, structure_type, is_deprecated }) => {
              if (
                structure_type === productStructureTypes.VARIANT &&
                is_deprecated
              ) {
                return <Chip label="Deprecated Variant" size="small" />;
              }
              if (structure_type !== productStructureTypes.CONFIGURABLE) {
                return <></>;
              }
              return (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={e => {
                    e.stopPropagation();
                    setActiveProductId(id);
                    setShowVariantOptionsModal(true);
                  }}
                >
                  Edit variants
                </Button>
              );
            }}
          />
        </Datagrid>
      </ListView>

      <Drawer open={Boolean(asideMode)} anchor="right">
        <div className={classes.productDrawer}>
          <div className={classes.drawerHeader}>
            <div>
              {asideMode === asideModes.CREATE
                ? "Add a Product"
                : "Edit Product"}
            </div>
            <IconButton
              onClick={() => setAsideMode(asideModes.CLOSED)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {asideMode === asideModes.CREATE && (
            <ProductCreate
              supplier_id={supplier_id}
              closeAside={closeAside}
              productSources={productSources}
              productWarehouses={productWarehouses}
              profitCategories={profitCategories}
              productCategories={productCategories}
            />
          )}

          {asideMode === asideModes.EDIT && (
            <ProductEdit
              id={activeProductId}
              supplier_id={supplier_id}
              closeAside={closeAside}
              productSources={productSources}
              productWarehouses={productWarehouses}
              profitCategories={profitCategories}
              productCategories={productCategories}
            />
          )}
        </div>
        <Notification />
      </Drawer>
      {showVariantOptionsModal && (
        <VariantOptionsModal
          id={activeProductId}
          toggleModal={setShowVariantOptionsModal}
        />
      )}
    </Fragment>
  );
}

function productExporter(products) {
  const formattedProducts = products.map(product => {
    const {
      active,
      bin,
      categoryString,
      custom_tag,
      id,
      is_back_ordered,
      items_per_case,
      product_name,
      product_price,
      profit_amount,
      profit_category_id,
      remove_from_store,
      ship_to_school_only,
      ship_to_home_only,
      sku,
      source_id,
      source_product_id,
      source_price,
      stock_qty,
      taxjar_tax_code,
      manual_reporting_tax_exempt,
      warehouse_id,
      weight_ounces,
      externally_fulfilled,
      no_shipping_fees,
      UPCs,
      product_description,
      structure_type,
      product_parent_id,
      is_deprecated,
    } = product;
    const exportObj = {
      ID: id,
      Sku: sku,
      Name: product_name,
      Active: active,
      Categories: categoryString,
      warehouse_id,
      source_id,
      source_product_id,
      source_price,
      bin,
      custom_tag,
      product_price,
      "Current Stock Qty": `${stock_qty}`,
      taxjar_tax_code,
      manual_reporting_tax_exempt,
      weight_ounces,
      externally_fulfilled,
      no_shipping_fees,
      items_per_case,
      profit_category_id,
      profit_amount,
      remove_from_store,
      ship_to_school_only,
      ship_to_home_only,
      is_back_ordered,
      structure_type,
      product_parent_id,
      is_deprecated,
      UPCs,
      product_description: product_description
        ? product_description.replace(/<\/?[^>]+(>|$)/g, "")
        : null,
    };
    return exportObj;
  });
  jsonExport(formattedProducts, (err, csv) => {
    downloadCSV(csv, "Products");
  });
}
