import { makeStyles } from "@material-ui/core";

export const sharedStyles = makeStyles(theme => ({
  screenContainer: {
    width: 532,
    padding: "0 16px 28px 16px",
    maxWidth: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  fullWidthScreenContainer: {
    width: 500,
    padding: "0 0 28px 0",
    maxWidth: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  screenHeader: {
    fontSize: 34,
    letterSpacing: 0.25,
    lineHeight: "42px",
    textAlign: "center",
    marginTop: 46,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "37px",
      letterSpacing: 0.21,
      marginTop: 32,
      marginBottom: 16,
    },
  },
  screenParagraph: {
    fontSize: 20,
    letterSpacing: 0.15,
    lineHeight: "32px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "26px",
      letterSpacing: 0.12,
    },
  },
  tooltipWrapper: {
    position: "relative",
  },
}));
