import { Button, makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useSelector } from "react-redux";
import { getCampaignInfoFetched, getIsProductCampaign } from "../../../state";
import { paths } from "../../../types";
import { useHistory } from "react-router-dom";
import { DonationStats } from "./DonationStats";

export function VolunteerDonations() {
  const isProduct = useSelector(getIsProductCampaign);
  const history = useHistory();
  const campaignInfoFetched = useSelector(getCampaignInfoFetched);

  const classes = styles();

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.title}>
          {!campaignInfoFetched ? "" : isProduct ? "Sales" : "Donations"}
        </div>
        <Button
          variant="text"
          color="primary"
          size="small"
          endIcon={<NavigateNextIcon />}
          className={classes.button}
          onClick={() => history.push(paths.DONATIONS)}
        >
          VIEW{!campaignInfoFetched ? "" : isProduct ? " SALES" : " DONATIONS"}
        </Button>
      </div>

      <div className={classes.content}>
        <DonationStats />
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    marginBottom: 40,
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.14,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.11,
    },
  },
  button: {
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
  },
  content: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      backgroundColor: "unset",
      padding: "0",
    },
  },
}));
