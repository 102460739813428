import { useState } from "react";
import { IconButton, makeStyles, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonSpinner, ResponsiveModal } from "..";
import { useDrawerTransition, useIsMobile } from "../../hooks/ui";
import { RedButton } from "./RedButton";

type Props = {
  onClose: () => void;
  actionFunc: (
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
    onClose: () => void,
  ) => void;
  title: string;
  subtitle?: string;
  actionLabel: string;
  redButton?: boolean;
};

export function ConfirmationDialog({
  onClose: _onClose,
  actionFunc,
  title,
  subtitle,
  actionLabel,
  redButton,
}: Props) {
  const classes = styles();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [submitting, setSubmitting] = useState(false);
  const isMobile = useIsMobile();

  const onAction = () => {
    actionFunc(setSubmitting, onClose);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.closeWrapper}>
            <IconButton className={classes.close} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}

        <h1 className={classes.title}>{title}</h1>
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}

        <div className={classes.buttons}>
          <Button
            variant={isMobile ? "contained" : "text"}
            className={classes.cancel}
            onClick={onClose}
            disabled={submitting}
            color={isMobile ? "secondary" : "default"}
          >
            Cancel
          </Button>

          {redButton && (
            <RedButton
              variant={isMobile ? "contained" : "text"}
              className={classes.actionButton}
              disabled={submitting}
              onClick={onAction}
            >
              {actionLabel}
              <ButtonSpinner show={submitting} isRedButton />
            </RedButton>
          )}

          {!redButton && (
            <Button
              color="primary"
              variant={isMobile ? "contained" : "text"}
              className={classes.actionButton}
              disabled={submitting}
              onClick={onAction}
            >
              {actionLabel}
              <ButtonSpinner show={submitting} />
            </Button>
          )}
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      height: "fit-content",
    },
  },
  closeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  close: {
    padding: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    padding: "16px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 0 16px",
    },
  },
  subtitle: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
    padding: "16px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 0 16px",
    },
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 48,
    marginBottom: 12,
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 16px",
    },
  },
  actionButton: {
    borderRadius: 4,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      textTransform: "none",
    },
  },
  cancel: {
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 8,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      marginRight: 16,
      color: theme.palette.secondary.contrastText,
      textTransform: "none",
    },
  },
}));
