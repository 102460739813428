import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getVolunteerLabel } from "../../state";
import {
  VolunteerFilterFields,
  VolunteerQueryParams,
  volunteerSearchFields,
  volunteerSortFields,
} from "../../types";
import {
  useIsDesktop,
  useIsMobile,
  useScrollToTop,
  useSetTopBarTitle,
} from "../../hooks/ui";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDownOutlined";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import {
  FilterChips,
  FilterDrawerButton,
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../components";
import { VolunteerRows } from "./components/VolunteerRows";
import { useList } from "../../hooks/list";
import { ActionsMenu } from "./components/ActionsMenu";
import { RootState } from "../../types/state";
import { Filters } from "./components/Filters";
import { AddVolunteer } from "./components/AddVolunteer";
import { BulkActions } from "./components/BulkActions";
import { NoStudentsSVG } from "../../assets";

export function Volunteers() {
  const { campaignId, campaign_type_id } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  const classes = styles();
  const {
    volunteerLabel,
    volunteerLabelLC,
    volunteerLabelLCSingular,
    volunteerLabelSing,
  } = useSelector(getVolunteerLabel);
  const [isReady, setIsReady] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showBulkActions, setShowBulkActions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [anchorEl, setAnchorEl] = useState<undefined | Element>(undefined);
  const searchFields = useMemo(
    () => volunteerSearchFields(volunteerLabelLCSingular),
    [volunteerLabelLCSingular],
  );

  const listProps = useList<VolunteerQueryParams>({
    baseUrl: "/volunteers/campaign_volunteer_list",
    isReady,
    initialState: {
      sort: "last_name",
      order: "asc",
      campaign_id: campaignId,
      campaign_type_id,
    },
    filterFields: VolunteerFilterFields,
  });
  const {
    listIsFiltered,
    total,
    noResults,
    showRows,
    refreshList,
    params,
    setParams,
    hasSelectedRows,
    filterDrawerActive,
  } = listProps;

  useEffect(() => {
    if (campaignId && campaign_type_id) {
      setParams(prev => {
        return {
          ...prev,
          campaign_id: campaignId,
          campaign_type_id,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, campaign_type_id]);

  useEffect(() => {
    if (params.campaign_id && params.campaign_type_id) setIsReady(true);
  }, [params.campaign_id, params.campaign_type_id]);

  useScrollToTop();
  useSetTopBarTitle(<h1 className={classes.title}>{volunteerLabel}</h1>, [
    volunteerLabel,
  ]);

  const handleActionsClick = (e: any, openBulkAction = false) => {
    setAnchorEl(e.currentTarget);
    if (openBulkAction) setShowBulkActions(true);
    else setShowActions(true);
  };

  return (
    <ListContainer listProps={listProps}>
      {isMobile && (
        <div className={classes.topSection}>
          <div>
            <h1 className={classes.title}>{volunteerLabel}</h1>
            <div className={classes.count}>
              {total} {volunteerLabelLCSingular}
              {total !== 1 ? "s" : ""}
            </div>
          </div>
          <div>
            <IconButton
              onClick={() => setShowSort(true)}
              className={classes.sort}
            >
              <SwapVertIcon />
            </IconButton>
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
            <IconButton
              onClick={() => setShowAdd(true)}
              className={classes.add}
            >
              <AddIcon />
            </IconButton>
            <IconButton onClick={() => setShowActions(true)}>
              <MoreHorizIcon />
            </IconButton>
          </div>
        </div>
      )}

      <div className={classes.searchAndActionsRow}>
        <MultiSearch listProps={listProps} fields={searchFields} />
        {isDeskTop && (
          <div className={classes.actions}>
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setShowAdd(true)}
              className={classes.add}
            >
              {volunteerLabelSing}
            </Button>
            <Button
              color="secondary"
              endIcon={<ArrowDropDownIcon />}
              className={classes.actionsButton}
              onClick={handleActionsClick}
            >
              More actions
            </Button>
            <Button
              color="secondary"
              endIcon={<ArrowDropDownIcon />}
              className={classes.bulkButton}
              onClick={e => handleActionsClick(e, true)}
              disabled={!hasSelectedRows}
            >
              Bulk actions
            </Button>
          </div>
        )}
      </div>

      <FilterChips listProps={listProps} />

      {noResults && (
        <ListNoResults
          SVG={NoStudentsSVG}
          listIsFiltered={listIsFiltered}
          label={volunteerLabelLC}
          buttonLabel={`Add ${volunteerLabelLCSingular}`}
          buttonFunc={() => setShowAdd(true)}
        />
      )}

      {showRows && <VolunteerRows listProps={listProps} />}

      <ActionsMenu
        isOpen={showActions}
        onClose={() => setShowActions(false)}
        anchorEl={anchorEl}
        listProps={listProps}
      />
      <BulkActions
        isOpen={showBulkActions}
        onClose={() => setShowBulkActions(false)}
        anchorEl={anchorEl}
        refreshData={refreshList}
        listProps={listProps}
      />
      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={volunteerSortFields}
        />
      )}
      {showFilter && (
        <Filters onClose={() => setShowFilter(false)} listProps={listProps} />
      )}
      {showAdd && (
        <AddVolunteer
          onClose={() => setShowAdd(false)}
          refreshList={refreshList}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0 8px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  sort: {
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
    },
  },
  add: {
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
      marginLeft: 8,
    },
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    [theme.breakpoints.only("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  actions: {
    [theme.breakpoints.only("md")]: {
      marginTop: 24,
    },
  },
  actionsButton: {
    width: 160,
    marginLeft: 16,
  },
  bulkButton: {
    width: 146,
    marginLeft: 16,
  },
}));
