import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, requiredField } from "../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../types";
import { setToast } from "../../state";
import {
  ButtonSpinner,
  CircularLoader,
  ResponsiveModal,
  TextFieldWrapper,
} from "..";
import { useDrawerTransition, useIsMobile } from "../../hooks/ui";
import { theme } from "../../theme/theme";

type Props = {
  onClose: () => void;
  ticketId: any;
};

export function EditEventTicket({ onClose: _onClose, ticketId }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<GenericObject>({});

  useEffect(() => {
    const fetch = async () => {
      const url = `campaign_event_tickets/${ticketId}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setInitialValues(data);
      setLoading(false);
    };
    if (ticketId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const { id: _rm, event_name: _rm2, ...rest } = values;
    const url = `/campaign_event_tickets/${ticketId}`;
    const res = await APIAuthClient.put<any, APIRes>(url, rest);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveModal isOpen={isOpen} onClose={onClose}>
        <div className={classes.container}>
          <div className={classes.top}>
            <h1>Edit event ticket</h1>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          {loading && (
            <div className={classes.loadingWrapper}>
              <CircularLoader show />
            </div>
          )}

          {!loading && (
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ handleSubmit, submitting, form }) => {
                return (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <div className={classes.mainContent}>
                      <Field
                        component={TextFieldWrapper}
                        name="event_name"
                        label="Event name"
                        disabled
                        className={classes.input}
                      />
                      <Field
                        select
                        component={TextFieldWrapper}
                        name="number_of_tickets"
                        label="Number of tickets"
                        validate={requiredField}
                        className={classes.input}
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                          <MenuItem key={num} value={num}>
                            {num}
                          </MenuItem>
                        ))}
                      </Field>
                    </div>

                    <div className={classes.bottom}>
                      {!isMobile && (
                        <Button
                          variant="text"
                          disabled={submitting}
                          onClick={onClose}
                        >
                          CANCEL
                        </Button>
                      )}
                      <Button
                        color="primary"
                        className={classes.save}
                        disabled={submitting}
                        onClick={() => form.submit()}
                      >
                        Save
                        <ButtonSpinner show={submitting} />
                      </Button>
                    </div>
                  </form>
                );
              }}
            />
          )}
        </div>
      </ResponsiveModal>
    </ThemeProvider>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 650,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100vw",
    minHeight: 340,
  },
  loadingWrapper: {
    paddingTop: 50,
  },
  top: {
    padding: "24px 18px 24px 24px",
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  mainContent: {
    flex: 1,
    width: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
