import { makeStyles, Button } from "@material-ui/core";
import { Fragment } from "react";
import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { TextFieldWrapper } from "../../../components";
import { requiredPositiveInteger } from "../../../lib";

export function NotificationSchedule({ fieldName }: { fieldName: string }) {
  const classes = styles();

  return (
    <FieldArray name={fieldName}>
      {({ fields }) => {
        const length = fields.length ? fields.length : 0;
        return (
          <Fragment>
            {fields.map((row, index) => {
              return (
                <div key={index} className={classes.notificationRow}>
                  <Field
                    component={TextFieldWrapper}
                    name={`${row}.days_before_event`}
                    validate={requiredPositiveInteger}
                    className={classes.daysInput}
                    type="number"
                    InputLabelProps={{ shrink: true }}
                  />
                  <div className={classes.daysBefore}>days before event</div>
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={() => fields.remove(index)}
                  >
                    REMOVE
                  </Button>
                </div>
              );
            })}
            <Button
              startIcon={<AddIcon />}
              variant="text"
              color="primary"
              onClick={() => fields.push({})}
              disabled={length > 1}
            >
              ADD NOTIFICATION
            </Button>
          </Fragment>
        );
      }}
    </FieldArray>
  );
}

const styles = makeStyles(_theme => ({
  notificationRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
  },
  daysInput: {
    width: 66,
  },
  daysBefore: {
    fontSize: 16,
    letterSpacing: 0.15,
    padding: "0 16px",
  },
}));
