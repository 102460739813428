export function TeamSVG() {
  return (
    <svg width="150px" height="136px" viewBox="0 0 150 136">
      <title>Team</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-113, -64)">
          <g transform="translate(113, 64)">
            <path
              d="M129.378307,115.235445 L19.728307,115.235445 L19.728307,96.6204449 L21.420307,96.6204449 L21.420307,82.7644449 C21.428307,75.9674449 26.936307,70.4594449 33.733307,70.4514449 L115.572307,70.4514449 C122.370307,70.4594449 127.878307,75.9674449 127.886307,82.7644449 L127.886307,96.6204449 L129.378307,96.6204449 L129.378307,115.235445 Z"
              fill="#E6E6E6"
            ></path>
            <path
              d="M132.349307,135.724045 L126.891307,135.724045 L126.891307,135.624045 C126.879307,125.573045 118.734307,117.427045 108.682307,117.416045 L40.425307,117.416045 C30.373307,117.427045 22.227307,125.573045 22.216307,135.624045 L22.216307,135.724045 L16.757307,135.724045 C14.168307,135.721045 12.070307,133.623045 12.067307,131.033045 L12.067307,100.604045 C10.482307,99.7080449 9.19330702,94.8450449 10.030307,90.9600449 C10.555307,88.5230449 12.073307,85.5790449 16.316307,85.3970449 C20.446307,85.2190449 23.512307,89.0970449 23.786307,92.8300449 C23.934307,94.7940449 23.357307,96.7440449 22.164307,98.3120449 L22.216307,98.3120449 L22.216307,102.653045 C31.173307,99.7830449 40.142307,98.3220449 48.877307,98.3120449 C57.815307,98.3200449 66.692307,99.7980449 75.151307,102.684045 C92.740307,96.9960449 110.146307,96.8850449 126.891307,102.357045 L126.891307,98.3120449 L126.942307,98.3120449 C125.749307,96.7440449 125.172307,94.7940449 125.321307,92.8300449 C125.621307,88.7350449 128.970307,85.4010449 132.786307,85.3970449 L132.799307,85.3970449 C137.299307,85.3970449 138.891307,88.1060449 139.434307,90.3800449 C140.395307,94.4040449 138.631307,99.7010449 137.040307,100.604045 L137.040307,131.033045 C137.037307,133.623045 134.938307,135.721045 132.349307,135.724045"
              fill="#D8D8D8"
            ></path>
            <polygon
              fill="#FFD1D2"
              points="101.853507 127.581345 104.488507 129.463345 113.001507 120.196345 109.113507 117.419345"
            ></polygon>
            <path
              d="M101.796007,126.241045 L102.538007,126.771045 L106.274007,127.662045 L106.985007,129.948045 L106.985007,129.948045 C108.811007,131.252045 109.234007,133.790045 107.929007,135.617045 L107.853007,135.724045 L99.357007,129.655045 L101.796007,126.241045 Z"
              fill="#212121"
            ></path>
            <path
              d="M119.707707,102.771345 L100.361707,102.771345 C96.603707,102.768345 93.559707,99.7193449 93.5627048,95.9613449 C93.565707,92.2073449 96.607707,89.1653449 100.361707,89.1623449 L119.707707,89.1623449 C123.465707,89.1643449 126.510707,92.2123449 126.508708,95.9703449 C126.507707,99.7253449 123.463707,102.769345 119.707707,102.771345"
              fill="#212121"
            ></path>
            <path
              d="M110.216007,55.2567449 L113.336007,57.6327449 L117.100007,58.7897449 C117.100007,58.7897449 118.656007,80.6737449 120.057007,82.0757449 C121.459007,83.4777449 120.195007,83.5147449 120.477007,85.3357449 C120.758007,87.1577449 121.443007,90.8907449 121.443007,90.8907449 C111.980007,95.8997449 103.823007,96.0337449 97.270007,90.1727449 C97.097007,89.4257449 97.468007,88.6587449 98.161007,88.3297449 C99.380007,87.6827449 97.036007,84.6627449 98.033007,84.1577449 C99.030007,83.6527449 97.628007,79.2717449 97.628007,79.2717449 L98.547007,70.1317449 L97.821007,68.6707449 L99.594007,59.7307449 L102.670007,58.6067449 L103.899007,55.6457449 L110.216007,55.2567449 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M112.170807,34.8248449 C112.117807,36.3178449 113.285807,37.5708449 114.778807,37.6238449 C114.937807,37.6298449 115.096807,37.6208449 115.254807,37.5988449 L119.652807,46.1468449 L122.102807,41.7938449 L117.550807,34.3438449 C117.241807,32.8738449 115.799807,31.9328449 114.329807,32.2418449 C113.099807,32.5008449 112.206807,33.5688449 112.170807,34.8248449"
              fill="#FFD1D2"
            ></path>
            <path
              d="M119.577107,36.9159449 L129.010107,51.5349449 L128.978107,51.6029449 C128.943107,51.6799449 126.807107,56.2929449 124.737107,60.5179449 C124.418107,61.1689449 124.101107,61.8109449 123.794107,62.4249449 C123.661107,62.6899449 123.530107,62.9499449 123.401107,63.2039449 C122.532107,64.9169449 121.772107,66.3339449 121.319107,67.0069449 C120.316107,68.5279449 118.823107,69.6609449 117.088107,70.2159449 L117.045107,70.2279449 L117.003107,70.2099449 C115.254107,69.4719449 114.252107,67.6199449 114.592107,65.7529449 C115.410107,61.3809449 117.233107,57.2589449 119.917107,53.7129449 L121.446107,51.6969449 L116.132107,40.1439449 L119.577107,36.9159449 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M103.280307,33.0338449 C103.333307,34.5268449 102.165307,35.7798449 100.672307,35.8328449 C100.513307,35.8388449 100.354307,35.8298449 100.196307,35.8078449 L95.798307,44.3558449 L93.348307,40.0028449 L97.900307,32.5528449 C98.209307,31.0828449 99.651307,30.1418449 101.121307,30.4508449 C102.351307,30.7098449 103.244307,31.7778449 103.280307,33.0338449 Z"
              fill="#FFD1D2"
            ></path>
            <path
              d="M95.874007,35.1249449 L86.441007,49.7439449 L86.473007,49.8119449 C86.508007,49.8889449 88.644007,54.5019449 90.714007,58.7269449 C91.033007,59.3779449 91.350007,60.0199449 91.657007,60.6339449 C91.790007,60.8989449 91.921007,61.1589449 92.050007,61.4129449 C92.919007,63.1259449 93.679007,64.5429449 94.132007,65.2159449 C95.135007,66.7369449 96.628007,67.8699449 98.363007,68.4249449 L98.406007,68.4369449 L98.448007,68.4189449 C100.197007,67.6809449 101.199007,65.8289449 100.859007,63.9619449 C100.041007,59.5899449 98.218007,55.4679449 95.534007,51.9219449 L94.005007,49.9059449 L99.319007,38.3529449 L95.874007,35.1249449 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M107.021507,41.5894449 C110.430507,41.5894449 113.193507,44.3524449 113.193507,47.7614449 C113.193507,51.1704449 110.430507,53.9344449 107.021507,53.9344449 C103.612507,53.9344449 100.849507,51.1704449 100.849507,47.7614449 C100.849507,44.3524449 103.612507,41.5894449 107.021507,41.5894449"
              fill="#FFD1D2"
            ></path>
            <path
              d="M101.994007,42.4591449 C102.502007,40.9821449 105.028007,39.9581449 109.125007,39.8161449 C113.221007,39.6731449 114.260007,44.5011449 114.260007,44.5011449 C116.995007,46.7191449 111.340007,52.6581449 111.067007,52.4441449 L111.190007,51.3291449 C111.393007,49.4801449 112.259007,48.0381449 111.158007,46.1121449 L110.101007,46.1241449 L108.097007,46.5091449 C107.782007,46.5701449 107.478007,46.3641449 107.418007,46.0491449 C107.379007,45.8481449 107.450007,45.6401449 107.604007,45.5051449 L107.622007,45.4891449 L107.577007,45.4181449 C106.870007,45.7421449 106.143007,46.0421449 105.371007,46.0881449 C104.502007,46.1391449 103.552007,45.7931449 103.136007,45.0291449 C103.025007,44.8191449 102.957007,44.5891449 102.937007,44.3521449 C101.765007,44.8201449 101.230007,46.2641449 101.230007,46.2641449 C101.230007,46.2641449 101.486007,43.9361449 101.994007,42.4591449"
              fill="#212121"
            ></path>
            <polygon
              fill="#FFD1D2"
              points="118.664307 124.955745 116.193307 127.047745 106.946307 118.512745 110.593307 115.424745"
            ></polygon>
            <path
              d="M121.322807,126.817145 L113.354807,133.564145 L113.269807,133.463145 C111.819807,131.750145 112.031807,129.186145 113.744807,127.736145 L113.744807,127.736145 L114.265807,125.399145 L117.915807,124.204145 L118.611807,123.615145 L121.322807,126.817145 Z"
              fill="#212121"
            ></path>
            <path
              d="M103.392807,101.354045 L105.434807,96.9250449 C105.412807,92.8850449 102.030807,89.6030449 97.997807,89.8570449 C95.752807,89.9980449 93.649807,91.2540449 92.780807,94.9870449 C90.546807,104.585045 112.466807,124.561045 112.466807,124.561045 L116.494807,122.072045 L116.679807,119.442045 L114.424807,118.052045 L114.422807,115.809045 L112.271807,114.789045 L103.392807,101.354045 Z"
              fill="#212121"
            ></path>
            <path
              d="M116.811207,100.134245 L114.286207,96.4222449 C113.977207,92.3942449 117.078207,88.8452449 121.117207,88.7662449 C123.367207,88.7222449 125.566207,89.8012449 126.738207,93.4502449 C129.754207,102.832245 109.551207,124.542245 109.551207,124.542245 L105.332207,122.393245 L104.931207,119.787245 L107.065207,118.216245 L106.882207,115.981245 L108.942207,114.788245 L116.811207,100.134245 Z"
              fill="#212121"
            ></path>
            <polygon
              fill="#FFFFFF"
              points="107.925107 69.7350449 107.925107 70.9210449 103.993107 70.9210449 103.993107 69.7350449 105.211107 69.7350449 105.211107 66.8770449 104.072107 67.5300449 104.072107 66.2480449 105.824107 65.2530449 106.699107 65.2530449 106.699107 69.7350449"
            ></polygon>
            <path
              d="M110.973707,69.4524449 C111.107707,69.0074449 111.164707,68.5434449 111.140707,68.0794449 C111.163707,67.6244449 111.107707,67.1694449 110.973707,66.7344449 C110.886707,66.4304449 110.570707,66.2554449 110.266707,66.3424449 C110.077707,66.3964449 109.929707,66.5444449 109.875707,66.7344449 C109.741707,67.1694449 109.684707,67.6244449 109.707707,68.0794449 C109.684707,68.5434449 109.740707,69.0074449 109.875707,69.4524449 C109.958707,69.7554449 110.272707,69.9334449 110.576707,69.8494449 C110.769707,69.7964449 110.920707,69.6454449 110.973707,69.4524449 M108.812707,70.2444449 C108.438707,69.7454449 108.251707,69.0244449 108.251707,68.0794449 C108.251707,67.1504449 108.438707,66.4384449 108.812707,65.9424449 C109.570707,65.0514449 110.907707,64.9454449 111.797707,65.7034449 C111.883707,65.7764449 111.963707,65.8564449 112.036707,65.9424449 C112.410707,66.4384449 112.597707,67.1504449 112.597707,68.0794449 C112.597707,69.0234449 112.410707,69.7454449 112.036707,70.2444449 C111.283707,71.1344449 109.952707,71.2464449 109.062707,70.4944449 C108.971707,70.4184449 108.888707,70.3344449 108.812707,70.2444449"
              fill="#FFFFFF"
            ></path>
            <polygon
              fill="#A0616A"
              points="65.990107 132.574045 62.971107 132.574045 61.534107 120.927045 65.991107 120.927045"
            ></polygon>
            <path
              d="M66.096407,135.724045 L56.811407,135.724045 L56.811407,135.606045 C56.811407,133.610045 58.429407,131.992045 60.425407,131.992045 L60.425407,131.992045 L62.121407,130.705045 L65.286407,131.992045 L66.096407,131.992045 L66.096407,135.724045 Z"
              fill="#212121"
            ></path>
            <polygon
              fill="#A0616A"
              points="87.354907 132.574045 90.373907 132.574045 91.810907 120.927045 87.353907 120.927045"
            ></polygon>
            <path
              d="M87.248407,135.724045 L96.533407,135.724045 L96.533407,135.606045 C96.533407,133.610045 94.915407,131.992045 92.919407,131.992045 L92.919407,131.992045 L91.223407,130.705045 L88.058407,131.992045 L87.248407,131.992045 L87.248407,135.724045 Z"
              fill="#212121"
            ></path>
            <path
              d="M61.401407,112.368845 C61.446407,111.703845 61.800407,108.028845 61.800407,108.028845 L62.649407,100.234845 L64.146407,79.5738449 L64.244407,78.2218449 L72.085407,76.7158449 L77.444407,74.1468449 L83.813407,76.1838449 L88.773407,78.0428449 C88.773407,78.0428449 88.782407,78.8398449 88.820407,79.8008449 C88.871407,81.0598449 88.960407,82.6038449 89.172407,83.0048449 C89.527407,83.7138449 89.438407,87.5208449 89.438407,87.5208449 C89.438407,87.5208449 89.790407,95.2668449 90.234407,96.1158449 C90.678407,96.9648449 90.767407,98.7338449 90.633407,99.0878449 C90.499407,99.4428449 91.077407,108.744845 91.077407,108.744845 C91.077407,108.744845 92.559407,125.204845 91.629407,127.686845 C90.699407,130.168845 87.185407,128.542845 87.185407,128.542845 C87.185407,128.542845 87.355407,127.880845 86.336407,127.568845 C85.317407,127.256845 82.212407,103.916845 82.212407,103.916845 C82.212407,103.916845 81.635407,99.1308449 81.104407,97.7588449 C80.573407,96.3878449 80.085407,92.5338449 80.085407,92.5338449 C80.085407,92.5338449 76.807407,86.1558449 76.764407,84.7378449 C76.764407,84.7378449 76.335407,83.4108449 75.930407,84.8718449 C75.524407,86.3318449 74.329407,89.8308449 74.329407,89.8308449 L73.163407,94.1288449 L71.792407,100.548845 L70.904407,105.954845 C70.904407,105.954845 69.575407,113.128845 69.363407,113.438845 C69.151407,113.748845 69.363407,120.302845 69.363407,120.302845 C69.363407,120.302845 68.335407,125.884845 66.883407,126.859845 C65.431407,127.833845 61.583407,126.874845 61.583407,126.874845 C61.583407,126.874845 61.356407,113.033845 61.401407,112.368845"
              fill="#212121"
            ></path>
            <polygon
              fill="#212121"
              points="64.746507 56.6692449 66.489507 61.2332449 64.338507 63.4592449 65.285507 64.3062449 64.587507 65.5042449 62.765507 78.0582449 72.752507 81.3512449 76.116507 77.6862449 78.767507 81.3512449 90.324507 78.8962449 87.377507 62.8872449 87.377507 61.8402449 86.545507 59.8202449 86.545507 55.5412449 90.366507 42.8062449 82.184507 40.8092449 79.048507 38.6512449 78.033507 41.6862449 73.712507 41.0182449 73.600507 38.7382449 70.294507 41.4532449 65.886507 42.1432449 62.553507 42.8062449 62.128507 43.7292449 62.977507 43.5442449 64.464507 55.5502449"
            ></polygon>
            <path
              d="M71.467207,44.3456449 L73.600207,38.7516449 L79.048207,38.6516449 L80.856207,44.3456449 L87.354207,80.6456449 C87.354207,80.6456449 67.447207,86.1886449 67.649207,79.5256449 C67.861207,72.5186449 68.819207,63.2116449 68.819207,63.2116449 L71.467207,44.3456449 Z"
              fill="#000000"
            ></path>
            <path
              d="M76.202407,24.3509449 C79.746407,24.3509449 82.620407,27.2239449 82.620407,30.7689449 C82.620407,34.3129449 79.746407,37.1869449 76.202407,37.1869449 C72.658407,37.1869449 69.784407,34.3129449 69.784407,30.7689449 C69.784407,27.2239449 72.658407,24.3509449 76.202407,24.3509449"
              fill="#A0616A"
            ></path>
            <path
              d="M69.406007,26.1130449 C70.451007,24.9760449 71.758007,22.8940449 75.716007,22.0200449 C80.608007,20.9400449 82.919007,25.3580449 81.685007,25.4310449 C85.292007,26.5230449 82.975007,33.9870449 81.941007,33.3610449 C81.932007,33.3550449 81.924007,33.3480449 81.916007,33.3410449 C80.792007,32.5140449 80.058007,31.2600449 79.886007,29.8750449 L79.690007,28.1080449 C79.662007,29.5460449 78.193007,29.7360449 76.692007,29.2060449 C76.873007,28.5650449 77.379007,28.0670449 78.023007,27.8970449 C77.105007,28.7440449 75.910007,29.3490449 74.241007,29.5420449 C74.446007,28.7490449 74.854007,28.1660449 75.493007,27.8200449 L75.433007,27.7610449 C74.822007,28.2270449 74.185007,28.6740449 73.449007,28.8850449 C72.622007,29.1220449 71.631007,28.9940449 71.064007,28.3460449 C70.912007,28.1680449 70.796007,27.9600449 70.725007,27.7360449 C70.848007,28.8740449 70.084007,30.1730449 69.491007,29.9500449 C68.998007,29.8540449 68.710007,26.8700449 69.406007,26.1130449"
              fill="#212121"
            ></path>
            <path
              d="M84.115507,30.4149449 L89.952507,40.2349449 L94.266507,38.1969449 C94.266507,38.1969449 86.832507,28.9129449 86.595507,28.6459449 C86.872507,27.4149449 86.098507,26.1929449 84.868507,25.9159449 C83.637507,25.6389449 82.415507,26.4129449 82.138507,27.6439449 C81.862507,28.8749449 82.635507,30.0969449 83.866507,30.3729449 C83.948507,30.3919449 84.031507,30.4059449 84.115507,30.4149449"
              fill="#A0616A"
            ></path>
            <path
              d="M86.941507,54.8176449 L96.000507,48.2916449 C98.345507,46.6016449 98.877507,43.3296449 97.187507,40.9836449 C97.093507,40.8526449 96.992507,40.7256449 96.886507,40.6036449 L90.366507,33.1256449 L87.526507,36.9736449 L89.715507,41.5506449 L85.477507,42.6686449 L86.941507,54.8176449 Z"
              fill="#212121"
            ></path>
            <path
              d="M71.393907,31.3379449 L65.556907,41.1579449 L61.242907,39.1199449 C61.242907,39.1199449 68.676907,29.8359449 68.913907,29.5689449 C68.636907,28.3379449 69.410907,27.1159449 70.640907,26.8389449 C71.871907,26.5619449 73.093907,27.3359449 73.370907,28.5669449 C73.646907,29.7979449 72.873907,31.0199449 71.642907,31.2959449 C71.560907,31.3149449 71.477907,31.3289449 71.393907,31.3379449"
              fill="#A0616A"
            ></path>
            <path
              d="M68.567907,55.7405449 L59.509907,49.2145449 C57.163907,47.5245449 56.631907,44.2525449 58.321907,41.9065449 C58.415907,41.7755449 58.516907,41.6485449 58.622907,41.5265449 L65.142907,34.0485449 L67.982907,37.8965449 L65.793907,42.4735449 L70.031907,43.5915449 L68.567907,55.7405449 Z"
              fill="#212121"
            ></path>
            <path
              d="M75.292907,51.8718449 C75.157907,51.9808449 75.084907,52.1488449 75.097907,52.3218449 C75.097907,52.6718449 75.330907,52.9048449 75.797907,53.0218449 C76.264907,52.9058449 76.498907,52.6718449 76.498907,52.3218449 C76.511907,52.1488449 76.438907,51.9808449 76.303907,51.8718449 C75.995907,51.6758449 75.600907,51.6758449 75.292907,51.8718449 M76.418907,55.1238449 C76.575907,55.0228449 76.666907,54.8468449 76.657907,54.6618449 C76.662907,54.4748449 76.576907,54.2968449 76.426907,54.1838449 C76.076907,53.8448449 75.519907,53.8448449 75.169907,54.1838449 C75.019907,54.2968449 74.933907,54.4748449 74.938907,54.6618449 C74.929907,54.8468449 75.020907,55.0228449 75.177907,55.1238449 C75.563907,55.3358449 76.032907,55.3358449 76.418907,55.1238449 M77.732907,53.9138449 C77.936907,54.1558449 78.044907,54.4648449 78.034907,54.7808449 C78.041907,55.0948449 77.940907,55.4008449 77.748907,55.6488449 C77.540907,55.9048449 77.266907,56.0988449 76.956907,56.2098449 C76.205907,56.4698449 75.390907,56.4698449 74.639907,56.2098449 C74.329907,56.0988449 74.055907,55.9048449 73.847907,55.6488449 C73.655907,55.4008449 73.554907,55.0948449 73.561907,54.7808449 C73.550907,54.4608449 73.658907,54.1478449 73.863907,53.9018449 C74.088907,53.6518449 74.386907,53.4808449 74.715907,53.4118449 C74.427907,53.3228449 74.170907,53.1528449 73.975907,52.9228449 C73.795907,52.7038449 73.699907,52.4288449 73.704907,52.1468449 C73.698907,51.8498449 73.795907,51.5598449 73.979907,51.3268449 C74.175907,51.0828449 74.434907,50.8978449 74.727907,50.7888449 C75.419907,50.5398449 76.176907,50.5398449 76.868907,50.7888449 C77.161907,50.8978449 77.420907,51.0838449 77.616907,51.3268449 C77.799907,51.5598449 77.896907,51.8498449 77.891907,52.1468449 C77.896907,52.4348449 77.799907,52.7148449 77.616907,52.9388449 C77.427907,53.1678449 77.173907,53.3358449 76.888907,53.4198449 C77.213907,53.4948449 77.508907,53.6668449 77.732907,53.9138449"
              fill="#FFFFFF"
            ></path>
            <path
              d="M5.98490702,35.0344449 C6.51090702,35.8114449 7.56690702,36.0154449 8.34390702,35.4904449 C8.42690702,35.4334449 8.50490702,35.3704449 8.57690702,35.3004449 L13.972907,38.0114449 L13.604907,34.8954449 L8.53690702,32.7984449 C7.83690702,32.1674449 6.75590702,32.2234449 6.12490702,32.9244449 C5.59590702,33.5114449 5.53890702,34.3834449 5.98490702,35.0344449"
              fill="#9E616A"
            ></path>
            <path
              d="M28.306807,47.6126449 L25.619807,45.7596449 C25.619807,45.7596449 22.543807,45.5516449 22.080807,44.5726449 C21.618807,43.5946449 21.540807,43.9236449 21.046807,43.8066449 C20.553807,43.6896449 20.298807,42.2176449 19.472807,42.3916449 C17.611807,42.7826449 8.86380702,37.0016449 9.80780702,36.2786449 L10.509807,33.3196449 C11.250807,33.5956449 12.024807,33.7796449 12.811807,33.8646449 C14.000807,33.9736449 16.804807,35.3336449 17.219807,35.7786449 C17.634807,36.2246449 18.370807,36.0516449 19.357807,36.2856449 C20.343807,36.5196449 20.688807,38.1066449 22.325807,37.6846449 C24.627807,37.0906449 36.554807,42.7476449 37.844807,43.2756449 C39.260807,43.8546449 40.249807,45.5826449 40.249807,45.5826449 L39.859807,47.2266449 L28.306807,47.6126449 Z"
              fill="#2498FE"
            ></path>
            <polygon
              fill="#9E616A"
              points="47.695707 131.667045 50.036707 131.667045 51.151707 122.636045 47.695707 122.636045"
            ></polygon>
            <path
              d="M46.931407,130.063445 L50.672407,129.840445 L50.672407,131.442445 L54.228407,133.899445 C54.683407,134.213445 54.797407,134.836445 54.483407,135.291445 C54.296407,135.562445 53.988407,135.724445 53.659407,135.724445 L49.206407,135.724445 L48.438407,134.138445 L48.139407,135.724445 L46.459407,135.724445 L46.931407,130.063445 Z"
              fill="#212121"
            ></path>
            <polygon
              fill="#9E616A"
              points="18.348907 129.677845 20.523907 130.543845 24.899907 122.564845 21.688907 121.286845"
            ></polygon>
            <path
              d="M18.232107,127.905245 L21.790107,129.081245 L21.197107,130.570245 L23.593107,134.168245 C23.900107,134.628245 23.775107,135.249245 23.315107,135.556245 C23.041107,135.738245 22.695107,135.774245 22.390107,135.653245 L18.252107,134.006245 L18.125107,132.249245 L17.260107,133.611245 L15.700107,132.990245 L18.232107,127.905245 Z"
              fill="#212121"
            ></path>
            <path
              d="M42.975807,104.792945 L39.071807,86.5719449 L32.974807,106.816945 L23.319807,126.014945 L23.268807,126.116945 L20.210807,124.469945 C19.778807,116.674945 20.803807,109.369945 24.553807,102.982945 L26.328807,85.4509449 C26.382807,84.8519449 27.706807,70.7889449 31.900807,68.2949449 L33.128807,65.4949449 L45.341807,62.4779449 L45.386807,62.5279449 C46.560807,63.8479449 47.336807,65.4739449 47.623807,67.2179449 L51.273807,89.3269449 L51.275807,89.3399449 L50.296807,104.762945 L51.635807,127.517945 L46.791807,127.517945 C43.795807,121.880945 42.523807,114.304945 42.975807,104.792945"
              fill="#212121"
            ></path>
            <path
              d="M53.490107,28.3748449 C54.193107,28.9958449 54.260107,30.0698449 53.638107,30.7728449 C53.572107,30.8478449 53.499107,30.9168449 53.421107,30.9798449 L55.415107,36.6788449 L52.372107,35.9138449 L50.945107,30.6188449 C50.409107,29.8418449 50.603107,28.7778449 51.380107,28.2418449 C52.029107,27.7938449 52.902107,27.8488449 53.490107,28.3748449"
              fill="#9E616A"
            ></path>
            <path
              d="M29.528407,71.4266449 C29.199407,71.4266449 29.012407,71.3626449 28.940407,71.2236449 C28.834407,71.0206449 29.018407,70.7556449 29.231407,70.4486449 C29.380407,70.2346449 29.815407,69.8086449 29.601407,69.7826449 C26.361407,69.3866449 27.916407,49.4106449 29.320407,46.7706449 C29.606407,46.2336449 29.304407,45.4106449 29.625407,44.5006449 C30.503407,42.0136449 31.701407,41.2496449 34.087407,39.7276449 C34.281407,39.6036449 34.482407,39.4746449 34.692407,39.3406449 C34.963407,39.1656449 34.948407,38.5876449 34.932407,37.9756449 C34.915407,37.2956449 34.896407,36.5916449 35.229407,36.1526449 L35.251407,36.1236449 L35.286407,36.1156449 C36.092407,35.9286449 38.804407,35.5826449 40.409407,36.1176449 L40.431407,36.1256449 L40.448407,36.1416449 C40.829407,36.5236449 40.864407,37.2626449 40.898407,37.9766449 C40.925407,38.5636449 40.951407,39.1166449 41.167407,39.3576449 C42.817407,41.1966449 44.688407,41.1606449 44.707407,41.1606449 L44.800407,41.1576449 L44.809407,41.2496449 C45.965407,43.4516449 45.060407,51.6276449 45.864407,56.7056449 C46.681407,61.8656449 53.656407,66.2576449 47.683407,67.0376449 C47.709407,67.0786449 47.711407,67.1296449 47.687407,67.1716449 C47.326407,67.8456449 30.768407,71.4266449 29.528407,71.4266449"
              fill="#2498FE"
            ></path>
            <path
              d="M56.530107,46.2665449 L53.215107,47.9105449 C46.827107,50.3645449 43.488107,49.7675449 41.810107,48.8345449 C40.021107,47.8385449 39.710107,46.3125449 39.698107,46.2485449 L39.697107,46.2175449 L40.106107,42.9775449 C40.309107,41.3745449 41.772107,40.2385449 43.376107,40.4415449 C43.496107,40.4565449 43.614107,40.4785449 43.731107,40.5085449 L51.358107,42.4495449 L51.219107,38.7475449 L51.521107,37.4145449 L50.964107,35.7935449 L51.192107,32.5395449 L53.928107,31.8695449 L56.798107,37.0095449 L56.282107,38.4285449 L56.768107,39.1345449 L56.767107,39.1675449 L56.530107,46.2665449 Z"
              fill="#2498FE"
            ></path>
            <polygon
              fill="#FFFFFF"
              points="39.898607 48.8639449 39.898607 49.9779449 37.430607 54.5319449 35.838607 54.5319449 38.298607 50.0499449 35.687607 50.0499449 35.687607 48.8639449"
            ></polygon>
            <path
              d="M149.238007,135.939245 L0.236007018,136.000245 C0.105007018,135.999245 0,135.893245 0,135.762245 C0,135.632245 0.106007018,135.527245 0.236007018,135.526245 L149.238007,135.465245 C149.369007,135.466245 149.474007,135.572245 149.474007,135.703245 C149.474007,135.833245 149.368007,135.938245 149.238007,135.939245"
              fill="#D8D8D8"
            ></path>
            <path
              d="M102.978607,21.0895449 C104.811607,17.1925449 103.328607,12.6365449 100.679607,11.3905449 C98.030607,10.1445449 93.575607,11.9075449 91.742607,15.8045449 C90.046607,19.4125449 91.309607,23.6375449 93.485607,25.1765449 C93.448607,25.1875449 93.414607,25.2055449 93.384607,25.2285449 L92.861607,25.6595449 C92.732607,25.7655449 92.714607,25.9565449 92.820607,26.0855449 C92.850607,26.1225449 92.888607,26.1505449 92.931607,26.1695449 L94.249607,26.7555449 C94.402607,26.8235449 94.581607,26.7545449 94.649607,26.6025449 C94.668607,26.5595449 94.677607,26.5125449 94.675607,26.4655449 L94.644607,25.7885449 C94.642607,25.7655449 94.638607,25.7425449 94.631607,25.7195449 C97.207607,26.4275449 101.278607,24.7045449 102.978607,21.0895449"
              fill="#DADADA"
            ></path>
            <path
              d="M101.477207,15.5001449 C102.341207,18.0071449 101.090207,20.7521449 98.631207,21.7441449 C98.361207,21.8561449 98.577207,22.2561449 98.845207,22.1451449 C101.477207,21.0481449 102.818207,18.1071449 101.920207,15.4011449 C101.829207,15.1251449 101.385207,15.2231449 101.477207,15.5001449"
              fill="#FFFFFF"
            ></path>
            <path
              d="M135.138607,34.1483449 C138.847607,31.9593449 140.186607,27.3583449 138.698607,24.8383449 C137.210607,22.3173449 132.536607,21.2663449 128.827607,23.4553449 C125.394607,25.4813449 124.060607,29.6853449 124.991607,32.1823449 C124.955607,32.1703449 124.917607,32.1653449 124.878607,32.1683449 L124.204607,32.2303449 C124.038607,32.2453449 123.915607,32.3923449 123.930607,32.5593449 C123.934607,32.6063449 123.949607,32.6513449 123.974607,32.6913449 L124.734607,33.9173449 C124.822607,34.0593449 125.009607,34.1033449 125.151607,34.0153449 C125.191607,33.9903449 125.225607,33.9573449 125.249607,33.9173449 L125.605607,33.3403449 C125.617607,33.3203449 125.626607,33.2983449 125.633607,33.2763449 C127.363607,35.3113449 131.697607,36.1793449 135.138607,34.1483449"
              fill="#FD7B6F"
            ></path>
            <path
              d="M137.044307,28.6835449 C136.347307,31.2415449 133.768307,32.8055449 131.177307,32.2415449 C130.891307,32.1825449 130.843307,32.6345449 131.128307,32.6935449 C133.920307,33.2685449 136.685307,31.5935449 137.466307,28.8505449 C137.546307,28.5715449 137.124307,28.4025449 137.044307,28.6835449"
              fill="#FFFFFF"
            ></path>
            <path
              d="M111.530307,16.2478449 C110.582307,14.1618449 110.692307,11.7488449 111.824307,9.75684494 C111.984307,9.48084494 112.142307,9.14584494 112.491307,9.09284494 C112.763307,9.04384494 113.033307,9.18784494 113.142307,9.44184494 C113.259307,9.85384494 113.020307,10.2838449 112.608307,10.4008449 C112.343307,10.4758449 112.058307,10.4058449 111.859307,10.2158449 C111.197307,9.68284494 111.281307,8.74384494 111.345307,7.98884494 C111.490307,6.26784494 111.488307,4.46084494 110.755307,2.85884494 C110.078307,1.37684494 108.695307,0.136844944 107.024307,0.000844944224 C106.732307,-0.0221550558 106.716307,0.431844944 107.007307,0.454844944 C108.663307,0.589844944 109.959307,1.95484494 110.510307,3.44784494 C110.821307,4.34784494 110.976307,5.29384494 110.968307,6.24484494 C110.973307,6.73284494 110.950307,7.22184494 110.913307,7.70884494 C110.855307,8.18284494 110.841307,8.66184494 110.868307,9.13884494 C110.946307,9.91784494 111.417307,10.7528449 112.259307,10.8768449 C113.045307,10.9938449 113.812307,10.1718449 113.600307,9.39084494 C113.428307,8.82684494 112.833307,8.50884494 112.269307,8.67984494 C112.131307,8.72184494 112.003307,8.79184494 111.893307,8.88484494 C111.598307,9.13084494 111.406307,9.55384494 111.238307,9.89184494 C110.208307,11.9528449 110.168307,14.3678449 111.129307,16.4618449 C111.254307,16.7258449 111.654307,16.5118449 111.530307,16.2478449 Z"
              fill="#DADADA"
            ></path>
            <path
              d="M42.950607,37.4246449 C42.779607,37.1126449 42.484607,36.2186449 42.154607,36.2306449 C41.816607,36.2456449 41.478607,36.2166449 41.147607,36.1446449 C41.111607,36.1386449 41.075607,36.1316449 41.042607,36.1236449 C39.646607,35.7746449 38.215607,35.5876449 36.777607,35.5656449 C35.617607,35.5496449 34.343607,35.6896449 33.533607,36.5196449 C32.723607,37.3486449 32.843607,39.0606449 33.959607,39.3766449 C30.228607,40.0226449 26.393607,39.1726449 23.285607,37.0106449 C21.309607,35.6216449 19.725607,33.7606449 18.049607,32.0216449 C16.373607,30.2806449 14.527607,28.6066449 12.268607,27.7556449 C10.007607,26.9016449 7.24360702,27.0206449 5.42260702,28.6106449 C3.20160702,30.5496449 3.03060702,33.8846449 3.02859838,36.8316449 C3.02660702,37.9336449 3.37160702,37.7016449 4.34460702,38.2206449 C5.24760702,38.7026449 6.19660702,39.6936449 6.74860702,38.8316449 C7.29960702,37.9696449 7.39660702,36.9056449 7.51060702,35.8886449 C7.62160702,34.8716449 7.78660702,33.8006449 8.43160702,33.0066449 C9.54060702,31.6416449 11.723607,31.6276449 13.253607,32.4936449 C14.783607,33.3586449 15.812607,34.8736449 16.785607,36.3376449 C17.758607,37.8006449 18.785607,39.3196449 20.314607,40.1906449 C22.322607,41.3346449 24.843607,41.1656449 26.938607,42.1406449 C28.270607,42.7616449 29.346607,43.8026449 30.451607,44.7676449 C31.557607,45.7326449 32.769607,46.6626449 34.204607,46.9746449 C35.637607,47.2896449 37.338607,46.8156449 38.043607,45.5276449 C38.677607,44.3636449 38.367607,42.8736449 38.932607,41.6716449 C39.368607,40.7436449 40.257607,40.1286449 41.059607,39.4886449 C41.390607,39.2236449 41.475607,40.1346449 41.756607,39.8126449 C42.566607,39.6516449 43.326607,38.1116449 42.950607,37.4246449"
              fill="#000000"
            ></path>
            <path
              d="M37.370707,23.2020449 C40.843707,23.2020449 43.658707,26.0170449 43.658707,29.4900449 C43.658707,32.9630449 40.843707,35.7780449 37.370707,35.7780449 C33.897707,35.7780449 31.082707,32.9630449 31.082707,29.4900449 C31.082707,26.0170449 33.897707,23.2020449 37.370707,23.2020449"
              fill="#212121"
            ></path>
            <path
              d="M37.621007,27.2629449 C40.116007,27.1919449 42.195007,29.1569449 42.266007,31.6519449 C42.337007,34.1469449 40.372007,36.2269449 37.877007,36.2969449 C35.382007,36.3679449 33.303007,34.4029449 33.232007,31.9079449 C33.161007,29.4129449 35.126007,27.3339449 37.621007,27.2629449"
              fill="#9E616A"
            ></path>
            <path
              d="M37.792607,24.8884449 L35.960607,24.9404449 C33.556607,25.0104449 31.663607,27.0134449 31.729607,29.4174449 L31.730607,29.4574449 L31.763607,29.4784449 C32.759607,30.1374449 33.934607,30.4714449 35.127607,30.4354449 L35.230607,30.4324449 L36.243607,30.3874449 C36.413607,29.9484449 36.470607,29.4744449 36.408607,29.0084449 C36.561607,29.4504449 36.680607,29.9024449 36.765607,30.3624449 L41.307607,30.1564449 C41.856607,30.1284449 42.284607,29.6684449 42.270607,29.1184449 C42.199607,26.7144449 40.196607,24.8224449 37.792607,24.8884449"
              fill="#212121"
            ></path>
            <path
              d="M34.142207,23.7545449 C33.729207,24.2645449 33.065207,24.4765449 32.468207,24.7525449 C30.331207,25.7635449 28.831207,27.7635449 28.460207,30.0975449 C28.319207,31.0345449 28.368207,31.9975449 28.130207,32.9135449 C27.894207,33.8285449 27.270207,34.7385449 26.343207,34.9225449 C25.715207,35.0475449 25.077207,34.8225449 24.477207,34.5985449 C24.147207,34.4775449 23.817207,34.3545449 23.487207,34.2315449 C23.803207,33.3685449 24.119207,32.5055449 24.434207,31.6425449 C23.912207,32.4635449 23.299207,33.2225449 22.607207,33.9065449 C21.425207,33.4685449 20.242207,33.0305449 19.058207,32.5925449 C21.938207,32.5225449 21.367207,29.7395449 21.932207,26.9165449 C22.243207,25.3645449 25.230207,24.2355449 25.779207,22.7515449 C26.638207,20.4255449 29.219207,19.2365449 31.545207,20.0945449 C32.547207,20.4645449 33.384207,21.1805449 33.907207,22.1125449 C34.505207,22.3305449 34.548207,23.2515449 34.142207,23.7545449"
              fill="#212121"
            ></path>
            <path
              d="M34.430307,20.9416449 C35.723307,20.9416449 36.771307,21.9896449 36.771307,23.2826449 C36.771307,24.5766449 35.723307,25.6246449 34.430307,25.6246449 C33.137307,25.6246449 32.089307,24.5766449 32.089307,23.2826449 C32.089307,21.9896449 33.137307,20.9416449 34.430307,20.9416449"
              fill="#212121"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
