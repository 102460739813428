import { useEffect, useState, useRef } from "react";
import { toggleSidebar } from "react-admin";
import { withRouter } from "react-router-dom";
import { isRepManager, nameToInitials, parseUserToken } from "../lib";
import { AppBar, IconButton, useMediaQuery, Avatar } from "@material-ui/core";
import { connect } from "react-redux";
import { styles } from "./Layout.styles";
import MenuIcon from "@material-ui/icons/Menu";
import classnames from "classnames";
import { roles } from "../lib";
import { UserMenu } from "./UserMenu";

const CustomAppBar = props => {
  const { open, role, toggleSidebar, history, userName } = props;
  const repManager = isRepManager(role);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isImpersonatingRef = useRef(false);
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [impersonatingTxt, setImpersonatingTxt] = useState("");
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const classes = styles({ isImpersonating });

  useEffect(() => {
    const impersonating = impersonatingCheck();
    setIsImpersonating(impersonating);
    isImpersonatingRef.current = impersonating;
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", function(_e) {
      if (isImpersonatingRef.current) {
        leaveImpersonationMode(role, history);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isImpersonating) {
      switch (role) {
        case roles.COMPANY:
          setImpersonatingTxt("A Company");
          break;
        case roles.REP:
          setImpersonatingTxt(`Rep - ${userName}`);
          break;
        default:
      }
    }
  }, [isImpersonating, role, userName]);

  const customTitle = getTitle(props);

  return (
    <AppBar className={classes.appBar}>
      {isMobile && isImpersonating && (
        <div className={classes.mobileImpersonatingBar}>
          <div className={classes.mobileImpersonatingTxt}>
            Impersonating - {impersonatingTxt}
          </div>
        </div>
      )}
      <div className={classes.mainContent}>
        <div className={classes.leftSide}>
          {!repManager && (
            <IconButton
              color="inherit"
              onClick={toggleSidebar}
              className={classes.menuButton}
              size="small"
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          )}

          {customTitle && <div className={classes.title}>{customTitle}</div>}
          <div
            className={classnames(
              classes.title,
              Boolean(customTitle) && classes.hideDefaultTitle,
            )}
            id="react-admin-title"
          />
        </div>

        {!isMobile && isImpersonating && (
          <div className={classes.desktopImpersonatingBar}>
            <span className={classes.impersonatingHeader}>Impersonating</span>
            <span className={classes.desktopImpersonatingTxt}>
              {impersonatingTxt}
            </span>
            <div
              onClick={() => leaveImpersonationMode(role, history)}
              className={classes.leaveImpersonation}
            >
              leave
            </div>
          </div>
        )}

        <Avatar
          onClick={e => setUserMenuAnchorEl(e.currentTarget)}
          className={classes.appBarAvatar}
        >
          {nameToInitials(userName)}
        </Avatar>

        <UserMenu
          anchorEl={userMenuAnchorEl}
          handleClose={() => setUserMenuAnchorEl(null)}
          isImpersonating={isImpersonating}
          impersonatingTxt={impersonatingTxt}
          leaveImpersonationMode={leaveImpersonationMode}
        />
      </div>
    </AppBar>
  );
};

export default withRouter(
  connect(
    state => {
      const {
        user: { role, userName },
      } = state;
      return { role, userName };
    },
    { toggleSidebar },
  )(CustomAppBar),
);

function getTitle(props) {
  const {
    location: { pathname },
  } = props;
  switch (true) {
    case pathname.includes("profile"):
      return "Profile";
    case pathname === "/":
      return "Dashboard";
    default:
      return null;
  }
}

function leaveImpersonationMode(role, history) {
  switch (role) {
    case roles.VOLUNTEER:
      localStorage.removeItem("volunteerImpersonationToken");
      const selectedCampaignSavePoint = localStorage.getItem(
        "selectedCampaignSavePoint",
      );
      localStorage.setItem("selectedCampaignId", selectedCampaignSavePoint);
      history.push("/volunteers");
      break;
    case roles.ORG:
      localStorage.removeItem("orgImpersonationToken");
      localStorage.removeItem("selectedCampaignId");
      const orgImpersonatePrevUrl = localStorage.getItem(
        "orgImpersonatePrevUrl",
      );
      localStorage.removeItem("orgImpersonatePrevUrl");
      history.push(orgImpersonatePrevUrl ? orgImpersonatePrevUrl : "/orgs");
      break;
    case roles.REP:
      const impersonatorToken = localStorage.getItem("token");
      const { role } = parseUserToken(impersonatorToken);
      localStorage.removeItem("repImpersonationToken");
      if (isRepManager(role)) history.push("/");
      else history.push("/reps");
      break;
    case roles.COMPANY:
      localStorage.removeItem("companyImpersonationToken");
      history.push("/companies");
      break;
    default:
  }
  window.location.reload();
}

function impersonatingCheck() {
  return Boolean(
    localStorage.getItem("orgImpersonationToken") ||
      localStorage.getItem("volunteerImpersonationToken") ||
      localStorage.getItem("repImpersonationToken") ||
      localStorage.getItem("companyImpersonationToken"),
  );
}
