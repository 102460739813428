import { makeStyles } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { ReactNode } from "react";

type GenericParams = {
  page: number;
  limit: number;
  sort: string;
  order: "asc" | "desc";
};

type Props = {
  columnName: string | ReactNode;
  fieldName: string;
  params: GenericParams;
  setParams: React.Dispatch<React.SetStateAction<GenericParams>>;
};
export function SortableColumnHeader({
  columnName,
  fieldName,
  params,
  setParams,
}: Props) {
  const classes = styles();
  const { sort, order } = params;
  const active = sort === fieldName;

  const changeSort = () => {
    let _order: "desc" | "asc";
    if (active) _order = order === "asc" ? "desc" : "asc";
    else _order = "asc";
    setParams(prev => {
      return { ...prev, page: 0, sort: fieldName, order: _order };
    });
  };

  return (
    <div className={classes.wrapper} onClick={changeSort}>
      <div>{columnName}</div>
      {active && order === "asc" && (
        <ArrowDownwardIcon className={classes.icon} fontSize="small" />
      )}
      {active && order === "desc" && (
        <ArrowUpwardIcon className={classes.icon} fontSize="small" />
      )}
      {!active && (
        <ArrowDownwardIcon className={classes.hoverIcon} fontSize="small" />
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: "100%",
    width: "fit-content",
    "& $hoverIcon": { display: "none" },
    "&:hover $hoverIcon": { display: "block" },
  },
  icon: {
    marginLeft: 7,
  },
  hoverIcon: {
    marginLeft: 7,
    color: theme.palette.text.secondary2,
  },
}));
