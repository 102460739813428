import React, { Fragment, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  makeStyles,
  Collapse,
  TableHead,
  MenuItem,
} from "@material-ui/core";
import { TextField } from "final-form-material-ui";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { ColorInput, ColorField } from "react-admin-color-input";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import {
  required,
  requiredPositiveInteger,
  configurableProductOptionDisplayTypeOptions,
  configurableProductOptionDisplayTypes,
} from "../../../lib";

export function Option({ fields, row, index }) {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const {
    display_type,
    configurable_product_option_items: items,
  } = fields.value[index];
  const isColorPicker =
    display_type === configurableProductOptionDisplayTypes.COLOR_SWATCH;

  useEffect(() => {
    // only run on component mount
    if (!Array.isArray(items) || !items.length) setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <TableRow className={classes.parentRow}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.deleteCell}>
          <IconButton onClick={() => fields.remove(index)} size="small">
            <RemoveIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Field
            component={TextField}
            variant="standard"
            name={`${row}.option_label`}
            label="Label"
            required
            validate={required}
          />
        </TableCell>
        <TableCell>
          <Field
            select
            component={TextField}
            variant="standard"
            name={`${row}.display_type`}
            label="Display type"
            required
            validate={required}
            className={classes.select}
          >
            {configurableProductOptionDisplayTypeOptions.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Field>
        </TableCell>
        <TableCell>
          <Field
            component={TextField}
            name={`${row}.sort_order`}
            label="Sort"
            variant="standard"
            required
            validate={requiredPositiveInteger}
            type="number"
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={5} className={classes.collapseCell}>
          <Collapse in={open} timeout="auto">
            <Table size="small" className={classes.expandTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Option Item Value</TableCell>
                  <TableCell>{`Option Item Text (optional)`}</TableCell>
                  <TableCell>Option Item Sort Number</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                <FieldArray name={`${row}.configurable_product_option_items`}>
                  {({ fields: optionItems }) => {
                    return (
                      <Fragment>
                        {optionItems.map((optionItem, i2) => {
                          return (
                            <TableRow key={i2}>
                              <TableCell>
                                {isColorPicker && (
                                  <ColorInput
                                    source={`${optionItem}.value`}
                                    variant="standard"
                                    label="Value"
                                    picker="Sketch"
                                  />
                                )}
                                {isColorPicker && (
                                  <ColorField
                                    source={`value`}
                                    record={optionItems.value[i2]}
                                  />
                                )}
                                {!isColorPicker && (
                                  <Field
                                    component={TextField}
                                    variant="standard"
                                    name={`${optionItem}.value`}
                                    label="Value"
                                    required
                                    validate={required}
                                  />
                                )}
                              </TableCell>
                              <TableCell>
                                <Field
                                  component={TextField}
                                  variant="standard"
                                  name={`${optionItem}.text`}
                                  label="Text"
                                  parse={value => value}
                                />
                              </TableCell>
                              <TableCell>
                                <Field
                                  component={TextField}
                                  variant="standard"
                                  name={`${optionItem}.sort_order`}
                                  label="Sort"
                                  required
                                  validate={required}
                                />
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => optionItems.remove(i2)}
                                  size="small"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell colSpan={4} align="right">
                            <Button
                              color="primary"
                              onClick={() => optionItems.push()}
                              startIcon={<AddIcon />}
                            >
                              add option item
                            </Button>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  }}
                </FieldArray>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  parentRow: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  collapseCell: {
    paddingBottom: 0,
    paddingTop: 0,
    height: "unset",
  },
  expandTable: {
    marginBottom: 4,
    backgroundColor: "#f7f7f2",
  },
  table: { marginBottom: 24, overflowX: "visible" },
  deleteCell: { width: 30 },
  select: { width: 145 },
}));
