import { makeStyles, InputAdornment, Button } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { FormApi } from "final-form";
import { TextField } from "final-form-material-ui";
import { getCampaignId, setToast } from "../../../state";
import {
  APIAuthClient,
  convertFileToB64,
  requiredPositiveInteger,
} from "../../../lib";
import { CircularLoader, NullableField } from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { APIRes, GenericObject, ToastTypes } from "../../../types";

export function WebpageSetup() {
  const classes = styles();
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<GenericObject>({});

  useEffect(() => {
    const fetch = async () => {
      const res = await APIAuthClient.get<any, APIRes>(
        `/campaigns/${campaign_id}/org_editable_fields`,
      );
      const { error, errorMessage, data } = res;
      if (error) dispatch(setToast(errorMessage));
      else setInitialValues(data);

      setLoading(false);
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const onSubmit = async (values: any, form: FormApi) => {
    const { goal, video_link, pictureFile, removePicture } = values;
    const update: GenericObject = {
      goal: Number(goal),
      video_link,
      removePicture: removePicture ? removePicture : null,
    };
    if (pictureFile) update.pictureBase64 = await convertFileToB64(pictureFile);

    const res = await APIAuthClient.put<any, APIRes>(
      `/campaigns/${campaign_id}/org_editable_fields`,
      update,
    );
    const { error, errorMessage, data } = res;
    if (error) dispatch(setToast(errorMessage));
    else {
      dispatch(setToast("Changes saved", ToastTypes.success));
      setInitialValues(data);
      form.restart(data);
    }
  };

  if (loading) {
    return (
      <div>
        <CircularLoader show />
      </div>
    );
  }

  return (
    <Fragment>
      <div className={classes.campaignId}>Campaign ID: {campaign_id}</div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Field
                name="goal"
                component={TextField}
                label="Goal"
                validate={requiredPositiveInteger}
                className={classes.halfInput}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />

              <div className={classes.subheader}>Featured image</div>
              <ImageDropzoneField
                fieldName="pictureFile"
                marginBottom={24}
                currImage={initialValues.picture as any}
                deleteFieldName="removePicture"
              />

              <div className={classes.subheader}>Featured video</div>
              <NullableField
                name="video_link"
                component={TextField}
                label="Youtube or Vimeo link"
                className={classes.input}
              />
              <div className={classes.bottom}>
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting || pristine}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </form>
          );
        }}
      />
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  campaignId: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.11,
    marginBottom: 24,
  },
  subheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  form: {
    width: 552,
    maxWidth: 552,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  halfInput: {
    marginBottom: 24,
    width: 264,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    marginBottom: 24,
    width: "100%",
  },
  bottom: {
    marginTop: 24,
    paddingTop: 16,
    borderTop: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      borderTop: "none",
      marginTop: 16,
      paddingTop: 0,
      display: "flex",
      justifyContent: "center",
    },
  },
  save: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
    },
  },
}));
