import React, { useState, useEffect, Fragment } from "react";
import {
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  List,
  Pagination,
  TopToolbar,
  required,
  Notification,
  useNotify,
  AutocompleteInput,
  useMutation,
  FunctionField,
  useListContext,
} from "react-admin";
import { Button, Drawer, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { styles } from "./Product.styles";
import { generateAuthHeader, APIClient } from "../../lib";

const requiredInput = [required()];

export function ProductSuggestions(props) {
  const { location, id: supplier_id } = props;
  const [openAside, setOpenAside] = useState(false);
  const [products, setProducts] = useState([]);
  const notify = useNotify();
  const basePath = `/product_suppliers/${supplier_id}/show/product-suggestions`;

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/products/autocomplete?supplier_id=${supplier_id}&exclude_variants=true`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setProducts(data);
    };
    if (supplier_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const onSuccess = msg => {
    setOpenAside(false);
    notify(msg);
  };

  const SuggestionActions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setOpenAside(true)}
      >
        add suggestion
      </Button>
    </TopToolbar>
  );

  return (
    <div>
      <List
        title=" "
        location={location}
        basePath={basePath}
        resource="product_suggestions"
        filter={{ supplier_id }}
        perPage={100}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        actions={<SuggestionActions />}
        sort={{ field: "product_name", order: "ASC" }}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
        empty={false}
      >
        <Fragment>
          <Datagrid rowClick={null}>
            <TextField source="product_id" label="Product ID" />
            <TextField source="product_name" />
            <TextField source="sku" />
            <FunctionField
              label=" "
              render={({ id }) => (
                <RemoveSuggestion id={id} onSuccess={onSuccess} />
              )}
            />
          </Datagrid>
          <Aside
            {...{ openAside, setOpenAside, basePath, onSuccess, products }}
          />
        </Fragment>
      </List>
    </div>
  );
}

function Aside({ openAside, setOpenAside, basePath, onSuccess, products }) {
  const { refetch } = useListContext({});
  const classes = styles();

  return (
    <Drawer open={Boolean(openAside)} anchor="right">
      <div className={classes.drawerContent}>
        <div className={classes.drawerHeader}>
          <div>Add a Suggestion</div>
          <IconButton onClick={() => setOpenAside(false)} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Create
          basePath={basePath}
          resource="product_suggestions"
          title=" "
          onSuccess={() => {
            refetch();
            onSuccess("Suggestion added successfully");
          }}
        >
          <SimpleForm>
            <AutocompleteInput
              source="product_id"
              label="Select a Product"
              validate={requiredInput}
              className={classes.suggestionAC}
              options={{
                suggestionsContainerProps: {
                  style: { maxWidth: 600 },
                },
                type: "search",
              }}
              choices={products.map(({ id, product_name, sku }) => {
                return { id, name: `${product_name} - ${sku}` };
              })}
              translateChoice={false}
            />
          </SimpleForm>
        </Create>
      </div>
      <Notification />
    </Drawer>
  );
}

function RemoveSuggestion({ id, onSuccess }) {
  const notify = useNotify();
  const { refetch } = useListContext({});

  const [remove, { loading, loaded }] = useMutation(
    {
      type: "delete",
      resource: "product_suggestions",
      payload: { id },
    },
    {
      onFailure: error => notify(error.message, "warning"),
      onSuccess: () => {
        refetch();
        onSuccess("Suggestion Removed");
      },
    },
  );

  return (
    <Fragment>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<DeleteIcon />}
        onClick={remove}
        disabled={loading || loaded}
      >
        remove
      </Button>
    </Fragment>
  );
}
