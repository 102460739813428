import React from "react";
import {
  Dialog,
  Button,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import { styles } from "./OrgProfit.styles";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import CloseIcon from "@material-ui/icons/Close";
import { Notification, useNotify } from "react-admin";
import {
  APIUtilClient,
  generateAuthHeader,
  getUserTimezone,
  orgProfitStatuses,
  required,
} from "../../../lib";

export function PaidEmailNotificationModal({
  setShowEmailModal,
  emailTemplate: { bodyTemplate = "", subjectTemplate = "" } = {},
  campaignId,
  status,
}) {
  const classes = styles();
  const notify = useNotify();
  const isInvoice = status === orgProfitStatuses.INVOICE_PAID;

  const onSubmit = async values => {
    const res = await APIUtilClient.post(
      `/org_profit/campaigns/${campaignId}/send-paid-email`,
      { ...values, timezone: getUserTimezone() },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Email successfully sent!", { type: "info" });
    setShowEmailModal(false);
  };

  return (
    <Dialog
      open={true}
      onClose={() => setShowEmailModal(false)}
      maxWidth={false}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogHeaderSection}>
          <div>
            <div className={classes.dialogHeader}>
              Send {isInvoice ? "Invoice" : "Profit"} paid notification emails
            </div>
            <div>
              {isInvoice ? "Invoice" : "Profit Statement"} PDF attachment
              included in email
            </div>
          </div>
          <IconButton
            className={classes.closeIcon}
            onClick={() => setShowEmailModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          onSubmit={onSubmit}
          initialValues={{
            emailRep: true,
            emailOrg: true,
            subject: subjectTemplate,
            message: bodyTemplate,
          }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      name="emailRep"
                      type="checkbox"
                    />
                  }
                  label="Email Rep"
                />
                <FormControlLabel
                  control={
                    <Field
                      component={Checkbox}
                      name="emailOrg"
                      type="checkbox"
                    />
                  }
                  label="Email Org"
                />
                <Field
                  name="subject"
                  variant="outlined"
                  label="Subject"
                  component={TextField}
                  validate={required}
                  fullWidth
                  className={classes.modalInput}
                />
                <Field
                  name="message"
                  variant="outlined"
                  label="Message"
                  component={TextField}
                  validate={required}
                  fullWidth
                  multiline={true}
                  minRows="12"
                  className={classes.modalInput}
                />
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                >
                  SEND
                </Button>
              </form>
            );
          }}
        />
      </div>
      <Notification />
    </Dialog>
  );
}
