import {
  CustomerDiscountCardFilterFields,
  ListProps,
  discountCardConfigLevels,
} from "../../../types";
import {
  FilterWrapper,
  FiltersDrawer,
  ToggleFilter,
} from "../../../components";
import { Fragment } from "react";
import { TextField, MenuItem, makeStyles } from "@material-ui/core";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function CustomerCardFilters({ onClose, listProps }: Props) {
  const { getFilterValue, isActiveFilter, updateTempFilters } = listProps;
  const classes = styles();

  const onOriginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target,
      currentTarget: { attributes },
    } = e;
    const origin = attributes.getNamedItem("data-level")?.value || "";
    updateTempFilters({
      field: CustomerDiscountCardFilterFields.discount_card_config_level,
      value: target.value,
      chipText: `Card origin - ${origin}`,
    });
  };

  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter customer cards"
    >
      <Fragment>
        <ToggleFilter
          header="Card status"
          truthyLabel="Assigned"
          falsyLabel="Unassigned"
          truthyChipText="Assigned cards"
          falsyChipText="Unassigned cards"
          field={CustomerDiscountCardFilterFields.assigned}
          listProps={listProps}
        />
        <FilterWrapper
          header="Card origin"
          active={isActiveFilter(
            CustomerDiscountCardFilterFields.discount_card_config_level,
          )}
        >
          <TextField
            select
            fullWidth
            value={getFilterValue(
              CustomerDiscountCardFilterFields.discount_card_config_level,
            )}
            onChange={onOriginChange}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem className={classes.labelOption} value="">
              Select an option
            </MenuItem>

            <MenuItem
              value={discountCardConfigLevels.DONATION_PRODUCT}
              data-level="Orders"
            >
              Orders
            </MenuItem>
            <MenuItem
              value={discountCardConfigLevels.MANUALLY_GENERATED}
              data-level="Manually generated"
            >
              Manually generated
            </MenuItem>
          </TextField>
        </FilterWrapper>
      </Fragment>
    </FiltersDrawer>
  );
}

const styles = makeStyles(theme => ({
  labelOption: {
    color: theme.palette.text.secondary2,
  },
}));
