export type AutocompleteTeam = {
  id: number;
  name: string;
};

export type TeamsAutocomplete = AutocompleteTeam[];

export type TeamQueryParams = {
  campaign_id?: number;
  page: number;
  limit: number;
  sort: string;
  order: "asc" | "desc";
  q?: string | null;
  active_only?: boolean | null;
};

export enum TeamFilterFields {
  q = "q",
}

export const teamSortFields = [
  { field: "id", label: "Team ID" },
  { field: "team_name", label: "team name" },
  { field: "goal", label: "Goal" },
  { field: "raised", label: "Raised" },
  { field: "memberCount", label: "Member count" },
  { field: "onCampaign", label: "Active in this campaign" },
];
