import { Fragment, useEffect, useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import { useSelector } from "react-redux";
import { sharedStyles } from "../shared.styles";
import { Contact, GuidedSetupScreen } from "../../../types";
import { AFFILIATES } from "../../../../lib";
import { affiliateLink, textMessageLink } from "../../../lib";
import { TextContactsSVG } from "../SVGs/TextContactsSVG";
import classNames from "classnames";
import { RootState } from "../../../types/state";
import { CircularLoader } from "../../../components";
import { Tooltip } from "../components/Tooltip";

export function TextContacts(props: GuidedSetupScreen) {
  const {
    activeScreen,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setDisableNext,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const [textContacts, setTextContacts] = useState<Contact[] | null>(null);
  const [noTextContacts, setNoTextContacts] = useState(false);
  const { contactsFetched, contacts } = useSelector(
    (state: RootState) => state.contacts,
  );
  const { orgName, webpageLink } = useSelector(
    (state: RootState) => state.volunteerSetup,
  );
  const link = affiliateLink(
    webpageLink,
    AFFILIATES.VOLUNTEER_SETUP_SHARING_LINKS,
  );

  useEffect(() => {
    if (contactsFetched) {
      const TC = contacts.filter(({ phone }) => Boolean(phone));
      if (TC.length) {
        setTextContacts(TC);
        setNoTextContacts(false);
      } else {
        setTextContacts(null);
        setNoTextContacts(true);
      }
    }
  }, [contactsFetched, contacts]);

  useEffect(() => {
    if (activeScreen === "TEXT_CONTACTS") {
      setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]);

  useEffect(() => {
    if (nextTriggeredBy === "TEXT_CONTACTS") {
      handleNext();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  if (activeScreen !== "TEXT_CONTACTS") return <></>;
  return (
    <div className={sharedClasses.fullWidthScreenContainer}>
      <TextContactsSVG />
      <h1 className={classNames(sharedClasses.screenHeader, classes.header)}>
        Text your contacts for their support
      </h1>

      <CircularLoader show={!contactsFetched} />

      {contactsFetched && noTextContacts && (
        <Fragment>
          <p className={sharedClasses.screenParagraph}>
            You have not added any fundraising contacts with phone numbers
          </p>
          <div className={classes.noContactsSpacer} />
          <div className={sharedClasses.tooltipWrapper}>
            <Tooltip
              text="Click to share a link to your page via text message"
              bottom={4}
            />
          </div>
          <Button
            color="primary"
            startIcon={<SmsOutlinedIcon />}
            className={classes.noContactsButton}
            href={textMessageLink(link, orgName, true)}
          >
            Send a text
          </Button>
        </Fragment>
      )}

      {contactsFetched && textContacts && (
        <div className={classes.contactsContainer}>
          {textContacts.map((c, i) => (
            <Row key={i} contact={c} link={link} orgName={orgName} />
          ))}
        </div>
      )}
    </div>
  );
}

type RowProps = {
  contact: Contact;
  link: string;
  orgName: string;
};
function Row({ contact, link, orgName }: RowProps) {
  const classes = styles();
  const { name, phone, alreadySupported } = contact;
  const [texted, setTexted] = useState(false);

  return (
    <div className={classes.row}>
      <div className={classes.nameAndNum}>
        <div className={classes.name}>{name}</div>
        <div className={classes.number}>{phone}</div>
      </div>

      {(alreadySupported || texted) && (
        <div className={classes.checkContainer}>
          <CheckCircleIcon className={classes.checkIcon} />
          <div className={classes.checkTxt}>
            {alreadySupported ? "Already supported" : "Texted"}
          </div>
        </div>
      )}
      {!alreadySupported && !texted && (
        <div className={classes.buttonWrapper} onClick={() => setTexted(true)}>
          <Button
            color="primary"
            variant="text"
            size="small"
            startIcon={<SmsOutlinedIcon />}
            href={textMessageLink(link, orgName, true, phone)}
            className={classes.button}
          >
            TEXT
          </Button>
        </div>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  header: {
    padding: "0 16px",
  },
  spacer: {
    height: 76,
  },
  noContactsSpacer: {
    height: 76,
  },
  noContactsButton: {
    width: 300,
  },
  contactsContainer: {
    paddingTop: 24,
    width: "100%",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #EAEBF3",
    overflowWrap: "anywhere",
  },
  nameAndNum: {
    paddingLeft: 24,
    paddingRight: 12,
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "18px",
    marginBottom: 4,
  },
  number: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "14px",
  },
  buttonWrapper: {
    marginRight: 16,
    width: "fit-content",
    minWidth: "fit-content",
  },
  button: {
    textWrap: "nowrap",
  },
  checkContainer: {
    display: "flex",
    alignItems: "center",
  },
  checkIcon: {
    color: "#1AC846",
  },
  checkTxt: {
    marginLeft: 8,
    marginRight: 20,
    fontSize: 14,
    letterSpacing: 0.15,
    textWrap: "nowrap",
  },
}));
