export function FacebookBlueSmallSVG() {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22">
      <title>FacebookOutlined</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-40, -263)" fill="#248BEF">
          <g transform="translate(16, 198)">
            <g transform="translate(15, 56)">
              <g transform="translate(9, 9)">
                <g transform="translate(1.8333, 1.8333)">
                  <path d="M18.3333334,9.1666667 C18.3333334,4.10666668 14.2266667,-2.22044605e-16 9.1666667,-2.22044605e-16 C4.10666668,-2.22044605e-16 -2.22044605e-16,4.10666668 -2.22044605e-16,9.1666667 C-2.22044605e-16,13.6033334 3.15333335,17.2975001 7.33333336,18.1500001 L7.33333336,11.9166667 L5.5,11.9166667 L5.5,9.1666667 L7.33333336,9.1666667 L7.33333336,6.87500003 C7.33333336,5.10583335 8.77250004,3.66666668 10.5416667,3.66666668 L12.8333334,3.66666668 L12.8333334,6.41666669 L11,6.41666669 C10.4958334,6.41666669 10.0833334,6.8291667 10.0833334,7.33333336 L10.0833334,9.1666667 L12.8333334,9.1666667 L12.8333334,11.9166667 L10.0833334,11.9166667 L10.0833334,18.2875001 C14.7125001,17.8291667 18.3333334,13.9241667 18.3333334,9.1666667 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
