import { makeStyles, Button, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { campaignActions, getCampaignId, setToast } from "../../../state";
import { APIAuthClient, requiredField } from "../../../lib";
import { TextFieldWrapper } from "../../../components";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import {
  giveawayDataPointOptions,
  giveawayTimezoneOptions,
} from "../../../types/giveaway";

export function CreateProgram() {
  const classes = styles();
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);

  const onSubmit = async (values: GenericObject) => {
    const info = { ...values, campaign_id };
    const res = await APIAuthClient.post<any, APIRes>(
      "/campaign_giveaways",
      info,
    );
    const { error, errorMessage, data } = res;
    if (error) dispatch(setToast(errorMessage));
    else {
      dispatch(
        campaignActions.updateCampaignInfo({ campaignGiveawayId: data.id }),
      );
      dispatch(setToast("Program created", ToastTypes.success));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>Create Giveaway program</div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Field
                select
                name="data_point"
                component={TextFieldWrapper}
                label="Select a data point"
                validate={requiredField}
                className={classes.input}
              >
                {giveawayDataPointOptions.map(dp => (
                  <MenuItem key={dp} value={dp}>
                    {dp}
                  </MenuItem>
                ))}
              </Field>
              <Field
                select
                name="date_range_timezone"
                component={TextFieldWrapper}
                label="Select a timezone for reports"
                validate={requiredField}
                className={classes.input}
              >
                {giveawayTimezoneOptions.map(({ name, value }) => (
                  <MenuItem key={name} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Field>

              <div className={classes.bottom}>
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting || pristine}
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    padding: "16px 32px 32px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.11,
    marginBottom: 32,
  },
  form: {
    width: 552,
    maxWidth: 552,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  input: {
    marginBottom: 24,
    width: "100%",
  },
  bottom: {
    marginTop: 24,
    paddingTop: 16,
    borderTop: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      borderTop: "none",
      marginTop: 16,
      paddingTop: 0,
      display: "flex",
      justifyContent: "center",
    },
  },
  save: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
    },
  },
}));
