import React, { useState } from "react";
import {
  Datagrid,
  TextField,
  Create,
  Filter,
  TextInput,
  SimpleForm,
  useListController,
  ListView,
  Pagination,
  useNotify,
  TopToolbar,
  Notification,
  required,
  minLength,
  Edit,
  Toolbar,
  SaveButton,
} from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton } from "@material-ui/core";
import { styles } from "./Product.styles";
const validateName = [required(), minLength(2)];
const asideModes = { CREATE: "add", EDIT: "edit", CLOSED: null };

const WarehouseFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Warehouses" source="q" alwaysOn />
    </Filter>
  );
};

export function ProductWarehouses(props) {
  const { record: { id: supplier_id } = {} } = props;
  const classes = styles();
  const notify = useNotify();
  const basePath = `/product_suppliers/${supplier_id}/show/warehouses`;
  const [asideMode, setAsideMode] = useState(asideModes.CLOSED);
  const [activeWarehouseId, setActiveWarehouseId] = useState(null);

  const controllerProps = useListController({
    sort: { field: "name", order: "ASC" },
    basePath,
    resource: "product_warehouses",
    filter: { supplier_id },
    perPage: 25,
  });

  const onSuccess = msg => {
    controllerProps.refetch();
    setAsideMode(asideModes.CLOSED);
    notify(msg);
  };

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setAsideMode(asideModes.CREATE)}
      >
        add warehouse
      </Button>
    </TopToolbar>
  );

  const onRowClick = id => {
    setActiveWarehouseId(id);
    setAsideMode(asideModes.EDIT);
  };

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={<WarehouseFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" />
          <TextField source="name" />
        </Datagrid>
      </ListView>

      <Drawer open={Boolean(asideMode)} anchor="right">
        <div className={classes.productDrawer}>
          <div className={classes.drawerHeader}>
            <div>
              {asideMode === asideModes.CREATE
                ? "Add a Warehouse"
                : "Edit Warehouse"}
            </div>
            <IconButton
              onClick={() => setAsideMode(asideModes.CLOSED)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {asideMode === asideModes.CREATE && (
            <WarehouseCreate onSuccess={onSuccess} supplier_id={supplier_id} />
          )}

          {asideMode === asideModes.EDIT && (
            <WarehouseEdit id={activeWarehouseId} onSuccess={onSuccess} />
          )}
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}

function WarehouseCreate({ supplier_id, onSuccess }) {
  const createProps = {
    resource: "product_warehouses",
    basePath: "",
    title: " ",
  };

  return (
    <Create
      {...createProps}
      onSuccess={() => onSuccess("Warehouse added successfully")}
    >
      <SimpleForm initialValues={{ supplier_id }}>
        <TextInput source="name" validate={validateName} />
      </SimpleForm>
    </Create>
  );
}

function WarehouseEdit({ id, onSuccess }) {
  const props = {
    id,
    resource: "product_warehouses",
    basePath: "",
    title: " ",
  };

  return (
    <Edit
      {...props}
      onSuccess={() => onSuccess("Warehouse updated successfully")}
      undoable={false}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="name" validate={validateName} />
      </SimpleForm>
    </Edit>
  );
}
