import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {
  Button,
  IconButton,
  makeStyles,
  MenuItem,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  composeValidators,
  requiredField,
  twoDecimalsOnly,
} from "../../../lib";
import {
  APIRes,
  DiscountCardOfferRedemptionMethods,
  GenericObject,
  ToastTypes,
  discountCardOfferRedemptionMethods,
  discountCardOfferUsesOptions,
} from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  ConfirmationDialog,
  NullableField,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RedButton } from "../../../components/ui/RedButton";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  record: GenericObject;
};

export function EditOffer({ onClose: _onClose, refreshList, record }: Props) {
  const { id, display_estimated_values, merchant_name } = record;
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    const { number_of_uses, ...rest } = record;
    setInitialValues({
      ...rest,
      number_of_uses: Number.isInteger(number_of_uses)
        ? number_of_uses
        : "Unlimited",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const {
      custom_expiration,
      discount_card_id,
      discount_card_merchant_id,
      estimated_value_per_use,
      notes,
      number_of_uses,
      offer_name,
      redemption_method,
      redemption_code,
      restrictions_text,
    } = values;
    const update = {
      custom_expiration,
      discount_card_id,
      discount_card_merchant_id,
      estimated_value_per_use,
      notes,
      offer_name,
      redemption_method,
      redemption_code,
      restrictions_text,
      number_of_uses: Number.isInteger(number_of_uses) ? number_of_uses : null,
    };
    const url = `/discount_card_offers/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Offer updated", ToastTypes.success));
    onClose();
    refreshList();
  };

  const onDelete = async () => {
    const url = `/discount_card_offers/${id}`;
    const res = await APIAuthClient.delete<any, APIRes>(url);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Offer deleted", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Edit offer</h1>
                <IconButton onClick={onClose} className={classes.close}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div className={classes.mainContent}>
                <TextField
                  label="Merchant"
                  value={merchant_name}
                  disabled
                  className={classes.input}
                />
                <Field
                  component={TextFieldWrapper}
                  name="offer_name"
                  label="Offer"
                  validate={requiredField}
                  className={classes.input}
                />
                <div className={classes.inputGroup}>
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="number_of_uses"
                    label="Number of uses"
                    validate={requiredField}
                    className={classes.halfInput}
                    SelectProps={{ displayEmpty: true }}
                  >
                    {discountCardOfferUsesOptions.map(uses => (
                      <MenuItem key={uses} value={uses}>
                        {uses}
                      </MenuItem>
                    ))}
                  </Field>
                  <NullableField
                    component={TextFieldWrapper}
                    name="custom_expiration"
                    label="Custom Expiration (optional)"
                    type="date"
                    className={classes.halfInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                {display_estimated_values && (
                  <Field
                    component={TextFieldWrapper}
                    type="number"
                    name="estimated_value_per_use"
                    label="Estimated value per use"
                    validate={composeValidators(requiredField, twoDecimalsOnly)}
                    className={classes.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}

                <div className={classes.inputGroup}>
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="redemption_method"
                    label="Redemption method"
                    className={classes.halfInput}
                    validate={requiredField}
                    SelectProps={{ displayEmpty: true }}
                  >
                    {discountCardOfferRedemptionMethods.map(method => (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    ))}
                  </Field>

                  {Boolean(values.redemption_method) &&
                    values.redemption_method !==
                      DiscountCardOfferRedemptionMethods.Manual && (
                      <Field
                        component={TextFieldWrapper}
                        name="redemption_code"
                        label="Redemption code"
                        validate={requiredField}
                        className={classes.halfInput}
                      />
                    )}
                </div>

                <NullableField
                  component={TextFieldWrapper}
                  name="restrictions_text"
                  label="Restrictions text"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />
                <NullableField
                  component={TextFieldWrapper}
                  name="notes"
                  label="Notes"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />
              </div>

              <div className={classes.buttons}>
                <div>
                  <RedButton
                    variant="text"
                    disabled={submitting}
                    onClick={() => setShowDelete(true)}
                    startIcon={<DeleteIcon />}
                  >
                    DELETE
                  </RedButton>
                </div>
                <div className={classes.saveAndCancel}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
      {showDelete && (
        <ConfirmationDialog
          title="Are you sure you want to delete this offer?"
          actionFunc={onDelete}
          onClose={() => setShowDelete(false)}
          redButton
          actionLabel="DELETE"
        />
      )}
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      borderBottom: "none",
      padding: "24px 12px 18px 16px",
    },
  },
  close: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -16,
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 8,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
      paddingBottom: 8,
    },
  },
  saveAndCancel: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
