import { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import { GenericObject } from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import { formatMoney } from "../../../../lib";
import classNames from "classnames";

type Props = {
  donation: GenericObject;
};

export function ProductsCard({ donation }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const { products } = donation;

  if (!Array.isArray(products) || !products.length) {
    return (
      <div className={sharedClasses.card}>
        <div className={sharedClasses.subheader}>Products</div>
        <div className={classes.noProducts}>No Products</div>
      </div>
    );
  }
  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Products</div>
      {products.map((product, index) => {
        const { product_name, price, qty, thumbnail_image, sku } = product;
        const isLast = index === products.length - 1;
        return (
          <Fragment key={index}>
            <div className={classes.productRow}>
              <div>
                <div className={classes.imgAndName}>
                  <div className={classes.imgWrapper}>
                    <img
                      src={thumbnail_image}
                      alt="product"
                      className={classes.img}
                    />
                  </div>
                  <div>
                    <div className={classes.nameAndPrice}>{product_name}</div>
                    <div className={classes.sku}>{`SKU: ${sku}`}</div>
                  </div>
                </div>
              </div>
              <div className={classes.leftColumn}>
                <div className={classes.nameAndPrice}>{formatMoney(price)}</div>
                <div>{`x${qty}`}</div>
              </div>
            </div>
            <div
              className={classNames(!isLast ? classes.divider : classes.spacer)}
            />
          </Fragment>
        );
      })}
    </div>
  );
}

const styles = makeStyles(theme => ({
  noProducts: {
    marginBottom: 16,
  },
  productRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  imgAndName: {
    display: "flex",
  },
  imgWrapper: {
    width: 64,
    height: 64,
    minWidth: 64,
    minHeight: 64,
    marginRight: 16,
  },
  img: {
    width: "100%",
    height: "auto",
    maxHeight: "100%",
    borderRadius: 8,
  },
  nameAndPrice: {
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "18px",
    paddingBottom: 8,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "17px",
    },
  },
  sku: {
    letterSpacing: 0.2,
    lineHeight: "18px",
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
  },
  leftColumn: {
    minWidth: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingLeft: 12,
  },
  divider: {
    width: "100%",
    height: 1,
    borderBottom: "1px solid #EAEBF3",
    margin: "16px 0",
  },
  spacer: {
    paddingBottom: 16,
  },
}));
