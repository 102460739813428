import React from "react";
import ReactDOM from "react-dom";
import "./css/quilljs.css";
import "./fonts/graphik.css";
import "./css/index.css";
import "./css/newApp.css";
import "./css/slick.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { init, ErrorBoundary, Replay, BrowserTracing } from "@sentry/react";
import { Button } from "@material-ui/core";

init({
  dsn:
    "https://7373edb7e5464e578c8b2fe3b32316ba@o4504804121837568.ingest.sentry.io/4505080894652417",
  integrations: [new BrowserTracing(), new Replay()],
  beforeSend: (event, _hint) => {
    return process.env.REACT_APP_ENV !== "development" ? event : null;
  },
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: ["Non-Error promise rejection captured"],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: process.env.REACT_APP_ENV === "production" ? 1 : 0,
  tracesSampleRate: process.env.REACT_APP_ENV === "production" ? 1 : 0,
});
// the `ErrorScreen` may never happen because react-admin has their own error handling and it may never propagate up to here
ReactDOM.render(
  <ErrorBoundary fallback={<ErrorScreen />}>
    <App />
  </ErrorBoundary>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function ErrorScreen() {
  return (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          marginBottom: 24,
          textAlign: "center",
          fontSize: 22,
          lineHeight: "32px",
          padding: "0 8px",
        }}
      >
        <div>We are sorry</div>
        <div>an unexpected Error ocurred</div>
      </div>

      <Button variant="contained" onClick={() => window.location.reload()}>
        Reload page
      </Button>
    </div>
  );
}
