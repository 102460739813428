import { QuillOptions } from "quill";

export const ROUTING_NUM_REGEX = /^\d{9}$/;
export const DIGITS_ONLY_REGEX = /^\d+$/;

export const quillConfigOptions: QuillOptions = {
  debug: "warn",
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  },
  theme: "snow",
};

export type GenericObject = Record<string, unknown>;
export type ObjectOfObjects = Record<string, GenericObject>;
export type StringObject = Record<string, string>;

export type PaymentMethod = "cc" | "check" | "cash" | "pledge";

export enum PaymentMethods {
  cc = "Credit Card",
  check = "Check",
  cash = "Cash",
  pledge = "Pledge",
}

export interface Base64File {
  title: string;
  base64: string | ArrayBuffer | null;
}

export const ACHAccountTypeOptions = [
  { id: "Business Checking", name: "Business Checking" },
  { id: "Business Savings", name: "Business Savings" },
  { id: "Personal Checking", name: "Personal Checking" },
  { id: "Personal Savings", name: "Personal Savings" },
];

export type GenericAutocomplete = {
  id: number;
  name: string;
}[];
