import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  makeStyles,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  composeValidators,
  requiredField,
  twoDecimalsOnly,
} from "../../../lib";
import {
  APIRes,
  DiscountCardOfferRedemptionMethods,
  GenericObject,
  ToastTypes,
  DiscountCardMerchantAutocomplete,
  discountCardOfferRedemptionMethods,
  discountCardOfferUsesOptions,
} from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  NullableField,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
const DEFAULT_RESTRICTION_TXT =
  "Cannot be combined with any other offer or special. Subject to product/service availability. Void where use is prohibited by law. Offer subject to change without notice. Buy one get one offers are valid for products/services of equal/lesser value. Additional restrictions may apply.";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  cardRecord: GenericObject;
};

export function AddOffer({
  onClose: _onClose,
  refreshList,
  cardRecord,
}: Props) {
  const { id: discount_card_id, display_estimated_values } = cardRecord;
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [merchants, setMerchants] = useState<DiscountCardMerchantAutocomplete>(
    [],
  );

  useEffect(() => {
    const fetch = async () => {
      const url = "/discount_card_merchants/autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return;
      }
      setMerchants(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const { number_of_uses, ...rest } = values;
    const post = {
      number_of_uses: Number.isInteger(number_of_uses) ? number_of_uses : null,
      discount_card_id,
      ...rest,
    };
    const url = `/discount_card_offers`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Offer added", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={{ restrictions_text: DEFAULT_RESTRICTION_TXT }}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Add offer</h1>
                {isMobile && (
                  <IconButton onClick={onClose} className={classes.close}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className={classes.mainContent}>
                <Field
                  select
                  component={TextFieldWrapper}
                  name="discount_card_merchant_id"
                  label="Merchant"
                  validate={requiredField}
                  className={classes.input}
                >
                  {merchants.map(({ id, merchant_name }) => (
                    <MenuItem key={id} value={id}>
                      {merchant_name}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  component={TextFieldWrapper}
                  name="offer_name"
                  label="Offer"
                  validate={requiredField}
                  className={classes.input}
                />
                <div className={classes.inputGroup}>
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="number_of_uses"
                    label="Number of uses"
                    validate={requiredField}
                    className={classes.halfInput}
                    SelectProps={{ displayEmpty: true }}
                  >
                    {discountCardOfferUsesOptions.map(uses => (
                      <MenuItem key={uses} value={uses}>
                        {uses}
                      </MenuItem>
                    ))}
                  </Field>
                  <NullableField
                    component={TextFieldWrapper}
                    name="custom_expiration"
                    label="Custom Expiration (optional)"
                    type="date"
                    className={classes.halfInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                {display_estimated_values && (
                  <Field
                    component={TextFieldWrapper}
                    type="number"
                    name="estimated_value_per_use"
                    label="Estimated value per use"
                    validate={composeValidators(requiredField, twoDecimalsOnly)}
                    className={classes.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}

                <div className={classes.inputGroup}>
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="redemption_method"
                    label="Redemption method"
                    className={classes.halfInput}
                    validate={requiredField}
                    SelectProps={{ displayEmpty: true }}
                  >
                    {discountCardOfferRedemptionMethods.map(method => (
                      <MenuItem key={method} value={method}>
                        {method}
                      </MenuItem>
                    ))}
                  </Field>

                  {Boolean(values.redemption_method) &&
                    values.redemption_method !==
                      DiscountCardOfferRedemptionMethods.Manual && (
                      <Field
                        component={TextFieldWrapper}
                        name="redemption_code"
                        label="Redemption code"
                        validate={requiredField}
                        className={classes.halfInput}
                      />
                    )}
                </div>

                <NullableField
                  component={TextFieldWrapper}
                  name="restrictions_text"
                  label="Restrictions text"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />
                <NullableField
                  component={TextFieldWrapper}
                  name="notes"
                  label="Notes"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />
              </div>

              <div className={classes.buttons}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      borderBottom: "none",
      padding: "24px 12px 18px 16px",
    },
  },
  close: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -16,
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 8,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
