import React from "react";
import {
  Create,
  TextInput,
  SimpleForm,
  ImageInput,
  ImageField,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

export function CreateShowcaseItem({ basePath, campaign_id, onSuccess }) {
  const classes = styles();

  return (
    <Create
      resource="campaign_showcase_items"
      basePath={basePath}
      title=" "
      onSuccess={() => onSuccess("Item added successfully")}
    >
      <SimpleForm initialValues={{ campaign_id }} className={classes.container}>
        <TextInput
          source="line_1"
          label="Line 1 (small text)"
          multiline
          fullWidth
        />
        <TextInput
          source="line_2"
          label="Line 2 (large text)"
          multiline
          fullWidth
        />
        <TextInput
          source="line_3"
          label="Line 3 (medium text)"
          multiline
          fullWidth
        />
        <TextInput source="paragraph" multiline fullWidth />
        <ImageInput
          source="imageBase64"
          label="Picture (square - currently 280px X 280px)"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="src" title="title" className={classes.image} />
        </ImageInput>
        <TextInput source="video_link" label="Video Link" fullWidth />
      </SimpleForm>
    </Create>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 800,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  image: {
    maxWidth: 200,
    "& img": { maxWidth: "100%" },
  },
}));
