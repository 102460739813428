import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";

const requiredField = [required()];

export default function DigitalContentInventoryGroupCreate(props) {
  return (
    <Create
      {...props}
      successMessage="Digital Content Inventory Group added successfully"
      title="Add Digital Content Inventory Group"
    >
      <SimpleForm redirect="list">
        <TextInput source="group_name" validate={requiredField} />
      </SimpleForm>
    </Create>
  );
}
