import { Fragment, useEffect, useState } from "react";
import { useNotify, Notification } from "react-admin";
import {
  Button,
  Dialog,
  IconButton,
  TextField,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { APIRes } from "../../../newApp/types";
import { ButtonSpinner, CircularLoader } from "../../../newApp/components";
import { DiscountCardAutocomplete } from "../../../newApp/types/discountCard";
import { APIClient, generateAuthHeader } from "../../../lib";

type Props = {
  onClose: () => void;
  campaign_id: any;
  product_id: any;
  setShowDiscountCard: React.Dispatch<React.SetStateAction<boolean>>;
  setDiscountCardId: React.Dispatch<any>;
  shouldRefetchRef: React.MutableRefObject<boolean>;
};
export function AssignDiscountCardToCampaignProduct({
  onClose,
  campaign_id,
  product_id,
  setDiscountCardId,
  setShowDiscountCard,
  shouldRefetchRef,
}: Props) {
  const classes = styles();
  const notify = useNotify();
  const [loading, setLoading] = useState(true);
  const [cardTemplates, setCardTemplates] = useState<DiscountCardAutocomplete>(
    [],
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const url = "/discount_cards/template_autocomplete";
      const res = await APIClient.get<any, APIRes>(url, {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) {
        notify(errorMessage || "Unexpected error", { type: "warning" });
      } else setCardTemplates(data);

      setLoading(false);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = async () => {
    setSubmitting(true);
    shouldRefetchRef.current = true;
    const url = "/discount_cards/create_campaign_card_from_template";
    const post = {
      discount_card_id: Number(selectedTemplateId),
      campaign_id,
      product_id,
    };
    const res = await APIClient.post<any, APIRes>(url, post, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) {
      notify(errorMessage || "Unexpected error", { type: "warning" });
      setSubmitting(false);
    } else {
      setDiscountCardId(data.id);
      setShowDiscountCard(true);
      onClose();
    }
  };

  return (
    <Dialog open={true} maxWidth={false}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div className={classes.header}>Assign campaign discount card</div>
          <div className={classes.close}>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <CircularLoader show={loading} />
        {!loading && (
          <Fragment>
            <div className={classes.inputLabel}>
              Select a Discount card template to use
            </div>
            <TextField
              select
              fullWidth
              value={selectedTemplateId}
              onChange={({ target }) => setSelectedTemplateId(target.value)}
              SelectProps={{
                displayEmpty: true,
              }}
            >
              <MenuItem value="" disabled>
                Select an option
              </MenuItem>
              {cardTemplates.map(({ id, card_name }) => (
                <MenuItem key={id} value={id}>
                  {card_name}
                </MenuItem>
              ))}
            </TextField>
            <Button
              color="primary"
              variant="contained"
              className={classes.save}
              disabled={submitting || !selectedTemplateId}
              onClick={save}
            >
              Save
              <ButtonSpinner show={submitting} />
            </Button>
          </Fragment>
        )}
      </div>
      <Notification />
    </Dialog>
  );
}

const styles = makeStyles(theme => ({
  container: {
    padding: 24,
    width: 424,
    maxWidth: "100%",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  header: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: "center",
    paddingBottom: 32,
  },
  close: {
    marginRight: -8,
    marginTop: -8,
  },
  inputLabel: {
    color: theme.palette.text.secondary,
    fontSize: 13,
    paddingBottom: "8px",
  },
  save: { marginTop: 32 },
}));
