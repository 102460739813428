import { IconButton, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import MaterialIcon from "@material-ui/icons/PermMediaOutlined";
import { RootState } from "../../../types/state";
import { paths } from "../../../types";
import { useHistory } from "react-router-dom";

export function MediaCenter() {
  const classes = styles();
  const history = useHistory();
  const hasMaterialBundle = useSelector(
    ({ campaign }: RootState) => campaign.hasMaterialBundle,
  );

  if (!hasMaterialBundle) return <></>;
  return (
    <div className={classes.container}>
      <div className={classes.iconWrapper}>
        <MaterialIcon color="primary" className={classes.materialIcon} />
      </div>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <h3 className={classes.title}>Media Center</h3>
          <IconButton
            onClick={() => history.push(paths.MEDIA_CENTER)}
            className={classes.iconButton}
          >
            <ArrowForwardIcon color="primary" fontSize="small" />
          </IconButton>
        </div>

        <div className={classes.text}>
          Access all campaign marketing resources.
        </div>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "16px 24px 24px 24px",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    display: "flex",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  iconWrapper: {
    height: 48,
    minWidth: 48,
    borderRadius: 8,
    backgroundColor: "#F1F9FF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 16,
  },
  iconButton: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  materialIcon: {
    fontSize: 31,
  },
  content: {
    flex: 1,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.12,
    lineHeight: "26px",
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: 8,
  },
  text: {
    fontSize: 14,
    letterSpacing: 0.47,
    lineHeight: "19px",
  },
}));
