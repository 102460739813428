import { SIX_DECIMAL_REGEX, TWO_DECIMAL_REGEX } from "./helpers";

const required = value => (value ? undefined : "Required");

const mustBeNumber = value => (isNaN(value) ? "Must be a number" : undefined);

const creditCard = value =>
  value && value.length !== 16 ? "Card number must be 16 digits" : undefined;

const CVV = value =>
  value && (value.length > 4 || value.length < 3)
    ? "CVV must be 3 or 4 digits"
    : undefined;

const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const pwMatchValidation = (value, allValues) => {
  if (value !== allValues.newPassword) {
    return "Passwords Must Match";
  }
  return null;
};

const emailOrPhoneValidation = (_value, allValues) => {
  const { email, phone } = allValues;
  if (!email && !phone) {
    return "email or phone is required";
  }
  return null;
};

const contactEmailOrPhoneValidation = (_value, allValues, meta) => {
  const contactSlot = meta.name.replace(/\D/g, "");
  const contact = allValues.contacts[contactSlot];
  if (!contact) return null;
  const { email, phone } = contact;
  if (!email && !phone) {
    return "email or phone is required";
  }
  return null;
};

const integerOnly = value =>
  value && !/^[0-9]+$/.test(value) ? "Must be a valid integer" : undefined;

const requiredInteger = value =>
  (!value && value !== 0) || !/^[0-9]+$/.test(value)
    ? "Must be a valid integer"
    : undefined;

const requiredPositiveInteger = value =>
  !value || value === "0" || !/^[0-9]+$/.test(value)
    ? "Must be a valid integer"
    : undefined;

const integerWithOptionalMinus = value =>
  value && !/^-?[0-9]+$/.test(value) ? "Must be a valid integer" : undefined;

const requiredPositiveOrNegativeInteger = value =>
  !value || !/^-?[0-9]+$/.test(value) ? "Must be a valid integer" : undefined;

const twoDecimalsOnly = value =>
  value && !TWO_DECIMAL_REGEX.test(value) ? "Up to 2 decimals only" : undefined;

const sixDecimalsOnly = value =>
  value && !SIX_DECIMAL_REGEX.test(value) ? "Up to 6 decimals only" : undefined;

const composeValidators = (...validators) => (value, allValues, fieldState) =>
  validators.reduce(
    (error, validator) => error || validator(value, allValues, fieldState),
    undefined,
  );

export {
  required,
  mustBeNumber,
  creditCard,
  minValue,
  email,
  CVV,
  integerOnly,
  integerWithOptionalMinus,
  pwMatchValidation,
  composeValidators,
  emailOrPhoneValidation,
  contactEmailOrPhoneValidation,
  twoDecimalsOnly,
  sixDecimalsOnly,
  requiredInteger,
  requiredPositiveInteger,
  requiredPositiveOrNegativeInteger,
};
