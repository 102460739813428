import { makeStyles, Tabs, Tab, Button } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { Fragment, useEffect, useState } from "react";
import { List, arrayMove } from "react-movable";
import { APIAuthClient } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { ButtonSpinner, CircularLoader } from "../../../components";
import { useIsMobile } from "../../../hooks/ui";
type Item = { id: number; name: string };
type Items = Item[];
enum Types {
  Both = "Both",
  Categories = "Categories",
  CategorizedMerchants = "CategorizedMerchants",
}

export function OfferOrdering({ discountCardId }: { discountCardId: any }) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(true);
  const [submittingType, setSubmittingType] = useState<Types | null>(null);
  const [categories, setCategories] = useState<Items>([]);
  const [categoryCopy, setCategoryCopy] = useState<Items>([]);
  const [categorizedMerchants, setCategorizedMerchants] = useState({});
  const [categoryId, setCategoryId] = useState("");
  const url = `/discount_cards/${discountCardId}/category_and_merchant_order`;

  useEffect(() => {
    const fetch = async () => {
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) dispatch(setToast(errorMessage));
      else {
        setupData(data);
        setLoading(false);
      }
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupData = (data: any) => {
    const {
      categories: _categories,
      categorizedMerchants: _categorizedMerchants,
    } = data;
    setCategories(_categories);
    setCategoryCopy(_categories);
    setCategorizedMerchants(_categorizedMerchants);
    if (_categories.length) setCategoryId(_categories[0].id);
  };

  const merchantChange = (newOrder: Items, categoryId: number | string) => {
    setCategorizedMerchants({
      ...categorizedMerchants,
      [categoryId]: newOrder,
    });
  };

  const saveChanges = async (type: Types) => {
    setSubmittingType(type);
    const update: GenericObject = {};
    if (type !== Types.CategorizedMerchants) {
      const categoryArr = categories.map(({ id }) => id);
      update.category_display_order = categoryArr;
    }
    if (type !== Types.Categories) {
      const merchantOrder = {};
      Object.keys(categorizedMerchants).forEach(catId => {
        merchantOrder[catId] = categorizedMerchants[catId].map(
          (m: Item) => m.id,
        );
      });
      update.category_merchant_display_order = merchantOrder;
    }

    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage, data } = res;
    if (error) dispatch(setToast(errorMessage));
    else {
      dispatch(setToast("Changes saved", ToastTypes.success));
      setupData(data);
    }
    setSubmittingType(null);
  };

  if (loading) {
    return (
      <div className={classes.loader}>
        <CircularLoader show />
      </div>
    );
  }
  return (
    <Fragment>
      <div className={classes.container}>
        {/* CATEGORIES */}
        <div className={classes.subheaderRow}>
          <div className={classes.subheader}>Categories sort order</div>
          {!isMobile && (
            <Button
              variant="contained"
              color="primary"
              className={classes.save}
              onClick={() => saveChanges(Types.Categories)}
              disabled={Boolean(submittingType)}
            >
              Save
              <ButtonSpinner show={submittingType === Types.Categories} />
            </Button>
          )}
        </div>

        <List
          values={categories}
          lockVertically={true}
          onChange={({ oldIndex, newIndex }) => {
            setCategories(arrayMove(categories, oldIndex, newIndex));
          }}
          renderList={({ children, props, isDragged }) => {
            return (
              <ul
                {...props}
                style={{ cursor: isDragged ? "grabbing" : undefined }}
                className={classes.list}
                onKeyDown={e => e.stopPropagation()}
              >
                {children}
              </ul>
            );
          }}
          renderItem={({ value, props, isDragged, isSelected }) => {
            return (
              <li
                key={value.name}
                {...props}
                style={{
                  ...props.style,
                  cursor: isDragged ? "grabbing" : "grab",
                  backgroundColor:
                    isDragged || isSelected ? "#EAEBF3" : "#FFFFFF",
                }}
                className={classes.item}
                onKeyDown={e => e.stopPropagation()}
              >
                <div className={classes.drag}>
                  <DragIndicatorIcon />
                </div>
                <div className={classes.itemText}>{value.name}</div>
              </li>
            );
          }}
        />

        {/* MERCHANTS */}
        <div className={classes.divider} />
        <div className={classes.subheaderRow}>
          <div className={classes.subheader}>Merchants sort order</div>
          {!isMobile && (
            <Button
              variant="contained"
              color="primary"
              className={classes.save}
              onClick={() => saveChanges(Types.CategorizedMerchants)}
              disabled={Boolean(submittingType)}
            >
              Save
              <ButtonSpinner
                show={submittingType === Types.CategorizedMerchants}
              />
            </Button>
          )}
        </div>
        <div className={classes.merchantContainer}>
          <Tabs
            value={categoryId ? categoryId : false}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, newValue) => setCategoryId(newValue)}
            classes={{ root: classes.tabs }}
            orientation={isMobile ? "horizontal" : "vertical"}
            variant="scrollable"
          >
            {categoryCopy.map(({ id, name }) => (
              <Tab key={id} value={id} label={name} className={classes.tab} />
            ))}
          </Tabs>
          {categoryId && Array.isArray(categorizedMerchants[categoryId]) && (
            <List
              values={categorizedMerchants[categoryId] as Items}
              lockVertically={true}
              onChange={({ oldIndex, newIndex }) => {
                merchantChange(
                  arrayMove(
                    categorizedMerchants[categoryId],
                    oldIndex,
                    newIndex,
                  ),
                  categoryId,
                );
              }}
              renderList={({ children, props, isDragged }) => (
                <ul
                  {...props}
                  style={{ cursor: isDragged ? "grabbing" : undefined }}
                  className={classes.list}
                >
                  {children}
                </ul>
              )}
              renderItem={({ value, props, isDragged, isSelected }) => {
                return (
                  <li
                    key={value.id}
                    {...props}
                    style={{
                      ...props.style,
                      cursor: isDragged ? "grabbing" : "grab",
                      backgroundColor:
                        isDragged || isSelected ? "#EAEBF3" : "#FFFFFF",
                    }}
                    className={classes.item}
                  >
                    <div className={classes.drag}>
                      <DragIndicatorIcon />
                    </div>
                    <div className={classes.itemText}>{value.name}</div>
                  </li>
                );
              }}
            />
          )}
        </div>
      </div>
      {isMobile && (
        <div className={classes.bottom}>
          <Button
            variant="contained"
            color="primary"
            className={classes.save}
            onClick={() => saveChanges(Types.Both)}
            disabled={Boolean(submittingType)}
          >
            Save
            <ButtonSpinner show={submittingType === Types.Both} />
          </Button>
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  loader: {
    marginTop: 50,
  },
  container: {
    padding: 24,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 0 16px",
    },
  },
  subheaderRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 4,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  subheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.15,
    color: theme.palette.primary.main,
  },
  save: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
    },
  },
  list: {
    padding: 0,
    width: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 80px)",
    },
  },
  item: {
    height: 56,
    width: 552,
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    marginBottom: 8,
    listStyleType: "none",
    zIndex: 99999,
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    letterSpacing: 0.15,
    overflow: "hidden",
    padding: "0 8px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drag: {
    paddingRight: 16,
  },
  itemText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  divider: {
    borderBottom: "1px solid #DBDEEE",
    marginBottom: 24,
    paddingTop: 32,
    [theme.breakpoints.down("sm")]: {
      marginRight: -16,
      marginLeft: -16,
    },
  },
  merchantContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: "1px solid #DBDEEE",
    width: "fit-content",
    marginRight: 40,
    [theme.breakpoints.down("sm")]: {
      marginRight: -16,
      marginLeft: -16,
      borderRight: "none",
      borderBottom: "1px solid #DBDEEE",
      width: "100vw",
      marginBottom: 24,
    },
  },
  tab: {
    maxWidth: "fit-content",
    minWidth: "fit-content",
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 18,
      paddingRight: 18,
    },
  },
  bottom: {
    position: "sticky",
    bottom: 0,
    padding: "16px 0",
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
  },
}));
