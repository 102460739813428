import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  regex,
  Toolbar,
  SaveButton,
  required,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { TWO_DECIMAL_REGEX } from "../../lib";

const validPercent = [
  required(),
  regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only"),
];

export default function ProfitCategoryCompanyEdit(props) {
  const classes = styles();

  const Title = ({ record }) =>
    `Editing - ${record ? record.profit_category_name : ""}`;

  const transformDataForSubmit = values => {
    const {
      org_profit_online_percent,
      org_profit_offline_percent,
      rep_commission_online_percent,
      rep_commission_offline_percent,
      rep_commission_custom_profit_product_online_percent,
      rep_commission_custom_profit_product_offline_percent,
    } = values;
    return {
      org_profit_online_percent,
      org_profit_offline_percent,
      rep_commission_online_percent,
      rep_commission_offline_percent,
      rep_commission_custom_profit_product_online_percent,
      rep_commission_custom_profit_product_offline_percent,
    };
  };

  return (
    <Edit
      title={<Title />}
      {...props}
      redirect="list"
      undoable={false}
      transform={transformDataForSubmit}
      successMessage="Record updated successfully"
    >
      <SimpleForm
        redirect="list"
        toolbar={
          <Toolbar>
            <SaveButton label="save" />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled={true} />
        <TextInput source="profit_category_name" disabled={true} />
        <TextInput source="profit_category_id" disabled={true} />
        <TextInput
          source="org_profit_online_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="org_profit_offline_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_online_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_offline_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_custom_profit_product_online_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_custom_profit_product_offline_percent"
          validate={validPercent}
          className={classes.input}
        />
      </SimpleForm>
    </Edit>
  );
}

const styles = makeStyles(_theme => ({
  input: { width: 416 },
}));
