import { Theme, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import TimeAgo from "react-timeago";
import { RootState } from "../../../types/state";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { GenericObject } from "../../../types";
import { getIsProductCampaign } from "../../../state";

const settings = {
  accessibility: false,
  arrows: false,
  dots: false,
  centerMode: true,
  infinite: true,
  focusOnSelect: false,
  swipeToSlide: false,
  swipe: false,
  draggable: false,
  touchMove: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  variableWidth: true,
  speed: 3000,
  autoplaySpeed: 0,
  cssEase: "linear",
  pauseOnHover: false,
};

export function RecentDonations({ donations }: { donations: GenericObject[] }) {
  const { secondary_color } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const isProduct = useSelector(getIsProductCampaign);
  const [secondaryColor, setSecondaryColor] = useState<string>("");
  const [carousel, setCarousel] = useState<GenericObject[]>([]);

  useEffect(() => {
    if (donations.length > 3) setCarousel(donations);
    else {
      setCarousel([...donations, ...donations, ...donations, ...donations]);
    }
  }, [donations]);

  useEffect(() => {
    if (secondary_color) setSecondaryColor(secondary_color);
  }, [secondary_color]);

  const classes = styles({ secondaryColor });

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        Recent {isProduct ? "sales" : "donations"}
      </h2>
      <div className={classes.sliderWrapper}>
        <Slider {...settings}>
          {carousel.map((donation, i) => {
            const {
              amount,
              created_at,
              donorName,
              first_name,
              last_name,
            } = donation;
            return (
              <div key={i} className={classes.cardWrapper}>
                <div className={classes.card}>
                  <div className={classes.topRow}>
                    <div className={classes.amount}>${amount}</div>
                    <TimeAgo
                      date={new Date(created_at as string)}
                      className={classes.timeAgo}
                    />
                  </div>
                  <div>
                    <div className={classes.donor}>{donorName}</div>
                    <div className={classes.volunteer}>
                      Via {first_name} {last_name}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

type StyleProps = {
  secondaryColor?: string;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: "100%",
    marginTop: 22,
    marginBottom: 32,
  },
  title: {
    marginBottom: 24,
    color: ({ secondaryColor }) => secondaryColor,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.23,
    lineHeight: "26px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      color: () => "#FFFFFF",
    },
  },
  sliderWrapper: {
    overflow: "hidden",
    maxHeight: 116,
  },
  cardWrapper: {
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    overflow: "hidden",
  },
  card: {
    height: 116,
    width: 250,
    borderRadius: 8,
    backgroundColor: "#EFEFEF",
    padding: "16px 16px 20px 16px",
    marginRight: 24,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
      width: 240,
      marginRight: 16,
    },
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  amount: {
    color: ({ secondaryColor }) => secondaryColor,
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: 0.25,
    lineHeight: "23px",
  },
  timeAgo: {
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "15px",
    color: theme.palette.text.secondary,
  },
  donor: {
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "19px",
    paddingBottom: 8,
    overflow: "hidden",
    textWrap: "nowrap",
    textOverflow: "ellipsis",
  },
  volunteer: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "18px",
    overflow: "hidden",
    textWrap: "nowrap",
    textOverflow: "ellipsis",
  },
}));
