import React from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  ImageInput,
  ImageField,
  Labeled,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { makeStyles } from "@material-ui/core";

export function EditShowcaseItem({ basePath, onSuccess, activeItemId }) {
  const classes = styles();

  const transformDataForEdit = values => {
    const { id: _rm, image: _rm2, ...rest } = values;
    return rest;
  };

  return (
    <Edit
      id={activeItemId}
      resource="campaign_showcase_items"
      basePath={basePath}
      title=" "
      onSuccess={() => onSuccess("Item updated successfully")}
      undoable={false}
      transform={transformDataForEdit}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
            <DeleteWithConfirmButton
              basePath={basePath}
              onSuccess={() => onSuccess("Item removed successfully")}
              undoable={false}
              className={classes.delete}
            />
          </Toolbar>
        }
        className={classes.container}
      >
        <TextInput
          source="line_1"
          label="Line 1 (small text)"
          multiline
          fullWidth
        />
        <TextInput
          source="line_2"
          label="Line 2 (large text)"
          multiline
          fullWidth
        />
        <TextInput
          source="line_3"
          label="Line 3 (medium text)"
          multiline
          fullWidth
        />
        <TextInput source="paragraph" multiline fullWidth />
        <Labeled label="Current Picture" formClassName={classes.imageSection}>
          <ImageField source="image" className={classes.image} />
        </Labeled>
        <ImageInput
          source="imageBase64"
          label="Change Picture (square - currently 280px X 280px)"
          accept="image/*"
          multiple={false}
          formClassName={classes.imageSection}
        >
          <ImageField source="src" title="title" className={classes.image} />
        </ImageInput>
        <TextInput source="video_link" label="Video Link" fullWidth />
      </SimpleForm>
    </Edit>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 800,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  delete: {
    position: "absolute",
    right: 16,
  },
  imageSection: {
    display: "inline-flex",
    marginRight: "10px",
  },
  image: {
    maxWidth: 200,
    "& img": {
      maxWidth: "100%",
    },
  },
}));
