import React from "react";
import { styles } from "./Dashboard.styles";

export const FulfillerAndDataEntryDashboard = () => {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.fulfillerHeader}>
        Welcome to Instaraise Management!
      </div>
      <div className={classes.fulfillerTxt}>
        Select an option in the menu to begin.
      </div>
    </div>
  );
};
