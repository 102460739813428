import {
  CircularProgress,
  Dialog,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { APIAuthClient } from "../../../lib";
import { APIRes } from "../../../types";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";

type Props = {
  close: () => void;
  id: number;
};
export function PreviewCard({ close, id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const url = `/discount_cards/${id}/get_preview_token`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return;
      }
      const src = `${process.env.REACT_APP_DISCOUNT_APP_URL}/admin/cards/${id}?jwt=${data.token}`;
      setIframeSrc(src);
    };
    if (id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Dialog open={true} fullScreen>
      <div className={classes.container}>
        <IconButton onClick={close} className={classes.close}>
          <CloseIcon />
        </IconButton>

        {!iframeSrc && (
          <div className={classes.loadingWrapper}>
            <CircularProgress size={48} />
          </div>
        )}

        {iframeSrc && (
          <iframe
            className={classes.iframe}
            title="Discount app"
            src={iframeSrc}
          ></iframe>
        )}
      </div>
    </Dialog>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    maxWidth: "100%",
    backgroundColor: theme.palette.secondary.main,
    padding: "32px 8px 24px 8px",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 40,
    },
  },
  close: {
    position: "absolute",
    top: 4,
    right: 4,
  },
  iframe: {
    border: "none",
    padding: 0,
    margin: 0,
    minHeight: "100%",
    width: 400,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  loadingWrapper: {
    paddingTop: 50,
  },
}));
