import { IconButton, makeStyles, Tabs, Tab } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { GenericObject } from "../../../types";
import { ResponsiveModal } from "../../../components";
import { useDrawerTransition } from "../../../hooks/ui";
import { useRef, useState } from "react";
import { EventGroupEvents } from "./EventGroupEvents";
import { EditEventGroup } from "./EditEventGroup";
enum Screens {
  Settings = "Settings",
  Events = "Events",
}

type Props = {
  onClose: () => void;
  refreshList: () => void;
  record: GenericObject;
};
export function EventGroupDetails({
  onClose: onCloseFunc,
  refreshList,
  record,
}: Props) {
  const { id, group_name } = record;
  const classes = styles();
  const refreshOnCloseRef = useRef(false);
  const { isOpen, onClose: _onClose } = useDrawerTransition(onCloseFunc);
  const [groupName, setGroupName] = useState(group_name as string);
  const [activeTab, setActiveTab] = useState<Screens>(Screens.Settings);

  const onClose = () => {
    if (refreshOnCloseRef.current) refreshList();
    _onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div className={classes.titleRow}>
            <div>
              <h2 className={classes.title}>{groupName}</h2>
              <div className={classes.groupId}>Group ID {id}</div>
            </div>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label="Settings"
              value={Screens.Settings}
              className={classes.tab}
            />
            <Tab
              label="Events"
              value={Screens.Events}
              className={classes.tab}
            />
          </Tabs>
        </div>

        <div className={classes.content}>
          {activeTab === Screens.Events && <EventGroupEvents groupId={id} />}
          {activeTab === Screens.Settings && (
            <EditEventGroup
              groupId={id}
              refreshOnCloseRef={refreshOnCloseRef}
              setGroupName={setGroupName}
            />
          )}
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 960,
    maxWidth: 960,
    maxHeight: "calc(100vh - 64px)",
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      overflow: "auto",
    },
  },
  top: {
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    borderBottom: "1px solid #C9CDDE",
  },
  titleRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      alignItems: "flex-start",
      lineHeight: "32px",
      position: "sticky",
      top: 0,
      borderBottom: "none",
      backgroundColor: "#FFFFFF",
      zIndex: 100,
      width: "100%",
    },
  },
  title: {
    lineHeight: "26px",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "19px",
      fontSize: 16,
    },
  },
  groupId: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "16px",
    color: theme.palette.text.secondary2,
    marginTop: 6,
  },
  tab: {
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      overflow: "auto",
    },
  },
}));
