import React, { useState, Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  minLength,
  email,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  NumberInput,
  FileInput,
  FileField,
  NullableBooleanInput,
} from "react-admin";
import { useSelector } from "react-redux";
import { useField } from "react-final-form";
import { getIsOwnerOrGivvr, getRoleBooleans } from "../../customState";
import { ageLevelOptions } from "../../lib";
import { styles } from "./OrgViews.styles";

const validateEmail = [required(), email(), minLength(2)];
const validateName = [required(), minLength(2)];
const requiredInput = [required()];

export default function OrgCreate(props) {
  const classes = styles();
  const isOwnerOrGivvr = useSelector(state => getIsOwnerOrGivvr(state));
  const { isCompanyOrCompanyAdmin } = useSelector(state =>
    getRoleBooleans(state),
  );
  const companyCanAllowOrgsToCreateCampaigns = useSelector(
    state => state.user.companyCanAllowOrgsToCreateCampaigns,
  );
  const [slugTouched, setSlugTouched] = useState(false);

  const SlugInput = props => {
    const nameField = useField("name");
    const slugField = useField("slug");
    setTimeout(() => {
      const name = nameField.input.value;
      if (name && !slugTouched)
        slugField.input.onChange(name.toLowerCase().replace(/\s/g, ""));
    }, 5);
    return (
      <TextInput
        {...props}
        source="slug"
        label="Unique URL"
        validate={requiredInput}
        onClick={() => setSlugTouched(true)}
      />
    );
  };

  const transformDataForSubmit = values => {
    const { class_options: CO, ...rest } = values;
    let class_options = CO;
    if (typeof class_options === "string") {
      class_options = class_options
        .trim()
        .replace(/,\s*$/, "")
        .split(",")
        .map(e => e.trim());
    }
    return { class_options, ...rest };
  };

  return (
    <Create
      {...props}
      successMessage="Org added successfully"
      transform={transformDataForSubmit}
    >
      <SimpleForm
        redirect="show"
        initialValues={{
          donation_notifications: false,
          allow_associated_volunteer_accounts: true,
        }}
        toolbar={
          <Toolbar>
            <SaveButton
              label="save"
              style={{ marginRight: "26px" }}
              redirect="show"
            />
            <SaveButton
              label="save & create campaign"
              redirect={(_basePath, id, _data) =>
                `/campaigns/create?org_id=${id}`
              }
            />
          </Toolbar>
        }
      >
        <TextInput source="name" validate={validateName} />
        <SlugInput />
        <TextInput source="address" validate={requiredInput} />
        <TextInput source="city" validate={requiredInput} />
        <TextInput source="state" validate={requiredInput} />
        <TextInput source="zip" validate={requiredInput} />
        <TextInput source="country" />
        <TextInput source="contact_name" />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="phone" validate={requiredInput} />
        <TextInput source="website" />
        <SelectInput
          source="age_level"
          label="Age Level"
          choices={ageLevelOptions}
          translateChoice={false}
        />
        <Fragment>
          <div className={classes.warning}>
            This number is for reference only. It's not used on any reports,
            projections etc.
          </div>
          <div className={classes.warning}>
            Campaign level 'Student Count' is the number that is used in reports
            and projections.
          </div>
        </Fragment>
        <NumberInput source="num_of_students" step={1} label="Student Count" />
        {isOwnerOrGivvr && (
          <ReferenceInput
            label="Gateway Provider"
            source="gateway_provider_id"
            reference="gateway_providers"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        {isOwnerOrGivvr && <TextInput source="gateway_login_key" />}
        {isOwnerOrGivvr && <TextInput source="gateway_trans_key" />}
        {isOwnerOrGivvr && <TextInput source="gateway_public_key" />}

        <BooleanInput
          source="allow_associated_volunteer_accounts"
          label="Allow Associated Student Accounts (The same email can be used for multiple students and accounts can be cross accessed)"
          fullWidth
        />

        {isOwnerOrGivvr && <BooleanInput source="can_submit_pledges" />}

        <ArrayInput
          source="notification_emails"
          label="Send contact form submissions and donation notifications(if enabled) to"
          // style={{ width: "400px" }}
        >
          <SimpleFormIterator>
            <TextInput source="email" label="Email" validate={validateEmail} />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="donation_notifications"
          label="Enable Donation/Sale Notifications"
          fullWidth
        />

        <ImageInput
          source="logoBase64"
          label="Logo"
          accept="image/*"
          multiple={false}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput
          source="class_options"
          label="Class Options (comma separated list)"
          fullWidth
          multiline
        />
        <Fragment>
          <div>OR</div>
        </Fragment>

        <FileInput
          source="classOptionsBase64"
          label="Class Options Upload (1 column CSV without a header)"
          accept={"application/vnd.ms-excel, text/csv, text/plain"}
          multiple={false}
          fullWidth
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="notes" fullWidth multiline />
        {isCompanyOrCompanyAdmin && (
          <NullableBooleanInput
            label="Org Can Manage - Override (keep default to use company setting)"
            source="org_can_manage_override"
            className={classes.manageOverride}
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
          />
        )}
        {isCompanyOrCompanyAdmin && companyCanAllowOrgsToCreateCampaigns && (
          <NullableBooleanInput
            label="Org Can Create Campaigns - Override (keep default to use company setting)"
            source="org_can_create_campaigns_override"
            className={classes.manageOverride}
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
          />
        )}
      </SimpleForm>
    </Create>
  );
}
