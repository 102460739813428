import { FC } from "react";
import { makeStyles, Button, ButtonProps, Theme } from "@material-ui/core";
import { hexToRGBA } from "../../../lib";
import classNames from "classnames";

export const RedButton: FC<ButtonProps> = props => {
  const { className, variant = "contained", children, ...rest } = props;
  const classes = styles({ variant });

  return (
    <Button
      className={classNames(classes.button, className && className)}
      {...rest}
      variant={variant}
    >
      {children}
    </Button>
  );
};

type StyleProps = {
  variant: "text" | "outlined" | "contained";
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  button: {
    color: ({ variant }) =>
      variant === "contained" ? "#FFFFFF" : theme.palette.error.main,
    textTransform: ({ variant }) =>
      variant === "contained" ? "unset" : "uppercase",
    backgroundColor: ({ variant }) =>
      variant === "contained" ? theme.palette.error.main : "inherit",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: ({ variant }) =>
        variant === "contained"
          ? theme.palette.error.main
          : hexToRGBA(theme.palette.error.main, 0.1),
    },
  },
}));
