import { SearchFields } from "./list";

export type DiscountCardMerchantAutocomplete = {
  id: number;
  merchant_name: string;
}[];

export const discountCardMerchantSearchFields: SearchFields = [
  { label: "Name", field: "merchant_name", placeholder: "Merchant Name" },
  {
    label: "ID",
    field: "discount_card_merchant_id",
    placeholder: "Merchant ID",
    isNumber: true,
  },
];

export enum DiscountCardMerchantFields {
  include_inactive = "include_inactive",
  discount_card_category_id = "discount_card_category_id",
}

export const discountCardMerchantSortFields = [
  { field: "id", label: "ID" },
  { field: "merchant_name", label: "Merchant name" },
  { field: "discount_card_category_id", label: "Category" },
  { field: "active", label: "Active" },
];
