import React from "react";
import { Show, TabbedShowLayout, TabbedShowLayoutTabs, Tab } from "react-admin";
import { DonationGroupItemList } from "./DonationGroupItemList";
import { DonationGroup } from "./DonationGroup";
import { DonationGroupCampaignDefaults } from "./DonationGroupCampaignDefaults";
import { DonationGroupItemsOrdering } from "../../components";

const Title = ({ record }) => {
  return <span>Donation Group - {record ? `${record.group_name}` : ""}</span>;
};

export function DonationGroupDetails({ id, resource, basePath }) {
  const props = { id, resource, basePath };

  return (
    <Show {...props} title={<Title />} actions={null}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="group items" path="">
          <DonationGroupItemList />
        </Tab>
        <Tab label="group config" path="group-config">
          <DonationGroup />
        </Tab>
        <Tab label="group campaign defaults" path="group-campaign-defaults">
          <DonationGroupCampaignDefaults />
        </Tab>
        <Tab label="group item order" path="group-item-order">
          <DonationGroupItemsOrdering
            group_id={id}
            urlPrefix="donation_group_items"
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
