// cSpell:ignore appversion, appsource
import axios, { AxiosResponse } from "axios";
import { getAuthToken } from "../../lib/tokenUtils";
const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_UTIL_BASE_URL,
  REACT_APP_VERSION,
} = process.env;

/** Axios instances to use for API requests. */

const APIPublicClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    appversion: REACT_APP_VERSION as string,
    appsource: "newAdminPublic",
  },
});
APIPublicClient.interceptors.response.use(
  success => successCB(success),
  e => errorCB(e),
);

const APIAuthClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${getAuthToken()}`,
    appversion: REACT_APP_VERSION as string,
    appsource: "newAdmin",
  },
});
APIAuthClient.interceptors.response.use(
  success => successCB(success),
  e => errorCB(e),
);

/** (used for reports & PDF downloads) */
const APIUtilAuthClient = axios.create({
  baseURL: REACT_APP_API_UTIL_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${getAuthToken()}`,
    appversion: REACT_APP_VERSION as string,
    appsource: "newAdminUtil",
  },
});
APIUtilAuthClient.interceptors.response.use(
  success => successCB(success),
  e => errorCB(e),
);

function errorCB(error: any) {
  if (!error || !error.response || !error.response.data) {
    return {
      error: true,
      errorMessage:
        "A connection error occurred, this may be related to  your network/internet/device",
      errorData: {},
    };
  }
  const {
    status,
    data: { code, message, errorData = {} },
  } = error.response;
  return {
    error: true,
    code,
    errorMessage: message,
    status,
    errorData,
    data: {},
  };
}

function successCB(res: AxiosResponse) {
  const totalRows =
    res && res.headers ? Number(res.headers["x-total-count"]) : 0;
  return res && res.data
    ? { data: res.data, totalRows }
    : { data: {}, totalRows };
}

export { APIPublicClient, APIAuthClient, APIUtilAuthClient };
