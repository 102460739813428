import React from "react";
import { Dialog, Button, IconButton } from "@material-ui/core";
import { styles } from "./RepCommission.styles";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import CloseIcon from "@material-ui/icons/Close";
import { Notification, useNotify } from "react-admin";
import { APIClient, generateAuthHeader, required } from "../../../lib";

export function PaidCommissionEmailModal({
  setShowEmailModal,
  template,
  campaignId,
}) {
  const classes = styles();
  const notify = useNotify();

  const onSubmit = async values => {
    const res = await APIClient.post(
      `/rep_commission/campaigns/${campaignId}/send-paid-email`,
      values,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Email successfully sent!", { type: "info" });
    setShowEmailModal(false);
  };

  return (
    <Dialog
      open={true}
      onClose={() => setShowEmailModal(false)}
      maxWidth={false}
    >
      <div className={classes.dialog}>
        <div className={classes.dialogHeader}>
          <div>Send rep a commission paid email</div>
          <IconButton
            className={classes.closeIcon}
            onClick={() => setShowEmailModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          onSubmit={onSubmit}
          initialValues={{
            subject: "Your commission payment was sent",
            message: template,
          }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="subject"
                  variant="outlined"
                  label="Subject"
                  component={TextField}
                  validate={required}
                  fullWidth
                  className={classes.modalInput}
                />
                <Field
                  name="message"
                  variant="outlined"
                  label="Message"
                  component={TextField}
                  validate={required}
                  fullWidth
                  multiline={true}
                  minRows="12"
                  className={classes.modalInput}
                />
                <Button
                  type="submit"
                  disabled={submitting}
                  variant="contained"
                  color="primary"
                >
                  SEND
                </Button>
              </form>
            );
          }}
        />
      </div>
      <Notification />
    </Dialog>
  );
}
