import React from "react";
import { Datagrid, List, TextField, Filter, TextInput } from "react-admin";

const DigitalContentFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Content Name" source="q" alwaysOn />
    </Filter>
  );
};

export default function DigitalContentInventoryGroupList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Digital Content"
      bulkActionButtons={false}
      filters={<DigitalContentFilter />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="group_name" />
      </Datagrid>
    </List>
  );
}
