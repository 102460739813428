import React, { useState } from "react";
import {
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  useListController,
  ListView,
  Pagination,
  TopToolbar,
  required,
  useNotify,
  Edit,
  Toolbar,
  SaveButton,
} from "react-admin";
import { Button, Drawer, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { styles } from "./Product.styles";
const requiredInput = [required()];
const categoryAsideModes = { CREATE: "add", EDIT: "edit", CLOSE: null };

export function CategoryList(props) {
  const { location, id: supplier_id } = props;
  const classes = styles();
  const [categoryAsideMode, setCategoryAsideMode] = useState(null);
  const [categoryRecordData, setCategoryRecordData] = useState({});
  const notify = useNotify();
  const basePath = `/product_suppliers/${supplier_id}/show/product-categories`;

  const controllerProps = useListController({
    sort: { field: "category_name", order: "ASC" },
    location,
    basePath,
    resource: "product_categories",
    filter: { supplier_id },
    perPage: 100,
    syncWithLocation: false,
  });

  const onSuccess = msg => {
    controllerProps.refetch();
    setCategoryAsideMode(categoryAsideModes.CLOSE);
    notify(msg);
  };

  const CategoryEditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const CategoryActions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setCategoryAsideMode(categoryAsideModes.CREATE)}
      >
        add category
      </Button>
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<CategoryActions />}
        sort={{ field: "category_name", order: "DESC" }}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
      >
        <Datagrid
          rowClick={(id, _basePath, record) => {
            setCategoryRecordData({
              id,
              basePath: "",
              record,
              resource: "product_categories",
            });
            setCategoryAsideMode(categoryAsideModes.EDIT);
          }}
        >
          <TextField source="id" />
          <TextField source="category_name" />
        </Datagrid>
      </ListView>

      <Drawer open={Boolean(categoryAsideMode)} anchor="right">
        <div className={classes.drawerContent}>
          <div className={classes.drawerHeader}>
            <div>
              {categoryAsideMode === categoryAsideModes.CREATE
                ? "Add a Category"
                : "Edit Category"}
            </div>
            <IconButton
              onClick={() => setCategoryAsideMode(categoryAsideModes.CLOSE)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {categoryAsideMode === categoryAsideModes.CREATE && (
            <Create
              basePath=""
              resource="product_categories"
              title=" "
              onSuccess={() => onSuccess("Category added successfully")}
            >
              <SimpleForm
                initialValues={{
                  product_supplier_id: Number(supplier_id),
                }}
              >
                <TextInput source="category_name" validate={requiredInput} />
              </SimpleForm>
            </Create>
          )}

          {categoryAsideMode === categoryAsideModes.EDIT && (
            <div>
              <Edit
                title=" "
                {...categoryRecordData}
                undoable={false}
                onSuccess={() => onSuccess("Category updated successfully")}
              >
                <SimpleForm toolbar={<CategoryEditToolbar />}>
                  <TextInput source="category_name" validate={requiredInput} />
                </SimpleForm>
              </Edit>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}
