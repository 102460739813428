import jwtDecode from "jwt-decode";
import { Location, History } from "history";
import queryString from "query-string";
import {
  Roles,
  volunteerAuthPaths,
  orgAuthPaths,
  orgEmployeeAuthPaths,
  paths,
} from "../types";
import { isOrgOrVolunteer } from "../../lib";
import { getIsImpersonating } from "./uiHelpers";
import { validateAndGetInt } from "./generalHelpers";

export function validJWTCheck(): boolean {
  try {
    const token = localStorage.getItem("token");
    if (!token) return false;
    const decoded: any = jwtDecode(token);
    const { exp } = decoded;
    if (exp * 1000 < new Date().getTime()) {
      return false;
    }
  } catch (_error) {
    return false;
  }
  return true;
}

export function pathAuthCheck(path: string, role: Roles): boolean {
  switch (role) {
    case Roles.VOLUNTEER:
      return Boolean(volunteerAuthPaths[path]);
    case Roles.ORG:
      return Boolean(orgAuthPaths[path]);
    case Roles.ORG_EMPLOYEE:
      return Boolean(orgEmployeeAuthPaths[path]);
    default:
      return false;
  }
}

export function clearAuthStorage() {
  localStorage.removeItem("token");
  localStorage.removeItem("orgImpersonationToken");
  localStorage.removeItem("volunteerImpersonationToken");
  localStorage.removeItem("companyImpersonationToken");
  localStorage.removeItem("superAdminCompanyToken");
  localStorage.removeItem("repImpersonationToken");
  sessionStorage.removeItem("isSuperAdminCompanyUser");
}

export function changeVolunteerToken(newToken: string) {
  if (localStorage.getItem("volunteerImpersonationToken")) {
    localStorage.setItem("volunteerImpersonationToken", newToken);
  } else {
    localStorage.setItem("token", newToken);
  }
}

export function getAuthJwt() {
  const token = localStorage.getItem("token");
  const superAdminCompanyToken = localStorage.getItem("superAdminCompanyToken");
  const companyIT = localStorage.getItem("companyImpersonationToken");
  const repIT = localStorage.getItem("repImpersonationToken");
  const orgIT = localStorage.getItem("orgImpersonationToken");
  const volunteerIT = localStorage.getItem("volunteerImpersonationToken");
  const isSuperAdminCompanyUser =
    sessionStorage.getItem("isSuperAdminCompanyUser") === "true";

  const authToken = volunteerIT
    ? volunteerIT
    : orgIT
    ? orgIT
    : repIT
    ? repIT
    : companyIT
    ? companyIT
    : isSuperAdminCompanyUser
    ? superAdminCompanyToken
    : token;

  return authToken;
}

export function loggedInCheck(location: Location<unknown>, history: History) {
  const {
    jwt,
    c: queryC,
    campaignId: queryCampaignId,
    campaign_id: queryCampaign_id,
    org_id: _queryOrgId,
  } = queryString.parse(location.search);

  if (jwt || !validJWTCheck() || getIsImpersonating()) {
    return clearAuthStorage();
  }

  try {
    const token = localStorage.getItem("token");
    if (!token) return clearAuthStorage();
    const decoded: any = jwtDecode(token);
    const { role, orgId } = decoded;

    if (!isOrgOrVolunteer(role)) return clearAuthStorage();

    const queryOrgId = validateAndGetInt(_queryOrgId);

    // if trying to access a different org then currently logged in one - log them out and let them log into the other one
    if (queryOrgId && queryOrgId !== orgId) {
      localStorage.removeItem("selectedCampaignId");
      return clearAuthStorage();
    }

    const _qCampaignId = validateAndGetInt(
      queryC ? queryC : queryCampaignId ? queryCampaignId : queryCampaign_id,
    );
    const qCampaignId = _qCampaignId ? _qCampaignId : "";

    history.replace(`${paths.HOME}?c=${qCampaignId}`);
  } catch (_error) {
    clearAuthStorage();
  }
}
