export function SharingSVG() {
  return (
    <svg width="122px" height="136px" viewBox="0 0 122 136">
      <title>Sharing</title>
      <defs>
        <polygon points="0 0.11825 61.4146 0.11825 61.4146 0.88175 0 0.88175"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-127, -64)">
          <g transform="translate(127, 64)">
            <path
              d="M51.0353,77.349 C51.3643,77.491 51.5163,77.872 51.3743,78.201 L51.3743,78.201 L50.4943,80.245 L50.5943,80.296 L49.9933,81.684 L49.8973,81.63 L46.7443,88.954 C46.5213,89.473 45.9483,89.745 45.4053,89.591 L40.5023,88.198 C40.0503,88.07 39.7883,87.599 39.9163,87.147 C39.9303,87.1 39.9473,87.054 39.9683,87.01 L44.9103,76.763 C45.1243,76.319 45.6163,76.083 46.0963,76.194 L50.9203,77.312 C50.9593,77.32 50.9983,77.333 51.0353,77.349"
              fill="#212121"
            ></path>
            <path
              d="M46.9548,76.9841 L49.4908,77.5711 C49.5848,77.5931 49.6818,77.5491 49.7268,77.4641 C49.7788,77.3681 49.8878,77.3181 49.9938,77.3431 L50.5288,77.4661 C50.8038,77.5301 50.9748,77.8051 50.9108,78.0801 C50.9048,78.1101 50.8948,78.1391 50.8828,78.1661 L46.3198,88.7881 C46.2018,89.0611 45.8988,89.2021 45.6148,89.1171 L40.7458,87.6481 C40.5078,87.5761 40.3728,87.3241 40.4448,87.0861 C40.4508,87.0641 40.4598,87.0431 40.4688,87.0231 L45.2918,76.8411 C45.4388,76.5321 45.7808,76.3671 46.1138,76.4441 L46.6768,76.5741 C46.6488,76.7631 46.7698,76.9411 46.9548,76.9841"
              fill="#FD7B6F"
            ></path>
            <path
              d="M66.7217,0.527 C60.8987,0.527 56.1787,5.247 56.1787,11.07 C56.1787,16.893 60.8987,21.613 66.7217,21.613 C72.5447,21.613 77.2647,16.893 77.2647,11.07 C77.2587,5.25 72.5417,0.534 66.7217,0.527 M66.7217,22.141 C60.6077,22.141 55.6517,17.184 55.6517,11.07 C55.6517,4.956 60.6077,0 66.7217,0 C72.8357,0 77.7917,4.956 77.7917,11.07 C77.7847,17.181 72.8327,22.134 66.7217,22.141"
              fill="#D8D8D8"
            ></path>
            <path
              d="M109.4216,10.8066 C103.5986,10.8066 98.8786,15.5266 98.8786,21.3496 C98.8786,27.1726 103.5986,31.8926 109.4216,31.8926 C115.2446,31.8926 119.9646,27.1726 119.9646,21.3496 C119.9586,15.5296 115.2416,10.8136 109.4216,10.8066 M109.4216,32.4206 C103.3076,32.4206 98.3516,27.4636 98.3516,21.3496 C98.3516,15.2356 103.3076,10.2796 109.4216,10.2796 C115.5356,10.2796 120.4916,15.2356 120.4916,21.3496 C120.4846,27.4606 115.5326,32.4136 109.4216,32.4206"
              fill="#D8D8D8"
            ></path>
            <path
              d="M89.6532,45.8627 C83.8302,45.8627 79.1102,50.5827 79.1102,56.4057 C79.1102,62.2287 83.8302,66.9487 89.6532,66.9487 C95.4762,66.9487 100.1962,62.2287 100.1962,56.4057 C100.1902,50.5857 95.4732,45.8697 89.6532,45.8627 M89.6532,67.4767 C83.5392,67.4767 78.5832,62.5197 78.5832,56.4057 C78.5832,50.2917 83.5392,45.3357 89.6532,45.3357 C95.7672,45.3357 100.7232,50.2917 100.7232,56.4057 C100.7162,62.5167 95.7642,67.4697 89.6532,67.4767"
              fill="#D8D8D8"
            ></path>
            <path
              d="M110.547567,20.6582848 L114.643233,16 L113.673033,16 L110.115267,20.0438968 L107.2758,16 L104,16 L108.294767,22.1156703 L104,27 L104.9702,27 L108.724867,22.7285928 L111.7242,27 L115,27 M105.320367,16.7158701 L106.810867,16.7158701 L113.6723,26.3193006 L112.181433,26.3193006"
              fill="#000000"
              fillRule="nonzero"
            ></path>
            <path
              d="M93.5629,60.1003 L91.8859,60.1003 L91.8859,57.2493 C91.8859,56.5663 91.6419,56.1003 91.0349,56.1003 C90.6459,56.1033 90.3019,56.3513 90.1749,56.7193 C90.1309,56.8513 90.1109,56.9913 90.1169,57.1313 L90.1169,60.1003 L88.4409,60.1003 L88.4399,54.8873 L90.1169,54.8863 L90.1169,55.6123 C90.4279,55.0743 91.0079,54.7493 91.6289,54.7643 C92.7299,54.7643 93.5629,55.4903 93.5629,57.0543 L93.5629,60.1003 Z M86.6529,54.0943 L86.6409,54.0943 C86.1379,54.0913 85.7319,53.6803 85.7359,53.1763 C85.7389,52.6733 86.1499,52.2673 86.6529,52.2702835 C87.1569,52.2743 87.5619,52.6853 87.5589166,53.1883 C87.5559,53.6873 87.1519,54.0913 86.6529,54.0943 L86.6529,54.0943 Z M87.4339,60.1013 L85.7569,60.1013 L85.7569,54.8873 L87.4339,54.8873 L87.4339,60.1013 Z M94.5739,50.3433 L84.8049,50.3443 C84.2789,50.3183 83.8309,50.7243 83.8049,51.2513 C83.8049,51.2533 83.8049,51.2543 83.8049,51.2553 L83.8059,61.0463 C83.8199,61.5933 84.2589,62.0343 84.8059,62.0493 L94.5729,62.0483 C95.1069,62.0273 95.5259,61.5803 95.5109,61.0453 L95.5109,51.2543 C95.5189,50.7593 95.1239,50.3503 94.6279,50.3423 C94.6099,50.3423 94.5919,50.3423 94.5739,50.3433 L94.5739,50.3433 Z"
              fill="#212121"
            ></path>
            <path
              d="M73.9586,11.1136 C73.9576,7.1166 70.7176,3.8776 66.7206,3.8776 C62.7236,3.8786 59.4836,7.1196 59.4845998,11.1166 C59.4856,14.6756 62.0746,17.7066 65.5906,18.2626 L65.5906,13.2066 L63.7526,13.2066 L63.7526,11.1136 L65.5906,11.1136 L65.5906,9.5186 C65.5906,7.7056 66.6716,6.7026 68.3246,6.7026 C68.8676,6.7106 69.4086,6.7576 69.9446,6.8446 L69.9446,8.6256 L69.0316,8.6256 C68.1336,8.6256 67.8526,9.1836 67.8526,9.7566 L67.8526,11.1136 L69.8586,11.1136 L69.5386,13.2066 L67.8526,13.2066 L67.8526,18.2636 C71.3696,17.7066 73.9586,14.6746 73.9586,11.1136 Z"
              fill="#212121"
            ></path>
            <polygon
              fill="#FFD1D2"
              points="34.8814 132.7018 37.8944 132.7018 39.3274 121.0788 34.8804 121.0788"
            ></polygon>
            <path
              d="M43.7062,135.4991 L34.2362,135.5001 L34.2352,131.8411 L40.0472,131.8401 C42.0672,131.8401 43.7062,133.4781 43.7062,135.4991 Z"
              fill="#212121"
            ></path>
            <polygon
              fill="#FFD1D2"
              points="18.6391 132.7018 21.6531 132.7018 23.0851 121.0788 18.6381 121.0788"
            ></polygon>
            <path
              d="M27.4641,135.4991 L17.9941,135.5001 L17.9931,131.8411 L23.8051,131.8401 C25.8251,131.8401 27.4641,133.4781 27.4641,135.4991 Z"
              fill="#212121"
            ></path>
            <path
              d="M27.4641,37.1284 C31.0391,37.1284 33.9381,40.0264 33.9381,43.6024 C33.9381,47.1774 31.0391,50.0764 27.4641,50.0764 C23.8891,50.0764 20.9901,47.1774 20.9901,43.6024 C20.9901,40.0264 23.8891,37.1284 27.4641,37.1284"
              fill="#FFD1D2"
            ></path>
            <path
              d="M49.6002,85.8219 C49.4292,84.4679 48.1932,83.5079 46.8392,83.6789 C46.6942,83.6969 46.5522,83.7279 46.4132,83.7719 L42.7952,79.4339 L39.6232,80.9899 L44.8182,87.0429 C45.3122,88.3239 46.7512,88.9609 48.0312,88.4669 C49.1022,88.0539 49.7512,86.9599 49.6002,85.8219 Z"
              fill="#FFD1D2"
            ></path>
            <path
              d="M18.4625,90.2083 C19.0445,88.9733 18.5165,87.5013 17.2815,86.9183 C17.1505,86.8563 17.0135,86.8063 16.8725,86.7683 L16.1465,81.1673 L12.6355,80.7783 L13.7715,88.6733 C13.5015,90.0193 14.3735,91.3293 15.7195,91.5993 C16.8455,91.8253 17.9795,91.2503 18.4625,90.2083 Z"
              fill="#FFD1D2"
            ></path>
            <path
              d="M13.5484,86.7834 C12.9604,86.7834 12.4614,86.3524 12.3754,85.7714 L10.6464,74.1324 C10.4294,72.6674 10.6054,71.1704 11.1564,69.7954 L16.4624,56.5534 C17.1434,54.8524 19.0744,54.0264 20.7744,54.7084 C21.3194,54.9264 21.7974,55.2854 22.1574,55.7484 L22.1574,55.7484 C22.9124,56.7154 23.0714,58.0214 22.5694,59.1404 L20.0674,64.7354 C17.2624,71.0134 16.2034,77.9314 17.0034,84.7594 C17.0704,85.3284 16.7214,85.8634 16.1734,86.0314 L13.8964,86.7314 C13.7844,86.7654 13.6664,86.7834 13.5484,86.7834"
              fill="#D8D8D8"
            ></path>
            <path
              d="M25.8878,50.0757 C22.0738,50.1577 18.9158,47.1327 18.8328,43.3197 C18.7508,39.5057 21.7758,36.3467 25.5898,36.2647 C26.1248,36.2527 26.6588,36.3037 27.1818,36.4157 C28.6058,35.5507 30.2608,34.9697 31.9198,35.1177 C33.5788,35.2657 35.2198,36.2507 35.8398,37.7957 C36.4598,39.3417 35.7938,41.3787 34.2508,42.0077 C33.2618,42.4097 32.1458,42.2147 31.0898,42.0527 C30.0348,41.8897 28.8838,41.7837 27.9648,42.3287 C27.0468,42.8727 26.6338,44.3327 27.4558,45.0137 C28.3488,45.7817 28.6728,47.0227 28.2688,48.1297 C27.8558,49.1987 26.7278,50.0577 25.8878,50.0757"
              fill="#212121"
            ></path>
            <path
              d="M17.7619,130.3601 C17.1069,130.3601 16.5759,129.8291 16.5749,129.1741 C16.5749,129.1641 16.5749,129.1531 16.5759,129.1431 L17.7269,85.4371 L17.8529,85.4341 L36.8899,85.0191 L40.1769,127.5181 C40.2139,128.0491 39.8929,128.5401 39.3919,128.7201 L39.3559,128.7271 L35.6189,128.9561 C34.9989,129.1651 34.3259,128.8321 34.1159,128.2111 C34.1029,128.1721 34.0909,128.1311 34.0819,128.0901 L27.9919,100.8371 C27.9439,100.6241 27.7329,100.4901 27.5199,100.5381 C27.3609,100.5731 27.2409,100.7011 27.2149,100.8611 L22.7159,129.1671 C22.6259,129.7191 22.1639,130.1351 21.6049,130.1651 L17.8219,130.3581 C17.8019,130.3591 17.7819,130.3601 17.7619,130.3601"
              fill="#212121"
            ></path>
            <path
              d="M23.3647,89.0545 C20.9957,89.0545 18.9267,88.6965 17.7847,87.6755 C17.0507,87.0195 16.7327,86.1265 16.8407,85.0225 C17.2467,80.8735 16.5437,71.7785 15.8817,64.8795 C15.5757,61.6115 16.5937,58.3565 18.7077,55.8455 C20.7417,53.4115 23.6797,51.9105 26.8437,51.6885 C29.0227,51.5325 34.1267,51.1705 35.7127,55.2345 C38.0957,61.3375 36.9307,64.1365 35.8037,66.8435 C34.8457,69.1435 33.8557,71.5215 35.0697,75.9865 C35.7397,78.4555 36.5517,80.8845 37.5017,83.2605 C38.1587,84.9465 38.4887,85.7925 38.1837,86.3445 C37.9437,86.7805 37.3447,86.9705 36.3227,87.1685 C35.9887,87.2325 35.5477,87.3265 35.0217,87.4385 C32.3517,88.0065 27.4187,89.0545 23.3647,89.0545"
              fill="#D8D8D8"
            ></path>
            <path
              d="M42.8147,85.2292 C42.8057,85.2292 42.7967,85.2292 42.7877,85.2292 C42.4417,85.2212 42.1167,85.0632 41.8977,84.7952 L35.1867,76.6182 C33.7537,74.8712 32.8107,72.7752 32.4527,70.5442 L30.3867,57.6312 C30.0977,55.8222 31.3297,54.1212 33.1377,53.8322 C34.9467,53.5422 36.6477,54.7742 36.9367,56.5832 C36.9437,56.6262 36.9497,56.6692 36.9557,56.7122 L37.7767,63.5612 C38.5367,69.8882 40.9897,75.8922 44.8787,80.9402 L45.3477,81.5492 C45.6977,82.0022 45.6737,82.6412 45.2897,83.0662 L43.6957,84.8372 C43.4707,85.0862 43.1507,85.2292 42.8147,85.2292"
              fill="#D8D8D8"
            ></path>
            <path
              d="M74.9924,15.8073 C77.4304,15.8073 79.4064,17.8053 79.4064,20.2693 C79.4064,22.7343 77.4304,24.7313 74.9924,24.7313 C72.5544,24.7313 70.5784,22.7343 70.5784,20.2693 C70.5784,17.8053 72.5544,15.8073 74.9924,15.8073"
              fill="#212121"
            ></path>
            <path
              d="M75.8751,22.6494 L74.9761,22.6494 L74.9761,19.2254 C74.6451,19.5374 74.2481,19.7724 73.8151,19.9134 L73.8151,19.0894 C74.0931,18.9874 74.3521,18.8374 74.5791,18.6484 C74.8371,18.4554 75.0341,18.1914 75.1461,17.8894 L75.8751,17.8894 L75.8751,22.6494 Z"
              fill="#212121"
            ></path>
            <path
              d="M74.9924,15.8073 C77.4304,15.8073 79.4064,17.8053 79.4064,20.2693 C79.4064,22.7343 77.4304,24.7313 74.9924,24.7313 C72.5544,24.7313 70.5784,22.7343 70.5784,20.2693 C70.5784,17.8053 72.5544,15.8073 74.9924,15.8073"
              fill="#2498FE"
            ></path>
            <path
              d="M75.8751,22.6494 L74.9761,22.6494 L74.9761,19.2254 C74.6451,19.5374 74.2481,19.7724 73.8151,19.9134 L73.8151,19.0894 C74.0931,18.9874 74.3521,18.8374 74.5791,18.6484 C74.8371,18.4554 75.0341,18.1914 75.1461,17.8894 L75.8751,17.8894 L75.8751,22.6494 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M117.4906,26.9687 C119.9546,26.9687 121.9526,28.9667 121.9526,31.4307 C121.9526,33.8957 119.9546,35.8927 117.4906,35.8927 C115.0266,35.8927 113.0286,33.8957 113.0286,31.4307 C113.0286,28.9667 115.0266,26.9687 117.4906,26.9687"
              fill="#212121"
            ></path>
            <path
              d="M118.896,30.2114 L118.069,30.3074 C118.063,30.1614 118.007,30.0224 117.911,29.9124 C117.823,29.8264 117.703,29.7804 117.58,29.7854 C117.395,29.7884 117.223,29.8834 117.123,30.0404 C116.958,30.3684 116.877,30.7324 116.887,31.0994 C117.074,30.8484 117.368,30.7024 117.68,30.7044 C118.034,30.7034 118.37,30.8594 118.596,31.1304 C118.858,31.4354 118.994,31.8294 118.978,32.2304 C118.998,32.6504 118.854,33.0624 118.577,33.3784 C118.033,33.9414 117.135,33.9564 116.573,33.4124 C116.524,33.3654 116.479,33.3154 116.438,33.2624 C116.148,32.8964 116.003,32.2974 116.003,31.4644 C116.003,30.6104 116.154,29.9944 116.456,29.6174 C116.735,29.2504 117.172,29.0404 117.632,29.0514 C117.939,29.0404 118.24,29.1464 118.472,29.3484 C118.71,29.5774 118.86,29.8834 118.896,30.2114 Z M116.96,32.1604 C116.945,32.3994 117.013,32.6374 117.152,32.8324 C117.252,32.9764 117.414,33.0644 117.589,33.0704103 C117.744,33.0714 117.891,32.9994 117.984,32.8754 C118.106,32.6874 118.162,32.4634 118.142,32.2404 C118.161,32.0054 118.101,31.7704 117.972,31.5734 C117.795,31.3394 117.461,31.2934 117.226,31.4704 C117.191,31.4974 117.158,31.5284 117.13,31.5644 C117.005,31.7364 116.945,31.9474 116.96,32.1604 L116.96,32.1604 Z"
              fill="#212121"
            ></path>
            <path
              d="M117.4906,26.9687 C119.9546,26.9687 121.9526,28.9667 121.9526,31.4307 C121.9526,33.8957 119.9546,35.8927 117.4906,35.8927 C115.0266,35.8927 113.0286,33.8957 113.0286,31.4307 C113.0286,28.9667 115.0266,26.9687 117.4906,26.9687"
              fill="#2498FE"
            ></path>
            <path
              d="M118.896,30.2114 L118.069,30.3074 C118.063,30.1614 118.007,30.0224 117.911,29.9124 C117.823,29.8264 117.703,29.7804 117.58,29.7854 C117.395,29.7884 117.223,29.8834 117.123,30.0404 C116.958,30.3684 116.877,30.7324 116.887,31.0994 C117.074,30.8484 117.368,30.7024 117.68,30.7044 C118.034,30.7034 118.37,30.8594 118.596,31.1304 C118.858,31.4354 118.994,31.8294 118.978,32.2304 C118.998,32.6504 118.854,33.0624 118.577,33.3784 C118.033,33.9414 117.135,33.9564 116.573,33.4124 C116.524,33.3654 116.479,33.3154 116.438,33.2624 C116.148,32.8964 116.003,32.2974 116.003,31.4644 C116.003,30.6104 116.154,29.9944 116.456,29.6174 C116.735,29.2504 117.172,29.0404 117.632,29.0514 C117.939,29.0404 118.24,29.1464 118.472,29.3484 C118.71,29.5774 118.86,29.8834 118.896,30.2114 Z M116.96,32.1604 C116.945,32.3994 117.013,32.6374 117.152,32.8324 C117.252,32.9764 117.414,33.0644 117.589,33.0704103 C117.744,33.0714 117.891,32.9994 117.984,32.8754 C118.106,32.6874 118.162,32.4634 118.142,32.2404 C118.161,32.0054 118.101,31.7704 117.972,31.5734 C117.795,31.3394 117.461,31.2934 117.226,31.4704 C117.191,31.4974 117.158,31.5284 117.13,31.5644 C117.005,31.7364 116.945,31.9474 116.96,32.1604 L116.96,32.1604 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M98.7121,61.4441 C101.1761,61.4441 103.1741,63.4421 103.1741,65.9061 C103.1741,68.3711 101.1761,70.3681 98.7121,70.3681 C96.2481,70.3681 94.2501,68.3711 94.2501,65.9061 C94.2501,63.4421 96.2481,61.4441 98.7121,61.4441"
              fill="#212121"
            ></path>
            <path
              d="M97.9809,65.6902 C97.7789,65.6112 97.6079,65.4712 97.4919,65.2882 C97.3899,65.1192 97.3369,64.9252 97.3389,64.7282 C97.3289,64.4032 97.4579,64.0902 97.6919,63.8662 C98.2839,63.4132 99.1059,63.4132 99.6979,63.8662 C99.9339,64.0892 100.0639,64.4032 100.0549,64.7282 C100.0629,65.1462 99.8159,65.5272 99.4309,65.6902 C99.9059,65.8672 100.2149,66.3272 100.1999,66.8342 C100.2419,67.5942 99.6589,68.2452 98.8989,68.2872 C98.8449,68.2902 98.7909,68.2892 98.7369,68.2862 C98.3659,68.2992 98.0019,68.1812 97.7089,67.9532 C97.3859,67.6892 97.2059,67.2882 97.2249,66.8722 C97.2229,66.6282 97.2849,66.3892 97.4069,66.1782 C97.5399,65.9572 97.7409,65.7862 97.9809,65.6902 Z M98.0799,66.7862 C98.0669,66.9942 98.1329,67.1992 98.2629,67.3612 C98.3769,67.4952 98.5459,67.5712 98.7209,67.5682 C98.8919,67.5722 99.0549,67.4992 99.1659,67.3692 C99.2929,67.2062 99.3559,67.0022 99.3419,66.7962 C99.3509,66.6042 99.2869,66.4162 99.1629,66.2702 C99.0489,66.1402 98.8819,66.0672 98.7089,66.0712 C98.5229,66.0592 98.3429,66.1442 98.2349,66.2972 C98.1339,66.4402 98.0789,66.6112 98.0799,66.7862 Z M98.1599,64.7912 C98.1509,64.9442 98.2029,65.0942 98.3059,65.2072 C98.4099,65.3092 98.5509,65.3632 98.6969,65.3562 C98.8429,65.3632 98.9869,65.3082 99.0919,65.2052 C99.1949,65.0922 99.2489,64.9412 99.2399,64.7882 C99.2469,64.6392 99.1939,64.4942 99.0929,64.3862 C98.9909,64.2832 98.8509,64.2282 98.7059,64.2352 C98.5579,64.2282 98.4129,64.2832 98.3079,64.3872 C98.2059,64.4962 98.1529,64.6422 98.1599,64.7912 L98.1599,64.7912 Z"
              fill="#212121"
            ></path>
            <path
              d="M98.7121,61.4441 C101.1761,61.4441 103.1741,63.4421 103.1741,65.9061 C103.1741,68.3711 101.1761,70.3681 98.7121,70.3681 C96.2481,70.3681 94.2501,68.3711 94.2501,65.9061 C94.2501,63.4421 96.2481,61.4441 98.7121,61.4441"
              fill="#2498FE"
            ></path>
            <path
              d="M97.9809,65.6902 C97.7789,65.6112 97.6079,65.4712 97.4919,65.2882 C97.3899,65.1192 97.3369,64.9252 97.3389,64.7282 C97.3289,64.4032 97.4579,64.0902 97.6919,63.8662 C98.2839,63.4132 99.1059,63.4132 99.6979,63.8662 C99.9339,64.0892 100.0639,64.4032 100.0549,64.7282 C100.0629,65.1462 99.8159,65.5272 99.4309,65.6902 C99.9059,65.8672 100.2149,66.3272 100.1999,66.8342 C100.2419,67.5942 99.6589,68.2452 98.8989,68.2872 C98.8449,68.2902 98.7909,68.2892 98.7369,68.2862 C98.3659,68.2992 98.0019,68.1812 97.7089,67.9532 C97.3859,67.6892 97.2059,67.2882 97.2249,66.8722 C97.2229,66.6282 97.2849,66.3892 97.4069,66.1782 C97.5399,65.9572 97.7409,65.7862 97.9809,65.6902 Z M98.0799,66.7862 C98.0669,66.9942 98.1329,67.1992 98.2629,67.3612 C98.3769,67.4952 98.5459,67.5712 98.7209,67.5682 C98.8919,67.5722 99.0549,67.4992 99.1659,67.3692 C99.2929,67.2062 99.3559,67.0022 99.3419,66.7962 C99.3509,66.6042 99.2869,66.4162 99.1629,66.2702 C99.0489,66.1402 98.8819,66.0672 98.7089,66.0712 C98.5229,66.0592 98.3429,66.1442 98.2349,66.2972 C98.1339,66.4402 98.0789,66.6112 98.0799,66.7862 Z M98.1599,64.7912 C98.1509,64.9442 98.2029,65.0942 98.3059,65.2072 C98.4099,65.3092 98.5509,65.3632 98.6969,65.3562 C98.8429,65.3632 98.9869,65.3082 99.0919,65.2052 C99.1949,65.0922 99.2489,64.9412 99.2399,64.7882 C99.2469,64.6392 99.1939,64.4942 99.0929,64.3862 C98.9909,64.2832 98.8509,64.2282 98.7059,64.2352 C98.5579,64.2282 98.4129,64.2832 98.3079,64.3872 C98.2059,64.4962 98.1529,64.6422 98.1599,64.7912 L98.1599,64.7912 Z"
              fill="#FFFFFF"
            ></path>
            <g transform="translate(0, 135.118)">
              <mask fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g></g>
              <path
                d="M61.1506,0.88175 L0.2636,0.88175 C0.1176,0.88175 -0.0004,0.76375 -0.0004,0.61775 C-0.0004,0.47275 0.1176,0.35475 0.2636,0.35475 L61.1506,0.35475 C61.2966,0.35475 61.4146,0.47275 61.4146,0.61775 C61.4146,0.76375 61.2966,0.88175 61.1506,0.88175"
                fill="#D8D8D8"
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
