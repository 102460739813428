import React from "react";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { EmailWYSIWYG } from "../../components";

const requiredField = [required()];

export default function DigitalContentCreate(props) {
  return (
    <Create
      {...props}
      successMessage="Digital Content added successfully"
      title="Add Digital Content"
    >
      <SimpleForm redirect="list">
        <TextInput source="name" validate={requiredField} />
        <EmailWYSIWYG
          source="instructions"
          label="Redemption Instructions - for email"
        />
      </SimpleForm>
    </Create>
  );
}
