import React, { useState, useCallback, Fragment } from "react";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from "@material-ui/core";
import {
  APIUtilClient,
  downloadPdf,
  downloadXlsx,
  generateAuthHeader,
  getUserTimezone,
} from "../../../lib";

export function StudentsReportModal({
  campaign_id,
  isProduct,
  setStudentReportModal,
}) {
  const classes = styles();
  const timezone = getUserTimezone();
  const [reportType, setReportType] = useState(isProduct ? "CSV" : "PDF");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [downloading, setDownloading] = useState(false);

  const handleClose = () => setStudentReportModal(false);

  const download = useCallback(async () => {
    setDownloading(true);
    const start_date = startDate ? startDate : "";
    const end_date = endDate ? endDate : "";
    const queryStr = `campaign_id=${campaign_id}&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`;

    switch (reportType) {
      case "PDF":
        await PDFDownload(queryStr, isProduct, campaign_id);
        break;
      case "XLSX":
        await XLSXDownload(queryStr, isProduct, campaign_id);
        break;
      default:
        await CSVDownload(queryStr);
    }

    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id, reportType, startDate, endDate]);

  return (
    <Fragment>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Students Report</DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.reportType}>
            Report type
          </DialogContentText>
          <RadioGroup
            value={reportType}
            onChange={({ target }) => setReportType(target.value)}
            row
            className={classes.radioGroup}
          >
            {isProduct && (
              <FormControlLabel value="CSV" control={<Radio />} label="CSV" />
            )}
            <FormControlLabel value="PDF" control={<Radio />} label="PDF" />
            <FormControlLabel
              value="XLSX"
              control={<Radio />}
              label="XLSX (Excel)"
            />
          </RadioGroup>

          <DialogContentText>{`Date filter (optional)`}</DialogContentText>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            InputLabelProps={{ shrink: true }}
            onChange={e => setStartDate(e.target.value)}
            className={classes.leftInput}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            InputLabelProps={{ shrink: true }}
            onChange={e => setEndDate(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" disabled={downloading} onClick={download}>
            download
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

async function CSVDownload(queryStr) {
  const res = await APIUtilClient.get(
    `/pdf/product-campaign-students-report-csv?${queryStr}`,
    { headers: generateAuthHeader() },
  );

  const { error, data, errorMessage } = res;
  if (error) {
    return alert(
      errorMessage ||
        "There was an error downloading your report, please try again later.",
    );
  }

  const { report, fileName } = data;
  jsonExport(report, (err, csv) => {
    downloadCSV(csv, fileName);
  });
}

async function XLSXDownload(queryStr, isProduct, campaign_id) {
  const type = isProduct ? "product" : "donation";
  const res = await APIUtilClient.get(
    `/xlsx_reports/${type}_campaign_students_report?${queryStr}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  downloadXlsx(res, `Students Report #${campaign_id}.XLSX`);
}

async function PDFDownload(queryStr, isProduct, campaign_id) {
  const type = isProduct ? "product" : "donation";
  const res = await APIUtilClient.get(
    `/pdf/${type}-campaign-students-report?${queryStr}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  return downloadPdf(res, `Students Report #${campaign_id}.pdf`);
}

const styles = makeStyles(_theme => ({
  reportType: {
    marginBottom: 0,
  },
  radioGroup: {
    marginBottom: 24,
  },
  leftInput: {
    marginRight: "36px",
  },
}));
