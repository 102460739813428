import { useDispatch } from "react-redux";
import { APIAuthClient } from "../../lib";
import { ReactComponent as ImpersonateIcon } from "../../assets/Impersonate.svg";
import { APIRes, paths } from "../../types";
import { setToast } from "../../state";
import { Button, makeStyles } from "@material-ui/core";

export function ImpersonateVolunteer({
  volunteerId,
  renderButton = false,
}: {
  volunteerId: number;
  renderButton?: boolean;
}) {
  const dispatch = useDispatch();
  const classes = styles();

  const impersonate = async (e: any) => {
    e.stopPropagation();
    const res = await APIAuthClient.get<any, APIRes>(
      `/volunteers/impersonate/${volunteerId}`,
    );
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    const { impersonationToken } = data;
    localStorage.setItem("volunteerImpersonationToken", impersonationToken);
    window.location.replace(paths.HOME);
  };

  if (renderButton) {
    return (
      <Button
        variant="text"
        color="primary"
        startIcon={<ImpersonateIcon />}
        onClick={impersonate}
        size="small"
      >
        IMPERSONATE
      </Button>
    );
  }
  return <ImpersonateIcon onClick={impersonate} className={classes.icon} />;
}

const styles = makeStyles(_theme => ({
  icon: {
    cursor: "pointer",
  },
}));
