import React, { useEffect } from "react";
import { FieldArray } from "react-final-form-arrays";
import { Popover, makeStyles, TextField, Button } from "@material-ui/core";
import { Field, useField } from "react-final-form";
import { EMOJI_REGEX } from "../../lib";

export function Personalization({ rowName, maxChars }) {
  const classes = styles();
  const openField = useField(`${rowName}.openPersonalization`);
  const qtyField = useField(`${rowName}.qty`);
  const personalizationField = useField(`${rowName}.personalization`);
  const charLimit = maxChars ? maxChars : 30;

  useEffect(() => {
    const current = personalizationField.input.value;
    const qty = Number(qtyField.input.value);
    const currQty = current.length;

    let update;
    switch (true) {
      case !Array.isArray(current):
        update = new Array(qty).fill("");
        break;
      case currQty < qty:
        update = [...current, ...new Array(qty - currQty).fill("")];
        break;
      case currQty > qty:
        update = current.slice(0, -(currQty - qty));
        break;

      default:
        break;
    }
    if (update) personalizationField.input.onChange(update);
    if (!Array.isArray(current) || qtyField.meta.dirty) {
      openField.input.onChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qtyField.input.value]);

  const handleChange = (e, input) => {
    const value = e.target.value;
    if (!value) {
      input.onChange(value);
    } else {
      input.onChange(value.replace(EMOJI_REGEX, "").slice(0, charLimit));
    }
  };

  return (
    <Popover
      open={Boolean(openField.input.value)}
      onClose={null}
      anchorReference={"none"}
      classes={{
        root: classes.popoverRoot,
      }}
    >
      <div className={classes.popover}>
        <div className={classes.header}>PERSONALIZATION</div>
        <FieldArray name={`${rowName}.personalization`}>
          {({ fields }) => {
            return (
              <div>
                {fields.map((row, index) => {
                  return (
                    <div key={index} className={classes.row}>
                      <div className={classes.rowNum}>{`${index + 1})`}</div>
                      <Field
                        variant="standard"
                        name={`${row}`}
                        render={({ input, meta: _rm, ...rest }) => {
                          const value = input.value;
                          return (
                            <TextField
                              {...rest}
                              {...input}
                              value={value}
                              label="text"
                              className={classes.input}
                              helperText={`${
                                value ? value.length : 0
                              } out of ${charLimit} characters`}
                              onChange={e => handleChange(e, input)}
                            />
                          );
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          }}
        </FieldArray>

        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            size="small"
            onClick={() => openField.input.onChange(false)}
          >
            done
          </Button>
        </div>
      </div>
    </Popover>
  );
}

const styles = makeStyles(theme => ({
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popover: {
    width: 500,
    maxWidth: "100%",
    minHeight: 260,
    padding: 24,
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: 22,
    textAlign: "center",
    paddingBottom: 24,
    fontWeight: 600,
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  rowNum: {
    paddingRight: 12,
  },
  input: { width: 320 },
  buttonContainer: {
    marginTop: 36,
    display: "flex",
    justifyContent: "center",
  },
  button: {
    fontWeight: 600,
    textTransform: "uppercase",
    width: "105px",
    fontSize: "18px",
    letterSpacing: "0.23px",
  },
}));
