import { useState } from "react";
import { makeStyles, Button, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { contactActions, getCampaignId } from "../../../state";
import { RootState, useAppDispatch } from "../../../types/state";

export function ConfirmContacts() {
  const classes = styles();
  const dispatch = useAppDispatch();
  const contactsConfirmed = useSelector(
    (state: RootState) => state.campaign.contacts_confirmed,
  );

  const campaign_id = useSelector(getCampaignId);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    if (!campaign_id) return;
    setSubmitting(true);
    const success = await dispatch(
      contactActions.confirmContacts(campaign_id, true),
    );
    if (!success) setSubmitting(false);
  };

  if (contactsConfirmed !== false) return <></>;
  return (
    <div className={classes.container}>
      <ErrorOutlineOutlinedIcon />
      <div className={classes.mainContent}>
        <div>
          <h3 className={classes.title}>Confirm your contacts</h3>
          <div className={classes.msg}>
            Your contacts were not yet confirmed for this campaign. Please
            confirm them to enable us to reach out on your behalf.
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            size="small"
            variant="text"
            className={classes.button}
            onClick={onSubmit}
            disabled={submitting}
          >
            CONFIRM CONTACTS
            {submitting && (
              <CircularProgress className={classes.spinner} size={24} />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#FDE9E9",
    padding: "14px 16px",
    display: "flex",
    color: "#5E0808",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "14px 16px 8px 16px",
      marginTop: 12,
      marginBottom: 0,
    },
  },
  mainContent: {
    height: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: 12,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "24px",
    paddingBottom: 4,
  },
  msg: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {},
  },
  buttonContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 4,
    },
  },
  button: {
    color: "#5E0808",
    width: 148,
    minWidth: 148,
    marginLeft: 16,
  },
  spinner: {
    position: "absolute",
    color: "#5E0808",
  },
}));
