import { makeStyles } from "@material-ui/core";

export const styles = makeStyles(
  theme => ({
    container: {
      marginTop: "16px",
      paddingLeft: "19px",
      boxSizing: "border-box",
      [theme.breakpoints.down("xs")]: {
        marginTop: 0,
        padding: 0,
        width: "100vw",
        height: "calc(100vh - 56px)",
        backgroundColor: "#ffffff",
      },
    },
    paper: {
      boxSizing: "border-box",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        boxShadow: "none",
      },
    },
    contentContainer: {
      padding: "16px",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    tabContainer: {
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    form: {
      //width: "200px",
    },
    saveIcon: {
      marginRight: "10px",
    },
    arrayInputContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
    },
    arrayInput: {
      marginRight: "10px",
      width: "250px",
    },
    addButton: {
      marginBottom: "18px",
    },
    button: {
      marginTop: "20px",
      backgroundColor: theme.palette.primary.main,
    },
    logoWrapper: {
      paddingTop: "20px",
    },
    logoContainer: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    currentLogo: {
      marginRight: "24px",
    },
    label: {
      color: "rgba(0, 0, 0, 0.54)",
      fontWeight: 400,
      fontSize: "12.5px",
      paddingTop: "8px",
    },
    image: {
      maxWidth: "250px",
    },
  }),
  {
    classNamePrefix: "profile",
  },
);
