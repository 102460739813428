import React from "react";
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TextField,
  TableFooter,
  Button,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

export function ProductGroups({
  groups = {},
  sources = {},
  orderedProducts = {},
  calculateProjections,
  projectionPercent,
}) {
  const classes = styles();

  const handleCustomProjection = (e, groupId) => {
    const value = e.target.value;
    groups[groupId].customProjectedItems = Number(value);
    calculateProjections();
  };

  const downloadGroups = () => {
    const data = Object.values(groups).map(group => {
      const {
        campaignCount,
        completedCampaignCount,
        completedItemCount,
        completedItemsPerStudent,
        completedStudentCount,
        group_name,
        itemCount,
        startedStudentCount,
        studentCount,
        ttlItemsPerStartedStudent,
        ttlItemsPerStartedStudentMultipliedByTtlStudents,
        calculatedProjectedItems,
        customProjectedItems,
      } = group;
      return {
        "Product Group Name": group_name,
        "Projection Percent": `${projectionPercent ? projectionPercent : 100}%`,
        "Total Campaigns": campaignCount,
        "Completed Campaigns": `${completedCampaignCount}`,
        "Total Students": studentCount,
        "Started Students": startedStudentCount,
        "Completed Students": completedStudentCount,
        "Total Items": itemCount,
        "Ttl Items / Started Students": `${ttlItemsPerStartedStudent}`,
        "(Ttl Items / Started Students) * Ttl Students": ttlItemsPerStartedStudentMultipliedByTtlStudents,
        "Completed Items": completedItemCount,
        "Completed Items / Completed Students": `${completedItemsPerStudent}`,
        "Calculated Projected Items": `${calculatedProjectedItems}`,
        "Custom Projected Items": customProjectedItems || "",
      };
    });
    jsonExport(data, (_err, csv) => {
      downloadCSV(csv, `Projections - Product Groups ${projectionPercent}%`);
    });
  };

  const downloadGroupProducts = (groupId, groupName) => {
    const rows = [];
    Object.keys(sources).forEach(sourceId => {
      const sourceName = sources[sourceId].sourceName;
      orderedProducts[sourceId].forEach(productId => {
        const { groups, product_name, sku } = sources[sourceId][productId];
        const group = groups[groupId];
        if (!group) return;
        const { sold, completedSold, completedPercent, projectedItems } = group;
        rows.push({
          Source: sourceName,
          "Product ID": productId,
          Sku: sku,
          Name: product_name,
          "Total Sold": sold,
          "Completed Sold": completedSold,
          "Completed Group Percent": completedPercent,
          "Group Projected Items": projectedItems,
        });
      });
    });

    jsonExport(rows, (_err, csv) => {
      downloadCSV(
        csv,
        `Projections - ${groupName} Group Products ${projectionPercent}%`,
      );
    });
  };

  return (
    <TableContainer
      component={Paper}
      elevation={6}
      className={classes.tableContainer}
    >
      <Table size="small" stickyHeader className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Product Group Name</TableCell>
            <TableCell>Total Campaigns</TableCell>
            <TableCell>Completed Campaigns</TableCell>
            <TableCell>Total Students</TableCell>
            <TableCell>Started Students</TableCell>
            <TableCell>Completed Students</TableCell>
            <TableCell>Total Items</TableCell>
            <TableCell>Ttl Items / Started Students</TableCell>
            <TableCell>(Ttl Items / Started Students) * Ttl Students</TableCell>
            <TableCell>Completed Items</TableCell>
            <TableCell>Completed Items / Completed Students</TableCell>
            <TableCell>Calculated Projected Items</TableCell>
            <TableCell>Custom Projected Items</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {Object.keys(groups).map(id => {
            const {
              group_name,
              campaignCount,
              completedCampaignCount,
              studentCount,
              startedStudentCount,
              completedStudentCount,
              itemCount,
              ttlItemsPerStartedStudent,
              ttlItemsPerStartedStudentMultipliedByTtlStudents,
              completedItemCount,
              completedItemsPerStudent,
              calculatedProjectedItems,
            } = groups[id];
            return (
              <TableRow key={id}>
                <TableCell>{group_name}</TableCell>
                <TableCell>{campaignCount}</TableCell>
                <TableCell>{completedCampaignCount}</TableCell>
                <TableCell>{studentCount}</TableCell>
                <TableCell>{startedStudentCount}</TableCell>
                <TableCell>{completedStudentCount}</TableCell>
                <TableCell>{itemCount}</TableCell>
                <TableCell>{ttlItemsPerStartedStudent}</TableCell>
                <TableCell>
                  {ttlItemsPerStartedStudentMultipliedByTtlStudents}
                </TableCell>
                <TableCell>{completedItemCount}</TableCell>
                <TableCell>{completedItemsPerStudent}</TableCell>
                <TableCell>{calculatedProjectedItems}</TableCell>
                <TableCell>
                  <TextField
                    variant="standard"
                    type="number"
                    onChange={e => handleCustomProjection(e, id)}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={() => downloadGroupProducts(id, group_name)}
                    startIcon={<DownloadIcon />}
                  >
                    products
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell colSpan={12} align="left" className={classes.title}>
              Product Group List
            </TableCell>
            <TableCell align="right" colSpan={2}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={downloadGroups}
                startIcon={<DownloadIcon />}
                className={classes.exportGroups}
              >
                export groups
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

const styles = makeStyles(
  _theme => ({
    tableContainer: {
      marginBottom: 16,
      "& .MuiTableCell-sizeSmall": {
        padding: "6px 10px",
      },
      // padding: "10px 6px 0 6px",
      maxHeight: "calc(100vh - 120px)",
    },
    table: {
      overflowY: "auto",
      overflowX: "auto",
    },
    title: {
      fontSize: 16,
    },
    exportGroups: {
      whiteSpace: "nowrap",
    },
  }),
  { classNamePrefix: "projectionsPG" },
);
