import { paths } from "./routes";

export enum ToastTypes {
  info = "info",
  warn = "warn",
  success = "success",
}

export type ConfirmationDialogDataProps = null | {
  title: string;
  subtitle?: string;
  actionLabel: string;
  redButton?: boolean;
  actionFunc: (
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
    onClose: () => void,
  ) => void;
};

export const WHITE_BACKGROUND_PATHS = {
  [paths.VOLUNTEER_GUIDED_SETUP]: true,
  [paths.VOLUNTEER_EZ_SHARE]: true,
  [paths.ADD_ASSOC_VOLUNTEER_ACCOUNT]: true,
  [paths.PROFILE]: true,
  [paths.LEADERBOARD]: true,
  [paths.ORG_CAMPAIGN_SETTINGS]: true,
};
