import React, { Fragment } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { useForm, Field } from "react-final-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TextField } from "final-form-material-ui";
import classNames from "classnames";
import { Input } from "./OrgProfitInput";
import { styles } from "./OrgProfit.styles";
const CARRIERS = {
  USPS: "USPS",
  UPS: "UPS",
  fedex: "Fedex",
  other: "Other",
};

export function PaymentDetails({ opData }) {
  const classes = styles();
  const form = useForm();
  const {
    pay_to,
    mail_to_name,
    mail_to_att,
    mail_to_address,
    mail_to_city_state_zip,
    tracking_carrier,
    tracking_number,
  } = form.getState().values;
  const {
    profit_paid_via_ach,
    ach_name_on_account,
    ach_routing_number,
    ach_account_number,
    ach_account_type,
  } = opData;
  const trackingLink = getTrackingLink(tracking_carrier, tracking_number);

  return (
    <div className={classes.paymentDetailsContainer}>
      <TableContainer
        component={Paper}
        className={classNames(classes.table, classes.leftPmtTbl)}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>
                <div>PAYMENT DETAILS</div>
                <div className={classes.paymentDetailsWarning}>
                  <div>
                    Editing ANY Org info here will NOT update the Org's account
                    for future Campaigns.
                  </div>
                  <div>
                    Permanent changes should also be edited in ORGANIZATIONS.
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Pay To:</TableCell>
              <TableCell>
                <Input name="pay_to" wideInput />
              </TableCell>
              <TableCell>
                <CopyToClipboard text={pay_to}>
                  <IconButton size="small">
                    <FileCopyOutlinedIcon color="primary" />
                  </IconButton>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mail To:</TableCell>
              <TableCell>
                <Input name="mail_to_name" wideInput />
              </TableCell>
              <TableCell rowSpan={4}>
                <CopyToClipboard
                  text={`${mail_to_name}\n${mail_to_att}\n${mail_to_address}\n${mail_to_city_state_zip}`}
                >
                  <IconButton size="small">
                    <FileCopyOutlinedIcon color="primary" />
                  </IconButton>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ATT:</TableCell>
              <TableCell>
                <Input name="mail_to_att" wideInput />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address:</TableCell>
              <TableCell>
                <Input name="mail_to_address" wideInput />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>City, State, Zip:</TableCell>
              <TableCell>
                <Input name="mail_to_city_state_zip" wideInput />
              </TableCell>
            </TableRow>

            {profit_paid_via_ach && (
              <Fragment>
                <TableRow>
                  <TableCell>ACH Account Type:</TableCell>
                  <TableCell>{ach_account_type}</TableCell>
                  <TableCell>
                    <CopyToClipboard text={ach_account_type}>
                      <IconButton size="small">
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ACH Account Name:</TableCell>
                  <TableCell>{ach_name_on_account}</TableCell>
                  <TableCell>
                    <CopyToClipboard text={ach_name_on_account}>
                      <IconButton size="small">
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ACH Routing Num:</TableCell>
                  <TableCell>{ach_routing_number}</TableCell>
                  <TableCell>
                    <CopyToClipboard text={ach_routing_number}>
                      <IconButton size="small">
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ACH Account Num:</TableCell>
                  <TableCell>{ach_account_number}</TableCell>
                  <TableCell>
                    <CopyToClipboard text={ach_account_number}>
                      <IconButton size="small">
                        <FileCopyOutlinedIcon color="primary" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow>
              </Fragment>
            )}

            <TableRow>
              <TableCell>Memo:</TableCell>
              <TableCell colSpan={2}>
                <Input name="payment_memo" wideInput />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Notes To Org:</TableCell>
              <TableCell colSpan={2}>
                <Input
                  name="notes_to_org"
                  wideInput
                  multiline={true}
                  minRows="2"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Internal Notes:</TableCell>
              <TableCell colSpan={2}>
                <Input name="notes" wideInput multiline={true} minRows="2" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* TRACKING TABLE */}
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell colSpan={3}>TRACKING</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Carrier:</TableCell>
              <TableCell>
                <Field
                  select
                  name="tracking_carrier"
                  component={TextField}
                  variant="standard"
                  parse={value => value}
                  SelectProps={{ displayEmpty: true }}
                  fullWidth
                >
                  <MenuItem value="">
                    <span className={classes.placeholder}>
                      Select a Carrier
                    </span>
                  </MenuItem>
                  {Object.keys(CARRIERS).map(k => (
                    <MenuItem key={k} value={CARRIERS[k]}>
                      {CARRIERS[k]}
                    </MenuItem>
                  ))}
                </Field>
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Tracking Number:</TableCell>
              <TableCell>
                <Input name="tracking_number" wideInput />
              </TableCell>
              <TableCell>
                <CopyToClipboard text={tracking_number}>
                  <IconButton size="small">
                    <FileCopyOutlinedIcon color="primary" />
                  </IconButton>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tracking Link:</TableCell>
              <TableCell>
                {trackingLink && (
                  <a
                    className={classes.trackingLink}
                    href={trackingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {trackingLink}
                  </a>
                )}
              </TableCell>
              <TableCell>
                <CopyToClipboard text={trackingLink}>
                  <IconButton size="small">
                    <FileCopyOutlinedIcon color="primary" />
                  </IconButton>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function getTrackingLink(carrier, trackingNumber) {
  if (!carrier || !trackingNumber) return null;
  let link = null;
  switch (carrier) {
    case CARRIERS.USPS:
      link = `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${trackingNumber}`;
      break;
    case CARRIERS.UPS:
      link = `http://wwwapps.ups.com/WebTracking/processRequest?HTMLVersion=5.0&Requester=NES&AgreeToTermsAndConditions=yes&loc=en_US&tracknum=${trackingNumber}`;
      break;
    case CARRIERS.fedex:
      link = `https://www.fedex.com/fedextrack/?action=track&trackingnumber=${trackingNumber}`;
      break;
  }
  return link;
}
