import { makeStyles, TextField, MenuItem } from "@material-ui/core";
import debounce from "lodash.debounce";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import omit from "lodash.omit";
import { useState, useCallback, useRef, useEffect } from "react";
import { ListProps, SearchFields, SearchField } from "../../types";
import classNames from "classnames";

type Props = {
  listProps: ListProps<any>;
  fields: SearchFields;
};
export function MultiSearch({ listProps, fields }: Props) {
  const { setParams } = listProps;
  const classes = styles();
  const [activeField, setActiveField] = useState<SearchField>(fields[0]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const fieldRef = useRef(fields[0]);
  const valueRef = useRef("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    setActiveField(fields[0]);
  }, [fields]);

  const updateFilters = () => {
    setParams((prev: any) => {
      const rest = omit(
        prev,
        fields.map(({ field }) => field),
      );
      if (!valueRef.current) {
        return { ...rest, page: 0 };
      } else {
        return { ...rest, page: 0, [fieldRef.current.field]: valueRef.current };
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(updateFilters, 1000), []);

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    valueRef.current = target.value;
    debouncedHandleSearch();
  };

  const onClear = () => {
    setSearch("");
    valueRef.current = "";
    updateFilters();
  };

  const onFieldChange = ({ target }) => {
    const index = target.value;
    setActiveIndex(index);
    setActiveField(fields[index]);
    fieldRef.current = fields[index];
    if (search) {
      setSearch("");
      valueRef.current = "";
      updateFilters();
    }
  };

  return (
    <div className={classes.wrapper}>
      <TextField
        placeholder={activeField.placeholder}
        value={search}
        size="small"
        onChange={onSearchChange}
        className={classNames(classes.input, classes.search)}
        variant="outlined"
        type={activeField.isNumber ? "number" : "text"}
        InputProps={{
          endAdornment: Boolean(search) ? (
            <CloseIcon
              fontSize="small"
              className={classNames(classes.searchIcon, classes.pointer)}
              onClick={onClear}
            />
          ) : (
            <SearchIcon fontSize="small" className={classes.searchIcon} />
          ),
          classes: { notchedOutline: classes.notchedOutline },
        }}
      />
      <TextField
        value={activeIndex}
        select
        size="small"
        onChange={onFieldChange}
        className={classNames(classes.input, classes.select)}
        variant="outlined"
        InputProps={{
          classes: { notchedOutline: classes.notchedOutline },
        }}
      >
        {fields.map((f, index) => {
          const { field, label } = f;
          return (
            <MenuItem key={field} value={index}>
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
}

const styles = makeStyles(theme => ({
  notchedOutline: { border: "none" },
  wrapper: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    backgroundColor: "#EAEBF3",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  search: {
    width: 256,
    borderRadius: "4px 0 0 4px !important",
    borderRight: `1px solid ${theme.palette.text.secondary2}`,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 126px)",
    },
  },
  select: {
    width: 126,
    borderRadius: "0 4px 4px 0 !important",
    [theme.breakpoints.down("sm")]: {
      "& .MuiSelect-root": {
        fontSize: 14,
      },
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary2,
  },
  pointer: {
    cursor: "pointer",
  },
}));
