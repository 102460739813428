export function ClassSVG() {
  return (
    <svg width="139px" height="136px" viewBox="0 0 139 136">
      <title>Class</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-118, -64)">
          <g transform="translate(118, 64)">
            <path
              d="M20.192,46.3741332 C31.344,46.3741332 40.384,55.4141332 40.384,66.5661332 C40.384,77.7181332 31.344,86.7581332 20.192,86.7581332 C9.04,86.7581332 0,77.7181332 0,66.5661332 C0,55.4141332 9.04,46.3741332 20.192,46.3741332"
              fill="#F3F3F3"
            ></path>
            <path
              d="M20.3724,51.3041332 C24.8194,51.3041332 28.4244,54.9091332 28.4244,59.3561332 C28.4244,63.8031332 24.8194,67.4081332 20.3724,67.4081332 C15.9254,67.4081332 12.3204,63.8031332 12.3204,59.3561332 C12.3204,54.9091332 15.9254,51.3041332 20.3724,51.3041332"
              fill="#212121"
            ></path>
            <path
              d="M40.4935,20.2280332 C40.4735,31.3800332 31.4175,40.4040332 20.2655,40.3840664 C9.1135,40.3650332 0.0895,31.3080332 0.109466819,20.1560332 C0.1295,9.00503318 9.1855,-0.0199668231 20.3375,0 C31.4605,-0.00896682315 40.4845,9.00003318 40.4935,20.1230332 C40.4935,20.1580332 40.4935,20.1930332 40.4935,20.2280332"
              fill="#F3F3F3"
            ></path>
            <path
              d="M68.1074,46.3741332 C79.2594,46.3741332 88.2994,55.4141332 88.2994,66.5661332 C88.2994,77.7181332 79.2594,86.7581332 68.1074,86.7581332 C56.9554,86.7581332 47.9154,77.7181332 47.9154,66.5661332 C47.9154,55.4141332 56.9554,46.3741332 68.1074,46.3741332"
              fill="#F3F3F3"
            ></path>
            <path
              d="M68.0486,0.000133176852 C79.2006,0.000133176852 88.2406,9.04013318 88.2406,20.1921332 C88.2406,31.3441332 79.2006,40.3841332 68.0486,40.3841332 C56.8966,40.3841332 47.8566,31.3441332 47.8566,20.1921332 C47.8566,9.04013318 56.8966,0.000133176852 68.0486,0.000133176852"
              fill="#F3F3F3"
            ></path>
            <path
              d="M117.2616,0.000133176852 C128.4136,0.000133176852 137.4536,9.04013318 137.4536,20.1921332 C137.4536,31.3441332 128.4136,40.3841332 117.2616,40.3841332 C106.1096,40.3841332 97.0696,31.3441332 97.0696,20.1921332 C97.0696,9.04013318 106.1096,0.000133176852 117.2616,0.000133176852"
              fill="#F3F3F3"
            ></path>
            <path
              d="M117.9914,46.3741332 C129.1434,46.3741332 138.1834,55.4141332 138.1834,66.5661332 C138.1834,77.7181332 129.1434,86.7581332 117.9914,86.7581332 C106.8394,86.7581332 97.7994,77.7181332 97.7994,66.5661332 C97.7994,55.4141332 106.8394,46.3741332 117.9914,46.3741332"
              fill="#F3F3F3"
            ></path>
            <path
              d="M40.4936,115.807633 C40.4936,126.959633 31.4536,135.999633 20.3016,135.999633 C9.1496,135.999633 0.1096,126.959633 0.1096,115.807633 C0.1096,104.655633 9.1496,95.615425 20.3016,95.615425 C31.4036,95.5656332 40.4446,104.526633 40.4936,115.628633 C40.4936,115.688633 40.4936,115.747633 40.4936,115.807633"
              fill="#F3F3F3"
            ></path>
            <path
              d="M69.3462,95.6156332 C80.4982,95.6156332 89.5382,104.655633 89.5382,115.807633 C89.5382,126.959633 80.4982,135.999633 69.3462,135.999633 C58.1942,135.999633 49.1542,126.959633 49.1542,115.807633 C49.1542,104.655633 58.1942,95.6156332 69.3462,95.6156332"
              fill="#F3F3F3"
            ></path>
            <path
              d="M10.9425,13.6237332 C11.7775,10.9627332 13.6765,8.52273318 16.3015,7.62273318 C18.9275,6.72373318 22.7735,8.09373318 24.7475,10.0607332 C28.3585,13.6597332 29.2395,26.0237332 26.7585,29.9307332 C26.2655,29.9047332 24.5625,29.8847332 24.0625,29.8667332 L23.3565,27.5117332 L23.3565,29.8427332 C22.5605,29.8187332 21.7585,29.8017332 20.9505,29.7917332 C15.3195,29.7227332 10.7515,25.2107332 10.6135,19.5807332 C10.5485,16.9887332 10.6135,14.6727332 10.9425,13.6237332"
              fill="#212121"
            ></path>
            <path
              d="M20.0019,10.9334332 C23.1799,10.9334332 25.7569,13.5094332 25.7569,16.6884332 C25.7569,19.8664332 23.1799,22.4424332 20.0019,22.4424332 C16.8239,22.4424332 14.2469,19.8664332 14.2469,16.6884332 C14.2469,13.5094332 16.8239,10.9334332 20.0019,10.9334332"
              fill="#9E616A"
            ></path>
            <path
              d="M15.0355,11.6340332 L21.6615,8.31603318 C24.4205,9.55903318 26.2875,12.2010332 26.5395,15.2160332 L26.7045,17.1940332 L23.9295,16.4880332 L23.6255,13.9590332 L23.1545,16.2910332 L21.8745,15.9650332 L21.8865,12.0380332 L20.6045,15.9620332 L16.0315,14.9120332 L15.0355,11.6340332 Z"
              fill="#212121"
            ></path>
            <path
              d="M62.5625,53.2879332 C64.1365,53.2879332 65.4135,54.5639332 65.4135,56.1389332 C65.4135,57.7129332 64.1365,58.9899332 62.5625,58.9899332 C60.9885,58.9899332 59.7115,57.7129332 59.7115,56.1389332 C59.7115,54.5639332 60.9885,53.2879332 62.5625,53.2879332"
              fill="#212121"
            ></path>
            <path
              d="M67.1238,55.3785332 C71.1128,55.3785332 74.3458,58.6115332 74.3458,62.6005332 C74.3458,66.5895332 71.1128,69.8225332 67.1238,69.8225332 C63.1348,69.8225332 59.9018,66.5895332 59.9018,62.6005332 C59.9018,58.6115332 63.1348,55.3785332 67.1238,55.3785332"
              fill="#212121"
            ></path>
            <path
              d="M67.7214,58.0833332 C70.9624,58.0833332 73.5904,60.7113332 73.5904,63.9523332 C73.5904,67.1933332 70.9624,69.8203332 67.7214,69.8203332 C64.4804,69.8203332 61.8524,67.1933332 61.8524,63.9523332 C61.8524,60.7113332 64.4804,58.0833332 67.7214,58.0833332"
              fill="#9E616A"
            ></path>
            <path
              d="M71.7068,57.9697332 C68.5368,56.0367332 64.3998,57.0407332 62.4678,60.2107332 C61.8028,61.3017332 61.4618,62.5587332 61.4868,63.8367332 C64.4768,64.5877332 67.6188,65.1617332 70.8078,64.0157332 L71.5608,62.1727332 L72.0048,64.0177332 C72.9758,64.4147332 73.9498,64.7507332 74.9228,64.0137332 C75.0038,61.5697332 73.7788,59.2677332 71.7068,57.9697332"
              fill="#212121"
            ></path>
            <path
              d="M59.9083,48.3999332 C57.9373,47.4959332 55.4633,47.9199332 53.8223,49.3379332 C52.1803,50.7549332 51.4003,53.0619332 51.7173,55.2069332 C52.0403,57.3959332 53.3713,59.2799332 54.5973,61.1219332 C55.8243,62.9629332 57.0223,64.9799332 57.0203,67.1929332 C57.0183,70.5109332 54.3323,73.2009332 51.0143,73.2069332 C52.8843,74.0129332 54.8363,74.8329332 56.8703,74.7379332 C58.9043,74.6429332 61.0363,73.3349332 61.3683,71.3259332 C61.6073,69.8789332 60.8963,68.4669332 60.3043,67.1249332 C59.3393,64.9379332 58.6423,62.6419332 58.2273,60.2879332 C58.0293,59.1649332 57.9013,57.9829332 58.2673,56.9029332 C58.8663,55.2589332 60.4693,54.1999332 62.2163,54.2939332 L62.8883,53.6899332 C63.1873,51.5419332 61.8793,49.3039332 59.9083,48.3999332"
              fill="#212121"
            ></path>
            <path
              d="M109.0209,12.2941332 C108.7439,13.7831332 108.2889,15.2331332 107.6669,16.6141332 C104.1049,24.1491332 114.2489,21.6931332 117.8739,21.6931332 C121.8379,21.6931332 125.6299,23.7781332 125.0509,14.5171332 C124.8029,10.5611332 121.8379,7.34113318 117.8739,7.34113318 C114.4889,7.34113318 109.7289,8.84513318 109.0209,12.2941332"
              fill="#212121"
            ></path>
            <path
              d="M117.2802,10.0285332 C120.5012,10.0285332 123.1122,12.6395332 123.1122,15.8605332 C123.1122,19.0805332 120.5012,21.6915332 117.2802,21.6915332 C114.0592,21.6915332 111.4482,19.0805332 111.4482,15.8605332 C111.4482,12.6395332 114.0592,10.0285332 117.2802,10.0285332"
              fill="#FFD1D2"
            ></path>
            <path
              d="M113.3199,9.91563318 C116.4699,7.99563318 120.5799,8.99163318 122.5009,12.1416332 C123.1619,13.2256332 123.4999,14.4756332 123.4759,15.7456332 C120.5039,16.4916332 117.3829,17.0626332 114.2139,15.9236332 L113.4649,14.0926332 L113.0239,15.9256332 C112.0589,16.3196332 111.0919,16.6536332 110.1239,15.9216332 C110.0439,13.4936332 111.2609,11.2056332 113.3199,9.91563318"
              fill="#212121"
            ></path>
            <path
              d="M20.3757,54.0795332 C24.3487,54.0795332 27.5687,57.2995332 27.5687,61.2725332 C27.5687,65.2455332 24.3487,68.4655332 20.3757,68.4655332 C16.4027,68.4655332 13.1827,65.2455332 13.1827,61.2725332 C13.1827,57.2995332 16.4027,54.0795332 20.3757,54.0795332"
              fill="#FFD1D2"
            ></path>
            <path
              d="M68.9017,105.650633 C72.2847,105.650633 75.0277,108.393633 75.0277,111.776633 C75.0277,115.159633 72.2847,117.902633 68.9017,117.902633 C65.5187,117.902633 62.7757,115.159633 62.7757,111.776633 C62.7757,108.393633 65.5187,105.650633 68.9017,105.650633"
              fill="#9E616A"
            ></path>
            <path
              d="M68.8716,9.08333318 C72.5076,9.08333318 75.4556,12.0313332 75.4556,15.6673332 C75.4556,19.3043332 72.5076,22.2523332 68.8716,22.2523332 C65.2356,22.2523332 62.2876,19.3043332 62.2876,15.6673332 C62.2876,12.0313332 65.2356,9.08333318 68.8716,9.08333318"
              fill="#FFD1D2"
            ></path>
            <path
              d="M74.6688,11.3179332 C74.6688,11.3179332 76.3828,8.04093318 72.6118,7.74293318 C72.6118,7.74293318 69.3958,4.82593318 66.1188,7.20993318 C66.1188,7.20993318 64.3318,7.20993318 63.3538,9.23193318 C63.3538,9.23193318 61.9478,8.69893318 61.6388,10.1259332 C61.6388,10.1259332 60.6098,13.1059332 61.6388,15.7869332 C62.6678,18.4679332 63.0098,18.7659332 63.0098,18.7659332 C63.0098,18.7659332 62.2878,15.4389332 66.4028,15.1409332 C70.5168,14.8429332 74.2918,12.2649332 74.6348,15.5429332 C74.9778,18.8199332 75.3548,17.3389332 75.3548,17.3389332 C75.3548,17.3389332 78.6118,12.6589332 74.6688,11.3179332"
              fill="#212121"
            ></path>
            <path
              d="M117.4341,56.1765332 C120.9941,56.1765332 123.8801,59.0625332 123.8801,62.6225332 C123.8801,66.1815332 120.9941,69.0675332 117.4341,69.0675332 C113.8741,69.0675332 110.9881,66.1815332 110.9881,62.6225332 C110.9881,59.0625332 113.8741,56.1765332 117.4341,56.1765332"
              fill="#FFD1D2"
            ></path>
            <path
              d="M28.5596,110.976233 L28.5596,110.976233 L28.4596,111.156233 C29.3486,108.964233 28.5406,106.264233 26.9536,104.611233 C25.3676,102.959233 23.1626,102.203233 20.9906,101.903233 C19.3786,101.681233 17.7056,101.687233 16.1726,102.278233 C13.9526,103.133233 12.2566,105.113233 10.8406,107.172233 C10.1936,108.114233 9.5566,109.187233 9.6206,110.365233 C9.6806,111.457233 10.3296,112.384233 10.8606,113.311233 C11.3916,114.238233 11.8346,115.388233 11.4536,116.400233 C11.2266,117.002233 10.7466,117.437233 10.3566,117.930233 C9.9676,118.423233 9.6476,119.089233 9.8356,119.708233 C10.0056,120.190233 10.4156,120.547233 10.9166,120.648233 C11.3876,120.757233 11.8756,120.686233 12.3526,120.614233 L24.7306,118.744233 C27.6116,118.308233 29.5936,115.620233 29.1586,112.739233 C29.0646,112.121233 28.8626,111.524233 28.5596,110.976233"
              fill="#212121"
            ></path>
            <path
              d="M13.379,111.636233 C13.914,108.337233 17.023,106.096233 20.322,106.632233 C23.621,107.167233 25.862,110.276233 25.326,113.575233 C24.791,116.874233 21.682,119.114233 18.383,118.579233 C15.084,118.044233 12.844,114.935233 13.379,111.636233"
              fill="#FFD1D2"
            ></path>
            <path
              d="M35.4633,129.139733 C28.1843,137.421733 15.6073,138.330733 7.2133,131.181733 L8.9453,126.348733 C9.7423,124.599733 11.4403,123.433733 13.3593,123.317733 L14.4613,123.249733 L17.0883,120.983733 C17.0883,120.983733 24.1673,120.730733 24.0623,120.609733 L28.3503,123.188733 L28.3473,123.171733 L30.0323,123.274733 C31.9513,123.390733 33.6493,124.556733 34.4473,126.305733 L35.4633,129.139733 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M84.2349,129.139733 C76.9559,137.421733 64.3789,138.330733 55.9849,131.181733 L57.7169,126.348733 C58.5139,124.599733 60.2119,123.433733 62.1309,123.317733 L63.2329,123.249733 L65.8599,120.983733 C65.8599,120.983733 72.9389,120.730733 72.8339,120.609733 L77.1219,123.188733 L77.1189,123.171733 L78.8039,123.274733 C80.7229,123.390733 82.4209,124.556733 83.2189,126.305733 L84.2349,129.139733 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M25.0929,106.988833 C24.9369,106.162833 24.0939,105.784833 23.3719,105.600833 C21.2769,105.066833 19.0979,104.946833 16.9569,105.248833 C15.3969,105.471833 13.7989,105.961833 12.6669,107.179833 C11.7539,108.205833 11.2369,109.523833 11.2079,110.896833 C11.1619,111.716833 11.3449,112.534833 11.7379,113.256833 C12.1449,113.946833 12.8399,114.446833 13.5569,114.429833 L13.6289,114.681833 C16.6679,114.258833 19.4899,112.367833 21.2679,109.565833 C20.9339,110.756833 20.2699,111.829833 19.3529,112.658833 C21.4039,111.838833 23.1869,110.463833 24.5029,108.689833 C24.8629,108.212833 25.2119,107.617833 25.0929,106.988833"
              fill="#212121"
            ></path>
            <path
              d="M35.2853,79.8341332 C28.0063,88.1161332 15.4293,89.0251332 7.0353,81.8761332 L8.7673,77.0431332 C9.5643,75.2941332 11.2623,74.1281332 13.1813,74.0121332 L14.2833,73.9441332 L16.9103,71.6781332 C16.9103,71.6781332 23.9893,71.4251332 23.8843,71.3041332 L28.1723,73.8831332 L28.1693,73.8661332 L29.8543,73.9691332 C31.7733,74.0851332 33.4713,75.2511332 34.2693,77.0001332 L35.2853,79.8341332 Z"
              fill="#FD7B6F"
            ></path>
            <path
              d="M83.3004,80.0763332 C76.0214,88.3583332 63.4444,89.2673332 55.0504,82.1183332 L56.7824,77.2853332 C57.5794,75.5363332 59.2774,74.3703332 61.1964,74.2543332 L62.2984,74.1863332 L64.9254,71.9203332 C64.9254,71.9203332 72.0044,71.6673332 71.8994,71.5463332 L76.1874,74.1253332 L76.1844,74.1083332 L77.8694,74.2113332 C79.7884,74.3273332 81.4864,75.4933332 82.2844,77.2423332 L83.3004,80.0763332 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M133.14,80.0763332 C125.861,88.3583332 113.284,89.2673332 104.89,82.1183332 L106.622,77.2853332 C107.419,75.5363332 109.117,74.3703332 111.036,74.2543332 L112.138,74.1863332 L114.765,71.9203332 C114.765,71.9203332 121.844,71.6673332 121.739,71.5463332 L126.027,74.1253332 L126.024,74.1083332 L127.709,74.2113332 C129.628,74.3273332 131.326,75.4933332 132.124,77.2423332 L133.14,80.0763332 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M35.2853,33.5545332 C28.0063,41.8365332 15.4293,42.7455332 7.0353,35.5965332 L8.7673,30.7635332 C9.5643,29.0145332 11.2623,27.8485332 13.1813,27.7325332 L14.2833,27.6645332 L16.9103,25.3985332 C16.9103,25.3985332 23.9893,25.1455332 23.8843,25.0245332 L28.1723,27.6035332 L28.1693,27.5865332 L29.8543,27.6895332 C31.7733,27.8055332 33.4713,28.9715332 34.2693,30.7205332 L35.2853,33.5545332 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M83.1224,33.6187332 C75.8434,41.9007332 63.2664,42.8097332 54.8724,35.6607332 L56.6044,30.8277332 C57.4014,29.0787332 59.0994,27.9127332 61.0184,27.7967332 L62.1204,27.7287332 L64.7474,25.4627332 C64.7474,25.4627332 71.8264,25.2097332 71.7214,25.0887332 L76.0094,27.6677332 L76.0064,27.6507332 L77.6914,27.7537332 C79.6104,27.8697332 81.3084,29.0357332 82.1064,30.7847332 L83.1224,33.6187332 Z"
              fill="#2498FE"
            ></path>
            <path
              d="M132.962,33.6187332 C125.683,41.9007332 113.106,42.8097332 104.712,35.6607332 L106.444,30.8277332 C107.241,29.0787332 108.939,27.9127332 110.858,27.7967332 L111.96,27.7287332 L114.587,25.4627332 C114.587,25.4627332 121.666,25.2097332 121.561,25.0887332 L125.849,27.6677332 L125.846,27.6507332 L127.531,27.7537332 C129.45,27.8697332 131.148,29.0357332 131.946,30.7847332 L132.962,33.6187332 Z"
              fill="#FD7B6F"
            ></path>
            <path
              d="M124.232,58.6246332 C123.687,56.6756332 122.45,54.9916332 120.752,53.8896332 C119.399,53.0246332 117.71,52.5826332 116.177,53.0396332 C114.644,53.4976332 113.375,55.0026332 113.475,56.6276332 C112.922,56.4396332 112.293,56.6896332 111.887,57.1176332 C111.482,57.5456332 111.265,58.1216332 111.109,58.6946332 C110.617,60.5176332 110.649,62.4416332 111.203,64.2466332 L111.172,64.5056332 C111.92,64.3816332 112.487,63.7406332 112.84,63.0566332 C113.193,62.3726332 113.396,61.6146332 113.778,60.9476332 C114.161,60.2806332 114.796,59.6856332 115.553,59.6646332 L115.486,60.8226332 C116.138,60.4866332 116.79,60.1506332 117.441,59.8146332 C117.286,60.1856332 117.131,60.5566332 116.976,60.9276332 C117.545,60.6896332 118.115,60.4516332 118.684,60.2136332 C118.62,60.5706332 118.556,60.9266332 118.493,61.2836332 C119.057,59.9986332 120.803,59.4346332 121.99,60.1526332 C122.68,60.5696332 123.132,61.3136332 123.37,62.0936332 C123.608,62.8746332 123.655,63.7006332 123.7,64.5166332 C124.58,62.6806332 124.769,60.5886332 124.232,58.6246332"
              fill="#212121"
            ></path>
            <path
              d="M63.3357,114.608533 C63.3237,114.574533 63.3127,114.541533 63.3007,114.507533 C63.2767,114.528533 63.2547,114.549533 63.2287,114.569533 L63.3357,114.608533 Z"
              fill="#212121"
            ></path>
            <path
              d="M76.2398,109.597133 C76.1758,108.340133 75.5778,107.170133 74.5948,106.383133 C74.2378,106.100133 73.8248,105.862133 73.5888,105.473133 C73.4538,105.197133 73.3398,104.912133 73.2468,104.620133 C72.7768,103.501133 71.4368,102.906133 70.2348,103.076133 C69.0318,103.245133 67.9918,104.054133 67.2908,105.045133 C66.2928,104.567133 65.0968,104.988133 64.6188,105.986133 C64.4128,106.416133 64.3668,106.906133 64.4898,107.367133 C63.8668,106.866133 62.8598,107.267133 62.4708,107.966133 C62.0828,108.664133 62.1438,109.519133 62.2598,110.310133 C62.4688,111.740133 62.8178,113.146133 63.3008,114.507133 C63.9068,113.974133 63.8698,113.001133 63.7958,112.180133 C63.7188,111.326133 63.7648,110.284133 64.5118,109.862133 C65.2328,109.455133 66.1078,109.902133 66.9038,110.133133 C68.1758,110.494133 69.5428,110.279133 70.6428,109.545133 C71.0568,109.265133 71.4258,108.914133 71.8728,108.689133 C72.3188,108.463133 72.8868,108.385133 73.3028,108.663133 C73.6828,108.917133 73.8368,109.391133 73.9668,109.829133 C74.2728,110.867133 74.5788,111.905133 74.8848,112.944133 C75.7938,112.073133 76.2868,110.855133 76.2398,109.597133"
              fill="#212121"
            ></path>
            <path
              d="M27.9646,58.7500332 C28.0146,58.2130332 28.0386,57.6740332 28.0336,57.1340332 C28.0636,56.0680332 27.8676,55.0060332 27.4606,54.0200332 C26.9586,52.9280332 26.0656,52.0650332 24.9566,51.6010332 C23.8576,51.1580332 22.6296,51.1590332 21.5316,51.6040332 C20.5396,50.9780332 19.4386,50.4810332 18.2786,50.4200332 C17.1176,50.3580332 15.8916,50.7890332 15.1766,51.7360332 C15.0166,51.9860332 14.8356,52.2220332 14.6346,52.4400332 C14.0596,52.9720332 13.1866,52.8960332 12.4226,52.7870332 C12.7656,53.0800332 13.1076,53.3740332 13.4506,53.6680332 C13.0356,53.7760332 12.6216,53.8840332 12.2066,53.9930332 C12.6906,54.2860332 13.1746,54.5800332 13.6586,54.8730332 C12.2536,55.7170332 11.6146,57.5570332 11.7836,59.2290332 C11.9426,60.8120332 12.7076,62.2590332 13.6206,63.5450332 C13.9846,62.7760332 13.9066,61.8560332 13.9006,60.9900332 C13.8976,60.7090332 13.9146,60.4290332 13.9516,60.1500332 C17.0386,60.8900332 20.2646,61.3650332 23.5356,60.1900332 L24.1916,58.5830332 C24.2836,58.5930332 24.3756,58.6020332 24.4676,58.6110332 L24.8486,60.1910332 C25.7106,60.5440332 26.5746,60.8520332 27.4386,60.5280332 C27.4806,60.7720332 27.5066,61.0210332 27.5256,61.2600332 C27.5996,60.9740332 27.6646,60.6860332 27.7226,60.3960332 C27.8366,60.3350332 27.9456,60.2650332 28.0496,60.1870332 C28.0706,59.7060332 28.0416,59.2250332 27.9646,58.7500332"
              fill="#212121"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
