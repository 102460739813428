import React from "react";
import { useNotify, useRefresh } from "react-admin";
import { makeStyles, Button, FormControlLabel } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { Checkbox } from "final-form-material-ui";
import { APIClient, generateAuthHeader } from "../../../lib";
import { RepList } from "./RepList";

export function RepTab({ record }) {
  const { is_rep_restricted, id: supplier_product_group_id } = record;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async ({ is_rep_restricted }) => {
    const res = await APIClient.put(
      "/supplier_product_group_companies/update_rep_restriction",
      { supplier_product_group_id, is_rep_restricted },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    refresh();
    notify("Your updates have been saved", { type: "info" });
  };

  return (
    <div className={classes.container}>
      <div className={classes.updateRestrictionContainer}>
        <Form
          initialValues={{ is_rep_restricted }}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div>
                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        name="is_rep_restricted"
                        type="checkbox"
                      />
                    }
                    label="Only allow select Reps to use this group"
                  />
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={submitting}
                  >
                    update
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>

      {is_rep_restricted && (
        <RepList supplier_product_group_id={supplier_product_group_id} />
      )}
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    marginTop: 16,
    minHeight: 300,
  },
  updateRestrictionContainer: {
    border: "1px solid #222222",
    padding: "4px 16px",
    width: "fit-content",
    display: "flex",
    alignItems: "center",
  },
}));
