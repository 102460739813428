import { useState, Fragment, useEffect } from "react";
import { useField } from "react-final-form";
import { IconButton, makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import FileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

type Props = {
  fieldName: string;
  accept?: string[];
  singleFileOnly?: boolean;
};
// Important even if the `singleFileOnly` prop is set it still return an array so you have to check if Array.isArray({FIELD}) && {FIELD}.length and then use {FIELD}[0] to get the file
// TODO: maybe if the `singleFileOnly` prop is set we should create a separate component or change the functionality to be a single file
export function MultiFileDropzoneField({
  fieldName,
  accept,
  singleFileOnly,
}: Props) {
  const input = useField(fieldName).input;
  const files = input.value as File[];
  const classes = styles();
  const [fileNames, setFileNames] = useState<string[]>([]);

  useEffect(() => {
    if (Array.isArray(files)) {
      setFileNames(files.map(f => f.name));
    } else {
      setFileNames([]);
    }
  }, [files]);

  const onDrop = (fileArr: File[]) => {
    if (Array.isArray(fileArr)) {
      input.onChange(fileArr);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept ? accept : ["image/*", "application/pdf"],
    multiple: singleFileOnly ? false : true,
    onDrop,
  });

  const removeFile = (index: number) => {
    const copy = [...files];
    // remove deleted index
    copy.splice(index, 1);
    input.onChange(copy);
  };

  return (
    <Fragment>
      <div {...getRootProps()} className={classes.uploadContainer}>
        <input {...getInputProps()} />
        <span className={classes.click}>Click to upload</span>or drag and drop
        files here
      </div>

      {fileNames.map((fileName, index) => {
        return (
          <div key={index} className={classes.filePreview}>
            <div className={classes.fileIconAndName}>
              <FileIcon />
              <div className={classes.fileName}>{fileName}</div>
            </div>
            <IconButton size="small" onClick={() => removeFile(index)}>
              <DeleteIcon color="primary" />
            </IconButton>
          </div>
        );
      })}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  uploadContainer: {
    height: 64,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  click: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
    marginRight: 6,
  },
  filePreview: {
    width: "100%",
    height: 40,
    border: "1px solid #C9CDDE",
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8,
    padding: "0 16px",
  },
  fileIconAndName: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    lineHeight: "18px",
    letterSpacing: 0.1,
    overflow: "hidden",
  },
  fileName: {
    marginLeft: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: 12,
  },
}));
