import React from "react";
import { useDataProvider, useNotify } from "react-admin";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { RA_SUB_PATH } from "../../lib";

export function ImpersonateRep({ repId }) {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const impersonate = e => {
    e.stopPropagation();
    dataProvider
      .getOne("reps/impersonate", { id: repId })
      .then(({ data }) => {
        const { impersonationToken } = data;
        localStorage.setItem("repImpersonationToken", impersonationToken);

        window.location.replace(`/${RA_SUB_PATH}`);
      })
      .catch(({ message }) => {
        return notify(message || "an unexpected error occurred", "warning");
      });
  };

  return <DashboardIcon onClick={e => impersonate(e)} />;
}
