import React from "react";
import Card from "@material-ui/core/Card";
import { styles } from "./StatsCard.styles";

export const StatsCard = props => {
  const {
    field1 = "",
    field1Txt = "",
    field2 = "",
    field2Txt = "",
    field3 = "",
    field3Txt = "",
    field4 = "",
    field4Txt = "",
    forOrg,
  } = props;
  const classes = styles({ forOrg });

  return (
    <Card elevation={3} className={classes.card}>
      <div className={classes.statsSection}>
        <div className={classes.statColumn}>
          <div className={classes.stat}>{field1}</div>
          <div className={classes.statText}>{field1Txt}</div>
        </div>

        <div className={classes.divider}></div>

        <div className={classes.statColumn}>
          <div className={classes.stat}>{field2}</div>
          <div className={classes.statText}>{field2Txt}</div>
        </div>

        <div className={classes.divider}></div>

        <div className={classes.statColumn}>
          <div className={classes.stat}>{field3}</div>
          <div className={classes.statText}>{field3Txt}</div>
        </div>

        {field4Txt && <div className={classes.divider}></div>}

        {field4Txt && (
          <div className={classes.statColumn}>
            <div className={classes.stat}>{field4}</div>
            <div className={classes.statText}>{field4Txt}</div>
          </div>
        )}
      </div>
    </Card>
  );
};
