import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  ImageInput,
  ImageField,
  required,
  email,
  Labeled,
  ReferenceInput,
  SelectInput,
  FunctionField,
  CreateButton,
  ShowButton,
  TopToolbar,
  NumberInput,
  FileInput,
  FileField,
  NullableBooleanInput,
} from "react-admin";
import { useSelector } from "react-redux";
import { getIsOwnerOrGivvr, getRoleBooleans } from "../../customState";
import { ageLevelOptions } from "../../lib";
import { styles } from "./OrgViews.styles";

const requiredInput = [required()];
const validateEmail = [required(), email()];

const Actions = props => {
  const {
    parentProps: { id },
    basePath,
    data,
  } = props;
  return (
    <TopToolbar>
      <ShowButton basePath={basePath} record={data} label="VIEW ORG" />
      <CreateButton
        to={{
          pathname: `/campaigns/create`,
          state: { org_id: id },
        }}
        label="add new campaign"
      />
      <CreateButton
        to={{
          pathname: `/campaign_reservations/create`,
          state: { org_id: id },
        }}
        label="add campaign reservation"
      />
    </TopToolbar>
  );
};

function OrgEdit(props) {
  const classes = styles();
  const isOwnerOrGivvr = useSelector(state => getIsOwnerOrGivvr(state));
  const { isCompanyOrCompanyAdmin } = useSelector(state =>
    getRoleBooleans(state),
  );
  const companyCanAllowOrgsToCreateCampaigns = useSelector(
    state => state.user.companyCanAllowOrgsToCreateCampaigns,
  );

  const OrgTitle = ({ record }) => {
    return <span>Editing - {record ? `${record.name}` : ""}</span>;
  };

  const transformDataForSubmit = values => {
    const { class_options: co, ...rest } = values;
    let class_options = co;
    if (typeof class_options === "string") {
      class_options = class_options
        .trim()
        .replace(/,\s*$/, "")
        .split(",")
        .map(e => e.trim());
    }
    return { class_options, ...rest };
  };

  return (
    <Edit
      title={<OrgTitle />}
      {...props}
      redirect="show"
      undoable={false}
      actions={<Actions parentProps={props} />}
      successMessage="Org updated successfully"
      transform={transformDataForSubmit}
    >
      <SimpleForm redirect="show">
        <TextInput
          name="address_id"
          type="hidden"
          style={{ display: "none" }}
        />
        <TextInput source="id" disabled={true} />
        <TextInput source="name" validate={requiredInput} />
        <TextInput source="slug" label="Unique URL" validate={requiredInput} />
        <TextInput source="address" validate={requiredInput} />
        <TextInput source="city" validate={requiredInput} />
        <TextInput source="state" validate={requiredInput} />
        <TextInput source="zip" validate={requiredInput} />
        <TextInput source="country" />
        <TextInput source="contact_name" />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="phone" />
        <TextInput source="website" />
        <SelectInput
          source="age_level"
          label="Age Level"
          choices={ageLevelOptions}
          translateChoice={false}
        />
        <Fragment>
          <div className={classes.warning}>
            This number is for reference only. It's not used on any reports,
            projections etc.
          </div>
          <div className={classes.warning}>
            Campaign level 'Student Count' is the number that is used in reports
            and projections.
          </div>
        </Fragment>
        <NumberInput source="num_of_students" step={1} label="Student Count" />
        {isOwnerOrGivvr && (
          <ReferenceInput
            label="Gateway Provider"
            source="gateway_provider_id"
            reference="gateway_providers"
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
        {isOwnerOrGivvr && (
          <TextInput
            label="Edit Gateway Login Key"
            source="gateway_login_key"
          />
        )}
        {isOwnerOrGivvr && (
          <FunctionField
            label="Current Gateway transaction Key"
            render={record =>
              record.masked_trans_key ? `xxxx${record.masked_trans_key}` : "N/A"
            }
            formClassName={classes.editInlineBlock}
          />
        )}
        {isOwnerOrGivvr && (
          <TextInput
            label="Edit Gateway transaction Key"
            source="gateway_trans_key"
            formClassName={classes.editInlineBlock}
          />
        )}
        {isOwnerOrGivvr && <TextInput source="gateway_public_key" />}

        <BooleanInput
          source="allow_associated_volunteer_accounts"
          label="Allow Associated Student Accounts (The same email can be used for multiple students and accounts can be cross accessed)"
          fullWidth
        />

        <ArrayInput
          source="notification_emails"
          label="Send contact form submissions and donation notifications(if enabled) to"
          // style={{ width: "400px" }}
        >
          <SimpleFormIterator>
            <TextInput source="email" label="Email" validate={validateEmail} />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="donation_notifications"
          label="Enable Donation/Sale Notifications"
          fullWidth
        />
        {isOwnerOrGivvr && <BooleanInput source="can_submit_pledges" />}
        <Labeled label="Current Logo" formClassName={classes.editInlineBlock}>
          <ImageField
            source="logo"
            // formClassName={classes.editInlineBlock}
            classes={{ image: classes.editViewImage }}
          />
        </Labeled>
        <ImageInput
          source="logoBase64"
          label="Change Logo"
          accept="image/*"
          multiple={false}
          formClassName={classes.editInlineBlock}
        >
          <ImageField
            source="src"
            title="title"
            classes={{ image: classes.editViewImage }}
          />
        </ImageInput>
        {/* <FunctionField
          label="Current Class Options"
          render={({ class_options }) => {
            if (Array.isArray(class_options)) {
              return <div>{class_options.join(", ")}</div>;
            }
            return "NONE";
          }}
        /> */}
        <TextInput
          source="class_options"
          label="Class Options (comma separated list)"
          fullWidth
          multiline
          format={v => (Array.isArray(v) ? v.join(", ") : v)}
        />
        <Fragment>
          <div className={classes.warning}>
            Warning! If you use this upload it will replace the existing Class
            options.
          </div>
        </Fragment>
        <FileInput
          source="classOptionsBase64"
          label="Class Options Upload (1 column CSV without a header)"
          accept={"application/vnd.ms-excel, text/csv, text/plain"}
          multiple={false}
          fullWidth
        >
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="notes" fullWidth multiline />
        {isCompanyOrCompanyAdmin && (
          <NullableBooleanInput
            label="Org Can Manage - Override (keep default to use company setting)"
            source="org_can_manage_override"
            className={classes.manageOverride}
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
          />
        )}
        {isCompanyOrCompanyAdmin && companyCanAllowOrgsToCreateCampaigns && (
          <NullableBooleanInput
            label="Org Can Create Campaigns - Override (keep default to use company setting)"
            source="org_can_create_campaigns_override"
            className={classes.manageOverride}
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
          />
        )}
        <TextInput source="new_password" label="Change Password" />
      </SimpleForm>
    </Edit>
  );
}
export default OrgEdit;
