import { Theme, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import CountdownComp from "react-countdown-now";
import { RootState } from "../../../types/state";
import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { isFuture, isPast } from "date-fns";
import { useIsMobile, useIsLGScreen } from "../../../hooks/ui";

export function OrgProgress() {
  const isMobile = useIsMobile();
  const isLgScreen = useIsLGScreen();
  const { show_profit_based_progress, start_date, end_date } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const profitBased = Boolean(show_profit_based_progress);
  const { campaignProgressStatsFetched, campaignProgressStats } = useSelector(
    ({ dashboardData }: RootState) => dashboardData,
  );
  const [showCountdown, setShowCountdown] = useState(false);
  const {
    orgFormattedRaised,
    orgPercentOfGoal,
    orgPercentRaised,
    orgFormattedGoal,
  } = campaignProgressStats;
  const classes = styles({ percentRaised: orgPercentRaised as string });

  useEffect(() => {
    if (
      start_date &&
      end_date &&
      isPast(new Date(start_date)) &&
      isFuture(new Date(end_date))
    ) {
      setShowCountdown(true);
    }
  }, [start_date, end_date]);

  if (!campaignProgressStatsFetched) {
    return <Skeleton variant="rect" className={classes.skeleton} />;
  }
  return (
    <div className={classes.container}>
      <div className={classes.progressBar}>
        <div className={classes.progress} />
      </div>
      <div className={classes.progressStats}>
        <div className={classes.progressStatsSection}>
          <div className={classes.raised}>${orgFormattedRaised}</div>
          <div>{profitBased ? "Profit Raised" : "Raised"}</div>
          {isLgScreen && (
            <div className={classes.percent}>
              {orgPercentOfGoal}% of goal raised
            </div>
          )}
        </div>

        {!isLgScreen && (
          <div className={classes.progressStatsSection}>
            <div className={classes.percent}>{orgPercentOfGoal}%</div>
            <div>Of goal raised</div>
          </div>
        )}

        <div className={classes.progressStatsSection}>
          <div className={classes.goal}>${orgFormattedGoal}</div>
          <div>Goal</div>
        </div>
      </div>

      {isMobile && showCountdown && end_date && (
        <Countdown endDate={end_date} />
      )}

      {isMobile && <div className={classes.separator} />}
    </div>
  );
}

function Countdown({ endDate }: { endDate: Date }) {
  const classes = styles({});
  return (
    <div className={classes.countdown}>
      <div className={classes.countdownSeparator} />
      <div className={classes.countdownTitle}>Campaign ends in</div>
      <CountdownComp
        date={new Date(endDate)}
        intervalDelay={1000}
        renderer={({ days, hours, minutes, seconds }) => {
          return (
            <div className={classes.timeCards}>
              <div className={classes.timeCard}>
                <div className={classes.time}>{days}</div>
                <div>days</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{hours}</div>
                <div>hours</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{minutes}</div>
                <div>minutes</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{seconds}</div>
                <div>seconds</div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

type StyleProps = {
  percentRaised?: string;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 32,
    },
  },
  skeleton: {
    marginBottom: 40,
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
    height: 60,
    width: "100%",
  },
  progressBar: {
    display: "flex",
    height: 20,
    width: "100%",
    borderRadius: 20,
    backgroundColor: "#F5F5F5",
    [theme.breakpoints.down("sm")]: {
      height: 16,
    },
  },
  progress: {
    height: "100%",
    width: ({ percentRaised }) => percentRaised,
    backgroundColor: theme.palette.secondary2.main,
    borderRadius: 20,
  },
  progressStats: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "17px",
  },
  progressStatsSection: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      fontSize: 14,
      letterSpacing: 0.15,
      lineHeight: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.15,
      lineHeight: "13px",
    },
  },
  raised: {
    color: theme.palette.secondary2.main,
    fontSize: 24,
    fontWeight: "bold",
    letterSpacing: 0.3,
    lineHeight: "26px",
    marginRight: 16,
    [theme.breakpoints.down("md")]: {
      fontWeight: 600,
      color: "inherit",
      fontSize: 16,
      letterSpacing: 0.17,
      lineHeight: "17px",
      marginRight: 0,
      marginBottom: 8,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  percent: {
    marginLeft: 16,
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 0.17,
      lineHeight: "17px",
      marginLeft: 0,
      marginBottom: 8,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  goal: {
    fontSize: 24,
    fontWeight: "bold",
    letterSpacing: 0.3,
    lineHeight: "26px",
    marginRight: 16,
    [theme.breakpoints.down("md")]: {
      fontWeight: 600,
      fontSize: 16,
      letterSpacing: 0.17,
      lineHeight: "17px",
      marginRight: 0,
      marginBottom: 8,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      lineHeight: "15px",
    },
  },
  separator: {
    paddingBottom: 32,
    borderBottom: "1px solid #DBDEEE",
    marginLeft: -16,
    marginRight: -16,
  },
  // COUNTDOWN
  countdown: {
    marginTop: 32,
  },
  countdownSeparator: {
    paddingBottom: 32,
    borderTop: "1px solid #DBDEEE",
    marginLeft: -16,
    marginRight: -16,
  },
  countdownTitle: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    lineHeight: "16px",
    marginBottom: 16,
  },
  timeCards: {
    display: "flex",
    alignItems: "center",
  },
  timeCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 75,
    minWidth: 64,
    borderRadius: 8,
    backgroundColor: "#F1F9FF",
    padding: "0 4px",
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "13px",
    color: theme.palette.text.secondary2,
    marginRight: 16,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  time: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "26px",
    color: theme.palette.primary.main,
    paddingBottom: 8,
  },
}));
