import React, { useState, useEffect } from "react";
import {
  EditView,
  SimpleForm,
  TextInput,
  required,
  minLength,
  email,
  NumberInput,
  ReferenceInput,
  SelectInput,
  useRefresh,
  FunctionField,
  AutocompleteInput,
  Toolbar,
  SaveButton,
  BooleanInput,
  useNotify,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from "react-admin";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import {
  APIClient,
  EMOJI_REGEX,
  generateAuthHeader,
  isCompanyOrCompanyAdmin,
  isCompanyOrCompanyAdminOrRep,
  isGivvrCompany,
} from "../../lib";
import { useDispatch } from "react-redux";
import { paymentTypes, campaignTypeBooleans } from "../../lib";

const validateEmail = [required(), email(), minLength(2)];
const validateName = [required(), minLength(1)];
const requiredInput = [required()];

const pmtTypeChoices = [
  { id: "cc", name: "Credit Card" },
  { id: "check", name: "Check" },
  { id: "cash", name: "Cash" },
  { id: "pledge", name: "Pledge" },
];

const editWarning = (
  <div style={{ color: "red", paddingTop: "10px" }}>
    Warning: updating amount will not credit or re-charge their Credit Card, you
    need to do it manually.
  </div>
);

const ticketPackageOptions = choice =>
  `${choice.num_of_tickets} tickets for $${choice.price}`;

export function DonationEdit(props) {
  const {
    id,
    record,
    record: {
      campaign_type_id,
      pmt_type,
      campaign_id,
      company_id,
      ship_to_school,
      shippingAddress: prevShippingAddress,
    },
    basePath,
    volunteerLabel,
    forExpand,
    associatedDonation,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const history = useHistory();
  const [volunteers, setVolunteers] = useState([]);
  const [saving, setSaving] = useState(false);
  const [displayShippingAddress, setDisplayShippingAddress] = useState(false);
  const [shippingAddressCheckbox, setShippingAddressCheckbox] = useState(false);
  const [addShippingAddress, setAddShippingAddress] = useState(false);
  const editProps = {
    basePath,
    defaultTitle: " ",
    loaded: true,
    loading: false,
    record,
    resource: "donations",
    save: record => onSave(record),
    saving: false,
    version: 0,
  };

  const isCompany = useSelector(state =>
    isCompanyOrCompanyAdmin(state.user.role),
  );
  const isCompanyOrRep = useSelector(state =>
    isCompanyOrCompanyAdminOrRep(state.user.role),
  );
  const { isRaffle, isProduct } = campaignTypeBooleans(campaign_type_id);
  const isGivvr = isGivvrCompany(company_id);

  useEffect(() => {
    if (isProduct && !ship_to_school) {
      if (prevShippingAddress) setDisplayShippingAddress(true);
      else setShippingAddressCheckbox(true);
    }
  }, [isProduct, ship_to_school, prevShippingAddress]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/campaigns/${campaign_id}/volunteers/autocomplete_list`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        return notify(errorMessage, "warning");
      }
      setVolunteers(data);
    };
    if (campaign_id) fetch();
  }, [notify, campaign_id]);

  const onSave = async record => {
    const {
      total_amount,
      additional_donation,
      products,
      shippingAddress,
      ...rest
    } = record;
    const update = { ...rest, total_amount: total_amount.toString() };
    if (isRaffle) {
      update.additional_donation = additional_donation
        ? additional_donation.toString()
        : additional_donation;
    }
    if (Array.isArray(products) && products.length) {
      const personalizations = [];
      products.forEach(p => {
        const {
          donation_product_id,
          donation_product_personalization: DPP,
        } = p;
        if (Array.isArray(DPP) && DPP.length) {
          personalizations.push({
            donation_product_id,
            personalization: DPP.map(({ text }) => (text ? text : "")),
          });
        }
      });
      update.personalizations = personalizations;
    }
    if (prevShippingAddress || addShippingAddress) {
      update.shippingAddress = shippingAddress;
    }

    setSaving(true);
    const res = await APIClient.put(`/donations/${id}`, update, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage } = res;
    setSaving(false);

    if (error) return notify(errorMessage, "warning");
    notify("Record updated successfully");

    if (forExpand) {
      dispatch({
        type: "RA/TOGGLE_LIST_ITEM_EXPAND",
        payload: id,
        meta: {
          resource: associatedDonation ? "donations/associated" : "donations",
        },
      });
      setTimeout(() => {
        refresh();
      }, 100);
    } else {
      history.push("/donations");
    }
  };

  const Title = ({ record }) => (
    <span>
      {isProduct ? "Order" : "Donation"} #{record ? record.id : ""}
    </span>
  );

  return (
    <EditView
      {...editProps}
      saving={saving}
      title={forExpand ? " " : <Title />}
    >
      <SimpleForm
        redirect={null}
        toolbar={
          <Toolbar>
            <SaveButton label="save" redirect={null} />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled={true} />
        {isGivvr && <TextInput source="title" />}
        <TextInput source="first_name" validate={validateName} />
        <TextInput source="last_name" validate={validateName} />
        <TextInput source="display_name" />
        <TextInput source="address" validate={requiredInput} />
        <TextInput source="apt" />
        <TextInput source="city" validate={requiredInput} />
        <TextInput source="state" validate={requiredInput} />
        <TextInput source="zip" validate={requiredInput} />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="phone" />

        {shippingAddressCheckbox && (
          <FormControlLabel
            control={
              <Checkbox
                checked={addShippingAddress}
                onChange={() =>
                  setAddShippingAddress(prevState => {
                    if (prevState) setDisplayShippingAddress(false);
                    else setDisplayShippingAddress(true);
                    return !prevState;
                  })
                }
              />
            }
            className={classes.addShipping}
            label="Add Shipping Address"
          />
        )}

        {displayShippingAddress && (
          <TextInput source="ship_to_name" label="Ship To Name" />
        )}
        {displayShippingAddress && (
          <TextInput
            source="shippingAddress.address"
            validate={requiredInput}
            label="Shipping Address"
          />
        )}
        {displayShippingAddress && (
          <TextInput source="shippingAddress.apt" label="Shipping Apt" />
        )}
        {displayShippingAddress && (
          <TextInput
            source="shippingAddress.city"
            validate={requiredInput}
            label="Shipping City"
          />
        )}
        {displayShippingAddress && (
          <TextInput
            source="shippingAddress.state"
            validate={requiredInput}
            label="Shipping State"
          />
        )}
        {displayShippingAddress && (
          <TextInput
            source="shippingAddress.zip"
            validate={requiredInput}
            label="Shipping Zip"
          />
        )}

        <BooleanInput source="anonymous" label="Donate Anonymously" />
        <TextInput source="solicited_by" />

        <AutocompleteInput
          label={volunteerLabel}
          source="volunteer_id"
          options={{ fullWidth: true, type: "search" }}
          choices={volunteers.map(t => {
            const { id, first_name, last_name } = t;
            return { id, name: `${first_name} ${last_name}` };
          })}
          translateChoice={false}
          allowEmpty
        />

        <FunctionField
          addLabel={false}
          render={record => (record.pmt_type === "cc" ? editWarning : "")}
        />
        {isRaffle && (
          <ReferenceInput
            label="choose a Ticket package"
            source="ticket_package_id"
            reference="campaign_ticket_packages"
            filter={{ campaign_id }}
            allowEmpty
            emptyValue={null}
            parse={v => {
              return v ? v : null;
            }}
          >
            <SelectInput optionText={ticketPackageOptions} resettable />
          </ReferenceInput>
        )}
        {isRaffle && (
          <NumberInput
            source="additional_donation"
            label="Additional Donation"
          />
        )}
        {!isRaffle && !isProduct && (
          <NumberInput source="total_amount" label={"Total Donation Amount"} />
        )}
        {!isProduct && (
          <TextInput
            disabled={true}
            source="num_of_payments"
            label="Payment Schedule"
            format={v => (v ? `Paid in ${v} monthly installments` : "N/A")}
          />
        )}

        {isProduct && (
          <FunctionField
            label="Items Total"
            render={({ items_total }) => (items_total ? `$${items_total}` : "")}
          />
        )}
        {isProduct && (
          <FunctionField
            label="Shipping And Handling"
            render={({ shipping_and_handling }) =>
              !shipping_and_handling || shipping_and_handling === "0.00"
                ? ""
                : `$${Number(shipping_and_handling).toFixed(2)}`
            }
          />
        )}
        {isProduct && (
          <FunctionField
            label="Online Fee"
            render={({ online_fee }) => (online_fee ? `$${online_fee}` : "")}
          />
        )}
        {isProduct && (
          <FunctionField
            label="Custom Fee"
            render={({ custom_fee }) => (custom_fee ? `$${custom_fee}` : "")}
          />
        )}
        {isProduct && (
          <FunctionField
            label="Additional Donation"
            render={({ additional_donation }) =>
              additional_donation ? `$${additional_donation}` : ""
            }
          />
        )}
        {isProduct && (
          <FunctionField
            label="Tax"
            render={({ total_tax }) => (total_tax ? `$${total_tax}` : "")}
          />
        )}
        {isProduct && (
          <FunctionField
            label="Total"
            render={({ charge_amount }) => `$${charge_amount}`}
          />
        )}
        {isProduct && (
          <NumberInput
            source="total_amount"
            label="Amount Credited To Campaign"
          />
        )}

        {pmt_type === "pledge" ? (
          <SelectInput
            source="pmt_type"
            label="Payment Type"
            choices={pmtTypeChoices}
            translateChoice={false}
          />
        ) : (
          <TextInput
            disabled={true}
            source="pmt_type"
            label="Payment Type"
            format={v => paymentTypes[v]}
          />
        )}
        <TextInput source="external_id" label="External ID" />
        <TextInput source="comments" label="Display message" />
        <TextInput
          source="internal_notes"
          label="Internal Notes"
          fullWidth
          multiline
        />
        {isCompanyOrRep && (
          <TextInput
            source="company_internal_notes"
            label="Company Internal Notes"
            fullWidth
            multiline
          />
        )}

        {isProduct && (
          <ArrayInput
            source="products"
            label="Products"
            className={classes.products}
          >
            <SimpleFormIterator disableRemove disableAdd disableReordering>
              <FormDataConsumer>
                {({ scopedFormData = {}, getSource }) => {
                  const {
                    donation_product_id,
                    thumbnail_image,
                    product_name,
                    sku,
                    price,
                    qty,
                    personalize_max_chars: maxChars,
                    donation_product_personalization: DPP,
                  } = scopedFormData;
                  const hasPersonalization = Boolean(
                    Array.isArray(DPP) && DPP.length,
                  );

                  return (
                    <div
                      key={donation_product_id}
                      className={classes.productRow}
                    >
                      <img
                        className={classes.img}
                        src={thumbnail_image}
                        alt="product"
                      />
                      <div>
                        <div>{product_name}</div>
                        <div>SKU: {sku}</div>
                        <div>{`$${price} / each`}</div>
                        <div>Qty: {qty}</div>
                      </div>
                      {hasPersonalization && (
                        <ArrayInput
                          source={getSource("donation_product_personalization")}
                          label=" "
                          className={classes.personalization}
                        >
                          <SimpleFormIterator disableRemove disableAdd>
                            <TextInput
                              helperText={false}
                              label="Personalization"
                              source="text"
                              format={v => sanitizePersonalization(v, maxChars)}
                              parse={v => sanitizePersonalization(v, maxChars)}
                              disabled={!isCompany}
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      )}
                    </div>
                  );
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </SimpleForm>
    </EditView>
  );
}

const styles = makeStyles(_theme => ({
  products: {
    width: "fit-content",
  },
  productRow: {
    display: "flex",
    alignItems: "center",
    margin: "12px 0",
  },
  img: {
    height: "75px",
    width: "75px",
    marginRight: "24px",
  },
  addShipping: { marginBottom: 16 },
  personalization: { width: "fit-content", marginLeft: 12 },
}));

function sanitizePersonalization(value, maxChars) {
  if (!value) return value;
  const charLimit = maxChars ? maxChars : 30;
  return value.replace(EMOJI_REGEX, "").slice(0, charLimit);
}
