import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import { format } from "date-fns";
import {
  APIUtilClient,
  downloadPdf,
  downloadXlsx,
  generateAuthHeader,
  getUserTimezone,
  isRaffleCampaign,
} from "../../lib";

function donationExporter(donations, campaign_type_id, isGivvr) {
  const formattedDonations = donations.map(d => {
    const {
      id,
      created_at,
      title,
      first_name,
      last_name,
      address,
      apt,
      city,
      state,
      zip,
      email,
      phone,
      pmt_type,
      total_amount,
      additional_donation,
      tip,
      processing_fee,
      num_of_payments,
      campaign_ticket_package,
      volunteerName,
      solicited_by,
      ccType,
      maskedCC,
      comments,
      comments_to_org,
      has_journal,
      ad_text,
      additional_msg,
      affiliate,
      external_id,
    } = d;
    let numberOfTickets = null;
    let TktPrice = null;
    if (campaign_ticket_package) {
      const { num_of_tickets, price } = campaign_ticket_package;
      numberOfTickets = num_of_tickets;
      TktPrice = price;
    }
    let exportObj = { ID: id, Date: format(new Date(created_at), "Pp") };
    if (isGivvr) exportObj.Title = title;
    exportObj = {
      ...exportObj,
      "First Name": first_name,
      "Last Name": last_name,
      Address: address,
      Apt: apt,
      City: city,
      State: state,
      Zip: zip,
      Email: email,
      Phone: phone,
      Fundraiser: volunteerName,
      "Solicited By": solicited_by,
      "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
      "Credit Card Type": ccType,
      "Credit Card Last 4": maskedCC,
      "Total Donation": `$${total_amount}`,
      Tip: tip ? `$${tip}` : null,
      "Processing Fee": processing_fee ? `$${processing_fee}` : null,
      "Payment Schedule": num_of_payments
        ? `${num_of_payments} monthly installments`
        : "N/A",
      "Display Message": comments,
      Affiliate: affiliate,
    };
    if (isGivvr) exportObj["Comments To Org"] = comments_to_org;
    if (has_journal) {
      exportObj["Journal Ad Text"] = ad_text;
      exportObj["In Honor Of"] = additional_msg;
    }
    if (isRaffleCampaign(campaign_type_id)) {
      exportObj["Number Of Tickets"] = numberOfTickets;
      exportObj["Ticket Package Price"] = TktPrice;
      exportObj["Additional Donation"] = additional_donation
        ? `$${additional_donation}`
        : null;
    }
    if (isGivvr) exportObj["External ID"] = external_id;

    return exportObj;
  });
  jsonExport(formattedDonations, (err, csv) => {
    downloadCSV(csv, "Donations");
  });
}

function ticketExporter(donations, isGivvr) {
  const tickets = [];
  donations.forEach(d => {
    const {
      id,
      created_at,
      title,
      first_name,
      last_name,
      email,
      phone,
      pmt_type,
      total_amount,
      additional_donation,
      campaign_ticket_package,
      volunteerName,
      solicited_by,
      affiliate,
      num_of_payments,
    } = d;
    if (campaign_ticket_package) {
      const { num_of_tickets, price } = campaign_ticket_package;
      for (let index = 1; index <= num_of_tickets; index++) {
        let data = {
          ID: id,
          Date: format(new Date(created_at), "Pp"),
          Ticket: `${index} of ${num_of_tickets}`,
          "Number Of Tickets": num_of_tickets,
          "Ticket Package Price": `$${price}`,
          "Additional Donation": additional_donation
            ? `$${additional_donation}`
            : null,
          "Total Donation": `$${total_amount}`,
          "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
          "Payment Schedule": num_of_payments
            ? `${num_of_payments} monthly installments`
            : "N/A",
        };
        if (isGivvr) data.Title = title;
        data = {
          ...data,
          "First Name": first_name,
          "Last Name": last_name,
          Email: email,
          Phone: phone,
          Fundraiser: volunteerName,
          "Solicited By": solicited_by,
          Affiliate: affiliate,
        };
        tickets.push(data);
      }
    }
  });
  jsonExport(tickets, (err, csv) => {
    downloadCSV(csv, "Tickets");
  });
}

function byOrderExporter(donations) {
  const formattedDonations = donations.map(d => {
    const {
      id,
      created_at,
      first_name,
      last_name,
      ship_to_name,
      address,
      apt,
      city,
      state,
      zip,
      email,
      phone,
      pmt_type,
      items_total,
      additional_donation,
      total_amount,
      total_tax,
      online_fee,
      custom_fee,
      volunteerName,
      solicited_by,
      products,
      ship_to_school,
      shippingAddress,
      shipping_and_handling,
      charge_amount,
      affiliate,
    } = d;
    const sAddress = shippingAddress ? shippingAddress.address : "";
    const sApt = shippingAddress ? shippingAddress.apt : "";
    const sCity = shippingAddress ? shippingAddress.city : "";
    const sState = shippingAddress ? shippingAddress.state : "";
    const sZip = shippingAddress ? shippingAddress.zip : "";

    let exportObj = {
      ID: id,
      Date: format(new Date(created_at), "Pp"),
      "First Name": first_name,
      "Last Name": last_name,
      "Billing Address": address,
      "Billing Apt": apt,
      "Billing City": city,
      "Billing State": state,
      "Billing Zip": zip,
      "Ship To School": ship_to_school ? "Yes" : "No",
      "Ship To Name": ship_to_name,
      "Shipping Address": sAddress,
      "Shipping Apt": sApt,
      "Shipping City": sCity,
      "Shipping State": sState,
      "Shipping Zip": sZip,
      Email: email,
      Phone: phone,
      Fundraiser: volunteerName,
      "Solicited By": solicited_by,
      Affiliate: affiliate,
      "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
      "Items Total": items_total ? `$${items_total}` : "",
      "Shipping And Handling":
        !shipping_and_handling || shipping_and_handling === "0.00"
          ? ""
          : `$${shipping_and_handling}`,
      Tax: !total_tax || total_tax === "0.00" ? "" : `$${total_tax}`,
      "Online Fee": online_fee ? `$${online_fee}` : "",
      "Custom Fee": custom_fee ? `$${custom_fee}` : "",
      "Additional Donation": additional_donation
        ? `$${additional_donation}`
        : "",
      "Campaign Credited": `$${total_amount}`,
      Total: `$${charge_amount}`,
    };
    let i = 1;
    products.forEach(p => {
      const { sku, product_name, qty } = p;
      exportObj = {
        ...exportObj,
        [`Product#${i} Name`]: product_name,
        [`Product#${i} Sku`]: sku,
        [`Product#${i} Qty`]: qty,
      };
      i++;
    });
    return exportObj;
  });
  jsonExport(formattedDonations, (err, csv) => {
    downloadCSV(csv, "Orders");
  });
}

function byProductExporter(donations) {
  const productArr = [];
  donations.forEach(d => {
    const {
      id,
      created_at,
      first_name,
      last_name,
      ship_to_name,
      address,
      apt,
      city,
      state,
      zip,
      email,
      phone,
      pmt_type,
      items_total,
      total_amount,
      total_tax,
      online_fee,
      custom_fee,
      additional_donation,
      volunteerName,
      solicited_by,
      products,
      ship_to_school,
      shippingAddress,
      shipping_and_handling,
      charge_amount,
      affiliate,
    } = d;
    const sAddress = shippingAddress ? shippingAddress.address : "";
    const sApt = shippingAddress ? shippingAddress.apt : "";
    const sCity = shippingAddress ? shippingAddress.city : "";
    const sState = shippingAddress ? shippingAddress.state : "";
    const sZip = shippingAddress ? shippingAddress.zip : "";

    products.forEach((p, index) => {
      const { sku, product_name, qty, price } = p;
      let exportObj = {
        "Order ID": id,
        Date: format(new Date(created_at), "Pp"),
        Product: `${index + 1} of ${products.length}`,
        "Product Name": product_name,
        "Product Sku": sku,
        "Product Qty": qty,
        "Price Per Item": price,
        "First Name": first_name,
        "Last Name": last_name,
        "Billing Address": address,
        "Billing Apt": apt,
        "Billing City": city,
        "Billing State": state,
        "Billing Zip": zip,
        "Ship To Name": ship_to_name,
        "Ship To School": ship_to_school ? "Yes" : "No",
        "Shipping Address": sAddress,
        "Shipping Apt": sApt,
        "Shipping City": sCity,
        "Shipping State": sState,
        "Shipping Zip": sZip,
        Email: email,
        Phone: phone,
        Fundraiser: volunteerName,
        "Solicited By": solicited_by,
        Affiliate: affiliate,
        "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
        "Order Items Total": items_total ? `$${items_total}` : "",
        "Order Shipping And Handling":
          !shipping_and_handling || shipping_and_handling === "0.00"
            ? ""
            : `$${shipping_and_handling}`,
        "Order Tax": !total_tax || total_tax === "0.00" ? "" : `$${total_tax}`,
        "Order Online Fee": online_fee ? `$${online_fee}` : "",
        "Order Custom Fee": custom_fee ? `$${custom_fee}` : "",
        "Order Additional Donation": additional_donation
          ? `$${additional_donation}`
          : "",
        "Campaign Credited": `$${total_amount}`,
        "Order Total": `$${charge_amount}`,
      };
      productArr.push(exportObj);
    });
  });
  jsonExport(productArr, (err, csv) => {
    downloadCSV(csv, "Order Products");
  });
}

async function shipstationShipToSchool(campaignId) {
  const res = await APIUtilClient.get(
    `/reports/shipstation-ship-to-school?campaign_id=${campaignId}`,
    { headers: generateAuthHeader() },
  );
  downloadReport(res, "ShipStation-ship-to-school");
}

async function neighborsReport(campaignId) {
  const res = await APIUtilClient.get(
    `/reports/neighbors?campaign_id=${campaignId}`,
    { headers: generateAuthHeader() },
  );
  return downloadReport(res, "Neighbors");
}

async function shipToSchoolStudentProductReport(campaignId) {
  const res = await APIUtilClient.get(
    `/reports/ship-to-school-student-products?campaign_id=${campaignId}`,
    { headers: generateAuthHeader() },
  );
  return downloadReport(res, "Ship To School Student Products");
}

async function dinnerReport(campaignId) {
  const timezone = getUserTimezone();
  const res = await APIUtilClient.get(
    `/reports/dinner-report?campaign_id=${campaignId}&timezone=${timezone}`,
    { headers: generateAuthHeader() },
  );
  return downloadReport(res, "Dinner Reservations");
}

async function dedicationsExport(campaignId) {
  const res = await APIUtilClient.get(
    `/campaign_donation_group_item_donations/campaign_donations_export?campaign_id=${campaignId}`,
    { headers: generateAuthHeader() },
  );
  return downloadReport(res, "Campaign Donations With Dedications");
}

async function personalizationReport(campaignId) {
  const res = await APIUtilClient.get(
    `/reports/personalization-export?campaign_id=${campaignId}`,
    { headers: generateAuthHeader() },
  );
  return downloadReport(res, "Personalization Report");
}

async function pullSheet(campaignId) {
  const res = await APIUtilClient.get(
    `/pdf/ship-to-school-pull-sheet?campaign_id=${campaignId}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  return downloadPdf(res, "ship-to-school-pull-sheet.pdf");
}

async function studentLabels(campaignId) {
  const res = await APIUtilClient.get(
    `/pdf/student-labels?campaign_id=${campaignId}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  return downloadPdf(res, "student-labels.pdf");
}

async function mrsFieldsOrderAggregatesXml(campaignId) {
  const res = await APIUtilClient.get(
    `/pdf/mrs-fields-order-aggregates-xml?campaign_id=${campaignId}`,
    { headers: { ...generateAuthHeader() } },
  );
  const { error, data, errorMessage } = res;
  if (error) {
    return alert(
      errorMessage ||
        "There was an error downloading your report, please try again later.",
    );
  }
  const blob = new Blob([data]);
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "campaign-orders.xml";
  link.click();
}

async function bigKahunaTopCampaignProducts(campaignId) {
  const res = await APIUtilClient.get(
    `/xlsx_reports/big_kahuna_top_campaign_products?campaign_id=${campaignId}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  downloadXlsx(res, "TOP SELLER REPORT.XLSX");
}

function downloadReport(res, name) {
  const { error, data, errorMessage } = res;
  if (error) {
    return alert(
      errorMessage ||
        "There was an error downloading your report, please try again later.",
    );
  }

  jsonExport(data, (err, csv) => {
    downloadCSV(csv, name);
  });
}

export {
  donationExporter,
  ticketExporter,
  byOrderExporter,
  byProductExporter,
  shipstationShipToSchool,
  neighborsReport,
  shipToSchoolStudentProductReport,
  dinnerReport,
  dedicationsExport,
  personalizationReport,
  pullSheet,
  studentLabels,
  mrsFieldsOrderAggregatesXml,
  bigKahunaTopCampaignProducts,
};
