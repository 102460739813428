import { GenericAutocomplete } from "../../types";
import { MaterialAutocomplete } from "../../types/materials";
import { materialTypes } from "./actions";

type MaterialState = {
  materialCategoryAutocomplete: GenericAutocomplete;
  materialAutocomplete: MaterialAutocomplete;
  materialAutocompleteIdMap: { [k: string]: { id: number; name: string } };
};

const initialState: MaterialState = {
  materialCategoryAutocomplete: [],
  materialAutocomplete: [],
  materialAutocompleteIdMap: {},
};

export const materialReducers = (
  state = initialState,
  action: any,
): MaterialState => {
  switch (action.type) {
    case materialTypes.MATERIAL_AUTOCOMPLETE_SET:
      return {
        ...state,
        ...action.data,
      };
    case materialTypes.MATERIAL_CATEGORIES_AUTOCOMPLETE_SET:
      return {
        ...state,
        materialCategoryAutocomplete: action.data,
      };
    default:
      return state;
  }
};
