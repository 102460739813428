import React, { useState } from "react";
import {
  TextField,
  TopToolbar,
  ImageField,
  BooleanField,
  useListController,
  ListView,
  TextInput,
  Filter,
  Datagrid,
  Notification,
  useNotify,
} from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import { CreateItem } from "./CreateItem";
import { EditItem } from "./EditItem";

const asideModes = { CREATE: "add", EDIT: "edit", CLOSED: null };

export function CampaignDonationGroupItems({ record }) {
  const { id: campaign_id, campaign_donation_group_id } = record;
  const classes = styles();
  const notify = useNotify();
  const basePath = `/campaigns/${campaign_id}/show`;
  const [asideMode, setAsideMode] = useState(asideModes.CLOSED);
  const [activeItemId, setActiveItemId] = useState(false);

  const controllerProps = useListController({
    sort: { field: "label", order: "ASC" },
    location: {},
    basePath,
    resource: "campaign_donation_group_items",
    filter: { campaign_id },
    perPage: 25,
  });

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        className={classes.addGroup}
        onClick={() => setAsideMode(asideModes.CREATE)}
      >
        add item
      </Button>
    </TopToolbar>
  );

  const ItemFilter = props => {
    return (
      <Filter {...props}>
        <TextInput label="Item Search" source="q" alwaysOn />
      </Filter>
    );
  };

  const onSuccess = msg => {
    controllerProps.refetch();
    notify(msg);
    setAsideMode(asideModes.CLOSED);
  };

  const onRowClick = id => {
    setActiveItemId(id);
    setAsideMode(asideModes.EDIT);
  };

  return (
    <div className={classes.container}>
      <div className={classes.itemsTitle}>GROUP ITEMS</div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        actions={<Actions />}
        filters={<ItemFilter />}
        empty={false}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" label="Item ID" />
          <TextField source="tier" />
          <TextField source="tier_order_number" label="Tier Order Number" />
          <TextField source="label" />
          <TextField source="amount" />
          <BooleanField source="multi_use" label="Multi Use" />
          <ImageField
            source="avatar"
            sortable={false}
            classes={{ image: classes.avatar }}
          />
        </Datagrid>
      </ListView>
      <Drawer open={Boolean(asideMode)} anchor="right">
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            <div>
              {asideMode === asideModes.CREATE ? "Add an Item" : "Edit Item"}
            </div>
            <IconButton
              onClick={() => setAsideMode(asideModes.CLOSED)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>

          {asideMode === asideModes.CREATE && (
            <CreateItem
              basePath={basePath}
              onSuccess={onSuccess}
              campaign_donation_group_id={campaign_donation_group_id}
            />
          )}

          {asideMode === asideModes.EDIT && (
            <EditItem
              basePath={basePath}
              onSuccess={onSuccess}
              activeItemId={activeItemId}
            />
          )}
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    marginBottom: 20,
  },
  avatar: { maxHeight: 50 },
  drawer: {
    padding: 20,
    width: "100%",
    minWidth: 350,
    maxWidth: "100%",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
  itemsTitle: {
    fontSize: "18px",
    fontWeight: 600,
    padding: "12px 0",
    textAlign: "center",
  },
  delete: {
    position: "absolute",
    right: 16,
  },
}));
