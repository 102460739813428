import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  FunctionField,
} from "react-admin";

import { ImpersonateCompany } from "../../components";

const CompanyFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Companies" source="q" alwaysOn />
    </Filter>
  );
};

export default function CompanyList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Companies"
      bulkActionButtons={false}
      filters={<CompanyFilter />}
    >
      <Datagrid rowClick={"edit"}>
        <TextField source="id" sortable={true} />
        <TextField source="company_name" sortable={true} />
        <TextField source="company_slug" sortable={true} />
        <FunctionField
          addLabel={false}
          render={record => <ImpersonateCompany companyId={record.id} />}
        />
      </Datagrid>
    </List>
  );
}
