import React, { useState } from "react";
import {
  Notification,
  useNotify,
  FileInput,
  FileField,
  useRefresh,
} from "react-admin";
import {
  Dialog,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import { APIClient, generateAuthHeader } from "../../lib";
import { styles } from "./Donation.styles";

export function CPTBrochureUpload({ setShowModal, campaign_id }) {
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [failedRows, setFailedRows] = useState(null);

  const onSubmit = async ({ json_file }) => {
    if (!json_file) return notify("Please upload A JSON file", "error");
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(json_file.rawFile);
    reader.onload = async () => {
      const res = await APIClient.post(
        "/donations/cpt_bulk_brochure_upload",
        {
          brochures: JSON.parse(reader.result),
          campaign_id: Number(campaign_id),
        },
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        setDisableSubmit(false);
        notify(errorMessage, "warning");
      } else if (!data.failedRows.length) {
        setShowModal(false);
        notify("Your Order Forms have been successfully uploaded!", {
          type: "info",
        });
        refresh();
      } else {
        setFailedRows(data.failedRows);
      }
    };
    reader.onerror = console.error;
  };

  return (
    <Dialog open={true} maxWidth={false}>
      <div className={classes.importContainer}>
        <div className={classes.cptHeader}>
          <div>CPT Order Form Upload</div>
          <div className={classes.importClose}>
            <IconButton onClick={() => setShowModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {!failedRows && (
          <div>
            <div className={classes.importSubHeader}>
              Upload CPT generated JSON file
            </div>

            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting }) => {
                return (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <div className={classes.fileDrop}>
                      <FileInput
                        source="json_file"
                        label="JSON File"
                        accept={"application/json"}
                        multiple={false}
                      >
                        <FileField source="src" title="title" />
                      </FileInput>
                    </div>

                    <Button
                      type="submit"
                      disabled={disableSubmit || submitting}
                      className={classes.importButtons}
                      startIcon={<SaveIcon />}
                    >
                      upload
                      {disableSubmit && (
                        <CircularProgress
                          size={20}
                          className={classes.cptProgress}
                        />
                      )}
                    </Button>
                  </form>
                );
              }}
            />
          </div>
        )}

        {failedRows && (
          <div>
            <div className={classes.importSubHeader}>
              Some of your Order Forms failed to upload successfully
            </div>
            <table className={classes.failedOrderTable}>
              <thead>
                <tr>
                  <th className={classes.failedOrderTh}>Row Number</th>
                  <th className={classes.failedOrderTh}>Message</th>
                </tr>
              </thead>
              <tbody>
                {failedRows.map(r => {
                  const { row_number, msg } = r;
                  return (
                    <tr key={row_number}>
                      <td className={classes.failedOrderCell}>{row_number}</td>
                      <td className={classes.failedOrderCell}>{msg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Button
              className={classes.importButtons}
              onClick={() => {
                setShowModal(false);
                refresh();
              }}
            >
              OK
            </Button>
          </div>
        )}
      </div>
      <Notification />
    </Dialog>
  );
}
