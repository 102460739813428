import React, { useState, useEffect } from "react";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import {
  useNotify,
  EditView,
  SimpleForm,
  Toolbar,
  SaveButton,
  TextInput,
  ListView,
  useListController,
  TopToolbar,
  Datagrid,
  TextField,
  Notification,
  SelectInput,
} from "react-admin";
import { APIClient, generateAuthHeader } from "../../../lib";
import { CreateShowcaseItem } from "./CreateShowcaseItem";
import { EditShowcaseItem } from "./EditShowcaseItem";
const asideModes = { CREATE: "add", EDIT: "edit", CLOSED: null };

export function Showcase({ record }) {
  const { id: campaign_id } = record;
  const basePath = `/campaigns/${campaign_id}/show`;
  const classes = styles();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const [asideMode, setAsideMode] = useState(asideModes.CLOSED);
  const [activeItemId, setActiveItemId] = useState(false);
  const [editProps, setEditProps] = useState({
    basePath,
    defaultTitle: " ",
    loaded: false,
    loading: true,
    resource: "volunteers",
    save: record => onSave(record),
    saving: false,
    version: 0,
  });

  useEffect(() => {
    fetchShowcase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const controllerProps = useListController({
    sort: { field: "id", order: "ASC" },
    location: {},
    basePath,
    resource: "campaign_showcase_items",
    filter: { campaign_id },
    perPage: 25,
  });

  const fetchShowcase = async () => {
    const res = await APIClient.get(
      `/campaign_showcase/campaigns/${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setEditProps({ ...editProps, loaded: true, loading: false, record: data });
  };

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setAsideMode(asideModes.CREATE)}
      >
        add item
      </Button>
    </TopToolbar>
  );

  const onSuccess = msg => {
    controllerProps.refetch();
    notify(msg);
    setAsideMode(asideModes.CLOSED);
  };

  const onRowClick = id => {
    setActiveItemId(id);
    setAsideMode(asideModes.EDIT);
  };

  const onSave = async record => {
    setSaving(true);
    const { id, header, display_mode, paragraph_button_label } = record;
    const res = await APIClient.put(
      `/campaign_showcase/${id}`,
      { campaign_id, header, display_mode, paragraph_button_label },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    setSaving(false);

    if (error) notify(errorMessage, "warning");
    else notify("Config updated successfully");
    fetchShowcase();
  };

  return (
    <div className={classes.container}>
      <div className={classes.subHeader}>Config</div>
      <EditView {...editProps} saving={saving}>
        <SimpleForm
          redirect={null}
          toolbar={
            <Toolbar>
              <SaveButton label="save" redirect={null} />
            </Toolbar>
          }
        >
          <SelectInput
            source="display_mode"
            label="Display Mode"
            choices={["text-only", "with-image"].map(m => {
              return { id: m, name: m };
            })}
            translateChoice={false}
          />
          <TextInput source="header" />
          <TextInput
            source="paragraph_button_label"
            label="Paragraph Button Label"
          />
        </SimpleForm>
      </EditView>

      <div className={classes.subHeader}>Showcase Items</div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        actions={<Actions />}
        empty={false}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" />
          <TextField source="line_1" />
          <TextField source="line_2" />
          <TextField source="line_3" />
        </Datagrid>
      </ListView>

      <Drawer open={Boolean(asideMode)} anchor="right">
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            <div>
              {asideMode === asideModes.CREATE ? "Add an Item" : "Edit Item"}
            </div>
            <IconButton
              onClick={() => setAsideMode(asideModes.CLOSED)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>

          {asideMode === asideModes.CREATE && (
            <CreateShowcaseItem
              basePath={basePath}
              onSuccess={onSuccess}
              campaign_id={campaign_id}
            />
          )}

          {asideMode === asideModes.EDIT && (
            <EditShowcaseItem
              basePath={basePath}
              onSuccess={onSuccess}
              activeItemId={activeItemId}
            />
          )}
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: { marginBottom: 40 },
  subHeader: {
    fontSize: "18px",
    fontWeight: 600,
    paddingTop: 24,
    textAlign: "center",
    textTransform: "uppercase",
  },
  drawer: {
    padding: 20,
    width: "100%",
    minWidth: 350,
    maxWidth: "100%",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
}));
