import { makeStyles } from "@material-ui/core";
import { Form, useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { APIRes, GenericObject, GuidedSetupScreen } from "../../../types";
import { sharedStyles } from "../shared.styles";
import { EventsSVG } from "../SVGs/EventsSVG";
import { Fragment, useEffect, useMemo, useState } from "react";
import { APIAuthClient } from "../../../lib";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, setToast } from "../../../state";
import { EventGroup } from "../components/EventGroup";
import { CircularLoader } from "../../../components";

export function Events(props: GuidedSetupScreen) {
  const { activeScreen, setDisableNext, handleNext, setSubmitting } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const campaignId = useSelector(getCampaignId);
  const [fetching, setFetching] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [refetchData, setRefetchData] = useState(false);

  useEffect(() => {
    if (activeScreen === "EVENTS") {
      setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]);

  useEffect(() => {
    if (campaignId) fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (activeScreen === "EVENTS" && refetchData) fetchEventData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]); // DON'T add refetchData to dependencies or this will run unwontedly (this should only run if they made changes and came back to this screen we want to fetch updated info)

  const fetchEventData = async () => {
    setFetching(true);
    setRefetchData(false);
    const url = `/campaign_event_groups/campaign_options_and_volunteer_selections?campaign_id=${campaignId}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    setInitialValues({ eventGroups: data });
    setFetching(false);
  };

  const submit = useMemo(
    () => async ({ eventGroups }) => {
      setSubmitting(true);
      setRefetchData(true);
      if (!Array.isArray(eventGroups) || !eventGroups.length) {
        return dispatch(
          setToast(
            "There was an error retrieving your Events, please refresh your page or try again later.",
          ),
        );
      }
      const update = {
        campaign_id: campaignId,
        eventGroups: formatEventGroupsUpdateBody(eventGroups),
      };

      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaign_event_groups/update_volunteer_selections`,
        update,
      );
      const { error, errorMessage } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      handleNext();
      setInitialValues({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (activeScreen !== "EVENTS") return <></>;
  return (
    <Fragment>
      <div className={sharedClasses.screenContainer}>
        <EventsSVG />
        <h1 className={sharedClasses.screenHeader}>
          Turn on event notifications
        </h1>
        <p className={sharedClasses.screenParagraph}>
          While you're here, keep your friends and family informed about your
          group's upcoming and future events.
        </p>
      </div>
      <CircularLoader show={fetching} />
      <div className={classes.eventsWrapper}>
        <Form
          mutators={{ ...arrayMutators }}
          onSubmit={submit}
          initialValues={initialValues}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.eventsContainer}>
                <EventsContent {...props} />
              </form>
            );
          }}
        />
      </div>
    </Fragment>
  );
}

function EventsContent(props: GuidedSetupScreen) {
  const { nextTriggeredBy, setNextTriggeredBy } = props;
  const form = useForm();

  useEffect(() => {
    if (nextTriggeredBy === "EVENTS") {
      form.submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  return (
    <FieldArray name="eventGroups">
      {({ fields: groups }) => {
        return groups.map((groupRow, index) => {
          return <EventGroup key={index} groupRow={groupRow} />;
        });
      }}
    </FieldArray>
  );
}

export function formatEventGroupsUpdateBody(eventGroups: GenericObject[]) {
  const update: GenericObject[] = [];
  eventGroups.forEach(group => {
    const {
      id: event_group_id,
      joinedGroup,
      notify_all_contacts,
      notify_all_supporters,
      send_notifications_for_all_events,
      contacts,
      event_group_events: EGE,
    } = group;
    if (!joinedGroup) return;
    const selectedEventIds: number[] = [];
    const selectedContactIds: number[] = [];
    if (!send_notifications_for_all_events) {
      (EGE as GenericObject[]).forEach(event => {
        const { joinedEvent, id: eventId } = event;
        if (joinedEvent) selectedEventIds.push(eventId as number);
      });
    }
    if (!notify_all_contacts) {
      (contacts as GenericObject[]).forEach(contact => {
        const { joinedContact, id: contactId } = contact;
        if (joinedContact) selectedContactIds.push(contactId as number);
      });
    }
    update.push({
      event_group_id,
      notify_all_contacts,
      notify_all_supporters,
      send_notifications_for_all_events,
      selectedEventIds,
      selectedContactIds,
    });
  });

  return update;
}

const styles = makeStyles(_theme => ({
  eventsWrapper: {
    marginTop: 4,
    backgroundColor: "#F7F7F7",
    display: "flex",
    flex: 1,
    width: "100%",
    maxWidth: "100vw",
    justifyContent: "center",
  },
  eventsContainer: {
    width: 500,
    maxWidth: "100vw",
    paddingTop: 24,
    paddingBottom: 8,
  },
}));
