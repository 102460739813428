import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  minLength,
  SelectInput,
  ImageInput,
  ImageField,
  Labeled,
  FunctionField,
  Toolbar,
  SaveButton,
  regex,
  NumberInput,
  ArrayInput,
  BooleanInput,
  SimpleFormIterator,
  FormDataConsumer,
  useNotify,
  AutocompleteArrayInput,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { CustomRichText } from "../../components";
import { useSelector } from "react-redux";
import { getIsOwner } from "../../customState";
import { styles } from "./Product.styles";
import {
  TWO_DECIMAL_REGEX,
  convertFileToBase64,
  productStructureTypes,
  productTypes,
} from "../../lib";

const validateName = [required(), minLength(2)];
const requiredInput = [required()];
const validMoney = regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only");
const requiredMoneyInput = [validMoney, required()];
const optionalMoneyInput = [validMoney];

export function ProductEdit(props) {
  const {
    closeAside,
    id,
    supplier_id,
    productSources,
    productWarehouses,
    profitCategories,
    productCategories,
  } = props;
  const notify = useNotify();
  const classes = styles();
  const basePath = `/product_suppliers/${supplier_id}/show`;
  const editProps = { id, basePath, resource: "products" };
  const isOwner = useSelector(getIsOwner);

  const ProductTitle = ({ record }) => {
    return <span>Editing - {record ? `${record.product_name}` : ""}</span>;
  };

  const ProductToolbar = props => (
    <Toolbar {...props} className={classes.editToolbar}>
      <SaveButton />
    </Toolbar>
  );

  const transformDataForSubmit = async values => {
    //remove properties that can't be edited (here)
    const {
      stock_qty: _rm1,
      remove_from_store: _rm2,
      structure_type: _rm3,
      is_deprecated: _rm4,
      product_parent_id: _rm5,
      categories: _rm6,
      inventory_threshold: _rm7,
      product_images: images,
      ...rest
    } = values;
    const product_images = [];
    if (Array.isArray(images)) {
      for (const i in images) {
        if (!images[i]) continue;
        const { id, remove, imageBase64 } = images[i];
        let data = { id: id ? id : null, remove };
        if (imageBase64) {
          const imageB64 = await convertFileToBase64(
            imageBase64,
            "imageBase64",
          ).catch(console.error);
          data = { ...data, ...imageB64 };
        }
        product_images.push(data);
      }
    }

    return { ...rest, product_images };
  };

  return (
    <Edit
      title={<ProductTitle />}
      {...editProps}
      redirect="show"
      undoable={false}
      transform={transformDataForSubmit}
      onSuccess={() => {
        notify(`Product updated successfully`);
        closeAside();
      }}
      className={classes.editForm}
    >
      <SimpleForm toolbar={<ProductToolbar />} submitOnEnter={false}>
        <TextInput source="id" disabled />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const isVariant =
              formData.structure_type === productStructureTypes.VARIANT;
            if (isVariant) {
              return (
                <Labeled label="Categories" className={classes.roCategories}>
                  <ArrayField source="categories">
                    <SingleFieldList linkType={false}>
                      <ChipField source="name" />
                    </SingleFieldList>
                  </ArrayField>
                </Labeled>
              );
            }
            return (
              <AutocompleteArrayInput
                {...rest}
                label="Categories"
                source="categoryIds"
                choices={productCategories}
                translateChoice={false}
                fullWidth
                validate={requiredInput}
              />
            );
          }}
        </FormDataConsumer>
        <SelectInput
          source="source_id"
          label="Source"
          choices={productSources}
          translateChoice={false}
          resettable
        />
        <TextInput source="source_product_id" label="Source Product ID" />
        <TextInput
          source="source_price"
          type="number"
          validate={optionalMoneyInput}
          label="Source Product Price"
        />
        <SelectInput
          source="warehouse_id"
          label="Warehouse"
          choices={productWarehouses}
          translateChoice={false}
          resettable
        />
        <SelectInput
          source="profit_category_id"
          label="Profit Category"
          choices={profitCategories}
          translateChoice={false}
          validate={requiredInput}
        />
        <TextInput source="sku" validate={validateName} />
        <TextInput source="product_name" validate={validateName} />
        <TextInput source="product_type" label="Product Type" disabled />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const product_type = formData.product_type;
            if (product_type !== productTypes.DIGITAL) {
              return <></>;
            }
            return (
              <Fragment>
                <BooleanInput
                  {...rest}
                  source="is_discount_card"
                  label="Is Discount Card"
                  disabled
                />
                <div />
                <BooleanInput
                  {...rest}
                  source="is_event_ticket"
                  label="Is Event Ticket"
                  disabled
                />
              </Fragment>
            );
          }}
        </FormDataConsumer>
        <TextInput
          source="product_price"
          type="number"
          label="Product Price"
          validate={requiredMoneyInput}
        />
        <NumberInput
          source="weight_ounces"
          label="Weight Ounces (integers only)"
          validate={requiredInput}
        />
        <Fragment>
          <div className={classes.warning}>
            This field is used to exclude this product from Fulfillment in
            Instaraise, to edit please contact support
          </div>
        </Fragment>
        <BooleanInput
          label="Externally Fulfilled"
          source="externally_fulfilled"
          disabled
        />
        <Fragment>
          <div className={classes.warning}>
            {`Use this field to exclude this product from being charged for
            Shipping (this will also affect all Distributors using this Product)`}
          </div>
        </Fragment>
        <BooleanInput label="No Shipping Fees" source="no_shipping_fees" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const structure_type = formData.structure_type;
            const isVariant = structure_type === productStructureTypes.VARIANT;
            return (
              <Fragment>
                <BooleanInput
                  {...rest}
                  label="Ship To School Only"
                  source="ship_to_school_only"
                  disabled={isVariant}
                />
                <BooleanInput
                  {...rest}
                  label="Ship To Home Only"
                  source="ship_to_home_only"
                  disabled={isVariant}
                />
              </Fragment>
            );
          }}
        </FormDataConsumer>
        <BooleanInput label="Can Personalize" source="can_personalize" />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const can_personalize = formData.can_personalize;
            if (!can_personalize) {
              formData.personalize_max_chars = null;
              return <></>;
            }
            return (
              <NumberInput
                {...rest}
                source="personalize_max_chars"
                label="Personalize Max Characters"
              />
            );
          }}
        </FormDataConsumer>

        <TextInput source="taxjar_tax_code" />
        <Fragment>
          <div className={classes.warning}>
            {`This field is for Tax reports only, and is not used for calculating
            tax on online sales (Taxjar)`}
          </div>
        </Fragment>
        <BooleanInput
          label="Manual Reporting Tax Exempt"
          source="manual_reporting_tax_exempt"
          className={classes.fitContent}
        />
        <Fragment>
          <div className={classes.warning}>
            This field is a DOLLAR amount (NOT %) and will override campaign's
            profit percentage
          </div>
        </Fragment>
        <TextInput
          source="profit_amount"
          type="number"
          label="Custom Profit Dollar Amount"
          validate={optionalMoneyInput}
        />
        {isOwner && (
          <NumberInput
            source="digital_content_id"
            label="Digital Content Id"
            step={1}
          />
        )}
        {isOwner && (
          <NumberInput
            source="digital_content_code_qty"
            label="Digital Content code(s) qty"
            step={1}
          />
        )}
        <CustomRichText
          source="product_description"
          label="Product Description"
        />
        <Labeled label="Current Main Image" formClassName={classes.imageRow}>
          <ImageField source="image" className={classes.image} />
        </Labeled>
        <ImageInput
          source="imageBase64"
          label="Main Image"
          accept="image/*"
          multiple={false}
          formClassName={classes.imageRow}
        >
          <ImageField source="src" title="title" className={classes.image} />
        </ImageInput>
        <FunctionField addLabel={false} render={() => ""} />
        <Labeled
          label="Current Thumbnail Image"
          formClassName={classes.imageRow}
        >
          <ImageField source="thumbnail_image" className={classes.image} />
        </Labeled>
        <ImageInput
          source="thumbnail_imageBase64"
          label="Thumbnail Image"
          accept="image/*"
          multiple={false}
          formClassName={classes.imageRow}
        >
          <ImageField source="src" title="title" className={classes.image} />
        </ImageInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const product_images = formData.product_images;
            const disableAdd =
              Array.isArray(product_images) && product_images.length >= 3;
            return (
              <ArrayInput
                {...rest}
                fullWidth={false}
                source="product_images"
                label="Additional Images"
                className={classes.productImageArr}
              >
                <SimpleFormIterator
                  disableRemove={record => {
                    return Boolean(record.id);
                  }}
                  disableAdd={disableAdd}
                  disableReordering
                  TransitionProps={{ enter: false, exit: false }}
                >
                  <FormDataConsumer>
                    {({ scopedFormData, getSource, id }) => {
                      const newRecord = !scopedFormData || !scopedFormData.id;
                      return (
                        <div className={classes.productImageGroup} key={id}>
                          {!newRecord && (
                            <Labeled
                              label="Current Image"
                              className={classes.productImageGroupItem}
                            >
                              {!newRecord ? (
                                <img
                                  src={scopedFormData.image}
                                  alt="product"
                                  className={classes.productImage}
                                />
                              ) : (
                                <span>N/A</span>
                              )}
                            </Labeled>
                          )}
                          <ImageInput
                            source={getSource("imageBase64")}
                            label="Image"
                            accept="image/*"
                            multiple={false}
                            className={classes.productImageGroupDropzone}
                          >
                            <ImageField
                              source="src"
                              title="title"
                              className={classes.image}
                            />
                          </ImageInput>
                          {!newRecord && (
                            <BooleanInput
                              label="remove"
                              source={getSource("remove")}
                            />
                          )}
                        </div>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            );
          }}
        </FormDataConsumer>
        <TextInput source="custom_tag" />
        <NumberInput source="items_per_case" />
        <TextInput source="bin" />
        <ArrayInput fullWidth={false} source="product_upc" label="UPC List">
          <SimpleFormIterator
            disableReordering
            TransitionProps={{ enter: false, exit: false }}
          >
            <TextInput source="upc" label="upc" validate={requiredInput} />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="active"
          label="Product Is Active (switch off to hide from all lists)"
          className={classes.fitContent}
        />
        <Fragment>
          <div className={classes.activeWarning}>
            Making this inactive will NOT remove it from `Product Groups` and
            Store
          </div>
        </Fragment>
      </SimpleForm>
    </Edit>
  );
}
