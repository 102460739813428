import { Button, IconButton, makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../../../hooks/ui";
import { GenericObject, VolunteerDetailModes } from "../../../../types";
import { useSelector } from "react-redux";
import { getVolunteerLabel } from "../../../../state";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/EditOutlined";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Fragment } from "react";
import { hexToRGBA } from "../../../../../lib";
import { RootState } from "../../../../types/state";
import { ImpersonateVolunteer } from "../../../../components";

type Props = {
  mode: VolunteerDetailModes;
  setMode: React.Dispatch<React.SetStateAction<VolunteerDetailModes>>;
  data: GenericObject;
  onClose: () => void;
  setShowDelete: (arg0: boolean) => void;
};
export function Top({ mode, data, setMode, onClose, setShowDelete }: Props) {
  const { id, first_name = "", last_name = "", slug } = data;
  const isMobile = useIsMobile();
  const classes = styles();
  const isView = mode === VolunteerDetailModes.view;
  const { volunteerLabelSing, volunteerLabelLCSingular } = useSelector(
    getVolunteerLabel,
  );
  const webpageLink = useSelector(
    ({ campaign }: RootState) => campaign.webpageLink,
  );

  return (
    <div className={classes.container}>
      {/* TOP ROW */}
      <div className={classes.topRow}>
        {/* LEFT */}
        {isView && isMobile && (
          <div className={classes.name}>
            {first_name} {last_name}
            {!isMobile && `${volunteerLabelSing} details`}
          </div>
        )}
        {isView && !isMobile && (
          <div className={classes.title}>{volunteerLabelSing} details</div>
        )}
        {!isView && isMobile && <div />}
        {!isView && !isMobile && (
          <div className={classes.title}>Edit {volunteerLabelLCSingular}</div>
        )}
        {/* RIGHT */}
        <div>
          {!isMobile && (
            <Fragment>
              {isView && (
                <Fragment>
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.edit}
                    startIcon={<EditIcon />}
                    onClick={() => setMode(VolunteerDetailModes.edit)}
                  >
                    EDIT
                  </Button>
                  <Button
                    variant="text"
                    className={classes.delete}
                    startIcon={<DeleteIcon />}
                    onClick={() => setShowDelete(true)}
                  >
                    DELETE
                  </Button>
                </Fragment>
              )}
              {!isView && (
                <Button
                  variant="text"
                  className={classes.link}
                  color="primary"
                  endIcon={<OpenInNewIcon />}
                  size={isMobile ? "small" : "medium"}
                  href={`${webpageLink}/${slug}`}
                  target="_blank"
                >
                  {volunteerLabelSing} page
                </Button>
              )}
            </Fragment>
          )}

          <IconButton
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
            className={classes.close}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      {/* BOTTOM ROW */}
      {!isMobile && isView && (
        <div className={classes.bottomRow}>
          <div className={classes.name}>
            {first_name} {last_name}
          </div>
        </div>
      )}
      {isMobile && (
        <div className={classes.bottomRow}>
          {/* LEFT */}
          {isView && (
            <ImpersonateVolunteer volunteerId={id as number} renderButton />
          )}
          {!isView && (
            <div className={classes.title}>Edit {volunteerLabelLCSingular}</div>
          )}
          {/* RIGHT */}
          <div>
            {isView && (
              <Fragment>
                <IconButton
                  onClick={() => setMode(VolunteerDetailModes.edit)}
                  color="primary"
                  className={classes.edit}
                >
                  <EditIcon />
                </IconButton>
              </Fragment>
            )}
            {/* {!isView && (
              <Button
                variant="text"
                className={classes.link}
                color="primary"
                endIcon={<OpenInNewIcon />}
                size={isMobile ? "small" : "medium"}
                href={`${webpageLink}/${slug}`}
                target="_blank"
              >
                {volunteerLabelSing} page
              </Button>
            )} */}
            <IconButton
              className={classes.delete}
              onClick={() => setShowDelete(true)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "16px 24px",
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 18,
      position: "sticky",
      top: 0,
      backgroundColor: "#FFFFFF",
      zIndex: 9,
      alignItems: "flex-end",
    },
  },
  topRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  bottomRow: {
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
  },
  close: {
    marginRight: -8,
    [theme.breakpoints.down("sm")]: {
      marginTop: -10,
      marginRight: -8,
    },
  },
  delete: {
    color: theme.palette.error.main,
    margin: "0 16px",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: hexToRGBA(theme.palette.error.main, 0.1),
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  link: {
    textTransform: "uppercase",
    marginRight: 16,
  },
  name: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "19px",
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0.15,
      lineHeight: "24px",
      paddingRight: 6,
      marginTop: 6,
    },
  },
  edit: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
}));
