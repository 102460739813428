import React, { Fragment } from "react";
import {
  SimpleShowLayout,
  TextField,
  FunctionField,
  TopToolbar,
  EditButton,
  CreateButton,
  ImageField,
  Show,
  BooleanField,
  useListController,
  ListView,
  TextInput,
  Filter,
  Datagrid,
} from "react-admin";
import Chip from "@material-ui/core/Chip";
import { useSelector } from "react-redux";
import { getIsOwner } from "../../customState";
import { styles } from "./OrgViews.styles";

const OrgShow = props => {
  const classes = styles();
  const isOwner = useSelector(state => getIsOwner(state));

  const OrgTitle = ({ record }) => {
    return <span>{record ? `Viewing - ${record.name}` : "Org - View"}</span>;
  };

  const OrgActions = ({ basePath, data }) => (
    <TopToolbar>
      <EditButton basePath={basePath} record={data} label="EDIT ORG" />
    </TopToolbar>
  );

  return (
    <Fragment>
      <Show {...props} actions={<OrgActions />} title={<OrgTitle />}>
        <SimpleShowLayout>
          <ImageField
            source="logo"
            label=""
            classes={{ image: classes.ShowImage }}
          />
          <TextField source="name" label="" className={classes.ShowName} />
          <FunctionField
            addLabel={false}
            render={record => {
              const {
                address = "",
                city = "",
                state = "",
                zip = "",
                contact_name = "",
                email = "",
                phone = "",
                id = "",
                slug = "",
              } = record;
              return (
                <div>
                  <div>{address}</div>
                  <div
                    className={classes.orgInfoLineBreak}
                  >{`${city}, ${state} ${zip}`}</div>
                  <div>
                    <span className={classes.orgInfoLabel}>Contact:</span>
                    {contact_name}
                  </div>
                  <div>
                    <span className={classes.orgInfoLabel}>Email:</span>
                    {email}
                  </div>
                  <div>
                    <span className={classes.orgInfoLabel}>Phone:</span>
                    {phone}
                  </div>
                  <div>
                    <span className={classes.orgInfoLabel}>ID:</span>
                    {id}
                  </div>
                  <div className={classes.orgInfoLineBreak}>
                    <span className={classes.orgInfoLabel}>Unique URL:</span>
                    {slug}
                  </div>
                </div>
              );
            }}
          />
          {isOwner && <BooleanField source="can_submit_pledges" />}
          <BooleanField
            source="allow_associated_volunteer_accounts"
            label="Allow Associated Student Accounts"
          />
          <BooleanField
            source="donation_notifications"
            label="Enable Donation/Sale Notifications"
          />
          <FunctionField
            label="Class Options"
            render={({ class_options }) => {
              if (Array.isArray(class_options)) {
                return <div>{class_options.join(", ")}</div>;
              }
              return "NONE";
            }}
          />
          <TextField source="notes" />
        </SimpleShowLayout>
      </Show>
      <Campaigns {...props} />
    </Fragment>
  );
};

export default OrgShow;

const Campaigns = ({ location, id }) => {
  const classes = styles();

  const Actions = () => (
    <TopToolbar>
      <CreateButton
        to={{
          pathname: `/campaigns/create`,
          state: { org_id: id },
        }}
        label="add new campaign"
      />
      <CreateButton
        to={{
          pathname: `/campaign_reservations/create`,
          state: { org_id: id },
        }}
        label="add campaign reservation"
      />
    </TopToolbar>
  );

  const controllerProps = useListController({
    sort: { field: "active", order: "DESC" },
    location,
    basePath: "",
    resource: "campaigns/org_campaigns",
    filter: { org_id: id },
    perPage: 25,
  });

  return (
    <div className={classes.campaignListWrapper}>
      <div className={classes.activeCampaignTitle}>Campaigns</div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        actions={<Actions />}
        empty={false}
        sort={{ field: "active", order: "DESC" }}
        filters={
          <Filter resource="campaigns/org_campaigns">
            <TextInput label="Search by name" source="q" alwaysOn />
          </Filter>
        }
      >
        <Datagrid rowClick={id => `/campaigns/${id}/show`}>
          <TextField source="name" />
          <FunctionField
            sortBy="active"
            label="Active"
            render={({ active }) => (active ? <Chip label={"active"} /> : null)}
          />
          <TextField source="campaign_slug" label="Unique Campaign URL" />
          <FunctionField
            sortBy="start_date"
            label="Start Date"
            render={record => new Date(record.start_date).toLocaleString()}
          />
          <FunctionField
            sortBy="end_date"
            label="End Date"
            render={record => new Date(record.end_date).toLocaleString()}
          />
          <TextField source="goal" />
          <TextField source="raised" />
        </Datagrid>
      </ListView>
    </div>
  );
};
