import React from "react";
import { Datagrid, List, TextField, Pagination } from "react-admin";

function ProductSupplierList(props) {
  const { staticContext: _rm, ...rest } = props;

  return (
    <div>
      <List
        {...rest}
        perPage={100}
        title="Product Suppliers"
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick="show">
          <TextField source="id" />
          <TextField source="supplier_name" />
        </Datagrid>
      </List>
    </div>
  );
}

export default ProductSupplierList;
