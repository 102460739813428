import React, { useEffect, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  SelectInput,
  NumberField,
  useNotify,
} from "react-admin";
import { APIClient, generateAuthHeader } from "../../lib";

const PCCFilter = ({ productSuppliers, ...props }) => {
  return (
    <Filter {...props}>
      <SelectInput
        source="supplier_id"
        label="Supplier"
        choices={productSuppliers}
        optionText="supplier_name"
        translateChoice={false}
        resettable
        alwaysOn
      />
    </Filter>
  );
};

export default function ProfitCategoryCompanyList(props) {
  const notify = useNotify();
  const [productSuppliers, setProductSuppliers] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/campaign_types/company_authorized", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setProductSuppliers(data.productSuppliers);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <List
        {...props}
        perPage={25}
        title="Company Profit Categories"
        bulkActionButtons={false}
        filters={<PCCFilter productSuppliers={productSuppliers} />}
        sort={{ field: "profit_category_id", order: "ASC" }}
      >
        <Datagrid rowClick="edit">
          <TextField source="supplier_name" label="Supplier" />
          <TextField source="profit_category_id" label="Profit Category ID" />
          <TextField
            source="profit_category_name"
            label="Profit Category Name"
          />
          <NumberField
            source="org_profit_online_percent"
            label="Org Profit Online %"
          />
          <NumberField
            source="org_profit_offline_percent"
            label="Org Profit Offline %"
          />
          <NumberField
            source="rep_commission_online_percent"
            label="Rep Commission Online %"
          />
          <NumberField
            source="rep_commission_offline_percent"
            label="Rep Commission Offline %"
          />
          <TextField source="id" label="Record ID" />
        </Datagrid>
      </List>
    </div>
  );
}
