import React, { useState } from "react";
import {
  Datagrid,
  TextField,
  Create,
  Filter,
  TextInput,
  SimpleForm,
  useListController,
  ListView,
  Pagination,
  TopToolbar,
  Notification,
  required,
  ExportButton,
  downloadCSV,
  BooleanInput,
  useNotify,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton } from "@material-ui/core";
import { styles } from "./DonationGroup.styles";

const requiredInput = [required()];

const GroupFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Group Name" source="q" alwaysOn />
    </Filter>
  );
};

export function DonationGroupList() {
  const classes = styles();
  const notify = useNotify();
  const [asideOpen, setAsideOpen] = useState(false);

  const controllerProps = useListController({
    sort: { field: "group_name", order: "ASC" },
    basePath: "/donation_groups",
    resource: "donation_groups",
    perPage: 25,
  });

  const onSuccess = () => {
    controllerProps.refetch();
    setAsideOpen(false);
    notify("Group added successfully");
  };

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        className={classes.addGroup}
        onClick={() => setAsideOpen(true)}
      >
        add group
      </Button>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues }}
        exporter={groupExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title="Donation Groups"
        {...controllerProps}
        bulkActionButtons={false}
        actions={<Actions />}
        filters={<GroupFilter />}
        empty={false}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick={"show"}>
          <TextField source="id" label="Group ID" />
          <TextField source="group_name" label="Group Name" />
        </Datagrid>
      </ListView>

      <Drawer open={asideOpen} anchor="right">
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            <div>Add a Group</div>
            <IconButton onClick={() => setAsideOpen(false)} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <Create
            resource="donation_groups"
            basePath="/donation_groups"
            title=" "
            onSuccess={onSuccess}
          >
            <SimpleForm>
              <TextInput source="group_name" validate={requiredInput} />
              <TextInput
                source="header"
                validate={requiredInput}
                multiline
                fullWidth
              />
              <TextInput
                source="taken_label"
                label="Taken label (ex: `Dedicated by`)"
                validate={requiredInput}
              />
              <TextInput source="tier_1_subheader" multiline fullWidth />
              <TextInput source="tier_2_subheader" multiline fullWidth />
              <TextInput source="tier_3_subheader" multiline fullWidth />
              <BooleanInput source="display_tier_1_avatars" />
              <BooleanInput source="display_tier_2_avatars" />
              <BooleanInput source="display_tier_3_avatars" />
            </SimpleForm>
          </Create>
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}

function groupExporter(groups) {
  const formattedGroups = groups.map(group => {
    const { id, group_name } = group;
    const exportObj = {
      ID: id,
      "Group Name": group_name,
    };
    return exportObj;
  });
  jsonExport(formattedGroups, (err, csv) => {
    downloadCSV(csv, "Donation Groups");
  });
}
