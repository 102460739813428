import { Fragment } from "react";
import { ListProps, SubmissionFilterFields } from "../../../types";
import { FiltersDrawer, ToggleFilter } from "../../../components";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function Filters({ onClose, listProps }: Props) {
  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter submissions"
    >
      <Fragment>
        <ToggleFilter
          header="Submission status"
          truthyLabel="Resolved"
          falsyLabel="Unresolved"
          truthyChipText="Resolved submissions"
          falsyChipText="Unresolved submissions"
          field={SubmissionFilterFields.resolved}
          listProps={listProps}
        />
      </Fragment>
    </FiltersDrawer>
  );
}
