import { makeStyles } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { formatDateOnlyString } from "../../../../lib";

export function OrderFormsDue() {
  const classes = styles();
  const OFDueDate = useSelector(
    ({ campaign }: RootState) => campaign.order_forms_to_org_due_date,
  );

  if (!OFDueDate) return <></>;
  return (
    <div className={classes.container}>
      <ReportProblemOutlinedIcon />

      <div className={classes.right}>
        <div className={classes.header}>Order forms due</div>
        <div>
          Your order forms are due on {formatDateOnlyString(OFDueDate)}.
        </div>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    borderRadius: 4,
    backgroundColor: "#FFF8E9",
    padding: "14px 16px",
    color: "#683800",
    display: "flex",
    marginBottom: 40,
  },
  right: {
    marginLeft: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 12,
    },
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "24px",
    paddingBottom: 4,
  },
}));
