import { GenericObject } from "../../types";
import { dashboardDataTypes } from "./actions";

type DashboardDataState = {
  campaignProgressStatsFetched: boolean;
  campaignProgressStats: GenericObject;
  campaignDonationStatsFetched: boolean;
  campaignDonationStats: GenericObject;
  volunteerCampaignParticipationStatsFetched: boolean;
  volunteerCampaignParticipationStats: GenericObject;
};

const initialState: DashboardDataState = {
  campaignProgressStatsFetched: false,
  campaignProgressStats: {},
  campaignDonationStatsFetched: false,
  campaignDonationStats: {},
  volunteerCampaignParticipationStatsFetched: false,
  volunteerCampaignParticipationStats: {},
};

export const dashboardDataReducers = (
  state = initialState,
  action: any,
): DashboardDataState => {
  switch (action.type) {
    case dashboardDataTypes.CAMPAIGN_PROGRESS_STATS_SET:
      return {
        ...state,
        campaignProgressStatsFetched: true,
        campaignProgressStats: action.data,
      };
    case dashboardDataTypes.CAMPAIGN_DONATION_STATS_SET:
      return {
        ...state,
        campaignDonationStatsFetched: true,
        campaignDonationStats: action.data,
      };
    case dashboardDataTypes.VOLUNTEER_CAMPAIGN_PARTICIPATION_STATS_SET:
      return {
        ...state,
        volunteerCampaignParticipationStatsFetched: true,
        volunteerCampaignParticipationStats: action.data,
      };
    default:
      return state;
  }
};
