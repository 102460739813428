import { Fragment, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { formatDateOnlyString } from "../../../../lib";
import { DiscountCardDetails } from "./DiscountCardDetails";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps<any>;
};
export function CampaignCardRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Id"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Template"
                fieldName="parentTemplateDiscountCardName"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Org"
                fieldName="orgName"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Card name"
                fieldName="card_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Campaign ID"
                fieldName="campaign_id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Expires on"
                fieldName="expires_on"
                params={params}
                setParams={setParams}
              />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Campaign cards per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);
  const {
    id,
    card_name,
    expires_on,
    orgName,
    parentTemplateDiscountCardName,
    campaign_id,
  } = row;

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>{parentTemplateDiscountCardName}</div>
            <div>{orgName}</div>
            <div>{card_name}</div>
            <div>{campaign_id}</div>

            <div>{formatDateOnlyString(expires_on)}</div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div>
              <div className={classes.campaignId}>
                Campaign ID: {campaign_id}
              </div>
              <div className={classes.name}>{card_name}</div>
              <div className={classes.accentTxt}>{orgName}</div>
              <div className={classes.accentTxt}>
                Exp. {formatDateOnlyString(expires_on)}
              </div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <DiscountCardDetails
          onClose={() => setShowEdit(false)}
          refreshFunc={refreshList}
          id={id}
          isCampaignCard
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "100px 1fr 1fr 1fr 136px 124px 52px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingTop: 4,
      paddingBottom: 3,
      letterSpacing: 0.1,
      lineHeight: "20px",
    },
  },
  campaignId: {
    fontSize: 12,
    letterSpacing: 0.09,
    lineHeight: "18px",
  },
  accentTxt: {
    fontSize: 12,
    letterSpacing: 0.09,
    lineHeight: "18px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary2,
  },
}));
