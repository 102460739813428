export enum Roles {
  SUPER_ADMIN = "super_admin",
  COMPANY = "company",
  REP = "rep",
  ORG = "org_admin",
  ORG_EMPLOYEE = "org_employee",
  VOLUNTEER = "org_volunteer",
  COMPANY_ADMIN = "company_admin",
  FULFILLER = "fulfiller",
  COMPANY_DATA_ENTRY = "company_data_entry",
  REP_MANAGER = "rep_manager",
}
