// cSpell:ignore appversion, appsource
import axios from "axios";
import { getAuthToken } from "./tokenUtils";

const {
  REACT_APP_API_BASE_URL,
  REACT_APP_API_UTIL_BASE_URL,
  REACT_APP_VERSION,
} = process.env;

/** Axios instance to use for API requests. */
const APIClient = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    appversion: REACT_APP_VERSION,
    appsource: "admin",
  },
});

APIClient.interceptors.response.use(
  success => successCB(success),
  e => errorCB(e),
);

/** Axios instance to use for API UTIL requests. (used for reports & PDF downloads) */
const APIUtilClient = axios.create({
  baseURL: REACT_APP_API_UTIL_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    appversion: REACT_APP_VERSION,
    appsource: "adminUtil",
  },
});

APIUtilClient.interceptors.response.use(
  success => successCB(success),
  e => errorCB(e),
);

function generateAuthHeader() {
  return { authorization: `Bearer ${getAuthToken()}` };
}

function errorCB(error) {
  if (!error || !error.response || !error.response.data) {
    return {
      error: true,
      errorMessage:
        "A connection error occurred, this may be related to  your network/internet/device",
      errorData: {},
    };
  }
  const {
    status,
    data: { code, message, errorData = {} },
  } = error.response;
  return { error: true, code, errorMessage: message, status, errorData };
}

function successCB(res) {
  return res && res.data ? { data: res.data } : { data: {} };
}

export { APIClient, APIUtilClient, generateAuthHeader };
