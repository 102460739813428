import React, { useState, Fragment } from "react";
import {
  Notification,
  downloadCSV,
  useNotify,
  FileInput,
  FileField,
  useRefresh,
} from "react-admin";
import {
  Dialog,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/GetApp";
import jsonExport from "jsonexport/dist";
import { APIClient, generateAuthHeader } from "../../lib";
import { styles } from "./Donation.styles";

export function Uploader(props) {
  const { setUploadModal, campaignId, forProduct } = props;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [failedRowsArr, setFailedRows] = useState(null);

  const onSubmit = async values => {
    const { csv } = values;
    if (!csv) return notify("Please upload A CSV file", "error");
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(csv.rawFile);
    reader.onload = async () => {
      const res = await APIClient.post(
        `/donations/csv_${forProduct ? "order" : "donation"}_upload`,
        { csv: reader.result, campaignId: Number(campaignId) },
        {
          headers: generateAuthHeader(),
        },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        setDisableSubmit(false);
        return notify(errorMessage, "warning");
      }

      refresh();

      if (!data.failedRows.length) {
        setUploadModal(false);
        return notify(
          `your ${
            forProduct ? "Orders" : "Donations"
          } have been successfully uploaded`,
          { type: "info" },
        );
      }
      setFailedRows(data.failedRows);
    };
    reader.onerror = console.error;
  };

  return (
    <Dialog open={true} onClose={() => setUploadModal(false)} maxWidth={false}>
      <div className={classes.importContainer}>
        <div className={classes.importClose}>
          <IconButton onClick={() => setUploadModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        {!failedRowsArr && (
          <div>
            <div className={classes.importSubHeader}>Step 1.</div>
            <div className={classes.downloadSection}>
              <div className={classes.importTxt}>Download the CSV template</div>
              <div>
                <Button
                  className={classes.importButtons}
                  onClick={() => exportTemplate(forProduct ? true : false)}
                >
                  <DownloadIcon /> &nbsp;&nbsp;&nbsp;download
                </Button>
              </div>
            </div>

            <div className={classes.importSubHeader}>Step 2.</div>
            <div className={classes.importTxt}>
              {`Add your ${
                forProduct ? "Orders" : "Donations"
              } and save/export it as a CSV.`}
            </div>
            <div className={classes.importTxt}>
              Make sure to use the headers of the columns exactly how they
              appear in the download file.
            </div>
            <ul>
              <li>
                Fill in the row_number so if any of them aren't successful, we
                can send you back which ones failed.
              </li>
              {forProduct && (
                <Fragment>
                  <li>
                    row_number, first_name, last_name, email, billing_address,
                    billing_city, billing_state, billing_zip, and at least one
                    product(SKU) with it's quantity are required fields.
                  </li>
                  <li>
                    If the order is using the "ship to school" option set
                    "ship_to_school" to Yes.
                  </li>
                  <li>
                    If the order is "ship to home" AND their shipping address is
                    different then their billing address fill in the shipping
                    fields (all 4 are required).
                  </li>
                  <li>Every product(SKU) entered must have a QTY filled in</li>
                  <li>You may enter up to 12 different products.</li>
                  <li>
                    If you would like to credit a Fundraiser for an order add
                    their ID to the fundraiser_ID field (their ID can be found
                    in the Fundraisers tab).
                  </li>
                </Fragment>
              )}
              {!forProduct && (
                <Fragment>
                  <li>
                    row_number, first_name, last_name, email, address, city,
                    state, zip, payment_type, amount, are required fields.
                  </li>
                  <li>
                    payment_type must be one of the following choices: "cc"
                    (Credit Card), "check", "cash", "pledge"
                  </li>
                  {/* <li>
                    if you would like us to charge their card: payment_type must
                    be "cc" and all of the following fields must be filled in
                    "cc_number", "exp_month" (2 digits), "exp_year" (2 digits),
                    "cvv" (3 or 4 digits)
                  </li> */}
                  <li>
                    If you would like to credit a Fundraiser for a donation add
                    their ID to the fundraiser_ID field (their ID can be found
                    in the Students tab).
                  </li>
                  <li>
                    If you want the donors name to be shown, set
                    'display_donor_name' to "yes" (lowercase), if not leave
                    blank.
                  </li>
                </Fragment>
              )}
            </ul>

            <div className={classes.importSubHeader}>Step 3.</div>
            <div className={classes.importTxt}>Upload your CSV file</div>

            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <div className={classes.fileDrop}>
                      <FileInput
                        source="csv"
                        label="File Upload"
                        accept={
                          "application/vnd.ms-excel, text/csv, text/plain"
                        }
                        multiple={false}
                      >
                        <FileField source="src" title="title" />
                      </FileInput>
                    </div>

                    <Button
                      type="submit"
                      disabled={disableSubmit}
                      className={classes.importButtons}
                    >
                      {disableSubmit && (
                        <CircularProgress
                          size={20}
                          className={classes.progress}
                        />
                      )}
                      {!disableSubmit && (
                        <div className={classes.saveBtnContent}>
                          <SaveIcon />
                          &nbsp;&nbsp;&nbsp;upload
                        </div>
                      )}
                    </Button>
                  </form>
                );
              }}
            />
          </div>
        )}
        {failedRowsArr && (
          <div>
            <div className={classes.importSubHeader}>
              {`Some of your ${
                forProduct ? "Orders" : "Donations"
              } failed to upload successfully`}
            </div>
            <table className={classes.failedOrderTable}>
              <thead>
                <tr>
                  <th className={classes.failedOrderTh}>Row Number</th>
                  <th className={classes.failedOrderTh}>Message</th>
                </tr>
              </thead>
              <tbody>
                {failedRowsArr.map(o => {
                  const { row_number, msg } = o;
                  return (
                    <tr key={row_number}>
                      <td className={classes.failedOrderCell}>{row_number}</td>
                      <td className={classes.failedOrderCell}>{msg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Button
              className={classes.importButtons}
              onClick={() => setUploadModal(false)}
            >
              OK
            </Button>
          </div>
        )}
      </div>
      <Notification />
    </Dialog>
  );
}

function exportTemplate(forProduct) {
  let template;
  if (forProduct) {
    template = {
      row_number: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone: undefined,
      billing_address: undefined,
      billing_apt: undefined,
      billing_city: undefined,
      billing_state: undefined,
      billing_zip: undefined,
      ship_to_school: undefined,
      shipping_address: undefined,
      shipping_apt: undefined,
      shipping_city: undefined,
      shipping_state: undefined,
      shipping_zip: undefined,
      fundraiser_ID: undefined,
      product_SKU_1: undefined,
      product_QTY_1: undefined,
      product_SKU_2: undefined,
      product_QTY_2: undefined,
      product_SKU_3: undefined,
      product_QTY_3: undefined,
      product_SKU_4: undefined,
      product_QTY_4: undefined,
      product_SKU_5: undefined,
      product_QTY_5: undefined,
      product_SKU_6: undefined,
      product_QTY_6: undefined,
      product_SKU_7: undefined,
      product_QTY_7: undefined,
      product_SKU_8: undefined,
      product_QTY_8: undefined,
      product_SKU_9: undefined,
      product_QTY_9: undefined,
      product_SKU_10: undefined,
      product_QTY_10: undefined,
      product_SKU_11: undefined,
      product_QTY_11: undefined,
      product_SKU_12: undefined,
      product_QTY_12: undefined,
    };
  } else {
    template = {
      row_number: undefined,
      external_id: undefined,
      title: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone: undefined,
      address: undefined,
      apt: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      payment_type: undefined,
      amount: undefined,
      // cc_number: undefined,
      // exp_month: undefined,
      // exp_year: undefined,
      // cvv: undefined,
      fundraiser_ID: undefined,
      comments: undefined,
      display_donor_name: undefined,
    };
  }
  jsonExport([template], (err, csv) => {
    downloadCSV(csv, `${forProduct ? "Order" : "Donation"} Upload Template`);
  });
}
