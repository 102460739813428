import { Dialog, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { getAuthJwt } from "../../../lib";
import { getIsProductCampaign, setToast } from "../../../state";
import { ToastTypes } from "../../../types";

type Props = {
  onClose: () => void;
};
export function AddDonation({ onClose }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isProduct = useSelector(getIsProductCampaign);
  const mainSiteBaseUrl = process.env.REACT_APP_DEFAULT_FRONTEND_URL;
  const [iframeSrc, setIframeSrc] = useState("");
  const { orgSlug, campaignSlug, orgEmail } = useSelector(
    (state: RootState) => state.campaign,
  );
  const { canSubmitPledges } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let s = `${mainSiteBaseUrl}/admin/${orgSlug}/${campaignSlug}?iframe=true&token=${getAuthJwt()}`;
    if (canSubmitPledges) s = `${s}&canSubmitPledges=true`;
    if (orgEmail) s = `${s}&orgEmail=${encodeURIComponent(orgEmail)}`;
    setIframeSrc(s);
  }, [mainSiteBaseUrl, campaignSlug, orgSlug, canSubmitPledges, orgEmail]);

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      const { data, origin } = event;
      if (origin !== mainSiteBaseUrl || typeof data !== "string") return;
      const res = JSON.parse(data);
      if (res.success) {
        dispatch(
          setToast(
            `${isProduct ? "Order" : "Donation"} added`,
            ToastTypes.success,
          ),
        );
      }
    };

    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog fullScreen open={true}>
      <div className={classes.container}>
        <div className={classes.close}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <iframe
          className={classes.iframe}
          title="form"
          src={iframeSrc}
        ></iframe>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100vh",
    maxHeight: "100vh",
    overflow: "hidden",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  close: {
    width: "100%",
    height: 48,
    padding: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "sticky",
    backgroundColor: "#FFFFFF",
    top: 0,
    cursor: "pointer",
    zIndex: 999,
    color: theme.palette.text.secondary2,
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },
  iframe: {
    border: "none",
    padding: 0,
    paddingBottom: 16,
    height: "calc(100vh - 48px)",
    width: "100%",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
