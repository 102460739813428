import { Fragment, useEffect, useState } from "react";
import FileIcon from "@material-ui/icons/InsertDriveFileOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, setToast } from "../state";
import { APIAuthClient } from "../lib";
import {
  APIRes,
  BOTTOM_BAR_HEIGHT,
  GenericAutocomplete,
  TOP_BAR_HEIGHT_DESKTOP,
  TOP_BAR_HEIGHT_MOBILE,
} from "../types";
import { CircularProgress, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useIsMobile, useSetTopBarTitle } from "../hooks/ui";
import classNames from "classnames";
import { CircularLoader } from "../components";
type Material = {
  id: number;
  link: null | string;
  name: string;
  needsLinkGeneration: boolean;
};
type CategorizedMaterials = {
  category_id: number;
  category_name: string;
  materials: Material[];
}[];

export const MediaCenter = () => {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const campaign_id = useSelector(getCampaignId);
  const [loading, setLoading] = useState(true);
  const [categoryId, setCategoryId] = useState<null | number>(null);
  const [categories, setCategories] = useState<GenericAutocomplete>([]);
  const [categorizedMaterials, setCategorizedMaterials] = useState<
    CategorizedMaterials
  >([]);

  useEffect(() => {
    const fetch = async () => {
      const url = `/material_bundles/campaign_bundle?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      const { categories, categorizedMaterials } = data;
      if (categories.length) setCategoryId(categories[0].id);
      setCategories(categories);
      setCategorizedMaterials(categorizedMaterials);
      setLoading(false);
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  useEffect(() => {
    const container = document.getElementById("medial-center-container");
    const section = document.getElementById(`media-section-${categoryId}`);
    if (!section || !container) return;

    if (isMobile) {
      const childTop = section.offsetTop - 121;
      container.scrollTo({ top: childTop, behavior: "smooth" });
    } else {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [categoryId, isMobile]);

  useSetTopBarTitle(<h1 className={classes.title}>Media Center</h1>);

  if (loading)
    return (
      <div className={classes.loader}>
        <CircularLoader show />
      </div>
    );
  return (
    <div className={classes.container} id="medial-center-container">
      <div className={classes.tabsWrapper}>
        {isMobile && <h1 className={classes.title}>Media Center</h1>}
        <Tabs
          value={categoryId}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, newValue) => setCategoryId(newValue)}
          classes={{ root: classes.tabs }}
          orientation={isMobile ? "horizontal" : "vertical"}
          variant="scrollable"
        >
          {categories.map(({ id, name }) => (
            <Tab
              key={id}
              value={id}
              label={name}
              className={classes.tab}
              classes={{ wrapper: classes.tabWrapper }}
            />
          ))}
        </Tabs>
      </div>

      <div className={classes.content}>
        {categorizedMaterials.map((cm, i) => {
          const { category_id, category_name, materials } = cm;
          const last = categorizedMaterials.length === i + 1;
          return (
            <div
              key={category_id}
              id={`media-section-${category_id}`}
              className={classNames(
                classes.section,
                last && classes.lastSection,
              )}
            >
              <div className={classes.sectionHeader}>{category_name}</div>
              <div className={classes.cards}>
                {materials.map(material => (
                  <Card key={material.id} material={material} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

function Card({ material }: { material: Material }) {
  const { id, name, link, needsLinkGeneration } = material;
  const classes = styles();
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);
  const [generating, setGenerating] = useState(false);

  const onClick = () => {
    if (!needsLinkGeneration) window.open(link as string, "_blank");
    else generateMaterialLink(id);
  };

  const generateMaterialLink = async (id: number) => {
    setGenerating(true);
    const post = { material_id: id, campaign_id };
    const url = "/material_campaign_copies/generate";
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage, data } = res;
    if (error) dispatch(setToast(errorMessage));
    else window.open(data.link as string, "_blank");
    setGenerating(false);
  };

  return (
    <div
      key={id}
      className={classes.card}
      onClick={generating ? undefined : onClick}
    >
      <FileIcon className={classes.icon} />
      <div className={classes.name}>{name}</div>
      {generating && (
        <Fragment>
          <div className={classes.cardOverlay} />
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        </Fragment>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  loader: {
    marginTop: 50,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  container: {
    paddingLeft: 24,
    display: "flex",
    position: "relative",
    maxHeight: `calc(100vh - ${TOP_BAR_HEIGHT_DESKTOP}px)`,
    minHeight: `calc(100vh - ${TOP_BAR_HEIGHT_DESKTOP}px)`,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      flexDirection: "column",
      maxHeight: `calc(100vh - ${TOP_BAR_HEIGHT_MOBILE + BOTTOM_BAR_HEIGHT}px)`,
      minHeight: "unset",
    },
  },
  tabsWrapper: {
    top: 0,
    position: "sticky",
    zIndex: 9,
    backgroundColor: "#F7F7F7",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 0,
    },
  },
  tabs: {
    borderRight: "1px solid #DBDEEE",
    width: "fit-content",
    minWidth: 200,
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginRight: -16,
      marginLeft: -16,
      borderRight: "none",
      borderBottom: "1px solid #DBDEEE",
      width: "100vw",
    },
  },
  tab: {
    maxWidth: 250,
    minWidth: "100%",
    paddingLeft: 24,
    paddingRight: 24,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 18,
      paddingRight: 18,
      maxWidth: "fit-content",
      minWidth: "fit-content",
    },
  },
  tabWrapper: {
    alignItems: "flex-start",
  },
  content: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
    },
  },
  section: {
    borderBottom: "1px solid #DBDEEE",
    flex: 1,
    paddingLeft: 24,
    paddingBottom: 8,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
    },
  },
  lastSection: {
    borderBottom: "none",
    paddingBottom: 60,
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 16,
    },
  },
  sectionHeader: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.11,
    paddingBottom: 24,
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    maxWidth: 800,
  },
  card: {
    position: "relative",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    minHeight: 150,
    width: 172,
    marginRight: 16,
    marginBottom: 16,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EEF6FE",
      boxShadow: "0 1px 18px 0 rgba(0,0,0,0.12)",
    },
    "&:hover $icon": { color: theme.palette.primary.main },
    "&:hover $name": { color: theme.palette.text.primary },
    [theme.breakpoints.down("sm")]: {
      width: 160,
    },
  },
  cardOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    opacity: 0.7,
    backgroundColor: "#FFFFFF",
  },
  spinner: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    zIndex: 9999,
  },
  // don't change this className because it's used in it's parents hover effect
  icon: {
    color: theme.palette.text.secondary,
    marginBottom: 18,
    fontSize: 30,
  },
  // don't change this className because it's used in it's parents hover effect
  name: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "22px",
    textAlign: "center",
  },
}));
