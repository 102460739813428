import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, TextField, InputAdornment } from "@material-ui/core";
import { GoalSVG } from "../SVGs/GoalSVG";
import { APIRes, GuidedSetupScreen } from "../../../types";
import { APIAuthClient, sanitizeIntegersOnlyInput } from "../../../lib";
import { setToast, updateVolunteerSetupData } from "../../../state";
import { sharedStyles } from "../shared.styles";
import { Tooltip } from "../components/Tooltip";
import { RootState } from "../../../types/state";

export function Goal(props: GuidedSetupScreen) {
  const {
    activeScreen,
    setDisableNext,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setSubmitting,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const currGoal = useSelector((state: RootState) => state.volunteerSetup.goal);
  const { volunteerCampaignId, show_volunteer_item_progress } = useSelector(
    (state: RootState) => state.campaign,
  );
  const [goal, setGoal] = useState<string>("");

  useEffect(() => {
    if (activeScreen === "GOAL") {
      setGoal(currGoal ? `${currGoal}` : "");
    }
  }, [activeScreen, currGoal]);

  useEffect(() => {
    if (activeScreen === "GOAL") {
      if (!goal) setDisableNext(true);
      else setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen, goal]);

  useEffect(() => {
    if (nextTriggeredBy === "GOAL") {
      submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  const submit = useMemo(
    () => async () => {
      setSubmitting(true);
      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaigns/${volunteerCampaignId}`,
        { goal: Number(goal) },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      const _goal = data.goal;
      dispatch(updateVolunteerSetupData({ goal: _goal }));
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [goal, volunteerCampaignId],
  );

  if (activeScreen !== "GOAL") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <GoalSVG />
      <h1 className={sharedClasses.screenHeader}>
        Set a goal for your fundraiser
      </h1>
      <p className={sharedClasses.screenParagraph}>
        Your goal is the{" "}
        {show_volunteer_item_progress
          ? "number of items you hope to sell"
          : "amount you hope to raise"}{" "}
        and will appear on your personal page
      </p>

      <div className={classes.contentSpacer} />
      <div className={sharedClasses.tooltipWrapper}>
        <Tooltip
          text="Increase your goal, and aim high! Take the challenge!"
          bottom={10}
        />
      </div>

      <TextField
        value={goal}
        type="number"
        onChange={({ target }) =>
          setGoal(sanitizeIntegersOnlyInput(target.value))
        }
        InputProps={{
          classes: { input: classes.input },
          startAdornment: show_volunteer_item_progress ? (
            undefined
          ) : (
            <InputAdornment position="start">$</InputAdornment>
          ),
        }}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  contentSpacer: {
    paddingBottom: 42,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 24,
    },
  },
  input: {
    textAlign: "right",
  },
}));
