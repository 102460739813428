import { useState } from "react";
import { IconButton, Button, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { APIAuthClient } from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, setToast } from "../../../state";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { useIsMobile, useDrawerTransition } from "../../../hooks/ui";

type Props = {
  team_id: number;
  teamName: string;
  onClose: () => void;
  refreshList: () => void;
  setOpenTeamId: React.Dispatch<React.SetStateAction<number | null>>;
};
export function AddTeamToCampaign({
  onClose: _onClose,
  refreshList,
  team_id,
  teamName = "",
  setOpenTeamId,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    const res = await APIAuthClient.post<any, APIRes>("/team_campaigns", {
      campaign_id,
      team_id,
    });
    const { error, errorMessage } = res;
    if (error) {
      setSubmitting(false);
      return dispatch(setToast(errorMessage));
    }
    dispatch(setToast("Team added to campaign", ToastTypes.success));
    onClose();
    refreshList();
    setTimeout(() => {
      setOpenTeamId(team_id);
    }, 300);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.mobileTop}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}

        <div className={classes.text}>
          Team {teamName} is currently inactive for this campaign. Would you
          like to add the team to this campaign?
        </div>

        <div className={classes.actions}>
          <Button
            variant="text"
            color={isMobile ? "primary" : "default"}
            disabled={submitting}
            className={classes.cancel}
            onClick={onClose}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            variant={isMobile ? "contained" : "text"}
            disabled={submitting}
            className={classes.submit}
            onClick={onSubmit}
          >
            ADD TO CAMPAIGN
            <ButtonSpinner show={submitting} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  mobileTop: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 2,
    },
  },
  text: {
    padding: "16px 24px",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 0 16px",
    },
  },
  actions: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "32px 8px 8px 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: "48px 8px 8px 8px",
    },
  },
  submit: {
    marginLeft: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
      marginBottom: 8,
    },
  },
  cancel: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      color: theme.palette.primary.main,
    },
  },
}));
