export function LinkedinBlueSmallSVG() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 18 18">
      <title>Linkedin</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-186, -265)" fill="#248BEF" fillRule="nonzero">
          <g transform="translate(31, 254)">
            <g transform="translate(144, 0)">
              <g transform="translate(11, 11)">
                <path d="M4.24282057,5.85365854 L0.586447718,5.85365854 C0.424172048,5.85365854 0.292682927,5.98320609 0.292682927,6.14295633 L0.292682927,17.7107022 C0.292682927,17.8705103 0.424172048,18 0.586447718,18 L4.24282057,18 C4.40509625,18 4.53658537,17.8705103 4.53658537,17.7107022 L4.53658537,6.14295633 C4.53658537,5.98320609 4.40509625,5.85365854 4.24282057,5.85365854 Z"></path>
                <path d="M2.41560472,0 C1.08362806,0 0,1.08297926 0,2.41413391 C0,3.74587707 1.08362806,4.82926829 2.41560472,4.82926829 C3.74652257,4.82926829 4.82926829,3.74581822 4.82926829,2.41413391 C4.82932712,1.08297926 3.74652257,0 2.41560472,0 Z"></path>
                <path d="M13.3738955,5.56097561 C11.9202777,5.56097561 10.8457229,6.18293262 10.1939648,6.88963091 L10.1939648,6.13801511 C10.1939648,5.97819907 10.0638109,5.84859817 9.90318309,5.84859817 L6.43712318,5.84859817 C6.27649536,5.84859817 6.14634146,5.97819907 6.14634146,6.13801511 L6.14634146,17.7105252 C6.14634146,17.8703991 6.27649536,18 6.43712318,18 L10.0484576,18 C10.2090855,18 10.3392394,17.8703991 10.3392394,17.7105252 L10.3392394,11.9848162 C10.3392394,10.0553892 10.8657869,9.3037155 12.2171077,9.3037155 C13.6888121,9.3037155 13.8057645,10.5087319 13.8057645,12.0840862 L13.8057645,17.7105831 C13.8057645,17.870457 13.9359184,18 14.0965462,18 L17.7092183,18 C17.8698461,18 18,17.870457 18,17.7105831 L18,11.3628592 C18,8.49386901 17.4503644,5.56097561 13.3738955,5.56097561 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
