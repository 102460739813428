import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, requiredField } from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RootState } from "../../../types/state";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddMaterial({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const materialCategoryAutocomplete = useSelector(
    ({ material }: RootState) => material.materialCategoryAutocomplete,
  );

  const onSubmit = async (values: any, _: any, complete: any) => {
    const post = { active: true, ...values };
    const url = `/materials`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Material added", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Add external material</h1>
                {isMobile && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className={classes.mainContent}>
                <Field
                  select
                  component={TextFieldWrapper}
                  name="material_category_id"
                  label="Category"
                  validate={requiredField}
                  className={classes.input}
                >
                  {materialCategoryAutocomplete.map(c => {
                    const { id, name } = c;
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    );
                  })}
                </Field>
                <Field
                  component={TextFieldWrapper}
                  name="name"
                  label="Name"
                  validate={requiredField}
                  className={classes.input}
                />
                <Field
                  component={TextFieldWrapper}
                  name="link"
                  label="Link"
                  validate={requiredField}
                  className={classes.input}
                />
              </div>
              <div className={classes.bottom}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
