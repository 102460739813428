import { useState } from "react";
import { useNotify } from "react-admin";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import {
  Button,
  Paper,
  FormControlLabel,
  RadioGroup,
  Radio,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import {
  APIClient,
  generateAuthHeader,
  integerOnly,
  requiredPositiveOrNegativeInteger,
} from "../../../lib";
import { TextFieldWrapper } from "../../../newApp/components";

export function EditProductInventory(props) {
  const {
    id,
    remove_from_store,
    is_back_ordered,
    sth_fulfillment_exclusion_flag,
    inventory_threshold,
    refetch,
  } = props;
  const notify = useNotify();
  const classes = styles();
  const [isDecrement, setIsDecrement] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const hasStockChange = formData => Boolean(formData.adjustmentQty);

  const resetForm = form => {
    setIsDecrement(false);
    form.change("adjustmentQty", null);
    form.change("notes", null);
  };

  const onSubmit = async (values, form) => {
    setShowConfirm(false);
    const { adjustmentQty, inventory_threshold, ...rest } = values;
    const update = {
      inventory_threshold: Number(inventory_threshold),
      ...rest,
    };
    if (adjustmentQty) {
      update.adjustmentQty = Number(adjustmentQty);
      update.adjustmentIsDecrement = isDecrement;
    }
    const res = await APIClient.put(
      `/inventory/products/${id}/update_inventory`,
      update,
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) notify(errorMessage, "warning");
    else notify("Inventory successfully updated", { type: "info" });
    refetch();
    setTimeout(() => resetForm(form), 10);
  };

  return (
    <div className={classes.container}>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          remove_from_store,
          is_back_ordered,
          sth_fulfillment_exclusion_flag,
          inventory_threshold: `${inventory_threshold}`,
        }}
        render={({
          handleSubmit,
          submitting,
          values,
          pristine,
          form,
          invalid,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper className={classes.paper}>
                <div className={classes.leftSection}>
                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        name="remove_from_store"
                        type="checkbox"
                        className={classes.checkbox}
                      />
                    }
                    label="Remove from store"
                    className={classes.rmFromStore}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        name="is_back_ordered"
                        type="checkbox"
                        className={classes.checkbox}
                      />
                    }
                    label="Back ordered"
                  />
                  <FormControlLabel
                    control={
                      <Field
                        component={Checkbox}
                        name="sth_fulfillment_exclusion_flag"
                        type="checkbox"
                        className={classes.checkbox}
                      />
                    }
                    label="STH fulfillment flag"
                  />
                  <Field
                    variant="standard"
                    component={TextFieldWrapper}
                    name="inventory_threshold"
                    label="Inventory threshold"
                    className={classes.threshold}
                    size="small"
                    helperText="Use negative number to oversell"
                    validate={requiredPositiveOrNegativeInteger}
                    type="number"
                  />
                </div>
                <div className={classes.rightSection}>
                  <div>
                    <RadioGroup
                      value={isDecrement}
                      onChange={({ target }) => {
                        const val = target.value;
                        setIsDecrement(val === "false" ? false : true);
                      }}
                      className={classes.radioGroup}
                      row
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio className={classes.radio} />}
                        label="Add"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio className={classes.radio} />}
                        className={classes.rightRadio}
                        label="Remove"
                      />
                    </RadioGroup>
                    <Field
                      variant="standard"
                      component={TextField}
                      name="adjustmentQty"
                      placeholder={`Stock qty to ${
                        isDecrement ? "remove" : "add"
                      }`}
                      type="number"
                      validate={integerOnly}
                      className={classes.input}
                      size="small"
                    />
                    <Field
                      variant="standard"
                      component={TextField}
                      name="notes"
                      placeholder="Stock adjustment notes"
                      multiline={true}
                      disabled={!hasStockChange(values)}
                      className={classes.input}
                      size="small"
                    />
                  </div>
                  <div className={classes.btnContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowConfirm(true)}
                      disabled={pristine || submitting || invalid}
                    >
                      save
                    </Button>
                  </div>
                </div>
              </Paper>
              {showConfirm && (
                <Dialog open={true}>
                  <DialogTitle>Are you sure?</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Are you sure you want to make make these inventory
                      changes.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowConfirm(false)}>
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => form.submit()}
                      disabled={pristine || submitting}
                    >
                      save
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  paper: {
    padding: 12,
    minWidth: 426,
    display: "flex",
  },
  leftSection: {
    width: 206,
    minWidth: 206,
    marginRight: 16,
    paddingRight: 16,
    borderRight: "1px solid lightgrey",
  },
  threshold: {
    marginTop: 6,
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 6,
  },
  input: { width: "100%", marginTop: 10 },
  rmFromStore: { width: 184 },
  radio: { paddingTop: 0, paddingBottom: 0, paddingRight: 4 },
  rightRadio: { marginRight: 0 },
  checkbox: { padding: 2 },
  radioGroup: { minWidth: 200 },
}));
