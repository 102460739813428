import React, { Fragment, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { printLabelModes } from "../../../lib";

export function PrintSTSLabels(props) {
  const {
    printLabelMode,
    setPrintLabelMode,
    volunteerData,
    closeDrawerAndRefresh,
  } = props;
  const classes = styles();
  const [labelArr, setLabelArr] = useState([]);
  const [labelCount, setLabelCount] = useState("");

  const print = () => {
    const iframe = document.getElementById("label-iframe");
    const template = document.getElementById("label-template");
    iframe.srcdoc = template.outerHTML.replace("display: none;", "");
    iframe.contentWindow.focus();
    setTimeout(() => {
      iframe.contentWindow.print();
      if (printLabelMode === printLabelModes.PRINT_AND_CLOSE) {
        closeDrawerAndRefresh();
      } else setPrintLabelMode(printLabelModes.CLOSED);
    }, 50);
  };

  const handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && labelArr.length) print();
  };

  const onLabelNumChange = e => {
    const num = e.target.value;
    // limit label count to 20 to avoid printing huge amount of labels by mistake (scanning the wrong barcode etc.)
    if (num > 20) {
      setLabelArr([]);
      setLabelCount("");
      return;
    }
    if (num && Number.isInteger(Number(num))) {
      setLabelArr(new Array(Number(num)).fill(""));
    } else {
      setLabelArr([]);
    }
    setLabelCount(num);
  };

  return (
    <Fragment>
      <Dialog open={Boolean(printLabelMode)}>
        <DialogTitle>Enter/Scan the number of labels to print</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              color="primary"
              autoFocus
              placeholder="Number of Labels"
              value={labelCount}
              onChange={onLabelNumChange}
              onKeyDown={handleKeyPress}
            />
          </div>
        </DialogContent>
      </Dialog>

      <iframe
        title="label"
        id="label-iframe"
        className={classes.iframe}
      ></iframe>
      <LabelTemplate
        labelArr={labelArr}
        volunteerData={volunteerData}
        labelCount={labelCount}
      />
    </Fragment>
  );
}

function LabelTemplate({ labelArr, labelCount, volunteerData }) {
  const {
    first_name,
    last_name,
    id: volunteer_id,
    campaign_id,
    class_name,
    phone,
    student_number,
    org_name,
  } = volunteerData;

  return (
    <div
      id="label-template"
      style={{
        width: "4in",
        boxSizing: "border-box",
        display: "none",
      }}
    >
      {labelArr.map((_, i) => {
        return (
          <div
            key={i}
            style={{
              width: "3.9in",
              height: "3.9in",
              padding: "0 24px",
              boxSizing: "border-box",
              margin: "12px 0",
              pageBreakAfter: "always",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 26,
                    lineHeight: "27px",
                    paddingBottom: 10,
                  }}
                >
                  {last_name}, {first_name}
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 25,
                    lineHeight: "26px",
                  }}
                >
                  {class_name ? class_name : ""}
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 26,
                    marginTop: 2,
                    marginBottom: 6,
                    letterSpacing: 1.4,
                  }}
                >
                  {phone}
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    lineHeight: "19px",
                  }}
                >
                  Seller ID: {volunteer_id}
                </div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    lineHeight: "19px",
                  }}
                >
                  Campaign ID: {campaign_id}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginLeft: 5,
                }}
              >
                <div>Student Delivery #</div>
                <div
                  style={{
                    width: 120,
                    height: 80,
                    fontWeight: 600,
                    fontSize: 62,
                    border: "1px solid #222222",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 2,
                  }}
                >
                  {student_number}
                </div>
                <div>
                  Box <span style={{ fontWeight: 600 }}>{i + 1}</span> of{" "}
                  <span style={{ fontWeight: 600 }}>{labelCount}</span>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 6 }}>Ship to:</div>
            <div
              style={{
                fontWeight: 600,
                fontSize: 24,
                lineHeight: "24px",
              }}
            >
              {org_name}
            </div>
          </div>
        );
      })}
    </div>
  );
}

const styles = makeStyles(_theme => ({
  iframe: {
    display: "none",
    width: "4in",
    boxSizing: "border-box",
    border: "none",
    padding: 0,
    margin: 0,
  },
}));
