import {
  IconButton,
  TextField,
  makeStyles,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { isPast, format } from "date-fns";
import { ResponsiveModal } from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import CloseIcon from "@material-ui/icons/Close";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToast } from "../../../state";
import { getUserTimezone } from "../../../../lib";
import { APIAuthClient, formatMoneyUnPadded } from "../../../lib";
import { APIRes } from "../../../types";
import { RootState } from "../../../types/state";
import { HourGlassSVG } from "../../../assets/HourGlassSVG";

type Data = { date: "string"; amount: number }[];
enum Ranges {
  sevenDays = "sevenDays",
  thirtyDays = "thirtyDays",
  all = "all",
}

type Props = {
  onClose: () => void;
};
export function DailyPerformance({ onClose: _onClose }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState<Data>([]);
  const { campaignId, start_date, end_date } = useSelector(
    (state: RootState) => state.campaign,
  );
  const started = Boolean(start_date && isPast(new Date(start_date)));
  const ended = Boolean(end_date && isPast(new Date(end_date)));

  useEffect(() => {
    const fetch = async () => {
      const timezone = getUserTimezone();
      const url = `/dashboards/campaign_daily_performance?campaign_id=${campaignId}&timezone=${timezone}`;
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      setAllData(data);
      setLoading(false);
    };
    if (campaignId && started) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, started]);

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div className={classes.title}>Daily performance</div>
          <IconButton onClick={onClose} className={classes.close}>
            <CloseIcon />
          </IconButton>
        </div>

        {started && loading && (
          <div className={classes.loader}>
            <CircularProgress color="primary" size={50} />
          </div>
        )}

        {!started && (
          <div className={classes.startingSoonContainer}>
            <HourGlassSVG />
            <div className={classes.startingSoon}>Campaign starting soon</div>
            <div className={classes.noData}>No data yet</div>
          </div>
        )}

        {!loading && started && (
          <GraphContent allData={allData} ended={ended} />
        )}
      </div>
    </ResponsiveModal>
  );
}

function GraphContent({ allData, ended }: { allData: Data; ended: boolean }) {
  const classes = styles();
  const isMobile = useIsMobile();
  const { start_date, end_date } = useSelector(
    (state: RootState) => state.campaign,
  );
  const [displayData, setDisplayData] = useState<Data>([]);
  const [range, setRange] = useState<Ranges>(
    ended ? Ranges.all : Ranges.sevenDays,
  );

  useEffect(() => {
    if (ended) setDisplayData(allData);
    else setDisplayData(allData.slice(-7));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended]);

  const onRangeChange = (range: Ranges) => {
    setRange(range);
    switch (range) {
      case Ranges.sevenDays:
        setDisplayData(allData.slice(-7));
        break;
      case Ranges.thirtyDays:
        setDisplayData(allData.slice(-30));
        break;
      case Ranges.all:
        setDisplayData(allData);
        break;
    }
  };

  return (
    <Fragment>
      {!ended && (
        <TextField
          className={classes.select}
          select
          value={range}
          onChange={({ target }) => onRangeChange(target.value as Ranges)}
        >
          <MenuItem value={Ranges.sevenDays}>Last 7 days</MenuItem>
          <MenuItem value={Ranges.thirtyDays}>Last 30 days</MenuItem>
          <MenuItem value={Ranges.all}>All time</MenuItem>
        </TextField>
      )}

      {ended && start_date && end_date && (
        <div className={classes.dateRange}>
          {format(new Date(start_date), "MMMM d, yyyy")} -{" "}
          {format(new Date(end_date), "MMMM d, yyyy")}
        </div>
      )}

      <div className={classes.chartContainer}>
        <ResponsiveContainer>
          <LineChart
            className={classes.chart}
            data={displayData}
            margin={{
              top: 8,
              right: isMobile ? 18 : 24,
              left: 6,
              bottom: 24,
            }}
          >
            <CartesianGrid stroke="#EAEBF3" />
            <XAxis
              dataKey="date"
              tickFormatter={d => d.slice(0, -5)}
              angle={-45}
              textAnchor="end"
            />
            <YAxis tickFormatter={yAxisTickFormatter} type="number" />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="amount"
              stroke="#FD7B6F"
              fill="#FD7B6F"
              connectNulls
              dot={{ r: displayData.length > 10 ? 2 : 6 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Fragment>
  );
}

function yAxisTickFormatter(num: any) {
  let tick = `${num}`;
  if (num > 999999) tick = `${(num / 1000000).toFixed(0)}m`;
  else if (num > 999) tick = `${(num / 1000).toFixed(0)}k`;
  return `$${tick}`;
}

function CustomTooltip(props) {
  const { active, payload, label } = props;
  const classes = styles();
  if (active && payload && payload.length) {
    return (
      <div className={classes.tooltip}>
        <div className={classes.topTtSection}>{label}</div>
        <div className={classes.bottomTttSection}>
          {formatMoneyUnPadded(payload[0].value)}
        </div>
      </div>
    );
  }

  return null;
}

const styles = makeStyles(theme => ({
  container: {
    width: 946,
    maxWidth: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    borderBottom: "1px solid #DBDEEE",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      borderBottom: "none",
      padding: 16,
      paddingTop: 24,
      marginBottom: 8,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  close: {
    [theme.breakpoints.down("sm")]: {
      marginTop: -12,
      marginRight: -4,
    },
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 200,
  },
  select: {
    width: 200,
    marginLeft: 24,
    marginBottom: 18,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
      marginRight: 16,
      width: "calc(100% - 32px)",
    },
  },
  dateRange: {
    marginLeft: 24,
    marginBottom: 18,
    fontSize: 16,
    letterSpacing: 0.12,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
    },
  },
  chartContainer: {
    width: 946,
    maxWidth: "100%",
    overflow: "hidden",
    flex: 1,
  },
  chart: {
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  startingSoonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid #EAEBF3",
    flex: 1,
    margin: 24,
    marginTop: 0,
  },
  startingSoon: {
    marginTop: 24,
    marginBottom: 8,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
  },
  noData: {
    color: theme.palette.text.secondary2,
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "20px",
  },
  tooltip: {
    height: 96,
    width: 190,
    borderRadius: 8,
    backgroundColor: "#FAFAFA",
    boxShadow:
      "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.2)",
  },
  topTtSection: {
    borderBottom: "1px solid #EAEBF3",
    fontSize: 16,
    letterSpacing: 0.15,
    height: "50%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
  },
  bottomTttSection: {
    height: "50%",
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
}));
