import { useEffect, useState } from "react";
import _isEqual from "lodash.isequal";
import _findIndex from "lodash.findindex";
import { makeStyles, TextField, Button } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { useField } from "react-final-form";
import { productStructureTypes } from "../../../../lib";
import { ResponsiveModal } from "../../../components";
import { ConfigurablePrizeSelection } from "./ConfigurablePrizeSelection";
import classNames from "classnames";

export function PrizeCard({
  tier_id,
  product,
  variantProducts,
  levelIsFullySelected,
  productField,
  setPickedLevelProducts,
}) {
  const classes = styles();
  const {
    id,
    image,
    product_name,
    structure_type,
    can_personalize,
    personalize_max_chars,
    prevIsPicked,
  } = product;
  const isPickedInput = useField(`${productField}.isPicked`).input;
  const isPicked = Boolean(isPickedInput.value);
  const activeProductIdInput = useField(`${productField}.activeProductId`)
    .input;
  const p13nTxtInput = useField(`${productField}.personalization_text`).input;
  const personalizationText = p13nTxtInput.value;
  const isConfProduct = structure_type === productStructureTypes.CONFIGURABLE;
  const [showConfigSelection, setShowConfigSelection] = useState(false);
  const [isPersonalize, setIsPersonalize] = useState(
    isConfProduct ? false : can_personalize,
  );
  const [personalizeMaxChars, setPersonalizeMaxChars] = useState(
    personalize_max_chars ? personalize_max_chars : 30,
  );

  // non configurable product initial pick
  useEffect(() => {
    if (isConfProduct) return;
    activeProductIdInput.onChange(id);
    if (prevIsPicked) isPickedInput.onChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfProduct, prevIsPicked, id]);

  // isPicked listener for level selected count
  useEffect(() => {
    setPickedLevelProducts((prev: any) => {
      if (isPicked) return { ...prev, [id]: id };
      const { [`${id}`]: _rm, ...rest } = prev;
      return rest;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPicked]);

  const toggleIsPicked = () => {
    if (isConfProduct) setShowConfigSelection(true);
    else isPickedInput.onChange(!isPickedInput.value);
  };

  const closeConfigSelection = () => setShowConfigSelection(false);

  const onPersonalizedTxtChange = (txt: string) => p13nTxtInput.onChange(txt);

  return (
    <div className={classes.card}>
      <div className={classes.imgContainer}>
        <img className={classes.img} src={image} alt="Prize" />
      </div>
      <div className={classes.productName}>{product_name}</div>
      <Button
        onClick={toggleIsPicked}
        color={isPicked ? "secondary" : "primary"}
        disabled={levelIsFullySelected && !isPicked}
        startIcon={isPicked ? <CheckIcon /> : null}
        className={classNames(classes.button, isPicked && classes.selectedBtn)}
      >
        {isPicked ? "Selected" : "Select"}
      </Button>

      {isConfProduct && (
        <ResponsiveModal
          isOpen={showConfigSelection}
          onClose={closeConfigSelection}
          keepMounted // DON'T REMOVE (this component takes care of setup we need right away)
        >
          <ConfigurablePrizeSelection
            {...{
              tier_id,
              product,
              variantProducts,
              productField,
              setIsPersonalize,
              setPersonalizeMaxChars,
              closeConfigSelection,
              showConfigSelection,
            }}
          />
        </ResponsiveModal>
      )}

      {isPersonalize && (
        <div>
          <TextField
            value={personalizationText}
            placeholder="Enter custom text"
            size="small"
            fullWidth
            className={classes.p13nInput}
            helperText={`${
              personalizationText ? personalizationText.length : 0
            } out of ${personalizeMaxChars} characters`}
            onChange={e => {
              onPersonalizedTxtChange(
                sanitizePersonalization(e.target.value, personalizeMaxChars),
              );
            }}
          />
        </div>
      )}
    </div>
  );
}

function sanitizePersonalization(value: string, max: number) {
  if (!value) return value;
  return value
    .replace(
      /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g,
      "",
    )
    .slice(0, max);
}

const styles = makeStyles(_theme => ({
  card: {
    width: 150,
    minWidth: 150,
    margin: "0 12px",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 24,
    paddingTop: 16,
  },
  imgContainer: {
    height: 150,
    width: 150,
    border: "1px solid #BDBDBD",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: 8,
  },
  productName: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "19px",
    marginTop: 8,
    marginBottom: 16,
  },
  p13nInput: {
    marginTop: 16,
    "& .MuiInputBase-root": {
      fontSize: 14,
    },
    "& .MuiFormHelperText-root": {
      fontSize: 11,
    },
  },
  button: {
    width: 81,
    minWidth: "fit-content",
  },
  selectedBtn: {
    width: 127,
  },
}));
