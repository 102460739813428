import { Field, FieldProps } from "react-final-form";
import { format } from "date-fns";
import { TextFieldWrapper } from "./TextFieldWrapper";
const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

export function DateTimeField(props: FieldProps<any, any>) {
  return (
    <Field
      {...props}
      format={formatDateTime}
      type="datetime-local"
      InputLabelProps={{ shrink: true }}
      component={TextFieldWrapper}
    />
  );
}

function formatDateTime(value: string | Date): string {
  if (!value) return "";
  if (typeof value === "string" && dateTimeRegex.test(value)) return value;
  return format(new Date(value), "yyyy-MM-dd'T'HH:mm");
}
