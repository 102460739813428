import React from "react";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

export const TextInput = props => {
  const { validation, name, label, type = "text" } = props;
  return (
    <Field
      name={name}
      variant="filled"
      label={label}
      component={TextField}
      validate={validation}
      fullWidth
      style={{ display: "block", marginBottom: "15px", width: "250px" }}
      type={type}
      InputLabelProps={{ shrink: true }}
    />
  );
};
