import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  TableHead,
} from "@material-ui/core";

export function CurrentVariants({ currentVariants }) {
  const classes = styles();

  return (
    <TableContainer component={Paper} className={classes.table} elevation={6}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Variant</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Sku</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentVariants.map((v, index) => {
            const { product_price, sku, product_name, variant } = v;
            return (
              <TableRow key={index}>
                <TableCell>{variant}</TableCell>
                <TableCell>{product_name}</TableCell>
                <TableCell>{sku}</TableCell>
                <TableCell>{`$${product_price}`}</TableCell>
              </TableRow>
            );
          })}
          {currentVariants.length < 1 && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                There are no current Variants
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const styles = makeStyles(_theme => ({
  table: { marginBottom: 24, overflowX: "visible" },
}));
