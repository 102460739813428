import { SearchFields } from "./list";

export const eventTicketSearchFields: SearchFields = [
  { label: "ID", field: "id", placeholder: "Ticket ID", isNumber: true },
  {
    label: "Customer name",
    field: "name",
    placeholder: "Customer name",
  },
  {
    label: "Customer email",
    field: "email",
    placeholder: "Customer email",
  },
  {
    label: "Order ID",
    field: "donation_id",
    placeholder: "Order ID",
    isNumber: true,
  },
  { label: "Ticket code", field: "code", placeholder: "Ticket code" },
];

export const eventTicketSortFields = [
  { field: "id", label: "Ticket ID" },
  { field: "event_name", label: "Event" },
  { field: "donation_id", label: "Order ID" },
  { field: "customerName", label: "Customer name" },
  { field: "customerEmail", label: "Customer email" },
  { field: "used", label: "Used" },
  { field: "used_date", label: "Used timestamp" },
];

export enum EventTicketFilterFields {
  used = "used",
  event_id = "event_id",
}
