import { useRef, useEffect, useCallback, Fragment } from "react";
import Quill from "quill";
import debounce from "lodash.debounce";
import { useField } from "react-final-form";
import { quillConfigOptions } from "../../types";
import { sanitizeQuillHtml } from "../../lib";
import { makeStyles } from "@material-ui/core";

type Props = {
  fieldName: string;
  currentValue?: string | null;
  msg?: string | null;
};
export function WYSIWYGField({ fieldName, currentValue, msg }: Props) {
  const quillDivRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);
  const field = useField(fieldName).input;
  const classes = styles();

  useEffect(() => {
    if (quillDivRef.current) {
      quillRef.current = new Quill(quillDivRef.current, quillConfigOptions);
    }
  }, []);

  const updateInput = () => {
    if (quillRef.current) {
      const value = sanitizeQuillHtml(quillRef.current.root.innerHTML);
      field.onChange(value);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(debounce(updateInput, 300), []);

  // this must be after Quill initialization
  useEffect(() => {
    if (quillRef.current) {
      quillRef.current.clipboard.dangerouslyPasteHTML(
        currentValue ? currentValue : "",
      );
      quillRef.current.on("text-change", onChange);
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off("text-change", onChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="quill">
        <div ref={quillDivRef} />
      </div>
      {msg && <div className={classes.msg}>{msg}</div>}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  msg: {
    fontSize: 12,
    letterSpacing: 0.4,
    paddingTop: 6,
    paddingLeft: 6,
    color: theme.palette.text.secondary,
    lineHeight: "15px",
  },
}));
