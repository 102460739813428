import React, { Fragment, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Button,
  IconButton,
  TextField as MuiTextField,
  makeStyles,
  Popover,
} from "@material-ui/core";
import { TextField } from "final-form-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import ListIcon from "@material-ui/icons/List";
import { useNotify, useRefresh, Notification } from "react-admin";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { APIClient, generateAuthHeader, required } from "../../../lib";
const dedicationTypeOptions = [
  "In memory of",
  "In honor of",
  "In appreciation of",
  "In celebration of",
  "With much gratitude for",
  "L'zchus Refuah Shleimah for",
];

export function DedicationOptions({ record }) {
  const { id, dedication_options } = record;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async ({ dedication_options = [] }) => {
    const res = await APIClient.put(
      `/campaigns/${id}/update_dedication_options`,
      { dedication_options },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    refresh();
    notify("info has been successfully updated");
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>DEDICATION OPTIONS</div>
      <Form
        onSubmit={onSubmit}
        initialValues={{ dedication_options }}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TableContainer component={Paper} className={classes.table}>
                <FieldArray name="dedication_options">
                  {({ fields }) => (
                    <Table size="small">
                      <TableBody>
                        {fields.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.leftCol}>
                              <IconButton
                                onClick={() => fields.remove(index)}
                                size="small"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </TableCell>
                            <TableCell className={classes.autocompleteCell}>
                              <Field
                                name={`${row}.label`}
                                validate={required}
                                className={classes.autocomplete}
                                render={({ input, meta, ...rest }) => {
                                  const showError =
                                    ((meta.submitError &&
                                      !meta.dirtySinceLastSubmit) ||
                                      meta.error) &&
                                    meta.touched;
                                  return (
                                    <Autocomplete
                                      fullWidth
                                      {...rest}
                                      {...input}
                                      freeSolo
                                      onChange={(_, val) => input.onChange(val)}
                                      onInputChange={(_, val) =>
                                        input.onChange(val)
                                      }
                                      options={dedicationTypeOptions}
                                      getOptionLabel={option => option}
                                      renderInput={params => (
                                        <MuiTextField
                                          variant="outlined"
                                          {...params}
                                          label="Select a dedication type or enter a custom one"
                                          helperText={
                                            showError
                                              ? meta.error || meta.submitError
                                              : undefined
                                          }
                                          error={showError}
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <FieldArray name={`${row}.sub_options`}>
                                {({ fields: subOptions }) => {
                                  return (
                                    <Fragment>
                                      <div>
                                        {subOptions.map((subOption, i2) => {
                                          return (
                                            <div
                                              key={i2}
                                              className={classes.subOption}
                                            >
                                              <Field
                                                className={
                                                  classes.subOptionInput
                                                }
                                                variant="standard"
                                                component={TextField}
                                                name={subOption}
                                                validate={required}
                                              />
                                              <IconButton
                                                onClick={() =>
                                                  subOptions.remove(i2)
                                                }
                                                size="small"
                                              >
                                                <RemoveIcon />
                                              </IconButton>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <Button
                                        color="primary"
                                        onClick={() => subOptions.push()}
                                        startIcon={<AddIcon />}
                                      >
                                        add sub-option
                                      </Button>
                                      <AddMultiSubOptions field={subOptions} />
                                    </Fragment>
                                  );
                                }}
                              </FieldArray>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Button
                              color="primary"
                              onClick={() => {
                                fields.push({
                                  label: "",
                                  sub_options: [],
                                });
                              }}
                              startIcon={<AddIcon />}
                            >
                              add option
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  )}
                </FieldArray>
              </TableContainer>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
              >
                save changes
              </Button>
            </form>
          );
        }}
      />
      <Notification />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: { marginBottom: 40 },
  header: {
    fontSize: 24,
    textAlign: "center",
    margin: 24,
    fontWeight: 600,
  },
  table: { marginBottom: 24, overflowX: "visible" },
  leftCol: { width: 30 },
  autocompleteCell: { width: 400 },
  autocomplete: { margin: "4px 0" },
  subOptionInput: { width: "100%" },
  subOption: { display: "flex" },
  popoverRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popover: {
    width: 800,
    maxWidth: "100%",
    padding: 24,
  },
  buttonContainer: {
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    width: 150,
  },
}));

function AddMultiSubOptions({ field }) {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [subOptions, setSubOptions] = useState("");

  const handleAdd = () => {
    subOptions.split(",").forEach(o => field.push(o.trim()));
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        color="primary"
        onClick={() => setOpen(true)}
        startIcon={<ListIcon />}
      >
        add sub-option list
      </Button>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorReference={"none"}
        classes={{ root: classes.popoverRoot }}
      >
        <div className={classes.popover}>
          <div className={classes.header}>SUB OPTIONS</div>
          <MuiTextField
            label="Add comma separated list"
            multiline
            minRows={20}
            type="textarea"
            fullWidth
            onChange={({ target }) => setSubOptions(target.value)}
          />
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              size="small"
              onClick={() => setOpen(false)}
            >
              cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleAdd}
            >
              add
            </Button>
          </div>
        </div>
      </Popover>
    </Fragment>
  );
}
