import React from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  useListController,
  ListView,
  DateInput,
  NumberInput,
  ExportButton,
  TopToolbar,
} from "react-admin";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { STHShipstationDownload } from "./components/STHShipstationDownload";
import Donation from "./Donation";
import { getUserTimezone, isProductCampaign } from "../../lib";
import { styles } from "./Donation.styles";
import { getSeasonId } from "../../customState";

const DonationFilter = props => {
  const classes = styles();
  return (
    <Filter {...props}>
      <TextInput label="Customer Name/Email" source="q" alwaysOn />
      <NumberInput
        label="Order ID"
        source="id"
        alwaysOn
        className={classes.narrowInput}
      />
      <TextInput
        label="Seller ID"
        source="volunteer_id"
        alwaysOn
        className={classes.narrowInput}
      />
      <TextInput
        label="Seller Name"
        source="name"
        alwaysOn
        className={classes.medInput}
      />
      <DateInput label="Start Date" source="start_date" alwaysOn />
      <DateInput label="End Date" source="end_date" alwaysOn />
    </Filter>
  );
};

const DonationActions = props => {
  const {
    currentSort,
    filterValues,
    resource,
    total,
    season_id,
    exporter,
  } = props;
  const timezone = getUserTimezone();

  return (
    <TopToolbar>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues, season_id, timezone }}
        exporter={exporter}
        maxResults={5000}
      />
      <STHShipstationDownload {...filterValues} />
    </TopToolbar>
  );
};

export default function AssociatedDonationsList() {
  const classes = styles();
  const season_id = useSelector(getSeasonId);
  const timezone = getUserTimezone();

  const controllerProps = useListController({
    location: {},
    resource: "donations/associated",
    basePath: "",
    perPage: 25,
    sort: { field: "id", order: "DESC" },
    filter: { season_id, timezone },
  });

  return (
    <ListView
      title="Sales"
      {...controllerProps}
      bulkActionButtons={false}
      filters={<DonationFilter />}
      actions={<DonationActions season_id={season_id} />}
      empty={false}
    >
      <Datagrid
        rowClick="expand"
        expand={<Donation associatedDonation forExpand />}
      >
        <TextField source="id" label="Order ID" sortable={true} />
        <FunctionField
          label="Date"
          source="created_at"
          render={record => {
            const date = record.created_at
              ? format(new Date(record.created_at), "Pp")
              : "";
            return date;
          }}
        />

        <TextField source="orgName" sortable={false} label="Org" />
        <TextField source="campaign_id" label="Campaign ID" />
        <TextField source="campaign_name" sortable={false} label="Campaign" />
        <TextField source="first_name" sortable={true} />
        <TextField source="last_name" sortable={true} />
        <TextField source="email" sortable={true} />
        <TextField source="total_amount" sortable={true} label="Sale" />
        <TextField source="volunteerName" label="Seller" />
        {/* <FunctionField
          label="Payment Type"
          source="pmt_type"
          render={record =>
            record.pmt_type === "cc" ? "credit card" : record.pmt_type
          }
        /> */}
        <FunctionField
          label="STS/STH"
          source="ship_to_school"
          render={({ ship_to_school, campaign_type_id }) => {
            if (!isProductCampaign(campaign_type_id)) return "";
            return ship_to_school ? "STS" : "STH";
          }}
        />
        <FunctionField
          label="Tracking"
          render={({ trackingLinks }) => {
            if (!Array.isArray(trackingLinks)) return <></>;
            return trackingLinks.map(({ trackingLink, tracking_number }, i) => {
              if (!trackingLink) return <div key={i}>{tracking_number}</div>;
              return (
                <div key={i}>
                  <a
                    className={classes.trackingLink}
                    href={trackingLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                  >
                    {tracking_number}
                  </a>
                </div>
              );
            });
          }}
        />
      </Datagrid>
    </ListView>
  );
}
