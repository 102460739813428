import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  TextField as MuiTextField,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Close";
import { useNotify, Notification } from "react-admin";
import { Form, Field, useField } from "react-final-form";
import { format } from "date-fns";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { APIClient, generateAuthHeader, poStatuses } from "../../../lib";
import { styles } from "./SourcePO.styles";

export function ReceiveSourcePO({ record, closeAside }) {
  const { id, productSource, created_at } = record;
  const classes = styles();
  const notify = useNotify();
  const [closeOrderTriggered, setCloseOrderTriggered] = useState(false);
  const [receiveOrderTriggered, setReceiveOrderTriggered] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [status, setStatus] = useState(null);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (id) fetchPoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchPoData = async () => {
    const res = await APIClient.get(`/product_source_purchase_orders/${id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    const { status, product_source_purchase_order_products } = data;

    setInitialValues({
      product_source_purchase_order_products,
    });
    setStatus(status);
    if (status !== poStatuses.CLOSED) setEditable(true);
  };

  const handleCloseOrder = async () => {
    setCloseOrderTriggered(true);
    const confirmed = window.confirm("Are you sure you want to close this PO?");
    if (!confirmed) {
      setCloseOrderTriggered(false);
      return;
    }
    const res = await APIClient.post(
      `/product_source_purchase_orders/${id}/close`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("PO successfully closed!");
    closeAside();
  };

  const handleReceiveOrder = async () => {
    setReceiveOrderTriggered(true);
    const confirmed = window.confirm(
      "Are you sure you want to receive the full PO?",
    );
    if (!confirmed) {
      setReceiveOrderTriggered(false);
      return;
    }
    const res = await APIClient.put(
      `/product_source_purchase_orders/${id}/receive`,
      { receiveFullOrder: true },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("PO successfully received & closed!");
    closeAside();
  };

  const onSubmit = async values => {
    const { product_source_purchase_order_products } = values;
    let receivingMoreThanOrdered = false;
    const formattedPOPs = product_source_purchase_order_products
      .filter(p => p.receive_item_qty)
      .map(p => {
        const { id, receive_item_qty } = p;
        const remaining = calcRemainingQty(p);
        if (remaining < 0) receivingMoreThanOrdered = true;
        return {
          id,
          receive_item_qty: Number(receive_item_qty),
        };
      });

    if (!formattedPOPs.length) {
      return notify("There are no changes to save.", "warning");
    }
    if (receivingMoreThanOrdered) {
      return notify(
        "You can not receive more than what was ordered!",
        "warning",
      );
    }
    const update = {
      product_source_purchase_order_products: formattedPOPs,
    };

    const res = await APIClient.put(
      `/product_source_purchase_orders/${id}/receive`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("PO has been successfully updated");
    closeAside();
  };

  return (
    <div className={classes.editContainer}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TableContainer component={Paper} className={classes.table}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>ID:</TableCell>
                      <TableCell>{id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Source:</TableCell>
                      <TableCell>{productSource}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Create date:</TableCell>
                      <TableCell>
                        {Boolean(created_at) &&
                          format(new Date(created_at), "Pp")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Status:</TableCell>
                      <TableCell>{status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleReceiveOrder}
                          disabled={receiveOrderTriggered || !editable}
                        >
                          receive full order
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          className={classes.closeOrder}
                          variant="contained"
                          onClick={handleCloseOrder}
                          disabled={closeOrderTriggered || !editable}
                        >
                          close order
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} className={classes.table}>
                <FieldArray name="product_source_purchase_order_products">
                  {({ fields }) => (
                    <Table>
                      <caption>Receive Order by Product</caption>
                      <TableHead>
                        <TableRow>
                          <TableCell>Product ID</TableCell>
                          <TableCell>Source product ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Thumbnail</TableCell>
                          <TableCell>SKU</TableCell>
                          <TableCell>Items per case</TableCell>
                          <TableCell>By case?</TableCell>
                          <TableCell>Cases ordered</TableCell>
                          <TableCell>Items ordered</TableCell>
                          <TableCell>Items received</TableCell>
                          <TableCell>Receive by cases</TableCell>
                          <TableCell>Receive by items</TableCell>
                          <TableCell>Remaining items</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields.map((row, index) => {
                          const values = fields.value[index];
                          const {
                            product_id,
                            source_product_id,
                            product_name,
                            sku,
                            thumbnail_image,
                            items_per_case,
                            by_case,
                            case_qty,
                            item_qty,
                            item_qty_received,
                          } = values;
                          return (
                            <TableRow key={index}>
                              <TableCell>{product_id}</TableCell>
                              <TableCell>{source_product_id}</TableCell>
                              <TableCell>{product_name}</TableCell>
                              <TableCell>
                                <img
                                  className={classes.thumbnail}
                                  alt="thumbnail"
                                  src={thumbnail_image}
                                />
                              </TableCell>
                              <TableCell>{sku}</TableCell>
                              <TableCell>{items_per_case}</TableCell>
                              <TableCell>
                                {by_case ? <CheckIcon /> : <XIcon />}
                              </TableCell>
                              <TableCell>
                                {formatQty(case_qty, true, by_case)}
                              </TableCell>
                              <TableCell>{item_qty}</TableCell>
                              <TableCell>
                                {formatQty(item_qty_received)}
                              </TableCell>
                              <TableCell>
                                <QtyInput
                                  values={values}
                                  row={row}
                                  isCase
                                  editable={editable}
                                />
                              </TableCell>
                              <TableCell>
                                <QtyInput
                                  values={values}
                                  row={row}
                                  editable={editable}
                                />
                              </TableCell>
                              <TableCell>{calcRemainingQty(values)}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </FieldArray>
              </TableContainer>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting || !editable}
              >
                save changes
              </Button>
            </form>
          );
        }}
      />
      <Notification />
    </div>
  );
}

function formatQty(value, caseField, isByCase) {
  if (caseField && !isByCase) return "N/A";
  return value ? value : 0;
}

function calcRemainingQty(values) {
  const { item_qty, item_qty_received, receive_item_qty } = values;
  return (
    Number(item_qty || 0) -
    Number(item_qty_received || 0) -
    Number(receive_item_qty || 0)
  );
}

function QtyInput({ values, row, isCase, editable }) {
  const { items_per_case, by_case } = values;
  const name = isCase ? `${row}.receive_case_qty` : `${row}.receive_item_qty`;
  const label = isCase ? "Case qty" : "Item qty";
  const disabled = !editable || (isCase && !by_case);
  const itemQtyInput = useField(`${row}.receive_item_qty`);
  const caseQtyInput = useField(`${row}.receive_case_qty`);

  const onChange = e => {
    const value = e.target.value;
    if (isCase) {
      const updatedItemQty = value ? items_per_case * Number(value) : null;
      itemQtyInput.input.onChange(updatedItemQty);
    } else {
      caseQtyInput.input.onChange(null);
    }
  };

  return (
    <Field
      variant="standard"
      type="number"
      name={name}
      label={label}
      disabled={disabled}
      render={({ input, meta: _rm, ...rest }) => {
        return (
          <MuiTextField
            {...rest}
            {...input}
            value={input.value}
            onChange={e => {
              input.onChange(e.target.value);
              onChange(e);
            }}
          />
        );
      }}
    />
  );
}
