import {
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { FieldRenderProps, useField, Field } from "react-final-form";
import { FieldValidator } from "final-form";

type Props = {
  fieldName: string;
  label: string | React.ReactNode;
  errorMsg?: string;
  validate?: FieldValidator<any>;
};
export function CheckboxField({ fieldName, label, errorMsg, validate }: Props) {
  const classes = styles();
  const { meta } = useField(fieldName);
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <FormControl>
      <FormControlLabel
        className={classes.label}
        control={
          <Field
            component={CheckboxWrapper}
            name={fieldName}
            type="checkbox"
            validate={validate}
          />
        }
        label={label}
      />
      {showError && errorMsg && (
        <FormHelperText className={classes.error}>{errorMsg}</FormHelperText>
      )}
    </FormControl>
  );
}

export function CheckboxWrapper(props: FieldRenderProps<any, any>) {
  const classes = styles();
  const {
    input: { checked, name, onChange, ...restInput },
    ...rest
  } = props;
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      {...rest}
      name={name}
      inputProps={restInput}
      className={classes.checkbox}
    />
  );
}

const styles = makeStyles(theme => ({
  label: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  error: {
    color: theme.palette.error.main,
  },
  checkbox: {
    marginTop: -9,
    marginBottom: -9,
  },
}));
