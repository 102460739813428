import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  Filter,
  useListController,
  ListView,
  Pagination,
  Notification,
  TextInput,
  BooleanInput,
  FunctionField,
  TopToolbar,
  useNotify,
  SelectInput,
} from "react-admin";
import { useSelector } from "react-redux";
import {
  TextField as MuiTextField,
  InputAdornment,
  Drawer,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import RefreshIcon from "@material-ui/icons/Refresh";
import { getSeasonId, getSupplierHasDistributors } from "../../customState";
import { APIClient, formatDateOnlyString, generateAuthHeader } from "../../lib";
import { ShipToHomeOrder } from "./ShipToHomeOrder";
import { PullSheetExport } from "./components/PullSheetExport";
import { ShipToHomePickSlipsExport } from "./components/ShipToHomePickSlipsExport";
import { styles } from "./OrderFulfillment.styles";

const OrderFilter = ({
  isForCampaign,
  supplierHasDistributors,
  productGroups,
  companies,
  ...props
}) => {
  const classes = styles();
  return (
    <Fragment>
      <Filter {...props}>
        <TextInput
          label="Order ID / Customer Name"
          source="q"
          alwaysOn
          className={classes.wideFilter}
        />
        <BooleanInput label="Unfulfilled Only" source="un_fulfilled" alwaysOn />
        <BooleanInput
          label="Not Downloaded Only"
          source="not_downloaded"
          alwaysOn
        />
        {!isForCampaign && supplierHasDistributors && (
          <BooleanInput
            source="exclude_supplier_distributors"
            label="Exclude Distributors"
            alwaysOn
          />
        )}
        {!isForCampaign && supplierHasDistributors && (
          <SelectInput
            source="company_id"
            label="Distributor"
            choices={companies}
            translateChoice={false}
            alwaysOn
          />
        )}
        {!isForCampaign && (
          <SelectInput
            source="supplier_product_group_id"
            label="Product Group"
            choices={productGroups}
            translateChoice={false}
            alwaysOn
          />
        )}
        {!isForCampaign && (
          <SelectInput
            source="supplier_product_group_id_exclude"
            label="Exclude Product Group"
            choices={productGroups}
            translateChoice={false}
            className={classes.wideFilter}
            alwaysOn
          />
        )}
        <BooleanInput
          label="Exclude STH Fulf. Flagged"
          source="sth_fulfillment_exclusion_flag"
          alwaysOn
        />
        <BooleanInput
          label="Exclude BO Orders"
          source="exclude_back_ordered"
          alwaysOn
        />
      </Filter>
    </Fragment>
  );
};

const OrderActions = ({ campaign_id, currentFilters }) => (
  <TopToolbar>
    <PullSheetExport
      campaign_id={campaign_id}
      currentFilters={currentFilters}
    />
    <ShipToHomePickSlipsExport
      campaign_id={campaign_id}
      currentFilters={currentFilters}
    />
  </TopToolbar>
);

const BulkActions = ({ selectedIds, campaign_id, currentFilters }) => {
  return (
    <div>
      <PullSheetExport
        campaign_id={campaign_id}
        selectedIds={selectedIds}
        currentFilters={currentFilters}
      />
      <ShipToHomePickSlipsExport
        campaign_id={campaign_id}
        selectedIds={selectedIds}
        currentFilters={currentFilters}
      />
    </div>
  );
};

export function ShipToHomeOrderList(props) {
  const { isForCampaign, campaign_id, resourceName } = props;
  const classes = styles();
  const notify = useNotify();
  const searchRef = useRef();
  const season_id = useSelector(getSeasonId);
  const supplierHasDistributors = useSelector(getSupplierHasDistributors);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [productGroups, setProductGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [disableRefresh, setDisableRefresh] = useState(false);

  const controllerProps = useListController({
    sort: { field: "id", order: "DESC" },
    basePath: "",
    resource: resourceName,
    perPage: 25,
    filter: {
      campaign_id: isForCampaign ? campaign_id : "",
      season_id: isForCampaign ? "" : season_id,
    },
    filterDefaultValues: { un_fulfilled: true },
  });
  const currentFilters = controllerProps.filterValues || {};

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        "/supplier_product_groups/supplier_group_autocomplete",
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setProductGroups(data);
    };
    if (!isForCampaign) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isForCampaign]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        "/company_product_suppliers/supplier_company_autocomplete",
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setCompanies(data);
    };
    if (!isForCampaign && supplierHasDistributors) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierHasDistributors, isForCampaign]);

  const handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13 && selectedOrderId) setOpenDrawer(true);
  };

  const onRowClick = id => {
    setSelectedOrderId(id);
    setOpenDrawer(true);
  };

  const focusInput = () => {
    setTimeout(() => {
      if (searchRef.current) searchRef.current.focus();
    }, 50);
    // set focus again in case it didn't pickup the first time
    setTimeout(() => {
      if (searchRef.current) searchRef.current.focus();
    }, 400);
  };

  const closeDrawerAndRefresh = () => {
    setOpenDrawer(false);
    setSelectedOrderId("");
    focusInput();
    // TODO: for now we aren't refreshing the list every time an order is fulfilled but we are adding a manual refresh button instead
    // controllerProps.refetch();
  };

  const refreshList = () => {
    if (disableRefresh) return;
    setDisableRefresh(true);
    controllerProps.refetch();
    focusInput();
    setTimeout(() => setDisableRefresh(false), 1000);
  };

  return (
    <Fragment>
      <div className={classes.homeOrdersTop}>
        <div className={classes.refreshContainer} onClick={refreshList}>
          <RefreshIcon className={classes.refreshIcon} /> Refresh list
        </div>
        <MuiTextField
          color="primary"
          autoFocus
          placeholder="scan or enter order ID"
          type="number"
          value={selectedOrderId}
          onChange={({ target }) => setSelectedOrderId(target.value)}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => selectedOrderId && setOpenDrawer(true)}
              >
                <IconButton size="small">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputRef={searchRef}
        />
        <div className={classes.homeOrdersTopBalancer} />
      </div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={
          <BulkActions
            campaign_id={campaign_id}
            currentFilters={currentFilters}
          />
        }
        exporter={false}
        component={isForCampaign ? "div" : undefined}
        filters={
          <OrderFilter
            isForCampaign={isForCampaign}
            supplierHasDistributors={supplierHasDistributors}
            companies={companies}
            productGroups={productGroups}
          />
        }
        actions={
          <OrderActions
            campaign_id={campaign_id}
            currentFilters={currentFilters}
          />
        }
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" label="Order ID" />
          <DateField source="created_at" label="Date" showTime />
          <BooleanField source="pick_slip_downloaded" label="PS Downloaded" />
          <BooleanField source="fulfilled" sortable={false} />
          <TextField source="company_name" label="Company" />
          <TextField source="first_name" label="First Name" />
          <TextField source="last_name" label="Last Name" />
          <TextField source="ship_to_name" label="Ship To" />
          <TextField source="shipAddress" label="address" sortable={false} />
          <TextField source="shipApt" label="apt" sortable={false} />
          <TextField source="shipCity" label="city" sortable={false} />
          <TextField source="shipState" label="state" sortable={false} />
          <TextField source="shipZip" label="zip" sortable={false} />
          {!isForCampaign && (
            <TextField source="productGroup" label="PG" sortable={false} />
          )}
          <TextField source="shipping_and_handling" label="Shipping Charged" />
          <FunctionField
            label="Shipping Cost"
            render={({ actualShippingCostArr: ASC }) => {
              if (!Array.isArray(ASC) || !ASC.length) return <></>;
              return ASC.map((cost, i) => {
                const isLast = i === ASC.length - 1;
                return (
                  <div key={i} className={!isLast ? classes.shippingRow : ""}>
                    {cost}
                  </div>
                );
              });
            }}
          />
          <FunctionField
            label="Shipped Date"
            render={({ shippedDateArr: SD }) => {
              if (!Array.isArray(SD) || !SD.length) return <></>;
              return SD.map((d, i) => {
                const isLast = i === SD.length - 1;
                return (
                  <div key={i} className={!isLast ? classes.shippingRow : ""}>
                    {formatDateOnlyString(d)}
                  </div>
                );
              });
            }}
          />
          <FunctionField
            label="Carrier"
            render={({ carrierArr: CR }) => {
              if (!Array.isArray(CR) || !CR.length) return <></>;
              return CR.map((c, i) => {
                const isLast = i === CR.length - 1;
                return (
                  <div key={i} className={!isLast ? classes.shippingRow : ""}>
                    {c}
                  </div>
                );
              });
            }}
          />
          <FunctionField
            label="Tracking"
            render={({ trackingArr: TR }) => {
              if (!Array.isArray(TR) || !TR.length) return <></>;
              return TR.map((t, i) => {
                const { trackingLink, trackingNumber } = t;
                const isLast = i === TR.length - 1;
                if (!trackingLink) {
                  return (
                    <div key={i} className={!isLast ? classes.shippingRow : ""}>
                      {trackingNumber}
                    </div>
                  );
                }
                return (
                  <div key={i} className={!isLast ? classes.shippingRow : ""}>
                    <a
                      className={classes.trackingLink}
                      href={trackingLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => e.stopPropagation()}
                    >
                      {trackingNumber}
                    </a>
                  </div>
                );
              });
            }}
          />
        </Datagrid>
      </ListView>

      <Drawer open={openDrawer} anchor="right">
        <div className={classes.drawerContent}>
          <div className={classes.drawerHeader}>
            <div>{`Order ID: ${selectedOrderId}`}</div>
            <IconButton onClick={closeDrawerAndRefresh} size="small">
              <CloseIcon />
            </IconButton>
          </div>

          <ShipToHomeOrder
            donation_id={selectedOrderId}
            closeDrawerAndRefresh={closeDrawerAndRefresh}
          />
        </div>
        <Notification />
      </Drawer>
    </Fragment>
  );
}
