import { Button, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TextField } from "final-form-material-ui";
import { Field, Form } from "react-final-form";
import { FormApi } from "final-form";
import { OnChange } from "react-final-form-listeners";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import { Fragment } from "react";
import { useIsMobile } from "../../../hooks/ui";
import {
  APIAuthClient,
  requiredField,
  requiredPositiveInteger,
} from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, setToast } from "../../../state";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refreshList: () => void;
};
export function AddTeam({ isOpen, onClose, refreshList }: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);

  const onSubmit = async (values: any) => {
    const { team_campaign_goal, ...rest } = values;
    const post = {
      campaign_id,
      ...rest,
      team_campaign_goal: Number(team_campaign_goal),
    };
    const res = await APIAuthClient.post<any, APIRes>("/teams", post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return;
    }
    dispatch(setToast("Team added", ToastTypes.success));
    if (refreshList) refreshList();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <Fragment>
                {isMobile && (
                  <div className={classes.closeWrapper}>
                    <IconButton onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}

                <h1 className={classes.title}>Add team</h1>

                <form onSubmit={handleSubmit} className={classes.form}>
                  <div className={classes.fields}>
                    <Field
                      name="team_name"
                      component={TextField}
                      validate={requiredField}
                      placeholder="Team name"
                      className={classes.wideInput}
                    />
                    <ChangeListener form={form} />
                    <div className={classes.slugAndGoal}>
                      <Field
                        name="team_slug"
                        component={TextField}
                        validate={requiredField}
                        placeholder="Unique URL"
                        className={classes.halfInput}
                      />
                      <Field
                        name="team_campaign_goal"
                        component={TextField}
                        validate={requiredPositiveInteger}
                        placeholder="Goal"
                        className={classes.halfInput}
                        type="number"
                      />
                    </div>
                  </div>

                  <div className={classes.actions}>
                    {!isMobile && (
                      <Button
                        variant="text"
                        className={classes.cancel}
                        onClick={onClose}
                        disabled={submitting}
                      >
                        CANCEL
                      </Button>
                    )}

                    <Button
                      color="primary"
                      type="submit"
                      variant={isMobile ? "contained" : "text"}
                      className={classes.save}
                      disabled={submitting}
                    >
                      Save
                      <ButtonSpinner show={submitting} />
                    </Button>
                  </div>
                </form>
              </Fragment>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

function ChangeListener({ form }: { form: FormApi }) {
  return (
    <OnChange name="team_name">
      {v => {
        if (v) form.change("team_slug", v.replace(/\s+/g, ""));
      }}
    </OnChange>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "100%",
    },
  },
  closeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
  },
  topContent: {
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #DBDEEE",
  },
  title: {
    padding: "16px 24px",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "none",
      padding: "0 16px",
    },
  },
  form: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  fields: {
    padding: 24,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
    },
  },
  wideInput: {
    width: "100%",
    marginBottom: 24,
  },
  slugAndGoal: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  halfInput: {
    width: "calc(50% - 12px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 24,
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 16,
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  save: {
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      textTransform: "none",
      width: 300,
      maxWidth: "100%",
      borderRadius: 18,
    },
  },
  cancel: {
    width: 71,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 16,
  },
}));
