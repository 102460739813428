import React, { useEffect, useState, Fragment } from "react";
import { useNotify } from "react-admin";
import { connect } from "react-redux";
import { makeStyles, Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import { APIClient, generateAuthHeader, isMrsFieldsCompany } from "../../lib";
import { DonationEdit } from "./DonationEdit";
import { DonationShow } from "./DonationShow";
import { BrochureEdit } from "./BrochureEdit";

function Donation(props) {
  const {
    id,
    companyId,
    volunteerLabel,
    basePath,
    canManage,
    forExpand,
    associatedDonation,
    allow_donations,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const [showActions, setShowActions] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [record, setRecord] = useState(null);
  const [isBrochure, setIsBrochure] = useState(false);

  useEffect(() => {
    fetchDonation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canManage) setShowActions(true);
    else if (isBrochure && isMrsFieldsCompany(companyId)) {
      setShowActions(true);
    }
  }, [canManage, companyId, isBrochure]);

  const fetchDonation = async () => {
    const res = await APIClient.get(`/donations/${id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setIsBrochure(Boolean(data.from_brochure));
    setRecord(data);
  };

  return (
    <Fragment>
      {showActions && (
        <div className={classes.modeToggle}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={editMode ? <ViewIcon /> : <EditIcon />}
            onClick={() => setEditMode(!editMode)}
          >
            {editMode ? "view" : "edit"}
          </Button>
        </div>
      )}
      {record && !editMode && (
        <DonationShow
          id={id}
          basePath={basePath}
          record={record}
          volunteerLabel={volunteerLabel}
          canManage={canManage}
          forExpand={forExpand}
          associatedDonation={associatedDonation}
        />
      )}

      {record && editMode && !isBrochure && (
        <DonationEdit
          id={id}
          basePath={basePath}
          record={record}
          volunteerLabel={volunteerLabel}
          forExpand={forExpand}
          associatedDonation={associatedDonation}
        />
      )}

      {record && editMode && isBrochure && (
        <BrochureEdit
          id={id}
          campaign_id={record.campaign_id}
          forExpand={forExpand}
          associatedDonation={associatedDonation}
          allowDonations={allow_donations}
        />
      )}
    </Fragment>
  );
}
export default connect(state => {
  const {
    selectedCampaign: { volunteer_label },
    user: { canManage, companyId },
  } = state;
  return {
    volunteerLabel: volunteer_label ? volunteer_label : "Student",
    canManage,
    companyId,
  };
})(Donation);

const styles = makeStyles(_theme => ({
  modeToggle: {
    marginTop: 16,
  },
}));
