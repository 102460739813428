export function TextOptInSVG() {
  return (
    <svg width="179px" height="136px" viewBox="0 0 179 136">
      <title>Text opt in</title>
      <defs>
        <polygon points="0 0 83.8776 0 83.8776 1 0 1"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-98, -64)">
          <g transform="translate(98, 64)">
            <path
              d="M71.5278,28.203 L70.8758,28.203 L70.8758,10.339 C70.8758,4.629 66.2468,0 60.5368,0 L22.6888,0 C16.9788,0 12.3498,4.629 12.3498,10.339 L12.3498,108.342 C12.3498,114.053 16.9788,118.682 22.6888,118.682 L60.5368,118.682 C66.2468,118.682 70.8758,114.053 70.8758,108.343 L70.8758,40.919 L71.5278,40.919 L71.5278,28.203 Z"
              fill="#000000"
            ></path>
            <path
              d="M60.9536,2.6897 L56.0136,2.6897 C56.7786,4.5657 55.8776,6.7067 54.0016,7.4717 C53.5626,7.6507 53.0916,7.7437 52.6166,7.7437 L30.9346,7.7437 C28.9086,7.7437 27.2666,6.1007 27.2666,4.0747 C27.2666,3.5997 27.3586,3.1297 27.5386,2.6897 L22.9236,2.6897 C18.6596,2.6897 15.2026,6.1467 15.2026,10.4107 L15.2026,108.2707 C15.2026,112.5347 18.6596,115.9917 22.9236,115.9917 L60.9536,115.9917 C65.2176,115.9917 68.6746,112.5347 68.6746,108.2707 L68.6746,10.4107 C68.6746,6.1467 65.2176,2.6897 60.9536,2.6897 Z"
              fill="#FFFFFF"
            ></path>
            <g transform="translate(0, 117.9797)">
              <mask fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g></g>
              <path
                d="M83.6586,0.719 L0.2186,0.719 C0.0976,0.719 -0.0004,0.621 -0.0004,0.5 C-0.0004,0.379 0.0976,0.281 0.2186,0.281 L83.6586,0.281 C83.7796,0.281 83.8776,0.379 83.8776,0.5 C83.8776,0.621 83.7796,0.719 83.6586,0.719"
                fill="#D9D9D9"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M41.9388,38.7165 C52.1528,38.7165 60.4328,46.9965 60.4328,57.2105 C60.4328,67.4245 52.1528,75.7045 41.9388,75.7045 C31.7248,75.7045 23.4448,67.4245 23.4448,57.2105 C23.4448,46.9965 31.7248,38.7165 41.9388,38.7165"
              fill="#FD7B6F"
            ></path>
            <path
              d="M40.1062,64.2652055 C39.6902,64.2662 39.2852,64.1312 38.9522,63.8822 L38.9312,63.8662 L34.5852,60.5412 C33.7392,59.8922 33.5782,58.6802 34.2272,57.8332 C34.8762,56.9872 36.0892,56.8272 36.9352,57.4762 L39.7512,59.6342 L46.4032,50.9562 C47.0522,50.1092 48.2642,49.9492 49.1102,50.5982 L49.1102,50.5982 L49.1102,50.5982 L49.0692,50.6562 L49.1122,50.5982 C49.9572,51.2482 50.1172,52.4592 49.4692,53.3062 L41.6442,63.5102 C41.2772,63.9862 40.7092,64.2642 40.1082,64.2632 L40.1062,64.2652055 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M152.0407,120.5164 C152.2657,120.1524 152.5307,119.8154 152.8307,119.5104 C152.9537,119.3814 153.0847,119.2614 153.2187,119.1434 C155.7617,116.8804 159.6567,117.1074 161.9197,119.6494 C162.6737,120.4964 163.1787,121.5344 163.3807,122.6494 C164.2847,119.3084 161.9327,116.0184 159.5687,113.4914 C157.2037,110.9654 154.5007,108.1934 154.5147,104.7314 C154.5207,102.8054 155.4627,101.0874 156.8857,99.8124 C156.9297,99.7744 156.9737,99.7354 157.0187,99.6984 C158.7377,98.2244 160.9507,97.4534 163.2137,97.5394 C167.4177,97.7644 171.0897,100.5904 173.6387,103.9424 C177.7427,109.3364 179.5417,116.9064 176.6687,123.0464 C174.1407,128.4464 168.7517,131.6524 163.2507,134.2304 C162.4827,134.5904 161.7167,134.9394 160.9517,135.2784 C160.9467,135.2794 160.9407,135.2834 160.9357,135.2844 C160.8987,135.3004 160.8617,135.3164 160.8277,135.3334 C160.6697,135.4034 160.5117,135.4724 160.3557,135.5414 L160.4277,135.5894 L160.6537,135.7354 C160.5757,135.6874 160.4977,135.6414 160.4197,135.5944 C160.3957,135.5804 160.3717,135.5664 160.3487,135.5524 C157.6467,133.9314 154.9077,132.2164 153.0407,129.6854 C151.1057,127.0574 150.3277,123.2944 152.0407,120.5164"
              fill="#F2F2F2"
            ></path>
            <path
              d="M170.0652,114.4142 C170.6632,115.7752 171.0602,117.2162 171.2422,118.6912 C171.3862,120.0072 171.3322,121.3362 171.0822,122.6362 C170.5462,125.3062 169.3762,127.8092 167.6712,129.9342 C166.3842,131.5462 164.8992,132.9902 163.2512,134.2302 C162.4832,134.5902 161.7162,134.9402 160.9512,135.2782 C160.9462,135.2792 160.9412,135.2832 160.9362,135.2842 C160.8992,135.3002 160.8622,135.3172 160.8272,135.3332 C160.6702,135.4032 160.5122,135.4732 160.3552,135.5412 L160.4282,135.5892 L160.6542,135.7352 C160.5752,135.6882 160.4982,135.6422 160.4192,135.5942 C160.3962,135.5802 160.3712,135.5662 160.3482,135.5522 C161.5542,131.3322 160.6402,126.7892 157.8942,123.3652 C156.5332,121.6992 154.7982,120.3782 152.8302,119.5102 C152.9542,119.3822 153.0842,119.2612 153.2192,119.1432 C153.9772,119.4912 154.7032,119.9062 155.3882,120.3822 C157.8382,122.0752 159.6782,124.5122 160.6352,127.3332 C161.4892,129.8912 161.5962,132.6412 160.9422,135.2582 C161.0782,135.1692 161.2142,135.0782 161.3462,134.9882 C163.8632,133.2912 166.1832,131.2522 167.9302,128.7532 C169.4372,126.6402 170.4012,124.1902 170.7382,121.6172 C171.0732,118.8062 170.3692,116.0422 169.1142,113.5342 C167.7292,110.8512 165.9832,108.3702 163.9252,106.1612 C161.8582,103.8902 159.5212,101.8802 156.9662,100.1772 C156.8462,100.0962 156.8112,99.9362 156.8862,99.8132 C156.9132,99.7592 156.9612,99.7172 157.0182,99.6982 C157.0772,99.6832 157.1402,99.6962 157.1892,99.7332 C157.5042,99.9432 157.8172,100.1562 158.1262,100.3742 C160.6992,102.1962 163.0362,104.3312 165.0832,106.7292 C167.0612,109.0402 168.8612,111.6092 170.0652,114.4142"
              fill="#FFFFFF"
            ></path>
            <path
              d="M114.985,0.7317 C117.154,0.7317 118.913,2.4897 118.913,4.6597 C118.913,6.8287 117.154,8.5867 114.985,8.5867 C112.816,8.5867 111.057,6.8287 111.057,4.6597 C111.057,2.4897 112.816,0.7317 114.985,0.7317"
              fill="#F2F2F2"
            ></path>
            <path
              d="M163.8223,56.3582 C165.1443,56.3582 166.2173,57.4302 166.2173,58.7532 C166.2173,60.0752 165.1443,61.1472 163.8223,61.1472 C162.5003,61.1472 161.4273,60.0752 161.4273,58.7532 C161.4273,57.4302 162.5003,56.3582 163.8223,56.3582"
              fill="#F2F2F2"
            ></path>
            <path
              d="M93.7418,62.9282 C95.0638,62.9282 96.1368,64.0002 96.1368,65.3232 C96.1368,66.6452 95.0638,67.7172 93.7418,67.7172 C92.4198,67.7172 91.3468,66.6452 91.3468,65.3232 C91.3468,64.0002 92.4198,62.9282 93.7418,62.9282"
              fill="#F2F2F2"
            ></path>
            <path
              d="M95.0939,135.9998 L178.5339,135.9998 C178.6549,135.9998 178.7529,135.9018 178.7529,135.7808 C178.7529,135.6598 178.6549,135.5618 178.5339,135.5618 L95.0939,135.5618 C94.9729,135.5618 94.8749,135.6598 94.8749,135.7808 C94.8749,135.9018 94.9729,135.9998 95.0939,135.9998"
              fill="#D9D9D9"
            ></path>
            <path
              d="M169.3254,21.163 C169.5724,19.408 168.3504,17.785 166.5954,17.538 C165.1274,17.331 163.7084,18.157 163.1634,19.537 L156.3924,27.299 L158.3794,32.848 L165.1884,23.778 C166.8674,24.315 168.6644,23.391 169.2014,21.712 C169.2594,21.533 169.3004,21.349 169.3254,21.163"
              fill="#FFD1D2"
            ></path>
            <polygon
              fill="#000000"
              points="141.2589 51.3664 163.7349 27.3794 160.7029 22.5594 132.5129 46.4014"
            ></polygon>
            <path
              d="M136.2426,21.0239 C140.5966,21.0239 144.1266,24.5539 144.1266,28.9079 C144.1266,33.2619 140.5966,36.7919 136.2426,36.7919 C131.8886,36.7919 128.3586,33.2619 128.3586,28.9079 C128.3586,24.5539 131.8886,21.0239 136.2426,21.0239"
              fill="#212121"
            ></path>
            <path
              d="M136.8763,24.4542 C138.5733,22.7342 140.6263,21.4062 142.8903,20.5652 C144.3443,20.0262 145.9273,19.6932 147.4473,19.9992 C148.9673,20.3052 150.4043,21.3572 150.8283,22.8482 C151.1753,24.0672 150.8173,25.3802 150.2793,26.5282 C149.7413,27.6762 149.0223,28.7412 148.5873,29.9322 C147.1153,33.9612 149.1873,38.4212 153.2173,39.8932 C154.2353,40.2662 155.3213,40.4202 156.4033,40.3482 C154.9103,40.5482 153.5333,41.2502 152.0733,41.6232 C150.6133,41.9972 148.8913,41.9722 147.8063,40.9262 C146.6583,39.8192 146.6353,38.0202 146.6903,36.4272 L146.9383,29.3222 C146.9803,28.1142 147.0163,26.8672 146.5803,25.7402 C146.1453,24.6122 145.1103,23.6252 143.9013,23.6352 C142.9853,23.6432 142.1743,24.1982 141.4403,24.7452 C140.7053,25.2922 139.9283,25.8762 139.0153,25.9582 C138.1033,26.0392 137.0613,25.3642 137.1293,24.4502 L136.8763,24.4542 Z"
              fill="#212121"
            ></path>
            <path
              d="M106.2984,81.8457 C104.5904,82.3197 102.8224,81.3207 102.3474,79.6127 C101.9504,78.1837 102.5844,76.6697 103.8804,75.9487 L110.6914,68.2217 L116.4524,69.4667 L108.3504,77.4027 C109.1024,78.9967 108.4204,80.8987 106.8264,81.6517 C106.6564,81.7317 106.4794,81.7967 106.2984,81.8457 Z"
              fill="#FFD1D2"
            ></path>
            <polygon
              fill="#000000"
              points="128.7068 54.1819 111.7308 75.4909 106.5568 73.1149 126.5098 42.0509"
            ></polygon>
            <polygon
              fill="#FFD1D2"
              points="115.1616 118.5258 111.1156 118.4618 111.6026 132.2518 115.1606 132.2518"
            ></polygon>
            <path
              d="M116.0687,131.0902 L109.0607,131.0902 L109.0597,131.0902 C106.5937,131.0902 104.5937,133.0892 104.5937,135.5562 L104.5937,135.7012 L116.0687,135.7012 L116.0687,131.0902 Z"
              fill="#212121"
            ></path>
            <polygon
              fill="#FFD1D2"
              points="141.9492 114.8685 137.5292 117.7055 146.3702 128.3415 149.3652 126.4195"
            ></polygon>
            <path
              d="M149.5009,124.951 L143.6029,128.737 L143.6029,128.737 C141.5279,130.07 140.9249,132.833 142.2579,134.909 L142.3359,135.031 L151.9919,128.832 L149.5009,124.951 Z"
              fill="#212121"
            ></path>
            <path
              d="M143.5237,72.7609 C143.5237,72.7609 133.7037,40.1459 130.5477,39.7049 C127.3917,39.2639 122.3077,48.0739 122.3077,48.0739 C115.7147,56.4849 108.9167,83.7319 108.9167,83.7319 C108.9167,83.7319 120.2497,84.4409 128.5337,88.8479 C136.8177,93.2559 146.5937,88.6249 146.5937,88.6249 L143.5237,72.7609 Z"
              fill="#000000"
            ></path>
            <path
              d="M143.5237,72.7609 C143.5237,72.7609 133.7037,40.1459 130.5477,39.7049 C127.3917,39.2639 123.5987,48.1629 123.5987,48.1629 C120.3137,61.7409 108.9167,83.7319 108.9167,83.7319 C108.9167,83.7319 120.2497,84.4409 128.5337,88.8479 C136.8177,93.2559 146.5937,88.6249 146.5937,88.6249 L143.5237,72.7609 Z"
              fill="#000000"
            ></path>
            <polygon
              fill="#212121"
              points="135.4672 63.0026 135.4672 79.3916 137.3962 103.1326 147.0322 121.0906 142.4332 124.3756 129.2932 107.2936 124.0962 90.3316 115.9342 127.8796 109.8022 127.4416 114.0602 75.3826 122.1422 60.7286"
            ></polygon>
            <path
              d="M125.5662,47.1508 C122.5002,50.2168 125.3472,53.5018 125.3472,53.5018 L121.8432,61.3858 L135.6402,65.1088 L140.4622,43.5638 L137.1772,40.0598 C130.6072,39.8408 130.6032,39.7048 130.6032,39.7048 C129.4522,40.8308 125.5662,47.1508 125.5662,47.1508"
              fill="#2498FE"
            ></path>
            <path
              d="M141.0175,41.3318 C138.4015,39.4008 134.7155,39.9558 132.7845,42.5718 C132.7705,42.5898 132.7575,42.6088 132.7445,42.6268 C129.2755,47.4558 126.0685,55.2548 130.4875,64.9808 C137.5825,80.5978 143.7575,88.5918 146.2675,88.5918 C146.3775,88.5918 146.4865,88.5748 146.5905,88.5408 C148.1355,88.0168 150.3695,85.4018 150.7735,83.0978 C150.9185,82.2728 150.8845,81.1248 149.8975,80.3358 C147.0645,78.0698 141.9795,71.0148 140.3925,61.7288 C139.5165,56.5978 141.2165,51.9238 142.7965,48.9058 C144.1775,46.3248 143.4515,43.1218 141.0935,41.3878 L141.0175,41.3318 Z"
              fill="#000000"
            ></path>
            <path
              d="M131.0806,25.8328 C133.8926,24.2958 137.4196,25.3288 138.9566,28.1418 C140.4946,30.9538 139.4606,34.4798 136.6476,36.0178 C133.8356,37.5548 130.3096,36.5218 128.7716,33.7088 C127.2346,30.8968 128.2686,27.3698 131.0806,25.8328"
              fill="#FFD1D2"
            ></path>
            <path
              d="M125.7025,30.4864 L134.0825,30.4864 L134.1685,29.2834 L134.5985,30.4864 L135.8885,30.4864 L136.0595,28.1014 L136.9105,30.4864 L139.4075,30.4864 L139.4075,30.3684 C139.4035,26.9114 136.6025,24.1104 133.1455,24.1064 L131.9645,24.1064 C128.5075,24.1104 125.7065,26.9114 125.7025,30.3684 L125.7025,30.4864 Z"
              fill="#212121"
            ></path>
            <path
              d="M138.1414,34.811 L141.7174,33.651 L141.7174,24.966 L134.9624,24.966 L135.1294,25.133 C137.4564,27.457 140.0444,35.701 138.1414,34.811"
              fill="#212121"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
