import { useState, useEffect, Fragment, useRef } from "react";
import {
  Button,
  MenuItem,
  makeStyles,
  ClickAwayListener,
} from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  APIClient,
  parseUserToken,
  isNewAppUser,
  IS_RN_WEBVIEW,
  getRedirectUrl,
} from "../../../lib";
import {
  APIRes,
  GenericObject,
  LoginModes,
  LoginTypes,
  VolunteerAccountSelect,
} from "../../types";
import { ButtonSpinner, TextFieldWrapper } from "../../components";
import {
  composeValidators,
  emailValidation,
  requiredField,
  requiredFieldGenerator,
} from "../../lib";
const idValidator = requiredFieldGenerator(
  "Enter either your Campaign ID or Participant ID",
);
const MULTI_USER_MSG =
  "This email is shared by multiple users. Please select your account.";

type Props = {
  mode: LoginModes;
  setMode: React.Dispatch<React.SetStateAction<LoginModes>>;
  type: LoginTypes;
  setType: React.Dispatch<React.SetStateAction<LoginTypes>>;
  campaign_id: number | null;
  org_id: number | null;
  volunteer_id: number | null;
};
export function LoginAndForgotPWForm({
  mode,
  setMode,
  type,
  setType,
  campaign_id,
  org_id,
  volunteer_id,
}: Props) {
  const location = useLocation();
  const {
    e: prefillEmail = "",
    token,
    sso_token,
    company_slug,
  } = queryString.parse(location.search);
  const classes = styles();
  const campaignIdRef = useRef<null | number>(null);
  const [volunteerOptions, setVolunteerOptions] = useState<
    VolunteerAccountSelect
  >([]);
  const [redirect, setRedirect] = useState("/");
  const [warning, setWarning] = useState("");
  const [showIdInput, setShowIdInput] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isForgot, setIsForgot] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setIsAdmin(type === LoginTypes.admin);
    setWarning("");
  }, [type]);

  useEffect(() => {
    setIsForgot(mode === LoginModes.forgotPW);
    setWarning("");
  }, [mode]);

  useEffect(() => {
    if (!location.state) return;
    const {
      nextPathname = "/",
      nextSearch = "",
    } = location.state as GenericObject;
    if ((nextPathname as string).includes("login")) return setRedirect("/");
    setRedirect(`${nextPathname}${nextSearch}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    if (volunteer_id || campaign_id || org_id) setShowIdInput(false);
    else setShowIdInput(true);

    if (campaign_id) campaignIdRef.current = campaign_id;
  }, [campaign_id, org_id, volunteer_id]);

  useEffect(() => {
    if (token) {
      const { role } = parseUserToken(token) as GenericObject;
      if (IS_RN_WEBVIEW && !isNewAppUser(role)) return;
      // TODO: see if timeout is needed - maybe to make sure campaignIdRef resolved
      setTimeout(() => {
        localStorage.setItem("token", token as string);
        if (campaignIdRef.current) {
          localStorage.setItem(
            "selectedCampaignId",
            `${campaignIdRef.current}`,
          );
        }
        window.location.replace(redirect);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (sso_token) ssoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sso_token]);

  const onLoginRes = (res: APIRes) => {
    const { error, errorMessage, code, data, errorData } = res;
    if (error) {
      if (code === 403) setWarning("Login failed. Please try again");
      else if (code === 110) {
        setWarning(MULTI_USER_MSG);
        if (Array.isArray(errorData?.volunteerAccounts)) {
          setVolunteerOptions(errorData.volunteerAccounts);
        }
      } else setWarning(errorMessage || "An unexpected error occurred");
      return;
    }

    if (!data.token) {
      setWarning(
        "An unexpected error occurred completing your login, please refresh your page",
      );
      return;
    }

    const { token, superAdminCompanyToken, campaign_id } = data;
    const { role } = parseUserToken(token) as GenericObject;

    if (IS_RN_WEBVIEW && !isNewAppUser(role)) {
      setWarning(
        "This App is not available for Admins, redirecting to your browser...",
      );
      setTimeout(() => {
        window.location.replace(`/admin/login?token=${token}`);
      }, 1000);

      return;
    }

    localStorage.setItem("token", token);
    if (superAdminCompanyToken) {
      localStorage.setItem("superAdminCompanyToken", superAdminCompanyToken);
    }

    if (campaignIdRef.current || campaign_id) {
      localStorage.setItem(
        "selectedCampaignId",
        `${campaign_id ? campaign_id : campaignIdRef.current}`,
      );
    }

    const path = isNewAppUser(role) ? redirect : `/admin${redirect}`;
    window.location.replace(path);
  };

  const onForgotPwRes = (res: APIRes) => {
    const { error, errorMessage, code, errorData, status } = res;
    if (error) {
      if (status === 404) {
        setWarning(forgotPW404Msg(isAdmin, showIdInput));
      } else if (code === 110) {
        setWarning(MULTI_USER_MSG);
        if (Array.isArray(errorData?.volunteerAccounts)) {
          setVolunteerOptions(errorData.volunteerAccounts);
        }
      } else setWarning(errorMessage || "An unexpected error occurred");
      return;
    }
    return setMode(LoginModes.emailSent);
  };

  const onSubmit = async (values: GenericObject) => {
    setWarning("");
    const {
      email,
      company_slug,
      campaignOrVolunteerId: id,
      formVolunteerId,
      password,
    } = values;

    const postObj: GenericObject = { email };

    if (mode === LoginModes.login) {
      postObj.password = password;
    } else {
      const redirect = getRedirectUrl(location);
      if (redirect) postObj.redirect = redirect;
    }

    if (type === LoginTypes.admin) {
      postObj.company_slug = company_slug;
    } else {
      // check volunteer_id first because it's the most specific
      if (formVolunteerId) postObj.volunteer_id = Number(formVolunteerId);
      else if (volunteer_id) postObj.volunteer_id = Number(volunteer_id);

      if (campaign_id) postObj.campaign_id = campaign_id;
      if (org_id) postObj.org_id = org_id;
      postObj.campaignOrVolunteerId = id ? Number(id) : null;
    }

    if (mode === LoginModes.login) {
      const res = await APIClient.post<any, APIRes>("/users/login", postObj);
      onLoginRes(res);
    } else {
      const res = await APIClient.post<any, APIRes>(
        "/users/forgot-password",
        postObj,
      );
      onForgotPwRes(res);
    }
  };

  const ssoLogin = async () => {
    setWarning("");
    const res = await APIClient.post<any, APIRes>("/users/login", {
      sso_token,
    });
    onLoginRes(res);
  };

  return (
    <Fragment>
      <Form
        initialValues={{ email: prefillEmail, company_slug: company_slug }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {isForgot && (
                <Fragment>
                  <div className={classes.reset}>Reset password</div>
                  <div className={classes.resetMsg}>
                    Enter your email address, and we'll send you a link to reset
                    your password.
                  </div>
                </Fragment>
              )}

              <Field
                name="email"
                label="Email"
                component={TextFieldWrapper}
                validate={composeValidators(requiredField, emailValidation)}
                className={classes.input}
                size="medium"
                fullWidth
              />

              {!isAdmin && showIdInput && (
                <Fragment>
                  <Field
                    name="campaignOrVolunteerId"
                    label="Your ID"
                    component={TextFieldWrapper}
                    validate={idValidator}
                    size="medium"
                    fullWidth
                    type="number"
                  />
                  <div
                    className={classes.tooltipLink}
                    onClick={() => setShowTooltip(true)}
                  >
                    Need help finding your ID?
                  </div>
                  {showTooltip && (
                    <ClickAwayListener
                      onClickAway={() => setShowTooltip(false)}
                    >
                      <div className={classes.tooltipContainer}>
                        <div className={classes.tooltip}>
                          <div className={classes.ttSubheader}>
                            Students, Sellers & Participants
                          </div>
                          <div className={classes.ttRow}>
                            <div className={classes.ttNum}>1.</div>
                            <div>Check your welcome email for your ID.</div>
                          </div>
                          <div className={classes.ttRow}>
                            <div className={classes.ttNum}>2.</div>
                            <div>
                              If you are already signed in on your browser or
                              another device, you can locate your ID on your
                              dashboard.
                            </div>
                          </div>
                          <div className={classes.ttRow}>
                            <div className={classes.ttNum}>3.</div>
                            <div>
                              If you have your campaign ID, you can use that ID.
                            </div>
                          </div>

                          <div className={classes.ttSubheader}>
                            Sponsors, Organizations & Chairpersons
                          </div>
                          <div className={classes.ttRow}>
                            <div className={classes.ttNum}>1.</div>
                            <div>
                              Check your welcome email for 'Campaign ID'.
                            </div>
                          </div>
                          <div className={classes.ttRow}>
                            <div className={classes.ttNum}>2.</div>
                            <div>
                              If you are signed in on on your browser or another
                              device, you can locate the campaign ID on the
                              dashboard.
                            </div>
                          </div>
                        </div>
                      </div>
                    </ClickAwayListener>
                  )}
                </Fragment>
              )}

              {isAdmin && (
                <Field
                  name="company_slug"
                  label="Company code"
                  component={TextFieldWrapper}
                  className={classes.input}
                  size="medium"
                  fullWidth
                />
              )}

              {!isForgot && (
                <Field
                  name="password"
                  label="Password"
                  component={TextFieldWrapper}
                  validate={requiredField}
                  className={classes.input}
                  size="medium"
                  fullWidth
                  type="password"
                />
              )}

              {warning && (
                <div className={classes.warning}>
                  <ErrorOutlineIcon className={classes.errorIcon} />
                  <div>{warning}</div>
                </div>
              )}

              {!isAdmin &&
                Array.isArray(volunteerOptions) &&
                volunteerOptions.length > 0 && (
                  <Field
                    select
                    name="formVolunteerId"
                    component={TextFieldWrapper}
                    validate={requiredField}
                    className={classes.input}
                    size="medium"
                    fullWidth
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem
                      className={classes.selectOption}
                      value=""
                      disabled
                    >
                      Select account
                    </MenuItem>
                    {volunteerOptions.map(({ volunteer_id, name }) => (
                      <MenuItem key={volunteer_id} value={volunteer_id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Field>
                )}

              <Button
                fullWidth
                type="submit"
                disabled={submitting}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
              >
                {isForgot ? "Send reset link" : "Sign in"}
                <ButtonSpinner show={submitting} />
              </Button>

              <div className={classes.forgotPwWrapper}>
                <div
                  className={classes.link}
                  onClick={
                    isForgot
                      ? () => setMode(LoginModes.login)
                      : () => setMode(LoginModes.forgotPW)
                  }
                >
                  {isForgot ? "Back to login" : "Forgot password?"}
                </div>
              </div>

              {!isAdmin && (
                <div className={classes.otherLogin}>
                  Company Admin or Rep?&nbsp;&nbsp;
                  <span
                    className={classes.link}
                    onClick={() => setType(LoginTypes.admin)}
                  >
                    {isForgot ? "Reset password here" : "Login here"}
                  </span>
                </div>
              )}

              {isAdmin && !isForgot && (
                <div className={classes.otherLogin}>
                  <span
                    className={classes.link}
                    onClick={() => setType(LoginTypes.reg)}
                  >
                    Back to participant login
                  </span>
                </div>
              )}

              <OnChange name="email">{() => setVolunteerOptions([])}</OnChange>
            </form>
          );
        }}
      />
    </Fragment>
  );
}

function forgotPW404Msg(isAdmin: boolean, showIdInput: boolean) {
  let msg = "";
  if (isAdmin) {
    msg =
      "The email address or company code you entered is incorrect. Please check your information and try again.";
  } else if (showIdInput) {
    msg =
      "The email address or ID you entered is incorrect. Please check your information and try again.";
  } else msg = "Email not found";
  return msg;
}

const styles = makeStyles(theme => ({
  input: {
    marginBottom: 24,
  },
  warning: {
    width: "100%",
    maxWidth: "100%",
    overflowWrap: "anywhere",
    backgroundColor: "#FDE9E9",
    minHeight: 48,
    display: "flex",
    padding: "12px 16px",
    color: "#5E0808",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    marginBottom: 14,
    marginTop: -8,
  },
  errorIcon: {
    marginRight: 12,
  },
  button: {
    borderRadius: 21,
  },
  forgotPwWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 7,
    marginBottom: 32,
  },
  link: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "24px",
    display: "inline-block",
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
  },
  otherLogin: {
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "24px",
    color: theme.palette.text.secondary2,
    textAlign: "center",
  },
  selectOption: {
    color: theme.palette.text.secondary2,
  },
  reset: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "18px",
    textAlign: "center",
    paddingBottom: 16,
  },
  resetMsg: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "20px",
    paddingBottom: 24,
  },
  tooltipContainer: {
    width: "100%",
    maxWidth: "100%",
    position: "relative",
    display: "flex",
    justifyContent: "center",
  },
  tooltip: {
    zIndex: 999,
    borderRadius: 8,
    backgroundColor: "#1B47AA",
    color: "#FFFFFF",
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: "18px",
    paddingBottom: 8,
    width: "calc(100% + 16px)",
    maxWidth: "calc(100% + 16px)",
    top: -12,
    padding: 12,
    paddingTop: 4,
    position: "absolute",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% + 32px)",
      maxWidth: "calc(100% + 32px)",
      fontSize: 13,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  ttSubheader: {
    fontWeight: 600,
    paddingBottom: 8,
    paddingTop: 8,
    fontSize: 14,
  },
  ttRow: {
    display: "flex",
    paddingBottom: 6,
  },
  ttNum: {
    width: 40,
    minWidth: 40,
    paddingLeft: 12,
    [theme.breakpoints.down("xs")]: {
      width: 30,
      minWidth: 30,
      paddingLeft: 10,
    },
  },
  tooltipLink: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "24px",
    display: "inline-block",
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: 6,
    marginBottom: 24,
    cursor: "pointer",
  },
}));
