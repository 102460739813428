import { Fragment, useEffect } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Contacts } from "./components/Contacts";
import { Progress } from "./components/VolunteerProgress";
import { Share } from "./components/Share";
import { FamilyMembers } from "./components/FamilyMembers";
import { OrderFormsDue } from "./components/OrderFormsDue";
import { TextOptIn } from "./components/TextOptIn";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/state";
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from "../../types";
import {
  contactActions,
  dashboardDataActions,
  getCampaignInfoFetched,
} from "../../state";
import { VolunteerHeader } from "./components/VolunteerHeader";
import { ShareCardView } from "../../types/dashboard";
import { VolunteerDonations } from "./components/VolunteerDonations";
const FULL_VIEW_MIN_WIDTH = 1200;

export function VolunteerDashboard() {
  const classes = styles();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(({ ui }: RootState) => ui.sidebarOpen);
  const campaignInfoFetched = useSelector(getCampaignInfoFetched);
  const contactsFetched = useSelector(
    ({ contacts }: RootState) => contacts.contactsFetched,
  );
  const display2Cols = useMediaQuery(
    `(min-width: ${FULL_VIEW_MIN_WIDTH +
      (sidebarOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH)}px)`,
  );

  useEffect(() => {
    if (campaignInfoFetched) {
      dispatch(dashboardDataActions.getCampaignProgressStats());
      dispatch(dashboardDataActions.getCampaignDonationStats());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignInfoFetched]);

  useEffect(() => {
    if (!contactsFetched && campaignInfoFetched) {
      dispatch(contactActions.fetchContacts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsFetched, campaignInfoFetched]);

  return (
    <div className={classes.container}>
      <VolunteerHeader />
      {display2Cols && (
        <div className={classes.columns}>
          <div className={classes.leftColumn}>
            <Contacts />
            <Progress />
            <VolunteerDonations />
          </div>
          <div className={classes.rightColumn}>
            <Share view={ShareCardView.volunteer} />
            <FamilyMembers />
            <OrderFormsDue />
            <TextOptIn />
          </div>
        </div>
      )}
      {!display2Cols && (
        <Fragment>
          <Share view={ShareCardView.volunteer} />
          <OrderFormsDue />
          <TextOptIn />
          <FamilyMembers />
          <Contacts />
          <Progress />
          <VolunteerDonations />
        </Fragment>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: 1680,
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "0 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  columns: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  leftColumn: {
    width: "68%",
    marginRight: 32,
  },
  rightColumn: {
    width: "32%",
    minWidth: 315,
  },
}));
