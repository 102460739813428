import { DiscountCardCategoryAutocomplete } from "../../types";
import { discountCardTypes } from "./actions";

type DiscountCardState = {
  discountCardCategoryAutocomplete: DiscountCardCategoryAutocomplete;
};

const initialState: DiscountCardState = {
  discountCardCategoryAutocomplete: [],
};

export const discountCardReducers = (
  state = initialState,
  action: any,
): DiscountCardState => {
  switch (action.type) {
    case discountCardTypes.DISCOUNT_CARD_CATEGORIES_AUTOCOMPLETE_SET:
      return {
        ...state,
        discountCardCategoryAutocomplete: action.data,
      };
    default:
      return state;
  }
};
