import { store } from "../../lib";
import {
  APIUtilAuthClient,
  downloadCsv,
  downloadExcel,
  downloadPdf,
  downloadXml,
} from "../lib";
import { setToast, toggleLoadingDownload } from "../state";
import { APIRes } from "../types";
const GENERIC_ERROR =
  "There was an error downloading your report, please try again later.";

export async function fetchAndDownloadCsv(
  url: string,
  fileName: string,
  CB?: () => void,
  dataProperty?: string,
) {
  store.dispatch(toggleLoadingDownload(true));
  const res = await APIUtilAuthClient.get<any, APIRes>(url);
  const { error, errorMessage, data } = res;
  if (error) {
    store.dispatch(setToast(errorMessage || GENERIC_ERROR));
    store.dispatch(toggleLoadingDownload(false));
  } else {
    const file = dataProperty ? data[dataProperty] : data;
    downloadCsv(file, fileName);
    if (CB) CB();
  }
}

export async function fetchAndDownloadPdf(
  url: string,
  fileName: string,
  CB?: () => void,
) {
  store.dispatch(toggleLoadingDownload(true));
  const res = await APIUtilAuthClient.get<any, APIRes>(url, {
    responseType: "arraybuffer",
    headers: { Accept: "application/pdf, application/json" },
  });
  const { error, errorMessage, data } = res;
  if (error) {
    store.dispatch(setToast(errorMessage || GENERIC_ERROR));
    store.dispatch(toggleLoadingDownload(false));
  } else {
    downloadPdf(data, fileName);
    if (CB) CB();
  }
}

export async function fetchAndDownloadXml(
  url: string,
  fileName: string,
  CB?: () => void,
) {
  store.dispatch(toggleLoadingDownload(true));
  const res = await APIUtilAuthClient.get<any, APIRes>(url, {
    headers: { Accept: "application/xml, application/json" },
  });
  const { error, errorMessage, data } = res;
  if (error) {
    store.dispatch(setToast(errorMessage || GENERIC_ERROR));
    store.dispatch(toggleLoadingDownload(false));
  } else {
    downloadXml(data, fileName);
    if (CB) CB();
  }
}

export async function fetchAndDownloadExcel(
  url: string,
  fileName: string,
  CB?: () => void,
) {
  store.dispatch(toggleLoadingDownload(true));
  const res = await APIUtilAuthClient.get<any, APIRes>(url, {
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
    },
    responseType: "arraybuffer",
  });
  const { error, errorMessage, data } = res;
  if (error) {
    store.dispatch(setToast(errorMessage || GENERIC_ERROR));
    store.dispatch(toggleLoadingDownload(false));
  } else {
    downloadExcel(data, fileName);
    if (CB) CB();
  }
}
