import { SearchFields } from "./list";

export const eventGroupSearchFields: SearchFields = [
  { label: "ID", field: "id", placeholder: "Group ID", isNumber: true },
  { label: "Name", field: "name", placeholder: "Group name" },
];

export const eventGroupSortFields = [
  { field: "id", label: "Group ID" },
  { field: "group_name", label: "Group name" },
  { field: "active", label: "Active" },
  { field: "active_start_date", label: "Active from" },
  { field: "active_end_date", label: "Active until" },
];

export const eventGroupEventSearchFields: SearchFields = [
  { label: "ID", field: "id", placeholder: "Event ID", isNumber: true },
  { label: "Name", field: "name", placeholder: "Event name" },
];

export const eventGroupEventSortFields = [
  { field: "id", label: "Event ID" },
  { field: "event_name", label: "Event name" },
  { field: "active", label: "Active" },
  { field: "location_name", label: "Location" },
  { field: "date", label: "Date" },
  { field: "time", label: "Time" },
];

export type CampaignEventAutocomplete = {
  event_id: number;
  event_name: string;
}[];
