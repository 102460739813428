import { Fragment } from "react";
import { VolunteerFilterFields, ListProps } from "../../../types";
import { FiltersDrawer, ToggleFilter } from "../../../components";
import { useSelector } from "react-redux";
import { getVolunteerLabel } from "../../../state";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function Filters({ onClose, listProps }: Props) {
  const { volunteerLabelLC } = useSelector(getVolunteerLabel);

  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header={`Filter ${volunteerLabelLC}`}
    >
      <Fragment>
        <ToggleFilter
          header="Account status"
          truthyLabel="Activated"
          falsyLabel="Not activated"
          truthyChipText="Activated accounts"
          falsyChipText="Not activated accounts"
          field={VolunteerFilterFields.activated}
          listProps={listProps}
        />
        <ToggleFilter
          header="Webpage status"
          truthyLabel="Enabled"
          falsyLabel="Disabled"
          truthyChipText="Webpage enabled"
          falsyChipText="Webpage disabled"
          field={VolunteerFilterFields.webpage_enabled}
          listProps={listProps}
        />
        <ToggleFilter
          header="Contacts confirmed"
          truthyLabel="Yes"
          falsyLabel="No"
          truthyChipText="Contacts confirmed"
          falsyChipText="Contacts not confirmed"
          field={VolunteerFilterFields.contacts_confirmed}
          listProps={listProps}
        />
        <ToggleFilter
          header="Has valid contacts"
          truthyLabel="Yes"
          falsyLabel="No"
          truthyChipText="Has valid contacts"
          falsyChipText="No valid contacts"
          field={VolunteerFilterFields.has_valid_contacts}
          listProps={listProps}
        />
        <ToggleFilter
          header="Has invalid contacts"
          truthyLabel="Yes"
          falsyLabel="No"
          truthyChipText="Has invalid contacts"
          falsyChipText="No invalid contacts"
          field={VolunteerFilterFields.has_invalid_contacts}
          listProps={listProps}
        />
      </Fragment>
    </FiltersDrawer>
  );
}
