import React, { Fragment, useState, useEffect } from "react";
import { useNotify, downloadCSV } from "react-admin";
import {
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  TableFooter,
  MenuItem,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { useSelector } from "react-redux";
import jsonExport from "jsonexport/dist";
import { APIClient, generateAuthHeader } from "../../../lib";
import { getSupplierHasDistributors } from "../../../customState";
import { ProductGroups } from "./ProductGroups";
import { Product } from "./Product";
import { ConvertToPOModal } from "./ConvertToPOModal";

export function Projections({ record }) {
  const { id: supplier_id } = record;
  const classes = styles();
  const defaultCompanySeasonId = useSelector(
    state => state.user.defaultCompanySeasonId,
  );
  const companySeasonOptions = useSelector(
    state => state.user.companySeasonOptions,
  );
  const supplierHasDistributors = useSelector(getSupplierHasDistributors);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [groups, setGroups] = useState({});
  const [sources, setSources] = useState({});
  const [orderedProducts, setOrderedProducts] = useState({});
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [seasonId, setSeasonId] = useState("");
  const [seasonIdIsSet, setSeasonIdIsSet] = useState(false);
  const [excludeDistributors, setExcludeDistributors] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [projectionPercent, setProjectionPercent] = useState(100);
  const [rerenderTrigger, setRerenderTrigger] = useState(1);
  const [POSourceData, setPOSourceData] = useState({});
  const [showPODialog, setShowPODialog] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    if (defaultCompanySeasonId) setSeasonId(defaultCompanySeasonId);
    setSeasonIdIsSet(true);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCompanySeasonId]);

  useEffect(() => {
    if (supplier_id && seasonIdIsSet) {
      setSources({});
      setOrderedProducts({});
      setGroups({});
      fetchProjections();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id, seasonIdIsSet, seasonId, excludeDistributors]);

  useEffect(() => {
    if (Array.isArray(companySeasonOptions) && companySeasonOptions.length) {
      setSeasonOptions(companySeasonOptions);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySeasonOptions]);

  useEffect(() => {
    calculateProjections();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoaded, projectionPercent]);

  const fetchProjections = async () => {
    setDataLoaded(false);
    const res = await APIClient.get(
      `/projections/${supplier_id}?season_id=${seasonId}&exclude_supplier_distributors=${excludeDistributors}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    const { groups, sources, orderedProducts: _orderedProducts } = data;
    setGroups(groups);
    setOrderedProducts(_orderedProducts);
    setSources(sources);
    setDataLoaded(true);
  };

  const calculateProjections = () => {
    // calc group figures
    Object.keys(groups).forEach(groupId => {
      const {
        studentCount,
        completedItemsPerStudent,
        customProjectedItems,
      } = groups[groupId];
      const calculatedProjectedItems = calcProjectedItems(
        projectionPercent,
        studentCount,
        completedItemsPerStudent,
      );
      groups[groupId].calculatedProjectedItems = calculatedProjectedItems;
      groups[groupId].projectedItems = customProjectedItems
        ? customProjectedItems
        : calculatedProjectedItems;
    });

    // calc product/group-product figures
    Object.keys(sources).forEach(sourceId => {
      orderedProducts[sourceId].forEach(productId => {
        const { fulfilled, onHand, onOrder } = sources[sourceId][productId];
        let projectedProductItems = 0;
        Object.keys(sources[sourceId][productId].groups).forEach(groupId => {
          if (!groups[groupId]) return;
          const projectedItems = groups[groupId].projectedItems;
          const { completedPercent } = sources[sourceId][productId].groups[
            groupId
          ];
          const projectedGroupProductItems = Math.round(
            (Number(completedPercent) / 100) * projectedItems,
          );
          sources[sourceId][productId].groups[
            groupId
          ].projectedItems = projectedGroupProductItems;
          projectedProductItems =
            projectedProductItems + projectedGroupProductItems;
        });
        sources[sourceId][productId].needed =
          projectedProductItems - fulfilled - onHand - onOrder;
        sources[sourceId][productId].projectedItems = projectedProductItems;
      });
    });
    setRerenderTrigger(rerenderTrigger + 1);
  };

  const handlePercentChange = e => setProjectionPercent(e.target.value);

  const downloadProducts = () => {
    const rows = [];
    Object.keys(sources).forEach(id => {
      const sourceName = sources[id]?.sourceName;
      orderedProducts[id].forEach(pId => {
        const {
          sku,
          product_name,
          sold,
          allocated,
          completedSold,
          fulfilled,
          items_per_case,
          onHand,
          onOrder,
          projectedItems,
          needed,
          stock_qty,
        } = sources[id][pId];
        rows.push({
          Source: sourceName,
          Name: product_name,
          Sku: sku,
          "Ttl Sold": sold,
          "Completed Sold": completedSold,
          Fulfilled: `${fulfilled}`,
          Allocated: allocated,
          "Curr Stock": stock_qty,
          "On Hand": onHand,
          "On Order": `${onOrder}`,
          "Projected Items": projectedItems,
          Needed: needed,
          "Case Count": items_per_case,
        });
      });
    });

    jsonExport(rows, (_err, csv) => {
      downloadCSV(csv, `Projections - Products ${projectionPercent}%`);
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.topActions}>
        {seasonOptions.length > 0 && (
          <TextField
            select
            label="Season"
            value={seasonId}
            className={classes.seasonSelect}
            onChange={({ target }) => setSeasonId(target.value)}
            SelectProps={{ displayEmpty: true }}
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="">All Seasons</MenuItem>
            {seasonOptions.map(({ id, season_name }) => (
              <MenuItem key={id} value={id}>
                {season_name}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          label="projection %"
          value={projectionPercent}
          type="number"
          onChange={handlePercentChange}
        />
        {supplierHasDistributors && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={excludeDistributors}
                onChange={({ target }) =>
                  setExcludeDistributors(target.checked)
                }
              />
            }
            label="Exclude Distributors"
          />
        )}
      </div>

      <ProductGroups
        groups={groups}
        sources={sources}
        orderedProducts={orderedProducts}
        rerenderTrigger={rerenderTrigger} // to cause a rerender when projections are recalculated
        calculateProjections={calculateProjections}
        projectionPercent={projectionPercent}
      />
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        elevation={6}
      >
        <Table size="small" className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormControlLabel
                  className={classes.checkboxFC}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      checked={expandAll}
                      onChange={({ target }) => setExpandAll(target.checked)}
                    />
                  }
                  label="expand"
                  labelPlacement="top"
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Sku</TableCell>
              <TableCell>Ttl Sold</TableCell>
              <TableCell>Completed Sold</TableCell>
              <TableCell>Fulfilled</TableCell>
              <TableCell>Allocated</TableCell>
              <TableCell>Curr Stock</TableCell>
              <TableCell>On Hand</TableCell>
              <TableCell>On Order</TableCell>
              <TableCell>Projected Items</TableCell>
              <TableCell>Needed</TableCell>
              <TableCell>Case Count</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Object.keys(sources).map(id => {
              return (
                <Fragment key={id}>
                  <TableRow className={classes.sourceRow}>
                    <TableCell />
                    <TableCell className={classes.source}>
                      {sources[id]?.sourceName}
                    </TableCell>
                    {Number(id) > 0 && (
                      <TableCell colSpan={11} align="right">
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            setPOSourceData({ source_id: id, ...sources[id] });
                            setShowPODialog(true);
                          }}
                        >
                          generate source PO
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                  {orderedProducts[id].map(pId => {
                    return (
                      <Product
                        key={pId}
                        product={sources[id][pId]}
                        expandAll={expandAll}
                        rerenderTrigger={rerenderTrigger} // to cause a rerender when projections are recalculated
                      />
                    );
                  })}
                </Fragment>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={13}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={downloadProducts}
                  startIcon={<DownloadIcon />}
                >
                  export
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ConvertToPOModal
        open={showPODialog}
        toggle={setShowPODialog}
        sourceData={POSourceData}
        supplier_id={supplier_id}
      />
    </div>
  );
}

const styles = makeStyles(
  theme => ({
    container: {
      minHeight: 400,
      maxWidth: "100vw",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      margin: "18px 0",
    },
    topActions: {
      marginBottom: 16,
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
    seasonSelect: {
      width: 200,
    },
    table: {
      overflowY: "auto",
      overflowX: "auto",
    },
    tableContainer: {
      maxHeight: "calc(100vh - 120px)",
    },
    sourceRow: {
      "& > *": {
        borderBottom: "unset",
      },
    },
    source: {
      color: theme.palette.primary.main,
      fontSize: 16,
      textTransform: "uppercase",
      fontWeight: 600,
    },
    checkboxFC: {
      marginLeft: 0,
      marginRight: 0,
    },
    checkbox: {
      padding: 0,
    },
  }),
  { classNamePrefix: "projections" },
);

function calcProjectedItems(_percent, _students, itemsPerStudent = 0) {
  const percent = !_percent ? 100 : Number(_percent);
  const students = _students ? Number(_students) : 0;
  const studentsToCalc = (percent / 100) * students;
  return Math.round(studentsToCalc * Number(itemsPerStudent));
}
