import { makeStyles, Button } from "@material-ui/core";
import { StartSVG } from "../SVGs/StartSVG";

export function Start({ setShowStartScreen }) {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <StartSVG />
        <h1 className={classes.header}>
          Let's set up your personal fundraising page
        </h1>
        <Button
          color="primary"
          className={classes.button}
          onClick={() => setShowStartScreen(false)}
        >
          Let's go!
        </Button>
      </div>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
    height: "100%",
  },
  container: {
    width: 532,
    padding: 16,
    maxWidth: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  header: {
    fontSize: 34,
    letterSpacing: 0.25,
    lineHeight: "42px",
    textAlign: "center",
    marginTop: 60,
    marginBottom: 32,
  },
  button: {
    width: 300,
    borderRadius: 18,
    letterSpacing: 0.3,
  },
}));
