import { Theme, makeStyles, useMediaQuery } from "@material-ui/core";
import MoneyIcon from "@material-ui/icons/MonetizationOnOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import { Skeleton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { getIsProductCampaign } from "../../../state";
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPEN_WIDTH } from "../../../types";
import classNames from "classnames";
import { Fragment } from "react";
import { ProductsIconSVG } from "../../../assets/ProductsIconSVG";
import { formatMoneyUnPadded, formatNumber } from "../../../lib";
const ONE_ROW_MIN_WIDTH = 1400;

export function DonationStats() {
  const isProduct = useSelector(getIsProductCampaign);
  const sidebarOpen = useSelector(({ ui }: RootState) => ui.sidebarOpen);
  const statsFetched = useSelector(
    ({ dashboardData }: RootState) =>
      dashboardData.campaignDonationStatsFetched,
  );
  const {
    donationCount,
    totalRaised,
    productCount,
    productTotalWithoutDonation,
    productDonationTotal,
    productPercent,
  } = useSelector(
    ({ dashboardData }: RootState) => dashboardData.campaignDonationStats,
  );
  const oneRow = useMediaQuery(
    `(min-width: ${ONE_ROW_MIN_WIDTH +
      (sidebarOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH)}px)`,
  );
  const classes = styles({ oneRow: Boolean(!isProduct || oneRow) });

  return (
    <div className={classes.cards}>
      {!isProduct && (
        <Fragment>
          {!statsFetched && (
            <Fragment>
              <Skeleton
                variant="rect"
                className={classNames(
                  classes.donationCard,
                  classes.leftCard,
                  classes.skeleton,
                )}
              />
              <Skeleton
                variant="rect"
                className={classNames(
                  classes.donationCard,
                  classes.rightCard,
                  classes.skeleton,
                )}
              />
            </Fragment>
          )}

          {statsFetched && (
            <Fragment>
              <div
                className={classNames(classes.donationCard, classes.leftCard)}
              >
                <div>
                  <PeopleIcon fontSize="large" className={classes.icon} />
                  <div className={classes.figure}>
                    {formatNumber(donationCount)}
                  </div>
                  <div className={classes.label}>Donations</div>
                </div>
              </div>
              <div
                className={classNames(classes.donationCard, classes.rightCard)}
              >
                <div>
                  <MoneyIcon fontSize="large" className={classes.icon} />
                  <div className={classes.figure}>
                    {formatMoneyUnPadded(totalRaised)}
                  </div>
                  <div className={classes.label}>Total raised</div>
                </div>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      {isProduct && (
        <Fragment>
          {!statsFetched && (
            <Fragment>
              <Skeleton
                variant="rect"
                className={classNames(
                  classes.productCard,
                  classes.leftCard,
                  classes.skeleton,
                )}
              />
              <Skeleton
                variant="rect"
                className={classNames(
                  classes.productCard,
                  classes.rightCard,
                  classes.skeleton,
                )}
              />
              <Skeleton
                variant="rect"
                className={classNames(
                  classes.productCard,
                  classes.productTotalCard,
                  classes.skeleton,
                )}
              />
            </Fragment>
          )}

          {statsFetched && (
            <Fragment>
              <div
                className={classNames(classes.productCard, classes.leftCard)}
              >
                <div>
                  <PeopleIcon fontSize="large" className={classes.icon} />
                  <div className={classes.figure}>
                    {formatNumber(donationCount)}
                  </div>
                  <div className={classes.label}>Supporters</div>
                </div>
              </div>

              <div
                className={classNames(classes.productCard, classes.rightCard)}
              >
                <div>
                  <div className={classes.icon}>
                    <ProductsIconSVG />
                  </div>
                  <div className={classes.figure}>
                    {formatNumber(productCount)}
                  </div>
                  <div className={classes.label}>Products sold</div>
                </div>
              </div>

              <div
                className={classNames(
                  classes.productCard,
                  classes.productTotalCard,
                )}
              >
                <div>
                  <MoneyIcon fontSize="large" className={classes.icon} />
                  <div className={classes.figure}>
                    {formatMoneyUnPadded(totalRaised)}
                  </div>
                  <div className={classes.label}>Total raised</div>
                </div>
                {(totalRaised as number) > 0 && (
                  <div className={classes.pieColumn}>
                    <div
                      className={classes.pie}
                      style={{
                        backgroundImage: `conic-gradient(#FFD1D2 ${productPercent}%, #FD7B6F ${productPercent}% 100%)`,
                      }}
                    />
                    <div>
                      <div className={classes.keyRow}>
                        <div className={classes.purchaseKey} />
                        <div className={classes.keyLabel}>Purchases</div>
                        <div className={classes.keyFigure}>
                          {formatMoneyUnPadded(productTotalWithoutDonation)}
                        </div>
                      </div>
                      <div className={classes.keyRow}>
                        <div className={classes.donationKey} />
                        <div className={classes.keyLabel}>Donations</div>
                        <div className={classes.keyFigure}>
                          {formatMoneyUnPadded(productDonationTotal)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
}

type StyleProps = {
  oneRow: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  cards: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: ({ oneRow }) => (oneRow ? "nowrap" : "wrap"),
  },
  productCard: {
    height: 160,
    width: ({ oneRow }) => (oneRow ? "calc(24% - 12px)" : "calc(50% - 12px)"),
    borderRadius: 8,
    backgroundColor: "#FFF4F4",
    display: "flex",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      width: () => "calc(50% - 7px)",
      height: "unset",
    },
  },
  donationCard: {
    width: "calc(50% - 12px)",
    height: 160,
    borderRadius: 8,
    backgroundColor: "#FFF4F4",
    display: "flex",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      width: "calc(50% - 7px)",
      padding: 16,
      height: "unset",
    },
  },
  leftCard: {
    marginRight: 12,
    [theme.breakpoints.down("sm")]: {
      marginRight: 7,
    },
  },
  rightCard: {
    marginLeft: 12,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 7,
    },
  },
  productTotalCard: {
    marginLeft: ({ oneRow }) => (oneRow ? 24 : 0),
    marginTop: ({ oneRow }) => (oneRow ? 0 : 24),
    width: ({ oneRow }) => (oneRow ? "calc(52% - 24px)" : "100%"),
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: () => 16,
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  skeleton: {
    backgroundColor: "#F0F0F0 !important",
  },
  icon: {
    color: theme.palette.secondary2.main,
    marginBottom: 12,
  },
  figure: {
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "35px",
    marginBottom: 12,
  },
  label: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "13px",
  },
  pieColumn: {
    marginLeft: 8,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      display: "flex",
      alignItems: "center",
      marginTop: 24,
      paddingTop: 24,
      borderTop: "1px solid #DBDEEE",
    },
  },
  pie: {
    width: 48,
    height: 48,
    borderRadius: "50%",
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      marginRight: 24,
    },
  },
  keyRow: {
    display: "flex",
    alignItems: "center",
    paddingTop: 8,
    [theme.breakpoints.down("sm")]: {
      padding: "4px 0",
    },
  },
  keyLabel: {
    marginLeft: 8,
    marginRight: 8,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
  },
  keyFigure: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "16px",
  },
  purchaseKey: {
    height: 14,
    width: 14,
    backgroundColor: "#FFD1D2",
  },
  donationKey: {
    height: 14,
    width: 14,
    backgroundColor: theme.palette.secondary2.main,
  },
}));
