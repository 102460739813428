import { Theme, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import classNames from "classnames";
import { GenericObject } from "../../../types";

const desktopSettings = {
  accessibility: false,
  dots: false,
  arrows: false,
  infinite: true,
  focusOnSelect: false,
  swipeToSlide: false,
  swipe: false,
  draggable: false,
  touchMove: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 0,
  cssEase: "linear",
  pauseOnHover: false,
  vertical: true,
};
const mobileSettings = {
  accessibility: false,
  arrows: false,
  dots: false,
  centerMode: true,
  infinite: true,
  focusOnSelect: false,
  swipeToSlide: false,
  swipe: false,
  draggable: false,
  touchMove: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  variableWidth: true,
  speed: 3000,
  autoplaySpeed: 0,
  cssEase: "linear",
  pauseOnHover: false,
};

export function Teams({ teams }: { teams: GenericObject[] }) {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { secondary_color } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const [secondaryColor, setSecondaryColor] = useState<string>("inherit");
  const [carousel, setCarousel] = useState<GenericObject[]>([]);

  useEffect(() => {
    if (teams.length > 4) setCarousel(teams);
    else {
      setCarousel([...teams, ...teams, ...teams, ...teams, ...teams]);
    }
  }, [teams]);

  useEffect(() => {
    if (secondary_color) setSecondaryColor(secondary_color);
  }, [secondary_color]);

  const classes = styles({ secondaryColor });
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Teams</h2>
      <div
        className={classNames(
          classes.sliderWrapper,
          isMobile && "even-height-slick", // DON"T CHANGE - USED FOR CUSTOM CSS
        )}
      >
        <Slider
          {...(isDesktop ? desktopSettings : {})}
          {...(isMobile ? mobileSettings : {})}
        >
          {carousel.map((team, i) => {
            return (
              <div key={i} className={classes.cardWrapper}>
                <div className={classes.card}>
                  <div className={classes.name}>Team {team.team_name}</div>
                  <div className={classes.amount}>${team.total}</div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

type StyleProps = {
  secondaryColor?: string;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: "100%",
    height: 432,
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    padding: "24px 24px 0 24px",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      marginBottom: 24,
      marginTop: 16,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "unset",
      height: "fit-content",
      padding: 0,
      marginBottom: 0,
      marginTop: 22,
    },
  },
  title: {
    marginBottom: 24,
    color: ({ secondaryColor }) => secondaryColor,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.3,
    lineHeight: "26px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      color: () => "#FFFFFF",
    },
  },
  sliderWrapper: {
    overflow: "hidden",
    maxHeight: "fit-content",
  },
  cardWrapper: {
    outline: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flex: 1,
  },
  card: {
    minHeight: 96,
    width: "100%",
    height: "100%",
    borderRadius: 8,
    backgroundColor: "#EFEFEF",
    padding: 16,
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: 200,
      minHeight: 88,
      marginBottom: 0,
      marginRight: 16,
      backgroundColor: "#FFFFFF",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  name: {
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0.3,
    lineHeight: "35px",
    textAlign: "center",
    paddingBottom: 8,
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "18px",
      textAlign: "left",
    },
  },
  amount: {
    color: ({ secondaryColor }) => secondaryColor,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.23,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.15,
    },
  },
}));
