import { Button, IconButton, makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../../hooks/ui";
import classNames from "classnames";
import FilterListIcon from "@material-ui/icons/FilterList";

type Props = {
  onClick: () => void;
  active: boolean;
};
export function FilterDrawerButton({ onClick, active }: Props) {
  const isMobile = useIsMobile();
  const classes = styles();

  if (isMobile) {
    return (
      <IconButton
        onClick={onClick}
        className={classNames(active && classes.activeFiltersBtn)}
      >
        <FilterListIcon color={active ? "primary" : "inherit"} />
      </IconButton>
    );
  }
  return (
    <Button
      color="primary"
      variant="text"
      startIcon={<FilterListIcon />}
      onClick={onClick}
      className={classNames(
        classes.filterBtn,
        active && classes.activeFiltersBtn,
      )}
    >
      FILTERS
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  filterBtn: {
    borderRadius: 18,
    width: 96,
  },
  activeFiltersBtn: {
    backgroundColor: "#EEF6FE",
    "&:hover": {
      opacity: 0.8,
      backgroundColor: "#EEF6FE",
    },
  },
}));
