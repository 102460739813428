import { useEffect, useState, useMemo } from "react";
import { MenuItem, makeStyles, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { APIRes, GuidedSetupScreen } from "../../../types";
import { APIAuthClient } from "../../../lib";
import {
  getVolunteerCampaignId,
  setToast,
  updateVolunteerSetupData,
} from "../../../state";
import { sharedStyles } from "../shared.styles";
import { ClassSVG } from "../SVGs/ClassSVG";
import { RootState } from "../../../types/state";

export function Class(props: GuidedSetupScreen) {
  const {
    activeScreen,
    setDisableNext,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setSubmitting,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const { class_name: currClass, class_options: classOptions } = useSelector(
    (state: RootState) => state.volunteerSetup,
  );
  const volunteerCampaignId = useSelector(getVolunteerCampaignId);
  const [className, setClassName] = useState("");
  const [classList, setClassList] = useState<string[]>([]);

  useEffect(() => {
    if (activeScreen === "CLASS") {
      setClassName(currClass ? currClass : "");
    }
  }, [activeScreen, currClass]);

  useEffect(() => {
    if (Array.isArray(classOptions)) setClassList(classOptions);
  }, [classOptions]);

  useEffect(() => {
    if (activeScreen === "CLASS") {
      if (!className) setDisableNext(true);
      else setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen, className]);

  useEffect(() => {
    if (nextTriggeredBy === "CLASS") {
      submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  const submit = useMemo(
    () => async () => {
      setSubmitting(true);
      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaigns/${volunteerCampaignId}`,
        { class_name: className },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      const _class = data.class_name;
      dispatch(updateVolunteerSetupData({ class_name: _class }));
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [className, volunteerCampaignId],
  );

  if (activeScreen !== "CLASS") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <ClassSVG />
      <h1 className={sharedClasses.screenHeader}>Select your class</h1>
      <div className={classes.contentSpacer} />
      <TextField
        select
        value={className}
        onChange={({ target }) => setClassName(target.value)}
        className={classes.select}
      >
        {classList.map((className, index) => (
          <MenuItem key={index} value={className}>
            {className}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  contentSpacer: {
    paddingBottom: 64,
  },
  select: { width: 200 },
}));
