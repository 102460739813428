import React, { Fragment, useState } from "react";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import {
  APIClient,
  APIUtilClient,
  downloadPdf,
  downloadXlsx,
  generateAuthHeader,
} from "../../lib";

export function StudentNumbersExport({
  campaign_id,
  renderMenuItem = false,
  bigKahunaSmallOrders = false,
}) {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [reportType, setReportType] = useState(
    bigKahunaSmallOrders ? "excel" : "csv",
  );
  const [order, setOrder] = useState("class");
  const [maxItems, setMaxItems] = useState("5");
  const label = bigKahunaSmallOrders
    ? "Small orders list"
    : "Student delivery numbers";

  const handleChange = event => {
    setReportType(event.target.value);
  };

  const downloadReport = async () => {
    setLoading(true);

    if (reportType === "csv") await getCsv();
    else if (bigKahunaSmallOrders && reportType === "excel") {
      await getSmallOrders();
    } else if (reportType === "excel") await getExcel();
    else await getPdf();

    toggleDialog();
    setLoading(false);
  };

  const getCsv = async () => {
    const url = `/reports/student-numbers-csv?campaign_id=${campaign_id}&_sort=${order}`;
    const res = await APIClient.get(url, { headers: generateAuthHeader() });

    const { error, data, errorMessage } = res;
    if (error) return alert(errorMessage);

    jsonExport(data, (err, csv) => {
      downloadCSV(csv, label);
    });
  };

  const getPdf = async () => {
    const res = await APIUtilClient.get(
      `/reports/student-numbers-pdf?campaign_id=${campaign_id}&_sort=${order}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    downloadPdf(res, `${label}.pdf`);
  };

  const getExcel = async () => {
    let url = `/xlsx_reports/student_numbers?campaign_id=${campaign_id}&_sort=${order}`;
    const res = await APIUtilClient.get(url, {
      responseType: "arraybuffer",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
        ...generateAuthHeader(),
      },
    });

    downloadXlsx(res, `${label}.XLSX`);
  };

  const getSmallOrders = async () => {
    let url = `/xlsx_reports/big_kahuna_small_orders?campaign_id=${campaign_id}&_sort=${order}`;
    if (maxItems) url = `${url}&max=${maxItems}`;
    const res = await APIUtilClient.get(url, {
      responseType: "arraybuffer",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
        ...generateAuthHeader(),
      },
    });

    downloadXlsx(res, "Small Orders List.XLSX");
  };

  const toggleDialog = () => {
    setShowDialog(prev => !prev);
  };

  return (
    <Fragment>
      {renderMenuItem ? (
        <MenuItem onClick={toggleDialog}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={label} />
        </MenuItem>
      ) : (
        <Button
          size="small"
          variant="outlined"
          startIcon={<DownloadIcon />}
          color="primary"
          onClick={toggleDialog}
          className={classes.button}
        >
          {label}
        </Button>
      )}
      <Dialog open={showDialog}>
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <DialogContentText>Select your preferences</DialogContentText>
          {!bigKahunaSmallOrders && (
            <RadioGroup value={reportType} onChange={handleChange} row>
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
              <FormControlLabel
                value="excel"
                control={<Radio />}
                label="Excel"
              />
            </RadioGroup>
          )}
          {bigKahunaSmallOrders && (
            <TextField
              className={classes.input}
              label="Max Ship To School Items"
              value={maxItems}
              onChange={e => setMaxItems(e.target.value)}
              type="number"
            />
          )}
          <div>
            <TextField
              className={classes.input}
              select
              label="Order Report By"
              value={order}
              onChange={e => setOrder(e.target.value)}
            >
              <MenuItem value="last_name">Last Name</MenuItem>
              <MenuItem value="class">Class</MenuItem>
              <MenuItem value="student_number">Student Number</MenuItem>
            </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={downloadReport}>
            download
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  input: {
    width: "100%",
    marginTop: 24,
  },
  button: { marginBottom: 6 },
}));
