import React, { useEffect, useState } from "react";
import {
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  DateField,
  useNotify,
  TabbedShowLayoutTabs,
} from "react-admin";
import { useSelector } from "react-redux";
import { APIClient, generateAuthHeader, isFulfiller } from "../../lib";
import { ShipToHomeOrderList } from "./ShipToHomeOrderList";
import { ShipToSchoolStudentOrderList } from "./ShipToSchoolStudentOrderList";
import { WholesaleInvoice } from "./wholesaleInvoice/WholesaleInvoice";
import { CampaignReplacements } from "./CampaignReplacements";
import { BackOrderedCampaignProducts } from "./BackOrderedCampaignProducts";
import { CampaignManagement } from "./CampaignManagement";
import { styles } from "./OrderFulfillment.styles";

const Title = ({ record = {} }) => {
  const { id, companyName, orgName } = record;
  return `Fulfillment - Campaign ID: ${id} Org: ${orgName} CO: ${companyName} `;
};

export default function CampaignOrderFulfillmentDetails(props) {
  const supplier_id = useSelector(state => state.user.supplier_id);
  const notify = useNotify();
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    if (supplier_id) fetchProductWarehouses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const fetchProductWarehouses = async () => {
    const res = await APIClient.get(
      `/product_warehouses/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setWarehouses(data);
  };

  return (
    <Show {...props} title={<Title />} actions={null}>
      <Tabs warehouses={warehouses} />
    </Show>
  );
}

function Tabs({ warehouses, record = {}, ...props }) {
  const { prizeFulfillmentOnly } = record;
  const role = useSelector(state => state.user.role);
  return (
    <TabbedShowLayout
      {...props}
      record={record}
      tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />}
    >
      <Tab label="Campaign Info" path="">
        <TextField source="id" label="Campaign ID" />
        <TextField source="org_id" label="Org ID" />
        <TextField source="orgName" label="Org Name" />
        <TextField source="orgAddress" label="Org Address" />
        <TextField source="orgCity" label="Org City" />
        <TextField source="orgState" label="Org State" />
        <TextField source="orgZip" label="Org Zip" />
        <TextField source="companyName" label="Company" />
        <DateField source="start_date" label="Start Date" />
        <DateField source="end_date" label="End Date" />
      </Tab>
      {!prizeFulfillmentOnly && (
        <Tab label="Ship to home" path="ship-to-home">
          <ShipToHomeOrderList
            campaign_id={record.id}
            isForCampaign
            resourceName="order_fulfillment/by_campaign/home"
          />
        </Tab>
      )}
      <Tab label="Ship to school" path="ship-to-school">
        <ShipToSchoolStudentOrderTab warehouses={warehouses} />
      </Tab>
      {!prizeFulfillmentOnly && !isFulfiller(role) && (
        <Tab label="Wholesale invoice" path="wholesale-invoice">
          <WholesaleInvoice />
        </Tab>
      )}
      <Tab label="Replacements" path="replacements">
        <CampaignReplacements campaign_id={record.id} />
      </Tab>
      {!prizeFulfillmentOnly && (
        <Tab label="Back Order" path="back-order">
          <BackOrderedCampaignProducts campaign_id={record.id} />
        </Tab>
      )}
      {!prizeFulfillmentOnly && !isFulfiller(role) && (
        <Tab label="Management" path="management">
          <CampaignManagement />
        </Tab>
      )}
    </TabbedShowLayout>
  );
}

const ShipToSchoolStudentOrderTab = props => {
  const classes = styles();
  const {
    record: {
      shipToSchoolMissingStudentCount,
      id,
      prizeFulfillmentOnly,
      printStsLabelsAtFulfillment,
    } = {},
    warehouses,
  } = props;

  if (shipToSchoolMissingStudentCount)
    return (
      <div className={classes.missingStudents}>
        <div>
          {shipToSchoolMissingStudentCount} ship to school orders are missing
          students.
        </div>
        <div>
          School order can't be fulfilled until all orders are associated with a
          student.
        </div>
      </div>
    );

  return (
    <ShipToSchoolStudentOrderList
      campaign_id={id}
      warehouses={warehouses}
      prizeFulfillmentOnly={prizeFulfillmentOnly}
      printStsLabelsAtFulfillment={printStsLabelsAtFulfillment}
    />
  );
};
