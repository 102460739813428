export function FamilyMembersSVG() {
  return (
    <svg width="34px" height="34px" viewBox="0 0 34 34">
      <title>Family members</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-41, -459)" fill="#248BEF">
          <g transform="translate(16, 396)">
            <g transform="translate(16, 54)">
              <g transform="translate(9.36, 9.36)">
                <g transform="translate(2.7733, 2.7733)">
                  <path d="M19.4133334,2.77333334 C19.4133334,1.23413334 20.6474668,0 22.1866668,0 C23.7258668,0 24.9600001,1.23413334 24.9600001,2.77333334 C24.9600001,4.31253335 23.7258668,5.54666669 22.1866668,5.54666669 C20.6474668,5.54666669 19.4133334,4.31253335 19.4133334,2.77333334 Z M24.9600001,27.7333334 L24.9600001,19.4133334 L28.4266668,19.4133334 L24.9045334,8.8330667 C24.5162668,7.69600003 23.4624001,6.93333336 22.2698668,6.93333336 L22.1034668,6.93333336 C20.9109334,6.93333336 19.8432001,7.69600003 19.4688001,8.8330667 L18.2762667,12.4106667 C19.7738667,13.2426667 20.8000001,14.8096001 20.8000001,16.6400001 L20.8000001,27.7333334 L24.9600001,27.7333334 Z M14.5600001,13.1733334 C15.7109334,13.1733334 16.6400001,12.2442667 16.6400001,11.0933334 C16.6400001,9.94240004 15.7109334,9.01333337 14.5600001,9.01333337 C13.4090667,9.01333337 12.4800001,9.94240004 12.4800001,11.0933334 C12.4800001,12.2442667 13.4090667,13.1733334 14.5600001,13.1733334 Z M4.85333335,5.54666669 C6.39253336,5.54666669 7.6266667,4.31253335 7.6266667,2.77333334 C7.6266667,1.23413334 6.39253336,0 4.85333335,0 C3.31413335,0 2.08000001,1.23413334 2.08000001,2.77333334 C2.08000001,4.31253335 3.31413335,5.54666669 4.85333335,5.54666669 Z M7.6266667,27.7333334 L7.6266667,18.0266667 L9.70666671,18.0266667 L9.70666671,9.70666671 C9.70666671,8.18133337 8.4586667,6.93333336 6.93333336,6.93333336 L2.77333334,6.93333336 C1.24800001,6.93333336 0,8.18133337 0,9.70666671 L0,18.0266667 L2.08000001,18.0266667 L2.08000001,27.7333334 L7.6266667,27.7333334 Z M16.6400001,27.7333334 L16.6400001,22.1866668 L18.0266667,22.1866668 L18.0266667,16.6400001 C18.0266667,15.5029334 17.0837334,14.5600001 15.9466667,14.5600001 L13.1733334,14.5600001 C12.0362667,14.5600001 11.0933334,15.5029334 11.0933334,16.6400001 L11.0933334,22.1866668 L12.4800001,22.1866668 L12.4800001,27.7333334 L16.6400001,27.7333334 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
