import React, { useEffect, useState } from "react";
import { useNotify, Notification } from "react-admin";
import { Card, MenuItem, makeStyles, Button } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { useSelector } from "react-redux";
import {
  APIClient,
  generateAuthHeader,
  isCompanyOrCompanyAdminOrRep,
  required,
} from "../../lib";

export function NoPrizeProgram({ campaign_id, refresh }) {
  const classes = styles();
  const notify = useNotify();
  const isAdmin = useSelector(state =>
    isCompanyOrCompanyAdminOrRep(state.user.role),
  );
  const canManage = useSelector(state => state.user.canManage);
  const [prizePrograms, setPrizePrograms] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/campaign_types/company_authorized", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      const { prizePrograms } = data;
      setPrizePrograms(prizePrograms);
    };
    if (isAdmin) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  const onSubmit = async ({ prize_program_id }) => {
    const res = await APIClient.post(
      "campaign_prize_programs",
      { campaign_id, prize_program_id },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    refresh();
  };

  if (!isAdmin || !canManage) return <NonAdminMsg />;
  return (
    <div className={classes.container}>
      <div className={classes.msg}>
        This Campaign does not have a Prize Program yet!
      </div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.priceSection}>
                <Field
                  label="Select A Prize Program"
                  name="prize_program_id"
                  variant="outlined"
                  component={TextField}
                  className={classes.select}
                  select
                  validate={required}
                >
                  {prizePrograms.map(({ id, program_name }) => (
                    <MenuItem key={id} value={id}>
                      {program_name}
                    </MenuItem>
                  ))}
                </Field>
              </div>

              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />
      <Notification />
    </div>
  );
}

function NonAdminMsg() {
  const classes = styles();
  return (
    <Card elevation={1}>
      <div className={classes.nonAdmin}>
        This Campaign does not have a Prize Program!
      </div>
    </Card>
  );
}

const styles = makeStyles(_theme => ({
  nonAdmin: {
    width: "100%",
    height: 300,
    padding: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
    color: "red",
  },
  container: {
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  select: { minWidth: 350 },
  msg: {
    textAlign: "center",
    padding: "16px 16px 36px 16px",
    fontSize: 24,
    color: "red",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 24,
  },
}));
