import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";
import { MaterialAutocomplete } from "../../types/materials";

const materialTypes = {
  MATERIAL_AUTOCOMPLETE_SET: "MATERIAL_AUTOCOMPLETE_SET",
  MATERIAL_CATEGORIES_AUTOCOMPLETE_SET: "MATERIAL_CATEGORIES_AUTOCOMPLETE_SET",
};

const materialActions = {
  fetchMaterialAutocomplete(): AppThunkAction {
    return async dispatch => {
      const url = "/materials/autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      const materialAutocomplete = data;
      const materialAutocompleteIdMap = {};
      (data as MaterialAutocomplete).forEach(
        e => (materialAutocompleteIdMap[e.id] = e),
      );

      dispatch({
        type: materialTypes.MATERIAL_AUTOCOMPLETE_SET,
        data: { materialAutocomplete, materialAutocompleteIdMap },
      });
    };
  },
  fetchMaterialCategoryAutocomplete(): AppThunkAction {
    return async dispatch => {
      const url = "/material_categories/autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));

      dispatch({
        type: materialTypes.MATERIAL_CATEGORIES_AUTOCOMPLETE_SET,
        data,
      });
    };
  },
};

export { materialActions, materialTypes };
