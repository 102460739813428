import { useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { calcPercentAmount, formatMoney } from "../../../lib";

export function ChangeListener({ name, setTotals, opData }) {
  const form = useForm();
  const values = form.getState().values;
  return (
    <OnChange name={name}>
      {() => {
        const { postCloseoutTotal } = opData;
        const {
          categoryAggregates,
          org_profit_adjustments,
          post_closeout_adjusted_profit_percent,
          post_closeout_custom_profit_amount,
        } = values;

        const {
          productProfit,
          productDeduction,
          productCredit,
        } = getProductFigures(categoryAggregates);

        const {
          donationProfit,
          donationCredit,
          donationDeduction,
        } = getDonationFigures(opData, values);

        const { customCredits, customDebits } = getCustomAdjustmentTotals(
          org_profit_adjustments,
        );

        const totalProfit = productProfit + donationProfit;
        const totalCredit = productCredit + donationCredit + customCredits;
        const totalDebit = productDeduction + donationDeduction + customDebits;
        const grandTotal = totalCredit - totalDebit;

        // post closeout
        const postCloseoutProfit = getUseFigure(
          0,
          postCloseoutTotal,
          post_closeout_adjusted_profit_percent,
          post_closeout_custom_profit_amount,
        );
        const postCloseoutDeduction = getDeduction(
          postCloseoutTotal,
          postCloseoutProfit,
        );

        setTotals({
          totalProfit: formatMoney(totalProfit),
          totalCredit: formatMoney(totalCredit),
          totalDebit: formatMoney(totalDebit),
          grandTotal: formatMoney(grandTotal, false, true),
          rawGrandTotal: Number(grandTotal).toFixed(2),
          postCloseoutDeduction: formatMoney(postCloseoutDeduction, true),
          isInvoice: grandTotal < 0,
        });
      }}
    </OnChange>
  );
}

function getUseFigure(orig, total, adjustedPercent, customAmt) {
  let use = orig || 0;

  if (customAmt) use = customAmt;
  else if (adjustedPercent && total) {
    use = calcPercentAmount(total, adjustedPercent);
  }

  return Number(use);
}

function getDeduction(ttl = 0, profit = 0) {
  if (ttl <= 0) return 0;
  return Number(ttl) - Number(profit);
}

function getCustomAdjustmentTotals(adjustments) {
  let customCredits = 0;
  let customDebits = 0;
  if (!Array.isArray(adjustments)) return { customCredits, customDebits };
  adjustments.forEach(a => {
    const { memo, is_debit, amount } = a;
    if (!amount || !memo) return;
    if (is_debit) customDebits = customDebits + Number(amount);
    else customCredits = customCredits + Number(amount);
  });
  return { customCredits, customDebits };
}

function getProductFigures(categories) {
  let productDeduction = 0;
  let productProfit = 0;
  let productCredit = 0;
  if (!Array.isArray(categories)) {
    return { productDeduction, productProfit, productCredit };
  }

  categories.forEach(c => {
    const {
      total,
      isOnline,
      org_profit_offline_percent,
      org_profit_online_percent,
      isCustomProductProfit,
      profit, // for customProductProfit
    } = c;
    const percent = isOnline
      ? org_profit_online_percent
      : org_profit_offline_percent;
    const useProfit = isCustomProductProfit
      ? Number(profit)
      : calcPercentAmount(total, percent);
    productProfit = productProfit + useProfit;
    if (isOnline) {
      productCredit = productCredit + useProfit;
    } else {
      productDeduction = productDeduction + getDeduction(total, useProfit);
    }
  });
  return { productDeduction, productProfit, productCredit };
}

function getDonationFigures(opData, formValues) {
  const { onlineDonationTotal, offlineDonationTotal } = opData;
  const { donation_profit_percent } = formValues;

  const onlineProfit = calcPercentAmount(
    onlineDonationTotal,
    donation_profit_percent,
  );
  const offlineProfit = calcPercentAmount(
    offlineDonationTotal,
    donation_profit_percent,
  );
  const donationProfit = onlineProfit + offlineProfit;

  return {
    donationProfit,
    donationCredit: onlineProfit,
    donationDeduction: getDeduction(offlineDonationTotal, offlineProfit),
  };
}
