import { isOrgOrVolunteer } from "../../lib";

const selectedCampaignTypes = {
  SELECTED_CAMPAIGN_SET: "SELECTED_CAMPAIGN_SET",
  SELECTED_CAMPAIGN_CHANGE: "SELECTED_CAMPAIGN_CHANGE",
  SELECTED_CAMPAIGN_CLEAR: "SELECTED_CAMPAIGN_CLEAR",
  CAMPAIGN_PREVIEWS_ADD: "CAMPAIGN_PREVIEWS_ADD",
};

const initialState = () => {
  const selectedCampaignId = localStorage.getItem("selectedCampaignId");
  return {
    campaignId: selectedCampaignId ? Number(selectedCampaignId) : undefined,
    campaigns: [],
  };
};

const setSelectedCampaignId = campaignId => {
  localStorage.setItem("selectedCampaignId", campaignId);
  return {
    type: selectedCampaignTypes.SELECTED_CAMPAIGN_SET,
    payload: campaignId,
  };
};

const changeSelectedCampaign = campaignId => {
  localStorage.setItem("selectedCampaignId", campaignId);
  return {
    type: selectedCampaignTypes.SELECTED_CAMPAIGN_CHANGE,
    payload: campaignId,
  };
};

const clearSelectedCampaign = () => {
  localStorage.removeItem("selectedCampaignId");
  return {
    type: selectedCampaignTypes.SELECTED_CAMPAIGN_CLEAR,
  };
};

const addPreviewsAndPullOutSelectedCampaign = campaigns => {
  return {
    type: selectedCampaignTypes.CAMPAIGN_PREVIEWS_ADD,
    payload: campaigns,
  };
};

const getActiveCampaignId = state => {
  const {
    user: { role },
    selectedCampaign: { campaignId },
  } = state;
  if (isOrgOrVolunteer(role) && campaignId) return campaignId;
  return null;
};

export {
  selectedCampaignTypes,
  initialState,
  setSelectedCampaignId,
  changeSelectedCampaign,
  clearSelectedCampaign,
  addPreviewsAndPullOutSelectedCampaign,
  getActiveCampaignId,
};
