import { useEffect, useState } from "react";
import { makeStyles, Button, Theme } from "@material-ui/core";
import { format } from "date-fns";
import { APIAuthClient, formatMoneyUnPadded } from "../lib";
import { APIRes, GenericObject, paths } from "../types";
import { MoneyHeartSVG } from "../assets/MoneyHeartSVG";
import { useAppDispatch } from "../types/state";
import { setToast } from "../state";
import { useIsMobile } from "../hooks/ui";
import { useSelector } from "react-redux";
import { getIsVolunteer } from "../../customState";

export function OrgCampaigns() {
  const classes = styles({});
  const dispatch = useAppDispatch();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIAuthClient.get<any, APIRes>(
        "/orgs/campaign_previews",
      );
      const { error, errorMessage, data } = res;
      if (error) dispatch(setToast(errorMessage));
      else setCampaigns(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <h1 className={classes.header}>Which campaign are you looking for?</h1>
      <h3 className={classes.subheader}>Select a campaign to proceed</h3>
      <div className={classes.cards}>
        {campaigns.map((c: GenericObject, index) => (
          <Card campaign={c} key={index} />
        ))}
      </div>
    </div>
  );
}

function Card({ campaign }: { campaign: GenericObject }) {
  const isMobile = useIsMobile();
  const isVolunteer = useSelector(getIsVolunteer);
  const { id, name, active, start_date, end_date, raised } = campaign;
  const _active = active as boolean;
  const classes = styles({ active: _active });

  return (
    <div className={classes.card}>
      {_active && <div className={classes.activeBadge}>Active</div>}
      <div className={classes.top}>
        <div className={classes.svgWrapper}>
          <MoneyHeartSVG color={active ? "#248BEF" : "#ADADAD"} />
        </div>
        <div className={classes.name}>{name}</div>
        <div className={classes.dates}>
          {format(new Date(start_date as string), "M/d/y")} -{" "}
          {format(new Date(end_date as string), "M/d/y")}
        </div>
        <div className={classes.raised}>{formatMoneyUnPadded(raised)}</div>
        <div className={classes.raisedTxt}>
          Raised {isVolunteer && "by you"}
        </div>
      </div>
      <Button
        color="primary"
        fullWidth
        className={classes.button}
        size={isMobile ? "small" : "medium"}
        onClick={() => {
          localStorage.setItem("selectedCampaignId", `${id}`);
          window.location.replace(paths.HOME);
        }}
      >
        Select campaign
      </Button>
    </div>
  );
}

type StyleProps = {
  active?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 24px 16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "40px 16px 16px 16px",
    },
  },
  top: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "32px",
    textAlign: "center",
    paddingBottom: 8,
  },
  subheader: {
    fontSize: 16,
    letterSpacing: 0.12,
    lineHeight: "24px",
    textAlign: "center",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 32,
    },
  },
  cards: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    maxWidth: 1168,
    width: "calc(100% + 24px)",
    marginRight: -24,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 16px)",
      marginRight: -8,
      marginLeft: -8,
    },
  },
  card: {
    position: "relative",
    width: 268,
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 24,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: 162,
      padding: 16,
      marginRight: 8,
      marginLeft: 8,
    },
  },
  svgWrapper: {
    height: 60,
    width: 60,
    backgroundColor: ({ active }) => (active ? "#F1F9FF" : "#F5F5F5"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      height: 48,
      width: 48,
    },
  },
  name: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    textAlign: "center",
    marginTop: 24,
    marginBottom: 8,
    color: ({ active }) =>
      active ? theme.palette.primary.main : theme.palette.text.secondary,
    overflowWrap: "anywhere",
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      fontSize: 16,
      lineHeight: "24px",
    },
  },
  dates: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "16px",
    textAlign: "center",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      lineHeight: "13px",
    },
  },
  raised: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.12,
    lineHeight: "17px",
    color: theme.palette.text.secondary,
    marginBottom: 8,
  },
  raisedTxt: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      lineHeight: "13px",
    },
  },
  button: {
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
      borderRadius: 15,
    },
  },
  activeBadge: {
    position: "absolute",
    top: 16,
    left: 16,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
    width: 52,
    color: "#FFFFFF",
    fontSize: 12,
    letterSpacing: 0.4,
    borderRadius: 10,
    backgroundColor: "#1AC846",
    [theme.breakpoints.down("sm")]: {
      top: 8,
      left: 8,
    },
  },
}));
