import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableFooter,
  Button,
  IconButton,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { TextField } from "final-form-material-ui";
import { OnChange } from "react-final-form-listeners";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useNotify } from "react-admin";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { APIClient, generateAuthHeader, required } from "../../lib";

export function ZoneCarriersTable({
  carrierOptions,
  carrierServiceOptions,
  zone,
  fetchZoneCarriers,
  readOnly,
}) {
  const { id, name, shipping_zone_carriers = [] } = zone;
  const classes = styles();
  const notify = useNotify();

  const onSubmit = async ({ shipping_zone_carriers = [] }) => {
    if (!shipping_zone_carriers.length) {
      return notify("You have no carriers!", "warning");
    }

    const res = await APIClient.put(
      `/shipping_zone_carriers/zone/${id}`,
      shipping_zone_carriers,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    fetchZoneCarriers();
    notify("Carriers have been successfully updated");
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ shipping_zone_carriers }}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, submitting, form }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TableContainer component={Paper} className={classes.table}>
              <FieldArray name="shipping_zone_carriers">
                {({ fields }) => (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Carrier</TableCell>
                        <TableCell>Service</TableCell>
                        <TableCell align="right" className={classes.rightCol}>
                          {!readOnly && (
                            <Button
                              color="primary"
                              onClick={() => fields.push({})}
                              startIcon={<AddIcon />}
                            >
                              add carrier
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((row, index) => {
                        const carrierCode = fields.value[index].carrier;
                        return (
                          <TableRow key={index}>
                            <OnChange name={`${row}.carrier`}>
                              {() =>
                                form.change(`${row}.carrier_service`, undefined)
                              }
                            </OnChange>
                            <TableCell>
                              <Field
                                name={`${row}.carrier`}
                                variant="standard"
                                fullWidth
                                select
                                required
                                validate={required}
                                component={TextField}
                                disabled={readOnly}
                              >
                                <MenuItem disabled>Select an option</MenuItem>
                                {carrierOptions.map(({ name, code }) => (
                                  <MenuItem key={code} value={code}>
                                    {name}
                                  </MenuItem>
                                ))}
                              </Field>
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`${row}.carrier_service`}
                                variant="standard"
                                fullWidth
                                select
                                required
                                validate={required}
                                component={TextField}
                                disabled={readOnly}
                              >
                                <MenuItem disabled>Select an option</MenuItem>
                                {Array.isArray(
                                  carrierServiceOptions[carrierCode],
                                ) &&
                                  carrierServiceOptions[carrierCode].map(
                                    ({ name, code }) => (
                                      <MenuItem key={code} value={code}>
                                        {name}
                                      </MenuItem>
                                    ),
                                  )}
                              </Field>
                            </TableCell>
                            <TableCell align="right">
                              {!readOnly && (
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                  size="small"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={2}>{`ZONE: ${name}`}</TableCell>
                        <TableCell align="right">
                          {!readOnly && (
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              disabled={submitting}
                            >
                              save
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                )}
              </FieldArray>
            </TableContainer>
          </form>
        );
      }}
    />
  );
}

const styles = makeStyles(_theme => ({
  table: { minWidth: 770, margin: "24px 0", width: "fit-content" },
  rightCol: { width: 175 },
}));
