import { Fragment, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { formatDateOnlyString } from "../../../../lib";
import { EditOffer } from "./EditOffer";
const MIN_WIDTH = 1100;

type Props = {
  listProps: ListProps<any>;
};
export function OfferRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Id"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Merchant"
                fieldName="merchant_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Category"
                fieldName="category_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Offer"
                fieldName="offer_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName={
                  <div>
                    Number <div>of uses</div>
                  </div>
                }
                fieldName="number_of_uses"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName={
                  <div>
                    Estimated <div>value per use</div>
                  </div>
                }
                fieldName="estimated_value_per_use"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Expires"
                fieldName="custom_expiration"
                params={params}
                setParams={setParams}
              />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Offers per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);
  const {
    id,
    merchant_name,
    merchantLogo,
    category_name,
    number_of_uses,
    offer_name,
    estimated_value_per_use,
    custom_expiration,
  } = row;

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div className={classes.merchant}>
              {merchantLogo && (
                <img
                  alt="logo"
                  src={merchantLogo as string}
                  className={classes.logo}
                />
              )}
              {merchant_name}
            </div>
            <div>{category_name}</div>
            <div>{offer_name}</div>
            <div>{number_of_uses ? number_of_uses : "Unlimited"}</div>
            <div>{estimated_value_per_use}</div>

            <div>
              {custom_expiration && formatDateOnlyString(custom_expiration)}
              {!custom_expiration && "Default"}
            </div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileMain}>
              <div className={classes.logoWrapper}>
                {merchantLogo && (
                  <img
                    alt="logo"
                    src={merchantLogo as string}
                    className={classes.logo}
                  />
                )}
              </div>

              <div>
                <div>{category_name}</div>
                <div className={classes.name}>{offer_name}</div>
                <div className={classes.uses}>
                  {number_of_uses
                    ? `${number_of_uses} use${
                        (number_of_uses as number) > 1 ? "s" : ""
                      }`
                    : "Unlimited"}
                </div>
              </div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>

      {showEdit && (
        <EditOffer
          onClose={() => setShowEdit(false)}
          refreshList={refreshList}
          record={row}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "100px .8fr .6fr 1fr 108px 140px 130px 52px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      alignItems: "flex-start",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  merchant: {
    display: "flex",
    alignItems: "center",
  },
  mobileMain: {
    display: "flex",
    overflow: "hidden",
  },
  logoWrapper: {
    height: 64,
    width: 64,
    minHeight: 64,
    minWidth: 64,
    border: "1px solid #EFEFEF",
    borderRadius: 8,
    marginRight: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    maxHeight: 48,
    maxWidth: 48,
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 58,
      maxWidth: 58,
      marginRight: 0,
      overflow: "hidden",
    },
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      paddingTop: 2,
      paddingBottom: 6,
      lineHeight: "20px",
    },
  },
  uses: {
    letterSpacing: 0.1,
    lineHeight: "16px",
    color: theme.palette.text.secondary2,
  },
}));
