import { useState, useEffect, Fragment } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import PublishOutlinedIcon from "@material-ui/icons/PublishOutlined";
import DeleteIcon from "@material-ui/icons/Delete";

type ImageDropzoneProps = {
  buttonText?: string;
  uploadStateTopMargin?: number;
  setFile: (file: File | undefined) => void;
  currImage?: string | null;
  onImageRemoved?: () => void;
};

export function ImageDropzone(props: ImageDropzoneProps) {
  const {
    buttonText = "upload image",
    uploadStateTopMargin = 0,
    setFile,
    currImage,
    onImageRemoved,
  } = props;
  const classes = styles({ uploadStateTopMargin });

  const [preview, setPreview] = useState("");

  useEffect(() => {
    if (currImage) setPreview(currImage);
  }, [currImage]);

  const onDrop = (fileArr: File[]) => {
    if (Array.isArray(fileArr) && fileArr.length) {
      setPreview(URL.createObjectURL(fileArr[0]));
      if (setFile) setFile(fileArr[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*"],
    multiple: false,
    onDrop,
  });

  const handleImageRemove = () => {
    setPreview("");
    if (onImageRemoved) onImageRemoved();
    setFile(undefined);
  };

  return (
    <Fragment>
      {preview && (
        <Fragment>
          <img src={preview} className={classes.preview} />
          <div className={classes.removeContainer}>
            <Button
              startIcon={<DeleteIcon color="primary" />}
              color="primary"
              className={classes.button}
              onClick={handleImageRemove}
              variant="text"
            >
              remove photo
            </Button>
          </div>
        </Fragment>
      )}

      {!preview && (
        <div className={classes.uploadContainer}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              startIcon={<PublishOutlinedIcon color="primary" />}
              color="primary"
              className={classes.button}
              variant="text"
            >
              {buttonText}
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  uploadContainer: {
    marginTop: ({ uploadStateTopMargin }: { uploadStateTopMargin?: number }) =>
      uploadStateTopMargin,
  },
  button: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    textTransform: "uppercase",
  },
  preview: {
    maxWidth: "100%",
    maxHeight: 200,
  },
  removeContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 8,
  },
}));
