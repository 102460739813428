import React, { useState, useEffect, Fragment } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  IconButton,
  TextField as MuiTextField,
  makeStyles,
  FormControlLabel,
} from "@material-ui/core";
import { TextField, Checkbox } from "final-form-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useNotify, Notification } from "react-admin";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  APIClient,
  generateAuthHeader,
  required,
  stateNames,
  shippingCalculatedByChoices,
} from "../../lib";
import { ZoneRateTable } from "./ZoneRateTable";
import { ZoneCarriersTable } from "./ZoneCarriersTable";

export function Shipping({
  readOnly = false,
  configLevel,
  supplier_id,
  shipping_calculated_by,
  shipping_rate_type,
}) {
  const isLiveRate =
    shipping_calculated_by === shippingCalculatedByChoices.LIVE_RATES;
  const classes = styles();
  const notify = useNotify();
  const [shippingZones, setShippingZones] = useState([]);
  const [shippingRatesByZone, setShippingRatesByZone] = useState([]);
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [carrierServiceOptions, setCarrierServiceOptions] = useState({});
  const [shippingCarriersByZone, setShippingCarriersByZone] = useState([]);

  useEffect(() => {
    if (supplier_id) {
      fetchShippingZones();
      fetchShippingRates();
      if (isLiveRate) {
        fetchCarrierOptions();
        fetchZoneCarriers();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id, isLiveRate]);

  const fetchShippingZones = async () => {
    const res = await APIClient.get(
      `/shipping_zones?supplier_id=${supplier_id}&shipping_config_level=${configLevel}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setShippingZones(data);
  };

  const fetchShippingRates = async () => {
    const res = await APIClient.get(
      `/shipping_rates?supplier_id=${supplier_id}&shipping_config_level=${configLevel}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setShippingRatesByZone(data);
  };

  const fetchCarrierOptions = async () => {
    const res = await APIClient.get(
      `/shipping_zone_carriers/supplier_carrier_options?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    const { carriers, carrierServices } = data;
    setCarrierOptions(carriers);
    setCarrierServiceOptions(carrierServices);
  };

  const fetchZoneCarriers = async () => {
    const res = await APIClient.get(
      `/shipping_zone_carriers?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setShippingCarriersByZone(data);
  };

  const onSubmit = async ({ shippingZones = [] }, form) => {
    const { dirty } = form.getState();
    if (!dirty) {
      return notify("There are no changes to save!", "warning");
    }
    const res = await APIClient.put(
      `/shipping_zones?supplier_id=${supplier_id}&shipping_config_level=${configLevel}`,
      shippingZones,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    fetchShippingZones();
    fetchShippingRates();
    if (isLiveRate) fetchZoneCarriers();
    notify("info has been successfully updated");
  };

  return (
    <div className={classes.container}>
      <div className={classes.subHeader}>SHIPPING ZONES</div>
      <Form
        onSubmit={onSubmit}
        initialValues={{ shippingZones }}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TableContainer component={Paper} className={classes.table}>
                <FieldArray name="shippingZones">
                  {({ fields }) => (
                    <Table size="small">
                      <TableHead>
                        {!readOnly && (
                          <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell align="right" colSpan={3}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  fields.push({
                                    name: "",
                                    shipping_zone_states: [],
                                  });
                                }}
                                startIcon={<AddIcon />}
                              >
                                add shipping zone
                              </Button>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Zone Name</TableCell>
                          <TableCell>Zone States</TableCell>
                          <TableCell>Availability</TableCell>
                          <TableCell>Ship From Zip</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.zoneName}>
                              <Field
                                variant="standard"
                                component={TextField}
                                name={`${row}.name`}
                                validate={required}
                                className={classes.zoneName}
                                disabled={readOnly}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                name={`${row}.shipping_zone_states`}
                                render={({ input, meta: _rm, ...rest }) => {
                                  return (
                                    <Autocomplete
                                      {...rest}
                                      {...input}
                                      multiple
                                      disabled={readOnly}
                                      disableCloseOnSelect
                                      onChange={(_, value) =>
                                        input.onChange(value)
                                      }
                                      options={stateNames}
                                      getOptionLabel={option => option}
                                      renderInput={params => (
                                        <MuiTextField
                                          variant="standard"
                                          {...params}
                                        />
                                      )}
                                    />
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell className={classes.noShipping}>
                              <FormControlLabel
                                control={
                                  <Field
                                    component={Checkbox}
                                    name={`${row}.no_shipping_available`}
                                    type="checkbox"
                                    disabled={readOnly}
                                  />
                                }
                                label="No shipping available"
                              />
                            </TableCell>
                            <TableCell className={classes.zip}>
                              <Field
                                variant="standard"
                                component={TextField}
                                name={`${row}.ship_from_zip`}
                                className={classes.zip}
                                disabled={readOnly}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.rightCol}
                            >
                              {!readOnly && (
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                  size="small"
                                >
                                  <RemoveIcon />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </FieldArray>
              </TableContainer>
              {!readOnly && (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  save changes
                </Button>
              )}
            </form>
          );
        }}
      />

      {isLiveRate && (
        <Fragment>
          <div className={classes.subHeader}>SHIPPING ZONE CARRIERS</div>
          <div className={classes.rateTables}>
            {shippingCarriersByZone.map((zone, i) => (
              <ZoneCarriersTable
                key={i}
                zone={zone}
                fetchZoneCarriers={fetchZoneCarriers}
                carrierOptions={carrierOptions}
                carrierServiceOptions={carrierServiceOptions}
                readOnly={readOnly}
              />
            ))}
          </div>
        </Fragment>
      )}

      <div className={classes.subHeader}>
        {isLiveRate
          ? "ADDITIONAL SHIPPING FEE ZONE RATES"
          : `SHIPPING ZONE RATES`}{" "}
        {`(rate type: ${shipping_rate_type})`}
      </div>
      <div className={classes.rateTables}>
        {shippingRatesByZone.map((zone, i) => (
          <ZoneRateTable
            key={i}
            zone={zone}
            rateType={shipping_rate_type}
            fetchShippingRates={fetchShippingRates}
            isLiveRate={isLiveRate}
            readOnly={readOnly}
          />
        ))}
      </div>

      <Notification />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: { marginBottom: 40 },
  table: { margin: "18px 0 32px 0" },
  subHeader: {
    fontSize: 24,
    textAlign: "center",
    margin: 24,
    fontWeight: 600,
  },
  zoneName: { width: 200 },
  zip: { width: 100 },
  noShipping: { width: 246 },
  rightCol: { width: 62 },
  rateTables: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
