import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useState, Fragment } from "react";
import { CampaignDonationFilterFields, ListProps } from "../../../types";
import { useSelector } from "react-redux";
import {
  getCampaignVolunteerAutocomplete,
  getIsProductCampaign,
  getVolunteerLabel,
} from "../../../state";
import { getIsVolunteer } from "../../../../customState";
import {
  DateRangeFilter,
  FilterWrapper,
  FiltersDrawer,
  SwitchFilter,
} from "../../../components";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function Filters({ onClose, listProps }: Props) {
  const { getFilterValue, isActiveFilter, updateTempFilters } = listProps;
  const isProduct = useSelector(getIsProductCampaign);
  const isVolunteer = useSelector(getIsVolunteer);
  const volunteers = useSelector(getCampaignVolunteerAutocomplete);
  const { volunteerLabelSing, volunteerLabelLCSingular } = useSelector(
    getVolunteerLabel,
  );
  const [volunteerName, setVolunteerName] = useState("");

  const onStudentACChange = (_: any, v: any) => {
    const vName = v ? `${v.first_name} ${v.last_name}` : "";
    updateTempFilters({
      field: CampaignDonationFilterFields.volunteer_id,
      value: v ? `${v.id}` : null,
      chipText: vName,
    });
    setVolunteerName(vName);
  };

  const onStudentInputChange = (_: any, v: any, action: string) => {
    if (action === "reset") return;
    setVolunteerName(v);
  };

  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header={isProduct ? "Filter orders" : "Filter donations"}
    >
      <Fragment>
        <DateRangeFilter
          header={`${isProduct ? "Order" : "Donation"} date`}
          listProps={listProps}
        />

        {!isVolunteer && (
          <SwitchFilter
            field={CampaignDonationFilterFields.unassigned}
            chipText={`Unassigned ${isProduct ? "sales" : "donations"}`}
            header={`${isProduct ? "Sales" : "Donation"} attribution`}
            switchLabel={`Show unassigned ${
              isProduct ? "sales" : "donations"
            } only`}
            listProps={listProps}
          />
        )}

        {!isVolunteer && (
          <FilterWrapper
            header={volunteerLabelSing}
            active={isActiveFilter(CampaignDonationFilterFields.volunteer_id)}
          >
            <Autocomplete
              fullWidth
              inputValue={volunteerName}
              value={getFilterValue(
                CampaignDonationFilterFields.volunteer_id,
                false,
                true,
              )}
              getOptionSelected={(o: any, v: any) => {
                if (!v) return false;
                if (typeof v === "string") return `${o.id}` === v;
                return o.id === v.id;
              }}
              onChange={onStudentACChange}
              onInputChange={onStudentInputChange}
              options={volunteers}
              getOptionLabel={({ first_name, last_name }) => {
                return `${first_name} ${last_name}`;
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={`Select a ${volunteerLabelLCSingular}`}
                />
              )}
            />
          </FilterWrapper>
        )}
      </Fragment>
    </FiltersDrawer>
  );
}
