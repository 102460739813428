import { RootState } from "../../types/state";
import { volunteerTypes } from "./actions";

type VolunteerState = {
  campaignVolunteerAutocomplete: {
    id: number;
    first_name: string;
    last_name: string;
  }[];
  orgVolunteerAutocomplete: {
    id: number;
    name: string;
    class?: string | null;
  }[];
};

const initialState: VolunteerState = {
  campaignVolunteerAutocomplete: [],
  orgVolunteerAutocomplete: [],
};

export const volunteerReducers = (
  state = initialState,
  action: any,
): VolunteerState => {
  switch (action.type) {
    case volunteerTypes.CAMPAIGN_VOLUNTEER_AUTOCOMPLETE_SET:
      return { ...state, campaignVolunteerAutocomplete: action.data };
    case volunteerTypes.ORG_VOLUNTEER_AUTOCOMPLETE_SET:
      return { ...state, orgVolunteerAutocomplete: action.data };
    default:
      return state;
  }
};

export function getOrgVolunteerAutocomplete(state: RootState) {
  return state.volunteer.orgVolunteerAutocomplete;
}

export function getCampaignVolunteerAutocomplete(state: RootState) {
  return state.volunteer.campaignVolunteerAutocomplete;
}
