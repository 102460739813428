import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(theme => ({
  appBar: {
    color: "unset",
    backgroundColor: "unset",
    height: "56px",
    maxHeight: "56px",
    minHeight: "unset",
  },
  mainContent: {
    color: "#FFFFFF",
    backgroundColor: ({ isImpersonating }) =>
      isImpersonating ? "rgb(50, 54, 57)" : theme.palette.primary.main,
    height: 56,
    maxHeight: 56,
    minHeight: "unset",
    padding: "0 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      padding: "0 12px",
    },
    [theme.breakpoints.down("sm")]: {
      height: ({ isImpersonating }) => (isImpersonating ? 40 : 56),
      maxHeight: ({ isImpersonating }) => (isImpersonating ? 40 : 56),
    },
  },
  desktopImpersonatingBar: {
    width: "fit-content",
    maxWidth: "45%",
    height: 44,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 6,
    color: "#FFFFFF",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 12px",
    fontSize: 14,
    letterSpacing: 0,
  },
  impersonatingHeader: {
    fontWeight: 600,
    marginRight: 8,
  },
  desktopImpersonatingTxt: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  leaveImpersonation: {
    height: 32,
    width: 160,
    minWidth: 50,
    border: "1px solid #FFFFFF",
    borderRadius: 4,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 48,
  },
  mobileImpersonatingBar: {
    width: "100%",
    height: 16,
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileImpersonatingTxt: {
    maxWidth: "calc(100vw - 32px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 10,
    letterSpacing: "-0.04px",
  },
  appBarAvatar: {
    backgroundColor: "#FFFFFF",
    color: ({ isImpersonating }) =>
      isImpersonating ? "rgb(50, 54, 57)" : theme.palette.primary.main,
    cursor: "pointer",
    height: 32,
    width: 32,
    fontSize: 16,
  },
  title: {
    fontSize: 16,
    textTransform: "capitalize",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      paddingRight: "8px",
      textOverflow: "ellipsis",
    },
  },
  hideDefaultTitle: {
    display: "none",
  },
  leftSide: {
    display: "flex",
    alignItems: "center",
  },
  menuButton: {
    marginRight: "16px",
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },

  //// SELECT STYLES //////
  select: {
    width: "200px",
    backgroundColor: "#ffffff",
  },

  //// MENU ////
  menu: {
    marginTop: 16,
    marginBottom: 100,
  },
  selectContainer: {
    margin: "8px 0 6px 14px",
    width: 212,
  },
  menuAvatar: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    height: 24,
    width: 24,
    fontSize: 14,
    fontWeight: 500,
    border: "1.5px solid rgba(0, 0, 0, 0.54)",
  },
}));
