import { Fragment, useEffect } from "react";
import { useLogout } from "react-admin";
import { RA_SUB_PATH } from "../../../lib";

export function AuthenticateSuperAdminCompany() {
  const logout = useLogout();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const superAdminCompanyToken = localStorage.getItem(
      "superAdminCompanyToken",
    );

    if (!token || !superAdminCompanyToken) logout();
    else {
      sessionStorage.setItem("isSuperAdminCompanyUser", "true");
      window.location.replace(`/${RA_SUB_PATH}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment />;
}
