import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import {
  APIRes,
  GenericObject,
  ToastTypes,
  VolunteerDetailModes,
} from "../../../../types";
import {
  APIAuthClient,
  requiredField,
  requiredPositiveInteger,
} from "../../../../lib";
import { useDispatch, useSelector } from "react-redux";
import { getVolunteerLabel, setToast } from "../../../../state";
import { Fragment, useEffect, useState } from "react";
import { makeStyles, Switch, MenuItem, Button } from "@material-ui/core";
import { RootState } from "../../../../types/state";
import { DuplicateEmailOptions } from "../DuplicateEmailOptions";
import { hexToRGBA } from "../../../../../lib";
import { useIsMobile } from "../../../../hooks/ui";

type Props = {
  data: GenericObject;
  refreshList: () => void;
  onClose: () => void;
  setMode: (value: React.SetStateAction<VolunteerDetailModes>) => void;
  setShowDelete: (arg0: boolean) => void;
};
export function VolunteerEdit({
  data,
  refreshList,
  onClose,
  setMode,
  setShowDelete,
}: Props) {
  const { id, slug, auto_generated_email } = data;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const classes = styles();
  const { volunteerLabelSing } = useSelector(getVolunteerLabel);
  const {
    class_options: classOptions,
    campaignId,
    allow_family_accounts,
  } = useSelector(({ campaign }: RootState) => campaign);
  const [initialValues, setInitialValues] = useState<GenericObject>({});

  useEffect(() => {
    setInitialValues(data);
  }, [data]);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const {
      first_name,
      last_name,
      email,
      phone,
      can_duplicate_email,
      class_name,
      goal,
      family_members,
      webpage_enabled,
      new_password,
      associate_shared_emails,
    } = values;
    const update = {
      campaign_id: campaignId,
      first_name,
      last_name,
      email,
      phone,
      class_name,
      goal: Number(goal),
      family_members,
      webpage_enabled,
      new_password: new_password ? new_password : null,
      can_duplicate_email,
      associate_shared_emails,
    };
    const url = `/volunteers/${id}/details`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.subheader}>
                {volunteerLabelSing} details
              </div>
              <div className={classes.idUrlRow}>
                <div className={classes.txtSection}>
                  <div className={classes.txtLabel}>
                    {volunteerLabelSing} ID
                  </div>
                  <div className={classes.txt}>{id}</div>
                </div>
                <div className={classes.txtSection}>
                  <div className={classes.txtLabel}>Unique URL</div>
                  <div className={classes.txt}>{slug}</div>
                </div>
              </div>
              <div className={classes.inputGroup}>
                <Field
                  component={TextField}
                  name="first_name"
                  label="First name"
                  validate={requiredField}
                  className={classes.halfInput}
                />
                <Field
                  component={TextField}
                  name="last_name"
                  label="Last name"
                  validate={requiredField}
                  className={classes.halfInput}
                />
              </div>
              <div className={classes.inputGroup}>
                <Field
                  component={TextField}
                  name="email"
                  label="Email"
                  validate={requiredField}
                  className={classes.halfInput}
                  disabled={Boolean(auto_generated_email)}
                />
                <Field
                  component={TextField}
                  name="phone"
                  label="Phone"
                  validate={requiredField}
                  className={classes.halfInput}
                />
              </div>

              {!auto_generated_email && <DuplicateEmailOptions />}

              <div className={classes.inputGroup}>
                <Field
                  component={TextField}
                  name="goal"
                  label="Goal"
                  validate={requiredPositiveInteger}
                  className={classes.halfInput}
                  type="number"
                />
                {Array.isArray(classOptions) && classOptions.length > 0 && (
                  <Field
                    select
                    component={TextField}
                    name="class_name"
                    label="Class"
                    validate={requiredField}
                    className={classes.halfInput}
                  >
                    {classOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                )}
              </div>
              <div className={classes.line} />

              {/* WEBPAGE STATUS */}
              <div className={classes.subheader}>Webpage status</div>
              <div className={classes.statusSubTxt}>
                By disabling the webpage it will become inaccessible online.
              </div>
              <div className={classes.statusSwitch}>
                Disabled
                <Field
                  name="webpage_enabled"
                  type="checkbox"
                  render={({ input, meta: _rm, ...rest }) => (
                    <Switch {...rest} {...input} className={classes.status} />
                  )}
                />
                Enabled
              </div>
              <div className={classes.line} />

              {/* CHANGE PASSWORD */}
              <div className={classes.subheader}>Change password</div>
              <Field
                component={TextField}
                name="new_password"
                placeholder="Enter new password"
                className={classes.halfInput}
              />

              {/* FAMILY MEMBERS */}
              {allow_family_accounts && (
                <Fragment>
                  <div className={classes.line} />
                  <div className={classes.subheader}>
                    Additional family members
                  </div>
                  <FieldArray name="family_members">
                    {({ fields }) => (
                      <div>
                        {fields.map((row, index) => {
                          return (
                            <div key={index} className={classes.fmRow}>
                              <Field
                                name={`${row}.first_name`}
                                component={TextField}
                                placeholder="First name"
                                validate={requiredField}
                                className={classes.fmInput}
                              />
                              <Field
                                name={`${row}.last_name`}
                                component={TextField}
                                placeholder="Last name"
                                validate={requiredField}
                                className={classes.fmInput}
                              />

                              {Array.isArray(classOptions) &&
                                classOptions.length > 0 && (
                                  <Field
                                    name={`${row}.class_name`}
                                    validate={requiredField}
                                    select
                                    SelectProps={{
                                      displayEmpty: true,
                                      renderValue: (value: string) =>
                                        value !== "" ? value : "Select a Class",
                                    }}
                                    component={TextField}
                                    className={classes.fmInput}
                                  >
                                    {classOptions.map((option, i) => (
                                      <MenuItem key={i} value={option}>
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                )}

                              <div className={classes.removeFmWrapper}>
                                <RemoveIcon
                                  onClick={() => fields.remove(index)}
                                  color="primary"
                                  className={classes.removeFM}
                                />
                              </div>
                            </div>
                          );
                        })}

                        <Button
                          color="primary"
                          variant="text"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            fields.push({
                              first_name: "",
                              last_name: "",
                              class_name: "",
                            })
                          }
                        >
                          FAMILY MEMBER
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Fragment>
              )}

              <div className={classes.bottom}>
                {!isMobile && (
                  <Button
                    variant="text"
                    className={classes.delete}
                    startIcon={<DeleteIcon />}
                    disabled={submitting}
                    onClick={() => setShowDelete(true)}
                  >
                    DELETE
                  </Button>
                )}

                <div className={classes.saveAndCancel}>
                  <Button
                    variant={isMobile ? "contained" : "text"}
                    color={isMobile ? "secondary" : "primary"}
                    className={classes.cancel}
                    onClick={() => setMode(VolunteerDetailModes.view)}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: "100%",
  },
  idUrlRow: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 24,
    fontSize: 16,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      fontSize: 14,
      lineHeight: "18px",
      marginBottom: 16,
    },
  },
  txtLabel: {
    fontWeight: 500,
    marginRight: 14,
    minWidth: "fit-content",
    width: "fit-content",
    letterSpacing: 0.3,
  },
  txt: {
    overflowWrap: "anywhere",
  },
  txtSection: {
    width: "calc(50% - 12px)",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 8,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  line: {
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #EAEBF3",
      marginLeft: -16,
      marginRight: -16,
      marginBottom: 24,
    },
  },
  subheader: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.34,
    lineHeight: "18px",
    marginBottom: 16,
  },
  statusSubTxt: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginBottom: 10,
    marginTop: -6,
  },
  statusSwitch: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    letterSpacing: 0.15,
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  status: {
    margin: "0 8px",
  },
  fmRow: {
    display: "flex",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #DBDEEE",
      borderRadius: 8,
      padding: "14px 16px",
      flexDirection: "column",
      marginBottom: 16,
    },
  },
  fmInput: {
    width: "100%",
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: 16,
    },
  },
  removeFmWrapper: {
    height: 40,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      height: "unset",
    },
  },
  removeFM: {
    color: theme.palette.error.main,
    cursor: "pointer",
  },
  bottom: {
    position: "sticky",
    bottom: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 0",
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0",
    },
  },
  saveAndCancel: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  delete: {
    color: theme.palette.error.main,
    "&:hover": {
      opacity: 0.8,
      backgroundColor: hexToRGBA(theme.palette.error.main, 0.1),
    },
  },
  cancel: {
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      textTransform: "unset",
    },
  },
}));
