export const dashboardTypes = {
  DASHBOARD_STATS: "DASHBOARD_STATS",
  DASHBOARD_STATS_UPDATE: "DASHBOARD_STATS_UPDATE",
  DASHBOARD_STATS_CLEAR: "DASHBOARD_STATS_CLEAR",
};

export const storeDashboardStats = data => ({
  type: dashboardTypes.DASHBOARD_STATS,
  payload: { ...data },
});

export const updateDashboardStats = data => ({
  type: dashboardTypes.DASHBOARD_STATS_UPDATE,
  payload: { ...data },
});

export const clearDashboardStats = () => ({
  type: dashboardTypes.DASHBOARD_STATS_CLEAR,
});
