import React from "react";
import {
  Datagrid,
  TextField,
  NumberField,
  ListView,
  useListController,
} from "react-admin";
import { useSelector } from "react-redux";
import { ProfitCategoryEdit } from "./ProfitCategoryEdit";

export function ProfitCategoryList({ record }) {
  const { id: campaign_id } = record;
  const canManage = useSelector(state => state.user.canManage);

  const controllerProps = useListController({
    location: {},
    resource: "profit_category_campaigns",
    basePath: "",
    filter: { campaign_id },
    perPage: 25,
    sort: { field: "id", order: "ASC" },
  });

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        component="div"
        bulkActionButtons={false}
      >
        <Datagrid
          rowClick={canManage ? "expand" : null}
          expand={canManage ? <ProfitCategoryEdit /> : null}
        >
          <TextField source="id" />
          <TextField source="profit_category_id" label="Profit Category ID" />
          <TextField
            source="profit_category_name"
            label="Profit Category Name"
          />
          <NumberField
            source="org_profit_online_percent"
            label="Org Profit Online %"
          />
          <NumberField
            source="org_profit_offline_percent"
            label="Org Profit Offline %"
          />
          <NumberField
            source="rep_commission_online_percent"
            label="Rep Commission Online %"
          />
          <NumberField
            source="rep_commission_offline_percent"
            label="Rep Commission Offline %"
          />
        </Datagrid>
      </ListView>
    </div>
  );
}
