import { useEffect, useState } from "react";
import { IconButton, makeStyles, Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { ButtonSpinner, ResponsiveModal } from "../../../components";
import {
  APIAuthClient,
  affiliateLink,
  formatMoney,
  formatMoneyUnPadded,
  requiredField,
} from "../../../lib";
import { APIRes, GenericObject, UpdateEmailRecipients } from "../../../types";
import { contactActions, getCampaignId, setToast } from "../../../state";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { RootState, useAppDispatch } from "../../../types/state";
import { AFFILIATES } from "../../../../lib";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  recipients: GenericObject;
  updateEmailRecipients: UpdateEmailRecipients;
};

export function EmailContacts({
  isOpen,
  onClose,
  recipients,
  updateEmailRecipients,
}: Props) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const campaign_id = useSelector(getCampaignId);
  const {
    show_volunteer_item_progress: itemProgress,
    volunteerName,
    orgName,
    webpageLink,
    drip_email_subject,
  } = useSelector((state: RootState) => state.campaign);
  const [showAll, setShowAll] = useState(false);
  const [loadingPrefill, setLoadingPrefill] = useState(true);
  const [defaultMsg, setDefaultMsg] = useState("");

  useEffect(() => {
    const fetch = async () => {
      const url = `/contacts/email-prefill?campaign_id=${campaign_id}&volunteer_item_based=${Boolean(
        itemProgress,
      )}`;
      const res = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      const { goal, raised } = data;
      const goalTxt = itemProgress
        ? `selling ${goal} items`
        : formatMoneyUnPadded(goal);
      const raisedTxt = itemProgress
        ? `sold ${Number(raised)} items`
        : `raised ${formatMoney(raised)}`;
      const link = affiliateLink(webpageLink, AFFILIATES.MANUAL_CONTACT_EMAIL);
      const msg = `Hello,\n\nIt's ${volunteerName} here.\n\nI'm raising funds for ${orgName}.\nPlease help me reach my goal of ${goalTxt}.\nI have ${raisedTxt} so far.\n\nPlease take a moment to view my personal fundraising page and consider supporting!\n\n${link}\n\nThank you very much,\n\n${volunteerName}`;
      setDefaultMsg(msg);
      setLoadingPrefill(false);
    };
    if (isOpen && campaign_id) {
      setLoadingPrefill(true);
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, campaign_id, itemProgress]);

  useEffect(() => {
    if (isOpen && !Object.keys(recipients).length) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, recipients]);

  useEffect(() => {
    if (isDesktop) setShowAll(true);
  }, [isDesktop]);

  const onSubmit = async (values: any) => {
    const ids = Object.keys(recipients).map(id => Number(id));
    const post = { ids, ...values, campaign_id };
    const success = await dispatch(contactActions.emailContacts(post));
    if (success) onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.container}>
              <div>
                <div className={classes.topContent}>
                  <h1 className={classes.title}>Send an email</h1>
                  {isMobile && (
                    <IconButton
                      size="small"
                      className={classes.close}
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
                <div className={classes.mainContent}>
                  <div className={classes.label}>
                    RECIPIENT{Object.keys(recipients).length > 1 && "S"}
                  </div>
                  <div className={classes.chipsWrapper}>
                    <div className={classes.chips}>
                      {Object.keys(recipients).map((id, index, arr) => {
                        const email = recipients[id] as string;
                        const count = arr.length;
                        if (!showAll && index > 0) return <></>;
                        if (!showAll) {
                          return (
                            <div
                              key={index}
                              className={classes.recipientTextRow}
                            >
                              <div className={classes.recipientText}>
                                {email} {count > 1 && `& ${count - 1} more`}
                              </div>
                              {count > 1 && (
                                <IconButton
                                  onClick={() => setShowAll(true)}
                                  size="small"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              )}
                            </div>
                          );
                        }
                        return (
                          <div key={index} className={classes.chip}>
                            <div className={classes.chipEmail}>{email}</div>
                            <div>
                              <IconButton
                                size="small"
                                className={classes.remove}
                                onClick={() =>
                                  updateEmailRecipients(
                                    Number(id),
                                    email,
                                    false,
                                  )
                                }
                              >
                                <CancelIcon />
                              </IconButton>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {isMobile && showAll && (
                      <div>
                        <IconButton
                          onClick={() => setShowAll(false)}
                          size="small"
                        >
                          <ExpandLessIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>

                  <div className={classes.label}>SUBJECT</div>
                  <Field
                    name="subject"
                    component={TextField}
                    size="small"
                    validate={requiredField}
                    placeholder="Subject"
                    className={classes.input}
                    defaultValue={
                      drip_email_subject
                        ? drip_email_subject
                        : "Help support my School"
                    }
                  />
                  <div className={classes.label}>MESSAGE</div>
                  {loadingPrefill && (
                    <Skeleton
                      variant="rect"
                      width={"100%"}
                      height={200}
                      className={classes.skeleton}
                    />
                  )}
                  {!loadingPrefill && (
                    <Field
                      name="message"
                      component={TextField}
                      size="small"
                      validate={requiredField}
                      placeholder="Message"
                      className={classes.input}
                      multiline={true}
                      minRows="11"
                      defaultValue={defaultMsg}
                    />
                  )}
                </div>
              </div>

              <div className={classes.actions}>
                {!isMobile && (
                  <Button
                    variant="text"
                    className={classes.cancel}
                    onClick={onClose}
                    disabled={submitting}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  type="submit"
                  className={classes.save}
                  disabled={submitting || loadingPrefill}
                >
                  Send
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 700,
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "100%",
      justifyContent: "space-between",
    },
  },
  topContent: {
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 16,
    marginBottom: 24,
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "unset",
      marginBottom: 0,
      padding: "32px 16px 16px 16px",
      position: "relative",
      borderBottom: "none",
    },
  },
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "rgba(0, 0, 0, 0.33)",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  label: {
    fontSize: 12,
    letterSpacing: 1,
    lineHeight: "32px",
    color: theme.palette.text.secondary,
  },
  mainContent: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  input: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 16,
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 8px",
    },
  },
  save: {
    width: 120,
    borderRadius: 18,
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
  },
  cancel: {
    width: 74,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 16,
  },
  recipientTextRow: {
    width: "100%",
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  recipientText: {
    fontSize: 14,
    letterSpacing: 0.1,
  },
  chipsWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  chips: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    height: 32,
    maxWidth: 236,
    width: "fit-content",
    borderRadius: 16,
    backgroundColor: "#E0E0E0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    marginBottom: 8,
    paddingLeft: 12,
    paddingRight: 6,
  },
  chipEmail: {
    overflow: "hidden",
    fontSize: 13,
    letterSpacing: 0.15,
    textOverflow: "ellipsis",
  },
  remove: {
    marginLeft: 2,
  },
  skeleton: {
    marginBottom: 16,
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
  },
}));
