import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  _theme => ({
    card: {
      height: "326px",
      flex: 1,
    },
    cardContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "0 25px",
      boxSizing: "border-box",
      maxWidth: "100%",
    },
  }),
  {
    classNamePrefix: "Chart",
  },
);
