import React from "react";
import { ImageInput, ImageField, useNotify, useRefresh } from "react-admin";
import { Button } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIClient,
  convertFileToBase64,
  generateAuthHeader,
  integerOnly,
  productTypes,
  twoDecimalsOnly,
} from "../../../lib";
import SaveIcon from "@material-ui/icons/Save";
import { useSelector } from "react-redux";
import { TextField } from "final-form-material-ui";
import { getIsOwner } from "../../../customState";
import { styles } from "../ProductGroup.styles";

export const CustomizeGroupProduct = ({ record, group_id }) => {
  const {
    company_custom_price,
    company_custom_digital_content_addition_id,
    company_custom_digital_content_addition_code_qty,
    company_custom_image,
    company_custom_thumbnail_image,
    id: product_id,
    product_type,
  } = record;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const isOwner = useSelector(state => getIsOwner(state));
  const showDigitalFields = isOwner && product_type !== productTypes.DIGITAL;

  const onSubmit = async values => {
    let {
      companyCustomImageBase64,
      companyCustomThumbnailImageBase64,
      company_custom_price,
      company_custom_digital_content_addition_id,
      company_custom_digital_content_addition_code_qty,
    } = values;
    let data = {
      group_id: Number(group_id),
      product_id,
      company_custom_price: company_custom_price ? company_custom_price : null,
      company_custom_digital_content_addition_id: company_custom_digital_content_addition_id
        ? Number(company_custom_digital_content_addition_id)
        : null,
      company_custom_digital_content_addition_code_qty: company_custom_digital_content_addition_code_qty
        ? Number(company_custom_digital_content_addition_code_qty)
        : null,
    };
    if (companyCustomImageBase64) {
      const imageB64 = await convertFileToBase64(
        companyCustomImageBase64,
        "companyCustomImageBase64",
      ).catch(console.error);
      data = { ...data, ...imageB64 };
    }
    if (companyCustomThumbnailImageBase64) {
      const tnB64 = await convertFileToBase64(
        companyCustomThumbnailImageBase64,
        "companyCustomThumbnailImageBase64",
      ).catch(console.error);
      data = { ...data, ...tnB64 };
    }

    const res = await APIClient.put(
      "/supplier_product_groups/update_company_customized_product",
      data,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    refresh();
    notify("Product Updated");
  };

  return (
    <div>
      <div className={classes.header}>Customize Group Product</div>
      <Form
        initialValues={{
          company_custom_price,
          company_custom_digital_content_addition_id,
          company_custom_digital_content_addition_code_qty,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="company_custom_price"
                label="Custom Price"
                variant="filled"
                component={TextField}
                validate={twoDecimalsOnly}
                type="number"
                InputLabelProps={{ shrink: true }}
                className={classes.customInput}
              />

              <div className={classes.imagesContainer}>
                <div className={classes.imageSection}>
                  <div className={classes.currentImage}>
                    <div className={classes.label}>Current Custom Image</div>
                    {company_custom_image && (
                      <img
                        src={company_custom_image}
                        alt="logo"
                        className={classes.image}
                      />
                    )}
                  </div>
                  <ImageInput
                    source="companyCustomImageBase64"
                    label="Change Custom Image"
                    accept="image/*"
                  >
                    <ImageField
                      source="src"
                      title="title"
                      className={classes.image}
                    />
                  </ImageInput>
                </div>
                <div className={classes.divider}></div>
                <div className={classes.imageSection}>
                  <div className={classes.currentImage}>
                    <div className={classes.label}>
                      Current Custom Thumbnail
                    </div>
                    {company_custom_thumbnail_image && (
                      <img
                        src={company_custom_thumbnail_image}
                        alt="logo"
                        className={classes.image}
                      />
                    )}
                  </div>
                  <ImageInput
                    source="companyCustomThumbnailImageBase64"
                    label="Change Custom Thumbnail"
                    accept="image/*"
                  >
                    <ImageField
                      source="src"
                      title="title"
                      className={classes.image}
                    />
                  </ImageInput>
                </div>
              </div>

              {showDigitalFields && (
                <Field
                  name="company_custom_digital_content_addition_id"
                  label="Company Custom Digital-Content Addition Id"
                  variant="filled"
                  component={TextField}
                  validate={integerOnly}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  className={classes.customInput}
                />
              )}
              {showDigitalFields && (
                <Field
                  name="company_custom_digital_content_addition_code_qty"
                  label="Company Custom Digital-Content Addition Code Qty"
                  variant="filled"
                  component={TextField}
                  validate={integerOnly}
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  className={classes.customInput}
                />
              )}

              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  className={classes.saveButton}
                  startIcon={<SaveIcon className={classes.icon} />}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
