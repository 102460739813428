import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  _theme => ({
    addButton: {
      backgroundColor: "green",
      color: "#ffffff",
    },
    removeButton: {
      backgroundColor: "red",
      color: "#ffffff",
    },
    icon: {
      color: "#FFFFFF",
    },
  }),
  {
    classNamePrefix: "digitalContent",
  },
);
