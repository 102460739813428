import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
  email,
  BooleanInput,
  NullableBooleanInput,
} from "react-admin";
import { useSelector } from "react-redux";
import { getRoleBooleans } from "../../customState";
import { styles } from "./Rep.styles";

const validateEmail = [required(), email(), minLength(2)];
const validateName = [required(), minLength(2)];
const requiredInput = [required()];

export default function RepCreate(props) {
  const classes = styles();
  const { isCompanyOrCompanyAdmin } = useSelector(state =>
    getRoleBooleans(state),
  );
  const {
    hasRepHierarchy,
    campaignManagementEnabled,
    enableDiscountCards,
  } = useSelector(({ user }) => user);

  return (
    <Create {...props} successMessage="Rep added successfully">
      <SimpleForm redirect="show">
        <TextInput source="first_name" validate={validateName} />
        <TextInput source="last_name" validate={requiredInput} />
        <TextInput source="email" validate={validateEmail} />
        <TextInput source="phone" />
        {hasRepHierarchy && (
          <BooleanInput
            source="allow_rep_hierarchy"
            label="Allow Rep Hierarchy"
          />
        )}
        {isCompanyOrCompanyAdmin && (
          <NullableBooleanInput
            label="Rep Can Manage - Override (keep default to use company setting)"
            source="rep_can_manage_override"
            className={classes.manageOverride}
            options={{
              SelectProps: { displayEmpty: true },
              InputLabelProps: { shrink: true },
            }}
          />
        )}
        <TextInput source="association" />
        {isCompanyOrCompanyAdmin && (
          <BooleanInput
            source="use_custom_checkpoint_emails"
            label="Rep will setup their own Checkpoint Emails (their campaigns will not use any of the company checkpoint emails)"
            className={classes.fitContent}
          />
        )}
        {isCompanyOrCompanyAdmin && enableDiscountCards && (
          <BooleanInput
            source="allow_discount_cards"
            label="Allowed to Use Discount Cards"
            className={classes.fitContent}
          />
        )}
        {isCompanyOrCompanyAdmin && (
          <TextInput
            source="general_notes"
            multiline
            fullWidth
            helperText="Will only display in this Rep profile"
          />
        )}
        {isCompanyOrCompanyAdmin && campaignManagementEnabled && (
          <TextInput
            source="org_profit_notes"
            multiline
            fullWidth
            helperText="Will display in Company view of Org Profit tab"
          />
        )}
        {isCompanyOrCompanyAdmin && campaignManagementEnabled && (
          <TextInput
            source="rep_commission_notes"
            multiline
            fullWidth
            helperText="Will display in Company view of Rep Commission tab"
          />
        )}
      </SimpleForm>
    </Create>
  );
}
