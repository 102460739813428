import { SearchFields } from "./list";

export type VolunteerQueryParams = {
  page: number;
  limit: number;
  sort: string;
  order: "asc" | "desc";
  campaign_id?: number;
  campaign_type_id?: number;
  name?: string;
  email?: string;
  volunteer_id?: number;
};

export type NotOnCampaignVolunteerQueryParams = {
  page: number;
  limit: number;
  sort: string;
  order: "asc" | "desc";
  campaign_id?: number;
  name?: string;
  email?: string;
  volunteer_id?: number;
  previous_campaign_id?: number;
  active_only?: boolean;
};

export enum VolunteerDetailModes {
  view = "view",
  edit = "edit",
}

export enum VolunteerFilterFields {
  activated = "activated",
  webpage_enabled = "webpage_enabled",
  contacts_confirmed = "contacts_confirmed",
  has_valid_contacts = "has_valid_contacts",
  has_invalid_contacts = "has_invalid_contacts",
}

export enum NotOnCampaignVolunteerFilterFields {
  previous_campaign_id = "previous_campaign_id",
  active_only = "active_only",
}

export function volunteerSearchFields(
  volunteerLabelLCSingular: string,
): SearchFields {
  const placeholder = `Search for a ${volunteerLabelLCSingular}`;
  return [
    {
      label: "Name",
      field: "name",
      placeholder,
    },
    {
      label: "Email",
      field: "email",
      placeholder,
    },
    {
      label: "ID",
      field: "id",
      placeholder,
      isNumber: true,
    },
  ];
}

export const volunteerSortFields = [
  { field: "id", label: "ID" },
  { field: "last_name", label: "Name" },
  { field: "activated", label: "Activated" },
  { field: "webpage_enabled", label: "Page Enabled" },
  { field: "class_name", label: "Class" },
  { field: "raised", label: "Amount raised" },
  { field: "itemsSold", label: "Number of items sold" },
  { field: "contacts_confirmed", label: "Contacts confirmed" },
  { field: "validContacts", label: "Number of valid contacts" },
  { field: "invalidContacts", label: "Number of invalid contacts" },
];
