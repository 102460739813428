import { APIAuthClient } from "../../lib";
import { APIRes, GenericObject } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const giveawayTypes = {
  GIVEAWAY_DATA_SET: "GIVEAWAY_DATA_SET",
  GIVEAWAY_DATA_UPDATE: "GIVEAWAY_DATA_UPDATE",
};

const giveawayActions = {
  fetchCampaignGiveawayData(id: any): AppThunkAction {
    return async dispatch => {
      const url = `/campaign_giveaways/${id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));

      dispatch({
        type: giveawayTypes.GIVEAWAY_DATA_SET,
        data,
      });
    };
  },
  updateCampaignGiveawayData(data: GenericObject) {
    return {
      type: giveawayTypes.GIVEAWAY_DATA_UPDATE,
      data,
    };
  },
};

export { giveawayActions, giveawayTypes };
