import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(theme => ({
  list: {
    "& div[class*='MuiCard-root']": {
      boxShadow: "none",
    },
  },
  uploadOrdersButton: {
    color: theme.palette.primary.main,
  },
  buttonIcon: {
    fontSize: "20px",
    marginRight: "5px",
  },
  columnLabel: {
    textTransform: "capitalize",
  },
  menu: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiIconButton-root": {
        padding: 4,
      },
    },
  },
  label: {
    color: theme.palette.primary.main,
  },
  trackingLink: {
    color: theme.palette.primary.main,
  },
  narrowInput: {
    width: 130,
  },
  medInput: {
    width: 160,
  },
  ///// IMPORT ORDERS MODAL /////
  importContainer: {
    padding: "12px 24px 24px 24px",
    width: "750px",
    maxWidth: "100%",
  },
  importClose: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cptHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 22,
    fontWeight: 600,
  },
  cptProgress: {
    color: "#ffffff",
    position: "absolute",
  },
  importSubHeader: {
    fontSize: "18px",
    padding: "16px 0",
  },
  importTxt: {
    fontSize: "15px",
    paddingRight: "24px",
  },
  downloadSection: {
    display: "flex",
    alignItems: "center",
  },
  fileDrop: {
    margin: "16px 0",
  },
  importButtons: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "90px",
    height: "36px",
  },
  progress: {
    color: "#ffffff",
  },
  saveBtnContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  failedOrderTable: {
    marginBottom: "36px",
  },
  failedOrderTh: {
    textAlign: "left",
    paddingRight: "32px",
  },
  failedOrderCell: {
    textAlign: "left",
  },
}));
