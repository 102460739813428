// cSpell:ignore Swipeable
import {
  makeStyles,
  SwipeableDrawer,
  IconButton,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ReactNode, useEffect } from "react";
import { ListProps } from "../../../types";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";

type Props = {
  onClose: () => void;
  children: ReactNode;
  listProps: ListProps;
  header: string;
};
export function FiltersDrawer({
  onClose: _onClose,
  listProps,
  children,
  header,
}: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const { onClose, isOpen } = useDrawerTransition(_onClose, true);
  const {
    hasClearableFilters,
    hasFilterChanges,
    setAllFiltersToBeCleared,
    updateFilters,
    setupInitialTempFilters,
  } = listProps;

  useEffect(() => {
    setupInitialTempFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={onClose}
      onOpen={() => {}}
      classes={{ paper: classes.drawer }}
      anchor={isMobile ? "bottom" : "right"}
      transitionDuration={500}
    >
      <div className={classes.wrapper}>
        <div className={classes.main}>
          <div className={classes.top}>
            <IconButton size="small" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.headerRow}>
            {header}
            <Button
              color="primary"
              variant="text"
              onClick={setAllFiltersToBeCleared}
              disabled={!hasClearableFilters}
            >
              CLEAR FILTERS
            </Button>
          </div>

          {isMobile && <div className={classes.contentTopBorder} />}

          {children}
        </div>

        <div className={classes.apply}>
          <Button
            fullWidth
            color="primary"
            onClick={() => {
              updateFilters();
              onClose();
            }}
            disabled={!hasFilterChanges}
          >
            Apply
          </Button>
        </div>
      </div>
    </SwipeableDrawer>
  );
}

const styles = makeStyles(theme => ({
  drawer: {
    top: 0,
    backgroundColor: "#FFFFFF",
    maxHeight: "unset",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "20px 20px 0 0",
      top: 75,
    },
  },
  wrapper: {
    width: 375,
    marginBottom: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      maxWidth: "100vw",
      minWidth: "100vw",
      maxHeight: "calc(100vh - 75px)",
    },
  },
  main: {
    marginBottom: 24,
  },
  top: {
    position: "sticky",
    top: 0,
    padding: 12,
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    zIndex: 999,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  headerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px 16px 16px",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  contentTopBorder: {
    borderTop: "1px solid #EAEBF3",
  },
  apply: {
    position: "sticky",
    bottom: 0,
    padding: 16,
    paddingBottom: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
  },
}));
