import React from "react";
import { Edit, SimpleForm, TextInput, required } from "react-admin";
import { EmailWYSIWYG } from "../../components";

const requiredField = [required()];

export default function DigitalContentEdit(props) {
  return (
    <Edit
      {...props}
      undoable={false}
      successMessage="Digital Content updated successfully"
      title="Edit Digital Content"
    >
      <SimpleForm redirect="list">
        <TextInput source="id" disabled />
        <TextInput source="name" validate={requiredField} />
        <EmailWYSIWYG
          source="instructions"
          label="Redemption Instructions - for email"
        />
      </SimpleForm>
    </Edit>
  );
}
