import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableFooter,
  Button,
  IconButton,
  FormControlLabel,
  TextField as MuiTextField,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { TextField, Checkbox } from "final-form-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useNotify, Notification } from "react-admin";
import { Form, Field, useField } from "react-final-form";
import { format } from "date-fns";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  APIClient,
  APIUtilClient,
  downloadPdf,
  generateAuthHeader,
  getUserTimezone,
  poStatuses,
} from "../../../lib";
import { styles } from "./SourcePO.styles";

export function EditSourcePO({ record, closeAside }) {
  const { id, productSource, product_source_id, created_at } = record;
  const classes = styles();
  const notify = useNotify();
  const [productAutocomplete, setProductAutocomplete] = useState([]);
  const [activeProduct, setActiveProduct] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [status, setStatus] = useState(null);
  const [editable, setEditable] = useState(false);
  const [deletePoTriggered, setDeletePoTriggered] = useState(false);

  useEffect(() => {
    if (id) {
      fetchPoData();
      fetchSourceProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchPoData = async () => {
    const res = await APIClient.get(`/product_source_purchase_orders/${id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    const {
      notes,
      available_on_store,
      product_source_purchase_order_products,
      status,
    } = data;

    setInitialValues({
      notes,
      available_on_store,
      product_source_purchase_order_products,
    });
    setStatus(status);
    if (status === poStatuses.AWAITING) setEditable(true);
  };

  const fetchSourceProducts = async () => {
    const res = await APIClient.get(
      `/product_sources/${product_source_id}/products`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductAutocomplete(data);
  };

  const handleDeletePo = async () => {
    setDeletePoTriggered(true);
    const confirmed = window.confirm(
      "Are you sure you want to DELETE this PO?",
    );
    if (!confirmed) {
      setDeletePoTriggered(false);
      return;
    }
    const res = await APIClient.delete(
      `/product_source_purchase_orders/${id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("PO successfully deleted!");
    closeAside();
  };

  const onSubmit = async values => {
    const { product_source_purchase_order_products = [], ...rest } = values;
    let missingInfo = false;
    const formattedPOPs = product_source_purchase_order_products.map(p => {
      const { id, product_id, by_case, case_qty, item_qty } = p;
      if (!item_qty) missingInfo = true;
      return {
        id,
        product_id,
        by_case,
        case_qty: case_qty ? Number(case_qty) : null,
        item_qty: item_qty ? Number(item_qty) : null,
      };
    });

    if (missingInfo) return notify("please fill in all info", "warning");

    const update = {
      ...rest,
      product_source_purchase_order_products: formattedPOPs,
    };

    const res = await APIClient.put(
      `/product_source_purchase_orders/${id}`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("info has been successfully updated");
    closeAside();
  };

  const exportPO = async () => {
    const timezone = getUserTimezone();
    const res = await APIUtilClient.get(
      `/product_source_purchase_orders/${id}/pdf?timezone=${timezone}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    return downloadPdf(res, "purchase-order.pdf");
  };

  const addAll = fields => {
    productAutocomplete.forEach(p => {
      const { id: product_id, ...rest } = p;
      fields.push({ product_id, ...rest, by_case: Boolean(p.items_per_case) });
    });
  };

  return (
    <div className={classes.editContainer}>
      <Button
        variant="contained"
        color={"secondary"}
        endIcon={<DownloadIcon />}
        className={classes.exportButton}
        onClick={exportPO}
      >
        export
      </Button>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>ID:</TableCell>
                      <TableCell>{id}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Source:</TableCell>
                      <TableCell>{productSource}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Create date:</TableCell>
                      <TableCell>
                        {Boolean(created_at) &&
                          format(new Date(created_at), "Pp")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Status:</TableCell>
                      <TableCell>{status}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Available on the store?</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Field
                              component={Checkbox}
                              name="available_on_store"
                              type="checkbox"
                              disabled={!editable}
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>General Notes:</TableCell>
                      <TableCell>
                        <Field
                          variant="standard"
                          component={TextField}
                          name="notes"
                          parse={value => value}
                          multiline={true}
                          disabled={!editable}
                        />
                      </TableCell>
                    </TableRow>
                    {editable && (
                      <TableRow>
                        <TableCell colSpan={2} align="center">
                          <Button
                            className={classes.closeOrder}
                            variant="contained"
                            onClick={handleDeletePo}
                            disabled={deletePoTriggered}
                          >
                            delete po
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TableContainer component={Paper} className={classes.table}>
                <FieldArray name="product_source_purchase_order_products">
                  {({ fields }) => (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Source product ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Thumbnail</TableCell>
                          <TableCell>SKU</TableCell>
                          <TableCell>Items per case</TableCell>
                          <TableCell>Original case qty</TableCell>
                          <TableCell>Original item qty</TableCell>
                          <TableCell>Order by case</TableCell>
                          <TableCell>Case rounding</TableCell>
                          <TableCell>Case qty input</TableCell>
                          <TableCell>Item qty input</TableCell>
                          <TableCell>Case qty</TableCell>
                          <TableCell>Item qty</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {fields.map((row, index) => {
                          const values = fields.value[index];
                          const {
                            id,
                            product_id,
                            source_product_id,
                            product_name,
                            sku,
                            thumbnail_image,
                            items_per_case,
                            orig_case_qty,
                            case_qty,
                            orig_item_qty,
                            item_qty,
                          } = values;
                          return (
                            <TableRow key={index}>
                              <TableCell>{product_id}</TableCell>
                              <TableCell>{source_product_id}</TableCell>
                              <TableCell>{product_name}</TableCell>
                              <TableCell>
                                <img
                                  className={classes.thumbnail}
                                  alt="thumbnail"
                                  src={thumbnail_image}
                                />
                              </TableCell>
                              <TableCell>{sku}</TableCell>
                              <TableCell>{items_per_case}</TableCell>
                              <TableCell>
                                {!id ? `N/A` : orig_case_qty}
                              </TableCell>
                              <TableCell>
                                {!id ? `N/A` : orig_item_qty}
                              </TableCell>
                              <TableCell>
                                <CheckboxInput
                                  values={values}
                                  row={row}
                                  caseCB
                                  editable={editable}
                                />
                              </TableCell>
                              <TableCell>
                                <CheckboxInput
                                  values={values}
                                  row={row}
                                  editable={editable}
                                />
                              </TableCell>
                              <TableCell>
                                <QtyInput
                                  values={values}
                                  row={row}
                                  isCase
                                  editable={editable}
                                />
                              </TableCell>
                              <TableCell>
                                <QtyInput
                                  values={values}
                                  row={row}
                                  editable={editable}
                                />
                              </TableCell>
                              <TableCell>{case_qty}</TableCell>
                              <TableCell>{item_qty}</TableCell>

                              <TableCell>
                                <IconButton
                                  onClick={() => fields.remove(index)}
                                  size="small"
                                  disabled={!editable}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                      {editable && (
                        <TableFooter>
                          <TableRow>
                            <TableCell>
                              <Button
                                disabled={Boolean(fields.length)}
                                color="primary"
                                variant="contained"
                                onClick={() => addAll(fields)}
                                className={classes.addAll}
                              >
                                add all
                              </Button>
                            </TableCell>
                            <TableCell colSpan={10} align="right">
                              <Autocomplete
                                value={activeProduct}
                                onChange={(_, newValue) =>
                                  setActiveProduct(newValue)
                                }
                                getOptionLabel={({ product_name, sku }) =>
                                  `${product_name} | ${sku}`
                                }
                                options={productAutocomplete}
                                getOptionSelected={(option, value) =>
                                  option.id === value.id
                                }
                                renderInput={params => (
                                  <MuiTextField
                                    {...params}
                                    label=""
                                    placeholder="Enter or select a product to add"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell colSpan={2}>
                              <Button
                                color="primary"
                                onClick={() => {
                                  fields.push(
                                    formatSelectedProduct(activeProduct),
                                  );
                                  setActiveProduct(null);
                                }}
                                startIcon={<AddIcon />}
                                disabled={!Boolean(activeProduct)}
                              >
                                add
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      )}
                    </Table>
                  )}
                </FieldArray>
              </TableContainer>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting || !editable}
              >
                save changes
              </Button>
            </form>
          );
        }}
      />
      <Notification />
    </div>
  );
}

function QtyInput({ values, row, isCase, editable }) {
  const classes = styles();
  const { items_per_case, round_cases_down, by_case } = values;
  const name = isCase ? `${row}.case_qty_input` : `${row}.item_qty_input`;
  const label = isCase ? "Case qty" : "Item qty";
  const disabled = !editable ? true : isCase && !by_case;
  const itemQtyInput = useField(`${row}.item_qty_input`);
  const caseQtyInput = useField(`${row}.case_qty_input`);
  const itemQty = useField(`${row}.item_qty`);
  const caseQty = useField(`${row}.case_qty`);

  const onChange = e => {
    const value = e.target.value;
    if (isCase) {
      const updatedItemQty = value ? items_per_case * Number(value) : null;
      itemQtyInput.input.onChange(null);
      caseQty.input.onChange(value);
      itemQty.input.onChange(updatedItemQty);
    } else {
      caseQtyInput.input.onChange(null);
      if (!by_case) {
        caseQty.input.onChange(null);
        itemQty.input.onChange(value);
      } else {
        const updatedCaseQty = !value
          ? null
          : round_cases_down
          ? Math.floor(Number(value) / items_per_case)
          : Math.ceil(Number(value) / items_per_case);
        const updatedItemQty = updatedCaseQty
          ? items_per_case * Number(updatedCaseQty)
          : null;
        caseQty.input.onChange(updatedCaseQty);
        itemQty.input.onChange(updatedItemQty);
      }
    }
  };

  return (
    <Field
      variant="standard"
      type="number"
      name={name}
      label={label}
      disabled={disabled}
      className={classes.qtyInput}
      render={({ input, meta: _rm, ...rest }) => {
        return (
          <MuiTextField
            {...rest}
            {...input}
            value={input.value}
            onChange={e => {
              input.onChange(e.target.value);
              onChange(e);
            }}
          />
        );
      }}
    />
  );
}

function CheckboxInput({ values, row, caseCB, editable }) {
  const { items_per_case, by_case } = values;
  const name = caseCB ? `${row}.by_case` : `${row}.round_cases_down`;
  const label = caseCB ? "by case" : "round down";
  const disabled = !editable
    ? true
    : caseCB
    ? !Boolean(items_per_case)
    : !Boolean(by_case);
  const itemQtyInput = useField(`${row}.item_qty_input`);
  const caseQtyInput = useField(`${row}.case_qty_input`);
  const itemQty = useField(`${row}.item_qty`);
  const caseQty = useField(`${row}.case_qty`);

  const onChange = () => {
    itemQtyInput.input.onChange(null);
    caseQtyInput.input.onChange(null);
    itemQty.input.onChange(null);
    caseQty.input.onChange(null);
  };

  return (
    <FormControlLabel
      control={
        <Field
          type="checkbox"
          name={name}
          disabled={disabled}
          render={({ input, meta: _rm, ...rest }) => {
            return (
              <MuiCheckbox
                {...rest}
                {...input}
                value={input.value}
                onChange={e => {
                  input.onChange(e.target.checked);
                  onChange();
                }}
              />
            );
          }}
        />
      }
      label={label}
    />
  );
}

function formatSelectedProduct(product) {
  const { id: product_id, ...rest } = product;
  return { product_id, ...rest, by_case: Boolean(product.items_per_case) };
}
