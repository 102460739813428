import { CampaignEventAutocomplete } from "../../types/events";
import { eventTypes } from "./actions";

type EventState = {
  campaignEventAutocomplete: CampaignEventAutocomplete;
  campaignHasCampaignEventTickets: boolean;
};

const initialState: EventState = {
  campaignEventAutocomplete: [],
  campaignHasCampaignEventTickets: false,
};

export const eventReducers = (
  state = initialState,
  action: any,
): EventState => {
  switch (action.type) {
    case eventTypes.CAMPAIGN_EVENT_TICKET_EVENT_AUTOCOMPLETE_SET:
      return {
        ...state,
        campaignEventAutocomplete: action.data,
      };
    case eventTypes.CAMPAIGN_HAS_EVENT_TICKETS_SET:
      return {
        ...state,
        campaignHasCampaignEventTickets: action.hasTickets,
      };
    default:
      return state;
  }
};
