import { Dispatch } from "react";

export type GuidedSetupScreens =
  | undefined
  | "PICTURE"
  | "GOAL"
  | "PARAGRAPH"
  | "CLASS"
  | "TEAM"
  | "FAMILY_MEMBERS"
  | "TEXT_AGREEMENT"
  | "PRIZE_PICKS"
  | "CONTACTS"
  | "TEXT_CONTACTS"
  | "EVENTS"
  | "SOCIAL";

export const GuidedSetupScreenIds = {
  1: "PICTURE",
  2: "GOAL",
  3: "PARAGRAPH",
  4: "CLASS",
  5: "TEAM",
  6: "FAMILY_MEMBERS",
  7: "TEXT_AGREEMENT",
  8: "PRIZE_PICKS",
  9: "CONTACTS",
  10: "TEXT_CONTACTS",
  11: "EVENTS",
  12: "SOCIAL",
} satisfies Record<number, GuidedSetupScreens>;

export type GuidedSetupScreen = {
  activeScreen: GuidedSetupScreens;
  setDisableNext: Dispatch<React.SetStateAction<boolean>>;
  handleNext: () => void;
  setSubmitting: Dispatch<React.SetStateAction<boolean>>;
  nextTriggeredBy: GuidedSetupScreens | null;
  setNextTriggeredBy: React.Dispatch<
    React.SetStateAction<GuidedSetupScreens | null>
  >;
};
