import React, { useEffect, useState, Fragment } from "react";
import { Card, TextField, makeStyles } from "@material-ui/core";
import {
  APIClient,
  generateAuthHeader,
  isRep,
  isHibernateCompany,
  getUserTimezone,
  isCompanyOrCompanyAdmin,
} from "../../lib";
import { useNotify } from "react-admin";
import classnames from "classnames";
import { connect } from "react-redux";

const _FilterableRaisedStatsCard = props => {
  const {
    role,
    hasRepHierarchy,
    repImpersonatingGrandchild,
    repImpersonatingChild,
    companyId,
    season_id,
  } = props;
  const notify = useNotify();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [stats, setStats] = useState({});
  const {
    children_offlineCharged = "",
    children_offlineCredited = "",
    children_offlineItems = "",
    children_onlineCharged = "",
    children_onlineCredited = "",
    children_onlineItems = "",
    children_totalCharged = "",
    children_totalCredited = "",
    children_totalItems = "",
    grandchildren_offlineCharged = "",
    grandchildren_offlineCredited = "",
    grandchildren_offlineItems = "",
    grandchildren_onlineCharged = "",
    grandchildren_onlineCredited = "",
    grandchildren_onlineItems = "",
    grandchildren_totalCharged = "",
    grandchildren_totalCredited = "",
    grandchildren_totalItems = "",
    hibernateChildrenPillowTtl = "",
    hibernateChildrenSheetTtl = "",
    hibernateGrandchildrenPillowTtl = "",
    hibernateGrandchildrenSheetTtl = "",
    hibernatePillowTtl = "",
    hibernateSheetTtl = "",
    offlineCharged = "",
    offlineCredited = "",
    offlineItems = "",
    onlineCharged = "",
    onlineCredited = "",
    onlineItems = "",
    totalCharged = "",
    totalCredited = "",
    totalItems = "",
  } = stats;
  const displayLevels = Boolean(
    isRep(role) && hasRepHierarchy && !repImpersonatingGrandchild,
  );
  const showTransacted = isCompanyOrCompanyAdmin(role);
  const isHibernate = isHibernateCompany(companyId);
  const classes = styles({ showTransacted });

  useEffect(() => {
    const timezone = getUserTimezone();
    let apiString = `/dashboards/raised-aggregates?timezone=${timezone}&start_date=${startDate}&end_date=${endDate}`;
    if (season_id) apiString = `${apiString}&season_id=${season_id}`;
    const fetch = async () => {
      const res = await APIClient.get(apiString, {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setStats(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, season_id]);

  return (
    <Card elevation={3} className={classes.card}>
      <DateFilter setStartDate={setStartDate} setEndDate={setEndDate} />
      {displayLevels && <div className={classes.levelLabel}>my totals</div>}
      <div className={classes.statsSectionWrapper}>
        <div className={classes.statsSection}>
          <Stat
            mainLabel="products sold"
            mainStat={totalItems}
            leftLabel={isHibernate ? "sheets" : "online"}
            leftStat={isHibernate ? hibernateSheetTtl : onlineItems}
            rightLabel={isHibernate ? "pillows" : "offline"}
            rightStat={isHibernate ? hibernatePillowTtl : offlineItems}
          />
          <div className={classes.divider} />
          <Stat
            mainLabel="campaign credited"
            mainStat={totalCredited}
            leftLabel="online"
            leftStat={onlineCredited}
            rightLabel="offline"
            rightStat={offlineCredited}
          />

          {showTransacted && (
            <Fragment>
              <div className={classes.divider} />
              <Stat
                mainLabel="total transacted"
                mainStat={totalCharged}
                leftLabel="online"
                leftStat={onlineCharged}
                rightLabel="offline"
                rightStat={offlineCharged}
              />
            </Fragment>
          )}
        </div>
      </div>

      {/* CHILDREN STATS */}
      {displayLevels && (
        <Fragment>
          <div className={classes.levelLabel}>level 1 totals</div>
          <div className={classes.statsSectionWrapper}>
            <div className={classes.statsSection}>
              <Stat
                mainLabel="products sold"
                mainStat={children_totalItems}
                leftLabel={isHibernate ? "sheets" : "online"}
                leftStat={
                  isHibernate ? hibernateChildrenSheetTtl : children_onlineItems
                }
                rightLabel={isHibernate ? "pillows" : "offline"}
                rightStat={
                  isHibernate
                    ? hibernateChildrenPillowTtl
                    : children_offlineItems
                }
              />
              <div className={classes.divider} />
              <Stat
                mainLabel="campaign credited"
                mainStat={children_totalCredited}
                leftLabel="online"
                leftStat={children_onlineCredited}
                rightLabel="offline"
                rightStat={children_offlineCredited}
              />

              {showTransacted && (
                <Fragment>
                  <div className={classes.divider} />
                  <Stat
                    mainLabel="total transacted"
                    mainStat={children_totalCharged}
                    leftLabel="online"
                    leftStat={children_onlineCharged}
                    rightLabel="offline"
                    rightStat={children_offlineCharged}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}

      {/* GRANDCHILDREN STATS */}
      {displayLevels && !repImpersonatingChild && (
        <Fragment>
          <div className={classes.levelLabel}>level 2 totals</div>
          <div className={classes.statsSectionWrapper}>
            <div className={classes.statsSection}>
              <Stat
                mainLabel="products sold"
                mainStat={grandchildren_totalItems}
                leftLabel={isHibernate ? "sheets" : "online"}
                leftStat={
                  isHibernate
                    ? hibernateGrandchildrenSheetTtl
                    : grandchildren_onlineItems
                }
                rightLabel={isHibernate ? "pillows" : "offline"}
                rightStat={
                  isHibernate
                    ? hibernateGrandchildrenPillowTtl
                    : grandchildren_offlineItems
                }
              />
              <div className={classes.divider} />
              <Stat
                mainLabel="campaign credited"
                mainStat={grandchildren_totalCredited}
                leftLabel="online"
                leftStat={grandchildren_onlineCredited}
                rightLabel="offline"
                rightStat={grandchildren_offlineCredited}
              />

              {showTransacted && (
                <Fragment>
                  <div className={classes.divider} />
                  <Stat
                    mainLabel="total transacted"
                    mainStat={grandchildren_totalCharged}
                    leftLabel="online"
                    leftStat={grandchildren_onlineCharged}
                    rightLabel="offline"
                    rightStat={grandchildren_offlineCharged}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Card>
  );
};

function DateFilter({ setStartDate, setEndDate }) {
  const classes = styles();
  return (
    <div className={classes.filterWrapper}>
      <div className={classes.filterContainer}>
        <div className={classes.filterHeader}>filter by date range</div>
        <div className={classes.inputsWrapper}>
          <TextField
            label="Start date"
            type="date"
            variant="outlined"
            className={classnames(classes.input, classes.leftInput)}
            InputLabelProps={{ shrink: true }}
            onChange={e => setStartDate(e.target.value)}
          />
          <TextField
            label="End date"
            type="date"
            variant="outlined"
            className={classes.input}
            InputLabelProps={{ shrink: true }}
            onChange={e => setEndDate(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export const FilterableRaisedStatsCard = connect(state => {
  const {
    role,
    hasRepHierarchy,
    repImpersonatingGrandchild,
    repImpersonatingChild,
    companyId,
    season_id,
  } = state.user;
  return {
    role,
    hasRepHierarchy,
    repImpersonatingGrandchild,
    repImpersonatingChild,
    companyId,
    season_id,
  };
})(_FilterableRaisedStatsCard);

function Stat(props) {
  const classes = styles();
  const {
    mainLabel,
    mainStat,
    leftLabel,
    leftStat,
    rightLabel,
    rightStat,
  } = props;

  return (
    <div className={classes.statWrapper}>
      <div className={classes.statContainer}>
        <div className={classes.subStatColumn}>
          <div className={classes.subStat}>{leftStat}</div>
          <div className={classes.subStatText}>{leftLabel}</div>
        </div>
        <div className={classes.mainStatColumn}>
          <div className={classes.stat}>{mainStat}</div>
          <div className={classes.statText}>{mainLabel}</div>
        </div>
        <div className={classes.subStatColumn}>
          <div className={classes.subStat}>{rightStat}</div>
          <div className={classes.subStatText}>{rightLabel}</div>
        </div>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  card: {
    width: "100%",
    maxWidth: "100%",
    paddingBottom: "20px",
    marginBottom: "20px",
  },
  levelLabel: {
    textAlign: "center",
    paddingTop: "16px",
    color: "rgba(0,0,0,0.51)",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0.07,
    textTransform: "uppercase",
    paddingBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  statsSectionWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  statsSection: {
    width: ({ showTransacted }) => (showTransacted ? "100%" : "80%"),
    maxWidth: ({ showTransacted }) => (showTransacted ? "100%" : "80%"),
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingBottom: 12,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: () => "100%",
      maxWidth: () => "100%",
    },
  },

  divider: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    borderRight: "1px solid #999999",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
      height: 0,
      width: "100%",
      marginBottom: "16px",
      paddingTop: "16px",
    },
  },

  // DateFilter
  filterWrapper: {
    width: "100%",
    maxWidth: "100%",
    padding: "16px 8px 24px 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  filterHeader: {
    color: "#999999",
    fontSize: "11px",
    fontWeight: 500,
    textTransform: "uppercase",
    textAlign: "center",
  },
  inputsWrapper: {
    marginTop: 16,
  },
  input: {
    width: 172,
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  leftInput: {
    marginRight: 12,
    [theme.breakpoints.down("sm")]: {
      marginRight: 4,
    },
  },

  // Stat
  statWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "33%",
    maxWidth: "33%",
    padding: "0 6px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  statContainer: {
    display: "flex",
    alignItems: "center",
  },
  subStatColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainStatColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 12px",
    },
  },
  stat: {
    fontSize: 20,
    color: "#1e88e5",
    paddingBottom: 6,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  statText: {
    color: "#999999",
    fontSize: "11px",
    fontWeight: 500,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  subStat: {
    fontSize: 14,
    color: "#1e88e5",
    paddingBottom: 6,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  subStatText: {
    color: "#999999",
    fontSize: "9px",
    fontWeight: 500,
    textTransform: "uppercase",
  },
}));
