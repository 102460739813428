import { makeStyles, Tabs, Tab } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { WebpageSetup } from "./components/WebpageSetup";
import { TOP_BAR_HEIGHT_DESKTOP, TOP_BAR_HEIGHT_MOBILE } from "../../types";
import { useIsMobile, useSetTopBarTitle } from "../../hooks/ui";
import { AchForm } from "./components/AchForm";
import { scrollToTop } from "../../lib";
enum Screens {
  WebpageSetup = "WebpageSetup",
  Ach = "ach",
}

export function OrgCampaignSettings() {
  const [activeTab, setActiveTab] = useState<Screens>(Screens.WebpageSetup);
  const classes = styles();
  const isMobile = useIsMobile();
  useSetTopBarTitle(<div className={classes.title}>Campaign settings</div>);

  useEffect(() => {
    scrollToTop();
  }, [activeTab]);

  return (
    <Fragment>
      {isMobile && <div className={classes.title}>Campaign settings</div>}
      <Tabs
        value={activeTab}
        onChange={(_, newValue) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        classes={{ root: classes.tabs }}
      >
        <Tab
          label="Webpage setup"
          value={Screens.WebpageSetup}
          className={classes.tab}
        />
        <Tab label="ACH" value={Screens.Ach} className={classes.tab} />
      </Tabs>
      <div className={classes.content}>
        {activeTab === Screens.WebpageSetup && <WebpageSetup />}
        {activeTab === Screens.Ach && <AchForm />}
      </div>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      height: 60,
      display: "flex",
      alignItems: "center",
      paddingLeft: 16,
    },
  },
  tabs: {
    borderBottom: "1px solid #DBDEEE",
    margin: "0 32px",
    position: "sticky",
    top: TOP_BAR_HEIGHT_DESKTOP,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      top: TOP_BAR_HEIGHT_MOBILE,
    },
  },
  tab: {
    maxWidth: "fit-content",
  },
  content: {
    padding: "24px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
    },
  },
}));
