import React, { useState, useEffect } from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  useNotify,
  useListContext,
  BooleanInput,
} from "react-admin";
import {
  Chip,
  Button,
  MenuItem,
  TextField as MuiTextField,
  makeStyles,
} from "@material-ui/core";
import { Form } from "react-final-form";
import { APIClient, generateAuthHeader } from "../../lib";

const PrizeProductFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Product ID/Sku/Name" source="q" alwaysOn />
      <BooleanInput source="exclude_variants" label="Excl. Variants" alwaysOn />
    </Filter>
  );
};

export default function SupplierCompanyPrizesByProductList(props) {
  const classes = styles();
  const notify = useNotify();
  const [supplierCompanies, setSupplierCompanies] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        "/company_product_suppliers/supplier_company_autocomplete",
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setSupplierCompanies(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List
      {...props}
      perPage={25}
      title="Supplier-Company Prizes"
      bulkActionButtons={false}
      actions={null}
      empty={false}
      filters={<PrizeProductFilter />}
    >
      <Datagrid
        rowClick="expand"
        expand={<EditCompanies supplierCompanies={supplierCompanies} />}
      >
        <TextField source="id" label="Product ID" />
        <TextField source="sku" />
        <TextField source="product_name" label="Name" />
        <FunctionField
          label="Companies"
          render={({ companies = [] }) =>
            companies.map(({ id, name }) => (
              <Chip
                key={id}
                label={name}
                size="small"
                className={classes.chip}
              />
            ))
          }
        />
      </Datagrid>
    </List>
  );
}

function EditCompanies({ record, supplierCompanies }) {
  const { id, companies = [] } = record;
  const classes = styles();
  const notify = useNotify();
  const { refetch } = useListContext();
  const [companyIds, setCompanyIds] = useState([]);

  useEffect(() => {
    const currCompanyIds = companies.map(({ id }) => id);
    setCompanyIds(currCompanyIds);
  }, [companies]);

  const onSubmit = async () => {
    const res = await APIClient.put(
      `supplier_company_prize_products/product/${id}/update_companies`,
      { companyIds },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) notify(errorMessage, "warning");
    else notify("Companies successfully updated");
    refetch();
  };

  return (
    <div>
      <div className={classes.expandTitle}>Update Authorized Companies</div>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <MuiTextField
                select
                SelectProps={{ multiple: true }}
                label="Select authorized companies"
                name="companies"
                value={companyIds}
                onChange={({ target }) => setCompanyIds(target.value)}
                className={classes.select}
              >
                {supplierCompanies.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </MuiTextField>

              <div className={classes.submit}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                >
                  save
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  chip: { margin: "0 2px" },
  expandTitle: {
    fontSize: 22,
    marginTop: 12,
    marginBottom: 24,
    fontWeight: 600,
  },
  submit: { margin: "12px 0" },
  select: { minWidth: 300 },
}));
