import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import {
  GenericObject,
  PaymentMethod,
  PaymentMethods,
} from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import { formatMoney } from "../../../../lib";

type Props = {
  donation: GenericObject;
};

export function OrderFeesCard({ donation }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const [paymentMethodTxt, setPaymentMethodTxt] = useState("");
  const {
    items_total,
    shipping_and_handling,
    online_fee,
    custom_fee,
    total_tax,
    additional_donation,
    charge_amount,
    total_amount,
    ccType,
    maskedCC,
    pmt_type,
  } = donation;

  useEffect(() => {
    const paymentType = pmt_type as PaymentMethod;
    if (paymentType === "cc") {
      setPaymentMethodTxt(`${ccType ? ccType : "Card"} ending in ${maskedCC}`);
    } else setPaymentMethodTxt(PaymentMethods[paymentType] || "");
  }, [pmt_type, ccType, maskedCC]);

  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Payment & Fees</div>
      {show(items_total) && (
        <div className={classNames(classes.paymentRow, classes.medWeight)}>
          <div>Items total</div>
          <div>{formatMoney(items_total)}</div>
        </div>
      )}
      {show(shipping_and_handling) && (
        <div className={classes.paymentRow}>
          <div>Shipping & handling</div>
          <div>{formatMoney(shipping_and_handling)}</div>
        </div>
      )}
      {show(online_fee) && (
        <div className={classes.paymentRow}>
          <div>Online fee</div>
          <div>{formatMoney(online_fee)}</div>
        </div>
      )}
      {show(custom_fee) && (
        <div className={classes.paymentRow}>
          <div>Custom fee</div>
          <div>{formatMoney(custom_fee)}</div>
        </div>
      )}
      {show(total_tax) && (
        <div className={classes.paymentRow}>
          <div>Tax</div>
          <div>{formatMoney(total_tax)}</div>
        </div>
      )}
      {show(additional_donation) && (
        <div className={classNames(classes.paymentRow, classes.medWeight)}>
          <div>Additional donation</div>
          <div>{formatMoney(additional_donation)}</div>
        </div>
      )}

      <div className={classes.divider} />

      <div className={classNames(classes.paymentRow, classes.medWeight)}>
        <div>Total</div>
        <div>{formatMoney(charge_amount)}</div>
      </div>

      <div className={classNames(classes.paymentRow, classes.credited)}>
        <div>Total credited towards campaign</div>
        <div>{formatMoney(total_amount)}</div>
      </div>

      <div className={classes.divider} />

      <div className={classes.paymentRow}>
        <div>Payment method</div>
        <div>{paymentMethodTxt}</div>
      </div>
    </div>
  );
}

function show(figure: any) {
  return figure > 0;
}

const styles = makeStyles(theme => ({
  paymentRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  medWeight: {
    fontWeight: 500,
  },
  credited: {
    fontWeight: 600,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
    },
  },
  divider: {
    width: "100%",
    height: 1,
    borderBottom: "1px solid #EAEBF3",
    margin: "16px 0",
  },
}));
