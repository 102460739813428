import React, { Fragment } from "react";
import { FieldArray } from "react-final-form-arrays";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import RemoveIcon from "@material-ui/icons/Delete";
import { TextInput } from "./TextInput";
import {
  required,
  email as emailValidation,
  contactEmailOrPhoneValidation,
  composeValidators,
} from "../lib";

export function ContactFieldArray() {
  const classes = styles();

  return (
    <FieldArray name="contacts">
      {({ fields }) => (
        <Fragment>
          {fields.map((name, index) => (
            <div key={name} className={classes.contactCard}>
              <IconButton
                className={classes.removeContact}
                onClick={() => fields.remove(index)}
                size="small"
              >
                <RemoveIcon />
              </IconButton>

              <TextInput
                name={`${name}.name`}
                label="Name"
                validation={required}
              />
              <TextInput
                name={`${name}.email`}
                label="Email (optional)"
                validation={composeValidators(
                  contactEmailOrPhoneValidation,
                  emailValidation,
                )}
              />
              <TextInput
                name={`${name}.phone`}
                label="Phone (optional)"
                validation={contactEmailOrPhoneValidation}
              />
            </div>
          ))}
          <Button
            color="primary"
            onClick={() => fields.push({})}
            startIcon={<AddIcon />}
            className={classes.addButton}
          >
            Add more
          </Button>
        </Fragment>
      )}
    </FieldArray>
  );
}

const styles = makeStyles(
  _theme => ({
    contactCard: {
      width: "316px",
      border: "solid 1px lightgray",
      margin: "20px",
      position: "relative",
      display: "flex",
      paddingTop: "12px",
      paddingLeft: "16px",
      flexDirection: "column",
    },
    removeContact: {
      position: "absolute",
      top: "4px",
      right: "4px",
    },
    addButton: {
      marginLeft: 20,
      marginBottom: 20,
    },
    footer: {
      width: "100%",
      height: "64px",
      padding: "0 20px",
      display: "flex",
      alignItems: "center",
      backgroundColor: "#f5f5f5",
    },
  }),
  {
    classNamePrefix: "contactViews",
  },
);
