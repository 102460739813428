import React from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  useListController,
  ListView,
  Pagination,
  useRefresh,
  useNotify,
  ImageField,
  FunctionField,
} from "react-admin";
import {
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Chip,
  InputAdornment,
} from "@material-ui/core";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { TextField as FFTextField } from "final-form-material-ui";
import { twoDecimalsOnly, APIClient, generateAuthHeader } from "../../lib";

const ProductFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Product Name" source="q" alwaysOn />
      <TextInput label="Sku" source="sku" alwaysOn />
    </Filter>
  );
};

export function ProductWholesalePrices(props) {
  const { record: { id: supplier_id } = {} } = props;
  const classes = styles();

  const controllerProps = useListController({
    sort: { field: "id", order: "ASC" },
    basePath: "",
    resource: "product_wholesale_prices",
    filter: { supplier_id },
    perPage: 25,
  });

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={null}
        filters={<ProductFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid
          rowClick="expand"
          expand={<EditWholesalePrices supplier_id={supplier_id} />}
        >
          <TextField source="id" />
          <TextField source="sku" />
          <TextField source="product_name" />
          <ImageField
            source="thumbnail_image"
            label="thumbnail"
            sortable={false}
            classes={{ image: classes.thumbnail }}
          />
          <TextField source="product_price" label="Retail Price" />
          <FunctionField
            label="Wholesale Prices"
            render={({ supplierCompanies }) =>
              supplierCompanies.map(({ id, companyName, wholesale_price }) => {
                const label = `${companyName} - ${
                  wholesale_price ? `$${wholesale_price}` : ""
                }`;
                return (
                  <Chip
                    key={id}
                    label={label}
                    size="small"
                    className={classes.chip}
                  />
                );
              })
            }
          />
        </Datagrid>
      </ListView>
    </div>
  );
}

function EditWholesalePrices({ record, supplier_id }) {
  const { supplierCompanies, id } = record;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();

  const onSubmit = async ({ supplierCompanies }) => {
    const updated = [];
    supplierCompanies.forEach(c => {
      const { company_id, wholesalePriceId, updatedWholesalePrice } = c;
      if (updatedWholesalePrice)
        updated.push({
          company_id,
          product_wholesale_price_id: wholesalePriceId,
          wholesale_price: updatedWholesalePrice,
        });
    });
    if (!updated.length) {
      return notify("There is nothing to update!", "warning");
    }
    const res = await APIClient.put(
      `/product_wholesale_prices/products/${id}`,
      { supplier_id, supplierCompanies: updated },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    notify("Prices have been successfully updated");
    refresh();
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={{ supplierCompanies }}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TableContainer component={Paper}>
                <FieldArray name="supplierCompanies">
                  {({ fields }) => (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Company</TableCell>
                          <TableCell>Current Wholesale Price</TableCell>
                          <TableCell>Adjust Wholesale Price</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {fields.map((row, index) => {
                          const { companyName, wholesale_price } = fields.value[
                            index
                          ];
                          return (
                            <TableRow key={index}>
                              <TableCell>{companyName}</TableCell>
                              <TableCell>${wholesale_price}</TableCell>
                              <TableCell>
                                <Field
                                  variant="standard"
                                  component={FFTextField}
                                  name={`${row}.updatedWholesalePrice`}
                                  type="number"
                                  validate={twoDecimalsOnly}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </FieldArray>
              </TableContainer>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
                className={classes.save}
              >
                save changes
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}

const styles = makeStyles(_theme => ({
  thumbnail: { maxHeight: 50 },
  chip: { margin: 3 },
  save: { color: "#FFFFFF", marginTop: 12, marginBottom: 8 },
}));
