import { useState } from "react";
import { APIUtilClient, generateAuthHeader } from "../../../lib";
import { Confirm, useNotify, useRefresh } from "react-admin";

export function AssignUnassignedSTSOrders({ campaign_id, closeModal }) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);

  const assign = async () => {
    setSubmitting(true);
    const res = await APIUtilClient.put(
      `/donations/assign_unassigned-sts-orders?campaign_id=${campaign_id}`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) {
      notify(errorMessage, { type: "warning" });
      setSubmitting(false);
    } else {
      notify("STS orders have been assigned");
      refresh();
      closeModal();
    }
  };

  return (
    <Confirm
      isOpen={true}
      title="Are you sure?"
      content="Are you sure you want to assign all unassigned STS orders to a generic student account?"
      translateOptions={false}
      onConfirm={assign}
      onClose={closeModal}
      loading={submitting}
    />
  );
}
