import { useDispatch, useSelector } from "react-redux";
import RemoveIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles, MenuItem } from "@material-ui/core";
import { FormApi } from "final-form";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import {
  APIAuthClient,
  convertFileToB64,
  requiredField,
  stateOptions,
} from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  NullableField,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { RootState } from "../../../types/state";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddMerchant({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const categories = useSelector(
    ({ discountCard }: RootState) =>
      discountCard.discountCardCategoryAutocomplete,
  );

  const onSubmit = async (values: any, _: any, complete: any) => {
    const {
      discount_card_merchant_addresses: addresses,
      logoFile,
      ...rest
    } = values;
    const post = {
      active: true,
      ...rest,
      discount_card_merchant_addresses: addresses ? addresses : [],
    };
    if (logoFile) post.logoBase64 = await convertFileToB64(logoFile);
    const url = `/discount_card_merchants`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Merchant added", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={{ discount_card_merchant_addresses: [{}] }}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {!isMobile && (
                <div className={classes.top}>
                  <h1>Add merchant</h1>
                </div>
              )}
              {isMobile && (
                <div className={classes.mobileTop}>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              {isMobile && (
                <div className={classes.mobileHeader}>
                  <h1>Add merchant</h1>
                </div>
              )}

              <div className={classes.mainContent}>
                <div className={classes.inputGroup}>
                  <Field
                    component={TextFieldWrapper}
                    name="merchant_name"
                    label="Merchant name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                  <ChangeListener form={form} />
                  <Field
                    component={TextFieldWrapper}
                    name="display_name"
                    label="Display name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="discount_card_category_id"
                    label="Category"
                    validate={requiredField}
                    className={classes.halfInput}
                  >
                    {categories.map(({ id, category_name }) => (
                      <MenuItem key={id} value={id}>
                        {category_name}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                <NullableField
                  component={TextFieldWrapper}
                  name="notes"
                  label="Notes"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />

                <div className={classes.subheader}>Locations</div>
                <FieldArray name="discount_card_merchant_addresses">
                  {({ fields }) => (
                    <div className={classes.locations}>
                      {fields.map((row, index) => {
                        return (
                          <div key={index}>
                            <div className={classes.addressRow}>
                              <div className={classes.addressInputs}>
                                <NullableField
                                  name={`${row}.location_name`}
                                  component={TextFieldWrapper}
                                  label="Location name (optional)"
                                  className={classes.input}
                                />
                                <div className={classes.inputGroup}>
                                  <Field
                                    name={`${row}.address`}
                                    component={TextFieldWrapper}
                                    label="Address"
                                    validate={requiredField}
                                    className={classes.halfInput}
                                  />
                                  <NullableField
                                    name={`${row}.address2`}
                                    component={TextFieldWrapper}
                                    label="Address line 2"
                                    className={classes.halfInput}
                                  />
                                </div>
                                <div className={classes.inputGroup}>
                                  <Field
                                    name={`${row}.city`}
                                    component={TextFieldWrapper}
                                    label="City"
                                    validate={requiredField}
                                    className={classes.halfInput}
                                  />
                                  <div className={classes.halfInputGroup}>
                                    <Field
                                      select
                                      name={`${row}.state`}
                                      component={TextFieldWrapper}
                                      label="State"
                                      validate={requiredField}
                                      className={classes.halfInput}
                                    >
                                      {stateOptions.map(({ name, code }) => (
                                        <MenuItem key={code} value={code}>
                                          {name}
                                        </MenuItem>
                                      ))}
                                    </Field>
                                    <Field
                                      name={`${row}.zip`}
                                      component={TextFieldWrapper}
                                      label="Zip"
                                      validate={requiredField}
                                      className={classes.halfInput}
                                    />
                                  </div>
                                </div>
                                <div className={classes.inputGroup}>
                                  <NullableField
                                    name={`${row}.phone`}
                                    component={TextFieldWrapper}
                                    label="Phone number"
                                    className={classes.halfInput}
                                  />
                                  <NullableField
                                    name={`${row}.website`}
                                    component={TextFieldWrapper}
                                    label="Website"
                                    className={classes.halfInput}
                                  />
                                </div>
                              </div>
                              <div className={classes.removeAddressWrapper}>
                                <IconButton
                                  disabled={fields.length === 1}
                                  onClick={() => fields.remove(index)}
                                >
                                  <RemoveIcon
                                    className={
                                      fields.length !== 1
                                        ? classes.removeAddress
                                        : ""
                                    }
                                  />
                                </IconButton>
                              </div>
                            </div>
                            <div className={classes.addressSeparator} />
                          </div>
                        );
                      })}

                      <Button
                        color="primary"
                        variant="text"
                        startIcon={<AddIcon />}
                        onClick={() => fields.push({})}
                      >
                        LOCATION
                      </Button>
                    </div>
                  )}
                </FieldArray>

                <div className={classes.subheader}>Logo</div>
                <ImageDropzoneField fieldName="logoFile" />
              </div>
              <div className={classes.bottom}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

function ChangeListener({ form }: { form: FormApi }) {
  return (
    <OnChange name="merchant_name">
      {value => {
        if (value) form.change("display_name", value);
      }}
    </OnChange>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  mobileTop: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 12,
    position: "sticky",
    top: 0,
  },
  mobileHeader: {
    display: "flex",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "0 16px",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInputGroup: {
    width: "calc(50% - 12px)",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  subheader: {
    padding: "16px 0",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
  },
  locations: {
    marginBottom: 24,
  },
  addressInputs: {
    flex: 1,
  },
  addressRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  removeAddressWrapper: {
    marginLeft: 16,
    width: "fit-content",
    maxWidth: "fit-content",
    color: theme.palette.error.main,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8,
    },
  },
  removeAddress: {
    color: theme.palette.error.main,
  },
  addressSeparator: {
    marginBottom: 24,
    borderBottom: "1px solid #EAEBF3",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      marginLeft: -16,
      marginRight: -16,
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
