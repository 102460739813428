import React, { Fragment, useState } from "react";
import {
  TextInput,
  Edit,
  SimpleForm,
  Toolbar,
  SaveButton,
  BooleanInput,
  required,
} from "react-admin";
import { ButtonGroup, Button, makeStyles } from "@material-ui/core";
import { CampaignDonationGroupItems } from "./CampaignDonationGroupItems";
import { DonationGroupItemsOrdering } from "../../../components";

const modes = {
  GROUP_CONFIG: "GROUP_CONFIG",
  ITEMS: "ITEMS",
  ORDERING: "ORDERING",
};
const requiredInput = [required()];

export function CampaignDonationGroup({ record }) {
  const { id: campaign_id, campaign_donation_group_id } = record;
  const classes = styles();
  const [mode, setMode] = useState(modes.GROUP_CONFIG);

  const transformDataForSubmit = values => {
    const {
      id: _rm,
      campaign_id: _rm2,
      donation_group_id: _rm3,
      ...rest
    } = values;
    return rest;
  };

  return (
    <Fragment>
      <ButtonGroup className={classes.buttons} color="primary">
        <Button
          variant={mode === modes.GROUP_CONFIG ? "contained" : "outlined"}
          onClick={() => setMode(modes.GROUP_CONFIG)}
        >
          group config
        </Button>
        <Button
          variant={mode === modes.ITEMS ? "contained" : "outlined"}
          onClick={() => setMode(modes.ITEMS)}
        >
          items
        </Button>
        <Button
          variant={mode === modes.ORDERING ? "contained" : "outlined"}
          onClick={() => setMode(modes.ORDERING)}
        >
          item sorting
        </Button>
      </ButtonGroup>

      {mode === modes.GROUP_CONFIG && (
        <Edit
          resource="campaign_donation_groups"
          id={campaign_donation_group_id}
          basePath={`/campaigns/${campaign_id}/show`}
          title=" "
          successMessage="Group updated successfully"
          undoable={false}
          transform={transformDataForSubmit}
        >
          <SimpleForm
            toolbar={
              <Toolbar>
                <SaveButton redirect={false} />
              </Toolbar>
            }
          >
            <TextInput source="id" disabled />
            <TextInput source="group_name" validate={requiredInput} />
            <TextInput
              source="header"
              validate={requiredInput}
              multiline
              fullWidth
            />
            <TextInput
              source="taken_label"
              label="Taken label (ex: `Dedicated by`)"
              validate={requiredInput}
            />
            <TextInput source="tier_1_subheader" multiline fullWidth />
            <TextInput source="tier_2_subheader" multiline fullWidth />
            <TextInput source="tier_3_subheader" multiline fullWidth />
            <BooleanInput source="display_tier_1_avatars" />
            <BooleanInput source="display_tier_2_avatars" />
            <BooleanInput source="display_tier_3_avatars" />
          </SimpleForm>
        </Edit>
      )}

      {mode === modes.ITEMS && <CampaignDonationGroupItems record={record} />}

      {mode === modes.ORDERING && (
        <DonationGroupItemsOrdering
          urlPrefix="campaign_donation_group_items"
          group_id={campaign_donation_group_id}
        />
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  buttons: {
    margin: "24px 0 12px 0",
  },
}));
