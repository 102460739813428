import { Theme, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { useState, useEffect } from "react";
import { useIsLGScreen, useIsMobile } from "../../../hooks/ui";
import { GenericObject } from "../../../types";
import { AwardSVG } from "../../../assets/AwardSVG";
import { AwardSmallSVG } from "../../../assets/AwardSmallSVG";
const LGScreenIndexSort = [0, 5, 1, 6, 2, 7, 3, 8, 4, 9];
const SMScreenIndexSort = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export function TopVolunteers({ volunteers }: { volunteers: GenericObject[] }) {
  const isLGScreen = useIsLGScreen();
  const isMobile = useIsMobile();
  const { primary_color, volunteer_label } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const [primaryColor, setPrimaryColor] = useState<string>("inherit");
  const [indexSort, setIndexSort] = useState<number[]>([]);

  useEffect(() => {
    if (primary_color) setPrimaryColor(primary_color);
  }, [primary_color]);

  useEffect(() => {
    setIndexSort(isLGScreen ? LGScreenIndexSort : SMScreenIndexSort);
  }, [isLGScreen]);

  const classes = styles({ primaryColor });
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>
        Top {volunteer_label ? volunteer_label : "student"}s
      </h2>
      <div className={classes.cards}>
        {indexSort.map(i => {
          let name = "";
          let raised = "";
          const award = i === 0 ? "gold" : i === 1 ? "silver" : "bronze";
          if (volunteers[i]) {
            const V = volunteers[i];
            name = `${V.first_name} ${V.last_name}`;
            raised = V.raised as string;
          }
          return (
            <div key={i} className={classes.card}>
              {i < 3 && (
                <div className={classes.award}>
                  {!isMobile && <AwardSVG type={award} />}
                  {isMobile && <AwardSmallSVG type={award} />}
                </div>
              )}
              <div className={classes.numAndName}>
                <div className={classes.numWrapper}>{i + 1}</div>
                <div className={classes.name}>{name}</div>
              </div>
              <div className={classes.raised}>{raised ? `$${raised}` : ""}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

type StyleProps = {
  primaryColor?: string;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: "100%",
  },
  title: {
    marginBottom: 22,
    paddingTop: 8,
    color: "#FFFFFF",
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.23,
    lineHeight: "26px",
    textAlign: "center",
  },
  cards: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  card: {
    position: "relative",
    width: "calc(50% - 20px)",
    height: 56,
    borderRadius: 28,
    backgroundColor: "#FFFFFF",
    padding: "8px 32px 8px 8px",
    marginBottom: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: 40,
      padding: "4px 21px 4px 5px",
      marginBottom: 16,
    },
  },
  award: {
    position: "absolute",
    top: -2,
    left: -4,
    [theme.breakpoints.down("sm")]: {
      top: -4.5,
      left: -4,
    },
  },
  numAndName: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    textWrap: "nowrap",
  },
  numWrapper: {
    zIndex: 9999,
    height: 40,
    width: 40,
    minWidth: 40,
    backgroundColor: ({ primaryColor }) => primaryColor,
    borderRadius: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.23,
    color: "#FFFFFF",
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      height: 32,
      width: 32,
      minWidth: 32,
      fontSize: 16,
      letterSpacing: 0.15,
      marginRight: 12,
    },
  },
  name: {
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0.3,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "38px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.15,
      lineHeight: "20px",
    },
  },
  raised: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.3,
    color: ({ primaryColor }) => primaryColor,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.15,
    },
  },
}));
