import { Theme, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import CountdownComp from "react-countdown-now";
import { RootState } from "../../../types/state";
import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";

export function Progress() {
  const classes = styles({});
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { primary_color, show_profit_based_progress, end_date } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const { campaignProgressStatsFetched, campaignProgressStats } = useSelector(
    ({ dashboardData }: RootState) => dashboardData,
  );
  const [primaryColor, setPrimaryColor] = useState<string>("inherit");
  const {
    orgFormattedRaised,
    orgPercentOfGoal,
    orgPercentRaised,
    orgFormattedGoal,
  } = campaignProgressStats;

  useEffect(() => {
    if (primary_color) setPrimaryColor(primary_color);
  }, [primary_color]);

  if (!campaignProgressStatsFetched) {
    return (
      <div className={classes.container}>
        <Skeleton
          variant="rect"
          width={"100%"}
          height={150}
          className={classes.skeleton}
        />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      {isDesktop && (
        <ProgressCard
          raised={orgFormattedRaised}
          goal={orgFormattedGoal}
          percentRaised={orgPercentRaised}
          percentOfGoal={orgPercentOfGoal}
          profitBased={Boolean(show_profit_based_progress)}
          primaryColor={primaryColor}
        />
      )}

      {isMobile && (
        <MobileProgressCard
          raised={orgFormattedRaised}
          goal={orgFormattedGoal}
          percentRaised={orgPercentRaised}
          percentOfGoal={orgPercentOfGoal}
          profitBased={Boolean(show_profit_based_progress)}
          primaryColor={primaryColor}
        />
      )}

      {end_date && <Countdown endDate={end_date} primaryColor={primaryColor} />}
    </div>
  );
}

type ProgressCardProps = {
  isVolunteer?: boolean;
  raised: any;
  goal: any;
  percentRaised: any;
  percentOfGoal: any;
  profitBased?: boolean;
  primaryColor: string;
};
function ProgressCard({
  raised,
  goal,
  percentRaised,
  percentOfGoal,
  profitBased,
  primaryColor,
}: ProgressCardProps) {
  const classes = styles({ percentRaised, primaryColor });

  return (
    <div className={classes.progressCard}>
      <div className={classes.top}>
        <div className={classes.figureSection}>
          <span className={classes.largeFigure}>
            ${raised} {profitBased ? "Profit Raised" : "Raised"}
          </span>
          <span className={classes.figureSpace} />
          {percentOfGoal}% of goal
        </div>
        <div className={classes.figureSection}>
          Goal
          <span className={classes.figureSpace} />
          <span className={classes.largeFigure}>${goal}</span>
        </div>
      </div>

      <div className={classes.progressBar}>
        <div className={classes.progress} />
      </div>
    </div>
  );
}

function MobileProgressCard({
  raised,
  goal,
  percentRaised,
  percentOfGoal,
  profitBased,
  primaryColor,
}: ProgressCardProps) {
  const classes = styles({ percentRaised, primaryColor });
  return (
    <div className={classes.progressCard}>
      <div className={classes.progressBar}>
        <div className={classes.progress} />
      </div>

      <div className={classes.bottom}>
        <div>
          <div className={classes.mobileFigure}>${raised}</div>
          <div className={classes.mobileLabel}>
            {profitBased ? "Profit Raised" : "Raised"}
          </div>
        </div>

        <div>
          <div className={classes.mobileFigure}>{percentOfGoal}%</div>
          <div className={classes.mobileLabel}>Of goal raised</div>
        </div>

        <div>
          <div className={classes.mobileFigure}>${goal}</div>
          <div className={classes.mobileLabel}>Goal</div>
        </div>
      </div>
    </div>
  );
}

function Countdown({
  endDate,
  primaryColor,
}: {
  endDate: Date;
  primaryColor: string;
}) {
  const classes = styles({ primaryColor });
  return (
    <div className={classes.countdown}>
      <div className={classes.countdownTitle}>Time remaining</div>
      <CountdownComp
        date={new Date(endDate)}
        intervalDelay={1000}
        renderer={({ days, hours, minutes, seconds }) => {
          return (
            <div className={classes.timeCards}>
              <div className={classes.timeCard}>
                <div className={classes.time}>{days}</div>
                <div>days</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{hours}</div>
                <div>hours</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{minutes}</div>
                <div>minutes</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{seconds}</div>
                <div>seconds</div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

type StyleProps = {
  percentRaised?: string;
  primaryColor?: string;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  skeleton: {
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
  },
  progressCard: {
    width: "calc(100% - 450px)",
    borderRadius: 8,
    padding: "32px 24px",
    backgroundColor: "#FFFFFF",
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 98,
      padding: 16,
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 24,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.3,
    [theme.breakpoints.only("lg")]: {
      fontSize: 22,
    },
  },
  figureSection: {
    display: "flex",
    alignItems: "center",
  },
  largeFigure: {
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0.4,
    color: ({ primaryColor }) => primaryColor,
    [theme.breakpoints.only("lg")]: {
      fontSize: 28,
    },
  },
  figureSpace: {
    paddingLeft: 16,
  },
  bottom: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: 16,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.17,
      lineHeight: "16px",
    },
  },
  progressBar: {
    display: "flex",
    height: 20,
    width: "100%",
    borderRadius: 20,
    backgroundColor: "#F5F5F5",
    [theme.breakpoints.down("sm")]: {
      height: 16,
    },
  },
  progress: {
    height: "100%",
    width: ({ percentRaised }) => percentRaised,
    backgroundColor: ({ primaryColor }) => primaryColor,
    borderRadius: 20,
  },
  mobileLabel: {
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "13px",
    fontWeight: 500,
  },
  mobileFigure: {
    color: ({ primaryColor }) => primaryColor,
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.17,
    lineHeight: "16px",
    paddingBottom: 8,
  },
  // COUNTDOWN
  countdown: {
    minHeight: 150,
    width: 418,
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: 16,
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 108,
      padding: "16px 0",
    },
  },
  countdownTitle: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0.3,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.2,
      marginBottom: 16,
    },
  },
  timeCards: {
    display: "flex",
    alignItems: "center",
  },
  timeCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 60,
    margin: "0 18px",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    [theme.breakpoints.down("sm")]: {
      minWidth: 57,
      margin: "0 9px",
    },
  },
  time: {
    fontSize: 32,
    fontWeight: "bold",
    letterSpacing: 0,
    lineHeight: "35px",
    color: ({ primaryColor }) => primaryColor,
    paddingBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      paddingBottom: 5,
      lineHeight: "26px",
    },
  },
}));
