import React from "react";
import {
  Datagrid,
  TextField,
  useListController,
  ListView,
  Pagination,
  TextInput,
  Filter,
  ExportButton,
  downloadCSV,
  TopToolbar,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { BOCampaignProducts } from "./BOCampaignProducts";

const CampaignFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Campaign ID" source="q" alwaysOn />
    </Filter>
  );
};

export function BOCampaigns(props) {
  const { location, id: supplier_id } = props;

  const controllerProps = useListController({
    sort: { field: "id", order: "ASC" },
    location,
    basePath: "/back-order-campaigns",
    resource: "inventory/back_order_campaigns",
    filter: { supplier_id },
    perPage: 25,
  });

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues, supplier_id }}
        exporter={backOrderExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={<CampaignFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
      >
        <Datagrid rowClick="expand" expand={<BOCampaignProducts />}>
          <TextField source="id" label="Campaign ID" />
          <TextField source="companyName" sortable={false} label="Company" />
          <TextField source="orgName" sortable={false} label="Org" />
          <TextField
            source="backOrderScanCount"
            label="Back Order Scan Count"
          />
          <TextField
            source="backOrderUnfulfilledCount"
            label="Back Order Unfulfilled Count"
          />
        </Datagrid>
      </ListView>
    </div>
  );
}

function backOrderExporter(campaigns) {
  const formattedCampaigns = campaigns.map(campaign => {
    const {
      backOrderScanCount,
      backOrderUnfulfilledCount,
      companyName,
      id,
      orgName,
    } = campaign;
    const exportObj = {
      "Campaign ID": id,
      Company: companyName,
      Org: orgName,
      "Back Order Scan Count": backOrderScanCount,
      "Back Order Unfulfilled Count": backOrderUnfulfilledCount,
    };
    return exportObj;
  });
  jsonExport(formattedCampaigns, (err, csv) => {
    downloadCSV(csv, "Back Order Campaigns");
  });
}
