import React from "react";
import { styles } from "./RepCommission.styles";
import { InputAdornment } from "@material-ui/core";
import { Field } from "react-final-form";
import { TextField } from "final-form-material-ui";

export const Input = props => {
  const classes = styles();
  const { isPercent, isMoney, wideInput, ...rest } = props;
  const data = { ...rest };
  if (isPercent) {
    data.InputProps = {
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    };
  }
  if (isMoney) {
    data.InputProps = {
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    };
  }
  return (
    <Field
      variant="standard"
      component={TextField}
      parse={value => value}
      {...data}
      className={wideInput ? classes.wideInput : classes.input}
    />
  );
};
