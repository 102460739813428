export const paths = {
  CONTACTS: "/contacts",
  DONATIONS: "/donations",
  HOME: "/",
  LOGIN: "/login",
  PROFILE: "/profile", // don't change this path name for legacy links already shared
  VOLUNTEER_EZ_SHARE: "/share",
  VOLUNTEER_GLOBAL_DASHBOARD: "/",
  VOLUNTEER_GUIDED_SETUP: "/volunteer_guided_setup",
  VOLUNTEER_SSO: "/volunteer_sso",
  ORG_CAMPAIGNS: "/org_campaigns",
  MOBILE_MENU: "/menu",
  ADD_ASSOC_VOLUNTEER_ACCOUNT: "/add_associated_volunteer_account",
  LEADERBOARD: "/leaderboard",
  TEAMS: "/teams",
  VOLUNTEERS: "/volunteers",
  SUBMISSIONS: "/submissions",
  ORG_CAMPAIGN_SETTINGS: "/org_campaign_settings",
  ORG_EMPLOYEES: "/org_employees",
  EVENTS: "/events",
  EVENT_TICKETS: "/event_tickets",
  MEDIA_CENTER: "/media_center",
  GIVEAWAYS: "/giveaways",
};

export const volunteerAuthPaths = {
  [paths.HOME]: true,
  [paths.CONTACTS]: true,
  [paths.VOLUNTEER_GUIDED_SETUP]: true,
  [paths.VOLUNTEER_EZ_SHARE]: true,
  [paths.PROFILE]: true,
  [paths.DONATIONS]: true,
  [paths.ORG_CAMPAIGNS]: true,
  [paths.MOBILE_MENU]: true,
  [paths.ADD_ASSOC_VOLUNTEER_ACCOUNT]: true,
  [paths.LEADERBOARD]: true,
};

export const orgEmployeeAuthPaths = {
  [paths.HOME]: true,
  [paths.DONATIONS]: true,
  [paths.ORG_CAMPAIGNS]: true,
  [paths.MOBILE_MENU]: true,
  [paths.LEADERBOARD]: true,
  [paths.TEAMS]: true,
  [paths.VOLUNTEERS]: true,
  [paths.SUBMISSIONS]: true,
  [paths.ORG_CAMPAIGN_SETTINGS]: true,
  [paths.PROFILE]: true,
  [paths.EVENT_TICKETS]: true,
  [paths.MEDIA_CENTER]: true,
  [paths.GIVEAWAYS]: true,
};

export const orgAuthPaths = {
  ...orgEmployeeAuthPaths,
  [paths.ORG_EMPLOYEES]: true,
  [paths.EVENTS]: true,
};

// QUERY PARAMS
export const fromMenuQueyParam = "from_menu";
export const showEmailSelectorParam = "show_email_selector";
