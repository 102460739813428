import React, { useState, Fragment } from "react";
import { useNotify } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { useSelector } from "react-redux";
import { APIClient, generateAuthHeader, isFulfiller } from "../../../lib";

export function ManuallyFulfillSTSOrders({ campaign_id }) {
  const classes = styles();
  const [showDialog, setShowDialog] = useState(false);
  const role = useSelector(state => state.user.role);

  const toggleDialog = () => {
    setShowDialog(prev => !prev);
  };

  if (isFulfiller(role)) return <></>;
  return (
    <Fragment>
      <Button
        size="small"
        variant="outlined"
        startIcon={<DoneIcon />}
        color="primary"
        onClick={toggleDialog}
        className={classes.button}
      >
        Manually fulfill STS orders
      </Button>
      {showDialog && (
        <Modal campaign_id={campaign_id} toggleDialog={toggleDialog} />
      )}
    </Fragment>
  );
}

function Modal({ campaign_id, toggleDialog }) {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const classes = styles();

  const markFulfilled = async () => {
    setLoading(true);

    const res = await APIClient.post(
      `/order_fulfillment/campaign/${campaign_id}/mark_sts_fulfilled`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) notify(errorMessage, "warning");
    else notify("STS orders have been successfully marked fulfilled!");

    toggleDialog();
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Manually fulfill STS orders</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.warning}>
          Warning! This action will mark all STS orders fully fulfilled and can
          not be undone!
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={toggleDialog} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={loading}
          onClick={markFulfilled}
        >
          continue
          {loading && (
            <CircularProgress
              color="primary"
              className={classes.spinner}
              size={24}
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = makeStyles(_theme => ({
  button: { marginLeft: 6, marginBottom: 6 },
  spinner: { position: "absolute" },
  warning: { color: "red" },
}));
