import { makeStyles } from "@material-ui/core";
import { TextField } from "final-form-material-ui";
import { DonationDetailModes, GenericObject } from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import { NullableField } from "../../../../components";

type Props = {
  donation: GenericObject;
  mode: DonationDetailModes;
};

export function MessageCard({ donation, mode }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const { comments } = donation;

  if (!comments && mode === DonationDetailModes.view) return <></>;
  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Message</div>
      {mode === DonationDetailModes.view && (
        <div className={classes.text}>{comments}</div>
      )}
      {mode === DonationDetailModes.edit && (
        <NullableField
          component={TextField}
          name="comments"
          className={classes.input}
          InputProps={{
            multiline: true,
            rows: 3,
          }}
        />
      )}
    </div>
  );
}

const styles = makeStyles(_theme => ({
  text: {
    lineHeight: "24px",
    marginBottom: 16,
  },
  input: {
    width: "100%",
    marginTop: 8,
    marginBottom: 16,
  },
}));
