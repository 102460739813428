import React, { useState, useEffect, useRef, Fragment } from "react";
import { useNotify, Notification } from "react-admin";
import {
  Dialog,
  makeStyles,
  Button,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TableContainer,
  TableHead,
  TableFooter,
  TextField as MuiTextField,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { APIClient, formatMoney, generateAuthHeader } from "../lib";

export function VolunteerPrizesDialog({
  campaign_id,
  volunteer_id,
  prizeListCB,
  brochureCB,
  fromBrochure,
}) {
  const notify = useNotify();
  const classes = styles();
  const openBrochure = useRef(false);
  const [campaignPrizes, setCampaignPrizes] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [data, setData] = useState({});

  useEffect(() => {
    if (campaign_id && volunteer_id) {
      fetchCampaignPrizes();
      fetchVolunteerPrizeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id, volunteer_id]);

  const fetchCampaignPrizes = async () => {
    const res = await APIClient.get(
      `/campaign_prize_programs/product_autocomplete?campaign_id=${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setCampaignPrizes(data);
  };

  const fetchVolunteerPrizeData = async () => {
    const res = await APIClient.get(
      `/volunteer_campaign_prizes/prize_data?campaign_id=${campaign_id}&volunteer_id=${volunteer_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setData(data);
    setInitialValues({ prizes: data.prizes });
  };

  const onSubmit = async ({ prizes = [] }, { getState }) => {
    if (activeProducts.length) {
      return notify("You have selected Prizes which weren't added!", "warning");
    }
    const { dirty } = getState();
    if (!dirty) {
      return notify("There are no changes to save!", "warning");
    }

    const formattedPrizes = prizes.map(({ qty, product_id }) => {
      return { product_id, qty: Number(qty) };
    });

    const data = {
      campaign_id,
      volunteer_id,
      prizes: formattedPrizes,
    };

    const res = await APIClient.put(
      "/volunteer_campaign_prizes/update_prizes",
      data,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("info has been successfully updated");
    if (fromBrochure) brochureCB(openBrochure.current);
    else prizeListCB(true);
  };

  const addProducts = fields => {
    if (!fields || !Array.isArray(fields.value)) {
      return notify("Please refresh your page and try again", "warning");
    }
    let hasDuplicates = false;
    const formatted = activeProducts.map(p => {
      const product_id = p.product_id;
      const exists = fields.value.some(e => e.product_id === product_id);
      if (exists) hasDuplicates = true;
      return { ...p, qty: 1, fulfilled_qty: 0 };
    });

    if (hasDuplicates) {
      return notify("This prize is already added, adjust the qty", "warning");
    }
    formatted.forEach(e => fields.push(e));
    setActiveProducts([]);
  };

  const onClose = () => {
    if (fromBrochure) brochureCB();
    else prizeListCB(false);
  };

  return (
    <Dialog open={true} maxWidth={false}>
      <div className={classes.container}>
        <IconButton className={classes.closeIcon} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div className={classes.header}>PRIZES</div>
        <TableContainer component={Paper} className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Number Of Sales</TableCell>
                <TableCell>Amount Raised</TableCell>
                <TableCell>Item Sold</TableCell>
                <TableCell>Contacts</TableCell>
                <TableCell>Contacts With Sent Emails</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.id && (
                <TableRow>
                  <TableCell>{data.id}</TableCell>
                  <TableCell>{data.first_name}</TableCell>
                  <TableCell>{data.last_name}</TableCell>
                  <TableCell>{data.NUMBER_OF_SALES}</TableCell>
                  <TableCell>
                    {formatMoney(data.DOLLAR_AMOUNT_RAISED)}
                  </TableCell>
                  <TableCell>{data.NUMBER_OF_ITEMS_SOLD}</TableCell>
                  <TableCell>{data.NUMBER_OF_CONTACTS}</TableCell>
                  <TableCell>
                    {data.NUMBER_OF_CONTACTS_WITH_SENT_EMAILS}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ submitting, form }) => {
            return (
              <form onSubmit={e => e.preventDefault()}>
                <div className={classes.subHeader}>Assigned Prizes</div>
                <TableContainer component={Paper} className={classes.table}>
                  <FieldArray name="prizes">
                    {({ fields }) => (
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Thumbnail</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Fulfilled Qty</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fields.map((row, index) => {
                            const values = fields.value[index];
                            const {
                              product_name,
                              sku,
                              thumbnail_image,
                              fulfilled_qty,
                            } = values;
                            return (
                              <TableRow key={index}>
                                <TableCell>{sku}</TableCell>
                                <TableCell>{product_name}</TableCell>
                                <TableCell>
                                  <img
                                    className={classes.thumbnail}
                                    alt="thumbnail"
                                    src={thumbnail_image}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Field
                                    variant="standard"
                                    component={TextField}
                                    name={`${row}.qty`}
                                  />
                                </TableCell>
                                <TableCell>{fulfilled_qty}</TableCell>

                                <TableCell>
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                    size="small"
                                    disabled={fulfilled_qty > 0}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={5} align="right">
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                value={activeProducts}
                                onChange={(_, newValue) => {
                                  setActiveProducts(newValue);
                                }}
                                getOptionLabel={({ product_name, sku }) =>
                                  `${product_name} | ${sku}`
                                }
                                options={campaignPrizes}
                                renderInput={params => (
                                  <MuiTextField
                                    {...params}
                                    label=""
                                    autoFocus
                                    placeholder="Enter or select prizes to add"
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => addProducts(fields)}
                                startIcon={<AddIcon />}
                                disabled={!activeProducts.length}
                              >
                                add
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    )}
                  </FieldArray>
                </TableContainer>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  disabled={submitting}
                  className={classes.button}
                  onClick={() => form.submit()}
                >
                  save
                </Button>
                {fromBrochure && (
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    disabled={submitting}
                    className={classes.addBrochure}
                    onClick={() => {
                      openBrochure.current = true;
                      form.submit();
                    }}
                  >
                    {`save & add Order Form`}
                  </Button>
                )}
              </form>
            );
          }}
        />

        {data.allow_student_picks && (
          <Fragment>
            <div className={classes.subHeader}>Picked Prizes</div>
            <TableContainer component={Paper} className={classes.table}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Tier</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Thumbnail</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Array.isArray(data.prizePicks) &&
                    data.prizePicks.map((pick, id) => {
                      const {
                        product_id,
                        product_name,
                        sku,
                        thumbnail_image,
                        tier_name,
                      } = pick;
                      return (
                        <TableRow key={id}>
                          <TableCell>{tier_name}</TableCell>
                          <TableCell>{product_id}</TableCell>

                          <TableCell>{sku}</TableCell>
                          <TableCell>{product_name}</TableCell>
                          <TableCell>
                            <img
                              className={classes.thumbnail}
                              alt="thumbnail"
                              src={thumbnail_image}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Fragment>
        )}
      </div>
      <Notification />
    </Dialog>
  );
}

const styles = makeStyles(
  _theme => ({
    container: { padding: 24, paddingTop: 0 },
    closeIcon: {
      position: "absolute",
      right: 0,
    },
    header: {
      fontSize: 24,
      fontWeight: 600,
      textAlign: "center",
      margin: "24px 0",
    },
    table: {
      marginBottom: 24,
    },
    thumbnail: {
      maxHeight: 40,
    },
    addBrochure: { marginLeft: 18 },
    subHeader: {
      margin: "24px 0 16px 0",
      textAlign: "center",
      fontWeight: 600,
      fontSize: 18,
    },
  }),
  { classNamePrefix: "prizes" },
);
