import React, { useState, Fragment } from "react";
import { useNotify, Notification } from "react-admin";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { APIUtilClient, generateAuthHeader } from "../../../lib";

export function SendSTHOrdersToShipstation({ campaign_id, callback }) {
  const classes = styles();
  const notify = useNotify();
  const [downloading, setDownloading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => {
    callback();
    setOpen(true);
  };

  const handleSubmit = async () => {
    setDownloading(true);
    const res = await APIUtilClient.post(
      "/shipstation_orders/send_campaign_sth_to_shipstation",
      { campaign_id },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    handleClose();
    if (error) notify(errorMessage, "warning");
    else {
      const count = data.orderCount;
      if (!count) notify("There are no orders to send at this time", "warning");
      else
        notify(
          `${count} Orders have been sent to ShipStation & marked fulfilled`,
        );
    }

    setDownloading(false);
  };

  return (
    <Fragment>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <HomeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Send STH Orders To ShipStation" />
      </MenuItem>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send Ship To Home Orders To ShipStation</DialogTitle>

        <DialogContent>
          <DialogContentText className={classes.confirmTxt}>
            Confirm Action:
          </DialogContentText>
          <DialogContentText>
            Sending orders to ShipStation will send all unfulfilled STH orders
            to ShipStation and mark them as 'fulfilled' in Instaraise.
          </DialogContentText>
          <DialogContentText className={classes.warning}>
            This action is NOT intended for use when fulfilling orders in
            Instaraise!
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" disabled={downloading} onClick={handleSubmit}>
            {!downloading ? `confirm` : `submitting...`}
            {downloading && (
              <CircularProgress
                color="primary"
                className={classes.spinner}
                size={24}
              />
            )}
          </Button>
        </DialogActions>
        <Notification />
      </Dialog>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  spinner: { position: "absolute" },
  confirmTxt: { fontWeight: 600, fontSize: 18, color: "unset" },
  warning: { color: "red" },
}));
