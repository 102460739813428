import { useEffect, useState } from "react";
import { IconButton, makeStyles, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import {
  ButtonSpinner,
  MultiFileDropzoneField,
  ResponsiveModal,
} from "../../../../components";
import {
  APIAuthClient,
  convertFileToB64,
  requiredField,
} from "../../../../lib";
import { APIRes, ListProps, ToastTypes } from "../../../../types";
import { setToast } from "../../../../state";
import {
  useDrawerTransition,
  useIsDesktop,
  useIsMobile,
} from "../../../../hooks/ui";
import { useAppDispatch } from "../../../../types/state";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};

export function EmailVolunteers({ onClose: _onClose, listProps }: Props) {
  const {
    hasSelectedRows,
    selectedRowCount,
    selectedRowIds,
    selectedRows,
    removeSelectedRow,
    unselectAllRows,
  } = listProps;
  const classes = styles();
  const { isOpen, onClose: onCloseFunc } = useDrawerTransition(_onClose);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const [showAll, setShowAll] = useState(false);
  const onClose = () => {
    unselectAllRows();
    onCloseFunc();
  };

  useEffect(() => {
    if (isDesktop) setShowAll(true);
  }, [isDesktop]);

  useEffect(() => {
    if (!hasSelectedRows) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelectedRows]);

  const onSubmit = async (values: any) => {
    const { attachment, ...rest } = values;
    const post = { ids: selectedRowIds, ...rest };
    if (Array.isArray(attachment) && attachment.length) {
      const converted = await convertFileToB64(attachment[0]);
      if (converted) post.attachmentBase64 = converted;
      else return;
    }

    const res = await APIAuthClient.post<any, APIRes>(
      "/volunteers/send-emails",
      post,
    );

    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("We're sending your emails", ToastTypes.success));
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.container}>
              <div>
                <div className={classes.topContent}>
                  <h1 className={classes.title}>Send an email</h1>
                  <IconButton className={classes.close} onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className={classes.mainContent}>
                  <div className={classes.label}>
                    RECIPIENT{selectedRowCount > 1 && "S"}
                  </div>
                  <div className={classes.chipsWrapper}>
                    <div className={classes.chips}>
                      {Object.keys(selectedRows).map((id, index) => {
                        const { first_name, last_name } = selectedRows[id];
                        if (!showAll && index > 0) return <div key={index} />;
                        if (!showAll) {
                          return (
                            <div
                              key={index}
                              className={classes.recipientTextRow}
                            >
                              <div className={classes.recipientText}>
                                {first_name} {last_name}{" "}
                                {selectedRowCount > 1 &&
                                  `& ${selectedRowCount - 1} more`}
                              </div>
                              {selectedRowCount > 1 && (
                                <IconButton
                                  onClick={() => setShowAll(true)}
                                  size="small"
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              )}
                            </div>
                          );
                        }
                        return (
                          <div key={index} className={classes.chip}>
                            <div className={classes.chipTxt}>
                              {first_name} {last_name}
                            </div>
                            <div>
                              <IconButton
                                size="small"
                                className={classes.remove}
                                onClick={() => removeSelectedRow(id)}
                              >
                                <CancelIcon />
                              </IconButton>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {showAll && (
                      <div>
                        <IconButton
                          onClick={() => setShowAll(false)}
                          size="small"
                        >
                          <ExpandLessIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>

                  <div className={classes.label}>SUBJECT</div>
                  <Field
                    name="subject"
                    component={TextField}
                    size="small"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <div className={classes.label}>MESSAGE</div>
                  <Field
                    name="message"
                    component={TextField}
                    size="small"
                    validate={requiredField}
                    className={classes.input}
                    multiline={true}
                    minRows="11"
                  />
                  <div className={classes.label}>Upload an attachment</div>
                  <MultiFileDropzoneField
                    fieldName="attachment"
                    singleFileOnly
                    accept={[
                      "image/*",
                      "application/pdf",
                      "application/vnd.ms-excel",
                      "text/csv",
                      "text/plain",
                    ]}
                  />
                </div>
              </div>

              <div className={classes.actions}>
                {!isMobile && (
                  <Button
                    variant="text"
                    className={classes.cancel}
                    onClick={onClose}
                    disabled={submitting}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  type="submit"
                  className={classes.save}
                  disabled={submitting}
                >
                  Send
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 700,
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "100%",
      justifyContent: "space-between",
    },
  },
  topContent: {
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 16,
    marginBottom: 24,
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  close: {
    marginRight: -4,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  label: {
    fontSize: 12,
    letterSpacing: 1,
    lineHeight: "32px",
    color: theme.palette.text.secondary,
    textTransform: "uppercase",
  },
  mainContent: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  input: {
    width: "100%",
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "24px 0",
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 8px",
    },
  },
  save: {
    width: 120,
    borderRadius: 18,
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
  },
  cancel: {
    width: 74,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 16,
  },
  recipientTextRow: {
    width: "100%",
    marginBottom: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  recipientText: {
    fontSize: 14,
    letterSpacing: 0.1,
  },
  chipsWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  chips: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    height: 32,
    maxWidth: 236,
    width: "fit-content",
    borderRadius: 16,
    backgroundColor: "#E0E0E0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    marginBottom: 8,
    paddingLeft: 12,
    paddingRight: 6,
  },
  chipTxt: {
    overflow: "hidden",
    fontSize: 13,
    letterSpacing: 0.15,
    textOverflow: "ellipsis",
  },
  remove: {
    marginLeft: 2,
  },
  skeleton: {
    marginBottom: 16,
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
  },
}));
