import React, { Fragment, useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { useSelector } from "react-redux";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  InputAdornment,
  Button,
  IconButton,
  FormControlLabel,
  TextField as MuiTextField,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { Form, Field, useForm } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { TextField, Checkbox } from "final-form-material-ui";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  APIClient,
  campaignTypeIds,
  generateAuthHeader,
  repCommissionStatuses,
  required,
  twoDecimalsOnly,
  composeValidators,
  formatMoney,
  calcPercentAmount,
  formatDateOnlyString,
} from "../../../lib";
import { getRoleBooleans } from "../../../customState";
import { PaidCommissionEmailModal } from "./PaidCommissionEmailModal";
import { CommissionWorksheet } from "./CommissionWorksheet";
import { Input } from "./RepCommissionInput";
import { CampaignDonationProductAggregatesCSV } from "../../../components";
import { ProfitAndCommissionAdditionalInfo } from "../components/ProfitAndCommissionAdditionalInfo";
import { styles } from "./RepCommission.styles";

const listenerFields = [
  "donation_commission_percent",
  "categoryAggregates",
  "rep_commission_adjustments",
];

export function RepCommission({ record }) {
  const { id: campaign_id, campaign_type_id, orgName } = record;
  const notify = useNotify();
  const classes = styles();
  const [rcData, setRepCommissionData] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [isProduct, setIsProduct] = useState(false);
  const [commissionPaidTriggered, setCommissionPaidTriggered] = useState(false);
  const [isPendingPayment, setIsPendingPayment] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [totalCommission, setTotalCommission] = useState(0);
  const [finalCommission, setFinalCommission] = useState(0);
  const [rawFinalCommission, setRawFinalCommission] = useState(0);
  const [commissionPaidOn, setCommissionPaidOn] = useState("");
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [paidEmailTemplate, setPaidEmailTemplate] = useState("");
  const { isCompanyOrCompanyAdmin, isRep } = useSelector(state =>
    getRoleBooleans(state),
  );

  useEffect(() => {
    if (campaign_id) fetchRepCommissionData();
    setIsProduct(campaign_type_id === campaignTypeIds.PRODUCT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  useEffect(() => {
    if (!Object.keys(rcData).length) return;
    const {
      donation_commission_percent,
      categoryAggregates,
      rep_commission_adjustments,
      notes,
      status,
    } = rcData;
    setIsPendingPayment(status === repCommissionStatuses.PENDING_PAYMENT);
    setIsPaid(status === repCommissionStatuses.PAID);
    if (isRep || status === repCommissionStatuses.PAID) {
      setDisableEdit(true);
    }
    setInitialValues({
      donation_commission_percent,
      categoryAggregates,
      rep_commission_adjustments,
      notes,
    });
  }, [rcData, isRep]);

  const fetchRepCommissionData = async () => {
    const res = await APIClient.get(
      `/rep_commission/campaigns/${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setRepCommissionData(data);
  };

  const onSubmit = async values => {
    if (isPaid) setDisableEdit(true);
    const {
      categoryAggregates,
      donation_commission_percent,
      rep_commission_adjustments = [],
      ...rest
    } = values;
    const update = {
      donation_commission_percent: donation_commission_percent
        ? donation_commission_percent
        : null,
      rep_commission_adjustments,
      ...rest,
    };
    if (isProduct) {
      if (!Array.isArray(categoryAggregates)) {
        return notify(
          "Something is not right, please refresh your page and try again.",
          "warning",
        );
      }
      const categories = {};
      let missingIds = false;
      categoryAggregates.forEach(c => {
        const {
          profit_category_campaign_id: id,
          isOnline,
          isCustomProductProfit,
          rep_commission_online_percent,
          rep_commission_offline_percent,
          rep_commission_custom_profit_product_online_percent,
          rep_commission_custom_profit_product_offline_percent,
        } = c;
        if (!id) missingIds = true;
        if (!categories[id]) categories[id] = { id };
        switch (true) {
          case Boolean(isOnline && !isCustomProductProfit):
            categories[
              id
            ].rep_commission_online_percent = rep_commission_online_percent;
            break;
          case Boolean(isOnline && isCustomProductProfit):
            categories[
              id
            ].rep_commission_custom_profit_product_online_percent = rep_commission_custom_profit_product_online_percent;
            break;
          case Boolean(!isOnline && !isCustomProductProfit):
            categories[
              id
            ].rep_commission_offline_percent = rep_commission_offline_percent;
            break;
          case Boolean(!isOnline && isCustomProductProfit):
            categories[
              id
            ].rep_commission_custom_profit_product_offline_percent = rep_commission_custom_profit_product_offline_percent;
            break;
          default:
        }
      });
      if (missingIds) {
        return notify(
          "Missing record Ids! Please refresh your page and try again.",
          "warning",
        );
      }
      update.campaignProfitCategories = Object.values(categories);
    }

    const res = await APIClient.put(
      `/rep_commission/campaigns/${campaign_id}`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    fetchRepCommissionData();
    notify("info has been successfully updated");
  };

  const handleCommissionPaid = async () => {
    setCommissionPaidTriggered(true);
    const confirmed = window.confirm(
      "Are you sure you want to mark the commission paid?",
    );
    if (confirmed) {
      const res = await APIClient.put(
        `/rep_commission/campaigns/${campaign_id}/mark-paid`,
        {
          commission_paid_on: commissionPaidOn,
          commission_amount: finalCommission,
        },
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");

      fetchRepCommissionData();
      notify("You have successfully marked the commission paid!");
      setShowEmailModal(true);
      setPaidEmailTemplate(data.emailTemplate);
    } else {
      setCommissionPaidTriggered(false);
    }
  };

  const handleEditAfterPaid = async () => {
    const confirmed = window.confirm(
      "The commission has already been paid, are you sure you want to edit?",
    );
    if (confirmed) setDisableEdit(false);
  };

  const CommissionListener = ({ name }) => {
    const form = useForm();
    const values = form.getState().values;
    return (
      <OnChange name={name}>
        {() => {
          const { donationWholesale } = rcData;
          const {
            categoryAggregates,
            donation_commission_percent,
            rep_commission_adjustments,
          } = values;

          const productCommission = getProductCommission(categoryAggregates);
          const donationCommission = calcPercentAmount(
            donationWholesale,
            donation_commission_percent,
          );
          const customTotal = getCustomAdjustmentsTotal(
            rep_commission_adjustments,
          );

          setTotalCommission(
            formatMoney(productCommission + donationCommission),
          );
          setFinalCommission(
            formatMoney(productCommission + donationCommission + customTotal),
          );
          setRawFinalCommission(
            (productCommission + donationCommission + customTotal).toFixed(2),
          );
        }}
      </OnChange>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.warning}>
        This report does not include post-closeout tagged sales!
      </div>

      <div className={classes.topSection}>
        <div>
          <div className={classes.orgAndRep}>{orgName}</div>
          <div className={classes.orgAndRep}>REP: {rcData.repName}</div>
        </div>

        {isProduct && (
          <CampaignDonationProductAggregatesCSV campaign_id={campaign_id} />
        )}
      </div>

      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {listenerFields.map(name => (
                <CommissionListener key={name} name={name} />
              ))}

              <div className={classes.status}>
                <TableContainer component={Paper} className={classes.table}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell className={classes.capitalize}>
                          {rcData.status
                            ? rcData.status
                            : "awaiting campaign end"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Commission Paid On:</TableCell>
                        <TableCell>
                          {formatDateOnlyString(rcData.commission_paid_on)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>General Notes:</TableCell>
                        <TableCell>
                          <Input
                            name="notes"
                            wideInput
                            disabled={disableEdit}
                            multiline={true}
                            minRows="4"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <CommissionWorksheet
                {...{
                  rcData,
                  disableEdit,
                  formData: values,
                  isProduct,
                  totalCommission,
                }}
              />

              <div className={classes.adjustmentsAndFees}>
                <TableContainer component={Paper} className={classes.table}>
                  <FieldArray name="rep_commission_adjustments">
                    {({ fields }) => (
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={3}>
                              CUSTOM ADJUSTMENTS
                            </TableCell>
                            <TableCell>
                              <Button
                                color="primary"
                                onClick={() => fields.push({ is_debit: false })}
                                startIcon={<AddIcon />}
                                disabled={disableEdit}
                              >
                                add
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {fields.map((row, index) => {
                            const isDebit = fields.value[index].is_debit;
                            return (
                              <TableRow key={index}>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        component={Checkbox}
                                        name={`${row}.is_debit`}
                                        type="checkbox"
                                        disabled={disableEdit}
                                      />
                                    }
                                    label="Is Debit"
                                  />
                                </TableCell>
                                <TableCell>
                                  <Field
                                    variant="standard"
                                    component={TextField}
                                    name={`${row}.memo`}
                                    label="Memo"
                                    validate={required}
                                    disabled={disableEdit}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Field
                                    variant="standard"
                                    component={TextField}
                                    name={`${row}.amount`}
                                    label="Amount"
                                    type="number"
                                    validate={composeValidators(
                                      required,
                                      twoDecimalsOnly,
                                    )}
                                    disabled={disableEdit}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {`$ ${isDebit ? "-" : "+"}`}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => fields.remove(index)}
                                    size="small"
                                    disabled={disableEdit}
                                  >
                                    <RemoveIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </FieldArray>
                </TableContainer>
                <ProfitAndCommissionAdditionalInfo data={rcData} />
              </div>

              {isCompanyOrCompanyAdmin && (
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={submitting || disableEdit}
                >
                  save changes
                </Button>
              )}

              <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Wholesale:</TableCell>
                      <TableCell colSpan={2}>
                        {formatMoney(rcData.totalWholesale)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Commission:</TableCell>
                      <TableCell colSpan={2}>{totalCommission}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Final Commission:</TableCell>
                      <TableCell>{finalCommission}</TableCell>
                      <TableCell className={classes.copyTtl}>
                        <CopyToClipboard text={rawFinalCommission}>
                          <IconButton size="small">
                            <FileCopyOutlinedIcon color="primary" />
                          </IconButton>
                        </CopyToClipboard>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </form>
          );
        }}
      />

      <div className={classes.actionContainer}>
        {isPendingPayment && isCompanyOrCompanyAdmin && (
          <Fragment>
            <MuiTextField
              type="date"
              value={commissionPaidOn}
              onChange={e => setCommissionPaidOn(e.target.value)}
              label="COMMISSION WAS PAID ON"
              InputLabelProps={{ shrink: true }}
              className={classes.paidOn}
            />
            <Button
              variant="contained"
              disabled={!Boolean(commissionPaidOn) || commissionPaidTriggered}
              className={classes.actionButton}
              onClick={handleCommissionPaid}
            >
              confirm
            </Button>
          </Fragment>
        )}

        {isPaid && isCompanyOrCompanyAdmin && disableEdit && (
          <Button
            variant="contained"
            className={classes.actionButton}
            onClick={handleEditAfterPaid}
          >
            edit
          </Button>
        )}
      </div>
      {showEmailModal && (
        <PaidCommissionEmailModal
          setShowEmailModal={setShowEmailModal}
          campaignId={campaign_id}
          template={paidEmailTemplate}
        />
      )}
    </div>
  );
}

function getCustomAdjustmentsTotal(adjustments) {
  if (!Array.isArray(adjustments)) return 0;
  let total = 0;
  adjustments.forEach(a => {
    const { memo, is_debit, amount } = a;
    if (!amount || !memo) return;
    if (is_debit) total = total + Number(-amount);
    else total = total + Number(amount);
  });
  return total;
}

function getProductCommission(categories) {
  let commission = 0;
  if (!Array.isArray(categories)) return commission;
  categories.forEach(c => {
    const {
      isOnline,
      isCustomProductProfit,
      rep_commission_online_percent,
      rep_commission_offline_percent,
      rep_commission_custom_profit_product_online_percent,
      rep_commission_custom_profit_product_offline_percent,
      wholesale,
    } = c;
    let percent = 0;
    switch (true) {
      case Boolean(isOnline && !isCustomProductProfit):
        percent = rep_commission_online_percent;
        break;
      case Boolean(isOnline && isCustomProductProfit):
        percent = rep_commission_custom_profit_product_online_percent;
        break;
      case Boolean(!isOnline && !isCustomProductProfit):
        percent = rep_commission_offline_percent;
        break;
      case Boolean(!isOnline && isCustomProductProfit):
        percent = rep_commission_custom_profit_product_offline_percent;
        break;
      default:
    }

    commission = commission + calcPercentAmount(wholesale, percent);
  });
  return commission;
}
