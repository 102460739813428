import { SearchFields } from "./list";

export enum DiscountCardOfferRedemptionMethods {
  Manual = "Manual",
  Code = "Code",
  Barcode = "Barcode",
  QrCode = "Qr code",
}
export const discountCardOfferRedemptionMethods = Object.values(
  DiscountCardOfferRedemptionMethods,
);

export const discountCardOfferSearchFields: SearchFields = [
  { label: "Name", field: "name", placeholder: "Name" },
  { label: "Merchant", field: "merchant_name", placeholder: "Merchant" },
  {
    label: "Offer ID",
    field: "discount_card_offer_id",
    placeholder: "Offer ID",
    isNumber: true,
  },
];

export const discountCardOfferUsesOptions = [
  "Unlimited",
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
];
