import { Fragment, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { EditEmployee } from "./EditEmployee";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps<any>;
};
export function EmployeeRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Id"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="First name"
                fieldName="first_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Last name"
                fieldName="last_name"
                params={params}
                setParams={setParams}
              />
              <div>Email</div>
              <SortableColumnHeader
                columnName="Phone"
                fieldName="phone"
                params={params}
                setParams={setParams}
              />
              <div>Campaigns</div>
              <div>Notes</div>
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Employees per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);
  const {
    id,
    first_name,
    last_name,
    phone,
    notes,
    email,
    org_employee_campaigns: campaigns,
  } = row;

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>{first_name}</div>
            <div>{last_name}</div>
            <div>{email}</div>
            <div>{phone}</div>
            <div>
              <CampaignChips campaigns={campaigns as any[]} />
            </div>
            <div>{notes}</div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div>
              <div>{`#${id}`}</div>
              <div className={classes.name}>
                {first_name} {last_name}
              </div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <EditEmployee
          onClose={() => setShowEdit(false)}
          refreshList={refreshList}
          record={row}
        />
      )}
    </ListRowContainer>
  );
}

function CampaignChips({ campaigns }: { campaigns: any[] }) {
  const classes = styles();
  return (
    <div className={classes.chips}>
      {campaigns.map(({ campaign_id, name }) => (
        <div key={campaign_id} className={classes.chipWrapper}>
          <div className={classes.chip}>
            {name} #{campaign_id}
          </div>
        </div>
      ))}
    </div>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "72px .6fr .7fr 1fr .6fr 1.5fr 1fr 52px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chipWrapper: {
    height: 32,
    borderRadius: 16,
    backgroundColor: "#E0E0E0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 12px",
    overflow: "hidden",
    marginRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
  chip: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    fontSize: 13,
    letterSpacing: 0.15,
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingTop: 8,
    },
  },
}));
