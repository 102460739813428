import React, { useState, useEffect } from "react";
import { Button, Dialog, makeStyles } from "@material-ui/core";
import { useNotify } from "react-admin";
import { useForm } from "react-final-form";

export function WarningModal({ showWarning, setShowWarning }) {
  const classes = styles();
  const form = useForm();
  const notify = useNotify();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (showWarning) {
      const { hasValidationErrors } = form.getState();
      if (hasValidationErrors) {
        form.submit(); // to trigger helper-text warnings
        setShowWarning(false);
        notify("Please fill in all required fields", "warning");
      } else {
        setOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWarning]);

  const close = () => {
    setOpen(false);
    setShowWarning(false);
  };

  const save = () => {
    form.submit();
    close();
  };

  return (
    <Dialog open={open}>
      <div className={classes.warningContainer}>
        <div className={classes.warningHeader}>Warning!</div>
        <div className={classes.text}>
          Adding or removing options will deprecate all current variants and you
          will need to create new variant products
        </div>
        <div className={classes.text}>
          If you remove an option-item it will deprecate all variant products
          that include that option-item
        </div>

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            size="small"
            onClick={close}
            className={classes.cancelButton}
          >
            cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={save}
          >
            save changes
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(_theme => ({
  warningContainer: {
    width: 800,
    maxWidth: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  warningHeader: {
    fontSize: 36,
    fontWeight: 600,
    paddingBottom: 18,
    textTransform: "uppercase",
    color: "#FF0000",
    textAlign: "center",
  },
  text: {
    color: "#FF0000",
    textAlign: "center",
    paddingBottom: 18,
    fontSize: 18,
  },
  buttonContainer: {
    marginTop: 24,
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  cancelButton: {
    marginRight: 16,
  },
}));
