import React, { useState } from "react";
import {
  Datagrid,
  Create,
  SimpleForm,
  TextInput,
  TextField,
  useListController,
  ListView,
  Pagination,
  TopToolbar,
  required,
  useNotify,
  Edit,
  Toolbar,
  SaveButton,
  Notification,
} from "react-admin";
import { Button, Drawer, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { styles } from "./Product.styles";
const requiredInput = [required()];
const profitCategoryAsideModes = { CREATE: "add", EDIT: "edit", CLOSE: null };

export function ProfitCategories(props) {
  const { location, id: supplier_id } = props;
  const classes = styles();
  const notify = useNotify();
  const basePath = `/product_suppliers/${supplier_id}/show/profit_categories`;
  const [profitCategoryAsideMode, setProfitCategoryAsideMode] = useState(null);
  const [profitCategoryRecordData, setProfitCategoryRecordData] = useState({});

  const controllerProps = useListController({
    sort: { field: "profit_category_name", order: "ASC" },
    location,
    basePath,
    resource: "profit_categories",
    filter: { supplier_id },
    perPage: 100,
  });

  const onSuccess = msg => {
    controllerProps.refetch();
    setProfitCategoryAsideMode(profitCategoryAsideModes.CLOSE);
    notify(msg);
  };

  const ProfitCategoryEditToolbar = props => (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );

  const ProfitCategoryActions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() =>
          setProfitCategoryAsideMode(profitCategoryAsideModes.CREATE)
        }
      >
        add profit category
      </Button>
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<ProfitCategoryActions />}
        sort={{ field: "profit_category_name", order: "ASC" }}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
      >
        <Datagrid
          rowClick={(id, _basePath, record) => {
            setProfitCategoryRecordData({
              id,
              basePath: "",
              record,
              resource: "profit_categories",
            });
            setProfitCategoryAsideMode(profitCategoryAsideModes.EDIT);
          }}
        >
          <TextField source="id" />
          <TextField source="profit_category_name" label="Category Name" />
        </Datagrid>
      </ListView>

      <Drawer open={Boolean(profitCategoryAsideMode)} anchor="right">
        <div className={classes.drawerContent}>
          <div className={classes.drawerHeader}>
            <div>
              {profitCategoryAsideMode === profitCategoryAsideModes.CREATE
                ? "Add a Profit Category"
                : "Edit Profit Category"}
            </div>
            <IconButton
              onClick={() =>
                setProfitCategoryAsideMode(profitCategoryAsideModes.CLOSE)
              }
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {profitCategoryAsideMode === profitCategoryAsideModes.CREATE && (
            <Create
              basePath=""
              resource="profit_categories"
              title=" "
              onSuccess={() => onSuccess("Profit Category added successfully")}
            >
              <SimpleForm initialValues={{ supplier_id: Number(supplier_id) }}>
                <TextInput
                  source="profit_category_name"
                  label="Category Name"
                  validate={requiredInput}
                />
              </SimpleForm>
            </Create>
          )}

          {profitCategoryAsideMode === profitCategoryAsideModes.EDIT && (
            <div>
              <Edit
                title=" "
                {...profitCategoryRecordData}
                undoable={false}
                onSuccess={() =>
                  onSuccess("Profit Category updated successfully")
                }
              >
                <SimpleForm toolbar={<ProfitCategoryEditToolbar />}>
                  <TextInput
                    source="profit_category_name"
                    label="Category Name"
                    validate={requiredInput}
                  />
                </SimpleForm>
              </Edit>
            </div>
          )}
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}
