import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  Button,
  IconButton,
  makeStyles,
  Dialog,
  TableHead,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useNotify, Notification, useRefresh } from "react-admin";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Option } from "./Option";
import { MissingVariants } from "./MissingVariants";
import { CurrentVariants } from "./CurrentVariants";
import { WarningModal } from "./WarningModal";
import { APIClient, generateAuthHeader } from "../../../lib";

export function VariantOptionsModal({ id, toggleModal }) {
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [missingVariants, setMissingVariants] = useState([]);
  const [currentVariants, setCurrentVariants] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    if (id) {
      fetchOptions();
      fetchVariantData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onClose = () => {
    toggleModal(false);
    refresh();
  };

  const fetchOptions = async () => {
    const res = await APIClient.get(
      `/configurable_product_options/product/${id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setInitialValues(data);
  };

  const fetchVariantData = async () => {
    const res = await APIClient.get(
      `/configurable_product_options/product/${id}/variants_data`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    const { missingVariants, currentVariants } = data;
    setMissingVariants(missingVariants);
    setCurrentVariants(currentVariants);
  };

  const resetVariantData = async () => {
    setMissingVariants([]);
    setCurrentVariants([]);
    fetchVariantData();
  };

  const onSubmit = async ({ configurable_product_options = [] }, form) => {
    const { dirty } = form.getState();
    if (!dirty) {
      return notify("There are no changes to save!", "warning");
    }
    const formatted = configurable_product_options.map(o => {
      const {
        id,
        display_type,
        option_label,
        sort_order,
        configurable_product_option_items: OI,
      } = o;
      const configurable_product_option_items = (OI || []).map(item => {
        const { id, text, value, sort_order } = item;
        return {
          id: id ? id : null,
          text,
          value,
          sort_order: Number(sort_order),
        };
      });
      return {
        id: id ? id : null,
        display_type,
        option_label,
        sort_order: Number(sort_order),
        configurable_product_option_items,
      };
    });
    const res = await APIClient.put(
      `/configurable_product_options/product/${id}`,
      { configurable_product_options: formatted },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Variant Options have been successfully updated");
    setInitialValues({});
    setTimeout(() => form.reset(), 10);
    fetchOptions();
    resetVariantData();
  };

  return (
    <Dialog open={true} maxWidth={false} onClose={onClose}>
      <div className={classes.container}>
        <IconButton
          className={classes.closeIcon}
          onClick={onClose}
          edge="start"
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.subHeader}>VARIANT OPTIONS</div>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <TableContainer
                  component={Paper}
                  className={classes.table}
                  elevation={6}
                >
                  <FieldArray name="configurable_product_options">
                    {({ fields }) => (
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.leftCol}>
                              <Button
                                color="primary"
                                onClick={() =>
                                  fields.push({
                                    configurable_product_option_items: [],
                                  })
                                }
                                startIcon={<AddIcon />}
                              >
                                add option
                              </Button>
                            </TableCell>
                            <TableCell />
                            <TableCell>Option Label</TableCell>
                            <TableCell>Display Type</TableCell>
                            <TableCell>Option Sort Number</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fields.map((row, index) => (
                            <Option key={index} {...{ fields, row, index }} />
                          ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <Button
                                type="button"
                                color="primary"
                                size="small"
                                variant="contained"
                                disabled={submitting}
                                onClick={() => setShowWarning(true)}
                              >
                                save changes
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    )}
                  </FieldArray>
                </TableContainer>
                <WarningModal
                  showWarning={showWarning}
                  setShowWarning={setShowWarning}
                />
              </form>
            );
          }}
        />

        <div className={classes.subHeader}>MISSING VARIANTS</div>
        <MissingVariants
          missingVariants={missingVariants}
          id={id}
          resetVariantData={resetVariantData}
        />

        <div className={classes.subHeader}>CURRENT VARIANTS</div>
        <CurrentVariants currentVariants={currentVariants} />
      </div>
      <Notification />
    </Dialog>
  );
}

const styles = makeStyles(_theme => ({
  container: { width: 1200, maxWidth: "100%", padding: 24 },
  closeIcon: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  subHeader: {
    fontSize: 20,
    textAlign: "center",
    marginBottom: 4,
    fontWeight: 600,
  },
  table: { marginBottom: 36, overflowX: "visible" },
  leftCol: { width: 165 },
}));
