import { Button, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getCampaignInfoFetched, getIsProductCampaign } from "../../../state";
import { paths } from "../../../types";
import { useHistory } from "react-router-dom";
import { DonationStats } from "./DonationStats";
import classNames from "classnames";
import { OrgProgress } from "./OrgProgress";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { Fragment, useState } from "react";
import { DailyPerformance } from "./DailyPerformance";

export function OrgCampaignOverview() {
  const isProduct = useSelector(getIsProductCampaign);
  const history = useHistory();
  const campaignInfoFetched = useSelector(getCampaignInfoFetched);
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showPerformance, setShowPerformance] = useState(false);

  const classes = styles();

  return (
    <Fragment>
      <div className={classes.container}>
        {isDesktop && (
          <div className={classes.top}>
            <div className={classes.title}>Campaign overview</div>
            <div>
              <Button
                variant="text"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => history.push(paths.DONATIONS)}
              >
                VIEW
                {!campaignInfoFetched
                  ? ""
                  : isProduct
                  ? " SALES"
                  : " DONATIONS"}
              </Button>
              <Button
                variant="text"
                color="primary"
                size="small"
                className={classNames(classes.button, classes.performanceBtn)}
                onClick={() => setShowPerformance(true)}
              >
                VIEW DAILY PERFORMANCE
              </Button>
            </div>
          </div>
        )}

        <div className={classes.content}>
          {isMobile && (
            <div className={classes.top}>
              <div className={classes.title}>Campaign overview</div>
              <div>
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  className={classNames(classes.button, classes.performanceBtn)}
                  onClick={() => history.push(paths.DONATIONS)}
                >
                  VIEW
                  {!campaignInfoFetched
                    ? ""
                    : isProduct
                    ? " SALES"
                    : " DONATIONS"}
                </Button>
              </div>
            </div>
          )}
          <OrgProgress />
          <DonationStats />
        </div>
      </div>

      {isMobile && (
        <div className={classes.mobileButtons}>
          <Button
            color="primary"
            size="large"
            className={classes.mobileButton}
            onClick={() => history.push(paths.LEADERBOARD)}
          >
            View Leaderboard
          </Button>
          <Button
            color="secondary"
            size="large"
            className={classes.mobileButton}
            onClick={() => setShowPerformance(true)}
          >
            View daily performance
          </Button>
        </div>
      )}

      {showPerformance && (
        <DailyPerformance onClose={() => setShowPerformance(false)} />
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 32,
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 26,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
  },
  button: {
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
  },
  performanceBtn: {
    marginLeft: 16,
  },
  content: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  mobileButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 16,
  },
  mobileButton: {
    width: 300,
    maxWidth: "100%",
    marginBottom: 16,
    borderRadius: 21,
  },
}));
