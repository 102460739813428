import React, { Fragment, useState } from "react";
import { useNotify, Notification } from "react-admin";
import { Button, makeStyles } from "@material-ui/core";
import { APIUtilClient, generateAuthHeader } from "../../lib";

export function CheckForDonationShippingUpdates({ donation_id, callback }) {
  const [submitting, setSubmitting] = useState(false);
  const classes = styles();
  const notify = useNotify();

  const checkForUpdates = async () => {
    setSubmitting(true);
    const res = await APIUtilClient.get(
      `/shipstation_orders/check_for_donation_shipping_updates?donation_id=${donation_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, { type: "warning" });
    notify("Shipping info has been updated.", { type: "info" });
    if (callback) callback();
  };

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={checkForUpdates}
        disabled={submitting}
        className={classes.button}
        size="small"
      >
        Check for Shipping Updates
      </Button>
      <Notification />
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  button: { width: 140 },
}));
