import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import { connect } from "react-redux";
import { updateProfileAddress as updateProfileAddressAction } from "../../customState";
import { TextInput } from "../../components";
import {
  APIClient,
  generateAuthHeader,
  isOrg,
  isVolunteer,
  required,
} from "../../lib";
import { useNotify } from "react-admin";
import { styles } from "./Profile.styles";

function _UpdateAddress(props) {
  const {
    role,
    profile,
    profile: { address_id },
    updateProfileAddress,
  } = props;

  const classes = styles();
  const notify = useNotify();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    const { address, city, state, zip, country } = profile;
    setInitialValues({ address, city, state, zip, country });
  }, [profile]);

  const onSubmit = async (values, form) => {
    const submitObj = formatForSubmit(values);
    let res;
    // if there is no address_id then we need to create an address (which is only possible for a volunteer because it is required for an org there will always be an existing address)
    if (!address_id && isVolunteer(role)) {
      res = await APIClient.post(`/addresses`, submitObj, {
        headers: generateAuthHeader(),
      });
    } else {
      res = await APIClient.put(`/addresses/${address_id}`, submitObj, {
        headers: generateAuthHeader(),
      });
    }
    const { error, errorMessage, data } = res;
    if (error) {
      return notify(errorMessage, "warning");
    }
    notify("your address has been successfully updated", { type: "info" });
    updateProfileAddress(data);
    setTimeout(form.reset, 100);
  };

  return (
    <div className={classes.container}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <TextInput
                name="address"
                label="Address"
                validation={isOrg(role) ? required : undefined}
              />
              <TextInput
                name="city"
                label="City"
                validation={isOrg(role) ? required : undefined}
              />
              <TextInput
                name="state"
                label="state"
                validation={isOrg(role) ? required : undefined}
              />
              <TextInput
                name="zip"
                label="zip"
                validation={isOrg(role) ? required : undefined}
              />
              <TextInput name="country" label="Country" />

              <Button
                type="submit"
                disabled={submitting}
                className={classes.button}
              >
                <SaveIcon className={classes.saveIcon} />
                update
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}

export const UpdateAddress = connect(
  state => {
    const {
      user: { role },
      profile,
    } = state;
    return { role, profile };
  },
  { updateProfileAddress: updateProfileAddressAction },
)(_UpdateAddress);

function formatForSubmit(values) {
  const { address, city, state, zip, country } = values;
  return {
    address: address ? address : null,
    city: city ? city : null,
    state: state ? state : null,
    zip: zip ? zip : null,
    country: country ? country : null,
  };
}
