import {
  IconButton,
  makeStyles,
  TextField,
  MenuItem,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { ResponsiveModal } from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useRef, useState, lazy, Suspense, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, setToast } from "../../../state";
import { APIAuthClient } from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { IDetectedBarcode } from "@yudiel/react-qr-scanner";
import { RootState } from "../../../types/state";
import classNames from "classnames";
const Scanner = lazy(() => import("../../../components/general/CameraScanner"));
const SUCCESS_SOUND_URL =
  "https://jm-assets.nyc3.digitaloceanspaces.com/images/app/scan-success.wav";
const ERROR_SOUND_URL =
  "https://jm-assets.nyc3.digitaloceanspaces.com/images/app/scan-error.wav";

enum Modes {
  input = "input",
  camera = "camera",
}

type Props = {
  onClose: () => void;
  refreshList: () => void;
};
export function ScanTickets({ onClose: onCloseFunc, refreshList }: Props) {
  const { onClose: _onClose, isOpen } = useDrawerTransition(onCloseFunc);
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const campaignEventAutocomplete = useSelector(
    ({ events }: RootState) => events.campaignEventAutocomplete,
  );
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [submitting, setSubmitting] = useState(false);
  const [eventId, setEventId] = useState<string>("");
  const [ticketCode, setTicketCode] = useState<string>("");
  const [mode, setMode] = useState<Modes>(Modes.input);
  const codeRef = useRef<HTMLInputElement>(null);
  const successSound = useMemo(() => new Audio(SUCCESS_SOUND_URL), []);
  const errorSound = useMemo(() => new Audio(ERROR_SOUND_URL), []);

  useEffect(() => {
    if (isMobile) setMode(Modes.camera);
  }, [isMobile]);

  useEffect(() => {
    if (eventId) focusInput();
  }, [eventId]);

  const onClose = () => {
    refreshList();
    _onClose();
  };

  const submit = async (scannedCode?: string) => {
    setSubmitting(true);
    const url = "/event_tickets/mark_used";
    const post = {
      event_group_event_id: Number(eventId),
      campaign_id: Number(campaign_id),
      ticket_code: scannedCode ? scannedCode : ticketCode,
    };
    const response = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage, status } = response;
    if (error) {
      playAudio(errorSound);
      let msg = errorMessage;
      if (status === 404) msg = "Invalid ticket";
      dispatch(setToast(msg));
    } else {
      playAudio(successSound);
      dispatch(setToast("Ticket processed", ToastTypes.success));
    }

    setTicketCode("");
    focusInput();
    setSubmitting(false);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && ticketCode) submit();
  };

  const focusInput = () => {
    setTimeout(() => {
      if (codeRef.current) codeRef.current.focus();
    }, 50);
    // set focus again in case it didn't pickup the first time
    setTimeout(() => {
      if (codeRef.current) codeRef.current.focus();
    }, 400);
  };

  const onCameraScan = (result: IDetectedBarcode[]) => {
    const { rawValue } = result[0];
    submit(rawValue);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        {submitting && (
          <div className={classes.submittingOverlay}>
            <div className={classes.processing}>
              <div>Processing...</div>
              <CircularProgress color="primary" size={48} />
            </div>
          </div>
        )}
        <div className={classes.top}>
          <div>Scan tickets</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.content}>
          <TextField
            select
            value={eventId}
            onChange={({ target }) => setEventId(target.value)}
            className={classes.eventSelector}
            fullWidth
            label="Select event"
          >
            {campaignEventAutocomplete.map(({ event_id, event_name }) => (
              <MenuItem key={event_id} value={event_id}>
                {event_name}
              </MenuItem>
            ))}
          </TextField>

          {eventId && (
            <div className={classes.toggle}>
              <div
                className={classNames(classes.toggleOption, {
                  [classes.activeOption]: mode === Modes.camera,
                })}
                onClick={() =>
                  mode !== Modes.camera ? setMode(Modes.camera) : null
                }
              >
                Use device camera
              </div>
              <div
                className={classNames(classes.toggleOption, {
                  [classes.activeOption]: mode === Modes.input,
                })}
                onClick={() =>
                  mode !== Modes.input ? setMode(Modes.input) : null
                }
              >
                Scan/enter manually
              </div>
            </div>
          )}

          <Suspense fallback={<div />}>
            <Scanner
              onScan={onCameraScan}
              allowMultiple={true}
              paused={submitting}
              classNames={{
                // we want to load camera right away just hide until event is selected
                container:
                  eventId && mode === Modes.camera
                    ? classes.showCamera
                    : classes.hideCamera,
              }}
            />
          </Suspense>

          {eventId && mode === Modes.input && (
            <div className={classes.inputWrapper}>
              <TextField
                value={ticketCode}
                autoFocus
                onChange={({ target }) => setTicketCode(target.value)}
                fullWidth
                placeholder="Enter ticket code or use scanner"
                onKeyDown={handleKeyPress}
                inputRef={codeRef}
              />
              <Button
                color="primary"
                className={classes.submit}
                disabled={!ticketCode}
                onClick={() => submit()}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </ResponsiveModal>
  );
}

function playAudio(player: HTMLAudioElement) {
  player.currentTime = 0;
  player.play();
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    position: "relative",
    minHeight: 350,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  submittingOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    backgroundColor: "rgba(255, 255, 255, .8)",
    zIndex: 999909,
  },
  processing: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    fontSize: 18,
    lineHeight: "48px",
  },
  top: {
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #DBDEEE",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  content: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px",
    },
  },
  eventSelector: {
    marginBottom: 24,
  },
  toggle: {
    width: 380,
    maxWidth: "100%",
    height: 36,
    borderRadius: 18,
    backgroundColor: "#E5E6EB",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
  },
  toggleOption: {
    width: "50%",
    height: 36,
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary2,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    cursor: "pointer",
  },
  activeOption: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: "unset",
  },
  showCamera: {
    display: "block",
  },
  hideCamera: {
    display: "none",
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  submit: {
    width: "fit-content",
    minWidth: "fit-content",
    marginLeft: 18,
  },
}));
