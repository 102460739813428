import { APIAuthClient } from "../../lib";
import { APIRes, GenericObject } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const campaignTypes = {
  CAMPAIGN_AUTOCOMPLETE_SET: "CAMPAIGN_AUTOCOMPLETE_SET",
  CAMPAIGN_INFO_SET: "CAMPAIGN_INFO_SET",
  CAMPAIGN_INFO_UPDATE: "CAMPAIGN_INFO_UPDATE",
  CONTACTS_MARK_CONFIRMED: "CONTACTS_MARK_CONFIRMED",
  CAMPAIGNS_SWITCH: "CAMPAIGNS_SWITCH",
  CAMPAIGN_CLEAR: "CAMPAIGN_CLEAR",
};

const campaignActions = {
  getOrgCampaignAutocomplete(): AppThunkAction {
    return async dispatch => {
      const url = "/campaigns/org_campaign_autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      dispatch({ type: campaignTypes.CAMPAIGN_AUTOCOMPLETE_SET, data });
      return;
    };
  },
  getCampaignInfo(
    campaign_id?: number,
  ): AppThunkAction<Promise<GenericObject | null>> {
    return async dispatch => {
      const url = `/campaigns/${campaign_id}/admin_info`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return null;
      }
      dispatch({ type: campaignTypes.CAMPAIGN_INFO_SET, data });
      return data;
    };
  },
  updateCampaignInfo(data: GenericObject) {
    return {
      type: campaignTypes.CAMPAIGN_INFO_UPDATE,
      data,
    };
  },
  switchCampaigns(campaign_id: number) {
    localStorage.setItem("selectedCampaignId", `${campaign_id}`);
    return {
      type: campaignTypes.CAMPAIGNS_SWITCH,
      data: campaign_id,
    };
  },
  clearCampaign() {
    localStorage.removeItem("selectedCampaignId");
    return { type: campaignTypes.CAMPAIGN_CLEAR };
  },
  getCampaignTicketPackages(): AppThunkAction {
    return async (dispatch, getState) => {
      const {
        campaign: { ticketPackagesFetched, campaignId },
      } = getState();
      if (ticketPackagesFetched || !campaignId) return;
      const url = `/campaign_ticket_packages/autocomplete?campaign_id=${campaignId}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      dispatch({
        type: campaignTypes.CAMPAIGN_INFO_UPDATE,
        data: { ticketPackagesFetched: true, ticketPackages: data },
      });
      return;
    };
  },
};

export { campaignTypes, campaignActions };
