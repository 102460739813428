import { parseUserToken, clearStorage } from "../../lib";
const userTypes = {
  USER_INFO: "USER_INFO",
  CLEAR_USER_INFO: "CLEAR_USER_INFO",
  ADD_PREVIEWS_TO_USER: "ADD_PREVIEWS_TO_USER",
  SEASON_ID_CHANGE: "SEASON_ID_CHANGE",
  SEASON_OPTIONS_SET: "SEASON_OPTIONS_SET",
  ASSOCIATED_VOLUNTEERS_SET: "ASSOCIATED_VOLUNTEERS_SET",
};

const storeUserInfo = () => ({
  type: userTypes.USER_INFO,
  payload: parseUserToken(),
});

const clearUserInfo = () => {
  clearStorage();
  return { type: userTypes.CLEAR_USER_INFO };
};

const addPreviewsToUser = previewInfo => ({
  type: userTypes.ADD_PREVIEWS_TO_USER,
  payload: previewInfo,
});

const changeSeasonId = id => ({
  type: userTypes.SEASON_ID_CHANGE,
  payload: id,
});

const setCompanySeasonOptions = seasonOptions => ({
  type: userTypes.SEASON_OPTIONS_SET,
  payload: seasonOptions,
});

const setAssociatedVolunteers = associatedVolunteers => ({
  type: userTypes.ASSOCIATED_VOLUNTEERS_SET,
  payload: associatedVolunteers,
});

export {
  userTypes,
  storeUserInfo,
  clearUserInfo,
  addPreviewsToUser,
  changeSeasonId,
  setCompanySeasonOptions,
  setAssociatedVolunteers,
};
