import { Fragment } from "react";
import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useIsMobile } from "../../hooks/ui";

type Props = {
  show: boolean;
};

export function SkeletonLoader({ show }: Props) {
  const classes = styles();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      {show && (
        <div className={classes.container}>
          <Skeleton
            variant="rect"
            width={"100%"}
            height={isMobile ? 150 : 56}
            className={classes.skeleton}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={isMobile ? 150 : 56}
            className={classes.skeleton}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={isMobile ? 150 : 56}
            className={classes.skeleton}
          />
          <Skeleton
            variant="rect"
            width={"100%"}
            height={isMobile ? 150 : 56}
            className={classes.skeleton}
          />

          {!isMobile && (
            <Fragment>
              <Skeleton
                variant="rect"
                width={"100%"}
                height={56}
                className={classes.skeleton}
              />
              <Skeleton
                variant="rect"
                width={"100%"}
                height={56}
                className={classes.skeleton}
              />
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    width: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  skeleton: {
    marginBottom: 16,
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
  },
}));
