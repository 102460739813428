import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, requiredField } from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { getCampaignId, setToast } from "../../../state";
import {
  ButtonSpinner,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RootState } from "../../../types/state";
import { giveawayTimezoneValueNameMapping } from "../../../types/giveaway";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddReport({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const { date_range_timezone = "" } = useSelector(
    ({ giveaway }: RootState) => giveaway,
  );

  const onSubmit = async (values: any, _: any, complete: any) => {
    const post = { campaign_id, ...values };
    const url = `/campaign_giveaway_reports`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(
      setToast(
        "your report has been generated, you can download it from the list",
        ToastTypes.success,
      ),
    );
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Generate report</h1>
                {isMobile && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className={classes.mainContent}>
                <div className={classes.timezone}>
                  Timezone:{" "}
                  {giveawayTimezoneValueNameMapping[date_range_timezone] ||
                    "Unknown"}
                </div>
                <Field
                  component={TextFieldWrapper}
                  name="start_date"
                  label="Start date (inclusive)"
                  validate={requiredField}
                  className={classes.input}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
                <Field
                  component={TextFieldWrapper}
                  name="end_date"
                  label="End date (inclusive)"
                  validate={requiredField}
                  className={classes.input}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className={classes.bottom}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting}
                  onClick={() => form.submit()}
                >
                  Generate
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: "24px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px 0 16px",
    },
  },
  timezone: {
    marginBottom: 24,
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
