// cSpell:ignore Swipeable
import { ReactNode } from "react";
import { Theme, SwipeableDrawer, Dialog, makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../hooks/ui";
import classNames from "classnames";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  keepMounted?: boolean;
  onClose: () => void;
  dynamicHeight?: boolean;
  fullScreen?: boolean;
  fullHeight?: boolean;
};

export function ResponsiveModal({
  children,
  isOpen,
  onClose,
  keepMounted = false,
  dynamicHeight = false,
  fullScreen = false,
  fullHeight = false,
}: Props) {
  const classes = styles({ dynamicHeight });
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <SwipeableDrawer
        open={isOpen}
        onClose={onClose}
        onOpen={() => {}}
        classes={{ paper: classes.drawer }}
        transitionDuration={500}
        anchor="bottom"
        keepMounted={keepMounted}
      >
        <div className={classes.wrapper}>{children}</div>
      </SwipeableDrawer>
    );
  } else {
    return (
      <Dialog
        open={isOpen}
        maxWidth={false}
        fullScreen={fullScreen}
        keepMounted={keepMounted}
        classes={{
          paper: classNames(classes.paper, fullHeight && classes.fullHeight),
          paperWidthFalse: classes.paperWidthFalse,
        }}
      >
        {children}
      </Dialog>
    );
  }
}

type StyleProps = {
  dynamicHeight?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(_theme => ({
  drawer: {
    top: ({ dynamicHeight }) => (dynamicHeight ? "auto" : 75),
    borderRadius: "20px 20px 0 0",
    maxHeight: "unset",
  },
  wrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "scroll",
  },
  paper: {
    marginLeft: 0,
    marginRight: 0,
  },
  paperWidthFalse: {
    maxWidth: "100%",
  },
  fullHeight: {
    height: "calc(100% - 64px)",
  },
}));
