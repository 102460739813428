import { makeStyles } from "@material-ui/core";

export const templateStyles = makeStyles(_theme => ({
  container: {
    margin: "48px 0",
    fontFamily: "Helvetica",
  },
  template: {
    padding: 16,
    border: "2px solid #D3D3D3",
    marginBottom: 24,
    width: 632,
    maxWidth: "100%",
  },
  logo: {
    textAlign: "center",
    marginBottom: 36,
  },
  header: {
    textAlign: "center",
    marginBottom: 24,
    fontSize: 36,
    fontWeight: 600,
  },
  subheader: {
    fontSize: 16,
    marginBottom: 36,
  },
  sth: {
    margin: "32px 0",
  },
  retain: {
    fontSize: 16,
    margin: "24px 0",
  },
  social: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hideSM: {
    marginLeft: 24,
  },
}));
