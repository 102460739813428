import {
  Datagrid,
  List,
  TextField,
  EmailField,
  Filter,
  TextInput,
  FunctionField,
  DateInput,
} from "react-admin";
import { useSelector } from "react-redux";
import { getUserTimezone } from "../../lib";
import { getRoleBooleans, getSeasonId } from "../../customState";
import { ImpersonateRep } from "../../components";

const RepFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Reps" source="q" alwaysOn />
      <DateInput label="Start Date" source="start_date" alwaysOn />
      <DateInput label="End Date" source="end_date" alwaysOn />
    </Filter>
  );
};

export default function RepList(props) {
  const { staticContext: _rm, ...rest } = props;
  const season_id = useSelector(getSeasonId);
  const timezone = getUserTimezone();
  const { isRep } = useSelector(state => getRoleBooleans(state));
  const hasRepHierarchy = useSelector(state => state.user.hasRepHierarchy);

  return (
    <div>
      <List
        {...rest}
        perPage={25}
        title="Reps"
        bulkActionButtons={false}
        filters={<RepFilter />}
        filter={{ season_id, timezone }}
      >
        <Datagrid rowClick="show">
          <TextField source="id" />
          {isRep && <TextField source="level" />}
          <TextField source="first_name" />
          <TextField source="last_name" />
          <EmailField source="email" sortable={false} />
          <TextField source="phone" />
          <TextField source="association" />
          {hasRepHierarchy && (
            <TextField source="parentRep" label="Parent Rep" />
          )}
          <TextField source="raised" />
          <TextField source="total_items" />
          <FunctionField
            addLabel={false}
            render={record => <ImpersonateRep repId={record.id} />}
          />
        </Datagrid>
      </List>
    </div>
  );
}
