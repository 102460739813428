import React, { useState, useEffect, Fragment } from "react";
import { List, arrayMove } from "react-movable";
import { Button, makeStyles } from "@material-ui/core";
import { Notification, useNotify } from "react-admin";
import { APIClient, generateAuthHeader } from "../lib";

export function DonationGroupItemsOrdering({ urlPrefix, group_id }) {
  const classes = styles();
  const notify = useNotify();
  const [tierItems, setTierItems] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const url = `/${urlPrefix}/group_items_for_ordering?group_id=${group_id}`;
      const res = await APIClient.get(url, { headers: generateAuthHeader() });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, { type: "warning" });
      setTierItems(data);
    };
    if (group_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group_id]);

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.header}>
          Drag & Drop the items to reorder - then press save
        </div>
        {tierItems.map(({ tier, items }) => (
          <Tier
            key={tier}
            tier={tier}
            originalItems={items}
            urlPrefix={urlPrefix}
            group_id={group_id}
          />
        ))}
      </div>
      <Notification />
    </Fragment>
  );
}

function Tier({ tier, originalItems, urlPrefix, group_id }) {
  const classes = styles();
  const notify = useNotify();
  const [items, setItems] = useState(originalItems);
  const [saving, setSaving] = useState(false);

  const onSave = async () => {
    if (!items.length) return;
    setSaving(true);
    const formatted = items.map(({ id }, index) => {
      return { id, tier_order_number: index + 1 };
    });
    const data = { items: formatted, group_id: Number(group_id) };
    const res = await APIClient.put(
      `/${urlPrefix}/update_group_items_ordering`,
      data,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) notify(errorMessage, { type: "warning" });
    else notify("Order updated successfully", { type: "info" });
    setSaving(false);
  };

  return (
    <div className={classes.tierContainer}>
      <div className={classes.tierHeader}>Tier {tier}</div>
      <List
        values={items}
        lockVertically={true}
        onChange={({ oldIndex, newIndex }) => {
          setItems(arrayMove(items, oldIndex, newIndex));
        }}
        renderList={({ children, props, isDragged }) => (
          <ul
            {...props}
            style={{ cursor: isDragged ? "grabbing" : undefined }}
            className={classes.list}
          >
            {children}
          </ul>
        )}
        renderItem={({ value, props, isDragged, isSelected }) => {
          return (
            <li
              key={value.id}
              {...props}
              style={{
                ...props.style,
                cursor: isDragged ? "grabbing" : "grab",
                backgroundColor:
                  isDragged || isSelected ? "#A9A9A9" : "#FFFFFF",
              }}
              className={classes.item}
            >
              {value.label}
            </li>
          );
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onSave}
        disabled={saving || !items.length}
      >
        save
      </Button>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    margin: "24px 12px",
  },
  header: {
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
  },
  tierContainer: {
    margin: "24px 0",
    border: "1px solid #808080",
    padding: 16,
  },
  tierHeader: {
    fontSize: 18,
    fontWeight: 600,
    paddingBottom: 12,
  },
  list: {
    padding: 0,
    width: "fit-content",
    marginBottom: 24,
  },
  item: {
    padding: 12,
    margin: "8px 0",
    listStyleType: "none",
    border: "2px solid #A9A9A9",
    borderRadius: 8,
    zIndex: 99999,
    width: "fit-content",
  },
}));
