import React, { useEffect, useState } from "react";
import {
  Datagrid,
  TextField,
  ImageField,
  Filter,
  TextInput,
  SelectInput,
  useListController,
  ListView,
  Pagination,
  BooleanField,
  FunctionField,
  BooleanInput,
  ExportButton,
  downloadCSV,
  TopToolbar,
  useNotify,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles, Chip, Button, CircularProgress } from "@material-ui/core";
import { EditProductInventory } from "./EditProductInventory";
import { ProductManualTransactionsDownload } from "./ProductManualTransactionsDownload";
import {
  APIClient,
  generateAuthHeader,
  productStructureTypes,
} from "../../../lib";
import { useSelector } from "react-redux";
import { getSeasonId } from "../../../customState";

const ProductFilter = ({ productSources, productCategories, ...props }) => {
  const classes = styles();
  return (
    <Filter {...props}>
      <TextInput
        label="Name/ID"
        source="q"
        alwaysOn
        className={classes.medInput}
      />
      <TextInput
        label="Sku"
        source="sku"
        alwaysOn
        className={classes.smallInput}
      />
      <SelectInput
        label="Category"
        source="category_id"
        choices={productCategories}
        translateChoice={false}
        className={classes.medInput}
        resettable
        alwaysOn
      />
      <SelectInput
        source="source_id"
        label="Source"
        choices={productSources}
        translateChoice={false}
        resettable
        alwaysOn
        className={classes.medInput}
      />
      <BooleanInput label="Back Ordered" source="back_ordered" alwaysOn />
      <BooleanInput
        label="Removed From Store"
        source="removed_from_store"
        alwaysOn
      />
      <BooleanInput
        label="STH fulfillment flag"
        source="sth_fulfillment_exclusion_flag"
        alwaysOn
      />
      <BooleanInput source="include_inactive" label="Incl. Inactive" alwaysOn />
    </Filter>
  );
};

export function Inventory(props) {
  const { record: { id: supplier_id } = {} } = props;
  const classes = styles();
  const notify = useNotify();
  const season_id = useSelector(getSeasonId);
  const [productSources, setProductSources] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    if (supplier_id) {
      fetchProductSources();
      fetchProductCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const fetchProductSources = async () => {
    const res = await APIClient.get(
      `/product_sources/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductSources(data);
  };

  const fetchProductCategories = async () => {
    const res = await APIClient.get(
      `/product_categories/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductCategories(data);
  };

  const totalsExport = async () => {
    setDownloading(true);
    const res = await APIClient.get(
      `/products/sales_report?supplier_id=${supplier_id}&season_id=${season_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) {
      notify(errorMessage, "warning");
      setDownloading(false);
      return;
    }
    jsonExport(data, (_err, csv) => {
      downloadCSV(csv, "Product Totals");
    });
    setTimeout(() => setDownloading(false), 2000);
  };

  const controllerProps = useListController({
    sort: { field: "product_name", order: "ASC" },
    basePath: "",
    resource: "inventory/products",
    filter: { supplier_id },
    perPage: 25,
    syncWithLocation: false,
  });

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues, supplier_id }}
        exporter={productExporter}
        maxResults={5000}
      />
      <Button
        size="small"
        color="primary"
        startIcon={<GetAppIcon />}
        className={classes.export}
        onClick={totalsExport}
        disabled={downloading}
      >
        Product totals
        {downloading && (
          <CircularProgress
            className={classes.spinner}
            size={30}
            color="primary"
          />
        )}
      </Button>
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={
          <ProductFilter
            productSources={productSources}
            productCategories={productCategories}
          />
        }
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
        classes={{ main: classes.mainList }}
      >
        <Datagrid rowClick={null}>
          <TextField source="id" />
          <TextField source="sku" />
          <TextField source="product_name" label="Name" />
          <ImageField
            source="thumbnail_image"
            label="Thumbnail"
            sortable={false}
            classes={{ image: classes.thumbnail }}
          />
          <ArrayField source="categories" sortable={false}>
            <SingleFieldList linkType={false}>
              <ChipField source="name" size="small" />
            </SingleFieldList>
          </ArrayField>
          <BooleanField source="active" />
          <TextField source="onOrder" label="On Order" sortable={false} />
          <TextField
            source="onOrderAvailable"
            label="On Order Available"
            sortable={false}
          />
          <TextField source="stock_qty" label="Curr Stock Qty" />
          <TextField
            source="totalAvailable"
            label="Total Available"
            sortable={false}
          />
          <TextField source="onHand" label="On Hand" sortable={false} />
          <BooleanField
            source="remove_from_store"
            label="Removed From Store?"
          />
          <BooleanField source="is_back_ordered" label="Back Ordered?" />
          <FunctionField
            label="Edit Inventory"
            render={record => (
              <EditProductInventory
                refetch={controllerProps.refetch}
                {...record}
              />
            )}
          />
          <FunctionField
            label="Manual Adjustments"
            render={record => <ProductManualTransactionsDownload {...record} />}
          />
          <TextField source="structure_type" label="Structure Type" />
          <TextField source="product_parent_id" label="Parent ID" />
          <FunctionField
            addLabel={false}
            render={({ structure_type, is_deprecated }) => {
              if (
                structure_type === productStructureTypes.VARIANT &&
                is_deprecated
              ) {
                return <Chip label="Deprecated Variant" size="small" />;
              }
              return <></>;
            }}
          />
        </Datagrid>
      </ListView>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  thumbnail: { maxHeight: 65 },
  mainList: { height: "calc(100vh - 190px)", overflow: "scroll" },
  smallInput: { width: 80 },
  medInput: {
    maxWidth: 120,
    minWidth: 120,
    width: 120,
  },
  export: {
    marginLeft: 12,
  },
  spinner: { position: "absolute" },
}));

function productExporter(products) {
  const formattedProducts = products.map(product => {
    const {
      active,
      categoryString,
      id,
      is_back_ordered,
      items_per_case,
      onHand,
      onOrder,
      onOrderAvailable,
      product_name,
      product_price,
      remove_from_store,
      sku,
      source_id,
      stock_qty,
      totalAvailable,
      warehouse_id,
      structure_type,
      product_parent_id,
      is_deprecated,
    } = product;
    const exportObj = {
      ID: id,
      Sku: sku,
      Name: product_name,
      Active: active,
      Categories: categoryString,
      warehouse_id,
      source_id,
      items_per_case,
      product_price,
      "On Order": onOrder,
      "On Order Available": onOrderAvailable,
      "Current Stock Qty": `${stock_qty}`,
      "Total Available": totalAvailable,
      "On Hand": onHand,
      remove_from_store,
      is_back_ordered,
      structure_type,
      product_parent_id,
      is_deprecated,
    };
    return exportObj;
  });
  jsonExport(formattedProducts, (err, csv) => {
    downloadCSV(csv, "Inventory");
  });
}
