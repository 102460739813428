import { dashboardTypes } from "./actions";

const initialState = {};

const dashboardReducers = (state = initialState, action) => {
  switch (action.type) {
    // TODO: this can be merged with DASHBOARD_STATS_UPDATE (originally DASHBOARD_STATS overwrote the dashboard state)
    case dashboardTypes.DASHBOARD_STATS:
      return { ...state, ...action.payload };
    case dashboardTypes.DASHBOARD_STATS_UPDATE:
      return { ...state, ...action.payload };
    case dashboardTypes.DASHBOARD_STATS_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};

export { dashboardReducers };
