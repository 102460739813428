import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { makeStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import { GuidedSetupScreen } from "../../../types";
import {
  contactActions,
  getCampaignId,
  updateVolunteerSetupData,
} from "../../../state";
import { ContactsSVG } from "../SVGs/ContactsSVG";
import { sharedStyles } from "../shared.styles";
import { RootState, useAppDispatch } from "../../../types/state";
import {
  AddContacts,
  CircularLoader,
  ConvertSupportersToContacts,
  ImportContacts,
} from "../../../components";
import { ContactList } from "../components/ContactList";
import { useIsMobile } from "../../../hooks/ui";
import { IS_RN_WEBVIEW } from "../../../../lib";
import classNames from "classnames";

export function Contacts(props: GuidedSetupScreen) {
  const {
    activeScreen,
    setDisableNext,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setSubmitting,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { convertibleDonors, contactsFetched, contactCount } = useSelector(
    (state: RootState) => state.contacts,
  );
  const campaign_id = useSelector(getCampaignId);
  const [CDonorsShown, setCDonorsShown] = useState(false);
  const [showCDonors, setShowCDonors] = useState(false);
  const [showAddContacts, setShowAddContacts] = useState(false);
  const [showImportContacts, setShowImportContacts] = useState(false);

  useEffect(() => {
    // only show convertibleDonors once
    if (activeScreen === "CONTACTS" && !CDonorsShown) {
      if (Array.isArray(convertibleDonors) && convertibleDonors.length) {
        setCDonorsShown(true);
        setShowCDonors(true);
      }
    }
  }, [activeScreen, convertibleDonors, CDonorsShown]);

  useEffect(() => {
    if (activeScreen === "CONTACTS") {
      setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]);

  useEffect(() => {
    if (nextTriggeredBy === "CONTACTS") {
      confirmContacts();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  const confirmContacts = useMemo(
    () => async () => {
      setSubmitting(true);
      if (!campaign_id) return;
      const success = await dispatch(
        contactActions.confirmContacts(campaign_id, false),
      );

      if (!success) {
        setSubmitting(false);
        return;
      }
      dispatch(updateVolunteerSetupData({ contacts_confirmed: true }));
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [campaign_id],
  );

  if (activeScreen !== "CONTACTS") return <></>;
  return (
    <div className={classes.wrapper}>
      <div className={sharedClasses.screenContainer}>
        <ContactsSVG />
        <h1 className={sharedClasses.screenHeader}>Add your contacts</h1>
        <p className={sharedClasses.screenParagraph}>We'll do the rest!</p>
        <p className={classes.message}>
          Your contacts will instantly receive emails on your behalf. You can
          also text them from the app.
        </p>

        <Button
          color="primary"
          className={classes.button}
          startIcon={<AddIcon />}
          onClick={() => setShowAddContacts(true)}
        >
          Add contacts
        </Button>
        {isMobile && IS_RN_WEBVIEW && (
          <Button
            color="primary"
            className={classNames(classes.button, classes.importBtn)}
            startIcon={<MobileScreenShareIcon />}
            onClick={() => setShowImportContacts(true)}
          >
            Import phone contacts
          </Button>
        )}
      </div>

      {!contactsFetched && (
        <div className={classes.loaderContainer}>
          <CircularLoader show={!contactsFetched} />
        </div>
      )}
      {contactCount > 0 && <ContactList />}
      <AddContacts
        isOpen={showAddContacts}
        onClose={() => setShowAddContacts(false)}
        openImportModal={() => setShowImportContacts(true)}
      />
      <ImportContacts
        isOpen={showImportContacts}
        onClose={() => setShowImportContacts(false)}
      />
      <ConvertSupportersToContacts
        showDialog={showCDonors}
        setShowDialog={setShowCDonors}
        mustComplete
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  message: {
    marginTop: 16,
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    textAlign: "center",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 32,
    },
  },
  button: {
    width: 300,
    maxWidth: "100%",
  },
  importBtn: {
    marginTop: 16,
  },
  loaderContainer: {
    marginBottom: 24,
  },
}));
