import { Fragment, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Button, makeStyles } from "@material-ui/core";
import { GenericObject } from "../../../types";

type Props = {
  formValues: GenericObject;
};

export function PreviewEventEmail({ formValues }: Props) {
  const classes = styles();
  const [previewEmail, setPreviewEmail] = useState(false);

  const togglePreview = () => setPreviewEmail(prev => !prev);

  return (
    <Fragment>
      <Button
        variant="text"
        onClick={togglePreview}
        color="primary"
        endIcon={previewEmail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        className={classes.expandBtn}
      >
        PREVIEW EMAIL
      </Button>
      {previewEmail && (
        <div className={classes.content}>
          <div className={classes.subjectWrapper}>
            <span className={classes.subject}>Subject:</span>{" "}
            {formValues.email_subject}
          </div>
          <div className={classes.bodyContent}>
            {formValues.emailLogo && (
              <div className={classes.logoWrapper}>
                <img
                  className={classes.logo}
                  alt="logo"
                  src={formValues.emailLogo as string}
                />
              </div>
            )}
            <div>Hello,</div>
            <div
              className={classes.body}
              dangerouslySetInnerHTML={{
                __html: formValues.email_template as string,
              }}
            />
            <div className={classes.clickHereWrapper}>
              <div className={classes.clickHere}>Click here</div>
            </div>

            <div>[Student's name]</div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  expandBtn: {
    marginTop: 16,
    marginBottom: 8,
  },
  content: {
    width: "calc(100% + 48px)",
    marginLeft: -24,
    marginRight: -24,
    backgroundColor: "#F7F7F7",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 32px)",
      marginLeft: -16,
      marginRight: -16,
    },
  },
  subjectWrapper: {
    padding: "0 24px 24px 0",
    fontSize: 16,
    letterSpacing: 0.2,
  },
  subject: {
    paddingRight: 6,
    fontWeight: 500,
  },
  bodyContent: {
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    width: "100%",
    padding: 24,
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  logoWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: 40,
  },
  logo: {
    maxWidth: 126,
    maxHeight: 126,
  },
  body: {
    margin: "40px 0",
  },
  clickHereWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  clickHere: {
    height: 40,
    width: 160,
    border: "1px solid #212121",
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    marginBottom: 24,
  },
}));
