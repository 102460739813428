import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  _theme => ({
    drawer: {
      padding: 20,
      width: "100%",
      minWidth: 350,
      maxWidth: "100%",
    },
    drawerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 10px 0 16px",
      fontSize: 18,
    },
    addGroup: {
      marginRight: 16,
    },
    avatar: { maxHeight: 50 },
    imageSection: {
      display: "inline-flex",
      marginRight: "10px",
    },
    image: {
      maxWidth: 200,
      "& img": {
        maxWidth: "100%",
      },
    },
    fitContent: {
      width: "fit-content",
    },
  }),
  {
    classNamePrefix: "donationGroup",
  },
);
