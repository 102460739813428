import { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { makeStyles, IconButton, Button, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import debounce from "lodash.debounce";
import { RootState, useAppDispatch } from "../../types/state";
import {
  Contact,
  GenericObject,
  UpdateEmailRecipients,
  showEmailSelectorParam,
} from "../../types";
import {
  contactActions,
  getCampaignId,
  setTopBarTitleComponent,
} from "../../state";
import { AddContacts, ImportContacts, SkeletonLoader } from "../../components";
import { NoContacts } from "./components/NoContacts";
import { EmailContacts } from "./components/EmailContacts";
import { useIsDesktop, useIsMobile } from "../../hooks/ui";
import { MobileEmailRecipientSelector } from "./components/MobileEmailRecipientSelector";
import { ActionsDrawer } from "./components/ActionsDrawer";
import { ContactRows } from "./components/ContactRows";
import { ConfirmContacts } from "./components/ConfirmContacts";
import { ConvertSupportersBanner } from "./components/ConvertSupportersBanner";

export function Contacts() {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const contacts = useSelector(
    (state: RootState) => state.contacts.contacts,
  ) as Contact[];
  const campaign_id = useSelector(getCampaignId);
  const contactCount = useSelector(
    (state: RootState) => state.contacts.contactCount,
  );
  const { [showEmailSelectorParam]: _QShowEmailSelector } = queryString.parse(
    location.search,
  );
  const QShowEmailSelector = _QShowEmailSelector === "true";
  const [displayContacts, setDisplayContacts] = useState<Contact[]>([]);
  const [search, setSearch] = useState("");
  const [searchTrigger, setSearchTrigger] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAdd, setShowAdd] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [emailRecipients, setEmailRecipients] = useState<GenericObject>({});
  const [showSearchRow, setShowSearchRow] = useState(false);
  const [showEmailSelector, setShowEmailSelector] = useState(false);
  const [showActionDrawer, setShowActionDrawer] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      await dispatch(contactActions.fetchContacts());
      setLoading(false);
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  useEffect(() => {
    dispatch(contactActions.fetchConvertibleDonors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const component = (
      <div className={classes.headerWrapper}>
        <h1 className={classes.title}>Contacts</h1>
        <span className={classes.count}>
          {`${contactCount} contact${contactCount !== 1 ? "s" : ""}`}
        </span>
      </div>
    );
    dispatch(setTopBarTitleComponent(component));
    return () => {
      dispatch(setTopBarTitleComponent(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!Array.isArray(contacts) || !contacts.length) {
      setDisplayContacts([]);
    } else {
      if (!searchTrigger) {
        setDisplayContacts(contacts);
      } else {
        const filtered = contacts.filter(({ name }: any) =>
          name.toLowerCase().includes(searchTrigger.toLowerCase()),
        );
        setDisplayContacts(filtered);
      }
    }
  }, [contacts, searchTrigger]);

  useEffect(() => {
    if (isDesktop) {
      setShowSearchRow(true);
      setShowEmailSelector(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (isMobile && QShowEmailSelector) setShowEmailSelector(true);
  }, [QShowEmailSelector, isMobile]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce((newVal: string) => setSearchTrigger(newVal), 300),
    [],
  );

  const updateEmailRecipients: UpdateEmailRecipients = (
    id,
    email,
    add,
    clearOthers,
  ) => {
    if (add && clearOthers) {
      setEmailRecipients({ [id]: email });
    } else if (add) {
      setEmailRecipients(prev => {
        return { ...prev, [id]: email };
      });
    } else {
      setEmailRecipients(prev => {
        const { [id]: _rm, ...rest } = prev;
        return rest;
      });
    }
  };

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    debouncedHandleSearch(target.value);
  };

  const closeSearch = () => {
    setShowSearchRow(false);
    setSearchTrigger("");
    setSearch("");
  };

  const onSendEmailClose = () => {
    setShowSendEmail(false);
    clearEmailRecipients();
  };
  const onAddClose = () => setShowAdd(false);
  const onImportClose = () => setShowImport(false);
  const onActionDrawerClose = () => setShowActionDrawer(false);
  const clearEmailRecipients = () => setEmailRecipients({});

  if (showEmailSelector) {
    return (
      <Fragment>
        <MobileEmailRecipientSelector
          setShowSendEmail={setShowSendEmail}
          setShowEmailSelector={setShowEmailSelector}
          updateEmailRecipients={updateEmailRecipients}
          emailRecipients={emailRecipients}
          clearEmailRecipients={clearEmailRecipients}
        />
        <EmailContacts
          isOpen={showSendEmail}
          onClose={onSendEmailClose}
          recipients={emailRecipients}
          updateEmailRecipients={updateEmailRecipients}
        />
      </Fragment>
    );
  }
  return (
    <div className={classes.wrapper}>
      {!isDesktop && (
        <div className={classes.mobileTopContent}>
          <div>
            <h1 className={classes.title}>Contacts</h1>
            <div className={classes.count}>
              {`${contactCount} contact${contactCount !== 1 ? "s" : ""}`}
            </div>
          </div>
          <div>
            <IconButton
              size="small"
              className={classes.mobileAction}
              onClick={() => setShowSearchRow(true)}
            >
              <SearchIcon />
            </IconButton>
            <IconButton
              size="small"
              className={classes.mobileAction}
              onClick={() => setShowAdd(true)}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              size="small"
              className={classes.mobileAction}
              onClick={() => setShowActionDrawer(true)}
            >
              <MoreHorizIcon />
            </IconButton>
          </div>
        </div>
      )}

      {isDesktop && <ConfirmContacts />}
      {isDesktop && <ConvertSupportersBanner />}

      {showSearchRow && (
        <div className={classes.searchAndActionsRow}>
          <TextField
            placeholder="Search contacts"
            value={search}
            size="small"
            onChange={onSearchChange}
            className={classes.input}
            InputProps={{
              endAdornment: (
                <SearchIcon fontSize="small" className={classes.searchIcon} />
              ),
            }}
          />
          {isMobile && (
            <Button
              color="primary"
              variant="text"
              size="small"
              className={classes.closeSearch}
              startIcon={<CloseIcon />}
              onClick={closeSearch}
            >
              CLOSE
            </Button>
          )}
          {isDesktop && (
            <div>
              <Button
                color="primary"
                className={classes.addButton}
                startIcon={<AddIcon />}
                onClick={() => setShowAdd(true)}
              >
                Contact
              </Button>
              <Button
                color="secondary"
                disabled={!Object.keys(emailRecipients).length}
                startIcon={<MailOutlineIcon />}
                onClick={() => setShowSendEmail(true)}
              >
                Compose email
              </Button>
            </div>
          )}
        </div>
      )}

      {isMobile && <ConfirmContacts />}
      {isMobile && <ConvertSupportersBanner />}
      <SkeletonLoader show={loading} />

      {!loading && displayContacts.length > 0 && (
        <ContactRows
          setShowSendEmail={setShowSendEmail}
          updateEmailRecipients={updateEmailRecipients}
          emailRecipients={emailRecipients}
          displayContacts={displayContacts}
        />
      )}

      {!loading && displayContacts.length < 1 && (
        <NoContacts
          hasSearch={Boolean(searchTrigger)}
          setShowAdd={setShowAdd}
        />
      )}
      <AddContacts
        isOpen={showAdd}
        onClose={onAddClose}
        openImportModal={() => setShowImport(true)}
      />
      <ImportContacts isOpen={showImport} onClose={onImportClose} />
      <EmailContacts
        isOpen={showSendEmail}
        onClose={onSendEmailClose}
        recipients={emailRecipients}
        updateEmailRecipients={updateEmailRecipients}
      />
      <ActionsDrawer
        isOpen={showActionDrawer}
        onClose={onActionDrawerClose}
        setShowAdd={setShowAdd}
        setShowEmailSelector={setShowEmailSelector}
        setShowImport={setShowImport}
      />
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "0 32px 18px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 16px 16px",
    },
  },
  mobileTopContent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 12,
  },
  mobileAction: {
    marginLeft: 8,
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  count: {
    fontSize: 16,
    letterSpacing: 0.53,
    paddingLeft: 25,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: "20px",
      paddingLeft: 0,
    },
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  input: {
    width: 350,
    borderRadius: 4,
    backgroundColor: "#EAEBF3",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      height: 32,
      width: "calc(100% - 86px)",
      "& .MuiInputBase-root": {
        height: 32,
      },
    },
  },
  searchIcon: {
    color: theme.palette.text.secondary2,
  },
  closeSearch: {
    marginLeft: 8,
    width: 78,
    borderRadius: 4,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
  },
  addButton: {
    width: 115,
    marginRight: 16,
  },
}));
