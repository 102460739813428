import { useEffect, useState } from "react";
import _isEqual from "lodash.isequal";
import _findIndex from "lodash.findindex";
import {
  makeStyles,
  Tooltip,
  MenuItem,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { useField } from "react-final-form";
import classnames from "classnames";
import {
  configurableProductOptionDisplayTypes,
  hexToRGBA,
} from "../../../../lib";
import { useIsMobile } from "../../../hooks/ui";

enum States {
  EMPTY = "EMPTY",
  DISPLAY_ONLY = "DISPLAY_ONLY",
  DISPLAYING_SELECTED = "DISPLAYING_SELECTED",
  NOT_DISPLAYING_SELECTED = "NOT_DISPLAYING_SELECTED",
}

export function ConfigurablePrizeSelection({
  tier_id,
  product,
  variantProducts,
  productField,
  setIsPersonalize,
  setPersonalizeMaxChars,
  closeConfigSelection,
  showConfigSelection,
}) {
  const isMobile = useIsMobile();
  const classes = styles();
  const { id, image, product_name, configurable_options } = product;
  const isPickedInput = useField(`${productField}.isPicked`).input;
  const savePointComboInput = useField(`${productField}.savePointCombo`).input;
  const isPicked = Boolean(isPickedInput.value);
  const activeProductIdInput = useField(`${productField}.activeProductId`)
    .input;
  const activeProductId = activeProductIdInput.value;
  const p13nTxtInput = useField(`${productField}.personalization_text`).input;
  const [currState, setCurrState] = useState<States>(States.EMPTY);
  const [variants, setVariants] = useState([]);
  const [availableCombos, setAvailableCombos] = useState([]);
  const [selectedCombo, setSelectedCombo] = useState({});
  const [displayedProductId, setDisplayedProductId] = useState(null);

  // variants setup
  useEffect(() => {
    if (variantProducts[id]) {
      setVariants(variantProducts[id]);
      setAvailableCombos(variantProducts[id].map(({ variant }) => variant));
    }
  }, [id, variantProducts]);

  // setup previous variant pick
  useEffect(() => {
    if (variantProducts[id]) {
      const pickedVariant = variantProducts[id].find(
        ({ prevIsPicked }) => prevIsPicked,
      );
      if (pickedVariant) {
        isPickedInput.onChange(true);
        activeProductIdInput.onChange(pickedVariant.id);
        setSelectedCombo(pickedVariant.variant);
        savePointComboInput.onChange(pickedVariant.variant);
        p13nTxtInput.onChange(pickedVariant.personalization_text);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tier_id, variantProducts]);

  // on close put back unsaved changes
  useEffect(() => {
    if (!showConfigSelection && savePointComboInput.value) {
      setSelectedCombo(savePointComboInput.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showConfigSelection]);

  // variant change listener
  useEffect(() => {
    const activeVariant = variants.find(({ variant }) =>
      _isEqual(variant, selectedCombo),
    );
    if (activeVariant) {
      const { id, can_personalize, personalize_max_chars } = activeVariant;
      setDisplayedProductId(id);
      setIsPersonalize(can_personalize);
      setPersonalizeMaxChars(
        personalize_max_chars ? personalize_max_chars : 30,
      );
    } else {
      setDisplayedProductId(null);
      setIsPersonalize(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCombo, variants]);

  const handleClick = (
    currState: States,
    _displayedProductId: number | null,
    _selectedCombo: any,
  ) => {
    switch (currState) {
      case States.DISPLAY_ONLY:
        isPickedInput.onChange(true);
        activeProductIdInput.onChange(_displayedProductId);
        savePointComboInput.onChange(_selectedCombo);
        return;
      case States.NOT_DISPLAYING_SELECTED:
        activeProductIdInput.onChange(_displayedProductId);
        savePointComboInput.onChange(_selectedCombo);
        return;
      case States.DISPLAYING_SELECTED:
        isPickedInput.onChange(false);
        activeProductIdInput.onChange(null);
        setSelectedCombo({});
        savePointComboInput.onChange(null);
        return;
    }
  };

  // state change listener
  useEffect(() => {
    let state: States;
    switch (true) {
      case Boolean(isPicked && activeProductId === displayedProductId):
        state = States.DISPLAYING_SELECTED;
        break;
      case Boolean(isPicked && activeProductId !== displayedProductId):
        state = States.NOT_DISPLAYING_SELECTED;
        break;
      case Boolean(displayedProductId):
        state = States.DISPLAY_ONLY;
        break;
      default:
        state = States.EMPTY;
    }
    setCurrState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPicked, activeProductId, displayedProductId]);

  return (
    <div className={classes.container}>
      <div className={classes.closeWrapper}>
        <IconButton onClick={closeConfigSelection} size="small">
          <CloseIcon className={classes.close} />
        </IconButton>
      </div>

      <div className={classes.mainContent}>
        <div className={classes.imgContainer}>
          <img className={classes.img} src={image} alt="Prize" />
        </div>

        <div className={classes.rightContent}>
          <div className={classes.productName}>{product_name}</div>
          {configurable_options.map((o: any, index: number) => {
            const { option_id, display_type, option_label, option_items } = o;
            const textSwatch =
              display_type ===
              configurableProductOptionDisplayTypes.TEXT_SWATCH;
            const colorSwatch =
              display_type ===
              configurableProductOptionDisplayTypes.COLOR_SWATCH;
            const dropdown = !textSwatch && !colorSwatch;
            const selectedItem = option_items[`_${selectedCombo[option_id]}`];
            const activeTxt = selectedItem ? selectedItem.display_text : "";
            const activeItemId = selectedItem ? selectedItem.item_id : "";
            return (
              <div key={index} className={classes.optionGroup}>
                <div className={classes.label}>
                  {option_label}
                  <span className={classes.displayText}>{activeTxt}</span>
                </div>
                {!dropdown && (
                  <SwatchOptionItems
                    {...{
                      option_id,
                      option_items,
                      selectedCombo,
                      availableCombos,
                      setSelectedCombo,
                      colorSwatch,
                    }}
                  />
                )}
                {dropdown && (
                  <DropdownOptionItems
                    {...{
                      option_id,
                      option_items,
                      selectedCombo,
                      availableCombos,
                      setSelectedCombo,
                      activeItemId,
                    }}
                  />
                )}
              </div>
            );
          })}
          <Button
            className={classes.button}
            size={isMobile ? "medium" : "large"}
            fullWidth
            onClick={() =>
              handleClick(currState, displayedProductId, selectedCombo)
            }
            disabled={currState === States.EMPTY}
            color={
              currState === States.DISPLAYING_SELECTED ? "secondary" : "primary"
            }
            startIcon={
              currState === States.DISPLAYING_SELECTED ? <CheckIcon /> : null
            }
          >
            {currState === States.DISPLAYING_SELECTED
              ? "Selected"
              : currState === States.NOT_DISPLAYING_SELECTED
              ? "Change selection"
              : "Select Prize"}{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}

function SwatchOptionItems({
  option_id,
  option_items,
  selectedCombo,
  availableCombos,
  setSelectedCombo,
  colorSwatch,
}) {
  const classes = styles();
  const handleClick = (item_id: number) => {
    setSelectedCombo({ ...selectedCombo, [option_id]: item_id });
  };

  return (
    <div className={classes.optionItems}>
      {Object.keys(option_items).map((key, index2) => {
        const { item_id, value } = option_items[key];
        const available =
          _findIndex(availableCombos, {
            ...selectedCombo,
            [option_id]: item_id,
          }) > -1;
        const selected = selectedCombo[option_id] === item_id;
        return (
          <Tooltip
            key={index2}
            title={!available ? "unavailable" : ""}
            placement="top"
            classes={{ tooltipPlacementTop: classes.tooltip }}
          >
            <div
              className={classnames(
                classes.swatchContainer,
                colorSwatch && selected && classes.selectedColorSwatchContainer,
                available && classes.pointer,
                !available && classes.disabledSwatch,
              )}
              onClick={!available ? undefined : () => handleClick(item_id)}
            >
              {colorSwatch && (
                <div
                  className={classnames(
                    classes.colorSwatch,
                    selected && classes.selectedColorSwatch,
                  )}
                  style={{
                    backgroundColor: hexToRGBA(value, available ? 1 : 0.5),
                  }}
                >
                  {!available && <div className={classes.diagonalLine} />}
                </div>
              )}
              {!colorSwatch && (
                <div
                  className={classnames(
                    classes.textSwatch,
                    selected && classes.selectedTextSwatch,
                  )}
                >
                  <div
                    className={classnames(!available && classes.unavailableTxt)}
                  >
                    {value}
                  </div>
                  {!available && <div className={classes.diagonalLine} />}
                </div>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

function DropdownOptionItems({
  option_id,
  option_items,
  selectedCombo,
  availableCombos,
  setSelectedCombo,
  activeItemId,
}) {
  const classes = styles();

  const renderValue = (itemId: any) => {
    return !itemId
      ? "Select an Option"
      : option_items[`_${itemId}`]?.display_text || "unknown";
  };

  return (
    <TextField
      select
      variant="outlined"
      size="small"
      value={activeItemId}
      SelectProps={{ displayEmpty: true, renderValue: renderValue }}
      onChange={({ target }) => {
        setSelectedCombo({ ...selectedCombo, [option_id]: target.value });
      }}
      className={classes.select}
    >
      {Object.keys(option_items).map((key, index2) => {
        const { item_id, display_text } = option_items[key];
        const available =
          _findIndex(availableCombos, {
            ...selectedCombo,
            [option_id]: item_id,
          }) > -1;
        return (
          <MenuItem key={index2} value={item_id} disabled={!available}>
            <span
              className={classnames(!available && classes.unavailableMenuItem)}
            >
              {display_text}
            </span>
          </MenuItem>
        );
      })}
    </TextField>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 720,
    padding: "12px 40px 40px 60px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "8px 24px 32px 24px",
      overflowY: "scroll",
    },
  },
  closeWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: -28,
    [theme.breakpoints.down("sm")]: {
      marginRight: -14,
    },
  },
  close: {
    fontSize: 35,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  mainContent: {
    display: "flex",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rightContent: {
    flex: 1,
  },
  imgContainer: {
    width: 260,
    maxWidth: 260,
    margin: "0 60px 0 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
      paddingTop: 32,
      paddingBottom: 52,
      margin: 0,
    },
  },
  img: {
    maxWidth: 260,
    maxHeight: 260,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 190,
      maxHeight: 190,
    },
  },
  productName: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.15,
    lineHeight: "24px",
    marginBottom: 24,
  },
  optionGroup: {
    marginBottom: 16,
    width: "100%",
  },
  optionItems: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginRight: -14,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    lineHeight: "16px",
    marginBottom: 10,
  },
  displayText: {
    fontWeight: 600,
    paddingLeft: 8,
  },
  swatchContainer: {
    marginRight: 12,
    marginBottom: 8,
  },
  selectedColorSwatchContainer: {
    border: `2px solid #000000 !important`,
    borderRadius: "100%",
  },
  colorSwatch: {
    height: 40,
    width: 40,
    border: "1px solid #000000",
    position: "relative",
    borderRadius: "100%",
  },
  selectedColorSwatch: {
    border: `1px solid #FFFFFF !important`,
  },
  textSwatch: {
    height: 40,
    minWidth: 66,
    width: "fit-content",
    padding: "0 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.text.primary}`,
    position: "relative",
    fontSize: 14,
    letterSpacing: 0,
    borderRadius: 20,
  },
  selectedTextSwatch: {
    border: `2px solid ${theme.palette.text.primary} !important`,
    fontWeight: 600,
  },
  disabledSwatch: {
    opacity: 0.5,
  },
  pointer: {
    cursor: "pointer",
  },
  unavailableTxt: {
    opacity: 0.5,
  },
  unavailableMenuItem: {
    textDecoration: "line-through",
  },
  diagonalLine: {
    position: "absolute",
    borderRadius: 20,
    top: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(to bottom left, transparent calc(50% - 1px),  ${theme.palette.text.primary} calc(50% - 1px), ${theme.palette.text.primary} 50%, transparent 50%)`,
  },
  tooltip: {
    marginBottom: "4px !important",
  },
  select: { width: "100%", maxWidth: 400, marginBottom: 8 },
  button: {
    marginTop: 8,
    borderRadius: 21,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 18,
    },
  },
}));
