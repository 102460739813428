import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useMutation, useNotify, useRefresh } from "react-admin";

export const ResolveSubmissionCheckbox = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [resolve, { loading }] = useMutation(
    {
      type: "update",
      resource: "submissions",
      payload: {
        id: record.id,
        data: { resolved: record.resolved ? false : true },
      },
    },
    {
      onFailure: error => notify(error.message, "warning"),
      onSuccess: () => {
        refresh();
        notify("Status successfully updated");
      },
    },
  );

  return (
    <Checkbox
      onChange={loading ? () => null : resolve}
      checked={Boolean(record.resolved)}
    />
  );
};
