import React, { useState, useEffect } from "react";
import {
  TextInput,
  List,
  Datagrid,
  TextField,
  Create,
  required,
  TopToolbar,
  SelectInput,
  SimpleForm,
  Filter,
  Notification,
  useNotify,
  useListContext,
  BooleanInput,
} from "react-admin";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { APIClient, generateAuthHeader } from "../../lib";

const PPFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Program Name" source="q" alwaysOn />
    </Filter>
  );
};

export default function CompanyPrizeProgramList(props) {
  const basePath = props.basePath;
  const [showCreate, setShowCreate] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const notify = useNotify();

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/campaign_types/company_authorized", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setSuppliers(data.productSuppliers);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setShowCreate(true)}
      >
        add prize program
      </Button>
    </TopToolbar>
  );

  return (
    <List
      {...props}
      perPage={25}
      title="Company Prize Programs"
      bulkActionButtons={false}
      exporter={false}
      empty={false}
      filters={<PPFilter />}
      actions={<Actions />}
    >
      <GridWrapper
        suppliers={suppliers}
        basePath={basePath}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
      />
    </List>
  );
}

const GridWrapper = ({
  suppliers,
  basePath,
  showCreate,
  setShowCreate,
  ...props
}) => {
  return (
    <div>
      <Datagrid {...props} rowClick="show">
        <TextField source="id" />
        <TextField source="program_name" label="Program Name" />
        <TextField source="supplier_name" label="Supplier" sortable={false} />
      </Datagrid>
      <AddPrizeProgram
        basePath={basePath}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        suppliers={suppliers}
      />
    </div>
  );
};

function AddPrizeProgram({ showCreate, setShowCreate, basePath, suppliers }) {
  const classes = styles();
  const requiredField = [required()];
  const notify = useNotify();
  const { refetch } = useListContext();

  return (
    <Drawer open={showCreate} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Add a Prize Program</div>
          <IconButton onClick={() => setShowCreate(false)} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Create
          resource="company_prize_programs"
          basePath={basePath}
          onSuccess={() => {
            refetch();
            notify("Prize Program added successfully");
            setShowCreate(false);
          }}
        >
          <SimpleForm>
            <SelectInput
              source="supplier_id"
              label="Select a Supplier"
              choices={suppliers}
              optionText="supplier_name"
              validate={requiredField}
              resettable
              translateChoice={false}
            />
            <TextInput
              source="program_name"
              label="Program Name"
              validate={requiredField}
            />
            <BooleanInput
              source="allow_student_picks"
              label="Allow Student Prize Picking"
              className={classes.fitContent}
            />
            <BooleanInput
              source="send_approaching_tier_notifications"
              label="Send Approaching Tier Notifications To Students"
              className={classes.fitContent}
            />
            <BooleanInput
              source="is_rep_restricted"
              label="Is Rep Restricted"
            />
          </SimpleForm>
        </Create>
      </div>
      <Notification />
    </Drawer>
  );
}

const styles = makeStyles(theme => ({
  drawer: {
    minWidth: 400,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
  fitContent: {
    width: "fit-content",
  },
}));
