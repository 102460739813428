import { GenericObject } from "../../types";

const volunteerSetupTypes = {
  VOLUNTEER_SETUP_DATA_SET: "VOLUNTEER_SETUP_DATA_SET",
  VOLUNTEER_SETUP_DATA_UPDATE: "VOLUNTEER_SETUP_DATA_UPDATE",
};

const setVolunteerSetupData = (data: GenericObject) => ({
  type: volunteerSetupTypes.VOLUNTEER_SETUP_DATA_SET,
  payload: { ...data },
});

const updateVolunteerSetupData = (data: GenericObject) => ({
  type: volunteerSetupTypes.VOLUNTEER_SETUP_DATA_UPDATE,
  payload: { ...data },
});

export { volunteerSetupTypes, setVolunteerSetupData, updateVolunteerSetupData };
