import React, { Fragment, useState } from "react";
import {
  Datagrid,
  ListView,
  TextField,
  useListController,
  useNotify,
} from "react-admin";
import { Drawer, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CampaignForm } from "../campaignViews/CampaignForm";
import { campaignTypeIds } from "../../lib";
import { styles } from "./Company.styles";

export function CompanyCampaignDefaults({ company_id }) {
  const classes = styles();
  const notify = useNotify();
  const [showAside, setShowAside] = useState(false);
  const [campaignFormProps, setCampaignFormProps] = useState({});

  const onRowClick = record => {
    const { id, campaign_type_id } = record;
    const isDonation = campaign_type_id === campaignTypeIds.DONATION;
    const isMatching = campaign_type_id === campaignTypeIds.MATCH;
    const isRaffle = campaign_type_id === campaignTypeIds.RAFFLE;
    const isProduct = campaign_type_id === campaignTypeIds.PRODUCT;
    setCampaignFormProps({
      formProps: {
        id,
        basePath: "",
        resource: "campaign_defaults",
        title: "Edit Campaign Default",
        component: "div",
        undoable: false,
        onSuccess: () => {
          setShowAside(false);
          notify("Campaign Defaults updated successfully");
        },
      },
      isDonation,
      isRaffle,
      isMatching,
      isProduct,
      isEdit: true,
      forDefaults: true,
    });
    setShowAside(true);
  };

  const controllerProps = useListController({
    basePath: "",
    resource: "campaign_defaults",
    filter: { company_id },
    perPage: 25,
  });

  return (
    <Fragment>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
      >
        <Datagrid rowClick={(_, __, record) => onRowClick(record)}>
          <TextField source="id" label="Default ID" sortable={false} />
          <TextField
            source="campaignType"
            label="Campaign Type"
            sortable={false}
          />
          <TextField source="supplier" sortable={false} />
        </Datagrid>
      </ListView>

      <Drawer open={showAside} anchor="right">
        <div className={classes.drawerContent}>
          <div className={classes.drawerHeader}>
            <div>Edit Campaign Default</div>
            <IconButton onClick={() => setShowAside(false)} size="small">
              <CloseIcon />
            </IconButton>
          </div>

          <div>
            <CampaignForm {...campaignFormProps} />
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
}
