import React from "react";
import { TabbedShowLayout, TabbedShowLayoutTabs, Tab, Show } from "react-admin";
import { makeStyles } from "@material-ui/core";
import { ProductList } from "./ProductList";
import { CategoryList } from "./CategoryList";
import { Inventory } from "./inventory/Inventory";
import { ProductOrdering, Shipping } from "../../components";
import { ProductSources } from "./ProductSources";
import { ProductWarehouses } from "./ProductWarehouses";
import { SourcePurchaseOrders } from "./sourcePO/SourcePurchaseOrders";
import { ProductWholesalePrices } from "./ProductWholesalePrices";
import { BOProducts } from "./backOrder/BOProducts";
import { BOCampaigns } from "./backOrder/BOCampaigns";
import { SupplierConfig } from "./SupplierConfig";
import { Projections } from "./projections/Projections";
import { ProfitCategories } from "./ProfitCategories";
import { ProductSuggestions } from "./ProductSuggestions";
import { shippingConfigLevels } from "../../lib";

const Title = ({ record }) => {
  return (
    <span>
      Inventory Management - {record ? `${record.supplier_name}` : ""}
    </span>
  );
};

export default function SupplierDetails(props) {
  const classes = styles();

  ///// DO NOT CHANGE THE PATHS BECAUSE THEY ARE USED FOR REDIRECTS!!
  return (
    <Show {...props} title={<Title />} actions={null}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="Products" path="" className={classes.tab}>
          <ProductList />
        </Tab>
        <Tab
          label="Categories"
          path="product-categories"
          className={classes.tab}
        >
          <CategoryList {...props} />
        </Tab>
        <Tab
          label="Display Order"
          path="product-display-order"
          className={classes.tab}
        >
          <ProductOrdering {...props} forSupplier />
        </Tab>
        <Tab label="Inventory" path="inventory" className={classes.tab}>
          <Inventory />
        </Tab>
        <Tab label="Sources" path="sources" className={classes.tab}>
          <ProductSources />
        </Tab>
        <Tab label="Warehouses" path="warehouses" className={classes.tab}>
          <ProductWarehouses />
        </Tab>
        <Tab label="PO" path="purchase-orders" className={classes.tab}>
          <SourcePurchaseOrders />
        </Tab>
        <Tab label="wholesale" path="wholesale" className={classes.tab}>
          <ProductWholesalePrices />
        </Tab>
        <Tab label="BO-P" path="back-order-products" className={classes.tab}>
          <BOProducts {...props} />
        </Tab>
        <Tab label="BO-C" path="back-order-campaigns" className={classes.tab}>
          <BOCampaigns {...props} />
        </Tab>
        <Tab label="Config" path="config" className={classes.tab}>
          <SupplierConfig {...props} />
        </Tab>
        <Tab label="Shipping" path="shipping" className={classes.tab}>
          <ShippingTab />
        </Tab>
        <Tab label="Projections" path="projections" className={classes.tab}>
          <Projections {...props} />
        </Tab>
        <Tab
          label="profit cat."
          path="profit_categories"
          className={classes.tab}
        >
          <ProfitCategories {...props} />
        </Tab>
        <Tab
          label="suggestions"
          path="product-suggestions"
          className={classes.tab}
        >
          <ProductSuggestions {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

function ShippingTab({ record }) {
  const {
    id: supplier_id,
    shipping_calculated_by,
    shipping_rate_type,
  } = record;
  return (
    <Shipping
      configLevel={shippingConfigLevels.SUPPLIER}
      supplier_id={supplier_id}
      shipping_calculated_by={shipping_calculated_by}
      shipping_rate_type={shipping_rate_type}
    />
  );
}

const styles = makeStyles(_theme => ({
  tab: {
    width: "fit-content",
    maxWidth: "fit-content",
    minWidth: "fit-content",
  },
}));
