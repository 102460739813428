import { TextField, MenuItem, makeStyles } from "@material-ui/core";
import { DiscountCardMerchantFields, ListProps } from "../../../types";
import { useSelector } from "react-redux";
import {
  FilterWrapper,
  FiltersDrawer,
  SwitchFilter,
} from "../../../components";
import { RootState } from "../../../types/state";
import { Fragment } from "react";

type Props = {
  onClose: () => void;
  listProps: ListProps;
};
export function MerchantFilters({ onClose, listProps }: Props) {
  const { getFilterValue, isActiveFilter, updateTempFilters } = listProps;
  const classes = styles();
  const categories = useSelector(
    ({ discountCard }: RootState) =>
      discountCard.discountCardCategoryAutocomplete,
  );

  const onCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target,
      currentTarget: { attributes },
    } = e;
    const category = attributes.getNamedItem("data-category")?.value || "";
    updateTempFilters({
      field: DiscountCardMerchantFields.discount_card_category_id,
      value: target.value,
      chipText: `Category - ${category}`,
    });
  };

  return (
    <FiltersDrawer
      onClose={onClose}
      listProps={listProps}
      header="Filter merchants"
    >
      <Fragment>
        <SwitchFilter
          field={DiscountCardMerchantFields.include_inactive}
          chipText="Include inactive"
          header="Include inactive merchants"
          switchLabel="Include inactive"
          listProps={listProps}
        />
        <FilterWrapper
          header="Category"
          active={isActiveFilter(
            DiscountCardMerchantFields.discount_card_category_id,
          )}
        >
          <TextField
            select
            fullWidth
            value={getFilterValue(
              DiscountCardMerchantFields.discount_card_category_id,
            )}
            onChange={onCategoryChange}
            SelectProps={{ displayEmpty: true }}
          >
            <MenuItem className={classes.labelOption} value="">
              Select a category
            </MenuItem>
            {categories.map(({ id, category_name }, index) => (
              <MenuItem key={index} value={id} data-category={category_name}>
                {category_name}
              </MenuItem>
            ))}
          </TextField>
        </FilterWrapper>
      </Fragment>
    </FiltersDrawer>
  );
}

const styles = makeStyles(theme => ({
  labelOption: {
    color: theme.palette.text.secondary2,
  },
}));
