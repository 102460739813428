import { useEffect, useState } from "react";
import _isEqual from "lodash.isequal";
import _findIndex from "lodash.findindex";
import { FieldArray } from "react-final-form-arrays";
import { makeStyles, Collapse, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import { Level } from "./Level";
import {
  prizeProgramTierRuleDataPoints,
  formatMoneyUnPadded,
} from "../../../../lib";
import classNames from "classnames";

type Props = {
  tierRow: string;
  tierData: any;
  tierNum: number;
  isLast: boolean;
};
export function Tier({ tierRow, tierData, tierNum, isLast }: Props) {
  const classes = styles();
  const { tier_id, rules, variants, prize_pick_message } = tierData;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (tierNum === 1) setOpen(true);
  }, [tierNum]);

  return (
    <div
      className={classNames(classes.container, isLast && classes.noDTBorder)}
    >
      <div className={classes.top} onClick={() => setOpen(!open)}>
        <div className={classes.numAndRules}>
          <div className={classes.tierNum}>{`Tier ${tierNum}`} </div>

          <div className={classes.rules}>
            {rules.map(
              (
                rule: { data_point: string; minimum: number },
                index: number,
              ) => {
                const { data_point, minimum } = rule;
                return (
                  <div key={index} className={classes.rule}>
                    <CheckOutlinedIcon
                      fontSize="small"
                      className={classes.check}
                    />
                    {getRuleDisplay(data_point, minimum)}
                  </div>
                );
              },
            )}
          </div>

          {prize_pick_message && (
            <div className={classes.pickMsg}>{prize_pick_message}</div>
          )}
        </div>
        <IconButton
          size="small"
          onClick={() => setOpen(!open)}
          className={classes.collapse}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>

      <Collapse in={open} timeout="auto">
        <div>
          <FieldArray name={`${tierRow}.levels`}>
            {({ fields: levels }) => (
              <div>
                {levels.map((levelRow, index) => {
                  const levelData = levels.value[index];
                  return (
                    <Level
                      key={index}
                      levelRow={levelRow}
                      levelData={levelData}
                      tier_id={tier_id}
                      levelNum={levels.value.length > 1 ? index + 1 : null} //if there is only one level don't display the levelNum
                      variants={variants}
                    />
                  );
                })}
              </div>
            )}
          </FieldArray>
        </div>
      </Collapse>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    borderBottom: "1px solid #DBDEEE",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  noDTBorder: {
    borderBottom: "none",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #DBDEEE",
    },
  },
  top: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "24px 18px 20px 18px",
    cursor: "pointer",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 20px 16px",
    },
  },
  collapse: {
    color: theme.palette.text.secondary2,
  },
  numAndRules: {
    fontSize: 16,
  },
  tierNum: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "20px",
    paddingBottom: 16,
  },
  rules: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  rule: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    marginRight: 12,
    marginBottom: 4,
  },
  check: {
    color: "#FD7B6F",
    marginRight: 4,
  },
  pickMsg: {
    marginTop: 4,
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
}));

function getRuleDisplay(dataPoint: string, min: number): string {
  let text: string;
  switch (dataPoint) {
    case prizeProgramTierRuleDataPoints.NUMBER_OF_SALES.id:
      text = `Reach ${min} Sales/Donations`;
      break;
    case prizeProgramTierRuleDataPoints.DOLLAR_AMOUNT_RAISED.id:
      text = `Raise $${formatMoneyUnPadded(min)}`;
      break;
    case prizeProgramTierRuleDataPoints.NUMBER_OF_ITEMS_SOLD.id:
      text = `Sell ${min} Items`;
      break;
    case prizeProgramTierRuleDataPoints.NUMBER_OF_CONTACTS.id:
      text = `Add ${min} Contacts`;
      break;
    case prizeProgramTierRuleDataPoints.NUMBER_OF_CONTACTS_WITH_SENT_EMAILS.id:
      text = `Add ${min} Contacts with email addresses`;
      break;

    default:
      text = `${min} ${dataPoint}`;
  }
  return text;
}
