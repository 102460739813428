import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const eventTypes = {
  CAMPAIGN_EVENT_TICKET_EVENT_AUTOCOMPLETE_SET:
    "CAMPAIGN_EVENT_TICKET_EVENT_AUTOCOMPLETE_SET",
  CAMPAIGN_HAS_EVENT_TICKETS_SET: "CAMPAIGN_HAS_EVENT_TICKETS_SET",
};

const eventActions = {
  fetchCampaignEventTicketEventAutocomplete(
    campaign_id: number,
  ): AppThunkAction {
    return async dispatch => {
      const url = `/campaign_event_tickets/event_autocomplete?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      dispatch({
        type: eventTypes.CAMPAIGN_EVENT_TICKET_EVENT_AUTOCOMPLETE_SET,
        data,
      });
    };
  },

  campaignHasCampaignEventTicketsCheck(campaign_id: number): AppThunkAction {
    return async dispatch => {
      const url = `/campaign_event_tickets/campaign_has_tickets_check?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      dispatch({
        type: eventTypes.CAMPAIGN_HAS_EVENT_TICKETS_SET,
        hasTickets: data.hasTickets,
      });
    };
  },
};

export { eventActions, eventTypes };
