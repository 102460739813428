import {
  TabbedShowLayoutTabs,
  Show,
  TabbedShowLayout,
  Tab,
  useShowContext,
} from "react-admin";
import { CompanyPrizeProgramTiers } from "./CompanyPrizeProgramTiers";
import { CompanyPrizeProgramReps } from "./CompanyPrizeProgramReps";
import EditCompanyPrizeProgram from "./EditCompanyPrizeProgram";

export default function CompanyPrizeProgramDetails(props) {
  const Title = ({ record }) => (
    <span>Prize Program - {record ? `${record.program_name}` : ""}</span>
  );

  return (
    <Show {...props} title={<Title />}>
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="Config" path="">
          <EditCompanyPrizeProgram {...props} />
        </Tab>
        <Tab label="Tiers" path="tiers">
          <CompanyPrizeProgramTiers />
        </Tab>
        <PrizeProgramRepsTab path="reps" />
      </TabbedShowLayout>
    </Show>
  );
}

function PrizeProgramRepsTab(props) {
  const { record } = useShowContext();
  if (!record || !record.is_rep_restricted) return <></>;
  return (
    <Tab label="Reps" {...props}>
      <CompanyPrizeProgramReps />
    </Tab>
  );
}
