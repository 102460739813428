import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  theme => ({
    card: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "20px",
    },
    statsSection: {
      width: "100%",
      maxWidth: "100%",
      height: "120px",
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        height: "unset",
        padding: "16px",
      },
    },
    statColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: ({ forOrg }) => (forOrg ? "25%" : 378),
      padding: "0 5px",
      [theme.breakpoints.down("sm")]: {
        minWidth: () => "unset",
      },
    },
    stat: {
      fontSize: "28px",
      color: "#1e88e5",
      paddingBottom: "8px",
    },
    statText: {
      color: "#999999",
      fontSize: "11px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
    divider: {
      height: "50px",
      display: "flex",
      alignItems: "center",
      borderRight: "1px solid #999999",
      [theme.breakpoints.down("sm")]: {
        borderRight: "none",
        borderBottom: "1px solid #999999",
        height: 0,
        width: "100%",
        marginBottom: "16px",
        paddingTop: "16px",
      },
    },
    levelMobileDivider: {
      [theme.breakpoints.down("sm")]: {
        borderBottom: "none !important",
        // height: 0,
        // width: "100%",
        // marginBottom: "16px",
        // paddingTop: "16px",
      },
    },
    levelLabel: {
      textAlign: "center",
      paddingTop: "16px",
      color: "rgba(0,0,0,0.51)",
      fontSize: 18,
      fontWeight: 500,
      letterSpacing: 0.07,
      textTransform: "uppercase",
    },
    // filter styles
    filterContainer: {
      display: "flex",
      flexDirection: "column",
    },
    inputsWrapper: {
      marginTop: 12,
    },
    input: {
      width: 172,
      [theme.breakpoints.down("sm")]: {
        width: 160,
      },
    },
    leftInput: {
      marginRight: 12,
      [theme.breakpoints.down("sm")]: {
        marginRight: 4,
      },
    },
    mobileFilterTxt: {
      [theme.breakpoints.down("sm")]: {
        padding: "12px 0",
      },
    },

    // HIBERNATE CUSTOM
    hibernateData: {
      display: "flex",
      alignItems: "center",
    },
    hibernateColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    hibernateTtlColumn: {
      padding: "0 32px",
    },
    hibernateStat: {
      fontSize: "18px",
      color: "#1e88e5",
      paddingBottom: "8px",
    },
    hibernateStatText: {
      color: "#999999",
      fontSize: "9px",
      fontWeight: 500,
      textTransform: "uppercase",
    },
  }),
  {
    classNamePrefix: "statsCard",
  },
);
