import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from "react-admin";

const requiredField = [required()];

export default function DigitalContentCodeEdit(props) {
  return (
    <Edit
      {...props}
      undoable={false}
      successMessage="Digital Content Code updated successfully"
      title="Edit Digital Content Code"
    >
      <SimpleForm redirect="list">
        <TextInput source="id" disabled />
        <NumberInput
          source="digital_content_id"
          validate={requiredField}
          label="Digital Content ID"
        />
        <TextInput source="code" validate={requiredField} />
      </SimpleForm>
    </Edit>
  );
}
