import React, { useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { makeStyles } from "@material-ui/core";
import { APIClient, generateAuthHeader } from "../../lib";
import { Replacements } from "./components/Replacements";

export const CampaignReplacements = ({ campaign_id }) => {
  const classes = styles();
  const notify = useNotify();
  const [replacements, setReplacements] = useState([]);

  useEffect(() => {
    if (campaign_id) fetchInvoiceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchInvoiceData = async () => {
    const res = await APIClient.get(
      `/order_replacement/campaigns/${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setReplacements(data);
  };
  return (
    <div className={classes.container}>
      <Replacements replacements={replacements} fullList />
      {replacements.length < 1 && (
        <div className={classes.empty}>There are no replacements yet.</div>
      )}
    </div>
  );
};

const styles = makeStyles(_theme => ({
  container: {
    minHeight: 400,
  },
  empty: {
    paddingTop: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    fontWeight: 600,
  },
}));
