import React from "react";
import { Datagrid, List, TextField } from "react-admin";

export default function CheckpointEmailList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Checkpoint Emails"
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" sortable={false} />
        <TextField source="email_code" sortable={false} />
        <TextField source="description" sortable={false} />
        <TextField source="days" sortable={false} />
      </Datagrid>
    </List>
  );
}
