import { Button, makeStyles } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { isNewAppUser, parseUserToken } from "../../../lib";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { APIRes, GenericObject } from "../../types";
import { APIPublicClient, requiredField } from "../../lib";
import { useDispatch } from "react-redux";
import { setToast } from "../../state";
import { TextFieldWrapper } from "../../components";

type Props = {
  campaign_id: null | number;
};
export function ResetPasswordForm({ campaign_id }: Props) {
  const classes = styles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { "set-pw": setPW, jwt, redirect } = queryString.parse(location.search);

  const onSubmit = async (values: GenericObject) => {
    const url = "/users/reset-password";
    const post = { newPassword: values.newPassword, jwt };
    const res = await APIPublicClient.post<any, APIRes>(url, post);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    if (data.token) {
      const token = data.token;
      if (campaign_id) {
        localStorage.setItem("selectedCampaignId", `${campaign_id}`);
      }
      localStorage.setItem("token", token);
      const { role } = parseUserToken(token) as GenericObject;
      let path = redirect ? (redirect as string) : "/";
      path = isNewAppUser(role) ? path : `/admin${path}`;
      window.location.replace(path);
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={values => {
        const { newPassword, confirmPW } = values;
        const errors: GenericObject = {};
        if (newPassword && confirmPW && newPassword !== confirmPW) {
          errors.confirmPW = "Passwords much match";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <div className={classes.title}>
              {setPW ? "Set a Password" : "Reset Password"}
            </div>
            <Field
              name="newPassword"
              label={setPW ? "Password" : "New password"}
              component={TextFieldWrapper}
              validate={requiredField}
              className={classes.input}
              size="medium"
              fullWidth
              type="password"
            />
            <Field
              name="confirmPW"
              label="Confirm password"
              component={TextFieldWrapper}
              validate={requiredField}
              className={classes.input}
              size="medium"
              fullWidth
              type="password"
            />
            <Button
              fullWidth
              type="submit"
              disabled={submitting}
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
            >
              {setPW ? "Set " : "Reset "}
              password and login
            </Button>
          </form>
        );
      }}
    />
  );
}

const styles = makeStyles(_theme => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: 18,
  },
  input: {
    marginBottom: 24,
  },
  button: {
    borderRadius: 21,
  },
}));
