import { SearchFields } from "./list";

export type CampaignDonationQueryParams = {
  page: number;
  limit: number;
  sort: string;
  order: "asc" | "desc";
  donation_name_or_email?: string | null;
  donation_id?: string | null;
  start_date?: string;
  end_date?: string;
  volunteer_id?: number;
  unassigned?: boolean;
  id_or_name?: string | null;
};

export enum CampaignDonationFilterFields {
  start_date = "start_date",
  end_date = "end_date",
  volunteer_id = "volunteer_id",
  unassigned = "unassigned",
}

export enum DonationDetailModes {
  view = "view",
  edit = "edit",
}

export function campaignDonationSearchFields(
  isProduct: boolean,
  volunteerLabelSing: string,
): SearchFields {
  return [
    {
      label: "Supporter",
      field: "donation_name_or_email",
      placeholder: "Name or email",
    },
    {
      label: `${isProduct ? "Order" : "Donation"} ID`,
      field: "donation_id",
      placeholder: `${isProduct ? "Order" : "Donation"} ID`,
      isNumber: true,
    },
    {
      label: volunteerLabelSing,
      field: "id_or_name",
      placeholder: `${volunteerLabelSing} ID/name`,
    },
  ];
}

export function campaignDonationSortFields(
  isProduct: boolean,
  volunteerLabelSing: string,
  isOrg: boolean,
) {
  const fields = [
    { field: "id", label: isProduct ? "Order ID" : "Donation ID" },
    { field: "last_name", label: "Name" },
    { field: "created_at", label: isProduct ? "Order date" : "Donation date" },
    { field: "solicited_by", label: "Solicited by" },
    { field: "total_amount", label: "Amount" },
  ];
  if (isOrg) {
    fields.push({ field: "volunteerName", label: volunteerLabelSing });
  }
  if (isProduct) {
    fields.push({ field: "ship_to_school", label: "Shipping Method" });
  }

  return fields;
}
