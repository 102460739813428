import { useEffect, useState } from "react";
import _isEqual from "lodash.isequal";
import _findIndex from "lodash.findindex";
import { makeStyles } from "@material-ui/core";
import { FieldArray } from "react-final-form-arrays";
import { PrizeCard } from "./PrizeCard";
import classNames from "classnames";

export function Level({ levelData, levelRow, variants, tier_id, levelNum }) {
  const classes = styles();
  const { number_of_prizes_to_assign: numToSelect } = levelData;
  const [levelIsFullySelected, setLevelIsFullySelected] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [pickedLevelProducts, setPickedLevelProducts] = useState({});

  useEffect(() => {
    const selected = Object.keys(pickedLevelProducts).length;
    setSelectedCount(selected);
    setLevelIsFullySelected(selected >= numToSelect);
  }, [pickedLevelProducts, numToSelect]);

  return (
    <div
      className={classNames(classes.container, levelNum && classes.containerBG)}
    >
      {levelNum && (
        <div className={classes.levelNum}>{`Level ${levelNum}`}</div>
      )}

      <div className={classes.numbers}>
        <div>Choose {numToSelect}</div>
        <div>{`${selectedCount}/${numToSelect} Selected`}</div>
      </div>

      <div className={classes.prizeCards}>
        <FieldArray name={`${levelRow}.levelProducts`}>
          {({ fields: levelProducts }) => {
            return levelProducts.map((productField, index) => {
              const product = levelProducts.value[index];
              return (
                <PrizeCard
                  key={index}
                  tier_id={tier_id}
                  product={product}
                  variantProducts={variants}
                  levelIsFullySelected={levelIsFullySelected}
                  productField={productField}
                  setPickedLevelProducts={setPickedLevelProducts}
                />
              );
            });
          }}
        </FieldArray>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    marginBottom: 8,
    paddingTop: 16,
    width: "100%",
    maxWidth: "100%",
  },
  containerBG: {
    backgroundColor: "#FAFAFA",
  },
  levelNum: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.12,
    marginBottom: 8,
    paddingLeft: 18,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
    },
  },
  numbers: {
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.secondary2,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    padding: "0 18px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  prizeCards: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "0 6px",
    [theme.breakpoints.down("sm")]: {
      overflowX: "auto",
      flexWrap: "nowrap",
      padding: "0 4px",
    },
  },
}));
