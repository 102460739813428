import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  _theme => ({
    ////// ORG LIST ///////
    idLabel: { fontWeight: 600 },
    image: { maxHeight: "40px", maxWidth: "162px" },
    impersonateLink: {
      display: "flex",
      alignItems: "center",
      marginLeft: "30px",
    },
    siteLink: {
      display: "flex",
      alignItems: "center",
      marginLeft: "16px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    externalIdentifierInput: {
      width: 295,
    },
    statusChip: {
      color: "#FFFFFF",
    },
    activeChip: {
      backgroundColor: "green",
    },
    inactiveChip: {
      backgroundColor: "orange",
    },
    endedChip: {
      backgroundColor: "red",
    },

    ////// ORG SHOW ///////
    ShowName: {
      fontSize: "24px",
    },
    ShowImage: {
      maxHeight: "80px",
      maxWidth: "300px",
    },
    orgInfoLabel: {
      fontWeight: 600,
      paddingRight: 8,
    },
    orgInfoLineBreak: {
      marginBottom: 12,
    },
    campaignListWrapper: {
      marginTop: "20px",
    },
    campaignToolbar: {
      width: "100%",
      height: "64px",
      backgroundColor: "#fafafa",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: 0,
    },
    activeCampaignTitle: {
      fontSize: "18px",
      fontWeight: 600,
      padding: "12px 0",
    },

    ////// ORG CREATE/EDIT ///////
    editViewImage: {
      maxHeight: "80px",
      maxWidth: "200px",
    },
    editInlineBlock: {
      display: "inline-flex",
      marginRight: "1rem",
    },
    manageOverride: {
      width: "590px !important",
    },
    warning: { color: "red", fontWeight: 600 },
  }),
  {
    classNamePrefix: "orgViews",
  },
);
