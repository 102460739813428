import { Fragment, useState } from "react";
import { makeStyles, IconButton, Button } from "@material-ui/core";
import PreviewIcon from "@material-ui/icons/VisibilityOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ManualGeneratedIcon from "@material-ui/icons/CardGiftcardOutlined";
import SaleGeneratedIcon from "@material-ui/icons/LanguageOutlined";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import classNames from "classnames";
import { PreviewCard } from "./PreviewCard";
const MIN_WIDTH = 1350;

type Props = {
  listProps: ListProps<any>;
};
export function CustomerCardRows({ listProps }: Props) {
  const { rows, params, setParams, nextPage, paginationProps } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="ID"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Campaign ID"
                fieldName="campaign_id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Campaign card ID"
                fieldName="parent_campaign_product_discount_card_id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Origin"
                fieldName="config_level"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Assigned"
                fieldName="assigned"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Card name"
                fieldName="card_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="User phone"
                fieldName="phone"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Order ID"
                fieldName="donation_id"
                params={params}
                setParams={setParams}
              />
              <div>Activation code</div>
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return <Row key={row.id as number} row={row} lastRow={lastRow} />;
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Customer cards per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
};
function Row({ row, lastRow }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showPreview, setShowPreview] = useState(false);
  const {
    id,
    campaign_id,
    card_name,
    activation_code,
    donation_id,
    config_level,
    assigned,
    phone,
    parent_campaign_product_discount_card_id,
  } = row;

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>{campaign_id}</div>
            <div>{parent_campaign_product_discount_card_id}</div>
            <div>
              {config_level === "donation_product" ? (
                <SaleGeneratedIcon />
              ) : (
                <ManualGeneratedIcon />
              )}
            </div>
            <div>
              {assigned ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>{card_name}</div>
            <div>{phone}</div>
            <div>{donation_id}</div>
            <div>{activation_code}</div>
            <div>
              <Button
                color="primary"
                variant="text"
                size="small"
                endIcon={<PreviewIcon />}
                onClick={() => setShowPreview(true)}
              >
                PREVIEW
              </Button>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div
                className={classNames(
                  classes.status,
                  !assigned && classes.unassigned,
                )}
              />
              <div className={classes.mobileContent}>
                <div className={classes.campaignId}>
                  Campaign ID: {campaign_id}
                </div>
                <div className={classes.name}>{card_name}</div>
                <div className={classes.phone}>Phone: {phone}</div>
                <div className={classes.code}>
                  {donation_id && `Order ID: ${donation_id}`}
                  {!donation_id && `Activation code: ${activation_code}`}
                </div>
              </div>
            </div>

            <div className={classes.preview}>
              <IconButton onClick={() => setShowPreview(true)}>
                <PreviewIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showPreview && (
        <PreviewCard close={() => setShowPreview(false)} id={id as number} />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns:
      "112px 120px 152px 112px 112px 1fr 144px 112px 142px 112px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  mobileContent: {
    overflow: "hidden",
  },
  preview: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  campaignId: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      color: theme.palette.text.secondary2,
    },
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      paddingTop: 6,
      paddingBottom: 6,
      lineHeight: "17px",
    },
  },
  phone: {
    fontSize: 12,
    letterSpacing: 0.09,
    paddingBottom: 4,
  },
  code: {
    fontSize: 12,
    letterSpacing: 0.09,
    color: theme.palette.text.secondary2,
  },
  status: {
    height: 8,
    width: 8,
    minWidth: 8,
    borderRadius: "100%",
    backgroundColor: "#5FEA6B",
    marginRight: 12,
  },
  unassigned: {
    backgroundColor: "#ED2A2A",
  },
}));
