import { SearchFields } from "./list";

export type SubmissionQueryParams = {
  page: number;
  limit: number;
  sort: string;
  order: "asc" | "desc";
  campaign_id?: number | null;
};

export const submissionSearchFields: SearchFields = [
  { label: "ID", field: "id", placeholder: "Submission ID", isNumber: true },
  { label: "Name", field: "name", placeholder: "Name" },
  { label: "Email", field: "email", placeholder: "Email" },
  { label: "Message", field: "message", placeholder: "Message" },
];

export enum SubmissionFilterFields {
  resolved = "resolved",
}

export const submissionSortFields = [
  { field: "resolved", label: "Resolved" },
  { field: "id", label: "Submission ID" },
  { field: "created_at", label: "Date" },
  { field: "name", label: "Name" },
  { field: "email", label: "Email" },
];
