import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Button } from "@material-ui/core";
import { CompleteSVG } from "../SVGs/CompleteSVG";
import { sharedStyles } from "../shared.styles";
import { APIAuthClient, affiliateLink, scrollToTop } from "../../../lib";
import { APIRes, paths } from "../../../types";
import { getVolunteerCampaignId, setToast } from "../../../state";
import { useHistory } from "react-router-dom";
import { AFFILIATES } from "../../../../lib";
import { RootState } from "../../../types/state";

export function Complete() {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setup_completed, webpageLink } = useSelector(
    (state: RootState) => state.volunteerSetup,
  );
  const volunteerCampaignId = useSelector(getVolunteerCampaignId);
  const link = affiliateLink(
    webpageLink,
    AFFILIATES.VOLUNTEER_SETUP_SHARING_LINKS,
  );

  useEffect(() => {
    scrollToTop();
    if (!setup_completed) markCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup_completed]);

  const markCompleted = useMemo(
    () => async () => {
      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaigns/${volunteerCampaignId}`,
        { setup_completed: true },
      );
      if (res.error) {
        dispatch(setToast("There was an error completing you setup."));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const goToDashboard = () => {
    if (!setup_completed) window.location.replace(paths.HOME);
    else history.replace(paths.HOME);
  };

  return (
    <div className={classes.wrapper}>
      <div className={sharedClasses.screenContainer}>
        <CompleteSVG />
        <h1 className={sharedClasses.screenHeader}>Well done!</h1>
        <p className={sharedClasses.screenParagraph}>
          Great job setting up your page and getting your fundraiser off to a
          great start!
        </p>
        <p className={classes.p2}>
          Log in to the app to see who supported you and manage your campaign.
          You can edit your page or add contacts at any time.
        </p>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href={link}
          target="_blank"
        >
          Go to my personal page
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={goToDashboard}
        >
          Visit my dashboard
        </Button>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 64,
  },
  p2: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    textAlign: "center",
    marginTop: 16,
    marginBottom: 48,
  },
  button: {
    width: 300,
    height: 36,
    borderRadius: 18,
    marginBottom: 16,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
  },
}));
