import { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  SelectInput,
  List,
  ImageField,
  NumberField,
  ArrayField,
  SingleFieldList,
  ChipField,
  useNotify,
} from "react-admin";
import { useSelector } from "react-redux";
import { EditCampaignProduct } from "./EditCampaignProduct";
import { RemoveProduct } from "./RemoveProduct";
import { getRoleBooleans, getIsOwner } from "../../../customState";
import { APIClient, generateAuthHeader } from "../../../lib";

const ProductFilter = ({ productCategories, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search Products" source="q" alwaysOn />
    <SelectInput
      label="Category"
      source="category_id"
      choices={productCategories}
      translateChoice={false}
      resettable
      alwaysOn
    />
  </Filter>
);

export const CampaignProducts = ({ record }) => {
  const { id: campaign_id, product_supplier_id: supplier_id } = record;
  const notify = useNotify();
  const basePath = `/campaigns/${campaign_id}/show/products`;
  const [productCategories, setProductCategories] = useState([]);

  useEffect(() => {
    if (supplier_id) {
      fetchProductCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const fetchProductCategories = async () => {
    const res = await APIClient.get(
      `/product_categories/autocomplete?supplier_id=${supplier_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProductCategories(data);
  };

  return (
    <List
      title=" "
      location={{}}
      resource="campaign_products"
      basePath={basePath}
      filter={{ campaign_id }}
      perPage={25}
      sort={{ field: "id", order: "ASC" }}
      component="div"
      exporter={false}
      bulkActionButtons={false}
      filters={<ProductFilter productCategories={productCategories} />}
    >
      <GridWrapper campaignRecord={record} />
    </List>
  );
};

function GridWrapper({ campaignRecord, refetch, ...props }) {
  const {
    company_can_customize_products,
    rep_can_customize_products,
  } = campaignRecord;
  const { isCompanyOrCompanyAdmin, isRep } = useSelector(getRoleBooleans);
  const isOwner = useSelector(getIsOwner);
  const canRemove = !isRep || (isRep && rep_can_customize_products);
  const canCustomize =
    (isCompanyOrCompanyAdmin && company_can_customize_products) ||
    (isRep && rep_can_customize_products);
  const [selectedRow, setSelectedRow] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const classes = styles();

  const closeEdit = refreshList => {
    setShowEdit(false);
    setSelectedRow({});
    if (refreshList) refetch();
  };

  return (
    <Fragment>
      <Datagrid
        refetch={refetch}
        {...props}
        rowClick={
          canCustomize
            ? (_id, _basePath, record) => {
                setSelectedRow(record);
                setShowEdit(true);
              }
            : null
        }
      >
        <TextField source="id" label="Product Id" />
        <TextField source="sku" />
        <ImageField
          source="thumbnail_image"
          label="Thumbnail"
          classes={{ image: classes.thumbnail }}
          sortable={false}
        />
        <ArrayField source="categories" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="product_name" label="Name" />
        <NumberField source="product_price" label="Price" sortable={false} />
        {isOwner && (
          <NumberField
            source="digital_content_addition_id"
            label="+DC Id"
            sortable={false}
          />
        )}
        <NumberField
          source="digital_content_addition_code_qty"
          label="+Dig. Codes"
          sortable={false}
        />
        {canRemove && (
          <FunctionField
            label="Remove"
            render={({ campaignProductId }) => (
              <RemoveProduct campaignProductId={campaignProductId} />
            )}
          />
        )}
      </Datagrid>
      {showEdit && (
        <EditCampaignProduct record={selectedRow} onClose={closeEdit} />
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  thumbnail: { maxHeight: 60, maxWidth: 60 },
}));
