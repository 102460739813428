import React, { Fragment, useEffect, useState } from "react";
import { useNotify } from "react-admin";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Button,
  TextField as MuiTextField,
  IconButton,
} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import {
  APIClient,
  APIUtilClient,
  campaignTypeIds,
  generateAuthHeader,
  orgProfitStatuses,
  formatMoney,
  formatDateOnlyString,
  downloadPdf,
  getUserTimezone,
  formatSum,
} from "../../../lib";
import { getRoleBooleans } from "../../../customState";
import { PaidEmailNotificationModal } from "./PaidEmailNotificationModal";
import { PaymentDetails } from "./PaymentDetails";
import { ProfitWorksheet } from "./ProfitWorksheet";
import { PostCloseout } from "./PostCloseout";
import { ChangeListener } from "./ChangeListener";
import { TaxReportDownload } from "./TaxReportDownload";
import { BigKahunaCustomInvoice } from "./BigKahunaCustomInvoice";
import { CustomAdjustments } from "./CustomAdjustments";
import { ProfitAndCommissionAdditionalInfo } from "../components/ProfitAndCommissionAdditionalInfo";
import { CampaignDonationProductAggregatesCSV } from "../../../components";
import { styles } from "./OrgProfit.styles";

const listenerFields = [
  "categoryAggregates",
  "org_profit_adjustments",
  "donation_profit_percent",
  "post_closeout_adjusted_profit_percent",
  "post_closeout_custom_profit_amount",
];

export function OrgProfit({ record }) {
  const { id: campaign_id, campaign_type_id, orgName } = record;
  const notify = useNotify();
  const classes = styles();
  const [opData, setOrgProfitData] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [isProduct, setIsProduct] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);
  const [isRepConfirmed, setIsRepConfirmed] = useState(false);
  const [isProfitConfirmed, setIsProfitConfirmed] = useState(false);
  const [isReadyForPayment, setIsReadyForPayment] = useState(false);
  const [isInvoiceSent, setIsInvoiceSent] = useState(false);
  const [disableEdit, setDisableEdit] = useState(true);
  const [editOverrideTriggered, setEditOverrideTriggered] = useState(false);
  const [totals, setTotals] = useState({});
  const [actionDate, setActionDate] = useState(getTodaysDate());
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [paidEmailTemplate, setPaidEmailTemplate] = useState({});
  const { isCompanyOrCompanyAdmin, isRep } = useSelector(state =>
    getRoleBooleans(state),
  );
  const canManage = useSelector(state => state.user.canManage);

  useEffect(() => {
    if (campaign_id) fetchOrgProfitData();
    setIsProduct(campaign_type_id === campaignTypeIds.PRODUCT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  useEffect(() => {
    if (!Object.keys(opData).length) return;
    const {
      pay_to,
      mail_to_name,
      mail_to_att,
      mail_to_address,
      mail_to_city_state_zip,
      payment_memo,
      org_profit_adjustments,
      notes,
      notes_to_org,
      status,
      post_closeout_adjusted_profit_percent,
      post_closeout_custom_profit_amount,
      categoryAggregates,
      donation_profit_percent,
      tracking_carrier,
      tracking_number,
    } = opData;
    setIsPendingConfirmation(status === orgProfitStatuses.PENDING_CONFIRMATION);
    setIsProfitConfirmed(status === orgProfitStatuses.PROFIT_CONFIRMED);
    setIsRepConfirmed(status === orgProfitStatuses.REP_CONFIRMED);
    setIsReadyForPayment(status === orgProfitStatuses.READY_FOR_PAYMENT);
    setIsInvoiceSent(status === orgProfitStatuses.INVOICE_SENT);

    if (
      canManage &&
      (!status ||
        status === orgProfitStatuses.PENDING_CONFIRMATION ||
        (isCompanyOrCompanyAdmin && status === orgProfitStatuses.REP_CONFIRMED))
    ) {
      setDisableEdit(false);
    } else setDisableEdit(true);

    setInitialValues({
      pay_to,
      mail_to_name,
      mail_to_att,
      mail_to_address,
      mail_to_city_state_zip,
      payment_memo,
      categoryAggregates,
      donation_profit_percent,
      org_profit_adjustments,
      notes,
      notes_to_org,
      post_closeout_adjusted_profit_percent,
      post_closeout_custom_profit_amount,
      tracking_carrier,
      tracking_number,
    });
  }, [opData, canManage, isCompanyOrCompanyAdmin]);

  const fetchOrgProfitData = async () => {
    const res = await APIClient.get(`/org_profit/campaigns/${campaign_id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setOrgProfitData(data);
    setTimeout(() => {
      setActionTriggered(false);
      setActionDate(getTodaysDate());
    }, 100);
  };

  const onSubmit = async values => {
    if (editOverrideTriggered) setDisableEdit(true);
    const { categoryAggregates, org_profit_adjustments = [], ...rest } = values;
    const update = { ...rest, org_profit_adjustments };
    // convert empty strings to null (percent & $ fields will fail validation without this)
    Object.keys(update).forEach(
      k => (update[k] = update[k] === "" ? null : update[k]),
    );

    if (isProduct) {
      if (!Array.isArray(categoryAggregates)) {
        return notify(
          "Something is not right, please refresh your page and try again.",
          "warning",
        );
      }
      const categories = {};
      let missingIds = false;
      categoryAggregates.forEach(c => {
        const {
          profit_category_campaign_id: id,
          isOnline,
          isCustomProductProfit,
          org_profit_online_percent,
          org_profit_offline_percent,
        } = c;
        if (isCustomProductProfit) return;
        if (!id) missingIds = true;
        if (!categories[id]) categories[id] = { id };
        if (isOnline) {
          categories[id].org_profit_online_percent = org_profit_online_percent;
        } else {
          categories[
            id
          ].org_profit_offline_percent = org_profit_offline_percent;
        }
      });
      if (missingIds) {
        return notify(
          "Missing record Ids! Please refresh your page and try again.",
          "warning",
        );
      }
      update.campaignProfitCategories = Object.values(categories);
    }

    const res = await APIClient.put(
      `/org_profit/campaigns/${campaign_id}`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) {
      return notify(errorMessage, "warning");
    }
    fetchOrgProfitData();
    notify("info has been successfully updated");
  };

  const handleAction = async status => {
    setActionTriggered(true);
    const confirmed = window.confirm(
      "Are you sure you want to change the status?",
    );
    if (!confirmed) return setActionTriggered(false);
    const update = {
      status,
      profit_amount: totals.rawGrandTotal,
    };
    if (actionDate) update.actionDate = actionDate;

    const res = await APIClient.put(
      `/org_profit/campaigns/${campaign_id}/change-status`,
      update,
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    fetchOrgProfitData();
    notify("You have successfully updated the status!");

    // there will only be an emailTemplate if it was changed to a `paid` status
    if (data && data.emailTemplate) {
      setShowEmailModal(true);
      setPaidEmailTemplate(data.emailTemplate);
    }
  };

  const handleEditOverride = async () => {
    const confirmed = window.confirm(
      "The profit has already been confirmed, are you sure you want to edit?",
    );
    if (confirmed) {
      setDisableEdit(false);
      setEditOverrideTriggered(true);
    }
  };

  const download = async () => {
    setActionTriggered(true);
    const timezone = getUserTimezone();
    const res = await APIUtilClient.get(
      `/org_profit/campaigns/${campaign_id}/pdf?timezone=${timezone}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    setTimeout(() => setActionTriggered(false), 2000);
    return downloadPdf(res, `${campaign_id} ${orgName} school-sheet-.pdf`);
  };

  return (
    <div className={classes.container}>
      <div className={classes.warning}>
        This report does not include post-closeout tagged sales! (view them on
        the `Post Closeout` table)
      </div>
      <div className={classes.topSection}>
        <div className={classes.rep}>REP: {opData.repName}</div>
        <div className={classes.downloadContainer}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<DownloadIcon />}
            onClick={download}
            size="small"
            disabled={actionTriggered}
          >
            export
          </Button>
          {isProduct && (
            <Fragment>
              <CampaignDonationProductAggregatesCSV campaign_id={campaign_id} />
              <TaxReportDownload campaign_id={campaign_id} />
              <BigKahunaCustomInvoice campaign_id={campaign_id} />
            </Fragment>
          )}
        </div>
      </div>

      <div className={classes.status}>
        <TableContainer component={Paper} className={classes.table}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>Rep confirmed</TableCell>
                <TableCell>Confirmed</TableCell>
                <TableCell>Ready for payment</TableCell>
                <TableCell>Profit paid</TableCell>
                <TableCell>Invoice sent</TableCell>
                <TableCell>Invoice paid</TableCell>
                <TableCell>PCO Status</TableCell>
                <TableCell>PCO Invoice sent</TableCell>
                <TableCell>PCO Invoice paid</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow>
                <TableCell className={classes.capitalize}>
                  {opData.status ? opData.status : "awaiting campaign end"}
                </TableCell>
                <TableCell>
                  {opData.rep_confirmed_on
                    ? format(new Date(opData.rep_confirmed_on), "M/d/y")
                    : ""}
                </TableCell>
                <TableCell>
                  {opData.profit_confirmed_on
                    ? format(new Date(opData.profit_confirmed_on), "M/d/y")
                    : ""}
                </TableCell>
                <TableCell>
                  {opData.ready_for_payment_on
                    ? format(new Date(opData.ready_for_payment_on), "M/d/y")
                    : ""}
                </TableCell>
                <TableCell>
                  {formatDateOnlyString(opData.profit_paid_on)}
                </TableCell>
                <TableCell>
                  {formatDateOnlyString(opData.invoice_sent_on)}
                </TableCell>
                <TableCell>
                  {formatDateOnlyString(opData.invoice_paid_on)}
                </TableCell>
                <TableCell className={classes.capitalize}>
                  {opData.post_closeout_status}
                </TableCell>
                <TableCell>
                  {formatDateOnlyString(opData.post_closeout_invoice_sent_on)}
                </TableCell>
                <TableCell>
                  {formatDateOnlyString(opData.post_closeout_invoice_paid_on)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {listenerFields.map(name => (
                <ChangeListener key={name} {...{ name, setTotals, opData }} />
              ))}

              <PaymentDetails opData={opData} />
              <ProfitWorksheet
                {...{ opData, disableEdit, formData: values, isProduct }}
              />

              <div className={classes.adjustmentsAndFees}>
                <CustomAdjustments disableEdit={disableEdit} />
                <ProfitAndCommissionAdditionalInfo data={opData} />
              </div>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={submitting}
              >
                save changes
              </Button>

              <TableContainer component={Paper} className={classes.table}>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Campaign Raised:</TableCell>
                      <TableCell colSpan={2}>{opData.campaignTotal}</TableCell>
                    </TableRow>

                    {isProduct && (
                      <Fragment>
                        <TableRow>
                          <TableCell>Items Qty Total:</TableCell>
                          <TableCell colSpan={2}>
                            {formatSum(opData.itemsQtyTotal)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Online Item Total:</TableCell>
                          <TableCell colSpan={2}>
                            {formatMoney(opData.onlineTtl)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Offline Item Total:</TableCell>
                          <TableCell colSpan={2}>
                            {formatMoney(opData.offlineTtl)}
                          </TableCell>
                        </TableRow>
                        {opData.donationTotal > 0 && (
                          <TableRow>
                            <TableCell>Donation Total:</TableCell>
                            <TableCell colSpan={2}>
                              {formatMoney(opData.donationTotal)}
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    )}
                    <TableRow>
                      <TableCell>Total Profit:</TableCell>
                      <TableCell colSpan={2}>{totals.totalProfit}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Credit:</TableCell>
                      <TableCell colSpan={2}>{totals.totalCredit}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total Debit:</TableCell>
                      <TableCell colSpan={2}>{totals.totalDebit}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell className={classes.grandTtl}>
                        {totals.isInvoice
                          ? "Total balance owed"
                          : "Total profit due to school"}
                        :
                      </TableCell>
                      <TableCell className={classes.grandTtl}>
                        {totals.grandTotal}
                      </TableCell>
                      <TableCell className={classes.copyTtl}>
                        <CopyToClipboard text={totals.rawGrandTotal}>
                          <IconButton size="small">
                            <FileCopyOutlinedIcon color="primary" />
                          </IconButton>
                        </CopyToClipboard>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </form>
          );
        }}
      />

      {isCompanyOrCompanyAdmin && disableEdit && (
        <Button
          variant="contained"
          className={classes.actionButton}
          onClick={handleEditOverride}
        >
          Edit Figures
        </Button>
      )}

      <div className={classes.actionContainer}>
        {canManage &&
          (isPendingConfirmation ||
            (isCompanyOrCompanyAdmin && isRepConfirmed)) && (
            <Button
              variant="contained"
              disabled={actionTriggered}
              className={classes.actionButton}
              onClick={() =>
                handleAction(
                  isRep
                    ? orgProfitStatuses.REP_CONFIRMED
                    : orgProfitStatuses.PROFIT_CONFIRMED,
                )
              }
            >
              approve profit
            </Button>
          )}

        {isCompanyOrCompanyAdmin && !totals.isInvoice && isProfitConfirmed && (
          <Fragment>
            <Button
              variant="contained"
              disabled={actionTriggered}
              className={classes.actionButton}
              onClick={() => handleAction(orgProfitStatuses.READY_FOR_PAYMENT)}
            >
              ready for payment
            </Button>
            <div className={classes.actionOr}>OR</div>
          </Fragment>
        )}

        {isCompanyOrCompanyAdmin &&
          (isReadyForPayment || isProfitConfirmed || isInvoiceSent) && (
            <Fragment>
              <MuiTextField
                type="date"
                value={actionDate}
                onChange={e => setActionDate(e.target.value)}
                label={
                  isInvoiceSent
                    ? "INVOICE PAID ON"
                    : totals.isInvoice
                    ? "INVOICE SENT ON"
                    : "PROFIT PAID ON"
                }
                InputLabelProps={{ shrink: true }}
                className={classes.paidOn}
              />
              <Button
                variant="contained"
                disabled={!Boolean(actionDate) || actionTriggered}
                className={classes.actionButton}
                onClick={() =>
                  handleAction(
                    isInvoiceSent
                      ? orgProfitStatuses.INVOICE_PAID
                      : totals.isInvoice
                      ? orgProfitStatuses.INVOICE_SENT
                      : orgProfitStatuses.PROFIT_PAID,
                  )
                }
              >
                confirm
              </Button>
            </Fragment>
          )}
      </div>

      <PostCloseout
        opData={opData}
        isProduct={isProduct}
        fetchOrgProfitData={fetchOrgProfitData}
      />

      {showEmailModal && (
        <PaidEmailNotificationModal
          setShowEmailModal={setShowEmailModal}
          campaignId={campaign_id}
          emailTemplate={paidEmailTemplate}
          status={opData.status}
        />
      )}
    </div>
  );
}

function getTodaysDate() {
  return new Date().toISOString().substring(0, 10);
}
