import React, { useState } from "react";
import { format } from "date-fns";
import { Button } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import jsonExport from "jsonexport/dist";
import { useNotify, downloadCSV } from "react-admin";
import { APIUtilClient, generateAuthHeader } from "../../../lib";

export function ProductManualTransactionsDownload({
  id: product_id,
  product_name,
}) {
  const notify = useNotify();
  const [downloading, setDownloading] = useState(false);

  const fetchReport = async e => {
    e.stopPropagation();
    setDownloading(true);

    const res = await APIUtilClient.get(
      `/stock_transactions/product_manual_transactions_export?product_id=${product_id}`,
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage, data } = res;
    if (error) {
      notify(errorMessage, "warning");
    } else {
      data.forEach(row => {
        row.Date = format(new Date(row.Date), "Pp");
      });
      jsonExport(data, (_err, csv) => {
        downloadCSV(
          csv,
          `${product_name} #${product_id} - Manual Adjustments Inventory Report`,
        );
      });
    }

    setTimeout(() => setDownloading(false), 3000);
  };

  return (
    <Button
      onClick={fetchReport}
      variant="outlined"
      color="primary"
      startIcon={<DownloadIcon />}
      size="small"
      disabled={downloading}
    >
      manual adjustments
    </Button>
  );
}
