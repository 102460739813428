import React, { Fragment } from "react";
import {
  Edit,
  TextInput,
  SimpleForm,
  required,
  BooleanInput,
  regex,
  SelectInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
  Labeled,
  BooleanField,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";
import {
  daysOfWeek,
  englishMonthOptions,
  hebrewMonthOptions,
  TWO_DECIMAL_REGEX,
} from "../../lib";
import { styles } from "./DonationGroup.styles";

const requiredInput = [required()];
const validMoney = regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only");
const requiredMoneyInput = [validMoney, required()];

export function EditItem({ donation_group_id, onSuccess, activeItemId }) {
  const basePath = `/donation_groups/${donation_group_id}/show`;
  const classes = styles();

  const transformDataForEdit = values => {
    const { id: _rm, avatar: _rm2, ...rest } = values;
    return rest;
  };

  return (
    <Edit
      id={activeItemId}
      resource="donation_group_items"
      basePath={basePath}
      title=" "
      onSuccess={() => onSuccess("Item updated successfully")}
      undoable={false}
      transform={transformDataForEdit}
    >
      <SimpleForm>
        <SelectInput
          source="tier"
          choices={[
            { id: "1", name: "1" },
            { id: "2", name: "2" },
            { id: "3", name: "3" },
          ]}
          validate={requiredInput}
          translateChoice={false}
        />
        <TextInput source="label" validate={requiredInput} />
        <BooleanInput source="multi_use" label="Multi Use" />
        <BooleanInput
          source="one_time_use_per_volunteer"
          label="One Time Use Per Student"
        />
        <TextInput
          source="amount"
          type="number"
          validate={requiredMoneyInput}
        />
        <Labeled label="Current Avatar" formClassName={classes.imageSection}>
          <ImageField source="avatar" className={classes.image} />
        </Labeled>
        <ImageInput
          source="avatarBase64"
          label="Change Avatar"
          accept="image/*"
          multiple={false}
          formClassName={classes.imageSection}
        >
          <ImageField source="src" title="title" className={classes.image} />
        </ImageInput>
        <BooleanField
          source="is_day_picker"
          label="Display Day Picker"
          disabled
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { is_day_picker, calendar_type } = formData;
            if (!is_day_picker) return;
            const isHebrew = calendar_type === "Hebrew";
            return (
              <Fragment>
                <TextInput
                  source="calendar_type"
                  label="Calendar Type"
                  {...rest}
                  disabled
                />
                <div />
                <TextInput
                  source="day_picker_year"
                  label="Year"
                  {...rest}
                  disabled
                />
                <div />
                <SelectInput
                  disabled
                  {...rest}
                  source="day_picker_month"
                  label="Month"
                  choices={isHebrew ? hebrewMonthOptions : englishMonthOptions}
                  translateChoice={false}
                />
                <div />
                <BooleanInput
                  source="day_picker_display_english_and_hebrew"
                  label={`Also Display ${
                    isHebrew ? "English" : "Hebrew"
                  } Dates`}
                  {...rest}
                  className={classes.fitContent}
                />
                <div />
                <ArrayInput
                  source="donation_group_item_days"
                  label="Disable Specific Days"
                  fullWidth={false}
                >
                  <SimpleFormIterator
                    disableReordering
                    disableRemove
                    disableAdd
                    TransitionProps={{ enter: false }}
                  >
                    <FormDataConsumer>
                      {({ scopedFormData = {}, getSource, id }) => {
                        const day = scopedFormData.day || "";
                        const dayOfWeek =
                          daysOfWeek[scopedFormData.day_of_week] || "";
                        return (
                          <BooleanInput
                            key={id}
                            label={`Disable Day ${day} (${dayOfWeek})`}
                            source={getSource("disable")}
                          />
                        );
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}
