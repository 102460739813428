import { Fragment, createRef, useLayoutEffect, useState } from "react";
import { format } from "date-fns";
import { makeStyles, Checkbox, CircularProgress } from "@material-ui/core";
import {
  APIRes,
  GenericObject,
  ListProps,
  SubmissionQueryParams,
  ToastTypes,
} from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { APIAuthClient } from "../../../lib";
import { useDispatch } from "react-redux";
import { setToast } from "../../../state";
import { StickyNoteSVG } from "../../../assets";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps<SubmissionQueryParams>;
};
export function SubmissionRows({ listProps }: Props) {
  const { rows, params, setParams, nextPage, paginationProps } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();

  return (
    <Fragment>
      {isMobile && <div className={classes.resolveHeader}>Resolve</div>}
      <ListRowsWrapper>
        <ListRows minWidth={MIN_WIDTH}>
          {isDesktop && (
            <ListHeaderRowContainer>
              <div className={classes.row}>
                <SortableColumnHeader
                  columnName="Resolve"
                  fieldName="resolved"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Submission ID"
                  fieldName="id"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Date"
                  fieldName="created_at"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Name"
                  fieldName="name"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Email"
                  fieldName="email"
                  params={params}
                  setParams={setParams}
                />
                <div>Message</div>
              </div>
            </ListHeaderRowContainer>
          )}
          {rows.map((row, index) => {
            const lastRow = index === rows.length - 1;
            return <Row key={row.id as number} row={row} lastRow={lastRow} />;
          })}
        </ListRows>
        <ListPagination
          nextPage={nextPage}
          paginationProps={paginationProps}
          label="Submissions per page:"
          minWidth={MIN_WIDTH}
        />
      </ListRowsWrapper>
    </Fragment>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
};
function Row({ row, lastRow }: RowProps) {
  const { id, name, created_at, email, message, resolved } = row;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const ref = createRef<HTMLDivElement>();
  const [submitting, setSubmitting] = useState(false);
  const [showExpand, setShowExpand] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isResolved, setIsResolved] = useState<boolean>(Boolean(resolved));

  useLayoutEffect(() => {
    if (ref.current && ref.current?.clientHeight < ref.current?.scrollHeight) {
      setShowExpand(true);
    }
  }, [ref]);

  const onChange = async (_: any, resolved: boolean) => {
    setSubmitting(true);
    setIsResolved(resolved);
    const url = `/submissions/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, { resolved });
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      setIsResolved(!resolved);
      setSubmitting(false);
      return;
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    setTimeout(() => setSubmitting(false), 1000);
  };

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div className={classes.checkboxWrapper}>
              <Checkbox
                checked={isResolved}
                onChange={onChange}
                disabled={submitting}
              />
              {submitting && (
                <CircularProgress
                  className={classes.spinner}
                  size={30}
                  color="secondary"
                />
              )}
            </div>
            <div>{`#${id}`}</div>
            <div>{format(new Date(created_at as string), "M/d/y")}</div>
            <div>{name}</div>
            <div>
              <a href={`mailto:${email}`} className={classes.email}>
                {email}
              </a>
            </div>
            <div>{message}</div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.checkboxWrapper}>
              <Checkbox
                checked={isResolved}
                onChange={onChange}
                disabled={submitting}
              />
              {submitting && (
                <CircularProgress
                  className={classes.spinner}
                  size={30}
                  color="secondary"
                />
              )}
            </div>
            <div className={classes.mobileWrapper}>
              <div className={classes.mobileTop}>
                <div>{`#${id}`}</div>
                <div>{format(new Date(created_at as string), "M/d/y")}</div>
              </div>

              <div className={classes.name}>{name}</div>
              <div>
                <a href={`mailto:${email}`} className={classes.email}>
                  {email}
                </a>
              </div>
              <div className={classes.messageBox}>
                <div
                  className={classes.message}
                  ref={ref}
                  style={{
                    height: showMore ? "fit-content" : 20,
                    maxHeight: showMore ? "fit-content" : 20,
                    lineHeight: !showMore && showExpand ? "20px" : "16px",
                  }}
                >
                  <StickyNoteSVG
                    fontSize="small"
                    className={classes.noteIcon}
                  />
                  {message}
                  {showExpand && !showMore && (
                    <div className={classes.msgOverlay}></div>
                  )}
                </div>
                {showExpand && !showMore && (
                  <div className={classes.expand}>
                    <ExpandMoreIcon
                      color="primary"
                      onClick={() => setShowMore(true)}
                    />
                  </div>
                )}
                {showMore && (
                  <div className={classes.collapse}>
                    <ExpandLessIcon
                      color="primary"
                      onClick={() => setShowMore(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "100px 144px 136px 1fr 1fr 1.5fr",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 2px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  email: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  resolveHeader: {
    paddingBottom: 6,
    paddingLeft: 3,
    fontSize: 12,
    letterSpacing: 0.11,
    color: theme.palette.text.secondary,
  },

  checkboxWrapper: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      maxWidth: "fit-content",
    },
  },
  spinner: {
    position: "absolute",
    left: 6,
    top: 6,
    color: theme.palette.secondary2.main,
  },
  mobileWrapper: {
    flex: 1,
    paddingRight: 16,
  },
  mobileTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  messageBox: {
    marginTop: 16,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    fontSize: 12,
    letterSpacing: 0.09,
    color: theme.palette.text.secondary,
    padding: "10px 16px",
    paddingRight: 0,
    overflow: "hidden",
    position: "relative",
  },
  message: {
    overflow: "hidden",
    position: "relative",
    paddingRight: 16,
    display: "flex",
  },
  noteIcon: {
    marginRight: 16,
    height: 20,
    minHeigh: 20,
    width: 20,
    minWidth: 20,
    color: "#5E5E5E",
  },
  msgOverlay: {
    position: "absolute",
    height: 16,
    width: 56,
    right: 0,
    top: 0,
    background:
      "linear-gradient(180deg, rgba(245,245,245,0.3) 0%, #F5F5F5 86.67%)",
    zIndex: 9,
  },
  expand: {
    position: "absolute",
    right: 6,
    top: 8,
    zIndex: 99,
    cursor: "pointer",
    backgroundColor: "#F5F5F5",
  },
  collapse: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    paddingRight: 6,
    marginBottom: -4,
  },
}));
