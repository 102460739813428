import { Fragment } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

type Props = {
  show: boolean;
};

export function CircularLoader({ show }: Props) {
  const classes = styles();

  return (
    <Fragment>
      {show && (
        <div className={classes.container}>
          <CircularProgress color="primary" size={50} />
        </div>
      )}
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
