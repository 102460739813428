import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, getVolunteerLabel, setToast } from "../../../../state";
import { ConfirmationDialog } from "../../../../components";
import { APIRes, ListProps, ToastTypes } from "../../../../types";
import { APIAuthClient } from "../../../../lib";
import { useIsMobile } from "../../../../hooks/ui";
enum ActionOptions {
  Activate = "activate",
  EnableWP = "enable_webpage",
  DisableWP = "disable_webpage",
}

type Props = {
  onClose: () => void;
  listProps: ListProps<any>;
  className: string;
};
export function BulkActionUpdates(props: Props) {
  const {
    onClose,
    listProps: {
      selectedRowCount,
      selectedRowIds,
      refreshList,
      setMobileBulkSelectHeaderProps,
      toggleMobileSelectRowsMode,
    },
    className,
  } = props;
  const dispatch = useDispatch();
  const campaign_id = useSelector(getCampaignId);
  const isMobile = useIsMobile();
  const confirmationActionRef = useRef<ActionOptions | null>(null);
  const [action, setAction] = useState<ActionOptions | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationTitle, setConfirmationTitle] = useState("");
  const [confirmationSubtitle, setConfirmationSubtitle] = useState("");
  const [confirmationActionLabel, setConfirmationActionLabel] = useState("");
  const { volunteerLabelLC, volunteerLabelLCSingular } = useSelector(
    getVolunteerLabel,
  );

  useEffect(() => {
    const suffix = selectedRowCount > 1 ? "s" : "";
    let mobileBtnLabel = "";
    switch (action) {
      case "activate":
        setConfirmationTitle(
          `Are you sure you want to activate ${selectedRowCount} ${volunteerLabelLCSingular} account${suffix}?`,
        );
        setConfirmationSubtitle("");
        setConfirmationActionLabel("Activate");
        mobileBtnLabel = "Activate accounts";
        break;
      case "enable_webpage":
        setConfirmationTitle(
          `Are you sure you want to enable ${selectedRowCount} ${volunteerLabelLC}' webpage${suffix}?`,
        );
        setConfirmationSubtitle("");
        setConfirmationActionLabel("Enable");
        mobileBtnLabel = "Enable webpages";
        break;
      case "disable_webpage":
        setConfirmationTitle(
          `Are you sure you want to disable ${selectedRowCount} ${volunteerLabelLC}' webpage${suffix}?`,
        );
        setConfirmationSubtitle(
          `The ${volunteerLabelLC}' links will not be accessible online.`,
        );
        setConfirmationActionLabel("Disable");
        mobileBtnLabel = "Disable webpages";
        break;
      default:
        setConfirmationTitle("");
        setConfirmationSubtitle("");
        setConfirmationActionLabel("");
        mobileBtnLabel = "";
    }
    if (action) {
      confirmationActionRef.current = action;
      setMobileBulkSelectHeaderProps({
        buttonLabel: mobileBtnLabel,
        callback: () => setShowConfirmation(true),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, selectedRowCount, volunteerLabelLC, volunteerLabelLCSingular]);

  const onClick = (_action: ActionOptions) => {
    setAction(_action);
    if (isMobile) {
      toggleMobileSelectRowsMode(true);
    } else {
      setShowConfirmation(true);
    }
    onClose();
  };

  const bulkActionUpdate = async (
    setShowSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
    onClose: () => void,
  ) => {
    setShowSubmitting(true);
    const url = "/volunteer_campaigns/bulk_action_updates";
    const body = {
      campaign_id,
      volunteer_ids: selectedRowIds,
      action: confirmationActionRef.current,
    };
    const res = await APIAuthClient.put<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return setShowSubmitting(false);
    }

    const count = selectedRowIds.length;
    let toast = `${count} ${volunteerLabelLCSingular}`;
    const suffix = count > 1 ? "s" : "";
    switch (confirmationActionRef.current) {
      case ActionOptions.Activate:
        toast = `${toast} account${suffix} activated`;
        break;
      case ActionOptions.EnableWP:
        toast = `${toast} webpage${suffix} enabled`;
        break;
      case ActionOptions.DisableWP:
        toast = `${toast} webpage${suffix} disabled`;
        break;
    }
    dispatch(setToast(toast, ToastTypes.success));
    refreshList();
    onClose();
  };

  return (
    <Fragment>
      <div
        className={className}
        onClick={() => onClick(ActionOptions.Activate)}
      >
        Activate accounts
      </div>
      <div
        className={className}
        onClick={() => onClick(ActionOptions.EnableWP)}
      >
        Enable webpages
      </div>

      <div
        className={className}
        onClick={() => onClick(ActionOptions.DisableWP)}
      >
        Disable webpages
      </div>

      {showConfirmation && (
        <ConfirmationDialog
          onClose={() => setShowConfirmation(false)}
          title={confirmationTitle}
          subtitle={confirmationSubtitle}
          actionLabel={confirmationActionLabel}
          actionFunc={bulkActionUpdate}
          redButton={action === ActionOptions.DisableWP}
        />
      )}
    </Fragment>
  );
}
