import { Button, makeStyles } from "@material-ui/core";
import { GenericObject } from "../../../../types";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { getIsProductCampaign, getVolunteerLabel } from "../../../../state";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { RootState } from "../../../../types/state";
import { formatMoneyUnPadded, formatNumber } from "../../../../lib";
import { useIsMobile } from "../../../../hooks/ui";

type Props = {
  data: GenericObject;
  rowData: GenericObject;
};
export function VolunteerView({ data, rowData }: Props) {
  const {
    id,
    slug,
    auto_generated_email,
    class_name,
    email,
    phone,
    activated,
    webpage_enabled,
    contacts_confirmed,
    goal,
    supporters,
    family_members,
  } = data;
  const { itemsSold, raised, invalidContacts, validContacts } = rowData;

  const classes = styles();
  const isMobile = useIsMobile();
  const { volunteerLabelSing } = useSelector(getVolunteerLabel);
  const isProduct = useSelector(getIsProductCampaign);
  const {
    show_volunteer_item_progress: itemBased,
    show_profit_based_progress: profitBased,
    allow_family_accounts,
    webpageLink,
  } = useSelector(({ campaign }: RootState) => campaign);
  const { raisedPercent, progressPercent } = getPercents(
    goal,
    itemBased ? itemsSold : raised,
  );

  return (
    <Fragment>
      {/* VOLUNTEER DETAILS */}
      <div className={classes.subheader}>{volunteerLabelSing} details</div>
      <div className={classes.detailColumns}>
        <div
          className={classNames(classes.detailColumn, classes.leftDetailCol)}
        >
          <div className={classes.detail}>
            <div className={classes.leftColLabel}>{volunteerLabelSing} ID</div>
            {id}
          </div>
          <div className={classes.detail}>
            <div className={classes.leftColLabel}>Unique URL</div>
            {slug}
          </div>
          <div className={classes.detail}>
            <div className={classes.leftColLabel}>Class</div>
            {class_name}
          </div>
        </div>
        <div className={classes.detailColumn}>
          <div className={classes.detail}>
            <div className={classes.rightColLabel}>Email</div>
            {auto_generated_email ? "SYSTEM GENERATED" : email}
          </div>
          <div className={classes.detail}>
            <div className={classes.rightColLabel}>Phone</div>
            {phone}
          </div>
        </div>
      </div>

      <div className={classes.separator} />
      {/* WEBPAGE STATUS */}
      <div className={classes.subheader}>Webpage status</div>
      <div className={classes.pageStatusWrapper}>
        <YesNo isYes={Boolean(webpage_enabled)} />
        <div className={classes.pageStatus}>
          {webpage_enabled && "Enabled"}
          {!webpage_enabled && "Disabled - Page link is not accessible online."}
        </div>
      </div>

      <div className={classes.separator} />
      {/* VOLUNTEER PROGRESS */}
      <div className={classes.progAndLink}>
        <div className={classes.subheader}>{volunteerLabelSing} progress</div>
        <Button
          variant="text"
          className={classes.link}
          color="primary"
          endIcon={<OpenInNewIcon />}
          size="small"
          href={`${webpageLink}/${slug}`}
          target="_blank"
        >
          {volunteerLabelSing} page
        </Button>
      </div>

      {/* for now if it's profit based we are hiding this component */}
      {!profitBased && (
        <Fragment>
          <div className={classes.progressBar}>
            <div
              className={classes.progress}
              style={{ width: progressPercent }}
            />
          </div>
          {!isMobile && (
            <div className={classes.progressStats}>
              <div className={classes.leftProgressStats}>
                <div className={classes.raised}>
                  {itemBased
                    ? formatNumber(itemsSold)
                    : formatMoneyUnPadded(raised)}
                </div>
                <div>{itemBased ? "Items sold" : "Raised"} </div>
                <div className={classes.dot}>•</div>
                {raisedPercent}% of goal
              </div>
              <div>
                <span className={classes.goal}>
                  {itemBased ? formatNumber(goal) : formatMoneyUnPadded(goal)}
                </span>
                Goal
              </div>
            </div>
          )}
          {isMobile && (
            <div className={classes.progressStats}>
              <div>
                <div className={classes.raised}>
                  {itemBased
                    ? formatNumber(itemsSold)
                    : formatMoneyUnPadded(raised)}
                </div>
                <div>{itemBased ? "Items sold" : "Raised"} </div>
              </div>

              <div>
                <div className={classes.goal}>{raisedPercent}%</div>
                <div>Of goal</div>
              </div>

              <div>
                <div className={classes.goal}>
                  {itemBased ? formatNumber(goal) : formatMoneyUnPadded(goal)}
                </div>
                <div>Goal</div>
              </div>
            </div>
          )}
        </Fragment>
      )}

      {/* STATS */}
      <div className={classes.statCardRow}>
        <div className={classes.statCard}>
          <YesNo isYes={Boolean(activated)} />
          <div className={classes.statLabel}>Account activated</div>
        </div>
        <div className={classes.statCard}>
          <YesNo isYes={Boolean(contacts_confirmed)} />
          <div className={classes.statLabel}>Contacts confirmed</div>
        </div>
      </div>

      <div className={classes.statCardRow}>
        <div className={classes.statCard}>
          <div className={classes.statNum}>{formatNumber(validContacts)}</div>
          <div className={classes.statLabel}>Valid contact</div>
        </div>
        <div className={classes.statCard}>
          <div className={classes.statNum}>{formatNumber(supporters)}</div>
          <div className={classes.statLabel}>Supporters</div>
        </div>
      </div>

      <div className={classes.statCardRow}>
        <div className={classes.statCard}>
          <div className={classes.statNum}>{formatNumber(invalidContacts)}</div>
          <div className={classes.statLabel}>Invalid contacts</div>
        </div>
        {isProduct && (
          <div className={classes.statCard}>
            <div className={classes.statNum}>{formatNumber(itemsSold)}</div>
            <div className={classes.statLabel}>Items sold</div>
          </div>
        )}
      </div>

      {/* FAMILY MEMBERS */}
      {allow_family_accounts &&
        Array.isArray(family_members) &&
        family_members.length && (
          <Fragment>
            <div className={classes.separator} />
            <div className={classes.fmSpacer} />
            <div className={classes.subheader}>Family members</div>
            <div className={classes.fmChips}>
              {family_members.map((fm, index) => {
                const { first_name, last_name, class_name } = fm;
                return (
                  <div key={index} className={classes.fmChip}>
                    {first_name} {last_name}
                    {class_name ? `, ${class_name}` : ""}
                  </div>
                );
              })}
            </div>
          </Fragment>
        )}
      <div className={classes.bottom} />
    </Fragment>
  );
}

const YesNo = ({ isYes }: { isYes?: boolean }) => {
  const classes = styles();
  return (
    <div className={classNames(classes.yesNo, isYes && classes.yes)}>
      {isYes ? <CheckIcon fontSize="small" /> : <CloseIcon fontSize="small" />}
    </div>
  );
};

function getPercents(goal: any, raised: any) {
  if (!goal || !raised) return { raisedPercent: 0, progressPercent: `0%` };
  const raisedPercent = Math.round((raised / goal) * 100);
  const progressPercent = raisedPercent > 100 ? 100 : raisedPercent;
  return {
    raisedPercent: formatNumber(raisedPercent),
    progressPercent: `${progressPercent}%`,
  };
}

const styles = makeStyles(theme => ({
  subheader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    color: theme.palette.primary.main,
    lineHeight: "22px",
  },
  separator: {
    [theme.breakpoints.down("sm")]: {
      border: "1px solid #EAEBF3",
      margin: "24px -16px 24px -16px",
    },
  },
  detailColumns: {
    display: "flex",
    marginTop: 16,
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 0,
    },
  },
  detailColumn: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  leftDetailCol: {
    paddingRight: 16,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
    },
  },
  leftColLabel: {
    width: 100,
    minWidth: 100,
    fontWeight: 500,
    letterSpacing: 0.3,
  },
  rightColLabel: {
    width: 60,
    minWidth: 60,
    fontWeight: 500,
    letterSpacing: 0.3,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      minWidth: 100,
    },
  },
  detail: {
    display: "flex",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "18px",
    marginBottom: 8,
    overflowWrap: "anywhere",
  },
  pageStatusWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 40,
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  pageStatus: {
    marginLeft: 16,
    fontSize: 14,
    letterSpacing: 0.13,
  },
  progAndLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  link: {
    textTransform: "uppercase",
  },
  progressBar: {
    height: 16,
    width: "100%",
    borderRadius: 20,
    backgroundColor: "#F5F5F5",
    marginBottom: 16,
    marginTop: 20,
  },
  progress: {
    height: "100%",
    backgroundColor: theme.palette.secondary2.main,
    borderRadius: 20,
  },
  progressStats: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 16,
    letterSpacing: 0.2,
    color: theme.palette.text.secondary,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.15,
      color: theme.palette.text.primary,
      marginBottom: 24,
    },
  },
  leftProgressStats: {
    display: "flex",
    alignItems: "center",
  },
  raised: {
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.2,
    color: theme.palette.secondary2.main,
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      fontSize: 14,
      letterSpacing: 0.17,
      marginBottom: 8,
    },
  },
  dot: {
    padding: "0 8px",
  },
  goal: {
    fontWeight: "bold",
    color: theme.palette.text.primary,
    paddingRight: 8,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.17,
      marginBottom: 8,
    },
  },
  statCardRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: 0,
    },
  },
  statCard: {
    width: "calc(50% - 12px)",
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    height: 44,
    display: "flex",
    alignItems: "center",
    paddingLeft: 12,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: 16,
    },
  },
  statLabel: {
    marginLeft: 16,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.53,
  },
  statNum: {
    color: theme.palette.secondary2.main,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
  },
  fmSpacer: {
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  fmChips: {
    display: "flex",
    flexWrap: "wrap",
  },
  fmChip: {
    display: "flex",
    alignItems: "center",
    minHeight: 32,
    borderRadius: 16,
    backgroundColor: "#E0E0E0",
    padding: "8px 12px",
    fontSize: 13,
    letterSpacing: 0.15,
    marginTop: 16,
    marginRight: 8,
    overflowWrap: "anywhere",
  },
  yesNo: {
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    backgroundColor: "#FDE9E9",
    color: theme.palette.error.main,
  },
  yes: {
    backgroundColor: "#EFFCF0",
    color: " #1AC846",
  },
  bottom: {
    height: 24,
  },
}));
