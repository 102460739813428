import React, { useState, useEffect } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  IconButton,
  makeStyles,
  TableHead,
  TableFooter,
} from "@material-ui/core";
import { TextField } from "final-form-material-ui";
import { Form } from "react-final-form";
import RemoveIcon from "@material-ui/icons/Delete";
import { useNotify } from "react-admin";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import {
  APIClient,
  generateAuthHeader,
  required,
  twoDecimalsOnly,
} from "../../../lib";

export function MissingVariants({ id, missingVariants, resetVariantData }) {
  const classes = styles();
  const notify = useNotify();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    setInitialValues({ variants: missingVariants });
  }, [missingVariants]);

  const onSubmit = async ({ variants }, form) => {
    const res = await APIClient.post(
      `/configurable_product_options/product/${id}/create_selected_missing_variants`,
      { variants },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    notify("Variant Products created successfully!");
    setTimeout(() => form.reset(), 10);
    resetVariantData();
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <TableContainer
              component={Paper}
              className={classes.table}
              elevation={6}
            >
              <FieldArray name="variants">
                {({ fields }) => (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Variant</TableCell>
                        <TableCell>Product Name</TableCell>
                        <TableCell>Sku</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fields.map((row, index) => {
                        const { variant } = fields.value[index];
                        return (
                          <TableRow key={index}>
                            <TableCell>{variant}</TableCell>
                            <TableCell>
                              <Field
                                component={TextField}
                                name={`${row}.product_name`}
                                label="Name"
                                variant="standard"
                                required
                                validate={required}
                                className={classes.nameInput}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                component={TextField}
                                name={`${row}.sku`}
                                label="Sku"
                                variant="standard"
                                required
                                validate={required}
                                className={classes.skuInput}
                              />
                            </TableCell>
                            <TableCell>
                              <Field
                                component={TextField}
                                name={`${row}.product_price`}
                                label="Price"
                                variant="standard"
                                required
                                validate={twoDecimalsOnly}
                                type="number"
                                className={classes.priceInput}
                              />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => fields.remove(index)}
                                size="small"
                              >
                                <RemoveIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {missingVariants.length < 1 && (
                        <TableRow>
                          <TableCell colSpan={5} align="center">
                            There are no missing Variants
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {missingVariants.length > 0 && (
                      <TableFooter>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Button
                              type="submit"
                              color="primary"
                              size="small"
                              variant="contained"
                              disabled={submitting}
                            >
                              create variant products
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                )}
              </FieldArray>
            </TableContainer>
          </form>
        );
      }}
    />
  );
}

const styles = makeStyles(_theme => ({
  table: { marginBottom: 36, overflowX: "visible" },
  nameInput: { minWidth: 300, width: "100%" },
  skuInput: { minWidth: 250, width: "100%" },
  priceInput: { width: 80 },
}));
