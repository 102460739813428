// cSpell:ignore Overridable
import { useHistory } from "react-router-dom";
import { makeStyles, SvgIconTypeMap } from "@material-ui/core";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ContactsOutlinedIcon from "@material-ui/icons/ContactsOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import StudentsIcon from "@material-ui/icons/PeopleOutlined";
import { paths } from "../types";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { useSelector } from "react-redux";
import { getIsProductCampaign, getVolunteerLabel } from "../state";
import classNames from "classnames";
import { getIsOrgOrEmp, getIsVolunteer } from "../../customState";

export function BottomNav() {
  const classes = styles();
  const isProduct = useSelector(getIsProductCampaign);
  const isOrg = useSelector(getIsOrgOrEmp);
  const isVolunteer = useSelector(getIsVolunteer);
  const { volunteerLabel } = useSelector(getVolunteerLabel);
  const {
    location: { pathname },
  } = useHistory();

  return (
    <div className={classes.container}>
      <NavButton
        Icon={HomeOutlinedIcon}
        link={paths.HOME}
        selected={pathname === paths.HOME}
        label="Home"
      />
      <NavButton
        Icon={MonetizationOnOutlinedIcon}
        link={paths.DONATIONS}
        selected={pathname === paths.DONATIONS}
        label={isProduct ? "Sales" : "Donations"}
      />
      {isVolunteer && (
        <NavButton
          Icon={ContactsOutlinedIcon}
          link={paths.CONTACTS}
          selected={pathname === paths.CONTACTS}
          label="Contacts"
        />
      )}
      {isOrg && (
        <NavButton
          Icon={StudentsIcon}
          link={paths.VOLUNTEERS}
          selected={pathname === paths.VOLUNTEERS}
          label={volunteerLabel}
        />
      )}
      <NavButton
        Icon={MoreHorizIcon}
        link={paths.MOBILE_MENU}
        selected={pathname === paths.MOBILE_MENU}
        label="More"
      />
    </div>
  );
}

type NavButtonProps = {
  Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  link: string;
  selected: boolean;
  label: string;
};
function NavButton({ Icon, link, selected, label }: NavButtonProps) {
  const classes = styles();
  const history = useHistory();
  return (
    <div className={classes.navWrapper} onClick={() => history.push(link)}>
      <Icon
        className={classNames(classes.icon, selected && classes.selected)}
      />
      <div className={classNames(classes.label, selected && classes.selected)}>
        {label}
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    height: 56,
    width: "100%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    position: "sticky",
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    borderTop: "1px solid #DBDEEE",
  },
  navWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "fit-content",
  },
  label: {
    fontSize: 12,
    letterSpacing: 0.13,
    paddingTop: 2,
    color: theme.palette.text.secondary2,
  },
  icon: {
    color: theme.palette.text.secondary2,
  },
  selected: {
    color: `${theme.palette.primary.main} !important`,
  },
}));
