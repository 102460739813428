import { Button, makeStyles, TextField } from "@material-ui/core";
import debounce from "lodash.debounce";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useState, useCallback, useRef } from "react";
import { TeamQueryParams, TeamFilterFields } from "../../../types";
import classNames from "classnames";
import { useIsMobile } from "../../../hooks/ui";

type Props = {
  setParams: React.Dispatch<React.SetStateAction<TeamQueryParams>>;
  showSearch: boolean;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
};
export function Search({ setParams, showSearch, setShowSearch }: Props) {
  const classes = styles();
  const valueRef = useRef("");
  const isMobile = useIsMobile();
  const [search, setSearch] = useState("");

  const updateFilters = () => {
    setParams(prev => {
      const { [TeamFilterFields.q]: _rm, ...rest } = prev;
      if (!valueRef.current) {
        return { ...rest, page: 0 };
      } else {
        return { ...rest, page: 0, [TeamFilterFields.q]: valueRef.current };
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(debounce(updateFilters, 500), []);

  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    valueRef.current = target.value;
    debouncedHandleSearch();
  };

  const onClear = () => {
    setSearch("");
    valueRef.current = "";
    updateFilters();
  };

  if (!showSearch && isMobile) return <></>;
  return (
    <div className={classes.wrapper}>
      <TextField
        placeholder="Search for a team"
        value={search}
        size="small"
        onChange={onSearchChange}
        className={classes.input}
        InputProps={{
          endAdornment: Boolean(search) ? (
            <CloseIcon
              fontSize="small"
              className={classNames(classes.icon, classes.pointer)}
              onClick={onClear}
            />
          ) : (
            <SearchIcon fontSize="small" className={classes.icon} />
          ),
        }}
      />
      {isMobile && (
        <Button
          color="primary"
          variant="text"
          size="small"
          className={classes.closeSearch}
          startIcon={<CloseIcon />}
          onClick={() => setShowSearch(false)}
        >
          CLOSE
        </Button>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 86px)",
    },
    [theme.breakpoints.only("md")]: {
      width: 300,
    },
    backgroundColor: "#EAEBF3",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  icon: {
    color: theme.palette.text.secondary2,
  },
  pointer: {
    cursor: "pointer",
  },
  closeSearch: {
    marginLeft: 8,
    width: 78,
    borderRadius: 4,
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
  },
}));
