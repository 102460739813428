import React, { useState, useEffect } from "react";
import {
  TextInput,
  List,
  Datagrid,
  TextField,
  Create,
  required,
  TopToolbar,
  NumberInput,
  SimpleForm,
  Filter,
  Notification,
  useNotify,
  useListContext,
} from "react-admin";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { CompanyPrizeProgramTierDetails } from "./CompanyPrizeProgramTierDetails";
import { APIClient, generateAuthHeader } from "../../lib";

const TierFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Tier Name" source="q" alwaysOn />
    </Filter>
  );
};

export function CompanyPrizeProgramTiers({ record }) {
  const { id: company_prize_program_id, supplier_id } = record;
  const basePath = `/company_prize_programs/${company_prize_program_id}/show/tiers`;
  const [showCreate, setShowCreate] = useState(false);
  const [prizeProducts, setPrizeProducts] = useState([]);
  const notify = useNotify();

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/supplier_company_prize_products/company_products_autocomplete?supplier_id=${supplier_id}`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setPrizeProducts(data);
    };
    if (supplier_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setShowCreate(true)}
      >
        add tier
      </Button>
    </TopToolbar>
  );

  return (
    <List
      resource="company_prize_program_tiers"
      basePath={basePath}
      perPage={25}
      title=" "
      bulkActionButtons={false}
      exporter={false}
      empty={false}
      filter={{ company_prize_program_id }}
      sort={{ field: "tier_order_number", order: "ASC" }}
      filters={<TierFilter />}
      actions={<Actions />}
      component="div"
    >
      <GridWrapper
        basePath={basePath}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        prizeProducts={prizeProducts}
        prizeProgramRecord={record}
      />
    </List>
  );
}

const GridWrapper = ({
  basePath,
  showCreate,
  setShowCreate,
  prizeProducts,
  prizeProgramRecord,
  ...props
}) => {
  const [showTierDetails, setShowTierDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  return (
    <div>
      <Datagrid
        {...props}
        rowClick={(id, basePath, record) => {
          setSelectedRow({ id, basePath, record, prizeProgramRecord });
          setShowTierDetails(true);
        }}
      >
        <TextField source="id" />
        <TextField source="tier_name" label="Tier Name" />
        <TextField source="tier_order_number" label="Order Number" />
        <TextField source="rules" label="Rules" sortable={false} />
      </Datagrid>
      <AddTier
        basePath={basePath}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        prizeProgramRecord={prizeProgramRecord}
      />
      <CompanyPrizeProgramTierDetails
        selectedRow={selectedRow}
        showTierDetails={showTierDetails}
        setShowTierDetails={setShowTierDetails}
        prizeProducts={prizeProducts}
      />
    </div>
  );
};

function AddTier({ showCreate, setShowCreate, basePath, prizeProgramRecord }) {
  const {
    id: company_prize_program_id,
    allow_student_picks,
  } = prizeProgramRecord;
  const classes = styles();
  const requiredField = [required()];
  const notify = useNotify();
  const { refetch } = useListContext();

  const transformDataForSubmit = values => {
    return { company_prize_program_id, ...values };
  };

  return (
    <Drawer open={showCreate} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Add a Tier</div>
          <IconButton onClick={() => setShowCreate(false)} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Create
          resource="company_prize_program_tiers"
          basePath={basePath}
          onSuccess={() => {
            refetch();
            notify("Tier added successfully");
            setShowCreate(false);
          }}
          transform={transformDataForSubmit}
        >
          <SimpleForm>
            <TextInput
              source="tier_name"
              label="Tier Name"
              validate={requiredField}
            />
            <NumberInput
              source="tier_order_number"
              validate={requiredField}
              label="Tier Order Number"
            />
            {allow_student_picks && (
              <TextInput
                fullWidth
                source="prize_pick_message"
                label="Tier Message For Student Prize Picking"
              />
            )}
          </SimpleForm>
        </Create>
      </div>
      <Notification />
    </Drawer>
  );
}

const styles = makeStyles(theme => ({
  drawer: {
    minWidth: 400,
    maxWidth: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
}));
