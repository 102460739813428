import React from "react";
import { Datagrid, List, TextField, Filter, TextInput } from "react-admin";

const CompanyEmployeeFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Employees" source="q" alwaysOn />
    </Filter>
  );
};

export default function CompanyEmployeeList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Employees"
      bulkActionButtons={false}
      filters={<CompanyEmployeeFilter />}
    >
      <Datagrid rowClick={"edit"}>
        <TextField source="id" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <TextField source="email" />
        <TextField source="role" />
      </Datagrid>
    </List>
  );
}
