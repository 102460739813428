import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  makeStyles,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import {
  APIUtilClient,
  generateAuthHeader,
  getUserTimezone,
} from "../../../lib";

export function STHShipstationDownload({
  start_date,
  end_date,
  campaign_id,
  asMenuItem,
  callback,
}) {
  const classes = styles();
  const timezone = getUserTimezone();
  const [downloading, setDownloading] = useState(false);

  const download = async () => {
    setDownloading(true);
    const url = `/reports/sth-shipstation-report?timezone=${timezone}&start_date=${
      start_date ? start_date : ""
    }&end_date=${end_date ? end_date : ""}&campaign_id=${
      campaign_id ? campaign_id : ""
    }`;
    const res = await APIUtilClient.get(url, { headers: generateAuthHeader() });
    const { error, data, errorMessage } = res;
    if (error) {
      alert(
        errorMessage ||
          "There was an error downloading your report, please try again later.",
      );
    } else {
      jsonExport(data, (err, csv) => {
        downloadCSV(csv, "STH ShipStation Report");
      });
    }

    setTimeout(() => {
      if (callback) callback();
      setDownloading(false);
    }, 2000);
  };

  if (asMenuItem) {
    return (
      <MenuItem onClick={download} disabled={downloading}>
        <ListItemIcon>
          <HomeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="STH ShipStation" />
      </MenuItem>
    );
  }
  return (
    <Button
      color="primary"
      size="small"
      disabled={downloading}
      startIcon={<HomeIcon />}
      onClick={download}
    >
      STH ShipStation
      {downloading && (
        <CircularProgress
          color="primary"
          className={classes.spinner}
          size={24}
        />
      )}
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  spinner: { position: "absolute" },
}));
