import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDownOutlined";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsDesktop, useIsMobile, useScrollToTop } from "../../hooks/ui";
import {
  campaignVolunteerAutocomplete,
  getIsProductCampaign,
  getVolunteerLabel,
  orgVolunteerAutocomplete,
} from "../../state";
import {
  CampaignDonationFilterFields,
  CampaignDonationQueryParams,
  campaignDonationSearchFields,
  campaignDonationSortFields,
} from "../../types";
import { DonationRows } from "./components/DonationRows";
import {
  FilterChips,
  FilterDrawerButton,
  ListContainer,
  MobileListSort,
  MultiSearch,
} from "../../components";
import { RootState } from "../../types/state";
import { NoDonations } from "./components/NoDonations";
import { ActionsMenu } from "./components/ActionsMenu";
import { Filters } from "./components/Filters";
import { getIsOrgOrEmp } from "../../../customState";
import { useSetTopBarTitle } from "../../hooks/ui";
import { useList } from "../../hooks/list";
import { getUserTimezone } from "../../../lib";

export function Donations() {
  const classes = styles();
  const dispatch = useDispatch();
  const isProduct = useSelector(getIsProductCampaign);
  const isOrg = useSelector(getIsOrgOrEmp);
  const { volunteerLabelSing } = useSelector(getVolunteerLabel);
  const [isReady, setIsReady] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [anchorEl, setAnchorEl] = useState<undefined | Element>(undefined);
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  const { campaignId, campaign_type_id, org_id } = useSelector(
    (state: RootState) => state.campaign,
  );
  useScrollToTop();

  useEffect(() => {
    if (campaignId && campaign_type_id) setIsReady(true);
  }, [campaignId, campaign_type_id]);

  const listProps = useList<CampaignDonationQueryParams>({
    baseUrl: `/donations/campaigns/${campaignId}`,
    isReady,
    initialState: {
      sort: "id",
      order: "desc",
      timezone: getUserTimezone(),
    },
    filterFields: CampaignDonationFilterFields,
  });
  const {
    listIsFiltered,
    total,
    noResults,
    refreshList,
    filterDrawerActive,
    showRows,
  } = listProps;

  useSetTopBarTitle(
    <h1 className={classes.title}>{isProduct ? "Sales" : "Donations"}</h1>,
    [isProduct],
  );

  useEffect(() => {
    if (isOrg && campaignId && org_id) {
      dispatch(orgVolunteerAutocomplete());
      dispatch(campaignVolunteerAutocomplete());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrg, campaignId, org_id]);

  const searchFields = useMemo(
    () => campaignDonationSearchFields(isProduct, volunteerLabelSing),
    [isProduct, volunteerLabelSing],
  );

  const handleActionsClick = (e: any) => {
    setAnchorEl(e.currentTarget);
    setShowActions(true);
  };

  return (
    <ListContainer listProps={listProps}>
      {isMobile && (
        <div className={classes.topSection}>
          <div>
            <h1 className={classes.title}>
              {isProduct ? "Orders" : "Donations"}
            </h1>
            <div className={classes.donationCount}>
              {total} {isProduct ? "Order" : "Donation"}
              {total !== 1 ? "s" : ""}
            </div>
          </div>
          <div>
            <IconButton onClick={() => setShowSort(true)}>
              <SwapVertIcon />
            </IconButton>
            <span className={classes.IconSpacer} />
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
            <span className={classes.IconSpacer} />
            <IconButton onClick={() => setShowActions(true)}>
              <MoreHorizIcon />
            </IconButton>
          </div>
        </div>
      )}

      <div className={classes.searchAndActionsRow}>
        <MultiSearch listProps={listProps} fields={searchFields} />
        {isDeskTop && (
          <div>
            <FilterDrawerButton
              onClick={() => setShowFilter(true)}
              active={filterDrawerActive}
            />
            <Button
              color="secondary"
              endIcon={<ArrowDropDownIcon />}
              className={classes.actionsButton}
              onClick={handleActionsClick}
            >
              More actions
            </Button>
          </div>
        )}
      </div>

      <FilterChips listProps={listProps} />

      {noResults && <NoDonations listIsFiltered={listIsFiltered} />}

      {showRows && <DonationRows listProps={listProps} />}

      <ActionsMenu
        isOpen={showActions}
        onClose={() => setShowActions(false)}
        anchorEl={anchorEl}
        refreshData={refreshList}
      />
      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={campaignDonationSortFields(
            isProduct,
            volunteerLabelSing,
            isOrg,
          )}
        />
      )}
      {showFilter && (
        <Filters onClose={() => setShowFilter(false)} listProps={listProps} />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0 8px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  donationCount: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  IconSpacer: {
    marginRight: 8,
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  actionsButton: {
    width: 160,
    marginLeft: 16,
  },
}));
