import React from "react";
import { Datagrid, TextField, Filter, List, DateInput } from "react-admin";
import { useSelector } from "react-redux";

import { getUserTimezone, isCompanyOrCompanyAdmin } from "../../lib";
import { getActiveCampaignId, getSeasonId } from "../../customState";

const AffiliateFilter = props => (
  <Filter {...props}>
    <DateInput label="Start Date" source="start_date" alwaysOn />
    <DateInput label="End Date" source="end_date" alwaysOn />
  </Filter>
);

export function AffiliateList(props) {
  const season_id = useSelector(getSeasonId);
  const campaign_id = useSelector(getActiveCampaignId);
  const role = useSelector(state => state.user.role);
  const timezone = getUserTimezone();

  return (
    <List
      title="Performance"
      {...props}
      sort={{ field: "id", order: "ASC" }}
      filter={{ season_id, timezone, campaign_id }}
      bulkActionButtons={false}
      filters={<AffiliateFilter />}
      actions={false}
      empty={false}
      pagination={false}
    >
      <Datagrid>
        <TextField source="id" label="Tag ID" sortable={false} />
        <TextField source="tagName" label="Tag Name" sortable={false} />
        <TextField source="donationCount" label="Sale Count" sortable={false} />
        <TextField
          source="donationCountPercent"
          label="% of Ttl Count"
          sortable={false}
        />
        <TextField
          source="campaignCredited"
          label="Campaign Credited"
          sortable={false}
        />
        {isCompanyOrCompanyAdmin(role) && (
          <TextField source="transacted" label="Transacted" sortable={false} />
        )}
      </Datagrid>
    </List>
  );
}
