import React, { Fragment, useEffect, useState } from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  BooleanField,
  DateField,
  useNotify,
  FileField,
  FileInput,
  useRefresh,
  NumberInput,
} from "react-admin";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  CircularProgress,
  Button,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import { generateAuthHeader, APIClient } from "../../lib";

const DigitalContentFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search Code" source="q" alwaysOn />
      <NumberInput label="Order ID" source="donation_id" alwaysOn />
      <NumberInput label="Product ID" source="product_id" alwaysOn />
    </Filter>
  );
};

export default function DigitalContentCodeList(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [unusedAggregates, setUnusedAggregates] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const classes = styles();

  useEffect(() => {
    fetchUnusedAggregates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUnusedAggregates = async () => {
    const res = await APIClient.get(
      "/digital_content_codes/unused-aggregates",
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setUnusedAggregates(data);
  };

  const onSubmit = async values => {
    const { csv } = values;
    if (!csv) return notify("Please upload A CSV file", "error");
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(csv.rawFile);
    reader.onload = async () => {
      const res = await APIClient.post(
        "/digital_content_codes/csv-upload",
        { csv: reader.result },
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage } = res;
      if (error) {
        setDisableSubmit(false);
        return notify(errorMessage, "warning");
      }
      notify("Your codes have been successfully uploaded", "info");
      refresh();
      fetchUnusedAggregates();
      setDisableSubmit(false);
    };
    reader.onerror = console.error;
  };

  return (
    <Fragment>
      <div className={classes.topContainer}>
        <TableContainer component={Paper} className={classes.aggregates}>
          <Table size="small">
            <caption>Digital Content Code Aggregates</caption>
            <TableHead>
              <TableRow>
                <TableCell>Digital Content ID</TableCell>
                <TableCell>Digital Content Name</TableCell>
                <TableCell>Available Codes</TableCell>
                <TableCell>Used Codes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unusedAggregates.map(row => (
                <TableRow key={row.digital_content_id}>
                  <TableCell>{row.digital_content_id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell align="right">{row.unused_count}</TableCell>
                  <TableCell align="right">{row.used_count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Paper className={classes.upload}>
          <div className={classes.uploadHeader}>Digital Code CSV Upload</div>
          <ul>
            <li>
              2 columns (with exact spelling): 1) digital_content_id 2) code
            </li>
            <li>The system will ignore duplicate codes and nonexistent ids</li>
          </ul>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <FileInput
                      source="csv"
                      label=" "
                      accept={"application/vnd.ms-excel, text/csv, text/plain"}
                      multiple={false}
                    >
                      <FileField source="src" title="title" />
                    </FileInput>
                  </div>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={disableSubmit}
                    startIcon={<SaveIcon />}
                  >
                    save
                    {disableSubmit && (
                      <CircularProgress
                        size={20}
                        className={classes.progress}
                      />
                    )}
                  </Button>
                </form>
              );
            }}
          />
        </Paper>
      </div>
      <List
        {...props}
        perPage={25}
        title="Digital Content Codes"
        bulkActionButtons={false}
        filters={<DigitalContentFilter />}
        sort={{ field: "used_date", order: "DESC" }}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="digital_content_id" label="Digital Content ID" />
          <TextField source="digitalContentName" label="Digital Content Name" />
          <TextField source="code" />
          <BooleanField source="used" label="used" />
          <DateField source="used_date" showTime />
          <TextField source="used_donation_id" label="Used Order ID" />
          <TextField source="used_product_id" label="Used Product ID" />
        </Datagrid>
      </List>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  topContainer: {
    width: "100%",
    margin: "20px 0",
    display: "flex",
    justifyContent: "space-around",
  },
  aggregates: {
    width: "fit-content",
    height: "fit-content",
  },
  upload: {
    padding: 24,
    marginLeft: 12,
  },
  progress: {
    color: "#ffffff",
    position: "absolute",
  },
  uploadHeader: {
    fontWeight: 600,
    fontSize: 20,
  },
}));
