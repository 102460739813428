import React, { useState, useEffect, Fragment } from "react";
import { Notification, useNotify } from "react-admin";
import { useSelector } from "react-redux";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import {
  Dialog,
  Button,
  IconButton,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  APIClient,
  generateAuthHeader,
  integerOnly,
  isFulfiller,
  required,
} from "../../../lib";
const COLUMNS = [
  { name: "Product ID", align: "left" },
  { name: "Name", align: "left" },
  { name: "Thumbnail", align: "left" },
  { name: "Sku", align: "left" },
  { name: "Fulfilled Qty", align: "center" },
  { name: "Replace Qty", align: "left" },
];

export function ReplacementModal(props) {
  const {
    fetchOrder,
    replaceableItems,
    isSchoolOrder,
    campaign_id,
    volunteer_id,
    donation_id,
  } = props;

  const classes = styles();
  const notify = useNotify();
  const [initialValues, setInitialValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const role = useSelector(state => state.user.role);

  useEffect(() => {
    if (replaceableItems) setInitialValues({ replaceableItems });
  }, [replaceableItems]);

  const onSubmit = async ({ replaceableItems, ...rest }) => {
    let overReplacing = false;
    const replacements = [];
    replaceableItems.forEach(p => {
      const { product_id, fulfilled_qty, replace_qty } = p;
      if (!replace_qty) return;
      if (replace_qty > fulfilled_qty) overReplacing = true;
      replacements.push({ product_id, replace_qty: Number(replace_qty) });
    });
    if (overReplacing) {
      notify("You can't replace more than what was fulfilled!", "warning");
      return;
    }
    if (!replacements.length) {
      return notify("No Items were selected!", "warning");
    }
    const url = isSchoolOrder
      ? `/order_replacement/ship_to_school/campaign/${campaign_id}/volunteer/${volunteer_id}`
      : `/order_replacement/ship_to_home/${donation_id}`;
    const res = await APIClient.post(
      url,
      { replacements, ...rest },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    fetchOrder();
    setIsOpen(false);
    notify("Replacement(s) successfully submitted!");
  };

  if (!replaceableItems.length || isFulfiller(role)) {
    return <Fragment></Fragment>;
  }
  return (
    <Fragment>
      <div>
        <Button
          onClick={() => setIsOpen(true)}
          variant="contained"
          className={classes.replaceButton}
        >
          replace fulfilled Items
        </Button>
      </div>
      <Dialog maxWidth={false} open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={classes.dialog}>
          <div className={classes.dialogHeader}>
            <div>Replace Fulfilled Items</div>
            <IconButton
              className={classes.closeIcon}
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <TableContainer component={Paper} className={classes.table}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>Reason:</TableCell>
                            <TableCell>
                              <Field
                                name="reason"
                                variant="standard"
                                component={TextField}
                                validate={required}
                                multiline={true}
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Notes:</TableCell>
                            <TableCell>
                              <Field
                                name="notes"
                                variant="standard"
                                component={TextField}
                                multiline={true}
                                fullWidth
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <TableContainer component={Paper} className={classes.table}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          {COLUMNS.map(({ name, align }) => (
                            <TableCell key={name} align={align}>
                              {name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <FieldArray name="replaceableItems">
                        {({ fields }) => (
                          <TableBody>
                            {fields.map((row, index) => {
                              const {
                                product_id,
                                product_name,
                                thumbnail_image,
                                sku,
                                fulfilled_qty,
                              } = fields.value[index];
                              return (
                                <TableRow key={index}>
                                  <TableCell>{product_id}</TableCell>
                                  <TableCell>{product_name}</TableCell>
                                  <TableCell>
                                    <img
                                      className={classes.thumbnail}
                                      alt="thumbnail"
                                      src={thumbnail_image}
                                    />
                                  </TableCell>
                                  <TableCell>{sku}</TableCell>
                                  <TableCell align="center">
                                    {fulfilled_qty}
                                  </TableCell>
                                  <TableCell>
                                    <Field
                                      name={`${row}.replace_qty`}
                                      type="number"
                                      variant="standard"
                                      component={TextField}
                                      validate={integerOnly}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        )}
                      </FieldArray>
                    </Table>
                  </TableContainer>

                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={submitting}
                  >
                    save
                  </Button>
                </form>
              );
            }}
          />
        </div>
        <Notification />
      </Dialog>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  dialog: {
    padding: "24px",
    width: "800px",
    maxWidth: "100%",
  },
  dialogHeader: {
    fontSize: "22px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingBottom: "24px",
  },
  closeIcon: {
    position: "absolute",
    right: 0,
  },
  replaceButton: {
    backgroundColor: "#FF0000",
    color: "#FFFFFF",
  },
  table: {
    margin: "24px 0",
  },
  thumbnail: {
    maxHeight: 50,
  },
}));
