export function ProductsIconSVG() {
  return (
    <svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
      <title>Products</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-210, -1011)" fill="#FD7B6F">
          <g transform="translate(16, 956)">
            <g transform="translate(194, 55)">
              <g transform="translate(5.8333, 2.9167)">
                <path d="M20.4166668,5.83333336 L17.5,5.83333336 C17.5,2.61041668 14.8895834,0 11.6666667,0 C8.44375004,0 5.83333336,2.61041668 5.83333336,5.83333336 L2.91666668,5.83333336 C1.31250001,5.83333336 0,7.14583336 0,8.75000004 L0,26.2500001 C0,27.8541668 1.31250001,29.1666668 2.91666668,29.1666668 L20.4166668,29.1666668 C22.0208334,29.1666668 23.3333334,27.8541668 23.3333334,26.2500001 L23.3333334,8.75000004 C23.3333334,7.14583336 22.0208334,5.83333336 20.4166668,5.83333336 Z M11.6666667,2.91666668 C13.2708334,2.91666668 14.5833334,4.22916668 14.5833334,5.83333336 L8.75000004,5.83333336 C8.75000004,4.22916668 10.0625,2.91666668 11.6666667,2.91666668 Z M20.4166667,26.25 L2.91666668,26.25 L2.91666668,8.75000004 L5.83333336,8.75000004 L5.83333336,11.6666667 C5.83333336,12.4687501 6.48958336,13.1250001 7.2916667,13.1250001 C8.09375003,13.1250001 8.75000004,12.4687501 8.75000004,11.6666667 L8.75000004,8.75000004 L14.5833334,8.75000004 L14.5833334,11.6666667 C14.5833334,12.4687501 15.2395834,13.1250001 16.0416667,13.1250001 C16.8437501,13.1250001 17.5000001,12.4687501 17.5000001,11.6666667 L17.5000001,8.75000004 L20.4166667,8.75000004 L20.4166667,26.25 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
