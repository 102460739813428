import React from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  useListController,
  ListView,
  Pagination,
  TopToolbar,
  ExportButton,
  downloadCSV,
} from "react-admin";
import jsonExport from "jsonexport/dist";

const GroupFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Group Name" source="q" alwaysOn />
    </Filter>
  );
};

export function CompanyProductGroupList() {
  const controllerProps = useListController({
    sort: { field: "group_name", order: "ASC" },
    basePath: "/supplier_product_groups/company_groups",
    resource: "supplier_product_groups/company_groups",
    perPage: 25,
  });

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues }}
        exporter={groupExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title="Company Product Groups"
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        empty={false}
        actions={<Actions />}
        filters={<GroupFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick="show">
          <TextField source="id" label="Group ID" />
          <TextField source="supplier_name" label="Supplier Name" />
          <TextField source="group_name" label="Group Name" />
        </Datagrid>
      </ListView>
    </div>
  );
}

function groupExporter(groups) {
  const formattedGroups = groups.map(group => {
    const { id, group_name, supplier_name } = group;
    const exportObj = {
      ID: id,
      "Supplier Name": supplier_name,
      "Group Name": group_name,
    };
    return exportObj;
  });
  jsonExport(formattedGroups, (err, csv) => {
    downloadCSV(csv, "Company Product Groups");
  });
}
