import {
  IconButton,
  Checkbox,
  makeStyles,
  Button,
  TextField,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  APIRes,
  NotOnCampaignVolunteerFilterFields,
  NotOnCampaignVolunteerQueryParams,
  ToastTypes,
  volunteerSearchFields,
} from "../../../../types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getVolunteerLabel, setToast } from "../../../../state";
import {
  ButtonSpinner,
  MultiSearch,
  ResponsiveModal,
  SkeletonLoader,
  SortableColumnHeader,
} from "../../../../components";
import {
  useDrawerTransition,
  useIsDesktop,
  useIsMobile,
} from "../../../../hooks/ui";
import { useList } from "../../../../hooks/list";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { Fragment, useEffect, useState } from "react";
import { MobileListLoadingMore } from "../../../../components/ui/MobileListLoadingMore";
import { ListPagination } from "../../../../components/list/ListPagination";
import { RootState } from "../../../../types/state";
import classNames from "classnames";
import { APIAuthClient } from "../../../../lib";

type Props = {
  onClose: () => void;
  refreshParentList: () => void;
};
export function ActivateOldVolunteers({
  onClose: _onClose,
  refreshParentList,
}: Props) {
  const classes = styles();
  const { isOpen, onClose: onCloseFunc } = useDrawerTransition(_onClose);
  const {
    volunteerLabelLC,
    volunteerLabel,
    volunteerLabelLCSingular,
    volunteerLabelSing,
  } = useSelector(getVolunteerLabel);
  const { campaignId, campaignAutocomplete } = useSelector(
    (state: RootState) => state.campaign,
  );
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const searchFields = volunteerSearchFields(volunteerLabelLCSingular);

  const onClose = () => {
    refreshParentList();
    onCloseFunc();
  };

  const listProps = useList<NotOnCampaignVolunteerQueryParams>({
    baseUrl: "/volunteers/not_on_campaign_list",
    isReady,
    initialState: {
      sort: "last_name",
      order: "asc",
      campaign_id: campaignId,
    },
  });
  const {
    noResults,
    loading,
    params,
    setParams,
    selectedRows,
    toggleSelectedRow,
    paginationProps,
    nextPage,
    rows,
    allRowsSelected,
    unselectAllRows,
    selectAllRows,
    selectedRowCount,
    selectedRowIds,
    showRows,
    refreshList,
  } = listProps;
  const {
    page,
    [NotOnCampaignVolunteerFilterFields.previous_campaign_id]: prevCampaignId,
    [NotOnCampaignVolunteerFilterFields.active_only]: activeOnly,
  } = params;

  useEffect(() => {
    if (params.campaign_id) setIsReady(true);
  }, [params.campaign_id]);

  const onCampaignACChange = (_: any, c: any) => {
    setParams((prev: any) => {
      // remove prev `previous_campaign_id`
      const {
        [NotOnCampaignVolunteerFilterFields.previous_campaign_id]: _rm,
        ...rest
      } = prev;
      if (!c) return { ...rest, page: 0 };
      return {
        ...rest,
        page: 0,
        [NotOnCampaignVolunteerFilterFields.previous_campaign_id]: c.id,
      };
    });
    setCampaignName(c ? c.name : "");
  };

  const onCampaignInputChange = (_: any, c: any, action: string) => {
    if (action === "reset") {
      if (!c) setCampaignName("");
    } else setCampaignName(c);
  };

  const onActiveOnlyChange = (_: any, checked: boolean) => {
    setParams((prev: any) => {
      // remove prev `active_only`
      const {
        [NotOnCampaignVolunteerFilterFields.active_only]: _rm,
        ...rest
      } = prev;
      if (!checked) return { ...rest, page: 0 };
      return {
        ...rest,
        page: 0,
        [NotOnCampaignVolunteerFilterFields.active_only]: true,
      };
    });
  };

  const activate = async () => {
    setSubmitting(true);
    const url = "/volunteer_campaigns/bulk_create";
    const body = {
      campaign_id: campaignId,
      volunteer_ids: selectedRowIds,
    };
    const res = await APIAuthClient.post<any, APIRes>(url, body);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return setSubmitting(false);
    }
    dispatch(
      setToast(
        `${selectedRowCount} ${volunteerLabelLC} successfully added to this campaign`,
        ToastTypes.success,
      ),
    );
    refreshList();
    setTimeout(() => setSubmitting(false), 1000);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.titleRow}>
            <h2>Activate old {volunteerLabelLC}</h2>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <div className={classes.top}>
          {isDesktop && (
            <div className={classes.titleRow}>
              <h2>Activate old {volunteerLabelLC}</h2>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}

          <div className={classes.topContent}>
            <div className={classes.warning}>
              <ErrorIcon className={classes.warningIcon} />
              <div>
                Activating {volunteerLabelLC} will&nbsp;
                <span className={classes.bold}>trigger a welcome email</span>
                &nbsp;even if campaign has not yet started and/or has not yet
                been activated.
              </div>
            </div>
            <div className={classes.filters}>
              <MultiSearch listProps={listProps} fields={searchFields} />
              {Array.isArray(campaignAutocomplete) &&
                campaignAutocomplete.length > 0 && (
                  <Autocomplete
                    className={classes.ac}
                    inputValue={campaignName}
                    clearOnBlur
                    clearOnEscape
                    value={prevCampaignId ? `${prevCampaignId}` : null}
                    getOptionSelected={(o: any, c: any) => {
                      if (!c) return false;
                      if (typeof c === "string") return `${o.id}` === c;
                      return o.id === c.id;
                    }}
                    onChange={onCampaignACChange}
                    onInputChange={onCampaignInputChange}
                    options={campaignAutocomplete}
                    getOptionLabel={o =>
                      typeof o === "string" ? o : o ? o.name : ""
                    }
                    renderInput={params => (
                      <TextField {...params} placeholder="Filter by campaign" />
                    )}
                  />
                )}
              {prevCampaignId && (
                <FormControlLabel
                  className={classes.activatedOnly}
                  control={
                    <Switch
                      checked={Boolean(activeOnly)}
                      onChange={onActiveOnlyChange}
                    />
                  }
                  label="Activated only"
                />
              )}
            </div>
          </div>
        </div>

        <div className={classes.content}>
          <div className={classes.rows}>
            <SkeletonLoader show={loading && (!isMobile || page === 0)} />
            {noResults && <div className={classes.noResults}>No Results</div>}
            {showRows && (
              <Fragment>
                {isDesktop && (
                  <div
                    className={classNames(
                      classes.rowContainer,
                      classes.headerRow,
                    )}
                  >
                    <div className={classes.row}>
                      <div>
                        <Checkbox
                          checked={allRowsSelected}
                          onChange={() => {
                            allRowsSelected
                              ? unselectAllRows()
                              : selectAllRows();
                          }}
                        />
                      </div>
                      <SortableColumnHeader
                        columnName={`${volunteerLabelSing} ID`}
                        fieldName="id"
                        params={params}
                        setParams={setParams}
                      />
                      <SortableColumnHeader
                        columnName="Name"
                        fieldName="last_name"
                        params={params}
                        setParams={setParams}
                      />
                      <SortableColumnHeader
                        columnName="Email"
                        fieldName="email"
                        params={params}
                        setParams={setParams}
                      />
                      <SortableColumnHeader
                        columnName="Phone"
                        fieldName="phone"
                        params={params}
                        setParams={setParams}
                      />
                    </div>
                  </div>
                )}
                {rows.map(row => {
                  const { id, first_name, last_name, email, phone } = row;
                  return (
                    <div key={id as number} className={classes.rowContainer}>
                      <div className={classes.row}>
                        <div>
                          <Checkbox
                            checked={Boolean(selectedRows[id as number])}
                            onChange={() =>
                              toggleSelectedRow(id as number, row)
                            }
                          />
                        </div>
                        {isDesktop && (
                          <Fragment>
                            <div>{id}</div>
                            <div>
                              {first_name} {last_name}
                            </div>
                            <div>{email}</div>
                            <div>{phone}</div>
                          </Fragment>
                        )}

                        {isMobile && (
                          <div>
                            <div>{`#${id}`}</div>
                            <div className={classes.name}>
                              {first_name} {last_name}
                            </div>
                            <div className={classes.phoneAndEmail}>
                              {phone}
                              {phone && email && (
                                <span className={classes.dot}>•</span>
                              )}
                              {email}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            )}
          </div>

          {showRows && (
            <ListPagination
              nextPage={nextPage}
              paginationProps={paginationProps}
              label={`${volunteerLabel} per page:`}
              customClassName={classes.customPagination}
              rowsPerPageOptions={[10, 20, 40, 100, 200, 300]}
            />
          )}

          <MobileListLoadingMore show={loading && page > 0} />
        </div>
        <div className={classes.bottom}>
          <Button variant="text" color="primary" onClick={onClose}>
            CANCEL
          </Button>
          <Button
            color="primary"
            className={classes.activate}
            disabled={submitting || !selectedRowCount}
            onClick={activate}
          >
            Activate
            <ButtonSpinner show={submitting} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 960,
    maxWidth: 960,
    maxHeight: "calc(100vh - 64px)",
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      overflow: "auto",
    },
  },
  top: {
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      position: "unset",
    },
  },
  titleRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    borderBottom: "1px solid #DBDEEE",
    padding: "16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      alignItems: "flex-start",
      lineHeight: "32px",
      position: "sticky",
      top: 0,
      borderBottom: "none",
      backgroundColor: "#FFFFFF",
      zIndex: 100,
      width: "100%",
    },
  },
  topContent: {
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  warning: {
    backgroundColor: "#FDE9E9",
    color: "#5E0808",
    padding: "13px 16px",
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  warningIcon: {
    marginRight: 12,
  },
  bold: {
    fontWeight: 500,
  },
  filters: {
    display: "flex",
    alignItems: "center",
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  ac: {
    width: 300,
    margin: "0 16px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "16px 0 0 0",
    },
  },
  activatedOnly: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      overflow: "visible",
    },
  },
  rows: {
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      overflowY: "inherit",
    },
  },
  rowContainer: {
    borderBottom: "1px solid #C9CDDE",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
    },
  },
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "66px 126px 1fr 1fr .7fr",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 0 6px 6px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "50px 1fr",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 16,
      },
    },
  },
  headerRow: {
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    fontWeight: 500,
  },
  name: {
    fontWeight: 500,
    margin: "8px 0",
  },
  phoneAndEmail: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  dot: {
    color: theme.palette.primary.main,
    padding: "0 6px",
  },
  customPagination: {
    marginRight: 10,
  },
  bottom: {
    position: "sticky",
    bottom: 0,
    borderTop: "1px solid #C9CDDE",
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: "100%",
    padding: 24,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      borderTop: "none",
      flexDirection: "column-reverse",
      padding: 16,
      alignItems: "center",
    },
  },
  activate: {
    width: 120,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 8,
    },
  },
  noResults: {
    height: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
  },
}));
