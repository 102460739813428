import React, { Fragment, useState } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  makeStyles,
  Collapse,
  TableHead,
  IconButton,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { getPrizeProgramTierRuleDataPointDisplay } from "../../lib";

export function PrizeProgramTier({ tier, hasTierNotifications }) {
  const {
    tier_name,
    tier_order_number,
    campaign_prize_program_tier_rules: tierRules,
    campaign_prize_program_tier_prize_levels: prizeLevels,
  } = tier;
  const classes = styles();
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <TableRow className={classes.parentRow}>
        <TableCell className={classes.expand}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{tier_name}</TableCell>
        <TableCell className={classes.orderNumber}>
          {tier_order_number}
        </TableCell>
        <TableCell>
          {Array.isArray(tierRules) &&
            tierRules.map(({ data_point }, index) => {
              return (
                <div key={index}>
                  {getPrizeProgramTierRuleDataPointDisplay(data_point)}
                </div>
              );
            })}
        </TableCell>
        <TableCell>
          {Array.isArray(tierRules) &&
            tierRules.map(({ minimum }, index) => {
              return <div key={index}>{minimum}</div>;
            })}
        </TableCell>
        {hasTierNotifications && (
          <TableCell>
            {Array.isArray(tierRules) &&
              tierRules.map(
                (
                  { minimum_approaching_tier_notification_trigger: min },
                  index,
                ) => {
                  return <div key={index}>{min ? min : "NONE"}</div>;
                },
              )}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={hasTierNotifications ? 6 : 5}
          className={classes.collapseCell}
        >
          <Collapse in={open} timeout="auto">
            <Table size="small" className={classes.expandTable}>
              <TableHead>
                <TableRow>
                  <TableCell>Prize Level Name</TableCell>
                  <TableCell>Number Of Prizes To Assign</TableCell>
                  <TableCell>Prize Products</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(prizeLevels) &&
                  prizeLevels.map((level, index) => {
                    const {
                      level_name,
                      number_of_prizes_to_assign,
                      campaign_prize_program_tier_prize_level_products: prizeProducts,
                    } = level;
                    return (
                      <TableRow key={index}>
                        <TableCell>{level_name}</TableCell>
                        <TableCell>{number_of_prizes_to_assign}</TableCell>
                        <TableCell>
                          {Array.isArray(prizeProducts) &&
                            prizeProducts.map(({ prizeProduct }, index) => {
                              return (
                                <Chip
                                  key={index}
                                  className={classes.chip}
                                  label={prizeProduct}
                                />
                              );
                            })}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  parentRow: { "& > *": { borderBottom: "unset" } },
  collapseCell: {
    paddingBottom: 0,
    paddingTop: 0,
    height: "unset",
  },
  expand: { width: 70 },
  expandTable: {
    marginBottom: 4,
    backgroundColor: "#f7f7f2",
  },
  orderNumber: { width: 140 },
  chip: { margin: 2 },
}));
