import { useSelector } from "react-redux";
import { roles } from "../../../lib";
import { VolunteerDashboard } from "./VolunteerDashboard";
import { makeStyles } from "@material-ui/core";
import { OrgDashboard } from "./OrgDashboard";
import { useSetTopBarTitle } from "../../hooks/ui";
import { getIsVolunteer } from "../../../customState";
import { getCampaignId } from "../../state";

export function DashboardController() {
  const classes = styles();
  const { role, userName } = useSelector((state: any) => state.user);
  const isVolunteer = useSelector(getIsVolunteer);
  const campaignId = useSelector(getCampaignId);

  useSetTopBarTitle(
    <div>
      <h1 className={classes.title}>
        {isVolunteer ? "Home" : `Hi, ${userName || ""}!`}
      </h1>
      {!isVolunteer && (
        <div className={classes.campaignId}>Campaign ID {campaignId}</div>
      )}
    </div>,
    [role, userName],
  );

  switch (role) {
    case roles.VOLUNTEER:
      return <VolunteerDashboard />;
    case roles.ORG:
    case roles.ORG_EMPLOYEE:
      return <OrgDashboard />;
    default:
      return <></>;
  }
}

const styles = makeStyles(theme => ({
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
  },
  campaignId: {
    color: theme.palette.text.secondary2,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.1,
    paddingTop: 6,
    lineHeight: "16px",
  },
}));
