export function VolunteerDashboardHeaderSVG() {
  return (
    <svg width="293px" height="168px" viewBox="0 0 293 168">
      <title>Volunteer Dashboard Header</title>
      <defs>
        <rect id="path-1" x="0" y="0" width="293" height="168"></rect>
        <polygon
          id="path-3"
          points="0 0 97 0 97 50.2068966 0 50.2068966"
        ></polygon>
        <polygon
          id="path-5"
          points="0 0 54 0 54 49.2413793 0 49.2413793"
        ></polygon>
        <polygon
          id="path-7"
          points="0 0 37 0 37 30.8965517 0 30.8965517"
        ></polygon>
      </defs>
      <g
        id="--Student"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Desktop-/-Student-/-Dashboard" transform="translate(-1035, -88)">
          <g id="Group-580-Copy" transform="translate(1035, 88)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Mask"></g>
            <g mask="url(#mask-2)">
              <g transform="translate(0, -23)">
                <path
                  d="M189,211.448276 L265.175702,188.22889 C274.316364,185.442253 274.259704,172.685731 265.095324,169.97823 L215.902826,155.448276 L189,211.448276 Z"
                  id="Fill-1"
                  stroke="none"
                  fill="#E3F2FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M286.045669,178.289232 C281.778652,176.30689 274.456577,176.144834 269.691718,177.927457 C268.879666,178.23217 268.196834,178.573402 267.639143,178.939741 C264.624891,178.106633 260.530622,178.187661 257.708161,179.243312 C254.360655,180.496398 254.077728,182.640796 257.075658,184.033115 C259.956608,185.371794 264.816682,185.525862 268.162829,184.424561 C268.360061,184.534121 268.568175,184.641398 268.791251,184.745251 C273.058268,186.727592 280.380344,186.889649 285.145202,185.107025 C289.91006,183.324402 290.312686,180.271573 286.045669,178.289232"
                  id="Fill-3"
                  stroke="none"
                  fill="#E3F2FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M256.446981,180.075125 L259.878362,176.044104 C260.410172,175.76155 261.405647,175.646679 262.219397,175.778714 C262.584182,175.83813 262.777932,176.074472 262.804656,176.434927 L262.898191,177.101702 C263.021122,178.221357 263.164096,178.588414 262.462587,178.962072 L258.29362,181.180256 C257.438447,181.849672 255.005213,181.542031 256.446981,180.075125"
                  id="Fill-5"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M259.432505,175.733484 C259.432505,175.733484 256.670987,178.295471 257.032659,178.473034 C258.530208,179.210064 262,176.948244 262,176.948244 C262,176.948244 260.076733,175.599607 259.432505,175.733484"
                  id="Fill-7"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M263.306618,177.009134 C262.629909,178.298502 260.702194,177.477995 260.592002,176.123508 C260.607559,168.534473 258.989679,162.576032 259,155.483208 C259.011717,147.517782 259.593791,147.381031 259.630089,135.172414 L267,136.343264 C265.645285,145.123987 265.078767,156.21385 265.078767,156.21385 C267.036299,164.723676 264.717078,166.547024 263.306618,177.009134"
                  id="Fill-9"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M280.027847,180.632328 L280,176.264692 C280.16476,175.802815 280.678765,175.200861 281.209015,174.851299 C281.448033,174.694816 281.675448,174.741509 281.869216,174.953517 L282.253269,175.324533 C282.878662,175.965608 283.142046,176.120828 282.925073,176.730354 L281.632518,180.347125 C281.473559,181.224186 279.926902,182.324613 280.027847,180.632328"
                  id="Fill-11"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M280,176.840648 C280,176.840648 280.079152,179.62019 280.359011,179.585892 C281.518021,179.444583 282,176.858483 282,176.858483 C282,176.858483 280.24311,176.490805 280,176.840648"
                  id="Fill-13"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M284,175.784254 C283.681157,177.185769 282.466641,176.836993 281.993007,175.579092 C279.960877,168.372768 276.76186,163.142413 274.859384,156.405397 C272.722737,148.842604 273.257229,148.559223 270,136.961145 L277.567568,136.137931 C278.603478,144.827835 281.0378,155.503966 281.0378,155.503966 C285.258174,163.066759 282.565867,165.482545 284,175.784254"
                  id="Fill-15"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M277.348986,127.448276 C277.348986,127.448276 279.011404,130.825832 278.423064,134.138991 C277.833425,137.450836 279.797157,156.115449 279.797157,156.115449 C279.797157,156.115449 283.131084,163.019068 281.595945,174.477789 C281.595945,174.477789 280.516672,177.204804 278.356827,176.60289 C278.356827,176.60289 273.270867,162.886332 272.868251,161.69433 C272.465634,160.501015 267.851125,140.033287 267.851125,140.033287 L266.832894,140.233049 L265.592575,156.165389 C265.592575,156.165389 267.360192,165.559464 263.563905,174.920683 C263.563905,174.920683 260.814422,175.935264 259.636443,175.207184 C259.636443,175.207184 258,159.156563 258,158.27209 C258,157.388932 258.818221,129.501094 258.818221,129.501094 L277.348986,127.448276 Z"
                  id="Fill-17"
                  stroke="none"
                  fill="#59486B"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M225,56 C225,57.5999741 223.656853,58.8965517 222,58.8965517 C220.343147,58.8965517 219,57.5999741 219,56 C219,54.4000259 220.343147,53.1034483 222,53.1034483 C223.656853,53.1034483 225,54.4000259 225,56"
                  id="Fill-19"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M248.969882,83.8791336 L248.969882,83.8791336 C248.817225,84.5721988 248.121788,85.0544108 247.428236,84.9517345 L245.013994,84.5978679 C244.318557,84.497025 243.877548,83.847964 244.030205,83.1567323 L244.030205,83.1567323 C244.182862,82.463667 244.876414,81.981455 245.571852,82.0822978 L247.986093,82.4379979 C248.679646,82.5388408 249.122539,83.1879019 248.969882,83.8791336"
                  id="Fill-21"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M229.374604,82.8046253 C231.575045,88.3309897 245.916755,84.8480818 245.916755,84.8480818 L246,83.0431227 L235.386274,79.7013375 C235.386274,79.7013375 227.174164,77.278261 229.374604,82.8046253"
                  id="Fill-23"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <polygon
                  id="Fill-25"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                  points="231.525061 68.5517241 236 79.967416 229.924599 82.0689655 226 72.1070577"
                ></polygon>
                <polygon
                  id="Fill-27"
                  stroke="none"
                  fill="#E3F2FE"
                  fillRule="evenodd"
                  points="89.4858789 200.827586 108 184.925648 105.885626 183.448276 89 197.276162"
                ></polygon>
                <polygon
                  id="Fill-29"
                  stroke="none"
                  fill="#E3F2FE"
                  fillRule="evenodd"
                  points="110.675593 203.724138 128 191.421493 125.717735 190.206897 110 200.897601"
                ></polygon>
                <path
                  d="M106.021084,71.113268 C98.805757,77.0366556 97.2780219,83.567274 97.23595,87.918024 C95.2454243,82.6930159 93.0787226,77.8146298 90.9343716,73.4138121 C104.911438,62.8392685 97.2201731,52.5279011 97.2201731,52.5279011 C90.3387916,58.3909508 88.8071123,64.7277175 88.7532077,69.0733324 C88.7426897,69.0540756 88.7321717,69.033535 88.7229685,69.0142782 C85.6780164,63.1512285 82.5962513,57.9262203 79.857635,53.5895919 C92.4029431,40.2549089 82.5265698,31.2838137 82.5265698,31.2838137 C76.3985378,38.9724069 76.6483396,46.0268098 77.6935628,50.2312083 C74.7196069,45.7109985 72.3714702,42.5220746 71.2749719,41.0726804 C65.411204,27.069145 53,27.0344828 53,27.0344828 C56.7983017,40.6785582 65.1745496,42.0727495 69.2147647,41.8930195 C70.0772382,43.0163321 72.6186425,46.4016752 75.9488946,51.4687778 C64.5211208,46.1539046 55.9765854,51.9399272 55.9765854,51.9399272 C67.3096974,61.1908876 75.0811621,57.3254086 78.1708157,54.9234453 C80.8489536,59.1779115 83.8531487,64.2822438 86.8218455,70.0040771 C87.1294961,70.5971861 87.4397762,71.2069844 87.7513711,71.8232015 C77.2294574,63.5158237 67.120374,67.8550196 67.120374,67.8550196 C77.1979035,79.9636873 86.1855084,76.2291544 89.0818942,74.5037463 C91.3274807,79.1266589 93.5941031,84.2823427 95.6490514,89.8026216 C85.4755454,81.8855146 75.6386145,85.898629 75.6386145,85.898629 C84.9036309,97.4578363 93.4678875,94.9801297 96.8875423,93.2534378 C101.031622,105.245281 104.002949,118.728883 103.550676,132.154715 C103.530955,132.73627 103.99769,133.221541 104.591955,133.241379 C104.711597,133.244649 104.82598,133.229244 104.933789,133.198433 C105.36634,133.072622 105.688453,132.68877 105.70423,132.224039 C106.16965,118.395099 103.0892,104.537915 98.8149602,92.2585038 C113.696572,81.8161902 106.021084,71.113268 106.021084,71.113268"
                  id="Fill-31"
                  stroke="none"
                  fill="#E7F4FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M200.15414,33.7931034 C197.432837,33.7969791 195.985365,30.7141944 197.819463,28.7977988 C200.984368,25.4938915 205.93589,22.1356734 211.919771,24.2706053 C218.41644,26.5878663 222.094133,28.8443509 222.094133,28.8443509 C222.094133,28.8443509 225.141015,15.5317381 239.189773,16.4601943 C248.752316,17.0925272 253.054036,24.8525068 254.825731,29.7456517 C255.52844,31.6866164 254.018565,33.7090474 251.86567,33.7129267 L200.15414,33.7931034 Z"
                  id="Fill-33"
                  stroke="none"
                  fill="#E7F4FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M3.56614597,57.9310345 C0.917700705,57.9310345 -0.812847785,55.0418826 0.388810982,52.6028273 C2.63492703,48.0440513 6.68353124,42.2478538 12.6824996,42.4901076 C18.9092768,42.7419965 22.7553842,47.3186663 24.7390537,50.6441503 C25.7235613,52.2945043 27.7805027,52.8794922 29.4337826,51.9627818 C31.5200328,50.805194 34.6081093,49.5953014 38.4488878,49.5457495 C40.4045808,49.5195971 42.2790086,50.0894441 43.9802483,50.9414618 C47.3853922,52.6468735 46.2250322,57.9310345 42.4402067,57.9310345 L3.56614597,57.9310345 Z"
                  id="Fill-35"
                  stroke="none"
                  fill="#E7F4FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M186.932394,56.2585109 C186.932394,56.2585109 188.066922,46.1177852 183.515802,46.3487126 C178.963381,46.57964 176.729455,57.9214393 182.275888,63.3645492 C187.822322,68.806404 198.788986,55.8606631 197.955004,53.8149474 C197.586803,52.9088083 195.862894,49.288017 186.932394,56.2585109"
                  id="Fill-37"
                  stroke="none"
                  fill="#1F66C9"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M76.1186574,60.990756 C76.1186574,60.990756 69.3945582,57.1229591 68.1430433,60.1375063 C66.8915284,63.1520534 74.1128812,68.1011698 79.6315591,66.1961155 C85.1502371,64.2924028 79.5212135,53.2028404 77.8353,53.1089293 C77.0880227,53.0673401 74.0276776,53.0633153 76.1186574,60.990756"
                  id="Fill-39"
                  stroke="none"
                  fill="#1F66C9"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M181,167.529994 L180.991972,86.1631241 L180.979931,86.1631241 C181.226108,79.1585092 176.861819,72.0904982 167.868332,66.729005 C150.433919,56.3320553 122.256022,56.2790096 104.92998,66.6112695 C96.9426083,71.3737349 92.6652836,77.5127999 92.0739237,83.7644252 L92.0672341,83.7644252 L92.0672341,83.8200584 C91.9789315,84.7981687 91.9762557,85.7788665 92.0672341,86.7569768 L92.0672341,165.914042 C91.4022888,173.20588 95.753199,180.647798 105.126654,186.236999 C122.561068,196.633949 150.740302,196.685701 168.065006,186.354735 C176.764151,181.167904 181.069572,174.349596 180.998662,167.529994 L181,167.529994 Z"
                  id="Fill-41"
                  stroke="none"
                  fill="#E7F4FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M171,168.965517 C171,178.029928 155.777698,185.37931 136.999339,185.37931 C118.222302,185.37931 103,178.029928 103,168.965517 C103,159.901106 118.222302,152.551724 136.999339,152.551724 C155.777698,152.551724 171,159.901106 171,168.965517"
                  id="Fill-43"
                  stroke="none"
                  fill="#DBDEEE"
                  fillRule="evenodd"
                ></path>
                <polygon
                  id="Fill-45"
                  stroke="none"
                  fill="#1F66C9"
                  fillRule="evenodd"
                  points="100 56 100 69.5172414 169 69.5172414 168.994747 56.3867503"
                ></polygon>
                <path
                  d="M158.971287,81.4404257 C145.540418,87.4540322 123.697052,87.4244416 110.180904,81.3718294 C96.6660677,75.3205622 96.5978441,65.5395408 110.028713,59.5245893 C123.459582,53.5109827 145.302948,53.5419183 158.819096,59.5931855 C172.333932,65.6457978 172.402156,75.4268192 158.971287,81.4404257"
                  id="Fill-47"
                  stroke="none"
                  fill="#1F66C9"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M158.971287,68.8887015 C145.540418,74.9023081 123.697052,74.8727175 110.180904,68.8201053 C96.6660677,62.768838 96.5978441,52.9878167 110.028713,46.9728651 C123.459582,40.9592586 145.302948,40.9901942 158.819096,47.0414614 C172.333932,53.0940736 172.402156,62.875095 158.971287,68.8887015"
                  id="Fill-49"
                  stroke="none"
                  fill="#248BEF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M171,67.5862069 C171,67.5862069 179.947388,73.1138493 180.674152,84.5605405 C181.402329,96.0059357 180.682635,166.068966 180.682635,166.068966"
                  id="Stroke-51"
                  stroke="#ABD1F5"
                  strokeWidth="0.526"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M100,68.5517241 C100,68.5517241 90.7512423,74.0199305 90,85.343541 L90.7351652,168"
                  id="Stroke-53"
                  stroke="#ABD1F5"
                  strokeWidth="0.526"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M151.751037,162.631554 C145.236367,158.730143 133.574012,158.188246 125.703448,161.41897 C117.832883,164.650908 116.734858,170.433161 123.249529,174.334572 C129.764199,178.234768 141.425167,178.777879 149.295732,175.545941 C157.166296,172.315218 158.265707,166.532965 151.751037,162.631554"
                  id="Fill-55"
                  stroke="none"
                  fill="#B8D8F7"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M149.957964,164.497144 C150.110408,165.136315 149.82215,169.92556 149.511719,170.612701 C148.926889,172.803774 146.886911,174.883349 143.440291,176.415803 C135.880456,179.777609 124.440228,179.234379 117.889294,175.20099 C114.836257,173.321075 113.177389,171.410044 113.139971,169.024497 C113.04019,167.733191 113,164.131533 113,164.131533 C113.239753,163.344562 114.215394,164.165242 114.832099,163.430131 C115.853474,161.981948 117.565004,160.65045 119.961146,159.584733 C127.522368,156.22163 138.96121,156.766157 145.513529,160.799547 C148.272765,162.497952 149.76533,162.446093 149.957964,164.497144"
                  id="Fill-57"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M145.751037,159.735002 C139.236367,155.833591 127.574012,155.291695 119.703448,158.522418 C111.832883,161.754356 110.734858,167.536609 117.249529,171.43802 C123.764199,175.338216 135.425167,175.881328 143.295732,172.64939 C151.166296,169.418666 152.265707,163.636413 145.751037,159.735002"
                  id="Fill-59"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M145.751037,159.735002 C139.236367,155.833591 127.574012,155.291695 119.703448,158.522418 C111.832883,161.754356 110.734858,167.536609 117.249529,171.43802 C123.764199,175.338216 135.425167,175.881328 143.295732,172.64939 C151.166296,169.418666 152.265707,163.636413 145.751037,159.735002"
                  id="Fill-61"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M141.243779,159.826646 C137.018088,156.951677 129.454295,156.552127 124.348734,158.934061 C119.243174,161.314714 118.530531,165.575302 122.756221,168.44899 C126.981912,171.323958 134.545705,171.723508 139.651266,169.342855 C144.756826,166.960922 145.469469,162.701614 141.243779,159.826646"
                  id="Fill-63"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M124.347613,160.587852 C129.453546,158.346332 137.017891,158.722531 141.243891,161.429477 C142.929144,162.507433 143.827089,163.793986 143.977604,165.092596 C144.156421,163.537157 143.266195,161.94434 141.243891,160.649346 C137.017891,157.9424 129.453546,157.566201 124.347613,159.807721 C121.276848,161.155768 119.796141,163.144077 120.022556,165.103448 C120.216811,163.40814 121.684654,161.757446 124.347613,160.587852"
                  id="Fill-65"
                  stroke="none"
                  fill="#2498FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M124.69326,166.468383 C123.884517,165.90968 123.224408,165.123402 123.21275,164.419003 L125.895446,164.287756 C126.054281,164.992155 126.406922,165.511123 127.161749,165.926538 C128.041894,166.41179 128.824408,166.468383 129.60255,166.149296 C131.346812,165.436468 127.161749,163.165533 130.2102,161.919288 C131.868488,161.241379 133.931876,161.272686 135.731512,161.954207 L137.474317,161.241379 L139,162.080638 L137.29071,162.780221 C138.143169,163.395517 138.325319,164.015629 138.245173,164.636945 L135.658652,164.577944 C135.651366,164.038507 135.45173,163.65199 134.791621,163.288352 C134.036794,162.872937 133.30674,162.780221 132.544627,163.092083 C130.937341,163.749522 135.222951,165.978314 131.989435,167.300417 C130.363206,167.965081 128.27796,168.019266 126.285974,167.279947 L124.525683,168 L123,167.160741 L124.69326,166.468383 Z"
                  id="Fill-67"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="130.966"
                  y1="173.793103"
                  x2="131.034"
                  y2="178.62069"
                  id="Stroke-69"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="133.987"
                  y1="173.793103"
                  x2="134.013"
                  y2="178.62069"
                  id="Stroke-71"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="139.987"
                  y1="172.827586"
                  x2="140.013"
                  y2="177.655172"
                  id="Stroke-73"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="141.988"
                  y1="171.862069"
                  x2="142.012"
                  y2="176.689655"
                  id="Stroke-75"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="147.067"
                  y1="168.965517"
                  x2="146.933"
                  y2="173.793103"
                  id="Stroke-77"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="126.013"
                  y1="173.793103"
                  x2="125.987"
                  y2="178.62069"
                  id="Stroke-79"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="123.013"
                  y1="172.827586"
                  x2="122.987"
                  y2="177.655172"
                  id="Stroke-81"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="119.012"
                  y1="171.862069"
                  x2="118.988"
                  y2="176.689655"
                  id="Stroke-83"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="114.933"
                  y1="168.965517"
                  x2="115.067"
                  y2="173.793103"
                  id="Stroke-85"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M168.15348,165.543889 C165.279832,158.737857 155.941875,152.158821 147.297171,150.849426 C138.65107,149.538749 133.973006,153.994026 136.846654,160.800058 C139.720302,167.60609 149.058259,174.185126 157.702963,175.495803 C166.347666,176.805198 171.027127,172.349921 168.15348,165.543889"
                  id="Fill-87"
                  stroke="none"
                  fill="#B8D8F7"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M164.594514,169.06926 C164.336114,169.683312 161.243523,173.525076 160.580435,173.93182 C158.796248,175.445958 155.896431,176.087564 152.179033,175.519434 C144.023726,174.271648 135.045368,167.68633 132.125044,160.811043 C130.764687,157.605637 130.556873,155.123186 131.950043,153.111771 C132.640476,151.982072 134.756891,148.955109 134.756891,148.955109 C135.422714,148.427653 135.726231,149.634765 136.666859,149.352668 C138.360812,148.691381 140.548321,148.498506 143.133683,148.893441 C151.28899,150.141227 160.267347,156.725233 163.187672,163.601832 C164.416778,166.497588 165.663659,167.254656 164.594514,169.06926"
                  id="Fill-89"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M165.15348,160.716303 C162.279832,153.910271 152.941875,147.331235 144.297171,146.02184 C135.65107,144.711163 130.973006,149.16644 133.846654,155.972472 C136.720302,162.778504 146.058259,169.35754 154.702963,170.668217 C163.347666,171.977612 168.027127,167.522335 165.15348,160.716303"
                  id="Fill-91"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M166.15348,160.716303 C163.279832,153.910271 153.941875,147.331235 145.297171,146.02184 C136.65107,144.711163 131.973006,149.16644 134.846654,155.972472 C137.720302,162.778504 147.058259,169.35754 155.702963,170.668217 C164.347666,171.977612 169.027127,167.522335 166.15348,160.716303"
                  id="Fill-93"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M160.435785,159.020858 C158.520436,154.309019 152.294553,149.754664 146.531179,148.847858 C140.767806,147.941051 137.648866,151.024702 139.564215,155.736541 C141.479564,160.44838 147.705447,165.004005 153.468821,165.910812 C159.232194,166.817619 162.351134,163.732697 160.435785,159.020858"
                  id="Fill-95"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M145.67918,147.630989 C151.582855,148.577957 157.960298,153.334021 159.922273,158.254544 C160.704606,160.216121 160.666376,161.907136 159.964598,163.172414 C161.1183,161.866022 161.361328,159.901791 160.421983,157.547633 C158.460008,152.62711 152.082565,147.871047 146.17889,146.924078 C142.62904,146.353775 140.058129,147.292786 139,149.201312 C140.257466,147.778207 142.600368,147.13761 145.67918,147.630989"
                  id="Fill-97"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M142.794562,156.068309 C142.521811,155.169374 142.516216,154.145335 142.970802,153.517241 L145.179386,154.745056 C144.842294,155.446664 144.779351,156.081206 145.103855,156.826665 C145.481511,157.695937 146.06338,158.138311 146.888627,158.246648 C148.737739,158.491695 146.919399,154.389093 150.15045,154.81599 C151.910045,155.04814 153.521374,156.10958 154.496285,157.613395 L156.345397,157.857152 L157,159.362256 L155.187254,159.123658 C155.457208,160.094818 155.194248,160.733229 154.721479,161.241379 L152.714311,159.893621 C153.063992,159.413845 153.159105,158.97276 152.876563,158.321451 C152.552059,157.575993 152.03593,157.12717 151.227467,157.021413 C149.522423,156.795712 151.448466,160.911211 148.020194,160.458519 C146.296967,160.231528 144.611505,159.233284 143.5205,157.582441 L141.654603,157.336105 L141,155.831001 L142.794562,156.068309 Z"
                  id="Fill-99"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="143"
                  y1="165.103448"
                  x2="140"
                  y2="169.931034"
                  id="Stroke-101"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="148"
                  y1="168"
                  x2="145"
                  y2="170.896552"
                  id="Stroke-103"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="152"
                  y1="170.896552"
                  x2="149"
                  y2="173.793103"
                  id="Stroke-105"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="156"
                  y1="170.896552"
                  x2="153"
                  y2="173.793103"
                  id="Stroke-107"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="161"
                  y1="170.896552"
                  x2="158"
                  y2="173.793103"
                  id="Stroke-109"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="140"
                  y1="164.137931"
                  x2="137"
                  y2="167.034483"
                  id="Stroke-111"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="136"
                  y1="160.275862"
                  x2="133"
                  y2="163.172414"
                  id="Stroke-113"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="135"
                  y1="158.344828"
                  x2="132"
                  y2="161.241379"
                  id="Stroke-115"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="134"
                  y1="153.517241"
                  x2="131"
                  y2="156.413793"
                  id="Stroke-117"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M130.680763,148.101067 C124.217335,146.488359 115.538735,150.1995 111.297029,156.390259 C107.055323,162.581019 108.855231,168.906915 115.318659,170.519623 C121.782087,172.132331 130.460687,168.42119 134.702393,162.23043 C138.945409,156.039671 137.144191,149.713775 130.680763,148.101067"
                  id="Fill-119"
                  stroke="none"
                  fill="#B8D8F7"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M137.27338,148.422852 C137.634221,148.979483 139.024683,153.59213 138.974491,154.336901 C139.191539,156.58808 138.026264,159.183099 135.372856,161.696375 C129.550551,167.212089 118.793125,170.236584 111.345676,168.453806 C107.874269,167.622102 105.682088,166.33108 104.822036,164.088983 C104.284843,162.900464 103.001549,159.510072 103.001549,159.510072 C102.951356,158.692641 104.135623,159.166232 104.453055,158.280032 C104.893933,156.597162 106.015799,154.809194 107.862061,153.061449 C113.684365,147.547033 124.441791,144.52124 131.887884,146.305316 C135.025579,147.055276 136.386197,146.544058 137.27338,148.422852"
                  id="Fill-121"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M132.371675,143.4553 C124.666161,141.582663 113.328007,144.570267 107.048349,150.127363 C100.767289,155.684458 101.922555,161.706545 109.62807,163.579182 C117.334986,165.45182 128.671739,162.464216 134.952798,156.90712 C141.232456,151.350025 140.07719,145.327938 132.371675,143.4553"
                  id="Fill-123"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M132.371675,141.524266 C124.666161,139.651629 113.328007,142.639233 107.048349,148.196328 C100.767289,153.753423 101.922555,159.775511 109.62807,161.648148 C117.334986,163.520785 128.671739,160.533181 134.952798,154.976086 C141.232456,149.41899 140.07719,143.396903 132.371675,141.524266"
                  id="Fill-125"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M128.581239,146.19936 C123.44437,144.8378 115.886139,147.009969 111.698561,151.051151 C107.510984,155.092332 108.28245,159.473575 113.419319,160.835134 C118.556188,162.196693 126.114419,160.024525 130.301997,155.982025 C134.488237,151.940843 133.718108,147.560919 128.581239,146.19936"
                  id="Fill-127"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M110.997504,151.55752 C115.20445,147.71559 122.79764,145.650521 127.958269,146.944948 C130.016073,147.461215 131.375405,148.433602 132,149.65911 C131.603752,148.075223 130.139648,146.799591 127.670821,146.179319 C122.510192,144.884892 114.917003,146.94996 110.710056,150.793144 C108.180784,153.103816 107.452762,155.52977 108.397041,157.37931 C107.965869,155.652572 108.804035,153.561188 110.997504,151.55752"
                  id="Fill-129"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M115.863209,156.891979 C115.033891,156.592966 114.246223,156.046535 114,155.401606 L116.083708,154.567889 C116.446305,155.17295 116.900776,155.556389 117.639445,155.738142 C118.500612,155.94921 119.141282,155.793254 119.652103,155.293727 C120.795018,154.175069 116.712127,153.20064 118.711311,151.244746 C119.799102,150.180027 121.446713,149.659394 123.104124,149.808314 L124.24704,148.689655 L125.739077,149.055506 L124.618212,150.151885 C125.502654,150.491938 125.855451,151.012572 126,151.605907 L123.928542,152.239109 C123.741119,151.744273 123.453246,151.442915 122.806452,151.284615 C122.069008,151.102862 121.457738,151.211914 120.957942,151.700887 C119.903226,152.732773 124.053491,153.642709 121.931809,155.717035 C120.866068,156.760648 119.228256,157.364536 117.399347,157.214443 L116.245406,158.344828 L114.753369,157.977804 L115.863209,156.891979 Z"
                  id="Fill-131"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="125"
                  y1="162.206897"
                  x2="127"
                  y2="167.034483"
                  id="Stroke-133"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="128"
                  y1="160.275862"
                  x2="130"
                  y2="165.103448"
                  id="Stroke-135"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="132"
                  y1="159.310345"
                  x2="134"
                  y2="164.137931"
                  id="Stroke-137"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="136.038"
                  y1="155.448276"
                  x2="137.962"
                  y2="160.275862"
                  id="Stroke-139"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="137.097"
                  y1="152.551724"
                  x2="138.903"
                  y2="157.37931"
                  id="Stroke-141"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="119"
                  y1="163.172414"
                  x2="121"
                  y2="168"
                  id="Stroke-143"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="114"
                  y1="163.172414"
                  x2="116"
                  y2="168"
                  id="Stroke-145"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="111.06"
                  y1="162.206897"
                  x2="112.94"
                  y2="167.034483"
                  id="Stroke-147"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="106"
                  y1="161.241379"
                  x2="108"
                  y2="166.068966"
                  id="Stroke-149"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M133.374289,129.335798 C132.671748,129.323792 127.742831,127.904505 127.098719,127.448306 C124.949361,126.374503 123.257698,123.965449 122.467513,120.371878 C120.731333,112.488431 123.981107,101.91314 129.725249,96.7508822 C132.401861,94.3444966 134.784935,93.2520189 137.283477,93.7895873 C138.652389,94.0070157 142.421069,94.834044 142.421069,94.834044 C143.186213,95.2488921 142.1011,95.9638714 142.722953,96.7188682 C143.994483,98.0207709 144.982214,99.9442786 145.533117,102.442704 C147.267907,110.326152 144.018133,120.901443 138.273991,126.065034 C135.856137,128.239318 135.559817,129.649268 133.374289,129.335798"
                  id="Fill-151"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M139.1474,126.756218 C144.966366,121.688286 148.275136,110.972509 146.537308,102.82321 C144.79948,94.6739107 138.672945,92.1751109 132.8526,97.2443703 C127.033634,102.312303 123.724864,113.02808 125.462692,121.177379 C127.20052,129.326678 133.328434,131.824151 139.1474,126.756218"
                  id="Fill-153"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M139.1474,126.756218 C144.966366,121.688286 148.275136,110.972509 146.537308,102.82321 C144.79948,94.6739107 138.672945,92.1751109 132.8526,97.2443703 C127.033634,102.312303 123.724864,113.02808 125.462692,121.177379 C127.20052,129.326678 133.328434,131.824151 139.1474,126.756218"
                  id="Fill-155"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M140.288544,121.837396 C144.521274,118.4585 146.927474,111.31473 145.663394,105.88192 C144.399314,100.449111 139.942751,98.7837077 135.711456,102.162604 C131.478726,105.5415 129.072526,112.68527 130.336606,118.11808 C131.600686,123.550889 136.055814,125.216292 140.288544,121.837396"
                  id="Fill-157"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M142.928618,105.665612 C144.02159,111.106264 141.941099,118.260347 138.28132,121.64412 C136.822371,122.99256 135.333648,123.53878 134,123.362621 C135.516017,123.916445 137.311169,123.453869 139.06166,121.835487 C142.721438,118.451714 144.801929,111.297631 143.708958,105.856979 C143.051438,102.584731 141.396474,100.678665 139.385457,100.413793 C141.03794,101.017043 142.357941,102.828058 142.928618,105.665612"
                  id="Fill-159"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M135.816641,105.950386 C136.659338,105.379405 137.732653,104.997868 138.560846,105.168765 L138.090908,107.573243 C137.229355,107.532175 136.538953,107.71102 135.877559,108.271403 C135.104482,108.925845 134.856459,109.602808 135.047915,110.372506 C135.478692,112.097371 139.110557,108.981486 139.861878,111.995363 C140.270898,113.636767 139.754546,115.451718 138.537639,116.867909 L138.966965,118.592775 L137.629673,119.724138 L137.209049,118.033717 C136.288029,118.629868 135.520754,118.631193 134.811496,118.400681 L135.481593,116.130006 C136.115429,116.262484 136.614375,116.184322 137.193095,115.694153 C137.854489,115.13377 138.134421,114.512448 137.945866,113.758647 C137.549899,112.16891 133.942691,115.384154 133.144957,112.186132 C132.744639,110.577847 133.166713,108.720504 134.495303,107.149313 L134.061626,105.40855 L135.398918,104.275862 L135.816641,105.950386 Z"
                  id="Fill-161"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="127"
                  y1="109.795724"
                  x2="122"
                  y2="108.411172"
                  id="Stroke-163"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="127"
                  y1="115.588828"
                  x2="122"
                  y2="114.204276"
                  id="Stroke-165"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="127"
                  y1="119.450897"
                  x2="122"
                  y2="118.066345"
                  id="Stroke-167"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="128"
                  y1="122.293379"
                  x2="123"
                  y2="121.016966"
                  id="Stroke-169"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="129"
                  y1="125.273931"
                  x2="124"
                  y2="123.829517"
                  id="Stroke-171"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="128"
                  y1="105.956828"
                  x2="123"
                  y2="104.525931"
                  id="Stroke-173"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="130"
                  y1="102.094759"
                  x2="125"
                  y2="100.663862"
                  id="Stroke-175"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="132"
                  y1="99.1431724"
                  x2="127"
                  y2="97.8223448"
                  id="Stroke-177"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="136"
                  y1="96.1828966"
                  x2="131"
                  y2="94.9895172"
                  id="Stroke-179"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M144.747263,61.7190725 L121.048994,55.4430379 C119.755319,55.1001912 119.642158,53.6589415 120.798921,52.2383919 C121.954286,50.8191361 123.959062,49.9380849 125.252737,50.2809315 L148.951006,56.5569661 C150.244681,56.8998128 150.357842,58.3410625 149.201079,59.7603183 L149.201079,59.7603183 C148.045714,61.1808679 146.040938,62.0619191 144.747263,61.7190725"
                  id="Fill-181"
                  stroke="none"
                  fill="#3F64B8"
                  fillRule="evenodd"
                ></path>
                <g
                  id="Group-185"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(145, 161.2414)"
                >
                  <mask id="mask-4" fill="white">
                    <use xlinkHref="#path-3"></use>
                  </mask>
                  <g id="Clip-184"></g>
                  <path
                    d="M96.7953727,11.7334982 L97,0 L58.8250435,0.178976644 C58.4490075,0.0697482508 57.9959042,0.0763282745 57.8125368,0.1947687 L0,24.744837 L0.111614909,36.5072873 C0.154134875,37.1284415 0.103642416,37.7759158 0.479678361,37.8851442 L42.5531842,50.1292523 C42.9292201,50.2384807 43.3809948,50.2319006 43.5643621,50.1134602 L95.8067835,13.6522331 C96.3821318,13.2218995 96.7395652,12.6139054 96.7953727,11.7334982"
                    id="Fill-183"
                    fill="#FFD1D2"
                    mask="url(#mask-4)"
                  ></path>
                </g>
                <g
                  id="Group-188"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(189, 162.2069)"
                >
                  <mask id="mask-6" fill="white">
                    <use xlinkHref="#path-5"></use>
                  </mask>
                  <g id="Clip-187"></g>
                  <path
                    d="M53.860107,11.37355 L54,0.000895966532 L16.1470782,0.000895966532 C15.7735904,-0.106613178 0.180805044,9.49667351 0,9.6119544 C0,9.6119544 0.269227949,49.2284264 0.409120903,49.2374934 C0.651953956,49.2530369 0.874990835,49.2232452 0.989808637,49.1494137 L52.8782169,13.2620841 C53.4496664,12.838524 53.8046778,12.2400996 53.860107,11.37355"
                    id="Fill-186"
                    fill="#FFD1D2"
                    mask="url(#mask-6)"
                  ></path>
                </g>
                <path
                  d="M188.44062,199.770203 L241.921066,163.028699 C242.102815,162.913595 241.962192,162.159598 241.586755,162.052254 L203.676969,150.697035 C203.301533,150.589691 202.849152,150.596158 202.666077,150.712555 L145.07573,187.348008 C144.893982,187.464406 145.050524,187.645468 145.425961,187.752813 L187.431055,199.785723 C187.806491,199.893067 188.258872,199.886601 188.44062,199.770203"
                  id="Fill-189"
                  stroke="none"
                  fill="#EC3C23"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M185.96658,171.06102 C189.600646,168.862763 197.039954,168.305015 202.580038,169.813326 C208.12148,171.322897 209.667487,174.328189 206.03342,176.525187 C202.399354,178.723444 194.961404,179.281192 189.419962,177.772881 C183.87852,176.26331 182.332513,173.259277 185.96658,171.06102"
                  id="Fill-191"
                  stroke="none"
                  fill="#FD7B6F"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M180.153312,191.173074 L164.240604,186.52 C165.923729,184.999533 165.120988,183.160829 162.290336,181.971695 L200.308708,157.28408 C203.906569,157.974248 208.115041,157.601007 210.70487,156.346393 L226.618895,160.999467 C224.894974,162.555293 225.779306,164.443763 228.774453,165.628968 L190.802141,190.287772 C187.150324,189.529503 182.804992,189.888338 180.153312,191.173074 L180.153312,191.173074 Z M156.355312,184.867261 C156.725099,185.329557 157.438355,185.740777 158.351638,186.006629 L184.847377,193.754332 C185.673806,193.996611 186.559454,194.097452 187.324032,194.062092 C187.988597,194.031971 188.559728,193.89839 188.92162,193.663968 L234.589724,164.00766 C235.041102,163.715615 235.124008,163.286061 234.818703,162.829004 C234.812123,162.818527 234.801596,162.80805 234.7937,162.797573 L234.858182,162.755665 L234.731849,162.717686 C234.360746,162.256701 233.64749,161.84548 232.735523,161.579628 L206.239784,153.830616 C204.69352,153.380107 202.941964,153.419396 202.165541,153.922289 L156.497437,183.578597 C156.086854,183.84445 155.985524,184.224239 156.198711,184.634149 L156,184.763802 L156.355312,184.867261 Z"
                  id="Fill-193"
                  stroke="none"
                  fill="#FD7B6F"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M145,193.184287 L186.679833,204.583205 C188.088331,204.984821 189.938816,204.177715 191.14629,203.369318 L242,168"
                  id="Stroke-195"
                  stroke="#EC3C23"
                  strokeWidth="0.526"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M242.795373,158.492109 L243,146.758621 L204.825043,146.937597 C204.449008,146.828369 203.995904,146.834949 203.812537,146.953389 L146,171.503437 L146.111615,183.265878 C146.154135,183.887031 146.103642,184.534505 146.479678,184.643734 L188.553184,196.887831 C188.92922,196.99706 189.380995,196.99048 189.564362,196.872039 L241.806783,160.410842 C242.382132,159.980509 242.739565,159.372516 242.795373,158.492109"
                  id="Fill-197"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M242.796997,158.492091 L243,146.758621 L205.128158,146.937597 C204.755108,146.828369 189.180593,156.585205 189,156.702329 C189,156.702329 189.268912,196.952238 189.408642,196.96145 C189.65119,196.977242 189.873966,196.946974 189.988649,196.871962 L241.816258,160.410821 C242.387038,159.980489 242.741633,159.372496 242.796997,158.492091"
                  id="Fill-199"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M189.44062,182.390893 L242.921066,145.649389 C243.102815,145.534285 242.962192,144.780288 242.586755,144.672944 L204.676969,133.317725 C204.301533,133.210381 203.849152,133.216847 203.666077,133.333245 L146.07573,169.968698 C145.893982,170.085095 146.050524,170.266158 146.425961,170.373502 L188.431055,182.406413 C188.806491,182.513757 189.258872,182.507291 189.44062,182.390893"
                  id="Fill-201"
                  stroke="none"
                  fill="#FD7B6F"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M185.96658,155.612744 C189.600646,153.414487 197.039954,152.856739 202.580038,154.36505 C208.12148,155.874621 209.667487,158.879913 206.03342,161.076911 C202.399354,163.275168 194.961404,163.832916 189.419962,162.324605 C183.87852,160.815034 182.332513,157.811001 185.96658,155.612744"
                  id="Fill-203"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M180.153312,173.793764 L164.240604,169.14069 C165.923729,167.620223 165.120988,165.781518 162.290336,164.592385 L200.308708,139.904769 C203.906569,140.594938 208.115041,140.221697 210.70487,138.967083 L226.618895,143.620157 C224.894974,145.175983 225.779306,147.064453 228.774453,148.249657 L190.802141,172.908462 C187.150324,172.150193 182.804992,172.509028 180.153312,173.793764 L180.153312,173.793764 Z M156.355312,167.487951 C156.725099,167.950246 157.438355,168.361467 158.351638,168.627319 L184.847377,176.375021 C185.673806,176.617301 186.559454,176.718141 187.324032,176.682782 C187.988597,176.65266 188.559728,176.519079 188.92162,176.284658 L234.589724,146.62835 C235.041102,146.336305 235.124008,145.90675 234.818703,145.449693 C234.812123,145.439216 234.801596,145.428739 234.7937,145.418263 L234.858182,145.376355 L234.731849,145.338376 C234.360746,144.87739 233.64749,144.46617 232.735523,144.200317 L206.239784,136.451306 C204.69352,136.000797 202.941964,136.040085 202.165541,136.542979 L156.497437,166.199287 C156.086854,166.465139 155.985524,166.844929 156.198711,167.254839 L156,167.384491 L156.355312,167.487951 Z"
                  id="Fill-205"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M146,177.736011 L187.679833,189.134929 C189.088331,189.536545 190.938816,188.729439 192.14629,187.921042 L243,152.551724"
                  id="Stroke-207"
                  stroke="#EC3C23"
                  strokeWidth="0.526"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
                <path
                  d="M92.8549487,180.705483 L94.7671041,168.101491 L112.513534,172.955026 L110.602734,185.559018 L92.8549487,180.705483 Z M112.966484,169.967938 L95.2200544,165.113115 L97.1308537,152.509123 L114.878639,157.363946 L112.966484,169.967938 Z M115.33159,154.37557 L97.5838039,149.522035 L99.4959594,136.918043 L117.243745,141.771578 L115.33159,154.37557 Z M119.607495,126.17921 L117.696695,138.783202 L99.9489096,133.929666 L101.861065,121.325675 L119.607495,126.17921 Z M123.184988,110.771039 L123.024964,110.727244 C122.458098,110.572673 121.884451,111.170348 121.750194,112.056556 L120.060445,123.190834 L102.314015,118.337298 L104.002408,107.201733 C104.136666,106.316813 103.782713,105.465383 103.215847,105.309524 L103.055823,105.265729 C102.488957,105.111157 101.915311,105.708833 101.781053,106.595041 L99.9543342,118.637424 L99.9543342,118.637424 L99.77668,119.809589 L99.77668,119.809589 L97.5892285,134.229792 L97.5892285,134.229792 L97.4576831,135.092815 L95.2241228,149.82216 L95.215986,149.880125 L92.7735804,165.983866 L92.6827191,166.585405 L92.6827191,166.585405 L90.4952676,181.005609 L90.4952676,181.005609 L90.3176134,182.177773 L88.0284515,197.267785 C87.8941938,198.152705 88.2481459,199.004135 88.8150119,199.159994 L88.9763924,199.203789 C89.5432583,199.35836 90.1169049,198.760685 90.2511626,197.874477 L92.4019984,183.693859 L110.148428,188.547394 L107.998948,202.7293 C107.863334,203.61422 108.217287,204.466938 108.784153,204.621509 L108.945533,204.665304 C109.512399,204.819876 110.086046,204.2222 110.220303,203.33728 L123.971549,112.663248 C124.105806,111.778328 123.751854,110.926898 123.184988,110.771039 L123.184988,110.771039 Z"
                  id="Fill-209"
                  stroke="none"
                  fill="#59486B"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M163.299804,23.5487711 C162.86526,24.9880999 162.068798,25.8114197 160.487978,25.8824977 C159.850082,25.9109289 159.836767,26.8076959 160.452876,27.2483793 C161.975595,28.3382414 162.962094,29.6211988 163.518891,32.0331111 C163.701666,32.8256305 164.752318,33.1348196 164.937514,32.4477326 C165.454367,30.5404738 166.176992,29.6105371 167.571406,29.3321484 C168.157254,29.2148697 168.141518,28.3536417 167.530252,27.9603436 C165.905856,26.920236 165.128761,25.6360941 164.717215,23.9598387 C164.523547,23.1673193 163.504366,22.8723458 163.299804,23.5487711"
                  id="Fill-211"
                  stroke="none"
                  fill="#DFE2F0"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M108.299294,18.7210984 C107.865474,20.1597976 107.067893,20.9839837 105.48755,21.0551934 C104.850293,21.0828861 104.83682,21.9796005 105.452521,22.4200456 C106.976279,23.5106086 107.96113,24.7937016 108.517551,27.2055998 C108.700779,27.9981371 109.751647,28.3067124 109.93757,27.6209896 C110.453573,25.7128339 111.175707,24.783152 112.571475,24.5049068 C113.157536,24.3875427 113.141369,23.5251143 112.52971,23.1334611 C110.904907,22.0916898 110.127534,20.8085969 109.716618,19.1325321 C109.522612,18.3399947 108.504079,18.0446064 108.299294,18.7210984"
                  id="Fill-213"
                  stroke="none"
                  fill="#DFE2F0"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="96"
                  y1="24.137931"
                  x2="96"
                  y2="39.5862069"
                  id="Stroke-219"
                  stroke="#ABD1F5"
                  strokeWidth="0.526"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="178"
                  y1="24.137931"
                  x2="178"
                  y2="40.5517241"
                  id="Stroke-221"
                  stroke="#ABD1F5"
                  strokeWidth="0.526"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M250.608329,86.896787 L250.608329,86.896787 C249.709285,86.9049602 248.98442,87.3007787 249.000255,87.777162 L249.72336,89.5379119 C249.737435,90.0142951 249.795495,89.6791922 250.698058,89.671019 L250.698058,89.671019 C251.597102,89.6628457 251.723777,90.1147093 252,89.2028089 L251.910272,87.8577268 C251.897956,87.6825859 251.852212,87.507445 251.753687,87.3439801 C251.570711,87.0392349 251.315601,86.8897814 250.608329,86.896787"
                  id="Fill-225"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <g
                  id="Group-229"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  transform="translate(256, 100.4138)"
                >
                  <mask id="mask-8" fill="white">
                    <use xlinkHref="#path-7"></use>
                  </mask>
                  <g id="Clip-228"></g>
                  <path
                    d="M14.407851,0.673076588 C14.407851,0.673076588 15.724397,4.83891117 21.5699162,5.10438759 C25.0898577,5.26260081 26.4064037,8.23110991 27.8519959,11.902193 C30.165218,17.7775348 36.1356649,14.9793061 36.9634827,23.6796925 C37.4700577,29.0133551 32.8161568,33.5358399 23.9792365,29.0951433 C15.1423162,24.6557876 1.90821459,32.0569485 0.0137062706,13.9670096 C-0.313027773,10.8402873 5.31283824,10.6163753 5.03964465,7.73635839 C4.74036725,4.5787979 7.11399397,0 7.11399397,0 L14.407851,0.673076588 Z"
                    id="Fill-227"
                    fill="#4E3F5E"
                    mask="url(#mask-8)"
                  ></path>
                </g>
                <path
                  d="M256.10039,113.073772 C258.550341,115.202419 260.164536,112.696466 260.164536,112.696466 L262,106.889389 C260.864332,105.151568 255.600536,103.438327 252.013584,101.37113 C251.259138,100.610372 251.045867,99.8397826 251.093853,98.8442837 L251.155168,91.0351482 L249.223733,90.7586207 L246.291256,98.9118793 C245.012963,101.961055 248.326661,105.333462 248.825182,105.943051 C248.825182,105.943051 253.186575,110.54201 256.10039,113.073772"
                  id="Fill-230"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M262.001383,112 C262.001383,112 261.833621,105.720638 265.19372,105.704461 C265.661751,105.701972 266.432483,105.23158 266.973454,105.241535 C269.780426,105.288823 269.384119,105.317445 271.443456,106.052899 C272.21905,106.329161 272.774609,108.581568 272.678572,109.433998 L274,111.940268 L262.001383,112 Z"
                  id="Fill-232"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M263.784046,106.552597 C264.366039,106.450608 265.129097,106.273123 265.060551,106.624121 C264.763088,108.160565 264.915699,109.200314 265.44208,109.636082 C268.614591,112.257309 270.077335,108.458583 270.368331,107.114194 C270.417477,106.886376 270.702007,106.547299 270.925752,106.679751 L272.116898,107.122141 C280.165221,107.299627 278.084271,111.136765 276.638341,114.675885 C276.638341,114.675885 273.915905,120.441526 273.934011,122.963414 C273.949531,125.143576 275.976161,128.437661 276.364157,129.121114 C276.865965,130.007218 274.218541,135.772859 265.986567,135.121195 C259.50316,134.608605 258.993593,132.007245 259,128.400574 C259.003939,126.130345 260.11102,119.752775 260.014021,117.958049 C259.917022,116.163323 258.520238,115.053374 259.353136,112.13148 C260.184739,109.210911 260.501603,106.206897 260.501603,106.206897 L263.784046,106.552597 Z"
                  id="Fill-234"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M268.583864,108.103562 L268.418551,108.126657 C267.860256,108.206809 267.358515,107.850879 267.306311,107.336003 L267.004687,104.432863 C266.951032,103.917988 267.364316,103.430282 267.924061,103.351488 L268.089374,103.327035 C268.647669,103.248241 269.487287,103.446584 269.540942,103.96146 L269.997728,107.038489 C270.051383,107.553365 269.142159,108.02341 268.583864,108.103562"
                  id="Fill-236"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M267,105.412627 C267,105.412627 268.752061,105.441946 270,104.275862 C270,104.275862 268.736842,106.057638 267.123653,106.206897 L267,105.412627 Z"
                  id="Fill-238"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M264.235798,101.170773 C264.235798,101.170773 263.188841,104.350001 265.507103,105.170035 C267.825365,105.991489 269,99.4482759 269,99.4482759 L264.235798,101.170773 Z"
                  id="Fill-240"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M269.412004,100.298017 C268.963953,99.4596748 268.187948,99.2479865 267.419642,99.6349135 C266.726781,99.2914457 265.8276,99.6194925 265.100866,100.595221 C264.089288,101.955074 263.656634,104.214952 264.311003,105.438819 C264.595847,105.972946 265.111643,106.12996 265.605885,106.184635 C266.069332,106.236505 266.545097,106.19585 267.005465,106.082295 C268.652935,105.677143 268.662174,105.895841 269.335019,104.990208 C270.346597,103.631757 270.064833,101.521884 269.412004,100.298017"
                  id="Fill-242"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M269,100.246394 C269.420843,101.656437 269.298489,104.817728 269.346893,105.241152 C269.346893,105.241152 272.17313,105.328851 271.991617,102.059306 C271.765733,97.989504 269.387229,99.892856 269.387229,99.892856 L269,100.246394 Z"
                  id="Fill-244"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M269.265807,99.7627992 C267.769192,99.114963 266.528949,98.5998017 264.028328,100.250469 C263.869942,99.9396991 264.404159,98.9571874 264.923612,98.6583699 C266.008154,98.0332439 266.559821,97.6949826 267.703422,97.5348164 L267.978584,97.5228637 C269.640296,97.4511475 270.476521,98.0631256 270.731549,99.496254 C270.754367,99.6229526 270.997315,100.206244 271,100.311428 L270.48189,100.124966 C270.499339,100.965241 270.221492,99.6982546 269.265807,99.7627992"
                  id="Fill-246"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M264,104.075947 L264,104.075947 C263.542,103.915672 263.17,103.214706 263.17,102.511809 L264.83,103.093051 C264.83,103.795947 264.458,104.236223 264,104.075947"
                  id="Fill-248"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M275.938087,88.874032 L275.938087,88.874032 C276.821123,88.7208466 277.698663,88.9592629 277.887361,89.4026943 L277.934994,91.166054 C278.123692,91.6106372 277.924002,91.3100252 277.039134,91.4632106 L277.039134,91.4632106 C276.156098,91.616396 276.225715,92.0586757 275.564354,91.2616509 L275.077036,89.9970076 C275.012915,89.8334563 274.983603,89.6629943 275.009251,89.4936842 C275.058716,89.1780992 275.24375,88.9949678 275.938087,88.874032"
                  id="Fill-250"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M278.096175,113.87717 C276.746509,117.112988 274.705988,117.793103 274.705988,117.793103 L273,106.71092 L277.720627,100.713782 C278.138473,99.6949274 278.415327,99.0332651 278.024398,98.0420898 L274.753412,89.7462687 L276.405568,88.8275862 L281.910616,96.0953266 C284.131862,98.7551561 282.307954,103.260259 282.06827,104.040546 C282.06827,104.040546 279.613749,110.238028 278.096175,113.87717"
                  id="Fill-252"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M272,103.310345 C272,103.842989 271.551667,104.275862 271,104.275862 C270.446667,104.275862 270,103.842989 270,103.310345 C270,102.777701 270.446667,102.344828 271,102.344828 C271.551667,102.344828 272,102.777701 272,103.310345"
                  id="Fill-254"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M278.965146,127.881734 C278.566753,127.18908 276.528155,123.500296 276.512166,121.290785 C276.501507,119.845072 275.936562,114.985757 276.421562,112.965517 C274.528198,117.146944 273.624819,121.422335 271.143859,122.015655 C275.439571,128.003888 272.800053,132.78803 269,134.206897 C275.346302,133.593442 279.422165,128.676406 278.965146,127.881734"
                  id="Fill-256"
                  stroke="none"
                  fill="#B9D9F7"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M62.9512658,127.227319 L62.9512658,127.227319 C62.7614256,127.990906 61.9640965,128.517871 61.1799737,128.396362 L58.4050044,127.378246 C57.6192308,127.256737 58.18545,127.283597 58.3752903,126.51873 L58.3752903,126.51873 C58.5651305,125.755143 57.840436,125.542822 59.40538,125.517241 L61.6157809,125.899675 C61.9030175,125.949557 62.1820001,126.028858 62.4345702,126.150367 C62.9033931,126.375478 63.0998365,126.62617 62.9512658,127.227319"
                  id="Fill-258"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M17.7566736,187.755895 C23.2374408,184.913727 32.7075729,184.572866 38.9094578,186.992364 C39.9679164,187.405117 40.8583875,187.872407 41.5876996,188.376883 C45.46917,187.163415 50.7696578,187.211755 54.4421679,188.645853 C58.8002861,190.346444 59.2113778,193.343548 55.3613197,195.340378 C51.6601288,197.259119 45.3762988,197.550401 41.0236436,196.061765 C40.7696135,196.217942 40.5032917,196.371639 40.2164835,196.520379 C34.7357163,199.361307 25.2655842,199.703408 19.0636993,197.283909 C12.8604487,194.863171 12.2759064,190.598062 17.7566736,187.755895"
                  id="Fill-260"
                  stroke="none"
                  fill="#E3F2FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M47.479156,191.393476 L43.5173297,186.697169 C42.908091,186.372367 41.7736057,186.252384 40.8520581,186.420845 C40.4380124,186.495986 40.2215256,186.777158 40.1966829,187.202553 L40.0996779,187.99032 C39.9778301,189.310134 39.8204927,189.746436 40.6237415,190.175466 L45.3982804,192.724197 C46.3801603,193.499845 49.1412539,193.099901 47.479156,191.393476"
                  id="Fill-262"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M40.0723822,188.174938 C41.1940557,188.168528 42.198812,188.781302 42.3120755,187.060922 C42.1695828,177.436024 44.1218548,169.276398 43.9939768,160.278375 C43.8502661,150.175309 42.4813617,153.268659 42.2402202,137.782676 L34,136.137931 C35.781768,147.252457 36.6464674,161.310346 36.6464674,161.310346 C34.4140814,172.137715 38.195619,174.927243 40.0723822,188.174938"
                  id="Fill-264"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M33,132.227936 L37.2034848,155.51971 C37.6544966,158.023464 37.6544966,160.572926 37.2584862,163.084299 C36.6727209,166.800572 36.4788409,173.933479 40.0498092,184.589672 C41.5664738,185.687921 43.1092641,185.613012 44.676805,184.536346 C44.676805,184.536346 47.1546198,159.787012 46.9923655,155.170555 C46.8314863,150.554099 43.1395149,126.482759 43.1395149,126.482759 L33,132.227936 Z"
                  id="Fill-266"
                  stroke="none"
                  fill="#59486B"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M25,188.964341 L24.9709322,191.770041 C24.9433943,192.064263 24.5976406,193.620108 24.4400626,193.888439 C23.9810975,194.666361 23.213096,194.985298 22.4649829,195.032373 C21.4736183,195.094748 19.4557018,193.766042 20.1380299,192.083093 C20.7331546,190.617868 21.9295236,188.275862 21.9295236,188.275862 C23.3385464,188.756032 23.9902768,188.974933 25,188.964341"
                  id="Fill-268"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M22.2311273,188.063757 C22.0946155,189.268613 23.8471315,189.755956 24.5530573,188.524097 C25.2540637,178.896821 28.1786132,170.966888 28.839035,161.967114 C29.580626,151.861496 28.8845389,151.63004 30,136.137931 L20.9053643,136.890162 C21.7232051,148.163346 21.3591737,162.28858 21.3591737,162.28858 C18.1652902,172.887971 21.5055242,174.649607 22.2311273,188.063757"
                  id="Fill-270"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M20.1202121,127.232325 C20.1202121,127.232325 21.6842668,147.339533 21.17951,151.783481 C20.6733702,156.227428 19.01113,164.351759 19.01113,164.351759 C19.01113,164.351759 18.6764693,177.446896 21.6773523,184.438605 C22.8085607,185.993732 24.5178195,185.365064 26.3197322,184.386428 L31.0907213,161.037887 C31.1460371,160.766821 31.1861411,160.491938 31.2110332,160.217055 L33.2176144,137.810244 L36,137.778428 L34.7291191,126.482759 C34.7291191,126.482759 19.446742,128.522749 20.1202121,127.232325"
                  id="Fill-272"
                  stroke="none"
                  fill="#59486B"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M32.0181086,96.3096356 C31.5084342,96.2470178 30.9933234,96.2546853 30.4863674,96.333916 C28.1432247,96.6993999 24.7752968,96.198457 24.5510401,96.379921 L18.5667842,98.4667573 C12.8679458,101.090318 13.9933067,104.513854 14.2882382,107.828768 C14.2882382,107.828768 16.7944767,114.250294 17.387058,117.33646 C17.8980915,120.00347 20.4491813,125.837155 20.1012436,126.763644 C19.8402904,127.457553 20.3907386,131.463818 24.0685485,132.070828 C27.3182319,132.607553 32.7031108,131.954538 35.3792407,131.658061 C44.1252519,130.691957 44.5696879,125.934277 43.6808159,121.528024 C43.1208537,118.753669 41.6244499,103.409735 41.3159271,101.193829 C41.0060451,98.9779236 33.2793826,103.598867 37.0210716,102.208494 C40.4338508,100.939524 40.5534543,99.2731221 39.7406937,97.8712486 C38.6819303,96.0489408 36.374125,95.2080723 34.2416477,95.7473527 L32.0181086,96.3096356 Z"
                  id="Fill-274"
                  stroke="none"
                  fill="#C9E1EE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M27.5602031,99.4482759 L27.8493926,99.1383653 C28.6974652,99.25582 29.420439,99.1015723 29.506546,98.3756175 L29.9923194,94.2831006 C30.0768017,93.5571458 29.4545569,92.867984 28.6081089,92.7505294 L28.3579113,92.7151514 C27.5114633,92.5991119 25.2206924,92.8764747 25.1345854,93.6024295 L25.002988,97.8916477 C24.916881,98.6176025 26.7137551,99.3308212 27.5602031,99.4482759"
                  id="Fill-276"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M28,96.35654 C28,96.35654 26.2406015,96.390722 25,94.6206897 C25,94.6206897 26.2955827,97.2809398 27.912594,97.5172414 L28,96.35654 Z"
                  id="Fill-278"
                  stroke="none"
                  fill="#1B47AA"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M24.6895789,89.7636711 C25.2067075,88.8345535 26.0919176,88.6045739 26.9654631,89.0403853 C27.7573568,88.6620688 28.7799494,89.032336 29.6016524,90.1201395 C30.7434827,91.6357051 31.4394678,94.1355833 30.6851599,95.4901631 C30.3559603,96.0823606 29.4914872,96.4859748 28.9277004,96.5446196 C28.4002033,96.5986648 27.9128841,96.3307386 27.3892753,96.20195 C25.5151702,95.7431407 25.5022096,95.9846193 24.7414214,94.9750088 C23.5982951,93.4594433 23.9352711,91.118251 24.6895789,89.7636711"
                  id="Fill-280"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M26,89.8933951 C25.2839357,91.7470237 24.7795487,95.0278529 24.6935548,95.5862069 C24.6935548,95.5862069 20.9379383,93.8671696 21.0007799,92.2574045 C21.2058422,86.8910766 25.3583535,89.4256571 25.3583535,89.4256571 L26,89.8933951 Z"
                  id="Fill-282"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M25.1166821,87.1215429 C24.1204531,87.5367194 23.5602266,88.5325522 23.3349181,90.1858709 C23.3069067,90.397153 23.0024358,91.3708232 23,91.5466453 L23.6369532,91.239326 C23.6089419,92.6473804 23.9609103,90.5286502 25.1337325,90.6453724 C26.9788264,89.5741873 28.7605905,92.9443572 30.8687474,90.8684744 C31.0660446,90.3498731 31.1147599,89.3407428 30.4790245,88.835439 C29.1503133,87.7805064 28.4756057,87.2072377 27.0726035,86.9309458 L26.7352497,86.9087834 C26.1019501,86.865936 25.5648633,86.9353783 25.1166821,87.1215429"
                  id="Fill-284"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M28.9910138,95.5162709 L28.9910138,95.5162709 C29.5310138,95.3308916 29.9730138,94.512133 29.9770138,93.6875813 L28.0230138,94.3557192 C28.0190138,95.182202 28.4510138,95.7016502 28.9910138,95.5162709"
                  id="Fill-286"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M22.0000342,93.1635069 C21.9944958,93.9633461 22.6627883,94.6153308 23.4916925,94.6206897 C24.3205967,94.6242377 24.9962736,93.9811599 24.9999658,93.1813207 C25.0055042,92.3814815 24.3372117,91.7294968 23.5101536,91.7241379 C22.6812494,91.7205899 22.0055725,92.3636677 22.0000342,93.1635069"
                  id="Fill-288"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M27.9662968,96.5517241 L29.5644031,96.7701611 C29.5644031,96.7701611 30.61618,98.6197664 29.451049,99.4482759 L27,98.5939159 C27,98.5939159 28.2877763,97.545677 27.9662968,96.5517241"
                  id="Fill-290"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M27,99.0477041 L26.0684134,101.37931 C26.0684134,101.37931 22.2634643,100.318028 22,97.7791674 L23.588064,96.5517241 C23.588064,96.5517241 25.5676856,99.4354118 27,99.0477041"
                  id="Fill-292"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M18.4581664,126.186874 C18.4673952,124.720556 17.3335702,119.62064 16.8378513,117.084045 C16.5122062,115.423353 15.5972358,110.582198 15,108.137931 C16.675688,110.328295 17.7594138,111.110655 17.9822236,111.670698 C19.7304236,116.078154 22.8352585,113.822188 23.4298574,116.137681 C25.0910429,122.604298 33.4088363,127.285093 32.9843111,130.969716 C19.5537578,132.711803 18.4515744,127.258366 18.4581664,126.186874"
                  id="Fill-294"
                  stroke="none"
                  fill="#91B8CC"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M63.9673685,122.31927 C64.1073301,122.872531 63.7705908,127.0008 63.4601809,127.587704 C62.8643049,129.468042 60.8535696,131.22751 57.4820196,132.492283 C50.083456,135.266061 38.9558167,134.604391 32.6270585,131.01443 C29.6767791,129.342188 28.0859286,127.664962 28.077614,125.606434 C27.99724,124.491191 28,121.383463 28,121.383463 C28.2425192,120.708087 29.1820634,121.43206 29.791797,120.809019 C30.8020148,119.575398 32.4857112,118.456417 34.8304142,117.576683 C42.2289779,114.802905 53.3566171,115.464574 59.6853753,119.054536 C62.3501885,120.566033 63.8052348,120.546096 63.9673685,122.31927"
                  id="Fill-296"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M59.9400396,115.396558 C53.651441,111.590603 42.3117315,110.875545 34.6138106,113.79857 C26.914504,116.722876 25.7712484,122.176797 32.059847,125.982752 C38.3484455,129.788707 49.6881551,130.503765 57.3874617,127.580741 C65.0853825,124.657716 66.2286381,119.202513 59.9400396,115.396558"
                  id="Fill-298"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M59.9400396,113.465524 C53.651441,109.659569 42.3117315,108.94451 34.6138106,111.867535 C26.914504,114.791842 25.7712484,120.245762 32.059847,124.051718 C38.3484455,127.857673 49.6881551,128.572731 57.3874617,125.649706 C65.0853825,122.726681 66.2286381,117.271479 59.9400396,113.465524"
                  id="Fill-300"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M55.2930132,116.195193 C51.0999659,113.658207 43.5411161,113.181605 38.4084985,115.129561 C33.2758809,117.078739 32.51471,120.715575 36.7064358,123.252562 C40.8994831,125.789548 48.4583329,126.267372 53.5909505,124.318194 C58.7235681,122.369016 59.4860604,118.73218 55.2930132,116.195193"
                  id="Fill-302"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M38.3987583,114.9949 C43.5317241,112.922041 51.0910869,113.428884 55.2844187,116.12815 C56.9548792,117.202917 57.8390075,118.464827 57.9724857,119.724138 C58.1707208,118.221802 57.2998082,116.663584 55.2936696,115.373083 C51.1003378,112.675117 43.5409751,112.168274 38.4080092,114.241133 C35.3221505,115.487448 33.8168858,117.382262 34.017764,119.282275 C34.2345009,117.644781 35.7212637,116.076166 38.3987583,114.9949"
                  id="Fill-304"
                  stroke="none"
                  fill="#2498FE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M38.4829514,121.039564 C37.7851686,120.447335 37.2191168,119.623485 37.2178126,118.895335 L39.556363,118.80657 C39.6854854,119.537494 39.986771,120.079792 40.6402087,120.523617 C41.4005962,121.039564 42.0814235,121.113072 42.7648593,120.796847 C44.2921558,120.090888 40.6741196,117.669268 43.3439538,116.43488 C44.7982113,115.762208 46.5941867,115.831556 48.1540898,116.568028 L49.6813862,115.862069 L51,116.75804 L49.5027017,117.45013 C50.2383082,118.101998 50.3896031,118.745544 50.3113471,119.387703 L48.0601826,119.280907 C48.0601826,118.721966 47.8906279,118.319749 47.319359,117.931402 C46.6672256,117.488965 46.0320477,117.379395 45.3642631,117.687299 C43.9556549,118.339167 47.6623812,120.720564 44.8295137,122.029848 C43.4052543,122.68865 41.5871064,122.706681 39.8602571,121.906409 L38.3186138,122.62069 L37,121.724718 L38.4829514,121.039564 Z"
                  id="Fill-306"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="45.003"
                  y1="130.344828"
                  x2="44.997"
                  y2="135.172414"
                  id="Stroke-308"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="52.023"
                  y1="130.344828"
                  x2="51.977"
                  y2="135.172414"
                  id="Stroke-310"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="54.023"
                  y1="128.413793"
                  x2="53.977"
                  y2="133.241379"
                  id="Stroke-312"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="59.021"
                  y1="126.482759"
                  x2="58.979"
                  y2="129.37931"
                  id="Stroke-314"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="62.098"
                  y1="122.62069"
                  x2="61.902"
                  y2="127.448276"
                  id="Stroke-316"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="40.047"
                  y1="128.413793"
                  x2="39.953"
                  y2="133.241379"
                  id="Stroke-318"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="37.047"
                  y1="128.413793"
                  x2="36.953"
                  y2="133.241379"
                  id="Stroke-320"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="34.043"
                  y1="126.482759"
                  x2="33.957"
                  y2="129.37931"
                  id="Stroke-322"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="28.967"
                  y1="122.62069"
                  x2="29.033"
                  y2="127.448276"
                  id="Stroke-324"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M62.9673685,114.595132 C63.1073301,115.148393 62.7705908,119.276662 62.4601809,119.863567 C61.8643049,121.743904 59.8535696,123.503372 56.4820196,124.768145 C49.083456,127.541923 37.9558167,126.880253 31.6270585,123.290292 C28.6767791,121.61805 27.0859286,119.940824 27.077614,117.882296 C26.99724,116.767053 27,113.659325 27,113.659325 C27.2425192,112.983949 28.1820634,113.707922 28.791797,113.084881 C29.8020148,111.85126 31.4857112,110.732279 33.8304142,109.852545 C41.2289779,107.078767 52.3566171,107.740436 58.6853753,111.330398 C61.3501885,112.841895 62.8052348,112.821958 62.9673685,114.595132"
                  id="Fill-326"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M58.9400396,107.67242 C52.651441,103.866465 41.3117315,103.151407 33.6138106,106.074432 C25.914504,108.998738 24.7712484,114.452659 31.059847,118.258614 C37.3484455,122.064569 48.6881551,122.779627 56.3874617,119.856603 C64.0853825,116.933578 65.2286381,111.478376 58.9400396,107.67242"
                  id="Fill-328"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M58.9400396,106.706903 C52.651441,102.900948 41.3117315,102.18589 33.6138106,105.108915 C25.914504,108.033221 24.7712484,113.487142 31.059847,117.293097 C37.3484455,121.099052 48.6881551,121.81411 56.3874617,118.891085 C64.0853825,115.968061 65.2286381,110.512858 58.9400396,106.706903"
                  id="Fill-330"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M54.2930132,109.436572 C50.0999659,106.899586 42.5411161,106.422984 37.4084985,108.37094 C32.2758809,110.320118 31.51471,113.956955 35.7064358,116.493941 C39.8994831,119.030928 47.4583329,119.508751 52.5909505,117.559573 C57.7235681,115.610395 58.4860604,111.973559 54.2930132,109.436572"
                  id="Fill-332"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M37.3987583,110.167496 C42.5317241,108.094717 50.0910869,108.60154 54.2844187,111.300702 C55.9548792,112.375428 56.8390075,113.63729 56.9724857,114.896552 C57.1707208,113.394274 56.2998082,111.836116 54.2936696,110.545665 C50.1003378,107.847803 42.5409751,107.340979 37.4080092,109.412459 C34.3221505,110.660025 32.8168858,112.554766 33.017764,114.454705 C33.2345009,112.817275 34.7212637,111.24872 37.3987583,110.167496"
                  id="Fill-334"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M38.4829514,114.280943 C37.7851686,113.688714 37.2191168,112.864864 37.2178126,112.136714 L39.556363,112.047949 C39.6867896,112.778873 39.9880753,113.321172 40.6402087,113.764996 C41.4005962,114.280943 42.0814235,114.354451 42.7648593,114.038226 C44.2921558,113.332267 40.6741196,110.910648 43.3439538,109.67626 C44.7982113,109.003588 46.5941867,109.072935 48.1540898,109.809407 L49.6813862,109.103448 L51,109.99942 L49.5027017,110.691509 C50.2383082,111.343377 50.3896031,111.986923 50.3113471,112.629082 L48.0601826,112.522287 C48.0601826,111.963345 47.8906279,111.561129 47.319359,111.172782 C46.6672256,110.730344 46.0320477,110.620775 45.3642631,110.928678 C43.9556549,111.580546 47.6623812,113.961944 44.8295137,115.271227 C43.4052543,115.93003 41.5871064,115.94806 39.8602571,115.147788 L38.3186138,115.862069 L37,114.966097 L38.4829514,114.280943 Z"
                  id="Fill-336"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="45.003"
                  y1="122.62069"
                  x2="44.997"
                  y2="127.448276"
                  id="Stroke-338"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="52.023"
                  y1="122.62069"
                  x2="51.977"
                  y2="127.448276"
                  id="Stroke-340"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="54.023"
                  y1="120.689655"
                  x2="53.977"
                  y2="125.517241"
                  id="Stroke-342"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="59.021"
                  y1="119.724138"
                  x2="58.979"
                  y2="122.62069"
                  id="Stroke-344"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="62.098"
                  y1="116.827586"
                  x2="61.902"
                  y2="121.655172"
                  id="Stroke-346"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="40.047"
                  y1="120.689655"
                  x2="39.953"
                  y2="125.517241"
                  id="Stroke-348"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="37.047"
                  y1="120.689655"
                  x2="36.953"
                  y2="125.517241"
                  id="Stroke-350"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="32.043"
                  y1="119.724138"
                  x2="31.957"
                  y2="122.62069"
                  id="Stroke-352"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="28.967"
                  y1="116.827586"
                  x2="29.033"
                  y2="121.655172"
                  id="Stroke-354"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M63.9673685,108.802029 C64.1073301,109.355289 63.7705908,113.483559 63.4601809,114.070463 C62.8643049,115.950801 60.8535696,117.710268 57.4820196,118.975041 C50.083456,121.748819 38.9558167,121.08715 32.6270585,117.497188 C29.6767791,115.824946 28.0859286,114.14772 28.077614,112.089193 C27.99724,110.97395 28,107.866222 28,107.866222 C28.2425192,107.190845 29.1820634,107.914819 29.791797,107.291778 C30.8020148,106.058157 32.4857112,104.939175 34.8304142,104.059442 C42.2289779,101.285663 53.3566171,101.947333 59.6853753,105.537295 C62.3501885,107.048792 63.8052348,107.028855 63.9673685,108.802029"
                  id="Fill-356"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M59.9400396,102.844834 C53.651441,99.0388791 42.3117315,98.3238208 34.6138106,101.246846 C26.914504,104.171152 25.7712484,109.625073 32.059847,113.431028 C38.3484455,117.236983 49.6881551,117.952041 57.3874617,115.029016 C65.0853825,112.105992 66.2286381,106.650789 59.9400396,102.844834"
                  id="Fill-358"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M59.9400396,102.844834 C53.651441,99.0388791 42.3117315,98.3238208 34.6138106,101.246846 C26.914504,104.171152 25.7712484,109.625073 32.059847,113.431028 C38.3484455,117.236983 49.6881551,117.952041 57.3874617,115.029016 C65.0853825,112.105992 66.2286381,106.650789 59.9400396,102.844834"
                  id="Fill-360"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M55.2930132,104.608986 C51.0999659,102.072 43.5411161,101.595398 38.4084985,103.543354 C33.2758809,105.492532 32.51471,109.129368 36.7064358,111.666355 C40.8994831,114.203342 48.4583329,114.681165 53.5909505,112.731987 C58.7235681,110.782809 59.4860604,107.145973 55.2930132,104.608986"
                  id="Fill-362"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M38.3987583,104.374393 C43.5317241,102.301613 51.0910869,102.808437 55.2844187,105.507599 C56.9548792,106.582325 57.8390075,107.844186 57.9724857,109.103448 C58.1707208,107.601171 57.2998082,106.043012 55.2936696,104.752561 C51.1003378,102.054699 43.5409751,101.547875 38.4080092,103.619355 C35.3221505,104.866921 33.8168858,106.761663 34.017764,108.661602 C34.2345009,107.024171 35.7212637,105.455617 38.3987583,104.374393"
                  id="Fill-364"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M38.4829514,109.453357 C37.7851686,108.861128 37.2191168,108.037278 37.2178126,107.309128 L39.556363,107.220363 C39.6867896,107.951287 39.986771,108.493585 40.6402087,108.93741 C41.4005962,109.453357 42.0814235,109.526865 42.7648593,109.21064 C44.2921558,108.504681 40.6741196,106.083061 43.3439538,104.848674 C44.7982113,104.176001 46.5941867,104.245349 48.1540898,104.981821 L49.6813862,104.275862 L51,105.170447 L49.5027017,105.863923 C50.2383082,106.515791 50.3896031,107.159337 50.3113471,107.801496 L48.0601826,107.694701 C48.0601826,107.135759 47.8906279,106.733542 47.319359,106.345196 C46.6672256,105.902758 46.0320477,105.793188 45.3642631,106.101092 C43.9556549,106.75296 47.6623812,109.134358 44.8295137,110.443641 C43.4052543,111.102443 41.5871064,111.120474 39.8602571,110.320202 L38.3186138,111.034483 L37,110.138511 L38.4829514,109.453357 Z"
                  id="Fill-366"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="45.003"
                  y1="116.827586"
                  x2="44.997"
                  y2="121.655172"
                  id="Stroke-368"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="52.023"
                  y1="116.827586"
                  x2="51.977"
                  y2="121.655172"
                  id="Stroke-370"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="54.023"
                  y1="114.896552"
                  x2="53.977"
                  y2="119.724138"
                  id="Stroke-372"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="59.021"
                  y1="114.896552"
                  x2="58.979"
                  y2="117.793103"
                  id="Stroke-374"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="62.098"
                  y1="112"
                  x2="61.902"
                  y2="116.827586"
                  id="Stroke-376"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="40.047"
                  y1="116.827586"
                  x2="39.953"
                  y2="121.655172"
                  id="Stroke-378"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="37.047"
                  y1="114.896552"
                  x2="36.953"
                  y2="119.724138"
                  id="Stroke-380"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="34.043"
                  y1="113.931034"
                  x2="33.957"
                  y2="116.827586"
                  id="Stroke-382"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="28.967"
                  y1="110.068966"
                  x2="29.033"
                  y2="114.896552"
                  id="Stroke-384"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M63.9673685,101.077891 C64.1073301,101.631151 63.7705908,105.759421 63.4601809,106.346325 C62.8643049,108.226663 60.8535696,109.98613 57.4820196,111.250903 C50.083456,114.024682 38.9558167,113.363012 32.6270585,109.77305 C29.6767791,108.100808 28.0859286,106.423582 28.077614,104.365055 C27.99724,103.249812 28,100.142084 28,100.142084 C28.2425192,99.4667073 29.1820634,100.190681 29.791797,99.5676399 C30.8020148,98.3340189 32.4857112,97.2150374 34.8304142,96.3353036 C42.2289779,93.5615254 53.3566171,94.2231948 59.6853753,97.8131567 C62.3501885,99.3246539 63.8052348,99.3047166 63.9673685,101.077891"
                  id="Fill-386"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M59.9400396,97.0517307 C53.651441,93.2457756 42.3117315,92.5307174 34.6138106,95.4537422 C26.914504,98.3780484 25.7712484,103.831969 32.059847,107.637924 C38.3484455,111.44388 49.6881551,112.158938 57.3874617,109.235913 C65.0853825,106.312888 66.2286381,100.857686 59.9400396,97.0517307"
                  id="Fill-388"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M59.9400396,95.1206963 C53.651441,91.3147411 42.3117315,90.5996829 34.6138106,93.5227077 C26.914504,96.447014 25.7712484,101.900935 32.059847,105.70689 C38.3484455,109.512845 49.6881551,110.227903 57.3874617,107.304879 C65.0853825,104.381854 66.2286381,98.9266514 59.9400396,95.1206963"
                  id="Fill-390"
                  stroke="none"
                  fill="#2498FF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M55.2930132,96.8848483 C51.0999659,94.3478617 43.5411161,93.8712602 38.4084985,95.8192162 C33.2758809,97.7683942 32.51471,101.40523 36.7064358,103.942217 C40.8994831,106.479204 48.4583329,106.957027 53.5909505,105.007849 C58.7235681,103.058671 59.4860604,99.4218349 55.2930132,96.8848483"
                  id="Fill-392"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M38.3987583,97.6155895 C43.5317241,95.5427303 51.0910869,96.0495736 55.2844187,98.7488392 C56.9548792,99.823607 57.8390075,101.085517 57.9724857,102.344828 C58.1707208,100.842492 57.2998082,99.2842737 55.2936696,97.9937726 C51.1003378,95.2958066 43.5409751,94.7889633 38.4080092,96.8618225 C35.3221505,98.1081373 33.8168858,100.002952 34.017764,101.902964 C34.2345009,100.26547 35.7212637,98.6968553 38.3987583,97.6155895"
                  id="Fill-394"
                  stroke="none"
                  fill="#43A7FF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M38.4829514,101.729219 C37.7851686,101.13699 37.2191168,100.31314 37.2178126,99.5849898 L39.556363,99.4962248 C39.6854854,100.227149 39.9880753,100.769447 40.6402087,101.213272 C41.4005962,101.729219 42.0814235,101.802727 42.7648593,101.486502 C44.2921558,100.780543 40.6741196,98.3589236 43.3439538,97.1245356 C44.7982113,96.4518635 46.5941867,96.5212112 48.1540898,97.2576831 L49.6813862,96.5517241 L51,97.4476956 L49.5027017,98.139785 C50.2383082,98.7916528 50.3896031,99.4351989 50.3113471,100.077358 L48.0601826,99.9705626 C48.0601826,99.4116207 47.8906279,99.0094044 47.319359,98.6210576 C46.6672256,98.1786197 46.0320477,98.0690504 45.3642631,98.3769539 C43.9556549,99.0288217 47.6623812,101.41022 44.8295137,102.719503 C43.4052543,103.378306 41.5871064,103.396336 39.8602571,102.596064 L38.3186138,103.310345 L37,102.414373 L38.4829514,101.729219 Z"
                  id="Fill-396"
                  stroke="none"
                  fill="#2498FF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="45.003"
                  y1="110.068966"
                  x2="44.997"
                  y2="114.896552"
                  id="Stroke-398"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="52.023"
                  y1="110.068966"
                  x2="51.977"
                  y2="114.896552"
                  id="Stroke-400"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="54.023"
                  y1="110.068966"
                  x2="53.977"
                  y2="114.896552"
                  id="Stroke-402"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="59.021"
                  y1="107.172414"
                  x2="58.979"
                  y2="110.068966"
                  id="Stroke-404"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="62.098"
                  y1="104.275862"
                  x2="61.902"
                  y2="109.103448"
                  id="Stroke-406"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="40.047"
                  y1="110.068966"
                  x2="39.953"
                  y2="114.896552"
                  id="Stroke-408"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="37.047"
                  y1="110.068966"
                  x2="36.953"
                  y2="114.896552"
                  id="Stroke-410"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="34.043"
                  y1="107.172414"
                  x2="33.957"
                  y2="110.068966"
                  id="Stroke-412"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="30.967"
                  y1="103.310345"
                  x2="31.033"
                  y2="108.137931"
                  id="Stroke-414"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M38.6186321,134.330517 L38.6186321,134.330517 C37.9888984,135.158181 36.9347789,135.421529 36.2793768,134.913644 L34.3080365,132.416971 C33.6492118,131.910796 34.1591592,132.203215 34.7906042,131.37384 L34.7906042,131.37384 C35.4220491,130.546176 34.8761658,129.959629 36.3444036,130.648779 L38.1685778,132.126262 C38.404728,132.317788 38.6169209,132.541805 38.779488,132.801732 C39.080665,133.287386 39.1148896,133.678988 38.6186321,134.330517"
                  id="Fill-416"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M18.1562557,117.960148 C20.0466817,120.838964 25.095862,126.29079 25.095862,126.29079 L33.7475775,135.172414 L36,132.718038 L28.0776403,121.081502 L21.935767,108.632992 L16.5956487,103.310345 L14,109.894452 C14,109.894452 16.2658297,115.081331 18.1562557,117.960148"
                  id="Fill-418"
                  stroke="none"
                  fill="#C9E1EE"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="22"
                  y1="109.103448"
                  x2="27"
                  y2="115.862069"
                  id="Stroke-420"
                  stroke="#7D9FB0"
                  strokeWidth="0.526"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M122,38.9265639 C122,38.9265639 119.894485,47.004826 118.246899,48.7724281 C116.599313,50.5400302 114,46.7412883 114,46.7412883 L119.91242,37.6551724 L122,38.9265639 Z"
                  id="Fill-422"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M122.809621,37.394072 L122.809621,37.394072 C123.644931,37.8697731 124.785622,37.6770453 125.344291,36.9657881 L126.69336,35.2465342 C127.25203,34.535277 127.025688,33.5639902 126.190379,33.0882892 L126.190379,33.0882892 C125.355069,32.6125881 124.214378,32.8068455 123.657505,33.5165731 L122.30664,35.2373566 C121.74797,35.9470843 121.974312,36.918371 122.809621,37.394072"
                  id="Fill-424"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M117.195848,42.4827586 L104,54.5141356 L106.096484,65.6551724 C106.096484,65.6551724 117.02556,55.7828705 120,50.4473757 L117.195848,42.4827586 Z"
                  id="Fill-426"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <polygon
                  id="Fill-428"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                  points="104 92.3556859 113.637411 94.6206897 114 83.3684521 104.362589 81.1034483"
                ></polygon>
                <path
                  d="M110.657806,137.103448 L112.854184,137.70129 C114.48127,137.486336 115.301756,137.143752 115.80667,137.909527 L115.950571,138.126161 C116.131078,138.651791 115.802883,139.464587 115.199511,139.743356 L110.73733,141.871068 C109.87014,142.101137 108.977704,141.664511 109.000425,140.641798 C109.000425,140.641798 109.082473,137.781898 109.477569,137.686176 C110.271547,137.496412 109.964811,137.288175 110.657806,137.103448"
                  id="Fill-430"
                  stroke="none"
                  fill="#292A36"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M112.819756,139.174016 C112.764546,140.363501 109.945591,140.225227 110.0008,139.035742 L110.181045,135.172414 L113,135.310687 L112.819756,139.174016 Z"
                  id="Fill-432"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M114.562145,134.82814 C112.920343,136.587115 109.200836,136.437876 108.495363,135.189119 C108.594301,127.955511 106.457807,120.182348 110.362286,110.358111 L109.090427,80.137931 L124,83.3063814 L118.156904,114.980681 L114.562145,134.82814 Z"
                  id="Fill-434"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M85.5181382,131.11953 C84.7547101,131.932462 82.3852019,129.934933 83.1500705,129.122002 L85.6319323,126.482759 L88,128.478895 L85.5181382,131.11953 Z"
                  id="Fill-436"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M88.4207719,129.235754 C86.402053,130.146183 84.1373723,126.482263 84,125.104212 C86.9201429,118.786074 89.3300458,109.410098 95.6805716,106.465901 L98.9761989,88.4441207 C99.0167564,85.6736492 99.9116389,83.9925569 101.382177,80.137931 L110,86.5723217 L100.085644,113.318279 C99.8815478,113.781983 99.6277361,114.218257 99.3281336,114.62057 L88.4207719,129.235754 Z"
                  id="Fill-438"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M84.7435671,131.396215 L84.499505,133.862976 C84.9768163,135.645411 86.5122451,136.02124 85.8263997,136.630891 L85.2703088,136.940613 C84.7064944,137.311546 83.0088725,136.988357 82.6983884,136.574578 L80.1280127,132.494321 C79.7572854,131.552912 80.2253286,130.480514 81.0996271,129.967574 C81.0996271,129.967574 82.0774202,129.340784 82.5315611,129.381183 C83.6143937,129.47667 84.4469853,130.643332 84.7435671,131.396215"
                  id="Fill-440"
                  stroke="none"
                  fill="#292A36"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M141.281839,54.3127926 C142.017631,53.9216654 146.76,49.9506198 147.294389,49.1852579 C149.219801,47.0386064 150.244271,43.9212836 149.950222,40.2127221 C149.303048,32.0744177 142.565447,24.1817047 134.901393,22.5860097 C131.328505,21.8414387 128.479324,22.1364084 126.01818,23.9828926 C124.644612,24.9249763 120.937455,27.7239397 120.937455,27.7239397 C120.263426,28.517889 121.628938,28.5828603 121.211363,29.6081073 C120.280881,31.4883766 119.844508,33.7792644 120.049939,36.3586249 C120.695771,44.4969293 127.433372,52.3883428 135.098768,53.9853372 C138.326586,54.6571404 139.079833,55.7850421 141.281839,54.3127926"
                  id="Fill-442"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M135.298686,55.7618901 C127.600945,54.2548563 120.777857,46.0062511 120.061386,37.3394708 C119.343586,28.6726904 125.003573,22.8690071 132.701314,24.3760409 C140.399055,25.8830747 147.222143,34.1316799 147.938614,42.7984602 C148.656414,51.4652406 142.996427,57.2689239 135.298686,55.7618901"
                  id="Fill-444"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M132.298686,55.7618901 C124.600945,54.2548563 117.777857,46.0062511 117.061386,37.3394708 C116.343586,28.6726904 122.003573,22.8690071 129.701314,24.3760409 C137.399055,25.8830747 144.222143,34.1316799 144.938614,42.7984602 C145.656414,51.4652406 139.996427,57.2689239 132.298686,55.7618901"
                  id="Fill-446"
                  stroke="none"
                  fill="#2498FF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M132.381206,51.0135238 C127.157192,50.0082887 122.52796,44.509436 122.041556,38.7315637 C121.555151,32.9536914 125.394781,29.0856384 130.618794,30.0895994 C135.842808,31.0948345 140.47204,36.5936872 140.958444,42.3715596 C141.444849,48.1494319 137.605219,52.0187589 132.381206,51.0135238"
                  id="Fill-448"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M122.88635,38.9690532 C123.384329,44.7836254 128.123716,50.3174056 133.472037,51.3290258 C135.603968,51.7316224 137.510026,51.3546689 139,50.3879241 C137.420205,51.8226554 135.186564,52.4624507 132.627982,51.9777961 C127.279661,50.9674581 122.541596,45.4336779 122.042296,39.6191057 C121.743772,36.1226695 123.046179,33.3211612 125.29435,31.862069 C123.571897,33.4262979 122.626133,35.936757 122.88635,38.9690532"
                  id="Fill-450"
                  stroke="none"
                  fill="#43A7FF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M131.881097,46.9688794 C130.916609,46.5254008 129.77159,45.6922383 129,44.685883 L130.038679,43.3055886 C130.874568,44.2463406 131.594224,44.8341465 132.378179,45.0952479 C133.293205,45.3996474 133.700021,45.1346099 133.696311,44.3316249 C133.688892,42.5340932 129.375903,41.1341178 129.362301,37.9930296 C129.354882,36.2820944 130.297112,35.410882 131.831636,35.5906351 L131.82298,33.7931034 L133.408202,34.3205543 L133.415621,36.0826602 C134.461719,36.587806 135.215997,37.3960392 135.856516,38.3236705 L134.654616,39.3877568 C134.063559,38.6162614 133.555348,38.1504777 132.869078,37.922178 C132.085123,37.6610767 131.662232,37.8513264 131.665942,38.6372545 C131.672125,40.294395 135.985114,41.5907171 136,44.9233671 C136.007372,46.5988765 135.146752,47.6026077 133.465082,47.4267908 L133.473737,49.2413793 L131.888516,48.7139284 L131.881097,46.9688794 Z"
                  id="Fill-452"
                  stroke="none"
                  fill="#2498FF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="141"
                  y1="33.7931034"
                  x2="144"
                  y2="30.8965517"
                  id="Stroke-454"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="145"
                  y1="36.6896552"
                  x2="148"
                  y2="33.7931034"
                  id="Stroke-456"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="146"
                  y1="40.5517241"
                  x2="149"
                  y2="37.6551724"
                  id="Stroke-458"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="146"
                  y1="45.3793103"
                  x2="149"
                  y2="42.4827586"
                  id="Stroke-460"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="145"
                  y1="50.2068966"
                  x2="148"
                  y2="47.3103448"
                  id="Stroke-462"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="138"
                  y1="29.9310345"
                  x2="141"
                  y2="27.0344828"
                  id="Stroke-464"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="136"
                  y1="26.0689655"
                  x2="139"
                  y2="23.1724138"
                  id="Stroke-466"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="132"
                  y1="24.137931"
                  x2="135"
                  y2="21.2413793"
                  id="Stroke-468"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="127"
                  y1="24.137931"
                  x2="130"
                  y2="21.2413793"
                  id="Stroke-470"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M99,84.1241158 C99.2631172,76.6317774 100.659462,75.5214292 100.217894,69.0502594 C100.24655,56.6743985 102.124842,54.3029761 106.307363,53.4312773 L109.119591,53.1034483 C110.000122,53.2579424 110.81813,53.6397816 111.68824,53.8369815 C115.335409,54.6634624 121.607237,56.2058918 124.234501,57.5460972 C126.573899,58.7406008 122.802987,60.6171395 122.823828,63.3377439 C123.528513,71.3073813 123.811169,83.3114514 123.966174,87.2479122 C122.696177,92.8951131 101.250824,87.919899 99,84.1241158"
                  id="Fill-472"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M124.846903,64.6896552 L122.589422,66.8904344 C120.01183,76.4970889 121.347947,85.668031 117,89.7931034 C119.686152,89.7647143 122.151009,89.9659072 123.958942,88.7624525 C124.057759,88.772327 124.890049,88.1033296 125,86.9072807 L124.846903,64.6896552 Z"
                  id="Fill-474"
                  stroke="none"
                  fill="#B9D9F7"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M118.999298,47.6575652 C118.95511,49.6134361 116.835469,50.7911152 115.087283,49.9101651 C113.339097,49.0303696 111.956843,46.7304316 112.001031,44.7734062 C112.045219,42.8175352 113.496517,41.9458218 115.246083,42.8256174 C116.994269,43.7065675 119.043486,45.7016943 118.999298,47.6575652"
                  id="Fill-476"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M113.5761,55.9613516 L113.303627,55.9089421 C112.385988,55.7309939 111.822251,55.0009186 112.050878,54.2854691 L113.339644,50.2560383 C113.566704,49.5418076 114.506266,49.1030311 115.423905,49.2809794 L115.694812,49.3333888 C116.614017,49.511337 117.177754,50.2414124 116.949127,50.955643 L116.441764,54.9692291 C116.214703,55.6846786 114.493739,56.1392998 113.5761,55.9613516"
                  id="Fill-478"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M110.163407,50.924381 C110.163407,50.924381 110.572179,52.4224594 113.177792,52.0896992 C115.143914,51.838485 113.700671,48.3096488 115.495007,47.4152736 C117.920058,46.2052366 116.936997,43.7141387 115.85237,42.9065705 C114.767743,42.1003176 113.403495,42.5856476 111.723264,43.1932967 C109.945227,43.8377729 107.530208,45.3082309 110.163407,50.924381"
                  id="Fill-480"
                  stroke="none"
                  fill="#9E616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M115.999808,48.2939782 C115.989086,48.8271105 115.53341,49.251546 114.981237,49.2411939 C114.429064,49.2308418 113.98947,48.7908782 114.000192,48.2577459 C114.010914,47.7246136 114.46659,47.3001782 115.018763,47.3105302 C115.570936,47.3208823 116.01053,47.7608459 115.999808,48.2939782"
                  id="Fill-482"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M109.059728,54.1424118 C109.059728,54.1424118 108.532588,56.1718683 110.610359,56.8049211 C112.68813,57.4392031 116,55.9899425 116,55.9899425 C116,55.9899425 113.699303,53.627365 109.059728,54.1424118"
                  id="Fill-484"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M115.458892,50.2032885 L115.458892,50.2032885 C115.192287,50.2316201 114.941481,50.0899621 114.901984,49.8886587 L114.001453,47.5356457 C113.963931,47.3328511 114.663028,47.4476686 114.929632,47.4178459 C115.198212,47.3895143 115.962478,47.20014 116,47.4029345 L115.875585,49.7827881 C115.913107,49.9855826 115.725496,50.1749569 115.458892,50.2032885"
                  id="Fill-486"
                  stroke="none"
                  fill="#9E616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M142,42.0804436 C142,42.0804436 138.636289,50.1263999 136.557943,51.7590723 C134.478237,53.3917447 132,49.218952 132,49.218952 L139.793798,40.5517241 L142,42.0804436 Z"
                  id="Fill-488"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M141.365467,41.8525311 L141.365467,41.8525311 C141.936406,42.5810296 142.980965,42.6949099 143.684906,42.1054122 L145.389613,40.6802345 C146.095177,40.0907367 146.205472,39.0122239 145.634533,38.2854 L145.634533,38.2854 C145.063594,37.5569014 144.019035,37.4430211 143.315094,38.0325189 L141.610387,39.4576966 C140.904823,40.0471943 140.794528,41.1240325 141.365467,41.8525311"
                  id="Fill-490"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M134.0675,48.2758621 L122,55.1889626 L122.963889,65.6551724 C122.963889,65.6551724 134.192008,56.140199 138,51.3031164 L134.0675,48.2758621 Z"
                  id="Fill-492"
                  stroke="none"
                  fill="#FFD1D2"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M118,55.2727295 L122.908646,54.6124605 L132.966728,49.2413793 C132.966728,49.2413793 135.361578,56.8430003 132.859387,59.669244 C130.355746,62.4967059 120.288961,69.5172414 120.288961,69.5172414 L118,55.2727295 Z"
                  id="Fill-494"
                  stroke="none"
                  fill="#91CBFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M210,143.614187 L209.926682,146.417076 C209.894286,146.710232 209.529402,148.262234 209.367421,148.52886 C208.903643,149.302208 208.138069,149.612608 207.399776,149.65373 C206.419363,149.705463 204.446603,148.359068 205.147385,146.685029 C205.757798,145.225881 206.978625,142.896552 206.978625,142.896552 C208.361432,143.390009 209.002537,143.615513 210,143.614187"
                  id="Fill-498"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M229.488231,147.971053 L225.565021,143.268188 C224.956293,142.939042 223.817945,142.8063 222.890105,142.960714 C222.473113,143.031149 222.251879,143.306115 222.222381,143.727368 L222.115117,144.506212 C221.975672,145.813316 221.813434,146.242696 222.615239,146.677494 L227.380478,149.263257 C228.357928,150.043456 231.137427,149.681801 229.488231,147.971053"
                  id="Fill-500"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M226.575236,142.906966 C226.575236,142.906966 229.326654,145.47446 228.967702,145.653241 C227.477556,146.393196 224,143.956063 224,143.956063 C224,143.956063 225.93472,142.774122 226.575236,142.906966"
                  id="Fill-502"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M223.99116,144.719256 C225.015761,144.727207 225.928609,145.301065 226.049151,143.725277 C226.022782,134.902718 229.01999,128.746674 228.999899,120.499299 C228.977298,111.239389 227.208104,109.781554 227.154112,95.5862069 L219,96.957898 C220.509277,107.164075 222.766913,120.499299 222.766913,120.499299 C220.278239,130.596801 222.417846,132.555602 223.99116,144.719256"
                  id="Fill-504"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M208.943539,146.320622 C209.599576,147.86869 210.896984,148.30556 211.170333,146.764052 C211.953044,138.064697 214.85321,130.927842 215.590586,122.795238 C216.4173,113.664262 215.751929,113.446483 217,99.4482759 L208.250166,100.021586 C208.907537,110.228338 208.398174,122.998586 208.398174,122.998586 C205.208658,132.548066 208.398174,134.180097 208.943539,146.320622"
                  id="Fill-506"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M224.117325,83.0425027 C224.117325,83.0425027 229.748441,81.6788481 228.916037,114.514743 C228.916037,114.514743 223.061253,117.065752 217.618223,118.554954 C212.486549,119.957341 209.151852,113.603859 205,115.114431 C205,115.114431 207.567107,108.837078 207.222708,105.897408 C206.878309,102.959072 205.163939,85.7804968 209.760586,85.2208775 C214.357234,84.6612582 224.117325,83.0425027 224.117325,83.0425027"
                  id="Fill-508"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M205.065749,115.158809 C205.065749,115.158809 205.378575,108.380905 205.065749,105.471179 C204.752922,102.561453 205.443218,83.5933683 209.618466,83.0394495 C210.570797,82.9125373 211.539288,85.2617348 212.819451,85.0581465 C212.528558,87.8872307 211.944462,92.5261356 210.794739,100.111783 C209.261775,110.223776 212.15686,115.805268 215,118.758621 C211.169899,118.621132 208.173232,118.758621 205.065749,115.158809"
                  id="Fill-510"
                  stroke="none"
                  fill="#3A2E45"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M228.902901,63.8378327 C226.82703,63.322292 224.243969,61.7243789 223.987603,61.7953972 L219.568409,62.4214109 C216.462916,62.8501514 212.584168,66.4221119 212.483008,71.7419133 C212.483008,71.7419133 211.420128,75.1889341 210.696761,77.9665411 C210.071782,80.3666987 208.762237,83.0404085 208.110929,83.690095 C207.265614,84.5317941 211.21365,92.3240865 221.254881,93.4248711 C228.8835,94.2613096 229.868777,92.8633 229.317244,88.5088223 C228.994361,85.9521613 228.91953,79.4276782 229.534808,77.4733581 C230.150087,75.5203531 232.16637,74.4669141 231.988993,71.0185781 C231.811615,67.5715573 230.209675,64.9241506 230.209675,64.9241506 C230.209675,64.9241506 229.353274,63.9496208 228.902901,63.8378327"
                  id="Fill-512"
                  stroke="none"
                  fill="#C9E1EE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M223.323915,65.6265257 L223.654463,65.434993 C224.306158,65.7408735 224.940621,65.7866127 225.233571,65.1905744 L226.886308,61.8287468 C227.179258,61.2327085 226.886308,60.4937354 226.234613,60.1864255 L226.041924,60.0963765 C225.390229,59.7904959 224.270127,59.7118818 223.977177,60.3079201 L222.056555,63.8169705 C221.763606,64.4144382 222.67222,65.3206451 223.323915,65.6265257"
                  id="Fill-514"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M225,62.8862816 C225,62.8862816 223.558964,62.4588572 223,60.8275862 C223,60.8275862 223.376278,63.1320179 224.633265,63.7241379 L225,62.8862816 Z"
                  id="Fill-516"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M223.729349,56.5292871 C224.461769,55.8192675 225.287049,55.8454721 225.891295,56.4768779 C226.683878,56.3433593 227.435916,56.9547997 227.789047,58.1689456 C228.282122,59.8585177 227.891062,62.283066 226.822514,63.3187712 C226.355597,63.7704884 225.802358,63.7692405 225.304051,63.6719092 C224.838442,63.5808171 224.39245,63.3911458 223.988312,63.1340912 C222.541783,62.2119393 222.454154,62.4303109 222.127181,61.3047614 C221.634105,59.6139415 222.660801,57.5649923 223.729349,56.5292871"
                  id="Fill-518"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M225,56.6419538 C223.874412,58.1055543 223.775492,61.5354763 222.056385,61.7931034 C222.056385,61.7931034 218.847484,61.2597457 220.446689,57.5527005 C222.438575,52.9363012 224.656778,56.0793519 224.656778,56.0793519 L225,56.6419538 Z"
                  id="Fill-520"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M223.177456,57.0040374 C225.131209,56.7283008 224.25758,58.8218563 228.846783,59.862069 C229.155201,59.4958791 228.973856,57.910029 228.51851,57.278315 C227.565459,55.959448 227.090258,55.2577056 225.875118,54.561799 L225.571995,54.4304959 C223.738697,53.6383003 222.540765,54.1387111 221.645956,56.0236406 C221.566536,56.1899579 221.046329,56.8989948 221,57.0434283 L221.659193,56.9996606 C221.283268,58.1609639 222.133071,56.5167568 223.177456,57.0040374"
                  id="Fill-522"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M226.598774,62.5287223 L226.598774,62.5287223 C227.123116,62.5152051 227.749462,61.8567223 228,61.0572741 L226.103066,61.1016879 C225.852528,61.9030672 226.074433,62.5422396 226.598774,62.5287223"
                  id="Fill-524"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M221.05251,60.5195216 C220.876329,61.0244797 221.157941,61.572299 221.680933,61.7424042 C222.203925,61.9125095 222.77131,61.640609 222.94749,61.1356508 C223.123671,60.6306927 222.842059,60.0828734 222.319067,59.9127682 C221.796075,59.7426629 221.22869,60.0145635 221.05251,60.5195216"
                  id="Fill-526"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M226.126223,63.7241379 L226.996603,64.2474126 C226.996603,64.2474126 227.098169,66.1180164 226.190505,66.6206897 L225,65.3530191 C225,65.3530191 226.038799,64.6800412 226.126223,63.7241379"
                  id="Fill-528"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M223,64.9361268 L221.909385,66.6206897 C221.909385,66.6206897 219.734208,64.3910674 220.027137,62.1102316 L221.089911,61.812095 C221.003969,61.5176166 221.915438,64.7422466 223,64.9361268"
                  id="Fill-530"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M210,145.230396 C210,145.230396 209.383175,147.939232 207.853962,147.710417 C206.323142,147.482794 207.317452,144.827586 207.317452,144.827586 L210,145.230396 Z"
                  id="Fill-532"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M273.169113,89.4041841 C273.676109,88.7702701 276.360695,83.3651773 276.535235,82.4674689 C277.409318,79.7891484 277.076862,76.5711263 275.313457,73.3154192 C271.444495,66.1741287 262.167575,61.5684942 254.593108,63.0287845 C251.063528,63.7098047 248.606121,65.059732 247.125305,67.6694121 C246.265073,69.0449113 244.043156,72.9924057 244.043156,72.9924057 C243.753642,73.9695216 245.014206,73.5092273 245.051607,74.5993979 C244.968493,76.6586091 245.499038,78.903553 246.726357,81.1673394 C250.595319,88.30863 259.87224,92.9142644 267.446706,91.4539741 C270.635518,90.8389025 271.772796,91.5751042 273.169113,89.4041841"
                  id="Fill-534"
                  stroke="none"
                  fill="#B2D5F6"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M267.517474,92.3946575 C259.71937,93.8817958 250.031977,89.6007151 245.879225,82.8307777 C241.72789,76.0620754 244.684423,69.3687182 252.482526,67.88158 C260.282047,66.3932066 269.96944,70.6755224 274.120775,77.4442247 C278.27211,84.2141621 275.315577,90.9075193 267.517474,92.3946575"
                  id="Fill-536"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M267.517474,92.3946532 C259.71937,93.8818135 250.031977,89.6006693 245.879225,82.8306314 C241.72789,76.0618287 244.684423,69.3683722 252.482526,67.8812119 C260.282047,66.3940516 269.96944,70.6751958 274.120775,77.4439985 C278.27211,84.2140363 275.315577,90.9074929 267.517474,92.3946532"
                  id="Fill-538"
                  stroke="none"
                  fill="#2498FF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M264.43299,87.6464153 C259.314639,88.7335663 252.957613,85.6038109 250.232793,80.6574028 C247.509303,75.7109948 249.449141,70.8201063 254.566161,69.7329553 C259.684512,68.6458043 266.041538,71.7742686 268.766358,76.7219678 C271.491178,81.6683758 269.55134,86.5592643 264.43299,87.6464153"
                  id="Fill-540"
                  stroke="none"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M250.769959,81.5995135 C253.570437,86.6090128 260.103974,89.7786881 265.364443,88.6776706 C267.460699,88.2383096 269.037336,87.1869817 270,85.7642891 C269.145362,87.6394188 267.377287,89.0477275 264.861232,89.5746991 C259.600763,90.6757167 253.067226,87.5060414 250.266748,82.4965421 C248.583452,79.4837814 248.634047,76.4919426 250.084881,74.3448276 C249.153667,76.3886405 249.309553,78.9868851 250.769959,81.5995135"
                  id="Fill-542"
                  stroke="none"
                  fill="#43A7FF"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M260.428254,83.4056292 C259.290963,83.4042879 257.814308,83.1279879 256.640557,82.5445388 L257.114545,80.8813736 C258.326159,81.3803231 259.279744,81.6110202 260.164615,81.5224969 C261.196731,81.420561 261.494025,81.0154998 261.16588,80.2979245 C260.429656,78.689751 255.57759,79.2074782 254.291652,76.398875 C253.591889,74.869836 254.176661,73.7029378 255.773916,73.233496 L255.039095,71.6266637 L256.827067,71.4482759 L257.547865,73.0229178 C258.791733,73.0457193 259.868723,73.4601693 260.881207,74.0288645 L260.115534,75.4747453 C259.216639,75.0267637 258.523888,74.818868 257.749801,74.89532 C256.86493,74.9838433 256.521359,75.3285477 256.842493,76.0300279 C257.519819,77.5121227 262.329815,76.9018483 263.694283,79.8821332 C264.37862,81.3789819 263.929874,82.6317209 262.188179,83.164202 L262.930012,84.7871294 L261.14204,84.9655172 L260.428254,83.4056292 Z"
                  id="Fill-544"
                  stroke="none"
                  fill="#2498FF"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="267"
                  y1="68.5517241"
                  x2="269"
                  y2="63.7241379"
                  id="Stroke-546"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="270"
                  y1="71.4482759"
                  x2="272"
                  y2="66.6206897"
                  id="Stroke-548"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="272"
                  y1="72.4137931"
                  x2="274"
                  y2="69.5172414"
                  id="Stroke-550"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="274"
                  y1="77.2413793"
                  x2="276"
                  y2="74.3448276"
                  id="Stroke-552"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="276"
                  y1="83.0344828"
                  x2="278"
                  y2="78.2068966"
                  id="Stroke-554"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="261"
                  y1="66.6206897"
                  x2="263"
                  y2="61.7931034"
                  id="Stroke-556"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="258"
                  y1="63.7241379"
                  x2="260"
                  y2="60.8275862"
                  id="Stroke-558"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="255"
                  y1="63.7241379"
                  x2="257"
                  y2="60.8275862"
                  id="Stroke-560"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="250"
                  y1="66.6206897"
                  x2="252"
                  y2="63.7241379"
                  id="Stroke-562"
                  stroke="#DBDEEE"
                  strokeWidth="0.263"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M235.908967,89.1672824 L235.908967,89.1672824 C235.702165,89.694859 235.209557,89.9363445 234.81338,89.7056259 L233.435473,88.901187 C233.040457,88.6689302 232.884774,88.0490662 233.091577,87.5230278 L233.091577,87.5230278 C233.297217,86.9954512 233.790987,86.7524276 234.187164,86.9846844 L235.56391,87.7891233 C235.960087,88.0198419 236.114608,88.641244 235.908967,89.1672824"
                  id="Fill-564"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M219.046601,84.5610354 C219.740528,89.4051152 234.452892,89.7931034 234.452892,89.7931034 L235,88.3866459 L225.638216,83.4270934 C225.638216,83.4270934 218.352673,79.7169555 219.046601,84.5610354"
                  id="Fill-566"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                ></path>
                <polygon
                  id="Fill-568"
                  stroke="none"
                  fill="#A0616A"
                  fillRule="evenodd"
                  points="219.915623 69.5172414 225 80.524176 219.455634 83.0344828 215 73.4320927"
                ></polygon>
                <path
                  d="M214.239281,69.7448231 C214.239281,69.7448231 213.746914,72.9395722 214.174747,76.2037194 C214.601386,79.4666491 222.187656,78.8846788 221.996446,73.413184 C221.805236,67.9416892 219.309941,66.6206897 219.309941,66.6206897 L214.239281,69.7448231 Z"
                  id="Fill-570"
                  stroke="none"
                  fill="#C9E1EE"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M222,67.3200631 C222,67.3200631 223.087906,71.3909155 224.487202,74.6293308 C225.886497,77.8664414 233.522688,76.223748 231.72862,70.6393735 C229.934551,65.0536942 227.074725,62.7586207 227.074725,62.7586207 L222,67.3200631 Z"
                  id="Fill-572"
                  stroke="none"
                  fill="#C9E1EE"
                  fillRule="evenodd"
                ></path>
                <line
                  x1="220"
                  y1="69.5172414"
                  x2="222"
                  y2="74.3448276"
                  id="Stroke-574"
                  stroke="#A5CEE3"
                  strokeWidth="0.503"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <line
                  x1="227.389"
                  y1="69.5172414"
                  x2="228.611"
                  y2="74.3448276"
                  id="Stroke-576"
                  stroke="#A5CEE3"
                  strokeWidth="0.503"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <path
                  d="M267.668804,156.336739 L269,140 L266.94257,140.564346 L266.099247,157.151313 C266.099247,157.151313 265.625314,171.468919 264,175.724138 L265.491497,175.331492 C265.491497,175.331492 269.800112,167.396048 267.668804,156.336739"
                  id="Fill-578"
                  stroke="none"
                  fill="#4E3F5E"
                  fillRule="evenodd"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
