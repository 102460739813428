import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  SelectInput,
  required,
} from "react-admin";
import { checkpointEmailDayOffsetOptions } from "../../lib";

const requiredField = [required()];

export default function CheckpointEmailEdit(props) {
  const transformDataForSubmit = values => {
    const { email_code, subject, main_body, day_offset } = values;
    return { email_code, subject, main_body, day_offset };
  };

  return (
    <Edit
      {...props}
      undoable={false}
      successMessage="Checkpoint Email updated successfully"
      title="Edit Checkpoint Email"
      transform={transformDataForSubmit}
    >
      <SimpleForm redirect="list">
        <TextInput source="id" disabled />
        <TextInput source="email_code" disabled fullWidth />
        <FormDataConsumer>
          {({ formData }) => {
            const { hasDaySelector, daySelectorLabel } = formData;
            if (!hasDaySelector) return <></>;
            return (
              <SelectInput
                label={daySelectorLabel}
                source="day_offset"
                choices={checkpointEmailDayOffsetOptions}
                translateChoice={false}
                validate={requiredField}
                fullWidth
              />
            );
          }}
        </FormDataConsumer>
        <TextInput
          source="subject"
          validate={requiredField}
          multiline
          fullWidth
        />
        <TextInput source="greeting" disabled multiline fullWidth />
        <TextInput
          source="main_body"
          validate={requiredField}
          multiline
          fullWidth
        />
        <TextInput source="closer" disabled multiline fullWidth />
      </SimpleForm>
    </Edit>
  );
}
