import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(theme => ({
  // campaign list
  raSearch: {
    width: "250px",
  },
  openChip: {
    color: "#FFFFFF",
    backgroundColor: theme.palette.primary.main,
  },
  noSalesChip: {
    backgroundColor: "#F5F5F5",
  },
  endedChip: {
    backgroundColor: "#ffae00",
    // color: "#FFFFFF",
  },
  fulfilledChip: {
    backgroundColor: "#008000",
    color: "#FFFFFF",
  },
  readyFulfillmentChip: {
    backgroundColor: "#1b4a9e",
    color: "#FFFFFF",
  },

  // order list
  wideFilter: {
    width: "220px",
  },
  homeOrdersTop: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "12px 0",
    position: "relative",
  },
  refreshContainer: {
    width: 100,
    display: "flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
  },
  homeOrdersTopBalancer: {
    width: 100,
  },
  refreshIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    marginRight: 4,
  },
  studentOrdersTop: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    margin: "12px 0",
  },
  studentOrdersTopLeft: {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
  },
  sortSelect: {
    marginLeft: 16,
    width: 150,
  },
  studentOrderInput: {
    width: 250,
  },
  stsButtonActions: {
    maxWidth: 430,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    height: "fit-content",
  },
  shippingRow: {
    borderBottom: "1px solid #808080",
    paddingBottom: 2,
    marginBottom: 2,
  },
  trackingLink: {
    color: theme.palette.primary.main,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "16px",
    fontSize: 18,
  },
  drawerContent: {
    minWidth: "calc(100vw - 16px)",
    padding: "16px",
  },
  // campaign fulfillment details
  missingStudents: {
    height: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 40,
    fontSize: 20,
    color: "red",
  },
  // fulfillment
  fulfillmentTable: {
    marginBottom: 42,
  },
  tableName: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 20,
  },
  productName: {
    width: 300,
    minWidth: 300,
  },
  thumbnail: {
    maxHeight: 35,
  },
  upcInput: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 24,
  },
  upcChip: {
    display: "flex",
    width: "fit-content",
    marginBottom: 3,
  },
  partialFulfilled: {
    color: "#0000B2",
    backgroundColor: "#E5E5FF",
    fontSize: 36,
  },
  partialBackOrdered: {
    color: "#ff8100",
    backgroundColor: "#ffead5",
    fontSize: 36,
  },
  reprint: {
    marginBottom: 24,
  },
}));
