import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  InputAdornment,
  Button,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { Field } from "react-final-form";
import { TextField, Checkbox } from "final-form-material-ui";
import { FieldArray } from "react-final-form-arrays";
import { required, twoDecimalsOnly, composeValidators } from "../../../lib";
import { styles } from "./OrgProfit.styles";

export function CustomAdjustments({ disableEdit }) {
  const classes = styles();

  return (
    <TableContainer component={Paper} className={classes.table}>
      <FieldArray name="org_profit_adjustments">
        {({ fields }) => (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>CUSTOM ADJUSTMENTS</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() => fields.push({ is_debit: false })}
                    startIcon={<AddIcon />}
                    disabled={disableEdit}
                  >
                    add
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {fields.map((row, index) => {
                const isDebit = fields.value[index].is_debit;
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            name={`${row}.is_debit`}
                            type="checkbox"
                            disabled={disableEdit}
                          />
                        }
                        label="Is Debit"
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        variant="standard"
                        component={TextField}
                        name={`${row}.memo`}
                        label="Memo"
                        validate={required}
                        disabled={disableEdit}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        variant="standard"
                        component={TextField}
                        name={`${row}.amount`}
                        label="Amount"
                        type="number"
                        validate={composeValidators(required, twoDecimalsOnly)}
                        disabled={disableEdit}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {`$ ${isDebit ? "-" : "+"}`}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => fields.remove(index)}
                        size="small"
                        disabled={disableEdit}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </FieldArray>
    </TableContainer>
  );
}
