import { Fragment, useState } from "react";
import { format } from "date-fns";
import { makeStyles, Button, Switch } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { APIRes, GenericObject, ListProps, ToastTypes } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ConfirmationDialog,
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { APIAuthClient } from "../../../lib";
import { setToast } from "../../../state";
import { useDispatch } from "react-redux";
import { giveawayTimezoneValueNameMapping } from "../../../types/giveaway";
import { formatDateOnlyString } from "../../../../lib";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps<any>;
};
export function ReportRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Id"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Generated on"
                fieldName="created_at"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Data point"
                fieldName="data_point"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Timezone"
                fieldName="date_range_timezone"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Start date"
                fieldName="start_date"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="End date"
                fieldName="end_date"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Marked given out"
                fieldName="marked_given_out"
                params={params}
                setParams={setParams}
              />
              <div />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Reports per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [showConf, setShowConf] = useState(false);
  const {
    id,
    created_at,
    data_point,
    start_date,
    end_date,
    date_range_timezone,
    marked_given_out,
  } = row;

  const download = async () => {
    const url = `/campaign_giveaway_reports/${id}/download`;
    const response = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = response;
    if (error) return dispatch(setToast(errorMessage));

    const downloadLink = document.createElement("a");
    downloadLink.target = "_blank";
    downloadLink.href = data.signedUrl;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    // window.open(data.signedUrl, "_blank");
  };

  const toggleMarkedGivenOut = async () => {
    const url = `/campaign_giveaway_reports/${id}`;
    const body = { marked_given_out: !marked_given_out };
    const response = await APIAuthClient.put<any, APIRes>(url, body);
    const { error, errorMessage } = response;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Changes saved", ToastTypes.success));
    refreshList();
  };

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>
              {format(new Date(created_at as string), "M/d/yyyy',' pp")}
            </div>
            <div>{data_point}</div>
            <div>
              {giveawayTimezoneValueNameMapping[date_range_timezone as any] ||
                "Unknown"}
            </div>
            <div>{formatDateOnlyString(start_date)}</div>
            <div>{formatDateOnlyString(end_date)}</div>
            <div>
              {marked_given_out ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>
              <Button
                onClick={() => setShowConf(true)}
                variant="outlined"
                size="small"
                className={marked_given_out ? classes.unMark : classes.mark}
              >
                {marked_given_out ? "Un-mark" : "Mark"} given out
              </Button>
            </div>
            <div>
              <Button
                onClick={download}
                color="secondary"
                variant="outlined"
                size="small"
              >
                Download
              </Button>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <div>
            <div className={classes.mobileTopRow}>
              <div>
                <div>
                  On: {format(new Date(created_at as string), "M/d/yyyy',' pp")}
                </div>
                <div className={classes.dates}>
                  {formatDateOnlyString(start_date)} -{" "}
                  {formatDateOnlyString(end_date)}
                </div>
              </div>
              <div className={classes.download}>
                <Button
                  onClick={download}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  className={classes.download}
                >
                  Download
                </Button>
              </div>
            </div>

            <div className={classes.mobileBottomRow}>
              Marked given out:
              <Switch
                checked={Boolean(marked_given_out)}
                onChange={() => setShowConf(true)}
              />
            </div>
          </div>
        )}
      </div>

      {showConf && (
        <ConfirmationDialog
          title={`Are you sure you want to ${
            marked_given_out ? "un-" : ""
          }mark this report "Given out"?`}
          actionFunc={toggleMarkedGivenOut}
          onClose={() => setShowConf(false)}
          actionLabel="Save"
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "72px 190px 1fr 136px 112px 112px 170px 200px 104px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 16,
        minWidth: "100%",
      },
    },
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mark: {
    color: "#5FEA6B",
    border: "1px solid #5FEA6B",
  },
  unMark: {
    color: "#ED2A2A",
    border: "1px solid #ED2A2A",
  },
  mobileTopRow: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flex: 1,
  },
  mobileBottomRow: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
  },
  dates: {
    color: theme.palette.text.secondary,
    paddingTop: 8,
  },
  download: {
    minWidth: "fit-content",
  },
}));
