import { volunteerSetupTypes } from "./actions";

type VolunteerSetupState = {
  class_options?: string[];
  class_name: string;
  goal?: number;
  display_text_html: string;
  picture: null | string;
  team_id?: null | number;
  agreed_to_receive_texts?: boolean;
  setup_completed?: boolean;
  orgName: string;
  first_name: string;
  last_name: string;
  family_members?: null | [];
  webpageLink: string;
  contacts_confirmed: boolean;
  volunteerCampaignId?: number;
};

const initialState: VolunteerSetupState = {
  class_options: [],
  class_name: "",
  display_text_html: "",
  picture: null,
  orgName: "",
  first_name: "",
  last_name: "",
  webpageLink: "",
  contacts_confirmed: false,
};

export const volunteerSetupReducers = (
  state = initialState,
  action: any,
): VolunteerSetupState => {
  switch (action.type) {
    case volunteerSetupTypes.VOLUNTEER_SETUP_DATA_SET:
      return action.payload;
    case volunteerSetupTypes.VOLUNTEER_SETUP_DATA_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
