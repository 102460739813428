import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles, Tabs, Tab } from "@material-ui/core";
import { Toast } from "../../components";
import { Merchants } from "./merchant/Merchants";
import { theme } from "../../theme/theme";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { TemplateCards } from "./card/TemplateCards";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/state";
import { isRep } from "../../../lib";
import { useHistory } from "react-router-dom";
import { CampaignCards } from "./card/CampaignCards";
import { discountCardActions } from "../../state";
import { CustomerCards } from "./card/CustomerCards";
enum Screens {
  Merchants = "Merchants",
  CardTemplates = "CardTemplates",
  CampaignCards = "CampaignCards",
  CustomerCards = "CustomerCards",
}

export function DiscountCardManagement() {
  const classes = styles();
  const history = useHistory();
  const dispatch = useDispatch();
  const sidebarOpen = useSelector(
    ({ admin }: RootState) => admin.ui.sidebarOpen,
  );
  const { role, allowDiscountCards } = useSelector(
    ({ user }: RootState) => user,
  );
  const [activeTab, setActiveTab] = useState<Screens>(Screens.Merchants);

  useEffect(() => {
    if (!isRep(role) || !allowDiscountCards) history.replace("/");
    else dispatch(discountCardActions.fetchDiscountCardCategoryAutocomplete());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, allowDiscountCards]);

  return (
    <ThemeProvider theme={theme}>
      <div
        className={classNames(
          "newApp",
          classes.wrapper,
          sidebarOpen && classes.sidebarOpen,
        )}
      >
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          classes={{ root: classes.tabs }}
        >
          <Tab
            label="Merchants"
            value={Screens.Merchants}
            className={classes.tab}
          />
          <Tab
            label="Card templates"
            value={Screens.CardTemplates}
            className={classes.tab}
          />
          <Tab
            label="Campaign cards"
            value={Screens.CampaignCards}
            className={classes.tab}
          />
          <Tab
            label="Customer cards"
            value={Screens.CustomerCards}
            className={classes.tab}
          />
        </Tabs>

        {activeTab === Screens.Merchants && <Merchants />}
        {activeTab === Screens.CardTemplates && <TemplateCards />}
        {activeTab === Screens.CampaignCards && <CampaignCards />}
        {activeTab === Screens.CustomerCards && <CustomerCards />}

        <Toast />
      </div>
    </ThemeProvider>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    maxWidth: "calc(100vw - 55px)",
    backgroundColor: "#F7F7F7",
    margin: -24,
    marginLeft: -5,
    marginTop: -8,
    paddingTop: 24,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      paddingTop: 8,
      paddingBottom: 0,
      maxWidth: () => "100vw",
    },
  },
  sidebarOpen: {
    maxWidth: "calc(100vw - 240px)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: () => "100vw",
    },
  },
  tabs: {
    borderBottom: "1px solid #DBDEEE",
    margin: "0 32px 18px 32px",
    position: "sticky",
    top: 56,
    backgroundColor: "#F7F7F7",
    zIndex: 9,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      top: 56,
    },
  },
  tab: {
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));
