import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  DateInput,
  regex,
  NumberInput,
} from "react-admin";
import { TWO_DECIMAL_REGEX } from "../../lib";

const requiredMoney = [
  regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only"),
  required(),
];
const requiredInput = [required()];

export default function GiftCardCreate(props) {
  return (
    <Create
      {...props}
      successMessage="Gift Card(s) added successfully"
      title="Add Gift Card(s)"
    >
      <SimpleForm redirect="list">
        <TextInput
          source="batch_identifier"
          label="Batch Identifier (Optional)"
        />
        <NumberInput
          source="numberOfCodesToGenerate"
          label="Number Of Codes To Generate"
          validate={requiredInput}
          defaultValue={1}
        />
        <TextInput source="amount" type="number" validate={requiredMoney} />
        <DateInput
          source="expiration_date"
          label="Expiration Date (Optional)"
        />
      </SimpleForm>
    </Create>
  );
}
