import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(_theme => ({
  container: {
    minHeight: 300,
    margin: "6px 24px 56px 24px",
  },
  warning: {
    color: "#FF0000",
    fontSize: 15,
    marginBottom: 4,
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  orgAndRep: {
    fontWeight: 600,
    fontSize: 16,
  },
  status: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    margin: "24px 0",
    width: "fit-content",
    height: "fit-content",
    minWidth: 300,
  },
  input: {
    width: 130,
  },
  wideInput: {
    width: 300,
  },
  actionContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "38px",
  },
  actionButton: {
    backgroundColor: "#FF0000",
    color: "#FFFFFF",
  },
  paidOn: {
    marginRight: 12,
    width: 218,
  },
  capitalize: {
    textTransform: "capitalize",
  },
  totalFigure: {
    fontWeight: 600,
    fontSize: 15,
  },
  adjustmentsAndFees: {
    display: "flex",
  },
  copyTtl: {
    width: 24,
    paddingLeft: 0,
    paddingRight: "8px !important",
  },
  // MODAL STYLES
  dialog: {
    padding: "24px",
    width: "800px",
    maxWidth: "100%",
  },
  dialogHeader: {
    fontSize: "22px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    paddingBottom: "24px",
  },
  closeIcon: {
    position: "absolute",
    right: 0,
  },
  modalInput: { margin: "12px 0" },
}));
