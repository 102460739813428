import { Button, Dialog, makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { APIAuthClient } from "../lib";
import { APIRes, ToastTypes, paths } from "../types";
import { useDispatch } from "react-redux";
import { setToast } from "../state";
import { useHistory } from "react-router-dom";
import { CircularLoader } from "../components";
import { useLogout } from "../hooks/ui";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  campaign_id: number | null;
};
export function JoinCampaignDialog({ isOpen, onClose, campaign_id }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = useLogout(campaign_id);
  const [loading, setLoading] = useState(true);
  const [campaignName, setCampaignName] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    history.replace(paths.ORG_CAMPAIGNS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const url = `/campaigns/volunteer_eligibility_check?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, status, data } = response;
      if (error) {
        if (status === 404) logout();
        else dispatch(setToast(errorMessage));
        onClose();
        return;
      }
      const { campaignName, alreadyOnCampaign, token } = data;
      if (alreadyOnCampaign) {
        localStorage.setItem("token", token);
        localStorage.setItem("selectedCampaignId", `${campaign_id}`);
        window.location.replace(paths.HOME);
      } else {
        setCampaignName(campaignName);
        setLoading(false);
      }
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const onCancel = () => onClose();

  const join = async () => {
    setSubmitting(true);
    const res = await APIAuthClient.post<any, APIRes>(
      "/volunteer_campaigns/existing_volunteer_join_campaign",
      { campaign_id },
    );
    const { error, errorMessage, data } = res;
    if (error) {
      setSubmitting(false);
      dispatch(setToast(errorMessage));
      return;
    }

    dispatch(setToast("You have joined this campaign", ToastTypes.success));
    localStorage.setItem("token", data.token);
    localStorage.setItem("selectedCampaignId", `${campaign_id}`);
    window.location.replace(paths.VOLUNTEER_GUIDED_SETUP);
  };

  return (
    <Dialog open={isOpen} maxWidth={false}>
      <div className={classes.container}>
        {loading && (
          <div className={classes.loadingWrapper}>
            <CircularLoader show />
          </div>
        )}

        {!loading && (
          <Fragment>
            <div className={classes.header}>
              You are not registered for{" "}
              {campaignName ? `the ${campaignName} campaign` : "this campaign"}.
              Do you want to join?
            </div>

            <div className={classes.buttons}>
              <Button
                color="primary"
                className={classes.register}
                disabled={submitting}
                onClick={join}
              >
                Register
              </Button>
              <Button
                color="primary"
                variant="text"
                onClick={onCancel}
                disabled={submitting}
              >
                CANCEL
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </Dialog>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    width: 600,
    maxWidth: "100%",
    padding: "16px 24px 24px 24px",
  },
  loadingWrapper: {
    height: 140,
    display: "flex",
    alignItems: "center",
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    marginBottom: 40,
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  register: {
    width: 120,
    borderRadius: 18,
    marginRight: 16,
  },
}));
