import React, { Fragment, useEffect, useState } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  Notification,
  DateInput,
  FunctionField,
  useNotify,
  useRefresh,
  BooleanInput,
  AutocompleteArrayInput,
  FormDataConsumer,
} from "react-admin";
import {
  makeStyles,
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  APIClient,
  APIUtilClient,
  generateAuthHeader,
  volunteerSetupScreens,
} from "../../lib";

export function Management({ record }) {
  const {
    id: campaign_id,
    campaign_management_id,
    allow_family_accounts,
  } = record;
  const [showDialog, setShowDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [
    volunteerSetupScreenOptions,
    setVolunteerSetupScreenOptions,
  ] = useState([]);
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = styles();
  const canManage = useSelector(state => state.user.canManage);

  useEffect(() => {
    if (!allow_family_accounts) {
      const options = volunteerSetupScreens.filter(
        ({ tag }) => tag !== "FAMILY_MEMBERS",
      );
      setVolunteerSetupScreenOptions(options);
    } else {
      setVolunteerSetupScreenOptions(volunteerSetupScreens);
    }
  }, [allow_family_accounts]);

  const ManagementToolbar = props => (
    <Toolbar {...props}>
      <SaveButton redirect={false} />
    </Toolbar>
  );

  const transformDataForSubmit = values => {
    const {
      kickoff_date,
      launch_packet_needed_by,
      no_checkpoint_emails,
      notes,
      order_forms_to_org_due_date,
      order_form_due_date,
      order_forms_received_date,
      ship_to_school_delivery_date,
      initial_volunteer_setup_screen_ids,
      no_initial_volunteer_setup,
    } = values;
    return {
      kickoff_date,
      launch_packet_needed_by,
      no_checkpoint_emails,
      notes,
      order_forms_to_org_due_date,
      order_form_due_date,
      order_forms_received_date,
      ship_to_school_delivery_date,
      initial_volunteer_setup_screen_ids,
      no_initial_volunteer_setup,
    };
  };

  const handleReadyForFulfillment = () => setShowDialog(true);

  const markReadyForFulfillment = async () => {
    setSubmitting(true);
    const res = await APIClient.put(
      `/campaign_management/${campaign_management_id}/mark_ready_for_fulfillment`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    refresh();
  };

  return (
    <Fragment>
      <Edit
        title=" "
        id={campaign_management_id}
        basePath={`/campaigns/${campaign_id}/show`}
        resource="campaign_management"
        undoable={false}
        successMessage="Campaign Management updated successfully"
        transform={transformDataForSubmit}
      >
        <SimpleForm toolbar={<ManagementToolbar />}>
          <FunctionField
            addLabel={false}
            render={({ orgEmail, repEmail, ready_for_fulfillment }) => {
              const mailto = `${orgEmail}${repEmail ? `,${repEmail}` : ""}`;
              return (
                <div className={classes.actions}>
                  <div>
                    {ready_for_fulfillment && (
                      <FormControlLabel
                        control={<Checkbox checked={true} disabled />}
                        label="Ready For Fulfillment"
                      />
                    )}
                    {canManage && !ready_for_fulfillment && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.readyButton}
                        onClick={handleReadyForFulfillment}
                        disabled={submitting}
                      >
                        mark ready for fulfillment
                      </Button>
                    )}
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    href={`mailto:${mailto}`}
                  >
                    {`email org & rep`}
                  </Button>
                </div>
              );
            }}
          />
          <DateInput
            source="launch_packet_needed_by"
            label="Launch Packet Needed By"
          />
          <DateInput source="kickoff_date" label="Kickoff Date" />
          <DateInput
            source="order_forms_to_org_due_date"
            label="Order Forms Due To Org"
          />
          <DateInput
            source="order_form_due_date"
            label="Order Forms Due To Company"
          />
          <DateInput
            source="order_forms_received_date"
            label="Order Forms Received On"
          />
          <DateInput
            source="ship_to_school_delivery_date"
            label="Ship To School Delivery Date"
          />
          <BooleanInput source="ship_to_school_shipped" disabled />
          <TextInput source="ship_to_school_shipping_cost" disabled />
          <TextInput source="ship_to_school_tracking" fullWidth disabled />
          <BooleanInput source="no_checkpoint_emails" />

          <BooleanInput
            source="no_initial_volunteer_setup"
            label="Don't show setup screens after campaign registration (go straight to Dashboard)"
            className={classes.fitContent}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (formData.no_initial_volunteer_setup) {
                formData.initial_volunteer_setup_screen_ids = null;
                return <></>;
              }
              return (
                <AutocompleteArrayInput
                  {...rest}
                  label="Customize which setup screens to show after campaign registration (the order you select them doesn't affect the order they are displayed)"
                  source="initial_volunteer_setup_screen_ids"
                  choices={volunteerSetupScreenOptions}
                  translateChoice={false}
                  fullWidth
                />
              );
            }}
          </FormDataConsumer>

          <TextInput source="notes" multiline fullWidth />
        </SimpleForm>
      </Edit>

      <ClearPrerenderCache campaign_id={campaign_id} />

      <Dialog open={showDialog}>
        <DialogTitle>
          Are you sure you want mark this campaign ready for fulfillment?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>You can't undo this action!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            cancel
          </Button>
          <Button onClick={markReadyForFulfillment} color="primary" autoFocus>
            yes
          </Button>
        </DialogActions>
      </Dialog>
      <Notification />
    </Fragment>
  );
}

function ClearPrerenderCache({ campaign_id }) {
  const classes = styles();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async () => {
    setSubmitting(true);
    const res = await APIUtilClient.post(
      `/prerender/clear_campaign_cache?campaign_id=${campaign_id}`,
      {},
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, { type: "warning" });
    notify("Your clear cache request has been submitted.", { type: "info" });
    setShowDialog(false);
  };
  return (
    <Fragment>
      <div className={classes.cacheContainer}>
        <div className={classes.cacheHeader}>Social Media Cache Manager</div>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setShowDialog(true)}
        >
          clear cache
        </Button>
      </div>

      <Dialog open={showDialog}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Clearing cache may temporarily affect your social media tags.
          </DialogContentText>
          <DialogContentText>
            Clearing cache is only necessary if a change has been made to your
            Campaign Banners/Images.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>cancel</Button>
          <Button
            onClick={onSubmit}
            color="primary"
            autoFocus
            disabled={submitting}
          >
            confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Notification />
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  readyButton: {
    marginBottom: 24,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  fitContent: {
    width: "fit-content",
  },

  // ClearPrerenderCache
  cacheContainer: {
    borderTop: "1px solid #808080",
    margin: "24px 0",
  },
  cacheHeader: {
    fontWeight: 600,
    fontSize: 18,
    paddingTop: 12,
    paddingBottom: 12,
  },
}));
