import React, { useState } from "react";
import { APIClient, generateAuthHeader } from "../../lib";
import {
  Button,
  TextField,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNotify, useRefresh } from "react-admin";
import SaveIcon from "@material-ui/icons/Save";
const MIN_2_WORDS = /(\w+\s+[^-])+\S+/;

export const UpdateDonationVolunteer = props => {
  const { volunteers, fetchVolunteers, campaignId, donationId } = props;
  const classes = styles();
  const [volunteerName, setVolunteerName] = useState("");
  const [volunteerId, setVolunteerId] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const onVolunteerChange = (_event, newValue) => {
    switch (true) {
      case newValue === null || newValue === "":
        setVolunteerName("");
        setVolunteerId(null);
        break;
      case typeof newValue === "string":
        setVolunteerName(newValue);
        setVolunteerId(null);
        break;
      case typeof newValue === "object":
        setVolunteerName(newValue.name);
        setVolunteerId(newValue.id);
        break;
      default:
        setVolunteerName("");
        setVolunteerId(null);
        break;
    }
  };

  const onSubmit = async () => {
    if (!volunteerId && !MIN_2_WORDS.test(volunteerName.trim())) {
      return notify("Student must have a first and last name", "warning");
    }
    setSubmitting(true);
    const res = await APIClient.put(
      `/donations/${donationId}/update-volunteer`,
      { campaignId, volunteerId, volunteerName },
      { headers: generateAuthHeader() },
    );
    setSubmitting(false);
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    refresh();
    if (!volunteerId) fetchVolunteers();
  };

  return (
    <div className={classes.container}>
      <Autocomplete
        className={classes.input}
        inputValue={volunteerName}
        freeSolo
        selectOnFocus
        onInputChange={onVolunteerChange}
        onChange={onVolunteerChange}
        options={volunteers}
        getOptionLabel={option => option.name || option}
        renderInput={params => (
          <TextField
            {...params}
            placeholder="Search or Add"
            variant="standard"
          />
        )}
      />

      <Button
        className={classes.button}
        onClick={onSubmit}
        disabled={submitting || (!volunteerId && !volunteerName)}
      >
        {submitting ? (
          <CircularProgress size={18} className={classes.spinner} />
        ) : (
          <SaveIcon />
        )}
      </Button>
    </div>
  );
};

const styles = makeStyles(theme => ({
  input: {
    minWidth: 162,
  },
  container: {
    display: "flex",
    alignItems: "center",
    width: 205,
    border: "1px solid lightgray",
    padding: 4,
  },
  button: {
    width: 42,
    minWidth: 42,
  },
  spinner: { position: "absolute", color: theme.palette.primary.main },
}));
