import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  theme => ({
    // create/edit styles
    paperContainer: {
      marginTop: 24,
    },
    enabledSuppliers: {
      minWidth: 200,
    },
    logo: {
      maxWidth: "200px",
    },
    inlineField: {
      display: "inline-flex",
      marginRight: "1rem",
    },
    supplierOptions: {
      width: "fit-content",
      "& .MuiFormHelperText-root": {
        lineHeight: 0,
      },
    },
    supplierRateType: {
      width: 380,
      "& .MuiFormHelperText-root": {
        lineHeight: 0,
      },
    },
    noHelperTxt: {
      "& .MuiFormHelperText-root": {
        lineHeight: 0,
      },
    },

    // drawer styles
    drawerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 10px 0 16px",
      fontSize: 18,
    },
    drawerContent: {
      padding: 20,
      width: "100%",
      minWidth: 900,
      maxWidth: "100%",
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
    },
  }),
  {
    classNamePrefix: "company",
  },
);
