import React, { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  required,
  TopToolbar,
  NumberInput,
  SimpleForm,
  Notification,
  useNotify,
  useListContext,
  Edit,
  SelectInput,
  NumberField,
  FunctionField,
  Toolbar,
  SaveButton,
  DeleteWithConfirmButton,
} from "react-admin";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import {
  prizeProgramTierRuleDataPointOptions,
  prizeProgramTierRuleDataPoints,
} from "../../lib";

export function CompanyPrizeProgramTierRules({
  company_prize_program_tier_id,
  basePath,
  hasTierNotifications,
}) {
  const classes = styles();
  const [showCreate, setShowCreate] = useState(false);

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={() => setShowCreate(true)}
      >
        add rule
      </Button>
    </TopToolbar>
  );

  return (
    <div className={classes.container}>
      <List
        resource="company_prize_program_tier_rules"
        basePath={basePath}
        perPage={25}
        title=" "
        bulkActionButtons={false}
        exporter={false}
        empty={false}
        filter={{ company_prize_program_tier_id }}
        actions={<Actions />}
        component="div"
      >
        <GridWrapper
          company_prize_program_tier_id={company_prize_program_tier_id}
          basePath={basePath}
          showCreate={showCreate}
          setShowCreate={setShowCreate}
          hasTierNotifications={hasTierNotifications}
        />
      </List>
    </div>
  );
}

const GridWrapper = ({
  company_prize_program_tier_id,
  basePath,
  showCreate,
  setShowCreate,
  hasTierNotifications,
  ...props
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  return (
    <div>
      <Datagrid
        {...props}
        rowClick={(id, basePath, record) => {
          setSelectedRow({ id, basePath, record });
          setShowEdit(true);
        }}
      >
        <TextField source="id" />
        <FunctionField
          source="data_point"
          label="Data Point"
          render={({ data_point }) =>
            prizeProgramTierRuleDataPoints[data_point]
              ? prizeProgramTierRuleDataPoints[data_point].name
              : data_point
          }
        />
        <NumberField source="minimum" />
      </Datagrid>
      <AddRule
        basePath={basePath}
        showCreate={showCreate}
        setShowCreate={setShowCreate}
        company_prize_program_tier_id={company_prize_program_tier_id}
        hasTierNotifications={hasTierNotifications}
      />
      <EditRule
        selectedRow={selectedRow}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        hasTierNotifications={hasTierNotifications}
      />
    </div>
  );
};

function AddRule({
  showCreate,
  setShowCreate,
  basePath,
  company_prize_program_tier_id,
  hasTierNotifications,
}) {
  const classes = styles();
  const requiredField = [required()];
  const notify = useNotify();
  const { refetch } = useListContext();

  const transformDataForSubmit = values => {
    return { company_prize_program_tier_id, ...values };
  };

  return (
    <Drawer open={showCreate} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Add a Rule</div>
          <IconButton onClick={() => setShowCreate(false)} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Create
          resource="company_prize_program_tier_rules"
          basePath={basePath}
          onSuccess={() => {
            refetch();
            notify("Rule added successfully");
            setShowCreate(false);
          }}
          transform={transformDataForSubmit}
        >
          <SimpleForm>
            <SelectInput
              source="data_point"
              label="Data Point"
              choices={prizeProgramTierRuleDataPointOptions}
              translateChoice={false}
              validate={requiredField}
              fullWidth
            />
            <NumberInput source="minimum" validate={requiredField} fullWidth />
            {hasTierNotifications && (
              <NumberInput
                source="minimum_approaching_tier_notification_trigger"
                label="Minimum To Trigger Approaching Tier Notification To Student (optional)"
                fullWidth
              />
            )}
          </SimpleForm>
        </Create>
      </div>
      <Notification />
    </Drawer>
  );
}

function EditRule({
  showEdit,
  setShowEdit,
  selectedRow,
  hasTierNotifications,
}) {
  const { id, basePath = "" } = selectedRow;
  const classes = styles();
  const requiredField = [required()];
  const notify = useNotify();
  const { refetch } = useListContext();

  const transformDataForSubmit = values => {
    const { id: _rm, ...rest } = values;
    return rest;
  };

  const closeAndRefetch = () => {
    refetch();
    setShowEdit(false);
  };

  if (!id) return <></>;
  return (
    <Drawer open={showEdit} anchor="right">
      <div className={classes.drawer}>
        <div className={classes.drawerHeader}>
          <div>Edit Rule</div>
          <IconButton onClick={closeAndRefetch} size="small">
            <CloseIcon />
          </IconButton>
        </div>
        <Edit
          resource="company_prize_program_tier_rules"
          basePath={basePath}
          id={id}
          undoable={false}
          onSuccess={() => {
            closeAndRefetch();
            notify("Rule updated successfully");
          }}
          transform={transformDataForSubmit}
        >
          <SimpleForm
            toolbar={
              <Toolbar>
                <SaveButton />
                <DeleteWithConfirmButton
                  basePath={basePath}
                  onSuccess={() => {
                    closeAndRefetch();
                    notify("Rule removed successfully");
                  }}
                  undoable={false}
                  className={classes.delete}
                />
              </Toolbar>
            }
          >
            <SelectInput
              source="data_point"
              label="Data Point"
              choices={prizeProgramTierRuleDataPointOptions}
              translateChoice={false}
              validate={requiredField}
              fullWidth
            />
            <NumberInput source="minimum" validate={requiredField} fullWidth />
            {hasTierNotifications && (
              <NumberInput
                source="minimum_approaching_tier_notification_trigger"
                label="Minimum To Trigger Approaching Tier Notification To Student (optional)"
                fullWidth
              />
            )}
          </SimpleForm>
        </Edit>
      </div>
      <Notification />
    </Drawer>
  );
}

const styles = makeStyles(
  theme => ({
    container: {
      margin: "12px 16px",
    },
    drawer: {
      minWidth: 900,
      maxWidth: "100%",
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      },
    },
    drawerHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "10px 10px 0 16px",
      fontSize: 18,
    },
    delete: {
      position: "absolute",
      right: 16,
    },
  }),
  {
    classNamePrefix: "rules",
  },
);
