import React from "react";
import {
  Show,
  Tab,
  Edit,
  SimpleForm,
  TextInput,
  required,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
} from "react-admin";
import { DigitalContentInventoryGroupProducts } from "./DigitalContentInventoryGroupProducts";
import { ProductOrdering } from "../../components";

const requiredField = [required()];

export default function DigitalContentInventoryGroupShow(props) {
  return (
    <Show
      {...props}
      title="Edit Digital Content Inventory Group"
      actions={null}
    >
      <TabbedShowLayout
        tabs={
          <TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" />
        }
      >
        <Tab label="edit inventory group" path="">
          <Edit
            {...props}
            undoable={false}
            actions={null}
            component="div"
            successMessage="Digital Content Inventory Group updated successfully"
            title=" "
          >
            <SimpleForm redirect="list">
              <TextInput source="id" disabled />
              <TextInput source="group_name" validate={requiredField} />
            </SimpleForm>
          </Edit>
        </Tab>
        <Tab label="inventory group products" path="inventory-group-products">
          <DigitalContentInventoryGroupProducts />
        </Tab>
        <Tab label="product ordering" path="group-product-ordering">
          <ProductOrdering forDigitalContentGroup />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
