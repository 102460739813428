// cSpell:ignore Swipeable
import { ReactNode, useEffect } from "react";
import { SwipeableDrawer, makeStyles } from "@material-ui/core";
import { useIsDesktop } from "../../hooks/ui";

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export function FlatTopDrawer({ children, isOpen, onClose }: Props) {
  const classes = styles();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (isDesktop) onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={onClose}
      onOpen={() => {}}
      classes={{ paper: classes.drawer }}
      anchor="bottom"
      transitionDuration={500}
    >
      <div className={classes.wrapper}>{children}</div>
    </SwipeableDrawer>
  );
}

const styles = makeStyles(_theme => ({
  drawer: {
    top: "auto",
    maxHeight: "unset",
  },
  wrapper: {
    width: "100vw",
    maxWidth: "100vw",
    minWidth: "100vw",
    display: "flex",
    flex: 1,
    overflow: "scroll",
  },
}));
