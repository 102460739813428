import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  requiredField,
  requiredPositiveInteger,
} from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  ConfirmationDialog,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { RedButton } from "../../../components/ui/RedButton";
import { RootState } from "../../../types/state";

type Props = {
  onClose: () => void;
  refreshList: () => void;
  record: GenericObject;
};

export function EditTier({ onClose: _onClose, refreshList, record }: Props) {
  const { id } = record;
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [showDelete, setShowDelete] = useState(false);
  const { data_point = "" } = useSelector(
    ({ giveaway }: RootState) => giveaway,
  );

  useEffect(() => {
    setInitialValues(record);
  }, [record]);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const { sort_order, min_threshold, giveaway_name, campaign_id } = values;
    const update = {
      sort_order: Number(sort_order),
      min_threshold: Number(min_threshold),
      giveaway_name,
      campaign_id,
    };
    const url = `campaign_giveaway_tiers/${id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    onClose();
    refreshList();
  };

  const onDelete = async () => {
    const url = `/campaign_giveaway_tiers/${id}`;
    const res = await APIAuthClient.delete<any, APIRes>(url);
    const { error, errorMessage } = res;
    if (error) return dispatch(setToast(errorMessage));
    dispatch(setToast("Tier deleted", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, form, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.top}>
                <h1>Edit tier</h1>
                {isMobile && (
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>

              <div className={classes.mainContent}>
                <Field
                  component={TextFieldWrapper}
                  name="giveaway_name"
                  label="Giveaway name"
                  validate={requiredField}
                  className={classes.input}
                />
                <Field
                  component={TextFieldWrapper}
                  name="sort_order"
                  label="Tier sort order"
                  validate={requiredPositiveInteger}
                  className={classes.input}
                  type="number"
                />
                <div className={classes.dataPoint}>
                  <span className={classes.dataPointLabel}>Data point:</span>{" "}
                  {data_point}
                </div>
                <Field
                  component={TextFieldWrapper}
                  name="min_threshold"
                  label="Minimum Threshold"
                  validate={requiredPositiveInteger}
                  className={classes.input}
                  type="number"
                />
              </div>

              <div className={classes.bottom}>
                <div>
                  <RedButton
                    variant="text"
                    disabled={submitting}
                    onClick={() => setShowDelete(true)}
                    startIcon={<DeleteIcon />}
                  >
                    DELETE
                  </RedButton>
                </div>
                <div className={classes.saveAndCancel}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={pristine || submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
      {showDelete && (
        <ConfirmationDialog
          title="Are you sure you want to delete this tier?"
          actionFunc={onDelete}
          onClose={() => setShowDelete(false)}
          redButton
          actionLabel="Delete"
        />
      )}
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      position: "sticky",
      top: 0,
      minHeight: "unset",
      borderBottom: "none",
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: "24px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 0 16px",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  dataPointLabel: {
    fontWeight: 600,
  },
  dataPoint: {
    fontSize: 16,
    color: theme.palette.primary.main,
    paddingTop: 16,
    paddingBottom: 20,
  },
  bottom: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 24,
    position: "sticky",
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column-reverse",
      padding: 16,
      paddingBottom: 8,
    },
  },
  saveAndCancel: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 12,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
