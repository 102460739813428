import { Fragment, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { formatDateOnlyString } from "../../../../lib";
import { DiscountCardDetails } from "./DiscountCardDetails";
import { formatMoneyUnPadded } from "../../../lib";
import classNames from "classnames";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps<any>;
};
export function TemplateCardRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Id"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Template name"
                fieldName="card_name"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Expires on"
                fieldName="expires_on"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Active"
                fieldName="active"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Estimated value"
                fieldName="estimated_card_value"
                params={params}
                setParams={setParams}
              />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Card templates per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);
  const { id, active, card_name, expires_on, estimated_card_value } = row;

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>{card_name}</div>

            <div>{formatDateOnlyString(expires_on)}</div>
            <div>
              {active ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>{formatMoneyUnPadded(estimated_card_value)}</div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div
                className={classNames(
                  classes.status,
                  !active && classes.inactiveStatus,
                )}
              />
              <div className={classes.mobileContent}>
                <div className={classes.id}>#{id}</div>
                <div className={classes.name}>{card_name}</div>
                <div className={classes.exp}>
                  <div>Expires {formatDateOnlyString(expires_on)}</div>
                </div>
              </div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <DiscountCardDetails
          onClose={() => setShowEdit(false)}
          refreshFunc={refreshList}
          id={id}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "132px 1fr 180px 180px 180px 52px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  mobileContent: {
    overflow: "hidden",
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  id: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      lineHeight: "18px",
    },
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      paddingTop: 4,
      paddingBottom: 6,
    },
  },
  exp: {
    lineHeight: "16px",
    color: theme.palette.text.secondary2,
  },
  status: {
    height: 8,
    width: 8,
    minWidth: 8,
    borderRadius: "100%",
    backgroundColor: "#5FEA6B",
    marginRight: 16,
  },
  inactiveStatus: {
    backgroundColor: "#ED2A2A",
  },
}));
