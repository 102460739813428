import React, { useEffect, useState, Fragment } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  SelectInput,
  useNotify,
  NumberInput,
  DateTimeInput,
  FormDataConsumer,
} from "react-admin";
import { APIClient, generateAuthHeader, isProductCampaign } from "../../lib";

const requiredInput = [required()];

export function CampaignReservationCreate(props) {
  const location = props.location;
  const notify = useNotify();
  const [initialValues, setInitialValues] = useState({});
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [productSuppliers, setProductSuppliers] = useState([]);
  const [productGroups, setProductGroups] = useState({});

  useEffect(() => {
    const org_id = location.state ? parseInt(location.state.org_id) : null;

    if (!org_id) {
      return notify(
        "we can't find your Organization ID, please start again",
        "warning",
      );
    }
    setInitialValues({ org_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/campaign_types/company_authorized", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      const { campaignTypes, productSuppliersWithPGs, productGroups } = data;
      setCampaignTypes(campaignTypes);
      setProductSuppliers(productSuppliersWithPGs);
      setProductGroups(productGroups);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Create
      {...props}
      successMessage="Campaign Reservation added successfully"
      title="Add Campaign Reservation"
    >
      <SimpleForm redirect="list" initialValues={initialValues}>
        <TextInput
          source="name"
          label="Campaign Name"
          validate={requiredInput}
        />
        <NumberInput source="student_count" label="Student Count" step={1} />
        <DateTimeInput source="start_date" label="Site Open Date" />
        <DateTimeInput source="end_date" label="Site Close Date" />
        <SelectInput
          source="campaign_type_id"
          label="Select a campaign type"
          choices={campaignTypes}
          resettable
          translateChoice={false}
        />

        <FormDataConsumer>
          {({ formData, ...rest }) => {
            const { campaign_type_id, product_supplier_id } = formData;
            const isProduct = isProductCampaign(campaign_type_id);
            if (!isProduct) {
              formData.product_supplier_id = null;
              formData.supplier_product_group_id = null;
              return <></>;
            }
            if (!product_supplier_id) formData.supplier_product_group_id = null;
            return (
              <Fragment>
                <SelectInput
                  {...rest}
                  source="product_supplier_id"
                  label="Select a Product Supplier"
                  choices={productSuppliers}
                  optionText="supplier_name"
                  resettable
                  translateChoice={false}
                />
                <div />
                {product_supplier_id && (
                  <SelectInput
                    {...rest}
                    source="supplier_product_group_id"
                    label="Select a Product Group"
                    choices={productGroups[product_supplier_id]}
                    optionText="group_name"
                    resettable
                    translateChoice={false}
                  />
                )}
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
}
