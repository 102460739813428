import { Fragment } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { Input } from "./OrgProfitInput";
import { FieldArray } from "react-final-form-arrays";
import {
  formatMoney,
  twoDecimalsOnly,
  required,
  composeValidators,
  calcAndFormatPercentAmount,
  calcPercentAmount,
  formatSum,
} from "../../../lib";
import { styles } from "./OrgProfit.styles";

export function ProfitWorksheet({ opData, disableEdit, formData, isProduct }) {
  const classes = styles();
  const {
    donationTotal,
    onlineDonationTotal,
    offlineDonationTotal,
    onlineDonationCount,
    offlineDonationCount,
    hasCustomProfitProducts,
    hasOfflineDonation,
  } = opData;
  const { donation_profit_percent } = formData;

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table size="small">
        <caption>Profit Worksheet</caption>
        <TableHead>
          <TableRow>
            <TableCell>Profit Category</TableCell>
            {isProduct && <TableCell>Online/Offline</TableCell>}
            <TableCell>Qty</TableCell>
            <TableCell>Total Raised</TableCell>
            {isProduct && hasCustomProfitProducts && (
              <TableCell>Is Custom Profit</TableCell>
            )}
            <TableCell>Profit %</TableCell>
            <TableCell>Profit</TableCell>
            {(isProduct || hasOfflineDonation) && (
              <TableCell>Offline Deduction</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name="categoryAggregates">
            {({ fields }) => {
              return fields.map((row, index) => {
                const {
                  profit_category_name,
                  isOnline,
                  total,
                  itemsQty,
                  isCustomProductProfit,
                  org_profit_online_percent,
                  org_profit_offline_percent,
                  profit,
                } = fields.value[index];
                const name = isOnline
                  ? "org_profit_online_percent"
                  : "org_profit_offline_percent";
                const percent = isOnline
                  ? org_profit_online_percent
                  : org_profit_offline_percent;
                const useProfit = isCustomProductProfit
                  ? profit
                  : calcPercentAmount(total, percent);
                return (
                  <TableRow key={index}>
                    <TableCell>{profit_category_name}</TableCell>
                    <TableCell>{isOnline ? "Online" : "Offline"}</TableCell>
                    <TableCell>{formatSum(itemsQty)}</TableCell>
                    <TableCell>{formatMoney(total)}</TableCell>
                    {hasCustomProfitProducts && (
                      <TableCell>
                        {isCustomProductProfit ? <CheckIcon /> : ""}
                      </TableCell>
                    )}
                    <TableCell>
                      {isCustomProductProfit ? (
                        "N/A"
                      ) : (
                        <Input
                          name={`${row}.${name}`}
                          type="number"
                          isPercent
                          disabled={disableEdit}
                          validate={composeValidators(
                            required,
                            twoDecimalsOnly,
                          )}
                        />
                      )}
                    </TableCell>
                    <TableCell>{formatMoney(useProfit)}</TableCell>
                    <TableCell>
                      {isOnline
                        ? ""
                        : formatMoney(getDeduction(total, useProfit))}
                    </TableCell>
                  </TableRow>
                );
              });
            }}
          </FieldArray>
          {(!isProduct || donationTotal > 0) && (
            <Fragment>
              <TableRow>
                <TableCell>{!isProduct && "Online "}Donations</TableCell>
                {isProduct && <TableCell>Online</TableCell>}
                <TableCell>{formatSum(onlineDonationCount)}</TableCell>
                <TableCell>{formatMoney(onlineDonationTotal)}</TableCell>
                {isProduct && hasCustomProfitProducts && <TableCell />}
                <TableCell>
                  <Input
                    name="donation_profit_percent"
                    type="number"
                    isPercent
                    disabled={disableEdit}
                    validate={composeValidators(required, twoDecimalsOnly)}
                  />
                </TableCell>
                <TableCell>
                  {calcAndFormatPercentAmount(
                    onlineDonationTotal,
                    donation_profit_percent,
                  )}
                </TableCell>
                {(isProduct || hasOfflineDonation) && <TableCell />}
              </TableRow>
              {hasOfflineDonation && (
                <TableRow>
                  <TableCell>{!isProduct && "Offline "}Donations</TableCell>
                  {isProduct && <TableCell>Offline</TableCell>}
                  <TableCell>{formatSum(offlineDonationCount)}</TableCell>
                  <TableCell>{formatMoney(offlineDonationTotal)}</TableCell>
                  {isProduct && hasCustomProfitProducts && <TableCell />}
                  <TableCell>
                    <Input
                      name="donation_profit_percent"
                      type="number"
                      isPercent
                      disabled
                    />
                  </TableCell>
                  <TableCell>
                    {calcAndFormatPercentAmount(
                      offlineDonationTotal,
                      donation_profit_percent,
                    )}
                  </TableCell>
                  <TableCell>
                    {formatMoney(
                      getDeduction(
                        offlineDonationTotal,
                        calcPercentAmount(
                          offlineDonationTotal,
                          donation_profit_percent,
                        ),
                      ),
                    )}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function getDeduction(ttl = 0, profit = 0) {
  if (ttl <= 0) return 0;
  return Number(ttl) - Number(profit);
}
