import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { getCampaignId } from "../../state";
import { TeamQueryParams, teamSortFields } from "../../types";
import {
  useIsDesktop,
  useIsLGScreen,
  useIsMobile,
  useScrollToTop,
} from "../../hooks/ui";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { ListContainer, ListNoResults, MobileListSort } from "../../components";
import { TeamRows } from "./components/TeamRows";
import { ReactComponent as NoTeamsSVG } from "../../assets/NoTeams.svg";
import { Search } from "./components/Search";
import { AddTeam } from "./components/AddTeam";
import classNames from "classnames";
import { useSetTopBarTitle } from "../../hooks/ui";
import { useList } from "../../hooks/list";

export function Teams() {
  const campaignId = useSelector(getCampaignId);
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  const isLargeScreen = useIsLGScreen();
  const classes = styles();
  const [isReady, setIsReady] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [openTeamId, setOpenTeamId] = useState<null | number>(null);

  useEffect(() => {
    if (campaignId) setIsReady(true);
  }, [campaignId]);

  const listProps = useList<TeamQueryParams>({
    baseUrl: `/teams/list`,
    isReady,
    initialState: {
      sort: "team_name",
      order: "asc",
      active_only: true,
      campaign_id: campaignId,
    },
  });
  const {
    params: { active_only },
    listIsFiltered,
    total,
    noResults,
    refreshList,
    setParams,
    showRows,
  } = listProps;

  useScrollToTop();
  useSetTopBarTitle(<h1 className={classes.title}>Teams</h1>);

  const AllTeamsToggle = () => (
    <div className={classes.toggle}>
      <div
        className={classNames(classes.toggleOption, {
          [classes.activeOption]: active_only,
        })}
        onClick={active_only ? undefined : () => toggleActiveTeams(true)}
      >
        This campaign's teams
      </div>
      <div
        className={classNames(classes.toggleOption, {
          [classes.activeOption]: !active_only,
        })}
        onClick={!active_only ? undefined : () => toggleActiveTeams(false)}
      >
        All teams
      </div>
    </div>
  );

  const toggleActiveTeams = (activeOnly: boolean) => {
    setParams(prev => {
      return { ...prev, page: 0, active_only: activeOnly };
    });
  };

  return (
    <ListContainer listProps={listProps}>
      {isMobile && (
        <div className={classes.topSection}>
          <div>
            <h1 className={classes.title}>Teams</h1>
            <div className={classes.teamCount}>
              {total} team
              {total !== 1 ? "s" : ""}
            </div>
          </div>
          <div>
            <IconButton onClick={() => setShowSearch(true)}>
              <SearchIcon />
            </IconButton>
            <IconButton
              onClick={() => setShowSort(true)}
              className={classes.sort}
            >
              <SwapVertIcon />
            </IconButton>
            <IconButton onClick={() => setShowAdd(true)}>
              <AddIcon />
            </IconButton>
          </div>
        </div>
      )}

      <div className={classes.searchAndActionsRow}>
        <div className={classes.searchAndToggle}>
          <Search
            setParams={setParams}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
          />
          {isLargeScreen && <div className={classes.toggleSpacer} />}
          {isLargeScreen && <AllTeamsToggle />}
        </div>

        {isDeskTop && (
          <div>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setShowAdd(true)}
              className={classes.add}
            >
              Team
            </Button>
          </div>
        )}
      </div>
      {!isLargeScreen && (
        <div className={classes.toggleWrapper}>
          <AllTeamsToggle />
        </div>
      )}

      {noResults && (
        <ListNoResults
          SVG={NoTeamsSVG}
          listIsFiltered={listIsFiltered}
          label="teams"
          paragraph="Maximize the success of your campaign by utilizing the power of teams to amplify fundraising efforts."
          buttonLabel="Create a team"
          buttonFunc={() => setShowAdd(true)}
        />
      )}

      {showRows && (
        <TeamRows
          openTeamId={openTeamId}
          setOpenTeamId={setOpenTeamId}
          listProps={listProps}
        />
      )}

      <AddTeam
        isOpen={showAdd}
        onClose={() => setShowAdd(false)}
        refreshList={refreshList}
      />
      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={teamSortFields}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0 8px 0",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  teamCount: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  sort: {
    marginRight: 8,
    marginLeft: 8,
  },
  searchAndActionsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  searchAndToggle: {
    display: "flex",
    alignItems: "center",
  },
  toggleSpacer: {
    marginLeft: 16,
  },
  toggleWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 24,
  },
  add: {
    width: 100,
  },
  toggle: {
    width: 380,
    maxWidth: "100%",
    height: 36,
    borderRadius: 18,
    backgroundColor: "#E5E6EB",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      height: 30,
    },
  },
  toggleOption: {
    width: "50%",
    height: 36,
    borderRadius: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.secondary2,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 30,
      fontSize: 12,
      letterSpacing: 0.26,
    },
  },
  activeOption: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: "unset",
  },
  mobileLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 16,
  },
  loading: {
    marginLeft: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    color: theme.palette.text.secondary,
  },
  loader: {
    color: theme.palette.secondary2.main,
  },
}));
