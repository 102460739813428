import { Button, Theme, makeStyles } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/CallOutlined";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "../../../types/state";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { paths } from "../../../types";
import { getVolunteerLabel } from "../../../state";
import { formatNumber } from "../../../lib";
import { useIsMobile } from "../../../hooks/ui";

export function VolunteerOverview() {
  const history = useHistory();
  const isMobile = useIsMobile();
  const {
    volunteerCampaignParticipationStatsFetched: statsFetched,
    volunteerCampaignParticipationStats,
  } = useSelector(({ dashboardData }: RootState) => dashboardData);
  const {
    emailCount,
    phoneCount,
    volunteerCount,
  } = volunteerCampaignParticipationStats;
  const { volunteerLabel, volunteerLabelLC } = useSelector(getVolunteerLabel);
  const { student_count } = useSelector(({ campaign }: RootState) => campaign);
  const show4Cols = Boolean(student_count);
  const classes = styles({ show4Cols });
  const participationPercent = getPercent(student_count, volunteerCount);

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.title}>{volunteerLabel} overview</div>
        <Button
          variant="text"
          color="primary"
          size="small"
          className={classes.manageButton}
          onClick={() => history.push(paths.VOLUNTEERS)}
        >
          manage {volunteerLabel}
        </Button>
      </div>

      <div className={classes.cards}>
        {!statsFetched && (
          <div className={classes.skeletons}>
            <Skeleton variant="rect" className={classes.skeleton} />
            <Skeleton variant="rect" className={classes.skeleton} />
            <Skeleton variant="rect" className={classes.skeleton} />
            {show4Cols && (
              <Skeleton variant="rect" className={classes.skeleton} />
            )}
          </div>
        )}
        {statsFetched && (
          <Fragment>
            <div className={classes.card}>
              {isMobile && (
                <Fragment>
                  <PeopleIcon className={classes.icon} fontSize="large" />
                  <div>
                    <div className={classes.number}>
                      {formatNumber(volunteerCount)}
                    </div>
                    <div className={classes.txt}>
                      Participating {volunteerLabelLC}
                    </div>
                  </div>
                </Fragment>
              )}
              {!isMobile && (
                <Fragment>
                  <div>
                    <PeopleIcon className={classes.icon} fontSize="large" />
                    <div className={classes.number}>
                      {formatNumber(volunteerCount)}
                    </div>
                  </div>
                  <div className={classes.txt}>
                    <div>Participating</div>
                    <div>{volunteerLabelLC}</div>
                  </div>
                </Fragment>
              )}
            </div>
            <div className={classes.separator} />

            <div className={classes.card}>
              {isMobile && (
                <Fragment>
                  <EmailIcon className={classes.icon} fontSize="large" />
                  <div>
                    <div className={classes.number}>
                      {formatNumber(emailCount)}
                    </div>
                    <div className={classes.txt}>
                      Contacts with email addresses
                    </div>
                  </div>
                </Fragment>
              )}
              {!isMobile && (
                <Fragment>
                  <div>
                    <EmailIcon className={classes.icon} fontSize="large" />
                    <div className={classes.number}>
                      {formatNumber(emailCount)}
                    </div>
                  </div>
                  <div className={classes.txt}>
                    <div>Contacts with</div>
                    <div>email addresses</div>
                  </div>
                </Fragment>
              )}
            </div>
            <div className={classes.separator} />

            <div className={classes.card}>
              {isMobile && (
                <Fragment>
                  <PhoneIcon className={classes.icon} fontSize="large" />
                  <div>
                    <div className={classes.number}>
                      {formatNumber(phoneCount)}
                    </div>
                    <div className={classes.txt}>
                      Contacts with phone numbers
                    </div>
                  </div>
                </Fragment>
              )}
              {!isMobile && (
                <Fragment>
                  <div>
                    <PhoneIcon className={classes.icon} fontSize="large" />
                    <div className={classes.number}>
                      {formatNumber(phoneCount)}
                    </div>
                  </div>
                  <div className={classes.txt}>
                    <div>Contacts with</div>
                    <div>phone numbers</div>
                  </div>
                </Fragment>
              )}
            </div>
            {show4Cols && <div className={classes.separator} />}

            {show4Cols && (
              <div className={classNames(classes.card, classes.graphCard)}>
                <div className={classes.graphWrapper}>
                  <div className={classes.graphTxt}>{participationPercent}</div>
                  <div
                    className={classes.graph}
                    style={{
                      background: `radial-gradient(white 55%, transparent 56%), conic-gradient(#FD7B6F 0% ${participationPercent}, #FFD1D2 ${participationPercent} 100%)`,
                    }}
                  />
                </div>
                <div className={classes.txt}>
                  <div>Of all {volunteerLabelLC}</div>
                  <div>are participating</div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}

function getPercent(totalStudents: any, registered: any) {
  if (!totalStudents || !registered) return "0%";
  const _percent = Math.round((registered / totalStudents) * 100);
  const percent = _percent > 100 ? 100 : _percent;
  return `${percent}%`;
}

type StyleProps = {
  show4Cols?: boolean;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: "100%",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      paddingBottom: 16,
      marginBottom: 32,
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingBottom: 32,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      textWrap: "nowrap",
    },
  },
  manageButton: {
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  cards: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  skeletons: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
      flexDirection: "column",
    },
  },
  skeleton: {
    height: 182,
    width: ({ show4Cols }) =>
      show4Cols ? "calc(25% - 18px)" : "calc(33.3333% - 16px)",
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
    [theme.breakpoints.down("sm")]: {
      width: () => "100%",
      height: 100,
      marginBottom: 16,
      "&:last-of-type": {
        marginBottom: 0,
      },
    },
  },
  card: {
    width: ({ show4Cols }) =>
      show4Cols ? "calc(25% - 18px)" : "calc(33.3333% - 16px)",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      width: () => "100%",
      flexDirection: "row",
      justifyContent: "unset",
      padding: "0 16px",
    },
  },
  icon: {
    color: theme.palette.secondary2.main,
    marginBottom: 12,
    [theme.breakpoints.down("sm")]: {
      marginRight: 24,
      marginBottom: 0,
      marginTop: 6,
    },
  },
  graphCard: {
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  graphWrapper: {
    width: 74,
    height: 74,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginRight: 24,
    },
  },
  graphTxt: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: 0,
  },
  graph: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
  },
  number: {
    fontSize: 32,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: "35px",
    marginBottom: 12,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 6,
      fontSize: 24,
      lineHeight: "26px",
    },
  },
  txt: {
    fontSize: 14,
    letterSpacing: 0.47,
    lineHeight: "19px",
  },
  separator: {
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #EAEBF3",
      marginBottom: 16,
      height: 16,
    },
  },
}));
