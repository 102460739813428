import { Theme, makeStyles, Tabs, Tab } from "@material-ui/core";
import { useSelector } from "react-redux";
import CountdownComp from "react-countdown-now";
import { RootState } from "../../../types/state";
import { useEffect, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import classNames from "classnames";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import { isFuture, isPast } from "date-fns";

export function Progress() {
  const classes = styles({});
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const {
    show_volunteer_item_progress,
    show_profit_based_progress,
    start_date,
    end_date,
  } = useSelector(({ campaign }: RootState) => campaign);
  const { campaignProgressStatsFetched, campaignProgressStats } = useSelector(
    ({ dashboardData }: RootState) => dashboardData,
  );
  const [activeTab, setActiveTab] = useState(0);
  const [showCountdown, setShowCountdown] = useState(false);
  const {
    volunteerFormattedRaised,
    volunteerPercentOfGoal,
    volunteerPercentRaised,
    volunteerFormattedGoal,
    orgFormattedRaised,
    orgPercentOfGoal,
    orgPercentRaised,
    orgFormattedGoal,
  } = campaignProgressStats;

  useEffect(() => {
    if (
      start_date &&
      end_date &&
      isPast(new Date(start_date)) &&
      isFuture(new Date(end_date))
    ) {
      setShowCountdown(true);
    }
  }, [start_date, end_date]);

  if (!campaignProgressStatsFetched) {
    return (
      <div className={classes.container}>
        <h3 className={classes.title}>Campaign overview</h3>
        <Skeleton
          variant="rect"
          width={"100%"}
          height={164}
          className={classes.skeleton}
        />
        <Skeleton
          variant="rect"
          width={"100%"}
          height={164}
          className={classes.skeleton}
        />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <h3 className={classes.title}>Campaign overview</h3>
      {isMobile && (
        <div className={classes.mobileContent}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            classes={{ root: classes.tabs }}
          >
            <Tab label="my progress" className={classes.tab} />
            <Tab label="campaign progress" className={classes.tab} />
          </Tabs>
          {activeTab === 0 && (
            <MobileProgressCard
              isVolunteer
              raised={volunteerFormattedRaised}
              goal={volunteerFormattedGoal}
              percentRaised={volunteerPercentRaised}
              percentOfGoal={volunteerPercentOfGoal}
              itemBased={Boolean(show_volunteer_item_progress)}
              profitBased={Boolean(show_profit_based_progress)}
            />
          )}
          {activeTab === 1 && (
            <MobileProgressCard
              raised={orgFormattedRaised}
              goal={orgFormattedGoal}
              percentRaised={orgPercentRaised}
              percentOfGoal={orgPercentOfGoal}
              profitBased={Boolean(show_profit_based_progress)}
            />
          )}
          {showCountdown && end_date && <Countdown endDate={end_date} />}
        </div>
      )}

      {isDesktop && (
        <ProgressCard
          isVolunteer
          raised={volunteerFormattedRaised}
          goal={volunteerFormattedGoal}
          percentRaised={volunteerPercentRaised}
          percentOfGoal={volunteerPercentOfGoal}
          itemBased={Boolean(show_volunteer_item_progress)}
          profitBased={Boolean(show_profit_based_progress)}
        />
      )}
      {isDesktop && (
        <ProgressCard
          raised={orgFormattedRaised}
          goal={orgFormattedGoal}
          percentRaised={orgPercentRaised}
          percentOfGoal={orgPercentOfGoal}
          profitBased={Boolean(show_profit_based_progress)}
        />
      )}
    </div>
  );
}

type CardProps = {
  isVolunteer?: boolean;
  raised: any;
  goal: any;
  percentRaised: any;
  percentOfGoal: any;
  profitBased?: boolean;
  itemBased?: boolean;
};
function ProgressCard({
  isVolunteer,
  raised,
  goal,
  percentRaised,
  percentOfGoal,
  profitBased,
  itemBased,
}: CardProps) {
  const classes = styles({ percentRaised });
  return (
    <div className={classes.card}>
      <div className={classes.top}>
        <div className={classes.prog}>
          {isVolunteer ? "My" : "Campaign"} progress
        </div>
        <div className={classes.percent}>{percentOfGoal}% of goal raised</div>
      </div>

      <div className={classes.progressBar}>
        <div
          className={classNames(
            classes.progress,
            isVolunteer && classes.volunteerProgress,
          )}
        />
      </div>

      <div className={classes.bottom}>
        <div>
          <div
            className={classNames(
              classes.amount,
              isVolunteer && classes.volunteerAmount,
            )}
          >
            {itemBased ? "" : "$"}
            {raised}
          </div>
          <div>
            {itemBased
              ? "Items sold"
              : profitBased
              ? "Profit Raised"
              : "Raised"}
          </div>
        </div>
        <div>
          <div className={classes.goal}>
            {itemBased ? "" : "$"}
            {goal}
          </div>
          <div>Goal</div>
        </div>
      </div>
    </div>
  );
}

function MobileProgressCard({
  isVolunteer,
  raised,
  goal,
  percentRaised,
  percentOfGoal,
  profitBased,
  itemBased,
}: CardProps) {
  const classes = styles({ percentRaised });
  return (
    <div className={classes.card}>
      <div className={classes.progressBar}>
        <div
          className={classNames(
            classes.progress,
            isVolunteer && classes.volunteerProgress,
          )}
        />
      </div>

      <div className={classes.bottom}>
        <div>
          <div>
            {itemBased ? "" : "$"}
            {raised}
          </div>
          <div className={classes.mobileLabel}>
            {itemBased
              ? "Items sold"
              : profitBased
              ? "Profit Raised"
              : "Raised"}
          </div>
        </div>

        <div>
          <div>{percentOfGoal}%</div>
          <div className={classes.mobileLabel}>Of goal raised</div>
        </div>

        <div>
          <div>
            {itemBased ? "" : "$"}
            {goal}
          </div>
          <div className={classes.mobileLabel}>Goal</div>
        </div>
      </div>
    </div>
  );
}

function Countdown({ endDate }: { endDate: Date }) {
  const classes = styles({});
  return (
    <div className={classes.countdown}>
      <div className={classes.countdownTitle}>Time remaining</div>
      <CountdownComp
        date={new Date(endDate)}
        intervalDelay={1000}
        renderer={({ days, hours, minutes, seconds }) => {
          return (
            <div className={classes.timeCards}>
              <div className={classes.timeCard}>
                <div className={classes.time}>{days}</div>
                <div>days</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{hours}</div>
                <div>hours</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{minutes}</div>
                <div>minutes</div>
              </div>
              <div className={classes.timeCard}>
                <div className={classes.time}>{seconds}</div>
                <div>seconds</div>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}

type StyleProps = {
  percentRaised?: string;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    width: "100%",
    marginBottom: 40,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.14,
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      letterSpacing: 0.11,
      lineHeight: "22px",
    },
  },
  skeleton: {
    marginTop: 16,
    borderRadius: 8,
    backgroundColor: "#F0F0F0",
  },
  tabs: {
    borderBottom: "1px solid #DBDEEE",
  },
  tab: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  mobileContent: {
    marginTop: 10,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
  },
  card: {
    marginTop: 16,
    width: "100%",
    borderRadius: 8,
    padding: 24,
    backgroundColor: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      borderRadius: 0,
      padding: "24px 16px",
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 16,
  },
  prog: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.2,
    lineHeight: "17px",
  },
  percent: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    lineHeight: "16px",
  },
  progressBar: {
    display: "flex",
    height: 20,
    width: "100%",
    borderRadius: 20,
    backgroundColor: "#F5F5F5",
    [theme.breakpoints.down("sm")]: {
      height: 16,
    },
  },
  progress: {
    height: "100%",
    width: ({ percentRaised }) => percentRaised,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
  },
  volunteerProgress: {
    backgroundColor: theme.palette.secondary2.main,
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
    fontSize: 16,
    letterSpacing: 0.2,
    lineHeight: "17px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.17,
      lineHeight: "16px",
    },
  },
  mobileLabel: {
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "13px",
    paddingTop: 8,
    fontWeight: "unset",
  },
  amount: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: "bold",
    letterSpacing: 0.3,
    lineHeight: "26px",
    paddingBottom: 8,
  },
  volunteerAmount: {
    color: theme.palette.secondary2.main,
  },
  goal: {
    fontSize: 24,
    fontWeight: "bold",
    letterSpacing: 0.3,
    lineHeight: "26px",
    paddingBottom: 8,
  },
  // COUNTDOWN
  countdown: {
    borderTop: "1px solid #DBDEEE",
    padding: "24px 0 18px 16px",
  },
  countdownTitle: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    lineHeight: "16px",
    marginBottom: 16,
  },
  timeCards: {
    display: "flex",
    alignItems: "center",
  },
  timeCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 75,
    minWidth: 64,
    borderRadius: 8,
    backgroundColor: "#F1F9FF",
    padding: "0 4px",
    fontSize: 12,
    letterSpacing: 0.15,
    lineHeight: "13px",
    color: theme.palette.text.secondary2,
    marginRight: 16,
    "&:last-of-type": {
      marginRight: 0,
    },
  },
  time: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "26px",
    color: theme.palette.primary.main,
    paddingBottom: 8,
  },
}));
