import {
  makeStyles,
  IconButton,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonSpinner, ResponsiveModal } from "../../../../components";
import { useIsMobile, useDrawerTransition } from "../../../../hooks/ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getCampaignId,
  getIsLoadingDownload,
  getVolunteerLabel,
} from "../../../../state";
import {
  fetchAndDownloadCsv,
  fetchAndDownloadExcel,
  fetchAndDownloadPdf,
} from "../../../../exports/fetchAndDownloadMethods";

type Props = {
  onClose: () => void;
  bigKahunaSmallOrders?: boolean;
};

export function StudentDeliveryNumbers({
  onClose: _onClose,
  bigKahunaSmallOrders = false,
}: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const isLoading = useSelector(getIsLoadingDownload);
  const { volunteerLabelSing, volunteerLabelLCSingular } = useSelector(
    getVolunteerLabel,
  );
  const campaign_id = useSelector(getCampaignId);
  const [reportType, setReportType] = useState(
    bigKahunaSmallOrders ? "excel" : "csv",
  );
  const [order, setOrder] = useState("class");
  const [maxItems, setMaxItems] = useState("5");

  const download = async () => {
    const fileName = bigKahunaSmallOrders
      ? "Small orders list"
      : `${volunteerLabelSing} delivery numbers`;
    if (reportType === "csv") {
      const url = `/reports/student-numbers-csv?campaign_id=${campaign_id}&_sort=${order}`;
      await fetchAndDownloadCsv(url, fileName, onClose);
    } else if (reportType === "excel") {
      let url = !bigKahunaSmallOrders
        ? `/xlsx_reports/student_numbers?campaign_id=${campaign_id}&_sort=${order}`
        : `/xlsx_reports/big_kahuna_small_orders?campaign_id=${campaign_id}&_sort=${order}`;
      if (bigKahunaSmallOrders && maxItems) url = `${url}&max=${maxItems}`;
      await fetchAndDownloadExcel(url, fileName, onClose);
    } else {
      const url = `/reports/student-numbers-pdf?campaign_id=${campaign_id}&_sort=${order}`;
      await fetchAndDownloadPdf(url, fileName, onClose);
    }
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={() => {}}>
      <div className={classes.container}>
        <div className={classes.top}>
          {isMobile && (
            <div className={classes.close}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <div className={classes.header}>
            {bigKahunaSmallOrders && "Export small orders list"}
            {!bigKahunaSmallOrders &&
              `Export ${volunteerLabelLCSingular} delivery numbers`}
          </div>
        </div>

        <div className={classes.content}>
          {!bigKahunaSmallOrders && (
            <div className={classes.section}>
              <div className={classes.label}>Select a format</div>
              <RadioGroup
                value={reportType}
                onChange={({ target }) => setReportType(target.value)}
              >
                <FormControlLabel
                  className={classes.radio}
                  value="csv"
                  control={<Radio />}
                  label="CSV"
                />
                <FormControlLabel
                  className={classes.radio}
                  value="pdf"
                  control={<Radio />}
                  label="PDF"
                />
                <FormControlLabel
                  className={classes.radio}
                  value="excel"
                  control={<Radio />}
                  label="Excel"
                />
              </RadioGroup>
            </div>
          )}

          {bigKahunaSmallOrders && (
            <div className={classes.section}>
              <div className={classes.label}>Max ship to school items</div>
              <TextField
                className={classes.input}
                label="Max Ship To School Items"
                value={maxItems}
                onChange={({ target }) => setMaxItems(target.value)}
                type="number"
              />
            </div>
          )}

          <div className={classes.section}>
            <div className={classes.label}>Order report by</div>
            <RadioGroup
              value={order}
              onChange={({ target }) => setOrder(target.value)}
            >
              <FormControlLabel
                className={classes.radio}
                value="class"
                control={<Radio />}
                label="Class"
              />
              <FormControlLabel
                className={classes.radio}
                value="last_name"
                control={<Radio />}
                label="Last name"
              />
              <FormControlLabel
                className={classes.radio}
                value="student_number"
                control={<Radio />}
                label={`${volunteerLabelSing} number`}
              />
            </RadioGroup>
          </div>
        </div>

        <div className={classes.bottom}>
          <Button
            variant="text"
            color={isMobile ? "primary" : "default"}
            onClick={onClose}
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={isLoading}
            onClick={download}
          >
            Export
            <ButtonSpinner show={isLoading} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    width: "100%",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    borderBottom: "1px solid #DBDEEE",
  },
  close: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
    paddingBottom: 2,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "24px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
    },
  },
  content: {
    flex: 1,
    padding: "24px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 0 16px",
    },
  },
  section: {
    marginBottom: 24,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 8,
  },
  radio: {
    marginLeft: 0,
  },
  input: {
    marginTop: 8,
  },
  bottom: {
    width: "100%",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    padding: 24,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: "16px 16px 8px 16px",
      alignItems: "center",
    },
  },
  actionBtn: {
    minWidth: 120,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 8,
    },
  },
}));
