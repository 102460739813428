import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, makeStyles, InputAdornment } from "@material-ui/core";
import { FormApi } from "final-form";
import PreviewIcon from "@material-ui/icons/VisibilityOutlined";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  composeValidators,
  convertFileToB64,
  requiredField,
  twoDecimalsOnly,
} from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  NullableField,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { useIsMobile } from "../../../hooks/ui";
import { PreviewCard } from "./PreviewCard";

type Props = {
  record: GenericObject;
  setRecord: React.Dispatch<React.SetStateAction<GenericObject>>;
  needsRefreshRef: React.MutableRefObject<boolean>;
  isCampaignCard: boolean;
};

export function EditCard({
  record,
  setRecord,
  needsRefreshRef,
  isCampaignCard,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setInitialValues(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any, form: FormApi, complete: any) => {
    needsRefreshRef.current = true;
    const {
      active,
      card_name,
      display_estimated_values,
      estimated_card_value,
      expires_on,
      notes,
      org_name,
      org_paragraph,
      org_video_link,
      support_email,
      support_message,
      support_name,
      support_phone,
      cardImageFile,
      removeCardImage,
      supportLogoFile,
      removeSupportLogo,
      orgLogoFile,
      removeOrgLogo,
      orgPictureFile,
      removeOrgPicture,
    } = values;
    const update: GenericObject = {
      active,
      card_name,
      display_estimated_values,
      estimated_card_value,
      expires_on,
      notes,
      org_name,
      org_paragraph,
      org_video_link,
      support_email,
      support_message,
      support_name,
      support_phone,
      removeCardImage: Boolean(removeCardImage),
      removeSupportLogo: Boolean(removeSupportLogo),
      removeOrgLogo: Boolean(removeOrgLogo),
      removeOrgPicture: Boolean(removeOrgPicture),
    };
    if (cardImageFile) {
      update.cardImageBase64 = await convertFileToB64(cardImageFile);
    }
    if (supportLogoFile) {
      update.supportLogoBase64 = await convertFileToB64(supportLogoFile);
    }
    if (orgLogoFile) {
      update.orgLogoBase64 = await convertFileToB64(orgLogoFile);
    }
    if (orgPictureFile) {
      update.orgPictureBase64 = await convertFileToB64(orgPictureFile);
    }

    const url = `/discount_cards/${record.id}`;
    const res = await APIAuthClient.put<any, APIRes>(url, update);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Changes saved", ToastTypes.success));
    form.restart(data);
    setRecord(data);
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, form, values, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <div className={classes.mainContent}>
                <div className={classes.activeWrapper}>
                  <div>
                    <Button
                      onClick={() => setShowPreview(true)}
                      startIcon={<PreviewIcon />}
                      color="primary"
                      variant="text"
                      size="small"
                    >
                      PREVIEW
                    </Button>
                  </div>
                  <SwitchField fieldName="active" label="Active" />
                </div>
                <Field
                  component={TextFieldWrapper}
                  name="card_name"
                  label={
                    isCampaignCard ? "Campaign card name" : "Template card name"
                  }
                  validate={requiredField}
                  className={classes.input}
                />
                <div className={classes.inputGroup}>
                  <Field
                    component={TextFieldWrapper}
                    name="expires_on"
                    label="Expires on"
                    validate={requiredField}
                    type="date"
                    className={classes.halfInput}
                    InputLabelProps={{ shrink: true }}
                  />
                  <SwitchField
                    fieldName="display_estimated_values"
                    label="Display estimated values"
                  />
                </div>
                {isMobile && <div className={classes.spacer} />}

                {values.display_estimated_values && (
                  <Field
                    component={TextFieldWrapper}
                    type="number"
                    name="estimated_card_value"
                    label="Estimated card value"
                    validate={composeValidators(requiredField, twoDecimalsOnly)}
                    className={classes.halfInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}

                <div className={classes.imageLabel}>Card image</div>
                <ImageDropzoneField
                  fieldName="cardImageFile"
                  deleteFieldName="removeCardImage"
                  currImage={values.card_image}
                />

                {isCampaignCard && (
                  <Fragment>
                    <div className={classes.subheader}>Org details</div>
                    <NullableField
                      component={TextFieldWrapper}
                      name="org_name"
                      label="Org name"
                      className={classes.input}
                    />
                    <NullableField
                      component={TextFieldWrapper}
                      name="org_paragraph"
                      label="Org paragraph"
                      className={classes.input}
                      type="textarea"
                      multiline
                      minRows={2}
                    />
                    <div className={classes.imageLabel}>Org logo</div>
                    <ImageDropzoneField
                      fieldName="orgLogoFile"
                      deleteFieldName="removeOrgLogo"
                      currImage={values.org_logo}
                    />
                    <div className={classes.spacer} />
                    <div className={classes.imageLabel}>Org Picture</div>
                    <ImageDropzoneField
                      fieldName="orgPictureFile"
                      deleteFieldName="removeOrgPicture"
                      currImage={values.org_picture}
                    />
                    <div className={classes.spacer} />
                    <NullableField
                      component={TextFieldWrapper}
                      name="org_video_link"
                      label="Org video link"
                      className={classes.input}
                    />
                  </Fragment>
                )}

                <div className={classes.subheader}>Support details</div>
                <Field
                  component={TextFieldWrapper}
                  name="support_name"
                  label="Support name"
                  validate={requiredField}
                  className={classes.input}
                />
                <div className={classes.inputGroup}>
                  <NullableField
                    component={TextFieldWrapper}
                    name="support_phone"
                    label="Support phone"
                    className={classes.halfInput}
                  />
                  <Field
                    component={TextFieldWrapper}
                    name="support_email"
                    label="Support email"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                </div>
                <NullableField
                  component={TextFieldWrapper}
                  name="support_message"
                  label="Support message"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />
                <div className={classes.imageLabel}>Support logo</div>
                <ImageDropzoneField
                  fieldName="supportLogoFile"
                  deleteFieldName="removeSupportLogo"
                  currImage={values.support_logo}
                />
                <div className={classes.spacer} />
                <NullableField
                  component={TextFieldWrapper}
                  name="notes"
                  label="Notes"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />
              </div>

              <div className={classes.buttons}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.save}
                  disabled={submitting || pristine}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
      {showPreview && (
        <PreviewCard
          close={() => setShowPreview(false)}
          id={record.id as number}
        />
      )}
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 0 16px",
      paddingBottom: 8,
    },
  },
  activeWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 18,
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  spacer: {
    height: 16,
  },
  imageLabel: {
    paddingTop: 8,
    paddingBottom: 16,
    fontSize: 16,
    letterSpacing: 0.15,
  },
  subheader: {
    marginTop: 40,
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      bottom: 0,
      justifyContent: "center",
      padding: 16,
      zIndex: 9,
      backgroundColor: "#FFFFFF",
    },
  },
  save: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
    },
  },
}));
