import {
  makeStyles,
  MobileStepper,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { GuidedSetupScreens } from "../../../types";
import classNames from "classnames";
import { Fragment } from "react";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";

type NavigationProps = {
  handleBack: () => void;
  activeScreenIndex: number;
  handleNextTriggered: (activeScreen: GuidedSetupScreens) => void;
  activeScreen: GuidedSetupScreens;
  submitting: boolean;
  disableNext: boolean;
  screens: GuidedSetupScreens[];
  handleNext: () => void;
};
export function Navigation(props: NavigationProps) {
  const classes = styles();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  return (
    <Fragment>
      {/* DESKTOP NAVIGATION */}
      {isDesktop && (
        <div className={classes.desktopNavigationWrapper}>
          <div className={classes.desktopNavigation}>
            <BackButton {...props} />
            <Stepper {...props} />
            <div className={classes.actionsWrapper}>
              <NextButton {...props} />
              <SkipButton {...props} />
            </div>
          </div>
        </div>
      )}

      {/* MOBILE NAVIGATION */}
      {isMobile && (
        <div className={classes.mobileNavigation}>
          <Stepper {...props} />
          <div className={classes.mobileButtons}>
            <div className={classes.mobileBackAndNext}>
              <BackButton {...props} />
              <NextButton {...props} />
            </div>

            <div className={classes.mobileSkipContainer}>
              <SkipButton {...props} />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export const BackButton = ({
  activeScreenIndex,
  handleBack,
}: NavigationProps) => {
  const classes = styles();
  if (activeScreenIndex < 1) {
    return <div className={classes.backButtonWrapper} />;
  }
  return (
    <div className={classes.backButtonWrapper}>
      <Button
        color="secondary"
        onClick={handleBack}
        className={classes.backButton}
      >
        Back
      </Button>
    </div>
  );
};

export const NextButton = ({
  activeScreen,
  handleNextTriggered,
  submitting,
  disableNext,
  activeScreenIndex,
  screens,
}: NavigationProps) => {
  const classes = styles();
  const isLast = activeScreenIndex === screens.length - 1;
  return (
    <Button
      color="primary"
      onClick={() => handleNextTriggered(activeScreen)}
      disabled={(disableNext && !isLast) || submitting}
      startIcon={submitting ? <CircularProgress size={22} /> : null}
      className={classNames(
        classes.nextButton,
        activeScreenIndex < 1 && classes.fsNextButton,
      )}
    >
      {activeScreen === "CONTACTS"
        ? "Confirm & continue"
        : isLast
        ? "Finish"
        : "Next"}
    </Button>
  );
};

export const SkipButton = ({
  screens,
  activeScreen,
  activeScreenIndex,
  handleNext,
}: NavigationProps) => {
  const classes = styles();
  const isLast = activeScreenIndex === screens.length - 1;
  if (isLast || activeScreen === "CONTACTS" || activeScreen === "CLASS") {
    return <div className={classes.noSkip} />;
  }
  return (
    <Button
      color="primary"
      variant="text"
      onClick={handleNext}
      className={classes.skip}
    >
      SKIP
    </Button>
  );
};

export const Stepper = ({ screens, activeScreenIndex }: NavigationProps) => {
  const classes = styles();
  return (
    <MobileStepper
      variant="dots"
      steps={screens.length}
      position="static"
      activeStep={activeScreenIndex}
      classes={{ dotActive: classes.activeStepDot }}
      nextButton={null}
      backButton={null}
    />
  );
};

const styles = makeStyles(theme => ({
  desktopNavigationWrapper: {
    width: "100%",
    position: "sticky",
    backgroundColor: "#FFFFFF",
    bottom: 0,
    height: 84,
  },
  desktopNavigation: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #DBDEEE",
    padding: "0 32px",
  },
  mobileNavigation: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    backgroundColor: "#FFFFFF",
    bottom: 0,
    paddingTop: 8,
  },
  mobileSkipContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
  mobileButtons: {
    marginTop: 16,
  },
  mobileBackAndNext: {
    display: "flex",
  },
  actionsWrapper: {
    minWidth: 184,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  activeStepDot: {
    backgroundColor: theme.palette.secondary2.main,
  },
  nextButton: {
    width: 120,
    minWidth: "fit-content",
    textWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      minWidth: "fit-content",
      maxWidth: "calc(50vw - 28px)",
    },
  },
  fsNextButton: {
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: 300,
    },
  },
  backButtonWrapper: {
    width: 184,
    [theme.breakpoints.down("sm")]: {
      width: "unset",
    },
  },
  backButton: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 160,
      maxWidth: "calc(50vw - 28px)",
      marginRight: 16,
    },
  },
  skip: {
    width: 48,
    minWidth: 48,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 8,
    },
  },
  noSkip: {
    [theme.breakpoints.down("sm")]: {
      height: 8,
    },
  },
}));
