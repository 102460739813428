import React from "react";
import {
  StatsCard,
  FilterableRaisedStatsCard,
  DailyPerformanceChart,
  PerformanceChart,
} from "../../components";
import { connect } from "react-redux";
import { styles } from "./Dashboard.styles";

const _RepDashboard = props => {
  const {
    totalContacts,
    totalOrgs,
    totalActiveCampaigns,
    topCampaignData,
  } = props;
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <StatsCard
        field1={totalOrgs}
        field1Txt="total organizations"
        field2={totalActiveCampaigns}
        field2Txt="total active campaigns"
        field3={totalContacts}
        field3Txt="total contacts"
      />

      <div className={classes.charts}>
        <div className={classes.leftChart}>
          <PerformanceChart
            data={topCampaignData}
            title="Top Campaign Performance"
            label="Campaign"
          />
        </div>
        <DailyPerformanceChart />
      </div>

      <FilterableRaisedStatsCard />
    </div>
  );
};

export const RepDashboard = connect(state => {
  return { ...state.dashboard };
})(_RepDashboard);
