import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  DateField,
  FunctionField,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { campaignTypeNames } from "../../lib";

const CRFilter = props => {
  const classes = styles();
  return (
    <Filter {...props}>
      <TextInput
        label="Campaign Name, Org ID/Name, Rep"
        source="q"
        alwaysOn
        className={classes.filter}
      />
    </Filter>
  );
};

export function CampaignReservationList(props) {
  const history = useHistory();

  const handleClick = (e, org_id, id) => {
    e.stopPropagation();
    history.push(
      `/campaigns/create?org_id=${org_id}&campaign_reservation_id=${id}`,
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      exporter={false}
      title="Campaign Reservations"
      sort={{ field: "id", order: "DESC" }}
      actions={null}
      filters={<CRFilter />}
      empty={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" label="Campaign Name" />
        <TextField source="org_id" label="Org ID" />
        <TextField source="org_name" label="Org" />
        <TextField source="rep_name" label="Rep" />
        <FunctionField
          source="campaign_type_id"
          label="Campaign Type"
          render={({ campaign_type_id }) =>
            campaign_type_id ? campaignTypeNames[campaign_type_id] : ""
          }
        />
        <DateField showTime source="start_date" label="Site Open Date" />
        <DateField showTime source="end_date" label="Site Close Date" />
        <TextField source="student_count" label="Student Count" />
        <TextField source="supplier_name" label="Supplier" />
        <TextField source="group_name" label="Supplier Group" />
        <FunctionField
          label=" "
          render={({ id, org_id }) => (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={e => handleClick(e, org_id, id)}
            >
              convert to campaign
            </Button>
          )}
        />
      </Datagrid>
    </List>
  );
}

const styles = makeStyles(_theme => ({
  filter: {
    width: 290,
  },
}));
