import React from "react";
import {
  TextInput,
  SimpleForm,
  required,
  Edit,
  Toolbar,
  SaveButton,
  BooleanInput,
} from "react-admin";

const requiredInput = [required()];

export function DonationGroup({ record }) {
  const { id } = record;

  const transformDataForSubmit = values => {
    const { id: _rm, company_id: _rm2, ...rest } = values;
    return rest;
  };

  return (
    <Edit
      id={id}
      resource="donation_groups"
      basePath={`/donation_groups/${id}/show`}
      title=" "
      successMessage="Group updated successfully"
      undoable={false}
      transform={transformDataForSubmit}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton redirect={false} />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled />
        <TextInput source="group_name" validate={requiredInput} />
        <TextInput
          source="header"
          validate={requiredInput}
          multiline
          fullWidth
        />
        <TextInput
          source="taken_label"
          label="Taken label (ex: `Dedicated by`)"
          validate={requiredInput}
        />
        <TextInput source="tier_1_subheader" multiline fullWidth />
        <TextInput source="tier_2_subheader" multiline fullWidth />
        <TextInput source="tier_3_subheader" multiline fullWidth />
        <BooleanInput source="display_tier_1_avatars" />
        <BooleanInput source="display_tier_2_avatars" />
        <BooleanInput source="display_tier_3_avatars" />
      </SimpleForm>
    </Edit>
  );
}
