import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  Toolbar,
  SaveButton,
} from "react-admin";

export default function GiftCardEdit(props) {
  const transformDataForSubmit = values => {
    const { expiration_date, batch_identifier } = values;
    return { expiration_date, batch_identifier };
  };

  return (
    <Edit
      {...props}
      undoable={false}
      successMessage="Gift Card updated successfully"
      title="Edit Gift Card"
      transform={transformDataForSubmit}
    >
      <SimpleForm
        redirect="list"
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled />
        <TextInput
          source="batch_identifier"
          label="Batch Identifier (Optional)"
        />
        <DateInput
          source="expiration_date"
          label="Expiration Date (Optional)"
        />
      </SimpleForm>
    </Edit>
  );
}
