import { getIsRepOrHigherUser } from "../user/reducers";
import { selectedCampaignTypes, initialState } from "./actions";

const selectedCampaignReducers = (state = initialState(), action) => {
  switch (action.type) {
    case selectedCampaignTypes.SELECTED_CAMPAIGN_SET:
      if (state.campaignId === action.payload) return state;
      return {
        campaignId: action.payload,
        campaigns: state.campaigns,
      };
    case selectedCampaignTypes.SELECTED_CAMPAIGN_CHANGE:
      return {
        ...getSelectedCampaignInfo(action.payload, state.campaigns),
        campaigns: state.campaigns,
      };
    case selectedCampaignTypes.SELECTED_CAMPAIGN_CLEAR:
      return { campaigns: state.campaigns };
    case selectedCampaignTypes.CAMPAIGN_PREVIEWS_ADD:
      return {
        ...getSelectedCampaignInfo(state.campaignId, action.payload),
        campaigns: action.payload,
      };
    default:
      return state;
  }
};

// TODO: now that orgs use the new app we only need data for company user
// if it's a company-user the data will be in `campaignRecord` and if it's an org-user the data is in state
function getCampaignDonationListProps(state, campaignRecord = {}, isCompany) {
  const {
    user: {
      role,
      companyId,
      orgId,
      canManage,
      no_cash_donations, // TODO: this is only defined in an org view not when it's 'isCompany` but for now this variable is only used for an org view
    },
    selectedCampaign: {
      campaignId,
      campaign_type_id,
      volunteer_label,
      product_supplier_id,
      allow_family_accounts,
    },
  } = state;
  const {
    id,
    campaign_type_id: rCampaignTypeId,
    product_supplier_id: rProductSupplierId,
    org_id,
    volunteer_label: rVolunteerLabel,
    allow_family_accounts: rAllowFamilyAccounts,
    allow_donations,
  } = campaignRecord;
  return {
    role,
    canManage,
    companyId,
    org_id: isCompany ? org_id : orgId,
    campaign_id: isCompany ? id : campaignId,
    productSupplierId: isCompany ? rProductSupplierId : product_supplier_id,
    campaign_type_id: isCompany ? rCampaignTypeId : campaign_type_id,
    allowFamilyAccounts: isCompany
      ? rAllowFamilyAccounts
      : allow_family_accounts,
    volunteer_label: isCompany ? rVolunteerLabel : volunteer_label,
    isRepOrHigherUser: getIsRepOrHigherUser(state),
    no_cash_donations,
    allow_donations,
  };
}

// TODO: now that orgs use the new app we only need data for company user
// if it's a company-user the data will be in `campaignRecord` and if it's an org-user the data is in state
function getCampaignVolunteerListProps(state, campaignRecord = {}, isCompany) {
  const {
    user: {
      role,
      companyId,
      classOptions,
      orgId,
      canManage,
      allow_associated_volunteer_accounts,
    },
    selectedCampaign: {
      campaignId,
      campaign_type_id,
      volunteer_label = "Student",
    },
  } = state;
  const {
    id,
    org_id: rOrgId,
    campaign_type_id: rCampaignTypeId,
    volunteer_label: rVolunteerLabel,
    org,
  } = campaignRecord;

  return {
    role,
    companyId,
    canManage,
    campaign_id: isCompany ? id : campaignId,
    org_id: isCompany ? rOrgId : orgId,
    campaign_type_id: isCompany ? rCampaignTypeId : campaign_type_id,
    volunteer_label: isCompany ? rVolunteerLabel : volunteer_label,
    classOptions: isCompany ? org?.class_options : classOptions,
    allow_associated_volunteer_accounts: isCompany
      ? org?.allow_associated_volunteer_accounts
      : allow_associated_volunteer_accounts,
  };
}

export {
  selectedCampaignReducers,
  getCampaignDonationListProps,
  getCampaignVolunteerListProps,
};

function getSelectedCampaignInfo(campaignId, campaigns) {
  if (!campaignId || !Array.isArray(campaigns)) {
    localStorage.removeItem("selectedCampaignId");
    return {};
  }

  const campaign = campaigns.find(c => c.id === Number(campaignId));
  if (!campaign) {
    localStorage.removeItem("selectedCampaignId");
    return {};
  }
  const { id, volunteer_label, ...rest } = campaign;
  return {
    campaignId: id,
    volunteer_label: volunteer_label ? volunteer_label : "student",
    ...rest,
  };
}
