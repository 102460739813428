const RA_SUB_PATH = "admin";

const RAFFLE_CAMPAIGN = 1;
const MATCHING_CAMPAIGN = 2;
const DONATION_CAMPAIGN = 3;
const PRODUCT_CAMPAIGN = 4;

const TWO_DECIMAL_REGEX = /^\d*?(\.\d{1,2})?$/;
const SIX_DECIMAL_REGEX = /^\d*?(\.\d{1,6})?$/;
const TEN_DIGIT_REGEX = /^\d{10}$/;
const EMOJI_REGEX = /[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2580-\u27BF]|\uD83E[\uDD10-\uDDFF]/g;

const IS_RN_WEBVIEW = window.isRNWebView === true;
const USER_INSTARAISE_APP_VERSION = window.instaraiseAppVersion;

function campaignTypeBooleans(campaignTypeId) {
  const isRaffle = campaignTypeId === RAFFLE_CAMPAIGN;
  const isMatching = campaignTypeId === MATCHING_CAMPAIGN;
  const isDonation = campaignTypeId === DONATION_CAMPAIGN;
  const isProduct = campaignTypeId === PRODUCT_CAMPAIGN;
  const campaignType = isRaffle
    ? "raffle"
    : isMatching
    ? "matching"
    : isDonation
    ? "donation"
    : isProduct
    ? "product"
    : "";
  return { isRaffle, isDonation, isMatching, isProduct, campaignType };
}

const paymentTypes = {
  cc: "Credit Card",
  check: "Check",
  cash: "Cash",
  pledge: "Pledge",
};

const validatePhone = phone => {
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone);
};

const roles = {
  SUPER_ADMIN: "super_admin",
  COMPANY: "company",
  REP: "rep",
  ORG: "org_admin",
  ORG_EMPLOYEE: "org_employee",
  VOLUNTEER: "org_volunteer",
  COMPANY_ADMIN: "company_admin",
  FULFILLER: "fulfiller",
  COMPANY_DATA_ENTRY: "company_data_entry",
  REP_MANAGER: "rep_manager",
};

const isSuperAdmin = role => role === roles.SUPER_ADMIN;
const isCompany = role => role === roles.COMPANY;
const isRep = role => role === roles.REP;
const isOrg = role => role === roles.ORG;
const isOrgEmployee = role => role === roles.ORG_EMPLOYEE;
const isOrgOrOrgEmployee = role =>
  role === roles.ORG || role === roles.ORG_EMPLOYEE;
const isVolunteer = role => role === roles.VOLUNTEER;
const isFulfiller = role => role === roles.FULFILLER;
const isCompanyDataEntry = role => role === roles.COMPANY_DATA_ENTRY;
const isRepManager = role => role === roles.REP_MANAGER;
const isOrgOrVolunteer = role =>
  role === roles.ORG || role === roles.ORG_EMPLOYEE || role === roles.VOLUNTEER;
const isCompanyOrCompanyAdminOrRep = role =>
  role === roles.COMPANY || role === roles.REP || role === roles.COMPANY_ADMIN;
const isCompanyOrCompanyAdmin = role =>
  role === roles.COMPANY || role === roles.COMPANY_ADMIN;
const isCompanyAdminOrDataEntry = role =>
  role === roles.COMPANY ||
  role === roles.COMPANY_ADMIN ||
  role === roles.COMPANY_DATA_ENTRY;
const isCompanyOrCompanyAdminOrFulfiller = role =>
  role === roles.COMPANY ||
  role === roles.COMPANY_ADMIN ||
  role === roles.FULFILLER;
const isSuperOrCompanyOrCompanyAdminOrRep = role =>
  role === roles.SUPER_ADMIN ||
  role === roles.COMPANY ||
  role === roles.COMPANY_ADMIN ||
  role === roles.REP;
const isSuperOrCompanyOrCompanyAdminOrRepImpersonator = impersonatorRole =>
  impersonatorRole === roles.SUPER_ADMIN ||
  impersonatorRole === roles.COMPANY ||
  impersonatorRole === roles.COMPANY_ADMIN ||
  impersonatorRole === roles.REP;

const isHibernateCompany = companyId => {
  const hibernateCompanyId = process.env.REACT_APP_HIBERNATE_COMPANY_ID;
  if (!companyId || !hibernateCompanyId) return false;
  return Number(companyId) === Number(hibernateCompanyId);
};

const isGivvrCompany = companyId => {
  const givvrCompanyId = process.env.REACT_APP_GIVVR_COMPANY_ID;
  if (!companyId || !givvrCompanyId) return false;
  return Number(companyId) === Number(givvrCompanyId);
};

const isJMSCompany = companyId => {
  const JMSCompanyId = process.env.REACT_APP_JMS_COMPANY_ID;
  if (!companyId || !JMSCompanyId) return false;
  return Number(companyId) === Number(JMSCompanyId);
};

const isMrsFieldsCompany = companyId => {
  const mrsFieldsCompanyId = process.env.REACT_APP_MRS_FIELDS_COMPANY_ID;
  if (!companyId || !mrsFieldsCompanyId) return false;
  return Number(companyId) === Number(mrsFieldsCompanyId);
};

const isSimplyGoodnessCompany = companyId => {
  const simplyGoodnessCompanyId =
    process.env.REACT_APP_SIMPLY_GOODNESS_COMPANY_ID;
  if (!companyId || !simplyGoodnessCompanyId) return false;
  return Number(companyId) === Number(simplyGoodnessCompanyId);
};

const isSchoolathonCompany = companyId => {
  const schoolathonCompanyId = process.env.REACT_APP_SCHOOLATHON_COMPANY_ID;
  if (!companyId || !schoolathonCompanyId) return false;
  return Number(companyId) === Number(schoolathonCompanyId);
};

const isGreatWesternSupplier = supplierId => {
  const gwSupplierId = process.env.REACT_APP_GREAT_WESTERN_SUPPLIER_ID;
  if (!supplierId || !gwSupplierId) return false;
  return Number(supplierId) === Number(gwSupplierId);
};

const isNeighborsSupplier = supplierId => {
  const neighborsSupplierId = process.env.REACT_APP_NEIGHBORS_SUPPLIER_ID;
  if (!supplierId || !neighborsSupplierId) return false;
  return Number(supplierId) === Number(neighborsSupplierId);
};

const isKidsAreFirstCompany = companyId => {
  const kidsAreFirstCompanyId = process.env.REACT_APP_KIDS_ARE_FIRST_COMPANY_ID;
  if (!companyId || !kidsAreFirstCompanyId) return false;
  return Number(companyId) === Number(kidsAreFirstCompanyId);
};

const isBigKahunaCompany = companyId => {
  const bigKahunaCompanyId = process.env.REACT_APP_BIG_KAHUNA_COMPANY_ID;
  if (!companyId || !bigKahunaCompanyId) return false;
  return Number(companyId) === Number(bigKahunaCompanyId);
};

const isBigKahunaSupplier = supplierId => {
  const bigKahunaSupplierId = process.env.REACT_APP_BIG_KAHUNA_SUPPLIER_ID;
  if (!supplierId || !bigKahunaSupplierId) return false;
  return Number(supplierId) === Number(bigKahunaSupplierId);
};

const campaignTypeIds = {
  RAFFLE: 1,
  MATCH: 2,
  DONATION: 3,
  PRODUCT: 4,
};

const isProductCampaign = campaignTypeId => {
  return Number(campaignTypeId) === campaignTypeIds.PRODUCT;
};

const isMatchingCampaign = campaignTypeId => {
  return Number(campaignTypeId) === campaignTypeIds.MATCH;
};

const isDonationCampaign = campaignTypeId => {
  return Number(campaignTypeId) === campaignTypeIds.DONATION;
};

const isRaffleCampaign = campaignTypeId => {
  return Number(campaignTypeId) === campaignTypeIds.RAFFLE;
};

const campaignTypeNames = {
  1: "raffle",
  2: "matching",
  3: "donation",
  4: "product",
};

function downloadPdf(res, fileName) {
  const { error, data } = res;
  if (error) {
    return alert(
      "There was an error downloading your PDF, please try again later.",
    );
  }
  const blob = new Blob([data], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

function downloadXlsx(res, fileName) {
  const { error, data } = res;
  if (error) {
    return alert(
      "There was an error downloading your File, please try again later.",
    );
  }
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
}

const socialMediaTypes = {
  FACEBOOK: "facebook",
  TWITTER: "twitter",
  LINKEDIN: "linkedin",
  PINTEREST: "pinterest",
  WHATSAPP: "whatsapp",
  SMS: "sms",
  EMAIL: "email",
};

const orgProfitStatuses = {
  PENDING_CONFIRMATION: "pending confirmation",
  REP_CONFIRMED: "rep confirmed",
  PROFIT_CONFIRMED: "profit confirmed",
  READY_FOR_PAYMENT: "ready for payment",
  PROFIT_PAID: "profit paid",
  INVOICE_SENT: "invoice sent",
  INVOICE_PAID: "invoice paid",
};

const orgProfitPostCloseoutStatuses = {
  INVOICE_SENT: "invoice sent",
  INVOICE_PAID: "invoice paid",
};

const repCommissionStatuses = {
  PENDING_CLOSEOUT: "pending closeout",
  PENDING_PAYMENT: "pending payment",
  PAID: "paid",
};

const wholesaleInvoiceStatuses = {
  PENDING: "pending",
  INVOICE_SENT: "invoice sent",
  PAID: "paid",
};

function formatMoney(num = 0, isNegative = false, removeMinus = false) {
  let padded = Number(num)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (removeMinus) padded = padded.replace("-", "");

  return `${isNegative ? "-" : ""}$${padded}`;
}

function formatMoneyUnPadded(amount) {
  if (!amount) return "0";
  const num = Number(amount);
  const paddedNum = !Number.isInteger(num) ? num.toFixed(2) : num;
  const formattedNum = paddedNum
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return formattedNum;
}

function formatSum(num = 0) {
  if (!num) return 0;
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function calcPercentAmount(num, percent) {
  if (!num || !percent) return 0;
  return Number(Number((num / 100) * percent).toFixed(2));
}

function calcAndFormatPercentAmount(num, percent) {
  return formatMoney(calcPercentAmount(num, percent));
}

function calculatePercentage(part, total) {
  if (!part || !total) return "0";
  const percent = (Number(part) / Number(total)) * 100;
  const padded = !Number.isInteger(percent) ? percent.toFixed(2) : percent;
  return `${padded}`;
}

function formatDateOnlyString(dateStr) {
  if (!dateStr) return "";
  return dateStr
    .replace(/-0+/g, "-")
    .replace(/(\d{4})-(\d+)-(\d+)/, "$2/$3/$1");
}

function formatTimeString(time) {
  if (!time) return "";
  let hoursInt = parseInt(time.substring(0, 3));
  const minutes = time.substring(3, 5);
  const amPm = hoursInt > 11 ? "PM" : "AM";
  let hours = hoursInt;
  if (hoursInt === 0) hours = 12;
  else if (hoursInt > 12) hours = hoursInt - 12;
  return `${hours}:${minutes} ${amPm}`;
}

const poStatuses = {
  AWAITING: "Awaiting Order",
  RECEIVING: "Receiving",
  CLOSED: "Closed",
};

const launchPacketSections = {
  HEADER: "header",
  MATERIAL: "material",
  INTERNAL: "internal",
};

const shippingRateTypes = {
  ITEM_COUNT: "item count",
  WEIGHT: "weight",
};

const shippingCalculatedByChoices = {
  RATE_TABLE: "rate-table",
  LIVE_RATES: "live-rates",
};

function randomInt() {
  return Math.floor(Math.random() * 999999);
}

const downloadStatuses = {
  GENERATING: "Generating",
  COMPLETE: "Complete",
  FAILED: "Failed",
};

const downloadTypes = {
  STH_PICK_SLIPS: "STH Pick Slips",
  STS_PICK_SLIPS: "STS Pick Slips",
  ORG_INVOICE: "Org Invoice",
  ORG_PROFIT_STATEMENT: "Org Profit Statement",
  WHOLESALE_INVOICE: "Campaign Wholesale Invoice",
};

const daysOfWeek = {
  1: "Sunday",
  2: "Monday",
  3: "Tuesday",
  4: "Wednesday",
  5: "Thursday",
  6: "Friday",
  7: "Shabbos",
};

const printLabelModes = {
  PRINT_AND_CLOSE: "PRINT_AND_CLOSE",
  PRINT_ONLY: "PRINT_ONLY",
  CLOSED: null,
};

const productTypes = {
  PHYSICAL: "physical",
  DIGITAL: "digital",
  PRIZE: "prize",
  DONATION: "donation",
};

function filterObjToQueryStr(filterObj) {
  if (!filterObj) return "";
  return new URLSearchParams(filterObj).toString();
}

const paymentPlans = {
  RECURRING: "recurring",
  RECURRING_DEFAULTED: "recurring-defaulted",
  INSTALLMENTS: "installments",
  INSTALLMENTS_DEFAULTED: "installments-defaulted",
};

function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const AFFILIATES = {
  VOLUNTEER_WELCOME_TEXT: 1,
  VOLUNTEER_REMINDER_TEXT: 2,
  VOLUNTEER_TO_CONTACT_TEXT: 3,
  VOLUNTEER_APPROACHING_PRIZE_TIER_TEXT: 4,
  EZ_SHARING_VIA_VOLUNTEER_REMINDER_TEXT: 6,
  EZ_SHARING_VIA_VOLUNTEER_APPROACHING_PRIZE_TIER_TEXT: 7,
  CONTACT_EMAIL_1: 10,
  CONTACT_EMAIL_2: 11,
  CONTACT_EMAIL_3: 12,
  CONTACT_EMAIL_4: 13,
  CONTACT_EMAIL_5_AND_UP: 14,
  VOLUNTEER_WELCOME_EMAIL: 15,
  MANUAL_CONTACT_EMAIL: 16,
  DASHBOARD_SHARING_LINKS: 17,
  VOLUNTEER_APPROACHING_PRIZE_TIER_EMAIL: 18,
  EZ_SHARING_VIA_VOLUNTEER_APPROACHING_PRIZE_TIER_EMAIL: 19,
  VOLUNTEER_SETUP_SHARING_LINKS: 20,
  KICKOFF_BASED_CHECKPOINT_EMAIL: 21,
  CAMPAIGN_END_BASED_CHECKPOINT_EMAIL: 22,
  WEBSITE_SHARING_LINKS: 23,
};

const configurableProductOptionDisplayTypes = {
  DROPDOWN: "dropdown",
  TEXT_SWATCH: "text-swatch",
  COLOR_SWATCH: "color-swatch",
};

const productStructureTypes = {
  STANDARD: "standard",
  CONFIGURABLE: "configurable",
  VARIANT: "variant",
};

const prizeProgramTierRuleDataPoints = {
  NUMBER_OF_SALES: { id: "NUMBER_OF_SALES", name: "Number of sales" },
  DOLLAR_AMOUNT_RAISED: {
    id: "DOLLAR_AMOUNT_RAISED",
    name: "Dollar amount raised",
  },
  NUMBER_OF_ITEMS_SOLD: {
    id: "NUMBER_OF_ITEMS_SOLD",
    name: "Number of items sold",
  },
  NUMBER_OF_CONTACTS: {
    id: "NUMBER_OF_CONTACTS",
    name: "Number of contacts added/confirmed",
  },
  NUMBER_OF_CONTACTS_WITH_SENT_EMAILS: {
    id: "NUMBER_OF_CONTACTS_WITH_SENT_EMAILS",
    name: "Number of contacts added/confirmed with verified-valid emails",
  },
};

function getPrizeProgramTierRuleDataPointDisplay(dataPoint) {
  if (prizeProgramTierRuleDataPoints[dataPoint]) {
    return prizeProgramTierRuleDataPoints[dataPoint].name;
  }
  return dataPoint;
}

const campaignFulfillmentStatuses = {
  NO_ITEMS: "No items",
  OPEN: "Open",
  ENDED: "Ended",
  READY_FOR_FULFILLMENT: "Ready for fulfillment",
  FULFILLED: "Fulfilled",
};

function hexToRGBA(hex = "#FFF", opacity = 1) {
  let rgba = "";
  try {
    rgba =
      "rgba(" +
      (hex = hex.replace("#", ""))
        .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))
        .map(function(l) {
          return parseInt(hex.length % 2 ? l + l : l, 16);
        })
        .concat(opacity)
        .join(",") +
      ")";
  } catch (error) {
    rgba = "rgba(255,255,255,0.5)";
  }
  return rgba;
}

const donationReceiptConfigCampaignTypes = {
  DONATION: "donation",
  PRODUCT: "product",
};

const donationReceiptConfigLevels = {
  COMPANY: "company",
  COMPANY_PRODUCT_GROUP: "company_product_group",
  CAMPAIGN: "campaign",
};

function nameToInitials(fullName) {
  try {
    if (!fullName || typeof fullName !== "string") return null;
    const namesArray = fullName.trim().split(" ");
    if (namesArray.length === 1) {
      return `${namesArray[0].charAt(0).toUpperCase()}`;
    } else {
      return `${namesArray[0].charAt(0).toUpperCase()}${namesArray[
        namesArray.length - 1
      ]
        .charAt(0)
        .toUpperCase()}`;
    }
  } catch (error) {
    return null;
  }
}

function getRedirectUrl(location) {
  try {
    if (!location || !location.state) return null;
    const { nextPathname, nextSearch } = location.state;
    if (!nextPathname || nextPathname.includes("login")) return null;
    return `${nextPathname}${nextSearch}`;
  } catch (error) {
    return null;
  }
}

const volunteerSetupScreens = [
  { id: 1, name: "Picture", tag: "PICTURE" },
  { id: 2, name: "Goal", tag: "GOAL" },
  { id: 3, name: "Paragraph", tag: "PARAGRAPH" },
  { id: 4, name: "Class", tag: "CLASS" },
  { id: 5, name: "Team", tag: "TEAM" },
  { id: 6, name: "Family Members", tag: "FAMILY_MEMBERS" },
  { id: 7, name: "Text Agreement", tag: "TEXT_AGREEMENT" },
  { id: 8, name: "Prize Selections", tag: "PRIZE_PICKS" },
  { id: 9, name: "Contacts", tag: "CONTACTS" },
  { id: 10, name: "Text Contacts", tag: "TEXT_CONTACTS" },
  { id: 11, name: "Org Events", tag: "EVENTS" },
  { id: 12, name: "Social Media Sharing", tag: "SOCIAL" },
];
const volunteerSetupScreenTags = {};
volunteerSetupScreens.forEach(({ id, tag }) => {
  volunteerSetupScreenTags[id] = tag;
});

const shippingConfigLevels = {
  SUPPLIER: "supplier",
  COMPANY: "company",
};

function isNewAppUser(role) {
  if (isVolunteer(role) || isOrgOrOrgEmployee(role)) return true;
  return false;
}

export {
  RA_SUB_PATH,
  campaignTypeBooleans,
  paymentTypes,
  validatePhone,
  roles,
  isSuperAdmin,
  isCompany,
  isRep,
  isOrg,
  isOrgEmployee,
  isOrgOrOrgEmployee,
  isVolunteer,
  isFulfiller,
  isCompanyDataEntry,
  isRepManager,
  isOrgOrVolunteer,
  isSuperOrCompanyOrCompanyAdminOrRep,
  isCompanyOrCompanyAdmin,
  isCompanyAdminOrDataEntry,
  isCompanyOrCompanyAdminOrFulfiller,
  isCompanyOrCompanyAdminOrRep,
  isSuperOrCompanyOrCompanyAdminOrRepImpersonator,
  isHibernateCompany,
  campaignTypeIds,
  TWO_DECIMAL_REGEX,
  SIX_DECIMAL_REGEX,
  downloadPdf,
  campaignTypeNames,
  TEN_DIGIT_REGEX,
  isGivvrCompany,
  isJMSCompany,
  isMrsFieldsCompany,
  isSimplyGoodnessCompany,
  isSchoolathonCompany,
  isGreatWesternSupplier,
  isNeighborsSupplier,
  isKidsAreFirstCompany,
  isBigKahunaCompany,
  socialMediaTypes,
  orgProfitStatuses,
  formatMoney,
  formatMoneyUnPadded,
  calcPercentAmount,
  calcAndFormatPercentAmount,
  calculatePercentage,
  formatDateOnlyString,
  formatTimeString,
  repCommissionStatuses,
  poStatuses,
  wholesaleInvoiceStatuses,
  launchPacketSections,
  shippingRateTypes,
  shippingCalculatedByChoices,
  isProductCampaign,
  isMatchingCampaign,
  isDonationCampaign,
  isRaffleCampaign,
  orgProfitPostCloseoutStatuses,
  randomInt,
  downloadStatuses,
  downloadTypes,
  daysOfWeek,
  isBigKahunaSupplier,
  printLabelModes,
  EMOJI_REGEX,
  IS_RN_WEBVIEW,
  USER_INSTARAISE_APP_VERSION,
  productTypes,
  downloadXlsx,
  formatSum,
  filterObjToQueryStr,
  paymentPlans,
  getUserTimezone,
  AFFILIATES,
  configurableProductOptionDisplayTypes,
  productStructureTypes,
  prizeProgramTierRuleDataPoints,
  getPrizeProgramTierRuleDataPointDisplay,
  campaignFulfillmentStatuses,
  hexToRGBA,
  donationReceiptConfigCampaignTypes,
  donationReceiptConfigLevels,
  nameToInitials,
  getRedirectUrl,
  volunteerSetupScreens,
  volunteerSetupScreenTags,
  shippingConfigLevels,
  isNewAppUser,
};
