import React, { useEffect, useState } from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  SelectInput,
  useListController,
  ListView,
  ImageField,
  NumberField,
  Pagination,
  BooleanField,
  useNotify,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { useSelector } from "react-redux";
import { getIsOwner } from "../../../customState";
import { APIClient, generateAuthHeader } from "../../../lib";
import { CustomizeGroupProduct } from "./CustomizeCompanyGroupProduct";
import { styles } from "../ProductGroup.styles";

const ProductFilter = ({ categories, ...props }) => (
  <Filter {...props}>
    <TextInput label="Search Products" source="q" alwaysOn />
    <SelectInput
      label="Category"
      source="category_id"
      choices={categories}
      translateChoice={false}
      resettable
      alwaysOn
    />
  </Filter>
);

export const CompanyGroupProducts = ({ record }) => {
  const {
    id,
    supplier_id,
    company_can_customize_products: canCustomize,
  } = record;
  const classes = styles();
  const notify = useNotify();
  const isOwner = useSelector(state => getIsOwner(state));
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/product_categories/autocomplete?supplier_id=${supplier_id}`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setCategories(data);
    };
    if (supplier_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const controllerProps = useListController({
    sort: { field: "product_name", order: "ASC" },
    basePath: `supplier_product_groups/company_groups/${id}/show`,
    resource: "supplier_product_groups/company_group_products",
    filter: { id },
    perPage: 25,
  });

  return (
    <ListView
      title=" "
      {...controllerProps}
      empty={false}
      exporter={false}
      bulkActionButtons={false}
      filters={<ProductFilter categories={categories} />}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
    >
      <Datagrid
        rowClick={canCustomize ? "expand" : null}
        expand={canCustomize ? <CustomizeGroupProduct group_id={id} /> : null}
      >
        <TextField source="id" label="Product Id" />
        <TextField source="sku" />
        <ImageField
          source="thumbnail_image"
          label="Default Group Thumbnail"
          sortable={false}
          classes={{ image: classes.thumbnail }}
        />
        <ArrayField source="categories" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="product_name" label="Name" />
        <NumberField source="product_price" label="Default Group Price" />
        <NumberField source="company_custom_price" label="Custom Price" />
        <NumberField source="wholesale_price" label="Wholesale Price" />
        <BooleanField
          source="ship_to_school_only"
          label="STS Only"
          sortable={false}
        />
        <BooleanField
          source="ship_to_home_only"
          label="STH Only"
          sortable={false}
        />
        {isOwner && (
          <NumberField
            source="digital_content_addition_id"
            label="+DC Id"
            sortable={false}
          />
        )}
        <NumberField
          source="digital_content_addition_code_qty"
          label="+Dig. Codes"
          sortable={false}
        />
      </Datagrid>
    </ListView>
  );
};
