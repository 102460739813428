import React, { useState, useEffect, Fragment } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TextField,
  Button,
} from "@material-ui/core";
import { Form } from "react-final-form";
import { useNotify } from "react-admin";
import { Input } from "./OrgProfitInput";
import {
  formatMoney,
  calcAndFormatPercentAmount,
  orgProfitPostCloseoutStatuses,
  APIClient,
  generateAuthHeader,
  calcPercentAmount,
} from "../../../lib";
import { CampaignDonationProductAggregatesCSV } from "../../../components";
import { styles } from "./OrgProfit.styles";

export function PostCloseout({ opData, isProduct, fetchOrgProfitData }) {
  const classes = styles();
  const { campaign_id, postCloseoutTotal } = opData;
  const notify = useNotify();
  const [initialValues, setInitialValues] = useState({});
  const [actionDate, setActionDate] = useState("");
  const [awaitingInvoicing, setAwaitingInvoicing] = useState(false);
  const [isInvoiceSent, setIsInvoiceSent] = useState(false);
  const [actionTriggered, setActionTriggered] = useState(false);

  useEffect(() => {
    const {
      post_closeout_status,
      post_closeout_adjusted_profit_percent,
      post_closeout_custom_profit_amount,
    } = opData;

    setInitialValues({
      post_closeout_adjusted_profit_percent,
      post_closeout_custom_profit_amount,
    });
    setAwaitingInvoicing(post_closeout_status === null);
    setIsInvoiceSent(
      post_closeout_status === orgProfitPostCloseoutStatuses.INVOICE_SENT,
    );
  }, [opData]);

  const handleAction = async status => {
    setActionTriggered(true);
    const confirmed = window.confirm(
      "Are you sure you want to change the status?",
    );
    if (!confirmed) return setActionTriggered(false);
    const res = await APIClient.put(
      `/org_profit/campaigns/${campaign_id}/change-post-closeout-status`,
      { post_closeout_status: status, actionDate },
      { headers: generateAuthHeader() },
    );

    const { error, errorMessage } = res;
    if (error) {
      setActionTriggered(false);
      return notify(errorMessage, "warning");
    }

    setActionDate("");
    setActionTriggered(false);
    fetchOrgProfitData();
    notify("You have successfully updated the status!");
  };

  const onSubmit = async values => {
    const update = { ...values };
    // convert empty strings to null (percent & $ fields will fail validation without this)
    Object.keys(update).forEach(
      k => (update[k] = update[k] === "" ? null : update[k]),
    );

    const res = await APIClient.put(
      `/org_profit/campaigns/${campaign_id}`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");

    fetchOrgProfitData();
    notify("info has been successfully updated");
  };

  if (!isProduct) return <></>;
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, submitting, values }) => {
        const {
          post_closeout_adjusted_profit_percent,
          post_closeout_custom_profit_amount,
        } = values;
        return (
          <form onSubmit={handleSubmit}>
            <TableContainer component={Paper} className={classes.pcoTable}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      POST CLOSEOUT
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>Profit %</TableCell>
                    <TableCell>Profit Based On %</TableCell>
                    <TableCell>Custom Profit</TableCell>
                    <TableCell>Deduction</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{formatMoney(postCloseoutTotal)}</TableCell>
                    <TableCell>
                      <Input
                        name="post_closeout_adjusted_profit_percent"
                        type="number"
                        isPercent
                      />
                    </TableCell>
                    <TableCell>
                      {calcAndFormatPercentAmount(
                        postCloseoutTotal,
                        post_closeout_adjusted_profit_percent,
                      )}
                    </TableCell>
                    <TableCell>
                      <Input
                        name="post_closeout_custom_profit_amount"
                        type="number"
                        isMoney
                      />
                    </TableCell>
                    <TableCell>
                      {getDeduction(
                        postCloseoutTotal,
                        post_closeout_adjusted_profit_percent,
                        post_closeout_custom_profit_amount,
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={submitting}
                      >
                        save
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={awaitingInvoicing || isInvoiceSent ? 1 : 6}
                    >
                      <CampaignDonationProductAggregatesCSV
                        campaign_id={campaign_id}
                        postCloseout
                      />
                    </TableCell>
                    {(awaitingInvoicing || isInvoiceSent) && (
                      <Fragment>
                        <TableCell colSpan={4} align="right">
                          <TextField
                            type="date"
                            value={actionDate}
                            variant="standard"
                            onChange={e => setActionDate(e.target.value)}
                            label={
                              awaitingInvoicing
                                ? "INVOICE SENT ON"
                                : "INVOICE PAID ON"
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            disabled={!Boolean(actionDate) || actionTriggered}
                            className={classes.actionButton}
                            onClick={() =>
                              handleAction(
                                awaitingInvoicing
                                  ? orgProfitPostCloseoutStatuses.INVOICE_SENT
                                  : orgProfitPostCloseoutStatuses.INVOICE_PAID,
                              )
                            }
                          >
                            confirm
                          </Button>
                        </TableCell>
                      </Fragment>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        );
      }}
    />
  );
}

function getDeduction(ttl = 0, percent = 0, custom) {
  let deduction = 0;
  const profit = custom ? Number(custom) : calcPercentAmount(ttl, percent);
  if (ttl > 0) {
    deduction = Number(ttl) - Number(profit);
  }
  return formatMoney(deduction);
}
