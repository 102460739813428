import { Avatar, makeStyles, Popover } from "@material-ui/core";
import { connect } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import { useHistory } from "react-router-dom";
import { clearUserInfo } from "../customState";
import { nameToInitials } from "../lib";

function _UserMenu(props) {
  const {
    anchorEl,
    handleClose,
    role,
    userName,
    clearUserInfo,
    // these 3 fields are only applicable for the AppBar
    isImpersonating,
    impersonatingTxt,
    leaveImpersonationMode,
  } = props;
  const classes = styles();
  const history = useHistory();
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    clearUserInfo();
    window.location.replace("/admin/login");
  };

  return (
    <Popover
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      getContentAnchorEl={null}
      open={open}
      onClose={handleClose}
      PaperProps={{ square: true, className: classes.paper }}
    >
      <div className={classes.container}>
        {isImpersonating && (
          <div className={classes.impContainer}>
            <div className={classes.imp}>Impersonating</div>
            <div className={classes.impTxt}>{impersonatingTxt}</div>
            <div
              className={classes.leave}
              onClick={() => leaveImpersonationMode(role, history)}
            >
              LEAVE
            </div>
          </div>
        )}

        <div className={classes.top}></div>
        <div className={classes.bigAvatarWrapper}>
          <Avatar className={classes.bigAvatar}>
            {nameToInitials(userName)}
          </Avatar>
        </div>

        <div className={classes.userInfo}>
          <div className={classes.userName}>{userName}</div>
        </div>

        <div
          className={classes.linkRow}
          onClick={() => {
            history.push("/profile");
            handleClose();
          }}
        >
          <SettingsIcon />
          <div className={classes.linkLabel}>Profile</div>
        </div>

        <div className={classes.linkRow} onClick={handleLogout}>
          <ExitIcon />
          <div className={classes.linkLabel}>Logout</div>
        </div>
      </div>
    </Popover>
  );
}

export const UserMenu = connect(
  state => {
    const {
      user: { role, userName = null },
    } = state;
    return { role, userName };
  },
  { clearUserInfo },
)(_UserMenu);

const styles = makeStyles(theme => ({
  paper: {
    width: 352,
    maxWidth: "100%",
  },
  container: {
    marginBottom: 8,
    [theme.breakpoints.down("xs")]: {
      maxHeight: "85vh",
    },
  },
  impContainer: {
    width: "100%",
    padding: 16,
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },
  imp: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    marginBottom: 6,
  },
  impTxt: {
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: "16px",
    marginBottom: 8,
  },
  leave: {
    height: 32,
    width: 160,
    border: "1px solid #FFFFFF",
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  top: {
    height: 70,
    width: "100%",
    backgroundColor: "#EDEDED",
  },
  bigAvatarWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: -27,
  },
  bigAvatar: {
    color: "#FFFFFF",
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    height: 54,
    width: 54,
  },
  userInfo: {
    padding: "8px 16px 14px 16px",
  },
  userName: {
    fontSize: 18,
    letterSpacing: 0.08,
    lineHeight: "20px",
    textAlign: "center",
  },
  linkRow: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  linkLabel: {
    marginLeft: 12,
    fontSize: 17,
    lineHeight: "19px",
    letterSpacing: 0.08,
    color: "#222222",
  },
  separator: {
    marginTop: 4,
    borderBottom: "1px solid #DDDDDD",
  },
}));
