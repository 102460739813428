import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isGivvrCompany } from "../lib";

export default function KnowledgeBase() {
  const classes = styles();
  const companyId = useSelector(state => state.user.companyId);
  const isGivvr = isGivvrCompany(companyId);
  const src = isGivvr
    ? "https://givvrsupport.tawk.help/"
    : "https://instaraisesupport.tawk.help/";

  return (
    <div className={classes.container}>
      <iframe className={classes.iframe} title="info" src={src}></iframe>
    </div>
  );
}

const styles = makeStyles(
  _theme => ({
    container: {
      width: "100%",
      marginTop: 24,
    },
    iframe: {
      width: "100%",
      minHeight: "calc(100vh - 100px)",
    },
  }),
  {
    classNamePrefix: "knowledge",
  },
);
