import React, { useState, useEffect } from "react";
import { useNotify, useMutation } from "react-admin";
import { Button, CircularProgress } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { styles } from "./ProductGroup.styles";

export const RemoveProduct = ({ groupProductId, refetch }) => {
  const classes = styles();
  const notify = useNotify();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowButton(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const [remove, { loading }] = useMutation(
    {
      type: "delete",
      resource: "supplier_product_groups/remove_group_product",
      payload: { id: groupProductId },
    },
    {
      onFailure: error => notify(error.message, "warning"),
      onSuccess: () => {
        refetch();
        notify("Product Removed");
      },
    },
  );

  if (!showButton) return <></>;
  return (
    <Button
      variant="contained"
      className={classes.removeButton}
      startIcon={<DeleteIcon />}
      onClick={e => {
        e.stopPropagation();
        remove();
      }}
      disabled={loading}
    >
      remove
      {loading && (
        <CircularProgress size={18} className={classes.removeSpinner} />
      )}
    </Button>
  );
};
