import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import { FormApi } from "final-form";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  requiredField,
  requiredPositiveInteger,
} from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import { ButtonSpinner, TextFieldWrapper } from "../../../components";

type Props = {
  record: GenericObject;
};
export function GenerateCards({ record }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const { id } = record;

  const onSubmit = async (values: any, form: FormApi, complete: any) => {
    const { batch_identifier, qty } = values;
    const post = {
      campaign_discount_card_id: id,
      batch_identifier,
      qty: Number(qty),
    };
    const url = "/discount_cards/generate_customer_cards";
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Cards generated", ToastTypes.success));
    form.restart();
  };

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form, pristine }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Field
                component={TextFieldWrapper}
                name="batch_identifier"
                label="Batch identifier"
                validate={requiredField}
                className={classes.input}
              />
              <Field
                component={TextFieldWrapper}
                name="qty"
                label="Qty to generate"
                validate={requiredPositiveInteger}
                className={classes.input}
                type="number"
              />

              <div className={classes.buttonWrapper}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.save}
                  disabled={submitting || pristine}
                  onClick={() => form.submit()}
                >
                  Generate
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 400,
    maxWidth: "100%",
    padding: 24,
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  buttonWrapper: {
    display: "flex",
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  save: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
    },
  },
}));
