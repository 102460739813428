import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useIsMobile, useScrollToTop } from "../../hooks/ui";
import { getCampaignId } from "../../state";
import { ListContainer, ListNoResults } from "../../components";
import { useSetTopBarTitle } from "../../hooks/ui";
import { useList } from "../../hooks/list";
import { EmployeeRows } from "./components/EmployeeRows";
import { ReactComponent as NoEmployeesSVG } from "../../assets/NoEmployees.svg";
import { AddEmployee } from "./components/AddEmployee";

export function OrgEmployees() {
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const [isReady, setIsReady] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const isMobile = useIsMobile();
  useScrollToTop();

  useEffect(() => {
    if (campaign_id) setIsReady(true);
  }, [campaign_id]);

  const listProps = useList<any>({
    baseUrl: "/org_employees/list",
    isReady,
    initialState: { sort: "last_name", order: "asc" },
  });
  const { listIsFiltered, total, noResults, refreshList, showRows } = listProps;

  useSetTopBarTitle(<h1 className={classes.title}>Employees</h1>);

  return (
    <ListContainer listProps={listProps}>
      <div className={classes.topSection}>
        <div>
          {isMobile && (
            <Fragment>
              <h1 className={classes.title}>Employees</h1>
              <div className={classes.count}>
                {total} Employee
                {total !== 1 ? "s" : ""}
              </div>
            </Fragment>
          )}
        </div>
        <div>
          {isMobile && (
            <IconButton onClick={() => setShowAdd(true)}>
              <AddIcon />
            </IconButton>
          )}
          {!isMobile && (
            <Button
              color="primary"
              className={classes.add}
              startIcon={<AddIcon />}
              onClick={() => setShowAdd(true)}
            >
              Employee
            </Button>
          )}
        </div>
      </div>

      {noResults && (
        <ListNoResults
          SVG={NoEmployeesSVG}
          listIsFiltered={listIsFiltered}
          label="employees"
          buttonLabel="Add employee"
          buttonFunc={() => setShowAdd(true)}
        />
      )}

      {showRows && <EmployeeRows listProps={listProps} />}

      {showAdd && (
        <AddEmployee
          onClose={() => setShowAdd(false)}
          refreshList={refreshList}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 24,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 0 12px 0",
    },
  },
  add: {
    width: 130,
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
}));
