import { ReactNode } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from "react-redux";
import { setToast } from "../../state";
import { ToastTypes } from "../../types";

type Props = {
  children: ReactNode;
  link: string;
};
export function CopyLink({ children, link }: Props) {
  const dispatch = useDispatch();

  const OnCopy = () => {
    dispatch(setToast("Link copied", ToastTypes.success));
  };

  return (
    <CopyToClipboard text={link} onCopy={OnCopy}>
      {children}
    </CopyToClipboard>
  );
}
