import React, { Fragment, useState, useEffect } from "react";
import { format } from "date-fns";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  makeStyles,
} from "@material-ui/core";
const COLUMNS = [
  { name: "Date", align: "left" },
  { name: "Reason", align: "left" },
  { name: "Notes", align: "left" },
  { name: "Product Name", align: "left" },
  { name: "Sku", align: "left" },
  { name: "Qty", align: "center" },
];
const FULL_LIST_COLUMNS = [
  { name: "Date", align: "left" },
  { name: "Order ID", align: "center" },
  { name: "Student ID", align: "center" },
  { name: "Reason", align: "left" },
  { name: "Notes", align: "left" },
  { name: "Product Name", align: "left" },
  { name: "Sku", align: "left" },
  { name: "Qty", align: "center" },
];

export function Replacements({ replacements = [], fullList }) {
  const classes = styles({ fullList });
  const [columns, setColumns] = useState(COLUMNS);

  useEffect(() => {
    if (fullList) setColumns(FULL_LIST_COLUMNS);
  }, [fullList]);

  if (!replacements.length) return <Fragment></Fragment>;
  return (
    <Fragment>
      <TableContainer component={Paper} className={classes.table}>
        <Table size="small">
          <TableHead>
            {!fullList && (
              <TableRow>
                <TableCell align="center" colSpan={columns.length}>
                  REPLACED ITEMS
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              {columns.map(({ name, align }) => (
                <TableCell key={name} align={align}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {replacements.map((r, i1) => {
              const {
                donation_id,
                volunteer_id,
                reason,
                notes,
                created_at,
                otherProducts,
                product_name,
                sku,
                qty,
              } = r;
              const class1 = otherProducts.length ? classes.noBorder : null;
              const opLength = otherProducts.length;
              return (
                <Fragment key={i1}>
                  <TableRow>
                    <TableCell className={class1}>
                      {format(new Date(created_at), "Pp")}
                    </TableCell>
                    {fullList && (
                      <TableCell className={class1} align="center">
                        {donation_id}
                      </TableCell>
                    )}
                    {fullList && (
                      <TableCell className={class1} align="center">
                        {volunteer_id}
                      </TableCell>
                    )}
                    <TableCell className={class1}>{reason}</TableCell>
                    <TableCell className={class1}>{notes}</TableCell>
                    <TableCell className={class1}>{product_name}</TableCell>
                    <TableCell className={class1}>{sku}</TableCell>
                    <TableCell className={class1} align="center">
                      {qty}
                    </TableCell>
                  </TableRow>
                  {otherProducts.map((op, i2) => {
                    const { product_name, sku, qty } = op;
                    const class2 =
                      opLength !== i2 + 1 ? classes.noBorder : null;
                    return (
                      <TableRow key={i2}>
                        <TableCell
                          colSpan={fullList ? 5 : 3}
                          className={class2}
                        />
                        <TableCell className={class2}>{product_name}</TableCell>
                        <TableCell className={class2}>{sku}</TableCell>
                        <TableCell className={class2} align="center">
                          {qty}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  table: {
    margin: "24px 0",
    backgroundColor: ({ fullList }) => (fullList ? "inherit" : "#faf7f8"),
  },
  noBorder: {
    borderBottom: "none",
  },
}));
