import { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  minLength,
  email,
  SelectInput,
  FormDataConsumer,
  AutocompleteArrayInput,
  useNotify,
} from "react-admin";
import {
  APIClient,
  generateAuthHeader,
  companyRoleOptions,
  roles,
} from "../../lib";

const validateEmail = [required(), email()];
const validateName = [required(), minLength(2)];
const requiredInput = [required()];

export default function CompanyEmployeeEdit(props) {
  const notify = useNotify();
  const [reps, setReps] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/reps/autocomplete", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setReps(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformDataForSubmit = values => {
    const { email, first_name, last_name, role, managingRepIds } = values;
    return { email, first_name, last_name, role, managingRepIds };
  };

  return (
    <Edit
      {...props}
      undoable={false}
      successMessage="Employee updated successfully"
      title="Add Employee"
      transform={transformDataForSubmit}
    >
      <SimpleForm redirect="list">
        <TextInput source="id" disabled />
        <TextInput source="first_name" validate={validateName} />
        <TextInput source="last_name" validate={validateName} />
        <TextInput source="email" validate={validateEmail} />
        <SelectInput
          label="Role"
          source="role"
          validate={requiredInput}
          choices={companyRoleOptions}
          translateChoice={false}
        />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            if (formData.role !== roles.REP_MANAGER) {
              formData.managingRepIds = null;
              return <></>;
            }
            return (
              <AutocompleteArrayInput
                {...rest}
                label="Managing Reps"
                source="managingRepIds"
                choices={reps}
                translateChoice={false}
                fullWidth
              />
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
}
