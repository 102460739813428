import { useState, Fragment } from "react";
import { downloadCSV, ExportButton } from "react-admin";
import jsonExport from "jsonexport/dist";
import {
  Toolbar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import PeopleIcon from "@material-ui/icons/People";
import AppleIcon from "@material-ui/icons/Apple";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import PhoneIcon from "@material-ui/icons/Phone";
import SchoolIcon from "@material-ui/icons/School";
import { UploadVolunteersModal } from "./UploadVolunteersModal";
import { StudentsReportModal } from "./components/StudentsReportModal";
import {
  mainSiteUrl,
  isHibernateCompany,
  isBigKahunaCompany,
  APIUtilClient,
  generateAuthHeader,
  downloadPdf,
  downloadXlsx,
  isProductCampaign,
  // downloadPdf,
} from "../../lib";
import { StudentNumbersExport } from "../../components";

export function VolunteerListActions(props) {
  const {
    currentSort,
    filterValues,
    total,
    campaign_id,
    campaign_type_id,
    companyId,
  } = props;
  const [uploadModal, setUploadModal] = useState(false);
  const [studentReportModal, setStudentReportModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const isProduct = isProductCampaign(campaign_type_id);
  const isHibernate = isHibernateCompany(companyId);
  const isBigKahuna = isBigKahunaCompany(companyId);

  return (
    <Fragment>
      <Toolbar>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            endIcon={<ExpandMoreIcon />}
          >
            Actions
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClick={handleClose}
          >
            <MenuItem onClick={() => setUploadModal(true)}>
              <ListItemIcon>
                <PublishIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Bulk import" />
            </MenuItem>
            {isProduct && (
              <StudentNumbersExport renderMenuItem campaign_id={campaign_id} />
            )}
            {isProduct && isBigKahuna && (
              <StudentNumbersExport
                renderMenuItem
                campaign_id={campaign_id}
                bigKahunaSmallOrders
              />
            )}
            {isBigKahuna && (
              <MenuItem onClick={() => bigKahunaLimoReport(campaign_id)}>
                <ListItemIcon>
                  <DirectionsCarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Monster limo report" />
              </MenuItem>
            )}
            {isBigKahuna && (
              <MenuItem onClick={() => bigKahunaLimoReport(campaign_id, true)}>
                <ListItemIcon>
                  <DirectionsCarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Blast party report" />
              </MenuItem>
            )}
            {isBigKahuna && (
              <MenuItem onClick={() => STSStudentInfoReport(campaign_id)}>
                <ListItemIcon>
                  <SchoolIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="STS Student Info" />
              </MenuItem>
            )}
            {isBigKahuna && (
              <MenuItem
                onClick={() =>
                  bigKahunaLargeOrderStudentPhoneNumbers(campaign_id)
                }
              >
                <ListItemIcon>
                  <PhoneIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Large order phone numbers" />
              </MenuItem>
            )}
            <MenuItem onClick={() => setStudentReportModal(true)}>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Students report" />
            </MenuItem>

            {isProduct && (
              <MenuItem onClick={() => teachersReport(campaign_id)}>
                <ListItemIcon>
                  <AppleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Teachers report - items" />
              </MenuItem>
            )}
            {isProduct && (
              <MenuItem onClick={() => orderFormDiscrepancyReport(campaign_id)}>
                <ListItemIcon>
                  <MoneyOffIcon />
                </ListItemIcon>
                <ListItemText primary="Order form discrepancies" />
              </MenuItem>
            )}
          </Menu>
          <ExportButton
            label="export"
            disabled={total === 0}
            resource="volunteers"
            sort={currentSort}
            maxResults={2000}
            filterValues={{
              ...filterValues,
              campaign_id,
              campaign_type_id,
              for_export: true,
            }}
            exporter={data => volunteerExporter(data, isProduct, isHibernate)}
          />
        </div>
      </Toolbar>

      {uploadModal && (
        <UploadVolunteersModal
          campaignId={campaign_id}
          setUploadModal={setUploadModal}
        />
      )}
      {studentReportModal && (
        <StudentsReportModal
          campaign_id={campaign_id}
          isProduct={isProduct}
          setStudentReportModal={setStudentReportModal}
        />
      )}
    </Fragment>
  );
}

function volunteerExporter(volunteers, isProduct, isHibernate) {
  const formattedVolunteers = volunteers.map(v => {
    const {
      id,
      first_name,
      last_name,
      activated,
      orgSlug,
      slug,
      campaign_slug,
      email,
      phone,
      raised,
      validContactCount,
      contactsEmailed,
      class_name,
      itemsSold,
      pillows: pillowsSold,
      sheets: sheetsSold,
      company_frontend_url,
      team,
    } = v;
    const webpageLink = mainSiteUrl(
      orgSlug,
      campaign_slug,
      slug,
      company_frontend_url,
    );
    let exportObj = {
      ID: id,
      "First Name": first_name,
      "Last Name": last_name,
      "Activated Campaign Page": activated ? "Yes" : "No",
      "Webpage Link": webpageLink,
      Email: email,
      Phone: phone,
      "Number Of Valid Contacts": validContactCount,
      "Number Of Contacts Manually Emailed": contactsEmailed,
      Class: class_name,
      Team: team,
    };
    if (!isHibernate || !isProduct) exportObj["Total Raised"] = raised;
    if (isProduct) {
      exportObj["Total Items Sold"] = itemsSold;
      if (isHibernate) {
        exportObj["Sheets Sold"] = sheetsSold;
        exportObj["Pillows Sold"] = pillowsSold;
      }
    }
    return exportObj;
  });
  jsonExport(formattedVolunteers, (err, csv) => {
    downloadCSV(csv, "Students");
  });
}

async function bigKahunaLimoReport(campaignId, isBlastParty = false) {
  let url = `/xlsx_reports/big_kahuna_monster_limo?campaign_id=${campaignId}`;
  let name = "Monster Limo Party Report.XLSX";
  if (isBlastParty) {
    url = `${url}&custom=blast_party`;
    name = "Blast Party Report.XLSX";
  }
  const res = await APIUtilClient.get(url, {
    responseType: "arraybuffer",
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
      ...generateAuthHeader(),
    },
  });
  downloadXlsx(res, name);
}

async function orderFormDiscrepancyReport(campaignId) {
  const res = await APIUtilClient.get(
    `/reports/order-form-discrepancy-report?campaign_id=${campaignId}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  return downloadPdf(res, "Order Form Discrepancy Report.pdf");
}

async function teachersReport(campaignId) {
  const res = await APIUtilClient.get(
    `/xlsx_reports/teachers_report?campaign_id=${campaignId}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  downloadXlsx(res, "Teachers Report.XLSX");
}

async function STSStudentInfoReport(campaignId) {
  const res = await APIUtilClient.get(
    `/xlsx_reports/sts_student_info?campaign_id=${campaignId}`,
    {
      responseType: "arraybuffer",
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/json",
        ...generateAuthHeader(),
      },
    },
  );
  downloadXlsx(res, "Ship To School Student Info Report.XLSX");
}

async function bigKahunaLargeOrderStudentPhoneNumbers(campaignId) {
  const res = await APIUtilClient.get(
    `/reports/big-kahuna-large-order-student-phones?campaign_id=${campaignId}`,
    { headers: generateAuthHeader() },
  );
  return downloadReport(res, "Large Order Student Phone Numbers", {
    includeHeaders: false,
  });
}

function downloadReport(res, name, options = {}) {
  const { error, data, errorMessage } = res;
  if (error) {
    return alert(
      errorMessage ||
        "There was an error downloading your report, please try again later.",
    );
  }

  jsonExport(data, options, (err, csv) => {
    downloadCSV(csv, name);
  });
}
