import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  theme => ({
    container: {
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 55px)",
        boxSizing: "border-box",
        padding: "8px 16px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    wrapper: {
      paddingTop: "16px",
      width: "100%",
      // height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        //margin: "0 16px",
      },
    },
    charts: {
      display: "flex",
      flex: 1,
      width: "100%",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        maxWidth: "100%",
      },
    },
    chart: {
      flex: 1,
    },
    leftChart: {
      marginRight: "15px",
      flex: 1,
      [theme.breakpoints.down("sm")]: {
        marginRight: "0",
        marginBottom: "20px",
      },
    },
    fulfillerHeader: {
      fontSize: 36,
      marginTop: 100,
      [theme.breakpoints.down("sm")]: {
        marginTop: 24,
        fontSize: 24,
      },
    },
    fulfillerTxt: {
      fontSize: 18,
      marginTop: 18,
    },
  }),
  {
    classNamePrefix: "dashboard",
  },
);
