import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { APIUtilClient, generateAuthHeader, downloadPdf } from "../../lib";

export function ExportBackOrderSlip({ product_id }) {
  const [downloading, setDownloading] = useState(false);
  const classes = styles();

  const exportBO = async e => {
    e.stopPropagation();
    setDownloading(true);
    const res = await APIUtilClient.get(
      `inventory/back_ordered_product/${product_id}/pdf`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    setTimeout(() => {
      setDownloading(false);
    }, 1000);
    return downloadPdf(res, "back-order-slip.pdf");
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={exportBO}
      disabled={downloading}
      className={classes.button}
      size="small"
    >
      Export BO Slip
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  button: { width: 140 },
}));
