import React, { useState, useEffect, Fragment } from "react";
import { useNotify } from "react-admin";
import { APIClient, generateAuthHeader } from "../../lib";
import { FulfillOrder } from "./FulfillOrder";
import { ReplacementModal } from "./components/ReplacementModal";
import { Replacements } from "./components/Replacements";

export function ShipToSchoolOrder(props) {
  const {
    volunteer_id,
    campaign_id,
    closeDrawerAndRefresh,
    warehouse_id,
    printStsLabelsAtFulfillment,
  } = props;
  const notify = useNotify();
  const [upcProductIds, setUpcProductIds] = useState({});
  const [prevFulfilledItems, setFulfilledItems] = useState({});
  const [prevOpenItems, setOpenItems] = useState({});
  const [prevBackOrderedItems, setBackOrderedItems] = useState({});
  const [prevPrizeItems, setPrizeItems] = useState({});
  const [replaceableItems, setReplaceableItems] = useState([]);
  const [replacements, setReplacements] = useState([]);
  const [volunteerData, setVolunteerData] = useState({});

  useEffect(() => {
    if (volunteer_id && campaign_id) fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volunteer_id, campaign_id]);

  const fetchOrder = async () => {
    const res = await APIClient.get(
      `/order_fulfillment/school/campaign/${campaign_id}/volunteer/${volunteer_id}?warehouse_id=${warehouse_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data, status } = res;
    if (error) {
      if (status === 404) closeDrawerAndRefresh();
      notify(errorMessage, "warning");
      return;
    }

    setUpcProductIds(data.upcProductIds);
    setFulfilledItems(data.fulfilledItems);
    setOpenItems(data.openItems);
    setBackOrderedItems(data.backOrderedItems);
    setPrizeItems(data.prizeItems);
    setReplaceableItems(data.replaceableItems);
    setReplacements(data.replacements);
    setVolunteerData(data.volunteerData);
  };

  return (
    <Fragment>
      <FulfillOrder
        {...{
          volunteer_id: volunteer_id,
          campaign_id,
          upcProductIds,
          prevFulfilledItems,
          prevOpenItems,
          prevBackOrderedItems,
          prevPrizeItems,
          warehouse_id,
          closeDrawerAndRefresh,
          isSchoolOrder: true,
          volunteerData,
          printStsLabelsAtFulfillment,
        }}
      />
      <ReplacementModal
        replaceableItems={replaceableItems}
        campaign_id={campaign_id}
        volunteer_id={volunteer_id}
        fetchOrder={fetchOrder}
        isSchoolOrder
      />
      <Replacements replacements={replacements} />
    </Fragment>
  );
}
