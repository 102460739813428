import { Fragment, useState } from "react";
import EditIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { format } from "date-fns";
import { makeStyles, IconButton } from "@material-ui/core";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { EventGroupDetails } from "./EventGroupDetails";
import classNames from "classnames";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps;
};
export function EventGroupRows({ listProps }: Props) {
  const { rows, params, setParams, nextPage, paginationProps } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <Fragment>
      <ListRowsWrapper>
        <ListRows minWidth={MIN_WIDTH}>
          {isDesktop && (
            <ListHeaderRowContainer>
              <div className={classes.row}>
                <SortableColumnHeader
                  columnName="Group ID"
                  fieldName="id"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Group name"
                  fieldName="group_name"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Active"
                  fieldName="active"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Active from"
                  fieldName="active_start_date"
                  params={params}
                  setParams={setParams}
                />
                <SortableColumnHeader
                  columnName="Active until"
                  fieldName="active_end_date"
                  params={params}
                  setParams={setParams}
                />
                <div />
              </div>
            </ListHeaderRowContainer>
          )}
          {rows.map((row, index) => {
            const lastRow = index === rows.length - 1;
            return (
              <Row
                key={row.id as number}
                row={row}
                lastRow={lastRow}
                listProps={listProps}
              />
            );
          })}
        </ListRows>
        <ListPagination
          nextPage={nextPage}
          paginationProps={paginationProps}
          label="Groups per page:"
          minWidth={MIN_WIDTH}
        />
      </ListRowsWrapper>
    </Fragment>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  listProps: ListProps;
};
function Row({ row, lastRow, listProps }: RowProps) {
  const { id, group_name, active, active_start_date, active_end_date } = row;
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            <div>{group_name}</div>
            <div>
              {active ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>{format(new Date(active_start_date as string), "M/d/y")}</div>
            <div>{format(new Date(active_end_date as string), "M/d/y")}</div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div
                className={classNames(
                  classes.status,
                  !active && classes.inactiveStatus,
                )}
              />
              <div className={classes.mobileContent}>
                <div>#{id}</div>
                <div className={classes.groupName}>{group_name}</div>
              </div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <EventGroupDetails
          onClose={() => setShowEdit(false)}
          refreshList={listProps.refreshList}
          record={row}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "120px 1fr 160px 160px 160px 48px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: 16,
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 0,
      },
    },
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mobileLeft: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  mobileContent: {
    overflow: "hidden",
    paddingRight: 12,
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  status: {
    height: 8,
    width: 8,
    minWidth: 8,
    borderRadius: "100%",
    backgroundColor: "#5FEA6B",
    marginRight: 16,
  },
  inactiveStatus: {
    backgroundColor: "#ED2A2A",
  },
  groupName: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      letterSpacing: 0.1,
      lineHeight: "22px",
    },
  },
}));
