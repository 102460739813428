import React from "react";
import {
  Datagrid,
  List,
  TextField,
  BooleanField,
  Filter,
  TextInput,
} from "react-admin";

const CompanySeasonFilter = props => (
  <Filter {...props}>
    <TextInput label="Season Name" source="q" alwaysOn />
  </Filter>
);

export default function CompanySeasonList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Company Seasons"
      bulkActionButtons={false}
      exporter={false}
      sort={{ field: "id", order: "DESC" }}
      filters={<CompanySeasonFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="season_name" label="Name" />
        <BooleanField source="is_default" />
      </Datagrid>
    </List>
  );
}
