import { useEffect, useState } from "react";
import {
  TextInput,
  SimpleForm,
  required,
  Edit,
  Toolbar,
  SaveButton,
  BooleanInput,
  useNotify,
  useRefresh,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { APIClient, generateAuthHeader } from "../../lib";
const requiredField = [required()];

export default function EditCompanyPrizeProgram(props) {
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [currConfProducts, setCurrConfProducts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const url = `/company_prize_programs/${props.id}/configurable_product_autocomplete`;
      const response = await APIClient.get(url, {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = response;
      if (error) return notify(errorMessage, { type: "warning" });
      setCurrConfProducts(data);
    };
    if (props.id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const transformDataForSubmit = values => {
    const {
      program_name,
      disable,
      supplier_id,
      is_rep_restricted,
      allow_student_picks,
      send_approaching_tier_notifications,
      required_prize_selection_configurable_product_id,
    } = values;
    return {
      program_name,
      disable,
      supplier_id,
      is_rep_restricted,
      allow_student_picks,
      send_approaching_tier_notifications,
      required_prize_selection_configurable_product_id,
    };
  };

  return (
    <Edit
      {...props}
      title=" "
      undoable={false}
      actions={false}
      onSuccess={() => {
        refresh();
        notify("Config updated successfully");
      }}
      transform={transformDataForSubmit}
    >
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled />
        <TextInput
          source="product_supplier.supplier_name"
          label="Supplier"
          disabled
        />
        <TextInput
          source="program_name"
          label="Program Name"
          validate={requiredField}
        />
        <BooleanInput
          source="allow_student_picks"
          label="Allow Student Prize Picking"
          className={classes.fitContent}
        />
        <BooleanInput
          source="send_approaching_tier_notifications"
          label="Send Approaching Tier Notifications To Students"
          className={classes.fitContent}
        />
        <BooleanInput source="is_rep_restricted" label="Is Rep Restricted" />
        <BooleanInput source="disable" />
        <SelectInput
          source="required_prize_selection_configurable_product_id"
          label="Optional - Select a required prize selection configurable product (product must be on the program already)"
          choices={currConfProducts}
          optionText="product_name"
          optionValue="product_id"
          translateChoice={false}
          fullWidth
          resettable
        />
      </SimpleForm>
    </Edit>
  );
}

const styles = makeStyles(_theme => ({
  fitContent: {
    width: "fit-content",
  },
}));
