import { makeStyles, Collapse, IconButton } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { useState, ReactNode } from "react";

type FilterWrapperProps = {
  header: string;
  children: ReactNode;
  active: boolean;
};
export function FilterWrapper({
  header,
  children,
  active,
}: FilterWrapperProps) {
  const classes = styles();
  const [expand, setExpand] = useState(false);
  return (
    <div className={classes.filterWrapper}>
      <div
        className={classes.filterTop}
        onClick={() => setExpand(prev => !prev)}
      >
        <div className={classes.headerAndActiveDot}>
          {header} {active && <span className={classes.activeDot} />}
        </div>
        <IconButton size="small">
          {!expand ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </div>
      <Collapse in={expand} timeout="auto">
        <div className={classes.filterBody}>{children}</div>
      </Collapse>
    </div>
  );
}

const styles = makeStyles(theme => ({
  filterWrapper: {
    borderBottom: "1px solid #EAEBF3",
  },
  filterTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 55,
    fontSize: 16,
    letterSpacing: 0.11,
    cursor: "pointer",
    padding: "0 16px",
  },
  headerAndActiveDot: {
    display: "flex",
    alignItems: "center",
  },
  activeDot: {
    height: 8,
    width: 8,
    borderRadius: "100%",
    backgroundColor: theme.palette.secondary2.main,
    marginLeft: 8,
  },
  filterBody: {
    padding: "0 16px 16px 16px",
  },
}));
