export function PhotoSVG() {
  return (
    <svg width="201px" height="136px" viewBox="0 0 201 136">
      <title>image</title>
      <defs>
        <polygon points="0 0.11075 173.096006 0.11075 173.096006 0.88925 0 0.88925"></polygon>
        <polygon points="0 136 200.693 136 200.693 0 0 0"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-87, -64)">
          <g transform="translate(87, 64)">
            <path
              d="M189.7627,135.4643 C190.2327,130.4043 191.5297,125.4563 193.6037,120.8173 C194.1817,119.5263 194.8177,118.2633 195.5117,117.0293 C195.5857,116.8913 195.5347,116.7203 195.3977,116.6463 C195.2667,116.5753 195.1037,116.6183 195.0237,116.7433 C192.4987,121.2393 190.7307,126.1183 189.7887,131.1873 C189.5267,132.6033 189.3297,134.0283 189.1977,135.4643 C189.1637,135.8263 189.7297,135.8243 189.7627,135.4643 Z"
              fill="#F2F2F2"
            ></path>
            <path
              d="M196.8812,110.7754 C198.8582,110.7754 200.4612,112.3784 200.4612,114.3554 C200.4612,116.3324 198.8582,117.9354 196.8812,117.9354 C194.9042,117.9354 193.3012,116.3324 193.3012,114.3554 C193.3012,112.3784 194.9042,110.7754 196.8812,110.7754"
              fill="#F2F2F2"
            ></path>
            <path
              d="M190.129,135.5594 C190.434,132.2764 191.276,129.0664 192.621,126.0574 C192.996,125.2194 193.408,124.4004 193.859,123.5994 C193.907,123.5104 193.874,123.3994 193.785,123.3514 C193.7,123.3054 193.594,123.3324 193.542,123.4144 C191.904,126.3304 190.757,129.4964 190.146,132.7854 C189.976,133.7034 189.848,134.6284 189.762,135.5594 C189.741,135.7944 190.107,135.7924 190.129,135.5594 Z"
              fill="#F2F2F2"
            ></path>
            <path
              d="M194.747,119.5425 C196.03,119.5425 197.07,120.5825 197.07,121.8655 C197.07,123.1475 196.03,124.1875 194.747,124.1875 C193.464,124.1875 192.424,123.1475 192.424,121.8655 C192.424,120.5825 193.464,119.5425 194.747,119.5425"
              fill="#F2F2F2"
            ></path>
            <path
              d="M189.6661,135.3401 C187.8871,132.5641 186.5771,129.5151 185.7861,126.3151 C185.5671,125.4241 185.3881,124.5241 185.2511,123.6161 C185.2341,123.5161 185.1391,123.4491 185.0391,123.4661 C184.9441,123.4821 184.8781,123.5691 184.8871,123.6651 C185.3901,126.9721 186.4321,130.1731 187.9731,133.1421 C188.4031,133.9711 188.8711,134.7791 189.3771,135.5661 C189.5041,135.7641 189.7931,135.5371 189.6661,135.3401"
              fill="#F2F2F2"
            ></path>
            <path
              d="M184.8846,119.3795 C186.1676,119.3795 187.2076,120.4195 187.2076,121.7025 C187.2076,122.9845 186.1676,124.0245 184.8846,124.0245 C183.6016,124.0245 182.5616,122.9845 182.5616,121.7025 C182.5616,120.4195 183.6016,119.3795 184.8846,119.3795"
              fill="#F2F2F2"
            ></path>
            <path
              d="M39.8294,135.4643 C39.3594,130.4043 38.0624,125.4563 35.9884,120.8173 C35.4104,119.5263 34.7744,118.2633 34.0804,117.0293 C34.0054,116.8913 34.0574,116.7203 34.1944,116.6453 C34.3254,116.5753 34.4884,116.6183 34.5684,116.7433 C37.0934,121.2393 38.8614,126.1183 39.8034,131.1873 C40.0654,132.6033 40.2624,134.0283 40.3944,135.4643 C40.4284,135.8263 39.8624,135.8243 39.8294,135.4643 Z"
              fill="#F2F2F2"
            ></path>
            <path
              d="M32.7109,110.7754 C34.6879,110.7754 36.2909,112.3784 36.2909,114.3554 C36.2909,116.3324 34.6879,117.9354 32.7109,117.9354 C30.7339,117.9354 29.1309,116.3324 29.1309,114.3554 C29.1309,112.3784 30.7339,110.7754 32.7109,110.7754"
              fill="#F2F2F2"
            ></path>
            <path
              d="M39.4632,135.5594 C39.1582,132.2764 38.3162,129.0664 36.9712,126.0574 C36.5962,125.2194 36.1842,124.4004 35.7332,123.5994 C35.6852,123.5104 35.7182,123.3994 35.8072,123.3504 C35.8922,123.3054 35.9982,123.3324 36.0502,123.4144 C37.6882,126.3304 38.8352,129.4964 39.4462,132.7854 C39.6162,133.7034 39.7442,134.6284 39.8302,135.5594 C39.8512,135.7944 39.4852,135.7924 39.4632,135.5594 Z"
              fill="#F2F2F2"
            ></path>
            <path
              d="M34.8451,119.5425 C36.1281,119.5425 37.1681,120.5825 37.1681,121.8655 C37.1681,123.1475 36.1281,124.1875 34.8451,124.1875 C33.5621,124.1875 32.5221,123.1475 32.5221,121.8655 C32.5221,120.5825 33.5621,119.5425 34.8451,119.5425"
              fill="#F2F2F2"
            ></path>
            <path
              d="M39.926,135.3401 C41.705,132.5641 43.015,129.5151 43.806,126.3151 C44.025,125.4241 44.204,124.5241 44.341,123.6161 C44.358,123.5161 44.453,123.4491 44.553,123.4661 C44.648,123.4821 44.714,123.5691 44.705,123.6651 C44.202,126.9721 43.16,130.1731 41.619,133.1421 C41.189,133.9711 40.721,134.7791 40.215,135.5661 C40.088,135.7641 39.799,135.5371 39.926,135.3401 Z"
              fill="#F2F2F2"
            ></path>
            <path
              d="M44.7076,119.3795 C45.9906,119.3795 47.0306,120.4195 47.0306,121.7025 C47.0306,122.9845 45.9906,124.0245 44.7076,124.0245 C43.4246,124.0245 42.3846,122.9845 42.3846,121.7025 C42.3846,120.4195 43.4246,119.3795 44.7076,119.3795"
              fill="#F2F2F2"
            ></path>
            <path
              d="M176.3065,88.6621 L176.3625,130.7551 C176.3625,132.3821 175.0465,133.7031 173.4195,133.7071 L155.9345,133.7331 C160.8565,124.5251 163.6435,110.0321 163.6275,98.8601 C163.6225,94.4511 163.1745,90.0541 162.2905,85.7351 L173.3545,85.7190994 C174.9815,85.7181 176.3025,87.0351 176.3065,88.6621"
              fill="#2498FE"
            ></path>
            <g transform="translate(27.5969, 135.1108)">
              <mask fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g></g>
              <path
                d="M172.819,0.88925 L0.277,0.88925 C0.124,0.88825 0,0.76325 0,0.60925 C0,0.45725 0.125,0.33325 0.277,0.33225 L172.819,0.33225 C172.973,0.33325 173.096006,0.45825 173.096006,0.61225 C173.096006,0.76525 172.972,0.88825 172.819,0.88925"
                fill="#212121"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M150.6839,82.9089 L119.5219,82.7869 L122.5529,72.7049 C123.0489,71.0799 124.5459,69.9669 126.2459,69.9589 L126.2609,69.9589 L144.0469,70.0289 C145.7519,70.0429 147.2479,71.1699 147.7329,72.8039 L150.6839,82.9089 Z"
              fill="#212121"
            ></path>
            <path
              d="M168.0804,80.1406 C166.2734,80.1406 164.8084,81.3006 164.8084,82.7306 L171.3524,82.7306 C171.3524,81.3006 169.8874,80.1406 168.0804,80.1406"
              fill="#FD7B6F"
            ></path>
            <path
              d="M92.1269,82.9647 C90.1919,82.9667 88.6239,84.5347 88.6209,86.4707 L88.6209,131.8087 C88.6239,133.7437 90.1919,135.3117 92.1269,135.3147 L178.1299,135.3147 C180.0649,135.3117 181.6329,133.7437 181.6349,131.8087 L181.6349,86.4707 C181.6329,84.5347 180.0649,82.9667 178.1299,82.9647 L92.1269,82.9647 Z M178.1299,135.7817 L92.1269,135.7817 C89.9339,135.7787 88.1569,134.0017 88.1539,131.8087 L88.1539,86.4707 C88.1569,84.2767 89.9339,82.4997 92.1269,82.4977 L178.1299,82.4977 C180.3229,82.4997 182.0999,84.2767 182.1029,86.4707 L182.1029,131.8087 C182.0999,134.0017 180.3229,135.7787 178.1299,135.7817 L178.1299,135.7817 Z"
              fill="#212121"
            ></path>
            <path
              d="M138.8675,79.1086 L131.3885,79.1086 C130.2915,79.1086 129.4025,78.2196 129.4025,77.1226 C129.4025,76.0246 130.2915,75.1356 131.3885,75.1356 L138.8675,75.1356 C139.9645,75.1356 140.8535,76.0246 140.8535,77.1226 C140.8535,78.2196 139.9645,79.1086 138.8675,79.1086"
              fill="#FFFFFF"
            ></path>
            <path
              d="M135.1283,91.8454 C125.5773,91.8454 117.8343,99.5884 117.8343,109.1394 C117.8343,118.6904 125.5773,126.4334 135.1283,126.4334 C144.6793,126.4334 152.4223,118.6904 152.4223,109.1394 C152.4113,99.5924 144.6753,91.8564 135.1283,91.8454 M135.1283,126.9004 C125.3193,126.9004 117.3673,118.9484 117.3673,109.1394 C117.3673,99.3304 125.3193,91.3784 135.1283,91.3784 C144.9373,91.3784 152.8893,99.3304 152.8893,109.1394 C152.8783,118.9444 144.9333,126.8894 135.1283,126.9004"
              fill="#212121"
            ></path>
            <path
              d="M135.1283,97.9217 C128.9333,97.9217 123.9103,102.9437 123.9103,109.1397 C123.9103,115.3347 128.9333,120.3577 135.1283,120.3577 C141.3233,120.3577 146.3463,115.3347 146.3463,109.1397 C146.3393,102.9467 141.3203,97.9287 135.1283,97.9217 M135.1283,120.8247 C128.6743,120.8247 123.4433,115.5927 123.4433,109.1397 C123.4433,102.6857 128.6743,97.4547 135.1283,97.4547 C141.5823,97.4547 146.8133,102.6857 146.8133,109.1397 C146.8063,115.5897 141.5793,120.8177 135.1283,120.8247"
              fill="#212121"
            ></path>
            <path
              d="M135.1283,100.2587 C140.0333,100.2587 144.0093,104.2347 144.0093,109.1397 C144.0093,114.0437 140.0333,118.0197 135.1283,118.0197 C130.2233,118.0197 126.2473,114.0437 126.2473,109.1397 C126.2473,104.2347 130.2233,100.2587 135.1283,100.2587"
              fill="#D8D8D8"
            ></path>
            <path
              d="M129.7531,109.1394 C129.7531,106.2294 131.8961,103.7624 134.7781,103.3554 C131.5831,102.9004 128.6251,105.1224 128.1711,108.3164 C127.7161,111.5114 129.9381,114.4694 133.1331,114.9234 C133.6781,115.0014 134.2321,115.0014 134.7781,114.9234 C131.8961,114.5164 129.7531,112.0494 129.7531,109.1394"
              fill="#FFFFFF"
            ></path>
            <path
              d="M162.7103,82.4973 L173.4503,82.4973 C173.3213,79.5313 170.8123,77.2323 167.8463,77.3613 C165.0613,77.4823 162.8313,79.7123 162.7103,82.4973 L162.7103,82.4973 Z M173.9233,82.9643 L162.2383,82.9643 L162.2383,82.7313 C162.2383,79.5043 164.8533,76.8883 168.0803,76.8883 C171.3073,76.8883 173.9233,79.5043 173.9233,82.7313 L173.9233,82.9643 Z"
              fill="#212121"
            ></path>
            <path
              d="M93.9965,85.3017 C92.9635,85.3017 92.1265,86.1387 92.1265,87.1717 C92.1265,88.2037 92.9635,89.0407 93.9965,89.0407 C95.0295,89.0407 95.8665,88.2037 95.8665,87.1717 C95.8645,86.1397 95.0285,85.3027 93.9965,85.3017 M93.9965,89.5087 C92.7055,89.5087 91.6595,88.4617 91.6595,87.1717 C91.6595,85.8807 92.7055,84.8347 93.9965,84.8347 C95.2875,84.8347 96.3335,85.8807 96.3335,87.1717 C96.3325,88.4617 95.2865,89.5067 93.9965,89.5087"
              fill="#212121"
            ></path>
            <path
              d="M93.9965,86.0028 C94.6415,86.0028 95.1655,86.5258 95.1655,87.1718 C95.1655,87.8168 94.6415,88.3398 93.9965,88.3398 C93.3515,88.3398 92.8275,87.8168 92.8275,87.1718 C92.8275,86.5258 93.3515,86.0028 93.9965,86.0028"
              fill="#FD7B6F"
            ></path>
            <path
              d="M100.0728,86.0028 C100.7178,86.0028 101.2418,86.5258 101.2418,87.1718 C101.2418,87.8168 100.7178,88.3398 100.0728,88.3398 C99.4278,88.3398 98.9038,87.8168 98.9038,87.1718 C98.9038,86.5258 99.4278,86.0028 100.0728,86.0028"
              fill="#D8D8D8"
            ></path>
            <path
              d="M106.149,86.0028 C106.794,86.0028 107.318,86.5258 107.318,87.1718 C107.318,87.8168 106.794,88.3398 106.149,88.3398 C105.504,88.3398 104.98,87.8168 104.98,87.1718 C104.98,86.5258 105.504,86.0028 106.149,86.0028"
              fill="#D8D8D8"
            ></path>
            <path
              d="M100.0728,85.3017 C99.0398,85.3017 98.2028,86.1387 98.2028,87.1717 C98.2028,88.2037 99.0398,89.0407 100.0728,89.0407 C101.1058,89.0407 101.9428,88.2037 101.9428,87.1717 C101.9408,86.1397 101.1048,85.3027 100.0728,85.3017 M100.0728,89.5087 C98.7818,89.5087 97.7358,88.4617 97.7358,87.1717 C97.7358,85.8807 98.7818,84.8347 100.0728,84.8347 C101.3638,84.8347 102.4098,85.8807 102.4098,87.1717 C102.4088,88.4617 101.3628,89.5067 100.0728,89.5087"
              fill="#212121"
            ></path>
            <path
              d="M106.149,85.3017 C105.116,85.3017 104.279,86.1387 104.279,87.1717 C104.279,88.2037 105.116,89.0407 106.149,89.0407 C107.182,89.0407 108.019,88.2037 108.019,87.1717 C108.017,86.1397 107.181,85.3027 106.149,85.3017 M106.149,89.5087 C104.858,89.5087 103.812,88.4617 103.812,87.1717 C103.812,85.8807 104.858,84.8347 106.149,84.8347 C107.44,84.8347 108.486,85.8807 108.486,87.1717 C108.485,88.4617 107.439,89.5067 106.149,89.5087"
              fill="#212121"
            ></path>
            <mask fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <g></g>
            <polygon
              fill="#212121"
              mask="url(#mask-4)"
              points="88.388 103.267 118.769 103.267 118.769 102.8 88.388 102.8"
            ></polygon>
            <polygon
              fill="#212121"
              mask="url(#mask-4)"
              points="88.388 112.674 118.015 112.674 118.015 112.207 88.388 112.207"
            ></polygon>
            <polygon
              fill="#212121"
              mask="url(#mask-4)"
              points="151.487 103.267 181.868 103.267 181.868 102.8 151.487 102.8"
            ></polygon>
            <polygon
              fill="#212121"
              mask="url(#mask-4)"
              points="152.189 112.674 181.816 112.674 181.816 112.207 152.189 112.207"
            ></polygon>
            <path
              d="M63.7604,56.6676 C61.8654,55.7966 60.6194,53.3076 60.6904,50.8526 C60.7614,48.3976 62.0534,46.1016 63.8354,44.9316 C65.5994,43.8006 67.8144,43.6336 69.7284,44.4856 C71.6414,45.2996 74.2084,45.5216 75.3584,47.5446 C76.2414,49.0996 75.9234,52.5676 75.2974,54.2966 C74.7574,55.7916 73.4354,56.6336 72.1464,57.1046 C69.2604,58.1496 66.0624,57.8926 63.3804,56.3986"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#FFD1D2"
              mask="url(#mask-4)"
              points="52.2049 132.9087 49.4799 132.0227 51.6029 121.0917 55.6239 122.3997"
            ></polygon>
            <path
              d="M47.7873,130.6106 L53.0423,132.3196 L51.9653,135.6286 L43.4023,132.8426 C43.9973,131.0156 45.9603,130.0166 47.7873,130.6106"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#FFD1D2"
              mask="url(#mask-4)"
              points="76.5963 132.9681 73.7313 132.9681 72.3683 121.9161 76.5963 121.9171"
            ></polygon>
            <path
              d="M71.6845,132.1492 L77.2105,132.1492 L77.2105,135.6282 L68.2055,135.6282 C68.2055,133.7072 69.7635,132.1492 71.6845,132.1492"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M76.4521,128.447808 C76.4351,128.447808 76.4181,128.447808 76.4001,128.4468 L73.2251,128.2948 C72.6701,128.2678 72.2321,127.8148 72.2241,127.2588 L71.7931,95.8518 C71.7911,95.6578 71.6321,95.5038 71.4381,95.5058 C71.3101,95.5078 71.1931,95.5798 71.1331,95.6928 L54.3161,127.5798 C54.1021,127.9898 53.6431,128.2108 53.1891,128.1218 L50.0291,127.5198 C49.4591,127.4118 49.0841,126.8608 49.1931,126.2908 C49.2031,126.2388 49.2161,126.1868 49.2341,126.1368 L61.4871,91.4198 L62.4301,81.1508 C62.4731,80.6568 62.8581,80.2618 63.3501,80.2038 C66.9201,79.7658 71.9091,76.8398 73.8881,75.6028 C74.1341,75.4478 74.4331,75.4028 74.7131,75.4788 C74.9971,75.5548 75.2361,75.7448 75.3751,76.0028 C80.1421,84.8628 81.9661,95.0108 80.5811,104.9768 L77.4921,127.5388 C77.4231,128.0598 76.9781,128.4498 76.4521,128.447808"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M67.9058,47.5997 C71.0758,47.5997 73.6458,50.1697 73.6458,53.3397 C73.6458,56.5097 71.0758,59.0797 67.9058,59.0797 C64.7358,59.0797 62.1658,56.5097 62.1658,53.3397 C62.1658,50.1697 64.7358,47.5997 67.9058,47.5997"
              fill="#FFD1D2"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M62.1865,83.6163 L60.4585,71.2663 C59.5815,67.3563 62.0405,63.4763 65.9505,62.5993 C69.8555,61.7233 73.7325,64.1763 74.6145,68.0803 C75.1065,70.3623 76.1065,73.1423 75.7415,76.1373 C73.1815,83.2423 68.5345,85.4323 62.1865,83.6163"
              fill="#BDBDBD"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M69.7819,64.4526 L65.5749,64.4526 C64.9949,64.4516 64.5239,63.9816 64.5239,63.4006 L64.5239,61.9986 C64.5239,61.4186 64.9949,60.9476 65.5749,60.9466 L69.7819,60.9466 C70.3629,60.9476 70.8329,61.4186 70.8339,61.9986 L70.8339,63.4006 C70.8329,63.9816 70.3629,64.4516 69.7819,64.4526"
              fill="#BDBDBD"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M91.1011,87.7954 C91.5321,86.4774 90.8131,85.0594 89.4961,84.6284 C89.3551,84.5824 89.2111,84.5494 89.0641,84.5284 L75.9701,65.7824 L72.0871,69.6204 L86.1791,86.8404 C86.0771,88.2314 87.1211,89.4414 88.5121,89.5434 C89.6751,89.6294 90.7451,88.9064 91.1011,87.7954"
              fill="#FFD1D2"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M80.0663,71.5171 L75.3373,74.6741 C74.8203,75.0191 74.1213,74.8791 73.7763,74.3621 C73.7453,74.3161 73.7183,74.2681 73.6953,74.2191 L71.2723,69.0941 C70.3223,67.6521 70.7203,65.7131 72.1623,64.7631 C73.5983,63.8161 75.5283,64.2071 76.4833,65.6381 L80.2763,69.8251 C80.6933,70.2861 80.6573,70.9981 80.1963,71.4151 C80.1553,71.4521 80.1123,71.4861 80.0663,71.5171 Z"
              fill="#BDBDBD"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M44.843,62.6069 C44.872,62.7329 44.89,62.8609 44.899,62.9899 L54.938,68.7819 L57.378,67.3769 L59.98,70.7829 L55.902,73.6889 C55.225,74.1719 54.311,74.1499 53.658,73.6349 L43.306,65.4679 C42.036,65.9379 40.624,65.2889 40.154,64.0189 C39.684,62.7479 40.332,61.3369 41.603,60.8669 C42.874,60.3959 44.285,61.0449 44.755,62.3159 C44.791,62.4109 44.82,62.5079 44.843,62.6069 Z"
              fill="#FFD1D2"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M57.8563,72.9903 L55.3333,67.8953 C55.0583,67.3383 55.2863,66.6623 55.8433,66.3863 C55.8923,66.3623 55.9433,66.3413 55.9963,66.3243 L61.3893,64.5813 C62.9413,63.8243 64.8133,64.4683 65.5703,66.0203 C66.3243,67.5653 65.6883,69.4303 64.1473,70.1923 L59.5073,73.4153 C58.9963,73.7703 58.2953,73.6433 57.9403,73.1323 C57.9093,73.0873 57.8803,73.0393 57.8563,72.9903 Z"
              fill="#BDBDBD"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M71.7578,46.1105 C72.1828,46.1725 72.5968,45.9415 72.7668,45.5475 C72.9218,45.1535 72.8858,44.7105 72.6698,44.3465 C72.4518,43.9895 72.1488,43.6915 71.7868,43.4815 C71.3708,43.2025 70.8888,43.0375 70.3888,43.0005 C69.8848,42.9675 69.3908,43.1545 69.0348,43.5145 C68.6848,43.8865 68.5948,44.4345 68.8078,44.8995 C69.0178,45.3045 69.4588,45.5315 69.8878,45.6855 C70.7388,45.9905 71.6458,46.1095 72.5468,46.0345"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M73.5931,55.9531 C72.5901,56.0001 72.7631,53.8711 72.4491,52.9171 C72.1351,51.9631 71.7021,50.8251 70.7141,50.6421 C69.9051,50.4931 69.1731,51.0841 68.4691,51.5091 C67.2121,52.2661 65.5861,52.5461 64.2661,51.9041 C62.9471,51.2631 62.1451,49.5491 62.7861,48.2301 C63.1811,47.4971 63.8621,46.9601 64.6671,46.7481 C65.4661,46.5431 66.2981,46.5031 67.1121,46.6301 C68.9291,46.8531 70.6751,47.4721 72.2271,48.4431 C73.2981,49.1151 74.3171,50.0291 74.6361,51.2521 C74.9541,52.4751 75.0841,55.8011 73.8381,56.0121 L73.5931,55.9531 Z"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M70.9683,43.1497 C70.2103,42.1887 70.4963,40.6757 71.3793,39.8287 C72.2623,38.9817 73.5763,38.7177 74.7943,38.8257 C77.2523,39.0727 79.3923,40.6117 80.4093,42.8627 C81.1793,44.6247 81.1733,46.6397 81.8703,48.4327 C83.3723,52.2987 87.7243,54.2147 91.5913,52.7117 C91.7343,52.6567 91.8753,52.5967 92.0153,52.5317 C93.2273,51.9727 94.3823,50.8787 94.3123,49.5447 C95.0793,52.5007 93.6983,55.8817 91.0823,57.4547 C89.5503,58.3767 87.7353,58.6897 85.9593,58.9057 C84.8023,59.0457 83.6133,59.1507 82.4843,58.8607 C80.9043,58.4567 79.5823,57.2907 78.7813,55.8707 C77.9803,54.4497 77.6653,52.7947 77.6533,51.1637 C77.6443,49.8937 77.8103,48.6227 77.7223,47.3557 C77.6343,46.0887 77.2533,44.7777 76.3353,43.8997 C75.4163,43.0227 73.8663,42.7337 72.8583,43.5067 C72.4523,43.8177 72.1553,44.2707 71.6993,44.4997 C71.2433,44.7297 70.5223,44.5507 70.4923,44.0417 L70.9683,43.1497 Z"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M1.6325,33.0812 C0.9315,33.0822 0.3635,33.6502 0.3625,34.3512 L0.3625,61.0152 C0.3635,61.7162 0.9315,62.2842 1.6325,62.2852 L48.9755,62.2852 C49.6765,62.2842 50.2445,61.7162 50.2455,61.0152 L50.2455,34.3512 C50.2445,33.6502 49.6765,33.0822 48.9755,33.0812 L1.6325,33.0812 Z M48.9755,62.6482 L1.6325,62.6482 C0.7315,62.6472 0.0015,61.9162 -0.0005,61.0152 L-0.0005,34.3512 C0.0015,33.4502 0.7315,32.7192 1.6325,32.7182 L48.9755,32.7182 C49.8765,32.7192 50.6065,33.4502 50.6075,34.3512 L50.6075,61.0152 C50.6065,61.9162 49.8765,62.6472 48.9755,62.6482 L48.9755,62.6482 Z"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M17.2397,37.8968 C20.9787,37.8968 24.0097,40.9278 24.0097,44.6668 C24.0097,48.4058 20.9787,51.4358 17.2397,51.4358 C13.5007,51.4358 10.4697,48.4058 10.4697,44.6668 C10.4697,40.9278 13.5007,37.8968 17.2397,37.8968"
              fill="#E6E6E6"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M43.3264,56.6207 L35.3794,42.8567 C35.2234,42.5857 34.8774,42.4937 34.6064,42.6497 C34.5204,42.6997 34.4494,42.7707 34.3994,42.8567 L29.5504,51.2567 L22.3204,38.7337 C22.1194,38.3867 21.6764,38.2687 21.3294,38.4687 C21.2184,38.5327 21.1274,38.6237 21.0634,38.7337 L10.8754,56.3817 C10.6744,56.7287 10.7934,57.1717 11.1404,57.3727 C11.2504,57.4357 11.3764,57.4697 11.5034,57.4697 L42.8364,57.4697 C43.1484,57.4697 43.4024,57.2167 43.4024,56.9037 C43.4024,56.8047 43.3764,56.7067 43.3264,56.6207"
              fill="#2498FE"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#FD7B6F"
              mask="url(#mask-4)"
              points="3.446 38.885 6.348 38.885 6.348 35.983 3.446 35.983"
            ></polygon>
            <path
              d="M30.412,0.2835 C29.864,0.2845 29.42,0.7275 29.42,1.2755 L29.42,22.1105 C29.42,22.6575 29.864,23.1015 30.412,23.1025 L67.404,23.1025 C67.952,23.1015 68.396,22.6575 68.396,22.1105 L68.396,1.2755 C68.396,0.7275 67.952,0.2845 67.404,0.2835 L30.412,0.2835 Z M67.404,23.3855 L30.412,23.3855 C29.708,23.3845 29.137,22.8145 29.136,22.1105 L29.136,1.2755 C29.137,0.5715 29.708,0.0005 30.412,0.0005 L67.404,0.0005 C68.108,0.0005 68.679,0.5715 68.68,1.2755 L68.68,22.1105 C68.679,22.8145 68.108,23.3855 67.404,23.3855 L67.404,23.3855 Z"
              fill="#D8D8D8"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M42.6069,4.0462 C45.5279,4.0462 47.8969,6.4142 47.8969,9.3362 C47.8969,12.2572 45.5279,14.6252 42.6069,14.6252 C39.6859,14.6252 37.3169,12.2572 37.3169,9.3362 C37.3169,6.4142 39.6859,4.0462 42.6069,4.0462"
              fill="#F2F2F2"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M62.99,18.6763 L56.781,7.9213 C56.659,7.7103 56.388,7.6373 56.177,7.7593 C56.11,7.7983 56.054,7.8543 56.015,7.9213 L52.226,14.4853 L46.577,4.7003 C46.42,4.4293 46.073,4.3363 45.802,4.4933 C45.716,4.5423 45.644,4.6143 45.595,4.7003 L37.634,18.4893 C37.477,18.7603 37.57,19.1073 37.841,19.2633 C37.927,19.3133 38.025,19.3393 38.125,19.3393 L62.607,19.3393 C62.851,19.3393 63.049,19.1413 63.049,18.8973 C63.049,18.8193 63.029,18.7433 62.99,18.6763"
              fill="#E6E6E6"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#D8D8D8"
              mask="url(#mask-4)"
              points="31.829 4.819 34.097 4.819 34.097 2.551 31.829 2.551"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}
