function mainSiteUrl(
  orgSlug,
  campaignSlug,
  volunteerSlug,
  companyFrontendUrl,
  affiliate,
) {
  if (!orgSlug) return "";
  let url = `${companyFrontendUrl}/${orgSlug}/${campaignSlug}`;
  if (volunteerSlug) url = `${url}/${volunteerSlug}`;
  if (affiliate) url = `${url}?a=${affiliate}`;
  return url;
}

export { mainSiteUrl };
