import { makeStyles, MenuItem, Button } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { FormApi } from "final-form";
import { format } from "date-fns";
import { setToast } from "../../../state";
import {
  APIAuthClient,
  composeValidators,
  regexValidatorGenerator,
  requiredCheckbox,
  requiredField,
} from "../../../lib";
import {
  CheckboxField,
  CircularLoader,
  TextFieldWrapper,
} from "../../../components";
import {
  ACHAccountTypeOptions,
  APIRes,
  DIGITS_ONLY_REGEX,
  GenericObject,
  ROUTING_NUM_REGEX,
  ToastTypes,
} from "../../../types";
import { RootState } from "../../../types/state";
import { useIsMobile } from "../../../hooks/ui";
import { LockSVG } from "../../../assets";
const AUTH_TXT_BASE =
  "is hereby authorized to deposit funds into the account listed above. I have the authorization to authorize deposits into the account listed above.";
const SUBMIT_TXT =
  "By clicking the 'Submit' button, you are certifying that the above statements and information are true.";
const routingValidator = regexValidatorGenerator(
  ROUTING_NUM_REGEX,
  "Must be 9 digits",
);
const accountNumValidator = regexValidatorGenerator(
  DIGITS_ONLY_REGEX,
  "Numbers only (with no spaces)",
);

export function AchForm() {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { company_name, campaignId, profit_paid_via_ach } = useSelector(
    ({ campaign }: RootState) => campaign,
  );
  const [showUnavailable, setShowUnavailable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<GenericObject>({});
  const companyAuthTxt = `${company_name || ""} ${AUTH_TXT_BASE}`;

  useEffect(() => {
    if (campaignId && profit_paid_via_ach) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, profit_paid_via_ach]);

  useEffect(() => {
    if (typeof profit_paid_via_ach !== "undefined" && !profit_paid_via_ach) {
      setShowUnavailable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profit_paid_via_ach]);

  const fetchData = async () => {
    const res = await APIAuthClient.get<any, APIRes>(
      `/org_campaign_settings/campaign/${campaignId}`,
    );
    const { error, errorMessage, data } = res;
    if (error) dispatch(setToast(errorMessage));
    else setInitialValues(data);

    setLoading(false);
  };

  const onSubmit = async (values: any, form: FormApi) => {
    const {
      ach_confirmed_by,
      ach_account_type,
      ach_name_on_account,
      ach_routing_number,
      ach_account_number,
      ach_confirmed,
    } = values;
    const update = {
      ach_confirmed_by,
      ach_account_type,
      ach_name_on_account,
      ach_routing_number,
      ach_account_number,
      ach_confirmed,
      ach_agreement_text: `${companyAuthTxt} ${SUBMIT_TXT}`,
    };

    const res = await APIAuthClient.put<any, APIRes>(
      `/org_campaign_settings/ach_form/campaign/${campaignId}`,
      update,
    );
    const { error, errorMessage, data } = res;
    if (error) dispatch(setToast(errorMessage));
    else {
      dispatch(setToast("Changes saved", ToastTypes.success));
      form.restart(data);
    }
  };

  if (showUnavailable) {
    return (
      <div className={classes.unavailableContainer}>
        <LockSVG />
        <div className={classes.unavailable}>
          This feature is not available for your campaign
        </div>
      </div>
    );
  } else if (loading) {
    return (
      <div>
        <CircularLoader show />
      </div>
    );
  }

  return (
    <Fragment>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <h2 className={classes.header}>ACH deposit authorization</h2>
              <h3 className={classes.subheader}>
                Fill out this form with your bank account information to enable
                direct deposits for your fundraising campaign earnings.
              </h3>

              <div className={classes.inputGroup}>
                <Field
                  name="ach_confirmed_by"
                  component={TextFieldWrapper}
                  label="Your full name"
                  validate={requiredField}
                  className={classes.halfInput}
                />
                <Field
                  select
                  name="ach_account_type"
                  component={TextFieldWrapper}
                  label="Account type"
                  validate={requiredField}
                  className={classes.halfInput}
                >
                  {ACHAccountTypeOptions.map(({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Field>
              </div>

              <Field
                name="ach_name_on_account"
                component={TextFieldWrapper}
                label="Name on account"
                validate={requiredField}
                className={classes.input}
                helperText="Enter the business or organization name if applicable."
              />

              <div className={classes.inputGroup}>
                <Field
                  name="ach_routing_number"
                  component={TextFieldWrapper}
                  label="Routing number"
                  type="number"
                  validate={composeValidators(requiredField, routingValidator)}
                  className={classes.halfInput}
                />
                <Field
                  name="ach_account_number"
                  component={TextFieldWrapper}
                  label="Account number"
                  type="number"
                  validate={composeValidators(
                    requiredField,
                    accountNumValidator,
                  )}
                  className={classes.halfInput}
                />
              </div>

              {values.ach_confirmed_on && (
                <div className={classes.lastUpdated}>
                  Last updated on{" "}
                  {format(new Date(values.ach_confirmed_on), "Pp")}
                </div>
              )}

              {!isMobile && <div className={classes.spacer} />}

              <CheckboxField
                fieldName="ach_confirmed"
                label={
                  <div>
                    <div className={classes.cbLabel}>{companyAuthTxt}</div>
                    <div className={classes.cbSubLabel}>{SUBMIT_TXT}</div>
                  </div>
                }
                errorMsg="Please read & affirm the checkbox"
                validate={requiredCheckbox}
              />

              <div className={classes.bottom}>
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting || pristine}
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </form>
          );
        }}
      />
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  unavailableContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 32,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 16,
    },
  },
  unavailable: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
    textAlign: "center",
    marginTop: 24,
    maxWidth: 254,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.14,
    lineHeight: "24px",
    marginBottom: 8,
  },
  subheader: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "21px",
    color: theme.palette.text.secondary,
    marginBottom: 24,
  },
  form: {
    width: 552,
    maxWidth: 552,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  inputGroup: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    marginBottom: 24,
    width: "calc(50% - 12px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    marginBottom: 24,
    width: "100%",
  },
  lastUpdated: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginTop: 16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 20,
    },
  },
  cbLabel: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  cbSubLabel: {
    fontSize: 12,
    letterSpacing: 0.11,
    lineHeight: "18px",
    color: theme.palette.text.secondary,
    marginTop: 4,
  },
  spacer: {
    marginTop: 16,
    marginBottom: 20,
    borderTop: "1px solid #DBDEEE",
  },
  bottom: {
    marginTop: 24,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  save: {
    width: 120,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
    },
  },
}));
