import { useEffect } from "react";
import { Notification, useNotify, useLogout } from "react-admin";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  storeDashboardStats,
  clearUserInfo,
  clearDashboardStats,
} from "../../customState";
import { SiteAdminDashboard } from "./SiteAdminDashboard";
import { CompanyDashboard } from "./CompanyDashboard";
import { RepDashboard } from "./RepDashboard";
import { FulfillerAndDataEntryDashboard } from "./FulfillerAndDataEntryDashboard";
import {
  APIClient,
  generateAuthHeader,
  isSuperAdmin,
  isRep,
  isFulfiller,
  isCompanyOrCompanyAdmin,
  isCompanyDataEntry,
  getUserTimezone,
  isRepManager,
} from "../../lib";
import { styles } from "./Dashboard.styles";
import { RepManagerDashboard } from "./RepManagerDashboard";

function _DashboardController(props) {
  const {
    role,
    season_id,
    storeDashboardStats,
    clearDashboardStats,
    clearUserInfo,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const logout = useLogout();

  useEffect(() => {
    if (
      role &&
      !isFulfiller(role) &&
      !isCompanyDataEntry(role) &&
      !isRepManager(role)
    ) {
      fetchDashboardData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, season_id]);

  const fetchDashboardData = async () => {
    clearDashboardStats();
    const timezone = getUserTimezone();
    let url = `/dashboards/stats?timezone=${timezone}`;
    if (season_id) url = `${url}&season_id=${season_id}`;
    const res = await APIClient.get(url, { headers: generateAuthHeader() });
    const { error, errorMessage, data, status } = res;
    if (error) {
      if (status === 403) {
        clearUserInfo();
        logout();
      }
      return notify(errorMessage, "warning");
    }
    storeDashboardStats(data);
  };

  return (
    <div className={classes.container}>
      {isSuperAdmin(role) && <SiteAdminDashboard />}
      {isCompanyOrCompanyAdmin(role) && <CompanyDashboard />}
      {isRep(role) && <RepDashboard />}
      {(isFulfiller(role) || isCompanyDataEntry(role)) && (
        <FulfillerAndDataEntryDashboard />
      )}
      {isRepManager(role) && <RepManagerDashboard />}
      <Notification />
    </div>
  );
}
export default withRouter(
  connect(
    state => {
      const {
        user: { role, season_id },
      } = state;
      return {
        role,
        season_id,
      };
    },
    {
      storeDashboardStats,
      clearDashboardStats,
      clearUserInfo,
    },
  )(_DashboardController),
);
