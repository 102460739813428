import React from "react";
import RichTextInput from "ra-input-rich-text";

export function EmailWYSIWYG({ label, source, ...rest }) {
  return (
    <RichTextInput
      source={source}
      label={`${label} (Don't leave empty lines on the top or bottom)`}
      toolbar={[
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        ["link"],
        ["clean"],
      ]}
      {...rest}
    />
  );
}
