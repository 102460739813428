import { useState, Fragment } from "react";
import { IconButton, Button, makeStyles } from "@material-ui/core";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { APIAuthClient, downloadCsv } from "../../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignId, getVolunteerLabel, setToast } from "../../../../state";
import {
  ButtonSpinner,
  MultiFileDropzoneField,
  ResponsiveModal,
} from "../../../../components";
import { useIsMobile, useDrawerTransition } from "../../../../hooks/ui";
import { FailedUploads } from "./FailedUploads";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};
export function VolunteerUpload({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const campaignId = useSelector(getCampaignId);
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const {
    volunteerLabelSing,
    volunteerLabel,
    volunteerLabelLC,
    volunteerLabelLCSingular,
  } = useSelector(getVolunteerLabel);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [failedRows, setFailedRows] = useState<null | GenericObject[]>(null);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const files: File[] | undefined = values.files;
    if (!files || !files.length) {
      complete();
      return dispatch(setToast("Please upload A CSV file"));
    }
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = async () => {
      const res = await APIAuthClient.post<any, APIRes>(
        "/volunteers/csv-upload",
        { csv: reader.result, campaignId },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        setDisableSubmit(false);
        complete();
        return dispatch(setToast(errorMessage));
      }

      refreshList();

      if (!data.failedRows.length) {
        onClose();
        return dispatch(
          setToast(`${volunteerLabel} have been uploaded`, ToastTypes.success),
        );
      }
      setFailedRows(data.failedRows);
      setDisableSubmit(false);
    };
    reader.onerror = console.error;
  };

  if (failedRows) {
    return <FailedUploads onClose={onClose} failedRows={failedRows} />;
  }
  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <h2>Bulk upload {volunteerLabelLC}</h2>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.mainContent}>
                  {/* SECTION 1 */}
                  <div className={classes.section}>
                    <div className={classes.sectionNum}>1.</div>
                    <div>
                      <div className={classes.sectionHeader}>
                        Download the CSV template with the required format.
                      </div>
                      <Button
                        color="primary"
                        className={classes.downloadBtn}
                        startIcon={<GetAppOutlinedIcon />}
                        onClick={() => exportTemplate(volunteerLabelSing)}
                      >
                        Download CSV template
                      </Button>
                    </div>
                  </div>

                  {/* SECTION 2 */}
                  <div className={classes.section}>
                    <div className={classes.sectionNum}>2.</div>
                    <div className={classes.sectionContent}>
                      <div className={classes.sectionHeader}>
                        Add your {volunteerLabelLC} and save the file as a CSV.
                      </div>
                      <div className={classes.instructions}>
                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            Use the column headers exactly as they appear in the
                            template file.
                          </div>
                        </div>

                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            Fill in the{" "}
                            <span className={classes.bold}>row_number</span> so
                            that if a {volunteerLabelLCSingular} fails to upload
                            we can identify it for you.
                          </div>
                        </div>

                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            The following fields are required:
                            <div>
                              <span className={classes.dash}>&#8259;</span>
                              <span className={classes.bold}>row_number</span>
                            </div>
                            <div>
                              <span className={classes.dash}>&#8259;</span>
                              <span className={classes.bold}>
                                first_name & last_name
                              </span>{" "}
                              OR <span className={classes.bold}>full_name</span>
                            </div>
                            <div>
                              <span className={classes.dash}>&#8259;</span>
                              <span className={classes.bold}>email</span>
                            </div>
                          </div>
                        </div>

                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            To activate the {volunteerLabelLCSingular}, set the{" "}
                            <span className={classes.bold}>activate</span> to
                            `yes` (case sensitive).
                          </div>
                        </div>

                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            To allow an email address to be shared by multiple{" "}
                            {volunteerLabelLC}, set{" "}
                            <span className={classes.bold}>
                              can_duplicate_email
                            </span>{" "}
                            to `yes` (case sensitive) for all {volunteerLabelLC}{" "}
                            sharing the email address.
                            <br />
                            PLEASE NOTE: This should only be allowed where
                            multiple {volunteerLabelLCSingular} accounts must
                            share a common email address, e.g. parent/guardian.{" "}
                            {volunteerLabel} should not be allowed to create
                            duplicate accounts. {volunteerLabel} cannot use an
                            email address that is in use by an admin user.
                          </div>
                        </div>

                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            To link {volunteerLabelLCSingular} accounts sharing
                            an email address, set{" "}
                            <span className={classes.bold}>
                              associate_shared_emails
                            </span>{" "}
                            to 'yes' (case sensitive). Once linked, all
                            associated {volunteerLabelLCSingular} accounts can
                            be accessed through any linked user login.
                            <br />
                            PLEASE NOTE: This field can only be configured if{" "}
                            <span className={classes.bold}>
                              can_duplicate_email
                            </span>{" "}
                            is set to `yes`.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SECTION 3 */}
                  <div className={classes.section}>
                    <div className={classes.sectionNum}>3.</div>
                    <div className={classes.sectionContent}>
                      <div className={classes.sectionHeader}>
                        Upload your CSV file.
                      </div>
                      <div className={classes.fileUploadSpacer} />
                      <MultiFileDropzoneField
                        fieldName="files"
                        singleFileOnly
                        accept={[
                          "application/vnd.ms-excel",
                          "text/csv",
                          "text/plain",
                        ]}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Fragment>
                      <div>
                        <Button
                          variant="text"
                          disabled={disableSubmit}
                          className={classes.cancel}
                          onClick={e => {
                            e.stopPropagation();
                            onClose();
                          }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          color="primary"
                          variant="text"
                          className={classes.upload}
                          disabled={disableSubmit}
                          onClick={e => {
                            e.stopPropagation();
                            form.submit();
                          }}
                        >
                          SAVE
                          <ButtonSpinner show={disableSubmit} />
                        </Button>
                      </div>
                    </Fragment>
                  )}

                  {isMobile && (
                    <Fragment>
                      <Button
                        color="primary"
                        className={classes.upload}
                        disabled={disableSubmit}
                        onClick={e => {
                          e.stopPropagation();
                          form.submit();
                        }}
                      >
                        Upload
                        <ButtonSpinner show={disableSubmit} />
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        disabled={disableSubmit}
                        onClick={e => {
                          e.stopPropagation();
                          onClose();
                        }}
                      >
                        CANCEL
                      </Button>
                    </Fragment>
                  )}
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

function exportTemplate(label: string) {
  const template = {
    row_number: undefined,
    first_name: undefined,
    last_name: undefined,
    full_name: undefined,
    email: undefined,
    phone: undefined,
    class_name: undefined,
    activate: undefined,
    team_id: undefined,
    can_duplicate_email: undefined,
    associate_shared_emails: undefined,
  };
  downloadCsv([template], `${label} Upload Template`);
}

const styles = makeStyles(theme => ({
  container: {
    width: 944,
    maxWidth: 944,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    position: "sticky",
    top: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    borderBottom: "1px solid #DBDEEE",
    minHeight: 64,
    padding: "0 16px 0 24px",
    backgroundColor: "#FFFFFF",
    zIndex: 9,
  },
  mainContent: {
    padding: 24,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
  },
  section: {
    marginTop: 24,
    display: "flex",
  },
  sectionNum: {
    width: 20,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  sectionContent: {
    width: "100%",
  },
  sectionHeader: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  downloadBtn: {
    marginTop: 16,
  },
  instructions: {
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    width: "100%",
    padding: 16,
    marginTop: 16,
  },
  instructionRow: {
    display: "flex",
    marginBottom: 6,
  },
  bullet: {
    width: 20,
    minWidth: 20,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  dash: {
    paddingLeft: 8,
    paddingRight: 12,
  },
  instructionsTxt: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  bold: {
    fontWeight: 500,
  },
  form: {
    width: "100%",
  },
  fileUploadSpacer: {
    height: 16,
  },
  bottom: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px 24px 16px 24px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
      padding: 16,
    },
  },
  upload: {
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
      width: 300,
      maxWidth: "100%",
    },
  },
  cancel: {
    color: theme.palette.text.secondary,
  },
}));
