import React from "react";
import { useSelector } from "react-redux";
import { useDataProvider, useNotify } from "react-admin";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  RA_SUB_PATH,
  isMrsFieldsCompany,
  isSimplyGoodnessCompany,
} from "../../lib";

export function ImpersonateCompany({ companyId }) {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const isMrsFields = isMrsFieldsCompany(companyId);
  const isSimplyGoodness = isSimplyGoodnessCompany(companyId);
  // hardcoding Bitbean super-admin user ID in production
  const isBitbeanAdminUser = useSelector(state => state.user.userId === 1);

  const impersonate = e => {
    e.stopPropagation();
    dataProvider
      .getOne("companies/impersonate", { id: companyId })
      .then(({ data }) => {
        const { impersonationToken } = data;
        localStorage.setItem("companyImpersonationToken", impersonationToken);
        window.location.replace(`/${RA_SUB_PATH}`);
      })
      .catch(({ message }) => {
        return notify(message || "an unexpected error occurred", "warning");
      });
  };

  // per client block his access to certain companies (but allow Bitbean admin access)
  if (!isBitbeanAdminUser && (isMrsFields || isSimplyGoodness)) return <></>;
  return <DashboardIcon onClick={e => impersonate(e)} />;
}
