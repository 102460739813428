import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { LoginAndForgotPWForm } from "./LoginAndForgotPWForm";
import { EmailSent } from "./EmailSent";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { Notification } from "react-admin";
import queryString from "query-string";
import { Toast } from "../../components";
import { theme } from "../../theme/theme";
import { APIPublicClient, loggedInCheck, validateAndGetInt } from "../../lib";
import { APIRes, LoginModes, LoginTypes } from "../../types";
import { useHistory, useLocation } from "react-router-dom";

export function Login() {
  return (
    <ThemeProvider theme={theme}>
      <LoginContent />
      <Notification />
    </ThemeProvider>
  );
}

function LoginContent() {
  const classes = styles();
  const location = useLocation();
  const history = useHistory();
  const {
    jwt,
    c: queryC,
    campaignId: queryCampaignId,
    campaign_id: queryCampaign_id,
    v: queryV,
    volunteer_id: queryVolunteer_id,
    org_id: queryOrgId,
    company_slug,
  } = queryString.parse(location.search);
  const [mode, setMode] = useState<LoginModes>(LoginModes.login);
  const [type, setType] = useState<LoginTypes>(
    Boolean(company_slug) ? LoginTypes.admin : LoginTypes.reg,
  );
  const [useOrgInfo, setUseOrgInfo] = useState(true);
  const [orgInfoFetched, setOrgInfoFetched] = useState(false);
  const [orgLogo, setOrgLogo] = useState(null);
  const [orgName, setOrgName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [campaign_id, setCampaignId] = useState<null | number>(null);
  const [org_id, setOrgId] = useState<null | number>(null);
  const [volunteer_id, setVolunteerId] = useState<null | number>(null);

  useEffect(() => {
    loggedInCheck(location, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    const cId = validateAndGetInt(
      queryC ? queryC : queryCampaignId ? queryCampaignId : queryCampaign_id,
    );
    if (cId) setCampaignId(cId);

    const vId = validateAndGetInt(queryV ? queryV : queryVolunteer_id);
    if (vId) setVolunteerId(vId);

    const oId = validateAndGetInt(queryOrgId);
    if (oId) setOrgId(oId);

    if (!vId && !cId && !oId) setUseOrgInfo(false);
    else {
      fetchOrgHeaderInfo(cId, oId, vId);
      setUseOrgInfo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryC,
    queryCampaignId,
    queryCampaign_id,
    queryOrgId,
    queryV,
    queryVolunteer_id,
  ]);

  useEffect(() => {
    if (jwt) setMode(LoginModes.resetPW);
  }, [jwt]);

  useEffect(() => {
    setIsAdmin(type === LoginTypes.admin);
  }, [type]);

  const fetchOrgHeaderInfo = async (
    cId: number | null,
    oId: number | null,
    vId: number | null,
  ) => {
    let url = "/orgs/login_header_info?";
    if (cId) url = `${url}campaign_id=${cId}`;
    else if (oId) url = `${url}org_id=${oId}`;
    else url = `${url}volunteer_id=${vId}`;
    const res = await APIPublicClient.get<any, APIRes>(url);
    const { error, data } = res;
    // TODO: for now if there is an error just ignoring
    if (error) {
      setUseOrgInfo(false);
      return;
    }
    const { logo, name } = data;
    setOrgLogo(logo);
    setOrgName(name);
    setOrgInfoFetched(true);
  };

  return (
    <div className={classes.container}>
      <div className={classes.cardWrapper}>
        <div className={classes.card}>
          {/* LOGO */}
          {!isAdmin && useOrgInfo && orgInfoFetched && orgLogo && (
            <img src={orgLogo} alt="Logo" className={classes.orgLogo} />
          )}
          {(isAdmin || !useOrgInfo || (orgInfoFetched && !orgLogo)) && (
            <img src="/images/InstaraiseLogo.png" />
          )}

          {/* HEADER */}
          {!isAdmin && orgName && (
            <div className={classes.orgName}>{orgName}</div>
          )}
          {(isAdmin || !useOrgInfo) && (
            <div className={classes.welcome}>Welcome to InstaRaise</div>
          )}

          <div className={classes.spacer} />

          {(mode === LoginModes.login || mode === LoginModes.forgotPW) && (
            <LoginAndForgotPWForm
              mode={mode}
              setMode={setMode}
              type={type}
              setType={setType}
              campaign_id={campaign_id}
              org_id={org_id}
              volunteer_id={volunteer_id}
            />
          )}
          {mode === LoginModes.emailSent && <EmailSent setMode={setMode} />}
          {mode === LoginModes.resetPW && (
            <ResetPasswordForm campaign_id={campaign_id} />
          )}
        </div>
      </div>
      <Notification />
      <Toast />
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    minHeight: "100vh",
    minWidth: "100%",
    backgroundImage: "url('/images/loginDesktopBG.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      backgroundImage: "url('/images/loginMobileBG.jpg')",
    },
  },
  cardWrapper: {
    padding: "24px 16px",
    maxWidth: "100%",
  },
  card: {
    width: 450,
    maxWidth: "100%",
    padding: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)",
  },
  orgLogo: {
    maxHeight: 72,
    maxWidth: 200,
  },
  orgName: {
    textAlign: "center",
    paddingTop: 16,
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 600,
    letterSpacing: 0.19,
  },
  welcome: {
    textAlign: "center",
    paddingTop: 16,
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 600,
    letterSpacing: 0.19,
  },
  spacer: {
    height: 32,
  },
}));
