import { IconButton, makeStyles } from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  ListContainer,
  ListNoResults,
  MobileListSort,
  MultiSearch,
} from "../../../components";
import { useList } from "../../../hooks/list";
import { CampaignCardRows } from "./CampaignCardRows";
import {
  campaignDiscountCardSearchFields,
  campaignDiscountCardSortFields,
} from "../../../types";
import { NoDiscountCardsSVG } from "../../../assets";
import { useState } from "react";

export function CampaignCards() {
  const classes = styles();
  const isMobile = useIsMobile();
  useScrollToTop();
  const [showSort, setShowSort] = useState(false);

  const listProps = useList<any>({
    baseUrl: "/discount_cards/campaign_card_list",
    isReady: true,
    initialState: { sort: "card_name", order: "asc" },
  });
  const { listIsFiltered, total, noResults, showRows } = listProps;

  return (
    <ListContainer listProps={listProps} topOffset={148}>
      {isMobile && (
        <div className={classes.mobileTop}>
          <div className={classes.count}>
            {total} Campaign card
            {total !== 1 ? "s" : ""}
          </div>
          <IconButton onClick={() => setShowSort(true)}>
            <SwapVertIcon />
          </IconButton>
        </div>
      )}
      <div className={classes.searchRow}>
        <MultiSearch
          listProps={listProps}
          fields={campaignDiscountCardSearchFields}
        />
      </div>

      {noResults && (
        <ListNoResults
          SVG={NoDiscountCardsSVG}
          listIsFiltered={listIsFiltered}
          label="campaign cards"
        />
      )}

      {showRows && <CampaignCardRows listProps={listProps} />}
      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={campaignDiscountCardSortFields}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  mobileTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  searchRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 16,
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
}));
