export function TextContactsSVG() {
  return (
    <svg width="129px" height="136px" viewBox="0 0 129 136">
      <title>Text contacts</title>
      <defs>
        <polygon points="0 0 63.2898 0 63.2898 126.929 0 126.929"></polygon>
        <polygon points="0 136 128.143 136 128.143 0 0 0"></polygon>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-123, -64)">
          <g transform="translate(123, 64)">
            <g transform="translate(64.8532, 0.0002)">
              <mask fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g></g>
              <path
                d="M63.29,30.163 L62.593,30.163 L62.593,11.058 C62.593,4.951 57.642,0 51.535,0 L11.057,0 C4.95,0 0,4.95 0,11.057 L0,115.872 C0,121.979 4.95,126.929 11.057,126.929 L51.535,126.929 C57.642,126.929 62.593,121.979 62.593,115.872 L62.593,43.763 L63.29,43.763 L63.29,30.163 Z"
                fill="#212121"
                mask="url(#mask-2)"
              ></path>
            </g>
            <path
              d="M116.8342,2.8768 L111.5502,2.8768 C112.3692,4.8828 111.4062,7.1728 109.3992,7.9908 C108.9292,8.1828 108.4262,8.2818 107.9182,8.2818 L84.7292,8.2818 C82.5622,8.2818 80.8062,6.5248 80.8062,4.3578 C80.8062,3.8498 80.9052,3.3468 81.0962,2.8768 L76.1622,2.8768 C71.6012,2.8768 67.9042,6.5738 67.9042,11.1348 L67.9042,115.7948 C67.9042,120.3558 71.6012,124.0528 76.1612,124.0528 L116.8342,124.0528 C121.3952,124.0528 125.0922,120.3558 125.0922,115.7948 L125.0922,11.1348 C125.0922,6.5738 121.3952,2.8768 116.8342,2.8768 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M98.6326,87.8717 C91.3906,87.8737 85.5216,93.7467 85.5235995,100.9887 C85.5266,108.2307 91.3986,114.0997 98.6406,114.096701 C100.8416,114.096701 103.0066,113.5417 104.9366,112.4847 L105.6356,112.1007 L106.2856,112.5627 C107.0286,113.1037 107.8716,113.4897 108.7666,113.6977 L106.7066,111.3897 L107.6096,110.5407 C110.2606,108.0717 111.7596,104.6077 111.745697,100.9847 C111.7376,93.7457 105.8716,87.8797 98.6326,87.8717 M98.6326,116.639702 C89.9776,116.6437 82.9576,109.6307 82.9535983,100.9747 C82.9496,92.3197 89.9626,85.2997 98.6176,85.2956983 C107.2736,85.2917 114.2936,92.3047 114.297601,100.9607 C114.2986,104.8617 112.8456,108.6227 110.2216,111.5097 L113.4946,115.1787 L111.8616,115.8717 C109.3346,116.9427 106.8626,115.9007 105.4746,115.0677 C103.3416,116.1017 101.0026,116.639702 98.6326,116.639702"
              fill="#E6E6E6"
            ></path>
            <path
              d="M113.016611,100.9844 C113.0136,93.0434 106.5736,86.6084 98.6326,86.611399 C90.6916,86.6144 84.2566,93.0544 84.259599,100.9954 C84.2626,108.9364 90.7026,115.3714 98.6436,115.368401 C101.0576,115.3674 103.4316,114.7594 105.5486,113.5994 C106.5426,114.3064 108.9686,115.7174 111.3646,114.7014 L108.4806,111.4674 C111.3786,108.7524 113.0216,104.9564 113.016611,100.9844"
              fill="#FFFFFF"
            ></path>
            <mask fill="white">
              <use xlinkHref="#path-3"></use>
            </mask>
            <g></g>
            <polygon
              fill="#2498FE"
              mask="url(#mask-4)"
              points="91.224 98.997 101.502 98.997 101.502 98.043 91.224 98.043"
            ></polygon>
            <polygon
              fill="#E6E6E6"
              mask="url(#mask-4)"
              points="91.224 102.299 109.138 102.299 109.138 101.345 91.224 101.345"
            ></polygon>
            <polygon
              fill="#E6E6E6"
              mask="url(#mask-4)"
              points="91.224 105.601 109.12 105.601 109.12 104.647 91.224 104.647"
            ></polygon>
            <path
              d="M98.6326,18.2833 C91.3906,18.2853 85.5216,24.1583 85.5235995,31.4003 C85.5266,38.6423 91.3986,44.5113 98.6406,44.5083011 C100.8416,44.5083011 103.0066,43.9533 104.9366,42.8963 L105.6356,42.5123 L106.2856,42.9743 C107.0286,43.5153 107.8716,43.9013 108.7666,44.1093 L106.7066,41.8013 L107.6096,40.9523 C110.2606,38.4833 111.7596,35.0193 111.745697,31.3963 C111.7376,24.1573 105.8716,18.2913 98.6326,18.2833 Z M98.6326,47.0513017 C89.9776,47.0553 82.9576,40.0423 82.9535983,31.3863 C82.9496,22.7313 89.9626,15.7113 98.6176,15.7072983 C107.2736,15.7033 114.2936,22.7163 114.297601,31.3723 C114.2986,35.2733 112.8456,39.0343 110.2216,41.9213 L113.4946,45.5903 L111.8616,46.2833 C109.3346,47.3543 106.8626,46.3123 105.4746,45.4793 C103.3416,46.5133 101.0026,47.0513017 98.6326,47.0513017 L98.6326,47.0513017 Z"
              fill="#E6E6E6"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M113.016611,31.396 C113.0136,23.455 106.5736,17.02 98.6326,17.022999 C90.6916,17.026 84.2566,23.466 84.259599,31.407 C84.2626,39.348 90.7026,45.783 98.6436,45.780001 C101.0576,45.779 103.4316,45.171 105.5486,44.011 C106.5426,44.718 108.9686,46.129 111.3646,45.113 L108.4806,41.879 C111.3786,39.164 113.0216,35.368 113.016611,31.396"
              fill="#FFFFFF"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#2498FE"
              mask="url(#mask-4)"
              points="91.224 29.409 101.502 29.409 101.502 28.455 91.224 28.455"
            ></polygon>
            <polygon
              fill="#E6E6E6"
              mask="url(#mask-4)"
              points="91.224 32.711 109.138 32.711 109.138 31.757 91.224 31.757"
            ></polygon>
            <polygon
              fill="#E6E6E6"
              mask="url(#mask-4)"
              points="91.224 36.013 109.12 36.013 109.12 35.059 91.224 35.059"
            ></polygon>
            <path
              d="M118.5563,43.854 C119.8083,43.854 120.8233,44.869 120.8233,46.121 C120.8233,47.373 119.8083,48.389 118.5563,48.389 C117.3043,48.389 116.2893,47.373 116.2893,46.121 C116.2893,44.869 117.3043,43.854 118.5563,43.854"
              fill="#FD7B6F"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M118.5563,113.4424 C119.8083,113.4424 120.8233,114.4574 120.8233,115.7094 C120.8233,116.9614 119.8083,117.9774 118.5563,117.9774 C117.3043,117.9774 116.2893,116.9614 116.2893,115.7094 C116.2893,114.4574 117.3043,113.4424 118.5563,113.4424"
              fill="#E6E6E6"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M68.485,83.0192 C66.826,84.0142 63.87,85.2612 60.849,83.9802 L58.896,83.1512 L62.809,78.7652 C55.875,71.1192 56.452,59.3002 64.097,52.3662 C71.743,45.4322 83.562,46.0092 90.496,53.6542 C97.43,61.3002 96.853,73.1192 89.208,80.0532 C83.554,85.1802 75.35,86.3552 68.485,83.0192 Z"
              fill="#2498FE"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M92.43,66.0931 C92.429,57.1841 85.626,49.7501 76.752,48.9621 C77.253,48.9181 77.759,48.8951 78.272,48.8951 C87.77,48.8981 95.467,56.6011 95.4640009,66.0991 C95.461,75.5931 87.765,83.2881 78.272,83.2911 C77.76,83.2911 77.254,83.2671 76.754,83.2241 C85.627,82.4351 92.429,75.0011 92.43,66.0931"
              fill="#000000"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#FFFFFF"
              mask="url(#mask-4)"
              points="66.79 63.804 79.079 63.804 79.079 62.663 66.79 62.663"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              mask="url(#mask-4)"
              points="66.79 67.752 88.209 67.752 88.209 66.611 66.79 66.611"
            ></polygon>
            <polygon
              fill="#FFFFFF"
              mask="url(#mask-4)"
              points="66.79 71.7 88.188 71.7 88.188 70.559 66.79 70.559"
            ></polygon>
            <path
              d="M0.1744,136 L66.6234,136 C66.7194,136 66.7984,135.922 66.7984,135.826 C66.7984,135.729 66.7194,135.651 66.6234,135.651 L0.1744,135.651 C0.0784,135.651 0.0004,135.729 0.0004,135.826 C0.0004,135.922 0.0784,136 0.1744,136 Z"
              fill="#D8D8D8"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M32.0534,60.9587 L32.3164,59.6457 C32.3164,59.6457 36.1794,58.3327 36.6114,60.9587 L32.0534,60.9587 Z"
              fill="#E6E6E6"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#9F616A"
              mask="url(#mask-4)"
              points="39.4673 133.2641 42.0003 133.2641 43.2043 123.4951 39.4663 123.4961"
            ></polygon>
            <path
              d="M38.8212,132.4372 L43.8092,132.4372 L43.8092,132.4372 C45.5642,132.4372 46.9872,133.8602 46.9872,135.6152 L46.9872,135.7182 L38.8212,135.7192 L38.8212,132.4372 Z"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#9F616A"
              mask="url(#mask-4)"
              points="27.2588 133.2641 29.7918 133.2641 30.9958 123.4951 27.2578 123.4961"
            ></polygon>
            <path
              d="M26.6127,132.4372 L31.6007,132.4372 L31.6007,132.4372 C33.3557,132.4372 34.7787,133.8602 34.7787,135.6152 L34.7787,135.7182 L26.6127,135.7192 L26.6127,132.4372 Z"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#212121"
              mask="url(#mask-4)"
              points="28.3766 66.474 25.4876 66.211 23.6496 74.878 23.9116 82.757 26.8006 93.263 30.7406 90.899 28.6396 80.131"
            ></polygon>
            <polygon
              fill="#212121"
              mask="url(#mask-4)"
              points="26.5479 92.3435 27.1949 97.8585 27.8019 110.7275 26.4069 129.9055 31.3969 129.6375 33.8429 109.6775 35.3359 100.4855 37.4369 110.9905 38.7509 130.6885 43.8719 130.2775 43.8719 110.9215 44.0029 96.8085 42.1649 91.2935"
            ></polygon>
            <path
              d="M37.3061,61.2214 C37.3061,61.2214 35.4681,58.5954 32.0531,60.4334 L30.4781,62.5344 L25.4871,66.2114 L26.5381,78.5554 L25.2251,94.0504 L43.8721,93.7884 L40.7201,78.2924 L40.7201,61.7464 L37.3061,61.2214 Z"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#000000"
              mask="url(#mask-4)"
              points="27.1946 70.5707 25.6886 88.5767 27.5886 78.8177"
            ></polygon>
            <path
              d="M58.5401,85.6673 C57.5271,86.0113 56.4271,85.4693 56.0831,84.4563 C56.0461,84.3483 56.0191,84.2373 56.0021,84.1243 L51.7051,83.0623 L51.7131,80.2933 L57.7611,81.8783 C58.8321,81.7873 59.7751,82.5823 59.8661,83.6543 C59.9421,84.5513 59.3941,85.3833 58.5401,85.6673 Z"
              fill="#9F616A"
              mask="url(#mask-4)"
            ></path>
            <polygon
              fill="#E6E6E6"
              mask="url(#mask-4)"
              points="53.324 84.4002 54.047 80.2602 55.858 80.5772 55.135 84.7162"
            ></polygon>
            <path
              d="M31.8537,48.2862 C34.4097,46.8892 37.6137,47.8282 39.0107,50.3842 C40.4077,52.9402 39.4687,56.1442 36.9127,57.5412 C34.3577,58.9382 31.1527,57.9992 29.7557,55.4432 C28.3587,52.8882 29.2977,49.6832 31.8537,48.2862"
              fill="#9F616A"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M34.7773,57.5661 C31.9043,58.2561 29.0163,56.4861 28.3263,53.6131 C27.7323,51.1361 28.9663,48.5831 31.2773,47.5111 C32.2043,47.0971 33.2183,46.9161 34.2313,46.9811 C35.8083,47.0641 37.3413,47.6471 38.9203,47.6041 L39.1493,47.9721 L40.0113,47.7631 L40.1963,48.4471 L40.4233,48.4401 C40.8303,49.1961 40.1753,50.2791 39.3173,50.2691 C38.9773,50.2661 38.6553,50.1241 38.3233,50.0531 C37.9903,49.9821 37.5993,49.9991 37.3703,50.2501 C37.0723,50.5771 37.2013,51.0861 37.2643,51.5231 C37.3263,51.9611 37.1703,52.5501 36.7293,52.5641 C36.5033,52.5721 36.2893,52.4101 36.0673,52.4531 C35.8093,52.5021 35.6783,52.8141 35.7163,53.0741 C35.7803,53.3291 35.8843,53.5731 36.0253,53.7951 C36.6653,55.0731 36.1253,56.8111 34.8733,57.5011"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
            <path
              d="M39.4668,62.7614 L40.7208,61.7464 C40.7208,61.7464 43.2498,61.5704 43.2048,64.2524 L47.2528,76.9154 L55.0188,79.6664 L53.9718,85.0734 L42.9848,80.8874 L37.4038,71.6364 L39.4668,62.7614 Z"
              fill="#212121"
              mask="url(#mask-4)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
