import React from "react";
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  NumberField,
  useNotify,
  useMutation,
  useRefresh,
  FunctionField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { Button } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { styles } from "./DigitalContentGroup.styles";

export function DigitalContentInventoryGroupProducts({ record }) {
  const groupId = record.id;

  return (
    <List
      title=" "
      location={{}}
      resource="digital_content_inventory_group_products"
      basePath=""
      filter={{ dc_inventory_group_id: groupId }}
      perPage={25}
      sort={{ field: "product_added", order: "DESC" }}
      bulkActionButtons={false}
      component="div"
    >
      <Datagrid rowClick={null}>
        <TextField source="id" label="Product Id" />
        <FunctionField
          sortBy="product_added"
          label="Action"
          render={record =>
            record.productAdded ? (
              <RemoveProduct
                digitalContentInventoryGroupProductId={
                  record.digitalContentInventoryGroupProductId
                }
              />
            ) : (
              <AddProduct product_id={record.id} group_id={groupId} />
            )
          }
        />
        <TextField source="sku" />
        <ImageField
          source="thumbnail_image"
          label="Thumbnail"
          sortable={false}
        />
        <ArrayField source="categories" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="digital_content_id" label="Digital Content ID" />
        <TextField
          source="digital_content_code_qty"
          label="Digital Content Code Qty"
        />
        <TextField source="product_name" label="Name" />
        <NumberField source="product_price" label="Price" />
      </Datagrid>
    </List>
  );
}

function RemoveProduct({ digitalContentInventoryGroupProductId }) {
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();

  const [remove, { loading }] = useMutation(
    {
      type: "delete",
      resource: "digital_content_inventory_group_products",
      payload: { id: digitalContentInventoryGroupProductId },
    },
    {
      onFailure: error => notify(error.message, "warning"),
      onSuccess: () => {
        refresh();
        notify("Product Removed");
      },
    },
  );

  return (
    <Button
      variant="contained"
      className={classes.removeButton}
      startIcon={<DeleteIcon className={classes.icon} />}
      onClick={remove}
      disabled={loading}
    >
      remove
    </Button>
  );
}

function AddProduct({ product_id, group_id }) {
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();

  const [add, { loading }] = useMutation(
    {
      type: "create",
      resource: "digital_content_inventory_group_products",
      payload: { data: { product_id, group_id: Number(group_id) } },
    },
    {
      onFailure: error => notify(error.message, "warning"),
      onSuccess: () => {
        refresh();
        notify("Product Added");
      },
    },
  );

  return (
    <Button
      variant="contained"
      className={classes.addButton}
      startIcon={<SaveIcon className={classes.icon} />}
      onClick={add}
      disabled={loading}
    >
      add
    </Button>
  );
}
