import { useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import {
  APIAuthClient,
  composeValidators,
  convertFileToB64,
  requiredField,
  twoDecimalsOnly,
} from "../../../lib";
import { APIRes, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  NullableField,
  ResponsiveModal,
  SwitchField,
  TextFieldWrapper,
} from "../../../components";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddCardTemplate({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const { cardImageFile, supportLogoFile, ...rest } = values;
    const post = { ...rest };
    if (cardImageFile) {
      post.cardImageBase64 = await convertFileToB64(cardImageFile);
    }
    if (supportLogoFile) {
      post.supportLogoBase64 = await convertFileToB64(supportLogoFile);
    }
    const url = `/discount_cards`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Card template added", ToastTypes.success));
    onClose();
    refreshList();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form, values }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              {!isMobile && (
                <div className={classes.top}>
                  <h1>Add card template</h1>
                </div>
              )}
              {isMobile && (
                <div className={classes.mobileTop}>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              {isMobile && (
                <div className={classes.mobileHeader}>
                  <h1>Add card template</h1>
                </div>
              )}

              <div className={classes.mainContent}>
                <div className={classes.inputGroup}>
                  <Field
                    component={TextFieldWrapper}
                    name="card_name"
                    label="Template name"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                  <Field
                    component={TextFieldWrapper}
                    name="expires_on"
                    label="Expires on"
                    validate={requiredField}
                    type="date"
                    className={classes.halfInput}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>

                <div className={classes.inputGroup}>
                  <div className={classes.halfInput}>
                    <SwitchField
                      fieldName="display_estimated_values"
                      label="Display estimated values"
                    />
                  </div>

                  {values.display_estimated_values && (
                    <Field
                      component={TextFieldWrapper}
                      type="number"
                      name="estimated_card_value"
                      label="Estimated value"
                      validate={composeValidators(
                        requiredField,
                        twoDecimalsOnly,
                      )}
                      className={classes.halfInput}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>

                <div className={classes.imageLabel}>Card image</div>
                <ImageDropzoneField
                  fieldName="cardImageFile"
                  deleteFieldName="removeCardImage"
                />

                <div className={classes.notesSpacer} />
                <NullableField
                  component={TextFieldWrapper}
                  name="notes"
                  label="Notes"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />

                <div className={classes.subheader}>Support details</div>
                <Field
                  component={TextFieldWrapper}
                  name="support_name"
                  label="Support name"
                  validate={requiredField}
                  className={classes.input}
                />
                <div className={classes.inputGroup}>
                  <NullableField
                    component={TextFieldWrapper}
                    name="support_phone"
                    label="Support phone"
                    className={classes.halfInput}
                  />
                  <Field
                    component={TextFieldWrapper}
                    name="support_email"
                    label="Support email"
                    validate={requiredField}
                    className={classes.halfInput}
                  />
                </div>
                <NullableField
                  component={TextFieldWrapper}
                  name="support_message"
                  label="Support message"
                  className={classes.input}
                  type="textarea"
                  multiline
                  minRows={2}
                />

                <div className={classes.imageLabel}>Support logo</div>
                <ImageDropzoneField
                  fieldName="supportLogoFile"
                  deleteFieldName="removeSupportLogo"
                />
              </div>

              <div className={classes.buttons}>
                {!isMobile && (
                  <Button
                    variant="text"
                    disabled={submitting}
                    onClick={onClose}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  className={classes.save}
                  disabled={submitting}
                  onClick={() => form.submit()}
                >
                  Save
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    padding: "0 24px",
    minHeight: 64,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  mobileTop: {
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 12,
    position: "sticky",
    top: 0,
  },
  mobileHeader: {
    display: "flex",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "0 16px 8px 16px",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
  },
  imageLabel: {
    paddingTop: 8,
    paddingBottom: 16,
    fontSize: 16,
    letterSpacing: 0.15,
  },
  subheader: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
  },
  notesSpacer: {
    height: 24,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
