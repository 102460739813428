import React, { useEffect, useState } from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  SelectInput,
  useListController,
  ListView,
  ImageField,
  BooleanInput,
  NumberField,
  BooleanField,
  Pagination,
  TopToolbar,
  ExportButton,
  downloadCSV,
  useNotify,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import { Chip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { getIsOwner } from "../../customState";
import { AddProduct } from "./AddProduct";
import { RemoveProduct } from "./RemoveProduct";
import { CustomizeGroupProduct } from "./CustomizeGroupProduct";
import {
  APIClient,
  generateAuthHeader,
  productStructureTypes,
} from "../../lib";
import { styles } from "./ProductGroup.styles";

const ProductFilter = ({ categories, ...props }) => (
  <Filter {...props}>
    <TextInput label="Product Name/ID" source="q" alwaysOn />
    <SelectInput
      label="Category"
      source="category_id"
      choices={categories}
      translateChoice={false}
      resettable
      alwaysOn
    />
    <TextInput source="sku" alwaysOn />
    <BooleanInput
      label="Added Products Only"
      source="added_products_only"
      alwaysOn
    />
    <BooleanInput source="include_inactive" label="Incl. Inactive" alwaysOn />
    <BooleanInput source="exclude_variants" label="Excl. Variants" alwaysOn />
  </Filter>
);

export const GroupProducts = ({ record }) => {
  const { id: group_id, supplier_id } = record;
  const classes = styles();
  const notify = useNotify();
  const [categories, setCategories] = useState([]);
  const isOwner = useSelector(state => getIsOwner(state));

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/product_categories/autocomplete?supplier_id=${supplier_id}`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setCategories(data);
    };
    if (supplier_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier_id]);

  const controllerProps = useListController({
    sort: { field: "product_name", order: "ASC" },
    basePath: `supplier_product_groups/${group_id}/show`,
    resource: "supplier_product_groups/group_curr_and_available_products",
    filter: { supplier_id, id: group_id },
    perPage: 25,
    syncWithLocation: true,
  });
  const refetch = controllerProps.refetch;

  const Actions = ({ currentSort, filterValues, resource, total }) => (
    <TopToolbar>
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues, supplier_id, id: group_id }}
        exporter={productExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  return (
    <ListView
      title=" "
      {...controllerProps}
      component="div"
      bulkActionButtons={false}
      actions={<Actions />}
      filters={<ProductFilter categories={categories} />}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
    >
      <Datagrid rowClick={"expand"} expand={<CustomizeGroupProduct />}>
        <BooleanField source="product_added" label="In Group" />
        <FunctionField
          sortBy="product_added"
          label="Action"
          render={({ id: product_id, product_added, groupProductId }) =>
            product_added ? (
              <RemoveProduct
                groupProductId={groupProductId}
                refetch={refetch}
              />
            ) : (
              <AddProduct
                product_id={product_id}
                group_id={group_id}
                refetch={refetch}
              />
            )
          }
        />
        <TextField source="id" label="Product Id" />
        <TextField source="sku" />
        <ImageField
          source="thumbnail_image"
          label="Thumbnail"
          sortable={false}
          classes={{ image: classes.thumbnail }}
        />
        <ArrayField source="categories" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="product_name" label="Name" />
        <NumberField source="product_price" label="Default Price" />
        <NumberField source="custom_price" label="Cus. Group Price" />
        <FunctionField
          label="STS Only"
          render={({ ship_to_school_only, custom_ship_to_school_only }) => {
            const stsOnly =
              typeof custom_ship_to_school_only === "boolean"
                ? custom_ship_to_school_only
                : ship_to_school_only;
            return stsOnly ? <CheckIcon /> : <XIcon />;
          }}
        />
        <FunctionField
          label="STH Only"
          render={({ ship_to_home_only, custom_ship_to_home_only }) => {
            const sthOnly =
              typeof custom_ship_to_home_only === "boolean"
                ? custom_ship_to_home_only
                : ship_to_home_only;
            return sthOnly ? <CheckIcon /> : <XIcon />;
          }}
        />
        {isOwner && (
          <NumberField
            source="digital_content_addition_id"
            label="+DC Id"
            sortable={false}
          />
        )}
        <NumberField
          source="digital_content_addition_code_qty"
          label="+Dig. Codes"
          sortable={false}
        />
        <BooleanField source="active" label="Active Product" />
        <FunctionField
          addLabel={false}
          render={({ structure_type, is_deprecated }) => {
            if (
              structure_type === productStructureTypes.VARIANT &&
              is_deprecated
            ) {
              return <Chip label="Deprecated Variant" size="small" />;
            }
            return <></>;
          }}
        />
      </Datagrid>
    </ListView>
  );
};

function productExporter(products) {
  const formattedProducts = products.map(product => {
    const {
      id,
      categoryString,
      custom_price,
      custom_ship_to_home_only,
      custom_ship_to_school_only,
      product_added,
      product_name,
      product_price,
      ship_to_home_only,
      ship_to_school_only,
      sku,
      product_description,
    } = product;
    const exportObj = {
      ID: id,
      Sku: sku,
      Name: product_name,
      "In Group?": product_added,
      Categories: categoryString,
      "Default Price": product_price,
      "Custom Group Price": custom_price,
      "Default ship_to_home_only": ship_to_home_only,
      "Custom ship_to_home_only": custom_ship_to_home_only,
      "Default ship_to_school_only": ship_to_school_only,
      "Custom ship_to_school_only": custom_ship_to_school_only,
      "Product Description": product_description
        ? product_description.replace(/<\/?[^>]+(>|$)/g, "")
        : null,
    };
    return exportObj;
  });
  jsonExport(formattedProducts, (err, csv) => {
    downloadCSV(csv, "Supplier Product Group Products");
  });
}
