import { useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  ListContainer,
  ListNoResults,
  MobileListSort,
} from "../../../components";
import { useList } from "../../../hooks/list";
import { AddTier } from "./AddTier";
import { TierRows } from "./TierRows";
import { NoResultsSVG } from "../../../assets";
import { useSelector } from "react-redux";
import { getCampaignId } from "../../../state";
import { giveawayTierSortFields } from "../../../types/giveaway";
import { getIsImpersonating } from "../../../lib";

export function Tiers() {
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const [showAdd, setShowAdd] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const isMobile = useIsMobile();
  const isImpersonating = getIsImpersonating();
  useScrollToTop();

  const listProps = useList<any>({
    baseUrl: "/campaign_giveaway_tiers",
    isReady: true,
    initialState: { sort: "sort_order", order: "asc", campaign_id },
  });
  const { listIsFiltered, total, noResults, refreshList, showRows } = listProps;

  return (
    <ListContainer listProps={listProps} topOffset={155}>
      {isMobile && (
        <div className={classes.mobileTop}>
          <div className={classes.count}>
            {total} Tier
            {total !== 1 ? "s" : ""}
          </div>
          <div>
            <IconButton onClick={() => setShowSort(true)}>
              <SwapVertIcon />
            </IconButton>
            {isImpersonating && <span className={classes.buttonSpacer} />}
            {isImpersonating && (
              <IconButton onClick={() => setShowAdd(true)}>
                <AddIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}

      {isImpersonating && !isMobile && (
        <div className={classes.topRow}>
          <Button
            color="primary"
            className={classes.add}
            startIcon={<AddIcon />}
            onClick={() => setShowAdd(true)}
          >
            Tier
          </Button>
        </div>
      )}

      {noResults && (
        <ListNoResults
          SVG={NoResultsSVG}
          listIsFiltered={listIsFiltered}
          label="tiers"
          buttonLabel="Add tier"
          buttonFunc={() => setShowAdd(true)}
        />
      )}

      {showRows && <TierRows listProps={listProps} />}

      {showAdd && (
        <AddTier onClose={() => setShowAdd(false)} refreshList={refreshList} />
      )}
      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={giveawayTierSortFields}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  mobileTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  topRow: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 16,
  },
  add: {
    width: 130,
    borderRadius: 20,
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  buttonSpacer: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
    },
  },
}));
