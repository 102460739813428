import { makeStyles } from "@material-ui/core";
import { TopVolunteers } from "./TopVolunteers";
import { Teams } from "./Teams";
import classNames from "classnames";
import { GenericObject } from "../../../types";

type Props = {
  hasTeams: boolean;
  teams: GenericObject[];
  volunteers: GenericObject[];
};
export function TopVolunteersAndTeams({ hasTeams, teams, volunteers }: Props) {
  const classes = styles();
  return (
    <div className={classes.container}>
      <div
        className={classNames(
          classes.volunteers,
          !hasTeams && classes.fullWidthVolunteers,
        )}
      >
        <TopVolunteers volunteers={volunteers} />
      </div>
      {hasTeams && (
        <div className={classes.teams}>
          <Teams teams={teams} />
        </div>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 32,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: 24,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
    },
  },
  volunteers: {
    width: "calc(100% - 450px)",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  fullWidthVolunteers: {
    width: "100%",
  },
  teams: {
    width: 418,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% + 32px)",
      marginLeft: -16,
      marginRight: -16,
    },
  },
}));
