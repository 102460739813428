import React, { useState, useEffect } from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  useListController,
  ListView,
  Pagination,
  useNotify,
  TopToolbar,
  Notification,
  Create,
  SimpleForm,
  SelectInput,
} from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton, makeStyles } from "@material-ui/core";
import { APIClient } from "../../lib";

const EventFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Event Search" source="q" alwaysOn />
    </Filter>
  );
};

export function CampaignEventGroupList({ record }) {
  const { id: campaign_id, org_id } = record;
  const classes = styles();
  const notify = useNotify();
  const basePath = `/campaigns/${campaign_id}/show/events`;
  const [showCreateAside, setShowCreateAside] = useState(false);
  const [eventGroups, setEventGroups] = useState([]);

  const controllerProps = useListController({
    sort: { field: "id", order: "ASC" },
    basePath,
    resource: "campaign_event_groups",
    filter: { campaign_id },
    perPage: 25,
  });

  useEffect(() => {
    const fetch = async () => {
      const url = `/event_groups/autocomplete?org_id=${org_id}`;
      const res = await APIClient.get(url);
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, { type: "warning" });
      setEventGroups(data);
    };
    if (org_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org_id]);

  const onSuccess = msg => {
    controllerProps.refetch();
    notify(msg);
    setShowCreateAside(false);
  };

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        className={classes.addGroup}
        onClick={() => setShowCreateAside(true)}
      >
        add event group to campaign
      </Button>
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={<EventFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick={null}>
          <TextField source="id" label="Event Group ID" />
          <TextField source="group_name" label="Event Group Name" />
        </Datagrid>
      </ListView>

      <Drawer
        open={showCreateAside}
        anchor="right"
        classes={{ paperAnchorRight: classes.drawer }}
      >
        <div className={classes.drawerContainer}>
          <div className={classes.drawerHeader}>
            <div>Add an Event Group to Campaign</div>
            <IconButton onClick={() => setShowCreateAside(false)} size="small">
              <CloseIcon />
            </IconButton>
          </div>

          <Create
            resource="campaign_event_groups"
            basePath={basePath}
            title=" "
            onSuccess={() =>
              onSuccess("Event Group successfully added to Campaign")
            }
          >
            <SimpleForm initialValues={{ campaign_id }}>
              <SelectInput
                source="event_group_id"
                label="Select An Event Group"
                translateChoice={false}
                choices={eventGroups}
              />
            </SimpleForm>
          </Create>
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}

const styles = makeStyles(theme => ({
  drawer: {
    width: "fit-content",
    overflow: "auto",
    maxWidth: "100vw",
  },
  drawerContainer: {
    padding: 20,
    width: "fit-content",
    minWidth: 700,
    maxWidth: "100vw",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0 16px",
    fontSize: 18,
  },
}));
