import React from "react";
import { Dialog, CircularProgress, makeStyles } from "@material-ui/core";

export function SubmittingModal() {
  const classes = styles();

  return (
    <Dialog maxWidth={false} open={true}>
      <div className={classes.dialog}>
        <div className={classes.saving}>Submitting...</div>
        <CircularProgress color="primary" size={75} />
      </div>
    </Dialog>
  );
}

const styles = makeStyles(_theme => ({
  dialog: {
    width: 400,
    height: 400,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  saving: {
    fontSize: 42,
    paddingBottom: "24px",
  },
}));
