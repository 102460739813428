import React, { useState, useEffect } from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  List,
  NumberInput,
  useNotify,
  BooleanInput,
} from "react-admin";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Products } from "./Products";
import { UpdateDonationVolunteer } from "./UpdateDonationVolunteer";
import { CampaignDonationListActions } from "./CampaignDonationListActions";
import Donation from "./Donation";
import {
  isGivvrCompany,
  isVolunteer,
  APIClient,
  generateAuthHeader,
  isProductCampaign,
} from "../../lib";
import { getCampaignDonationListProps } from "../../customState";
import { styles } from "./Donation.styles";

export default function DonationList({ record, forCampaignShow }) {
  const classes = styles();
  const props = useSelector(state =>
    getCampaignDonationListProps(state, record, forCampaignShow),
  );
  const {
    companyId,
    campaign_id,
    campaign_type_id,
    volunteer_label,
    role,
    org_id,
    allow_donations,
  } = props;
  const isProduct = isProductCampaign(campaign_type_id);
  const isGivvr = isGivvrCompany(companyId);
  const basePath = forCampaignShow
    ? `/campaigns/${campaign_id}/show`
    : "/donations";

  const [volunteers, setVolunteers] = useState([]);
  const notify = useNotify();

  useEffect(() => {
    if (!isVolunteer(role) && campaign_id) fetchVolunteers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVolunteers = async () => {
    const res = await APIClient.get(
      `/volunteers/autocomplete?org_id=${org_id}&campaign_id=${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setVolunteers(data);
  };

  const ProductPanel = ({ record }) => <Products products={record.products} />;

  if (!campaign_id || !campaign_type_id) return <></>;

  return (
    <List
      syncWithLocation
      title={forCampaignShow ? " " : isProduct ? "Sales" : "Donations"}
      location={{}}
      resource="donations"
      basePath={basePath}
      perPage={25}
      sort={{ field: "id", order: "DESC" }}
      filter={{ campaign_id, campaign_type_id }}
      empty={false}
      component={forCampaignShow ? "div" : undefined}
      bulkActionButtons={false}
      filters={
        <Filter>
          <TextInput label="Customer Name/Email" source="q" alwaysOn />
          <NumberInput label="Order ID" source="donation_id" alwaysOn />
          <TextInput label="Seller ID/Name" source="id_or_name" alwaysOn />
          <BooleanInput label="Unassigned Sales" source="unassigned" alwaysOn />
        </Filter>
      }
      actions={
        <CampaignDonationListActions
          {...props}
          forCampaignShow={forCampaignShow}
        />
      }
    >
      <Datagrid
        rowClick={forCampaignShow ? "expand" : "show"}
        expand={
          forCampaignShow ? (
            <Donation forExpand allow_donations={allow_donations} />
          ) : isProduct ? (
            <ProductPanel />
          ) : null
        }
      >
        <TextField source="id" label="Order ID" />
        <FunctionField
          label="Date"
          source="created_at"
          render={record => {
            const date = record.created_at
              ? format(new Date(record.created_at), "Pp")
              : "";
            return date;
          }}
        />
        {isGivvr && <TextField source="title" sortable={true} />}
        <TextField source="first_name" sortable={true} />
        <TextField source="last_name" sortable={true} />
        <TextField source="email" sortable={true} />
        <TextField source="phone" sortable={true} />
        <FunctionField
          label="Payment Type"
          source="pmt_type"
          render={record =>
            record.pmt_type === "cc" ? "credit card" : record.pmt_type
          }
        />
        {isProduct && (
          <FunctionField
            label="STS/STH"
            source="ship_to_school"
            render={({ ship_to_school }) => {
              return ship_to_school ? "STS" : "STH";
            }}
          />
        )}
        {isProduct && (
          <FunctionField
            label="Items Total"
            source="items_total"
            render={({ items_total }) => (items_total ? `$${items_total}` : "")}
          />
        )}
        {isProduct && (
          <FunctionField
            label="Additional Donation"
            source="additional_donation"
            render={({ additional_donation }) =>
              additional_donation ? `$${additional_donation}` : ""
            }
          />
        )}
        {!isProduct && (
          <FunctionField
            label="Donation"
            source="total_amount"
            render={record => `$${record.total_amount}`}
          />
        )}
        <TextField
          source="volunteerName"
          sortable={true}
          label={<span className={classes.columnLabel}>{volunteer_label}</span>}
        />
        <FunctionField
          onClick={e => e.stopPropagation()}
          label="Solicited By"
          source="solicited_by"
          render={({ solicited_by }) => (solicited_by ? solicited_by : "")}
        />
        {!isVolunteer(role) && (
          <FunctionField
            onClick={e => e.stopPropagation()}
            label={`Update ${volunteer_label}`}
            render={({ id }) => (
              <UpdateDonationVolunteer
                donationId={id}
                campaignId={campaign_id}
                volunteers={volunteers}
                fetchVolunteers={fetchVolunteers}
              />
            )}
          />
        )}
        {isProduct && (
          <FunctionField
            label="Tracking"
            render={({ trackingLinks }) => {
              if (!Array.isArray(trackingLinks)) return <></>;
              return trackingLinks.map((tl, i) => {
                const { trackingLink, tracking_number } = tl;
                if (!trackingLink) {
                  return <div key={i}>{tracking_number}</div>;
                }
                return (
                  <div key={i}>
                    <a
                      className={classes.trackingLink}
                      href={trackingLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e => e.stopPropagation()}
                    >
                      {tracking_number}
                    </a>
                  </div>
                );
              });
            }}
          />
        )}
      </Datagrid>
    </List>
  );
}
