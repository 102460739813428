// cSpell:ignore Overridable
import { useHistory } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ScreenIcon from "@material-ui/icons/PersonalVideoOutlined";
import LeaderboardIcon from "@material-ui/icons/InsertChartOutlined";
import AccountIcon from "@material-ui/icons/AccountCircleOutlined";
import TeamsIcon from "@material-ui/icons/GroupWorkOutlined";
import SubmissionsIcon from "@material-ui/icons/MailOutline";
import PrivacyIcon from "@material-ui/icons/VerifiedUserOutlined";
import EventIcon from "@material-ui/icons/EventOutlined";
import { ReactComponent as EmployeesIcon } from "../assets/EmployeesIcon.svg";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import EventTicketIcon from "@material-ui/icons/ConfirmationNumberOutlined";
import MaterialIcon from "@material-ui/icons/PermMediaOutlined";
import GiveawayIcon from "@material-ui/icons/SportsEsportsOutlined";
import { IconButton, SvgIconTypeMap, makeStyles } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { fromMenuQueyParam, paths } from "../types";
import { useEffect, useState } from "react";
import { useIsDesktop } from "../hooks/ui";
import { Account } from "../layout/Account";
import { getShowLeaderboard } from "../state";
import { useSelector } from "react-redux";
import {
  getIsOrgAdmin,
  getIsOrgOrEmp,
  getIsVolunteer,
} from "../../customState";
import { RootState } from "../types/state";
import { getIsImpersonating } from "../lib";

export const MobileMenu = () => {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const showLB = useSelector(getShowLeaderboard);
  const isVolunteer = useSelector(getIsVolunteer);
  const isOrg = useSelector(getIsOrgOrEmp);
  const isOrgAdmin = useSelector(getIsOrgAdmin);
  const isImpersonating = getIsImpersonating();
  const {
    company_frontend_url,
    hasMaterialBundle,
    campaignGiveawayId,
  } = useSelector(({ campaign }: RootState) => campaign);
  const campaignHasCampaignEventTickets = useSelector(
    ({ events }: RootState) => events.campaignHasCampaignEventTickets,
  );
  const [showAccount, setShowAccount] = useState(false);

  useEffect(() => {
    if (isDesktop) history.replace(paths.HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  return (
    <div className={classes.container}>
      {isVolunteer && (
        <LinkCard
          label="Campaign setup"
          Icon={ScreenIcon}
          onClick={() =>
            history.push(
              `${paths.VOLUNTEER_GUIDED_SETUP}?${fromMenuQueyParam}=true`,
            )
          }
        />
      )}
      {isOrg && (
        <LinkCard
          label="Teams"
          Icon={TeamsIcon}
          onClick={() => history.push(paths.TEAMS)}
        />
      )}
      {isOrg && (
        <LinkCard
          label="Submissions"
          Icon={SubmissionsIcon}
          onClick={() => history.push(paths.SUBMISSIONS)}
        />
      )}
      {showLB && (
        <LinkCard
          label="Leaderboard"
          Icon={LeaderboardIcon}
          onClick={() => history.push(paths.LEADERBOARD)}
        />
      )}
      {hasMaterialBundle && isOrg && (
        <LinkCard
          label="Media center"
          Icon={MaterialIcon}
          onClick={() => history.push(paths.MEDIA_CENTER)}
        />
      )}
      {isOrgAdmin && (
        <LinkCard
          label="Employees"
          Icon={EmployeesIcon}
          onClick={() => history.push(paths.ORG_EMPLOYEES)}
        />
      )}
      {isOrg && (
        <LinkCard
          label="Campaign settings"
          Icon={SettingsIcon}
          onClick={() => history.push(paths.ORG_CAMPAIGN_SETTINGS)}
        />
      )}
      {isOrgAdmin && isImpersonating && (
        <LinkCard
          label="Events"
          Icon={EventIcon}
          onClick={() => history.push(paths.EVENTS)}
        />
      )}
      {campaignHasCampaignEventTickets && isOrg && (
        <LinkCard
          label="Event tickets"
          Icon={EventTicketIcon}
          onClick={() => history.push(paths.EVENT_TICKETS)}
        />
      )}
      {isOrg && (isImpersonating || campaignGiveawayId) && (
        <LinkCard
          label="Giveaways"
          Icon={GiveawayIcon}
          onClick={() => history.push(paths.GIVEAWAYS)}
        />
      )}
      <LinkCard
        label="Account"
        Icon={AccountIcon}
        onClick={() => setShowAccount(true)}
      />
      <LinkCard
        label="Privacy policy"
        Icon={PrivacyIcon}
        onClick={() =>
          window.location.assign(`${company_frontend_url}/privacy`)
        }
      />

      <Account isOpen={showAccount} onClose={() => setShowAccount(false)} />
    </div>
  );
};

type LinkCardProps = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
  onClick: () => void;
};
function LinkCard({ label, Icon, onClick }: LinkCardProps) {
  const classes = styles();
  return (
    <div className={classes.linkCard} onClick={onClick}>
      <div className={classes.left}>
        <div>
          <Icon />
        </div>
        <div className={classes.label}>{label}</div>
      </div>

      <div>
        <IconButton size="small">
          <ChevronRightIcon />
        </IconButton>
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    backgroundColor: "#F7F7F7",
    padding: "24px 16px",
  },
  linkCard: {
    minHeight: 64,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    marginBottom: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.secondary,
    paddingLeft: 18,
    paddingRight: 6,
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    letterSpacing: 0.15,
    color: theme.palette.text.secondary,
    marginLeft: 18,
  },
}));
