import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Form, useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { APIRes, GuidedSetupScreen } from "../../../types";
import { APIAuthClient } from "../../../lib";
import { getCampaignId, setToast } from "../../../state";
import { sharedStyles } from "../shared.styles";
import { PrizeSVG } from "../SVGs/PrizeSVG";
import { Tier } from "../components/Tier";

export function Prizes(props: GuidedSetupScreen) {
  const { activeScreen, handleNext, setDisableNext, setSubmitting } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const [tierPrizeOptions, setTierPrizeOptions] = useState([]);
  const [refetchData, setRefetchData] = useState(false);
  const campaignId = useSelector(getCampaignId);

  useEffect(() => {
    if (campaignId) fetchPrizePickData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (activeScreen === "PRIZE_PICKS") setDisableNext(false);
    if (activeScreen === "PRIZE_PICKS" && refetchData) fetchPrizePickData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen]); // DON'T add refetchData to dependencies or this will run unwontedly (this should only run if they made changes and came back to this screen we want to fetch updated info)

  const fetchPrizePickData = async () => {
    setTierPrizeOptions([]);
    setRefetchData(false);
    const url = `/volunteer_campaign_prize_picks/volunteer_prize_picking_data?campaign_id=${campaignId}`;
    const res = await APIAuthClient.get<any, APIRes>(url);
    const { error, errorMessage, data } = res;
    if (error) return dispatch(setToast(errorMessage));
    const { tierPrizeOptions } = data;
    setTierPrizeOptions(tierPrizeOptions);
  };

  const submit = useMemo(
    () => async ({ tierPrizeOptions }) => {
      setSubmitting(true);
      setRefetchData(true);
      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaign_prize_picks/update_volunteer_picks?campaign_id=${campaignId}`,
        formatPrizePicksForSubmit(tierPrizeOptions),
      );
      const { error, errorMessage } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (activeScreen !== "PRIZE_PICKS") return <></>;
  return (
    <div className={classes.container}>
      <PrizeSVG />
      <h1 className={sharedClasses.screenHeader}>Pick your prizes</h1>
      <p className={classes.availability}>Prizes are subject to availability</p>
      <Form
        mutators={{ ...arrayMutators }}
        initialValues={{ tierPrizeOptions }}
        onSubmit={submit}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <PrizeContent {...props} />
            </form>
          );
        }}
      />
    </div>
  );
}

function PrizeContent(props: GuidedSetupScreen) {
  const classes = styles();
  const { nextTriggeredBy, setNextTriggeredBy } = props;
  const form = useForm();

  useEffect(() => {
    if (nextTriggeredBy === "PRIZE_PICKS") {
      form.submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  return (
    <div className={classes.tiers}>
      <FieldArray name="tierPrizeOptions">
        {({ fields: tiers }) => {
          return tiers.map((tierRow, index) => {
            const isLast = tiers && tiers.length === index + 1;
            const tierData = tiers.value[index];
            return (
              <Tier
                key={index}
                tierRow={tierRow}
                tierData={tierData}
                tierNum={index + 1}
                isLast={isLast}
              />
            );
          });
        }}
      </FieldArray>
    </div>
  );
}

function formatPrizePicksForSubmit(tierPrizeData: any) {
  const formatted: any[] = [];
  tierPrizeData.forEach((tier: any) => {
    const picks: any[] = [];
    const { tier_id, levels } = tier;
    levels.forEach(({ levelProducts }) => {
      levelProducts.forEach((product: any) => {
        const { isPicked, activeProductId, personalization_text } = product;
        if (isPicked) {
          picks.push({
            product_id: Number(activeProductId),
            personalization_text: personalization_text
              ? personalization_text
              : null,
          });
        }
      });
    });
    if (picks.length) formatted.push({ tier_id, picks });
  });
  return { tierPrizePicks: formatted };
}

const styles = makeStyles(theme => ({
  container: {
    width: 536,
    paddingBottom: 28,
    maxWidth: "100vw",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  availability: {
    color: theme.palette.text.secondary2,
    fontSize: 14,
    letterSpacing: 0.15,
    textAlign: "center",
    marginBottom: 32,
  },
  form: {
    width: "100%",
    display: "flex",
    flex: 1,
  },
  tiers: {
    width: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    borderRadius: 8,
    border: "1px solid #C9CDDD",
    [theme.breakpoints.down("sm")]: {
      border: "none",
      borderTop: "1px solid #DBDEEE",
      borderRadius: 0,
    },
  },
}));
