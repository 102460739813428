export enum LoginModes {
  login = "login",
  forgotPW = "forgotPW",
  emailSent = "emailSent",
  resetPW = "reset",
}

export enum LoginTypes {
  reg = "reg",
  admin = "admin",
}

export type VolunteerAccountSelect = {
  volunteer_id: number;
  name: string;
}[];
