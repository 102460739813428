import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  makeStyles,
  TableHead,
  Button,
} from "@material-ui/core";
import { ImageField, ImageInput, useNotify } from "react-admin";
import { Form } from "react-final-form";
import { APIClient, convertFileToBase64, generateAuthHeader } from "../../lib";

export function CustomizeProducts({ campaign_id }) {
  const classes = styles();
  const notify = useNotify();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (campaign_id) fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchProducts = async () => {
    const res = await APIClient.get(
      `/campaign_prize_programs/campaign/${campaign_id}/products_for_customizing`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProducts(data);
  };

  return (
    <div className={classes.container}>
      <TableContainer component={Paper} elevation={1}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Product ID</TableCell>
              <TableCell>Sku</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Default Image</TableCell>
              <TableCell>Custom Image</TableCell>
              <TableCell>Change Custom Image</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => {
              return (
                <Row
                  key={index}
                  product={product}
                  campaign_id={campaign_id}
                  fetchProducts={fetchProducts}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function Row({ product, fetchProducts, campaign_id }) {
  const classes = styles();
  const notify = useNotify();
  const { product_id, product_name, sku, image, custom_image } = product;

  const onSubmit = async ({ customImageB64 }, form) => {
    if (!customImageB64) {
      return notify("Please select an image!", { type: "warning" });
    }
    const customImageBase64 = await convertFileToBase64(
      customImageB64,
      "customImageB64",
      true,
    ).catch(console.error);
    const res = await APIClient.post(
      "/campaign_prize_programs/customize_product",
      { customImageBase64, product_id, campaign_id },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, { type: "warning" });
    fetchProducts();
    notify("Record updated successfully", { type: "info" });
    setTimeout(() => form.reset(), 10);
  };

  return (
    <TableRow>
      <TableCell>{product_id}</TableCell>
      <TableCell>{sku}</TableCell>
      <TableCell>{product_name}</TableCell>
      <TableCell>
        <img src={image} alt="product" className={classes.image} />
      </TableCell>
      <TableCell>
        {custom_image && (
          <img src={custom_image} alt="product" className={classes.image} />
        )}
      </TableCell>
      <TableCell>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <ImageInput
                  className={classes.imageInput}
                  source="customImageB64"
                  label=" "
                  accept="image/*"
                  helperText={false}
                  multiple={false}
                >
                  <ImageField
                    source="src"
                    title="title"
                    classes={{ image: classes.image }}
                  />
                </ImageInput>

                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  type="submit"
                  disabled={submitting}
                  className={classes.button}
                >
                  update
                </Button>
              </form>
            );
          }}
        />
      </TableCell>
    </TableRow>
  );
}

const styles = makeStyles(_theme => ({
  container: { margin: "12px 0 24px 0" },
  image: {
    maxHeight: 84,
    maxWidth: 84,
  },
  imageInput: { width: 200 },
  form: { display: "flex", alignItems: "center" },
  button: { height: "fit-content", marginLeft: 8 },
}));
