import React, { useState, useEffect } from "react";
import { useNotify } from "react-admin";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Close";
import { APIClient, generateAuthHeader } from "../../../lib";
import { ExportBackOrderSlip } from "../../../components";

export function BOCampaignProducts({ id: campaign_id }) {
  const classes = styles();
  const notify = useNotify();
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (campaign_id) fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  const fetchProducts = async () => {
    const res = await APIClient.get(
      `/inventory/back_order_campaign/${campaign_id}/products`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setProducts(data);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Product ID</TableCell>
          <TableCell>Thumbnail</TableCell>
          <TableCell>Sku</TableCell>
          <TableCell>Name</TableCell>
          <TableCell align="center">Is Back Ordered</TableCell>
          <TableCell>BO Scan Count</TableCell>
          <TableCell>BO Unfulfilled Count</TableCell>
          <TableCell>Needed STS Student BO Slips</TableCell>
          <TableCell>Export BO Slips</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {products.map((p, index) => {
          const {
            id,
            thumbnail_image,
            sku,
            product_name,
            is_back_ordered,
            backOrderScanCount,
            backOrderUnfulfilledCount,
            neededBOSlips,
          } = p;
          return (
            <TableRow key={index}>
              <TableCell>{id}</TableCell>
              <TableCell>
                <img
                  className={classes.thumbnail}
                  alt="thumbnail"
                  src={thumbnail_image}
                />
              </TableCell>
              <TableCell>{sku}</TableCell>
              <TableCell>{product_name}</TableCell>
              <TableCell align="center">
                {is_back_ordered ? <CheckIcon /> : <XIcon />}
              </TableCell>
              <TableCell>{backOrderScanCount}</TableCell>
              <TableCell>{backOrderUnfulfilledCount}</TableCell>
              <TableCell>{neededBOSlips}</TableCell>
              <TableCell>
                <ExportBackOrderSlip product_id={id} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const styles = makeStyles(_theme => ({
  thumbnail: { maxHeight: 35 },
}));
