import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const dashboardDataTypes = {
  CAMPAIGN_PROGRESS_STATS_SET: "CAMPAIGN_PROGRESS_STATS_SET",
  CAMPAIGN_DONATION_STATS_SET: "CAMPAIGN_DONATION_STATS_SET",
  VOLUNTEER_CAMPAIGN_PARTICIPATION_STATS_SET:
    "VOLUNTEER_CAMPAIGN_PARTICIPATION_STATS_SET",
};

const dashboardDataActions = {
  getCampaignProgressStats(): AppThunkAction {
    return async (dispatch, getState) => {
      const {
        campaign: {
          campaignId,
          campaign_type_id,
          show_volunteer_item_progress,
          show_profit_based_progress,
        },
        user: { volunteerId },
      } = getState();
      let url = `/campaigns/${campaignId}/progress?campaign_type_id=${campaign_type_id}`;
      if (show_profit_based_progress) url = `${url}&profit_based=true`;
      if (volunteerId) url = `${url}&volunteer_id=${volunteerId}`;
      if (volunteerId && show_volunteer_item_progress) {
        url = `${url}&volunteer_item_based=true`;
      }
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return;
      }
      dispatch({ type: dashboardDataTypes.CAMPAIGN_PROGRESS_STATS_SET, data });
    };
  },
  getCampaignDonationStats(): AppThunkAction {
    return async (dispatch, getState) => {
      const {
        campaign: { campaignId, campaign_type_id },
      } = getState();
      const url = `/dashboards/campaign_donation_stats?campaign_id=${campaignId}&campaign_type_id=${campaign_type_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return;
      }
      dispatch({
        type: dashboardDataTypes.CAMPAIGN_DONATION_STATS_SET,
        data,
      });
    };
  },
  getVolunteerParticipationStats(): AppThunkAction {
    return async (dispatch, getState) => {
      const {
        campaign: { campaignId },
      } = getState();
      const url = `/dashboards/volunteer_participation_stats?campaign_id=${campaignId}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return;
      }
      dispatch({
        type: dashboardDataTypes.VOLUNTEER_CAMPAIGN_PARTICIPATION_STATS_SET,
        data,
      });
    };
  },
};

export { dashboardDataTypes, dashboardDataActions };
