import queryString from "query-string";
import { GenericObject } from "../types";

export function generateQueryParamsWithPagination(params: GenericObject) {
  const { page, ...rest } = params;
  const offset = (page as number) * (params.limit as number);
  return queryString.stringify({ ...rest, offset });
}

export function generateListExportQueryParams(params: GenericObject) {
  const { page: _rm, limit: _rm2, ...rest } = params;
  return queryString.stringify({ ...rest, offset: 0, limit: 5000 });
}
