import React from "react";
import {
  Datagrid,
  List,
  TextField,
  required,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  Edit,
  SelectInput,
} from "react-admin";
import { launchPacketSectionOptions } from "../../lib";
const requiredInput = [required()];

const ListFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
    </Filter>
  );
};

export function LaunchPacketFieldList(props) {
  return (
    <div>
      <List
        {...props}
        perPage={25}
        title="Launch Packet Fields"
        bulkActionButtons={false}
        filters={<ListFilter />}
      >
        <Datagrid rowClick="edit">
          <TextField source="section" />
          <TextField source="name" />
        </Datagrid>
      </List>
    </div>
  );
}

export function LaunchPacketFieldCreate(props) {
  return (
    <Create {...props} successMessage="Field added successfully">
      <SimpleForm redirect="list">
        <SelectInput
          label="Section"
          source="section"
          validate={requiredInput}
          choices={launchPacketSectionOptions}
          translateChoice={false}
        />
        <TextInput source="name" validate={requiredInput} />
      </SimpleForm>
    </Create>
  );
}

export function LaunchPacketFieldEdit(props) {
  return (
    <Edit
      {...props}
      redirect="list"
      undoable={false}
      successMessage="Field updated successfully"
    >
      <SimpleForm redirect="list">
        <SelectInput
          label="Section"
          source="section"
          validate={requiredInput}
          choices={launchPacketSectionOptions}
          translateChoice={false}
        />
        <TextInput source="name" validate={requiredInput} />
      </SimpleForm>
    </Edit>
  );
}
