import { makeStyles } from "@material-ui/core";
import { ListProps } from "../../../types";
import { FilterWrapper } from "./FilterWrapper";
import classNames from "classnames";
import { hexToRGBA } from "../../../../lib";

type Props = {
  header: string;
  truthyLabel: string;
  falsyLabel: string;
  truthyChipText: string;
  falsyChipText: string;
  field: string;
  listProps: ListProps;
};
export function ToggleFilter({
  header,
  truthyLabel,
  falsyLabel,
  truthyChipText,
  falsyChipText,
  field,
  listProps,
}: Props) {
  const { isActiveFilter, updateTempFilters, tempFilters } = listProps;
  const value = tempFilters[field];
  const classes = styles();

  const onClick = (isTruthySide = false) => {
    let val: null | boolean = null;
    if (isTruthySide && !value) val = true;
    else if (!isTruthySide && value !== false) {
      val = false;
    }
    const chipText =
      val === true ? truthyChipText : val === false ? falsyChipText : "";
    updateTempFilters({
      field,
      value: val,
      chipText,
    });
  };

  return (
    <FilterWrapper active={isActiveFilter(field)} header={header}>
      <div className={classes.container}>
        <div
          className={classNames(
            classes.toggle,
            classes.leftToggle,
            value === true && classes.activeToggle,
          )}
          onClick={() => onClick(true)}
        >
          {truthyLabel}
        </div>
        <div
          className={classNames(
            classes.toggle,
            classes.rightToggle,
            value === false && classes.activeToggle,
          )}
          onClick={() => onClick(false)}
        >
          {falsyLabel}
        </div>
      </div>
    </FilterWrapper>
  );
}

const styles = makeStyles(theme => ({
  container: {
    display: "flex",
    cursor: "pointer",
  },
  toggle: {
    height: 30,
    padding: "0 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 500,
    letterSpacing: 0.37,
    color: "#C9CDDD",
    "&:hover": {
      opacity: 0.7,
    },
  },
  leftToggle: {
    border: "1px solid #C9CDDD",
    borderRadius: "15px 0 0 15px",
  },
  rightToggle: {
    height: 30,
    border: "1px solid #C9CDDD",
    borderRadius: "0 15px 15px 0",
    borderLeft: "none",
  },
  activeToggle: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
    color: theme.palette.primary.main,
  },
}));
