import { APIAuthClient } from "../../lib";
import { APIRes } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const discountCardTypes = {
  DISCOUNT_CARD_CATEGORIES_AUTOCOMPLETE_SET:
    "DISCOUNT_CARD_CATEGORIES_AUTOCOMPLETE_SET",
};

const discountCardActions = {
  fetchDiscountCardCategoryAutocomplete(): AppThunkAction {
    return async dispatch => {
      const url = "/discount_card_categories/autocomplete";
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      dispatch({
        type: discountCardTypes.DISCOUNT_CARD_CATEGORIES_AUTOCOMPLETE_SET,
        data,
      });
    };
  },
};

export { discountCardActions, discountCardTypes };
