import { Fragment, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ChevronRightIcon from "@material-ui/icons/ChevronRightOutlined";
import LocationIcon from "@material-ui/icons/LocationOnOutlined";
import { GenericObject, ListProps } from "../../../types";
import { useIsDesktop, useIsMobile } from "../../../hooks/ui";
import {
  ListHeaderRowContainer,
  ListRowContainer,
  ListRows,
  ListRowsWrapper,
  SortableColumnHeader,
} from "../../../components";
import { ListPagination } from "../../../components/list/ListPagination";
import { EditMerchant } from "../merchant/EditMerchant";
import classNames from "classnames";
const MIN_WIDTH = 1200;

type Props = {
  listProps: ListProps<any>;
};
export function MerchantRows({ listProps }: Props) {
  const {
    rows,
    params,
    setParams,
    refreshList,
    nextPage,
    paginationProps,
  } = listProps;
  const classes = styles();
  const isDesktop = useIsDesktop();

  return (
    <ListRowsWrapper>
      <ListRows minWidth={MIN_WIDTH}>
        {isDesktop && (
          <ListHeaderRowContainer>
            <div className={classes.row}>
              <SortableColumnHeader
                columnName="Id"
                fieldName="id"
                params={params}
                setParams={setParams}
              />
              <div>Logo</div>
              <SortableColumnHeader
                columnName="Merchant name"
                fieldName="merchant_name"
                params={params}
                setParams={setParams}
              />
              <div>Location</div>
              <SortableColumnHeader
                columnName="Category"
                fieldName="discount_card_category_id"
                params={params}
                setParams={setParams}
              />
              <SortableColumnHeader
                columnName="Active"
                fieldName="active"
                params={params}
                setParams={setParams}
              />
              <div />
            </div>
          </ListHeaderRowContainer>
        )}
        {rows.map((row, index) => {
          const lastRow = index === rows.length - 1;
          return (
            <Row
              key={row.id as number}
              row={row}
              lastRow={lastRow}
              refreshList={refreshList}
            />
          );
        })}
      </ListRows>
      <ListPagination
        nextPage={nextPage}
        paginationProps={paginationProps}
        label="Merchants per page:"
        minWidth={MIN_WIDTH}
      />
    </ListRowsWrapper>
  );
}

type RowProps = {
  row: GenericObject;
  lastRow: boolean;
  refreshList: () => any;
};
function Row({ row, lastRow, refreshList }: RowProps) {
  const classes = styles();
  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();
  const [showEdit, setShowEdit] = useState(false);
  const {
    id,
    active,
    merchant_name,
    discount_card_merchant_addresses,
    category_name,
    logo,
  } = row;
  const addresses = discount_card_merchant_addresses as Array<GenericObject>;

  return (
    <ListRowContainer lastRow={lastRow}>
      <div className={classes.row}>
        {isDesktop && (
          <Fragment>
            <div>{id}</div>
            {logo && (
              <img alt="logo" src={logo as string} className={classes.logo} />
            )}
            {!logo && <div />}
            <div>{merchant_name}</div>
            <div>
              {addresses.map((a, index) => {
                const { location_name, address } = a;
                return (
                  <div
                    key={index}
                    className={classNames(
                      classes.locationChip,
                      index > 0 && classes.locationSpace,
                    )}
                  >
                    <div className={classes.location}>
                      {location_name ? `${location_name} ` : ""}
                      {address}
                    </div>
                  </div>
                );
              })}
            </div>
            <div>{category_name}</div>
            <div>
              {active ? (
                <CheckIcon className={classes.check} />
              ) : (
                <CloseIcon className={classes.x} />
              )}
            </div>
            <div>
              <IconButton onClick={() => setShowEdit(true)}>
                <EditIcon color="primary" />
              </IconButton>
            </div>
          </Fragment>
        )}

        {isMobile && (
          <Fragment>
            <div className={classes.mobileLeft}>
              <div className={classes.logoContainer}>
                <div
                  className={classNames(
                    classes.status,
                    !active && classes.inactiveStatus,
                  )}
                />
                {logo && (
                  <img
                    alt="logo"
                    src={logo as string}
                    className={classes.logo}
                  />
                )}
              </div>
              <div className={classes.mobileContent}>
                <div className={classes.idAndCat}>
                  {`#${id}`}&nbsp; • &nbsp;{category_name}
                </div>
                <div className={classes.name}>{merchant_name}</div>
                {addresses.length > 0 && (
                  <div className={classes.locationChip}>
                    <div className={classes.location}>
                      <LocationIcon className={classes.locationIcon} />
                      <div className={classes.locationTxt}>
                        {addresses.length > 1
                          ? `${addresses.length} locations`
                          : `${
                              addresses[0].location_name
                                ? `${addresses[0].location_name} `
                                : ""
                            }${addresses[0].address}`}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={classes.mobileEdit}>
              <IconButton onClick={() => setShowEdit(true)}>
                <ChevronRightIcon />
              </IconButton>
            </div>
          </Fragment>
        )}
      </div>
      {showEdit && (
        <EditMerchant
          onClose={() => setShowEdit(false)}
          refreshList={refreshList}
          record={row}
        />
      )}
    </ListRowContainer>
  );
}

const styles = makeStyles(theme => ({
  row: {
    maxWidth: "fit-content",
    minWidth: "100%",
    minHeight: 52,
    display: "grid",
    gridTemplateColumns: "72px 118px 1fr 1fr .6fr 92px 52px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0 10px 16px",
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "16px",
    "& > *": {
      paddingRight: 18,
      minWidth: 0,
      overflowWrap: "anywhere",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
      display: "flex",
      padding: "16px 0 16px 16px",
      letterSpacing: 0.1,
      "& > *": {
        paddingRight: 12,
      },
    },
  },
  locationChip: {
    height: 32,
    borderRadius: 16,
    backgroundColor: "#E0E0E0",
    fontSize: 13,
    letterSpacing: 0.15,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    padding: "0 12px",
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      height: 20,
      borderRadius: 12,
      backgroundColor: "#EFEFEF",
      color: theme.palette.text.secondary,
      fontSize: 12,
      letterSpacing: 0.14,
      padding: "0 6px 0 3px",
    },
  },
  location: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
  },
  locationTxt: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  locationSpace: {
    marginTop: 8,
  },
  check: {
    color: "#1AC846",
  },
  x: {
    color: theme.palette.error.main,
  },
  mobileLeft: {
    display: "flex",
    overflow: "hidden",
  },
  mobileContent: {
    overflow: "hidden",
  },
  mobileEdit: {
    minWidth: "fit-content",
    maxWidth: "fit-content",
  },
  logoContainer: {
    height: 64,
    width: 64,
    minHeight: 64,
    minWidth: 64,
    border: "1px solid #EFEFEF",
    borderRadius: 8,
    marginRight: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  status: {
    height: 8,
    width: 8,
    borderRadius: "100%",
    backgroundColor: "#5FEA6B",
    position: "absolute",
    top: 5,
    right: 5,
  },
  inactiveStatus: {
    backgroundColor: "#ED2A2A",
  },
  logo: {
    maxHeight: 75,
    maxWidth: 75,
    [theme.breakpoints.down("sm")]: {
      maxHeight: 56,
      maxWidth: 56,
      overflow: "hidden",
    },
  },
  idAndCat: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.09,
      lineHeight: "18px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  name: {
    [theme.breakpoints.down("sm")]: {
      fontWeight: 500,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      lineHeight: "20px",
      paddingTop: 2,
      paddingBottom: 8,
    },
  },
  locationIcon: {
    fontSize: 13,
    marginRight: 2,
  },
}));
