import React, { useEffect, useState } from "react";
import {
  Datagrid,
  TextField,
  DateField,
  Filter,
  TextInput,
  List,
  Pagination,
  TopToolbar,
  ExportButton,
  downloadCSV,
  BooleanField,
  FunctionField,
  SelectInput,
  useNotify,
  BooleanInput,
} from "react-admin";
import { useSelector } from "react-redux";
import { Chip } from "@material-ui/core";
import jsonExport from "jsonexport/dist";
import { format } from "date-fns";
import {
  campaignFulfillmentStatusOptions,
  formatDateOnlyString,
  campaignShippingOptions,
  APIClient,
  generateAuthHeader,
  wholesaleInvoiceStatusOptions,
  campaignFulfillmentStatuses,
} from "../../lib";
import { getSeasonId, getSupplierHasDistributors } from "../../customState";
import { DatagridWrapper } from "../../components";
import { styles } from "./OrderFulfillment.styles";

const CampaignFilter = ({
  productGroups,
  companies,
  supplierHasDistributors,
  ...props
}) => {
  const classes = styles();
  return (
    <Filter {...props}>
      <TextInput
        label="Campaign ID / Org ID / Name"
        source="q"
        alwaysOn
        className={classes.raSearch}
      />
      <SelectInput
        source="status"
        label="Status"
        choices={campaignFulfillmentStatusOptions}
        translateChoice={false}
        alwaysOn
      />
      <SelectInput
        source="shipping_options"
        label="STH/S"
        choices={campaignShippingOptions}
        translateChoice={false}
        alwaysOn
      />
      <SelectInput
        source="supplier_product_group_id"
        label="Product Group"
        choices={productGroups}
        translateChoice={false}
        alwaysOn
      />
      <BooleanInput source="has_sales_only" label="Has Sales Only" alwaysOn />
      {supplierHasDistributors && (
        <BooleanInput
          source="exclude_supplier_distributors"
          label="Exclude Distributors"
          alwaysOn
        />
      )}
      {supplierHasDistributors && (
        <SelectInput
          source="company_id"
          label="Company"
          choices={companies}
          translateChoice={false}
          // alwaysOn
        />
      )}
      <SelectInput
        source="wholesale_invoice_status"
        label="WS Inv Status"
        choices={wholesaleInvoiceStatusOptions}
        translateChoice={false}
        // alwaysOn
      />
    </Filter>
  );
};

export default function SupplierCampaignList() {
  const classes = styles();
  const notify = useNotify();
  const season_id = useSelector(getSeasonId);
  const supplierHasDistributors = useSelector(getSupplierHasDistributors);
  const [productGroups, setProductGroups] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        "/supplier_product_groups/supplier_group_autocomplete",
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setProductGroups(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        "/company_product_suppliers/supplier_company_autocomplete",
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setCompanies(data);
    };
    if (supplierHasDistributors) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierHasDistributors]);

  const Actions = ({
    currentSort,
    filters,
    displayedFilters,
    filterValues,
    showFilter,
    resource,
    total,
  }) => (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportButton
        label="export"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={{ ...filterValues }}
        exporter={campaignListExporter}
        maxResults={5000}
      />
    </TopToolbar>
  );

  const Data = ({ ...props }) => {
    return (
      <DatagridWrapper>
        <Datagrid {...props} rowClick="show">
          <TextField source="id" label="Campaign ID" />
          <FunctionField
            source="shipping_options"
            label="STH/S"
            render={({ shipping_options }) =>
              shipping_options === "home"
                ? "STH"
                : shipping_options === "school"
                ? "STS"
                : "BOTH"
            }
          />
          <FunctionField
            label="Status"
            render={({ status }) => {
              // TODO: fix - not getting past second if
              const CN =
                status === campaignFulfillmentStatuses.NO_ITEMS
                  ? classes.noSalesChip
                  : campaignFulfillmentStatuses.OPEN
                  ? classes.openChip
                  : campaignFulfillmentStatuses.ENDED
                  ? classes.endedChip
                  : campaignFulfillmentStatuses.FULFILLED
                  ? classes.fulfilledChip
                  : classes.readyFulfillmentChip;
              return <Chip label={status} size="small" className={CN} />;
            }}
          />
          <BooleanField
            source="doing_offline_sales"
            label="Doing Offline Sales"
          />
          <BooleanField source="ready_for_fulfillment" />
          <TextField source="org_id" label="Org ID" />
          <TextField source="orgName" label="Org Name" />
          <TextField source="student_count" label="Student Count" />
          <TextField source="companyName" label="Company" />
          <DateField source="start_date" label="Start Date" />
          <DateField source="end_date" label="End Date" />
          <TextField source="group_name" label="PG" />

          <TextField source="totalItems" label="Total Items" sortable={false} />
          <TextField
            source="fulfilledItems"
            label="Fulfilled Items"
            sortable={false}
          />
          <TextField
            source="unfulfilledItems"
            label="Unfulfilled Items"
            sortable={false}
          />
          <TextField source="backOrderScanItemCount" label="BO Scan Count" />
          <TextField
            source="STH_unshipped"
            label="STH Fulfilled Unshipped"
            sortable={false}
          />
          <FunctionField
            source="ship_to_school_delivery_date"
            label="STS Delivery Date"
            render={({ ship_to_school_delivery_date }) => (
              <div>{formatDateOnlyString(ship_to_school_delivery_date)}</div>
            )}
          />
          <BooleanField source="ship_to_school_shipped" label="STS shipped" />
          <TextField source="wholesale_invoice_status" label="WS Inv Status" />
          <FunctionField
            label=" "
            render={({ prizeFulfillmentOnly }) => {
              if (!prizeFulfillmentOnly) return;
              return <Chip label="Prize Fulfillment" size="small" />;
            }}
          />
        </Datagrid>
      </DatagridWrapper>
    );
  };

  return (
    <div>
      <List
        title="Campaign Fulfillment List"
        sort={{ field: "id", order: "DESC" }}
        basePath="/order_fulfillment/campaigns"
        perPage={25}
        filter={{ season_id }}
        syncWithLocation
        bulkActionButtons={false}
        exporter={false}
        actions={<Actions />}
        filters={
          <CampaignFilter
            productGroups={productGroups}
            companies={companies}
            supplierHasDistributors={supplierHasDistributors}
          />
        }
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100, 200, 500]} />}
        empty={false}
      >
        <Data />
      </List>
    </div>
  );
}

function campaignListExporter(campaigns) {
  const formattedCampaigns = campaigns.map(campaign => {
    const {
      backOrderScanItemCount,
      companyName,
      end_date,
      fulfilledItemCount,
      id,
      orgName,
      org_id,
      start_date,
      totalItemCount,
      unfulfilledItemCount,
    } = campaign;
    const exportObj = {
      "Campaign ID": id,
      "Org ID": org_id,
      "Org Name": orgName,
      "Company Name": companyName,
      "Start Date": format(new Date(start_date), "Pp"),
      "End Date": format(new Date(end_date), "Pp"),
      "Total Item Count": totalItemCount,
      "Fulfilled Item Count": fulfilledItemCount,
      "Unfulfilled Item Count": unfulfilledItemCount,
      "Back Order Scan Item Count": backOrderScanItemCount,
    };
    return exportObj;
  });
  jsonExport(formattedCampaigns, (err, csv) => {
    downloadCSV(csv, "Campaign Fulfillment List");
  });
}
