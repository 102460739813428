import React from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  BooleanField,
  DateField,
  NumberInput,
} from "react-admin";
import { formatDateOnlyString } from "../../lib";

const GiftCardFilter = props => (
  <Filter {...props}>
    <TextInput label="Gift Code / Batch" source="q" alwaysOn />
    <NumberInput label="Order ID" source="donation_id" alwaysOn />
  </Filter>
);

export default function GiftCardList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Gift Cards"
      bulkActionButtons={false}
      sort={{ field: "id", order: "DESC" }}
      filters={<GiftCardFilter />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="batch_identifier" label="Batch Identifier" />
        <TextField source="amount" />
        <TextField source="gift_code" label="Gift Code" />
        <FunctionField
          label="Expiration Date"
          source="expiration_date"
          render={record => {
            return <div>{formatDateOnlyString(record.expiration_date)}</div>;
          }}
        />
        <BooleanField source="redeemed" />
        <DateField source="redeemed_on" label="Redeemed On" />
        <TextField source="redeemed_donation_id" label="Redeemed Order ID" />
      </Datagrid>
    </List>
  );
}
