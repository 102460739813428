import { SearchFields } from "./list";

export type DiscountCardAutocomplete = {
  id: number;
  card_name: string;
}[];

export const discountCardTemplateSearchFields: SearchFields = [
  { label: "Name", field: "q", placeholder: "Search for a template" },
  {
    label: "ID",
    field: "discount_card_id",
    placeholder: "Template ID",
    isNumber: true,
  },
];

export const campaignDiscountCardSearchFields: SearchFields = [
  {
    label: "Card name",
    field: "discount_card_name",
    placeholder: "Search for a card",
  },
  { label: "Org name", field: "org_name", placeholder: "Search Orgs" },
  {
    label: "Template name",
    field: "discount_card_template_name",
    placeholder: "Search By template",
  },
  {
    label: "Campaign ID",
    field: "campaign_id",
    placeholder: "Campaign ID",
    isNumber: true,
  },
  {
    label: "Card ID",
    field: "discount_card_id",
    placeholder: "Card ID",
    isNumber: true,
  },
];

export const customerDiscountCardSearchFields: SearchFields = [
  {
    label: "Card name",
    field: "discount_card_name",
    placeholder: "Search for a card",
  },
  {
    label: "Campaign ID",
    field: "campaign_id",
    placeholder: "Campaign ID",
    isNumber: true,
  },
  {
    label: "Campaign card ID",
    field: "campaign_discount_card_id",
    placeholder: "Campaign card ID",
    isNumber: true,
  },
  {
    label: "Phone",
    field: "phone",
    placeholder: "User phone",
  },
  {
    label: "Order ID",
    field: "donation_id",
    placeholder: "Order ID",
    isNumber: true,
  },
  {
    label: "Batch identifier",
    field: "batch_identifier",
    placeholder: "Generated cards batch identifier",
  },
  {
    label: "Activation code",
    field: "activation_code",
    placeholder: "Activation code",
  },
  {
    label: "Card ID",
    field: "discount_card_id",
    placeholder: "Card ID",
    isNumber: true,
  },
];

export enum DiscountCardTemplateFilterFields {
  include_inactive = "include_inactive",
}

export enum CustomerDiscountCardFilterFields {
  assigned = "assigned",
  discount_card_config_level = "discount_card_config_level",
}

export const campaignDiscountCardSortFields = [
  { field: "id", label: "Campaign card ID" },
  { field: "parentTemplateDiscountCardName", label: "Template" },
  { field: "orgName", label: "Org" },
  { field: "card_name", label: "Card name" },
  { field: "campaign_id", label: "Campaign ID" },
  { field: "expires_on", label: "Expires on" },
];

export const discountCardTemplateSortFields = [
  { field: "id", label: "ID" },
  { field: "card_name", label: "Card name" },
  { field: "active", label: "Active" },
  { field: "expires_on", label: "Expires on" },
  { field: "estimated_card_value", label: "Estimated value" },
];

export const customerDiscountCardSortFields = [
  { field: "id", label: "ID" },
  { field: "campaign_id", label: "Campaign ID" },
  {
    field: "parent_campaign_product_discount_card_id",
    label: "Campaign card ID",
  },
  { field: "config_level", label: "Origin" },
  { field: "assigned", label: "Assigned" },
  { field: "card_name", label: "Card name" },
  { field: "phone", label: "User phone" },
  { field: "donation_id", label: "Order ID" },
];

export const discountCardConfigLevels = {
  TEMPLATE: "template",
  CAMPAIGN_PRODUCT: "campaign_product",
  DONATION_PRODUCT: "donation_product",
  MANUALLY_GENERATED: "manually_generated",
};
