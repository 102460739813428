import React, { Fragment, useState, useEffect } from "react";
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  DateField,
  FunctionField,
  SelectInput,
} from "react-admin";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { ReportDownloader } from "../../components";
import { downloadCodeOptions, downloadTypes } from "../../lib";

const DownloadFilter = props => {
  return (
    <Filter {...props}>
      <SelectInput
        source="download_code"
        label="Download Type"
        choices={downloadCodeOptions()}
        translateChoice={false}
        alwaysOn
      />
      <TextInput label="File name" source="q" alwaysOn />
      <TextInput label="Campaign ID" source="campaign_id" alwaysOn />
    </Filter>
  );
};

export default function DownloadList(props) {
  return (
    <List
      {...props}
      perPage={25}
      title="Downloads"
      bulkActionButtons={false}
      exporter={false}
      filters={<DownloadFilter />}
      sort={{ field: "id", order: "DESC" }}
      empty={false}
    >
      <Datagrid rowClick={null}>
        <TextField source="id" />
        <FunctionField
          label="Download Type"
          source="download_code"
          render={({ download_code }) => downloadTypes[download_code]}
        />
        <TextField source="status" />
        <DateField source="created_at" label="Generated On" showTime />
        <TextField source="file_name" />
        <TextField source="campaign_id" label="Campaign ID" />
        <FunctionField
          label="Download"
          render={({ id, file_name }) =>
            !file_name ? "" : <Download id={id} />
          }
        />
      </Datagrid>
    </List>
  );
}

function Download({ id }) {
  const classes = styles();
  const [loading, setLoading] = useState(false);
  const [downloadId, setDownloadId] = useState(null);

  useEffect(() => {
    if (!loading) setDownloadId(null);
  }, [loading]);

  const handleClick = () => {
    setLoading(true);
    setDownloadId(id);
  };
  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClick}
        disabled={loading}
        size="small"
      >
        download
        {loading && (
          <CircularProgress
            color="primary"
            className={classes.spinner}
            size={24}
          />
        )}
      </Button>
      <ReportDownloader downloadId={downloadId} setLoading={setLoading} />
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  spinner: { position: "absolute" },
}));
