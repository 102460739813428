import { useState } from "react";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { useIsMobile, useScrollToTop } from "../../../hooks/ui";
import {
  ListContainer,
  ListNoResults,
  MobileListSort,
} from "../../../components";
import { useList } from "../../../hooks/list";
import { ReportRows } from "./ReportRows";
import { NoResultsSVG } from "../../../assets";
import { useSelector } from "react-redux";
import { getCampaignId } from "../../../state";
import { AddReport } from "./AddReport";
import { giveawayReportSortFields } from "../../../types/giveaway";

export function Reports() {
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const [showAdd, setShowAdd] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const isMobile = useIsMobile();
  useScrollToTop();

  const listProps = useList<any>({
    baseUrl: "/campaign_giveaway_reports",
    isReady: true,
    initialState: { sort: "id", order: "desc", campaign_id },
  });
  const { listIsFiltered, total, noResults, refreshList, showRows } = listProps;

  return (
    <ListContainer listProps={listProps} topOffset={155}>
      {isMobile && (
        <div className={classes.mobileTop}>
          <div className={classes.count}>
            {total} Report
            {total !== 1 ? "s" : ""}
          </div>
          <div>
            <IconButton onClick={() => setShowSort(true)}>
              <SwapVertIcon />
            </IconButton>
            <span className={classes.buttonSpacer} />
            <IconButton onClick={() => setShowAdd(true)}>
              <AddIcon />
            </IconButton>
          </div>
        </div>
      )}

      {!isMobile && (
        <div className={classes.generateWrapper}>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setShowAdd(true)}
            className={classes.generate}
          >
            Generate report
          </Button>
        </div>
      )}

      {noResults && (
        <ListNoResults
          SVG={NoResultsSVG}
          listIsFiltered={listIsFiltered}
          label="Reports"
          buttonLabel="Generate report"
          buttonFunc={() => setShowAdd(true)}
        />
      )}

      {showRows && <ReportRows listProps={listProps} />}

      {showSort && (
        <MobileListSort
          onClose={() => setShowSort(false)}
          listProps={listProps}
          sortFields={giveawayReportSortFields}
        />
      )}
      {showAdd && (
        <AddReport
          onClose={() => setShowAdd(false)}
          refreshList={refreshList}
        />
      )}
    </ListContainer>
  );
}

const styles = makeStyles(theme => ({
  mobileTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 16,
  },
  generateWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 16,
  },
  generate: {
    borderRadius: 20,
  },
  count: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  buttonSpacer: {
    marginRight: 16,
    [theme.breakpoints.down("sm")]: {
      marginRight: 8,
    },
  },
}));
