import React, { useState, useEffect } from "react";
import { ChangePW } from "./ChangePW";
import { UpdateProfile } from "./UpdateProfile";
import { UpdateAddress } from "./UpdateAddress";
import { Notification, useNotify } from "react-admin";
import { Paper, Tabs, Tab, Divider } from "@material-ui/core";
import { APIClient, generateAuthHeader, isOrg, isVolunteer } from "../../lib";
import { connect } from "react-redux";
import {
  storeProfile as storeProfileAction,
  clearProfile as clearProfileAction,
} from "../../customState";
import { styles } from "./Profile.styles";

const Profile = props => {
  const classes = styles();
  const notify = useNotify();
  const { storeProfile, clearProfile, role } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get("/users/profile", {
        headers: generateAuthHeader(),
      });
      const { error, errorMessage, data } = res;
      if (error) {
        return notify(errorMessage, "warning");
      }
      storeProfile(data);
    };
    fetch();
  }, [notify, storeProfile]);

  useEffect(() => {
    return clearProfile;
  }, [clearProfile]);

  const handleChange = (_event, newValue) => {
    setActiveIndex(newValue);
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        <Tabs value={activeIndex} onChange={handleChange} variant="scrollable">
          <Tab label="Update Profile" />
          {(isOrg(role) || isVolunteer(role)) && <Tab label="Update Address" />}
          <Tab label="Change Password" />
        </Tabs>
        <Divider />
        <div className={classes.contentContainer}>
          {activeIndex === 0 && <UpdateProfile />}
          {(isOrg(role) || isVolunteer(role)) && activeIndex === 1 && (
            <UpdateAddress />
          )}
          {activeIndex === (isOrg(role) || isVolunteer(role) ? 2 : 1) && (
            <ChangePW />
          )}
        </div>

        <Notification />
      </Paper>
    </div>
  );
};

export default connect(
  state => {
    return { role: state.user.role };
  },
  {
    storeProfile: storeProfileAction,
    clearProfile: clearProfileAction,
  },
)(Profile);
