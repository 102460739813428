import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  minLength,
} from "react-admin";

const validateName = [required(), minLength(2)];

export default function ProductSupplierCreate(props) {
  return (
    <Create {...props} successMessage="Supplier added successfully">
      <SimpleForm redirect="show">
        <TextInput source="supplier_name" validate={validateName} />
      </SimpleForm>
    </Create>
  );
}
