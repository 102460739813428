const profileTypes = {
  PROFILE: "PROFILE",
  PROFILE_UPDATE: "PROFILE_UPDATE",
  PROFILE_ADDRESS_UPDATE: "PROFILE_ADDRESS_UPDATE",
  CLEAR_PROFILE: "CLEAR_PROFILE",
};

const storeProfile = data => ({
  type: profileTypes.PROFILE,
  payload: { ...data },
});

const updateProfile = data => ({
  type: profileTypes.PROFILE_UPDATE,
  payload: { ...data },
});

const updateProfileAddress = data => {
  const { address, city, state, zip, country, id: address_id } = data;
  return {
    type: profileTypes.PROFILE_ADDRESS_UPDATE,
    payload: { address, city, state, zip, country, address_id },
  };
};

const clearProfile = () => ({
  type: profileTypes.CLEAR_PROFILE,
});

export {
  profileTypes,
  storeProfile,
  updateProfile,
  updateProfileAddress,
  clearProfile,
};
