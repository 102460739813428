import { makeStyles } from "@material-ui/core";
import { TextField } from "final-form-material-ui";
import { DonationDetailModes, GenericObject } from "../../../../types";
import { sharedStyles } from "./sharedStyles";
import { Fragment } from "react";
import { NullableField } from "../../../../components";

type Props = {
  donation: GenericObject;
  mode: DonationDetailModes;
};

export function AdditionalInfoCard({ donation, mode }: Props) {
  const classes = styles();
  const sharedClasses = sharedStyles();
  const { external_id, internal_notes } = donation;

  if (!external_id && !internal_notes && mode === DonationDetailModes.view) {
    return <></>;
  }
  return (
    <div className={sharedClasses.card}>
      <div className={sharedClasses.subheader}>Additional info</div>
      {mode === DonationDetailModes.view && (
        <Fragment>
          {external_id && (
            <div className={classes.external}>
              <div className={classes.externalLabel}>External ID</div>
              <div>{external_id}</div>
            </div>
          )}
          {internal_notes && (
            <div className={classes.internal}>Internal notes</div>
          )}
          {internal_notes && (
            <div className={classes.internalText}>{internal_notes}</div>
          )}
        </Fragment>
      )}
      {mode === DonationDetailModes.edit && (
        <Fragment>
          <NullableField
            component={TextField}
            label="External ID"
            name="external_id"
            className={classes.halfInput}
          />
          <NullableField
            component={TextField}
            label="Internal notes"
            name="internal_notes"
            className={classes.input}
            InputProps={{
              multiline: true,
              rows: 4,
            }}
          />
        </Fragment>
      )}
    </div>
  );
}

const styles = makeStyles(theme => ({
  external: {
    fontSize: 16,
    letterSpacing: 0.17,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    display: "flex",
    marginBottom: 16,
  },
  externalLabel: {
    fontWeight: 500,
    marginRight: 16,
    textWrap: "nowrap",
  },
  internal: {
    fontSize: 16,
    letterSpacing: 0.17,
    lineHeight: "22px",
    color: theme.palette.text.secondary,
    marginBottom: 8,
    fontWeight: 500,
  },
  internalText: {
    fontSize: 16,
    lineHeight: "22px",
    marginBottom: 16,
    letterSpacing: 0.17,
  },
  input: {
    width: "100%",
    marginTop: 8,
    marginBottom: 16,
  },
  halfInput: {
    marginTop: 8,
    marginBottom: 16,
  },
}));
