import { campaignTypeIds, isRaffleCampaign, store } from "../../../lib";
import { setToast, toggleLoadingDownload } from "../../state";
import { APIUtilAuthClient, downloadCsv } from "../../lib";
import { APIRes, GenericObject } from "../../types";
import { format } from "date-fns";

async function fetchData(campaignId: any, campaign_type_id: any) {
  if (!campaignId || !campaign_type_id) {
    store.dispatch(setToast("No campaign selected"));
    return;
  }
  store.dispatch(toggleLoadingDownload(true));
  const url = `/donations/campaigns/${campaignId}/csv_report_data?campaign_type_id=${campaign_type_id}`;
  const res = await APIUtilAuthClient.get<any, APIRes>(url);
  const { error, errorMessage, data } = res;
  if (error) {
    store.dispatch(setToast(errorMessage));
    store.dispatch(toggleLoadingDownload(false));
    return null;
  }
  return data;
}

export async function campaignDonationsCsv(
  campaignId: any,
  campaign_type_id: any,
  isGivvr: boolean,
  CB?: () => void,
) {
  const data = await fetchData(campaignId, campaign_type_id);
  const formatted = data.map((d: GenericObject) => {
    const {
      id,
      created_at,
      title,
      first_name,
      last_name,
      address,
      apt,
      city,
      state,
      zip,
      email,
      phone,
      pmt_type,
      total_amount,
      additional_donation,
      tip,
      processing_fee,
      num_of_payments,
      campaign_ticket_package,
      volunteerName,
      solicited_by,
      ccType,
      maskedCC,
      comments,
      comments_to_org,
      has_journal,
      ad_text,
      additional_msg,
      affiliate,
      external_id,
    } = d;
    let numberOfTickets: any = null;
    let TktPrice: any = null;
    if (campaign_ticket_package) {
      const {
        num_of_tickets,
        price,
      } = campaign_ticket_package as GenericObject;
      numberOfTickets = num_of_tickets;
      TktPrice = price;
    }
    let exportObj: GenericObject = {
      ID: id,
      Date: format(new Date(created_at as string), "Pp"),
    };
    if (isGivvr) exportObj.Title = title;
    exportObj = {
      ...exportObj,
      "First Name": first_name,
      "Last Name": last_name,
      Address: address,
      Apt: apt,
      City: city,
      State: state,
      Zip: zip,
      Email: email,
      Phone: phone,
      Fundraiser: volunteerName,
      "Solicited By": solicited_by,
      "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
      "Credit Card Type": ccType,
      "Credit Card Last 4": maskedCC,
      "Total Donation": `$${total_amount}`,
      Tip: tip ? `$${tip}` : null,
      "Processing Fee": processing_fee ? `$${processing_fee}` : null,
      "Payment Schedule": num_of_payments
        ? `${num_of_payments} monthly installments`
        : "N/A",
      "Display Message": comments,
      Affiliate: affiliate,
    };
    if (isGivvr) exportObj["Comments To Org"] = comments_to_org;
    if (has_journal) {
      exportObj["Journal Ad Text"] = ad_text;
      exportObj["In Honor Of"] = additional_msg;
    }
    if (isRaffleCampaign(campaign_type_id)) {
      exportObj["Number Of Tickets"] = numberOfTickets;
      exportObj["Ticket Package Price"] = TktPrice;
      exportObj["Additional Donation"] = additional_donation
        ? `$${additional_donation}`
        : null;
    }
    if (isGivvr) exportObj["External ID"] = external_id;

    return exportObj;
  });

  downloadCsv(formatted, "Donations");
  if (CB) CB();
}

export async function campaignOrdersCsv(campaignId: any, CB?: () => void) {
  const data = await fetchData(campaignId, campaignTypeIds.PRODUCT);
  const formatted = data.map((d: GenericObject) => {
    const {
      id,
      created_at,
      first_name,
      last_name,
      ship_to_name,
      address,
      apt,
      city,
      state,
      zip,
      email,
      phone,
      pmt_type,
      items_total,
      additional_donation,
      total_amount,
      total_tax,
      online_fee,
      custom_fee,
      volunteerName,
      solicited_by,
      products,
      ship_to_school,
      shippingAddress,
      shipping_and_handling,
      charge_amount,
      affiliate,
    } = d;
    const SA = shippingAddress as GenericObject | null;
    const sAddress = SA ? SA.address : "";
    const sApt = SA ? SA.apt : "";
    const sCity = SA ? SA.city : "";
    const sState = SA ? SA.state : "";
    const sZip = SA ? SA.zip : "";

    let exportObj = {
      ID: id,
      Date: format(new Date(created_at as string), "Pp"),
      "First Name": first_name,
      "Last Name": last_name,
      "Billing Address": address,
      "Billing Apt": apt,
      "Billing City": city,
      "Billing State": state,
      "Billing Zip": zip,
      "Ship To School": ship_to_school ? "Yes" : "No",
      "Ship To Name": ship_to_name,
      "Shipping Address": sAddress,
      "Shipping Apt": sApt,
      "Shipping City": sCity,
      "Shipping State": sState,
      "Shipping Zip": sZip,
      Email: email,
      Phone: phone,
      Fundraiser: volunteerName,
      "Solicited By": solicited_by,
      Affiliate: affiliate,
      "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
      "Items Total": items_total ? `$${items_total}` : "",
      "Shipping And Handling":
        !shipping_and_handling || shipping_and_handling === "0.00"
          ? ""
          : `$${shipping_and_handling}`,
      Tax: !total_tax || total_tax === "0.00" ? "" : `$${total_tax}`,
      "Online Fee": online_fee ? `$${online_fee}` : "",
      "Custom Fee": custom_fee ? `$${custom_fee}` : "",
      "Additional Donation": additional_donation
        ? `$${additional_donation}`
        : "",
      "Campaign Credited": `$${total_amount}`,
      Total: `$${charge_amount}`,
    };
    let i = 1;
    (products as GenericObject[]).forEach(p => {
      const { sku, product_name, qty } = p;
      exportObj = {
        ...exportObj,
        [`Product#${i} Name`]: product_name,
        [`Product#${i} Sku`]: sku,
        [`Product#${i} Qty`]: qty,
      };
      i++;
    });
    return exportObj;
  });
  downloadCsv(formatted, "Orders");
  if (CB) CB();
}

export async function campaignByProductOrdersCsv(
  campaignId: any,
  CB?: () => void,
) {
  const data = await fetchData(campaignId, campaignTypeIds.PRODUCT);
  const productArr: GenericObject[] = [];
  data.forEach((d: GenericObject) => {
    const {
      id,
      created_at,
      first_name,
      last_name,
      ship_to_name,
      address,
      apt,
      city,
      state,
      zip,
      email,
      phone,
      pmt_type,
      items_total,
      total_amount,
      total_tax,
      online_fee,
      custom_fee,
      additional_donation,
      volunteerName,
      solicited_by,
      products: _products,
      ship_to_school,
      shippingAddress,
      shipping_and_handling,
      charge_amount,
      affiliate,
    } = d;
    const SA = shippingAddress as GenericObject | null;
    const sAddress = SA ? SA.address : "";
    const sApt = SA ? SA.apt : "";
    const sCity = SA ? SA.city : "";
    const sState = SA ? SA.state : "";
    const sZip = SA ? SA.zip : "";
    const products = _products as GenericObject[];
    products.forEach((p, index) => {
      const { sku, product_name, qty, price } = p;
      const exportObj = {
        "Order ID": id,
        Date: format(new Date(created_at as string), "Pp"),
        Product: `${index + 1} of ${products.length}`,
        "Product Name": product_name,
        "Product Sku": sku,
        "Product Qty": qty,
        "Price Per Item": price,
        "First Name": first_name,
        "Last Name": last_name,
        "Billing Address": address,
        "Billing Apt": apt,
        "Billing City": city,
        "Billing State": state,
        "Billing Zip": zip,
        "Ship To Name": ship_to_name,
        "Ship To School": ship_to_school ? "Yes" : "No",
        "Shipping Address": sAddress,
        "Shipping Apt": sApt,
        "Shipping City": sCity,
        "Shipping State": sState,
        "Shipping Zip": sZip,
        Email: email,
        Phone: phone,
        Fundraiser: volunteerName,
        "Solicited By": solicited_by,
        Affiliate: affiliate,
        "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
        "Order Items Total": items_total ? `$${items_total}` : "",
        "Order Shipping And Handling":
          !shipping_and_handling || shipping_and_handling === "0.00"
            ? ""
            : `$${shipping_and_handling}`,
        "Order Tax": !total_tax || total_tax === "0.00" ? "" : `$${total_tax}`,
        "Order Online Fee": online_fee ? `$${online_fee}` : "",
        "Order Custom Fee": custom_fee ? `$${custom_fee}` : "",
        "Order Additional Donation": additional_donation
          ? `$${additional_donation}`
          : "",
        "Campaign Credited": `$${total_amount}`,
        "Order Total": `$${charge_amount}`,
      };
      productArr.push(exportObj);
    });
  });
  downloadCsv(productArr, "Order Products");
  if (CB) CB();
}

export async function campaignTicketCsv(
  campaignId: any,
  isGivvr: boolean,
  CB?: () => void,
) {
  const data = await fetchData(campaignId, campaignTypeIds.RAFFLE);
  const tickets: GenericObject[] = [];
  data.forEach((d: GenericObject) => {
    const {
      id,
      created_at,
      title,
      first_name,
      last_name,
      email,
      phone,
      pmt_type,
      total_amount,
      additional_donation,
      campaign_ticket_package,
      volunteerName,
      solicited_by,
      affiliate,
      num_of_payments,
    } = d;
    if (campaign_ticket_package) {
      const {
        num_of_tickets,
        price,
      } = campaign_ticket_package as GenericObject;
      for (let index = 1; index <= (num_of_tickets as number); index++) {
        let data: GenericObject = {
          ID: id,
          Date: format(new Date(created_at as string), "Pp"),
          Ticket: `${index} of ${num_of_tickets}`,
          "Number Of Tickets": num_of_tickets,
          "Ticket Package Price": `$${price}`,
          "Additional Donation": additional_donation
            ? `$${additional_donation}`
            : null,
          "Total Donation": `$${total_amount}`,
          "Payment Type": pmt_type === "cc" ? "credit card" : pmt_type,
          "Payment Schedule": num_of_payments
            ? `${num_of_payments} monthly installments`
            : "N/A",
        };
        if (isGivvr) data.Title = title;
        data = {
          ...data,
          "First Name": first_name,
          "Last Name": last_name,
          Email: email,
          Phone: phone,
          Fundraiser: volunteerName,
          "Solicited By": solicited_by,
          Affiliate: affiliate,
        };
        tickets.push(data);
      }
    }
  });
  downloadCsv(tickets, "Tickets");
  if (CB) CB();
}
