import { useState } from "react";
import {
  IconButton,
  Theme,
  makeStyles,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonSpinner, ResponsiveModal } from "..";
import { Contact } from "../../types";
import { contactActions, getCampaignId } from "../../state";
import { useAppDispatch } from "../../types/state";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  contact: Contact;
};

export function DeleteContact({ isOpen, onClose, contact }: Props) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const campaign_id = useSelector(getCampaignId);
  const [submitting, setSubmitting] = useState(false);
  const { id, name } = contact;

  const onSubmit = async () => {
    if (!campaign_id) return;
    setSubmitting(true);
    const success = await dispatch(
      contactActions.deleteContact(id, campaign_id),
    );
    if (success) onClose();
    setSubmitting(false);
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose} dynamicHeight>
      <div className={classes.container}>
        {isMobile && (
          <div className={classes.closeWrapper}>
            <IconButton className={classes.close} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
        <h1 className={classes.title}>
          Are you sure you want to delete {name} from your contacts?
        </h1>
        <div className={classes.actions}>
          <Button
            variant={isMobile ? "contained" : "text"}
            className={classes.cancel}
            onClick={onClose}
            size={isMobile ? "medium" : "small"}
            disabled={submitting}
            color={isMobile ? "secondary" : "default"}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            size={isMobile ? "medium" : "small"}
            variant={isMobile ? "contained" : "text"}
            className={classes.delete}
            disabled={submitting}
            onClick={onSubmit}
          >
            Delete
            <ButtonSpinner show={submitting} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "fit-content",
    },
  },
  closeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  close: {
    color: "rgba(0, 0, 0, 0.33)",
    padding: 12,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    padding: "16px 24px 50px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 48px 16px",
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 16,
    padding: "0 8px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0 16px",
    },
  },
  delete: {
    width: 67,
    borderRadius: 4,
    color: "#ED2A2A",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      textTransform: "none",
      color: "#FFFFFF",
      backgroundColor: "#ED2A2A",
      "&:hover": {
        backgroundColor: "#ED2A2A",
      },
    },
  },
  cancel: {
    width: 73,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 6,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: 160,
      borderRadius: 18,
      marginRight: 16,
      color: theme.palette.secondary.contrastText,
      textTransform: "none",
    },
  },
}));
