// cSpell:ignore Autorenew
import { makeStyles, IconButton, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { getIsProductCampaign, setToast } from "../../../../state";
import {
  APIRes,
  DonationDetailModes,
  GenericObject,
  ToastTypes,
} from "../../../../types";
import { format } from "date-fns";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import SchoolIcon from "@material-ui/icons/LocationCity";
import { getCanManage } from "../../../../../customState";
import { Fragment, useState } from "react";
import { APIUtilAuthClient } from "../../../../lib";
import { ButtonSpinner } from "../../../../components";
import { useIsMobile } from "../../../../hooks/ui";

type Props = {
  loaded: boolean;
  donation: GenericObject;
  onClose: () => void;
  setShowDelete: React.Dispatch<React.SetStateAction<boolean>>;
  mode: DonationDetailModes;
  setMode: React.Dispatch<React.SetStateAction<DonationDetailModes>>;
  refreshData: () => void;
};
export function Top({
  loaded,
  donation,
  onClose,
  setShowDelete,
  mode,
  setMode,
  refreshData,
}: Props) {
  const {
    id,
    created_at,
    ship_to_school: STS,
    products,
    shipstationOrders,
  } = donation;
  const classes = styles();
  const isProduct = useSelector(getIsProductCampaign);
  const canManage = useSelector(getCanManage);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const hasProducts = Boolean(Array.isArray(products) && products.length);
  const [submitting, setSubmitting] = useState(false);

  const checkForShippingUpdates = async () => {
    setSubmitting(true);
    const url = `/shipstation_orders/check_for_donation_shipping_updates?donation_id=${id}`;
    const res = await APIUtilAuthClient.get<any, APIRes>(url);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
    } else {
      dispatch(setToast("Shipping info has been updated.", ToastTypes.success));
      setTimeout(() => refreshData(), 1500);
    }
    setTimeout(() => setSubmitting(false), 1000);
  };

  return (
    <Fragment>
      {isMobile && (
        <div className={classes.mobileClose}>
          <IconButton
            onClick={e => {
              e.stopPropagation();
              onClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <div className={classes.container}>
        <div className={classes.left}>
          <div>
            <div className={classes.headerWrapper}>
              <h1 className={classes.header}>
                {loaded && `${isProduct ? "Order" : "Donation"}`} {`#${id}`}
              </h1>
              {isMobile && canManage && mode === DonationDetailModes.view && (
                <div>
                  <IconButton
                    color="primary"
                    onClick={() => setMode(DonationDetailModes.edit)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                  <IconButton
                    className={classes.delete}
                    onClick={() => setShowDelete(true)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              )}
            </div>

            {created_at && (
              <div className={classes.date}>
                {format(new Date(created_at as string), "MMMM d, yyyy")}
              </div>
            )}
          </div>

          {hasProducts && (
            <Fragment>
              <div className={classes.divider} />
              <div>
                <div className={classes.shippingChip}>
                  {STS ? (
                    <SchoolIcon className={classes.shippingIcon} />
                  ) : (
                    <HomeIcon className={classes.shippingIcon} />
                  )}
                  <div className={classes.shippingMethod}>
                    {STS ? "Ship to school" : "Ship to home"}
                  </div>
                </div>
                <div className={classes.ShipmentsWrapper}>
                  {Array.isArray(shipstationOrders) &&
                    shipstationOrders.map((so, i) => {
                      const { noTracking, tracking_number, trackingLink } = so;
                      const num =
                        shipstationOrders.length > 1 ? ` ${i + 1}` : "";
                      const label = `${
                        noTracking ? "Shipment" : "Tracking"
                      } ${num}`;
                      return (
                        <div key={i} className={classes.shipRow}>
                          <div className={classes.shipLabel}>{label}</div>
                          {noTracking && (
                            <div>
                              <Button
                                size="small"
                                color="primary"
                                variant="text"
                                startIcon={<AutorenewIcon />}
                                onClick={checkForShippingUpdates}
                                disabled={submitting}
                              >
                                CHECK FOR SHIPPING UPDATES
                                <ButtonSpinner show={submitting} />
                              </Button>
                            </div>
                          )}
                          {!noTracking && (
                            <a
                              className={classes.tracking}
                              href={trackingLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {tracking_number}
                            </a>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </Fragment>
          )}
        </div>

        {!isMobile && (
          <div className={classes.right}>
            {canManage && mode === DonationDetailModes.view && (
              <Fragment>
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<EditOutlinedIcon />}
                  onClick={() => setMode(DonationDetailModes.edit)}
                >
                  EDIT
                </Button>
                <Button
                  variant="text"
                  className={classes.delete}
                  startIcon={<DeleteOutlineIcon />}
                  onClick={() => setShowDelete(true)}
                >
                  DELETE
                </Button>
              </Fragment>
            )}

            <IconButton
              size="medium"
              onClick={e => {
                e.stopPropagation();
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </Fragment>
  );
}

const styles = makeStyles(theme => ({
  container: {
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    width: 960,
    maxWidth: "100%",
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    justifyContent: "space-between",
    padding: 24,
    paddingBottom: 20,
    position: "sticky",
    top: 0,
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      padding: "0 16px 24px 16px",
      borderBottom: "none",
    },
  },
  mobileClose: {
    width: "100%",
    position: "sticky",
    top: 0,
    padding: 12,
    display: "flex",
    justifyContent: "flex-end",
    backgroundColor: "#FFFFFF",
    zIndex: 9,
  },
  left: {
    height: "100%",
    display: "flex",
    overflow: "hidden",
    paddingRight: 16,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      flex: 1,
      paddingRight: 0,
    },
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    textWrap: "nowrap",
  },
  date: {
    marginTop: 4,
    fontSize: 14,
    letterSpacing: 0.47,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    textWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      letterSpacing: 0.4,
    },
  },
  divider: {
    borderRight: "1px solid #DBDEEE",
    paddingLeft: 32,
    marginRight: 32,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  delete: {
    color: theme.palette.error.main,
    margin: "0 16px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 4px 0 8px",
    },
  },
  right: {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    marginTop: -12,
    marginRight: -12,
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginRight: 0,
    },
  },
  shippingChip: {
    height: 20,
    padding: "0 8px",
    borderRadius: 12,
    backgroundColor: "#FFD1D2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FD7B6F",
    fontSize: 12,
    letterSpacing: 0.15,
    textWrap: "nowrap",
    maxWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  shippingMethod: {
    marginLeft: 3,
  },
  shippingIcon: {
    fontSize: 16,
  },
  ShipmentsWrapper: {
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  shipRow: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
    overflow: "hidden",
    maxWidth: "100%",
  },
  shipLabel: {
    marginRight: 24,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    textWrap: "nowrap",
    [theme.breakpoints.down("sm")]: {
      marginRight: 12,
    },
  },
  tracking: {
    fontSize: 14,
    letterSpacing: 0.17,
    lineHeight: "18px",
    color: theme.palette.primary.main,
    textDecoration: "underline",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
  },
}));
