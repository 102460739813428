import React, { useState, useEffect, Fragment, useMemo } from "react";
import { useNotify } from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import {
  Button,
  IconButton,
  Chip,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  InputAdornment,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import XIcon from "@material-ui/icons/Close";
import { APIClient, generateAuthHeader, printLabelModes } from "../../lib";
import { AlertDialog } from "./AlertDialog";
import { PrintSTSLabels } from "./components/PrintSTSLabels";
import { SubmittingModal } from "./components/SubmittingModal";
import { styles } from "./OrderFulfillment.styles";
const SUCCESS_SOUND_URL =
  "https://jm-assets.nyc3.digitaloceanspaces.com/images/app/scan-success.wav";
const ERROR_SOUND_URL =
  "https://jm-assets.nyc3.digitaloceanspaces.com/images/app/scan-error.wav";
const COLUMNS = [
  { name: "Product ID", align: "left" },
  { name: "Name", align: "left" },
  { name: "Thumbnail", align: "left" },
  { name: "Sku", align: "left" },
  { name: "Back Ordered", align: "center" },
  { name: "UPCs", align: "left" },
  { name: "Ordered Qty", align: "center" },
  { name: "Fulfilled Qty", align: "center" },
  { name: "Back Order Scanned Qty", align: "center" },
];

export function FulfillOrder(props) {
  const {
    isSchoolOrder,
    donation_id,
    volunteer_id,
    campaign_id,
    closeDrawerAndRefresh,
    warehouse_id,
    upcProductIds,
    prevOpenItems,
    prevBackOrderedItems,
    prevPrizeItems,
    prevFulfilledItems,
    volunteerData,
    printStsLabelsAtFulfillment,
  } = props;
  const classes = styles();
  const notify = useNotify();
  const [alreadyFulfilled, setAlreadyFulfilled] = useState(true);
  const [fulfilledItems, setFulfilledItems] = useState({});
  const [openItems, setOpenItems] = useState({});
  const [backOrderedItems, setBackOrderedItems] = useState({});
  const [prizeItems, setPrizeItems] = useState({});
  const [enteredUPC, setEnteredUPC] = useState("");
  const [addedItems, setAddedItems] = useState({});
  const [addedPrizes, setAddedPrizes] = useState({});
  const [disable, setDisable] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(null);
  const [printLabelMode, setPrintLabelMode] = useState(printLabelModes.CLOSED);
  const successSound = useMemo(() => new Audio(SUCCESS_SOUND_URL), []);
  const errorSound = useMemo(() => new Audio(ERROR_SOUND_URL), []);

  useEffect(() => {
    const unfulfilledPrizes = Object.keys(prevPrizeItems).some(
      id => prevPrizeItems[id].qty > prevPrizeItems[id].fulfilled_qty,
    );
    setAlreadyFulfilled(
      !Boolean(
        Object.keys(prevOpenItems).length ||
          Object.keys(prevBackOrderedItems).length ||
          unfulfilledPrizes,
      ),
    );
    setFulfilledItems({ ...prevFulfilledItems });
    setOpenItems({ ...prevOpenItems });
    if (isSchoolOrder) {
      setBackOrderedItems({ ...prevBackOrderedItems });
      setPrizeItems({ ...prevPrizeItems });
    }
  }, [
    prevOpenItems,
    prevFulfilledItems,
    prevBackOrderedItems,
    prevPrizeItems,
    isSchoolOrder,
  ]);

  useEffect(() => {
    if (!alreadyFulfilled) {
      const unfulfilledPrizes = Object.keys(prizeItems).some(
        id => prizeItems[id].qty > prizeItems[id].fulfilled_qty,
      );
      if (
        !unfulfilledPrizes &&
        !Object.keys(openItems).length &&
        (!Object.keys(prevBackOrderedItems).length ||
          !Object.keys(backOrderedItems).length)
      ) {
        handleSubmit();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alreadyFulfilled,
    prevBackOrderedItems,
    backOrderedItems,
    openItems,
    addedPrizes, // use addedPrizes as dependency to trigger prize check
  ]);

  const handleKeyPress = e => {
    const code = e.keyCode || e.charCode;
    if (code === 13) handleItemScan();
  };

  const errorAlert = msg => {
    playAudio(errorSound);
    setAlert(msg);
    setEnteredUPC("");
  };

  const dealWithPrizeScan = productId => {
    const { qty, fulfilled_qty } = prizeItems[productId];
    if (fulfilled_qty === qty) {
      return errorAlert("Item has already been scanned!");
    }
    prizeItems[productId].fulfilled_qty = fulfilled_qty + 1;
    const prevAddedCount = addedPrizes[productId] || 0;
    playAudio(successSound);
    setEnteredUPC("");
    setAddedPrizes({ ...addedPrizes, [productId]: prevAddedCount + 1 });
  };

  const handleItemScan = () => {
    const scanned = `${enteredUPC}`.trim();
    let productId = upcProductIds[scanned];
    let isBoScan = false;
    if (!productId) {
      if (isSchoolOrder && /^\d+_bo$/.test(scanned)) {
        productId = scanned.replace("_bo", "");
        isBoScan = true;
        if (!openItems[productId]) {
          return errorAlert("This product has no open items to back order!");
        }
      } else {
        // we allow a prize product to also be a sold item - the way we differentiate is by prefixing upc with a `prize_` in the DB
        if (isSchoolOrder && upcProductIds[`prize_${scanned}`]) {
          const prizeProductId = upcProductIds[`prize_${scanned}`];
          if (Boolean(prizeItems[prizeProductId])) {
            return dealWithPrizeScan(prizeProductId);
          }
        }
        return errorAlert("UPC is not on this order!");
      }
    }

    if (Boolean(prizeItems[productId])) {
      return dealWithPrizeScan(productId);
    }

    if (!openItems[productId] && !backOrderedItems[productId]) {
      // we allow a prize product to also be a sold item - the way we differentiate is by prefixing upc with a `prize_` in the DB
      if (isSchoolOrder && upcProductIds[`prize_${scanned}`]) {
        const prizeProductId = upcProductIds[`prize_${scanned}`];
        if (Boolean(prizeItems[prizeProductId])) {
          return dealWithPrizeScan(prizeProductId);
        }
      }
      return errorAlert("Item has already been scanned!");
    }

    const inBO = Boolean(backOrderedItems[productId]);
    const item = openItems[productId] || backOrderedItems[productId];
    const {
      qty,
      fulfilled_qty,
      back_ordered_qty,
      is_back_ordered,
      unfulfilledCount,
      scanCount,
    } = item;
    if (isBoScan && !is_back_ordered) {
      return errorAlert("This item is not back ordered!");
    }
    // check if they are trying to fulfill the same item they just back-ordered (i think this is the only case you can hit this if condition)
    if (scanCount >= unfulfilledCount) {
      return errorAlert("You can't back order and fulfill the same item!");
    }
    const fullyFulfilled = qty - fulfilled_qty === 1;
    const fullyScanned = qty - (fulfilled_qty + back_ordered_qty) === 1;
    const unScanned = qty - (fulfilled_qty + back_ordered_qty);
    const { [productId]: _rm, ...restOpenItems } = openItems;
    const { [productId]: __rm, ...restBackOrderedItems } = backOrderedItems;
    switch (true) {
      case isBoScan:
        setOpenItems({ ...restOpenItems });
        setBackOrderedItems({
          ...backOrderedItems,
          [productId]: {
            ...item,
            back_ordered_qty: back_ordered_qty + unScanned,
            scanCount: scanCount + unScanned,
          },
        });
        break;
      case inBO && !fullyFulfilled:
        setBackOrderedItems({
          ...backOrderedItems,
          [productId]: {
            ...item,
            fulfilled_qty: fulfilled_qty + 1,
            back_ordered_qty: back_ordered_qty - 1,
            scanCount: scanCount + 1,
          },
        });
        break;
      case inBO && fullyFulfilled:
        setBackOrderedItems({ ...restBackOrderedItems });
        setFulfilledItems({
          [productId]: {
            ...item,
            fulfilled_qty: qty,
            back_ordered_qty: 0,
            scanCount: scanCount + 1,
          },
          ...fulfilledItems,
        });
        break;
      // from here on we know it's in `open items`
      case !fullyScanned:
        setOpenItems({
          ...restOpenItems,
          [productId]: {
            ...item,
            fulfilled_qty: fulfilled_qty + 1,
            scanCount: scanCount + 1,
          },
        });
        break;
      case fullyScanned:
        setOpenItems({ ...restOpenItems });
        if (back_ordered_qty) {
          setBackOrderedItems({
            [productId]: {
              ...item,
              fulfilled_qty: fulfilled_qty + 1,
              scanCount: scanCount + 1,
            },
            ...backOrderedItems,
          });
        } else {
          setFulfilledItems({
            [productId]: { ...item, fulfilled_qty: qty },
            ...fulfilledItems,
          });
        }
        break;
      default:
        return errorAlert(
          "An error occurred with the order please refresh your page and start again.",
        );
    }
    playAudio(successSound);
    setEnteredUPC("");
    const { fulfill, backOrder } = addedItems[productId] || {
      fulfill: 0,
      backOrder: 0,
    };
    const updated = {
      fulfill: isBoScan ? fulfill : fulfill + 1,
      backOrder: isBoScan ? backOrder + unScanned : backOrder,
    };
    setAddedItems({ ...addedItems, [productId]: updated });
  };

  const handleSubmit = async () => {
    if (submitting || disable) return;
    setSubmitting(true);
    if (!Object.keys(addedItems).length && !Object.keys(addedPrizes).length) {
      setSubmitting(false);
      return setAlert("there are no items to submit");
    }

    const scannedProducts = Object.keys(addedItems).map(product_id => {
      return { product_id: Number(product_id), ...addedItems[product_id] };
    });

    const scannedPrizes = Object.keys(addedPrizes).map(product_id => {
      return {
        product_id: Number(product_id),
        fulfill: addedPrizes[product_id],
      };
    });

    const url = isSchoolOrder
      ? `/order_fulfillment/fulfill/school/campaign/${campaign_id}/volunteer/${volunteer_id}?warehouse_id=${warehouse_id}`
      : `/order_fulfillment/fulfill/home/${donation_id}`;
    const res = await APIClient.post(
      url,
      { scannedProducts, scannedPrizes },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) {
      setSubmitting(false);
      return notify(errorMessage, "warning");
    }
    setDisable(true);
    setSubmitting(false);
    notify("Order submitted successfully");
    if (printStsLabelsAtFulfillment && isSchoolOrder) {
      setPrintLabelMode(printLabelModes.PRINT_AND_CLOSE);
    } else {
      closeDrawerAndRefresh();
    }
  };

  return (
    <Fragment>
      <AlertDialog alert={alert} setAlert={setAlert} />
      {!alreadyFulfilled && (
        <div className={classes.upcInput}>
          <TextField
            color="primary"
            autoFocus
            value={enteredUPC}
            placeholder="Scan or enter item UPC"
            onChange={({ target }) => setEnteredUPC(target.value)}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" onClick={handleItemScan}>
                  <IconButton size="small">
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}

      <TableContainer component={Paper} className={classes.fulfillmentTable}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={COLUMNS.length}
                className={classes.tableName}
              >
                OPEN ITEMS
              </TableCell>
            </TableRow>
            <TableRow>
              {COLUMNS.map(({ name, align }) => (
                <TableCell key={name} align={align}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(openItems).map(pId => {
              const {
                product_id,
                product_name,
                thumbnail_image,
                sku,
                UPCs,
                qty,
                fulfilled_qty,
                back_ordered_qty,
                is_back_ordered,
              } = openItems[pId];
              return (
                <TableRow key={pId}>
                  <TableCell>{product_id}</TableCell>
                  <TableCell className={classes.productName}>
                    {product_name}
                  </TableCell>
                  <TableCell>
                    <img
                      className={classes.thumbnail}
                      alt="thumbnail"
                      src={thumbnail_image}
                    />
                  </TableCell>
                  <TableCell>{sku}</TableCell>
                  <TableCell align="center">
                    {is_back_ordered ? <CheckIcon /> : <XIcon />}
                  </TableCell>
                  <TableCell>
                    {UPCs.map(upc => {
                      return (
                        <Chip
                          key={upc}
                          label={upc}
                          size="small"
                          className={classes.upcChip}
                        />
                      );
                    })}
                  </TableCell>
                  <TableCell align="center">{qty}</TableCell>
                  <TableCell
                    align="center"
                    className={
                      fulfilled_qty > 0 ? classes.partialFulfilled : ""
                    }
                  >
                    {fulfilled_qty}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={
                      back_ordered_qty > 0 ? classes.partialBackOrdered : ""
                    }
                  >
                    {back_ordered_qty}
                  </TableCell>
                </TableRow>
              );
            })}
            {Object.keys(openItems).length < 1 && (
              <TableRow>
                <TableCell colSpan={COLUMNS.length} align="center">
                  All items scanned!
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {isSchoolOrder && (
        <TableContainer component={Paper} className={classes.fulfillmentTable}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={COLUMNS.length}
                  className={classes.tableName}
                >
                  BACK ORDER SCANNED ITEMS
                </TableCell>
              </TableRow>
              <TableRow>
                {COLUMNS.map(({ name, align }) => (
                  <TableCell key={name} align={align}>
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(backOrderedItems).map(pId => {
                const {
                  product_id,
                  product_name,
                  thumbnail_image,
                  sku,
                  UPCs,
                  qty,
                  fulfilled_qty,
                  back_ordered_qty,
                  is_back_ordered,
                } = backOrderedItems[pId];
                return (
                  <TableRow key={pId}>
                    <TableCell>{product_id}</TableCell>
                    <TableCell className={classes.productName}>
                      {product_name}
                    </TableCell>
                    <TableCell>
                      <img
                        className={classes.thumbnail}
                        alt="thumbnail"
                        src={thumbnail_image}
                      />
                    </TableCell>
                    <TableCell>{sku}</TableCell>
                    <TableCell align="center">
                      {is_back_ordered ? <CheckIcon /> : <XIcon />}
                    </TableCell>
                    <TableCell>
                      {UPCs.map(upc => {
                        return (
                          <Chip
                            key={upc}
                            label={upc}
                            size="small"
                            className={classes.upcChip}
                          />
                        );
                      })}
                    </TableCell>
                    <TableCell align="center">{qty}</TableCell>
                    <TableCell
                      align="center"
                      className={
                        fulfilled_qty > 0 ? classes.partialFulfilled : ""
                      }
                    >
                      {fulfilled_qty}
                    </TableCell>
                    <TableCell
                      align="center"
                      className={
                        back_ordered_qty > 0 ? classes.partialBackOrdered : ""
                      }
                    >
                      {back_ordered_qty}
                    </TableCell>
                  </TableRow>
                );
              })}
              {Object.keys(backOrderedItems).length < 1 && (
                <TableRow>
                  <TableCell colSpan={COLUMNS.length} align="center">
                    There are no back ordered items
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isSchoolOrder && (
        <TableContainer component={Paper} className={classes.fulfillmentTable}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={COLUMNS.length}
                  className={classes.tableName}
                >
                  PRIZE ITEMS
                </TableCell>
              </TableRow>
              <TableRow>
                {COLUMNS.map(({ name, align }) => (
                  <TableCell key={name} align={align}>
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(prizeItems).map(pId => {
                const {
                  product_id,
                  product_name,
                  thumbnail_image,
                  sku,
                  UPCs,
                  qty,
                  fulfilled_qty,
                  is_back_ordered,
                } = prizeItems[pId];
                return (
                  <TableRow key={pId}>
                    <TableCell>{product_id}</TableCell>
                    <TableCell className={classes.productName}>
                      {product_name}
                    </TableCell>
                    <TableCell>
                      <img
                        className={classes.thumbnail}
                        alt="thumbnail"
                        src={thumbnail_image}
                      />
                    </TableCell>
                    <TableCell>{sku}</TableCell>
                    <TableCell align="center">
                      {is_back_ordered ? <CheckIcon /> : <XIcon />}
                    </TableCell>
                    <TableCell>
                      {UPCs.map(upc => {
                        return (
                          <Chip
                            key={upc}
                            label={upc}
                            size="small"
                            className={classes.upcChip}
                          />
                        );
                      })}
                    </TableCell>
                    <TableCell align="center">{qty}</TableCell>
                    <TableCell
                      align="center"
                      className={
                        fulfilled_qty > 0 ? classes.partialFulfilled : ""
                      }
                    >
                      {fulfilled_qty}
                    </TableCell>
                    <TableCell align="center">N/A</TableCell>
                  </TableRow>
                );
              })}
              {Object.keys(prizeItems).length < 1 && (
                <TableRow>
                  <TableCell colSpan={COLUMNS.length} align="center">
                    There are no prize items
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {!alreadyFulfilled && Object.keys(prevBackOrderedItems).length > 0 && (
        <Button
          color="primary"
          variant="contained"
          disabled={submitting || disable}
          onClick={handleSubmit}
        >
          {!printStsLabelsAtFulfillment || !isSchoolOrder
            ? "submit"
            : "submit & print labels"}
        </Button>
      )}

      <TableContainer component={Paper} className={classes.fulfillmentTable}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={COLUMNS.length}
                className={classes.tableName}
              >
                FULFILLED ITEMS
              </TableCell>
            </TableRow>
            <TableRow>
              {COLUMNS.map(({ name, align }) => (
                <TableCell key={name} align={align}>
                  {name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(fulfilledItems).map(pId => {
              const {
                product_id,
                product_name,
                thumbnail_image,
                sku,
                UPCs,
                qty,
                fulfilled_qty,
                back_ordered_qty,
                is_back_ordered,
              } = fulfilledItems[pId];
              return (
                <TableRow key={pId}>
                  <TableCell>{product_id}</TableCell>
                  <TableCell className={classes.productName}>
                    {product_name}
                  </TableCell>
                  <TableCell>
                    <img
                      className={classes.thumbnail}
                      alt="thumbnail"
                      src={thumbnail_image}
                    />
                  </TableCell>
                  <TableCell>{sku}</TableCell>
                  <TableCell align="center">
                    {is_back_ordered ? <CheckIcon /> : <XIcon />}
                  </TableCell>
                  <TableCell>
                    {UPCs.map(upc => {
                      return (
                        <Chip
                          key={upc}
                          label={upc}
                          size="small"
                          className={classes.upcChip}
                        />
                      );
                    })}
                  </TableCell>
                  <TableCell align="center">{qty}</TableCell>
                  <TableCell align="center">{fulfilled_qty}</TableCell>
                  <TableCell align="center">{back_ordered_qty}</TableCell>
                </TableRow>
              );
            })}
            {Object.keys(fulfilledItems).length < 1 && (
              <TableRow>
                <TableCell colSpan={COLUMNS.length} align="center">
                  There are no fulfilled items
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isSchoolOrder && printStsLabelsAtFulfillment && (
        <Fragment>
          <PrintSTSLabels
            volunteerData={volunteerData}
            printLabelMode={printLabelMode}
            setPrintLabelMode={setPrintLabelMode}
            closeDrawerAndRefresh={closeDrawerAndRefresh}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => setPrintLabelMode(printLabelModes.PRINT_ONLY)}
            className={classes.reprint}
          >
            reprint labels
          </Button>
        </Fragment>
      )}

      {submitting && <SubmittingModal />}
    </Fragment>
  );
}

function playAudio(player) {
  player.currentTime = 0;
  player.play();
}
