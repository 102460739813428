import { SearchFields } from "./list";

export type MaterialAutocomplete = {
  id: number;
  name: string;
}[];

export const materialSearchFields: SearchFields = [
  { label: "Name", field: "name", placeholder: "Material name" },
  {
    label: "ID",
    field: "id",
    placeholder: "Material ID",
    isNumber: true,
  },
];

export enum MaterialFields {
  include_inactive = "include_inactive",
  category_id = "category_id",
}

export const materialSortFields = [
  { field: "id", label: "ID" },
  { field: "category_name", label: "Category" },
  { field: "name", label: "Name" },
  { field: "is_internal", label: "Is internal" },
];

export const materialBundleSearchFields: SearchFields = [
  { label: "Name", field: "name", placeholder: "Bundle name" },
  {
    label: "ID",
    field: "id",
    placeholder: "Bundle ID",
    isNumber: true,
  },
];

export enum MaterialBundleFields {
  include_inactive = "include_inactive",
}

export const materialBundleSortFields = [
  { field: "id", label: "ID" },
  { field: "bundle_name", label: "Bundle name" },
  { field: "active", label: "Active" },
  { field: "is_rep_restricted", label: "Rep restricted" },
];

export const materialCategorySearchFields: SearchFields = [
  { label: "Name", field: "name", placeholder: "Category name" },
  {
    label: "ID",
    field: "id",
    placeholder: "Category ID",
    isNumber: true,
  },
];

export const materialCategorySortFields = [
  { field: "id", label: "ID" },
  { field: "category_name", label: "Category name" },
];
