export function FacebookSVG() {
  return (
    <svg width="39px" height="39px" viewBox="0 0 39 39">
      <title>Facebook</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-55, -415)" fill="#1877F2">
          <g transform="translate(55, 415)">
            <g transform="translate(3.2083, 3.2083)">
              <path
                d="M32.0833335,16.0416667 C32.0833335,7.1866667 24.8966668,0 16.0416667,0 C7.1866667,0 0,7.1866667 0,16.0416667 C0,23.8058334 5.51833336,30.2706251 12.8333334,31.7625001 L12.8333334,20.8541668 L9.62500004,20.8541668 L9.62500004,16.0416667 L12.8333334,16.0416667 L12.8333334,12.0312501 C12.8333334,8.93520837 15.3518751,6.41666669 18.4479167,6.41666669 L22.4583334,6.41666669 L22.4583334,11.2291667 L19.2500001,11.2291667 C18.3677084,11.2291667 17.6458334,11.9510417 17.6458334,12.8333334 L17.6458334,16.0416667 L22.4583334,16.0416667 L22.4583334,20.8541668 L17.6458334,20.8541668 L17.6458334,32.0031251 C25.7468751,31.2010418 32.0833335,24.3672918 32.0833335,16.0416667 Z"
                id="FacebookOutlined"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
