import { makeStyles } from "@material-ui/core";
export const styles = makeStyles(
  theme => ({
    datagridBarClosed: {
      maxWidth: "calc(100vw - 95px)",
      overflowX: "auto",
      [theme.breakpoints.down("sm")]: {
        maxWidth: () => "100vw",
      },
    },
    datagridBarOpen: {
      maxWidth: "calc(100vw - 280px)",
      overflowX: "auto",
      [theme.breakpoints.down("sm")]: {
        maxWidth: () => "100vw",
      },
    },
    list: {
      "& div[class*='MuiCard-root']": {
        boxShadow: "none",
      },
    },
    wideSelect: {
      width: 216,
    },
    alertBadge: {
      padding: "6px !important",
      height: "16px !important",
      fontSize: "12px !important",
      backgroundColor: "#FACBCB !important",
      border: "1px solid #FF0000",
      color: "#FF0000",
    },
    badge: {
      padding: "6px !important",
      height: "16px !important",
      fontSize: "12px !important",
      backgroundColor: "#CCE3CC !important",
      border: "1px solid #008000",
      color: "#008000",
    },
    // campaign products
    checkIcon: {
      backgroundColor: "green",
      color: "#ffffff",
    },
    xIcon: {
      backgroundColor: "red",
      color: "#ffffff",
    },

    // campaign type select
    typeSelectCard: {
      marginTop: 18,
      paddingTop: 60,
      width: "100%",
      minHeight: 500,
      display: "flex",
      // justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    selectInput: {
      width: 300,
    },
    selectWrapper: {
      marginTop: 24,
    },
    startButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 24,
      color: "#FFFFFF",
    },

    // create/edit
    flex: {
      display: "flex",
      alignItems: "center",
    },
    flexStart: {
      display: "flex",
      alignItems: "flex-start",
    },
    inlineBlock: {
      display: "inline-flex !important",
      marginRight: "1rem !important",
    },
    inline: {
      display: "inline",
    },
    space: {
      marginLeft: 32,
    },
    spaceRight: {
      marginRight: 16,
    },
    activateContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
    },
    activateWarning: {
      fontStyle: "italic",
      fontSize: 16,
      color: "#FF0000",
      fontWeight: 500,
    },
    activateInput: { height: 38 },
    currentImg: {
      display: "inline-flex",
      marginRight: "1rem",
      width: "300px",
    },
    image: {
      maxHeight: "80px",
      maxWidth: "350px",
    },
    imageContainer: {
      maxWidth: "350px",
      width: "fit-content",
      margin: "8px 12px 0 12px",
    },
    picker: {
      margin: "10px 0",
    },
    fitContent: {
      width: "fit-content",
    },
    ticket: {
      width: "unset",
      display: "inline-block",
    },
    inputCont: {
      width: "256px",
    },
    maskedKey: {
      verticalAlign: "middle",
      display: "inline-flex",
      marginRight: "1rem",
    },
    logo: {
      width: "75px",
    },
    tipPercents: {
      width: "fit-content",
      minWidth: 256,
    },
    actionSelectSpacer: {
      marginRight: 10,
    },
    reservation: {
      width: "256px",
      marginRight: 16,
    },
    form: {
      "& .MuiPaper-root": { overflow: "visible" },
    },
    toolbar: {
      position: "sticky",
      bottom: 0,
      zIndex: 2,
    },
    ////////// LIST ///////////
    statusChip: {
      color: "#FFFFFF",
    },
    activeChip: {
      backgroundColor: "green",
    },
    inactiveChip: {
      backgroundColor: "orange",
    },
    endedChip: {
      backgroundColor: "red",
    },
    impersonateLink: {
      display: "flex",
      alignItems: "center",
      // marginLeft: "30px",
    },
    siteLink: {
      display: "flex",
      alignItems: "center",
      // marginLeft: "16px",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }),
  {
    classNamePrefix: "campaign",
  },
);
