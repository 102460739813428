import { giveawayTypes } from "./actions";

type GiveawayState = {
  dataFetched: boolean;
  data_point?: string;
  date_range_timezone?: string;
  id?: number;
};

const initialState: GiveawayState = {
  dataFetched: false,
};

export const giveawayReducers = (
  state = initialState,
  action: any,
): GiveawayState => {
  switch (action.type) {
    case giveawayTypes.GIVEAWAY_DATA_SET:
      return {
        ...state,
        dataFetched: true,
        ...action.data,
      };
    case giveawayTypes.GIVEAWAY_DATA_UPDATE:
      return {
        ...state,
        dataFetched: true,
        ...action.data,
      };
    default:
      return state;
  }
};
