import { makeStyles } from "@material-ui/core";
import { LoginModes } from "../../types";
import { Fragment } from "react";

type Props = {
  setMode: React.Dispatch<React.SetStateAction<LoginModes>>;
};
export const EmailSent = ({ setMode }: Props) => {
  const classes = styles();

  return (
    <Fragment>
      <div className={classes.title}>Email sent!</div>
      <div className={classes.txt}>
        We sent you an email with a link to reset your password.
      </div>
      <div className={classes.link} onClick={() => setMode(LoginModes.login)}>
        Back to login
      </div>
    </Fragment>
  );
};

const styles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: 16,
  },
  txt: {
    width: "100%",
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "20px",
    marginBottom: 16,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: 296,
      textAlign: "left",
    },
  },
  link: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "24px",
    display: "inline-block",
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
  },
}));
