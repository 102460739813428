import { useEffect, useState, useMemo } from "react";
import { MenuItem, makeStyles, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { APIRes, GuidedSetupScreen } from "../../../types";
import { sharedStyles } from "../shared.styles";
import { APIAuthClient } from "../../../lib";
import {
  getCampaignId,
  getVolunteerCampaignId,
  setToast,
  updateVolunteerSetupData,
} from "../../../state";
import { TeamSVG } from "../SVGs/TeamSVG";
import { RootState } from "../../../types/state";

export function Team(props: GuidedSetupScreen) {
  const {
    activeScreen,
    setDisableNext,
    handleNext,
    nextTriggeredBy,
    setNextTriggeredBy,
    setSubmitting,
  } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const currTeamId = useSelector(
    (state: RootState) => state.volunteerSetup.team_id,
  );
  const volunteer_id = useSelector((state: any) => state.user.volunteerId);
  const volunteerCampaignId = useSelector(getVolunteerCampaignId);
  const campaignId = useSelector(getCampaignId);
  const [teamId, setTeamId] = useState<string>("");
  const [teamList, setTeamList] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    if (activeScreen === "TEAM") {
      setTeamId(currTeamId ? `${currTeamId}` : "");
    }
  }, [activeScreen, currTeamId]);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIAuthClient.get<any, APIRes>(
        `/team_campaigns/autocomplete?campaign_id=${campaignId}`,
      );
      const { error, errorMessage, data } = res;
      if (error) return dispatch(setToast(errorMessage));
      setTeamList(data);
    };
    if (campaignId) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (activeScreen === "TEAM") {
      if (!teamId) setDisableNext(true);
      else setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen, teamId]);

  useEffect(() => {
    if (nextTriggeredBy === "TEAM") {
      submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  const submit = useMemo(
    () => async () => {
      setSubmitting(true);
      const res = await APIAuthClient.post<any, APIRes>(
        "/campaign_team_volunteers",
        { team_id: Number(teamId), campaign_id: campaignId, volunteer_id },
      );
      const { error, errorMessage } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      dispatch(updateVolunteerSetupData({ team_id: Number(teamId) }));
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, campaignId, volunteer_id, volunteerCampaignId],
  );

  if (activeScreen !== "TEAM") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <TeamSVG />
      <h1 className={sharedClasses.screenHeader}>Select your team</h1>
      <div className={classes.contentSpacer} />
      <TextField
        select
        value={teamId}
        onChange={({ target }) => setTeamId(target.value)}
        className={classes.select}
      >
        {teamList.map(({ id, name }: { id: number; name: string }, index) => (
          <MenuItem key={index} value={id}>
            {name}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  contentSpacer: {
    paddingBottom: 64,
  },
  select: { width: 200 },
}));
