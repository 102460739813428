import React from "react";
import { Dialog, Button, makeStyles } from "@material-ui/core";

export function AlertDialog({ alert, setAlert }) {
  const classes = styles();

  return (
    <Dialog
      open={Boolean(alert)}
      // onClose={() => setAlert(null)} - onClose allows it to close with clickAway or enter key WE WANT THEM TO PRESS THE OK BUTTON TO DISMISS
      maxWidth={false}
    >
      <div className={classes.dialog}>
        <div className={classes.msg}>{alert}</div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setAlert(null)}
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
}

const styles = makeStyles(
  _theme => ({
    dialog: {
      padding: "40px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 300,
    },
    msg: {
      fontSize: 20,
      marginBottom: 24,
    },
  }),
  { classNamePrefix: "fulfillmentAlert" },
);
