export function LightbulbSVG() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <title>Lightbulb</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-43, -440)">
          <g transform="translate(27, 424)">
            <g transform="translate(16, 16)">
              <g transform="translate(0.8333, 0.8333)">
                <rect x="0" y="0" width="18.3333333" height="18.3333333"></rect>
                <path
                  d="M5.34722222,15.2777778 L8.40277778,15.2777778 C8.40277778,16.1180556 7.71527778,16.8055556 6.875,16.8055556 C6.03472222,16.8055556 5.34722222,16.1180556 5.34722222,15.2777778 Z M3.81944444,14.5138889 L9.93055556,14.5138889 L9.93055556,12.9861111 L3.81944444,12.9861111 L3.81944444,14.5138889 Z M12.6041667,7.25694444 C12.6041667,10.175 10.5722222,11.7333333 9.72430556,12.2222222 L4.02569444,12.2222222 C3.17777778,11.7333333 1.14583333,10.175 1.14583333,7.25694444 C1.14583333,4.09444444 3.7125,1.52777778 6.875,1.52777778 C10.0375,1.52777778 12.6041667,4.09444444 12.6041667,7.25694444 Z M11.0763889,7.25694444 C11.0763889,4.94236111 9.18958333,3.05555556 6.875,3.05555556 C4.56041667,3.05555556 2.67361111,4.94236111 2.67361111,7.25694444 C2.67361111,9.14375 3.81180556,10.2284722 4.46875,10.6944444 L9.28125,10.6944444 C9.93819444,10.2284722 11.0763889,9.14375 11.0763889,7.25694444 Z M16.3243056,5.62986111 L15.2777778,6.11111111 L16.3243056,6.59236111 L16.8055556,7.63888889 L17.2868056,6.59236111 L18.3333333,6.11111111 L17.2868056,5.62986111 L16.8055556,4.58333333 L16.3243056,5.62986111 Z M14.5138889,4.58333333 L15.2319444,3.00972222 L16.8055556,2.29166667 L15.2319444,1.57361111 L14.5138889,0 L13.7958333,1.57361111 L12.2222222,2.29166667 L13.7958333,3.00972222 L14.5138889,4.58333333 Z"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
