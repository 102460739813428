import {
  IconButton,
  Theme,
  makeStyles,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import { FieldValidator } from "final-form";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import arrayMutators from "final-form-arrays";
import { Field, Form } from "react-final-form";
import { TextField } from "final-form-material-ui";
import { ButtonSpinner, ResponsiveModal } from "..";
import { composeValidators, emailValidation, requiredField } from "../../lib";
import { Contact } from "../../types";
import { contactActions, getCampaignId } from "../../state";
import { useAppDispatch } from "../../types/state";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  contact: Contact;
};

export function EditContact({ isOpen, onClose, contact }: Props) {
  const classes = styles();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );
  const id = contact.id;
  const campaign_id = useSelector(getCampaignId);

  const onSubmit = async (values: any) => {
    const { name, email, phone } = values;
    const update = {
      email: email ? email : null,
      phone: phone ? phone : null,
      name,
      campaign_id: Number(campaign_id),
    };

    const success = await dispatch(contactActions.editContact(update, id));
    if (success) onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}
        initialValues={contact}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.container}>
              <div>
                <div className={classes.topContent}>
                  <h1 className={classes.title}>Edit contact</h1>
                  {isMobile && (
                    <IconButton
                      size="small"
                      className={classes.close}
                      onClick={onClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>
                <div className={classes.inputs}>
                  <Field
                    name="name"
                    variant="outlined"
                    component={TextField}
                    size="small"
                    validate={requiredField}
                    placeholder="Name"
                    className={classes.nameInput}
                  />
                  <div className={classes.emailAndPhone}>
                    <Field
                      name="phone"
                      variant="outlined"
                      component={TextField}
                      size="small"
                      validate={emailOrPhoneValidation}
                      placeholder="Phone"
                      className={classes.input}
                    />
                    <Field
                      name="email"
                      variant="outlined"
                      component={TextField}
                      size="small"
                      validate={composeValidators(
                        emailValidation,
                        emailOrPhoneValidation,
                      )}
                      placeholder="Email"
                      className={classes.input}
                    />
                  </div>
                </div>
              </div>

              <div className={classes.actions}>
                {!isMobile && (
                  <Button
                    variant="text"
                    className={classes.cancel}
                    onClick={onClose}
                    size="small"
                    disabled={submitting}
                  >
                    CANCEL
                  </Button>
                )}
                <Button
                  color="primary"
                  type="submit"
                  size={isMobile ? "medium" : "small"}
                  variant={isMobile ? "contained" : "text"}
                  className={classes.save}
                  disabled={submitting}
                >
                  {isMobile ? " Save contact" : "SAVE"}
                  <ButtonSpinner show={submitting} />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </ResponsiveModal>
  );
}

const emailOrPhoneValidation: FieldValidator<any> = (_, allValues: any) => {
  const { email, phone } = allValues;
  if (!email && !phone) return "email or phone is required";
  return null;
};

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    minHeight: 260,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minHeight: "100%",
      justifyContent: "space-between",
    },
  },
  topContent: {
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 16,
    marginBottom: 24,
    borderBottom: "1px solid #DBDEEE",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "unset",
      marginBottom: 0,
      padding: "16px 16px 24px 16px",
      position: "relative",
      borderBottom: "none",
    },
  },
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    color: "rgba(0, 0, 0, 0.33)",
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "32px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
      position: "relative",
    },
  },
  inputs: {
    padding: "0 24px",
    [theme.breakpoints.down("sm")]: {},
  },
  nameInput: {
    width: "100%",
    marginBottom: 24,
  },
  emailAndPhone: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  input: {
    width: 264,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
      width: "100%",
    },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 24,
    padding: "0 8px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  save: {
    width: 64,
    borderRadius: 4,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      borderRadius: 18,
    },
  },
  cancel: {
    width: 71,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    marginRight: 6,
  },
}));
