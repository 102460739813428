import React from "react";
import Card from "@material-ui/core/Card";
import Chart from "react-google-charts";
import { styles } from "./Chart.styles";

export function PerformanceChart({ data, title, label }) {
  const classes = styles();

  return (
    <Card elevation={3} className={classes.card}>
      <div className={classes.cardContent}>
        {Array.isArray(data) && data.length > 1 ? (
          <Chart
            style={{ flex: 1, height: "100%" }}
            chartType="BarChart"
            loader={<div>Loading Chart...</div>}
            data={data}
            options={{
              legend: { position: "none" },
              bars: "horizontal",
              bar: { group: { width: 30, gap: 10 } },
              title,
              hAxis: { format: "currency", min: 1 },
              vAxis: { textStyle: { fontSize: 8 } },
            }}
          />
        ) : (
          <div>{`No ${label} data to display`}</div>
        )}
      </div>
    </Card>
  );
}
