import React, { useState } from "react";
import {
  EditView,
  SimpleForm,
  TextInput,
  regex,
  Toolbar,
  SaveButton,
  useNotify,
  useRefresh,
  required,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { APIClient, generateAuthHeader, TWO_DECIMAL_REGEX } from "../../../lib";

const validPercent = [
  required(),
  regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only"),
];

export function ProfitCategoryEdit(props) {
  const {
    record,
    id,
    basePath,
    record: { campaign_id },
  } = props;
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const editProps = {
    basePath,
    defaultTitle: " ",
    loaded: true,
    loading: false,
    record,
    resource: "profit_category_campaigns",
    save: record => onSave(record),
    saving: false,
    version: 0,
  };

  const onSave = async values => {
    const {
      org_profit_online_percent,
      org_profit_offline_percent,
      rep_commission_online_percent,
      rep_commission_offline_percent,
      rep_commission_custom_profit_product_online_percent,
      rep_commission_custom_profit_product_offline_percent,
    } = values;
    const update = {
      org_profit_online_percent,
      org_profit_offline_percent,
      rep_commission_online_percent,
      rep_commission_offline_percent,
      rep_commission_custom_profit_product_online_percent,
      rep_commission_custom_profit_product_offline_percent,
    };
    setSaving(true);
    const res = await APIClient.put(
      `/profit_category_campaigns/${id}?campaign_id=${campaign_id}`,
      update,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    setSaving(false);

    if (error) return notify(errorMessage, "warning");
    notify("Record updated successfully");

    dispatch({
      type: "RA/TOGGLE_LIST_ITEM_EXPAND",
      payload: id,
      meta: { resource: "profit_category_campaigns" },
    });
    setTimeout(() => {
      refresh();
    }, 100);
  };

  return (
    <EditView {...editProps} saving={saving} title=" " {...props}>
      <SimpleForm
        redirect={null}
        toolbar={
          <Toolbar>
            <SaveButton label="save" redirect={null} />
          </Toolbar>
        }
      >
        <TextInput source="id" label="ID" disabled={true} />
        <TextInput
          source="profit_category_name"
          label="Profit Category Name"
          disabled={true}
        />
        <TextInput
          source="profit_category_id"
          label="Profit Category ID"
          disabled={true}
        />
        <TextInput
          source="org_profit_online_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="org_profit_offline_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_online_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_offline_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_custom_profit_product_online_percent"
          validate={validPercent}
          className={classes.input}
        />
        <TextInput
          source="rep_commission_custom_profit_product_offline_percent"
          validate={validPercent}
          className={classes.input}
        />
      </SimpleForm>
    </EditView>
  );
}

const styles = makeStyles(_theme => ({
  input: { width: 416 },
}));
