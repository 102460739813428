import { useDispatch } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  IconButton,
  makeStyles,
  MenuItem,
  ListSubheader,
} from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, requiredField } from "../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../types";
import { setToast } from "../../state";
import { ButtonSpinner, ResponsiveModal, TextFieldWrapper, Toast } from "..";
import { useDrawerTransition, useIsMobile } from "../../hooks/ui";
import { theme } from "../../theme/theme";
import { useEffect, useState } from "react";

type Props = {
  onClose: () => void;
  campaign_id: any;
  product_id: any;
  org_id: any;
  shouldRefetchRef: React.MutableRefObject<boolean>;
  setTicketId: React.Dispatch<any>;
};

export function CreateEventTicket({
  onClose: _onClose,
  campaign_id,
  product_id,
  org_id,
  shouldRefetchRef,
  setTicketId,
}: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const [eventOptions, setEventOptions] = useState<GenericObject[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const url = `/event_group_events/org_autocomplete?org_id=${org_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setEventOptions(generateEventOptions(data));
    };
    if (org_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org_id]);

  const onSubmit = async (values: any, _: any, complete: any) => {
    shouldRefetchRef.current = true;
    const post = { campaign_id, product_id, ...values };
    const url = `/campaign_event_tickets`;
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage, data } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Ticket created", ToastTypes.success));
    setTicketId(data.id);
    onClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveModal isOpen={isOpen} onClose={onClose}>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, form }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.top}>
                  <h1>Create event ticket</h1>
                  {isMobile && (
                    <IconButton onClick={onClose}>
                      <CloseIcon />
                    </IconButton>
                  )}
                </div>

                <div className={classes.mainContent}>
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="event_group_event_id"
                    label="Select an event"
                    validate={requiredField}
                    className={classes.input}
                  >
                    {eventOptions}
                  </Field>
                  <Field
                    select
                    component={TextFieldWrapper}
                    name="number_of_tickets"
                    label="Number of tickets"
                    validate={requiredField}
                    className={classes.input}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(num => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Field>
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}
                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </form>
            );
          }}
        />
        <Toast />
      </ResponsiveModal>
    </ThemeProvider>
  );
}

const styles = makeStyles(theme => ({
  form: {
    width: 650,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "100%",
  },
  top: {
    padding: 24,
    borderBottom: "1px solid #DBDEEE",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100%",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));

function generateEventOptions(data: GenericObject[]) {
  const arr: any[] = [];
  data.forEach(group => {
    const { id, group_name, event_group_events } = group;
    arr.push(
      <ListSubheader key={`${id}-${group_name}`}>{group_name}</ListSubheader>,
    );

    (event_group_events as GenericObject[]).forEach((e: GenericObject) => {
      const { id: eventId, event_name } = e;
      arr.push(
        <MenuItem key={`${eventId}-${event_name}`} value={eventId as number}>
          {event_name}
        </MenuItem>,
      );
    });
  });

  return arr;
}
