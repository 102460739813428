import React, { useState, useEffect } from "react";
import { useNotify } from "react-admin";
import { Button, FormControlLabel } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form, Field } from "react-final-form";
import { Checkbox } from "final-form-material-ui";
import { EmailWYSIWYG } from "../../components";
import { APIClient, generateAuthHeader } from "../../lib";
import { templateStyles } from "./Template.styles";

export function DonationCampaignReceiptTemplate({ urlSuffix }) {
  const classes = templateStyles();
  const notify = useNotify();
  const [initialValues, setInitialValues] = useState({});

  useEffect(() => {
    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchConfig = async () => {
    const res = await APIClient.get(`/donation_receipt_config/${urlSuffix}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setInitialValues(data);
  };

  const onSubmit = async (values, { reset }) => {
    const {
      dynamic_content_1,
      dynamic_content_2,
      hide_social_media_links,
    } = values;
    const res = await APIClient.put(
      `/donation_receipt_config/${urlSuffix}`,
      {
        dynamic_content_1: dynamic_content_1 ? dynamic_content_1 : null,
        dynamic_content_2: dynamic_content_2 ? dynamic_content_2 : null,
        hide_social_media_links,
      },
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    if (error) return notify(errorMessage, "warning");
    await fetchConfig();
    notify("Config has been successfully updated", { type: "info" });
    reset();
  };

  return (
    <div className={classes.container}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.template}>
                <div className={classes.logo}>{`{{ ORG LOGO }}`}</div>
                <div className={classes.header}>
                  Thank you for your donation
                </div>
                <div className={classes.subheader}>
                  Thank you for your Donation to {`{{ ORG NAME }}`}
                </div>

                <EmailWYSIWYG
                  label="Optional Custom content Here"
                  source="dynamic_content_1"
                />

                <div>{`{{ NAME }}`}</div>
                <div>{`{{ DONATION AMOUNT }}`}</div>
                <div>{`{{ CONFIRMATION NUMBER }}`}</div>
                <div>{`{{ DATE }}`}</div>

                <div className={classes.retain}>
                  Please retain this email as your receipt.
                </div>

                <EmailWYSIWYG
                  label="Optional Custom content Here"
                  source="dynamic_content_2"
                />

                <div className={classes.social}>
                  <div>{`{{ SOCIAL MEDIA LINKS }}`}</div>
                  <FormControlLabel
                    className={classes.hideSM}
                    control={
                      <Field
                        component={Checkbox}
                        name="hide_social_media_links"
                        type="checkbox"
                        size="small"
                      />
                    }
                    label="Hide Social Media Links"
                  />
                </div>
              </div>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                startIcon={<SaveIcon />}
              >
                update
              </Button>
            </form>
          );
        }}
      />
    </div>
  );
}
