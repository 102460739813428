import { useState, Fragment } from "react";
import { IconButton, Button, makeStyles } from "@material-ui/core";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { APIAuthClient, downloadCsv } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import {
  getCampaignId,
  getIsProductCampaign,
  getVolunteerLabel,
  setToast,
} from "../../../state";
import {
  ButtonSpinner,
  MultiFileDropzoneField,
  ResponsiveModal,
} from "../../../components";
import { useIsMobile, useDrawerTransition } from "../../../hooks/ui";
import { FailedUploads } from "./FailedUploads";

type Props = {
  onClose: () => void;
  refreshData: () => void;
};
export function DonationUpload({ onClose: _onClose, refreshData }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isProduct = useSelector(getIsProductCampaign);
  const campaignId = useSelector(getCampaignId);
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const { volunteerLabelSing, volunteerLabel } = useSelector(getVolunteerLabel);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [failedRows, setFailedRows] = useState<null | GenericObject[]>(null);

  const onSubmit = async (values: any, _: any, complete: any) => {
    const files: File[] | undefined = values.files;
    if (!files || !files.length) {
      complete();
      return dispatch(setToast("Please upload A CSV file"));
    }
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(files[0]);
    reader.onload = async () => {
      const res = await APIAuthClient.post<any, APIRes>(
        `/donations/csv_${isProduct ? "order" : "donation"}_upload`,
        { csv: reader.result, campaignId },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        setDisableSubmit(false);
        complete();
        return dispatch(setToast(errorMessage));
      }

      refreshData();

      if (!data.failedRows.length) {
        onClose();
        return dispatch(
          setToast(
            `${isProduct ? "Orders" : "Donations"} have been uploaded`,
            ToastTypes.success,
          ),
        );
      }
      setFailedRows(data.failedRows);
      setDisableSubmit(false);
    };
    reader.onerror = console.error;
  };

  if (failedRows) {
    return <FailedUploads onClose={onClose} failedRows={failedRows} />;
  }
  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <h2>Bulk upload {isProduct ? "orders" : "donations"}</h2>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, form }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.mainContent}>
                  {/* SECTION 1 */}
                  <div className={classes.section}>
                    <div className={classes.sectionNum}>1.</div>
                    <div>
                      <div className={classes.sectionHeader}>
                        Download the CSV template with the required format.
                      </div>
                      <Button
                        color="primary"
                        className={classes.downloadBtn}
                        startIcon={<GetAppOutlinedIcon />}
                        onClick={() => exportTemplate(isProduct ? true : false)}
                      >
                        Download CSV template
                      </Button>
                    </div>
                  </div>

                  {/* SECTION 2 */}
                  <div className={classes.section}>
                    <div className={classes.sectionNum}>2.</div>
                    <div className={classes.sectionContent}>
                      <div className={classes.sectionHeader}>
                        Add your {isProduct ? "orders" : "donations"} and save
                        the file as a CSV.
                      </div>
                      <div className={classes.instructions}>
                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            Use the column headers exactly as they appear in the
                            template file.
                          </div>
                        </div>

                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            Fill in the{" "}
                            <span className={classes.bold}>row_number</span> so
                            that if {isProduct ? "an order" : "a donation"}{" "}
                            fails to upload we can identify it for you.
                          </div>
                        </div>

                        {isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              The following fields are required:{" "}
                              <span className={classes.bold}>
                                row_number, first_name, last_name, email,
                                billing_address, billing_city, billing_state,
                                billing_zip,
                              </span>{" "}
                              and at least one{" "}
                              <span className={classes.bold}>product_SKU</span>{" "}
                              with it's corresponding{" "}
                              <span className={classes.bold}>product_QTY</span>.
                            </div>
                          </div>
                        )}

                        {!isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              The following fields are required:{" "}
                              <span className={classes.bold}>
                                row_number, first_name, last_name, email,
                                address, city, state, zip, payment_type
                              </span>{" "}
                              and <span className={classes.bold}>amount</span>.
                            </div>
                          </div>
                        )}

                        {isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              For Ship-to-School orders set{" "}
                              <span className={classes.bold}>
                                ship_to_school
                              </span>{" "}
                              to `Yes`.
                            </div>
                          </div>
                        )}

                        {isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              For Ship-to-Home orders IF the shipping address is
                              different from the billing address fill out the
                              shipping fields. (All 4 are required).
                            </div>
                          </div>
                        )}

                        {isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              Every{" "}
                              <span className={classes.bold}>product_SKU</span>{" "}
                              entered must have the corresponding{" "}
                              <span className={classes.bold}>product_QTY</span>{" "}
                              filled out.
                            </div>
                          </div>
                        )}

                        {isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              You may enter up to 12 different products per
                              order.
                            </div>
                          </div>
                        )}

                        {!isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              For{" "}
                              <span className={classes.bold}>payment_type</span>{" "}
                              use one of the following options: `cc` (for credit
                              card), `check`, `cash`, or `pledge`.
                            </div>
                          </div>
                        )}

                        <div className={classes.instructionRow}>
                          <div className={classes.bullet}>•</div>
                          <div className={classes.instructionsTxt}>
                            If you would like to credit a {volunteerLabelSing}{" "}
                            for {isProduct ? "an order" : "a donation"} enter
                            their ID in the{" "}
                            <span className={classes.bold}>fundraiser_ID</span>{" "}
                            field. (The {volunteerLabelSing} ID can be found in
                            the {volunteerLabel} tab).
                          </div>
                        </div>

                        {!isProduct && (
                          <div className={classes.instructionRow}>
                            <div className={classes.bullet}>•</div>
                            <div className={classes.instructionsTxt}>
                              To display the donor's name on the campaign
                              webpage, set{" "}
                              <span className={classes.bold}>
                                display_donor_name
                              </span>{" "}
                              to `yes` (in lowercase). If you do not want to
                              display the donor's name, leave this field empty.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* SECTION 3 */}
                  <div className={classes.section}>
                    <div className={classes.sectionNum}>3.</div>
                    <div className={classes.sectionContent}>
                      <div className={classes.sectionHeader}>
                        Upload your CSV file.
                      </div>
                      <div className={classes.fileUploadSpacer} />
                      <MultiFileDropzoneField
                        fieldName="files"
                        singleFileOnly
                        accept={[
                          "application/vnd.ms-excel",
                          "text/csv",
                          "text/plain",
                        ]}
                      />
                    </div>
                  </div>
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Fragment>
                      <div>
                        <Button
                          variant="text"
                          disabled={disableSubmit}
                          className={classes.cancel}
                          onClick={e => {
                            e.stopPropagation();
                            onClose();
                          }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          color="primary"
                          variant="text"
                          className={classes.upload}
                          disabled={disableSubmit}
                          onClick={e => {
                            e.stopPropagation();
                            form.submit();
                          }}
                        >
                          SAVE
                          <ButtonSpinner show={disableSubmit} />
                        </Button>
                      </div>
                    </Fragment>
                  )}

                  {isMobile && (
                    <Fragment>
                      <Button
                        color="primary"
                        className={classes.upload}
                        disabled={disableSubmit}
                        onClick={e => {
                          e.stopPropagation();
                          form.submit();
                        }}
                      >
                        Upload
                        <ButtonSpinner show={disableSubmit} />
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        disabled={disableSubmit}
                        onClick={e => {
                          e.stopPropagation();
                          onClose();
                        }}
                      >
                        CANCEL
                      </Button>
                    </Fragment>
                  )}
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

function exportTemplate(isProduct: boolean) {
  let template: GenericObject;
  if (isProduct) {
    template = {
      row_number: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone: undefined,
      billing_address: undefined,
      billing_apt: undefined,
      billing_city: undefined,
      billing_state: undefined,
      billing_zip: undefined,
      ship_to_school: undefined,
      shipping_address: undefined,
      shipping_apt: undefined,
      shipping_city: undefined,
      shipping_state: undefined,
      shipping_zip: undefined,
      fundraiser_ID: undefined,
      product_SKU_1: undefined,
      product_QTY_1: undefined,
      product_SKU_2: undefined,
      product_QTY_2: undefined,
      product_SKU_3: undefined,
      product_QTY_3: undefined,
      product_SKU_4: undefined,
      product_QTY_4: undefined,
      product_SKU_5: undefined,
      product_QTY_5: undefined,
      product_SKU_6: undefined,
      product_QTY_6: undefined,
      product_SKU_7: undefined,
      product_QTY_7: undefined,
      product_SKU_8: undefined,
      product_QTY_8: undefined,
      product_SKU_9: undefined,
      product_QTY_9: undefined,
      product_SKU_10: undefined,
      product_QTY_10: undefined,
      product_SKU_11: undefined,
      product_QTY_11: undefined,
      product_SKU_12: undefined,
      product_QTY_12: undefined,
    };
  } else {
    template = {
      row_number: undefined,
      external_id: undefined,
      title: undefined,
      first_name: undefined,
      last_name: undefined,
      email: undefined,
      phone: undefined,
      address: undefined,
      apt: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
      payment_type: undefined,
      amount: undefined,
      fundraiser_ID: undefined,
      comments: undefined,
      display_donor_name: undefined,
    };
  }
  downloadCsv(
    [template],
    `${isProduct ? "Order" : "Donation"} Upload Template`,
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 944,
    maxWidth: 944,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    position: "sticky",
    top: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    borderBottom: "1px solid #DBDEEE",
    minHeight: 64,
    padding: "0 16px 0 24px",
    backgroundColor: "#FFFFFF",
    zIndex: 9,
  },
  mainContent: {
    padding: 24,
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 16,
    },
  },
  section: {
    marginTop: 24,
    display: "flex",
  },
  sectionNum: {
    width: 20,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  sectionContent: {
    width: "100%",
  },
  sectionHeader: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "24px",
  },
  downloadBtn: {
    marginTop: 16,
  },
  instructions: {
    borderRadius: 8,
    backgroundColor: "#F5F5F5",
    width: "100%",
    padding: 16,
    marginTop: 16,
  },
  instructionRow: {
    display: "flex",
    marginBottom: 6,
  },
  bullet: {
    width: 20,
    minWidth: 20,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  instructionsTxt: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
  },
  bold: {
    fontWeight: 500,
  },
  form: {
    width: "100%",
  },
  fileUploadSpacer: {
    height: 16,
  },
  bottom: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px 24px 16px 24px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexDirection: "column",
      padding: 16,
    },
  },
  upload: {
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: 8,
      width: 300,
      maxWidth: "100%",
    },
  },
  cancel: {
    color: theme.palette.text.secondary,
  },
}));
