import {
  makeStyles,
  IconButton,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonSpinner, ResponsiveModal } from "../../../../components";
import { useIsMobile, useDrawerTransition } from "../../../../hooks/ui";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getCampaignId,
  getIsLoadingDownload,
  getIsProductCampaign,
  getVolunteerLabel,
} from "../../../../state";
import {
  fetchAndDownloadCsv,
  fetchAndDownloadExcel,
  fetchAndDownloadPdf,
} from "../../../../exports/fetchAndDownloadMethods";
import { getUserTimezone } from "../../../../../lib";

type Props = {
  onClose: () => void;
  bigKahunaSmallOrders?: boolean;
};

export function VolunteersReport({ onClose: _onClose }: Props) {
  const classes = styles();
  const isMobile = useIsMobile();
  const timezone = getUserTimezone();
  const { isOpen, onClose } = useDrawerTransition(_onClose);
  const isLoading = useSelector(getIsLoadingDownload);
  const isProduct = useSelector(getIsProductCampaign);
  const { volunteerLabel, volunteerLabelLC } = useSelector(getVolunteerLabel);
  const campaign_id = useSelector(getCampaignId);
  const [reportType, setReportType] = useState(isProduct ? "csv" : "pdf");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const download = async () => {
    const type = isProduct ? "product" : "donation";
    const start_date = startDate ? startDate : "";
    const end_date = endDate ? endDate : "";
    const queryStr = `campaign_id=${campaign_id}&start_date=${start_date}&end_date=${end_date}&timezone=${timezone}`;
    const fileName = `${volunteerLabel} Report #${campaign_id}`;

    if (reportType === "csv") {
      const url = `/pdf/product-campaign-students-report-csv?${queryStr}`;
      await fetchAndDownloadCsv(url, fileName, onClose, "report");
    } else if (reportType === "excel") {
      const url = `/xlsx_reports/${type}_campaign_students_report?${queryStr}`;
      await fetchAndDownloadExcel(url, fileName, onClose);
    } else {
      const url = `/pdf/${type}-campaign-students-report?${queryStr}`;
      await fetchAndDownloadPdf(url, fileName, onClose);
    }
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={() => {}}>
      <div className={classes.container}>
        <div className={classes.top}>
          {isMobile && (
            <div className={classes.close}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
          <div className={classes.header}>
            Download {volunteerLabelLC} report
          </div>
        </div>

        <div className={classes.content}>
          <div className={classes.section}>
            <div className={classes.label}>Select a format</div>
            <RadioGroup
              value={reportType}
              onChange={({ target }) => setReportType(target.value)}
            >
              {isProduct && (
                <FormControlLabel
                  className={classes.radio}
                  value="csv"
                  control={<Radio />}
                  label="CSV"
                />
              )}
              <FormControlLabel
                className={classes.radio}
                value="pdf"
                control={<Radio />}
                label="PDF"
              />
              <FormControlLabel
                className={classes.radio}
                value="excel"
                control={<Radio />}
                label="Excel"
              />
            </RadioGroup>
          </div>

          <div className={classes.section}>
            <div className={classes.label}>Date range (optional)</div>
            <div className={classes.inputs}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                InputLabelProps={{ shrink: true }}
                onChange={e => setStartDate(e.target.value)}
                className={classes.leftInput}
              />
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                InputLabelProps={{ shrink: true }}
                onChange={e => setEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className={classes.bottom}>
          <Button
            variant="text"
            color={isMobile ? "primary" : "default"}
            onClick={onClose}
            disabled={isLoading}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            className={classes.actionBtn}
            disabled={isLoading}
            onClick={download}
          >
            Download
            <ButtonSpinner show={isLoading} />
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  top: {
    width: "100%",
    position: "sticky",
    top: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    borderBottom: "1px solid #DBDEEE",
  },
  close: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: 12,
    paddingBottom: 2,
  },
  header: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    padding: "24px 18px",
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 0,
    },
  },
  content: {
    flex: 1,
    padding: "24px 24px 0 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px 0 16px",
    },
  },
  section: {
    marginBottom: 24,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
    marginBottom: 8,
  },
  radio: {
    marginLeft: 0,
  },
  inputs: {
    paddingTop: 8,
    display: "flex",
  },
  leftInput: {
    marginRight: 16,
  },
  bottom: {
    width: "100%",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    padding: 24,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: "16px 16px 8px 16px",
      alignItems: "center",
    },
  },
  actionBtn: {
    minWidth: 120,
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginLeft: 0,
      marginBottom: 8,
    },
  },
}));
