export function TwitterSVG() {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <title>Twitter</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-130, -418)" fill="#000000" fillRule="nonzero">
          <g transform="translate(130, 418)">
            <path d="M19.0474667,13.5513739 L30.9621333,0 L28.1397333,0 L17.7898667,11.7640633 L9.5296,0 L0,0 L12.4938667,17.7910408 L0,32 L2.8224,32 L13.7450667,19.5740883 L22.4704,32 L32,32 M3.84106667,2.08253122 L8.17706667,2.08253122 L28.1376,30.0197835 L23.8005333,30.0197835"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
