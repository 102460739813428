import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useNotify, useRefresh, useRedirect } from "react-admin";
import { CampaignForm } from "./CampaignForm";
import {
  campaignTypeIds,
  APIClient,
  generateAuthHeader,
  isOrg,
} from "../../lib";

export default function CampaignEdit(props) {
  const campaignId = props.id;
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const campaignData = useSelector(state => {
    const data = state.admin.resources.campaigns.data;
    return data && data[campaignId] ? data[campaignId] : null;
  }, shallowEqual);
  const isOrgUser = useSelector(state => isOrg(state.user.role));

  const [isRaffle, setIsRaffle] = useState(false);
  const [isMatching, setIsMatching] = useState(false);
  const [isDonation, setIsDonation] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [alreadyHasDonations, setAlreadyHasDonations] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const res = await APIClient.get(
        `/campaigns/${campaignId}/donation-check`,
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) return notify(errorMessage, "warning");
      setAlreadyHasDonations(data.hasDonations);
    };
    if (campaignId) fetch();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    if (campaignData) {
      if (campaignData.campaign_default) {
        setDefaultData(campaignData.campaign_default);
      }
      const campaignTypeId = campaignData.campaign_type_id;
      switch (campaignTypeId) {
        case campaignTypeIds.RAFFLE:
          setIsRaffle(true);
          break;
        case campaignTypeIds.MATCH:
          setIsMatching(true);
          break;
        case campaignTypeIds.DONATION:
          setIsDonation(true);
          break;
        case campaignTypeIds.PRODUCT:
          setIsProduct(true);
          break;
        default:
      }
    }
  }, [campaignData, props.id]);

  const formProps = {
    ...props,
    undoable: false,
    hasShow: isOrgUser ? false : true,
    title: "Edit Campaign",
    onSuccess: () => {
      notify("Campaign updated successfully");
      if (isOrgUser) refresh();
      else redirect("show", "/campaigns", campaignId);
    },
  };

  return (
    <CampaignForm
      formProps={formProps}
      defaultData={defaultData}
      isDonation={isDonation}
      isMatching={isMatching}
      isRaffle={isRaffle}
      isProduct={isProduct}
      isEdit
      alreadyHasDonations={alreadyHasDonations}
    />
  );
}
