import React, { useState, Fragment } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { APIUtilClient, generateAuthHeader, downloadPdf } from "../../../lib";

export function StudentPickSlipsDownload({ campaign_id, setShowModal }) {
  const classes = styles();
  const [excludeBrochures, setExcludeBrochures] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const download = async () => {
    setDownloading(true);
    const res = await APIUtilClient.get(
      `/pdf/student-pick-slips?campaign_id=${campaign_id}&exclude_brochures=${excludeBrochures}`,
      {
        responseType: "arraybuffer",
        headers: {
          Accept: "application/pdf, application/json",
          ...generateAuthHeader(),
        },
      },
    );
    downloadPdf(res, "student-pick-slips.pdf");
    handleClose();
  };

  return (
    <Fragment>
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle>Student Pick Slips</DialogTitle>

        <DialogContent>
          <DialogContentText>Select your preferences</DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={excludeBrochures}
                onChange={({ target }) => setExcludeBrochures(target.checked)}
              />
            }
            label="Exclude Order-Form (Brochure) Orders"
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" disabled={downloading} onClick={download}>
            download
            {downloading && (
              <CircularProgress
                color="primary"
                className={classes.spinner}
                size={24}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const styles = makeStyles(_theme => ({
  spinner: { position: "absolute" },
}));
