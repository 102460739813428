import {
  Button,
  makeStyles,
  TextField,
  Tooltip,
  MenuItem,
  Dialog,
} from "@material-ui/core";
import _findIndex from "lodash.findindex";
import _isEqual from "lodash.isequal";
import { ButtonSpinner, CircularLoader } from "../../../components";
import { useEffect, useState } from "react";
import { APIAuthClient } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { campaignActions, getCampaignId, setToast } from "../../../state";
import classNames from "classnames";
import {
  configurableProductOptionDisplayTypes,
  hexToRGBA,
} from "../../../../lib";

type Props = {
  onClose: () => void;
};
export function RequiredPrizeSelection({ onClose }: Props) {
  const classes = styles();
  const campaign_id = useSelector(getCampaignId);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [selectedCombo, setSelectedCombo] = useState({});
  const [selectedProductId, setSelectedProductId] = useState<null | number>(
    null,
  );
  const [data, setData] = useState<GenericObject>({});
  const {
    image = "",
    product_name = "",
    configurableOptions = [],
    variants = [],
    availableCombos = [],
  } = data;

  useEffect(() => {
    const fetch = async () => {
      const url = `/volunteer_campaign_prize_picks/required_prize_selection_data?campaign_id=${campaign_id}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) return dispatch(setToast(errorMessage));
      setData(data);
      setLoading(false);
    };
    if (campaign_id) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign_id]);

  useEffect(() => {
    const activeVariant = (variants as GenericObject[]).find(({ variant }) =>
      _isEqual(variant, selectedCombo),
    );
    if (activeVariant) {
      setSelectedProductId(activeVariant.product_id as number);
    } else setSelectedProductId(null);
  }, [variants, selectedCombo]);

  const onSave = async () => {
    setSubmitting(true);
    const url = "/volunteer_campaign_prize_picks/required_prize_selection_pick";
    const post = { campaign_id, product_id: selectedProductId };
    const response = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = response;
    if (error) {
      dispatch(setToast(errorMessage));
      setSubmitting(false);
      return;
    }
    dispatch(
      campaignActions.updateCampaignInfo({
        missingRequiredPrizeSelection: false,
      }),
    );
    dispatch(
      setToast("Your selection has been submitted!", ToastTypes.success),
    );
    onClose();
  };

  return (
    <Dialog
      open
      maxWidth={false}
      classes={{
        paper: classes.paper,
        paperWidthFalse: classes.paperWidthFalse,
      }}
    >
      <div className={classes.container}>
        <div className={classes.top}>Select your prize</div>

        {loading && (
          <div className={classes.loadingWrapper}>
            <CircularLoader show />
          </div>
        )}

        {!loading && (
          <div className={classes.content}>
            <div className={classes.imgContainer}>
              <img className={classes.img} src={image as string} alt="Prize" />
            </div>
            <div className={classes.info}>
              <div className={classes.productName}>{product_name}</div>

              {(configurableOptions as GenericObject[]).map(
                (o: any, index: number) => {
                  const {
                    option_id,
                    display_type,
                    option_label,
                    option_items,
                  } = o;
                  const textSwatch =
                    display_type ===
                    configurableProductOptionDisplayTypes.TEXT_SWATCH;
                  const colorSwatch =
                    display_type ===
                    configurableProductOptionDisplayTypes.COLOR_SWATCH;
                  const dropdown = !textSwatch && !colorSwatch;
                  const selectedItem =
                    option_items[`_${selectedCombo[option_id]}`];
                  const activeTxt = selectedItem
                    ? selectedItem.display_text
                    : "";
                  const activeItemId = selectedItem ? selectedItem.item_id : "";
                  return (
                    <div key={index} className={classes.optionGroup}>
                      <div className={classes.label}>
                        {option_label}
                        <span className={classes.displayText}>{activeTxt}</span>
                      </div>
                      {!dropdown && (
                        <SwatchOptionItems
                          {...{
                            option_id,
                            option_items,
                            selectedCombo,
                            availableCombos,
                            setSelectedCombo,
                            colorSwatch,
                          }}
                        />
                      )}
                      {dropdown && (
                        <DropdownOptionItems
                          {...{
                            option_id,
                            option_items,
                            selectedCombo,
                            availableCombos,
                            setSelectedCombo,
                            activeItemId,
                          }}
                        />
                      )}
                    </div>
                  );
                },
              )}

              <Button
                color="primary"
                className={classes.save}
                disabled={!selectedProductId || submitting}
                onClick={onSave}
              >
                Save selection
                <ButtonSpinner show={submitting} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}

function SwatchOptionItems({
  option_id,
  option_items,
  selectedCombo,
  availableCombos,
  setSelectedCombo,
  colorSwatch,
}) {
  const classes = styles();
  const handleClick = (item_id: number) => {
    setSelectedCombo({ ...selectedCombo, [option_id]: item_id });
  };

  return (
    <div className={classes.optionItems}>
      {Object.keys(option_items).map((key, index2) => {
        const { item_id, value } = option_items[key];
        const available =
          _findIndex(availableCombos, {
            ...selectedCombo,
            [option_id]: item_id,
          }) > -1;
        const selected = selectedCombo[option_id] === item_id;
        return (
          <Tooltip
            key={index2}
            title={!available ? "unavailable" : ""}
            placement="top"
            classes={{ tooltipPlacementTop: classes.tooltip }}
          >
            <div
              className={classNames(
                classes.swatchContainer,
                colorSwatch && selected && classes.selectedColorSwatchContainer,
                available && classes.pointer,
                !available && classes.disabledSwatch,
              )}
              onClick={!available ? undefined : () => handleClick(item_id)}
            >
              {colorSwatch && (
                <div
                  className={classNames(
                    classes.colorSwatch,
                    selected && classes.selectedColorSwatch,
                  )}
                  style={{
                    backgroundColor: hexToRGBA(value, available ? 1 : 0.5),
                  }}
                >
                  {!available && <div className={classes.diagonalLine} />}
                </div>
              )}
              {!colorSwatch && (
                <div
                  className={classNames(
                    classes.textSwatch,
                    selected && classes.selectedTextSwatch,
                  )}
                >
                  <div
                    className={classNames(!available && classes.unavailableTxt)}
                  >
                    {value}
                  </div>
                  {!available && <div className={classes.diagonalLine} />}
                </div>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

function DropdownOptionItems({
  option_id,
  option_items,
  selectedCombo,
  availableCombos,
  setSelectedCombo,
  activeItemId,
}) {
  const classes = styles();

  const renderValue = (itemId: any) => {
    return !itemId
      ? "Select an Option"
      : option_items[`_${itemId}`]?.display_text || "unknown";
  };

  return (
    <TextField
      select
      variant="outlined"
      size="small"
      value={activeItemId}
      SelectProps={{ displayEmpty: true, renderValue: renderValue }}
      onChange={({ target }) => {
        setSelectedCombo({ ...selectedCombo, [option_id]: target.value });
      }}
      className={classes.select}
    >
      {Object.keys(option_items).map((key, index2) => {
        const { item_id, display_text } = option_items[key];
        const available =
          _findIndex(availableCombos, {
            ...selectedCombo,
            [option_id]: item_id,
          }) > -1;
        return (
          <MenuItem key={index2} value={item_id} disabled={!available}>
            <span
              className={classNames(!available && classes.unavailableMenuItem)}
            >
              {display_text}
            </span>
          </MenuItem>
        );
      })}
    </TextField>
  );
}

const styles = makeStyles(theme => ({
  paper: {
    margin: 16,
  },
  paperWidthFalse: {
    maxWidth: "calc(100% - 32px)",
  },
  container: {
    width: 700,
    maxWidth: "100%",
  },
  top: {
    padding: 24,
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    borderBottom: "1px solid #DBDEEE",
  },
  loadingWrapper: {
    minHeight: 300,
    paddingTop: 100,
  },
  content: {
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "unset",
    },
  },
  imgContainer: {
    width: 300,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: 16,
      paddingBottom: 24,
    },
  },
  img: {
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      width: 190,
    },
  },
  info: {
    width: 312,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  productName: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0.15,
    lineHeight: "32px",
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  optionGroup: {
    marginBottom: 24,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  optionItems: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginRight: -14,
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.17,
    lineHeight: "16px",
    marginBottom: 10,
  },
  displayText: {
    fontWeight: 600,
    paddingLeft: 8,
  },
  swatchContainer: {
    marginRight: 12,
    marginBottom: 8,
  },
  selectedColorSwatchContainer: {
    border: `2px solid #000000 !important`,
    borderRadius: "100%",
  },
  colorSwatch: {
    height: 40,
    width: 40,
    border: "1px solid #000000",
    position: "relative",
    borderRadius: "100%",
  },
  selectedColorSwatch: {
    border: `1px solid #FFFFFF !important`,
  },
  textSwatch: {
    height: 40,
    minWidth: 66,
    width: "fit-content",
    padding: "0 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.text.primary}`,
    position: "relative",
    fontSize: 14,
    letterSpacing: 0,
    borderRadius: 20,
  },
  selectedTextSwatch: {
    border: `2px solid ${theme.palette.text.primary} !important`,
    fontWeight: 600,
  },
  disabledSwatch: {
    opacity: 0.5,
  },
  pointer: {
    cursor: "pointer",
  },
  unavailableTxt: {
    opacity: 0.5,
  },
  unavailableMenuItem: {
    textDecoration: "line-through",
  },
  diagonalLine: {
    position: "absolute",
    borderRadius: 20,
    top: 0,
    width: "100%",
    height: "100%",
    background: `linear-gradient(to bottom left, transparent calc(50% - 1px),  ${theme.palette.text.primary} calc(50% - 1px), ${theme.palette.text.primary} 50%, transparent 50%)`,
  },
  tooltip: {
    marginBottom: "4px !important",
  },
  select: { width: "100%", maxWidth: 400, marginBottom: 8 },
  save: {
    width: "100%",
    height: 42,
    borderRadius: 21,
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.43,
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
}));
