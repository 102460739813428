import React, { useState, useEffect, Fragment } from "react";
import {
  Toolbar,
  useNotify,
  EditView,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  email,
  minLength,
  SaveButton,
  ArrayInput,
  SimpleFormIterator,
  useListContext,
} from "react-admin";
import { useDispatch } from "react-redux";
import { APIClient, generateAuthHeader } from "../../lib";

const validateEmail = [required(), email(), minLength(2)];
const validateName = [required(), minLength(1)];
const requiredInput = [required()];

export function CompanyEditVolunteer({
  record,
  classOptions,
  allow_associated_volunteer_accounts,
}) {
  const { id, campaign_id, allow_family_accounts } = record;
  const notify = useNotify();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const { refetch } = useListContext();

  const [editProps, setEditProps] = useState({
    basePath: `campaigns/${campaign_id}/show/volunteers`,
    defaultTitle: " ",
    loaded: false,
    loading: true,
    resource: "volunteers",
    save: record => onSave(record),
    saving: false,
    version: 0,
  });

  useEffect(() => {
    fetchVolunteer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchVolunteer = async () => {
    const res = await APIClient.get(
      `/volunteers/${id}?campaign_id=${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setEditProps({ ...editProps, loaded: true, loading: false, record: data });
  };

  const onSave = async record => {
    setSaving(true);
    const res = await APIClient.put(
      `/volunteers/${id}?campaign_id=${campaign_id}`,
      record,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    setSaving(false);

    if (error) {
      fetchVolunteer();
      return notify(errorMessage, "warning");
    }

    dispatch({
      type: "RA/TOGGLE_LIST_ITEM_EXPAND",
      payload: id,
      meta: { resource: "volunteers" },
    });
    refetch();
    notify("Student updated successfully");
  };

  return (
    <EditView {...editProps} saving={saving}>
      <SimpleForm
        redirect={null}
        toolbar={
          <Toolbar>
            <SaveButton label="save" redirect={null} />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled={true} />
        <TextInput source="first_name" validate={validateName} />
        <TextInput source="last_name" validate={validateName} />
        <TextInput source="slug" label="Unique URL" disabled={true} />
        <TextInput source="email" validate={validateEmail} />
        <BooleanInput
          source="can_duplicate_email"
          label="Allow This Email To Be Duplicated (Please note: This should only be allowed where multiple student accounts must share a common email address (e.g. parent/guardian). Otherwise, students should not be allowed to create duplicate accounts.)"
          fullWidth
        />
        {allow_associated_volunteer_accounts && (
          <Fragment>
            <div>
              This switch should only be used when accounts need to be
              associated manually.
            </div>
            <div>
              Typically, this happens automatically when accounts are created
              (even if switch is 'off').
            </div>
          </Fragment>
        )}
        {allow_associated_volunteer_accounts && (
          <BooleanInput
            source="associate_shared_emails"
            label="Associate This Account With Other Accounts With The Same Email (Associated users share the same email address and all associated user accounts can be accessed from any associated user login)"
            fullWidth
          />
        )}
        <TextInput source="phone" validate={requiredInput} />
        <TextInput source="address" />
        <TextInput source="city" />
        <TextInput source="state" />
        <TextInput source="zip" />
        {Array.isArray(classOptions) && (
          <SelectInput
            source="class_name"
            label="Class"
            choices={classOptions.map(o => {
              return { id: o, name: o };
            })}
            translateChoice={false}
          />
        )}
        {allow_family_accounts && (
          <ArrayInput
            source="family_members"
            label="Additional Family Members"
            style={{ width: "400px" }}
          >
            <SimpleFormIterator>
              <TextInput
                source="first_name"
                label="First Name"
                validate={requiredInput}
              />
              <TextInput
                source="last_name"
                label="Last Name"
                validate={requiredInput}
              />
              <SelectInput
                source="class_name"
                label="Class"
                choices={
                  !Array.isArray(classOptions)
                    ? []
                    : classOptions.map(o => {
                        return { id: o, name: o };
                      })
                }
                translateChoice={false}
                validate={requiredInput}
              />
            </SimpleFormIterator>
          </ArrayInput>
        )}
        <TextInput source="new_password" label="Change Password" />
      </SimpleForm>
    </EditView>
  );
}
