import { Fragment, useEffect, useState } from "react";
import _isEqual from "lodash.isequal";
import _findIndex from "lodash.findindex";
import { useField } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { makeStyles, Theme, Switch } from "@material-ui/core";
import CircleIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import classNames from "classnames";
import { format } from "date-fns";
enum Modes {
  none = "none",
  allEvents = "allEvents",
  selectEvents = "selectEvents",
}

export function EventGroup({ groupRow }) {
  const [mode, setMode] = useState<Modes>(Modes.none);
  const [supportersToggle, setSupportersToggle] = useState(false);
  const [showContacts, setShowContacts] = useState(false);
  const [unselectAllContacts, setUnselectAllContacts] = useState(false);
  const classes = styles({ mode });
  const joinedInput = useField(`${groupRow}.joinedGroup`).input;
  const allEventsInput = useField(
    `${groupRow}.send_notifications_for_all_events`,
  ).input;
  const allContactsInput = useField(`${groupRow}.notify_all_contacts`).input;
  const allSupportersInput = useField(`${groupRow}.notify_all_supporters`)
    .input;
  const {
    group_name,
    joinedGroup,
    send_notifications_for_all_events: allEvents,
    notify_all_contacts: allContacts,
    notify_all_supporters: allSupporters,
    prevJoinedGroup,
    contacts,
  } = useField(groupRow).input.value;

  useEffect(() => {
    switch (true) {
      case Boolean(joinedGroup && allEvents):
        setMode(Modes.allEvents);
        break;
      case Boolean(joinedGroup && !allEvents):
        setMode(Modes.selectEvents);
        break;
      default:
        setMode(Modes.none);
    }
  }, [joinedGroup, allEvents]);

  useEffect(() => {
    if (allContacts && allSupporters) return;
    if (prevJoinedGroup && Array.isArray(contacts)) {
      const hasSelected = contacts.some(({ joinedContact }) => joinedContact);
      if (hasSelected) setShowContacts(true);
    }
    if (allSupporters) setSupportersToggle(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevJoinedGroup]); // DON'T ADD to deps

  const toggleMode = (mode: Modes) => {
    switch (mode) {
      case Modes.allEvents:
        joinedInput.onChange(true);
        allEventsInput.onChange(true);
        break;
      case Modes.selectEvents:
        joinedInput.onChange(true);
        allEventsInput.onChange(false);
        break;
      default:
        joinedInput.onChange(false);
        allEventsInput.onChange(false);
    }
  };

  const onEveryoneToggle = (checked: boolean) => {
    allContactsInput.onChange(checked);
    allSupportersInput.onChange(checked);
    setSupportersToggle(false);
    setShowContacts(false);
  };

  const onSupportersToggle = (checked: boolean) => {
    allSupportersInput.onChange(checked);
    setSupportersToggle(checked);
    allContactsInput.onChange(false);
  };

  const onContactsToggle = (checked: boolean) => {
    setShowContacts(checked);
    if (!checked) setUnselectAllContacts(true);
    allContactsInput.onChange(false);
    if (!supportersToggle) {
      allSupportersInput.onChange(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.groupName}>{group_name}</div>
      <div className={classes.eventsSection}>
        <p className={classes.which}>
          Which events should we send notifications for?
        </p>
        <div className={classes.toggleWrapper}>
          <div className={classes.toggle}>
            <div
              className={classNames(
                classes.toggleOption,
                mode === Modes.allEvents && classes.selectedToggle,
              )}
              onClick={() => toggleMode(Modes.allEvents)}
            >
              All events
            </div>
            <div
              className={classNames(
                classes.toggleOption,
                mode === Modes.selectEvents && classes.selectedToggle,
                classes.middleToggle,
              )}
              onClick={() => toggleMode(Modes.selectEvents)}
            >
              Select events
            </div>
            <div
              className={classNames(
                classes.toggleOption,
                mode === Modes.none && classes.selectedToggle,
              )}
              onClick={() => toggleMode(Modes.none)}
            >
              None
            </div>
          </div>
        </div>

        {joinedGroup && !allEvents && (
          <FieldArray name={`${groupRow}.event_group_events`}>
            {({ fields: events }) => (
              <Fragment>
                {events.map((eventRow, index) => {
                  const values = events.value[index];
                  return (
                    <Event
                      key={index}
                      eventRow={eventRow}
                      values={values}
                      prevJoinedGroup={prevJoinedGroup}
                    />
                  );
                })}
              </Fragment>
            )}
          </FieldArray>
        )}
      </div>

      {joinedGroup && (
        <div className={classes.contactsSection}>
          <div className={classes.who}>Who should we notify?</div>
          <div className={classes.everyoneRow}>
            <div>Everyone</div>
            <Switch
              checked={Boolean(allContacts && allSupporters)}
              onChange={(_, checked) => onEveryoneToggle(checked)}
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                checked: classes.checked,
              }}
            />
          </div>
          <div className={classes.everyoneMsg}>
            All of my fundraising contacts and supporters of this campaign
          </div>
          <div className={classes.toggleRow}>
            <div>Supporters of this campaign</div>
            <Switch
              checked={supportersToggle}
              onChange={(_, checked) => onSupportersToggle(checked)}
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                checked: classes.checked,
              }}
            />
          </div>
          <div className={classes.toggleRow}>
            <div>Select fundraising contacts</div>
            <Switch
              checked={showContacts}
              onChange={(_, checked) => onContactsToggle(checked)}
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                checked: classes.checked,
              }}
            />
          </div>

          <FieldArray name={`${groupRow}.contacts`}>
            {({ fields: contacts }) => (
              <Fragment>
                {contacts.map((contactRow, index) => {
                  const length = contacts.length ? contacts.length : 0;
                  const notLast = index < length - 1;
                  const values = contacts.value[index];
                  return (
                    <Contact
                      key={index}
                      contactRow={contactRow}
                      values={values}
                      prevJoinedGroup={prevJoinedGroup}
                      unselectAllContacts={unselectAllContacts}
                      notLast={notLast}
                      showContacts={showContacts}
                    />
                  );
                })}
              </Fragment>
            )}
          </FieldArray>
        </div>
      )}
    </div>
  );
}

function Event({ eventRow, values, prevJoinedGroup }) {
  const { event_name, date, time } = values;
  const classes = styles({});
  const input = useField(`${eventRow}.joinedEvent`).input;

  // if they weren't previously on the group set all events to be checked
  useEffect(() => {
    if (!prevJoinedGroup) input.onChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevJoinedGroup]);

  const onChange = (checked: boolean) => {
    input.onChange(checked);
  };

  return (
    <div className={classes.eventRow}>
      <div>
        <div className={classes.eventName}>{event_name}</div>
        <div className={classes.eventDate}>
          {date &&
            time &&
            format(new Date(`${date} ${time}`), "LLLL d, yyyy, h:mmaaa")}
        </div>
      </div>
      <Switch
        checked={Boolean(input.value)}
        onChange={(_, checked) => onChange(checked)}
        classes={{
          switchBase: classes.switchBase,
          track: classes.track,
          checked: classes.checked,
        }}
      />
    </div>
  );
}

function Contact({
  contactRow,
  values,
  prevJoinedGroup,
  unselectAllContacts,
  notLast,
  showContacts,
}) {
  const classes = styles({});
  const { name, email } = values;
  const input = useField(`${contactRow}.joinedContact`).input;
  const checked = Boolean(input.value);

  // if they weren't previously on the group set all contacts to be checked
  useEffect(() => {
    if (!prevJoinedGroup) input.onChange(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevJoinedGroup]);

  useEffect(() => {
    if (unselectAllContacts) input.onChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unselectAllContacts]);

  if (!showContacts) return <></>;
  return (
    <div
      className={classNames(
        classes.contactRow,
        notLast && classes.contactRowBorder,
      )}
      onClick={() => input.onChange(!checked)}
    >
      <div className={classes.contactCheckWrapper}>
        {checked ? (
          <CheckCircleIcon className={classes.checkedIcon} />
        ) : (
          <CircleIcon className={classes.unCheckedIcon} />
        )}
      </div>

      <div>
        <div className={classes.contactName}>{name}</div>
        <div className={classes.contactEmail}>{email}</div>
      </div>
    </div>
  );
}

type StyleProps = {
  mode?: Modes;
};
const styles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    marginBottom: 16,
  },
  groupName: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: "32px",
    padding: 16,
    borderBottom: "1px solid #EAEBF3",
  },
  eventsSection: {
    padding: 16,
    borderBottom: ({ mode }) =>
      mode === Modes.none ? "none" : "1px solid #EAEBF3",
    paddingBottom: ({ mode }) => (mode === Modes.selectEvents ? 0 : 16),
  },
  which: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.12,
    lineHeight: "26px",
    paddingBottom: 16,
  },
  toggleWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  toggle: {
    height: 36,
    width: 311,
    maxWidth: "100%",
    borderRadius: 18,
    backgroundColor: "#E5E6EB",
    display: "flex",
    cursor: "pointer",
    marginBottom: 8,
  },
  toggleOption: {
    height: "100%",
    width: "33.33333%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.26,
    color: theme.palette.text.secondary2,
  },
  selectedToggle: {
    borderRadius: 18,
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
  },
  middleToggle: {
    borderRight: ({ mode }) =>
      mode === Modes.allEvents ? "1px solid #FFFFFF" : "none",
    borderLeft: ({ mode }) =>
      mode === Modes.none ? "1px solid #FFFFFF" : "none",
  },
  eventRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "8px 0 16px 0",
  },
  switchBase: {
    "&$checked": {
      color: theme.palette.secondary2.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.secondary2.main,
    },
  },
  checked: {}, // don't remove
  track: {}, // don't remove
  eventName: {
    fontSize: 16,
    letterSpacing: 0.15,
    lineHeight: "22px",
    paddingBottom: 4,
    paddingTop: 8,
    overflowWrap: "anywhere",
  },
  eventDate: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
  },
  toggleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "8px 0",
    fontSize: 16,
    letterSpacing: 0.15,
  },
  everyoneRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "16px 0 2px 0",
    fontSize: 16,
    letterSpacing: 0.15,
  },
  everyoneMsg: {
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    maxWidth: 250,
    paddingBottom: 16,
  },
  contactsSection: {
    padding: 16,
  },
  who: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.12,
    lineHeight: "26px",
  },
  contactRow: {
    minHeight: 58,
    display: "flex",
    alignItems: "center",

    padding: "8px 0",
  },
  contactRowBorder: {
    borderBottom: "1px solid #EAEBF3",
  },
  contactCheckWrapper: {
    marginLeft: 10,
    marginRight: 24,
  },
  contactName: {
    fontSize: 14,
    letterSpacing: 0.1,
    lineHeight: "16px",
    overflowWrap: "anywhere",
    paddingBottom: 6,
  },
  contactEmail: {
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "14px",
    overflowWrap: "anywhere",
    color: theme.palette.text.secondary,
  },
  unCheckedIcon: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  checkedIcon: {
    cursor: "pointer",
    color: "#1AC846",
  },
}));
