import { makeStyles, TextField, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { SIDEBAR_OPEN_WIDTH, paths } from "../types";
import { RootState } from "../types/state";

export function CampaignSelector() {
  const { campaignId, campaignAutocomplete } = useSelector(
    (state: RootState) => state.campaign,
  );
  const classes = styles();

  const onSelect = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const value = e.target.value;
    if (!value) {
      localStorage.removeItem("selectedCampaignId");
      window.location.replace(paths.ORG_CAMPAIGNS);
    } else {
      localStorage.setItem("selectedCampaignId", `${value}`);
      window.location.replace(paths.HOME);
    }
  };

  if (!Array.isArray(campaignAutocomplete) || campaignAutocomplete.length < 1) {
    return <div />;
  }
  return (
    <div className={classes.container}>
      <TextField
        select
        size="small"
        value={campaignId}
        className={classes.select}
        SelectProps={{ displayEmpty: true }}
        onChange={onSelect}
      >
        {campaignAutocomplete.map(({ id, name }) => {
          return (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          );
        })}
        <MenuItem className={classes.viewAll} value="">
          View all campaigns
        </MenuItem>
      </TextField>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: SIDEBAR_OPEN_WIDTH - 32,
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      maxWidth: "calc(100vw - 120px)",
    },
  },
  select: {
    height: 32,
    paddingLeft: 0,
    width: "100%",
    maxWidth: "100%",
    overflow: "hidden",
    "& .MuiInputBase-root": {
      height: 32,
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: 200,
      "& .MuiOutlinedInput-input": {
        paddingLeft: 16,
      },
    },
  },
  viewAll: {
    borderTop: "1px solid #DBDEEE",
    marginTop: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
}));
