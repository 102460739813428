import { APIAuthClient } from "../../lib";
import { APIRes, TeamsAutocomplete } from "../../types";
import { setToast } from "../ui/actions";
import { AppThunkAction } from "../../types/state";

const teamActions = {
  fetchCampaignTeamAutocomplete(
    campaignId?: number,
  ): AppThunkAction<Promise<null | TeamsAutocomplete>> {
    return async dispatch => {
      const url = `/team_campaigns/autocomplete?campaign_id=${campaignId}`;
      const response = await APIAuthClient.get<any, APIRes>(url);
      const { error, errorMessage, data } = response;
      if (error) {
        dispatch(setToast(errorMessage));
        return null;
      }
      return data;
    };
  },
};

export { teamActions };
