import { makeStyles, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Fragment, ReactNode, FC } from "react";

type Props = {
  SVG: FC;
  listIsFiltered: boolean;
  children?: ReactNode | ReactNode[];
  label: string;
  paragraph?: string | null;
  buttonLabel?: string;
  buttonFunc?: () => void;
};
export function ListNoResults({
  SVG,
  listIsFiltered,
  children,
  label,
  paragraph,
  buttonLabel,
  buttonFunc,
}: Props) {
  const classes = styles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <SVG />
        {listIsFiltered && (
          <h1 className={classes.header}>No search results</h1>
        )}
        {!listIsFiltered && (
          <Fragment>
            <h1 className={classes.header}>No {label} yet</h1>

            {paragraph && <p className={classes.p}>{paragraph}</p>}

            {Boolean(children) && children}

            {buttonLabel && buttonFunc && (
              <Button
                color="primary"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={buttonFunc}
              >
                {buttonLabel}
              </Button>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    width: "100%",
    padding: "56px 32px 56px 32px",
    borderRadius: 8,
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "24px 0 40px 0",
      backgroundColor: "inherit",
    },
  },
  container: {
    width: 542,
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  header: {
    paddingTop: 32,
    paddingBottom: 32,
    fontSize: 34,
    letterSpacing: 0.26,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
    },
  },
  p: {
    fontSize: 20,
    letterSpacing: 0.15,
    lineHeight: "32px",
    textAlign: "center",
    paddingBottom: 32,
    marginTop: -16,
  },
  button: {
    width: 200,
    [theme.breakpoints.down("sm")]: {
      width: 300,
      maxWidth: "100%",
      marginBottom: 16,
    },
  },
}));
