import React from "react";
import {
  TextInput,
  SimpleForm,
  required,
  minLength,
  Edit,
  Toolbar,
  SaveButton,
  BooleanInput,
  Show,
  TabbedShowLayout,
  Tab,
  AutocompleteArrayInput,
  FormDataConsumer,
} from "react-admin";
import { GroupProducts } from "./GroupProducts";

const validateName = [required(), minLength(2)];

const Title = ({ record }) => {
  return (
    <span>
      Product Group - {record ? `${record.group_name} #${record.id}` : ""}
    </span>
  );
};

export function ProductGroupDetails({ id, resource, basePath }) {
  const props = { id, resource, basePath };

  const transformDataForSubmit = values => {
    const { company_product_suppliers: _rm, ...rest } = values;
    return rest;
  };

  return (
    <Show {...props} title={<Title />} actions={null}>
      <TabbedShowLayout>
        <Tab label="products" path="">
          <GroupProducts />
        </Tab>
        <Tab label="config" path="config">
          <Edit
            {...props}
            title=" "
            successMessage="Group updated successfully"
            undoable={false}
            transform={transformDataForSubmit}
          >
            <SimpleForm
              toolbar={
                <Toolbar>
                  <SaveButton redirect={false} />
                </Toolbar>
              }
            >
              <TextInput source="group_name" validate={validateName} />
              <BooleanInput source="active" />

              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  const { company_product_suppliers = [] } = formData;
                  return (
                    <AutocompleteArrayInput
                      {...rest}
                      label="Companies (Distributors) who can use this group"
                      allowEmpty
                      source="company_ids"
                      choices={company_product_suppliers}
                      optionValue="company_id"
                      optionText="company_name"
                      translateChoice={false}
                      fullWidth
                    />
                  );
                }}
              </FormDataConsumer>
            </SimpleForm>
          </Edit>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}
