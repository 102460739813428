import React, { useState, useEffect } from "react";
import { useNotify, useMutation, useListContext } from "react-admin";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export const RemoveProduct = ({ campaignProductId }) => {
  const classes = styles();
  const notify = useNotify();
  const { refetch } = useListContext();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowButton(true);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const [remove, { loading }] = useMutation(
    {
      type: "delete",
      resource: "campaign_products",
      payload: { id: campaignProductId },
    },
    {
      onFailure: error => notify(error.message, "warning"),
      onSuccess: () => {
        refetch();
        notify("Product Removed");
      },
    },
  );

  if (!showButton) return <></>;
  return (
    <Button
      variant="contained"
      className={classes.removeButton}
      startIcon={<DeleteIcon />}
      onClick={e => {
        e.stopPropagation();
        remove();
      }}
      disabled={loading}
    >
      remove
      {loading && (
        <CircularProgress size={18} className={classes.removeSpinner} />
      )}
    </Button>
  );
};

const styles = makeStyles(_theme => ({
  removeButton: {
    backgroundColor: "#FACBCB",
    color: "#FF0000",
  },
  removeSpinner: {
    position: "absolute",
    color: "#FF0000",
  },
}));
