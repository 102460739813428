import { Button, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ListProps } from "../../types";

type Props = {
  listProps: ListProps<any>;
};
export function MobileBulkSelectHeader({ listProps }: Props) {
  const classes = styles();
  const {
    mobileBulkSelectHeaderProps: { buttonLabel, callback },
    toggleMobileSelectRowsMode,
    selectedRowCount,
    hasSelectedRows,
    unselectAllRows,
  } = listProps;

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <IconButton
          onClick={() => {
            toggleMobileSelectRowsMode(false);
            unselectAllRows();
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.header}>{selectedRowCount} selected</div>
      </div>
      <Button onClick={callback} color="secondary" disabled={!hasSelectedRows}>
        {buttonLabel}
      </Button>
    </div>
  );
}

const styles = makeStyles(_theme => ({
  container: {
    backgroundColor: "#EAEBF3",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: -16,
    marginRight: -16,
    padding: "18px 16px 18px 12px",
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.12,
    marginLeft: 12,
  },
}));
