import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field, useForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Button, makeStyles, MenuItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/DeleteOutline";
import { APIRes, GuidedSetupScreen } from "../../../types";
import { APIAuthClient, requiredField } from "../../../lib";
import {
  getVolunteerCampaignId,
  setToast,
  updateVolunteerSetupData,
} from "../../../state";
import { sharedStyles } from "../shared.styles";
import { FamilyMembersSVG } from "../SVGs/FamilyMembersSVG";
import { RootState } from "../../../types/state";
import { TextFieldWrapper } from "../../../components";

export function FamilyMembers(props: GuidedSetupScreen) {
  const { activeScreen, handleNext, setSubmitting, setDisableNext } = props;
  const classes = styles();
  const sharedClasses = sharedStyles();
  const dispatch = useDispatch();
  const {
    family_members: currFMs,
    class_name,
    first_name,
    last_name,
  } = useSelector((state: RootState) => state.volunteerSetup);
  const volunteerCampaignId = useSelector(getVolunteerCampaignId);
  const [initialValues, setInitialValues] = useState<object>({
    family_members: [],
  });

  useEffect(() => {
    if (activeScreen === "FAMILY_MEMBERS") {
      const FM = Array.isArray(currFMs) ? currFMs : [];
      setInitialValues({
        family_members: FM,
        class_name,
        first_name,
        last_name,
      });

      setDisableNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen, currFMs]);

  const submit = useMemo(
    () => async ({ family_members, ...rest }) => {
      setSubmitting(true);
      const FM =
        !Array.isArray(family_members) || !family_members.length
          ? null
          : family_members;
      const res = await APIAuthClient.put<any, APIRes>(
        `/volunteer_campaigns/${volunteerCampaignId}`,
        { family_members: FM, ...rest },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        dispatch(setToast(errorMessage));
        setSubmitting(false);
        return;
      }
      const { family_members: _fms, first_name, last_name, class_name } = data;
      dispatch(
        updateVolunteerSetupData({
          family_members: _fms,
          class_name,
          first_name,
          last_name,
        }),
      );
      handleNext();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [volunteerCampaignId],
  );

  if (activeScreen !== "FAMILY_MEMBERS") return <></>;
  return (
    <div className={sharedClasses.screenContainer}>
      <FamilyMembersSVG />
      <h1 className={sharedClasses.screenHeader}>Add your family members</h1>
      <p className={classes.paragraph}>
        Add family members that will be participating as part of your joint
        family fundraising account.
      </p>

      <Form
        mutators={{ ...arrayMutators }}
        onSubmit={submit}
        initialValues={initialValues}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FamilyMemberContent {...props} />
            </form>
          );
        }}
      />
    </div>
  );
}

function FamilyMemberContent(props: GuidedSetupScreen) {
  const classes = styles();
  const { nextTriggeredBy, setNextTriggeredBy } = props;
  const form = useForm();
  const { class_options: classOptions } = useSelector(
    (state: RootState) => state.volunteerSetup,
  );

  useEffect(() => {
    if (nextTriggeredBy === "FAMILY_MEMBERS") {
      form.submit();
      setNextTriggeredBy(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTriggeredBy]);

  return (
    <div>
      <div className={classes.subheader}>Your info</div>
      <Field
        component={TextFieldWrapper}
        name="first_name"
        placeholder="First name"
        validate={requiredField}
        className={classes.input}
      />
      <Field
        component={TextFieldWrapper}
        name="last_name"
        placeholder="Last name"
        validate={requiredField}
        className={classes.input}
      />
      {Array.isArray(classOptions) && classOptions.length > 0 ? (
        <Field
          name="class_name"
          placeholder="Class"
          validate={requiredField}
          select
          fullWidth
          SelectProps={{
            displayEmpty: true,
            renderValue: (value: string) =>
              value !== "" ? value : "Select a Class",
          }}
          component={TextFieldWrapper}
          className={classes.input}
        >
          {classOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </Field>
      ) : (
        <Field
          name="class_name"
          placeholder="Class"
          component={TextFieldWrapper}
          className={classes.input}
        />
      )}
      <div className={classes.spacer} />

      <div className={classes.subheader}>Family members</div>
      <FieldArray name="family_members">
        {({ fields }) => (
          <div>
            {fields.map((row, index) => {
              return (
                <div key={index} className={classes.group}>
                  <Field
                    name={`${row}.first_name`}
                    component={TextFieldWrapper}
                    placeholder="First name"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <Field
                    name={`${row}.last_name`}
                    component={TextFieldWrapper}
                    placeholder="Last name"
                    validate={requiredField}
                    className={classes.input}
                  />

                  {Array.isArray(classOptions) && classOptions.length > 0 ? (
                    <Field
                      name={`${row}.class_name`}
                      placeholder="Class"
                      validate={requiredField}
                      select
                      fullWidth
                      SelectProps={{
                        displayEmpty: true,
                        renderValue: (value: string) =>
                          value !== "" ? value : "Select a Class",
                      }}
                      component={TextFieldWrapper}
                      className={classes.input}
                    >
                      {classOptions.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Field>
                  ) : (
                    <Field
                      name={`${row}.class_name`}
                      placeholder="Class"
                      component={TextFieldWrapper}
                      className={classes.input}
                    />
                  )}

                  <div className={classes.removeWrapper}>
                    <RemoveIcon
                      onClick={() => fields.remove(index)}
                      color="primary"
                      className={classes.remove}
                      fontSize="large"
                    />
                  </div>
                </div>
              );
            })}
            <div className={classes.addWrapper}>
              <Button
                color="primary"
                className={classes.add}
                startIcon={<AddIcon />}
                onClick={() =>
                  fields.push({ first_name: "", last_name: "", class_name: "" })
                }
              >
                Add family member
              </Button>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

const styles = makeStyles(theme => ({
  paragraph: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: "20px",
    textAlign: "center",
    marginBottom: 24,
  },
  subheader: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0.12,
    lineHeight: "20px",
    paddingBottom: 16,
  },
  spacer: {
    height: 24,
  },
  addWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 8,
  },
  add: {
    width: 300,
    borderRadius: 18,
  },
  group: {
    border: "1px solid #DBDEEE",
    borderRadius: 8,
    padding: 16,
    marginBottom: 24,
  },
  input: {
    width: "100%",
    maxWidth: "100%",
    marginBottom: 16,
  },
  removeWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  remove: {
    cursor: "pointer",
  },
}));
