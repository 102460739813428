import React from "react";
import { useSelector } from "react-redux";
import { Button, makeStyles } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import jsonExport from "jsonexport/dist";
import { useNotify, downloadCSV } from "react-admin";
import {
  isKidsAreFirstCompany,
  APIClient,
  generateAuthHeader,
} from "../../../lib";

export function TaxReportDownload({ campaign_id }) {
  const companyId = useSelector(state => state.user.companyId);
  const isKidsAreFirst = isKidsAreFirstCompany(companyId);
  const classes = styles();
  const notify = useNotify();

  const fetchReport = async () => {
    const res = await APIClient.get(
      `/order_taxes/kaf_offline_tax_report?campaign_id=${campaign_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");

    jsonExport(data, (_err, csv) => {
      downloadCSV(csv, `Offline Sales Tax Report Campaign-${campaign_id}`);
    });
  };

  if (!isKidsAreFirst) return <></>;
  return (
    <Button
      onClick={fetchReport}
      variant="contained"
      color="primary"
      endIcon={<DownloadIcon />}
      className={classes.button}
      size="small"
    >
      Offline Tax Report
    </Button>
  );
}

const styles = makeStyles(_theme => ({
  button: { marginLeft: 10 },
}));
