import { CircularProgress, makeStyles } from "@material-ui/core";
import { useIsMobile } from "../../hooks/ui";

type Props = {
  show: boolean;
};

export function MobileListLoadingMore({ show }: Props) {
  const classes = styles();
  const isMobile = useIsMobile();

  if (!isMobile || !show) return <></>;
  return (
    <div className={classes.mobileLoader}>
      <CircularProgress size={24} className={classes.loader} />
      <div className={classes.loading}>Loading</div>
    </div>
  );
}

const styles = makeStyles(theme => ({
  mobileLoader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    paddingTop: 16,
  },
  loading: {
    marginLeft: 16,
    fontSize: 14,
    letterSpacing: 0.15,
    color: theme.palette.text.secondary,
  },
  loader: {
    color: theme.palette.secondary2.main,
  },
}));
