import { makeStyles, TablePagination, Theme } from "@material-ui/core";
import { MutableRefObject, useEffect, useRef } from "react";
import { useIsDesktop, useIsMobile } from "../../hooks/ui";
import { PaginationComponentProps } from "../../types";
import classNames from "classnames";

type Props = {
  nextPage: () => void;
  paginationProps: PaginationComponentProps;
  label: string;
  customClassName?: string;
  minWidth?: number;
  rowsPerPageOptions?: number[];
};
export function ListPagination({
  nextPage,
  paginationProps,
  label,
  customClassName,
  minWidth,
  rowsPerPageOptions,
}: Props) {
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const classes = styles({ minWidth });

  if (isMobile) {
    return <InfiniteScroll nextPage={nextPage} />;
  } else if (isDesktop) {
    return (
      <div className={classes.desktopWrapper}>
        <div
          className={classNames(
            classes.pagination,
            customClassName && customClassName,
          )}
        >
          <TablePagination
            {...paginationProps}
            rowsPerPageOptions={
              rowsPerPageOptions ? rowsPerPageOptions : [10, 20, 40]
            }
            component="div"
            labelRowsPerPage={label}
          />
        </div>
      </div>
    );
  } else return <></>;
}

type InfiniteScrollProps = {
  nextPage: () => void;
};
function InfiniteScroll({ nextPage }: InfiniteScrollProps) {
  const classes = styles({});
  const observerRef = useRef<null | HTMLDivElement>(null);

  useInfiniteScrollObserver(observerRef, nextPage);

  return (
    <div className={classes.mobileWrapper}>
      <div ref={observerRef} className={classes.observer}></div>
    </div>
  );
}

function useInfiniteScrollObserver(
  observerRef: MutableRefObject<HTMLDivElement | null>,
  callback: () => void,
) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) callback();
      },
      { threshold: 1 },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    const ref = observerRef.current;
    return () => {
      if (ref) observer.unobserve(ref);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerRef]);
}

type StyleProps = {
  minWidth?: number;
};
const styles = makeStyles<Theme, StyleProps>(_theme => ({
  desktopWrapper: {
    overflow: "unset",
    position: "sticky",
    bottom: 0,
    backgroundColor: "#FFFFFF",
    zIndex: 9,
    display: "flex",
    justifyContent: "flex-end",
    minWidth: ({ minWidth }) => (minWidth ? minWidth : "unset"),
  },
  mobileWrapper: {
    position: "relative",
  },
  observer: {
    position: "absolute",
    bottom: 180,
    height: 1,
    width: 1,
  },
  pagination: {
    position: "sticky",
    right: 0,
  },
}));
