import { useDispatch } from "react-redux";
import arrayMutators from "final-form-arrays";
import { FormApi } from "final-form";
import CloseIcon from "@material-ui/icons/Close";
import { Button, IconButton, makeStyles } from "@material-ui/core";
import { Form, Field } from "react-final-form";
import { APIAuthClient, convertFileToB64, requiredField } from "../../../lib";
import { APIRes, GenericObject, ToastTypes } from "../../../types";
import { setToast } from "../../../state";
import {
  ButtonSpinner,
  DateTimeField,
  ResponsiveModal,
  TextFieldWrapper,
} from "../../../components";
import { ImageDropzoneField } from "../../../components/ui/ImageDropzoneField";
import { useDrawerTransition, useIsMobile } from "../../../hooks/ui";
import { NotificationSchedule } from "./NotificationSchedule";

type Props = {
  onClose: () => void;
  refreshList: () => void;
};

export function AddEventGroup({ onClose: _onClose, refreshList }: Props) {
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { isOpen, onClose } = useDrawerTransition(_onClose);

  const onSubmit = async (values: any, _form: FormApi, complete: any) => {
    const {
      imageFile,
      group_name,
      active,
      active_start_date,
      active_end_date,
      event_group_notifications: EGN,
    } = values;
    const event_group_notifications = !EGN
      ? []
      : EGN.map((e: GenericObject) => {
          const { id, days_before_event } = e;
          return {
            id: id ? id : null,
            days_before_event: Number(days_before_event),
          };
        });
    const post: GenericObject = {
      group_name,
      active,
      active_start_date,
      active_end_date,
      event_group_notifications,
    };
    if (imageFile) post.group_logoBase64 = await convertFileToB64(imageFile);
    const url = "/event_groups";
    const res = await APIAuthClient.post<any, APIRes>(url, post);
    const { error, errorMessage } = res;
    if (error) {
      dispatch(setToast(errorMessage));
      return complete();
    }
    dispatch(setToast("Group added", ToastTypes.success));
    refreshList();
    onClose();
  };

  return (
    <ResponsiveModal isOpen={isOpen} onClose={onClose}>
      <div className={classes.container}>
        <div className={classes.top}>
          <div>Add event group</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>

        <Form
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit, submitting, form, pristine }) => {
            return (
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.mainContent}>
                  <Field
                    component={TextFieldWrapper}
                    name="group_name"
                    label="Group name"
                    validate={requiredField}
                    className={classes.input}
                  />
                  <div className={classes.inputGroup}>
                    <DateTimeField
                      name="active_start_date"
                      label="Active from"
                      validate={requiredField}
                      className={classes.halfInput}
                    />
                    <DateTimeField
                      name="active_end_date"
                      label="Active until"
                      validate={requiredField}
                      className={classes.halfInput}
                    />
                  </div>

                  <div className={classes.logoHeader}>Group logo</div>
                  <div className={classes.logoWrapper}>
                    <ImageDropzoneField fieldName="imageFile" noDelete />
                  </div>

                  <div className={classes.notificationsHeader}>
                    Event notifications
                  </div>

                  <div className={classes.notificationSubheader}>
                    Schedule email notifications for participants who have opted
                    in to have this group's event notifications sent on their
                    behalf.
                  </div>

                  <NotificationSchedule fieldName="event_group_notifications" />
                </div>

                <div className={classes.bottom}>
                  {!isMobile && (
                    <Button
                      variant="text"
                      disabled={submitting}
                      onClick={onClose}
                    >
                      CANCEL
                    </Button>
                  )}

                  <Button
                    color="primary"
                    className={classes.save}
                    disabled={submitting || pristine}
                    onClick={() => form.submit()}
                  >
                    Save
                    <ButtonSpinner show={submitting} />
                  </Button>
                </div>
              </form>
            );
          }}
        />
      </div>
    </ResponsiveModal>
  );
}

const styles = makeStyles(theme => ({
  container: {
    width: 600,
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    zIndex: 9,
    backgroundColor: "#FFFFFF",
    top: 0,
    padding: 24,
    borderBottom: "1px solid #DBDEEE",
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 0.15,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "auto",
  },
  mainContent: {
    flex: 1,
    width: "100%",
    maxWidth: "100vw",
    padding: 24,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 16,
      paddingTop: 24,
    },
  },
  inputGroup: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  halfInput: {
    width: "calc(50% - 12px)",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: 16,
    },
  },
  input: {
    width: "100%",
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      width: "100%",
    },
  },
  logoHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 16,
  },
  logoWrapper: {
    width: "100%",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  notificationsHeader: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.11,
    lineHeight: "22px",
    color: theme.palette.primary.main,
    paddingBottom: 8,
  },
  notificationSubheader: {
    fontSize: 14,
    letterSpacing: 0.13,
    lineHeight: "24px",
    color: theme.palette.text.secondary,
    paddingBottom: 16,
    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px",
    },
  },
  bottom: {
    width: "100%",
    maxWidth: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 24,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 16,
    },
  },
  save: {
    marginLeft: 16,
    width: 120,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 300,
      maxWidth: "100%",
    },
  },
}));
