import React, { useState } from "react";
import {
  Notification,
  downloadCSV,
  useNotify,
  FileInput,
  FileField,
  useRefresh,
} from "react-admin";
import {
  Dialog,
  IconButton,
  Button,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "react-final-form";
import CloseIcon from "@material-ui/icons/Close";
import DownloadIcon from "@material-ui/icons/GetApp";
import jsonExport from "jsonexport/dist";
import { APIClient, generateAuthHeader } from "../../lib";

export function UploadOrgsModal({ setUploadModal }) {
  const classes = styles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [failedRowsArr, setFailedRows] = useState(null);

  const onSubmit = async values => {
    const { csv } = values;
    if (!csv) return notify("Please upload A CSV file", "error");
    setDisableSubmit(true);
    const reader = new FileReader();
    reader.readAsText(csv.rawFile);
    reader.onload = async () => {
      const res = await APIClient.post(
        "/orgs/csv_upload",
        { csv: reader.result },
        { headers: generateAuthHeader() },
      );
      const { error, errorMessage, data } = res;
      if (error) {
        setDisableSubmit(false);
        return notify(errorMessage, "warning");
      }

      refresh();

      if (!data.failedRows.length) {
        setUploadModal(false);
        return notify("your Orgs have been successfully uploaded", {
          type: "info",
        });
      }
      setFailedRows(data.failedRows);
    };
    reader.onerror = console.error;
  };

  return (
    <Dialog open={true} onClose={() => setUploadModal(false)} maxWidth={false}>
      <div className={classes.importContainer}>
        <div className={classes.importClose}>
          <IconButton onClick={() => setUploadModal(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        {!failedRowsArr && (
          <div>
            <div className={classes.importSubHeader}>Step 1.</div>
            <div className={classes.downloadSection}>
              <div className={classes.importTxt}>Download the CSV template</div>
              <div>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={exportTemplate}
                  startIcon={<DownloadIcon />}
                >
                  download
                </Button>
              </div>
            </div>

            <div className={classes.importSubHeader}>Step 2.</div>
            <div className={classes.importTxt}>
              Add your Org list and save/export it as a CSV.
            </div>
            <div className={classes.importTxt}>
              Make sure to use the headers of the columns exactly how they
              appear in the download file.
            </div>
            <ul>
              <li>
                Fill in the row_number so if any of them aren't successful, we
                can send you back which ones failed.
              </li>
              <li>
                row_number, org_name, email, phone, address, city, state, and
                zip are required fields.
              </li>
              <li>
                If you are adding `class_options` the classes must be separated
                by a `|` THERE CAN NOT BE ANY COMMAS IN THE LIST.
              </li>
            </ul>

            <div className={classes.importSubHeader}>Step 3.</div>
            <div className={classes.importTxt}>Upload your CSV file</div>

            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit} className={classes.form}>
                    <div className={classes.fileDrop}>
                      <FileInput
                        source="csv"
                        label="File Upload"
                        accept={
                          "application/vnd.ms-excel, text/csv, text/plain"
                        }
                        multiple={false}
                      >
                        <FileField source="src" title="title" />
                      </FileInput>
                    </div>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disableSubmit}
                      startIcon={<SaveIcon />}
                    >
                      {disableSubmit && (
                        <CircularProgress
                          size={20}
                          className={classes.progress}
                        />
                      )}
                      save
                    </Button>
                  </form>
                );
              }}
            />
          </div>
        )}
        {failedRowsArr && (
          <div>
            <div className={classes.importSubHeader}>
              Some of your Orgs failed to upload successfully
            </div>
            <table className={classes.failedUploadTable}>
              <thead>
                <tr>
                  <th className={classes.failedUploadTh}>Row Number</th>
                  <th className={classes.failedUploadTh}>Message</th>
                </tr>
              </thead>
              <tbody>
                {failedRowsArr.map(o => {
                  const { row_number, msg } = o;
                  return (
                    <tr key={row_number}>
                      <td className={classes.failedUploadCell}>{row_number}</td>
                      <td className={classes.failedUploadCell}>{msg}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Button
              onClick={() => setUploadModal(false)}
              variant="contained"
              color="primary"
            >
              OK
            </Button>
          </div>
        )}
      </div>
      <Notification />
    </Dialog>
  );
}

function exportTemplate() {
  jsonExport(
    [
      {
        row_number: undefined,
        rep_id: undefined,
        org_name: undefined,
        contact_name: undefined,
        org_slug: undefined,
        email: undefined,
        phone: undefined,
        address: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
        country: undefined,
        website: undefined,
        num_of_students: undefined,
        class_options: undefined,
      },
    ],
    (err, csv) => {
      downloadCSV(csv, "Org Upload Template");
    },
  );
}

const styles = makeStyles(_theme => ({
  importContainer: {
    padding: "12px 24px 24px 24px",
    width: "750px",
    maxWidth: "100%",
  },
  importClose: {
    display: "flex",
    justifyContent: "flex-end",
  },
  importSubHeader: {
    fontSize: "18px",
    padding: "16px 0",
  },
  importTxt: {
    fontSize: "15px",
    paddingRight: "24px",
  },
  downloadSection: {
    display: "flex",
    alignItems: "center",
  },
  fileDrop: {
    margin: "16px 0",
  },
  progress: {
    color: "#ffffff",
    position: "absolute",
  },
  failedUploadTable: {
    marginBottom: "36px",
  },
  failedUploadTh: {
    textAlign: "left",
    paddingRight: "32px",
  },
  failedUploadCell: {
    textAlign: "left",
  },
}));
