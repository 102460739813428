import { makeStyles } from "@material-ui/core";

export const styles = makeStyles(_theme => ({
  manageOverride: {
    width: "530px !important",
  },
  fitContent: {
    width: "fit-content",
  },
}));
