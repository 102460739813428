import React, { Fragment } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  Notification,
  DateInput,
  BooleanInput,
  regex,
} from "react-admin";
import { TWO_DECIMAL_REGEX } from "../../lib";

export function CampaignManagement({ record }) {
  const { id: campaign_id, campaign_management_id } = record;
  const validMoney = [regex(TWO_DECIMAL_REGEX, "Up to 2 decimals only")];

  const ManagementToolbar = props => (
    <Toolbar {...props}>
      <SaveButton redirect={false} />
    </Toolbar>
  );

  const transformDataForSubmit = values => {
    const {
      ship_to_school_shipped,
      ship_to_school_shipping_cost,
      ship_to_school_tracking,
    } = values;
    return {
      ship_to_school_shipped,
      ship_to_school_shipping_cost,
      ship_to_school_tracking,
    };
  };

  return (
    <Fragment>
      <Edit
        title=" "
        id={campaign_management_id}
        basePath={`/order_fulfillment/campaigns/${campaign_id}/show`}
        resource="campaign_management/supplier_controlled"
        undoable={false}
        successMessage="Info updated successfully"
        transform={transformDataForSubmit}
      >
        <SimpleForm toolbar={<ManagementToolbar />}>
          <DateInput
            source="order_form_due_date"
            label="Order Form Due Date"
            disabled
          />
          <DateInput
            source="ship_to_school_delivery_date"
            label="Ship To School Delivery Date"
            disabled
          />
          <BooleanInput source="ship_to_school_shipped" />
          <TextInput
            type="number"
            source="ship_to_school_shipping_cost"
            validate={validMoney}
          />
          <TextInput source="ship_to_school_tracking" fullWidth />
        </SimpleForm>
      </Edit>
      <Notification />
    </Fragment>
  );
}
