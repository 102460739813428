import React, { useState, useEffect, Fragment } from "react";
import { useNotify } from "react-admin";
import { APIClient, generateAuthHeader } from "../../lib";
import { FulfillOrder } from "./FulfillOrder";
import { ReplacementModal } from "./components/ReplacementModal";
import { Replacements } from "./components/Replacements";

export function ShipToHomeOrder(props) {
  const { donation_id, closeDrawerAndRefresh } = props;
  const notify = useNotify();
  const [upcProductIds, setUpcProductIds] = useState({});
  const [prevFulfilledItems, setFulfilledItems] = useState({});
  const [prevOpenItems, setOpenItems] = useState({});
  const [prevBackOrderedItems] = useState({});
  const [prevPrizeItems] = useState({});
  const [replaceableItems, setReplaceableItems] = useState([]);
  const [replacements, setReplacements] = useState([]);

  useEffect(() => {
    if (donation_id) fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donation_id]);

  const fetchOrder = async () => {
    const res = await APIClient.get(`/order_fulfillment/home/${donation_id}`, {
      headers: generateAuthHeader(),
    });
    const { error, errorMessage, data, status } = res;
    if (error) {
      if (status === 404) closeDrawerAndRefresh();
      notify(errorMessage, "warning");
      return;
    }

    setUpcProductIds(data.upcProductIds);
    setFulfilledItems(data.fulfilledItems);
    setOpenItems(data.openItems);
    setReplaceableItems(data.replaceableItems);
    setReplacements(data.replacements);
  };

  return (
    <Fragment>
      <FulfillOrder
        {...{
          donation_id,
          upcProductIds,
          prevFulfilledItems,
          prevOpenItems,
          prevBackOrderedItems,
          prevPrizeItems,
          closeDrawerAndRefresh,
        }}
      />
      <ReplacementModal
        replaceableItems={replaceableItems}
        donation_id={donation_id}
        fetchOrder={fetchOrder}
      />
      <Replacements replacements={replacements} />
    </Fragment>
  );
}
