import { Dispatch, SetStateAction, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import queryString from "query-string";
import { ToastTypes, paths } from "../types";
import { campaignActions, getCampaignId, setToast } from "../state";
import { isOrgOrVolunteer } from "../../lib";
import { RootState } from "../types/state";
import { getIsVolunteer } from "../../customState";
import { validateAndGetInt } from "../lib";

type Props = {
  setUseCampaignId: Dispatch<SetStateAction<number | null>>;
  setJoinCampaignId: Dispatch<SetStateAction<number | null>>;
};
export function useCampaignSwitchController({
  setUseCampaignId,
  setJoinCampaignId,
}: Props) {
  const { role, activeCampaignIds } = useSelector(
    (state: RootState) => state.user,
  );
  const stateCampaignId = useSelector(getCampaignId);
  const isVolunteer = useSelector(getIsVolunteer);
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const {
    location: { search },
    replace,
  } = useHistory();
  const {
    c: queryC,
    campaignId: queryCampaignId,
    campaign_id: queryCampaign_id,
  } = queryString.parse(search);

  useEffect(() => {
    if (!isOrgOrVolunteer(role)) return;
    const qCampaignId = validateAndGetInt(
      queryC ? queryC : queryCampaignId ? queryCampaignId : queryCampaign_id,
    );
    if (path === paths.ORG_CAMPAIGNS) {
      dispatch(campaignActions.clearCampaign());
    } else if (path === paths.ADD_ASSOC_VOLUNTEER_ACCOUNT) {
      dealWithAddAssocVolunteerCampaign(qCampaignId);
    } else if (!stateCampaignId && !qCampaignId) {
      dispatch(
        setToast("Please select a Campaign to continue.", ToastTypes.warn),
      );
      dispatch(campaignActions.clearCampaign());
      replace(paths.ORG_CAMPAIGNS);
    } else if (
      stateCampaignId &&
      (!qCampaignId || qCampaignId === stateCampaignId)
    ) {
      if (campaignAccessCheck(stateCampaignId)) {
        setUseCampaignId(stateCampaignId);
      }
    } else if (
      qCampaignId &&
      (!stateCampaignId || qCampaignId !== stateCampaignId)
    ) {
      if (campaignAccessCheck(qCampaignId)) {
        dispatch(campaignActions.switchCampaigns(qCampaignId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, role, queryC, queryCampaignId, queryCampaign_id, stateCampaignId]);

  const campaignAccessCheck = (campaign_id: number) => {
    if (
      Array.isArray(activeCampaignIds) &&
      activeCampaignIds.includes(campaign_id)
    ) {
      return true;
    } else {
      dispatch(campaignActions.clearCampaign());
      if (isVolunteer) setJoinCampaignId(campaign_id);
      return false;
    }
  };

  const dealWithAddAssocVolunteerCampaign = (
    queryCampaignId: number | null,
  ) => {
    const authCampaignIds = Array.isArray(activeCampaignIds)
      ? activeCampaignIds
      : [];
    if (stateCampaignId && !authCampaignIds.includes(stateCampaignId)) {
      dispatch(campaignActions.clearCampaign());
    } else if (stateCampaignId && stateCampaignId === queryCampaignId) {
      setUseCampaignId(stateCampaignId);
    } else if (queryCampaignId && authCampaignIds.includes(queryCampaignId)) {
      dispatch(campaignActions.switchCampaigns(queryCampaignId));
      setUseCampaignId(queryCampaignId);
    } else if (queryCampaignId && !authCampaignIds.includes(queryCampaignId)) {
      dispatch(campaignActions.clearCampaign());
    }
  };
}
