import React, { useState, useEffect } from "react";
import { useNotify } from "react-admin";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { APIClient, generateAuthHeader } from "../../../lib";

export function BOProductCampaigns({ id: product_id }) {
  const notify = useNotify();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    if (product_id) fetchCampaigns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  const fetchCampaigns = async () => {
    const res = await APIClient.get(
      `/inventory/back_order_product/${product_id}/campaigns`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setCampaigns(data);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Campaign ID</TableCell>
          <TableCell>Company</TableCell>
          <TableCell>Org</TableCell>
          <TableCell>BO Scan Count</TableCell>
          <TableCell>BO Unfulfilled Count</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {campaigns.map((c, index) => {
          const {
            campaign_id,
            orgName,
            companyName,
            backOrderScanCount,
            backOrderUnfulfilledCount,
          } = c;
          return (
            <TableRow key={index}>
              <TableCell>{campaign_id}</TableCell>
              <TableCell>{companyName}</TableCell>
              <TableCell>{orgName}</TableCell>
              <TableCell>{backOrderScanCount}</TableCell>
              <TableCell>{backOrderUnfulfilledCount}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
