import { contactTypes } from "./actions";
import { Contact } from "../../types";

type ContactsState = {
  contactsFetched: boolean;
  contacts: Contact[];
  convertibleDonors: object[];
  contactCount: number;
};

const initialState: ContactsState = {
  contactsFetched: false,
  contacts: [],
  convertibleDonors: [],
  contactCount: 0,
};

export const contactReducers = (
  state = initialState,
  action: any,
): ContactsState => {
  switch (action.type) {
    case contactTypes.CONTACTS_SET:
      return {
        ...state,
        contacts: action.data.contacts,
        contactCount: action.data.contactCount,
        contactsFetched: true,
      };
    case contactTypes.CONVERTIBLE_DONORS_SET:
      return { ...state, convertibleDonors: action.data };
    case contactTypes.CONVERTIBLE_DONORS_CLEAR:
      return { ...state, convertibleDonors: [] };
    default:
      return state;
  }
};
