import React, { Fragment, useState, useEffect } from "react";
import {
  FormDataConsumer,
  SimpleForm,
  TextInput,
  useNotify,
  Labeled,
  EditView,
  Toolbar,
  SaveButton,
  ImageField,
  ImageInput,
  BooleanInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import { APIClient, convertFileToBase64, generateAuthHeader } from "../../lib";
import { ThemeColorPickers, CustomRichText } from "../../components";

export const DonationGroupCampaignDefaults = ({ record }) => {
  const { id: donation_group_id } = record;
  const classes = styles();
  const notify = useNotify();
  const [saving, setSaving] = useState(false);
  const [editProps, setEditProps] = useState({
    basePath: `donation_groups/${donation_group_id}/show`,
    defaultTitle: " ",
    loaded: false,
    loading: true,
    resource: "donation_group_campaign_defaults",
    save: record => onSave(record),
    saving: false,
    version: 0,
  });

  useEffect(() => {
    fetchDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDefaults = async () => {
    setEditProps({ ...editProps, loading: true, loaded: false });
    const res = await APIClient.get(
      `/donation_group_campaign_defaults?donation_group_id=${donation_group_id}`,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage, data } = res;
    if (error) return notify(errorMessage, "warning");
    setEditProps({ ...editProps, loaded: true, loading: false, record: data });
  };

  const onSave = async record => {
    const {
      // remove fields that can't be updated
      company_id: __,
      donation_group_id: ___,
      banner: ____,
      small_banner: _____,
      secondary_banner: ______,
      secondary_small_banner: _______,
      social_media_banner: ________,
      picture: __________,
      id,
      bannerBase64,
      small_bannerBase64,
      secondary_bannerBase64,
      secondary_small_bannerBase64,
      social_media_bannerBase64,
      pictureBase64,
      ...rest
    } = record;
    let data = rest;
    if (bannerBase64) {
      const bannerB64 = await convertFileToBase64(
        bannerBase64,
        "bannerBase64",
      ).catch(console.error);
      data = { ...data, ...bannerB64 };
    }
    if (small_bannerBase64) {
      const smallBannerB64 = await convertFileToBase64(
        small_bannerBase64,
        "small_bannerBase64",
      ).catch(console.error);
      data = { ...data, ...smallBannerB64 };
    }
    if (secondary_bannerBase64) {
      const secondaryBannerB64 = await convertFileToBase64(
        secondary_bannerBase64,
        "secondary_bannerBase64",
      ).catch(console.error);
      data = { ...data, ...secondaryBannerB64 };
    }
    if (secondary_small_bannerBase64) {
      const secondarySmallBannerB64 = await convertFileToBase64(
        secondary_small_bannerBase64,
        "secondary_small_bannerBase64",
      ).catch(console.error);
      data = { ...data, ...secondarySmallBannerB64 };
    }
    if (social_media_bannerBase64) {
      const socialMediaBannerB64 = await convertFileToBase64(
        social_media_bannerBase64,
        "social_media_bannerBase64",
      ).catch(console.error);
      data = { ...data, ...socialMediaBannerB64 };
    }
    if (pictureBase64) {
      const pictureB64 = await convertFileToBase64(
        pictureBase64,
        "pictureBase64",
      ).catch(console.error);
      data = { ...data, ...pictureB64 };
    }

    setSaving(true);
    const res = await APIClient.put(
      `/donation_group_campaign_defaults/${id}`,
      data,
      { headers: generateAuthHeader() },
    );
    const { error, errorMessage } = res;
    setSaving(false);

    if (error) notify(errorMessage, "warning");
    else notify("Defaults updated successfully");

    fetchDefaults();
  };

  return (
    <EditView {...editProps} saving={saving}>
      <SimpleForm
        redirect={null}
        toolbar={
          <Toolbar>
            <SaveButton label="save" redirect={null} />
          </Toolbar>
        }
      >
        <TextInput source="id" disabled={true} />
        <TextInput
          source="donation_group_id"
          label="Group Id"
          disabled={true}
        />
        <TextInput source="name" />
        <TextInput source="campaign_slug" />
        <BooleanInput
          label="Hide Campaign Stats (progress bar, countdown)"
          source="hide_stats_component"
          className={classes.fitContent}
        />
        <BooleanInput
          label="Hide Countdown (only show progress bar)"
          source="hide_countdown"
          className={classes.fitContent}
        />
        <CustomRichText label="Display Text" source="text_html" />
        <CustomRichText
          label="Default Student Display Text"
          source="default_volunteer_display_html"
        />
        <FormDataConsumer>
          {props => <ThemeColorPickers {...props} />}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Fragment>
                <div className={classes.section}>
                  <Labeled
                    label="Current Picture"
                    classes={{ value: classes.imageContainer }}
                  >
                    <ImageField
                      source="picture"
                      record={formData}
                      classes={{ image: classes.image }}
                    />
                  </Labeled>
                  <ImageInput
                    source="pictureBase64"
                    label="Picture"
                    accept="image/*"
                    multiple={false}
                    classes={{ root: classes.imageContainer }}
                  >
                    <ImageField
                      source="src"
                      title="title"
                      classes={{ image: classes.image }}
                    />
                  </ImageInput>
                </div>

                <div className={classes.section}>
                  <Labeled
                    label="Current Banner"
                    classes={{ value: classes.imageContainer }}
                  >
                    <ImageField
                      source="banner"
                      record={formData}
                      classes={{ image: classes.image }}
                    />
                  </Labeled>
                  <ImageInput
                    source="bannerBase64"
                    label="Banner"
                    accept="image/*"
                    multiple={false}
                    classes={{ root: classes.imageContainer }}
                  >
                    <ImageField
                      source="src"
                      title="title"
                      classes={{ image: classes.image }}
                    />
                  </ImageInput>
                </div>

                <div className={classes.section}>
                  <Labeled
                    label="Current Mobile Banner"
                    classes={{ value: classes.imageContainer }}
                  >
                    <ImageField
                      source="small_banner"
                      record={formData}
                      classes={{ image: classes.image }}
                    />
                  </Labeled>
                  <ImageInput
                    source="small_bannerBase64"
                    label="Mobile Banner"
                    accept="image/*"
                    multiple={false}
                    classes={{ root: classes.imageContainer }}
                  >
                    <ImageField
                      source="src"
                      title="title"
                      classes={{ image: classes.image }}
                    />
                  </ImageInput>
                </div>

                <div className={classes.section}>
                  <Labeled
                    label="Current Student Page Custom Banner"
                    classes={{ value: classes.imageContainer }}
                  >
                    <ImageField
                      source="secondary_banner"
                      record={formData}
                      classes={{ image: classes.image }}
                    />
                  </Labeled>
                  <ImageInput
                    source="secondary_bannerBase64"
                    label="Student Page Custom Banner"
                    accept="image/*"
                    multiple={false}
                    classes={{ root: classes.imageContainer }}
                  >
                    <ImageField
                      source="src"
                      title="title"
                      classes={{ image: classes.image }}
                    />
                  </ImageInput>
                </div>

                <div className={classes.section}>
                  <Labeled
                    label="Current Student Page Custom Mobile Banner"
                    classes={{ value: classes.imageContainer }}
                  >
                    <ImageField
                      source="secondary_small_banner"
                      record={formData}
                      classes={{ image: classes.image }}
                    />
                  </Labeled>
                  <ImageInput
                    source="secondary_small_bannerBase64"
                    label="Student Page Custom Mobile Banner"
                    accept="image/*"
                    multiple={false}
                    classes={{ root: classes.imageContainer }}
                  >
                    <ImageField
                      source="src"
                      title="title"
                      classes={{ image: classes.image }}
                    />
                  </ImageInput>
                </div>

                <div className={classes.section}>
                  <Labeled
                    label="Current Social Media Banner"
                    classes={{ value: classes.imageContainer }}
                  >
                    <ImageField
                      source="social_media_banner"
                      record={formData}
                      classes={{ image: classes.image }}
                    />
                  </Labeled>
                  <ImageInput
                    source="social_media_bannerBase64"
                    label="Social Media Banner"
                    accept="image/*"
                    multiple={false}
                    classes={{ root: classes.imageContainer }}
                  >
                    <ImageField
                      source="src"
                      title="title"
                      classes={{ image: classes.image }}
                    />
                  </ImageInput>
                </div>
              </Fragment>
            );
          }}
        </FormDataConsumer>
      </SimpleForm>
    </EditView>
  );
};

const styles = makeStyles(_theme => ({
  section: {
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },
  imageContainer: {
    maxWidth: "350px",
    width: "350px",
    margin: "0 12px",
  },
  image: {
    maxHeight: "80px",
    maxWidth: "350px",
  },
  fitContent: {
    width: "fit-content",
  },
}));
