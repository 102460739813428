import React, { useState } from "react";
import {
  Datagrid,
  TextField,
  Filter,
  TextInput,
  useListController,
  ListView,
  Pagination,
  useNotify,
  TopToolbar,
  Notification,
  BooleanField,
  ImageField,
} from "react-admin";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { Button, Drawer, IconButton } from "@material-ui/core";
import { styles } from "./DonationGroup.styles";
import { CreateItem } from "./CreateItem";
import { EditItem } from "./EditItem";
const asideModes = { CREATE: "add", EDIT: "edit", CLOSED: null };

const ItemFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Item Search" source="q" alwaysOn />
    </Filter>
  );
};

export function DonationGroupItemList({ record }) {
  const { id: donation_group_id } = record;
  const classes = styles();
  const notify = useNotify();
  const basePath = `/donation_groups/${donation_group_id}/show`;
  const [asideMode, setAsideMode] = useState(asideModes.CLOSED);
  const [activeItemId, setActiveItemId] = useState(false);

  const controllerProps = useListController({
    sort: { field: "id", order: "ASC" },
    basePath,
    resource: "donation_group_items",
    filter: { donation_group_id },
    perPage: 25,
  });

  const onSuccess = msg => {
    controllerProps.refetch();
    notify(msg);
    setAsideMode(asideModes.CLOSED);
  };

  const onRowClick = id => {
    setActiveItemId(id);
    setAsideMode(asideModes.EDIT);
  };

  const Actions = () => (
    <TopToolbar>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<AddIcon />}
        className={classes.addGroup}
        onClick={() => setAsideMode(asideModes.CREATE)}
      >
        add item
      </Button>
    </TopToolbar>
  );

  return (
    <div>
      <ListView
        title=" "
        {...controllerProps}
        bulkActionButtons={false}
        exporter={false}
        component="div"
        empty={false}
        actions={<Actions />}
        filters={<ItemFilter />}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      >
        <Datagrid rowClick={onRowClick}>
          <TextField source="id" label="Item ID" />
          <TextField source="tier" />
          <TextField source="tier_order_number" label="Tier Order Number" />
          <TextField source="label" />
          <TextField source="amount" />
          <BooleanField source="multi_use" label="Multi Use" />
          <ImageField
            source="avatar"
            sortable={false}
            classes={{ image: classes.avatar }}
          />
        </Datagrid>
      </ListView>

      <Drawer open={Boolean(asideMode)} anchor="right">
        <div className={classes.drawer}>
          <div className={classes.drawerHeader}>
            <div>
              {asideMode === asideModes.CREATE ? "Add an Item" : "Edit Item"}
            </div>
            <IconButton
              onClick={() => setAsideMode(asideModes.CLOSED)}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>

          {asideMode === asideModes.CREATE && (
            <CreateItem
              donation_group_id={donation_group_id}
              onSuccess={onSuccess}
            />
          )}

          {asideMode === asideModes.EDIT && (
            <EditItem
              donation_group_id={donation_group_id}
              onSuccess={onSuccess}
              activeItemId={activeItemId}
            />
          )}
        </div>
        <Notification />
      </Drawer>
    </div>
  );
}
