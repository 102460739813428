import { Fragment, useEffect } from "react";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { setToast } from "../state";
import { APIPublicClient } from "../lib";
import { APIRes, paths } from "../types";
import { Toast } from "../components";

export const VolunteerSSO = ({ location }) => {
  const { sso_token, campaign_id } = queryString.parse(
    location.search,
  ) as Record<string, string>;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sso_token || !campaign_id) return showError();
    autoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const autoLogin = async () => {
    const res = await APIPublicClient.post<any, APIRes>("/users/login", {
      sso_token,
    });
    const { error, data } = res;
    if (error) return showError();
    if (data.token) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("selectedCampaignId", campaign_id);
      window.location.replace(paths.VOLUNTEER_GUIDED_SETUP);
    } else showError();
  };

  const showError = () => {
    dispatch(
      setToast(
        "We are having trouble logging you in, please check your email for a login link. If that doesn't work please contact support.",
      ),
    );
    setTimeout(() => {
      window.location.replace(paths.LOGIN);
    }, 6000);
  };

  return (
    <Fragment>
      <Toast />
    </Fragment>
  );
};
