import { GenericAutocomplete } from "../../types";
import { repTypes } from "./actions";

type RepState = {
  repAutocomplete: GenericAutocomplete;
  repAutocompleteIdMap: { [k: string]: { id: number; name: string } };
};

const initialState: RepState = {
  repAutocomplete: [],
  repAutocompleteIdMap: {},
};

export const repReducers = (state = initialState, action: any): RepState => {
  switch (action.type) {
    case repTypes.REP_AUTOCOMPLETE_SET:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
